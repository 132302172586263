import React from "react";
import {
  Box,
  Container,
  Grid,
  Typography,
  Snackbar,
  Alert,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import MuiThemeLayout from "../../../Layouts/MuiThemeLayout";
import useTheme from "@mui/material/styles/useTheme";
import banner from "../../../assets/dealAnalyzer/analyzerbanner.webp";
import { menuData } from "./pageStaticData";
import IconCard from "../../../components/DealAnalyzerMainMenuComponents/IconCard";
import { useDispatch, useSelector } from "react-redux";
import { updateMessageQueue } from "../../../actions/dealAnalyzer";
const useStyles = (_theme) => {
  return {
    banner: {
      backgroundImage: `url(${banner})`,
      width: "100%",
      backgroundSize: "100% 100%",
      height: "380px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      border: "red",
    },
    gridContainer: {
      margin: "-80px 0 100px 0",
    },
    inner: {
      display: "grid",
      gridTemplateColumns: "1fr 1fr 1fr",
      gridGap: "40px",
      margin: "0 100px",
    },
  };
};
function DealAnalyzerMainMenu() {
  const theme = useTheme();
  const styles = useStyles(theme);
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const propertyAddress = searchParams.get("address");
  const straightAestheticRemodelData = useSelector(
    (state) => state.straightAestheticRemodel
  );
  const currentMessage = straightAestheticRemodelData?.messageQueue?.[0];

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(updateMessageQueue());
  };
  // useEffect(() => {
  //   dispatch(GetDetails());
  //   // NOTE: Run effect once on component mount, please
  //   // recheck dependencies if effect is updated.
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);
  return (
    <>
      <MuiThemeLayout>
        <>
          <Box sx={styles.banner}>
            <Box>
              <Typography
                variant="h1"
                align="center"
                sx={{ color: "#fff", fontWeight: 700 }}
              >
                Analyze This Property
              </Typography>
            </Box>
            <Box sx={{ padding: "10px", mt: 2 }}>
              <Typography variant="h3" align="center" sx={{ color: "#fff" }}>
                {propertyAddress}
              </Typography>
            </Box>
          </Box>
          <Box sx={styles.gridContainer}>
            <Container maxWidth="xl">
              <Box sx={{ mx: 5 }}>
                <Grid
                  container
                  rowSpacing={4}
                  columnSpacing={{ xs: 1, sm: 3, md: 4 }}
                >
                  {menuData.map((element) => (
                    <Grid item xs={12} sm={6} md={4}>
                      <IconCard
                        icon={element.icon}
                        title={element.title}
                        link={`${element.navigation}/?${searchParams}`}
                        helpText={element?.info}
                        createLink={element?.create}
                        typeId={element?.typeId}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Container>
            <Snackbar
              open={!!currentMessage?.message}
              autoHideDuration={6000}
              onClose={handleClose}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
              <Alert
                onClose={handleClose}
                severity={currentMessage?.["severity"]}
              >
                {currentMessage?.message}
              </Alert>
            </Snackbar>
          </Box>
        </>
      </MuiThemeLayout>
    </>
  );
}

export default DealAnalyzerMainMenu;
