import { SWITCH_TO_SIGNIN, SWITCH_TO_SIGNUP, TOGGLE_AUTH_MODAL, SET_MODAL } from '../actions/types';

const initialState = {
	authModal: {
		isOpen: false,
		isSignin: true,
	},
};

const modalReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case TOGGLE_AUTH_MODAL:
			return {
				...state,
				authModal: {
					...state.authModal,
					isOpen: !state.authModal.isOpen,
					isSignin: true,
				},
			};
		case SWITCH_TO_SIGNIN:
			return {
				...state,
				authModal: { ...state.authModal, isSignin: true },
			};
		case SWITCH_TO_SIGNUP:
			return {
				...state,
				authModal: { ...state.authModal, isSignin: false },
			};
		case SET_MODAL:
			return {
				...state,
				authModal: { ...state.authModal, isOpen: payload || false },
			};

		default:
			return state;
	}
};

export default modalReducer;
