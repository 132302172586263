import BARicon from "../../../../assets/dealAnalyzer/iconComponents/BARicon"
import NCFicon from "../../../../assets/dealAnalyzer/iconComponents/NCFicon"
import RDSADicon from "../../../../assets/dealAnalyzer/iconComponents/RDSADicon"
import SARicon from "../../../../assets/dealAnalyzer/iconComponents/SARicon"
import Rds from "../../../../assets/dealAnalyzer/iconComponents/Rds"
import * as actionCreators from "../../../../actions/dealAnalyzer"

export const menuData = [
    {
        title:'Straight Aesthetic Remodel',
        icon:SARicon,
        info:"Straight aesthetic Remodel not intending to  add square footage to the property",
        navigation:"straight-aesthetic-remodel",
        create:"deal_analyzer/straight_aesthetics/questionnaire",
        reduxActionCreator :actionCreators.updateQuestionnaireStraightAestheticRemodel
    },
    {
        title:'Remodel & Add Addition',
        icon:SARicon,
        info:"Straight aesthetic Remodel not intending to  add square footage to the property",
        navigation:"remodel-and-add-addition",
        create:"deal_analyzer/remodel_add_addition/questionnaire",
        reduxActionCreator: actionCreators.updateQuestionnaireRemodelAddAddition

    },
    {
        title:'Remodel Down to Studs',
        icon:Rds,
        info:"This option is for a property that needs new plumbing, electrical, roofing, major miscellaneous repairs and room layout reconfiguration",
        navigation:"remodel-down-to-studs",
        create:"deal_analyzer/remodel_down_to_studs/questionnaire",
        reduxActionCreator:actionCreators.updateQuestionnaireRemodelDownToStuds
    },
    {
        title:'Remodel Down to Studs & Add Addition',
        icon:RDSADicon,
        info:"This option is for a property that needs a full remodel and additional square footage",
        navigation:"remodel-down-to-studs-add-addition",
        create:"deal_analyzer/remodel_down_to_studs_and_addition/questionnaire",
        reduxActionCreator:actionCreators.updateQuestionnaireDownToStudsAddAddition
    },
    {
        title:'New Construction / Full Development',
        icon:NCFicon,
        info:"This option is for a property that needs to be demolished and a complete New Home needs to be rebuilt on the site",
        navigation:"new-construction",
        create:"deal_analyzer/new_construction/questionnaire",
        reduxActionCreator: actionCreators.updateQuestionnaireNewConstruction
    },
    {
        title:'Buy & Rent',
        icon:BARicon,
        info:"This option is for a property that will be rented out",
        navigation:"buy-and-rent",
        create:"deal_analyzer/buy_and_rent/questionnaire",
        reduxActionCreator: actionCreators.updateQuestionnaireBuyAndRent
    },
]