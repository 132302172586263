import { ThemeProvider } from '@emotion/react';
import React, { Fragment } from 'react';
import { theme } from '../theme';
import { Avatar, createTheme, Divider, Grid, IconButton, Stack, Typography, Link as NLink } from '@mui/material';
import { makeStyles, styled } from '@mui/styles';
import { Link as RLink } from 'react-router-dom';
import BG from '../images/indprodtemp1.jpg';
import Mas1 from '../images/indprodtemp1_1.jpg';
import Mas2 from '../images/indprodtemp1_2.jpg';
import Mas3 from '../images/indprodtemp1_3.jpg';
import RequestAQuote from '../components/RequestAQuote';
import { ReactComponent as FBIcon } from '../images/facebookfillable.svg';
import { ReactComponent as IGIcon } from '../images/instagramfillable.svg';
import { ReactComponent as LIIcon } from '../images/linkedInfillable.svg';
import { ReactComponent as YTIcon } from '../images/youtubefillable.svg';
import { ReactComponent as TWIcon } from '../images/twitterfillable.svg';
//! Construction site
// https://www.figma.com/design/Q83dnbULW6YZeYlFf9qbZX/Premier-Agent?node-id=899-4020&t=3DOJ2qQWiK7y4nIT-4

const pageTheme = createTheme({
	...theme,
	typography: {
		fontFamily: 'Work Sans, serif',
		button: {
			textTransform: 'capitalize',
		},
	},
	palette: {
		primary: {
			main: '#122c77',
		},
		secondary: {
			main: '#FFAA01',
		},
		text: {
			main: '#000',
		},
		paper: {
			main: '#fff',
		},
	},
	primaryColor: '#122c77',
	secondaryColor: '#FFAA01',
});

const Link = styled(RLink)(({ theme }) => ({
	cursor: 'pointer',
}));

const useStyles = makeStyles((theme) => ({
	root: {
		fontFamily: 'Work Sans, serif',
		scrollMarginTop: '100px',
		minHeight: '100vh',
	},
	leftLinks: {
		display: 'flex',
		alignItems: 'center',
		cursor: 'pointer',
		// fontFamily: 'Roboto, sans-serif',
		color: '#14171F',
	},
	banner: {
		// background: `url(${BG}) `,
		backgroundImage: `linear-gradient(0deg, #00000033, #00000033),url(${BG})`,
		height: '92vh',
		backgroundSize: 'cover',
		color: 'white',
		paddingLeft: '3rem',
		paddingRight: '3rem',
		textWrap: 'balance',
	},
}));

const navItems = [
	{ label: 'Home', value: '#home' },
	{ label: 'Request A Quote', value: '#quote' },
	{ label: 'About Us', value: '#about-us' },
];

const socials = [
	{
		key: 'facebook',
		Icon: <FBIcon fill='#122c77' />,
	},
	{
		key: 'twitter',
		Icon: <TWIcon fill='#122c77' />,
	},
	{ key: 'instagram', Icon: <IGIcon fill='#122c77' /> },
	{
		key: 'linkedin',
		Icon: <LIIcon fill='#122c77' />,
	},
	{
		key: 'youtube',
		Icon: <YTIcon fill='#122c77' />,
	},
];

const IndProfTemp1 = ({ address, icon_file, company_name, id, formatted_phone_number, user, ...profDetails }) => {
	const classes = useStyles();

	return (
		<ThemeProvider theme={pageTheme}>
			<Stack className={classes.root}>
				<Stack px='6rem' py='1rem' alignItems='center' justifyContent='space-between' sx={{ background: 'white' }} direction='row'>
					<Stack direction='row' gap='1rem' alignItems={'center'}>
						<Avatar src={icon_file} />
						<Typography fontSize='30' fontWeight={700} color='#D2153D'>
							{company_name}
						</Typography>
					</Stack>
					<Stack direction='row' gap='2rem'>
						{navItems.map((item) => (
							<NLink href={item.value} className={classes.leftLinks} underline='none' key={item.value}>
								{item.label}
							</NLink>
						))}
					</Stack>
				</Stack>
				<Stack className={classes.banner} justifyContent='center'>
					<Typography fontSize={'90px'} fontWeight={600} sx={{ wordWrap: 'break-word', width: '50%' }}>
						Building things is our <br />
						mission
					</Typography>
				</Stack>
				<Grid container spacing={4} px='10rem' py='3rem' mt='3rem'>
					<Grid item xs={6}>
						<Stack gap={5}>
							<img src={Mas1} alt='fds' style={{ borderRadius: '20px', objectFit: 'cover', width: '100%', height: '900px' }} />
							<Typography sx={{ textWrap: 'balance', fontFamily: 'Manrope', fontWeight: 400, fontSize: '70px' }}>
								With our knowledge we guarantee success
							</Typography>
						</Stack>
					</Grid>
					<Grid item xs={6}>
						<Stack gap={5}>
							<img src={Mas2} alt='fds' style={{ borderRadius: '20px', objectFit: 'cover', width: '100%', height: '400px' }} />
							<img src={Mas3} alt='fds' style={{ borderRadius: '20px', objectFit: 'cover', width: '100%', height: '900px' }} />
						</Stack>
					</Grid>
				</Grid>
				<Stack sx={{ width: '50%', margin: 'auto', minWidth: '300px' }} gap='2rem' mt='2rem' pt='5rem' id='quote'>
					<Typography fontSize='30px' textAlign={'center'}>
						Want A Quote?
					</Typography>
					<RequestAQuote withMargin={false} id={id} />
				</Stack>
				<Stack sx={{ paddingX: '3rem' }}>
					<Grid container spacing={2} my='5rem' className={classes.color}>
						<Grid item xs={4}>
							<Stack gap='1rem'>
								<Avatar src={icon_file} />
								<Typography fontWeight={700} color='#D2153D'>
									{company_name}
								</Typography>
							</Stack>
						</Grid>
						<Grid item xs={4}>
							<Stack gap='1rem'>
								<Typography color='black !important' fontWeight={600} fontSize='20px'>
									{address}
								</Typography>
								<Stack direction='row' gap='1rem'>
									{socials.map((social) => (
										<Fragment key={social.key}>
											{profDetails?.[social.key] && (
												<IconButton href={profDetails?.[social.key]} target='_blank'>
													{social.Icon}
												</IconButton>
											)}
										</Fragment>
									))}
								</Stack>
								<Typography color='black !important' sx={{ cursor: 'pointer' }} component='a' href={`tel: ${formatted_phone_number}`}>
									Phone: {formatted_phone_number}
								</Typography>
								<Typography color='black !important' component='a' href={`mailto: ${user?.email}`} sx={{ cursor: 'pointer' }}>
									Email: {user?.email}
								</Typography>
							</Stack>
						</Grid>
						<Grid item xs={4}>
							<Stack gap='1rem'>
								<Typography color='black !important' fontWeight={600} fontSize='20px'>
									Navigation
								</Typography>
								{navItems.map((item) => (
									<Link to={item.value} underline='none' key={item.value} style={{ fontWeight: 400, color: 'black' }}>
										{item.label}
									</Link>
								))}
							</Stack>
						</Grid>
						<Grid item xs={12}>
							<Divider sx={{ background: 'grey' }} />
						</Grid>
						<Grid item xs={12} mt='3rem'>
							<Stack direction={'row'} justifyContent={'space-between'}>
								<Typography color='black !important'>&copy; {new Date().getFullYear()} BPO Tech All rights reserved.</Typography>
								<Stack direction='row' gap='1rem'>
									<Link to='/privacy-policy' color='black !important' style={{ color: 'black' }}>
										Privacy Policy
									</Link>
									<Link to='/terms-of-service' color='black !important' style={{ color: 'black' }}>
										Terms and Conditions
									</Link>
								</Stack>
							</Stack>
						</Grid>
					</Grid>
				</Stack>
			</Stack>
		</ThemeProvider>
	);
};

export default IndProfTemp1;
