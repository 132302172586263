import React from 'react'
import { useCallback } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actionCreators from "../../../actions/dealAnalyzer/index";
import { interestOnlyMortgageCalculator, mortgageLoanCalculator, yearsToMonthsTransformer } from './utils/genericFunctions';


export const SecondaryStraightAstheticRemodelCalcs = () => {

    const financialOptions = useSelector(
        (state) => state?.straightAestheticRemodel.financeOptions
    );





    // calculates total Monthly Mortgage loan

    const localLoanAmount = financialOptions.localSecondaryLoanAmount
        ;
    const loanRate = financialOptions.secondary_interest_rate;
    const secondaryHomeLoanInterestRate = loanRate / 100 / 12;
    const secondaryHomeLoanLoanTerm = +financialOptions.secondary_term_of_loan
        * 12;

    const dispatch = useDispatch()


    const secondaryLoanInterestRateFunc = useCallback(() => {
        const TotalSecondaryMonthlyLoanAmount = mortgageLoanCalculator(
            localLoanAmount,
            secondaryHomeLoanInterestRate,
            secondaryHomeLoanLoanTerm
        );
        const localTotalSecondaryMonthlyLoanAmount = {
            TotalSecondaryMonthlyLoanAmount: TotalSecondaryMonthlyLoanAmount,
        };
        //   const generalData = useSelector((state) => state?.straightAestheticRemodel);
        dispatch(
            actionCreators.updateFinanceOptions(localTotalSecondaryMonthlyLoanAmount)
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [localLoanAmount, secondaryHomeLoanInterestRate, secondaryHomeLoanLoanTerm]);


    //runs whenever the secondaryLoan changes 
    useEffect(() => {
        secondaryLoanInterestRateFunc();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [secondaryLoanInterestRateFunc]);


    const rate = +financialOptions.secondary_annual_interest_rate;
    const amount = +financialOptions.localSecondaryLoanAmount;
    const monthOrYearChecker = financialOptions.secondary_interest_only_term_month_or_year
        ;
    const monthOrYear = financialOptions.secondary_interest_only_term


    const interestAloneMortgageFunc = useCallback(() => {

        const timeTransformer = yearsToMonthsTransformer(monthOrYearChecker, monthOrYear)
        const res = interestOnlyMortgageCalculator(rate, amount)

        const yearsToMonthsTransformed = { secondaryYearsToMonthsTransformed: timeTransformer }
        const interestOnlyReturned = { secondaryInterestOnlyReturned: res }
        dispatch(actionCreators.updateFinanceOptions(interestOnlyReturned))
        dispatch(actionCreators.updateFinanceOptions(yearsToMonthsTransformed))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rate, amount, monthOrYear, monthOrYearChecker])




    useEffect(() => { interestAloneMortgageFunc() }, [interestAloneMortgageFunc])





    return (
        <></>
    )
}
