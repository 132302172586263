import React from "react";
import useTheme from "@mui/material/styles/useTheme";
import {
  FormControl,
  InputAdornment,
  OutlinedInput,
  Box,
  Typography,
} from "@mui/material";

import StyledLabel from "./StyledLabel";
import HtmlToolTip from "./HtmlToolTip";
import commaSeparate from "../utils/commaSeparate";
import { convertFromPercent, convertToPercent } from "../utils/percentConvert";
import { preventMinus } from "../utils/preventNegativeValues";

const useStyle = (theme) => {
  return {
    root: {
      padding: "1.25rem",
    },
    row1: {
      display: "flex",
      marginTop: "1.875rem",
    },
    flatrate: {
      width: "4.5rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "white",
      cursor: "pointer",
      padding: "5px",
      boxSizing: "border-box",
      backgroundColor: theme.palette.primary.main,
    },
    percentage: {
      width: "4.625rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      border: `solid 1px ${theme.palette.primary.main}`,
    },
    symbolsContainer: {
      display: "flex",
      boxSizing: "border-box",
      // border: `solid 1px ${theme.palette.primary.main}`,
      borderRadius: "5px",
      marginRight: "3.75rem",
    },
    row: {
      display: "flex",
    },
    flatRateActive: {
      display: "flex",
    },
    percentageActive: {
      display: "flex",
      flexDirection: "row-reverse",
    },
  };
};

function FlatRatePercentageInput({
  handler,
  title,
  rate,
  rateHandler,
  data,
  name,
  toolTipText,
  noToolTip,
  referenceValue,
  fieldOnly,
}) {
  const theme = useTheme();
  const styles = useStyle(theme);
  const percentage = convertToPercent(data?.[name], referenceValue);
  const flatRate = convertFromPercent(data?.[name], referenceValue);

  //console.log(name)
  const handleChange = (e) => {
    handler({ [name]: e.target.value });
  };

  // this function sets name and the value of the changed event to the handler function
  const handleValues = (e) => {
    let value = e.target.value;
    const variable = e.target.name;
    if (value) {
      value = Number.parseInt(value.split(",").join(""));
      if (isNaN(value)) {
        return;
      }
      handler({ [variable]: +value });
    } else {
      handler({ [variable]: +value });
    }
  };

  //this one passes the rate code to the rateHandler due the option selected to switch btm percentage and dollars
  const handleRateSwap = (rateCode) => {
    if (+rateCode === +rate) {
      return;
    }
    if (+rateCode === 1) {
      rateHandler(rateCode);
      handler({ [name]: percentage });
    } else if (+rateCode === 0) {
      rateHandler(rateCode);
      handler({
        [name]: flatRate ? flatRate.split(",").join("").split(".")[0] : "",
      });
    }
  };

  const selectHandler = (e) => {
    rate === 0 || !rate ? handleValues(e) : handleChange(e);
  };

  return (
    <Box>
      <Box>
        <StyledLabel label={title} />
        {!noToolTip && <HtmlToolTip content={toolTipText} />}
      </Box>

      <Box sx={styles.row}>
        {!fieldOnly && (
          <Box sx={styles.symbolsContainer}>
            <Box
              sx={
                !rate || +rate === 0
                  ? {
                      ...styles.flatrate,
                      borderRadius: fieldOnly ? "5px" : "5px 0 0 5px ",
                    }
                  : {
                      ...styles.percentage,
                      borderRadius: fieldOnly ? "5px" : "5px 0 0 5px ",
                    }
              }
              id="dollar"
              onClick={(e) => {
                e.currentTarget.id === "dollar" && handleRateSwap(0);
              }}
            >
              <Typography variant="inputLabelFont">$</Typography>
            </Box>

            <Box
              sx={
                !rate || +rate === 0
                  ? { ...styles.percentage, borderRadius: "0 5px 5px 0" }
                  : { ...styles.flatrate, borderRadius: "0 5px 5px 0" }
              }
              id="percent"
              onClick={(e) => {
                e.currentTarget.id === "percent" && handleRateSwap(1);
              }}
            >
              <Typography variant="inputLabelFont">%</Typography>
            </Box>
          </Box>
        )}
        <Box
          width={"100%"}
          sx={
            !rate || +rate === 0
              ? styles.flatRateActive
              : styles.percentageActive
          }
        >
          <FormControl
            sx={{
              marginRight: "0.5rem",
              width: fieldOnly ? "100%" : "50%",
              backgroundColor: !rate || +rate === 0 ? "white" : "#E5E4E2",
            }}
          >
            <OutlinedInput
              fullWidth
              onChange={selectHandler}
              name={name}
              placeholder="0"
              disabled={+rate === 1}
              value={
                +rate === 0
                  ? commaSeparate(data?.[name])
                  : referenceValue
                  ? flatRate
                  : ""
              }
              margin="normal"
              onKeyPress={preventMinus}
              inputProps={{ style: { fontSize: "1rem" }, min: 0 }}
              startAdornment={
                <InputAdornment position="start">
                  {" "}
                  <Typography variant="adornment" color="secondary">
                    {" "}
                    $
                  </Typography>
                </InputAdornment>
              }
            />
          </FormControl>

          {!fieldOnly && (
            <FormControl
              sx={{
                minWidth: "6.25rem",
                width: "50%",
                marginRight: "5px",
                backgroundColor: !rate || rate === 0 ? "#E5E4E2" : "white",
              }}
            >
              <OutlinedInput
                fullWidth
                onChange={selectHandler}
                placeholder="0"
                disabled={+rate === 0}
                value={
                  +rate === 1 ? data?.[name] : referenceValue ? percentage : ""
                }
                margin="normal"
                onKeyPress={preventMinus}
                inputProps={{ style: { fontSize: "1rem" }, min: 0 }}
                type="number"
                endAdornment={
                  <InputAdornment position="start">
                    {" "}
                    <Typography variant="adornment" color="secondary">
                      {" "}
                      %
                    </Typography>
                  </InputAdornment>
                }
              />
            </FormControl>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default FlatRatePercentageInput;
