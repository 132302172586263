import React, { useEffect } from 'react';
import './myteam.scss';
import { ReactComponent as CloseIcon } from '../../images/Close.svg';
import { useState } from 'react';
import Modal from '../../components/Modal/Modal';
import { Box, Grid, Paper, Typography, Button, Avatar } from '@mui/material';
import { getYoutubeEmbedLink } from '../../utils/functions';
import { makeStyles } from '@mui/styles';
import base from '../../apis';
import { toast } from 'react-toastify';
import { sampleSize } from 'lodash';
import { formatNumber } from '../../utils/functions';
import Spinner from '../../components/Spinner/Spinner';

const useStyles = makeStyles((theme) => ({
	root: {
		padding: '20px',
		backgroundColor: '#eaf8ff',
		display: 'flex',
		flexDirection: 'column',
		gap: '20px',
		minHeight: '60vh',
	},
	padder: {
		padding: '20px',
	},
	agentCard: {
		background: 'linear-gradient(326.69deg, #1378a5 52.43%, #3b93ba 81.08%)',
		width: '60%',
		color: 'white',
		[theme.breakpoints.down('lg')]: {
			width: '100%',
		},
	},
	whiteButton: {
		backgroundColor: 'white',
		color: '#1378a5',
		maxWidth: '300px',
		margin: '0px auto',
		display: 'block',
		textTransform: 'capitalize',
		textAlign: 'center',
	},
}));

const MyTeam = () => {
	const [detailsModal, setDetailsModal] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [featuredAgents, setFeaturedAgents] = useState([]);
	const [agentCount, setAgentCount] = useState(0);
	const [selectedAgent, setselectedAgent] = useState(null);

	const classes = useStyles();

	useEffect(() => {
		setIsLoading(true);
		base
			.get('/register/agents/')
			.then(({ data }) => {
				setAgentCount(data?.count);
				setFeaturedAgents(sampleSize(data?.results, 3));
			})
			.catch((err) => {
				toast.warn('Could not get featured agents');
			})
			.finally(() => {
				setIsLoading(false);
			});
	}, []);

	const viewDetails = (agent) => {
		setselectedAgent(agent);
		setDetailsModal(true);
	};

	return (
		<>
			{isLoading && <Spinner />}
			<Modal style={{ minWidth: '50vw' }} open={detailsModal} onClose={() => setDetailsModal(false)}>
				<div className='my-teambpomodal'>
					<div className='bpomodal-header'>
						<p className='header_title'>BPO HOMES</p>
						<CloseIcon width='18' height='18' style={{ cursor: 'pointer', color: '#ffffff' }} onClick={() => setDetailsModal(false)} />
					</div>
					<div className='bpomodal-body'>
						<div className='agent_info_wrapper'>
							{getYoutubeEmbedLink(selectedAgent?.video_link || '') && (
								<iframe
									style={{
										margin: '0 auto',
										aspectRatio: '16/9',
										borderRadius: '5px',
										border: 'none',
										flex: 2,
										width: '70%',
									}}
									src={`https://www.youtube.com/embed/${getYoutubeEmbedLink(selectedAgent?.video_link)}`}
									title='Client video'
									allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
									allowFullScreen
								></iframe>
							)}
							<div className='profile_info'>
								<Avatar
									src={selectedAgent?.profile_image}
									sx={{
										width: 100,
										height: 100,
										'&>img': {
											objectPosition: 'top center',
										},
									}}
								/>
								<div className='agent_info'>
									<p className='agent_name'>
										{selectedAgent?.first_name} {selectedAgent?.last_name}
									</p>
									<p className='agent_type'>Real Estate Broker</p>
								</div>
							</div>
							<p className='info_title'>About {selectedAgent?.first_name}</p>
							<p className='info_details'>{selectedAgent?.bio}</p>
							<Button
								variant='contained'
								color='paper'
								sx={{ width: 'fit-content', textTransform: 'capitalize', marginLeft: 'auto' }}
								href={`/agent-finder/${selectedAgent?.id}`}
							>
								View Agent Page
							</Button>
						</div>
					</div>
				</div>
			</Modal>
			<Box className={classes.root}>
				<Paper elevation={0} className={classes.padder}>
					<Typography variant='body2' paragraph>
						We've found <b>{featuredAgents.length}</b> great agents for you to interview.
					</Typography>
					<Typography variant='body2' paragraph>
						We've evaluated <b>{formatNumber(agentCount, 0)}</b> agents to find the best matches for your specific needs.
					</Typography>
				</Paper>
				{featuredAgents.map((agent, index) => (
					<Paper elevation={0} className={[classes.padder, classes.agentCard]} key={agent.id}>
						<Grid container spacing={2}>
							<Grid item xl={6} lg={6} md={6} sm={6} display='flex' alignItems='center' justifyContent='center'>
								{getYoutubeEmbedLink(agent?.video_link || '') ? (
									<iframe
										style={{
											margin: '0 auto',
											aspectRatio: '16/9',
											borderRadius: '5px',
											border: 'none',
											flex: 2,
											width: '100%',
											height: '200px',
										}}
										src={`https://www.youtube.com/embed/${getYoutubeEmbedLink(agent?.video_link)}`}
										title='Client video'
										allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
										allowFullScreen
									></iframe>
								) : (
									<Avatar
										sx={{
											width: 120,
											height: 120,
											'&>img': {
												objectPosition: 'top center',
											},
										}}
										src={agent?.profile_image}
									/>
								)}
							</Grid>
							<Grid item xl={6} lg={6} md={6} sm={6}>
								<Typography variant='h5' sx={{ fontWeight: 900 }}>
									{agent.brokerage_name || 'BPO HOMES'}
								</Typography>
								<Typography variant='body2' mt={2} sx={{ fontWeight: 500 }}>
									{agent.first_name} {agent.last_name}
								</Typography>
								<Typography variant='body2' sx={{ marginTop: '5px' }}>
									Real Estate Broker
								</Typography>
								<p
									style={{ fontWeight: 300, cursor: 'pointer', marginTop: '5px', borderBottom: '1px solid currentColor', width: 'fit-content' }}
									onClick={() => viewDetails(agent)}
								>
									View Details
								</p>
							</Grid>
							<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
								{
									<>
										<Typography variant='body2' sx={{ fontWeight: 500 }}>
											Years of experience
										</Typography>
										<Typography sx={{ fontSize: '30px' }} mt={1}>
											{new Date().getFullYear() - new Date(agent.year_work_commenced).getFullYear()}
										</Typography>
									</>
								}
							</Grid>
							<Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
								<Button fullWidth variant='contained' color='paper' className={classes.whiteButton} href={`/agent-finder/${agent.id}#contact`}>
									Message {agent.first_name}
								</Button>
							</Grid>
							<Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
								<Button fullWidth variant='contained' color='paper' className={classes.whiteButton} href={`/order-bpo-report/${agent.id}`}>
									Buy {agent.first_name}'s BPO Report
								</Button>
							</Grid>
						</Grid>
					</Paper>
				))}
			</Box>
		</>
	);
};

export default MyTeam;
