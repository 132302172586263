import { Box, FormControl,InputAdornment,OutlinedInput, Typography } from '@mui/material'
import React from 'react'
import EnhancedRadioButtonGroup from '../atomicComponents/EnhancedRadioButtonGroup'
import StyledLabel from '../atomicComponents/StyledLabel'

const QuestionnaireDemolishingCost = ({handler,data}) => {
    const showDemolishingCostInput = +data?.has_demolishing_cost === 0
    const specialHandler = (e)=>{
        handler({[e.target.name]:e.target.value})
    }


  return (
    <Box>

      <Box sx={{ display: "flex", marginTop: "1.875rem" }}>
        <Box sx={{ margin: "0.3rem 1.875rem 0 0" }}>
          <StyledLabel label="Is there any Demolishing Cost?" />
        </Box>
        <EnhancedRadioButtonGroup
          options={options}
          name="has_demolishing_cost"
          choice={data?.has_demolishing_cost}
          specialHandler={specialHandler}
          styles={{ display: "flex" }}
        />
      </Box>
      {showDemolishingCostInput && (
        <Box sx={{ width: "100%", marginTop: "10px" }}>
          <Box sx={{ marginBottom: "10px" }}>
            <StyledLabel label="What is the Demolishing Cost Amount" />
          </Box>
          <FormControl sx={{ width: "100%" }}>
            <OutlinedInput
              fullWidth
              margin="normal"
              name={"demolishing_cost"}
              onChange={specialHandler}
              value={data?.["demolishing_cost"]}
              placeholder="0"
              type="number"
              renderValue={(value) => value}
              inputProps={{ style: { fontSize: "1rem" } }}
              startAdornment={
                <InputAdornment position="start">
                  {" "}
                  <Typography variant="adornment" color="secondary">
                    {" "}
                    $
                  </Typography>
                </InputAdornment>
              }
            />
          </FormControl>
        </Box>
      )}
        <Box sx={{ width: "100%", marginTop: "10px" }}>
          <Box sx={{ marginBottom: "10px" }}>
            <StyledLabel label="What is the Estimated Development Cost" />
          </Box>
          <FormControl sx={{ width: "100%" }}>
            <OutlinedInput
              fullWidth
              margin="normal"
              name={"development_cost"}
              onChange={specialHandler}
              value={data?.["development_cost"]}
              placeholder="0"
              type="number"
              renderValue={(value) => value}
              inputProps={{ style: { fontSize: "1rem" } }}
              startAdornment={
                <InputAdornment position="start">
                  {" "}
                  <Typography variant="adornment" color="secondary">
                    {" "}
                    $
                  </Typography>
                </InputAdornment>
              }
            />
          </FormControl>
        </Box>
    </Box>
  );
}
export default QuestionnaireDemolishingCost

const options = [
    'Yes',
    'No'
]