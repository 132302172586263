import React from 'react'
import { Box, Typography,Card } from '@mui/material';
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { IoAlertCircle } from "react-icons/io5";
import { ClickAwayListener } from '@mui/material';


const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#fff",
      maxWidth: 280,
      fontSize: theme.typography.pxToRem(12),


      marginLeft:"30px"
    },
  }));

function HtmlToolTipClickable({ body }) {
    const [open, setOpen] = React.useState(false);

    const handleTooltipClose = () => {
        setOpen(false);
      };
    
      const handleTooltipOpen = (e) => {
        e.stopPropagation()
        setOpen(prev=>!prev);
      };
 
  return (
    <>
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <HtmlTooltip
          PopperProps={{
            disablePortal: true,
          }}
          onClose={handleTooltipClose}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title={
            <React.Fragment>
              <Card sx={{ width:"250px",top:"100px",padding:"10px",left:"0"}}>

                <Box sx={{ textAlign: "center" }}>
                  <Typography variant="toolTip" sx={{ color: "#1378A5" }}>
                    {body}
                  </Typography>
                </Box>
              </Card>
            </React.Fragment>
          }
          placement="bottom"
        >
          <span style={{ display: "inline-block" }} onClick={handleTooltipOpen}>
            <IoAlertCircle
              style={{
                fontSize: "28px",
                marginLeft: "50px",
                color: "#000",
              }}
            />
          </span>
        </HtmlTooltip>
      </ClickAwayListener>
    </>
  );
}

export default HtmlToolTipClickable