import { useSelector } from "react-redux";
import useProformaData2 from "./dealReporthooks/useProformaData2";
import useProformaData from "./dealReporthooks/useProformaData";
import useProformaData3 from "./dealReporthooks/useProformaData3";
import useProformaData4 from "./dealReporthooks/useProformaData4";
import useProformaData5 from "./dealReporthooks/useProformaData5";
import useProformaData6 from "./dealReporthooks/useProformaData6";

function useSelectProformaType() {
  const analyzerType = useSelector(
    (state) =>
      state?.straightAestheticRemodel?.dealAnalyzerReportId?.analyzerTypeId
  );
  const straightAestheticRemodel = useProformaData;
  const straightRemodelAddAddition = useProformaData2;
  const remodelDownToStuds = useProformaData3;
  const remodelDownToStudsAddAddition = useProformaData4;
  const newConstruction = useProformaData5;
  const buyAndRent = useProformaData6;

  switch (analyzerType) {
    case 1:
      return straightAestheticRemodel;
    case 2:
      return straightRemodelAddAddition;
    case 3:
      return remodelDownToStuds;
    case 4:
      return remodelDownToStudsAddAddition;
    case 5:
      return newConstruction;
    case 6:
      return buyAndRent;
    default:
      return null;
  }
}

export default useSelectProformaType;
