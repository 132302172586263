import { Typography, Box } from '@mui/material'
import React,{useEffect} from 'react'
import EnhancedRadioButtonGroup from '../atomicComponents/EnhancedRadioButtonGroup'
import StyledLabel from '../atomicComponents/StyledLabel';

const QuestionnaireSecondaryLoan = ({handler,data,extraComponentData}) => {

    const additionalStyles = {
        display: "flex",
        justifyContent: "space-between",
      };
    const additionalStyles1 = {
        display: "flex",
        flexDirection:"row-reverse",
        justifyContent: "space-between",
      };

      const specialHandler = (e)=>{
        handler({[e.target.name]:e.target.value})
      }
      useEffect(()=>{
        !data?.secondary_wholesale_fee_option && handler({secondary_wholesale_fee_option:1})
        !data?.secondary_lender_points_option && handler({secondary_lender_points_option:1})
        !data?.secondary_loan_type && handler({secondary_loan_type:"0"})
        !data?.secondary_loan_sub_type && handler({secondary_loan_sub_type:1})

      // NOTE: Run effect once on component mount, please
      // recheck dependencies if effect is updated.
      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[])
  return (
      <Box>
        <Typography variant="heading" sx={{color:"#1378A5"}}>{extraComponentData?.secondaryHeaders?.[0]}</Typography>
        <Box sx={{ display: "flex",marginTop:"2rem", alignItems:"center"}}>
          <Box sx={{display:"flex",mr:4}}>
            <StyledLabel label="Is there a wholesale fee?" />
          </Box>
          <Box sx={{mt:2}}>
          <EnhancedRadioButtonGroup
            options={responseOptions}
            styles={additionalStyles1}
            specialHandler = {specialHandler}
            name='secondary_wholesale_fee_option'
            choice = {data?.secondary_wholesale_fee_option}
          />
          </Box>

        </Box>
        <Box sx={{ display: "flex" }}>
          <Box  sx={{display:"flex", mr:4}}>
            <StyledLabel label="Is there Lender Points?" />
          </Box>
          <Box sx={{mt:2}}>
          <EnhancedRadioButtonGroup
            options={responseOptions}
            styles={additionalStyles1}
            specialHandler = {specialHandler}
            name = 'secondary_lender_points_option'
            choice = {data?.secondary_lender_points_option}
          />
          </Box>

        </Box>
        <Box>
          <Box  sx={{display:"flex",margin:"0.3rem 3.125rem 0 0"}}>
            <StyledLabel label="What Type of Loan are you using?" />
          </Box>
          <EnhancedRadioButtonGroup
            options={loanType}
            styles={additionalStyles}
            specialHandler = {specialHandler}
            name = 'secondary_loan_type'
            choice = {data?.secondary_loan_type}
          />
        </Box>
        {!!+data?.secondary_loan_type && 
        <Box>
          <Box  sx={{display:"flex",margin:"0.3rem 3.125rem 0 0"}}>
            <StyledLabel label="What Type of Loan is it?" />
          </Box>
          <EnhancedRadioButtonGroup
            options={loanSubType}
            styles={additionalStyles}
            specialHandler = {specialHandler}
            name = 'secondary_loan_sub_type'
            choice = {data?.secondary_loan_sub_type}
          />
        </Box>}
        


      </Box>
  );
}
export default QuestionnaireSecondaryLoan
const responseOptions = [
    'No',
    'Yes'
]
const loanType=[
    'Conventional',
    'Private Hard Money',
    'Seller Financing'
]
const loanSubType =[
  'Conventional Mortgage Loan',
  'Interest Only Mortgage Loan'
]

