import axios from 'axios';
import { URL } from '../constants';
import { toast } from 'react-toastify';
import { signout } from '../actions';
import { store } from '../index';
//create axios instance with base use

//const token = { username: process.env.REACT_APP_LISTING_API_KEY, password: process.env.REACT_APP_LISTING_API_SECRET }
const sampleToken = { username: 'simplyrets', password: 'simplyrets' };

export const mlsAPI = axios.create({
	baseURL: 'https://api.simplyrets.com/properties',
	auth: sampleToken,
	withCredentials: true,
	headers: {
		'Content-Type': 'application/json',
		'Accept-Language': 'en-US,en;q=0.9',
	},
});

const base = axios.create({
	baseURL: URL,
	headers: {
		'Content-Type': 'application/json',
		'Accept-Language': 'en-US,en;q=0.9',
	},
});

base.interceptors.request.use((config) => {
	// console.log(config);
	const token = localStorage.getItem('atoken');
	if (token) {
		config.headers.Authorization = `JWT ${token}`;
	}
	return config;
});

base.interceptors.response.use(
	(config) => {
		return config;
	},
	(error) => {
		if ('message' in error && error.message === 'canceled') return;
		const { status } = error?.response;
		if (status === 401) {
			localStorage.setItem('nextRoute', window.location.pathname);
			store.dispatch(signout());
			localStorage.clear();
			toast.error('Session expired. Please login again.');
			window.location.href = '/';
		}

		return Promise.reject(error);
	}
);

//create axios instance for file upload
const file = axios.create({
	baseURL: URL,
	headers: {
		'Content-Type': 'multipart/form-data',
		'Accept-Language': 'en-US,en;q=0.9',
	},
});

file.interceptors.request.use((config) => {
	// console.log(config);
	const token = localStorage.getItem('atoken');
	if (token) {
		config.headers.Authorization = `JWT ${token}`;
	}
	return config;
});

const leadUrl = axios.create({
	baseURL: process.env.REACT_APP_PREMIER_LEADS_URL,
	headers: {
		'Content-Type': 'application/json',
		'Accept-Language': 'en-US,en;q=0.9',
	},
});

leadUrl.interceptors.request.use((config) => {
	// console.log(config);
	const token = localStorage.getItem('ac_premier_leads');
	if (token) {
		config.headers.Authorization = `Bearer ${token}`;
	}
	return config;
});

export default base;
export { file, leadUrl };
