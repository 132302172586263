import { useDispatch, useSelector } from "react-redux";
import { switchToSignin, signout, toggleAuthModal } from "../../../actions";

function useAuth() {
  const dispatch = useDispatch();

  const loggedIn = useSelector(
    (state) => state.auth.token.access && state.auth.token.refresh
  );
  const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token?.split(".")[1]));
    } catch (e) {
      return null;
    }
  };

  const checkAuth = () => {
    const user = localStorage.getItem("atoken");
    if (user && loggedIn) {
      const decodedJwt = parseJwt(user);
      if (decodedJwt.exp * 1000 < Date.now()) {
        dispatch(signout());
        return false;
      }
      return true;
    }
  };

  const openLogin = () => {
    dispatch(toggleAuthModal());
    dispatch(switchToSignin());
  };

  return { checkAuth, openLogin };
}

export default useAuth;
