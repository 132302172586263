import {
	Button,
	Checkbox,
	Chip,
	Collapse,
	Divider,
	IconButton,
	InputAdornment,
	MenuItem,
	Pagination,
	Popover,
	Select,
	Stack,
	Tab as Tabb,
	Tabs,
	TextField,
	Typography,
} from '@mui/material';
import { styled } from '@mui/styles';
import ChevronUp from '@untitled-ui/icons-react/build/esm/ChevronUp';
import ChevronUpDouble from '@untitled-ui/icons-react/build/esm/ChevronUpDouble';
import DotsVertical from '@untitled-ui/icons-react/build/esm/DotsVertical';
import Edit05 from '@untitled-ui/icons-react/build/esm/Edit05';
import SearchLg from '@untitled-ui/icons-react/build/esm/SearchLg';
import Trash01 from '@untitled-ui/icons-react/build/esm/Trash01';
import dayjs from 'dayjs';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import base from '../apis';
import BadgeForQuotes from '../components/BadgeForQuotes';
import DatePickerForQuotes from '../components/DatePickerForQuotes';
import DeleteDialog from '../components/DeleteDialog';
import FollowUpDialog from '../components/FollowUpDialog';
import LoadingDialog from '../components/LoadingDialog';
import QuoteTableHeader from '../components/QuoteTableHeader';

const Tab = styled(Tabb)(({ theme }) => ({
	textTransform: 'capitalize',
	gap: '1rem',
	minHeight: 'unset',
}));

const tabs = [
	{
		value: '',
		tabKey: 'all',
		label: 'All Follow-Ups',
	},
	{
		value: 'not_sent',
		label: 'Pending Follow-Ups',
	},
	{
		value: 'is_sent',
		label: 'Completed Follow-Ups',
	},
];

const Followups = () => {
	const [followups, setFollowups] = useState([]);
	const [page, setPage] = useState(0);
	const [count, setCount] = useState(0);
	const [isLoading, setIsLoading] = useState(true);
	const [followUp, setFollowUp] = useState({
		date: null,
		message: '',
	});

	const { indPro } = useSelector((state) => state.subscriptions);
	const navigate = useNavigate();
	const selectedListing = useSelector((state) => state.auth.selectedListing);
	const [selectedTab, setSelectedTab] = useState('');
	const [tabCounts, setTabCounts] = useState({
		all: 0,
		is_sent: 0,
		not_sent: 0,
	});

	const getAllData = useCallback(
		async (extraParams) => {
			const { start_date, end_date, page: mainPage } = extraParams || {};
			try {
				setIsLoading(true);
				const params = {
					profession_id: selectedListing?.id,
				};
				if (selectedTab) {
					params['is_sent'] = selectedTab === 'is_sent';
				}
				if (mainPage || page) params.page = mainPage || page;
				if (start_date) params.date_gt = dayjs(start_date).format('YYYY-MM-DD');
				if (end_date) params.date_lt = dayjs(end_date).format('YYYY-MM-DD');
				const { data } = await base.get('professionals/follow-up/', {
					params,
				});
				setTabCounts(data.quote_count);
				setCount(data.count);
				setPage(0);
				setFollowups(
					data.results.map((item) => {
						const { request_quote, ...rest } = item;
						const { id, message, ...others } = request_quote;
						return { ...rest, ...others };
					})
				);
			} catch (error) {
				toast.warn(error?.response?.data?.detail || 'Could not get followups');
			} finally {
				setIsLoading(false);
			}
		},
		[selectedListing?.id, selectedTab, page]
	);

	useEffect(() => {
		if (!selectedListing) {
			navigate('/my-listing/all');
			return;
		}
		if (!indPro) {
			navigate('/industry-prof-subscription', { replace: true });
			return;
		}
		getAllData();
	}, [indPro, selectedListing, navigate, getAllData]);

	const [detailsModalOpen, setDetailsModalOpen] = useState(false);
	const [isFollowupLoading, setIsFollowupLoading] = useState(false);

	const handleEditFollowup = async () => {
		if (!followUp.message) {
			toast.warn('Please enter a follow up message');
			return;
		}
		if (!followUp.date) {
			toast.warn('Please select a date');
			return;
		}
		setIsFollowupLoading(true);
		try {
			await base.patch(`professionals/follow-up/${followUp?.id}/`, {
				send_date: followUp.date?.format(),
				message: followUp.message,
			});
			getAllData();
			toast.success('Follow up saved successfully');
			setDetailsModalOpen(false);
		} catch (error) {
			toast.error('Could not save follow up');
		} finally {
			setDetailsModalOpen(false);
		}
	};

	const [selectedFollows, setSelectedFollows] = useState([]);
	const allFollowIds = followups.map((i) => i.id);
	const equal = _.isEqual(allFollowIds.sort(), selectedFollows.sort()) && selectedFollows.length > 0;

	const handleSelectAll = () => {
		if (equal) setSelectedFollows([]);
		else setSelectedFollows(allFollowIds);
	};
	const onCheckboxChange = (id) => {
		if (selectedFollows.includes(id)) setSelectedFollows((v) => v.filter((i) => i !== id));
		else setSelectedFollows((v) => [id, ...v]);
	};

	const handlePageChange = async (e, page) => {
		setPage(page);
	};

	const openFollowup = (id) => {
		const item = followups.find((el) => el.id === id);
		setFollowUp({ id: item.id, message: item.message, date: dayjs(item.send_date) });
		setDetailsModalOpen(true);
	};
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);
	const [toDelete, setToDelete] = useState([]);
	const [deleteOpen, setDeleteOpen] = useState(false);
	const startDeletionProcess = (ids) => {
		setToDelete(ids);
		setDeleteOpen(true);
	};

	const deleteQuotes = async () => {
		try {
			setIsLoading(true);
			await base.post('/professional/follow-up/delete/', {
				ids: toDelete,
			});
			setDeleteOpen(false);
			setToDelete([]);
			getAllData();
		} catch (error) {
			toast.error('Could not delete follow-up');
		} finally {
			setIsLoading(false);
		}
	};
	const [option, setOption] = useState('');

	const handleSendNow = async (ids) => {
		try {
			setIsLoading(true);
			await base.post('/professionals/send-follow-up/', {
				ids,
			});
			toast.success(`Follow-up${ids.length > 1 ? 's' : ''} sent`);
			getAllData();
		} catch (error) {
			toast.error('Could not send follow-ups');
		} finally {
			setIsLoading(false);
		}
	};

	const handleBulkAction = async () => {
		if (option === 'Delete Selected') {
			startDeletionProcess(selectedFollows);
			setOption('');
			return;
		}
		if (option === 'Send Selected Now') {
			await handleSendNow(selectedFollows);
			setOption('');
			return;
		}
	};

	return (
		<>
			<LoadingDialog isLoading={isLoading} />
			<FollowUpDialog
				isOpen={detailsModalOpen}
				setOpen={setDetailsModalOpen}
				date={followUp.date}
				onDateChange={(value) => setFollowUp((v) => ({ ...v, date: value }))}
				message={followUp.message}
				onMessageChange={(e) => setFollowUp((v) => ({ ...v, message: e.target.value }))}
				onSubmit={handleEditFollowup}
				isLoading={isFollowupLoading}
				actionButtonText='Save Changes'
				cancelButtonText={'Cancel'}
				title='Edit Follow-Up'
			/>
			<DeleteDialog
				isOpen={deleteOpen}
				onCancel={() => {
					setToDelete([]);
				}}
				items={selectedFollows}
				onConfirm={deleteQuotes}
				setOpen={setDeleteOpen}
			/>

			<Stack p='1rem' sx={{ minHeight: '100vh' }} gap='1rem'>
				<Stack sx={{ borderBottom: '1px solid #EAECF0', paddingBottom: '20px' }} direction='row' justifyContent={'space-between'}>
					<Typography variant='h1' color='#475467' fontWeight={600} fontSize='24px'>
						Follow-Ups
					</Typography>
					<Stack direction='row' gap='1rem'>
						<DatePickerForQuotes label='Start Date' value={startDate} onChange={setStartDate} />
						<DatePickerForQuotes label='End Date' value={endDate} onChange={setEndDate} />
						<Button
							color='secondary'
							variant='outlined'
							onClick={() => {
								getAllData({ start_date: startDate, end_date: endDate, page: 1 });
							}}
						>
							Find
						</Button>
					</Stack>
				</Stack>
				<Tabs
					value={selectedTab}
					onChange={(event, newValue) => {
						setSelectedTab(newValue);
					}}
					sx={{ borderBottom: 1, borderColor: '#EAECF0' }}
					textColor='secondary'
					indicatorColor='secondary'
				>
					{tabs.map((el) => (
						<Tab
							label={el.label}
							value={el.value}
							key={el.value}
							icon={<BadgeForQuotes badgeContent={tabCounts?.[el.tabKey || el.value]} isActive={selectedTab === el.value}></BadgeForQuotes>}
							iconPosition='end'
						/>
					))}
				</Tabs>
				<Stack direction='row' justifyContent={'space-between'} sx={{ background: '#F9FAFB', borderRadius: '12px', padding: '1rem' }}>
					<TextField
						size='small'
						color='secondary'
						placeholder='Search'
						InputProps={{
							startAdornment: (
								<InputAdornment position='start'>
									<SearchLg />
								</InputAdornment>
							),
						}}
					/>
					<Stack direction='row' gap='1rem'>
						<Select
							size='small'
							value={option}
							onChange={(e) => setOption(e.target.value)}
							displayEmpty
							color='secondary'
							renderValue={(value) => (value ? value : 'Bulk Action')}
							disabled={selectedFollows.length === 0}
						>
							<MenuItem value='Delete Selected'>Delete Selected</MenuItem>
							<MenuItem value='Send Selected Now'>Send Selected Now</MenuItem>
						</Select>
						<Button color='secondary' variant='outlined' onClick={handleBulkAction}>
							Apply
						</Button>
					</Stack>
				</Stack>
				<QuoteTableHeader
					allselected={equal}
					onChange={handleSelectAll}
					indeterminate={selectedFollows.length > 0 && selectedFollows.length < allFollowIds.length}
					dateText='Follow-Up Date'
				/>
				{followups.map((item, index) => (
					<Collapsible
						key={item.id}
						{...item}
						index={index}
						handleCheckbox={onCheckboxChange}
						selected={selectedFollows?.includes(item.id)}
						openEdit={openFollowup}
						handleDeleteFollow={startDeletionProcess}
						handleSendNow={handleSendNow}
					/>
				))}
				<Pagination
					page={page}
					count={Math.ceil(count / 10)}
					onChange={handlePageChange}
					color='secondary'
					variant='outlined'
					shape='rounded'
					sx={{ margin: 'auto' }}
				/>
			</Stack>
		</>
	);
};

export default Followups;

const Collapsible = ({
	first_name,
	last_name,
	email,
	phone_number,
	message,
	handleCheckbox,
	id,
	index,
	send_date,
	is_sent,
	openEdit,
	handleDeleteFollow,
	handleSendNow,
	selected,
}) => {
	const [open, setOpen] = useState(false);
	const name = `${first_name} ${last_name}`;

	const [anchorEl, setAnchorEl] = useState(null);

	const handleClick = (event) => {
		preventer(event);
		setAnchorEl(event.currentTarget);
	};
	const handleDelete = (e) => {
		preventer(e);
		handleDeleteFollow([id]);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};
	const popOverOpen = Boolean(anchorEl);

	const preventer = (e) => {
		e?.stopPropagation();
	};

	return (
		<>
			<Popover
				component={Stack}
				open={popOverOpen}
				anchorEl={anchorEl}
				onClose={handleClose}
				sx={{
					'& .MuiPaper-root': {
						borderRadius: '12px',
						border: '1px solid #EAECF0',
						boxShadow: '0px 8px 8px -4px #10182808, 0px 20px 24px -4px #10182814',
						p: '0.5rem',
					},
				}}
			>
				<Stack divider={<Divider sx={{ borderColor: '#EAECF0' }} />} gap='5px'>
					<Button
						onClick={() => {
							openEdit(id);
							handleClose();
						}}
						sx={{ whiteSpace: 'nowrap' }}
						color='text'
						variant='text'
						fullWidth
					>
						Edit Followup
					</Button>

					<Button
						variant='text'
						color='text'
						onClick={() => {
							handleSendNow([id]);
							handleClose();
						}}
					>
						Send Now
					</Button>
				</Stack>
			</Popover>
			<Stack
				direction='row'
				justifyContent={'space-evenly'}
				alignItems='center'
				sx={{ p: '1rem', boxShadow: '0px 0px 5px 0px #0000001A', borderRadius: '8px', fontSize: '14px', color: '#475467', cursor: 'pointer' }}
				onClick={() => setOpen(true)}
			>
				<Stack direction='row' gap='1rem' sx={{ flex: 2 }} alignItems='center'>
					<Checkbox
						size='small'
						color='secondary'
						sx={{
							marginRight: '1.2rem',
						}}
						onClick={preventer}
						onChange={(e) => handleCheckbox(id)}
						checked={selected}
					/>
					<Typography color='#475467' fontSize='14px'>
						{index + 1}
					</Typography>
					<Typography fontWeight={500} fontSize='14px' color='#101828'>
						{name}
					</Typography>
				</Stack>
				<Typography sx={{ flex: 2 }} fontSize='14px' textAlign={'center'}>
					{email}
				</Typography>
				<Typography sx={{ flex: 2 }} fontSize='14px' textAlign={'center'}>
					{phone_number}
				</Typography>
				<Typography sx={{ flex: 1 }} fontSize='14px' textAlign={'center'}>
					{dayjs(send_date).format('MMMM DD, YYYY HH:MM')}
				</Typography>
				<Stack sx={{ flex: 1 }} fontSize='14px' alignItems='center'>
					<Chip
						label={!is_sent ? 'Pending' : 'Completed'}
						sx={{
							color: is_sent ? '#027A48' : '#B54708',
							background: is_sent ? '#ECFDF3' : '#FFFAEB',
							textTransform: 'capitalize',
						}}
					/>
				</Stack>
				<Stack sx={{ flex: 1 }} gap='1rem' direction='row'>
					<IconButton
						size='small'
						onClick={(e) => {
							preventer(e);
							openEdit(id);
						}}
					>
						<Edit05 />
					</IconButton>
					<IconButton onClick={handleDelete} size='small'>
						<Trash01 />
					</IconButton>
					<IconButton onClick={handleClick} size='small'>
						<DotsVertical />
					</IconButton>
				</Stack>
			</Stack>
			<Collapse in={open}>
				<Stack
					divider={<Divider sx={{ borderColor: '#EAECF0' }} />}
					gap='1rem'
					justifyContent={'space-evenly'}
					sx={{ p: '1rem', boxShadow: '0px 0px 5px 0px #0000001A', borderRadius: '8px', fontSize: '14px', color: '#475467' }}
				>
					<Stack direction='row' justifyContent='space-between' alignItems='center'>
						<Typography color='#101828' fontWeight={600} fontSize='18px'>
							Notes
						</Typography>
						<IconButton
							onClick={() => {
								setOpen(false);
							}}
						>
							<ChevronUp />
						</IconButton>
					</Stack>
					<Typography color='#475467'>{message}</Typography>
					<Stack direction='row' justifyContent={'space-between'}>
						<Button
							color='text'
							variant='outlined'
							startIcon={<ChevronUpDouble />}
							endIcon={<ChevronUpDouble />}
							onClick={() => {
								setOpen(false);
							}}
							sx={{ borderColor: '#D0D5DD', color: '#344054' }}
						>
							Cascade
						</Button>
						<Stack direction='row' gap='1rem'>
							<Button
								color='text'
								variant='outlined'
								startIcon={<Edit05 />}
								sx={{ borderColor: '#D0D5DD', color: '#344054' }}
								onClick={() => {
									openEdit(id);
								}}
							>
								Edit Follow Up
							</Button>
						</Stack>
					</Stack>
				</Stack>
			</Collapse>
		</>
	);
};
