import React, { useEffect } from "react";
import { Box, Container, Typography, Grid } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import MuiThemeLayout from "../../../../Layouts/MuiThemeLayout";
import useTheme from "@mui/material/styles/useTheme";
// import {Snackbar,Alert} from "@mui/material";
import banner from "../../../../assets/dealAnalyzer/analyzerbanner.webp";
import { menuData } from "./pageStaticData";
import IconCard from "../../../../components/DealAnalyzerMainMenuComponents/IconCard";
import { useDispatch, useSelector } from "react-redux";
// import { updateMessageQueue } from "../../../../actions/dealAnalyzer";
import { GetDetails } from "../../../../actions";
const useStyles = (_theme) => {
  return {
    banner: {
      backgroundImage: `url(${banner})`,
      width: "100%",
      backgroundSize: "100% 100%",
      height: "380px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      border: "red",
    },
    gridContainer: {
      margin: "-80px 0 100px 0",
    },
    inner: {
      display: "grid",
      gridTemplateColumns: "1fr 1fr 1fr",
      gridGap: "4rem",
      margin: "0 10rem",
    },
  };
};
function QuestionnairesMainMenu() {
  const theme = useTheme();
  const styles = useStyles(theme);
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const propertyAddress = searchParams.get("address");
  // const straightAestheticRemodelData = useSelector(
  //   (state) => state.straightAestheticRemodel
  // );
  const questionnaireData = useSelector(
    (state) => state.dealAnalyzerQuestionnaires
  );
  const questionnaireDataArray = [
    questionnaireData?.straightAestheticRemodel,
    questionnaireData?.remodelAndAddAddition,
    questionnaireData?.remodelDownToStuds,
    questionnaireData?.remodelDownToStudsAddAddition,
    questionnaireData?.newConstruction,
    questionnaireData?.buyAndRent,
  ];
  // const currentMessage = straightAestheticRemodelData?.messageQueue?.[0];

  // const handleClose = (event, reason) => {
  //   if (reason === "clickaway") {
  //     return;
  //   }
  //   dispatch(updateMessageQueue());
  // };
  useEffect(() => {
    dispatch(GetDetails());
    // NOTE: Run effect once on component mount, please
    // recheck dependencies if effect is updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <MuiThemeLayout>
        <>
          <Box sx={styles.banner}>
            <Box>
              <Typography
                variant="h1"
                align="center"
                sx={{ color: "#fff", fontWeight: 700 }}
              >
                Analyze This Property
              </Typography>
            </Box>
            <Box sx={{ padding: "10px", mt: 2 }}>
              <Typography variant="h3" align="center" sx={{ color: "#fff" }}>
                {propertyAddress}
              </Typography>
            </Box>
          </Box>
          <Box sx={styles.gridContainer}>
            <Container maxWidth="xl">
              <Box sx={{ mx: 5 }}>
                <Grid
                  container
                  rowSpacing={4}
                  columnSpacing={{ xs: 1, sm: 3, md: 4 }}
                >
                  {menuData.map((element, index) => (
                    <Grid item xs={12} sm={6} md={4}>
                      <IconCard
                        icon={element.icon}
                        title={element.title}
                        link={`${element.navigation}/?${searchParams}`}
                        helpText={element?.info}
                        createLink={element?.create}
                        actionCreator={element?.reduxActionCreator}
                        dummyLoad={{ investors: [] }}
                        referenceData={questionnaireDataArray[index]}
                        propertyAddress={propertyAddress}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Container>
            {/* <Snackbar
              open={currentMessage}
              autoHideDuration={6000}
              onClose={handleClose}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
              <Alert
                onClose={handleClose}
                severity={currentMessage?.["severity"]}
              >
                {currentMessage?.message}
              </Alert>
            </Snackbar> */}
          </Box>
        </>
      </MuiThemeLayout>
    </>
  );
}

export default QuestionnairesMainMenu;
