export const getCostPsqftFromAdvancedRemodelCost = ({
  aestheticItems,
  nonAestheticItems,
  additionalItems,
  preExistingSquareFootage,
  additionalSquareFootage,
}) => {
  const remodelCost =
    (getTotal(aestheticItems) + getTotal(nonAestheticItems)).toFixed(2) || 0;

  const newConstructionCost = getTotal(additionalItems).toFixed(2);
  const averageCost = remodelCost + newConstructionCost / 2;
  const estimatedRemodelCostPsqft =
    (remodelCost / +preExistingSquareFootage).toFixed(2) || 0;
  const estimatedNewConstructionCostPsqft =
    (newConstructionCost / +additionalSquareFootage).toFixed(2) || 0;

  const estimatedTotalRemodelWithAdditional = (
    getTotal(aestheticItems) +
    getTotal(nonAestheticItems) +
    getTotal(additionalItems)
  ).toFixed(2);

  return {
    estimatedRemodelCostPsqft,
    estimatedNewConstructionCostPsqft,
    remodelCost,
    newConstructionCost,
    estimatedTotalRemodelWithAdditional,
    averageCost,
  };
};

const getTotal = (items) => {
  if (!items?.length) {
    return 0;
  }
  const res = items
    .map((item) => (+item?.value ? +item.value : 0))
    .reduce((a, b) => a + b);

  return res;
};
