import { SET_SUBSCRIPTIONS } from '../actions/types';

const initialState = {
	bpoFarm: false,
	premierAgentWebsite: false,
	liveLeads: false,
	dealAnalyzer: false,
};

const subscriptionReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case SET_SUBSCRIPTIONS:
			return { ...state, ...payload };
		case 'SIGNOUT':
			return initialState;

		default:
			return state;
	}
};

export default subscriptionReducer;
