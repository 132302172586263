import { PictureAsPdf, UploadFile } from '@mui/icons-material'
import { Box, Button, Divider, FormControlLabel, Grid, Radio, RadioGroup, Stack, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'
import { makeStyles } from '@mui/styles'
import React, { useRef, useState } from 'react'
import { toast } from 'react-toastify'
import BottomActions from '../helpers/BottomActions'


const useStyles = makeStyles(theme => ({
  label: {
    fontSize: '.9rem',
    color: grey[600],
    marginBottom: '.5rem'
  },
  btn: {
    textTransform: 'none',
    borderRadius: '8px'
  }
}))

const DropZone = ({ onChange, classes, file, remove, preview }) => {
  const upload = useRef()
  return (
    <>
      <input type={'file'} ref={upload} accept='.pdf' onChange={onChange} style={{ display: 'none' }} />
      <Box onClick={() => {
        upload?.current?.click()
        // console.log('click to upload pdf')
      }} sx={{ p: 4, mb: 3, border: '2px dashed lightgrey', borderRadius: '10px', textAlign: 'center', cursor: 'pointer' }}>
        {file ? <PictureAsPdf sx={{ fontSize: '3rem', color: 'lightgrey' }} /> : <UploadFile sx={{ fontSize: '3rem', color: 'lightgrey' }} />}
        <Typography variant='body2'>{file ? file?.name : 'Click to browse a PDF file'}</Typography>
        <Typography variant='body2' color={'GrayText'}>Max file size: 1MB</Typography>
      </Box>
      <Stack direction={'row'} justifyContent={'flex-end'} gap={1} mt={2} mb={3}>
        <Button className={classes.btn} disableElevation size='small' variant='contained' color='secondary' onClick={() => remove()}>Delete</Button>
      </Stack>
    </>
  )
}


const StageTwo = ({ data, dispatch, exit, saveForm, load }) => {
  const classes = useStyles()
  const [file1, setFile1] = useState()
  const [file2, setFile2] = useState()
  // const [open, setOpen] = useState({ status: false, type: null })

  React.useEffect(() => {
    window?.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  const onFormSubmit = (val) => {
    if (val === 'continue') {
      if (data?.signedListing === '') {
        toast.error('Please select an option for Listing Agreement', { theme: 'colored' })
        return 
      }else{
        const payload = {}
        file1 && Object.defineProperty(payload, 'contractSignedFile', {
          value: file1,
          writable: true,
          enumerable: true
        })
        file2 && Object.defineProperty(payload, 'buyerBrokerAgreement', {
          value: file2,
          writable: true,
          enumerable: true
        })
        saveForm('stage-two', payload)
      }
    }
    if (val === 'exit') {
      if (data?.signedListing === '') {
        toast.error('Please select an option for Listing Agreement', { theme: 'colored' })
        return
      }else{
        exit('stage-two', { file1, file2 })
      }
    }
  }

  const uploadFiles = (e, type) => {
    // console.log(e)
    if (type === 1) {
      //validate file ext
      const file = e?.target?.files[0];
      if (file?.type !== 'application/pdf') return toast.error('Invalid File. Please select a PDF file', { theme: 'colored' })
      // console.log(file)
      setFile1(file)
    }
    if (type === 2) {
      //validate file ext
      const file = e?.target?.files[0];
      if (file?.type !== 'application/pdf') return toast.error('Invalid File. Please select a PDF file', { theme: 'colored' })
      // console.log(file)
      setFile2(file)
    }
  }


  return (
    <Box>
      <Stack direction={'row'} gap={2} alignItems={'center'}>
        <Typography fontWeight={500} color={'secondary'}>Have you signed the Listing Agreement?</Typography>
        <RadioGroup row
          value={data?.signedListing}
          onChange={(e) => {
            dispatch({ type: 'SIGNED_LISTING', payload: e?.target?.value })
            if(e?.target?.value === 'false'){
              setFile1()
              setFile2()
            }
          }}
        >
          <FormControlLabel disabled={data?.disableForm} value={true} sx={{ mr: 2 }} control={<Radio size='small' color='secondary' />} label="Yes" />
          <FormControlLabel disabled={data?.disableForm} value={false} control={<Radio size='small' color='secondary' />} label="No" />
        </RadioGroup>
      </Stack>
      <Divider sx={{ mt: 2, mb: 3, bgcolor: '#ededed' }} />

      {/* List Agreement Upload */}
      {
        data?.signedListing === 'true' && 
        <Grid container>
          <Grid item sm={3}>
            <Typography fontWeight={500} color={'secondary'}>Listing Agreement Upload</Typography>
          </Grid>
          <Grid item sm={9}>
            <Typography className={classes.label}>Upload RLA</Typography>
            <DropZone
              onChange={(e) => uploadFiles(e, 1)}
              classes={classes}
              file={file1}
              remove={() => setFile1()}
              preview={() => { }}
            />
          </Grid>
          <Divider sx={{ mt: 2, mb: 3, bgcolor: '#ededed' }} />
          <Grid item sm={3}>
            <Typography fontWeight={500} color={'secondary'}>Buy Broker Agreement Upload</Typography>
          </Grid>
          <Grid item sm={9}>
            <Typography className={classes.label}>Upload BRBC</Typography>
            <DropZone
              onChange={(e) => uploadFiles(e, 2)}
              classes={classes}
              file={file2}
              remove={() => setFile2()}
              preview={() => { }}
            />
          </Grid>
        </Grid>
      }


      {/* Bottom Actions */}
      <BottomActions
        classes={classes}
        load={load}
        onSubmit={(val) => onFormSubmit(val)}
        resetDispatch={() => { dispatch({ type: 'RESET' }); setFile1(); setFile2() }}
        buttons={['Clear Entries', 'Save & Exit', 'Save & Continue']}
      />
    </Box>
  )
}

export default StageTwo