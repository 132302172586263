import React from 'react';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import LeftNavBar from './LeftNavbar/LeftNavBar';
import NavbarAuth from './NavbarAuth/NavbarAuth';
import './privateroutes.scss';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { signout, toggleAuthModal } from '../actions';
import { toast } from 'react-toastify';
import Footer from './Footer/Footer';
import useMediaQuery from '@mui/material/useMediaQuery';

function PrivateRoutes() {
	//! auth represents whether a user is logged in or not
	const { pathname, search } = useLocation();
	const loggedIn = useSelector((state) => state.auth.token.access && state.auth.token.refresh);
	const subs = useSelector((state) => state.subscriptions);
	const isOpen = useSelector((state) => !state.sidebar.isOpen);
	const below1200px = useMediaQuery('(max-width:1200px)');
	const isVerified = JSON.parse(localStorage.getItem('details'))?.is_email_verified;

	const parseJwt = (token) => {
		try {
			return JSON.parse(atob(token?.split('.')[1]));
		} catch (e) {
			return null;
		}
	};
	const dispatch = useDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		const { premierAgentWebsite } = subs;
		if (pathname.split('/')[1] === 'premier-agent-website' && !premierAgentWebsite) {
			toast.warn('You are not signed up to Investor Website');
			navigate('/agent-dashboard');
		}
		if (pathname.split('/')[1] === 'live-leads' && !subs.liveLeads) {
			toast.warn('You are not signed up to Live Leads');
			navigate('/agent-dashboard');
		}
	}, [navigate, pathname, subs]);

	useEffect(() => {
		const user = localStorage.getItem('atoken');
		if (user) {
			const decodedJwt = parseJwt(user);
			if (decodedJwt.exp * 1000 < Date.now()) {
				toast.error('Session expired. Please login again.');
				dispatch(signout());
				localStorage.setItem('nextRoute', pathname);
				navigate('/', { state: { from: pathname + search } });
			}
		} else {
			toast.error('Login to continue.');
			dispatch(signout());
			localStorage.setItem('nextRoute', pathname);
			navigate('/', { state: { from: pathname + search } });
		}
	}, [dispatch, navigate, pathname, search]);

	React.useLayoutEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

	useEffect(() => {
		if (loggedIn && !isVerified) {
			toast.error('Please verify your email to access your account.');
			dispatch(toggleAuthModal());
		}
		// eslint-disable-next-line
	}, []);

	return (
		<>
			{loggedIn && isVerified ? (
				<>
					<NavbarAuth />
					<div>
						{isOpen && <LeftNavBar />}
						<div
							style={{
								marginLeft: isOpen && !below1200px ? '22.7%' : '0',
								// marginLeft: isOpen ? '22.7%' : '0',
								// marginLeft: '0',
								background: '#fff',
								minHeight: 'calc(100vh - var(--navheight))',
							}}
						>
							<Outlet />
							<Footer />
						</div>
					</div>
					{/* <Outlet /> */}
				</>
			) : (
				<>
					<Navigate to='/' state={{ from: pathname + search }} replace />
				</>
			)}
		</>
	);
}

export default PrivateRoutes;
