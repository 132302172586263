import { AddComment, Bookmark, Comment, CreditCard, Dashboard, Devices, Person, Store } from '@mui/icons-material';
import LockIcon from '@mui/icons-material/Lock';
import { Button, MenuItem, Select, Stack } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { getSubscriptionStatus } from '../../utils/functions';
import CreateNewListingDialog from '../CreateNewListingDialog';
import Lockable from '../Lockable/Lockable';
import './leftnavbar.scss';

const IndProfLeftNav = ({ onClose }) => {
	const Username = useSelector((state) => state.auth.first_name);
	const { indPro, indElite, indUltra } = useSelector((state) => state.subscriptions);
	const { listings, selectedListing } = useSelector((state) => state.auth);
	const [selectedList, setSelectedList] = useState(selectedListing?.id);

	const toolTipText = selectedList ? 'Click to Upgrade' : 'Click to create a listing';
	const toolTipLink = selectedList ? '/industry-prof-subscription' : '/my-listing';
	const dispatch = useDispatch();

	const handleListingChange = (e) => {
		const { value } = e.target;
		setSelectedList(value || selectedList);
		if (!value) return;
		const selectedOne = listings.find((i) => i.id === value);
		dispatch({ type: 'SET_LISTING', payload: selectedOne });
		const sub = [];
		if (selectedOne) {
			if (selectedOne?.subscription) sub.push(selectedOne?.subscription);
		}
		dispatch({ type: 'SET_SUBSCRIPTIONS', payload: getSubscriptionStatus(sub) });
	};

	const [detailsModalOpen, setDetailsModalOpen] = useState(false);
	const handleNewListing = () => {
		//open modal
		setDetailsModalOpen(true);
	};

	return (
		<>
			{detailsModalOpen && <CreateNewListingDialog open={detailsModalOpen} close={() => setDetailsModalOpen(false)} />}

			<div className='left_navbar'>
				<h1 style={{ fontSize: '1.6rem', margin: '2rem 0' }}>Welcome, {Username}!</h1>
				<Select
					color='secondary'
					value={selectedListing?.id}
					onChange={handleListingChange}
					fullWidth
					size='small'
					displayEmpty
					renderValue={(value) => {
						if (!value) return 'No listing selected';
						const listing = listings.find((i) => i.id === value);
						return listing?.company_name;
					}}
					sx={{
						my: '1rem',
						mx: 'auto',
						px: '1rem',
						color: 'white',
						width: '80%',
						background: '#ffffff17',
						fontWeight: 400,
						'& .MuiOutlinedInput-notchedOutline': {
							borderColor: '#D0D5DD',
						},
						'&:hover .MuiOutlinedInput-notchedOutline': {
							borderColor: '#D0D5DD',
						},
						'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
							borderColor: '#D0D5DD',
						},
						'& .MuiSelect-icon': {
							color: 'white',
						},
					}}
				>
					{listings?.map((i) => (
						<MenuItem value={i.id} key={i.id} color='secondary'>
							{i.company_name}
						</MenuItem>
					))}
					<MenuItem color='secondary'>
						<Stack direction={'row'} justifyContent={'space-between'} sx={{ width: '100%' }} gap='1rem'>
							<Button
								href='/industry-professionals'
								color='secondary'
								variant='outlined'
								sx={{ textTransform: 'capitalize', fontSize: '14px', mr: 'auto' }}
							>
								Claim A Listing
							</Button>
							<Button
								color='secondary'
								variant='contained'
								sx={{ textTransform: 'capitalize', fontSize: '14px', ml: 'auto' }}
								onClick={handleNewListing}
							>
								Create A Listing
							</Button>
						</Stack>
					</MenuItem>
				</Select>
				<div className='nav_links_wrapper'>
					<NavLink className='nav_link ' to='industry-dashboard' onClick={onClose}>
						<Dashboard className='nav_icon' />
						<p>Dashboard</p>
					</NavLink>

					<Lockable subscribed={indElite} link={toolTipLink} title={toolTipText}>
						<NavLink className='nav_link ' to='/industry-website' onClick={onClose}>
							<Devices className='nav_icon' />
							<p>Industry Website</p>
							{!indElite && <LockIcon fontSize='16px' sx={{ ml: 'auto' }} />}
						</NavLink>
					</Lockable>
					<Lockable subscribed={indPro} link={toolTipLink} title={toolTipText}>
						<NavLink className='nav_link ' to='/requested-quotes' onClick={onClose}>
							<Comment className='nav_icon' />
							<p>Quotes</p>
							{!indPro && <LockIcon fontSize='16px' sx={{ ml: 'auto' }} />}
						</NavLink>
					</Lockable>
					<Lockable subscribed={indPro} link={toolTipLink} title={toolTipText}>
						<NavLink className='nav_link ' to='/new-quote' onClick={onClose}>
							<AddComment className='nav_icon' />
							<p>Generate New Quote</p>
							{!indPro && <LockIcon fontSize='16px' sx={{ ml: 'auto' }} />}
						</NavLink>
					</Lockable>
					<Lockable subscribed={indPro} link={toolTipLink} title={toolTipText}>
						<NavLink className='nav_link ' to='/follow-ups' onClick={onClose}>
							<Bookmark className='nav_icon' />
							<p>Follow Ups</p>
							{!indPro && <LockIcon fontSize='16px' sx={{ ml: 'auto' }} />}
						</NavLink>
					</Lockable>
					<NavLink className='nav_link ' to='/my-listing/all' onClick={onClose}>
						<Store className='nav_icon' />
						<p>Business Listing</p>
					</NavLink>
					<Lockable subscribed={indElite || indUltra} link={toolTipLink} title={toolTipText}>
						<NavLink className='nav_link ' to='ind-payment' style={{ pointerEvents: indPro ? 'all' : 'none' }} onClick={onClose}>
							<CreditCard className='nav_icon' />
							<p>Payment</p>
							{!(indElite || indUltra) && <LockIcon fontSize='16px' sx={{ ml: 'auto' }} />}
						</NavLink>
					</Lockable>
					<NavLink className='nav_link ' to='ind_prof' onClick={onClose}>
						<Person className='nav_icon' />
						<p>Profile</p>
					</NavLink>

					<div className='hr'>
						<hr />
					</div>
				</div>
			</div>
		</>
	);
};

export default IndProfLeftNav;
