import React from 'react'
import { useTheme } from '@mui/material/styles'
import { Box,Table, TableContainer,TableBody, Typography, tableCellClasses, TableHead, TableRow, TableCell } from '@mui/material'
import SubSectionContainer from '../TableComponents/SubSectionContainer'
import InnerSectionHeader from '../TableComponents/InnerSectionHeader'
import commaSeparate from '../../utils/commaSeparate'
import Summary from './Summary'
import Disclaimer from './Disclaimer'

const useStyles = (_theme,printVersion)=>{
  return{
      root:{

      },
      table:{
        [`& .${tableCellClasses.root}`]: {
          borderBottom: "none",
          padding:"5px 0",
          margin:"0rem 0 !important"
        },
        // width:"48%",
        overflow:printVersion?"hidden":"scroll",
      },
      table2:{
        [`& .${tableCellClasses.root}`]: {
          borderBottom: "none",
          padding:"5px 0",
          margin:"0rem 0 !important"
        },
        width:"48%",
        alignSelf:"start",
        overflow:printVersion?"hidden":"scroll",
      },
      fullTable:{
        [`& .${tableCellClasses.root}`]: {
          borderBottom: "none",
          padding:"5px 0",
          margin:"0rem 0 !important"
        },
        alignSelf:"start",
        overflow:printVersion?"hidden":"scroll",

      },
      valueCell:{
        width:"18% !important",
        borderBottom:"solid 1px white !important",
        borderTop:"solid 2px white !important",
        backgroundColor:"#f5f5f5",
        "print-color-adjust": "exact",
        "-webkit-print-color-adjust": "exact",

    },
      valueCell1:{
        width:"28% !important",
        borderBottom:"solid 1px white !important",
        borderTop:"solid 2px white !important",
        backgroundColor:"#f5f5f5",
        "print-color-adjust": "exact",
        "-webkit-print-color-adjust": "exact",

    },
      valueCell2:{
        width:"18% !important",
        borderBottom:"solid 1px white !important",
        borderTop:"solid 2px white !important",
        backgroundColor:"#e8e8e8",
        "print-color-adjust": "exact",
        "-webkit-print-color-adjust": "exact",

    }
  }
}

function ProformaPage5({

    investorsProfit,
    printVersion,
    analyzerData
}) {

    const theme = useTheme();
    const styles = useStyles(theme,printVersion);

  return (
    <>
      <Box sx={{height:"1rem"}}/>
      {  investorsProfit?.profit?.investors?.length > 2 ?
        <SubSectionContainer printVersion = {printVersion} title = {`Investors' Profit` }>
        <Box sx={{height:"1.25rem"}}/>
        <TableContainer>
        <InnerSectionHeader title={`Investors' Profit`}/>
        <Box sx={{height:"1.25rem"}}/>
            <Table sx={{...styles.table,width:"100%"}}>
                <TableHead>
                    <TableRow>
                        {investorsProfit.profit.header.map(element=>(
                            <TableCell sx={{width:"20% !important"}}><Typography sx={{fontWeight:"700",p:1}} variant='h4'>{element}</Typography></TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                  {
                    investorsProfit?.profit?.investors.map((investor, index)=>(
                      <TableRow>
                        <TableCell  ><Typography sx={{p:1}} variant = 'h4'>{investor?.investor}</Typography></TableCell>
                        <TableCell sx={styles.valueCell1}><Typography sx={{p:1}} variant = 'h4'>{commaSeparate(investor?.equity_in_value) }</Typography></TableCell>
                        <TableCell sx={styles.valueCell2}><Typography sx={{p:1}} variant = 'h4'>{commaSeparate(investor?.profit_in_percent) }</Typography></TableCell>
                        <TableCell sx={styles.valueCell}><Typography sx={{p:1}} variant = 'h4'>{commaSeparate(investor?.roi_in_percent) }</Typography></TableCell>
                        <TableCell sx={styles.valueCell2}><Typography sx={{p:1}} variant = 'h4'>{commaSeparate(investor?.annualized_roi_percent) }</Typography></TableCell>
                      </TableRow>
                    ))
                  }

                </TableBody>

            </Table>
        </TableContainer>

        </SubSectionContainer>
        :
        ""
        }
        <Box sx={{mt:4}}/>
        <InnerSectionHeader title='Summary' />
        <Summary text={analyzerData?.summaryText?.text} printVersion/>
        <Disclaimer printVersion={printVersion}/>
    </>
  )
}

export default ProformaPage5