import { useEffect, useState } from 'react';
import Territory from './Territory/Territory';
import BPOSub1 from './BPOSub/BPOSub1';
import base from '../apis';
import { Skeleton } from '@mui/material';
import { toast } from 'react-toastify';

const AgentFarmGeneral = () => {
	const [loading, setLoading] = useState(true);
	const [farmCount, setFarmCount] = useState(null);
	useEffect(() => {
		const getFarmCount = async () => {
			try {
				setLoading(true);
				const { data } = await base.get('/register/agents/me/');
				setFarmCount(data.farm_count);
			} catch (error) {
				toast.error('Could not get agent details');
			} finally {
				setLoading(false);
			}
		};
		getFarmCount();
	}, []);

	if (loading) {
		return (
			<div style={{ padding: '1rem' }}>
				<Skeleton variant='rectangular' width='100%' height='90vh' />
			</div>
		);
	}

	if (!farmCount) {
		return <BPOSub1 />;
	}

	return <Territory farmCount={farmCount} />;
};

export default AgentFarmGeneral;
