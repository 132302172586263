import { Box } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Spinner from '../../../components/Spinner/Spinner';
import { headerImages, URL as urlLink } from '../../../constants';
import { formatPhoneNumber, getSubscriptionStatus } from '../../../utils/functions';
import DigiCard from '../DigiCard';
import NewTemp1 from '../NewTemp1';
import NewTemp2 from '../NewTemp2';
import NewTemp3 from '../NewTemp3';
import NewTemp4 from '../NewTemp4';
import NewTemp5 from '../NewTemp5';
import NewTemp6 from '../NewTemp6';
import NewTemp7 from '../NewTemp7';
import './temp1.scss';
import NewTemp8 from '../NewTemp8';
import NewTemp9 from '../NewTemp9';
import NewTemp10 from '../NewTemp10';
import NewTemp11 from '../NewTemp11';
import NewTemp12 from '../NewTemp12';
import NewTemp13 from '../NewTemp13';
import NewTemp14 from '../NewTemp14';

const Temps = {
	1: NewTemp1,
	2: NewTemp2,
	3: NewTemp3,
	4: NewTemp4,
	5: NewTemp5,
	6: NewTemp6,
	7: NewTemp7,
	8: NewTemp8,
	9: NewTemp9,
	10: NewTemp10,
	11: NewTemp11,
	12: NewTemp12,
	13: NewTemp13,
	14: NewTemp14,
};

function Temp1() {
	const { id } = useParams();
	const [selectedTemplate, setSelectedTemplate] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [agent, setAgent] = useState(null);
	const [listings, setListings] = useState({
		active: [],
		sold: [],
	});
	const [testimonials, setTestimonials] = useState([]);
	const [serviceAreas, setServiceAreas] = useState([]);
	const navigate = useNavigate();

	useEffect(() => {
		//call agent with listings
		setIsLoading(true);
		(async () => {
			try {
				const res = await axios.get(urlLink + `register/agents/${id}/`).catch(() => {
					toast.warn('Could not get agent info');
					setIsLoading(false);
				});
				setAgent(res.data);
				setSelectedTemplate(res.data?.selected_template);

				// check if user has subscription to premier agent website
				const subscriptionsRes = await axios.get(urlLink + `register/subscriptions/`, { params: { user_id: res.data?.user?.id } });
				const { premierAgentWebsite } = getSubscriptionStatus(subscriptionsRes.data);
				if (!premierAgentWebsite) {
					navigate('/404', { replace: true });
					return;
				}

				const listingsRes = await axios.get(urlLink + `premiersite/listings/?agent_id=${id}`);
				const listings = listingsRes.data.map((listing) => ({ ...listing, address: listing.address.replace(', USA', '') }));
				const active = listings.filter((listing) => !listing.sold_price && !listing.date_sold);
				const sold = listings.filter((listing) => listing.sold_price && listing.date_sold);
				setListings({ active, sold });
				const testimonials = await axios.get(urlLink + `premiersite/choose-testimonials/?agent_id=${id}`).catch((err) => {});
				setTestimonials(testimonials.data);
				const serviceAreas = res?.data.service_areas;
				setServiceAreas(res?.data?.user?.role === 'realty_agent' ? serviceAreas : []);
			} catch (error) {
				toast.warn('An error occurred');
			} finally {
				setIsLoading(false);
			}
		})();

		return () => {
			setAgent(null);
			setListings({ active: [], sold: [] });
		};
	}, [id, navigate]);
	const Component = Temps[selectedTemplate];

	const [contactDetails, setContactDetails] = useState({
		phone: '',
		email: '',
		name: '',
		message: '',
	});

	const updateContactDetails = (e) => {
		const { name, value } = e.target;
		setContactDetails({ ...contactDetails, [name]: name === 'phone' ? formatPhoneNumber(value) : value });
	};

	const sendMessage = async () => {
		if (!contactDetails.phone || !contactDetails.email || !contactDetails.name || !contactDetails.message) {
			toast.warn('Please fill all fields');
			return;
		}
		if (!contactDetails.email.includes('@') || !contactDetails.email.includes('.')) {
			toast.warn('Please enter a valid email');
			return;
		}

		setIsLoading(true);
		try {
			await axios.post(urlLink + 'register/contact_agent/', {
				client_name: contactDetails.name,
				client_email: contactDetails.email,
				client_phone: contactDetails.phone,
				client_message: contactDetails.message,
				agent_id: id,
			});
			toast.success('Message sent successfully');
		} catch (error) {
			toast.warn('Could not send message');
		} finally {
			setIsLoading(false);
			setContactDetails({
				phone: '',
				email: '',
				name: '',
				message: '',
			});
		}
	};
	if (isLoading) return <Spinner />;

	return (
		<>
			{isLoading && <Spinner />}
			<div className='temp1'>
				{/* <TemplateNavBar agent={agent} /> */}
				{selectedTemplate && (
					<Component
						agent={agent}
						isLoading={isLoading}
						setIsLoading={setIsLoading}
						listings={listings}
						testimonials={testimonials}
						contactDetails={contactDetails}
						updateContactDetails={updateContactDetails}
						sendMessage={sendMessage}
						serviceAreas={serviceAreas}
					/>
				)}
				{agent && (
					<Box
						sx={{
							fontFamily: 'Lato, sans-serif',
							fontSize: '16px',
							textAlign: 'left',

							color: '#818181',
							backgroundColor: '#ffffff',
							padding: '2rem',
							paddingRight: '10rem',
						}}
					>
						Copyright &copy; {new Date().getFullYear()} BPO Tech All rights reserved.
					</Box>
				)}
			</div>

			{/* digital business card */}
			<DigiCard agent={agent} setIsLoading={setIsLoading} isLoading={isLoading} listings={listings} headerImages={headerImages} />
		</>
	);
}

export default Temp1;
