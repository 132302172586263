import { Box, CircularProgress, TextField } from '@mui/material';
// import { useLoadScript } from '@react-google-maps/api';
import React from 'react';
// import { useState } from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';
import Input from '../Input/Input';

// const libraries = ['places'];

function PlacesSearch({ placeholder, onChange, onSelect, value, name = 'places_search', format = false, mui = false, types = ['address'], ...rest }) {
	// const { isLoaded } = useLoadScript({ googleMapsApiKey: process.env.REACT_APP_GOOGLE_KEY, libraries });
	// const [width, setWidth] = useState('');
	// const element = document.getElementsByName(name);
	const Item = mui ? TextField : Input;

	// React.useLayoutEffect(() => {
	// 	if (name && element?.[0]) {
	// 		const thewidth = document.getElementsByName(name)[0]?.offsetWidth;
	// 		setWidth(thewidth);
	// 	}
	// }, [element, name]);

	// if (!isLoaded)
	// 	return (
	// 		<Box display='flex' justifyContent='center'>
	// 			<CircularProgress size={20} color='secondary' />
	// 		</Box>
	// 	);
	return (
		<PlacesAutocomplete
			value={value}
			onChange={onChange}
			onSelect={onSelect || onChange}
			searchOptions={{ componentRestrictions: { country: 'us' }, types: types }}
		>
			{({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
				<div style={{ position: 'relative', width: '100%', display: 'flex', justifyContent: 'center' }}>
					<Item {...getInputProps({ placeholder: placeholder, autoComplete: 'off' })} name={name} {...rest} />
					<div style={{ position: 'absolute', top: '100%', width: '100%', zIndex: '1000', boxShadow: '0px 4px 10px 0px #0000001a' }}>
						{loading ? (
							<Box sx={{ display: 'flex' }}>
								<CircularProgress />
							</Box>
						) : null}
						{suggestions
							.filter((item) => {
								if (!format) return true;
								return item.formattedSuggestion.secondaryText === 'CA, USA';
							})
							.map((s) => {
								return { ...s, description: s.description.split(', ').slice(0, -1).join(', ') };
							})
							.map((suggestion) => {
								const style = {
									backgroundColor: suggestion.active ? '#41b6e6' : '#fff',
									padding: '10px',
									cursor: 'pointer',
								};
								return (
									<div key={suggestion.placeId} {...getSuggestionItemProps(suggestion, { style })}>
										{suggestion.description}
									</div>
								);
							})}
					</div>
				</div>
			)}
		</PlacesAutocomplete>
	);
}

export default PlacesSearch;
