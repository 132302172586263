import { Circle, Search } from '@mui/icons-material';
import {
	Box,
	Button,
	Card,
	CardContent,
	CardHeader,
	Container,
	Grid,
	IconButton,
	InputAdornment,
	ListItem,
	ListItemIcon,
	ListItemText,
	TextField,
	Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import BannerImg from '../../assets/explore.webp';
import Check1 from '../../assets/sellerguide.webp';
import Check2 from '../../assets/bpoguide.webp';
import Check3 from '../../assets/homeimprove.webp';
import SEO from '../../components/SEO';

const useStyles = makeStyles((theme) => ({
	banner: {
		height: '60vh',
		backgroundImage: `url(${BannerImg})`,
		backgroundPosition: 'center',
		backgroundSize: 'cover',
	},
	title: {
		fontWeight: 600,
		marginBottom: '1rem',
	},
	search: {
		margin: '2rem auto',
		width: '80%',
	},
}));

const Explore = () => {
	const classes = useStyles();
	const navigate = useNavigate();

	const lists = [
		'Local market knowledge',
		'Listing costs in some markets have decreased**',
		'Support with negotiating and evaluating offers',
		'Priority placement using tools like 3D home tours on BPO Home',
	];

	return (
		<div>
			<SEO>
				<title>List Your Property with a BPO Home Premier Agent</title>
				<meta name='og:title' content='List Your Property with a BPO Home Premier Agent' />
				<meta
					name='description'
					content='Sell your house easily with a participating BPO Home Premier Agent. Benefit from local market knowledge, decreased listing costs, support in negotiations, and more. Find a BPO Homes Seller Agent today.'
				/>
				<meta
					name='og:description'
					content='Sell your house easily with a participating BPO Home Premier Agent. Benefit from local market knowledge, decreased listing costs, support in negotiations, and more. Find a BPO Homes Seller Agent today.'
				/>
				<meta name='og:image' content={window.location.origin + BannerImg} />
			</SEO>
			<Box className={classes.banner}></Box>
			<Box padding={'4rem 0'} textAlign={'center'}>
				<Container>
					<Typography textAlign={'center'}>
						When we put you in touch with a participating BPO Homes Agent partner, selling your house is simple. To determine if they are accessible
						in your area, start by entering your address. Soon they'll be in additional locations!
					</Typography>
					<TextField
						fullWidth
						className={classes.search}
						variant='outlined'
						placeholder='Enter an address, neighborhood, city, or Zip code'
						InputProps={{
							endAdornment: (
								<InputAdornment position='end'>
									<IconButton>
										<Search />
									</IconButton>
								</InputAdornment>
							),
						}}
					/>
				</Container>
			</Box>

			<Box bgcolor={grey[100]} padding={'4rem 0 0 0'}>
				<Container>
					<Typography mb={4} variant='body1'>
						Why list your property with a participating BPO Home Premier Agent?
					</Typography>
					<Grid container>
						{lists?.map((el, index) => {
							return (
								<Grid item sm={6} key={index}>
									<ListItem>
										<ListItemIcon sx={{ minWidth: '35px' }}>
											<Circle sx={{ fontSize: '.7rem' }} color='secondary' />
										</ListItemIcon>
										<ListItemText>{el}</ListItemText>
									</ListItem>
								</Grid>
							);
						})}
					</Grid>
				</Container>
				{/* Divider Section */}
				<Box sx={{ height: '12rem', background: (theme) => theme.secondaryColor, marginTop: '3rem' }}>
					<Container sx={{ display: 'flex', justifyContent: 'space-between', height: '100%', alignItems: 'center' }}>
						<Typography variant='h5' sx={{ color: '#fff' }} textAlign='left'>
							Get A BPO Homes Seller Agent Now!
						</Typography>
						<Button size='large' sx={{ textTransform: 'none' }} variant='contained' disableElevation color='paper' href='/agent-finder'>
							Find An Agent
						</Button>
					</Container>
				</Box>
			</Box>

			<Box mt={4} mb={5}>
				<Container>
					<Typography variant='h6' mb={3}>
						Read Also:
					</Typography>
					<Grid container spacing={4}>
						<Grid item xs={12} sm={12} lg={4}>
							<Card variant='elevation' sx={{ cursor: 'pointer' }} onClick={() => navigate('/sellers-guide')}>
								<CardHeader style={{ backgroundImage: `url(${Check1})`, backgroundSize: 'cover', height: '15rem' }}></CardHeader>
								<CardContent style={{ padding: '2rem' }}>
									<Typography color={'secondary'} mb={2}>
										Getting Started
									</Typography>
									<Typography variant='body1' mb={3.5} color={'textSecondary'}>
										Seller's Guide
									</Typography>
								</CardContent>
							</Card>
						</Grid>

						<Grid item xs={12} sm={12} lg={4}>
							<Card variant='elevation' sx={{ cursor: 'pointer' }} onClick={() => navigate('/bpo-guide')}>
								<CardHeader style={{ backgroundImage: `url(${Check2})`, backgroundSize: 'cover', height: '15rem' }}></CardHeader>
								<CardContent style={{ padding: '2rem' }}>
									<Typography color={'secondary'} mb={2}>
										Getting Started
									</Typography>
									<Typography variant='body1' color={'textSecondary'}>
										Why Buyer Needs A Broker's price Opinion
									</Typography>
								</CardContent>
							</Card>
						</Grid>
						<Grid item xs={12} sm={12} lg={4}>
							<Card variant='elevation' sx={{ cursor: 'pointer' }} onClick={() => navigate('/home-improvement')}>
								<CardHeader style={{ backgroundImage: `url(${Check3})`, backgroundSize: 'cover', height: '15rem' }}></CardHeader>
								<CardContent style={{ padding: '2rem' }}>
									<Typography color={'secondary'} mb={2}>
										Getting Started
									</Typography>
									<Typography variant='body1' color={'textSecondary'}>
										Home Improvement and Remodeling Trends in 2022
									</Typography>
								</CardContent>
							</Card>
						</Grid>
					</Grid>
				</Container>
			</Box>
		</div>
	);
};

export default Explore;
