import { Box, Typography, Button } from '@mui/material'

import React, { useState } from 'react'
import { useTheme } from '@mui/material/styles'
import { ClipLoader } from 'react-spinners'
import { useEffect } from 'react'
import { ZillowSearchModal } from '../zillowComps/ZillowSearchModal'
import { useSelector } from 'react-redux'
import ZillowCompCheckTableItem from '../zillowComps/ZillowCompsCheckTable'
import useZillowComps from '../zillowComps/useZillowComps'


const useStyles = (theme) => {
    return {

        body: {
            position: 'relative',
            height: '45rem',
            overflow: 'scroll',
            display: 'block'
        },

        heading: {
            borderBottom: `solid 1px ${theme.palette.primary.main}`
        },
        buttonBox: {
            display: "flex",
            marginTop: "1.25rem",
            justifyContent: "flex-end"
        }

    }
}

function ZillowComps({ handleClose, handleAddComps }) {
    const theme = useTheme()
    const styles = useStyles(theme)
    const [errorArray, setErrorArray] = useState([])
    const [showGooglePlaces, setShowGooglePlaces] = useState(false)

    const {
        savedCustomComps,
        handleUpdateCustomComps,
        deleteCustomComp,
        validateRequiredFields,
        //  submitCustomComps,
        saveOldCustomComps,
        restoreOldCompsOnCancel,
        replaceAllCustomComps,
        loading
    } = useZillowComps();




    const analyzerData = useSelector((state) => state?.straightAestheticRemodel);
    //const resultData = analyzerData?.dealAnalyzerResults

    const zillowComp = analyzerData?.estimatedResaleValue?.zillowComps

    //resultData?.zillowComps
    //analyzerData?.estimatedResaleValue?.zillowComps


    const openPopUp = () => {

        setShowGooglePlaces((prev) => !prev)
    }
    //this sets back the add model when the add link is clicked


    const addCustomCompsAndClosePopup = async () => {
        const errors = validateRequiredFields()
        if (errors?.length && !errors.every(element => element === '')) {
            setErrorArray(prev => errors)
            return
        }
        replaceAllCustomComps(savedCustomComps.map(element => ({ ...element, new: false })))

        handleClose()

    }
    const handleCancel = () => {
        restoreOldCompsOnCancel()
        handleClose()
    }

    useEffect(() => {
        saveOldCustomComps()
        // NOTE: Run effect once on component mount, please
        // recheck dependencies if effect is updated.
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [])
    return (
        <Box sx={styles.body}>
            {/* {isLoading && <Spinner />} */}
            <Box sx={styles.heading}>
                <Typography variant="tableRow">Zillow Comps</Typography>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Box>
                    <Typography variant='h6' color='primary'>Help Us Out with Built Dates Your Comps</Typography>
                </Box>

                {
                    showGooglePlaces ?
                        (<ZillowSearchModal showModel={openPopUp} />
                        ) : (''
                        )
                }

            </Box>
            <Button
                variant="outlined"
                sx={{ my: 2, px: 2, py: 1, textTransform: "none" }}
                onClick={openPopUp}
            >
                <Typography variant="h4">Add a new Comp</Typography>
            </Button>
            {
                !!zillowComp?.length &&
                zillowComp.map((element, index) => (
                    <ZillowCompCheckTableItem
                        list={element}
                        noCheckbox={true}
                        isCustomComps
                        deleteCustomComp={deleteCustomComp}
                        index={index}
                        handleUpdate={handleUpdateCustomComps}
                        errors={errorArray?.[index]}
                    />
                ))
            }
            <Box sx={styles.buttonBox}>
                <Button variant="outlined" onClick={handleCancel} sx={{ mr: 4 }}>
                    <Typography variant="buttonFont" sx={{ padding: "0.5rem 1rem" }}>
                        Cancel
                    </Typography>
                </Button>
                <Button variant="contained" onClick={addCustomCompsAndClosePopup}>
                    {!loading ? (
                        <Typography variant="buttonFont" sx={{ padding: "0.5rem 1.25rem" }}>
                            OK
                        </Typography>
                    ) : (
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                px: 2
                            }}
                        >
                            <ClipLoader color={"#fff"} loading={true} size={24} />
                        </Box>
                    )}
                </Button>
            </Box>
        </Box >
    );
}

export default ZillowComps



// replace the google search with input box and send the data the tables 