import React, { useEffect, useMemo } from "react";
import useTheme from "@mui/material/styles/useTheme";
import { useDispatch, useSelector } from "react-redux";
import useProformaData from "../../hooks/dealReporthooks/useProformaData";
import useSaveNow from "../../hooks/useSaveNow";
import StyledLabel from "../../atomicComponents/StyledLabel";
import EnhancedRadioButtonGroup from "../../atomicComponents/EnhancedRadioButtonGroup";
import { developmentCostOptions } from "./constants";
import { getCostPsqftFromAdvancedRemodelCost } from "./remodelCostUtils/getCostPerSqft";
import EditIcon from "@mui/icons-material/Edit";
import RegularToolTip from "../../atomicComponents/RegularToolTip";
import {
  FormControl,
  OutlinedInput,
  Typography,
  Box,
  InputAdornment,
  Grid,
  Divider,
} from "@mui/material";
import { greyout } from "../../utils/greyout";
import commaSeparate from "../../utils/commaSeparate";
import { isNaNChecker } from "../../localCalcutions/utils/genericFunctions";
import * as actionCreators from "../../../../actions/dealAnalyzer/index";

const useStyles = (_theme) => {
  return {
    root: {},
    inputContainer: {
      display: "flex",
      flexDirection: "column",
      margin: "1rem 0 1.5rem 0",
    },
    underInput: {
      "&:hover": {
        borderBottom: "solid",
      },
      cursor: "pointer",
    },
    bottomLabel: {
      marginTop: "10px",
      display: "flex",
      alignItems: "center",
      padding: "5px 0",
    },
  };
};

function DevelopmentCostMainCard({
  handleOpenDialog,
  handleSubMenu,
  handler,
  data,
}) {
  const theme = useTheme();
  const styles = useStyles(theme);
  const { saveNow } = useSaveNow();
  const analyzerData = useSelector((state) => state?.straightAestheticRemodel);
  const { fetchResult, resultData } = useProformaData();
  const preExistingSquareFootage =
    analyzerData?.propertyInformation?.pre_existing_livable_sqft;
  const additionalSquareFootage =
    analyzerData?.propertyInformation?.additional_sqrft;
  const aestheticItems = analyzerData?.developmentCost?.aesthetic_items || [];
  const nonAestheticItems =
    analyzerData?.developmentCost?.non_aesthetic_items || [];
  const { estimatedRemodelCostPsqft, remodelCost } =
    getCostPsqftFromAdvancedRemodelCost({
      preExistingSquareFootage,
      aestheticItems,
      nonAestheticItems,
      additionalSquareFootage,
    }) || {};
  const isAdvanced = +analyzerData?.developmentCost?.remodel_cost_option;
  let advancedRemodelTimer;
  const handleChange = (e) => {
    handler({ [e.target.name]: e.target.value });
  };
  const specialHandler = (e) => {
    handler({ [e.target.name]: e.target.value });
    if (+e.target.value === 1) {
      advancedRemodelTimer = setTimeout(() => {
        handleSubMenu(1);
      }, 1000);
    }
  };

  const save = async () => {
    try {
      await saveNow({ development_cost: analyzerData?.developmentCost });
      await fetchResult();
    } catch (err) {}
  };
  useEffect(() => {
    !data?.remodel_cost_option && handler({ remodel_cost_option: "0" });

    return () => {
      advancedRemodelTimer && clearTimeout(advancedRemodelTimer);
    };
    // NOTE: Run effect once on component mount, please
    // recheck dependencies if effect is updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    save();
    // NOTE: Run effect once on component mount, please
    // recheck dependencies if effect is updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    data?.pre_existing_livable_sqft,
    data?.est_remodel_cost_psqft,
    data?.new_construction_cost_psqft,
  ]);

  //========================== local calculations goes here ========================//
  const estDevelopmentCost =
    +analyzerData?.developmentCost?.new_construction_cost_psqft;
  const newConstructionSqFt =
    +analyzerData?.propertyInformation?.pre_existing_livable_sqft;
  const dispatch = useDispatch();

  const totalNewConstructionBuildCost = useMemo(() => {
    const result = estDevelopmentCost * newConstructionSqFt;
    dispatch(
      actionCreators.updateFinanceOptions({
        totalNewConstructionBuildCost: isNaNChecker(result),
      })
    );
    return isNaNChecker(result).toFixed(2);
    // recheck dependencies if effect is updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [estDevelopmentCost, newConstructionSqFt]);

  useEffect(() => {
    dispatch(
      actionCreators.updateFinanceOptions({
        AdvancedDevelopmentCost: remodelCost,
      })
    );
    // recheck dependencies if effect is updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remodelCost]);

  const totalConstructionCost =
    +totalNewConstructionBuildCost ||
    resultData?.development_cost?.total_new_construction_build_cost;

  const simpleEstimatedRemodelCostPsqft = isNaN(
    +data?.["new_construction_cost_psqft"]
  )
    ? resultData?.development_cost?.new_construction_cost_psqft
    : data?.["new_construction_cost_psqft"];

  //===============================================================================//
  useEffect(() => {
    const grandTotalRemodelCost = isAdvanced
      ? remodelCost
      : totalConstructionCost;
    handler({ grandTotalRemodelCost });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remodelCost, totalConstructionCost]);

  return (
    <>
      <StyledLabel label="Choose a Development Cost Calculator " />
      <EnhancedRadioButtonGroup
        options={developmentCostOptions}
        name="remodel_cost_option"
        choice={data?.remodel_cost_option}
        specialHandler={specialHandler}
        styles={{ display: "flex" }}
      />
      <Divider
        light={true}
        // variant="middle"
        sx={{
          height: "1px",
          mt: -2,
          mb: 2,
          backgroundColor: "#C4c4c4",
        }}
      />
      <Box>
        <Grid container columns={24}>
          <Grid item sm={24} md={24}>
            <Box sx={styles.inputContainer}>
              <StyledLabel label={"Est. New Development Cost P/Sqft"} />
              <FormControl sx={{ minWidth: "18.7rem", width: "100%" }}>
                <OutlinedInput
                  fullWidth
                  margin="normal"
                  name={"new_construction_cost_psqft"}
                  sx={isAdvanced ? greyout : {}}
                  onChange={isAdvanced ? "" : handleChange}
                  value={
                    isAdvanced
                      ? estimatedRemodelCostPsqft
                      : simpleEstimatedRemodelCostPsqft
                  }
                  placeholder="0"
                  renderValue={(value) => value}
                  inputProps={{ style: { fontSize: "1rem" } }}
                  startAdornment={
                    <InputAdornment position="start">
                      {" "}
                      <Typography variant="adornment" color="secondary">
                        {" "}
                        $
                      </Typography>
                    </InputAdornment>
                  }
                  endAdornment={
                    <InputAdornment position="start">
                      {" "}
                      <Typography variant="adornment" color="secondary">
                        {" "}
                        /SqFt
                      </Typography>
                    </InputAdornment>
                  }
                />
              </FormControl>
              {!isAdvanced && (
                <Box sx={styles.bottomLabel}>
                  <Typography
                    variant="notice"
                    color="primary"
                    sx={styles.underInput}
                    onClick={() => handleOpenDialog("new_construction")}
                  >
                    {remodelMainCardStaticData.input1.lowerLabel}
                  </Typography>
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>

        <Box sx={styles.inputContainer}>
          <Box>
            <StyledLabel label={"Total New Construction Build Cost"} />
            <RegularToolTip content="Edit">
              <EditIcon
                sx={{
                  fontSize: "1.2rem",
                  mx: 3,
                  color: "#1378A5",
                  cursor: "pointer",
                }}
                onClick={() => handleSubMenu(1)}
              />
            </RegularToolTip>
          </Box>
          <FormControl sx={{ minWidth: "18.7rem", width: "100%" }}>
            <OutlinedInput
              fullWidth
              margin="normal"
              name={"est_total_remodel_cost_plus_additional_sqft_cost"}
              sx={greyout}
              value={
                isAdvanced
                  ? commaSeparate(remodelCost)
                  : commaSeparate(totalConstructionCost)
              }
              placeholder="0"
              renderValue={(value) => value}
              inputProps={{ style: { fontSize: "1rem" } }}
              startAdornment={
                <InputAdornment position="start">
                  {" "}
                  <Typography variant="adornment" color="secondary">
                    {" "}
                    $
                  </Typography>
                </InputAdornment>
              }
            />
          </FormControl>
          {/* <Box sx={styles.bottomLabel}>
            <Typography
              variant="notice"
              color="primary"
              sx={styles.underInput}
              onClick={() => handleSubMenu(1)}
            >
              {remodelMainCardStaticData.input3.lowerLabel}
            </Typography>
            <HtmlToolTip
              content={{
                text: "Add Items and their estimated dollar value individually",
              }}
            />
          </Box> */}
        </Box>
      </Box>
    </>
  );
}

export default DevelopmentCostMainCard;

const remodelMainCardStaticData = {
  input1: {
    label: "Est. Remodel Cost Per Square foo",
    lowerLabel:
      "Get an estimated dollar per square foot from a Local Licensed Contractor",
    name: "est_remodel_cost_psqft",
  },
  input2: {
    label: "Pre Existing Livable Square Footage",
    name: "pre_existing_livable_square_footage",
  },
  input3: {
    label: "Est. Total Remodel + Additional Sqft Cost",
    lowerLabel: "Advanced Remodel Cost",
    name: "est_total_remodel_cost_plus_additional_sqft_cost",
  },
};
