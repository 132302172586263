import { Circle } from '@mui/icons-material';
import {
	alpha,
	Box,
	Card,
	CardContent,
	CardHeader,
	Container,
	Divider,
	Grid,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';
import React from 'react';
import Guide from '../../assets/bpoguide.webp';
import Check1 from '../../assets/buycheck.webp';
import Check2 from '../../assets/checklist3.webp';
import Check3 from '../../assets/checklist2.webp';
import { useNavigate } from 'react-router-dom';
import SEO from '../../components/SEO';

const useStyles = makeStyles((theme) => ({
	banner: {
		height: '60vh',
		backgroundImage: `url(${Guide})`,
		backgroundPosition: 'center',
		backgroundSize: 'cover',
	},
	title: {
		fontWeight: 600,
		marginBottom: '1rem',
	},
	listIcon: {
		fontSize: '.8rem',
		minWidth: '32px',
		color: '#000',
	},
	secDivider: {
		height: '100%',
		backgroundImage: `url(${Guide})`,
	},
}));
const BPOGuide = () => {
	const classes = useStyles();
	const navigate = useNavigate();

	const lists = [
		'What Is A Broker Price Opinion?',
		'The Function of Broker Price Opinions',
		'What Kinds Of Broker Price Opinions Are There?',
		`What's the difference between an appraisal and a BPO?`,
		'Where Can I Find a Broker Price Opinion?',
		'Will I benefit from a broker price opinion?',
		'Determine if a BPO is a right choice for you.',
	];

	const scenarios = [
		'Foreclosures and short sales',
		'Asset and portfolio valuation',
		'Sales of non-performing and performing loans',
		'Complete loan sales',
		'PMI appeals are made',
	];

	const choices = [
		'A BPO can be requested by a lender, loss mitigation business, mortgage company, or homeowner who needs an evaluation of a property.',
		`A broker or real estate expert uses real estate comps, which are similar to those used in a CMA, along with a few other elements, such as the size and age of the property, to complete a BPO.`,
		`Because they provide less thorough house valuation results, BPOs differ from home appraisals in appearance. Some BPOs, including external BPOs, solely examine a home's outside.`,
		`In a situation involving a short sale or foreclosure, the buyer or seller of a home may decide to obtain a BPO. However, a BPO is not the best option if you want a more thorough appraisal of your house. Get a BPO report now if a BPO is the best option for you.`,
	];

	return (
		<div>
			<SEO>
				<title>Understanding Broker Price Opinions in Real Estate</title>
				<meta name='og:title' content='Understanding Broker Price Opinions in Real Estate' />
				<meta
					name='description'
					content='Learn the basics of broker price opinions (BPOs) in real estate, including their purpose, difference from appraisals, and when they may be necessary for home buyers and sellers. Discover the benefits of BPOs and how to find a qualified professional to help you make informed decisions.'
				/>
				<meta
					name='og:description'
					content='Learn the basics of broker price opinions (BPOs) in real estate, including their purpose, difference from appraisals, and when they may be necessary for home buyers and sellers. Discover the benefits of BPOs and how to find a qualified professional to help you make informed decisions.'
				/>
				<meta name='og:image' content={window.location.origin + Guide} />
			</SEO>
			<Box className={classes.banner}></Box>
			<Box sx={{ background: (theme) => theme.secondaryColor, padding: '4rem', color: '#fff' }}>
				<Typography textAlign={'center'} gutterBottom variant='h5'>
					Why Buyers & Sellers Need A Broker’s Price Opinion
				</Typography>
				<Divider sx={{ width: '7rem', height: '3px', margin: '1rem auto', background: (theme) => theme.primaryColor }} />
				<Typography textAlign={'center'}>Broker Price Opinion: A Guide </Typography>
			</Box>

			<Container sx={{ padding: '3rem 0' }}>
				<Typography variant='body1'>
					In real estate, we frequently have the good fortune of having access to a variety of options for achieving a goal. One of these options is
					the broker price opinion (BPO). What is a broker price opinion, and why might it be important for a property's listed price?
				</Typography>
			</Container>

			<Box padding={'3.5rem 0'} bgcolor={grey[100]}>
				<Container>
					<Typography variant='h6' mb={3}>
						Let's investigate.
					</Typography>
					<Grid container spacing={3}>
						{lists?.map((el, index) => {
							return (
								<Grid item sm={6} key={index}>
									<ListItem>
										<ListItemIcon sx={{ minWidth: '35px' }}>
											<Circle sx={{ fontSize: '1rem' }} color='secondary' />
										</ListItemIcon>
										<ListItemText>{el}</ListItemText>
									</ListItem>
								</Grid>
							);
						})}
					</Grid>
				</Container>
			</Box>

			<Box padding={'3.5rem 0'}>
				<Container>
					{/* What is BPO */}
					<Box>
						<Typography variant='h6' className={classes.title} mb={3}>
							What Is A Broker Price Opinion?
						</Typography>
						<Typography paragraph>
							A broker price opinion is a determination of the estimated value of a property made by a real estate broker or other qualified
							professional. When selling a house, a BPO is utilized as a part of the listing agreement. A BPO might be requested by a lender, loss
							mitigation firm, or mortgage business that needs an evaluation of a property.
						</Typography>
						<Typography paragraph>
							Additionally, a BPO can be requested by a homeowner who wants to sell or refinance their house. Because it's a quicker and less
							expensive choice than an evaluation, they frequently go this path. Instead of using it to replace an assessment, they might also utilize
							it as a supporting document.
						</Typography>
						<Typography paragraph> The following scenarios see the biggest use of BPOs:</Typography>
						<List>
							{scenarios?.map((el, index) => {
								return (
									<ListItem key={index}>
										<ListItemIcon sx={{ minWidth: '35px' }}>
											<Circle sx={{ fontSize: '1rem' }} color='secondary' />
										</ListItemIcon>
										<ListItemText>{el}</ListItemText>
									</ListItem>
								);
							})}
						</List>
						<Typography paragraph>
							Be aware that not all states permit BPOs. To evaluate the property worth of the home, the majority of lenders want a home appraisal.
						</Typography>
					</Box>

					{/* The Functions */}
					<Box mt={5}>
						<Typography variant='h6' className={classes.title}>
							The Function of Broker Price Opinions
						</Typography>
						<Typography paragraph>
							A broker or real estate expert analyzes real estate comps, or comparable residences, to determine the broker pricing opinion. This
							procedure resembles conducting comparative market analysis in many ways. (Real estate agents use comparative market assessments to
							determine the worth of a property; they look at comparable homes that have recently sold in the same neighborhood.)
						</Typography>
						<Typography paragraph>
							A few more elements as well as comps affect the broker pricing opinion. The report typically contains the following items:
						</Typography>

						<Typography>
							The broker gives homeowners or buyers a broker pricing opinion form along with the property results after considering these criteria.
							We'll go into more detail about the two primary categories of broker price opinions in the following section.
						</Typography>
					</Box>

					<Box mt={5}>
						<Typography className={classes.title} variant='h6'>
							What Kinds Of Broker Price Opinions Are There?
						</Typography>
						<Typography paragraph mb={5}>
							Broker price opinions come in two varieties: internal and external. We'll go over both definitions because one might suit you better
							than the other.
						</Typography>

						<Typography className={classes.title} variant='h6'>
							Internal BPO
						</Typography>
						<Typography paragraph mb={5}>
							To properly assess a home, an agent must enter inside and spend time measuring and inspecting its features. The broker assesses the
							interior conditions of the house, sizes the rooms accurately, and personally confirms the number of bedrooms, baths, etc.
						</Typography>

						<Typography className={classes.title} variant='h6'>
							External BPO
						</Typography>
						<Typography paragraph mb={5}>
							In contrast to an indoor BPO, an external BPO, also known as a drive-by BPO, has the broker only evaluate the property outside. A broker
							might check out some of the property's formal details, such as its square footage and the number of bedrooms. Compared to internal BPOs,
							external BPOs are more prevalent but less accurate.
						</Typography>
					</Box>
				</Container>

				<Box className={classes.secDivider}>
					<Grid container>
						<Grid item sm={5}></Grid>
						<Grid item sm={7}>
							<Box padding={'3rem'} sx={{ background: (theme) => alpha(theme.secondaryColor, 0.9), color: '#fff' }}>
								<Typography variant='h6' mb={3}>
									What's the difference between an appraisal and a BPO?
								</Typography>
								<Typography paragraph>
									Time and money are the two key distinctions between appraisals and BPOs.While BPOs are carried out by agents or brokers, home
									appraisals are performed by qualified specialists. Because appraisers are required to achieve specific educational standards and
									benchmarks, the accuracy of the assessment will be substantially higher. They also enroll in valuation-related continuing education
									courses.
								</Typography>
								<Typography paragraph>
									Ordering a BPO is less expensive than ordering an appraisal. Typically, appraisals are between $300 and $400 for single-family homes
									and up to $600 for multi-family buildings (for multifamily homes). BPOs might run you half of each of those sums.
								</Typography>
								<Typography paragraph>
									It frequently requires a week or two to do all of the time-consuming, substantial work, including in-depth findings from
									assessments.
								</Typography>
								<Typography paragraph>
									On the other hand, a BPO that is conducted internally or externally may go significantly more quickly—in certain situations, in less
									than a day.
								</Typography>
							</Box>
						</Grid>
					</Grid>
				</Box>

				<Container>
					<Box mt={5}>
						<Typography className={classes.title} variant='h6'>
							Where Can I Find a Broker Price Opinion?
						</Typography>
						<Typography paragraph mb={5}>
							{' '}
							Homeowners or prospective house buyers can request a BPO right here at BPO Homes. Depending on the area, a BPO report can cost anywhere
							between $50 and $300 on average. Be aware that before selecting this strategy, you should research whether BPOs are permitted in your
							state.
						</Typography>

						<Typography className={classes.title} variant='h6'>
							Will I benefit from a broker price opinion?
						</Typography>
						<Typography paragraph mb={5}>
							When is a BPO necessary when purchasing or selling a home? A BPO might be the best choice when determining the worth of property up for
							foreclosure or short sale. A BPO would be more likely to accomplish the seller's desire to sell the house swiftly during a short sale or
							foreclosure situation.In some circumstances, BPOs don't make sense for house buyers or sellers. Get an appraisal rather than a BPO if
							you require a comprehensive, in-depth assessment of a home's value. You don't need a BPO if you're looking for a mortgage because
							lenders need an appraisal, not a BPO.
						</Typography>

						<Typography className={classes.title} variant='h6'>
							Determine if a BPO is a right choice for you.
						</Typography>
						<List>
							{choices?.map((el, index) => {
								return (
									<ListItem key={index}>
										<ListItemIcon sx={{ minWidth: '35px' }}>
											<Circle sx={{ fontSize: '1rem' }} color='secondary' />
										</ListItemIcon>
										<ListItemText>{el}</ListItemText>
									</ListItem>
								);
							})}
						</List>
					</Box>

					<Box mt={4} mb={5}>
						<Typography variant='h6' mb={3}>
							Read Also:
						</Typography>
						<Grid container spacing={4}>
							<Grid item xs={12} sm={12} lg={4}>
								<Card variant='elevation' sx={{ cursor: 'pointer' }} onClick={() => navigate('/buyer-checklist')}>
									<CardHeader style={{ backgroundImage: `url(${Check1})`, backgroundSize: 'cover', height: '15rem' }}></CardHeader>
									<CardContent style={{ padding: '2rem' }}>
										<Typography color={'secondary'} mb={2}>
											Getting Started
										</Typography>
										<Typography variant='body1' color={'textSecondary'}>
											Your Best Checklist for Buying A Home
										</Typography>
									</CardContent>
								</Card>
							</Grid>
							<Grid item xs={12} sm={12} lg={4}>
								<Card variant='elevation' sx={{ cursor: 'pointer' }} onClick={() => navigate('/buyer-firsttimer')}>
									<CardHeader style={{ backgroundImage: `url(${Check2})`, backgroundSize: 'cover', height: '15rem' }}></CardHeader>
									<CardContent style={{ padding: '2rem' }}>
										<Typography color={'secondary'} mb={2}>
											Getting Started
										</Typography>
										<Typography variant='body1' color={'textSecondary'} mb={3.5}>
											Buying a Home in 2022?
										</Typography>
									</CardContent>
								</Card>
							</Grid>
							<Grid item xs={12} sm={12} lg={4}>
								<Card variant='elevation' sx={{ cursor: 'pointer' }} onClick={() => navigate('/buyer-score')}>
									<CardHeader style={{ backgroundImage: `url(${Check3})`, backgroundSize: 'cover', height: '15rem' }}></CardHeader>
									<CardContent style={{ padding: '2rem' }}>
										<Typography color={'secondary'} mb={2}>
											Getting Started
										</Typography>
										<Typography variant='body1' color={'textSecondary'}>
											How to Raise Your Credit Score to Get Your First Home Mortgage
										</Typography>
									</CardContent>
								</Card>
							</Grid>
						</Grid>
					</Box>
				</Container>
			</Box>
		</div>
	);
};

export default BPOGuide;
