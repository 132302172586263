import { ChevronLeft, ChevronRight, Place } from '@mui/icons-material';
import { Box, Button, Link as DLink, Divider, Grid, IconButton, Stack, TextField, ThemeProvider, Typography, createTheme } from '@mui/material';
import { makeStyles, styled } from '@mui/styles';
import React, { useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import { Link as RLink, useParams } from 'react-router-dom';
import { ReactComponent as FBIcon } from '../../images/facebookfillable.svg';
import { ReactComponent as IGIcon } from '../../images/instagramfillable.svg';
import { ReactComponent as LIIcon } from '../../images/linkedInfillable.svg';
import RBG from '../../images/temp3reviewbg.webp';
import SBG from '../../images/temp3small.webp';
import { ReactComponent as TWIcon } from '../../images/twitterfillable.svg';
import { ReactComponent as YTIcon } from '../../images/youtubefillable.svg';
import { theme } from '../../theme';
import { formatNumber, formatPhoneNumber, getYoutubeEmbedLink, hideButton, scrollToTheLeft } from '../../utils/functions';
import { headerImages } from '../../constants';
import ServiceAreasSlider from '../../components/ServiceAreasSlider/ServiceAreasSlider';
import InvestorQuestionnaires from '../../components/InvestorQuestionnaires/InvestorQuestionnaires';

const Link = styled(DLink)(({ theme }) => ({
	cursor: 'pointer',
}));

const WhiteIconButton = styled(IconButton)(({ theme }) => ({
	border: '1px solid white',
	borderRadius: '50%',
	width: '40px',
	aspectRatio: '1',
	cursor: 'pointer',
}));

const useStyles = makeStyles((theme) => ({
	root: {},
	input: {
		'&::placeholder': {
			color: '#fff',
			fontFamily: 'Sarabun, sans-serif',
			opacity: 1,
			fontSize: '14px',
		},
		color: 'white',
	},
	carousel: {
		position: 'relative',
		background: `linear-gradient(rgba(0,0,0,0.2), rgba(0,0,0,0.2))`,
	},
	navroot: {
		position: 'sticky',
		top: 0,
		zIndex: 100,
		fontFamily: 'Sarabun, sans-serif',
	},
	container: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: '1rem', // Add padding
		color: 'white',
	},
	leftLinks: {
		display: 'flex',
		alignItems: 'center',
		fontFamily: 'Sarabun, sans-serif',
		color: 'white',
		gap: '1rem',
	},
	link: {
		marginLeft: theme.spacing(2), // Add margin between links
		cursor: 'pointer', // Set cursor to pointer
		fontFamily: 'Sarabun, sans-serif',
		fontWeight: '400',
		fontSize: '16px',
		color: 'white',
	},
	// scroll: {
	// 	animation: `${marquee} 5s linear infinite`,
	// },
}));

const pageTheme = createTheme({
	...theme,
	typography: {
		fontFamily: 'Sarabun, sans-serif',
	},
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					borderRadius: 0,
				},
			},
		},
	},
	palette: {
		primary: {
			main: '#001322',
		},
		secondary: {
			main: theme.palette.primary.main,
		},
		success: {
			main: '#fff',
		},
		text: {
			main: '#000',
		},
		paper: {
			main: '#fff',
		},
	},
	primaryColor: '#001322',
	secondaryColor: theme.palette.primary.main,
});

function NewTemp3({ agent, listings, testimonials, contactDetails, updateContactDetails, sendMessage, serviceAreas }) {
	const name = agent?.user.first_name + ' ' + agent?.user.last_name;
	const agent_id = agent?.id;
	const member_since = agent?.year_work_commenced;
	const agent_phone = formatPhoneNumber(agent?.phone);
	const brokerage_phone = formatPhoneNumber(agent?.brokerage?.brokerage_phone);
	const brokerage_name = agent?.brokerage_name;
	const license = agent?.licenses.find((license) => license.category === 'real_estate_agent')?.number;
	const address = agent?.address;
	const video = agent?.video_link;
	const classes = useStyles();
	const [selectedTestimonial, setselectedTestimonial] = useState(0);
	const showInvestorForm = agent?.questionnaires_on;

	const goForward = () => {
		if (selectedTestimonial === testimonials.length - 1) {
			setselectedTestimonial(0);
		} else {
			setselectedTestimonial(selectedTestimonial + 1);
		}
	};

	const goBack = () => {
		if (selectedTestimonial === 0) {
			setselectedTestimonial(testimonials.length - 1);
		} else {
			setselectedTestimonial(selectedTestimonial - 1);
		}
	};
	const [currentImage, setCurrentImage] = useState(0);

	const testimonial = testimonials[selectedTestimonial];
	const selectedListing = listings?.active ? listings?.active?.[currentImage] : null;

	return (
		<ThemeProvider theme={pageTheme}>
			<Box>
				<Box sx={{ height: '100vh', position: 'relative' }}>
					<Box sx={{ position: 'absolute', top: '0', left: '0', width: '100%' }}>
						<Nav name={name} hasActive={listings.active.length > 0} showInvestor={showInvestorForm} />
					</Box>
					<Box sx={{ position: 'relative' }}>
						<Carousel
							// autoPlay={true}
							infiniteLoop={true}
							className={classes.carousel}
							showThumbs={false}
							stopOnHover={false}
							transitionTime={1000}
							interval={5000}
							showArrows={true}
							useKeyboardArrows={false}
							showStatus={false}
							showIndicators={false}
							autoFocus={true}
							animationHandler={'fade'}
							swipeable={true}
							onChange={(index) => {
								setCurrentImage(index);
							}}
							renderArrowPrev={(onClickHandler, hasPrev) => (
								<IconButton
									onClick={onClickHandler}
									// disabled={!hasPrev}
									sx={{
										position: 'absolute',
										bottom: '20vh',
										left: '3rem',
										backgroundColor: 'secondary.main',
										borderRadius: '0',
										zIndex: '100',
										'&:hover': { backgroundColor: 'secondary.main' },
									}}
								>
									<ChevronLeft />
								</IconButton>
							)}
							renderArrowNext={(onClickHandler, hasNext) => (
								<Box
									sx={{
										position: 'absolute',
										bottom: '20vh',
										left: '6rem',
										display: 'flex',
										gap: '10px',
										zIndex: '100',
									}}
								>
									<IconButton
										onClick={onClickHandler}
										sx={{
											backgroundColor: 'secondary.main',
											borderRadius: '0',
											zIndex: '100',
											'&:hover': { backgroundColor: 'secondary.main' },
										}}
									>
										<ChevronRight />
									</IconButton>
									<Button
										color='secondary'
										variant='contained'
										disableElevation
										sx={{ textTransform: 'capitalize', color: '#fff' }}
										href={`/order-bpo-report/{agent_id}`}
										target='_blank'
									>
										Order A BPO Report
									</Button>
								</Box>
							)}
						>
							{(listings.active.length > 0 ? listings.active : headerImages.map((item) => ({ photos: [{ image: item.img }] })))
								.map((item) => item.photos?.[0])
								.filter(Boolean)
								.map((image) => (
									<Box key={image.id} sx={{ width: '100%' }}>
										<img
											src={image.image}
											alt='slider'
											style={{
												width: '100%',
												objectFit: 'cover',
												height: '100vh',
												zIndex: -1,
												backgroundImage: 'linear-gradient(0deg, rgba(0, 33, 60, 0.35), rgba(0, 33, 60, 0.35))',
											}}
											fill='true'
											width={'100%'}
											height={'100%'}
										/>
									</Box>
								))}
						</Carousel>
						{selectedListing && (
							<Box
								sx={{
									position: 'absolute',
									bottom: '40vh',
									left: '3rem',
									display: 'flex',
									flexDirection: 'column',
									gap: '10px',
									zIndex: '100',
								}}
							>
								<Typography fontWeight={500} display='flex' gap='10px' color='white' alignItems='center'>
									<Place />
									{selectedListing?.address}
								</Typography>
								<Typography color='secondary' variat='h6' fontWeight={700} textAlign='left'>
									$ {formatNumber(selectedListing?.list_price)}
								</Typography>
							</Box>
						)}
						<Stack direction='column' spacing={3} sx={{ position: 'absolute', top: '0', right: '3rem', height: '100vh', justifyContent: 'center' }}>
							{agent?.facebook && (
								<WhiteIconButton target='blank' href={agent?.facebook}>
									<FBIcon fill='white' />
								</WhiteIconButton>
							)}
							{agent?.twitter && (
								<WhiteIconButton target='blank' href={agent?.twitter}>
									<TWIcon fill='white' />
								</WhiteIconButton>
							)}
							{agent?.instagram && (
								<WhiteIconButton target='blank' href={agent?.instagram}>
									<IGIcon fill='white' />
								</WhiteIconButton>
							)}
							{agent?.linkedin && (
								<WhiteIconButton target='blank' href={agent?.linkedin}>
									<LIIcon fill='white' />
								</WhiteIconButton>
							)}
							{agent?.video_link && (
								<WhiteIconButton target='blank' href={agent?.video_link}>
									<YTIcon fill='white' />
								</WhiteIconButton>
							)}
						</Stack>
					</Box>
				</Box>
				<Grid container spacing={2} alignItems='center' justifyContent='center' id='about'>
					<Grid item xs={8} display='flex' flexDirection='column' alignItems='flex-start' sx={{ padding: '5rem !important' }}>
						<Typography variant='body1' color='primary' textTransform='uppercase' fontSize='25px'>
							Meet
						</Typography>
						<Typography variant='h6' fontWeight='700' color='primary' fontSize='30px' mb={1}>
							{name}
						</Typography>
						<Divider sx={{ width: '50px', height: '7px', backgroundColor: 'secondary.main', mb: 2 }} />
						<Typography textAlign='justify' fontWeight={500}>
							{agent?.bio}
						</Typography>
						<Grid container spacing={2} sx={{ borderLeft: '4px solid #FFAA01' }} mt={2} fontFamily={'Sarabun, sans-serif'}>
							<Grid item xs={4} paddingLeft={0}>
								{address}
							</Grid>
							<Grid item xs={4}>
								Cell Phone:{agent_phone}
							</Grid>
							<Grid item xs={4}>
								Member Since: {member_since}
							</Grid>
							<Grid item xs={4} paddingLeft={0}>
								{agent?.user?.email}
							</Grid>
							<Grid item xs={4}>
								Brokerage: {brokerage_name} {brokerage_phone ? `(${brokerage_phone})` : ''}
							</Grid>
							<Grid item xs={4}>
								Real Estate License: {license}
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={4}>
						<img
							src={agent?.profile_image}
							alt='Agent'
							style={{
								width: '400px',
								aspectRatio: '3/4',
								objectFit: 'cover',
								objectPosition: 'top center',
							}}
						/>
					</Grid>
				</Grid>
				{listings.active.length > 0 && (
					<Box>
						<Box sx={{ paddingRight: '3rem' }}>
							<Typography variant='h6' fontWeight='700' color='primary' fontSize='30px' mb={1} textAlign='right' textTransform='uppercase'>
								Active Listings
							</Typography>
							<Divider sx={{ width: '50px', height: '7px', backgroundColor: 'secondary.main', mb: 2, marginLeft: 'auto' }} />
						</Box>
						<Box position={'relative'}>
							<IconButton
								ref={(el) => hideButton(el, 'active')}
								sx={{
									position: 'absolute',
									top: '40%',
									left: '0',
									backgroundColor: 'white',
									borderRadius: '2px',
									'&:hover': { backgroundColor: 'white' },
									boxShadow: '0px 0px 10px 0px #00000026',
								}}
								onClick={() => scrollToTheLeft('active', true)}
							>
								<ChevronLeft fontSize='large' />
							</IconButton>
							<Box
								display='flex'
								gap='20px'
								px={5}
								id='active'
								sx={{
									maxWidth: '100%',
									overflowX: 'scroll',
									'&::-webkit-scrollbar': {
										display: 'none',
									},
									scrollBehavior: 'smooth',
								}}
								className={classes.scroll}
							>
								{listings.active.map((listing) => (
									<Card key={listing.id} agentId={agent_id} {...listing} />
								))}
							</Box>
							<IconButton
								ref={(el) => hideButton(el, 'active')}
								sx={{
									position: 'absolute',
									top: '40%',
									right: '0',
									backgroundColor: 'white',
									borderRadius: '2px',
									'&:hover': { backgroundColor: 'white' },
									boxShadow: '0px 0px 10px 0px #00000026',
								}}
								onClick={() => scrollToTheLeft('active', false)}
							>
								<ChevronRight fontSize='large' />
							</IconButton>
						</Box>
					</Box>
				)}
				{showInvestorForm && (
					<Box padding={'2rem 0'}>
						<InvestorQuestionnaires agent={agent} />
					</Box>
				)}
				{video && (
					<Box px={5} py={2} mt={3}>
						<Typography variant='h6' fontWeight='700' color='primary' fontSize='30px' mb={1} textAlign='left' textTransform='uppercase'>
							video spotlight
						</Typography>
						<Divider sx={{ width: '50px', height: '7px', backgroundColor: 'secondary.main', mb: 2, marginRight: 'auto' }} />
						<iframe
							style={{
								aspectRatio: '16/9',
								borderRadius: '5px',
								border: 'none',
								width: '70%',
								margin: '3rem auto',
								display: 'block',
							}}
							src={`https://www.youtube.com/embed/${getYoutubeEmbedLink(video)}`}
							title='Client video'
							allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
							allowFullScreen
						></iframe>
					</Box>
				)}
				{listings.sold.length > 0 && (
					<Box>
						<Box sx={{ paddingRight: '3rem' }}>
							<Typography variant='h6' fontWeight='700' color='primary' fontSize='30px' mb={1} textAlign='right' textTransform='uppercase'>
								Sold Listings
							</Typography>
							<Divider sx={{ width: '50px', height: '7px', backgroundColor: 'secondary.main', mb: 2, marginLeft: 'auto' }} />
						</Box>
						<Box position='relative' my='1rem'>
							<IconButton
								ref={(el) => hideButton(el, 'sold')}
								sx={{
									position: 'absolute',
									top: '40%',
									left: '0',
									backgroundColor: 'white',
									borderRadius: '2px',
									'&:hover': { backgroundColor: 'white' },
									boxShadow: '0px 0px 10px 0px #00000026',
								}}
								onClick={() => scrollToTheLeft('sold', true)}
							>
								<ChevronLeft fontSize='large' />
							</IconButton>
							<Box
								display='flex'
								gap='20px'
								px={5}
								id='sold'
								sx={{
									maxWidth: '100%',
									overflowX: 'scroll',
									'&::-webkit-scrollbar': {
										display: 'none',
									},
									scrollBehavior: 'smooth',
								}}
								className={classes.scroll}
							>
								{listings.sold.map((listing) => (
									<Card key={listing.id} agentId={agent_id} {...listing} />
								))}
							</Box>
							<IconButton
								ref={(el) => hideButton(el, 'sold')}
								sx={{
									position: 'absolute',
									top: '40%',
									right: '0',
									backgroundColor: 'white',
									borderRadius: '2px',
									'&:hover': { backgroundColor: 'white' },
									boxShadow: '0px 0px 10px 0px #00000026',
								}}
								onClick={() => scrollToTheLeft('sold', false)}
							>
								<ChevronRight fontSize='large' />
							</IconButton>
						</Box>
					</Box>
				)}
				<Stack direction='row' minHeight={'400px'} mb={'2rem'}>
					<Box flex='1' display='flex' flexDirection={'column'} justifyContent={'center'} px={5}>
						<Typography variant='body1'>{testimonial?.description}</Typography>
						<Typography variant='body1' textAlign='left' mt='1rem' fontWeight='500'>
							{testimonial?.client?.user?.first_name || testimonial?.client?.user?.last_name
								? testimonial?.client?.user?.first_name + ' ' + testimonial?.client?.user?.last_name
								: name}
						</Typography>
						{testimonials.length !== 1 && (
							<Stack direction='row' gap='10px'>
								<IconButton sx={{ cursor: 'pointer' }} onClick={goBack}>
									<ChevronLeft fontSize='small' />
								</IconButton>
								<IconButton sx={{ cursor: 'pointer' }} onClick={goForward}>
									<ChevronRight fontSize='small' />
								</IconButton>
							</Stack>
						)}
					</Box>
					<Box
						flex='1'
						px={5}
						sx={{
							background: `linear-gradient(0deg, rgba(255, 170, 1, 0.75), rgba(255, 170, 1, 0.75)),url(${RBG})`,
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							alignItem: 'center',
							flexDirection: 'column',
							backgroundSize: 'cover',
							borderTopLeftRadius: '5px',
							borderBottomLeftRadius: '5px',
						}}
					>
						<Typography variant='h6' color='black' textTransform='uppercase'>
							Customers say about my services
						</Typography>
						<Divider sx={{ width: '50px', height: '7px', backgroundColor: 'black', mb: 2 }} />
					</Box>
				</Stack>
				{serviceAreas.length > 0 && (
					<Box my={2}>
						<ServiceAreasSlider serviceAreas={serviceAreas} />
					</Box>
				)}
				<Box id='contact'>
					<Grid container spacing={0} alignItems='center' justifyContent='center' mb={2}>
						<Grid item xs={6} sx={{ backgroundImage: `url(${SBG})`, width: '100%', backgroundSize: 'cover', height: '60vh' }}></Grid>
						<Grid
							item
							xs={6}
							sx={{
								backgroundColor: '#00213c',
								padding: '2rem',
								height: '60vh',
								display: 'flex',
								flexDirection: 'column',
								gap: '2rem',
								alignItems: 'flex-start',
							}}
						>
							<Typography variant='h6' color='#fff' textTransform='uppercase' mb={1}>
								Stay updated. Be the first to know
							</Typography>
							<TextField
								variant='standard'
								color='success'
								fullWidth
								placeholder='Enter full name'
								value={contactDetails.name}
								onChange={updateContactDetails}
								name='name'
								sx={{ color: 'white' }}
								InputProps={{
									classes: { input: classes.input },
								}}
							/>
							<TextField
								variant='standard'
								color='success'
								fullWidth
								placeholder='Enter phone number'
								value={contactDetails.phone}
								onChange={updateContactDetails}
								name='phone'
								sx={{ color: 'white' }}
								InputProps={{
									classes: { input: classes.input },
								}}
							/>
							<TextField
								variant='standard'
								color='success'
								fullWidth
								placeholder='Enter email'
								value={contactDetails.email}
								onChange={updateContactDetails}
								name='email'
								sx={{ color: 'white' }}
								InputProps={{
									classes: { input: classes.input },
								}}
							/>
							<TextField
								variant='standard'
								color='success'
								fullWidth
								multiline
								value={contactDetails.message}
								onChange={updateContactDetails}
								name='message'
								placeholder='Write message'
								sx={{ fontFamily: 'Sarabun, sans-serif', color: 'white' }}
								InputProps={{
									classes: { input: classes.input },
								}}
							/>
							<Button variant='outlined' disableElevation color='secondary' mt={2} onClick={sendMessage}>
								Submit
							</Button>
						</Grid>
					</Grid>
				</Box>
			</Box>
		</ThemeProvider>
	);
}

export default NewTemp3;

const Card = ({ id, agentId, list_price, beds, baths, square_footage, address, photos }) => {
	return (
		<Link component={RLink} to={`/view-listing/${agentId}/${id}`} target='_blank'>
			<Box display='flex' flexDirection='column' gap='10px' fontFamily='Sarabun, sans-serif'>
				<img alt='house' src={photos?.[0]?.image} style={{ width: '300px', aspectRatio: '13.5/10' }} />
				<Typography variant='h6' fontWeight='400' fontFamily='Sarabun, sans-serif'>
					$ {formatNumber(list_price, 0)}
				</Typography>
				<Typography variant='body' fontWeight='300' color='#818181'>
					{address}
				</Typography>
				<Box display='flex' color='#818181'>
					<Typography variant='body' fontWeight='300'>
						{beds} Beds
					</Typography>
					<Typography
						variant='body'
						fontWeight='300'
						sx={{ borderLeft: '1px solid currentColor', borderRight: '1px solid currentColor' }}
						px='10px'
						mx='10px'
					>
						{baths} Baths
					</Typography>
					<Typography variant='body' fontWeight='300'>
						{formatNumber(square_footage, 0)} Sqft
					</Typography>
				</Box>
			</Box>
		</Link>
	);
};

const Nav = ({ name, hasActive, showInvestor }) => {
	const classes = useStyles();
	const { id: agentId } = useParams();

	return (
		<Box className={classes.navroot}>
			<Box className={classes.container}>
				{/* Right side with agent name */}
				<Typography variant='h6' className={classes.link} fontWeight='400' sx={{ width: '30%', marginLeft: '4rem !important' }}>
					Listing agent: {name}
				</Typography>
				<Box className={classes.leftLinks} flex={1}>
					<Link href='#home' className={classes.link} underline='none'>
						Home
					</Link>
					<Link href='#about' className={classes.link} underline='none'>
						About
					</Link>
					{hasActive && (
						<Link href='#active' className={classes.link} underline='none'>
							Active Listings
						</Link>
					)}
					<Link href='#contact' className={classes.link} underline='none'>
						Contact
					</Link>
					<Link href={`/agent/${agentId}/blogs`} className={classes.link} underline='none'>
						Blog
					</Link>
					{showInvestor && (
						<Link href='#investors' className={classes.link} underline='none'>
							Investors
						</Link>
					)}
				</Box>
			</Box>
		</Box>
	);
};
