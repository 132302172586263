import { Checkbox, Stack, Typography } from '@mui/material';

const QuoteTableHeader = ({ allselected, onChange, indeterminate, dateText = 'Date' }) => (
	<Stack
		direction='row'
		justifyContent={'space-evenly'}
		alignItems='center'
		sx={{ p: '1rem', background: '#2578AA', borderRadius: '8px', color: 'white' }}
	>
		<Stack direction='row' gap='1rem' sx={{ flex: 2 }} alignItems='center'>
			<Checkbox
				size='small'
				color='secondary'
				sx={{
					marginRight: '1.2rem',
				}}
				checked={allselected}
				onChange={onChange}
				indeterminate={indeterminate}
			/>
			<Typography>#</Typography>
			<Typography>Name</Typography>
		</Stack>
		<Typography sx={{ flex: 2 }} textAlign={'center'}>
			Email
		</Typography>
		<Typography sx={{ flex: 2 }} textAlign={'center'}>
			Phone Number
		</Typography>
		<Typography sx={{ flex: 1 }} textAlign={'center'}>
			{dateText}
		</Typography>
		<Typography sx={{ flex: 1 }} textAlign={'center'}>
			Submission
		</Typography>
		<Typography sx={{ flex: 1 }} textAlign={'center'}>
			Actions
		</Typography>
	</Stack>
);

export default QuoteTableHeader;
