import React from 'react'
import { Box, Typography } from '@mui/material'
import moment from 'moment'
import useTheme from "@mui/material/styles/useTheme";

const useStyles = (theme)=>{
    return {
        root:{
            marginTop:"5px",
            color:theme.palette.primary.main
        },
        mainContainer:{
            display:"flex",
            alignItems:"center",
            justifyContent:"space-between",
        },
        icon:{
            marginRight:"1rem"
        },
        time:{
            color:"black"
        },
        leftHeader:{
          display:"flex",
          alignItems:"center"
        }

    }
}
function CardMainHeader({icon:Icon,analyzerType,address,updateTime}) {
    const theme = useTheme()
    const styles = useStyles(theme)
  return (
    <Box sx={styles.root}>
      <Box sx={styles.mainContainer}>
        <Box sx={styles.leftHeader}>
          {/* <img src={StraightAesthetic} alt="icon" style={styles.icon} /> */}
          <Icon sx={{fontSize:{xs:"1.25rem",sm:"2rem",md:"3rem"},mr:2}}/>
          <Typography variant="h3" sx={{fontWeight:"500"}}>{analyzerType}</Typography>
        </Box>
        <Box>
          <Typography variant="h4" sx={{fontWeight:"700"}}>{address}</Typography>
        </Box>
      </Box>
      <Box sx={styles.time}>
        <Typography variant="h4">
          {" "}
          {'Updated '+ moment(updateTime).fromNow()}
        </Typography>
      </Box>
    </Box>
  );
}

export default CardMainHeader