import {
    FormControl,
    InputAdornment,
    Box,
    Typography,
    OutlinedInput,
    Grid
  } from "@mui/material";
  import StyledLabel from "./StyledLabel";
  import React from "react";
  import useTheme from "@mui/material/styles/useTheme";
import commaSeparate from "../utils/commaSeparate";
  
  const useStyle = (_theme)=>{
      return{
          inputs:{
              display:"flex",
              flexDirection:"column",
              marginTop:"10px",
              width:"100%"
        },
        root:{
            display:"flex",
            justifyContent:"space-between",
        }
      }
  }
  
  function PurchasePriceWithRemodelCost({handler,labelArray,nameArray, data,remodelCost}) {
  
    const handleValues = (e) => {
      let value = e.target.value;
      let name = e.target.name
      if (value) {
        value = Number.parseInt(value.split(",").join(""));
        if (isNaN(value)) {
          return;
        }
        handler({ [name]: value });
      } else {
        handler({ [name]: value });
      }
    };
  
  
    const theme = useTheme();
    const styles = useStyle(theme);
    return (
      <Grid container columns={24} >
        {labelArray &&
          labelArray.map((element, index) => (
            <>
            <Grid item sm={24} md={11}>
            <Box
              sx={
                styles.inputs
              }
            >
              <StyledLabel label={element} />
              <FormControl
                sx={{
                  marginTop: "5px",
                  backgroundColor: element.includes("Remodel")||element.includes("Construction") ? "#E5E4E2": 'inherit'
                }}
              >
                <OutlinedInput
                  fullWidth
                  name={nameArray?.[index]}
                  onChange={handleValues}
                  value = {element.includes("Remodel") || element.includes("Construction") ?commaSeparate(remodelCost) :commaSeparate(data?.[nameArray?.[index]])}
                  margin="normal"
                  inputProps={{ style: { fontSize: "1rem" } }}
                  startAdornment={
                    <InputAdornment position="start">
                      {" "}
                      <Typography variant="adornment" color="secondary">
                        {" "}
                        $
                      </Typography>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Box>
            </Grid>
            { !index &&
              <Grid item sm = {0} md={2}/>
            }
            </>
          ))}
      </Grid>
    );
  }
  
  export default PurchasePriceWithRemodelCost;
  