/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { AiFillHome } from 'react-icons/ai';
import Button from '../../components/Button/Button';
import Modal from '../../components/Modal/Modal';
import { ReactComponent as CloseIcon } from '../../images/Close.svg';
import './territory.scss';
import { Box, Card, CardContent, Checkbox, Divider, Grid, Button as MBut, Stack, TextField, Typography } from '@mui/material';
import { styled } from '@mui/styles';
import { Marker, MarkerClusterer, Polygon } from '@react-google-maps/api';
import axios from 'axios';
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import base from '../../apis';
import MarkerIcon from '../../assets/markericon.svg';
import Map from '../../components/Map/Map';
import Spinner from '../../components/Spinner/Spinner';
import { californiaCounties, countiesAndCities, mapPolygonStyles } from '../../constants';
import { getTerritoriesOutline } from '../../utils/functions';

const selectStyles1 = {
	container: (provided) => ({
		...provided,
		flex: 1,
	}),
};

const SButton = styled(MBut)(({ theme }) => ({
	textTransform: 'capitalize',
	fontSize: '15px',
}));

function Territory({ farmCount, isSearch = false }) {
	const [terModal, setTerModal] = useState(false);
	const navigate = useNavigate();
	const [cities, setCities] = useState([]);
	const [territories, setTerritories] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [territoriesToBuy, setTerritoriesToBuy] = useState([]);

	const resetBoudAndTerr = () => {
		setBoundaries([]);
		setCenter({ lat: null, lng: null });
		setTerritoriesToBuy([]);
		setTerritories([]);
	};

	const onCountySelect = (e) => {
		if (!e) {
			setCities([]);
			setSearchParams({ ...searchParams, county: null, city: null, zip_code: '' });
			return;
		}
		setSearchParams({ ...searchParams, county: e, city: null, zip_code: '' });
		setCities(countiesAndCities[e.value]);
		resetBoudAndTerr();
	};

	const onCityChange = (e) => {
		if (!e) {
			setSearchParams({ ...searchParams, city: null, zip_code: '' });
			return;
		}
		setSearchParams({ ...searchParams, city: e, zip_code: '' });
		resetBoudAndTerr();
	};
	const [searchParams, setSearchParams] = useState({
		state: null,
		county: null,
		city: null,
		zip_code: '',
	});
	const cityRef = useRef();

	// eslint-disable-next-line no-unused-vars
	const [boundaries, setBoundaries] = useState([]);
	const [center, setCenter] = useState({ lat: null, lng: null });

	const onSearch = () => {
		setTerritoriesToBuy([]);
		if (!(searchParams.county?.value && searchParams.city?.value) && !searchParams.zip_code) {
			toast.warn('Please select a county and a city or enter a zip code');
			return;
		}

		const county = searchParams?.county?.value?.split('_')?.join(' ');
		const city = searchParams?.city?.value?.split('_')?.join(' ');
		const zip_code = searchParams?.zip_code;

		const params = {};
		let url = `https://nominatim.openstreetmap.org/search.php?polygon_geojson=1&format=json`;

		if (county && city) {
			params['city'] = city;
			params['county'] = county;
			url += `&city=${city}&state=CA`;
		}
		if (zip_code) {
			params['zip_code'] = zip_code;
			url += `&postalcode=${zip_code}`;
		}
		url += `&country=USA`;
		resetBoudAndTerr();
		axios
			.get(url)
			.then(({ data }) => {
				if (data.length) {
					const selected = data[0];

					setCenter({ lat: +selected.lat, lng: +selected.lon });

					// let boundaries = undefined;
					// if (selected?.type === 'postcode' || selected?.geojson?.type === 'Point') {
					// 	const boundingBox = selected.boundingbox.map((item) => +item);
					// 	const latMin = boundingBox[0];
					// 	const latMax = boundingBox[1];
					// 	const lonMin = boundingBox[2];
					// 	const lonMax = boundingBox[3];
					// 	boundaries = [
					// 		[
					// 			{ lng: lonMin, lat: latMin },
					// 			{ lng: lonMax, lat: latMin },
					// 			{ lng: lonMax, lat: latMax },
					// 			{ lng: lonMin, lat: latMax },
					// 			{ lng: lonMin, lat: latMin },
					// 		],
					// 	];
					// } else if (selected?.geojson?.type === 'Polygon') {
					// 	boundaries = selected.geojson.coordinates.map((item) => item.map((cor) => ({ lng: cor[0], lat: cor[1] })));
					// } else if (selected?.geojson?.type.toLowerCase() === 'multipolygon') {
					// 	boundaries = selected.geojson.coordinates.map((item) => item.flat().map((i) => ({ lng: i[0], lat: i[1] })));
					// }

					// setBoundaries(boundaries);
				}
			})
			.catch();

		setIsLoading(true);
		base
			.get('/farm/territories_second_search/', {
				params,
			})
			.then(({ data }) => {
				if (!data.length) {
					toast.warn('No territories found', { toastId: 'no_territories_found' });
				}
				const dataForm = data?.filter((el) => !!el?.territory)?.map((item) => ({ ...item?.territory, ...item?.random_parcel }));

				setTerritories(dataForm);
			})
			.catch((err) => {
				toast.error('Could not get territories');
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const [selectedTerritory, setSelectedTerritory] = useState(null);

	const openMoreDetails = (id, fromMap = false) => {
		if (fromMap) {
			//scroll element into view
			const element = document.getElementById(`${id}`);
			if (element) {
				element.scrollIntoView({ behavior: 'smooth', block: 'center' });
			}
		}
		setIsLoading(true);
		base
			.get(`/farm/territories/${id}`)
			.then(({ data }) => {
				setSelectedTerritory({ ...data, outline: getTerritoriesOutline(data) });
				setTerModal(true);
			})
			.catch((err) => {
				toast.error('could not load farm details');
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const handleAddTerritory = (id) => {
		if (!territoriesToBuy.includes(id)) {
			if (territoriesToBuy.length >= farmCount) {
				toast.warn(`You can only select ${farmCount} territor${farmCount > 1 ? 'ies' : 'y'}`);
				return;
			}
			setTerritoriesToBuy([...territoriesToBuy, id]);
		} else {
			setTerritoriesToBuy(territoriesToBuy.filter((t) => t !== id));
		}
	};

	const handleBuyTerritories = async () => {
		if (!territoriesToBuy.length) {
			toast.warn('Please select at least one territory');
			return;
		}
		if (territoriesToBuy.length > farmCount) {
			toast.warn(`You can only select ${farmCount} territor${farmCount > 1 ? 'ies' : 'y'}`);
			return;
		}
		try {
			setIsLoading(true);
			const { data } = await base.post('/register/assign-farm-agent/', {
				farm_id: territoriesToBuy,
			});
			toast.success(data);

			navigate('/allocated-territories');
		} catch (error) {
			toast.error('Could not assign farm');
		} finally {
			setIsLoading(false);
		}
	};

	const [zoom, setZoom] = useState(15);

	const getZoomLevel = (coordinates) => {
		if (!coordinates.length) {
			setCenter({
				lat: 37.3361663,
				lon: -121.890591,
			});
			setZoom(10);
			return;
		}
		const minLat = Math.min(...coordinates.map((coord) => coord.lat));
		const maxLat = Math.max(...coordinates.map((coord) => coord.lat));
		const minLon = Math.min(...coordinates.map((coord) => coord.lng));
		const maxLon = Math.max(...coordinates.map((coord) => coord.lng));

		// Calculate the distance between the two latitude points
		const latDistance = maxLat - minLat;

		// Calculate the distance between the two longitude points
		const lonDistance = maxLon - minLon;

		// Determine the maximum distance
		const maxDistance = Math.max(latDistance, lonDistance);

		// Calculate the zoom level based on the maximum distance
		const zoomLevel = Math.floor(Math.log2(360 / maxDistance));
		setZoom(zoomLevel + 1);
	};

	const findCenter = (coordinates) => {
		if (!coordinates.length) return;
		// const bounds = new window.google.maps.LatLngBounds();

		// for (const coord of coordinates) {
		// 	const latLng = new window.google.maps.LatLng(coord.lat, coord.lng);
		// 	bounds.extend(latLng);
		// }

		// setCenter(bounds.getCenter());
		const numCoords = coordinates.length;
		let totalLat = 0;
		let totalLon = 0;

		// Calculate the sum of latitudes and longitudes
		for (const coord of coordinates) {
			totalLat += coord.lat;
			totalLon += coord.lng;
		}

		// Calculate the average latitude and longitude
		const avgLat = totalLat / numCoords;
		const avgLon = totalLon / numCoords;
		setCenter({
			lat: avgLat,
			lng: avgLon,
		});
	};

	return (
		<>
			{isLoading && <Spinner />}
			<Modal open={terModal} onClose={() => setTerModal(false)} style={{ minWidth: '70vw' }}>
				<div className='termod'>
					<div>
						<Map
							center={{
								lng: selectedTerritory?.parcels?.features.map((item) => item.geometry.coordinates[0][0][0][0])[0],
								lat: selectedTerritory?.parcels?.features.map((item) => item.geometry.coordinates[0][0][0][1])[1],
							}}
						>
							{selectedTerritory?.outline && (
								<Polygon paths={selectedTerritory?.outline.map((el) => ({ lng: el[0], lat: el[1] }))} options={mapPolygonStyles} />
							)}
						</Map>
					</div>
					<div>
						<CloseIcon
							onClick={() => setTerModal(false)}
							style={{
								marginLeft: 'auto',
								display: 'block',
								cursor: 'pointer',
							}}
						/>
						<h2>{selectedTerritory?.name}</h2>
						<p>
							{selectedTerritory?.state} &nbsp; <span className='gr'>&gt;</span> &nbsp; {selectedTerritory?.county} &nbsp;{' '}
							<span className='gr'>&gt;</span> &nbsp; {selectedTerritory?.city}
						</p>
						<div className='terhouse'>
							<div>
								<div className='tericontainer'>
									<AiFillHome size={40} className='tericon' />
								</div>
								<span>Home Owners</span>
							</div>
							<div>
								<span style={{ width: '180px' }}>Number of home owners in this territory</span>
								<p style={{ fontWeight: '700' }}>300</p>
							</div>
						</div>
						<p className='terside'>BPO Farm By BPO Homes</p>
					</div>
				</div>
			</Modal>
			<div className='ter' style={{ height: '100%', overflow: 'auto' }}>
				<form className='modinputs' onSubmit={(e) => e.preventDefault()}>
					{/* <Select
						placeholder='Select State'
						options={[{value:'CA', label:'CA'}]}
						isSearchable={true}
						onChange={(e) => console.log(e)}
						styles={selectStyles1}
						isClearable={true}
					/> */}
					<Select
						placeholder='Select County'
						options={californiaCounties}
						isSearchable={true}
						name='city'
						value={searchParams.county}
						onChange={onCountySelect}
						styles={selectStyles1}
						isClearable={true}
					/>
					<Select
						placeholder='Select City'
						options={cities}
						ref={cityRef}
						isSearchable={true}
						onChange={onCityChange}
						styles={selectStyles1}
						isClearable={true}
						value={searchParams.city}
					/>
					<Divider orientation='vertical' flexItem sx={{ height: '35px !important', width: '1px !important', backgroundColor: 'grey !important' }} />
					<TextField
						size='medium'
						value={searchParams.zip_code}
						type='number'
						onChange={(e) => {
							setSearchParams({ ...searchParams, zip_code: e.target.value, city: null, county: null });
						}}
						inputProps={{ maxLength: 5 }}
						placeholder='Enter zipcode'
						sx={{ '& input': { padding: '5px 10px' } }}
					/>
					<MBut sx={{ textTransform: 'capitalize' }} onClick={onSearch} variant='contained' color='secondary'>
						Search
					</MBut>
				</form>
				<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '10px 0' }}>
					<p className='terheader'>Territories & Zipcodes Covered - In (City) {!isSearch ? `- ${farmCount} left to select` : ''}</p>
					{!isSearch && (
						<MBut
							variant='contained'
							color='secondary'
							disabled={territories.length === 0}
							onClick={handleBuyTerritories}
							sx={{ textTransform: 'capitalize' }}
						>
							Assign
						</MBut>
					)}
				</div>
				<div>
					{/* {center.lat && center.lng && boundaries.length > 0 && (
						<Map center={center}>
							{boundaries.map((item, index) => (
								<Polygon key={index} paths={item} options={{ ...mapPolygonStyles, zIndex: '-1', fillOpacity: 0.5 }} />
							))}
							<MarkerClusterer averageCenter={true} minimumClusterSize={3}>
								{(cluster) =>
									territories.map((terri) => (
										<Marker
											clusterer={cluster}
											key={terri?.id}
											position={{ lng: terri?._x_coord, lat: terri?._y_coord }}
											onClick={() => openMoreDetails(terri?.id)}
											icon={MarkerIcon}
											label={{ text: `# ${terri?.name.split('#')[1]}`, color: 'white', fontSize: '12px' }}
										/>
									))
								}
							</MarkerClusterer>
						</Map>
					)} */}

					<Grid container spacing={2}>
						<Grid item xs={9}>
							<Box sx={{ position: 'sticky !important', top: '--navheight', width: '100%', height: '70vh' }}>
								{center.lat && center.lng && (
									<Map center={center} zoom={zoom}>
										{/* {boundaries.map((item, index) => (
											<Polygon key={index} paths={item} options={{ ...mapPolygonStyles, zIndex: '-1', fillOpacity: 0.5 }} />
										))} */}
										<MarkerClusterer averageCenter={true} minimumClusterSize={3}>
											{(cluster) =>
												territories.map((terri) => (
													<Marker
														clusterer={cluster}
														key={terri?.id}
														position={{ lng: terri?._x_coord, lat: terri?._y_coord }}
														onClick={() => openMoreDetails(terri?.id, true)}
														icon={MarkerIcon}
														label={{ text: `# ${terri?.name.split('#')[1]}`, color: 'white', fontSize: '12px' }}
													/>
												))
											}
										</MarkerClusterer>
									</Map>
								)}
							</Box>
						</Grid>
						<Grid item xs={3}>
							<Box sx={{ height: '70vh', overflowY: 'auto' }}>
								{territories.map(({ id, name, _y_coord, _x_coord }, index) => (
									<Card key={id} sx={{ marginBottom: '10px' }} id={id}>
										<CardContent>
											<Stack direction='row' justifyContent='space-between' alignItems='center'>
												<Typography variant='body1' fontWeight={700}>
													{name}
												</Typography>
												{!isSearch && (
													<Checkbox size='small' checked={territoriesToBuy.includes(id)} onChange={() => handleAddTerritory(id)} color='secondary' />
												)}
											</Stack>
											<Stack direction='row' justifyContent='space-between' alignItems='center'>
												<SButton variant='text' color='secondary' onClick={() => setCenter({ lat: _y_coord, lng: _x_coord })}>
													View on Map
												</SButton>
												<SButton variant='contained' color='secondary' onClick={() => openMoreDetails(id, false)}>
													Details
												</SButton>
											</Stack>
										</CardContent>
									</Card>
								))}
							</Box>
						</Grid>
					</Grid>
				</div>

				{/* <div className='iitems'>
					{territories.map(({ id, name }, index) => (
						<TerritoryItem
							name={name}
							key={id}
							moreDetails={() => openMoreDetails(id)}
							checked={territoriesToBuy.includes(id)}
							onChange={() => handleAddTerritory(id)}
						/>
					))}
				</div> */}
			</div>
		</>
	);
}

export default Territory;
