import { Box, CircularProgress, Divider, Stack, Tooltip, Typography } from '@mui/material';
import React, { useState } from 'react';
import base from '../apis';
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
	'@global': {
		'@keyframes resize': {
			from: {
				transform: 'scale(1)',
			},
			to: {
				transform: 'scale(1.05)',
			},
		},
	},

	smallBox: {
		position: 'relative',
		'&::before': {
			bottom: 0,
			position: 'absolute',
			backgroundColor: '#FFAA01',
			width: '70px',
			height: '8px',
			borderTopLeftRadius: '15px',
			borderTopRightRadius: '15px',
			content: "''",
		},
	},

	pulser: {
		width: '79px',
		aspectRatio: '1/1',
		background: 'white',
		color: '#005EA9',
		borderRadius: '50%',
		position: 'relative',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		animation: 'resize 1.5s infinite alternate ',
		transformOrigin: 'center',

		fontSize: '46px',
		fontWeight: 700,
		'&::before': {
			position: 'absolute',
			content: "''",
			backgroundColor: '#ffffff26',
			zIndex: 10,
			borderRadius: '50%',
			width: '107px',
			aspectRatio: '1/1',
		},
	},
	baseCard: { borderRadius: '5px', boxShadow: '-10px 10px 20px 0px #0000000D', padding: '1rem', height: '100%' },
}));

const ReportSummary = () => {
	const classes = useStyles();
	const [reportState, setreportState] = useState({
		pending: 0,
		completed: 0,
		incompleted: 0,
	});
	const [isLoading, setIsLoading] = useState(true);
	React.useEffect(() => {
		(async () => {
			try {
				const { data } = await base.get('/leads/summary_report/');
				setreportState(data);
			} catch (error) {
			} finally {
				setIsLoading(false);
			}
		})();
	}, []);
	return (
		<Tooltip title='Click to view new reports'>
			<Stack
				sx={{
					background: 'radial-gradient(106.6% 130.76% at 28.01% 41.11%, #005EA9 30%, #003E70 100%)',
					borderRadius: '5px',
					color: 'white',
					cursor: 'pointer',
					boxSizing: 'border-box',
				}}
				alignItems={'center'}
				gap='1rem'
				padding='1rem'
				component={Link}
				to='/customer-requested-report'
				className={`${classes.smallBox} ${classes.baseCard}`}
			>
				{isLoading ? (
					<Stack width='100%' height='100%' alignItems='center' justifyContent='center'>
						<CircularProgress color='primary' />
					</Stack>
				) : (
					<>
						<Typography color='white'>Report Summary</Typography>
						<Stack
							direction='row'
							padding='1rem'
							gap='1rem'
							divider={<Divider orientation='vertical' flexItem sx={{ backgroundColor: 'white', borderRadius: '5px', width: '3px' }} />}
						>
							<Stack gap='1rem' flex={1}>
								<Box className={classes.pulser}>{reportState.pending}</Box>
								<Typography sx={{ whiteSpace: 'nowrap' }}>New Reports</Typography>
							</Stack>
							<Stack justifyContent={'center'} gap='1rem' flex={1}>
								<Stack direction='row' gap='.5rem' alignItems='center'>
									<Typography fontSize={16} sx={{ whiteSpace: 'nowrap' }}>
										Saved Reports:{' '}
									</Typography>
									<Typography fontSize={30} fontWeight={500} sx={{ whiteSpace: 'nowrap' }}>
										{reportState.incompleted}
									</Typography>
								</Stack>
								<Stack direction='row' gap='.5rem' alignItems='center'>
									<Typography fontSize={16} sx={{ whiteSpace: 'nowrap' }}>
										Completed Reports:{' '}
									</Typography>
									<Typography fontSize={30} fontWeight={500} sx={{ whiteSpace: 'nowrap' }}>
										{reportState.completed}
									</Typography>
								</Stack>
							</Stack>
						</Stack>
					</>
				)}
			</Stack>
		</Tooltip>
	);
};

export default ReportSummary;
