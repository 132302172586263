import { grey } from '@mui/material/colors'
import { makeStyles } from '@mui/styles'
import React from 'react'
import BottomActions from '../helpers/BottomActions'
import ContingencyItem from '../helpers/ContingencyItem'


const useStyles = makeStyles(theme => ({
    label: {
        fontSize: '.9rem',
        color: grey[600],
        marginBottom: '.5rem'
    },
    btn: {
        textTransform: 'none',
        borderRadius: '8px'
    }
}))



const StageFive = ({ data, dispatch, exit, saveForm, load }) => {
    const classes = useStyles()

    const onFormSubmit = (val) => {
        if(val === 'continue'){
            saveForm('stage-five')
        }
        if(val === 'exit'){
            exit('stage-five')
        }
    }
  return (
    <div>
        {
            data?.contingents?.map((el,i) => (
                <ContingencyItem
                    key={i}
                    title={el?.name}
                    index={i}
                    classes={classes}
                    dispatch={dispatch}
                    data={el}
                />
            ))
        }

        <BottomActions
            classes={classes}
            load={load}
            onSubmit={(val) => onFormSubmit(val)}
            resetDispatch={() => { dispatch({ type: 'RESET' }) }}
            buttons={['Clear Entries', 'Save & Exit', 'Save & Continue']}
        />


    </div>
  )
}

export default StageFive