import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const NavbarDropdown = ({ label, children, routesToCheck }) => {
	const [show, setShow] = useState(false);
	const { pathname } = useLocation();

	useEffect(() => {
		if (routesToCheck.includes(pathname.split('/')[1])) {
			setShow(true);
		}
	}, [pathname, routesToCheck]);
	return (
		<div>
			<span
				onClick={() => {
					setShow((isShow) => !isShow);
				}}
			>
				{label({ show })}
			</span>
			{show && children}
		</div>
	);
};

export default NavbarDropdown;
