import React from 'react';
import './button.scss';

/**
 *
 * @param {children,onClick,text,invert} param0 takes a children parameter or actual text, an onclick function and invert to invert the colors of a button content
 * @returns
 */
function Button({ children, onClick, text, invert, unhover, ...rest }) {
	let classNames = '';

	if (invert) {
		if (text) classNames += ' inverttxt';
		else classNames += ' invert';
	}
	if (text)
		return (
			<button className={'button button--text' + classNames} {...rest} onClick={onClick}>
				{text}
			</button>
		);
	return (
		<button className={'button button--children' + classNames} onClick={onClick} {...rest}>
			{children}
		</button>
	);
}

export default Button;
