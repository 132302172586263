import { Box, Container, Grid, Hidden, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';

const useStyles = makeStyles((theme) => ({
	banner: {
		height: '60vh',
		backgroundPosition: 'center',
		backgroundSize: 'cover',
		background: 'lightgrey',
	},
	bannerTitle: {
		fontSize: '2.2rem !important',
		fontWeight: '700 !important',
		lineHeight: '1.2 !important',
		color: (props) => props.titleColor,
	},
	bannerWrap: {
		display: 'flex',
		flexDirection: 'column !important',
		justifyContent: 'center',
		[theme.breakpoints.down('md')]: {
			padding: '3rem',
		},
	},
}));

const AdBanner = (props) => {
	const { title, bgImage, children } = props;
	const classes = useStyles(props);

	return (
		<div>
			{/* BANNER */}
			<Box className={classes.banner} style={{ backgroundImage: `url(${bgImage})` }}>
				<Container style={{ height: '100%' }}>
					<Grid container spacing={2} style={{ height: '100%' }}>
						<Grid item sm={12} md={7} lg={6} className={classes.bannerWrap}>
							<Typography className={classes.bannerTitle} variant='h6'>
								{title}
							</Typography>
						</Grid>
						<Hidden mdDown>
							<Grid item sm={12} md={5} lg={6} className={classes.bannerWrap}>
								{children}
							</Grid>
						</Hidden>
					</Grid>
				</Container>
			</Box>
		</div>
	);
};

export default AdBanner;
