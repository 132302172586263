import React, { useEffect, useRef, useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import AddIcon from "@mui/icons-material/Add";

import {
  FormControl,
  InputAdornment,
  OutlinedInput,
  Select,
  Box,
  MenuItem,
  Typography,
  Grid,
  Button,
  Checkbox,
} from "@mui/material";
import { listingSource, propertyType } from "./cardStaticData";
import useTheme from "@mui/material/styles/useTheme";
import StyledLabel from "../atomicComponents/StyledLabel";
import { preventMinus } from "../utils/preventNegativeValues";
import PropertyInformationAdditionalUnit from "./AdditonalInputAddons/PropertyInfo/PropertyInfo";

let id = 1;
const useStyle = (_theme) => {
  return {
    root: {
      padding: "10px",
    },
    row1: {
      display: "flex",
      marginTop: "10px",
      width: "100%",
    },
    row2: {
      display: "flex",
      marginTop: "10px",
      width: "100%",
      justifyContent: "space-between",
    },
  };
};

function PropertyInformationEnhanced({ handleEvents, data }) {
  const theme = useTheme();
  const styles = useStyle(theme);
  const focusADU = useRef();
  const focusBasement = useRef();
  const scrollRef = useRef(null);
  const componentDataFilter = (e) => {
    handleEvents({ [e.target.name]: e.target.value });
  };
  const handleDate = (year) => {
    handleEvents({ year_of_construction: year });
  };
  //checks if value exist and set adu to true
  const hasAduValue = data?.adu ? true : false;

  const [showADU, setShowADU] = useState(data?.showADU || hasAduValue || false);
  const [showBasement, setShowBasement] = useState(data?.showBasement || false);
  const [additionalUnitArray, setAdditionalUnitArray] = useState(
    data?.additional_units || []
  );
  //--------------------- setting carport or garage -----------------//
  const prevCarportOrGarageValue = data.carpot_or_garage
    ? data.carpot_or_garage === "Garage"
      ? 0
      : 1
    : 0;
  const [carportOrgarage, setCarportOrGarage] = useState(
    prevCarportOrGarageValue
  );

  //================== toggle adu ===========================//
  const toggleADU = () => {
    setShowADU((prev) => !prev);
    handleEvents({ showADU: !showADU });
  };

  //================== toggle adu ===========================//
  const toggleBasement = () => {
    setShowBasement((prev) => !prev);
    handleEvents({ showBasement: !showBasement });
  };

  // focus ADU field
  useEffect(() => {
    if (!showADU) return;

    focusADU?.current?.focus();
  }, [showADU]);

  //focus basement field
  useEffect(() => {
    if (!showBasement) return;

    focusBasement?.current?.focus();
  }, [showBasement]);





  // Creating new fields
  const addAdditionalUnitFields = () => {
    let additionalInitializer = [
      { name: `bedrooms${id}`, value: "" },
      { name: `bathrooms${id}`, value: "" },
      { name: `additional_sqrft${id}`, value: "" },
      { name: `preExistLivable${id}`, value: "" },
      { name: `garage_or_carpot${id}`, value: "" },
      { name: `year_of_construction${id}`, value: "" }
    ];
    setAdditionalUnitArray([...additionalUnitArray, additionalInitializer]);
    id++;


  };


  // clear input
  useEffect(() => {
    if (data?.property_type < 2) {
      setAdditionalUnitArray([]);
      handleEvents({ additional_units: [] });
    }

    if (data?.property_type !== 0) {
      handleEvents({ adu: 0 });
      setShowADU(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.property_type]);

  //-------------- this effect adds garage or carport option to the property field
  useEffect(() => {
    handleEvents({
      carpot_or_garage: carportOrgarage === 0 ? "Garage" : "Carport",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carportOrgarage]);

  useEffect(() => {
    if ([0, 3, 4, 5].includes(data?.property_type)) {
      setTimeout(() => {
        scrollRef.current.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "nearest",
        });
      }, 0);
    }
  }, [data?.property_type]);

  // Straight Remodel & Addition comp

  return (
    <Box sx={styles.root}>
      <Grid container columns={24}>
        <Grid item sm={24} md={11}>
          <FormControl sx={{ width: "100%", marginRight: "40px" }}>
            <StyledLabel
              label="Listing Date"
              showToolTip
              toolTipContent={toolTipTexts.listingDate}
            />
            <OutlinedInput
              sx={{
                "&:hover ": {
                  borderColor: "red",
                },
              }}
              fullWidth
              name="listing_date"
              onChange={componentDataFilter}
              value={data?.["listing_date"]}
              margin="normal"
              inputProps={{ style: { fontSize: "16px" } }}
              type="date"
            />
            {/* {selectedDate > currentDate && <Box>
              <Typography
                variant="h6"
                sx={{ backgroundColor: "pink", padding: "5px", width: "80%" }}
              >
                Listing Date can not be greater than today's date
              </Typography>
            </Box>} */}
          </FormControl>
        </Grid>
        <Grid sm={0} md={2} />
        <Grid item sm={24} md={11}>
          <FormControl sx={{ width: "100%" }}>
            <StyledLabel
              label="Listing Source"
              showToolTip
              toolTipContent={toolTipTexts.listingSource}
            />
            <Select
              name="listing_source"
              onChange={componentDataFilter}
              defaultValue={data?.["listing_source"]}
              inputProps={{ style: { fontSize: "34px" } }}
              sx={{ fontSize: "16px" }}
            >
              {listingSource.map((element, index) => (
                <MenuItem sx={{ fontSize: "16px" }} value={index}>
                  {element}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {data?.listing_source === 3 && (
            <FormControl sx={{ width: "100%", mt: "1rem" }}>
              <StyledLabel
                label="Please Specify"
                showToolTip
                toolTipContent={toolTipTexts.bedrooms}
              />
              <OutlinedInput
                name="other_listing_source"
                type="text"
                onChange={componentDataFilter}
                value={data?.other_listing_source}
                inputProps={{ style: { fontSize: "1rem" }, min: 0 }}
                sx={{ fontSize: "16px" }}
              ></OutlinedInput>
            </FormControl>
          )}
        </Grid>
      </Grid>
      <Grid container columns={36} sx={{ mt: 2 }}>
        <Grid item sm={36} md={10}>
          <FormControl sx={{ width: "100%" }}>
            <StyledLabel
              label="Bedrooms"
              showToolTip
              toolTipContent={toolTipTexts.bedrooms}
            />
            <OutlinedInput
              name="bedrooms"
              onChange={componentDataFilter}
              type="number"
              onKeyPress={preventMinus}
              value={data?.bedrooms}
              inputProps={{ style: { fontSize: "1rem" }, min: 0 }}
              sx={{ fontSize: "16px" }}
            >
              {/* {bedBath.map((element) => (
              <MenuItem sx={{ fontSize: "16px" }} value={element}>
                {element}
              </MenuItem>
            ))} */}
            </OutlinedInput>
          </FormControl>
        </Grid>
        <Grid item sm={0} md={2} />
        <Grid item sm={36} md={10}>
          <FormControl sx={{ width: "100%" }}>
            <StyledLabel
              label="Bathrooms"
              showToolTip
              toolTipContent={toolTipTexts.bathrooms}
            />
            <OutlinedInput
              name="bathrooms"
              onChange={componentDataFilter}
              type="number"
              onKeyPress={preventMinus}
              value={data?.bathrooms}
              inputProps={{ style: { fontSize: "1rem" }, min: 0 }}
              sx={{ fontSize: "16px" }}
            >
              {/* {bedBath.map((element) => (
              <MenuItem sx={{ fontSize: "16px" }} value={element}>
                {element}
              </MenuItem>
            ))} */}
            </OutlinedInput>
          </FormControl>
        </Grid>
        <Grid item sm={0} md={2} />
        <Grid item sm={36} md={12}>
          <FormControl sx={{ width: "100%" }}>
            <StyledLabel
              label="Lot Size"
              showToolTip
              toolTipContent={toolTipTexts.lotSize}
            />
            <OutlinedInput
              fullWidth
              name="lot_size"
              onChange={componentDataFilter}
              value={data?.lot_size}
              type="number"
              margin="normal"
              onKeyPress={preventMinus}
              inputProps={{ style: { fontSize: "16px" }, min: 0 }}
              endAdornment={
                <InputAdornment position="start">
                  {" "}
                  <Typography variant="adornment"> SqFt</Typography>
                </InputAdornment>
              }
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container columns={24} sx={{ mt: 2 }}>
        <Grid item sm={24} md={11}>
          <FormControl sx={{ width: "100%" }}>
            <StyledLabel label="Pre Existing Livable SqFt (Main Unit)" />
            <OutlinedInput
              name="pre_existing_livable_sqft"
              onChange={componentDataFilter}
              value={data?.pre_existing_livable_sqft}
              onKeyPress={preventMinus}
              inputProps={{ style: { fontSize: "16px" }, min: 0 }}
              sx={{ fontSize: "16px" }}
              endAdornment={
                <InputAdornment position="start">
                  {" "}
                  <Typography variant="adornment"> SqFt</Typography>
                </InputAdornment>
              }
            />
          </FormControl>
        </Grid>
        <Grid item sm={0} md={2} />
        <Grid item sm={24} md={11}>
          <FormControl sx={{ width: "100%" }}>
            <StyledLabel
              label="Garage / Carport Spaces"
              showToolTip
              toolTipContent={toolTipTexts.garage}
            />
            <OutlinedInput
              name="garage"
              onChange={componentDataFilter}
              value={data?.garage}
              type="number"
              onKeyPress={preventMinus}
              inputProps={{ style: { fontSize: "16px" }, min: 0 }}
              sx={{ fontSize: "16px" }}
              endAdornment={
                <>
                  <InputAdornment position="start">
                    {
                      <FormControl sx={{ width: "7.5rem" }}>
                        <Select
                          // name={`${name}_month_or_year`}
                          displayEmpty={true}
                          // disabled={disable}
                          error={true}
                          placeholder="none"
                          renderValue={(value) =>
                            value ? value : <b>months</b>
                          }
                          sx={{
                            fieldSet: {
                              border: "none !important",
                            },
                            fontSize: "1.125rem",
                            color: "black",
                            fontWeight: "bold",
                          }}
                          onChange={(e) => setCarportOrGarage(e.target.value)}
                          value={carportOrgarage === 0 ? "Garage" : "Carport"}
                        >
                          {["Garage", "Carport"].map((element, index) => (
                            <MenuItem
                              value={index}
                              key={element}
                              sx={{
                                "&:hover": { backgroundColor: "#1378A5" },
                                fontSize: "1rem",
                              }}
                            >
                              {element}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    }
                  </InputAdornment>
                </>
                // <InputAdornment position="start">
                //   {" "}
                //   <Typography variant="adornment"> Spaces</Typography>
                // </InputAdornment>

                //-------------------- updating the carport and garage---------------------//
              }
            />
          </FormControl>
        </Grid>
      </Grid>
      {/* <Grid
        item
        sx={{
          width: "100%",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
      >
        {additionalUnitArray?.map((item, index) => (
          <FormControl sx={{ width: "45%", mt: "1rem" }} key={index}>
            <StyledLabel
              label={`Unit ${index + 2}`}
              showToolTip
              toolTipContent={toolTipTexts.bedrooms}
            />
            <Box sx={{ display: "flex" }}>
              <OutlinedInput
                name={"value"}
                onKeyPress={preventMinus}
                type="number"
                onChange={(event) => componentDynamicDataFilter(index, event)}
                value={item?.value}
                inputProps={{ style: { fontSize: "1rem" }, min: 0 }}
                sx={{ fontSize: "16px" }}
                endAdornment={
                  <InputAdornment position="start">
                    {" "}
                    <Typography variant="adornment"> SqFt</Typography>
                  </InputAdornment>
                }
              ></OutlinedInput>
              <Button
                variant="outlined"
                onClick={() => deleteUnit(item?.name)}
                startIcon={<DeleteIcon />}
              >
                {" "}
                Delete
              </Button>
            </Box>
          </FormControl>
        ))}
      </Grid> */}
      <Grid container columns={24} sx={{ mt: 2 }}>
        <Grid item sm={24} md={14}>
          <FormControl sx={{ width: "100%" }}>
            <StyledLabel label="Additional Square Footage" />
            <OutlinedInput
              name="additional_sqrft"
              onChange={componentDataFilter}
              value={data?.additional_sqrft || null}
              inputProps={{ style: { fontSize: "1rem" }, min: 0 }}
              sx={{ fontSize: "1rem" }}
              onKeyPress={preventMinus}
              endAdornment={
                <InputAdornment position="start">
                  {" "}
                  <Typography variant="adornment"> SqFt</Typography>
                </InputAdornment>
              }
            />
          </FormControl>
        </Grid>
        <Grid sm={0} md={2} />
        <Grid item sm={24} md={7}>
          <FormControl sx={{ width: "100%" }}>
            <StyledLabel
              label="Year of Construction"
              showToolTip
              toolTipContent={toolTipTexts.constuctionYear}
            />
            <LocalizationProvider dateAdapter={AdapterMoment}>
              {/* <YearPicker
            variant="inline"
            value = {data?.year_of_construction}
            // type="number"
            // defaultValue={2022}
            // inputProps={{ style: { fontSize: "1.60px", min: "1700" } }}
            // renderInput={(params) => <TextField {...params} />}
            sx={{ fontSize: "1.60px" }} */}
              {/* /> */}
              <DatePicker
                views={["year"]}
                name="year_of_construction"
                sx={{ fontSize: "16px", padding: "10px" }}
                value={
                  data?.["year_of_construction"]
                    ? data.year_of_construction
                    : null
                }
                onChange={handleDate}
                renderInput={(params) => (
                  <OutlinedInput
                    size="normal"
                    // InputProps={{ style: { fontSize: 40 } }}

                    sx={{ fontSize: "16px" }}
                    {...params}
                    helperText={null}
                  />
                )}
              />
            </LocalizationProvider>
          </FormControl>
        </Grid>
      </Grid>
      <Box sx={styles.row2}>
        <FormControl sx={{ width: "300px", marginRight: "40px" }}>
          <StyledLabel label="Property Type" />
          <Select
            name="property_type"
            onChange={componentDataFilter}
            value={data?.property_type}
            inputProps={{ style: { fontSize: "34px" } }}
            sx={{ fontSize: "16px" }}
          >
            {propertyType.map((element, index) => (
              <MenuItem sx={{ fontSize: "16px" }} value={index}>
                {element}
              </MenuItem>
            ))}
          </Select>
          {data?.property_type === 0 && (
            <Box
              sx={{
                mt: "1rem",
                display: "flex",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <StyledLabel label="Add ADU" />{" "}
              <Checkbox
                sx={{ "& .MuiSvgIcon-root": { fontSize: 30 } }}
                checked={showADU}
                onClick={toggleADU}
              />
            </Box>
          )}
        </FormControl>
        <div ref={scrollRef} />
        {showADU && (
          <FormControl sx={{ minWidth: "40%", mt: "1rem" }}>
            <StyledLabel
              label="Optional ADU Square Footage"
              showToolTip
              toolTipContent={
                "(Accessory Dwelling Unit) a second small dwelling right on the same grounds (or attached to) your single-family home"
              }
            />
            <OutlinedInput
              inputRef={focusADU}
              name="adu"
              onKeyPress={preventMinus}
              type="number"
              onChange={componentDataFilter}
              endAdornment={
                <InputAdornment position="start">
                  {" "}
                  <Typography variant="adornment"> SqFt</Typography>
                </InputAdornment>
              }
              value={data?.adu}
              inputProps={{ style: { fontSize: "1rem" }, min: 0 }}
              sx={{ fontSize: "16px" }}
            ></OutlinedInput>
          </FormControl>
        )}
        {data?.property_type === 6 && (
          <FormControl sx={{ width: "50%" }}>
            <StyledLabel
              label="Please Specify"
              showToolTip
              toolTipContent={toolTipTexts.bedrooms}
            />
            <OutlinedInput
              name="other_property_type"
              type="text"
              onChange={componentDataFilter}
              value={data?.other_property_type}
              inputProps={{ style: { fontSize: "1rem" }, min: 0 }}
              sx={{ fontSize: "16px" }}
            ></OutlinedInput>
          </FormControl>
        )}
      </Box>
      {[3, 4, 5].includes(data?.property_type) && (
        <Box>
          <Button
            size="large"
            variant="contained"
            startIcon={<AddIcon />}
            sx={{ textTransform: "none", mt: "2rem" }}
            disabled={
              additionalUnitArray?.length &&
              data?.property_type === additionalUnitArray?.length + 2
            }
            onClick={addAdditionalUnitFields}
          >
            <Typography variant="h5"> Add Additional Unit </Typography>
          </Button>
        </Box>
      )}
      <>
        <Grid container columns={24}>
          <Grid item sm={24} md={14}>
            <Box
              sx={{
                mt: "1rem",
                display: "flex",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <StyledLabel label="Add Basement" />{" "}
              <Checkbox
                sx={{ "& .MuiSvgIcon-root": { fontSize: 30 } }}
                checked={showBasement}
                onClick={toggleBasement}
              />
            </Box>
          </Grid>
          {showBasement && (
            <Grid item sm={24} md={10}>
              <FormControl sx={{ width: "100%" }}>
                <StyledLabel label="Basement Square Footage" />
                <OutlinedInput
                  inputProps={{ style: { fontSize: "1rem" }, min: 0 }}
                  inputRef={focusBasement}
                  name="basement_psqf"
                  onChange={componentDataFilter}
                  value={data?.basement_psqf || null}
                  onKeyPress={preventMinus}
                  sx={{ fontSize: "1rem" }}
                  type="number"
                  endAdornment={
                    <InputAdornment position="start">
                      {" "}
                      <Typography variant="adornment"> SqFt</Typography>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Grid>
          )}{" "}
        </Grid>
      </>
      <div ref={scrollRef} />

      {additionalUnitArray
        ?.map((item, index) =>
          <>
            <Box mt={2} />
            <PropertyInformationAdditionalUnit
              unitIndex={index}
              item={item}
              data={data}
              additionalUnitArray={additionalUnitArray}
              setAdditionalUnitArray={setAdditionalUnitArray}
              handleEvents={handleEvents}
            />
          </>

        )}
    </Box>
  );
}

export default PropertyInformationEnhanced;

const toolTipTexts = {
  listingDate:
    "The date home was actively listed on MLS or another source provider",
  listingSource: "MLS, BPO HOMES, Others (Leads to type in option)",
  bedrooms: "Number of bedrooms in the property",
  bathrooms: "Number of bathrooms in the property",
  lotSize: "Square footage of the property's land",
  pre_existing_livable_sqft:
    "Amount of square footage currently existing at the residence",
  garage: "Number of garage spaces, currently at the residence",
  constuctionYear: "The year property was built",
};
