export const UPDATE_PROPERTY_INFORMATION = 'UPDATE_PROPERTY_INFORMATION';
export const UPDATE_FINANCE_OPTIONS = 'UPDATE_FINANCE_OPTIONS'
export const UPDATE_PROPERTY_PERMIT_AND_FEES = 'PROPERTY_PERMIT_AND_FEES'
export const UPDATE_TAXES = 'TAXES'
export const UPDATE_REMODEL_COSTS = 'UPDATE_REMODEL_COSTS'
export const UPDATE_CLOSING_COSTS = 'UPDATE_CLOSING_COSTS'
export const UPDATE_RENTAL_INCOME = 'UPDATE_RENTAL_INCOME'
export const UPDATE_PROPERTY_MANAGEMENT = 'UPDATE_PROPERTY_MANAGEMENT'
export const UPDATE_OTHER_COSTS = 'UPDATE_OTHER_COSTS'
export const UPDATE_CARRYING_MONTHS = 'UPDATE_CARRYING_MONTHS'
export const UPDATE_ESTIMATED_RESALE_VALUE = 'UPDATE_ESTIMATED_RESALE_VALUE'
export const UPDATE_INVESTOR_PROFIT = 'UPDATE_INVESTOR_PROFIT'
export const UPDATE_INVESTOR_PROFIT_ITEMS = 'UPDATE_INVESTOR_PROFIT_ITEMS'
export const UPDATE_ZILLOW_COMPS_ITEMS = 'UPDATE_ZILLOW_COMPS_ITEMS'
export const ADD_INVESTOR_PROFIT_ITEM = 'ADD_INVESTOR_PROFIT_ITEM'
export const DELETE_INVESTOR_PROFIT_ITEM = 'DELETE_INVESTOR_PROFIT_ITEM'
export const UPDATE_SUMMARY_TEXT = 'UPDATE_SUMMARY_TEXT'
export const UPDATE_ANALYZER_REPORT_ID = 'UPDATE_ANALYZER_REPORT_ID'
export const UPDATE_DEAL_ANALYZER_RESULTS = 'UPDATE_DEAL_ANALYZER_RESULTS'
export const UPDATE_CUSTOM_COMPS = 'UPDATE_CUSTOM_COMPS'
export const UPDATE_AESTHETIC_ITEMS = 'UPDATE_AESTHETIC_ITEMS'
export const UPDATE_NON_AESTHETIC_ITEMS = 'UPDATE_NON_AESTHETIC_ITEMS'
export const UPDATE_DEMOLISHING_COST = 'UPDATE_DEMOLISHING_COST'
export const UPDATE_DEVELOPMENT_COST = 'UPDATE_DEVELOPMENT_COST'
export const ADD_TO_MESSAGE_QUEUE = 'ADD_TO_MESSAGE_QUEUE'
export const UPDATE_MESSAGE_QUEUE = 'UPDATE_MESSAGE_QUEUE'
export const RESTORE_INITIAL_STATE = 'RESTORE_INITIAL_STATE'
export const UPDATE_ALL_STATE = 'UPDATE_ALL_STATE'
export const RAISE_INPUT_CARD_ERROR = "RAISE_INPUT_CARD_ERROR"

export const REMOVE_INPUT_CARD_ERROR = "REMOVE_INPUT_CARD_ERROR"
export const UPDATE_DEAL_ANALYZER_ORDER_DETAILS = 'UPDATE_DEAL_ANALYZER_ORDER_DETAILS'