import React from "react";
import {
  Box,
  Typography,
  FormControl,
  OutlinedInput,
  InputAdornment,
} from "@mui/material";
import StyledLabel from "../atomicComponents/StyledLabel";
import MonthYearSelector from "../atomicComponents/MonthYearSelector";
import { preventMinus } from "../utils/preventNegativeValues";

function QuestionnairePrimaryInterestAndTerm({
  data,
  handler,
  extraComponentData,
}) {
  const dealFinanceOption = data?.deal_finance_option || 0;
  const header = extraComponentData.primaryHeaders?.[dealFinanceOption];
  const handleChange = (e) => {
    handler({ [e.target.name]: e.target.value });
  };
  return (
    <Box sx={{ width: "50%", minWidth: "21.875rem" }}>
      <Box>
        <Typography variant="heading" sx={{ color: "#1378A5" }}>
          {header}
        </Typography>
      </Box>
      <Box sx={{ width: "100%", marginTop: "1.875rem" }}>
        <Box sx={{ marginBottom: "10px" }}>
          <StyledLabel label="What is the Interest Rate" />
        </Box>
        <FormControl sx={{ width: "100%" }}>
          <OutlinedInput
            fullWidth
            margin="normal"
            name={"primary_interest_rate"}
            onChange={handleChange}
            value={data?.["primary_interest_rate"]}
            // placeholder='0'
            renderValue={(value) => value}
            inputProps={{ style: { fontSize: "1rem" }, min: 0 }}
            onKeyPress={preventMinus}
            endAdornment={
              <InputAdornment>
                <Typography variant="adornment">%</Typography>
              </InputAdornment>
            }
          />
        </FormControl>
      </Box>
      <Box sx={{ marginTop: "1.875rem" }}>
        <MonthYearSelector
          data={data}
          label="What is the term of the Loan"
          handler={handler}
          name="primary_term_of_loan"
        />
      </Box>
    </Box>
  );
}

export default QuestionnairePrimaryInterestAndTerm;
