import { Dialog, DialogContent, Button as NButton, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import AlertCircle from '@untitled-ui/icons-react/build/esm/AlertCircle';
import React from 'react';

const useStyles = makeStyles((theme) => ({
	red: {
		stroke: '#D92D20',
		'& path': {
			stroke: 'inherit',
		},
	},
}));

const DeleteDialog = ({ isOpen, setOpen, onCancel, onConfirm, items = [] }) => {
	const classes = useStyles();
	return (
		<Dialog open={isOpen} maxWidth='sm' fullWidth>
			<DialogContent>
				<Stack direction='row' gap='1rem'>
					<Stack
						sx={{ border: '8px solid #FEF3F2', background: '#FEE4E2', borderRadius: '50%', height: '48px', aspectRatio: '1' }}
						alignItems='center'
						justifyContent='center'
					>
						<AlertCircle stroke='#D92D20' className={classes.red} />
					</Stack>
					<Stack>
						<Typography color='#1D2939' fontWeight={600} fontSize='18px'>
							Delete
						</Typography>
						<Typography color='#475467'>
							Are you sure you want to delete {items.length > 1 ? 'the selected Entries' : 'this Entry?'} You will lose all related data.
							<b> This action cannot be undone.</b>
						</Typography>
						<Stack direction='row' justifyContent={'flex-end'} gap='1rem' mt='2rem'>
							<NButton
								color='text'
								variant='outlined'
								onClick={() => {
									setOpen(false);
									if (onCancel) onCancel();
								}}
							>
								Cancel
							</NButton>
							<NButton color='error' variant='contained' onClick={onConfirm}>
								Delete
							</NButton>
						</Stack>
					</Stack>
				</Stack>
			</DialogContent>
		</Dialog>
	);
};

export default DeleteDialog;
