import { Card, CardContent, CardHeader, Grid, Typography } from '@mui/material';
import { Link, useParams } from 'react-router-dom';

const ReadMoreItem = ({ title, link, image, description }) => {
	const { id: agentId } = useParams();

	return (
		<Grid item xs={12} sm={12} lg={4} key={title}>
			<Link to={`/agent/${agentId}/blogs/${link}`} style={{ textDecoration: 'none' }}>
				<Card variant='elevation' sx={{ cursor: 'pointer', height: '100%' }}>
					<CardHeader style={{ backgroundImage: `url(${image})`, backgroundSize: 'cover', height: '15rem' }}></CardHeader>
					<CardContent style={{ padding: '2rem' }}>
						<Typography color={'secondary'} mb={2}>
							{title}
						</Typography>
						<Typography
							variant='body1'
							color={'textSecondary'}
							sx={{
								display: '-webkit-box',
								'-webkit-line-clamp': '3',
								' -webkit-box-orient': 'vertical',
								overflow: 'hidden',
								'text-overflow': 'ellipsis',
							}}
						>
							{description}
						</Typography>
					</CardContent>
				</Card>
			</Link>
		</Grid>
	);
};

export default ReadMoreItem;
