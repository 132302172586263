import React from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Typography } from '@mui/material';
const useStyles = makeStyles((theme) => ({
	root: {
		minHeight: '60vh',
		padding: '4rem 8rem',
		[theme.breakpoints.down('md')]: {
			padding: '20px',
		},
	},
	content: {
		textAlign: 'justify',
		fontWeight: 300,
	},
}));

const headers = [
	{
		subs: [
			{
				content:
					'BPO Homes & BPO Tech established in 2022 based in the Silicon Valley of San Jose Ca. BPO Homes is a Real Estate Company with a focus on providing consumers with detailed Real Estate property analysis through our unique BPO Reporting process. We give our consumers the opportunity to hear and see what a Licensed unbiased professional has to say in regards to the nuances of Real Estate acquisition or sale of a property. The BPO Reports show disadvantages or attributes of a property which prove to be vital information when attempting to make a sound financial decision in regards to whether or not you should buy or sell for a certain price in any given Real Estate market.',
				render: false,
			},
			{
				content:
					'Knowing that Banks/Lenders have used BPO Reports for decades in comparison with Appraisals to determine whether or not or the amount they should lend on a property. At BPOHomes we believe if lenders use BPO Reports to decide if they are making a sound Real Estate decision so should Buyers & Sellers.',
				render: false,
			},
			{
				content: "For the 1st time in the Real Estate industry BPO Homes has made BPO's accessible to theeveryday consumer!!",
				render: false,
			},
			{
				content:
					'Now more than ever in our ever changing Real Estate market you need to keep up with advancements in Real Estate Tech and here at BPO Homes we are here for you to make sure that you have the necessary tools to accomplish that task.',
				render: false,
			},
			{
				content:
					'We help Agents brand themselves and build their business whether they are BPO Homes Realty Agents or not. We have Tremendous Platforms for our subscribing Agent Business growth at a very reasonable low cost for such subscribing agents.',
				render: false,
			},
			{
				content:
					'We believe everyone deserves a chance to grow their business and we are able to help everyone in some type of way regardless of marketing budget. With our Platforms such as BPO Premier Agent Combo, BPO Report Agent, & as a Partner Premier Agent you can start building your business with us.',
				render: false,
			},
			{
				content:
					'Last but not least check out our Deal Analyzer Platform which makes Real Estate investing abreeze for our investing consumers and for our subscribing agents to evaluate Real Estate investment deals for their clients.Thanks for visiting our site!The BPO Homes Team',
				render: false,
			},
			{
				content: '<b style="font-weight:400">Thanks for visiting our site!</b>',
				render: true,
			},
			{
				content: '<b style="font-weight:400">The BPO Homes Team</b>',
				render: true,
			},
		],
	},
];

function AboutUs() {
	const classes = useStyles();
	return (
		<Box className={classes.root}>
			<Typography variant='h1' fontSize='35px' textAlign='center' fontWeight='700' mb='2rem'>
				About Us
			</Typography>

			{headers.map((header, index) => (
				<React.Fragment key={index}>
					{header.name && (
						<Typography variant='h2' fontSize='20px' fontWeight='700' mb='1rem' mt='1rem'>
							{header.name}
						</Typography>
					)}
					{header.subs.map((sub, index) =>
						sub.render ? (
							<Typography key={index} paragraph className={classes.content} dangerouslySetInnerHTML={{ __html: sub.content }}></Typography>
						) : (
							<Typography key={index} paragraph className={classes.content}>
								{sub.content}
							</Typography>
						)
					)}
				</React.Fragment>
			))}
		</Box>
	);
}

export default AboutUs;
