import React, { useEffect, useRef } from "react";
import {
  Box,
  OutlinedInput,
  InputAdornment,
  Typography,
  FormControl,
} from "@mui/material";
import useTheme from "@mui/material/styles/useTheme";
import { greyout } from "../utils/greyout";
const useStyles = (_theme) => {
  return {
    inputs: {
      display: "flex",
      justifyContent: "space-between",
    },
    inputContainer: {
      width: "47%",
    },
    result:{
        marginTop:"5px"
    }
  };
};


function CapCalculator({resultData}) {
  const theme = useTheme();
  const scrollRef = useRef()
  const styles = useStyles(theme);
  useEffect(()=>{
    scrollRef.current.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"})
  },[])
  return (
    <Box>
      {/* <Box sx={styles.inputs} >
        {capRateStaticData.map((element) => (
          <Box sx={styles.inputContainer}>
            <Box sx={{marginBottom:"10px"}}>
              <StyledLabel label={element.label} />
              <HtmlToolTip content={{text:element?.toolTipText}}/>
            </Box>
            <FormControl sx={{ width: "100%" }}>
              <OutlinedInput
                fullWidth
                name={element.variableName}
                // onChange={handleChange}
                placeholder="0"
                margin="normal"
                inputProps={{ style: { fontSize: "1rem" } }}
                type="number"
                startAdornment={
                  <InputAdornment position="start">
                    {" "}
                    <Typography variant="adornment" color="secondary">
                      {" "}
                      $
                    </Typography>
                  </InputAdornment>
                }
              />
            </FormControl>
          </Box>
        ))}

      </Box> */}

      <Box sx={styles.result}>
        {/* <Box sx={{marginBottom:"10px"}}>
        <StyledLabel label = 'Cap Rate'/>
        </Box> */}
        <FormControl sx={{ width:"100%", minWidth:"18.75rem"}}>
              <OutlinedInput
                fullWidth
                name='cap_rate'
                // onChange={handleChange}
                placeholder="0"
                value = {resultData?.capitalization_rate?.cap_rate}
                margin="normal"
                inputProps={{ style: { fontSize: "1rem" } }}
                sx={{...greyout}}
                endAdornment={
                  <InputAdornment position="start">
                    {" "}
                    <Typography variant="adornment" color="secondary">
                      {" "}
                      %
                    </Typography>
                  </InputAdornment>
                }
              />
            </FormControl>
        </Box>
        <div ref = {scrollRef}/>
    </Box>
  );
}

export default CapCalculator;


