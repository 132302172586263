
import * as reportTypes from "../../actions/dealAnalyzer/dealAnalyzerReportTypes";

const initialState = {
    allReports: [],
    uncompletedReports:[],
    completedReports:[]
  };
  
  const dealReportsReducer = (
    state = initialState,
    { type, payload }
  ) => {
    switch (type) {
        case reportTypes.UPDATE_ALL_REPORTS : 
        return {
            ...state,allReports:[...payload]
        }
        case reportTypes.UPDATE_UNCOMPLETED_REPORTS : 
        return {
            ...state,uncompletedReports:[...payload]
        }
        case reportTypes.UPDATE_COMPLETED_REPORTS : 
        return {
            ...state,completedReports:[...payload]
        }
        default: return state

    }
}

export default dealReportsReducer;
