import { Typography, Box, Button, TableContainer, Modal } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "@mui/material/styles";
import CompTable from "./CompTable";
import CompCheckTableItem from "./CompCheckTableItem";
import CustomComp from "./CustomComp";
import { CloseOutlined } from "@mui/icons-material";
import useFetchComps from "../../../hooks/useFetchComps";
import { ClipLoader } from "react-spinners";
import useCustomComps from "./compsHooks/useCustomComps";
import EnhancedRadioButtonGroup from "../../../atomicComponents/EnhancedRadioButtonGroup";
import ZillowComps from "./ZillowComps";
import useZillowComps from "../zillowComps/useZillowComps";
import ZillowCompCheckTableItem from "../zillowComps/ZillowCompsCheckTable";
import { updateEstimatedResaleValue } from "../../../../../actions/dealAnalyzer";
import { useEffect } from "react";

const useStyles = (theme) => {
  return {
    root: {
      height: "45rem",
      margin: "-2.125rem",
      overflow: "scroll",
      display: "block",

      // height:"90vh",
      // position:"absolute"
    },
    header: {
      color: theme.palette.primary.main,
      display: "flex",
      alignItems: "center",
      padding: "1.875rem 0",
      boxShadow: "0px 5px 10px #888888",
    },
    title: {
      width: "95%",
      textAlign: "center",
    },
    closeIcon: {
      width: "5%",
      cursor: "pointer",
    },
    selected: {
      margin: "1rem 5rem",
    },
    buttons: {
      display: "flex",
      justifyContent: "flex-end",
      width: "100%",
    },
    button: {
      textTransform: "none",
      marginLeft: "1.25rem",
      padding: "0.9375rem 1.25rem",
      marginTop: "1rem",
    },
    topTableCellsheader: {
      display: "flex",
      alignItems: "center",
      width: "100%",
      margin: "2.5rem 0 -0.625rem 0",
    },
    check: {
      width: "6%",
    },
    address: {
      width: "53%",
      cursor: "pointer",
    },
    date: {
      width: "39%",
      cursor: "pointer",
    },
    modal: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "75rem",
      bgcolor: "background.paper",
      boxShadow: 24,
      p: 4,
      "&:focus": {
        outline: 0,
      },
    },
  };
};

function CompsMainCard({
  handleMainClose,
  compSearchData,
  ownerData,
  handler,
}) {
  const appState = useSelector((state) => state.straightAestheticRemodel);
  const address = appState?.propertyInformation?.property_address;
  const splitAddress = address ? address.split(",") : "";
  const [open, setOpen] = React.useState(false);
  const [openZillow, setOpenZillow] = React.useState(false);
  const [selectedComps, setSelectedComps] = React.useState([]);
  const { savedCustomComps, deleteCustomComp } = useCustomComps();
  const zillowData = useZillowComps();
  const analyzerData = useSelector((state) => state.straightAestheticRemodel);
  const savedZillowComps = zillowData?.savedCustomComps;
  const deleteZilowComps = zillowData?.deleteCustomComp;
  const { submitZillowComps, deleteZillowComp } = useZillowComps();
  const calculateAverageZillowComps = zillowData?.calculateAverageZillowComps;

  const [compsToApply, setCompsToApply] = React.useState(0);

  // use to open comps
  const handleClickOpen = () => {
    setCompsToApply(0);
    setOpen(true);
  };

  const handleClickOpenZillow = () => {
    setCompsToApply(1);
    setOpenZillow(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseZillow = () => {
    setOpenZillow(false);
  };

  // used for adding new comps
  const handleAddComps = (value) => {
    if (selectedComps.includes(value)) {
      setSelectedComps((prev) => prev.filter((element) => element !== value));
    } else {
      setSelectedComps((prev) => [...prev, value]);
    }
  };

  const { data, loading, sendSelectedComps, loadingSelectedComps, fetchData } =
    useFetchComps();

  const handleCompsToApply = (value) => {
    setCompsToApply(value);
  };

  const addressStub = splitAddress
    ? splitAddress
        .slice(splitAddress.length - 2, splitAddress.length_1)
        .join(",")
    : "";

  const dispatch = useDispatch();
  //applies comp based selected comp
  useEffect(() => {
    dispatch(updateEstimatedResaleValue({ compsToApply }));
    // recheck dependencies if effect is updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [compsToApply]);
  const savedZillowCompsFromServer =
    analyzerData?.dealAnalyzerResults?.zillowComps;
  const savedZillowCompsIds =
    savedZillowCompsFromServer &&
    savedZillowCompsFromServer.map((item) => item.id);

  const useSelectedComps = async () => {
    if (+compsToApply === 1) {
      calculateAverageZillowComps();
      handleMainClose();
      if (savedZillowCompsIds.length > 0) {
        await deleteZillowComp(savedZillowCompsIds);
        submitZillowComps();
      } else {
        submitZillowComps();
      }
    } else {
      handler({ ids: selectedComps });
      sendSelectedComps({
        pl: selectedComps,
        url: "straight_model",
        id: ownerData?.requestId,
        type: appState?.dealAnalyzerReportId?.analyzerTypeId,
        comps_option: savedCustomComps?.length ? +compsToApply + 1 : undefined,
        closeWindow: handleMainClose,
      });
    }
  };

  React.useEffect(() => {
    const onLoad = async () => {
      await fetchData({ ...compSearchData });
    };
    onLoad();
    // NOTE: Run effect once on component mount, please
    // recheck dependencies if effect is updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const theme = useTheme();
  const styles = useStyles(theme);
  return (
    <Box sx={styles.root}>
      <Box sx={styles.header}>
        <Box sx={styles.title}>
          <Typography variant="dealAnalyzerHeader" color="primary">
            {address}
          </Typography>
        </Box>
        <Box sx={styles.closeIcon} onClick={handleMainClose}>
          <CloseOutlined sx={{ fontSize: "2.5rem" }} />
        </Box>
      </Box>
      <Box sx={styles.selected}>
        <CompTable headers={compTableHeaders} owners={[ownerData]} />
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          {(!!savedCustomComps?.length || !!savedZillowComps?.length) && (
            <Box sx={{ width: "100%", mt: 2 }}>
              <Typography
                variant="h4"
                sx={{
                  fontWeight: 600,
                }}
              >
                Comps to Use
              </Typography>
              <EnhancedRadioButtonGroup
                options={radioOptions}
                radioOverride={{ display: "flex" }}
                styles={{ display: "flex", width: "100%" }}
                handler={handleCompsToApply}
                choice={compsToApply}
              />
            </Box>
          )}
          <Box sx={{ width: "100%" }}>
            <Box sx={styles.buttons}>
              <Button
                variant="outlined"
                sx={styles.button}
                onClick={handleClickOpen}
              >
                <Typography variant="buttonFont">Add Custom Comps</Typography>
              </Button>
              <Button
                variant="contained"
                sx={styles.button}
                onClick={handleClickOpenZillow}
              >
                {!loadingSelectedComps ? (
                  <Typography variant="buttonFont">
                    Add Comps from Zillow
                  </Typography>
                ) : (
                  <Box sx={{ margin: "-0.625rem 4.0625rem" }}>
                    <ClipLoader color={"#fff"} loading={true} size={14} />
                  </Box>
                )}
              </Button>
            </Box>
          </Box>
        </Box>
        {(savedCustomComps?.length && +compsToApply === 0) ||
        (savedCustomComps?.length && +compsToApply === 3) ? (
          <>
            <Box>
              <Typography
                variant="h4"
                sx={{
                  textAlign: "center",
                  mt: 4,
                  textDecoration: "underline",
                  fontWeight: 600,
                }}
              >
                User Custom Comps
              </Typography>
            </Box>

            <Box
              sx={{
                maxWidth: "content",
                overflowY: "auto",
                mt: 2,
                marginBottom: compsToApply === 0 ? "10vh" : "5vh",
              }}
            >
              <TableContainer component={"paper"} sx={{ maxWidth: "content" }}>
                {savedCustomComps
                  .map((element) => ({ ...element, new: false }))
                  .map((listItem) => (
                    <CompCheckTableItem
                      list={listItem}
                      checked={selectedComps.includes(listItem?.id)}
                      handleAddComps={handleAddComps}
                      noCheckbox
                      isCustomComps
                      fill
                      deleteCustomComp={deleteCustomComp}
                    />
                  ))}
              </TableContainer>
            </Box>
          </>
        ) : (
          ""
        )}
        {(savedZillowComps?.length && +compsToApply === 1) ||
        (savedZillowComps?.length && +compsToApply === 3) ? (
          <>
            <Box>
              <Typography
                variant="h4"
                sx={{
                  textAlign: "center",
                  mt: 4,
                  textDecoration: "underline",
                  fontWeight: 600,
                }}
              >
                User Zillow Comps
              </Typography>
            </Box>

            <Box
              sx={{
                maxWidth: "content",
                overflowY: "auto",
                mt: 2,
                marginBottom: compsToApply === 0 ? "10vh" : "5vh",
              }}
            >
              <TableContainer component={"paper"} sx={{ maxWidth: "content" }}>
                {savedZillowComps
                  .map((element) => ({ ...element, new: false }))
                  .map((listItem) => (
                    <ZillowCompCheckTableItem
                      list={listItem}
                      checked={selectedComps.includes(listItem?.id)}
                      handleAddComps={handleAddComps}
                      noCheckbox
                      isCustomComps
                      fill
                      deleteCustomComp={deleteZilowComps}
                    />
                  ))}
              </TableContainer>
            </Box>
          </>
        ) : (
          ""
        )}
        {((!savedCustomComps?.length && !savedZillowComps?.length) ||
          +compsToApply === 2 ||
          +compsToApply === 3) && (
          <Box>
            <Box>
              <Typography
                variant="h4"
                sx={{
                  textAlign: "center",
                  mt: 4,
                  mb: -1,
                  textDecoration: "underline",
                  fontWeight: 600,
                }}
              >
                BPO Generated Comps
              </Typography>
            </Box>
            <Box sx={styles.topTableCellsheader}>
              <Box sx={styles.check}></Box>
              <Box sx={styles.address}>
                <Typography variant="tableHeader">{`ADDRESS ( in ${addressStub})`}</Typography>
              </Box>
              <Box sx={styles.date}>
                <Typography variant="tableHeader">CLOSE DATE</Typography>
              </Box>
            </Box>
            {!loading && data?.length ? (
              <Box
                sx={{
                  maxWidth: "content",
                  height: "20vh",
                  overflowY: "auto",
                  mt: 2,
                  // marginBottom:"50vh"
                }}
              >
                <TableContainer
                  component={"paper"}
                  sx={{ maxWidth: "content" }}
                >
                  {data.map((listItem) => (
                    <CompCheckTableItem
                      list={listItem}
                      checked={selectedComps.includes(listItem?.id)}
                      handleAddComps={handleAddComps}
                    />
                  ))}
                </TableContainer>
              </Box>
            ) : (
              ""
            )}
            {!loading && !data?.length ? (
              <Box
                sx={{
                  display: "flex",
                  marginTop: "10vh",
                  marginBottom: "10vh",
                  justifyContent: "center",
                }}
              >
                <Typography variant="noResult">
                  No Comps Found for Address : <b>{compSearchData?.address}</b>
                </Typography>
              </Box>
            ) : (
              ""
            )}

            {loading && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  paddingTop: "10vh",
                  marginBottom: "10vh",
                }}
              >
                <ClipLoader color={"#1378A5"} loading={true} size={24} />
              </Box>
            )}
          </Box>
        )}
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        width="34.375rem"
        sx={{
          padding: "0",
          "&:focus": {
            outline: 0,
          },
        }}
        fullwidth={true}
      >
        <Box sx={styles.modal}>
          <CustomComp
            handleClose={handleClose}
            handleAddComps={handleAddComps}
          />
        </Box>
      </Modal>
      <Modal
        open={openZillow}
        onClose={handleCloseZillow}
        width="34.375rem"
        sx={{
          padding: "0",
          "&:focus": {
            outline: 0,
          },
        }}
        fullwidth={true}
      >
        <Box sx={styles.modal}>
          <ZillowComps
            handleClose={handleCloseZillow}
            handleAddComps={handleAddComps}
          />
        </Box>
      </Modal>
      <Box sx={{ width: "95%", marginBottom: "2rem" }}>
        <Box sx={styles.buttons}>
          <Button
            variant="contained"
            sx={styles.button}
            onClick={useSelectedComps}
          >
            {!loadingSelectedComps ? (
              <Typography variant="buttonFont">Use Selected Comps</Typography>
            ) : (
              <Box sx={{ margin: "-0.625rem 4.0625rem" }}>
                <ClipLoader color={"#fff"} loading={true} size={14} />
              </Box>
            )}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default CompsMainCard;

const compTableHeaders = [
  "Subject Property",
  "Beds",
  "Baths",
  "Year Built",
  "SqFt",
  "Lot Size",
];

const radioOptions = [
  "Custom Comps",
  "Zillow Comps",
  // 'BPO Comps',
  // 'BPO & Custom'
];
