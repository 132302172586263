import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Button, Card, Divider, Radio, Skeleton, Stack, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/system';
import { Elements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React, { useEffect, useState } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import base from '../../apis';
import Checkbox from '../../components/Checkbox/Checkbox';
import Spinner from '../../components/Spinner/Spinner';
// import { farmPackages as packages } from '../../constants';
import Footer from '../../components/Footer/Footer';
import BPOLogo from '../../images/bpoVerticalLogo.webp';
import { formatNumber } from '../../utils/functions';
import '../ChoosePackage/choosepackage.scss';
import '../ChooseSubscription/choosesubscription.scss';
import './bposub.scss';
import { useSelector } from 'react-redux';

export const packages = [
	{
		title: 'Premier Agent Basic (SETUP FEE OF $99 NOT INCLUDED)',
		benefits: [
			`NOD Notice of Default Pre Foreclosure Leads Unlimited`,
			`Divorce & Probate Leads Unlimited`,
			`For Sale By Owner FSBO Leads Unlimited`,
			`Expired,Canceled & Withdrawn Leads Unlimited`,
			`Luxury Investor Website`,
			`Deal Analyzer Access`,
			`BPO Report Ordering Page`,
			`Unlimited BPO Tech Training Center Access`,
			`Investor Log (Investor List Purchase Available)  `,
			`20% Referral Fee Required From BPO Homes Pre Qualified Leads`,
		],
	},
	{
		title: 'Premier Agent (SET UP FEE NOT INCLUDED $99)',
		benefits: [
			`Marketing to 2000 Sellers`,
			`Seller Calls Performed Quarterly`,
			`Seller Texts Performed Quarterly`,
			`Seller Emails Performed Monthly (Marketing Designs Included)`,
			`Premier Agent Advertising`,
			`NOD (Notice of Default / Pre-Foreclosure) Leads Unlimited`,
			`Divorce Leads Unlimited`,
			`Probate Leads Unlimited`,
			`FSBO (For Sale By Owner) Leads Unlimited`,
			`Expired Leads Unlimited`,
			`Canceled Leads Unlimited`,
			`Withdrawn Leads Unlimited`,
			`Deal Analyzer Access`,
			`Unlimited BPO Tech Training Center Access`,
			`BPO Report Ordering Page`,
			`Luxury Investor Website`,
			`Investor Log (Investor List Available for Purchase)`,
			`MDA (Million Dollar Agent) Business Program`,
			`20% Referral Fee Required from BPO Homes Pre-Qualified Leads`
		],
	},
];

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const useStyles = makeStyles((theme) => ({
	card: {
		padding: '20px',
	},
	titleText: {
		// fontSize: '1.5rem',
		fontWeight: 'bold',
		color: theme.secondaryColor,
		textTransform: 'uppercase',
		textAlign: 'center',
		display: 'block',
	},
	divider: {
		background: theme.secondaryColor,
		height: '2px',
		width: '5rem',
		margin: '10px auto ',
		display: 'block',
		border: 'none !important',
	},
	checkbox: {
		color: '#1378a5',
		fontSize: 'small',
	},
}));

function PremPay() {
	const classes = useStyles();
	const navigate = useNavigate();
	const [farmPrices, setFarmPrices] = useState({
		base_setup_price_id: '',
		addon_setup_price_id: '',
		addon_setup_price_amount: '',
		standard: {
			monthly: {
				amount: '',
				priceId: '',
			},
			yearly: {
				amount: '',
				priceId: '',
			},
		},
		pro: {
			monthly: {
				amount: '',
				priceId: '',
			},
			yearly: {
				amount: '',
				priceId: '',
			},
		},
	});

	const isClient = useSelector((state) => state.auth.role === 'client');

	useEffect(() => {
		setIsLoading(true);
		const controller = new AbortController();
		if (isClient) {
			toast.warn('Clients not allowed');
			navigate('/client-dashboard');
			return;
		}

		base
			.get('/register/list_prices/', {
				signal: controller.signal,
			})
			.then((res) => {
				if (!res) return;
				const out = res?.data?.flat();
				//add product=bpo_combo_farm to metadata
				// farmProducts1 only gets premier agent from farm subscriptions 
				const farmProducts1 = out?.filter((item) => item.metadata?.product === 'premier_farm_agent' && item.active);
				const farmProducts = out?.filter((item) => item.metadata?.product === 'premier_agent_website' && item.active);
				const standardMonthly = farmProducts.find((item) => item.metadata?.package_grade === 'basic' && item.recurring?.interval === 'month');
				const proMonthly = farmProducts1.find((item) => item.metadata?.package_grade === 'basic' && item.recurring?.interval === 'month');
				const standardYearly = farmProducts.find((item) => item.metadata?.package_grade === 'basic' && item.recurring?.interval === 'year');
				const proYearly = farmProducts1.find((item) => item.metadata?.package_grade === 'basic' && item.recurring?.interval === 'year');
				const baseSetup = out.find((item) => item.metadata?.package === 'setup_fee');
				// const addOnSetup = out.find((item) => item.metadata?.product === 'farm_addon_setup');

				const toSet = {
					base_setup_price_id: baseSetup?.id,
					base_setup_price_amount: formatNumber(baseSetup?.unit_amount / 100),

					standard: {
						monthly: {
							amount: formatNumber(standardMonthly?.unit_amount / 100),
							priceId: standardMonthly?.id,
						},
						yearly: {
							amount: formatNumber(standardYearly?.unit_amount / 100),
							priceId: standardYearly?.id,
						},
					},
					pro: {
						monthly: {
							amount: formatNumber(proMonthly?.unit_amount / 100),
							priceId: proMonthly?.id,
						},
						yearly: {
							amount: formatNumber(proYearly?.unit_amount / 100),
							priceId: proYearly?.id,
						},
					},
				};
				setFarmPrices(toSet);
			})
			.catch((err) => {
				toast.error('Could not get prices');
			})
			.finally(() => {
				setIsLoading(false);
			});

		return () => {
			controller.abort();
		};
	}, [navigate, isClient]);

	const [isLoading, setIsLoading] = useState(false);

	const [isMonthly, setIsMonthly] = useState(true);

	const [selectedPackages, setSelectedPackages] = useState({
		0: '',
		1: '',
		2: '',
		3: '',
	});

	const handleSelected = (index, id) => {
		setSelectedPackages((current) => ({ ...current, [index]: id }));
	};

	const [termsAgreed, setTermsAgreed] = useState(false);
	const [termsAgreed1, setTermsAgreed1] = useState(false);

	const handleIsMonthly = (e, value) => {
		setIsMonthly(value);
		setSelectedPackages({
			0: '',
			1: '',
			2: '',
		});
	};

	if (isLoading) {
		return (
			<Stack direction='row' gap='1rem' justifyContent={'center'} p='1rem'>
				{[1, 2].map((item) => (
					<Skeleton key={item} variant='rectangular' width={'20vw'} height={'90vh'}></Skeleton>
				))}
			</Stack>
		);
	}

	return (
		<>
			<Elements stripe={stripePromise}>
				{isLoading && <Spinner />}
				<div className='choose-package_header'>
					<Link to={'/agent-dashboard'}>
						<img alt='Logo' src={BPOLogo} />
					</Link>
					<div style={{ textAlign: 'right' }}>
						<p>Call Sales: 1.888.616.5270</p>
						<p>9:00 AM to 6:30 PM PST, Monday to Friday</p>
					</div>
				</div>

				{[0].map((territory, index) => (
					<Stack gap='1rem' key={index}>
						<Box display={'flex'} justifyContent='center' gap='20px' alignItems='center' p='1rem'>
							<ToggleButtonGroup size='small' color='secondary' value={isMonthly} exclusive onChange={handleIsMonthly}>
								<ToggleButton value={true}>Monthly</ToggleButton>
								<ToggleButton value={false}>Annual</ToggleButton>
							</ToggleButtonGroup>
						</Box>
						<Stack direction='row' p='1rem' gap='1rem' justifyContent='center'>
							{packages.map((item, packageIndex) => {
								let value;
								switch (packageIndex) {
									case 0:
										value = 'standard';
										break;
									case 1:
										value = 'pro';

										break;

									default:
										break;
								}
								const price =
									farmPrices?.[value][isMonthly ? 'monthly' : 'yearly']?.amount || farmPrices?.[value][isMonthly ? 'monthly' : 'yearly']?.amount;
								const id = farmPrices?.[value][isMonthly ? 'monthly' : 'yearly']?.priceId;
								return (
									<Card
										key={item.title}
										id={id}
										sx={{
											borderColor: selectedPackages[index] === id ? 'var(--primary-color)' : 'white',
											borderWidth: '1px',
											borderStyle: 'solid',
											cursor: 'pointer',
										}}
										className={classes.card}
										onClick={() => handleSelected(index, id)}
									>
										<Typography paragraph gutterBottom className={classes.titleText}>
											{item.title}
										</Typography>
										<Divider className={classes.divider} />
										<Box display='flex' gap='20px' alignItems='center' width='fit-content' mx='auto' mb='30px !important'>
											<Radio checked={selectedPackages[index] === id} size={'small'} value={id} name='radio-buttons' color='secondary' />
											<Typography display='flex' gap='10px'>
												<Typography variant='span' sx={{ fontWeight: 'bolder', fontSize: '20px' }}>
													$ {price}
												</Typography>
												per {isMonthly ? 'month' : 'year'}
											</Typography>
										</Box>
										{item.benefits.map((benefit, benIndex) => (
											<Box display='flex' alignItems='center' gap='10px' key={benefit} mb={'20px'}>
												<CheckCircleOutlineIcon className={classes.checkbox} />
												<Typography fontSize='15px' fontWeight={packageIndex && benIndex === 0 ? '700' : ''}>
													{benefit}
												</Typography>
											</Box>
										))}
									</Card>
								);
							})}
						</Stack>
					</Stack>
				))}
				<Box display='flex' justifyContent='center'>
					<Box display='flex' alignItems='center' flexDirection='column' mt='2rem' sx={{ maxWidth: '85%' }} gap='1rem'>
						<Checkbox
							text='By checking this you have agreed to the terms and conditions'
							checked={termsAgreed}
							setChecked={(e) => setTermsAgreed(e.target.checked)}
							labelStyle={{ marginRight: 'auto' }}
						/>
						<div>
							<Checkbox
								labelStyle={{ marginRight: 'auto', display: 'inline' }}
								checked={termsAgreed1}
								setChecked={(e) => setTermsAgreed1(e.target.checked)}
								text={`By providing my phone number to “BPO Homes”, I agree and acknowledge that “BPO Homes ” may send text messages to my wireless phone number for any purpose. Message and data rates may apply. Message frequency will vary, and you will be able to Opt-out by replying “STOP”. For more information on how your data will be handled please visit `}
							/>
							<NavLink to='/privacy-policy' className='blue_link'>
								https://bpotech.io/privacy-policy
							</NavLink>
						</div>
						<p style={{ margin: 'auto' }}>
							By placing the order you agree with our{' '}
							<NavLink to={`/terms-of-service`} className='blue_link'>
								Terms & Conditions
							</NavLink>{' '}
							and{' '}
							<NavLink to='/privacy-policy' className='blue_link'>
								Privacy Policy
							</NavLink>
						</p>
						<StripePay
							base_setup_price_id={farmPrices?.base_setup_price_id}
							isMonthly={isMonthly}
							isChecked={termsAgreed && termsAgreed1}
							selectedPackage={selectedPackages['0']}
						/>
					</Box>
				</Box>
				<Footer />
			</Elements>
		</>
	);
}

export default PremPay;

const StripePay = ({ base_setup_price_id, isMonthly, isChecked, selectedPackage }) => {
	const stripe = useStripe();

	const confirmPayment = () => {
		if (!isChecked) {
			toast.warn('Please agree to the terms and conditions');
			return;
		}
		if (!selectedPackage) {
			toast.warn('Please select a package');
			return;
		}
		const body = {
			interval: isMonthly ? 'monthly' : 'yearly',
			price_data: [
				{
					price: base_setup_price_id,
					quantity: 1,
				},
				{
					price: selectedPackage,
					quantity: 1,
				},
			],
		};

		base
			.post('/register/create_subscription/', {
				...body,
				cancel_url: window.location.href,
				success_url: window.location.origin + `/payment_success?type=premier-agent-website&`,
				payment_method_types: ['card'],
				mode: 'subscription',
				package: 'premier-agent-website',
			})
			.then((res) => {
				if (!res?.data?.sessionId) {
					toast.error('Something went wrong');
					return;
				}
				stripe.redirectToCheckout({
					sessionId: res?.data?.sessionId,
				});
			})
			.catch((err) => {
				toast.error('Could not process payment');
			});
	};

	return (
		<Button onClick={confirmPayment} variant='contained' color='secondary' mb='1rem'>
			Confirm payment
		</Button>
	);
};
