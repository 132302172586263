import React, { useEffect, useRef } from "react";
import {
  Box,
  OutlinedInput,
  InputAdornment,
  Typography,
  FormControl,
  Button,
} from "@mui/material";
import useTheme from "@mui/material/styles/useTheme";
import StyledLabel from "./StyledLabel";

import { greyout } from "../utils/greyout";
import commaSeparate from "../utils/commaSeparate";
import { useSelector } from "react-redux";

const useStyles = (_theme) => {
  return {
    inputs: {
      display: "flex",
      justifyContent: "space-between",
    },
    inputContainer: {
      width: "40%",
    },
    inputContainer2: {
      width: "50%",
    },
    result: {
      marginTop: "1.875rem",
    },
  };
};

function CompAverage({ estimates, zillowEstimate }) {
  const theme = useTheme();
  const styles = useStyles(theme);
  const scrollRef = useRef(null);
  useEffect(() => {
    scrollRef.current.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  }, []);

  const analyzerData = useSelector((state) => state.straightAestheticRemodel);
  const compsToApply = +analyzerData?.estimatedResaleValue?.compsToApply;

  const averageSquareFootage =
    compsToApply === 1
      ? zillowEstimate?.averageAveragePricePerSquareFootage
      : estimates?.average_square_footage;
  const averagePricePerSquareFootage =
    compsToApply === 1
      ? zillowEstimate?.averageAveragePricePerSquareFootage
      : estimates?.average_price_per_sqft;
  const averageLotSize =
    compsToApply === 1
      ? zillowEstimate?.averageLotSize
      : estimates?.average_lot_size;
  const averagePurchasePrice =
    compsToApply === 1
      ? zillowEstimate?.averageTotalPrice
      : estimates?.average_purchase_price;

  //this component displays the average square footage calculated
  return (
    <Box>
      <Box sx={styles.inputs}>
        <Box sx={styles.inputContainer}>
          <Box sx={{ marginBottom: "10px" }}>
            <StyledLabel label="Average Square Footage" />
          </Box>
          <FormControl sx={{ width: "100%" }}>
            <OutlinedInput
              fullWidth
              // name={element.variableName}
              // onChange={handleChange}
              sx={{ ...greyout }}
              placeholder="0"
              disabled={true}
              margin="normal"
              value={commaSeparate(averageSquareFootage)}
              inputProps={{ style: { fontSize: "1rem" } }}
              endAdornment={
                <InputAdornment position="start">
                  {" "}
                  <Typography variant="adornment" color="secondary">
                    {" "}
                    SqFt
                  </Typography>
                </InputAdornment>
              }
            />
          </FormControl>
        </Box>
        {/* This section displays the average square footage price */}
        <Box sx={styles.inputContainer2}>
          <Box sx={{ marginBottom: "10px" }}>
            <StyledLabel label="Average Price per Square footage" />
          </Box>
          <FormControl sx={{ width: "100%" }}>
            <OutlinedInput
              fullWidth
              // name={element.variableName}
              // onChange={handleChange}
              value={commaSeparate(averagePricePerSquareFootage)}
              placeholder="0"
              margin="normal"
              inputProps={{ style: { fontSize: "1rem" } }}
              sx={{ ...greyout }}
              endAdornment={
                <InputAdornment position="start">
                  {" "}
                  <Typography variant="adornment" color="secondary">
                    {" "}
                    /SqFt
                  </Typography>
                </InputAdornment>
              }
              startAdornment={
                <InputAdornment position="start">
                  {" "}
                  <Typography variant="adornment" color="secondary">
                    {" "}
                    $
                  </Typography>
                </InputAdornment>
              }
            />
          </FormControl>
        </Box>
      </Box>

      {/* This displays the average lot size */}
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box sx={{ ...styles.result, width: "40%" }}>
          <Box sx={{ marginBottom: "10px" }}>
            <StyledLabel label="Average Lot Size" />
          </Box>
          <FormControl sx={{ width: "100%" }}>
            <OutlinedInput
              fullWidth
              name="cap_rate"
              // onChange={handleChange}
              value={commaSeparate(averageLotSize)}
              placeholder="0"
              margin="normal"
              inputProps={{ style: { fontSize: "1rem" } }}
              sx={{ ...greyout }}
              endAdornment={
                <InputAdornment position="start">
                  {" "}
                  <Typography variant="adornment" color="secondary">
                    {" "}
                    SqFt
                  </Typography>
                </InputAdornment>
              }
            />
          </FormControl>
        </Box>

        {/* This displays average purchase price  */}
        <Box sx={{ ...styles.result, width: "50%" }}>
          <Box sx={{ marginBottom: "10px" }}>
            <StyledLabel label="Average Purchase Price" />
          </Box>
          <FormControl sx={{ width: "100%" }}>
            <OutlinedInput
              fullWidth
              name="cap_rate"
              // onChange={handleChange}
              value={commaSeparate(averagePurchasePrice)}
              placeholder="0"
              margin="normal"
              inputProps={{ style: { fontSize: "1rem" } }}
              sx={{ ...greyout }}
              endAdornment={
                <InputAdornment position="start">
                  {" "}
                  <Typography variant="adornment" color="secondary">
                    {" "}
                    SqFt
                  </Typography>
                </InputAdornment>
              }
            />
          </FormControl>
        </Box>
      </Box>
      <Box sx={{ margin: "1.875rem 0" }}>
        <Button
          href="https://bpotech.io/order-bpo-report"
          variant="outlined"
          sx={{ textTransform: "none", padding: "1rem" }}
          target="_blank"
        >
          <Typography variant="h4">
            Get a BPO report on this property
          </Typography>
        </Button>
      </Box>
      <div ref={scrollRef} />
    </Box>
  );
}

export default CompAverage;
