import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	ToggleButton,
	FormLabel,
	Grid,
	IconButton,
	ImageList,
	ImageListItem,
	InputLabel,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Pagination,
	ToggleButtonGroup,
	Typography,
	CircularProgress,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { useState } from 'react';
import { Box } from '@mui/system';
import { grey } from '@mui/material/colors';
import base, { file } from '../../apis';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import Spinner from '../../components/Spinner/Spinner';
import { exteriorList, interiorList } from '../../constants';
import { Circle, Close, Add, Delete } from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({
	root: {
		padding: '20px',
		minHeight: '60vh',
	},
	header: {
		color: theme.secondaryColor,
		fontSize: '18px',
	},
	question: {
		color: grey[500],
		fontSize: '18px',
	},
	answer: {
		fontSize: '16px',
	},
	button: {
		backgroundColor: theme.secondaryColor,
		color: 'white',
		textTransform: 'none',
		width: 'fit-content',
		display: 'block',
		'&:hover': {
			backgroundColor: theme.secondaryColor,
		},
	},
	accSum: {
		boxShadow: ' 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
		borderRadius: '5px',
	},

	toggleButton: {
		border: 'none',
		backgroundColor: 'transparent',
		fontSize: '14px',
		textTransform: 'capitalize',
	},
	toggleButtonGroup: {
		borderBottom: '1px solid grey',
		width: '100%',
		borderRadius: '0',
	},
	headerText: {
		fontSize: '16px',
	},
	listItem: {
		padding: '5px',
		'& #icon': {
			width: '10px',
			height: '10px',
		},
	},
	addPhotos: {
		border: '1px dashed black',
		height: '100px',
		width: '100px',
		borderRadius: '5px',
		alignItems: 'center',
		justifyContent: 'center',
		textAlign: 'center',
		backgroundColor: '#e6e6e6',
		cursor: 'pointer',
		display: 'flex',
		flexDirection: 'column',
	},
	listItemImage: {
		'&:hover #delete': {
			display: 'block',
		},
		'& #delete': {
			position: 'absolute',
			top: '10px',
			right: '10px',
			display: 'none',
			cursor: 'pointer',
			// fill:'white',
			fill: '#d52121',
		},
	},
}));

const corres = {
	Complete: 'completed',
	Pending: 'saved',
	Incomplete: 'requested',
};

/**
 *
 * @param {'Complete'|'Incomplete|Pending'} status
 * @returns
 */
function CustomerReqRep({ status = 'Incomplete' }) {
	const classes = useStyles();
	const [reports, setReports] = useState([]);
	const agent_id = useSelector((state) => state.auth.typeId);
	const [isLoading, setIsLoading] = useState(false);
	const [pageCount, setPageCount] = useState(null);
	const [page, setPage] = useState(1);
	const handlePageChange = (e, page) => {
		setPage(page);
	};
	const [detailsOpen, setDetailsOpen] = useState(false);
	const [selectedReport, setSelectedReport] = useState(null);
	const [toggleSelected, setToggleSelected] = useState('purpose');

	React.useEffect(() => {
		setIsLoading(true);
		setReports([]);
		base
			.get('/leads/report/search', {
				params: {
					agent_id,
					status,
					ordering: '-date_created',
					page,
				},
			})
			.then(({ data }) => {
				setReports(data?.results);
				setPageCount(data?.count);
				if (!data?.results?.length) {
					toast.warn(`No ${corres[status]} reports`);
				}
			})
			.catch((err) => {
				// console.log(err);
				toast.warn('Could not get reports');
			})
			.finally(() => {
				setIsLoading(false);
			});
	}, [status, agent_id, page]);

	const onReportSelect = (report) => {
		setSelectedReport(report);
		setDetailsOpen(true);
	};

	const [uploadLoading, setUploadLoading] = useState(false);

	const extSelector = React.useRef(null);
	const intSelector = React.useRef(null);

	const setImages = async (e) => {
		setUploadLoading(true);
		const { files, name } = e.target;
		let formData = new FormData();
		for (let i = 0; i < files.length; i++) {
			formData.append(name, files[i]);
		}
		try {
			const { data } = await file.patch(`/leads/report/${selectedReport?.id}/`, formData);
			setSelectedReport((prev) => ({ ...prev, ...data }));
			setReports((prev) => {
				const index = prev.findIndex((item) => item.id === data.id);
				if (index !== -1) {
					prev[index] = data;
				}
				return [...prev];
			});
		} catch (error) {
			toast.error('Error uploading images');
		} finally {
			setUploadLoading(false);
		}
	};

	const deletePhotos = async (type, image) => {
		const url = `/leads/${type.split('_')[0]}/${image.id}/`;
		setIsLoading(true);
		try {
			await base.delete(url);
			setSelectedReport((prev) => ({ ...prev, [type]: prev[type].filter((item) => item.id !== image.id) }));
		} catch (error) {
			toast.error('Error deleting image');
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<>
			{isLoading && <Spinner />}
			{/* report details dialog */}
			{/* image upload dialog */}
			<Dialog open={uploadLoading}>
				<DialogContent sx={{ padding: '3rem', width: '20rem', textAlign: 'center' }}>
					<CircularProgress value={30} color='primary' />
					<Typography mt={2}>Uploading Images...</Typography>
				</DialogContent>
			</Dialog>
			<Dialog open={detailsOpen} fullWidth maxWidth='md' onClose={() => setDetailsOpen(false)}>
				<DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
					<Box display='flex' justifyContent='space-between' alignItems='center' marginRight='40px' flex='1' padding={1}>
						<Typography variant='body1'>{selectedReport?.clients_physical_address}</Typography>
						<Typography variant='body2'>Ordered Date:{new Date(selectedReport?.date_created).toDateString()}</Typography>
					</Box>
					<IconButton onClick={() => setDetailsOpen(false)}>
						<Close fontSize='small' />
					</IconButton>
				</DialogTitle>
				<DialogContent sx={{ position: 'relative' }}>
					<ToggleButtonGroup
						color='secondary'
						value={toggleSelected}
						exclusive
						onChange={(e) => setToggleSelected(e.target.value)}
						sx={{ marginBottom: '1rem' }}
						className={classes.toggleButtonGroup}
					>
						<ToggleButton className={classes.toggleButton} value='purpose'>
							Purpose
						</ToggleButton>
						<ToggleButton className={classes.toggleButton} value='info'>
							Property Info
						</ToggleButton>
						<ToggleButton className={classes.toggleButton} value='photos'>
							Property Photos
						</ToggleButton>
					</ToggleButtonGroup>
					<Box sx={{ height: '300px', overflowX: 'auto', display: 'flex', flexDirection: 'column', gap: '1rem' }}>
						{toggleSelected === 'purpose' && (
							<>
								<Typography variant='body1' className={classes.headerText} fontWeight='500'>
									Why would you like a BPO Report?
								</Typography>
								<Typography variant='body1' className={classes.headerText}>
									{selectedReport?.reason_for_request}
								</Typography>
							</>
						)}
						{toggleSelected === 'info' && (
							<>
								<Typography variant='body1' fontWeight='300' fontSize='14px'>
									If you can provide more infomation regarding your property, it will help the agent make a more accurate BPO Report
								</Typography>
								<Grid container spacing={2}>
									<Grid item xs={4}>
										<InputLabel className={classes.headerText} sx={{ color: 'black', fontWeight: '500' }}>
											Gross Living Area (sqft)
										</InputLabel>
										<Typography>{selectedReport?.customer_livable_square_footage || 'N/A'}</Typography>
									</Grid>
									<Grid item xs={4}>
										<InputLabel className={classes.headerText} sx={{ color: 'black', fontWeight: '500' }}>
											Lot Size (sqft)
										</InputLabel>
										<Typography>{selectedReport?.customer_lot_size || ''}</Typography>
									</Grid>
									<Grid item xs={4}>
										<InputLabel className={classes.headerText} sx={{ color: 'black', fontWeight: '500' }}>
											Number of Garages
										</InputLabel>
										<Typography>{selectedReport?.customer_garage || 'N/A'}</Typography>
									</Grid>
								</Grid>
								<FormControl my={1}>
									<FormLabel className={classes.headerText} sx={{ color: 'black', fontWeight: '500' }}>
										Current Marketing Conditions{' '}
									</FormLabel>
									<Typography>{selectedReport?.property_condition || 'N/A'}</Typography>
								</FormControl>
								<Grid container spacing={2} alignItems='center' justifyContent='center'>
									<Grid item xs={6}>
										<InputLabel className={classes.headerText} sx={{ color: 'black', display: 'flex', alignItems: 'center', fontWeight: '500' }}>
											When was the last time the home was remodelled?
										</InputLabel>
									</Grid>
									<Grid item xs={6}>
										<Typography>
											{selectedReport?.last_time_remodeled
												? `${selectedReport?.last_time_remodeled} ${selectedReport?.is_year ? 'years' : 'months'} `
												: 'N/A'}
										</Typography>
									</Grid>
								</Grid>
								<Grid container spacing={2} alignItems='center' justifyContent='center'>
									<Grid item xs={6}>
										<InputLabel className={classes.headerText} sx={{ color: 'black', display: 'flex', alignItems: 'center', fontWeight: '500' }}>
											Is the home a new construction?
										</InputLabel>
									</Grid>
									<Grid item xs={6}>
										<Typography>{typeof selectedReport?.is_home_new === 'boolean' ? (selectedReport?.is_home_new ? 'Yes' : 'No') : 'N/A'}</Typography>
									</Grid>
								</Grid>

								<Box>
									<InputLabel className={classes.headerText} sx={{ color: 'black', fontWeight: '500' }}>
										Defects or nearby nuisances affecting the home?
									</InputLabel>
									<Typography>{selectedReport?.nearby_nuisances || 'N/A'}</Typography>
								</Box>
							</>
						)}
						{toggleSelected === 'photos' && (
							<Box>
								<Typography variant='body1' fontWeight='300' fontSize='14px'>
									If you can provide more infomation regarding your property, it will help the agent make a more accurate BPO Report
								</Typography>
								<Box>
									<Typography variant='body1' className={classes.headerText} fontWeight='500'>
										EXTERIOR PHOTO UPLOADS
									</Typography>
									<Typography variant='body1' className={classes.headerText}>
										Kindly upload the following photos
									</Typography>

									<List sx={{ display: 'flex' }}>
										{exteriorList?.map((item) => (
											<ListItem className={classes.listItem} key={item}>
												<ListItemIcon sx={{ minWidth: 'unset', paddingRight: '5px' }}>
													<Circle color='primary' id='icon' />
												</ListItemIcon>
												<ListItemText>
													<Typography variant='body2' whiteSpace='nowrap'>
														{item}
													</Typography>
												</ListItemText>
											</ListItem>
										))}
									</List>
									<input
										type='file'
										accept='image/png, image/jpeg'
										name='exterior_photos'
										style={{ display: 'none' }}
										ref={extSelector}
										multiple
										onChange={setImages}
									/>
									{selectedReport?.exterior_photos?.length > 0 && (
										<ImageList sx={{ width: '100%', height: '100%', mx: 'auto' }} cols={5} rowHeight={200}>
											{selectedReport?.exterior_photos?.map((item) => (
												<ImageListItem key={item.image} className={classes.listItemImage}>
													<img
														src={`${item.image}`}
														alt={'Property'}
														style={{
															cursor: 'pointer',
															border: '2px solid white',
															borderRadius: '5px',
															aspectRatio: '4/3',
															objectFit: 'contain',
														}}
													/>
													<Delete id='delete' onClick={() => deletePhotos('exterior_photos', item)} />
												</ImageListItem>
											))}
										</ImageList>
									)}
									<Box
										className={classes.addPhotos}
										onClick={() => {
											extSelector.current.click();
										}}
									>
										<Add />
										<p style={{ color: 'grey' }}>Add Photos</p>
									</Box>
								</Box>
								<Box mt={1}>
									<Typography variant='body1' className={classes.headerText} fontWeight='500'>
										INTERIOR PHOTO UPLOADS
									</Typography>
									<Typography variant='body1' className={classes.headerText}>
										Kindly upload the following photos
									</Typography>
									<List sx={{ display: 'flex' }}>
										{interiorList.map((item) => (
											<ListItem className={classes.listItem} key={item}>
												<ListItemIcon sx={{ minWidth: 'unset', paddingRight: '5px' }}>
													<Circle color='primary' id='icon' />
												</ListItemIcon>
												<ListItemText>
													<Typography variant='body2' whiteSpace='nowrap'>
														{item}
													</Typography>
												</ListItemText>
											</ListItem>
										))}
									</List>
									<input
										type='file'
										accept='image/png, image/jpeg'
										name='interior_photos'
										style={{ display: 'none' }}
										ref={intSelector}
										multiple
										onChange={setImages}
									/>
									{selectedReport?.interior_photos?.length > 0 && (
										<ImageList sx={{ width: '100%', height: '100%', mx: 'auto' }} cols={5} rowHeight={200}>
											{selectedReport?.interior_photos.map((item, index) => (
												<ImageListItem key={item.image} className={classes.listItemImage}>
													<img
														src={`${item.image}`}
														alt={'Property'}
														style={{
															cursor: 'pointer',
															border: '2px solid white',
															borderRadius: '5px',
															aspectRatio: '4/3',
															objectFit: 'contain',
														}}
													/>
													<Delete id='delete' onClick={() => deletePhotos('interior_photos', item)} />
												</ImageListItem>
											))}
										</ImageList>
									)}
									<Box
										className={classes.addPhotos}
										onClick={() => {
											intSelector.current.click();
										}}
									>
										<Add />
										<p style={{ color: 'grey' }}>Add Photos</p>
									</Box>
								</Box>
							</Box>
						)}
					</Box>
				</DialogContent>
				<DialogActions sx={{ padding: '1rem 2rem' }}>
					<Box display={'flex'} gap='20px' justifyContent='flex-end'>
						{status !== 'Incomplete' && (
							<Button variant='outlined' color='secondary' sx={{ textTransform: 'capitalize' }} href={`/create-bpo-report/${selectedReport?.id}`}>
								Edit Report
							</Button>
						)}
						<Button
							variant='contained'
							className={classes.button}
							href={status === 'Incomplete' ? `/create-bpo-report/${selectedReport?.id}` : `/preview-report/${selectedReport?.id}`}
						>
							{status === 'Incomplete' ? 'Create Report' : 'View Report'}
						</Button>
					</Box>
				</DialogActions>
			</Dialog>
			<div className={classes.root}>
				{reports.map((report) => (
					<Accordion elevation={0} key={report.id}>
						<AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.accSum}>
							<Grid container spacing={2}>
								<Grid item xs={4} className={classes.header}>
									Name
								</Grid>
								<Grid item xs={4} className={classes.header}>
									Email
								</Grid>
								<Grid item xs={4} className={classes.header}>
									Date Requested
								</Grid>
								<Grid item xs={4}>
									{report.client_register?.user?.first_name} {report.client_register?.user?.last_name}
								</Grid>
								<Grid item xs={4}>
									{report.client_register?.user?.email}
								</Grid>
								<Grid item xs={4}>
									{new Date(report.date_created).toDateString()}
								</Grid>
							</Grid>
						</AccordionSummary>
						<AccordionDetails>
							<Box>
								<Typography className={classes.question}>Full Property Address</Typography>
								<Typography className={classes.answer}>{report.clients_physical_address}</Typography>
								<Typography className={classes.question} sx={{ mt: '10px' }}>
									Why would you like a BPO Report?
								</Typography>
								<Typography className={classes.answer}>{report.reason_for_request}</Typography>
								<Box display={'flex'} gap='20px' justifyContent='flex-end'>
									<Button variant='contained' className={classes.button} onClick={() => onReportSelect(report)}>
										View Details
									</Button>
								</Box>
							</Box>
						</AccordionDetails>
					</Accordion>
				))}
				<Pagination
					size='large'
					color='secondary'
					sx={{
						display: 'flex',
						justifyContent: 'center',
						marginTop: '2rem',
					}}
					shape='rounded'
					variant='outlined'
					count={Math.ceil(pageCount / 6)}
					onChange={handlePageChange}
				/>
			</div>
		</>
	);
}

export default CustomerReqRep;
