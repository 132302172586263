import { Box,Typography} from '@mui/material'
import React from 'react'
import EnhancedLoanAmount from '../atomicComponents/EnhancedLoanAmount'
import FlatRatePercentageInput from '../atomicComponents/FlatRatePercentageInput'


function QuestionnairePrimaryLoanAmount({handler,data, extraComponentData}) {

    const dealFinanceOption = data?.deal_finance_option || 0
    const header = extraComponentData.primaryHeaders?.[dealFinanceOption]

    const rateHandler = (value) =>{
        handler({primary_down_payment_dollar_or_percent:value})
    }
  return (
    <Box sx={{width:"50%",minWith:"18.75rem"}}>
        <Box><Typography variant="heading" sx={{color:"#1378A5"}}>{header}</Typography></Box>
        <Box>
            <EnhancedLoanAmount
            name='primary_loan_amount'
            data = {data}
            label='What is your Loan Amount?'
            noToolTip={true}
            handler={handler}
            overrideStyle = {{            
            minWidth: "25rem",
            marginTop: "10px",
            marginRight:"5px"}}
            />
        </Box>
        <Box sx={{marginTop:"1.5625rem"}}>
            <FlatRatePercentageInput
            name='primary_down_payment'
            title='What is your Down Payment Amount?'
            noToolTip={true}
            data={data}
            rate = {+data?.primary_down_payment_dollar_or_percent || 0}
            handler = {handler}
            rateHandler={rateHandler}   
            referenceValue={data?.primary_loan_amount} 
            />         
        </Box>
    </Box>
  )
}

export default QuestionnairePrimaryLoanAmount