import { makeStyles } from '@mui/styles'
import React, { useEffect, useState } from 'react'
import RadioInputItem from '../helpers/RadioInputItem'
import { Button, Dialog, DialogActions, DialogContent, Divider, TextField, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'
import BottomActions from '../helpers/BottomActions'
import FormInputItem from '../helpers/FormInputItem'
import { toast } from 'react-toastify'


const useStyles = makeStyles(theme => ({
    label: {
        fontSize: '.9rem',
        color: grey[600],
        marginBottom: '.5rem'
    },
    btn: {
        textTransform: 'none',
        borderRadius: '8px'
    }
}))


const StageFour = ({ data, dispatch, exit, saveForm, load, contingency }) => {
    const classes = useStyles()
    const [value, setValue] = useState({ type: 'ctgs', name: '', days: '', startDate: '', notes: '', read: false })
    const [open, setOpen] = useState(false)

    useEffect(()=>{
        dispatch({type: 'CONTINGENCY', contingency})
        // eslint-disable-next-line
    },[])

    const onFormSubmit = (val) => {
        if(val === 'continue'){
            if (data?.contingents?.length === 0) {
                toast.error('Please add a contingent', { theme: 'colored' })
                return
            }if (data?.contingency_notes === '') {
                toast.error('Please add a note description', { theme: 'colored' })
                return
            } else{
                saveForm('stage-four')
            }
        }
        if(val === 'exit'){
            if (data?.contingents?.length === 0) {
                toast.error('Please add a contingent', { theme: 'colored' })
                return
            }if (data?.contingency_notes === '') {
                toast.error('Please add a note description', { theme: 'colored' })
                return
            } else{
                exit('stage-four')
            }
        }
    }

    const addToContingents = () => {
        if (value?.name === '') return toast.error('Please provide contingency name')
        if (value?.days === '') return toast.error('Please provide number of days')
        dispatch({type: 'ADD_CONTINGENT', payload: {...value, startDate: new Date().toISOString()?.split('T')[0]}})
        setValue({ type: 'ctgs', name: '', days: '', startDate: '', notes: '', read: false })
        setOpen(false)
    }

    return (
        <div>
            <RadioInputItem
                formData={['Contract Pending & Contingent', 'Contract Pending Non Contingent']}
                onChange={(val) => dispatch({ type: 'CONTINGENCY', payload: val })}
                action={'CONTINGENCY'}
                title={'Contingency'}
                value={contingency}
                disable={true}
            />
            <Divider sx={{ mt: 2, mb: 3, bgcolor: '#ededed' }} />
            <FormInputItem required={true}
                title={'Contingents'}
                formData={data?.contingents}
                formDispatch={dispatch}
                classes={classes}
                data={data}
                flex={12}
                isContingent={data?.contingents?.length > 0}
                addContingency={() => setOpen(true)}
                showButton={true}
                updateContingent={(index, val)=> dispatch({type: 'UPDATE_CONTINGENT', payload: {index, value: val}})}
                removeContingent={(val)=> dispatch({type: 'REMOVE_CONTINGENT', payload: val})}
            />

            <Divider sx={{ mt: 2, mb: 3, bgcolor: '#ededed' }} />
            <BottomActions
                classes={classes}
                load={load}
                onSubmit={(val) => onFormSubmit(val)}
                resetDispatch={() => { dispatch({ type: 'RESET' }) }}
                buttons={['Clear Entries', 'Save & Exit', 'Save & Continue']}
            />

            {/* Dialog  */}
            <Dialog open={open} maxWidth={'sm'} fullWidth>
                <Typography p={3} fontWeight={500}>Add Contingency</Typography>
                <Divider sx={{ bgcolor: '#ededed' }} />
                <DialogContent sx={{ p: 3 }}>
                    <Typography className={classes.label}>Contingency</Typography>
                    <TextField size='small' fullWidth value={value?.name} onChange={(e) => setValue(prev => ({ ...prev, name: e?.target?.value }))} />
                    <Typography mt={2} className={classes.label}>Number of Days</Typography>
                    <TextField type='number' inputProps={{ min: 0 }} size='small' fullWidth value={value?.days} onChange={(e) => setValue(prev => ({ ...prev, days: e?.target?.value }))} />
                </DialogContent>
                <DialogActions sx={{ mx: 2, my: 2 }}>
                    <Button variant='outlined' color='secondary' disableElevation className={classes.btn} onClick={() => {
                        setValue({ type: 'ctgs', name: '', days: '', startDate: '', notes: '' })
                        setOpen(false)
                    }}>Cancel</Button>
                    <Button variant='contained' color='secondary' disableElevation className={classes.btn} onClick={addToContingents}>Add</Button>
                </DialogActions>
            </Dialog>

        </div>
    )
}

export default StageFour