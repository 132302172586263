import * as questionnaireTypes from "../../actions/dealAnalyzer/dealAnalyzerQuestionnaireTypes";

const initialState = {
  straightAestheticRemodel: {},
  remodelAndAddAddition: {},
  remodelDownToStuds: {},
  remodelDownToStudsAddAddition: {},
  newConstruction: {},
  buyAndRent: {},
};

const dealAnalyzerQuestionnaireReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case questionnaireTypes.UPDATE_STRAIGHT_AESTHETIC_REMODEL_QUESTIONNAIRE:
      return {
        ...state,
        straightAestheticRemodel: {
          ...state.straightAestheticRemodel,
          ...payload,
        },
      };
    case questionnaireTypes.UPDATE_REMODEL_ADD_ADDITION_QUESTIONNAIRE:
      return {
        ...state,
        remodelAndAddAddition: { ...state.remodelAndAddAddition, ...payload },
      };
    case questionnaireTypes.UPDATE_REMODEL_DOWN_TO_STUDS_QUESTIONNAIRE:
      return {
        ...state,
        remodelDownToStuds: { ...state.remodelDownToStuds, ...payload },
      };
    case questionnaireTypes.UPDATE_DOWN_TO_STUDS_ADDITION_QUESTIONNAIRE:
      return {
        ...state,
        remodelDownToStudsAddAddition: {
          ...state.remodelDownToStudsAddAddition,
          ...payload,
        },
      };
    case questionnaireTypes.UPDATE_NEW_CONSTRUCTION_QUESTIONNAIRE:
      return {
        ...state,
        newConstruction: { ...state.newConstruction, ...payload },
      };
    case questionnaireTypes.UPDATE_BUY_AND_RENT_QUESTIONNAIRE:
      return {
        ...state,
        buyAndRent: { ...state.buyAndRent, ...payload },
      };

    default:
      return state;
  }
};

export default dealAnalyzerQuestionnaireReducer;
