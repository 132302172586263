import React from "react";
import { FormGroup, FormControlLabel, Checkbox, Box } from "@mui/material";
import StyledLabel from "./StyledLabel";
import HtmlToolTip from "./HtmlToolTip";

function EnhancedCheckBoxGroup({ options, data,name, handler, toolTipText,autoCheckedArray}) {


  const handleValue = (e) => {
    handler({ [e.target.name]: e.target.checked });
  };
  const checkSize = { "& .MuiSvgIcon-root": { fontSize: 28 } };
  const styles = {
    display: "flex",
    alignItems: "center",
    padding: "1rem 0",
  };

  return (
    <Box sx={{display:"flex",alignItems:"center"}}>
      <FormGroup>
        {options &&
          options.map((element,index) => (
            <Box sx={styles} key = {element?.name}>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={ handleValue}
                    name={element?.name}
                    checked = { data?.[element?.name]}
                    sx={checkSize}
                  />
                }
                label={<StyledLabel label={element?.label} />}
              />
            </Box>
          ))}
      </FormGroup>
      <HtmlToolTip content = {toolTipText}/>
    </Box>
  );
}

export default EnhancedCheckBoxGroup;
