import { Close } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, Divider, FormControlLabel, Grid, IconButton, MenuItem, Radio, RadioGroup, Stack, TextField, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'
import { makeStyles } from '@mui/styles'
import React from 'react'
import { toast } from 'react-toastify'

const useStyles = makeStyles(theme => ({
    label: {
        fontSize: '.9rem',
        color: grey[600],
        marginBottom: '.5rem'
    },
    btn: {
        textTransform: 'none',
        borderRadius: '8px'
    }
}))




export const BrokerInfo = ({ dispatch, classes, data, isDisabled }) => {
    const brokerFields = [
        { type: 'select', label: 'Select Broker', action: 'BROKERAGE_NAME', key: 'brokerage_name' },
        { type: 'text', label: 'Broker Name', action: 'BROKER_NAME', key: 'broker_name' },
        { type: 'text', label: 'Brokerage Residence Address', action: 'BROKERAGE_ADDRESS', key: 'brokerage_address' },
        { type: 'text', label: 'Brokerage Email Address', action: 'BROKERAGE_EMAIL', key: 'brokerage_email' },
        { type: 'text', label: 'Brokerage Phone Number', action: 'BROKERAGE_PHONE', key: 'brokerage_phone' },
        { type: 'text', label: "Brokerage License Number", action: 'BROKERAGE_LICENSE', key: 'brokerage_license' },
        { type: 'text', label: 'City', action: 'BROKERAGE_CITY', key: 'brokerage_city' },
        { type: 'text', label: 'State', action: 'BROKERAGE_STATE', key: 'brokerage_state' },
        { type: 'text', label: 'Zip', action: 'BROKERAGE_ZIP', key: 'brokerage_zip' }
    ]
    const options = ['', 'BPO Homes', 'Berkshire Hathaway', 'Better Homes & Gardens', 'Coldwell Banker', 'Compass', 'Exp Realty', 'Exit', 'Intero', 'Keller Williams Realty', 'Remax', 'Side']

    return (
        <Grid container>
            <Grid item sm={3}>
                <Typography fontWeight={500} color={'secondary'}>Broker Information</Typography>
            </Grid>
            <Grid item sm={9}>
                <Grid container spacing={3}>
                    {
                        brokerFields?.map((el, i) => {
                            return (
                                <Grid key={i} item sm={(el?.label === 'City' || el?.label === 'State' || el?.label === 'Zip') ? 4 : 6}>
                                    <Typography className={classes.label}>{el?.label}<span style={{ color: 'red', marginLeft: '5px' }}>*</span></Typography>
                                    {el?.type === 'select' ?
                                        <TextField disabled={isDisabled} defaultValue={''} value={data?.[el?.key]} select size='small' fullWidth
                                            onChange={(e) => dispatch({ type: el?.action, payload: e?.target?.value })}>
                                            {
                                                options?.map((opt, i) => <MenuItem key={i} value={opt}>{opt}</MenuItem>)
                                            }
                                        </TextField>
                                        :
                                        <TextField value={data?.[el?.key]} disabled={isDisabled}
                                            onChange={(e) => dispatch({ type: el?.action, payload: e?.target?.value })}
                                            type={el?.type} size='small' fullWidth />
                                    }
                                </Grid>
                            )
                        })
                    }
                </Grid>
            </Grid>
        </Grid>
    )
}

export const AgentInfo = ({ dispatch, classes, isDisabled, data }) => {
    const agentFields = [
        { type: 'text', label: 'Agent First Name', action: 'FIRST_NAME', key: 'first_name' },
        { type: 'text', label: 'Agent Last Name', action: 'LAST_NAME', key: 'last_name' },
        { type: 'text', label: 'Agent License Number', action: 'LICENSE', key: 'license' },
        { type: 'text', label: 'Agent Phone 1', action: 'PHONE_1', key: 'phone_1' },
        { type: 'text', label: 'Agent Phone 2', action: 'PHONE_2', key: 'phone_2' },
        { type: 'text', label: 'Agent Email 1', action: 'EMAIL_1', key: 'email_1' },
        { type: 'text', label: 'Agent Email 2', action: 'EMAIL_2', key: 'email_2' },
    ]
    return (
        <Grid container>
            <Grid item sm={3}>
                <Typography fontWeight={500} color={'secondary'}>Agent Information</Typography>
            </Grid>
            <Grid item sm={9}>
                <Grid container spacing={3}>
                    {
                        agentFields?.map((el, i) => {
                            return (
                                <Grid key={i} item sm={6}>
                                    <Typography className={classes.label}>{el?.label} {(el?.label === 'Agent Phone 2' || el?.label === 'Agent Email 2') ? null : <span style={{ color: 'red', marginLeft: '5px' }}>*</span>}</Typography>
                                    <TextField value={data?.[el?.key]}
                                        disabled={isDisabled} required={true}
                                        onChange={(e) => dispatch({ type: el?.action, payload: e?.target?.value })}
                                        type={el?.type} size='small' fullWidth />
                                </Grid>
                            )
                        })
                    }
                </Grid>
            </Grid>
        </Grid>
    )
}

const ContractSign = ({ dispatch, data, saveForm, exitForm, load }) => {
    const classes = useStyles()
    const [open, setOpen] = React.useState(false)

    React.useEffect(() => {
        window?.scrollTo({ top: 0, behavior: 'smooth' })
    }, [])


    const onFormSubmit = (val) => {
        if (data?.isAgreementSigned) {
            saveForm('contract')
        } else {
            dispatch({ type: 'AGREEMENT', payload: val });
            saveForm('contract')
            setOpen(false)
        }
    }

    const validateForm = () => {
        if (data?.broker?.broker_name === '') return toast.error('Please provide broker name', { theme: 'colored' })
        if (data?.broker?.brokerage_name === '') return toast.error('Please select your brokerage', { theme: 'colored' })
        if (data?.broker?.brokerage_address === '') return toast.error('Please provide brokerage address', { theme: 'colored' })
        if (data?.broker?.brokerage_email === '' || !data?.broker?.brokerage_email?.includes('@')) return toast.error('Please provide a valid brokerage email', { theme: 'colored' })
        if (data?.broker?.brokerage_license === '') return toast.error('Please provide brokerage license', { theme: 'colored' })
        if (data?.broker?.brokerage_city === '') return toast.error('Please provide brokerage city', { theme: 'colored' })
        if (data?.broker?.brokerage_zip === '') return toast.error('Please provide brokerage zip', { theme: 'colored' })
        if (data?.broker?.brokerage_state === '') return toast.error('Please provide brokerage state', { theme: 'colored' })
        if (data?.broker?.brokerage_address === '') return toast.error('Please provide brokerage address', { theme: 'colored' })
        if (data?.agent?.first_name === '') return toast.error('Please provide your first name', { theme: 'colored' })
        if (data?.agent?.last_name === '') return toast.error('Please provide your last name', { theme: 'colored' })
        if (data?.agent?.license === '') return toast.error('Please provide your license number', { theme: 'colored' })
        if (data?.agent?.phone_1 === '') return toast.error('Please provide your phone number', { theme: 'colored' })
        if (data?.agent?.email_1 === '' || !data?.agent?.email_1?.includes('@')) return toast.error('Please provide a valid agent email address', { theme: 'colored' })

        data?.isAgreementSigned ? onFormSubmit(true) : setOpen(true)
    }


    return (
        <Box>
            {!data?.agent?.isFirstTime &&
                <>
                    <Stack direction={'row'} gap={2} alignItems={'center'} justifyContent={'space-between'}>
                        <Typography fontWeight={500} color={'secondary'}>Have you switched your Broker since the Last Transaction with BPO Tech?</Typography>
                        <RadioGroup row
                            value={data?.switchedBroker}
                            onChange={(e) => dispatch({ type: 'SWITCHED', payload: e?.target?.value })}
                        >
                            <FormControlLabel value="yes" sx={{ mr: 2 }} control={<Radio size='small' color='secondary' />} label="Yes" />
                            <FormControlLabel value="no" control={<Radio size='small' color='secondary' />} label="No" />
                        </RadioGroup>
                    </Stack>

                    <Divider sx={{ mt: 2, mb: 5, bgcolor: '#ededed' }} />
                </>
            }
            {/* Broker Info */}
            <BrokerInfo classes={classes} dispatch={dispatch} data={data?.broker} isDisabled={!data?.agent?.isFirstTime && (data?.switchedBroker === 'no')} />

            <Divider sx={{ mt: 5, mb: 2, bgcolor: '#ededed' }} />
            <Stack direction={'row'} gap={2} alignItems={'center'} justifyContent={'space-between'}>
                <Typography fontWeight={500} color={'secondary'}>Has your Agent License number changed since your last logged in?</Typography>
                <RadioGroup row
                    value={data?.changedLicense}
                    onChange={(e) => dispatch({ type: 'CHANGED_LICENSE', payload: e?.target?.value })}
                >
                    <FormControlLabel value="yes" sx={{ mr: 2 }} control={<Radio size='small' color='secondary' />} label="Yes (provide license number below)" />
                    <FormControlLabel value="no" control={<Radio size='small' color='secondary' />} label="No" />
                </RadioGroup>
            </Stack>
            <Divider sx={{ my: 2, mb: 5, bgcolor: '#ededed' }} />

            {/* Agent Info */}
            <AgentInfo classes={classes} data={data?.agent} dispatch={dispatch} isDisabled={data?.changedLicense === 'no'} />

            <Divider sx={{ mt: 5, mb: 4, bgcolor: '#ededed' }} />

            {/* Bottom Actions */}
            <Box>
                <Stack direction={'row'} gap={2} alignItems={'center'} justifyContent={'flex-end'}>
                    {/* <Button variant='outlined' color={'secondary'} disableElevation size='small' className={classes.btn}
                        onChange={() => dispatch({ type: 'RESET' })}>Reset</Button> */}
                    <LoadingButton onClick={() => exitForm('contract')} loading={load} variant='outlined' color={'secondary'} disableElevation size='small' className={classes.btn}>Save & Exit</LoadingButton>
                    <LoadingButton loading={load} variant='contained' color='secondary' disableElevation size='small'
                        onClick={validateForm} className={classes.btn}>Sign & Continue</LoadingButton>
                </Stack>
            </Box>

            {/* Dialog */}
            <Dialog open={open} maxWidth='md' fullWidth>
                <DialogContent dividers>
                    <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} mb={2}>
                        <Typography fontSize={'1.2rem'}>Referral Fee Agreement</Typography>
                        <IconButton disabled={load} size='small' onClick={() => { dispatch({ type: 'AGREEMENT', payload: false }); setOpen(false) }}><Close fontSize='small' /></IconButton>
                    </Stack>
                    <Divider sx={{ bgcolor: '#eee' }} />
                    <Box textAlign={'justify'} p={3}>
                        <Typography my={2}>This Referral Fee Agreement ("Agreement") is made and entered into as of [Date], by and between:</Typography>
                        <Typography my={2}>[Referring Party's Name], with a principal place of business at [Address] ("Referrer"), and [Receiving Party's Name], with a principal place of business at [Address] ("Receiver").</Typography>
                        <Typography my={2}>WHEREAS, Referrer has contacts and relationships with individuals or entities ("Potential Clients") who may be interested in the services or products provided by Receiver; and</Typography>
                        <Typography>WHEREAS, Receiver is interested in acquiring new clients and is willing to compensate Referrer for the referral of Potential Clients;</Typography>
                        <Typography my={2}>NOW, THEREFORE, in consideration of the mutual covenants contained herein and for other good and valuable consideration, the receipt and sufficiency of which are hereby acknowledged, the parties agree as follows:</Typography>
                        <Typography my={2}>1. Referral Services: Referrer agrees to refer Potential Clients to Receiver for the purposes of engaging in business transactions related to the services or products provided by Receiver.</Typography>
                        <Typography my={2}>2. Compensation: In consideration of the referral services provided by Referrer, Receiver agrees to pay Referrer a referral fee for each Successful Referral. A "Successful Referral" is defined as a referred Potential Client who enters into a contractual relationship with Receiver and completes a transaction for services or products provided by Receiver.</Typography>
                        <Typography my={2}>3. Referral Fee: The referral fee payable to Referrer for each Successful Referral shall be [Specify amount or percentage]. The parties agree that the referral fee shall be paid within [Specify timeframe, e.g., 30 days] of the completion of the transaction with the referred Potential Client.</Typography>
                        <Typography my={2}>4. Payment Method: The referral fee shall be paid to Referrer via [Specify payment method, e.g., check, electronic transfer] to the following account: [Provide account details].</Typography>
                        <Typography>5. Term: This Agreement shall commence on the Effective Date and shall continue until terminated by either party upon [Specify notice period, e.g., 30 days] written notice to the other party.</Typography>
                    </Box>

                </DialogContent>
                <DialogActions sx={{ display: 'flex', gap: 1, alignItems: 'center', px: 3 }}>
                    <FormControlLabel disabled={load} sx={{ '& > span': { fontSize: '.9rem' } }} label={'Dont show again'}
                        control={<Checkbox fontSize={'small'} color='secondary'
                            onChange={(e, checked) => dispatch({ type: 'SHOW_AGREEMENT', payload: checked })} />}
                    />
                    <Button disabled={load} variant='outlined' color={'secondary'} disableElevation size='small' className={classes.btn}
                        onClick={() => {
                            dispatch({ type: 'AGREEMENT', payload: false })
                            setOpen(false)
                        }}>Decline</Button>
                    <LoadingButton loading={load} variant='contained' color={'secondary'} disableElevation size='small' className={classes.btn}
                        onClick={() => { onFormSubmit(true) }}>Accept</LoadingButton>
                </DialogActions>
            </Dialog>
        </Box>
    )
}

export default ContractSign