import React, { useState, useEffect, Fragment } from 'react';
import { NavLink } from 'react-router-dom';

import { toast } from 'react-toastify';
import Modal from '../../components/Modal/Modal';
// import Input from '../../components/Input/Input';
import { ReactComponent as Close } from '../../images/Close.svg';
import base from '../../apis';
import './profileoverview.scss';
import { useSelector } from 'react-redux';
import Spinner from '../../components/Spinner/Spinner';
import Button from '../../components/Button/Button';
import {
	Avatar,
	Box,
	Dialog,
	Slide,
	Button as MUIButton,
	Typography,
	TextField,
	Grid,
	IconButton,
	InputAdornment,
	Card,
	Rating,
	CircularProgress,
	Pagination,
	Stack,
	DialogTitle,
	DialogContent,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import NoReviews from '../../images/noReviews.webp';

import { ratingOptions } from '../../constants';
import { grey } from '@mui/material/colors';
import { useCallback } from 'react';

const useStyles = makeStyles((theme) => ({
	dialog: {
		marginTop: 'var(--navheight)',
		'& .MuiDialog-paper': {
			borderTopRightRadius: '5px',
			borderTopLeftRadius: '5px',
		},
	},
	container: {
		padding: '20px',
		width: '60%',
		marginLeft: 'auto',
		marginRight: 'auto',
	},
	body2: {
		fontWeight: '300',
		textAlign: 'justify',
		marginBottom: '1rem',
	},
}));

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction='up' ref={ref} {...props} />;
});

function ProfileOverview() {
	const classes = useStyles();
	const [data, setData] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [aboutModal, setaboutModal] = useState(false);
	const [aboutMe, setAboutMe] = useState('');
	const id = useSelector((state) => state.auth.typeId);
	const [emails, setEmails] = useState(['']);
	const [requestModalOpen, setRequestModalOpen] = useState(false);
	const [zillowUrl, setZillowUrl] = useState('');
	const [zillowModalOpen, setZillowModalOpen] = useState(false);

	useEffect(() => {
		if (!id) return;
		const fetchProfileInfo = async () => {
			setIsLoading(true);
			try {
				const { data: responseData } = await base.get(`/register/agents/${id}/`);
				setData(responseData);
				setAboutMe(responseData.bio || '');

				if (responseData?.length === 0) {
					toast.warn('No teams found');
				}
			} catch (error) {
				toast.error('Could not fetch profile');
			} finally {
				setIsLoading(false);
			}
		};
		fetchProfileInfo();
	}, [id]);

	const saveBio = () => {
		setIsLoading(true);
		base
			.patch(`/register/agents/${id}/`, {
				bio: aboutMe || null,
			})
			.then(({ data }) => {
				setData(data);
				setaboutModal(false);
				toast.success('Bio updated');
			})
			.catch((err) => {
				toast.error('Could not update bio');
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const copyReviewLink = () => {
		window.navigator.clipboard.writeText(`${window.location.origin}/agent-finder/${id}?review=true`);
		toast.success('Link copied to clipboard');
	};

	const onAddEmail = () => {
		setEmails([...emails, '']);
	};
	const onDeleteEmail = (index) => {
		if (emails.length === 1) return;
		setEmails(emails.filter((_, i) => i !== index));
	};

	const requestReviewOpen = () => {
		setEmails(['']);
		setRequestModalOpen(true);
	};

	const handleSendRequest = () => {
		if (!emails.every((email) => email)) {
			toast.error('Please fill all the email fields');
			return;
		}
		setIsLoading(true);
		base
			.post(`/register/send_client_review/`, {
				agent_id: id,
				emails,
			})
			.then(({ data }) => {
				setRequestModalOpen(false);
				toast.success('Request sent');
				setEmails(['']);
			})
			.catch((err) => {
				toast.error('Could not send request');
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const [reviews, setReviews] = useState([]);
	const [isReviewLoading, setIsReviewLoading] = useState(false);
	const [pageCount, setPageCount] = useState(null);

	const handlePageChange = (e, page) => {
		getReviews(page);
	};

	const getReviews = useCallback(
		(page = 1) => {
			setReviews([]);
			setIsReviewLoading(true);
			base
				.get(`register/agents/${id}/reviews/?page=${page}`)
				.then(({ data }) => {
					setReviews(data?.results);
					setPageCount(data?.count);
					const up = document.getElementById('up');
					up?.scrollIntoView();
				})
				.catch((err) => {
					toast.error('Could not get reviews');
				})
				.finally(() => {
					setIsReviewLoading(false);
				});
		},
		[id]
	);

	useEffect(() => {
		getReviews();
	}, [getReviews]);

	const getZillowReviews = async () => {
		if (!zillowUrl) {
			toast.error('Please enter a valid url');
			return;
		}

		setIsLoading(true);
		try {
			await base.post('/premiersite/get-agents-reviews/', {
				zillow_url: zillowUrl.endsWith('/') ? zillowUrl : zillowUrl + '/',
				agent_id: id,
			});
			setZillowModalOpen(false);
			getReviews(1);
		} catch (error) {
			toast.warn('Could not get reviews');
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<>
			{isLoading && <Spinner />}
			<Modal open={aboutModal} onClose={() => setaboutModal(false)} style={{ minWidth: '50vw' }}>
				<div className='mod2'>
					<div className='mod2header' style={{ position: 'relative' }}>
						<h3 style={{ textAlign: 'center' }}>About Me</h3>
						<Close
							onClick={() => setaboutModal(false)}
							style={{ fill: '#1378A5', cursor: 'pointer', position: 'absolute', top: '10px', right: '10px' }}
							width='18px'
							height='18px'
						/>{' '}
					</div>
					<div className='modal_body'>
						<textarea
							rows={10}
							value={aboutMe}
							onChange={(e) => {
								setAboutMe(e.target.value);
							}}
							style={{ width: '95%', resize: 'vertical', margin: '20px' }}
						></textarea>
						<Button
							style={{
								width: 'fit-content',
								height: '45px',
								fontSize: '16px',
								padding: '10px 30px',
								color: 'white',
								backgroundColor: 'var(--primary-color)',
								border: '1px solid var(--primary-color)',
								margin: '0px 20px 20px auto',
							}}
							onClick={saveBio}
							type='submit'
							text='Save'
						/>
					</div>
				</div>
			</Modal>

			<Dialog
				fullWidth
				fullScreen
				open={requestModalOpen}
				onClose={() => setRequestModalOpen(false)}
				TransitionComponent={Transition}
				className={classes.dialog}
			>
				<Box className={classes.container}>
					<Box display='flex' justifyContent='flex-end' sx={{ backgroundColor: 'white', position: 'sticky', top: 0, opacity: '10000' }}>
						<IconButton onClick={() => setRequestModalOpen(false)}>
							<CloseIcon />
						</IconButton>
					</Box>
					<Typography paragraph>Request Reviews</Typography>
					<Typography variant='body2' className={classes.body2}>
						Fill out this form to request feedback from your clients. Each client will receive an email containing a link to a review on BPO Homes.
					</Typography>
					<Typography variant='body2' className={classes.body2}>
						Please keep in mind that in order to submit a review, reviewers must first create an account with BPO Homes. This allows for review
						moderation and verification. Reviewers can unsubscribe from BPO Homes emails at any time. All reviews will be evaluated by a dedicated
						team of trained moderators before they are published, which will typically take seven business days.
					</Typography>
					<Grid container spacing={2}>
						{emails.map((item, index) => (
							<Grid item xl={4} key='index'>
								<TextField
									placeholder='Client Email'
									value={item}
									onChange={(e) => {
										const newEmails = [...emails];
										newEmails[index] = e.target.value;
										setEmails(newEmails);
									}}
									InputProps={{
										endAdornment: (
											<InputAdornment position='end'>
												<DeleteIcon
													color='red'
													sx={{ cursor: 'pointer' }}
													onClick={() => {
														onDeleteEmail(index);
													}}
												/>
											</InputAdornment>
										),
									}}
								/>
							</Grid>
						))}
						<Grid item xl={4} display='flex' alignItems={'center'}>
							<IconButton onClick={onAddEmail}>
								<AddIcon />
							</IconButton>
						</Grid>
					</Grid>

					<Box></Box>
					<Typography variant='body2' my='1rem'>
						Message
					</Typography>
					<Box sx={{ border: '1px solid grey', borderRadius: '5px', fontWeight: '400', padding: '10px', marginTop: '10px', marginBottom: '10px' }}>
						<p style={{ marginTop: 0 }}>Hello,</p>
						<p>Kindly leave a review on BPO Homes for me.</p>
						<p>
							Please keep in mind that if you do not already have a BPO Homes account, you will be required to provide your email address in order to
							help prevent deceptive reviews.
						</p>
						<p>Thank you very much!</p>
					</Box>
					<MUIButton
						sx={{ width: 'fit-content', textTransform: 'capitalize', marginBottom: '1rem', marginTop: '1rem' }}
						variant='contained'
						color='secondary'
						onClick={handleSendRequest}
					>
						Send Request
					</MUIButton>

					<Typography variant='body2'>Provide the client with a direct link to the review form.</Typography>
					<Box display='flex' gap='10px'>
						<TextField defaultValue={`${window.location.origin}/agent-finder/${id}?review=true`} fullWidth disabled />
						<MUIButton sx={{ width: '7rem', textTransform: 'capitalize' }} variant='outlined' color='secondary' onClick={copyReviewLink}>
							Copy&nbsp;Link
						</MUIButton>
					</Box>
				</Box>
			</Dialog>

			{/* Add from zillow dialog */}
			<Dialog open={zillowModalOpen} maxWidth='sm' fullWidth onClose={() => setZillowModalOpen(false)}>
				<DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
					<Box display='flex' justifyContent='space-between' alignItems='center' marginRight='40px' flex='1' padding={1}>
						<Typography variant='body1'>Zillow Profile Url</Typography>
					</Box>
					<IconButton onClick={() => setZillowModalOpen(false)}>
						<Close fontSize='small' />
					</IconButton>
				</DialogTitle>
				<DialogContent dividers>
					<Grid container spacing={2}>
						<Grid item xs={10}>
							<TextField
								fullWidth
								placeholder='https://www.zillow.com/profile/profile-name-here/'
								size='small'
								color='secondary'
								value={zillowUrl}
								onChange={(e) => setZillowUrl(e.target.value)}
							/>
						</Grid>
						<Grid item xs={2}>
							<MUIButton color='secondary' variant='contained' onClick={getZillowReviews}>
								Add
							</MUIButton>
						</Grid>
					</Grid>
				</DialogContent>
			</Dialog>

			<div className='profile_overview'>
				<div className='main'>
					<div className='col1'>
						<div className='row1'>
							<Avatar
								src={data?.profile_image}
								sx={{
									width: '120px',
									height: '120px',
									marginRight: '10px',
									'&>img': {
										objectPosition: 'top center',
									},
								}}
							/>
							<div className='info'>
								<p className='section_title'>
									{data?.first_name} {data?.last_name}
								</p>
								<MUIButton variant='text' color='secondary' onClick={requestReviewOpen}>
									Request Reviews
								</MUIButton>
							</div>
						</div>
						<div className='row2'>
							<p className='section_title'>About Me</p>
							<p className='section_details' style={{ whiteSpace: 'pre-line' }}>
								{data?.bio}
							</p>
							{data?.specialties?.length > 0 ? (
								<p className='section_highlighted'>Specialities: {data?.specialties.map((item) => item.title).join(', ')}</p>
							) : (
								<NavLink to='profile/edit' className='section_highlighted'>
									Add specialties{' '}
								</NavLink>
							)}
						</div>
						{/* <div className='row3'>
							<p className='section_title'>For Sale</p>
							<p className='section_highlighted'>Most recent listings and sales around your area.</p>
							<p className='section_details'>Your listings will appear here within 24 hours once an MLS Agent ID is linked to your profile.</p>
						</div> */}
						<div className='row4'>
							<p className='section_title'>Sold</p>
							<p className='section_details'>Past sales data currently unavailable.</p>
						</div>
						<div className='row5'>
							<Stack direction='row' justifyContent='space-between' alignItems='center'>
								<p className='section_title' id='up' style={{ scrollMarginTop: 'var(--navheight)' }}>
									Ratings & reviews ({pageCount})
								</p>
								<MUIButton variant='contained' color='secondary' onClick={() => setZillowModalOpen(true)}>
									Add from Zillow
								</MUIButton>
							</Stack>
							<Box>
								<Grid container spacing={2}>
									<Grid item xl={12}>
										{!isReviewLoading ? (
											reviews.length > 0 ? (
												reviews.map((review) => (
													<React.Fragment key={review.id}>
														<Card sx={{ padding: '10px', marginBottom: '10px' }} key={review.id}>
															<Grid container spacing={2}>
																<Grid item xl={12} sm={12} fontWeight={300}>
																	{new Date(review?.date_created).toLocaleDateString('en-US')} -{' '}
																	{`${
																		review?.client?.user?.first_name || review?.client?.user?.last_name
																			? review?.client?.user?.first_name + ' ' + review?.client?.user?.last_name
																			: review?.name
																	} `}
																</Grid>
																<Grid item xl={12} sm={12} md={12}>
																	<Grid container spacing={0} alignItems='flex-end'>
																		{ratingOptions.map((item) => (
																			<Fragment key={item.value}>
																				<Grid item xl={6} lg={6} xs={6}>
																					<Typography variant='body2'>{item.label}</Typography>
																				</Grid>
																				<Grid item xl={6} lg={6} xs={6}>
																					<Rating readOnly value={review[item.value] || 1} />
																				</Grid>
																			</Fragment>
																		))}
																	</Grid>
																</Grid>
																<Grid item xl={12} sm={12} md={12} textAlign='justify'>
																	{review.description}
																</Grid>
															</Grid>
														</Card>
													</React.Fragment>
												))
											) : (
												<Box bgcolor={grey[200]} padding={'4rem'} textAlign='center' borderRadius={'10px'}>
													<img src={NoReviews} alt='no-reviews' width={'15%'} style={{ marginBottom: '10px' }} />
													<Typography paragraph>Sorry, no reviews available</Typography>
												</Box>
											)
										) : (
											<Box height={'20rem'} padding={'4rem'} display='flex' alignItems='center' justifyContent='center' borderRadius={'10px'}>
												<CircularProgress />
											</Box>
										)}
									</Grid>
									<Grid item xl={12} display='flex' justifyContent='center'>
										<Pagination
											size='large'
											color='secondary'
											sx={{
												display: 'flex',
												justifyContent: 'center',
											}}
											shape='rounded'
											variant='outlined'
											count={Math.ceil(pageCount / 5)}
											onChange={handlePageChange}
										/>
									</Grid>
								</Grid>
							</Box>
						</div>
						<div className='row6'>
							<p className='section_title'>Service Areas ({data?.service_areas?.length})</p>
							{data?.service_areas?.length > 0 ? (
								<p className='section_highlighted'>{data?.service_areas.map((item) => item.location).join(', ')}</p>
							) : (
								<NavLink to='/profile/edit?state=3' className='section_highlighted'>
									Add Service Area{' '}
								</NavLink>
							)}
						</div>
					</div>
					<div className='col2'>
						<div className='right_row '>
							<p className='section_title'>Professional Information</p>
							<p>
								<span className='grey_text'>Cell phone:</span>&nbsp;&nbsp;
								<b>{data?.phone}</b>
							</p>
							<p>
								<span className='grey_text'>Screen name:</span>&nbsp;&nbsp;
								<b>{data?.screen_name}</b>
							</p>
							<p>
								<span className='grey_text'>Member since:</span>&nbsp;&nbsp;
								<b>{data?.year_work_commenced && new Date(data?.year_work_commenced)?.getFullYear()}</b>
							</p>
							<p>
								<span className='grey_text'>Real Estate License:</span>
								&nbsp;&nbsp;
								<b>{data?.licenses?.find((item) => item.category === 'real_estate_agent')?.number || 'Not provided'}</b>
							</p>
						</div>
						<div className='right_row'>
							<p className='section_title'>Promote yourself on BPO Homes</p>
							{/* <progress className='progressbar' value='0.4' /> */}
							{/* <p className='grey_text'>Your profile is 0% completeYour profile is 0% complete</p> */}
							<p>
								<b>1.</b>&nbsp;&nbsp;&nbsp;&nbsp;
								<NavLink to='/profile/edit' className='section_highlighted'>
									Add a photo{' '}
								</NavLink>
							</p>
							<p>
								<b>2.</b>&nbsp;&nbsp;&nbsp;&nbsp;
								<span className='section_highlighted' onClick={() => setaboutModal(true)}>
									{data?.bio ? 'Edit' : 'Add'} About me
								</span>
							</p>
							<p>
								<b>2.</b>&nbsp;&nbsp;&nbsp;&nbsp;
								<NavLink to='/profile/edit?state=3' className='section_highlighted'>
									Add service areas
								</NavLink>
							</p>
							<MUIButton variant='text' href={`/agent-finder/${id}`} sx={{ textTransform: 'capitalize' }}>
								View Public Page
							</MUIButton>
							{/* <p>
                <b>4.</b>&nbsp;&nbsp;&nbsp;&nbsp;
                <span className="section_highlighted">
                  Add your past sales to your profile
                </span>
              </p> */}
							{/* <p>
								<b>5.</b>&nbsp;&nbsp;&nbsp;&nbsp;
								<span className='section_highlighted'>Connect your listings</span>
							</p> */}
							{/* <p>
                <b>6.</b>&nbsp;&nbsp;&nbsp;&nbsp;
                <span className="section_highlighted">Request Reviews</span>
              </p> */}
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default ProfileOverview;
