import { Divider } from '@mui/material'
import { grey } from '@mui/material/colors'
import { makeStyles } from '@mui/styles'
import React from 'react'
import { toast } from 'react-toastify'
import BottomActions from '../helpers/BottomActions'
import FormInputItem from '../helpers/FormInputItem'
import RadioInputItem from '../helpers/RadioInputItem'


const useStyles = makeStyles(theme => ({
    label: {
        fontSize: '.9rem',
        color: grey[600],
        marginBottom: '.5rem'
    },
    btn: {
        textTransform: 'none',
        borderRadius: '8px'
    }
}))

const StageSix = ({ data, dispatch, exit, saveForm, load }) => {
    const classes = useStyles()
    const closed = [
        { type: 'date', label: 'Select Date', action: 'CLOSED_DATE', dataName: 'closed', dataKey: 'date' },
        { type: 'time', label: 'Select Time', action: 'CLOSED_TIME', dataName: 'closed', dataKey: 'time' },
        { type: 'textarea', label: 'Notes', action: 'CLOSED_NOTES', dataName: 'closed', dataKey: 'notes' },
    ]
    const transactions = [
        { type: 'radio', label: ['Seller Not Interested', 'Buyer Not Interested'], action: 'FT_INTEREST', dataName: 'fell_through', dataKey: 'interest' },
        { type: 'date', label: 'Select Date', action: 'FT_DATE', dataName: 'fell_through', dataKey: 'date' },
        { type: 'time', label: 'Select Time', action: 'FT_TIME', dataName: 'fell_through', dataKey: 'time' },
        { type: 'textarea', label: 'Notes', action: 'FT_NOTES', dataName: 'fell_through', dataKey: 'notes' },
    ]

    const validateForm = () => {
        if (data?.status === '') return toast.error('Please select', { theme: 'colored' })
        if (data?.status === 'closed') {
            if (data?.closed?.date === '') return toast.error('Please provide a date', { theme: 'colored' })
            if (data?.closed?.time === '') return toast.error('Please provide a time', { theme: 'colored' })
            if (data?.closed?.notes === '') return toast.error('Please provide a date', { theme: 'colored' })
        }
        if (data?.status === 'transaction fell through') {
            if (data?.closed?.interest === '') return toast.error('Please select an interest', { theme: 'colored' })
            if (data?.closed?.date === '') return toast.error('Please provide a date', { theme: 'colored' })
            if (data?.closed?.time === '') return toast.error('Please provide a time', { theme: 'colored' })
            if (data?.closed?.notes === '') return toast.error('Please provide a date', { theme: 'colored' })
        }
    }


    const onFormSubmit = (val) => {
        if(val === 'continue'){
            validateForm()
            saveForm('stage-six')
        }
        if(val === 'exit'){
            validateForm()
            exit('stage-six')
        }
    }


  return (
    <div>
        <RadioInputItem
            formData={['Closed Escrow', 'Transaction Fell Through']}
            onChange={(val) => { dispatch({type: 'STATUS', payload: val});}}
            title={'Contact Outcome'}
            value={data?.status}
        />
        <Divider sx={{ mt: 2, mb: 3, bgcolor: '#ededed' }} />
        <FormInputItem required={true}
            title={'Closed Escrow'}
            formData={closed}
            formDispatch={dispatch}
            classes={classes}
            data={data}
            disable={data?.status !== 'closed escrow'}
        />
        <Divider sx={{ mt: 2, mb: 3, bgcolor: '#ededed' }} />
        <FormInputItem required={true}
            title={'Transaction Fell Through'}
            formData={transactions}
            formDispatch={dispatch}
            classes={classes}
            data={data}
            disable={data?.status !== 'transaction fell through'}
        />
        <BottomActions
            classes={classes}
            load={load}
            onSubmit={(val) => onFormSubmit(val)}
            resetDispatch={() => { dispatch({ type: 'RESET' }) }}
            buttons={['Clear Entries', 'Save & Exit', 'Save & Continue']}
            />

    </div>
  )
}

export default StageSix