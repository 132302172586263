import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import base from '../../apis';
import FINISH from '../../assets/finish.webp';
import Spinner from '../../components/Spinner/Spinner';
import { getSubscriptionStatus } from '../../utils/functions';

const PaymentSuccess = () => {
	const [searchParams] = useSearchParams();
	const agentId = useSelector((state) => state.auth.typeId);
	const id = useSelector((state) => state.auth.id);
	const role = useSelector((state) => state.auth.role);
	const [isLoading, setIsLoading] = React.useState(false);
	const selectedListing = useSelector((state) => state.auth.selectedListing);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	React.useEffect(() => {
		let route = '';
		if (!agentId) {
			return;
		}
		const sessionId = searchParams.get('session_id');
		setIsLoading(true);
		if (sessionId) {
			localStorage.removeItem('sessionId');
		}

		const getDetails = async () => {
			setIsLoading(true);
			try {
				const type = searchParams.get('type');
				if (type === 'industry_professional') {
					const { data } = await base.get(`register/ind-prof/profile/${selectedListing?.id}`);
					dispatch({ type: 'UPDATE_LISTING', payload: data });
					const sub = data?.subscription ? [data?.subscription] : [];
					dispatch({ type: 'START_LOADING' });
					dispatch({ type: 'SET_SUBSCRIPTIONS', payload: getSubscriptionStatus(sub) });
					navigate('/industry-dashboard');

					return;
				}
				if (type === 'BPO_Farm') {
					route = '/available-territories';
				}
				if (type === 'premier-agent-website') {
					toast.success('Successfully signed up to Premier Agent');
					route = '/premier-agent-website';
				}
				if (type === 'combo-package') {
					toast.success('Successfully signed up to Combo Package');
					route = '/premier-agent-website';
				}
				if (type === 'bpo-report') {
					const request = JSON.parse(localStorage.getItem('request'));
					const tier = searchParams.get('tier');
					const num_of_report = searchParams.get('num_of_report');
					const mode = searchParams.get('mode');
					const code = searchParams.get('code');
					const drive_amount = searchParams.get('drive_amount');
					//make post
					const body = {
						clients_physical_address: request.propertyAddress,
						reason_for_request: request.client_reason,
						client_register_id: request.client_id,
						client_id: request.client_id,
						payment_status: 'Complete',
						agent_id: +request.agent_id,
						tier: tier,
						num_of_report: num_of_report,
						status: mode,
						session_id: sessionId,
					};
					if (code) {
						body.short_uuid = code;
					}
					if (drive_amount) {
						body.drive_amount = drive_amount;
					}
					const { data } = await base.post('/register/bporeport_confirmation/', body);
					localStorage.removeItem('request');
					localStorage.removeItem('propertyAddress');
					route = `/client-ordered-report?id=${data.id}`;
				}
				if (type !== 'investor_list') {
					const { data } = await base.get(`/register/subscriptions/`, {
						params: {
							user_id: id,
						},
					});
					dispatch({ type: 'SET_SUBSCRIPTIONS', payload: getSubscriptionStatus(data) });
				} else {
					route = '/investor-leads';
					await base.post('/register/check_investor_status/', {
						session_id: sessionId,
					});
					const countiesChosen = searchParams.get('counties').split(',');
					if (countiesChosen.length > 0) {
						await base.post('/register/import-investors/', {
							county: countiesChosen,
						});
					}
				}
				if (role !== 'client') {
					const { data } = await base.get('/register/agents/me/');
					dispatch({ type: 'GET_TYPE_ID', payload: data });
				}
				navigate(route || (role === 'client' ? '/client-dashboard' : '/agent-dashboard'));
			} catch (error) {
				toast.error('Something went wrong');
			} finally {
				setIsLoading(false);
			}
		};

		getDetails();

		return () => {
			setIsLoading(false);
		};
	}, [searchParams, agentId, navigate, dispatch, id, role, selectedListing?.id]);
	return (
		<>
			{isLoading && <Spinner />}
			<Box
				height={'90vh'}
				sx={{
					display: 'flex',
					justifyContent: 'center',
					flexDirection: 'column',
					alignItems: 'center',
					'& img': {
						width: { xs: '60%', sm: '50%', md: '40%', lg: '30%' },
					},
				}}
			>
				<img src={FINISH} alt='finish' />
				<Typography variant='h5'>Congratulations</Typography>
				<Typography variant='body1' mb={3}>
					Your payment was successful
				</Typography>
				<Button variant='contained' color='secondary' disableElevation sx={{ textTransform: 'none' }}>
					Redirecting. Please wait...
				</Button>
			</Box>
		</>
	);
};

export default PaymentSuccess;
