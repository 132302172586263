import { Search } from '@mui/icons-material'
import { Badge, Box, Button, ButtonGroup, Container, Dialog, DialogActions, DialogContent, Divider, FormControlLabel, InputAdornment, Radio, RadioGroup, Skeleton, Stack, TextField, Toolbar, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'
import { makeStyles } from '@mui/styles'
import React, { useEffect, useReducer, useState } from 'react'
import LeadItem from './helpers/LeadItem'
import ConfirmIcon from '../../../assets/confirm_icon.svg'
import ContractForm from './ContractForm'
import axios from 'axios'
import { leadUrl } from '../../../apis'
import { formReducerFn, initState } from './utils'
import { LoadingButton } from '@mui/lab'
import LeadFiles from '../../../assets/leadFiles.webp'


const useStyles = makeStyles(theme => ({
    btn: {
        textTransform: 'none',
        borderRadius: '8px'
    },
    badge: {
        textTransform: 'none', display: 'flex', gap: 2, pr: 2,

    }
}))


const PremierLeads = () => {
    const classes = useStyles()
    const [type, setType] = useState('assigned')
    const [isLoading, setIsLoading] = useState(false)
    const [open, setOpen] = useState(false)
    const [load, setLoad] = useState(false)
    const [data, setData] = useState([])
    const [filtered, setFiltered] = useState([])

    const [agentData, setAgentData] = useState()
    const [leadState, setLeadState] = useState({ confirm: false, lead: null })
    // const [showFilter, setShowFilter] = useState(false)
    // const [filterParams, setFilterParams] = useState({type: [], source: [], state: []})
    // const [params, setParams] = useState({type: '', source: '', state: ''})


    const assigned_Leads = data?.filter(el => el?.isContractSigned === false)
    const processing_Leads = data?.filter(el => (el?.isContractSigned === true && !el?.progress?.some(el => el?.status === 'lead_level_6')))
    const finalized_Leads = data?.filter(el => (el?.isContractSigned === true && el?.progress?.some(el => el?.status === 'lead_level_6')))

    const fetchPremierLeads = async () => {
        const url = 'lead-distribution/agents/'
        if (localStorage.getItem('ac_premier_leads')) {
            try {
                setIsLoading(true)
                const { data: res } = await leadUrl.get(url)
                if (res?.status === 'success') {
                    // console.log(res?.data?.results)
                    const allLeads = res?.data?.results?.reduce((prev, cur) => [...prev, ...cur?.leadsObj], [])
                    setData(allLeads)
                    setAgentData({ ...res?.data?.results?.[0]?.agentObj })
                    formDispatch({ type: 'UPDATE_AGENT', payload: res?.data?.results?.[0]?.agentObj })
                    formDispatch({ type: 'UPDATE_BROKER', payload: res?.data?.results?.[0]?.agentObj?.brokerData })
                    setIsLoading(false)
                }
            } catch (error) {
                // console.log(error?.response)
                setIsLoading(false)
            }
        } else {
            try {
                setIsLoading(true)
                const { data: res } = await axios.post(`https://leads-api-dev.bpohomes.com/api/accounts/login/`, { email: 'fetchdata@leads.com', password: '#$3809?23-2' })
                if (res?.status === 'success') {
                    localStorage.setItem('ac_premier_leads', res?.data?.token?.access)
                    const { data: resp } = await leadUrl.get(url)
                    const allLeads = resp?.data?.results?.reduce((prev, cur) => [...prev, ...cur?.leadsObj], [])
                    setData(allLeads)
                    // setFilterParams({type: allLeadTypes, source: allLeadSources, state: allLeadStates })
                    setAgentData(resp?.data?.results?.[0]?.agentObj)
                    formDispatch({ type: 'UPDATE_AGENT', payload: resp?.data?.results?.[0]?.agentObj })
                    formDispatch({ type: 'UPDATE_BROKER', payload: res?.data?.results?.[0]?.agentObj?.brokerData })
                    setIsLoading(false)
                }
            } catch (error) {
                // console.log(error?.response)
                setIsLoading(false)
            }
        }
    }

    const [formInput, formDispatch] = useReducer(formReducerFn, initState)

    useEffect(() => {
        fetchPremierLeads()
    }, [leadState?.lead])

    useEffect(() => {
        if (type === 'assigned') setFiltered(assigned_Leads)
        if (type === 'processing') setFiltered(processing_Leads)
        if (type === 'finalized') setFiltered(finalized_Leads)
        // eslint-disable-next-line
    }, [data, type])

    const onSearchHandler = (e) => {
        const val = e?.target?.value
        if (val !== '') {
            const result = filtered?.filter(el => el?.uniqueId?.includes(val?.toLowerCase()))
            setFiltered(result)
        } else {
            if (type === 'assigned') setFiltered(assigned_Leads)
            if (type === 'processing') setFiltered(processing_Leads)
            if (type === 'finalized') setFiltered(finalized_Leads)
        }
    }


    return (
        <Container maxWidth={false}>
            <Toolbar />
            {
                leadState?.confirm ?
                    <ContractForm
                        agent={agentData} setLeadState={setLeadState}
                        lead={leadState?.lead}
                        dispatch={formDispatch}
                        data={formInput}

                    />
                    :
                    <Box>
                        {/* Title */}
                        <Typography fontSize={'1.4rem'} mb={2}>Premier Agent Leads</Typography>
                        <Divider light sx={{ mb: 3, bgcolor: grey[300] }} />

                        {/* Filter */}
                        {/* { showFilter && 
                            <Box bgcolor={grey[100]} py={2} mb={3}>
                                <Grid container spacing={3}>
                                    <Grid item sm={3.5}>
                                        <TextField select value={params?.type} fullWidth size='small' label={'Lead Type'} onChange={(e)=>setParams(prev=>({...prev, type: e?.target?.value}))} >
                                            {filterParams?.type?.map((el, i)=> <MenuItem key={i} value={el?.toLowerCase()}>{el}</MenuItem>)}
                                        </TextField>
                                    </Grid>
                                    <Grid item sm={3.5}>
                                        <TextField select value={params?.source} color='secondary' fullWidth size='small' label={'Lead Source'} onChange={(e)=>setParams(prev=>({...prev, source: e?.target?.value}))}>
                                            {filterParams?.source?.map((el, i)=> <MenuItem key={i} value={el?.toLowerCase()}>{el}</MenuItem>)}
                                        </TextField>
                                    </Grid>
                                    <Grid item sm={3.5}>
                                        <TextField select value={params?.state} fullWidth size='small' label={'Lead State'} onChange={(e)=>setParams(prev=>({...prev, state: e?.target?.value}))}>
                                            {filterParams?.state?.map((el, i)=> <MenuItem key={i} value={el?.toLowerCase()}>{el}</MenuItem>)}
                                        </TextField>
                                    </Grid>
                                    <Grid item sm={1.5}>
                                        <Button className={classes.btn} fullWidth variant='contained' color='secondary' disableElevation size='medium'>Filter</Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        } */}
                        <Stack direction={'row'} justifyContent={'space-between'}>
                            <ButtonGroup variant='outlined' disableRipple>
                                <Button variant={type === 'assigned' ? 'contained' : 'outlined'}
                                    onClick={() => setType('assigned')} disableElevation
                                    className={classes.badge}
                                    color='secondary'>Recently Assigned
                                    <Badge badgeContent={assigned_Leads?.length || 0} showZero variant='standard' color='primary' sx={{ mx: 2, '& > span': { bgcolor: type === 'assigned' ? theme => theme.palette.primary.main : grey[300] } }} />
                                </Button>
                                <Button variant={type === 'processing' ? 'contained' : 'outlined'}
                                    onClick={() => setType('processing')} disableElevation
                                    className={classes.badge}
                                    color='secondary'>Under Processing <Badge badgeContent={processing_Leads?.length || 0} showZero variant='standard' color='primary' sx={{ mx: 2, '& > span': { bgcolor: type === 'processing' ? theme => theme.palette.primary.main : grey[300] } }} />
                                </Button>
                                <Button variant={type === 'finalized' ? 'contained' : 'outlined'}
                                    onClick={() => setType('finalized')} disableElevation
                                    className={classes.badge} color='secondary'>Finalized Leads
                                    <Badge badgeContent={finalized_Leads?.length || 0} showZero variant='standard' color='primary' sx={{ mx: 2, '& > span': { bgcolor: type === 'finalized' ? theme => theme.palette.primary.main : grey[300] } }} />
                                </Button>
                            </ButtonGroup>
                            <Stack direction={'row'} gap={1.5}>
                                <TextField size='small' placeholder='Search' onChange={onSearchHandler} InputProps={{
                                    startAdornment: <InputAdornment position='start'><Search fontSize='small' /></InputAdornment>
                                }} />
                                {/* <Button size='small' disableRipple onClick={() => setShowFilter(!showFilter)} startIcon={<Tune fontSize='small' />} className={classes.btn} disableElevation variant='contained' color='secondary'>Filter</Button> */}
                            </Stack>
                        </Stack>

                        {/* Data */}
                        <Box bgcolor={'#f7f7f7'} mt={4} p={3} borderRadius={'10px'} border={'1px solid lightgrey'}>
                            {
                                isLoading ?
                                    Array(3).fill(0)?.map((el, i) => <Skeleton key={i} height={'20rem'} sx={{ my: '-4rem', borderRadius: '10px' }} animation={'wave'} />)
                                    :
                                    filtered?.length > 0 ? filtered?.map((el, i) => (
                                        <LeadItem key={i}
                                            lead={el} dispatch={formDispatch}
                                            lastAssigned={agentData?.lastAssignedDate}
                                            deadline={agentData?.nextAssignedDate}
                                            acceptLead={(val) => {
                                                setLeadState(prev => ({ ...prev, lead: val }))
                                                setOpen(true)
                                            }}
                                            openLead={(val) => setLeadState({ lead: val, confirm: true })}
                                            isFinalized={el?.isContractSigned === true && el?.progress?.some(el => el?.status === 'lead_level_6')}
                                        />
                                    ))
                                        :
                                        <Box textAlign={'center'} py={3}>
                                            <img alt='nolead' src={LeadFiles} width='10%' style={{ marginBottom: '1rem' }} />
                                            <Typography color={'GrayText'}>No Premier Leads</Typography>
                                        </Box>
                            }
                        </Box>
                    </Box>
            }

            {/* Dialog */}
            <Dialog open={open}>
                <DialogContent>
                    <Stack direction={'row'} gap={2} alignItems={'flex-start'}>
                        <img src={ConfirmIcon} width={'12%'} alt='confirm_icon' />
                        {agentData?.isFirstTime ?
                            <Box>
                                <Typography fontSize={'1.2rem'}>Confirm</Typography>
                                <Typography variant='body2' color={'GrayText'}>By Accepting this Lead, you agree to our Terms and Conditions</Typography>
                            </Box>
                            :
                            <Box>
                                <Typography fontSize={'1.2rem'}>Confirm Broker</Typography>
                                <Typography variant='body2' color={'GrayText'}>Have you switched your broker since the last transaction with BPOTech?</Typography>
                                <RadioGroup row
                                    value={formInput?.changedLicense}
                                    onChange={(e) => formDispatch({ type: 'CHANGED_LICENSE', payload: e?.target?.value })}
                                >
                                    <FormControlLabel value="yes" sx={{ mr: 2 }} control={<Radio size='small' color='secondary' />} label="Yes" />
                                    <FormControlLabel value="no" control={<Radio size='small' color='secondary' />} label="No" />
                                </RadioGroup>
                            </Box>
                        }
                    </Stack>
                </DialogContent>
                <DialogActions sx={{ px: 3, pb: 2 }}>
                    <LoadingButton disabled={load} disableElevation variant='outlined' size='small' className={classes.btn} onClick={() => { setLeadState({ confirm: false, id: '', type: '' }); setOpen(false) }}>Cancel</LoadingButton>
                    <LoadingButton loading={load} disableElevation variant='contained' color='secondary' size='small' className={classes.btn} onClick={() => {
                        setLoad(true)
                        setTimeout(() => {
                            setLeadState(prev => ({ ...prev, confirm: true }))
                            setOpen(false)
                            setLoad(false)
                        }, 1000)
                    }}>{!agentData?.isFirstTime ? 'Continue' : 'I agree, continue'}</LoadingButton>
                </DialogActions>
            </Dialog>
        </Container>
    )
}

export default PremierLeads