import React from 'react';
import { makeStyles, styled } from '@mui/styles';
import {
	Box,
	Button as Buttoner,
	Grid,
	IconButton,
	InputAdornment,
	InputLabel,
	OutlinedInput,
	Divider,
	Typography,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
} from '@mui/material';
import { Close, Visibility, VisibilityOff } from '@mui/icons-material';
import { useState } from 'react';
import { Fragment } from 'react';
import base from '../../apis';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import Spinner from '../../components/Spinner/Spinner';
import { useNavigate } from 'react-router-dom';
import { signout } from '../../actions';

const useStyles = makeStyles((theme) => ({
	root: {
		minHeight: '60vh',
		padding: '10px',
	},
	passwordContainer: {
		display: 'flex',
		// maxWidth: '40%',
		// marginRight: 'auto',
		flexDirection: 'column',
	},
}));

const Button = styled(Buttoner)(({ theme }) => ({
	textTransform: 'capitalize',
}));

function AccountSettings() {
	const classes = useStyles();
	const [showPassword, setShowPassword] = useState(false);
	const userId = useSelector((state) => state.auth.id);
	const canDeleteAccount = useSelector((state) => state.auth.role !== 'agent');
	const [passwords, setPasswords] = useState([
		{
			name: 'Current password',
			value: '',
		},
		{
			name: 'New password',
			value: '',
		},
		{
			name: 'Confirm password',
			value: '',
		},
	]);

	const handleInputChange = (e, index) => {
		const { value } = e.target;
		setPasswords((pass) => {
			const newPass = [...pass];
			newPass[index].value = value;
			return newPass;
		});
	};

	const handlePasswordChange = async () => {
		for (let index = 0; index < checks.length; index++) {
			const element = checks[index];
			if (!element.check) {
				toast.error(element.errorMessage);
				return;
			}
		}

		if (!passwords[1].value) {
			toast.error('Enter your current password');
			return;
		}
		if (passwords[1].value !== passwords[2].value) {
			toast.error('Passwords do not match');
			return;
		}

		try {
			setIsLoading(true);
			await base.put(`/core/change-password/${userId}/`, {
				old_password: passwords[0].value,
				password: passwords[1].value,
				password2: passwords[2].value,
			});
			toast.success('Password changed successfully');
		} catch (err) {
			let message = 'Error changing password';
			if (err.response.data.old_password) {
				message = err.response.data.old_password.old_password;
			}
			toast.error(message);
		} finally {
			//set is loading to false
			setIsLoading(false);
		}
	};

	const [isLoading, setIsLoading] = useState(false);

	const match = passwords[1].value && passwords[2].value && passwords[1].value === passwords[2].value;
	const checks = [
		{
			title: 'New password entered',
			errorMessage: 'Enter a new password',
			check: passwords[1].value,
		},
		{
			title: 'New passwords match',
			errorMessage: 'Passwords do not match',
			check: match,
		},
		{
			title: 'More than 8 characters',
			check: passwords[1].value.length > 8 && passwords[2].value.length > 8,
			errorMessage: 'Password must be more than 8 characters',
		},
		{ title: 'Contains at least one number', check: passwords[1].value.match(/\d+/g), errorMessage: 'Password must contain at least one number' },
	];

	const [deleteModalOpen, setDeleteModalOpen] = useState(false);
	const toggleDeleteModal = (open = false) => {
		setDeleteModalOpen(open);
	};

	const navigate = useNavigate();
	const dispatch = useDispatch();
	const onAttemptDelete = async () => {
		try {
			setIsLoading(true);
			await base.delete(`/operations/delete-user/${userId}/`);
			toast.success('Account deleted successfully');
			dispatch(signout());
			navigate('/');
		} catch {
			toast.error('Error deleting account');
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<>
			{isLoading && <Spinner />}
			<Dialog
				sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
				maxWidth='xs'
				open={deleteModalOpen}
				onClose={() => toggleDeleteModal()}
			>
				<DialogTitle>
					Delete Account{' '}
					<IconButton onClick={() => toggleDeleteModal()} sx={{ position: 'absolute', top: '5%', right: '5%' }}>
						<Close fontSize='small' />
					</IconButton>
				</DialogTitle>
				<DialogContent>
					Are you sure you want to <b>delete</b> your account?. This process is irreversible and cannot undone.
				</DialogContent>
				<DialogActions>
					<Button variant='outlined' color='secondary' onClick={() => toggleDeleteModal()}>
						Cancel
					</Button>
					<Button variant='outlined' color='error' onClick={onAttemptDelete}>
						Delete Account
					</Button>
				</DialogActions>
			</Dialog>
			<Box>
				<Typography>Change password</Typography>
				<Grid container spacing={4}>
					<Grid item lg={6} xl={6} md={12} sm={12} xs={12}>
						<Box className={classes.passwordContainer}>
							{passwords.map((input, index) => (
								<Fragment key={input.name}>
									<InputLabel>{input.name}</InputLabel>
									<OutlinedInput
										size='small'
										error={index === 2 && passwords[1].value !== passwords[2].value}
										helpertext='Password must be at least 8 characters long'
										onChange={(e) => handleInputChange(e, index)}
										value={input.value}
										type={showPassword ? 'text' : 'password'}
										endAdornment={
											<InputAdornment position='end'>
												<IconButton
													size='small'
													onClick={() => setShowPassword((cur) => !cur)}
													// onMouseDown={handleMouseDownPassword}
												>
													{showPassword ? <VisibilityOff fontSize='14px' /> : <Visibility fontSize='14px' />}
												</IconButton>
											</InputAdornment>
										}
									/>
								</Fragment>
							))}
							<Button
								variant='contained'
								color='secondary'
								sx={{ width: 'fit-content', display: 'block', marginLeft: 'auto', marginTop: '1rem', textTransform: '' }}
								onClick={handlePasswordChange}
							>
								Change Password
							</Button>
						</Box>
					</Grid>
					<Grid item lg={6} xl={6} md={12} sm={12} xs={12} display='flex' justifyContent='center' flexDirection='column'>
						{checks.map((check) => (
							<Box display='flex' alignItems='center' gap='10px' key={check.title}>
								{check.check ? <CheckCircleIcon fontSize='14px' color='success' /> : <CancelIcon fontSize='14px' color='error' />}
								<Typography color={check.check ? 'grey' : 'unset'}>{check.title}</Typography>
							</Box>
						))}
					</Grid>
				</Grid>
				{canDeleteAccount && (
					<>
						<Divider sx={{ marginTop: '1rem', marginBottom: '1rem' }}>Delete Account</Divider>
						<Typography>Deleting your account will permanently delete all your data. This action cannot be undone.</Typography>
						<Button
							variant='contained'
							color='error'
							sx={{ width: 'fit-content', display: 'block', marginLeft: 'auto', marginTop: '1rem', textTransform: '' }}
							onClick={() => toggleDeleteModal(true)}
						>
							Delete Account
						</Button>
					</>
				)}
			</Box>
		</>
	);
}

export default AccountSettings;
