import { ChevronLeft, ChevronRight, Email, Language, LocationOn, Phone } from '@mui/icons-material';
import { Avatar, Button, Chip, Grid, IconButton, Paper, Rating, Skeleton, Stack, Tooltip, Typography } from '@mui/material';
import { makeStyles, styled } from '@mui/styles';
import axios from 'axios';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import ImagesCarousel from '../components/ImagesCarousel';
import RequestAQuote from '../components/RequestAQuote';
import { URL } from '../constants';
import { ReactComponent as Emeservice } from '../images/emergencyService.svg';
import { ReactComponent as FBIcon } from '../images/facebookfillable.svg';
import { ReactComponent as FreeConsu } from '../images/freeConsult.svg';
import { ReactComponent as FreeEsti } from '../images/freeEstimate.svg';
import BG from '../images/indItemBg.jpg';
import { ReactComponent as IGIcon } from '../images/instagramfillable.svg';
import { ReactComponent as LIIcon } from '../images/linkedInfillable.svg';
import { ReactComponent as LocalOwned } from '../images/locallyOwned.svg';
import { ReactComponent as OfferCom } from '../images/offerComme.svg';
import { ReactComponent as OfferPlan } from '../images/offersPlan.svg';
import { ReactComponent as SatGuar } from '../images/satGuarante.svg';
import { ReactComponent as TWIcon } from '../images/twitterfillable.svg';
import { ReactComponent as YearsExp } from '../images/yearsExper.svg';
import { ReactComponent as YTIcon } from '../images/youtubefillable.svg';
import { hideButton, scrollToTheLeft } from '../utils/functions';

const highlights = [
	{
		title: 'Free Estimate',
		Icon: <FreeEsti />,
		key: 'free',
	},
	{
		title: 'Offers Payment Plan',
		Icon: <OfferPlan />,
		key: 'plan',
	},
	{
		title: 'Locally Owned & Operated',
		Icon: <LocalOwned />,
		key: 'local',
	},
	{
		title: 'Years In Business',
		Icon: <YearsExp />,
		key: 'years',
	},
	{
		title: 'Satisfaction Guaranteed',
		Icon: <SatGuar />,
		key: 'satisfaction',
	},
	{
		title: 'Offers Commercial Services',
		Icon: <OfferCom />,
		key: 'commercial',
	},
	{
		title: 'Free Consultation',
		Icon: <FreeConsu />,
		key: 'consultation',
	},
	{
		title: 'Emergency Services',
		Icon: <Emeservice />,
		key: 'emergency',
	},
];

const useStyles = makeStyles((theme) => ({
	root: {
		minHeight: 'calc(100vh - var(--navheight))',
	},
	bluer: {
		height: '40vh',
		width: '100%',
		backgroundSize: 'cover',
		backgroundPosition: 'center',
	},
}));

const WhiteIconButton = styled(IconButton)(({ theme }) => ({
	border: '1px solid black',
	borderRadius: '50%',
	width: '40px',
	aspectRatio: '1',
}));

const socials = [
	{
		key: 'web_link',
		Icon: <Language sx={{ fill: 'black' }} />,
	},
	{
		key: 'facebook',
		Icon: <FBIcon fill='black' />,
	},
	{
		key: 'twitter',
		Icon: <TWIcon fill='black' />,
	},
	{ key: 'instagram', Icon: <IGIcon fill='black' /> },
	{
		key: 'linkedin',
		Icon: <LIIcon fill='black' />,
	},
	{
		key: 'youtube',
		Icon: <YTIcon fill='black' />,
	},
];

const IndustryProfessionalId = () => {
	const classes = useStyles();
	const [isLoading, setIsLoading] = useState(true);
	const [profDetails, setProfDetails] = useState(null);
	const { id } = useParams();
	const [imagesDialogOpen, setImagesDialogOpen] = useState(false);
	const [selectedPhotoIndex, setSelectedPhotoIndex] = useState(0);
	const [requestOpen, setRequestOpen] = useState(false);

	useEffect(() => {
		(async () => {
			try {
				setIsLoading(true);
				const { data } = await axios.get(URL + `professionals/${id}/`);
				setProfDetails(data);
			} catch (error) {
				toast.error('Could not get details');
			} finally {
				setIsLoading(false);
			}
		})();
	}, [id]);

	if (isLoading) {
		return (
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Skeleton width='100%' height='50vh' variant='rounded' />
				</Grid>
				<Grid item xs={9}>
					<Skeleton width='100%' height='50vh' variant='rounded' />
				</Grid>
				<Grid item xs={3}>
					<Skeleton width='100%' height='50vh' variant='rounded' />
				</Grid>
			</Grid>
		);
	}
	return (
		<>
			<ImagesCarousel open={imagesDialogOpen} close={() => setImagesDialogOpen(false)} images={profDetails?.images} startItem={selectedPhotoIndex} />
			<Stack className={classes.root}>
				<Stack
					className={classes.bluer}
					sx={{
						background: `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),url(${profDetails?.bg_image || BG})`,
					}}
				></Stack>
				<Grid
					container
					spacing={2}
					sx={{
						px: {
							xl: '5rem',
							lg: '5rem',
							md: '5rem',
							sm: '2rem',
							xs: '1rem',
						},
					}}
				>
					<Grid item xs={12} sm={12} md={12} lg={8}>
						<Stack sx={{ marginTop: '-10rem' }} gap='2rem'>
							<Stack direction='row' gap='1rem'>
								<Avatar
									sx={{
										width: {
											xs: 100,
											sm: 150,
											md: 200,
											lg: 200,
										},
										height: {
											xs: 100,
											sm: 150,
											md: 200,
											lg: 200,
										},
										border: '5px solid white',
										backgroundColor: 'white',
										boxShadow: '0px 1px 2px 0px #00000030',
									}}
									src={profDetails?.icon_file}
								/>
								<Stack justifyContent={'center'} alignItems={'flex-start'}>
									<Typography fontWeight={700} fontSize={32} color='white'>
										{profDetails?.company_name}
									</Typography>
									<Typography fontWeight={300} fontSize={20} color='white'>
										{profDetails?.address}
									</Typography>
									<Typography fontWeight={400} fontSize={20} color='white'>
										{profDetails?.city?.replace('county', '')}
									</Typography>
								</Stack>
							</Stack>
							{profDetails?.bio && <Spoiler text={profDetails.bio} />}
							{profDetails?.services.length > 0 && (
								<Stack gap='1rem'>
									<Typography fontWeight={700}>Services</Typography>
									<Stack direction='row' gap='1rem' sx={{ flexWrap: 'wrap' }}>
										{profDetails?.services.map((item) => (
											<Chip color='secondary' label={item} key={item} />
										))}
									</Stack>
								</Stack>
							)}
							<Stack direction='row' gap='1rem' alignItems='center'>
								{socials.map((i) => i.key).some((i) => profDetails?.[i]) && <Typography fontWeight={700}>Follow Us</Typography>}
								{socials.map((social) => (
									<Fragment key={social.key}>
										{profDetails?.[social.key] && (
											<WhiteIconButton href={profDetails?.[social.key]} target='_blank'>
												{social.Icon}
											</WhiteIconButton>
										)}
									</Fragment>
								))}
							</Stack>
						</Stack>
						<Typography fontWeight={800} mt='2rem' mb='1rem'>
							Highlights from the Business
						</Typography>
						<Stack direction='row' gap='3rem 1rem' sx={{ flexWrap: 'wrap' }} alignItems='center'>
							{highlights?.map((item) => {
								const show = profDetails?.highlight?.includes(item.key) || !(profDetails?.highlight?.length > 0);
								if (!show) return null;
								return (
									<Stack
										gap='1rem'
										key={item.title}
										sx={{
											width: {
												lg: '16%',
												xl: '16%',
												md: '20%',
												sm: '40%',
												xs: '40%',
											},
										}}
										alignItems='center'
									>
										{item.Icon}
										<Typography fontWeight={300} fontSize={'18px'} textAlign={'center'}>
											{item.title}
										</Typography>
									</Stack>
								);
							})}
						</Stack>
						{profDetails?.images.length > 0 && (
							<Stack my='2rem' gap='1rem'>
								<Typography fontWeight={800}>Images</Typography>
								<Stack direction='row'>
									<Stack sx={{ background: 'white', position: 'relative', width: '60px' }} alignItems='center' justifyContent={'center'}>
										<IconButton onClick={() => scrollToTheLeft('indProfcarousel', true)} ref={(el) => hideButton(el, 'indProfcarousel')}>
											<ChevronLeft />
										</IconButton>
									</Stack>
									<Stack
										id='indProfcarousel'
										sx={{
											flex: '1',
											overflowX: 'scroll',
											maxWidth: '100%',
											scrollBehavior: 'smooth',
											'&::-webkit-scrollbar': {
												display: 'none',
											},
										}}
										direction='row'
										gap='10px'
									>
										{profDetails?.images.map((i, index) => (
											<img
												src={i.image}
												key={i.image}
												alt='listing'
												onClick={() => {
													setImagesDialogOpen(true);
													setSelectedPhotoIndex(index);
												}}
												style={{ width: '200px', aspectRatio: '1', borderRadius: '5px', objectFit: 'cover', cursor: 'pointer' }}
											/>
										))}
									</Stack>
									<Stack sx={{ background: 'white', position: 'relative', width: '60px' }} alignItems='center' justifyContent={'center'}>
										<IconButton onClick={() => scrollToTheLeft('indProfcarousel', false)} ref={(el) => hideButton(el, 'indProfcarousel')}>
											<ChevronRight />
										</IconButton>
									</Stack>
								</Stack>
							</Stack>
						)}
						{profDetails?.reviews?.length > 0 && (
							<Stack gap='1rem' mt='2rem'>
								<Typography fontWeight={800} fontSize={20}>
									Reviews
								</Typography>
								{profDetails?.reviews.map((review) => (
									<Stack gap='1rem' key={review.id}>
										<Stack gap='1rem'>
											<Stack direction='row' gap='1rem' alignItems='center'>
												<Avatar src={review.profile} />
												<Stack direction='row' alignItems='center' gap='1rem'>
													<Typography fontWeight={700}>{review.name}</Typography>
													<Typography fontWeight={400} fontSize={'12px'} color='#818181'>
														{new Date(review.date).toLocaleDateString('en-US', {
															year: 'numeric',
															month: 'short',
															day: 'numeric',
														})}
													</Typography>
												</Stack>
											</Stack>
											<Rating readOnly value={review.rating} />
										</Stack>
										<Typography fontSize='16px' color='#2D2E2F' fontWeight='300'>
											{review.description}
										</Typography>
									</Stack>
								))}
							</Stack>
						)}
					</Grid>
					<Grid
						item
						xs={12}
						sm={12}
						md={12}
						lg={4}
						sx={{
							position: {
								xs: 'static',
								sm: 'static',
								md: 'static',
								lg: 'sticky',
								xl: 'sticky',
							},
							top: {
								md: '200px',
								lg: '200px',
								xs: 0,
							},

							maxHeight: '60vh',
						}}
					>
						<Stack
							gap='1rem'
							sx={{
								maxWidth: {
									lg: '400px',
								},
							}}
						>
							<Paper>
								{requestOpen ? (
									<RequestAQuote id={id} gap='10px' />
								) : (
									<Stack p='1.5rem' gap='1rem'>
										<Button
											variant='contained'
											sx={{ color: 'white', textTransform: 'capitalize', fontSize: '16px' }}
											type='submit'
											onClick={() => setRequestOpen(true)}
										>
											Request A Quote
										</Button>
									</Stack>
								)}
							</Paper>
							{(profDetails?.web_link || profDetails?.formatted_phone_number || profDetails?.address || profDetails?.service_areas.length > 0) && (
								<Paper>
									<Stack gap='1rem' sx={{ background: 'white', borderRadius: '4px', borderLeft: '5px solid #FFAA01' }} padding='1rem'>
										{profDetails?.web_link && (
											<Stack gap='1rem' direction='row' alignItems='center'>
												<Language sx={{ fill: '#818181' }} />
												<Typography
													fontWeight={600}
													color='#252525'
													component='a'
													href={profDetails?.web_link}
													target='_blank'
													sx={{ cursor: 'pointer', textOverflow: 'ellipsis', overflow: 'hidden' }}
												>
													{profDetails?.web_link}
												</Typography>
											</Stack>
										)}
										<Stack gap='1rem' direction='row' alignItems='center'>
											<Phone sx={{ fill: '#818181' }} />
											<Typography fontWeight={600} color='#252525' component='a' href={`tel:${profDetails?.formatted_phone_number}`}>
												{profDetails?.formatted_phone_number}
											</Typography>
										</Stack>
										{profDetails?.address && (
											<Stack gap='1rem' direction='row' alignItems='center'>
												<Email sx={{ fill: '#818181' }} />
												<Tooltip title={profDetails?.address}>
													<Typography fontWeight={600} color='#252525' sx={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
														{profDetails?.address}
													</Typography>
												</Tooltip>
											</Stack>
										)}
										{profDetails?.service_areas.length > 0 && (
											<Stack gap='1rem' direction='row' alignItems='center'>
												<LocationOn sx={{ fill: '#818181' }} />
												<Tooltip title={profDetails?.service_areas.map((item) => item.location).toString()}>
													<Typography fontWeight={600} color='#252525' sx={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
														{profDetails?.service_areas.map((item) => item.location).toString()}
													</Typography>
												</Tooltip>
											</Stack>
										)}
									</Stack>
								</Paper>
							)}
						</Stack>
					</Grid>
				</Grid>
			</Stack>
		</>
	);
};

export default IndustryProfessionalId;

export function Spoiler({ text, maxWidth = '70%', buttonProps }) {
	const [open, setOpen] = useState(false);
	const [showSpoiler, setShowSpoiler] = useState(false);
	const contentRef = useRef(null);

	useEffect(() => {
		const lineHeight = parseFloat(getComputedStyle(contentRef.current).lineHeight);
		const height = contentRef.current.offsetHeight;
		const maxHeight = lineHeight * 3; // 3 lines of content

		if (height > maxHeight && maxHeight) {
			setShowSpoiler(true);
		}
	}, [text]);
	if (showSpoiler) return null;

	return (
		<div>
			<div
				ref={contentRef}
				style={{
					maxHeight: !open ? '4.5em' : 'none', // 4.5em equals 3 lines with line-height of 1.5em
					overflow: 'hidden',
					textOverflow: 'ellipsis',
				}}
			>
				<Typography fontWeight={400} fontSize={'16px'} sx={{ maxWidth, whiteSpace: 'pre-line' }}>
					{text}
				</Typography>
			</div>
			<Button variant='text' color='secondary' onClick={() => setOpen(!open)} mt='2rem' {...buttonProps}>
				{open ? 'Hide' : 'Show more'}
			</Button>
		</div>
	);
}
