import React, { useState, useEffect, useCallback } from "react";
import useTheme from "@mui/material/styles/useTheme";
import {
  Box,
  Button,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";
import FlatRatePercentageInput from "../../atomicComponents/FlatRatePercentageInput";
import PurchasePrice from "../../atomicComponents/PurchasePrice";
import Points from "../../atomicComponents/Points";
import ExtraOptionsToggle from "../../atomicComponents/ExtraOptionsToggle";
import WholesaleFee from "../../atomicComponents/WholesaleFee";
import EnhancedCheckBoxGroup from "../../atomicComponents/EnhancedCheckBoxGroup";
import LoanAmount from "../../atomicComponents/LoanAmount";
import InterestTermResult from "../../atomicComponents/InterestTermResult";
import InterestOnlyMortgageResult from "../../atomicComponents/InterestOnlyMortgageResult";
import { makePmiCompulsory } from "../../utils";
import PurchasePriceWithRemodelCost from "../../atomicComponents/PurchasePriceWithRemodelCost";
import { sarLoanAmount } from "../../utils/loanAmount";
import InterestOnlyMortgage from "../../atomicComponents/InterestOnlyMortgage";
import { useMemo } from "react";
import {
  interestOnlyMortgageCalculator,
  isNaNChecker,
  mortgageLoanCalculator,
  yearsToMonthsTransformer,
} from "../../localCalcutions/utils/genericFunctions";
import * as actionCreators from "../../../../actions/dealAnalyzer/index";
import StyledLabel from "../../atomicComponents/StyledLabel";
import { preventMinus } from "../../utils/preventNegativeValues";

const useStyle = (theme) => {
  return {
    root: {
      padding: "2rem",
    },
    heading: {
      color: theme.palette.primary.main,
      display: "flex",
      justifyContent: "center",
      marginTop: "5rem",
      padding: "0.5rem",
    },
    span: {
      borderBottom: `solid 3px ${theme.palette.primary.main} `,
    },
    flatRate: {
      marginTop: "2rem",
    },

    lenderPointsExtra: {
      marginTop: "1rem",
    },
    button: {
      width: "100%",
      margin: "10px 0 10px 0",
      padding: "1rem",
    },
  };
};

function PrimaryHomeLoanBuyAndSell({
  data,
  handler,
  mortgagePrimaryResultLoading,
  fetchResult,
  labelsAndNames,
  dealAnalyzerResultsData,
  remodelCost,
}) {
  const theme = useTheme();
  const styles = useStyle(theme);
  const rateHandler = (value) => {
    handler({ primary_dollar_or_percent: value });
  };
  const [showMortgageResult, setShowMortgageResult] = useState(false);
  const [pmi_value, setPmiValue] = useState(+data.pmi_value);
  const dealFinanceOptionIsPayAllCash = +data?.deal_finance_option === 5;
  const primaryLoanTypeIsConventional =
    +data?.primary_loan_type === 0 || !data?.primary_loan_type;
  const primaryLoanTypeIsPrivate = +data?.primary_loan_type === 1;
  const primaryLoanTypeIsSeller = +data?.primary_loan_type === 2;
  const isSellerPrimaryLoanTypeAndMortgage =
    primaryLoanTypeIsSeller && +data?.primary_sub_loan_type === 1;
  const isPrivatePrimaryLoanTypeAndMortgage =
    primaryLoanTypeIsPrivate && +data?.primary_sub_loan_type === 1;
  const mortgageData = useSelector(
    (state) =>
      state?.straightAestheticRemodel.dealAnalyzerResults
        ?.primary_finance_option_summary?.interest_only_mortgage_loan
  );

  const monthlyPayment =
    dealAnalyzerResultsData?.primary_finance_option_summary
      ?.total_monthly_payment || 0;

  const showPurchasePriceOnly = () => {
    const option = data?.deal_finance_option;
    const match = [0, 1, 2];
    return !option || match.includes(+option);
  };
  const showPurchasePriceAndRemodelPrice = () => {
    const option = +data?.deal_finance_option;
    const match = [3, 5];
    return match.includes(+option);
  };

  const showRemodelPriceOnly = () => {
    return +data?.deal_finance_option === 4;
  };
  const showPrimaryConventionalLoan = () => {
    const dealFinanceOption = +data?.deal_finance_option;
    const primaryLoanType = +data?.primary_loan_type;
    const subType = +data?.primary_sub_loan_type;
    const deals = [1, 2, 3, 4, 5];
    const basedOnDeals =
      deals.includes(+dealFinanceOption) || !dealFinanceOption;
    const basedOnLoanTypes = !+primaryLoanType;
    const basedOnSubType = !+subType;
    return basedOnDeals && (basedOnLoanTypes || basedOnSubType);
  };
  const showPrimaryMortgageLoan = () => {
    return (
      (isPrivatePrimaryLoanTypeAndMortgage ||
        isSellerPrimaryLoanTypeAndMortgage) &&
      !primaryLoanTypeIsConventional &&
      !dealFinanceOptionIsPayAllCash
    );
  };
  const handleShowmortgageResult = () => {
    fetchResult("primary");
    setShowMortgageResult(true);
  };
  const toolTipText = {
    primaryLenderPointsInfo:
      "Commission payable to Lender or Mortgage Broker for providing the loan. Usually between 0 - 3 percent of the loan amount.",
    primaryWholesaleFee:
      "This is a finders fee paid to an individual who found the home",
    primaryDownPayment:
      "The amount of cash or capital you are applying to the purchase price",
    includePmi: {
      title: "Private Mortgage Insurance",
      text: "A type of insurance that is often required for conventional loan borrowers with a down payment of less than 20% of the home's purchase price. PMI insures the lender's loan in the event the Borrower/Buyer defaults on the loan.",
    },
  };

  const getPurchaseAmount = () => {
    const dealFinanceType = +data?.deal_finance_option || 0;
    switch (dealFinanceType) {
      case 0:
        return data?.primary_purchase_price;
      case 1:
        return data?.primary_purchase_price;
      case 2:
        return data?.primary_purchase_price;
      case 3:
        return +data?.primary_purchase_price + +remodelCost;
      case 4:
        return +remodelCost;
      case 5:
        return data?.primary_purchase_price;
      default:
        return "";
    }
  };

  const localLoanAmount = sarLoanAmount({
    purchasePrice: getPurchaseAmount(),
    pmi_value,
    lenderPoints: data?.primary_lender_points,
    wholesaleFee: data?.primary_wholesale_fee,
    pmi: data?.primary_include_pmi,
    downPayment: data?.primary_down_payment,
    downPaymentRate: data?.primary_dollar_or_percent,
    wholesaleFeeOption: data?.primary_wholesale_fee_options,
    lenderpointOption: data?.primary_lender_points_options,
  });

  useEffect(() => {
    const checkPmi = makePmiCompulsory(
      data?.primary_purchase_price,
      data?.primary_down_payment,
      data?.primary_dollar_or_percent
    );
    if (checkPmi) {
      handler({ primary_include_pmi: true });
    } else {
      handler({ primary_include_pmi: false });
    }
    // NOTE: Run effect once on component mount, please
    // recheck dependencies if effect is updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    data?.primary_purchase_price,
    data?.primary_down_payment,
    data?.primary_dollar_or_percent,
  ]);

  const dispatch = useDispatch();

  const localGeneralLoanAmount = useCallback(
    () => {
      const amount = { localGeneralLoanAmount: localLoanAmount?.effectiveLoan };
      dispatch(actionCreators.updateFinanceOptions(amount));
    },

    // recheck dependencies if effect is updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [localLoanAmount]
  );

  // dispatch PMI Value
  const dispatchPmi = useCallback(() => {
    handler({ pmi_value });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pmi_value]);

  useEffect(() => {
    if (!pmi_value) return;

    dispatchPmi();

    // recheck dependencies if effect is updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatchPmi]);
  useEffect(() => {
    localGeneralLoanAmount();

    // recheck dependencies if effect is updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localGeneralLoanAmount]);

  //================================= local calculations ========================//
  const financialOptions = useSelector(
    (state) => state?.straightAestheticRemodel.financeOptions
  );

  // primary monthly payment
  const localPrimaryLoanAmount = financialOptions.localGeneralLoanAmount;
  const localPrimaryRate = +financialOptions.primary_interest_rate;
  const localPrimaryTermsOfLoan = +financialOptions.primary_term_of_loan;

  // const localTotalRemodelCost = financialOptions.totalRemodelCost

  //console.log(mortgageData)

  const localPrimaryAmount = useMemo(() => {
    const time = localPrimaryTermsOfLoan * 12;
    const rate = localPrimaryRate / 100 / 12;
    const amount = mortgageLoanCalculator(localPrimaryLoanAmount, rate, time);

    dispatch(
      actionCreators.updateFinanceOptions({
        localMonthlyAmount: isNaNChecker(amount),
      })
    );
    return isNaNChecker(amount);
    // NOTE: Run effect once on component mount, please
    // recheck dependencies if effect is updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localPrimaryLoanAmount, localPrimaryRate, localPrimaryTermsOfLoan]);

  //Obtain Single Primary Loan for Home & Remodel calcs
  const primaryAnnualRate = financialOptions.primary_annual_interest_rate;
  const primaryOnlyTermFor = financialOptions.primary_interest_only_term;
  const primaryMortgageAmount = financialOptions.localGeneralLoanAmount;
  const primaryMonthOrYearChecker =
    financialOptions.primary_interest_only_term_month_or_year;

  const PrimaryInterestAloneMortgageFunc = useCallback(() => {
    const timeTransformer = yearsToMonthsTransformer(
      primaryMonthOrYearChecker,
      primaryOnlyTermFor
    );
    const res = interestOnlyMortgageCalculator(
      primaryAnnualRate,
      primaryMortgageAmount
    );

    dispatch(
      actionCreators.updateFinanceOptions({
        primaryInterestOnly: res,
        primaryInterestOnlyTime: timeTransformer,
      })
    );

    // recheck dependencies if effect is updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    primaryAnnualRate,
    primaryOnlyTermFor,
    primaryMortgageAmount,
    primaryMonthOrYearChecker,
  ]);

  useEffect(() => {
    // recheck dependencies if effect is updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    PrimaryInterestAloneMortgageFunc();
  }, [PrimaryInterestAloneMortgageFunc]);

  const primaryInterestOnly = financialOptions?.primaryInterestOnly;
  const primaryMonthOfTerms = financialOptions?.primaryInterestOnlyTime;

  const localOnlyPayment = primaryInterestOnly
    ? {
        interest_only_payment_term: primaryMonthOfTerms,
        interest_only_payment: primaryInterestOnly,
      }
    : 0;

  const interestOnlyPayment = localOnlyPayment || mortgageData;
  const monthlyPrimaryLoanPayment = localPrimaryAmount || monthlyPayment;

  return (
    <>
      {showPrimaryConventionalLoan() && (
        <Box>
          <Box sx={styles.purchasePrice}>
            {showPurchasePriceOnly() && (
              <PurchasePrice
                handler={handler}
                name="primary_purchase_price"
                data={data}
                label={
                  +data?.deal_finance_option === 0 ? "Rental Cost Amount" : ""
                }
              />
            )}
            {showRemodelPriceOnly() && (
              <PurchasePrice
                handler={handler}
                name="primary_remodel_cost"
                label="Remodel Cost"
                data={data}
              />
            )}
            {showPurchasePriceAndRemodelPrice() && (
              <PurchasePriceWithRemodelCost
                handler={handler}
                labelArray={
                  labelsAndNames
                    ? labelsAndNames.map((el) => el?.label)
                    : ["Purchase Price", "Remodel Cost Amount"]
                }
                nameArray={
                  labelsAndNames
                    ? labelsAndNames.map((el) => el?.name)
                    : ["primary_purchase_price", "primary_remodel_cost"]
                }
                remodelCost={remodelCost}
                data={data}
              />
            )}
          </Box>
          {+data?.deal_finance_option !== 5 && (
            <Box>
              <Points
                handler={handler}
                label="Lender Points"
                name="primary_lender_points"
                toolTipText={toolTipText.primaryLenderPointsInfo}
                data={data}
              />
              <ExtraOptionsToggle
                options={lenderPointOptions}
                handler={handler}
                name="primary_lender_points_options"
                data={data}
                showText="Lender Points Options"
              />
            </Box>
          )}

          <Box sx={styles.purchasePrice}>
            <WholesaleFee
              handler={handler}
              name="primary_wholesale_fee"
              tooltipText={toolTipText.primaryWholesaleFee}
              data={data}
            />
            <ExtraOptionsToggle
              options={wholesaleFeeOptions}
              handler={handler}
              name="primary_wholesale_fee_options"
              data={data}
              showText="Wholesale Fee Options"
            />
          </Box>
          {!dealFinanceOptionIsPayAllCash && ( //shown only if pay all cash in deal financeoption is false
            <>
              <Box sx={styles.flatRate}>
                <FlatRatePercentageInput
                  title="Down Payment"
                  name="primary_down_payment"
                  rate={
                    data?.primary_dollar_or_percent
                      ? data?.primary_dollar_or_percent
                      : 0
                  }
                  rateName="primary_dollar_or_percent"
                  rateHandler={rateHandler}
                  handler={handler}
                  data={data}
                  toolTipText={toolTipText.primaryDownPayment}
                  referenceValue={getPurchaseAmount()}
                />
              </Box>
              <Box
                display="flex"
                sx={{ alignItems: "center", gap: 6, marginTop: 3 }}
              >
                <EnhancedCheckBoxGroup
                  options={mortgageInsuranceData}
                  data={data}
                  handler={handler}
                  toolTipText={toolTipText.includePmi}
                  name="primary_loan_include_pmi"
                  autoCheckedArray={
                    //supply boolean array to match indexes in options prop
                    [
                      makePmiCompulsory(
                        data?.primary_purchase_price,
                        data?.primary_down_payment,
                        data?.primary_down_payment_dollar_or_percent
                      ),
                    ]
                  }
                />
                <>
                  <StyledLabel label="Enter PMI Percentage" />
                  <Tooltip
                    placement="top"
                    title={
                      data?.primary_include_pmi ? null : (
                        <Typography variant="h5">
                          Check the PMI box to Add your desired PMI percentage
                        </Typography>
                      )
                    }
                  >
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      disabled={!data?.primary_include_pmi}
                      type="number"
                      value={pmi_value}
                      onKeyPress={preventMinus}
                      onChange={(e) => setPmiValue(e.target.value)}
                      inputProps={{ style: { fontSize: 15 }, min: 0 }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">
                            <Typography fontSize={15} fontWeight={"900"}>
                              %
                            </Typography>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Tooltip>
                </>
              </Box>
              <Box>
                <LoanAmount
                  handler={handler}
                  label="Loan Amount"
                  name="primary_loan_amount"
                  loanAmount={
                    localLoanAmount
                      ? localLoanAmount?.effectiveLoan.toFixed(2)
                      : ""
                  }
                  greyed
                />
              </Box>
              <Box>
                <InterestTermResult
                  handler={handler}
                  prefixName="primary"
                  data={data}
                  beforeFetchResult={fetchResult}
                  monthlyPayment={monthlyPrimaryLoanPayment}
                />
              </Box>
            </>
          )}
        </Box>
      )}
      {showPrimaryMortgageLoan() && (
        <Box>
          <PurchasePrice
            handler={handler}
            name="primary_purchase_price"
            data={data}
            label={+data?.deal_finance_option === 0 ? "Rental Cost Amount" : ""}
          />
          <Box>
            <Points
              handler={handler}
              label="Lender Points"
              name="primary_lender_points"
              toolTipText={toolTipText.primaryLenderPointsInfo}
              data={data}
            />
            <ExtraOptionsToggle
              options={lenderPointOptions}
              handler={handler}
              name="primary_lender_points_options"
              data={data}
              showText="Lender Points Options"
            />
          </Box>
          <Box sx={styles.purchasePrice}>
            <WholesaleFee
              handler={handler}
              name="primary_wholesale_fee"
              tooltipText={toolTipText.primaryWholesaleFee}
              data={data}
            />
            <ExtraOptionsToggle
              options={wholesaleFeeOptions}
              handler={handler}
              name="primary_wholesale_fee_options"
              data={data}
              showText="Wholesale Fee Options"
            />
          </Box>
          <Box sx={styles.flatRate}>
            <FlatRatePercentageInput
              title="Down Payment "
              name="primary_down_payment"
              rate={
                data?.primary_dollar_or_percent
                  ? data?.primary_dollar_or_percent
                  : 0
              }
              rateHandler={rateHandler}
              handler={handler}
              data={data}
              toolTipText={toolTipText.primaryDownPayment}
              referenceValue={getPurchaseAmount()}
            />
          </Box>
          <Box>
            <EnhancedCheckBoxGroup
              options={mortgageInsuranceData}
              data={data}
              handler={handler}
              toolTipText={toolTipText.includePmi}
              name="primary_loan_include_pmi"
              autoCheckedArray={
                //supply boolean array to match indexes in options prop
                [
                  makePmiCompulsory(
                    data?.primary_purchase_price,
                    data?.primary_down_payment,
                    data?.primary_down_payment_dollar_or_percentage
                  ),
                ]
              }
            />
          </Box>

          <InterestOnlyMortgage
            data={data}
            handler={handler}
            namePrefix="primary_"
            dealAnalyzerResultsData={dealAnalyzerResultsData}
            mortgageAmount={
              localLoanAmount ? localLoanAmount?.effectiveLoan.toFixed(2) : ""
            }
          />
          <Box sx={{ mt: 2 }} />
          {!interestOnlyPayment && (
            <Button
              sx={styles.button}
              onClick={handleShowmortgageResult}
              variant="contained"
            >
              {!mortgagePrimaryResultLoading && (
                <Typography variant="h5">CALCULATE</Typography>
              )}
              {mortgagePrimaryResultLoading && (
                <ClipLoader color={"#fff"} loading={true} size={16} />
              )}
            </Button>
          )}

          {showMortgageResult && !mortgagePrimaryResultLoading && (
            <InterestOnlyMortgageResult mortgageResults={interestOnlyPayment} />
          )}
        </Box>
      )}
    </>
  );
}

export default PrimaryHomeLoanBuyAndSell;

const lenderPointOptions = [
  "No Payable Lender Points",
  "Add Lender Points to Loan Amount",
  "Add Lender Points to Upfront Closing Cost",
  "Add Lender Points to Back End Closing Costs",
];
const wholesaleFeeOptions = [
  "Add Wholesale Fee to Loan Amount",
  "Add Wholesale Fee to Upfront Closing Cost",
  "Add Wholesale Fee to Back End Closing Cost",
];
const mortgageInsuranceData = [
  {
    name: "primary_include_pmi",
    label: "Add PMI",
  },
];
