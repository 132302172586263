import PropertyAddress from '../../../../components/DealAnalyzerComponents/QuestionnaireUserInputCards/PropertyAddress'
import QuestionnaireDealTypes from '../../../../components/DealAnalyzerComponents/QuestionnaireUserInputCards/QuestionnaireDealTypes'
import QuestionnairePrimaryLoanAmount from '../../../../components/DealAnalyzerComponents/QuestionnaireUserInputCards/QuestionnairePrimaryLoanAmount'
import QuestionnairePrimaryInterestAndTerm from '../../../../components/DealAnalyzerComponents/QuestionnaireUserInputCards/QuestionnairePrimaryInterestAndTerm'
import QuestionnaireSecondaryLoanAmount from '../../../../components/DealAnalyzerComponents/QuestionnaireUserInputCards/QuestionnaireSecondaryLoanAmount'
import QuestionnaireSecondaryInterestAndTerm from '../../../../components/DealAnalyzerComponents/QuestionnaireUserInputCards/QuestionnaireSecondaryInterestTerm'
import QuestionnaireRentalIncome from '../../../../components/DealAnalyzerComponents/QuestionnaireUserInputCards/QuestionnaireRentalIncome'
import QuestionnairePropertyManagement from '../../../../components/DealAnalyzerComponents/QuestionnaireUserInputCards/QuestionnairePropertyManagement'
import ContactInfo from '../../../../components/DealAnalyzerComponents/QuestionnaireUserInputCards/ContactInfo'
import QuestionnairePrimaryLoan from '../../../../components/DealAnalyzerComponents/QuestionnaireUserInputCards/QuestionnairePrimaryLoan'
import QuestionnaireSecondaryLoan  from '../../../../components/DealAnalyzerComponents/QuestionnaireUserInputCards/QuestionnaireSecondaryLoan'
import CashForRemodel from '../../../../components/DealAnalyzerComponents/QuestionnaireUserInputCards/CashForRemodel'
import CashforHomeAndRemodel from '../../../../components/DealAnalyzerComponents/QuestionnaireUserInputCards/CashForHomeAndRemodel'
import QuestionnaireAdditionalSqft from '../../../../components/DealAnalyzerComponents/QuestionnaireUserInputCards/QuestionnaireAdditionalSqft'

function useQuestionnaireRemodelAndAddAdditionFlowControl(dealOption) {
    const flow0 = [
        PropertyAddress,
        QuestionnaireAdditionalSqft,
        QuestionnaireDealTypes,
        QuestionnairePrimaryLoan,
        QuestionnairePrimaryLoanAmount,
        QuestionnairePrimaryInterestAndTerm,
        QuestionnaireSecondaryLoan,
        QuestionnaireSecondaryLoanAmount,
        QuestionnaireSecondaryInterestAndTerm,
        QuestionnaireRentalIncome,
        QuestionnairePropertyManagement, 
        ContactInfo
    ]
    const flow1 = [
        PropertyAddress,
        QuestionnaireAdditionalSqft,
        QuestionnaireDealTypes,
        QuestionnairePrimaryLoan,
        QuestionnairePrimaryLoanAmount,
        QuestionnairePrimaryInterestAndTerm,
        CashForRemodel,
        QuestionnaireRentalIncome,
        QuestionnairePropertyManagement, 
        ContactInfo
    ]
    const flow2 = [
        PropertyAddress,
        QuestionnaireAdditionalSqft,
        QuestionnaireDealTypes,
        QuestionnairePrimaryLoan,
        QuestionnairePrimaryLoanAmount,
        QuestionnairePrimaryInterestAndTerm,
        QuestionnaireRentalIncome,
        QuestionnairePropertyManagement, 
        ContactInfo
    ]
    const flow3 = [
        PropertyAddress,
        QuestionnaireAdditionalSqft,
        QuestionnaireDealTypes,
        QuestionnairePrimaryLoan,
        QuestionnairePrimaryLoanAmount,
        QuestionnairePrimaryInterestAndTerm,
        CashForRemodel,
        QuestionnaireRentalIncome,
        QuestionnairePropertyManagement, 
        ContactInfo
    ]
    const flow4 = [
        PropertyAddress,
        QuestionnaireAdditionalSqft,
        QuestionnaireDealTypes,
        CashforHomeAndRemodel,
        QuestionnaireRentalIncome,
        QuestionnairePropertyManagement, 
        ContactInfo
    ]


    if(!dealOption || +dealOption === 0) {return {currentFlow:flow0, headings:flow0MainHeadings, counterArray:flow0CounterArray}}
    if(+dealOption === 1) {return {currentFlow:flow1, headings:flow1MainHeadings, counterArray:flow1CounterArray}}
    if(+dealOption === 2) {return {currentFlow:flow2, headings:flow2MainHeadings, counterArray:flow2CounterArray}}
    if(+dealOption === 3) {return {currentFlow:flow3, headings:flow3MainHeadings, counterArray:flow3CounterArray}}
    if(+dealOption === 4) {return {currentFlow:flow4, headings:flow4MainHeadings, counterArray:flow4CounterArray}}




}

export default useQuestionnaireRemodelAndAddAdditionFlowControl

const flow0MainHeadings= [
    'Property Address',
    'Square Footage',
    'Finance Options',
    'Finance Options',
    'Finance Options',
    'Finance Options',
    'Finance Options',
    'Finance Options',
    'Finance Options',
    "Rental Income",
    "Property Management & Investors",
    "Contact Info"
]

const flow1MainHeadings= [
    'Property Address',
    'Square Footage',
    'Finance Options',
    'Finance Options',
    'Finance Options',
    'Finance Options',
    'Finance Options',
    "Rental Income",
    "Property Management & Investors",
    "Contact Info"
]
const flow2MainHeadings= [
    'Property Address',
    'Square Footage',
    'Finance Options',
    'Finance Options',
    'Finance Options',
    'Finance Options',
    "Rental Income",
    "Property Management & Investors",
    "Contact Info"
]
const flow3MainHeadings= [
    'Property Address',
    'Square Footage',
    'Finance Options',
    'Finance Options',
    'Finance Options',
    'Finance Options',
    'Finance Options',
    "Rental Income",
    "Property Management & Investors",
    "Contact Info"
]
const flow4MainHeadings= [
    'Property Address',
    'Square Footage',
    'Finance Options',
    'Finance Options',
    "Rental Income",
    "Property Management & Investors",
    "Contact Info"
]
const flow0CounterArray = [0, 1,2,3,3,3,4,4,4,5,6,7 ];
const flow1CounterArray = [0, 1, 2, 3,3,3, 4, 5, 6];
const flow2CounterArray = [0, 1, 2, 3,3,3, 4, 5,6];
const flow3CounterArray = [0, 1, 2, 3,3,3, 4, 5, 6,7];
const flow4CounterArray = [0, 1, 2, 3, 4, 5,6];