import { Box, Typography } from "@mui/material";
import React from "react";
import RegularToolTip from "./RegularToolTip";

export default function StyledLabel({
  label,
  fontSize,
  showToolTip,
  toolTipContent,
  disable,
}) {
  return (
    <Box
      sx={{ display: "inline-flex", alignItems: "center", flexWrap: "nowrap" }}
    >
      <label style={{ marginBottom: "5px" }}>
        {!showToolTip && (
          <Typography
            variant={"h4"}
            sx={{ color: disable ? "#e8e8e8" : "inherit", fontSize }}
          >
            {label}
          </Typography>
        )}
        {showToolTip && (
          <RegularToolTip content={toolTipContent}>
            <Typography variant={"h4"}>{label}</Typography>
          </RegularToolTip>
        )}
      </label>
    </Box>
  );
}
