function commaSeparate(x) {
	return x === 0 || x === '0'
		? 0
		: x
		? x
				.toString()
				.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
				.trim()
		: '';
}

export default commaSeparate;
