export const aestheticRemodelMenu = [
    'Property Information',
    'Demolishing Cost',
    'Development Cost',
    'Finance Options',
    'Property Permit & Fees',
    'Taxes',
    'Rental Income',
    'Property Management',
    'Other Costs',
    'Carrying Months',
    'Est. Resale Value',
    'Closing Costs',
    'Investor Profit',
    'Summary'
]

export const subMenus ={ 
    "Development Cost":[
    'Aesthetic Items',
    'Non Aesthetic Items'
    ],
}