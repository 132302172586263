import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  OutlinedInput,
  Typography,
  FormControl,
  InputAdornment,
} from "@mui/material";
import MonthYearSelector from "../atomicComponents/MonthYearSelector";
import { useTheme } from "@mui/material/styles";
import StyledLabel from "../atomicComponents/StyledLabel";
import useProformaData from "../hooks/dealReporthooks/useProformaData";
import { useSelector } from "react-redux";
import commaSeparate from "../utils/commaSeparate";
import useSaveNow from "../hooks/useSaveNow";
import { preventMinus } from "../utils/preventNegativeValues";
import {
  generateField,
  multiplyArraysRentalINcome,
} from "../utils/GenerateInputField";
import { greyout } from "../utils/greyout";

const useStyle = (_theme) => {
  return {
    root: {
      margin: "1.25rem",
      "& > div": {
        marginBottom: "1.875rem",
      },
    },
    monthYear: {
      minWidth: "18.75rem",
    },
    label: {
      display: "flex",
      flexDirection: "column",
    },
  };
};

function RentalIncome({ data, handleEvents, additionalInfo }) {
  const theme = useTheme();
  const styles = useStyle(theme);
  const { saveNow } = useSaveNow();
  const { resultData, fetchResult } = useProformaData({ noAutoLoad: true });
  const analyzerData = useSelector((state) => state?.straightAestheticRemodel);
  const disable =
    !!additionalInfo &&
    !!!+analyzerData?.demolishingCost?.demolishing_cost_option;
  const handleChange = (e) => {
    handleEvents({ [e.target.name]: e.target.value });
  };

  const { additional_units } = analyzerData?.propertyInformation || {};

  const save = async () => {
    try {
      saveNow({ rental_income: analyzerData?.rentalIncome });
    } catch (err) {}
  };
  const handleValues = (e) => {
    let value = e.target.value;
    if (value) {
      value = Number.parseInt(value.split(",").join(""));
      if (isNaN(value)) {
        return;
      }
      handleEvents({ [e.target.name]: value });
    } else {
      handleEvents({ [e.target.name]: value });
    }
  };

  useEffect(() => {
    handleEvents({
      owning_months: data?.owning_months || 0,
      est_rental_income_per_month: data?.est_rental_income_per_month || 0,
      vacancy_months: data?.vacancy_months || 0,
    });
    // NOTE: Run effect once on component mount, please
    // recheck dependencies if effect is updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    save();
    fetchResult();
    // NOTE: Run effect once on component mount, please
    // recheck dependencies if effect is updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    data?.owning_months,
    data?.est_rental_income_per_month,
    data?.vacancy_months,
  ]);

  //================================== Rental Income Calculations goes here =============================================//
  const rentalIncome = useSelector(
    (state) => state?.straightAestheticRemodel.rentalIncome
  );
  const vacancyMonths = rentalIncome.vacancy_months;
  const estIncomePerMonth = rentalIncome.est_rental_income_per_month;

  const amountLost = useMemo(() => {
    return vacancyMonths * estIncomePerMonth;

    // recheck dependencies if effect is updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vacancyMonths, estIncomePerMonth]);

  const totalMonthlyLost =
    amountLost || resultData?.rental_income?.total_vacancy_factor_loss_factor;

  //===========xxxx================================================================xxxx===================================//

  //========================== dynamic form generation ============================//
  // generate fields for additional sqf added
  const generateNewForm = useMemo(() => {
    return generateField(additional_units?.length);
  }, [additional_units]);

  const formsWithValues =
    data?.additional_units_rental_price?.length === generateNewForm?.length
      ? data?.additional_units_rental_price
      : undefined;

  // empty prevPrices if unit length changes
  useEffect(() => {
    if (!formsWithValues) {
      handleEvents({ additional_units_rental_price: [] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // generate fields for additional sqf added
  const [additionalUnitArray, setAdditionalUnitArray] = useState(
    formsWithValues || generateNewForm
  );

  const componentDynamicDataFilter = (index, event) => {
    let data = [...additionalUnitArray];

    data[index][event.target.name] = event.target.value;
    setAdditionalUnitArray(data);
    handleEvents({ additional_units_rental_price: data });
    // handleEvents({ [e.target.name]: e.target.value });
  };

  const unitCostResult = useMemo(() => {
    return multiplyArraysRentalINcome(
      data?.additional_units_rental_price,
      +data?.owning_months,
      +data?.vacancy_months
    );
  }, [
    data?.additional_units_rental_price,
    data.owning_months,
    data?.vacancy_months,
  ]);

  //dispatch total units rental income
  useEffect(() => {
    if (!unitCostResult?.totalSumProfit) return;
    handleEvents({ unitsRentalIncome: unitCostResult?.totalSumProfit });
    handleEvents({ unitsRentalLoss: unitCostResult?.totalSum });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unitCostResult?.totalSumProfit, unitCostResult?.totalSum]);

  //=============xxxxxxxxxxx============= dynamic form generation ==================xxxxxxxxx==========//

  return (
    <Box sx={styles.root}>
      <Box sx={styles.monthYear}>
        <MonthYearSelector
          data={data}
          handler={handleEvents}
          label={rentalIncomeStaticData.label1}
          override={{ marginTop: "10px" }}
          name="owning_months"
          disable={disable}
        />
      </Box>
      <Box sx={styles.wholesalerText}>
        <Box sx={styles.label}>
          <StyledLabel
            label={rentalIncomeStaticData.label2.upper}
            disable={disable}
          />
          {/* <HtmlToolTip content = {tooltipText}/> */}
          <Typography
            variant="h5"
            color={disable ? "#ffcedf" : "error"}
            sx={{ marginTop: "-0.5rem" }}
          >
            {rentalIncomeStaticData.label2.subUpper}{" "}
          </Typography>
        </Box>

        <FormControl
          sx={{
            minWidth: "18.75rem",
            width: "100%",
            marginTop: "10px",
          }}
        >
          <OutlinedInput
            fullWidth
            disabled={disable}
            name={"est_rental_income_per_month"}
            value={commaSeparate(data?.["est_rental_income_per_month"])}
            onChange={handleValues}
            margin="normal"
            inputProps={{ style: { fontSize: "1rem" }, min: 0 }}
            placeholder="0"
            onKeyPress={preventMinus}
            startAdornment={
              <InputAdornment position="start">
                {" "}
                <Typography variant="adornment" color="secondary">
                  {" "}
                  $
                </Typography>
              </InputAdornment>
            }
          />
        </FormControl>
      </Box>
      <Box sx={{ display: "flex", gap: "1rem" }}>
        {additionalUnitArray.map((item, index) => (
          <FormControl sx={{ width: "45%", mt: "1rem" }} key={index}>
            <StyledLabel
              label={`Unit ${index + 2} Rental Income`}
              showToolTip
              toolTipContent={`Unit ${index + 2} Est Rental Income Per Month`}
            />

            <OutlinedInput
              name={"value"}
              onKeyPress={preventMinus}
              type="number"
              onChange={(event) => componentDynamicDataFilter(index, event)}
              value={item?.value}
              inputProps={{ style: { fontSize: "1rem" }, min: 0 }}
              sx={{ fontSize: "16px" }}
              startAdornment={
                <InputAdornment position="start">
                  {" "}
                  <Typography variant="adornment">$</Typography>
                </InputAdornment>
              }
            ></OutlinedInput>
          </FormControl>
        ))}
      </Box>
      <Box sx={styles.wholesalerText}>
        <Box sx={styles.label}>
          <StyledLabel
            label={rentalIncomeStaticData.label3.upper}
            disable={disable}
          />
          <Typography
            variant="h5"
            sx={{
              marginTop: "-0.5rem",
              color: disable ? "#e8e8e8" : "inherit",
            }}
          >
            {rentalIncomeStaticData.label3.subUpper}{" "}
          </Typography>
        </Box>
        <FormControl
          sx={{
            minWidth: "18.75rem",
            marginTop: "10px",
            width: "100%",
          }}
        >
          <OutlinedInput
            fullWidth
            disabled={disable}
            name={"vacancy_months"}
            onChange={handleChange}
            value={data?.vacancy_months}
            margin="normal"
            onKeyPress={preventMinus}
            inputProps={{ style: { fontSize: "1rem" }, min: 0 }}
            placeholder="0"
            type="number"
            endAdornment={
              <InputAdornment position="start">
                {" "}
                <Typography
                  variant="adornment"
                  color="secondary"
                  sx={{ color: disable ? "#e8e8e8" : "inherit" }}
                >
                  {" "}
                  months
                </Typography>
              </InputAdornment>
            }
          />
        </FormControl>
      </Box>
      {additionalUnitArray?.length > 0 && (
        <Box sx={styles.wholesalerText}>
          <Box sx={styles.label}>
            <StyledLabel
              label={"Vacancy Loss Factor (Main)"}
              disable={disable}
            />
          </Box>
          <FormControl
            sx={{
              minWidth: "18.75rem",
              marginTop: "10px",
              width: "100%",
              // backgroundColor:"grey !important"
            }}
          >
            <OutlinedInput
              fullWidth
              disabled={disable}
              sx={{ backgroundColor: "#E5E4E2 !important" }}
              margin="normal"
              inputProps={{ style: { fontSize: "1rem" }, min: 0 }}
              value={totalMonthlyLost || 0}
              placeholder="0"
              onKeyPress={preventMinus}
              startAdornment={
                <InputAdornment position="start">
                  {" "}
                  <Typography variant="adornment" color="secondary">
                    {" "}
                    $
                  </Typography>
                </InputAdornment>
              }
            />
          </FormControl>
        </Box>
      )}
      <Box sx={{ display: "flex", gap: "2rem" }}>
        {unitCostResult?.indexMultiplications?.map((item, index) => (
          <Box
            sx={styles.inputContainer}
            display={"flex"}
            flexDirection={"column"}
          >
            <StyledLabel label={`Unit ${index + 2} Vacancy Loss`} />
            <FormControl>
              <OutlinedInput
                fullWidth
                margin="normal"
                name={"test name"}
                onChange={handleChange}
                value={item?.value || 0}
                sx={greyout}
                placeholder="0"
                onKeyPress={preventMinus}
                renderValue={(value) => value}
                inputProps={{ style: { fontSize: "1rem" }, min: 0 }}
                type="number"
                startAdornment={
                  <InputAdornment position="start">
                    {" "}
                    <Typography variant="adornment" color="secondary">
                      {" "}
                      $
                    </Typography>
                  </InputAdornment>
                }
              />
            </FormControl>
          </Box>
        ))}
      </Box>
      <Box sx={styles.wholesalerText}>
        <Box sx={styles.label}>
          <StyledLabel
            label={rentalIncomeStaticData.label4}
            disable={disable}
          />
        </Box>
        <FormControl
          sx={{
            minWidth: "18.75rem",
            marginTop: "10px",
            width: "100%",
            // backgroundColor:"grey !important"
          }}
        >
          <OutlinedInput
            fullWidth
            disabled={disable}
            sx={{ backgroundColor: "#E5E4E2 !important" }}
            margin="normal"
            inputProps={{ style: { fontSize: "1rem" }, min: 0 }}
            value={commaSeparate(
              (+totalMonthlyLost || 0) + (+unitCostResult?.totalSum || 0)
            )}
            placeholder="0"
            onKeyPress={preventMinus}
            startAdornment={
              <InputAdornment position="start">
                {" "}
                <Typography variant="adornment" color="secondary">
                  {" "}
                  $
                </Typography>
              </InputAdornment>
            }
          />
        </FormControl>
      </Box>
    </Box>
  );
}

export default RentalIncome;
const rentalIncomeStaticData = {
  label1: "How many years/months do you intend to rent this property?",
  label2: {
    upper: "Est Rental Income Per Month",
    subUpper:
      "(Please Enter an estimated Rental Income Amount, if the property will be rented during the holding period)",
  },
  label3: {
    upper: "Vacancy Months",
    subUpper:
      "Approximately, how long will this property be vacant during each calender year you own the property?",
  },
  label4: "Total Vacancy Loss Factor",
};
