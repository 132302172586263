import { LoadingButton } from '@mui/lab';
import { Autocomplete, Button, Checkbox, CircularProgress, Divider, FormControlLabel, Grid, Stack, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ChevronLeft from '@untitled-ui/icons-react/build/esm/ChevronLeft';
import ChevronRight from '@untitled-ui/icons-react/build/esm/ChevronRight';
import Plus from '@untitled-ui/icons-react/build/esm/Plus';
import Save01 from '@untitled-ui/icons-react/build/esm/Save01';
import UploadCloud02 from '@untitled-ui/icons-react/build/esm/UploadCloud02';
import Building01 from '@untitled-ui/icons-react/build/esm/Building01';
import { sortBy } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import base, { file as fileUploader } from '../apis';
import ImagesCarousel from '../components/ImagesCarousel';
import LoadingDialog from '../components/LoadingDialog';
import PlacesSearch from '../components/PlacesSearch/PlacesSearch';
import { mainWithOptions, states, timeZones } from '../constants';
import { getValidUrl } from '../utils/functions';
import { Link } from 'react-router-dom';

const links = [
	{
		value: 'web_link',
		label: 'Web Link',
	},
	{ value: 'facebook', label: 'Facebook' },
	{
		value: 'instagram',
		label: 'Instagram',
	},
	{
		value: 'twitter',
		label: 'Twitter',
	},
	{
		value: 'youtube',
		label: 'YouTube',
	},
	{
		value: 'linkedin',
		label: 'LinkedIn',
	},
];

const highlights = [
	{
		title: 'Free Estimate',
		key: 'free',
	},
	{
		title: 'Offers Payment Plan',
		key: 'plan',
	},
	{
		title: 'Locally Owned & Operated',
		key: 'local',
	},
	{
		title: 'Years In Business',
		key: 'years',
	},
	{
		title: 'Satisfaction Guaranteed',
		key: 'satisfaction',
	},
	{
		title: 'Offers Commercial Services',
		key: 'commercial',
	},
	{
		title: 'Free Consultation',
		key: 'consultation',
	},
	{
		title: 'Emergency Services',
		key: 'emergency',
	},
];

const steps = [
	{
		title: 'Company Information',
		subTitle: 'Please provide your company info',
	},
	{
		title: 'Address and Contact',
		subTitle: 'Please provide your company info',
	},
	{
		title: 'License and Services',
		subTitle: 'Please provide your services',
	},
	{
		title: 'Add your socials',
		subTitle: 'Share posts to your social accounts',
	},
	{
		title: 'Images for listing',
		subTitle: 'Upload Images for your listing',
	},
];

const useStyles = makeStyles((theme) => ({
	withBorder: {
		borderBottom: '1px solid #EAECF0',
		paddingBottom: '1rem',
		paddingTop: '1rem',
	},
	hoverer: {
		position: 'relative',
		width: '100%',
		cursor: 'pointer',
		'& .item': {
			width: '100%',
			display: 'none',
			position: 'absolute',
			aspectRatio: '1',
			background: '#0c111da3',
			borderRadius: '8px',
		},
		'&:hover': {
			'& .item': {
				display: 'flex',
				backdropFilter: 'blur(2px)',
			},
		},
	},
}));

const MyListing = () => {
	const filePickerRef = useRef(null);
	const filePickerRef1 = useRef(null);
	const newFilePickerRef = useRef(null);
	const [isLoading, setIsLoading] = useState(false);
	const selectedListing = useSelector((state) => state.auth.selectedListing);
	const [listingData, setListingData] = useState(null);
	const [step, setStep] = useState(1);
	const [isNew, setIsNew] = useState(false);
	const dispatch = useDispatch();
	const classes = useStyles();

	useEffect(() => {
		(async () => {
			try {
				setStep(1);
				setIsLoading(true);
				// const { data } = await base.get('register/ind-prof/profile/');
				const data = selectedListing;
				let dataToUse = { ...data };
				if (!Object.keys(data).length) {
					setIsNew(true);
					dataToUse = {
						...dataToUse,
						company_name: '',
						industry: '',
						bio: '',
						services: [],
						address: '',
						state: '',
						county: '',
						zip_code: '',
						icon_file: null,
						timezone: '',
						formatted_phone_number: '',
						images: [],
						service_areas: [],
						mail_addresses: [],
						licenses: [],
						hightlight: [],
					};
					links.forEach((i) => {
						dataToUse[i] = '';
					});
				}
				setListingData({
					...dataToUse,
					service_areas:
						dataToUse.service_areas.length === 0
							? [
									{
										location: '',
									},
							  ]
							: dataToUse?.service_areas,
					mail_addresses:
						dataToUse.mail_addresses.length === 0
							? [
									{
										name: '',
										suite_number: '',
									},
							  ]
							: dataToUse?.mail_addresses,
					licenses: dataToUse.licenses.length === 0 ? [{ state: '', description: '', expiration: '' }] : dataToUse?.licenses,
				});
			} catch (error) {
				toast.error('Could not get details');
			} finally {
				setIsLoading(false);
			}
		})();
	}, [selectedListing]);

	const handleChange = (e) => {
		const { name, value } = e.target;
		setListingData((v) => ({
			...v,
			[name]: value,
		}));
	};

	const setImage = async (e, iden) => {
		const { files } = e.target;
		const file = files[0];
		if (file) {
			let formData = new FormData();
			formData.append(iden || 'icon_file', file);
			try {
				const call = isNew
					? fileUploader.post(`register/ind-prof/create-profile/`, formData)
					: fileUploader.patch(`register/ind-prof/profile/${selectedListing?.id}/`, formData);
				const { data } = await call;
				setListingData(data);
				dispatch({ type: isNew ? 'ADD_LISTING' : 'UPDATE_LISTING', payload: data });

				setIsNew(false);
				if (iden) setStep(4);
				else setStep(1);

				if (iden) filePickerRef1.current.value = '';
				else filePickerRef.current.value = '';
			} catch (error) {
				toast.error(error?.response?.data?.detail?.[iden ? 'bg_image' : 'icon_file'][0] || 'Could not upload image');
			} finally {
			}
		}
	};

	const [isImagesUploading, setIsImagesUploading] = useState(false);
	const [imagesDialogOpen, setImagesDialogOpen] = useState(false);

	const setImages = async (e) => {
		const { files } = e.target;

		if (files.length > 0) {
			let formData = new FormData();
			for (let index = 0; index < files.length; index++) {
				formData.append(`images`, files[index]);
			}

			try {
				setIsImagesUploading(true);
				const call = isNew
					? fileUploader.post(`register/ind-prof/create-profile/`, formData)
					: fileUploader.patch(`register/ind-prof/profile/${selectedListing?.id}/`, formData);
				const { data } = await call;
				setListingData(data);
				dispatch({ type: isNew ? 'ADD_LISTING' : 'UPDATE_LISTING', payload: data });
				setStep(5);
				setIsNew(false);
			} catch (error) {
				toast.error('Could not upload image');
			} finally {
				setIsImagesUploading(false);
			}
		}
	};

	const [isSaveLoading, setIsSaveLoading] = useState(false);
	const handleSave = async (page) => {
		//checks
		const body = {};
		if (step === 5) {
			setStep(1);
			return;
		}
		switch (step) {
			case 1:
				if (!listingData?.company_name) {
					toast.warn('Enter a company name');

					return;
				}
				if (!listingData?.industry) {
					toast.warn('Select an industry');
					return;
				}

				body.company_name = listingData?.company_name;
				body.industry = listingData?.industry;
				body.bio = listingData?.bio;
				body.services = listingData?.services;
				break;

			case 2:
				body.mail_addresses = listingData?.mail_addresses.filter((i) => i.name);
				body.address = listingData?.address;
				body.state = listingData?.state;
				body.county = listingData?.county;
				body.zip_code = listingData?.zip_code;
				body.timezone = listingData?.timezone;
				body.formatted_phone_number = listingData?.formatted_phone_number;
				break;

			case 3:
				body.licenses = listingData?.licenses?.filter((item) => item.description);
				body.service_areas = listingData?.service_areas?.filter((item) => item.location);
				body.highlights = listingData?.highlight;
				break;
			case 4:
				links.forEach((i) => {
					body[i.value] = listingData?.[i.value] || '';
				});

				break;

			default:
				break;
		}
		//patch
		try {
			setIsSaveLoading(true);
			if (body) {
				const call = isNew
					? base.post(`register/ind-prof/create-profile/`, body)
					: base.patch(`register/ind-prof/profile/${selectedListing?.id}/`, body);
				const { data } = await call;
				setListingData(data);
				dispatch({ type: isNew ? 'ADD_LISTING' : 'UPDATE_LISTING', payload: data });
				setIsNew(false);
			}
			if (!page) {
				setStep((v) => (v + 1 <= 5 ? v + 1 : 5));
			} else {
				setStep(page);
			}
		} catch (error) {
			toast.error('Could not update listing data');
		} finally {
			setIsSaveLoading(false);
		}
	};

	const onBlurField = (e) => {
		const { name, value } = e.target;
		setListingData({ ...listingData, [name]: getValidUrl(value) });
	};

	const changeStep = (step) => {
		setStep(step);
	};

	const addMailingAddress = () => {
		if (listingData.mail_addresses.length === 2) {
			return;
		}
		setListingData((val) => ({ ...val, mail_addresses: [...val.mail_addresses, { name: '', suite_number: '' }] }));
	};

	const handleRemove = (index) => {
		setListingData((val) => {
			const mail_addresses = val.mail_addresses.filter((i, index1) => index1 !== index);
			if (mail_addresses.length === 0) mail_addresses[0] = { name: '', suite_number: '' };
			return { ...val, mail_addresses: mail_addresses };
		});
	};
	const handleRemoveLicense = (index) => {
		setListingData((val) => {
			const licenses = val.licenses.filter((i, index1) => index1 !== index);
			if (licenses.length === 0) licenses[0] = { state: '', description: '', expiration: '' };
			return { ...val, licenses: licenses };
		});
	};
	const handleRemoveServiceArea = (index) => {
		setListingData((val) => {
			const service_areas = val.service_areas.filter((i, index1) => index1 !== index);
			if (service_areas.length === 0) service_areas[0] = { location: '' };
			return { ...val, service_areas: service_areas };
		});
	};

	const deleteImage = async (id) => {
		const image = listingData?.images.find((i) => i.id === id);
		const imageIndex = listingData?.images.findIndex((i) => i.id === id);
		setListingData((v) => ({ ...v, images: v.images.filter((i) => i.id !== id) }));
		try {
			await base.post('professionals/image/delete/', { id });
		} catch {
			toast.warn('Could not delete image');
			setListingData((v) => {
				v.images.splice(imageIndex, 0, image);
				const toReturn = { ...v, images: v.images };
				dispatch({ type: isNew ? 'ADD_LISTING' : 'UPDATE_LISTING', payload: toReturn });

				return toReturn;
			});
		}
	};

	const [selectedPhotoIndex, setSelectedPhotoIndex] = useState(0);
	const handlePreview = (index) => {
		setSelectedPhotoIndex(index);
		setImagesDialogOpen(true);
	};

	const getSaveContinueButton = () =>
		step === 5 ? (
			<Button
				variant='outlined'
				color='secondary'
				startIcon={<UploadCloud02 style={{ stroke: '#0D5894' }} className='iconer' />}
				onClick={() => {
					newFilePickerRef.current?.click();
				}}
			>
				Upload New Images
			</Button>
		) : (
			<LoadingButton
				loading={isSaveLoading}
				sx={{ textTransform: 'capitalize', width: 'fit-content' }}
				variant='contained'
				color='secondary'
				onClick={() => handleSave(step + 1)}
				endIcon={<ChevronRight className='iconer' style={{ stroke: 'white' }} />}
			>
				{step === 5 ? 'Go To Start' : 'Save & Continue'}
			</LoadingButton>
		);

	const getContent = () => {
		switch (step) {
			case 1:
				// name, logo, description, industry
				return (
					<>
						<Grid item xs={12}>
							<Stack direction='row' gap='2rem' alignItems='center' className={classes.withBorder}>
								<Title title={'Company Name'} />
								<TextField
									placeholder='Company Name'
									size='small'
									fullWidth
									sx={{ width: '60%' }}
									color='secondary'
									value={listingData?.company_name || ''}
									name='company_name'
									onChange={handleChange}
								/>
							</Stack>
						</Grid>
						<Grid item xs={12}>
							<Stack direction='row' gap='2rem' alignItems='center' className={classes.withBorder}>
								<Title title={'Company Logo'} />
								<Stack sx={{ width: '60%' }}>
									{listingData?.icon_file ? (
										<>
											<img
												src={listingData?.icon_file}
												alt='profile'
												style={{ width: '200px', aspectRatio: '1', borderRadius: '5px', cursor: 'pointer', objectFit: 'cover' }}
												onClick={() => {
													filePickerRef.current?.click();
												}}
											/>

											<input
												type='file'
												id='prof'
												accept='image/png, image/jpeg'
												ref={filePickerRef}
												onChange={setImage}
												style={{ display: 'none' }}
											/>
										</>
									) : (
										<>
											<ImageSelector
												onClick={() => {
													filePickerRef.current?.click();
												}}
											/>

											<input type='file' accept='image/png, image/jpeg' ref={filePickerRef} onChange={setImage} style={{ display: 'none' }} />
										</>
									)}
								</Stack>
							</Stack>
						</Grid>
						<Grid item xs={12}>
							<Stack direction='row' gap='2rem' className={classes.withBorder}>
								<Title title={'Company Description'} />
								<TextField
									placeholder='Company Description'
									size='small'
									multiline
									maxRows={6}
									rows={6}
									fullWidth
									sx={{ width: '60%' }}
									color='secondary'
									value={listingData?.bio || ''}
									name='bio'
									onChange={handleChange}
								/>
							</Stack>
						</Grid>
						<Grid item xs={12}>
							<Stack direction='row' gap='2rem' alignItems='center' className={classes.withBorder}>
								<Title title='Industry' />
								<Autocomplete
									size='small'
									fullWidth
									disableClearable
									value={listingData?.industry || ''}
									onChange={(e, newValue) => {
										setListingData((v) => ({ ...v, industry: newValue, services: [] }));
									}}
									name='industry'
									sx={{ width: '60%' }}
									options={sortBy(mainWithOptions.map((i) => i.name))}
									renderInput={(params) => <TextField size='small' color='secondary' {...params} fullWidth />}
								/>
							</Stack>
						</Grid>
						<Grid item xs={12}>
							<Stack direction='row' gap='2rem' className={classes.withBorder}>
								<Title title='Services' />
								<Stack width={'75%'} sx={{ flexWrap: 'wrap' }} gap='1rem' direction='row'>
									<Grid container spacing={2}>
										{sortBy(mainWithOptions.find((i) => i.name === listingData?.industry)?.options || []).map((item) => (
											<Grid item xs={4} key={item}>
												<FormControlLabel
													label={
														<Typography color='#344054' fontWeight={500} fontSize={14}>
															{item}
														</Typography>
													}
													key={item}
													control={
														<Checkbox
															color='secondary'
															checked={listingData?.services.includes(item)}
															onChange={(e) => {
																if (listingData?.services.includes(item))
																	setListingData((v) => ({ ...v, services: v.services.filter((ser) => ser !== item) }));
																else setListingData((v) => ({ ...v, services: [...v.services, item] }));
															}}
														/>
													}
												/>
											</Grid>
										))}
									</Grid>
								</Stack>
							</Stack>
						</Grid>
					</>
				);

			case 2:
				//company address, mailing address, timezone, phone number
				return (
					<>
						<Grid item xs={12}>
							<Stack direction='row' gap='2rem' className={classes.withBorder}>
								<Title title={'Company Address'} />
								<Stack sx={{ width: '60%' }}>
									<Grid container spacing={2}>
										<Grid item xs={8}>
											<PlacesSearch
												mui={true}
												placeholder='Company Address'
												size='small'
												fullWidth
												color='secondary'
												value={listingData?.address || ''}
												name='address'
												onChange={(e) => {
													setListingData((v) => ({ ...v, address: e }));
												}}
											/>
										</Grid>
										<Grid item x={4} />
										<Grid item xs={4}>
											<Autocomplete
												size='small'
												options={states.map((i) => i.value)}
												sx={{ flex: '1' }}
												onChange={(e, newValue) => {
													setListingData((v) => ({ ...v, state: newValue }));
												}}
												renderInput={(params) => <TextField size='small' color='secondary' {...params} label='State' fullWidth />}
											/>
										</Grid>
										<Grid item xs={4}>
											<TextField
												placeholder='County'
												size='small'
												color='secondary'
												name='county'
												fullWidth
												onChange={handleChange}
												value={listingData?.county || ''}
											/>
										</Grid>
										<Grid item xs={4}>
											<TextField
												placeholder='Zip Code'
												size='small'
												color='secondary'
												name='zip_code'
												fullWidth
												onChange={handleChange}
												value={listingData?.zip_code || ''}
											/>
										</Grid>
									</Grid>
								</Stack>
							</Stack>
						</Grid>
						<Grid item xs={12} className={classes.withBorder}>
							{listingData?.mail_addresses.map((item, index) => (
								<Stack direction='row' gap='2rem' mb='1rem' key={index}>
									<Title title={`Mailing Address ${index + 1}`} />
									<Stack sx={{ width: '60%' }}>
										<Grid container spacing={2}>
											<Grid item xs={8}>
												<PlacesSearch
													mui={true}
													placeholder='Mailing Address'
													size='small'
													fullWidth
													color='secondary'
													value={listingData?.mail_addresses[index].name || ''}
													name='address'
													onChange={(e) => {
														const mailingaddress = listingData.mail_addresses;
														mailingaddress[index].name = e;
														setListingData((cur) => ({ ...cur, mail_addresses: mailingaddress }));
													}}
												/>
											</Grid>
											<Grid item xs={3}>
												<TextField
													size='small'
													placeholder='Suite Number'
													fullWidth
													onChange={(e) => {
														const mailingaddress = listingData.mail_addresses;
														mailingaddress[index].suite_number = e.target.value;
														setListingData((cur) => ({ ...cur, mail_addresses: mailingaddress }));
													}}
													value={listingData.mail_addresses[index].suite_number || ''}
												/>
											</Grid>
											<Grid item xs={1}>
												<Button
													variant='text'
													sx={{ textTransform: 'capitalize', width: 'fit-content', marginRight: 'auto', color: '#475467' }}
													onClick={() => handleRemove(index)}
												>
													Remove
												</Button>
											</Grid>
										</Grid>
									</Stack>
								</Stack>
							))}
							<Button
								variant='text'
								color='secondary'
								sx={{ textTransform: 'capitalize', width: 'fit-content', marginLeft: '22.5%' }}
								onClick={addMailingAddress}
								startIcon={<Plus />}
							>
								Add Mailing Address
							</Button>
						</Grid>
						<Grid item xs={12}>
							<Stack className={classes.withBorder}>
								<Stack direction='row' gap='2rem' mb='1rem'>
									<Title title={`Timezone`} />
									<Stack sx={{ width: '60%' }}>
										<Grid container spacing={2}>
											<Grid item xs={8}>
												<Autocomplete
													size='small'
													fullWidth
													options={timeZones.map((item) => item.value)}
													disableClearable
													value={listingData?.timezone || ''}
													onChange={(e, newValue) => {
														setListingData((v) => ({ ...v, timezone: newValue }));
													}}
													renderInput={(params) => <TextField size='small' color='secondary' {...params} fullWidth />}
												/>
											</Grid>
										</Grid>
									</Stack>
								</Stack>

								<Stack direction='row' gap='2rem' alignItems='center'>
									<Title title={'Phone Number'} />
									<Stack sx={{ width: '60%' }}>
										<Grid container spacing={2}>
											<Grid item xs={8}>
												<TextField
													placeholder='Phone Number'
													size='small'
													fullWidth
													color='secondary'
													value={listingData?.formatted_phone_number}
													name='formatted_phone_number'
													onChange={handleChange}
												/>
											</Grid>
										</Grid>
									</Stack>
								</Stack>
							</Stack>
						</Grid>
					</>
				);

			case 3:
				//license , Service areas, highlights
				return (
					<>
						<Grid item xs={12}>
							<Stack direction='row' gap='2rem' className={classes.withBorder}>
								<Title title='License:' />

								<Stack sx={{ width: '60%' }} gap='1rem'>
									{listingData?.licenses.map((item, index) => (
										<Stack direction='row' gap='1rem' alignItems={'center'} key={index}>
											<Autocomplete
												size='small'
												disableClearable
												fullWidth
												options={states.map((i) => i.value)}
												value={listingData?.licenses[index].state}
												onChange={(e, nV) => {
													const licenses = listingData.licenses;
													licenses[index].state = nV;
													setListingData((cur) => ({ ...cur, licenses: licenses }));
												}}
												renderInput={(params) => <TextField size='small' color='secondary' {...params} label='State' fullWidth />}
											/>
											<TextField
												placeholder='License Number'
												size='small'
												fullWidth
												color='secondary'
												value={listingData?.licenses[index].description}
												onChange={(e) => {
													const licenses = listingData.licenses;
													licenses[index].description = e.target.value;
													setListingData((cur) => ({ ...cur, licenses: licenses }));
												}}
											/>
											<input
												type='date'
												value={listingData?.licenses[index].expiration}
												onChange={(e) => {
													const licenses = listingData.licenses;
													licenses[index].expiration = e.target.value;
													setListingData((cur) => ({ ...cur, licenses: licenses }));
												}}
												style={{ padding: '10px', borderRadius: '5px', border: '1px solid #c4c4c4', fontFamily: 'inherit', width: '100%' }}
											/>
											<Button
												variant='text'
												color='secondary'
												sx={{ textTransform: 'capitalize', width: 'fit-content', marginRight: 'auto', textWrap: 'nowrap', color: '#475467' }}
												onClick={() => handleRemoveLicense(index)}
											>
												Remove
											</Button>
										</Stack>
									))}

									<Button
										variant='text'
										color='secondary'
										sx={{ textTransform: 'capitalize', width: 'fit-content', marginRight: 'auto', textWrap: 'nowrap', color: '#475467' }}
										startIcon={<Plus />}
										onClick={() => {
											setListingData((v) => ({ ...v, licenses: [...v.licenses, { state: '', description: '', expiration: '' }] }));
										}}
									>
										Add Another License
									</Button>
								</Stack>
							</Stack>
						</Grid>
						<Grid item xs={12}>
							<Stack direction='row' gap='2rem' className={classes.withBorder}>
								<Title title='Service Areas' />
								<Stack gap='1rem' sx={{ width: '60%' }}>
									{listingData?.service_areas.map((item, index) => (
										<Stack direction='row' gap='1rem' key={index}>
											<PlacesSearch
												mui={true}
												types={['country', 'locality']}
												placeholder='Service Areas'
												size='small'
												fullWidth
												color='secondary'
												value={listingData.service_areas[index].location || ''}
												onChange={(e) => {
													const serviceArea = listingData.service_areas;
													serviceArea[index].location = e;
													setListingData((cur) => ({ ...cur, service_areas: serviceArea }));
												}}
											/>
											<Button
												variant='text'
												color='error'
												sx={{ textTransform: 'capitalize', width: 'fit-content', marginRight: 'auto', color: '#475467' }}
												onClick={() => handleRemoveServiceArea(index)}
											>
												Remove
											</Button>
										</Stack>
									))}

									<Button
										variant='text'
										color='secondary'
										sx={{ textTransform: 'capitalize', width: 'fit-content', marginRight: 'auto', color: '#475467' }}
										onClick={() => {
											setListingData((v) => ({ ...v, service_areas: [...v.service_areas, { location: '' }] }));
										}}
										startIcon={<Plus />}
									>
										Add Service Area
									</Button>
								</Stack>
							</Stack>
							<Grid item xs={12}>
								<Stack direction='row' gap='2rem' className={classes.withBorder}>
									<Title title='Business Highlights' />
									<Stack width={'70%'} sx={{ flexWrap: 'wrap' }} gap='1rem' direction='row'>
										<Grid container spacing={2}>
											{sortBy(highlights).map(({ key, title }) => (
												<Grid item xs={4} key={key}>
													<FormControlLabel
														label={
															<Typography color='#344054' fontWeight={500} fontSize={14}>
																{title}
															</Typography>
														}
														control={
															<Checkbox
																color='secondary'
																size='small'
																checked={listingData?.highlight.includes(key)}
																onChange={(e) => {
																	if (listingData?.highlight.includes(key))
																		setListingData((v) => ({ ...v, highlight: v.highlight.filter((ser) => ser !== key) }));
																	else setListingData((v) => ({ ...v, highlight: [...v.highlight, key] }));
																}}
															/>
														}
													/>
												</Grid>
											))}
										</Grid>
									</Stack>
								</Stack>
							</Grid>
						</Grid>
					</>
				);
			case 4:
				//links
				return (
					<>
						{links.map((social) => (
							<Grid item xs={12} key={social.value}>
								<Stack direction='row' gap='2rem' alignItems='center' className={classes.withBorder}>
									<Title title={social.label} />
									<TextField
										placeholder={social.label}
										size='small'
										fullWidth
										onBlur={onBlurField}
										sx={{ width: '60%' }}
										color='secondary'
										value={listingData?.[social.value]}
										name={social.value}
										onChange={handleChange}
									/>
								</Stack>
							</Grid>
						))}
						<Grid item xs={12}>
							<Stack direction='row' gap='2rem' alignItems='center' className={classes.withBorder}>
								<Title title='Profile Background' />
								<Stack>
									{listingData?.bg_image ? (
										<>
											<img
												src={listingData?.bg_image}
												alt='profile'
												style={{ width: '200px', aspectRatio: '1', borderRadius: '5px', cursor: 'pointer', objectFit: 'cover' }}
												onClick={() => {
													filePickerRef1.current?.click();
												}}
											/>
											<input
												type='file'
												id='bg_image'
												accept='image/png, image/jpeg'
												ref={filePickerRef1}
												onChange={(e) => setImage(e, 'bg_image')}
												style={{ display: 'none' }}
											/>
										</>
									) : (
										<>
											<ImageSelector
												onClick={() => {
													filePickerRef1.current?.click();
												}}
											/>

											<input
												type='file'
												accept='image/png, image/jpeg'
												ref={filePickerRef1}
												onChange={(e) => setImage(e, 'bg_image')}
												style={{ display: 'none' }}
											/>
										</>
									)}
								</Stack>
							</Stack>
						</Grid>
					</>
				);

			case 5:
				return (
					<Grid item xs={12}>
						<Stack>
							<Grid container spacing={2}>
								{listingData.images.map((i, index) => (
									<Grid item xs={2.4} key={i.id}>
										<ImageItem image={i.image} onDelete={() => deleteImage(i.id)} onPreview={() => handlePreview(index)} />
									</Grid>
								))}
								<input type='file' accept='image/*' ref={newFilePickerRef} style={{ display: 'none' }} multiple onChange={setImages} />
							</Grid>
						</Stack>
					</Grid>
				);

			default:
				break;
		}
	};

	if (isLoading) {
		return (
			<Stack sx={{ minHeight: '100vh' }} alignItems='center' justifyContent='center'>
				<CircularProgress color='secondary' />
			</Stack>
		);
	}
	return (
		<>
			<LoadingDialog isLoading={isImagesUploading} />
			<ImagesCarousel
				open={imagesDialogOpen}
				close={() => setImagesDialogOpen(false)}
				images={listingData?.images || []}
				startItem={selectedPhotoIndex}
			/>
			<Stack sx={{ minHeight: '100vh' }} p='2rem' gap='1rem'>
				<Typography
					fontWeight={700}
					fontSize={24}
					color='#344054'
					sx={{ borderBottom: '1px solid #EAECF0', paddingBottom: '1rem' }}
					fontFamily='Lato'
				>
					{' '}
					Business Listing
				</Typography>

				<Stack direction='row' gap='1rem' my='1rem'>
					{steps.map((item, index) => (
						<Stack
							key={item.title}
							sx={{ flex: 1, color: index + 1 <= step ? '#0D5894' : '#344054', cursor: 'pointer' }}
							onClick={() => changeStep(index + 1)}
						>
							<Divider sx={{ backgroundColor: index + 1 <= step ? '#0D5894' : '#EAECF0', height: '5px', border: 'none' }} />
							<Typography color='currentcolor' fontSize={14} fontWeight={600} mt='1rem'>
								{item.title}
							</Typography>
							<Typography color='currentcolor' fontSize={14} fontWeight={400}>
								{item.subTitle}
							</Typography>
						</Stack>
					))}
				</Stack>

				<Stack
					sx={{
						borderBottom: '1px solid #EAECF0',
						borderTop: '1px solid #EAECF0',
						paddingY: '1.5rem',
						position: 'sticky',
						top: '74px',
						background: 'white',
						zIndex: 100,
					}}
					direction='row'
					justifyContent='space-between'
				>
					<Stack>
						<Typography color='#101828' fontSize={18} fontWeight={600}>
							{steps[step - 1].title}
						</Typography>
						<Typography color='#475467' fontSize={14} fontWeight={400}>
							{steps[step - 1].subTitle}
						</Typography>
					</Stack>
					<Stack direction='row' alignItems='center'>
						{getSaveContinueButton()}
					</Stack>
				</Stack>
				<Grid container spacing={2}>
					{getContent()}

					<Grid item xs={12}>
						<Stack direction='row' gap='2rem' sx={{ mt: '2rem' }} className={classes.withBorder}>
							{step > 1 && (
								<Button
									sx={{ textTransform: 'capitalize', width: 'fit-content', marginRight: 'auto' }}
									variant='outlined'
									color='text'
									onClick={() => setStep((v) => v - 1)}
									startIcon={<ChevronLeft className='iconer' style={{ stroke: '#344054' }} />}
								>
									Previous
								</Button>
							)}
							{step !== 5 && (
								<LoadingButton
									loading={isSaveLoading}
									variant='outlined'
									color='secondary'
									sx={{ marginLeft: 'auto', marginRight: '10px' }}
									startIcon={<Save01 style={{ stroke: '#0D5894' }} className='iconer' />}
									onClick={() => handleSave(step)}
								>
									Save
								</LoadingButton>
							)}

							{step !== 5 ? (
								getSaveContinueButton()
							) : (
								<>
									<Button
										variant='contained'
										color='secondary'
										component={Link}
										sx={{ marginLeft: 'auto', marginRight: '10px' }}
										startIcon={<Building01 style={{ stroke: '#ffffff' }} className='iconer' />}
										to='/industry-dashboard'
									>
										Go To Dashboard
									</Button>
								</>
							)}
						</Stack>
					</Grid>
				</Grid>
			</Stack>
		</>
	);
};

export default MyListing;

const Title = ({ title, sx }) => {
	return (
		<Typography sx={{ width: '20%', ...sx }} fontWeight={700} color='#344054'>
			{title}
		</Typography>
	);
};

const ImageSelector = ({ onClick }) => (
	<Stack
		sx={{ width: '100%', border: '2px solid #005EA9', borderRadius: '12px', cursor: 'pointer' }}
		alignItems='center'
		p='1rem 1.5rem'
		onClick={onClick}
	>
		<Stack
			sx={{ boxShadow: '0px 1px 2px 0px #1018280D', border: '1px solid #EAECF0', borderRadius: '8px', padding: '10px' }}
			alignItems='center'
			justifyContent='center'
		>
			<UploadCloud02 style={{ stroke: '#475467' }} className='iconer' />
		</Stack>
		<Typography color='#0D5894' fontSize='14px' fontWeight={600} mt='12px'>
			Click to upload
		</Typography>
		<Typography color='#475467' fontSize='12px' fontWeight={400}>
			JPG, PNG
		</Typography>
	</Stack>
);

export const ImageItem = ({ image, onDelete, onPreview, deleteText = 'Delete', previewText = 'Preview' }) => {
	const classes = useStyles();
	return (
		<Stack className={classes.hoverer}>
			<Stack className='item' gap='1rem' alignItems={'center'} justifyContent={'center'}>
				{deleteText && (
					<Button color='paper' variant='outlined' onClick={onDelete}>
						{deleteText}
					</Button>
				)}
				{previewText && (
					<Button color='paper' variant='outlined' onClick={onPreview}>
						{previewText}
					</Button>
				)}
			</Stack>
			<img
				src={image}
				alt='listing'
				style={{
					width: '100%',
					aspectRatio: '1',
					objectFit: 'cover',
					// boxShadow: '0px 4px 4px 0px #00000040',
					borderRadius: '8px',
					border: '1px solid #EAECF0',
				}}
			/>
		</Stack>
	);
};
