import { Close } from '@mui/icons-material';
import { Box, Button, Dialog, DialogContent, Grid, IconButton, TextField, ThemeProvider, Typography, createTheme } from '@mui/material';
import { makeStyles, styled } from '@mui/styles';
import axios from 'axios';
import { useState } from 'react';
import { toast } from 'react-toastify';
import Spinner from '../../components/Spinner/Spinner';
import { URL } from '../../constants';
import Logo from '../../images/bpoVerticalLogo.webp';
import BG from '../../images/magnetBackground.webp';
import PhoneBook from '../../images/phoneImage.webp';
import Upgrading from '../../images/Ebook.3.webp';
import Reasons from '../../images/Ebook.4.webp';
import Howto from '../../images/Ebook.5.webp';
import Moving from '../../images/Ebook.6.webp';
import Together from '../../images/Ebook.7.webp';
import { theme } from '../../theme';
import { useSearchParams } from 'react-router-dom';

const pageTheme = createTheme({
	...theme,
	typography: {
		fontFamily: 'Montserrat',
		fontSize: 16,
	},
});

// const topics = [
// 	`The basics of selling your home `,
// 	`How to prepare your home for sale `,
// 	`How to market your home effectively `,
// 	`How to negotiate the sale of your home `,
// 	`Closing the sale of your home`,
// ];

const option = {
	'sell-home': {
		title: 'How to Sell Your Home in Less Than 5-7 Years',
		subtitle: 'All you need to know to sell your home quickly and for the best possible price.',
		description: 'This Comprehensive Ebook Will Teach You Everything You Need To Know To Sell Your Home Quickly And For The Best Possible Price.',
		tip: 'We also include a number of tips and strategies that will help you sell your home faster and for more money. This ebook is the perfect resource for anyone who is thinking about selling their home.',
		image: PhoneBook,
	},
	upgrading: {
		title: 'The Secret Guide to downgrading or upgrading your Home for best results',
		subtitle: 'This free ebook will help you make the most of your decision, from setting your budget to closing on the home.',
		description: `Are you thinking about buying your first home? If so, you've come to the right place! This ebook is a comprehensive guide to the home buying process. It will walk you through every step, from setting your budget to closing on the home`,
		tip: 'This ebook is packed with valuable information that will help you make the most of your home buying experience. So what are you waiting for? Start reading today',
		image: Upgrading,
	},
	reasons: {
		title: 'Top 17 Reasons Why People Buy and Sell Homes: A Guide for Real Estate Agents',
		subtitle: `Understand the Factors that Influence People's Decisions About Homeownership`,
		description:
			'Are you a real estate agent who wants to help your clients make the best decisions about buying or selling a home? Then you need to understand the 17 most common reasons why people buy and sell homes.',
		tip: `This ebook will give you a comprehensive overview of the factors that influence people's decisions about homeownership. You'll learn about everything from family size changes to retirement decisions.`,
		image: Reasons,
	},
	howto: {
		title: `How to Buy a Home in a New City (Without Getting Scammed)`,
		subtitle: `This ebook will walk you through the process of buying a home in a new city in California without getting scammed.`,
		description: `Buying a home in a new city can be a daunting task, especially if you're not familiar with the area. There are a lot of things to consider, from the real estate market to the different neighborhoods. And with so many scams out there, it's important to be careful not to get taken advantage of.`,
		tip: `By following the tips in this ebook, you can buy a home in a new city in California without getting scammed. This will give you peace of mind and financial security as you move to your new home.`,
		image: Howto,
	},
	moving: {
		title: `Moving for Work? Here's How to Make the Most of Your Home Sale (And Your New Life)`,
		subtitle: ` Packed with helpful tips and advice, this ebook will help you make the most of your move to California.`,
		description: `Are you considering moving for work in California? If so, you're in luck! This ebook is packed with helpful tips and advice to help you make the most of your move.`,
		tip: `We know that moving can be stressful, so we've made sure to include plenty of helpful tips and advice. We'll also provide you with links to additional resources that you can use to get more information.`,
		image: Moving,
	},
	together: {
		title: `Moving in Together: The Ultimate Guide to Buying or Selling a Home`,
		subtitle: `This ebook will help you make an informed decision about buying or selling a home when you move in together.`,
		description: `Are you thinking about moving in with your partner? If so, you may be wondering whether to buy or sell a home. This ebook will help you make an informed decision about buying or selling a home when you move in together.`,
		tip: `So whether you're just starting to think about moving in together or you're already in the process, this ebook is the perfect resource for you.`,
		image: Together,
	},
};

const useStyles = makeStyles((theme) => ({
	root: {
		height: '100vh',
		width: '100vw',
		overflowX: 'hidden',
		background: `url(${BG})`,
		// padding: '1rem',
		fontFamily: 'Montserrat ',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
	},
	content: {},
	card: {
		maxWidth: '480px',
		padding: '2rem 3rem',
		background: '#fff',
		boxShadow: '0px 0px 30px 0px rgba(0, 0, 0, 0.05)',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		gap: '10px',
		margin: 'auto',
	},
	textfield: {
		'MuiInputBase-root': {
			background: '#C4C4C4',
		},
	},
}));

const MatBut = styled(Button)(({ theme }) => ({
	fontFamily: 'Lato',
	fontWeight: '700',
	fontSize: '16px',
	textTransform: 'uppercase',
	width: '100%',
	borderRadius: '50px',
	background: 'linear-gradient(310deg, #FFAA01 0%, #FFEFCF 100%)',
	boxShadow: '0px 4px 10px 0px rgba(0, 0, 0, 0.25)',
	padding: '10px 12px',
	margin: '2rem 0',
}));
const MatBut1 = styled(Button)(({ theme }) => ({
	fontFamily: 'Lato',
	fontWeight: '700',
	fontSize: '16px',
	width: 'fit-content',
	color: 'white',
	textTransform: 'capitalize',
	borderRadius: '5px',
	background: 'linear-gradient(311deg, #005EA9 0%, #3C9AE5 100%)',
	padding: '10px 3rem',
	textAlign: 'center',
	margin: '1rem 0',
}));

function validateEmail(email) {
	var regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
	return regex.test(email);
}

const LeadMagnet = () => {
	const classes = useStyles();
	const [open, setOpen] = useState(false);
	const [details, setDetails] = useState({
		name: '',
		phone: '',
		email: '',
		nameError: '',
		phoneError: '',
		emailError: '',
	});

	const [searchParams] = useSearchParams();
	const [selectedOption] = useState(searchParams.get('type') || 'sell-home');
	const [isLoading, setIsLoading] = useState(false);

	const handleDetails = (e) => {
		const { name, value } = e.target;
		setDetails({ ...details, [name]: value });
	};

	const onSubmit = async () => {
		const errors = {
			nameError: '',
			phoneError: '',
			emailError: '',
		};

		if (details.name === '') {
			errors.nameError = 'Name is required';
		}
		if (details.phone === '' || details.phone.length < 10) {
			errors.phoneError = 'Enter a correct phone number';
		}
		if (!validateEmail(details.email)) {
			errors.emailError = 'Enter a valid email';
		}
		setDetails({ ...details, ...errors });
		if (Object.values(errors).some((val) => val)) {
			setTimeout(() => {
				setDetails({
					...details,
					...{
						nameError: '',
						phoneError: '',
						emailError: '',
					},
				});
			}, 3000);
			return;
		}
		setIsLoading(true);
		try {
			await axios.post(URL + 'leads/create-lead-magnet/', {
				full_name: details.name,
				phone: details.phone,
				email: details.email,
				page: window.location.href,
				lead_type: selectedOption,
			});
			setOpen(true);
		} catch (error) {
			toast.error('Something went wrong, please try again later');
		} finally {
			setIsLoading(false);
		}
		//send data to backend
	};

	return (
		<ThemeProvider theme={pageTheme}>
			{isLoading && <Spinner />}
			<Dialog open={open} fullWidth onClose={() => setOpen(false)}>
				<DialogContent dividers sx={{ position: 'relative' }}>
					<IconButton onClick={() => setOpen(false)} sx={{ position: 'absolute', top: '5%', right: '5%' }}>
						<Close fontSize='small' />
					</IconButton>
					<Box sx={{ padding: '1rem', textAlign: 'center', fontFamily: 'Lato' }}>
						<Typography variant='body1' fontSize={'24px'} fontWeight={'700'} style={{ marginBottom: '1rem', fontFamily: 'inherit' }}>
							Your Download is ready
						</Typography>
						<Typography variant='body1' fontSize={'18px'} sx={{ fontFamily: 'inherit' }}>
							Your ebook is ready for download. A download link has been sent to your email.
						</Typography>
						<MatBut1
							variant='contained'
							color='primary'
							size='small'
							onClick={() => {
								setOpen(false);
							}}
						>
							Ok, thanks
						</MatBut1>
					</Box>
				</DialogContent>
			</Dialog>
			<Box className={classes.root}>
				<img src={Logo} alt='logo' style={{ width: '200px', marginLeft: '1rem', padding: '1rem' }} />
				<Grid container spacing={2} sx={{ px: '1rem' }} alignItems={'center'}>
					<Grid item xs={12} sm={12} md={6} lg={3}>
						<Box
							sx={{
								width: {
									xs: '50%',
									sm: '50%',
									md: '100%',
									lg: '100%',
								},
								margin: 'auto',
							}}
						>
							<img src={option[selectedOption]?.image || option['sell-home']?.image} alt='phonebook' style={{ width: '100%' }} />
						</Box>
					</Grid>
					<Grid item xs={12} sm={12} md={6} lg={5}>
						<Typography variant='body1'>Free Ebook:</Typography>
						<Typography variant='h4' color='#005EA9' sx={{ textTransform: 'capitalize', fontWeight: '900' }}>
							{option[selectedOption]?.title || option['sell-home']?.title}
						</Typography>
						<Typography variant='body1' sx={{ fontWeight: '600', textTransform: 'capitalize' }} my='1rem'>
							{option[selectedOption]?.subtitle || option['sell-home']?.subtitle}
						</Typography>
						<Typography variant='body1' sx={{ fontWeight: '300', textTransform: 'capitalize' }}>
							{option[selectedOption]?.description || option['sell-home']?.description} <br></br>
						</Typography>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={4}>
						<Box className={classes.card}>
							<Typography variant='h6' color='#005EA9' sx={{ textTransform: 'capitalize', fontWeight: '900', fontFamily: 'Lato' }}>
								get your free copy
							</Typography>
							<Typography variant='body1' sx={{ fontWeight: '300', fontSize: '14px', textAlign: 'center' }}>
								Simply enter your name and phone number below. We will also send you occasional updates on the latest home selling tips and advice.
							</Typography>
							<TextField
								variant='outlined'
								placeholder='Full Name'
								fullWidth
								size='small'
								color='secondary'
								name='name'
								value={details.name}
								onChange={handleDetails}
								helperText={details.nameError}
								sx={{
									'.MuiFormHelperText-root': {
										color: 'red !important',
									},
								}}
							/>
							<TextField
								variant='outlined'
								placeholder='Phone number'
								fullWidth
								size='small'
								color='secondary'
								name='phone'
								value={details.phone}
								onChange={handleDetails}
								helperText={details.phoneError}
								sx={{
									'.MuiFormHelperText-root': {
										color: 'red !important',
									},
								}}
							/>
							<TextField
								variant='outlined'
								placeholder='Email'
								fullWidth
								size='small'
								color='secondary'
								name='email'
								value={details.email}
								onChange={handleDetails}
								helperText={details.emailError}
								sx={{
									'.MuiFormHelperText-root': {
										color: 'red !important',
									},
								}}
							/>
							<MatBut variant='contained' color='primary' size='large' onClick={onSubmit}>
								get my free ebook now
							</MatBut>
							<Typography variant='body1' sx={{ fontWeight: '300', fontSize: '12px', textAlign: 'center', color: '#5F5F5F' }}>
								By entering your name and phone number, you agree to receive occasional marketing emails from us. You can unsubscribe at any time.
							</Typography>
						</Box>
					</Grid>
				</Grid>
				<Box>
					<Typography
						variant='body1'
						sx={{
							fontWeight: '500',
							fontFamily: 'Montserrat',
							fontSize: '16px',
							textAlign: 'center',
							width: {
								xs: '100%',
								sm: '100%',
								md: '60%',
								lg: '60%',
							},
							marginBottom: '1rem',
							mx: 'auto',
							mt: '2rem',
						}}
					>
						{option[selectedOption]?.tip}
					</Typography>
					<Box
						sx={{
							padding: '1.5rem',
							display: 'flex',
							justifyContent: 'center',
							flexDirection: {
								xs: 'column',
								sm: 'column',
								md: 'column',
								lg: 'row',
							},
							alignItems: 'center',
							color: 'white',
							gap: {
								xs: '1rem',
								sm: '1rem',
								md: '3rem',
								lg: '3rem',
							},
							background: 'linear-gradient(311deg, #F1D59C 0%, #DB9200 48.44%, #EBC16F 100%)',
						}}
					>
						<Typography variant='body1' sx={{ fontFamily: 'Lato', fontWeight: '900', color: 'black' }}>
							LIMITED OFFER
						</Typography>
						<Typography variant='body1' sx={{ fontWeight: '500' }}>
							This ebook is available for free for a limited time only. So act now and don't miss out!
						</Typography>
					</Box>
				</Box>
			</Box>
		</ThemeProvider>
	);
};

export default LeadMagnet;
