import React from 'react';
import { useEffect } from 'react';
import { NavLink, Outlet, useNavigate, useParams } from 'react-router-dom';
import Button from '../../components/Button/Button';
import { ReactComponent as Back } from '../../images/back.svg';
import base from '../../apis';
import './managelistings.scss';
import { toast } from 'react-toastify';
import Spinner from '../../components/Spinner/Spinner';
import { useSelector } from 'react-redux';

function ManageListings() {
	// const listings = useLocation().state;
	const navigate = useNavigate();
	const { id } = useParams();
	const [listing, setListing] = React.useState([]);
	const [isLoading, setIsLoading] = React.useState(false);
	const typeId = useSelector((state) => state.auth.typeId);

	useEffect(() => {
		setIsLoading(true);
		base
			.get(`premiersite/listings/${id}`)
			.then(({ data }) => {
				setListing(data);
			})
			.catch(() => {
				toast.error('Error fetching listing');
			})
			.finally(() => {
				setIsLoading(false);
			});
	}, [id]);
	return (
		<>
			{isLoading && <Spinner />}
			<div className='managelistings'>
				<div className='propHolder'>
					<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '20px' }}>
						<Back onClick={() => navigate('../')} style={{ cursor: 'pointer' }} />
						<h3>{listing.address}</h3>
					</div>
					<div className='propHolder-buttons'>
						<Button
							style={{
								width: '200px',
								height: '45px',
								fontSize: '16px',
								padding: '10px 20px',
								backgroundColor: 'white',
								color: 'var(--primary-color)',
								border: '1px solid var(--primary-color)',
								display: 'block',
								marginTop: '20px',
							}}
							onClick={() => {
								window.open(`/view-listing/${typeId}/${id}`, '_blank');
							}}
							text='Preview Website'
						/>
					</div>
				</div>
				<div className='selectHeaders'>
					<div className='selectHeaders-header'>
						<NavLink
							to={{
								pathname: '',
								state: {
									listing,
								},
							}}
							className='selectHeaders-items'
							style={({ isActive }) => (isActive ? { backgroundColor: 'var(--primary-color)', color: 'white' } : undefined)}
							end
						>
							Property Info
						</NavLink>
						<NavLink
							to={{
								pathname: 'description',
								state: {
									listing,
								},
							}}
							className='selectHeaders-items'
							style={({ isActive }) => (isActive ? { backgroundColor: 'var(--primary-color)', color: 'white' } : undefined)}
						>
							Description
						</NavLink>
						<NavLink
							to={{
								pathname: 'images',
								state: {
									listing,
								},
							}}
							className='selectHeaders-items'
							style={({ isActive }) => (isActive ? { backgroundColor: 'var(--primary-color)', color: 'white' } : undefined)}
						>
							Images
						</NavLink>
						<NavLink
							to={{
								pathname: 'videos',
								state: {
									listing,
								},
							}}
							className='selectHeaders-items'
							style={({ isActive }) => (isActive ? { backgroundColor: 'var(--primary-color)', color: 'white' } : undefined)}
						>
							Videos
						</NavLink>
						<NavLink
							to={{
								pathname: 'brochure',
								state: {
									listing,
								},
							}}
							className='selectHeaders-items'
							style={({ isActive }) => (isActive ? { backgroundColor: 'var(--primary-color)', color: 'white' } : undefined)}
						>
							Brochure
						</NavLink>
						<NavLink
							to={{
								pathname: '3d-tour',
								state: {
									listing,
								},
							}}
							className='selectHeaders-items'
							style={({ isActive }) => (isActive ? { backgroundColor: 'var(--primary-color)', color: 'white' } : undefined)}
						>
							3D Video Tour
						</NavLink>
						<NavLink
							to={{
								pathname: 'profile',
								state: {
									listing,
								},
							}}
							className='selectHeaders-items'
							style={({ isActive }) => (isActive ? { backgroundColor: 'var(--primary-color)', color: 'white' } : undefined)}
						>
							Profile
						</NavLink>
					</div>
					<Outlet context={[listing, setIsLoading, setListing]} />
				</div>
			</div>
		</>
	);
}

export default ManageListings;
