import React from 'react'
import useTheme from "@mui/material/styles/useTheme";
import { Box, Typography,FormControl,OutlinedInput,InputAdornment } from '@mui/material';
import HtmlToolTip from './HtmlToolTip';
import StyledLabel from './StyledLabel';
import commaSeparate from '../utils/commaSeparate';

const useStyles = (_theme)=>{
    return{
        lenderPoints:{
            display:"flex",
            alignItems:"center",
            marginTop:"1.25rem",
          },
    }
}


function Points({handler,label,name,toolTipText,data}) {
    const theme = useTheme();
    const styles = useStyles(theme);

    const handleValues = (e) => {
      let value = e.target.value;
      const variable = e.target.name
      if (value) {
        value = Number.parseInt(value.split(",").join(""));
        if (isNaN(value)) {
          return;
        }
        handler({ [variable]: value });
      } else {
        handler({ [variable]: value });
      }
    };

  return (
    <>
      <Box sx={styles.lenderPoints}>
        <Box sx={{display:"flex",alignItems:"center"}}>
          <StyledLabel label={label} />
          <HtmlToolTip content={toolTipText} />
        </Box>

        <FormControl
          sx={{
            minWidth: "18.75rem",
            marginLeft: "3.125rem",
          }}
        >
          <OutlinedInput
            fullWidth
            name={name}
            onChange={handleValues}
            value={commaSeparate(data?.[name])}
            margin="normal"
            inputProps={{ style: { fontSize: "1rem" } }}
            placeholder = '0'
            endAdornment={
              <InputAdornment position="start">
                {" "}
                <Typography variant="adornment" color="secondary">
                  {" "}
                  Points
                </Typography>
              </InputAdornment>
            }
          />
        </FormControl>
      </Box>
    </>
  );
}

export default Points