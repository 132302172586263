export const getDealAnalyzerBaseUrlWithCode = (code)=>{

    const urlArray = [
        'deal_analyzer/straight_model',
        'deal_analyzer/remodel_add_addition',
        'deal_analyzer/remodel_down_studs',
        'deal_analyzer/remodel_down_studs_addition',
        "deal_analyzer/new_construction",
        "deal_analyzer/buy_and_rent"
    ]

    if(!Number.isNaN(+code)){
        return urlArray[+code-1]
    }
}