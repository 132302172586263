import { Button, Grid, Stack, Typography } from '@mui/material';
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import base from '../apis';
import Spinner from '../components/Spinner/Spinner';
import IndThumb1 from '../images/indthumb1.jpg';
import IndThumb2 from '../images/indthumb2.jpg';
import IndThumb3 from '../images/indthumb3.jpg';
import IndThumb4 from '../images/indthumb4.jpg';
import { ImageItem } from './MyListing';

const templates = [
	{
		title: 'Construction Templates',
		options: [
			{
				title: 'construction_1',
				image: IndThumb1,
				id: 1,
			},
			{
				title: 'construction_2',
				image: 'https://bpooperationsmain.s3.eu-west-2.amazonaws.com/ind-professional/ind7thumb.jpg',
				id: 2,
			},
		],
	},
	{
		title: 'Landscaping Templates',
		options: [
			{
				title: 'land_1',
				image: IndThumb2,
				id: 2,
			},
		],
	},
	{
		title: 'Electrical Templates',
		options: [
			{
				title: 'electrical_1',
				image: IndThumb3,
				id: 2,
			},
		],
	},
	{
		title: 'Plumbing Templates',
		options: [
			{
				title: 'plumbing_1',
				image: IndThumb4,
				id: 2,
			},
		],
	},
	{
		title: 'Estate Planning Templates',
		options: [
			{
				title: 'est_planning_1',
				image: 'https://bpooperationsmain.s3.eu-west-2.amazonaws.com/ind-professional/indProfThumb5.jpg',
				id: 1,
			},
		],
	},
	{
		title: 'Roofing Templates',
		options: [
			{
				title: 'roofing_1',
				image: 'https://bpooperationsmain.s3.eu-west-2.amazonaws.com/ind-professional/ind6thumb.jpg',
				id: 1,
			},
		],
	},
];

const IndProfWebHomePage = () => {
	const { indElite } = useSelector((state) => state.subscriptions);
	const typeId = useSelector((state) => state.auth.typeId);
	const selectedListing = useSelector((state) => state.auth.selectedListing);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	useEffect(() => {
		if (!selectedListing) {
			navigate('/my-listing/all');
			return;
		}
		if (!indElite) {
			navigate('/industry-prof-subscription', { replace: true });
		}
	}, [selectedListing, navigate, indElite]);

	const [isChangeLoading, setIsChangeLoading] = useState(false);
	const handleChangeTemplate = async (id) => {
		try {
			setIsChangeLoading(true);
			const { data } = await base.patch(`register/ind-prof/profile/${selectedListing?.id}/`, {
				prof_template: id,
			});
			dispatch({ type: 'UPDATE_LISTING', payload: data });
		} catch (error) {
			toast.error('Could not update template');
		} finally {
			setIsChangeLoading(false);
		}
	};

	return (
		<>
			{isChangeLoading && <Spinner />}
			<Stack sx={{ padding: '1rem', minHeight: '100vh' }} gap='1rem'>
				<Stack direction='row' justifyContent={'space-between'} sx={{ borderBottom: '1px solid #EAECF0', paddingBottom: '1rem' }}>
					<Typography fontWeight={700} fontSize={24} color='#344054' fontFamily='Lato'>
						{' '}
						Industry Website
					</Typography>
					<Button
						sx={{ textTransform: 'capitalize' }}
						variant='outlined'
						color='secondary'
						href={`/industry-professional-website/${typeId}`}
						target='_blank'
					>
						Preview
					</Button>
				</Stack>
				{templates.map((item, index) => (
					<Fragment key={item.title}>
						<Typography
							fontWeight={600}
							fontSize={18}
							mt='2rem'
							color='#101828'
							sx={{ borderBottom: '1px solid #EAECF0', borderTop: index !== 0 ? '1px solid #EAECF0' : '', paddingY: '1rem' }}
						>
							{item.title}
						</Typography>
						<Grid container spacing={2}>
							{item.options.map((el, index) => (
								<Grid item xs={3} key={el.id}>
									<Stack
										sx={{ border: `${el.title === selectedListing?.prof_template ? '2px solid var(--primary-color)' : 'none'}`, borderRadius: '8px' }}
									>
										<ImageItem image={el.image} previewText='Select' deleteText='' onPreview={() => handleChangeTemplate(el.title)} />
									</Stack>
								</Grid>
							))}
						</Grid>
					</Fragment>
				))}
			</Stack>
		</>
	);
};

export default IndProfWebHomePage;
