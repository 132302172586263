import { Badge, ChevronLeft, ChevronRight, LocationOn, Phone } from '@mui/icons-material';
import { Box, Button, Link as DLink, Divider, Grid, IconButton, Stack, TextField, Typography, createTheme } from '@mui/material';
import { ThemeProvider, makeStyles, styled } from '@mui/styles';
import React from 'react';
import { Link as RLink } from 'react-router-dom';
import BG from '../../images/temp11bg.webp';
import { formatNumber, formatPhoneNumber, getYoutubeEmbedLink, hideButton, scrollToTheLeft } from '../../utils/functions';
import InvestorQuestionnaires from '../../components/InvestorQuestionnaires/InvestorQuestionnaires';

const pageTheme = createTheme({
	// ...theme,
	typography: {
		fontFamily: 'Raleway, san-serif',
		button: {
			textTransform: 'capitalize',
		},
	},
	palette: {
		primary: {
			main: '#000',
		},
		secondary: {
			main: '#00167C',
		},
		text: {
			main: '#000',
		},
		paper: {
			main: '#fff',
		},
	},
	primaryColor: '#000',
	secondaryColor: '#00167C',
});
const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		height: '100vh',
		// backgroundImage: `url(${BG})`,
		background: `linear-gradient(0deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.40) 100%), url(${BG}), lightgray 50% / cover no-repeat`,
		backgroundSize: 'cover',
		backgroundPosition: 'center',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	header: {
		position: 'absolute',
		top: '0',
		width: '100%',
		padding: '1.5rem',
		display: 'flex',
		justifyContent: 'space-between',
		// background: 'rgba(0, 0, 0, 0.1)',
		fontFamily: 'Raleway, san-serif',
	},
	padder: {
		padding: '3rem 5rem',
	},
	title: {
		paddingLeft: '1rem',
		textTransform: 'uppercase',
		fontWeight: '500',
		fontSize: '1.5rem',
	},
}));

const Link = styled(DLink)(({ theme }) => ({
	cursor: 'pointer',
	fontFamily: 'Raleway, san-serif',
	fontWeight: '500',
	textTransform: 'capitalize',
}));

const TransBut = styled(Button)(({ theme }) => ({
	backgroundColor: 'transparent',
	border: '1px solid black',
	color: 'black',
	padding: '0.5rem 1rem',
	width: 'fit-content',
	borderRadius: '0',
	textTransform: 'uppercase',
	fontFamily: 'Raleway, san-serif',
	fontWeight: '500',
	fontSize: '16px',
}));

const FullBut = styled(Button)(({ theme }) => ({
	backgroundColor: '#00167C',
	color: 'white',
	padding: '0.5rem 1rem',
	width: 'fit-content',
	borderRadius: '0',
	textTransform: 'uppercase',
	fontFamily: 'Raleway, san-serif',
	fontSize: '16px',
}));

function NewTemp11({ agent, listings, testimonials, contactDetails, updateContactDetails, sendMessage, serviceAreas }) {
	const classes = useStyles();
	const name = agent?.user.first_name + ' ' + agent?.user.last_name;
	const agent_id = agent?.id;
	const member_since = agent?.year_work_commenced;
	const agent_phone = formatPhoneNumber(agent?.phone);
	const brokerage_phone = formatPhoneNumber(agent?.brokerage?.brokerage_phone);
	const brokerage_name = agent?.brokerage_name;
	const license = agent?.licenses.find((license) => license.category === 'real_estate_agent')?.number;
	const address = agent?.address;
	const video = agent?.video_link;
	const showInvestorForm = agent?.questionnaires_on;

	return (
		<ThemeProvider theme={pageTheme}>
			<Box className={classes.root}>
				<Box className={classes.header}>
					<Box display='flex' gap='1rem'>
						<Link href='#home' underline='none' color='white' fontFamily='inherit' sx={{ textTransform: 'uppercase' }}>
							Home
						</Link>
						<Link href='#about' underline='none' color='white' fontFamily='inherit' sx={{ textTransform: 'uppercase' }}>
							About
						</Link>
						{true && (
							<Link href='#active' underline='none' color='white' fontFamily='inherit' sx={{ textTransform: 'uppercase' }}>
								Active Listings
							</Link>
						)}
						<Link href='#contact' underline='none' color='white' fontFamily='inherit' sx={{ textTransform: 'uppercase' }}>
							Contact
						</Link>
						<Link underline='none' color='white' fontFamily='inherit' sx={{ textTransform: 'uppercase' }} href={`/agent/${agent?.id}/blogs`}>
							Blog
						</Link>
						{showInvestorForm && (
							<Link href='#investors' underline='none' color='white' fontFamily='inherit' sx={{ textTransform: 'uppercase' }}>
								Investors
							</Link>
						)}
					</Box>

					<Typography variant='body1' color='white' fontWeight='400' fontSize='16px' sx={{ textTransform: 'capitalize' }}>
						{name}
					</Typography>
				</Box>
				<Typography variant='h4' whiteSpace={'pre-line'} color='white' textTransform='capitalize' fontFamily='Raleway' textAlign='center'>
					{agent?.landing_page_title || 'Welcome'}
				</Typography>
			</Box>
			<Grid container spacing={2} className={classes.padder} alignItems={'center'} justifyContent={'center'} id='about'>
				<Grid item xs={6}>
					<Box>
						<Typography variant={'h6'} className={classes.title} fontFamily='Raleway'>
							who I am
						</Typography>
						<Divider sx={{ width: '100px', height: '5px', background: 'black' }} />
						<Typography variant='body1' whiteSpace={'pre-line'} fontWeight={400} my='1rem' color='#6C757D' fontFamily='Raleway'>
							{agent?.bio}
						</Typography>
						<Stack direction='row' spacing={2} mt='2rem'>
							<TransBut variant='contained' disableElevation href='#contact'>
								Contact Me
							</TransBut>
							<FullBut variant='contained' disableElevation href={`/order-bpo-report/${agent_id}`} target='_blank'>
								Order a bpo report
							</FullBut>
						</Stack>
					</Box>
				</Grid>
				<Grid item xs={6}>
					<img
						src={agent?.profile_image}
						alt='Agent'
						style={{
							width: '400px',
							aspectRatio: '3/4',
							objectFit: 'cover',
							objectPosition: 'top center',
							marginLeft: 'auto',
							marginRight: '1rem',
							display: 'block',
						}}
					/>
				</Grid>
			</Grid>
			{listings?.active.length > 0 && (
				<Box className={classes.padder} sx={{ background: '#f7f9f9', display: 'flex', flexDirection: 'column', position: 'relative' }}>
					<Typography variant='h6' textAlign={'center'} mb='1rem' fontSize='16px' fontWeight={700} fontFamily='Raleway, san-serif'>
						ON THE MARKET
					</Typography>
					<Box
						display='flex'
						gap='20px'
						id='active'
						sx={{
							maxWidth: '100%',
							overflowX: 'scroll',
							padding: '1rem',
							'&::-webkit-scrollbar': {
								display: 'none',
							},
							scrollBehavior: 'smooth',
						}}
					>
						<IconButton
							ref={(el) => hideButton(el, 'active')}
							sx={{
								position: 'absolute',
								top: '40%',
								left: '15px',
								backgroundColor: 'white',
								borderRadius: '2px',
								justifyContent: 'center',
								gap: '2rem',
								'&:hover': { backgroundColor: 'white' },
								boxShadow: '0px 0px 10px 0px #00000026',
							}}
							onClick={() => scrollToTheLeft('active', true)}
						>
							<ChevronLeft fontSize='large' />
						</IconButton>
						{listings?.active?.map((active) => (
							<Card key={active.id} {...active} agentId={agent_id} />
						))}
						<IconButton
							ref={(el) => hideButton(el, 'active')}
							sx={{
								position: 'absolute',
								top: '40%',
								right: '15px',
								backgroundColor: 'white',
								borderRadius: '2px',
								'&:hover': { backgroundColor: 'white' },
								boxShadow: '0px 0px 10px 0px #00000026',
							}}
							onClick={() => scrollToTheLeft('active', false)}
						>
							<ChevronRight fontSize='large' />
						</IconButton>
					</Box>
				</Box>
			)}
			{showInvestorForm && (
				<Box padding={'3rem 5rem'}>
					<InvestorQuestionnaires agent={agent} />
				</Box>
			)}
			{listings?.sold.length > 0 && (
				<Box className={classes.padder} sx={{ display: 'flex', flexDirection: 'column', position: 'relative' }}>
					<Typography variant='h6' textAlign={'center'} mb='1rem' fontSize='16px' fontWeight={700} fontFamily='Raleway, san-serif'>
						PROPERTIES SOLD
					</Typography>
					<Box
						display='flex'
						gap='20px'
						id='sold'
						sx={{
							maxWidth: '100%',
							overflowX: 'scroll',
							padding: '1rem',
							'&::-webkit-scrollbar': {
								display: 'none',
							},
							scrollBehavior: 'smooth',
						}}
					>
						<IconButton
							ref={(el) => hideButton(el, 'sold')}
							sx={{
								position: 'absolute',
								top: '40%',
								left: '15px',
								backgroundColor: 'white',
								borderRadius: '2px',
								justifyContent: 'center',
								gap: '2rem',
								'&:hover': { backgroundColor: 'white' },
								boxShadow: '0px 0px 10px 0px #00000026',
							}}
							onClick={() => scrollToTheLeft('sold', true)}
						>
							<ChevronLeft fontSize='large' />
						</IconButton>
						{listings?.sold?.map((sold) => (
							<Card key={sold.id} {...sold} agentId={agent_id} />
						))}
						<IconButton
							ref={(el) => hideButton(el, 'sold')}
							sx={{
								position: 'absolute',
								top: '40%',
								right: '15px',
								backgroundColor: 'white',
								borderRadius: '2px',
								'&:hover': { backgroundColor: 'white' },
								boxShadow: '0px 0px 10px 0px #00000026',
							}}
							onClick={() => scrollToTheLeft('sold', false)}
						>
							<ChevronRight fontSize='large' />
						</IconButton>
					</Box>
				</Box>
			)}
			{video && (
				<iframe
					style={{
						border: 'none',
						width: '100%',
						height: '70vh',
						margin: '2rem 0',
					}}
					src={`https://www.youtube.com/embed/${getYoutubeEmbedLink(video)}`}
					title='Client video'
					allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
					allowFullScreen
				></iframe>
			)}
			{testimonials?.length > 0 && (
				<Box className={classes.padder} sx={{ background: '#f7f9f9', display: 'flex', flexDirection: 'column', position: 'relative' }}>
					<Typography variant='h6' textAlign={'center'} mb='1rem' fontSize='16px' fontWeight={700} fontFamily='Raleway, san-serif'>
						TESTIMONIALS
					</Typography>
					<Stack direction='row' spacing={2} mt='2rem' justifyContent={'center'}>
						{testimonials.map((testimonial) => (
							<Testimonial key={testimonial.id} {...testimonial} />
						))}
					</Stack>
				</Box>
			)}
			<Grid container spacing={4} id='contact' className={classes.padder} mt='5rem'>
				<Grid item xs={6} sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
					<Box>
						<Typography variant={'h6'} className={classes.title} fontFamily='Raleway'>
							Contact Me
						</Typography>
						<Divider sx={{ width: '100px', height: '5px', background: 'black' }} />
					</Box>
					<TextField
						variant='outlined'
						fullWidth
						size='small'
						name='name'
						placeholder='Full Name'
						value={contactDetails.name}
						onChange={updateContactDetails}
					/>
					<TextField
						variant='outlined'
						fullWidth
						size='small'
						name='email'
						placeholder='Email'
						value={contactDetails.email}
						onChange={updateContactDetails}
					/>
					<TextField
						variant='outlined'
						fullWidth
						size='small'
						name='phone'
						placeholder='Phone Number'
						value={contactDetails.phone}
						onChange={updateContactDetails}
					/>
					<TextField
						variant='outlined'
						fullWidth
						size='small'
						multiline
						rows={5}
						name='message'
						placeholder='Message'
						value={contactDetails.message}
						onChange={updateContactDetails}
					/>
					<TransBut variant='contained' disableElevation onClick={sendMessage}>
						Contact Me
					</TransBut>
				</Grid>
				<Grid item xs={6}>
					<Typography variant={'h6'} fontFamily='Raleway'>
						GET IN TOUCH
					</Typography>
					<Typography variant='body1' fontFamily={'Raleway'}>
						Don't wonder, know the true market value of your home. Request your personalized BPO report now.
					</Typography>
					<Grid container spacing={2} mt='2rem'>
						<Grid item xs={6}>
							<Stack direction={'row'} alignItems='center' gap='1rem'>
								<Phone sx={{ fill: '#00167C' }} fontSize='small' />
								<Box>
									<Typography fontWeight='700' fontFamily='Raleway' fontSize='20px'>
										Phone
									</Typography>
									<Typography fontFamily='Raleway'>{agent_phone}</Typography>
									<Typography fontFamily='Raleway'>{brokerage_phone}</Typography>
								</Box>
							</Stack>
						</Grid>
						<Grid item xs={6}>
							<Stack direction={'row'} alignItems='center' gap='1rem'>
								<LocationOn sx={{ fill: '#00167C' }} fontSize='small' />
								<Box>
									<Typography fontWeight='700' fontFamily='Raleway' fontSize='20px'>
										Address
									</Typography>
									<Typography fontFamily='Raleway'>{address}</Typography>
								</Box>
							</Stack>
						</Grid>
						<Grid item xs={6}>
							<Stack direction={'row'} alignItems='center' gap='1rem'>
								<Badge sx={{ fill: '#00167C' }} fontSize='small' />
								<Box>
									<Typography fontWeight='700' fontFamily='Raleway' fontSize='20px'>
										Others
									</Typography>
									<Typography fontFamily='Raleway'>Member Since: {member_since}</Typography>
									<Typography fontFamily='Raleway'>Real Estate License: {license}</Typography>
									<Typography fontFamily='Raleway'>Brokerage: {brokerage_name}</Typography>
								</Box>
							</Stack>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</ThemeProvider>
	);
}

export default NewTemp11;

const Card = ({ id, agentId, list_price, photos, beds, baths, square_footage, address }) => {
	return (
		<Link component={RLink} to={`/view-listing/${agentId}/${id}`} target='_blank'>
			<Box
				sx={{
					minWidth: '380px',
					background: 'white',
					boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.05)',
					'&:hover p': {
						color: '#00167C',
					},
				}}
			>
				<img
					src={photos?.[0]?.image}
					alt='listing'
					style={{ width: '380px', aspectRatio: '.93', clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 90%)', objectFit: 'cover' }}
				/>
				<Stack padding={'1rem'}>
					<Typography fontSize='30px' fontWeight={'700'} fontFamily='Raleway, san-serif'>
						$ {formatNumber(list_price, 0)}
					</Typography>
					<Typography fontWeight={300} fontFamily='Raleway, san-serif' color='#777'>
						{address}
					</Typography>
					<Stack direction='row' justifyContent={'space-between'}>
						<Typography fontWeight={300} fontFamily='Raleway, san-serif' color='#222'>
							{beds} beds
						</Typography>
						<Typography fontWeight={300} fontFamily='Raleway, san-serif' color='#222'>
							{baths} baths
						</Typography>
						<Typography fontWeight={300} fontFamily='Raleway, san-serif' color='#222'>
							{formatNumber(square_footage, 0)} sqft
						</Typography>
					</Stack>
				</Stack>
			</Box>
		</Link>
	);
};

const Testimonial = ({ description, name, client }) => {
	return (
		<Stack direction='column' justifyContent={'space-between'} sx={{ flex: '1', boxShadow: `0px 4px 20px 0px rgba(0, 0, 0, 0.05)`, padding: '1rem' }}>
			<Typography fontWeight={700} fontFamily='Raleway, san-serif' color='#222'>
				{client?.user?.first_name || client?.user?.last_name ? client?.user?.first_name + ' ' + client?.user?.last_name : name}
			</Typography>
			<Typography fontWeight={300} fontFamily='Raleway, san-serif' color='#777'>
				{description}
			</Typography>
		</Stack>
	);
};
