import { Box, Container, Typography} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import React from 'react'
import { useNavigate } from 'react-router'
import QuestionnaireMainHeader from '../../../../components/DealAnalyzerComponents/QuestionnaireMainHeader'
import MuiThemeLayout from '../../../../Layouts/MuiThemeLayout'
import SARicon from '../../../../assets/dealAnalyzer/iconComponents/SARicon'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'

import * as actionTypes from '../../../../actions/dealAnalyzer'
import useQuestionnaireNewConstructionFlowControl from '../SpecialHooks/useQuestionnaireNewConstructionFlowControl'
import useSubmitQuestionnaire from '../SpecialHooks/useSubmitQuestionnaire'
import useCreateAlert from '../../../../components/DealAnalyzerComponents/hooks/useCreateAlert'
import useQuestionnaireButtons from '../SpecialHooks/useQuestionnaireButtons'
const useStyles = (_theme)=>{
    return {
        root:{
        },
        mainHeading:{
            textAlign:"center",
            marginBottom:"1.25rem",
        },
        componentContainer:{
            display:"flex",
            justifyContent:"center",
            margin:"2rem 0",
        },
        buttonContainer:{
            display:"flex",
            justifyContent:"center",
            margin:"1rem 0",
        },
        button:{
            margin:"0.625rem",
            padding:"0.9375rem 3.75rem",
            textTransform:"none"
        }
    }
}

function QuestionnaireNewConstruction() {
    const theme = useTheme()
    const styles = useStyles(theme)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [activeTab,setActiveTab] = React.useState(0)
    const newConstructionQuestionnaireData = useSelector(state=> state.dealAnalyzerQuestionnaires.newConstruction)
    const dealFinanceOption = newConstructionQuestionnaireData?.deal_finance_option || 0
    const {loading,submitQuestionnaire, submitError} = useSubmitQuestionnaire()
    const {questionnaireButtons:QuestionnaireButtons,buttonDisableControl} = useQuestionnaireButtons()
    const {createAlert} = useCreateAlert()



    const {currentFlow,headings, counterArray} = useQuestionnaireNewConstructionFlowControl(dealFinanceOption)
    const ActiveComponent = currentFlow[activeTab] 
    const extraComponentData = {
        financeDealOptions,
        primaryHeaders,
        secondaryHeaders,
        additionalSqftHeader,
        labels
    }
    const url = "deal_analyzer/new_construction/questionnaire"
    const id = newConstructionQuestionnaireData?.id


    const handleNext = ()=>{
        if(activeTab < headings.length-1){
            setActiveTab(prev=>prev+1)
        }
    }
    const handlePrevious = () => {
        if(activeTab > 0){
            setActiveTab(prev=>prev-1)
        }else{
            navigate(`/analyzer-questionnaire`)
        }
    }
    const handleData = (payload) => {
      dispatch(actionTypes.updateQuestionnaireNewConstruction(payload));
    };
     const handleSubmit = async ()=>{ 
        try{
          await submitQuestionnaire(url,id,newConstructionQuestionnaireData)
          if (submitError){
            createAlert("Submit Error", "error")
          }else{
            createAlert("Submit Successful", "success")
            setTimeout(()=>{
              dispatch(actionTypes.updateMessageQueue())
              navigate("/client-ordered-report")
            },1000)
          }
        }catch{
          createAlert("Submit Error", "error")
        }
      }
    
  return (
    <MuiThemeLayout>
        <Container maxWidth="xl">

        <Box sx={styles.root}>
            <QuestionnaireMainHeader
                analyzerType = 'New Construction'
                icon ={SARicon}
                progress = {counterArray?.[+activeTab]}
                total = {counterArray?.[counterArray?.length-1]}
            />
            <Container maxWidth="lg">
                <Box sx={styles.mainHeading}><Typography variant='menuItemActive'>{headings[activeTab]}</Typography></Box>
                <Box sx={styles.componentContainer}>
                    <ActiveComponent                 
                    data = {newConstructionQuestionnaireData}
                    handler = {handleData}
                    extraComponentData = {extraComponentData}
                    handleButtonDisableControl = {buttonDisableControl}
                    />
                </Box>
                <QuestionnaireButtons
                activeTab={activeTab}
                handleNext = {handleNext}
                loading = {loading}
                handlePrevious = {handlePrevious}
                headings={headings}
                handleSubmit = {handleSubmit}
                />
            </Container>

            </Box>
        </Container>
    </MuiThemeLayout>
  )
}

export default QuestionnaireNewConstruction

const primaryHeaders = {
    0:'Primary Loan for Land',
    1:'Primary Loan for Land',
    2:'Single Primary Loan',
    3:'Primary Loan for New Construction',
    4:'Pay Cash for Land & New Construction'
}
const secondaryHeaders = {
    0: 'Secondary Loan for New Construction',
    1: 'Cash For New Construction',
    3: 'Cash For Land'
}
const additionalSqftHeader = "What is the New Construction square footage you intend to have?"


const financeDealOptions = [
    'Obtain a Primary Loan for Land & a Secondary Loan for New Construction',
    'Obtain a Primary Loan for Land and pay Cash for New Construction',
    'Obtain a Single Loan for Land & New Construction',
    'Obtain a Primary Loan for New Construction & Pay Cash for Land',
    'Pay Cash for Land & New Construction'
  ]
const labels={
    payAllCash:{
        label1:"What is the Purchase Price",
        label2:"What is the New Construction Cost"
    }
}