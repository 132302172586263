import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.scss';
import { Provider } from 'react-redux';
import { applyMiddleware, compose, createStore } from 'redux';
import reduxThunk from 'redux-thunk';
import RootReducer from './reducer';
import { StyledEngineProvider } from '@mui/material';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { PersistGate } from 'redux-persist/integration/react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { HelmetProvider } from 'react-helmet-async';
import ReactGA from 'react-ga4';

const persistConfig = {
	key: 'root',
	storage,
};
const persistedReducer = persistReducer(persistConfig, RootReducer);
//!Remove line below before deployment
let composeEnhancers;
//console.log(process.env)
if (process.env.REACT_APP_URL?.includes('dev-')) {
	composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
} else {
	composeEnhancers = compose;
}

export const store = createStore(persistedReducer, composeEnhancers(applyMiddleware(reduxThunk)));
const persistor = persistStore(store);

ReactGA.initialize('G-53644YWMDD');
// const rootElement = document.getElementById('root');
// const outPutStyle = rootElement.hasChildNodes() ? 'hydrate' : 'render';

ReactDOM.hydrate(
	<Provider store={store}>
		<PersistGate persistor={persistor}>
			<StyledEngineProvider injectFirst>
				<GoogleOAuthProvider clientId='61807210049-7akpgsb23ro9r1a495121mt2f3t3pdsb.apps.googleusercontent.com'>
					<HelmetProvider>
						<App />
					</HelmetProvider>
				</GoogleOAuthProvider>
			</StyledEngineProvider>
		</PersistGate>
	</Provider>,
	document.getElementById('root')
);
