import React, { useState, useRef, useEffect } from "react";
import EnhancedRadioButtonGroup from "../../atomicComponents/EnhancedRadioButtonGroup";
import useTheme from "@mui/material/styles/useTheme";
import { Box, Button, Divider, Typography } from "@mui/material";
import { BiPlus } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";

import StyledLabel from "../../atomicComponents/StyledLabel";
import LoanTypeSelector from "./LoanTypeSelector";
import SecondaryRemodelAddition from "./SecondaryRemodelAddition";
import InterestOnlyMortgage from "../../atomicComponents/InterestOnlyMortgage";
import InterestOnlyMortgageResult from "../../atomicComponents/InterestOnlyMortgageResult";
import LoanTypeSecondary from "./LoanTypeSecondary";
import SecondaryCash from "./SecondaryCash";
import PrimaryHomeLoanBuyAndSell from "./PrimaryHomeLoanBuyAndSell";
import useSaveNow from "../../hooks/useSaveNow";
import useProformaData from "../../hooks/dealReporthooks/useProformaData";
import LoanAmount from "../../atomicComponents/LoanAmount";
import { sarLoanAmount } from "../../utils/loanAmount";
import InterestTermResult from "../../atomicComponents/InterestTermResult";
import { makePmiCompulsory } from "../../utils";
import {
  interestOnlyMortgageCalculator,
  isNaNChecker,
  mortgageLoanCalculator,
  yearsToMonthsTransformer,
} from "../../localCalcutions/utils/genericFunctions";
import { useMemo } from "react";
import { useCallback } from "react";
import * as actionCreators from "../../../../actions/dealAnalyzer/index";

const useStyle = (theme) => {
  return {
    root: {
      padding: "2rem",
    },
    row1: {
      display: "flex",
      marginTop: "1rem",
    },
    button: {
      textTransform: "none",
      width: "100%",
      margin: "1.25rem 0",
      padding: "1rem",
    },
    span: {
      borderBottom: `solid 3px ${theme.palette.primary.main} `,
    },
  };
};

function FinanceOptionsBuyAndRent({ handleEvents, data }) {
  const theme = useTheme();
  const styles = useStyle(theme);
  const [setShowMortgageResult] = useState(false);
  const [mortgagePrimaryResultLoading, setMortgagePrimaryResultLoading] =
    useState(false);
  const [mortgageSecondaryResultLoading, setMortgageSecondaryResultLoading] =
    useState(false);
  const fetchAndUpdateResults = useProformaData()?.fetchResult;

  const { saveNow } = useSaveNow();
  const dealAnalyzerResultsData = useSelector(
    (state) => state?.straightAestheticRemodel.dealAnalyzerResults
  );
  const analyzerData = useSelector((state) => state?.straightAestheticRemodel);
  const secondaryMonthlyPayment =
    dealAnalyzerResultsData?.secondary_finance_option_summary
      ?.total_monthly_payment;
  const showSecondary = !!analyzerData?.financeOptions?.showSecondary;
  const { resultData } = useProformaData();
  const remodelCost = resultData?.remodel_cost?.estimated_total_remodel_cost;

  const financialOptions = useSelector(
    (state) => state?.straightAestheticRemodel.financeOptions
  );

  const [pmi_value, setPmiValue] = useState(
    financialOptions?.secondaryPmiValue
  );

  const scrollRef = useRef(null);
  const [applyPadding, setApplyPadding] = useState(false);
  const mortgageData = useSelector(
    (state) =>
      state?.straightAestheticRemodel.dealAnalyzerResults
        ?.secondary_finance_option_summary?.interest_only_mortgage_loan
  );

  const handleDealFinanceOption = (value) => {
    handleEvents({ deal_finance_option: value });
  };

  const handleShowSecondary = () => {
    handleEvents({ showSecondary: true });
    setTimeout(() => {
      scrollRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }, 0);
  };
  const checkDealFinanceOption = () => {
    return data?.deal_finance_option ? data.deal_finance_option : 0;
  };

  const loanTypeIsConventional =
    +data?.secondary_loan_type === 0 || !data?.secondary_loan_type;
  const loanTypeIsPrivate = +data?.secondary_loan_type === 1;
  const loanTypeIsSeller = +data?.secondary_loan_type === 2;
  const isSellerLoanTypeAndConventional =
    loanTypeIsSeller &&
    (+data?.secondary_sub_loan_type === 0 || !data?.secondary_sub_loan_type);
  const isSellerLoanTypeAndMortgage =
    loanTypeIsSeller && +data?.secondary_sub_loan_type === 1;
  const isPrivateLoanTypeAndConventional =
    loanTypeIsPrivate &&
    (+data?.secondary_sub_loan_type === 0 || !data?.secondary_sub_loan_type);
  const isPrivateLoanTypeAndMortgage =
    loanTypeIsPrivate && +data?.secondary_sub_loan_type === 1;

  const localSecondaryLoanAmount = sarLoanAmount({
    purchasePrice: remodelCost,
    pmi_value,
    lenderPoints: data?.secondary_lender_points,
    wholesaleFee: 0,
    pmi: data?.secondary_include_pmi,
    downPayment: data?.secondary_down_payment,
    downPaymentRate: data?.secondary_dollar_or_percent,
    wholesaleFeeOption: data?.secondary_wholesale_fee_options,
    lenderpointOption: data?.secondary_lender_points_options,
  });

  const dispatch = useDispatch();

  //dispatches secondary loan amounts
  const localSecondaryLoanAmountDispatcher = useCallback(
    () => {
      const amount = {
        localSecondaryLoanAmount: localSecondaryLoanAmount?.effectiveLoan,
      };
      dispatch(actionCreators.updateFinanceOptions(amount));
    },

    // recheck dependencies if effect is updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [localSecondaryLoanAmount?.effectiveLoan]
  );
  useEffect(() => {
    // recheck dependencies if effect is updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    localSecondaryLoanAmountDispatcher();
  }, [localSecondaryLoanAmountDispatcher]);

  useEffect(() => {
    dispatch(
      actionCreators.updateFinanceOptions({ universalRemodelCost: remodelCost })
    );
    // recheck dependencies if effect is updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remodelCost]);

  const showSecondaryToggleButton = () => {
    const dealOption = checkDealFinanceOption();
    return +dealOption === 1;
  };
  const showSecondaryConventionalLoan = () => {
    return (
      loanTypeIsConventional ||
      isSellerLoanTypeAndConventional ||
      isPrivateLoanTypeAndConventional
    );
  };
  const showSecondaryMortgageLoan = () => {
    return (
      (isPrivateLoanTypeAndMortgage || isSellerLoanTypeAndMortgage) &&
      !loanTypeIsConventional
    );
  };
  const buySellPrimaryControls = () => {
    const dealFinanceOption = data?.deal_finance_option;
    const purchaseOnlyMatch = [0, 1, 2];
    const purchaseAndRemodel = [3, 5];
    return {
      showPurchaseOnly:
        purchaseOnlyMatch.includes(+dealFinanceOption) || !dealFinanceOption,
      showPurchaseAndRemodel: purchaseAndRemodel.includes(+dealFinanceOption),
      showPrimary: +dealFinanceOption === 5,
    };
  };

  const fetchResult = async (level) => {
    level === "mortgage"
      ? setMortgageSecondaryResultLoading(true)
      : setMortgagePrimaryResultLoading(true);
    try {
      await saveNow({ finance_options: analyzerData?.financeOptions });
      await fetchAndUpdateResults();
    } catch (err) {
    } finally {
      if (level === "mortgage") {
        setShowMortgageResult(true);
      }
      setMortgagePrimaryResultLoading(false);
      setMortgageSecondaryResultLoading(false);
    }
  };
  const handleShowmortgageResult = () => {
    fetchResult("mortgage");
  };

  useEffect(() => {
    setApplyPadding(true);
    const timer = setTimeout(() => {
      setApplyPadding(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, [showSecondary]);
  useEffect(() => {
    !data?.deal_finance_option && handleEvents({ deal_finance_option: "0" });
    !data?.primary_loan_type && handleEvents({ primary_loan_type: "0" });
    !data?.secondary_loan_type && handleEvents({ secondary_loan_type: "1" });
    !data?.primary_sub_loan_type &&
      handleEvents({ primary_sub_loan_type: "1" });
    !data?.secondary_sub_loan_type &&
      handleEvents({ secondary_sub_loan_type: "1" });
    !data?.primary_month_year && handleEvents({ primary_month_year: "0" });
    !data?.secondary_month_year && handleEvents({ secondary_month_year: "0" });
    !data?.primary_wholesale_fee_options &&
      handleEvents({ primary_wholesale_fee_options: "1" });

    // NOTE: Run effect once on component mount, please
    // recheck dependencies if effect is updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    const checkPmi = makePmiCompulsory(
      data?.secondary_purchase_price,
      data?.secondary_down_payment,
      data?.secondary_dollar_or_percent
    );
    if (checkPmi) {
      handleEvents({ secondary_include_pmi: true });
    } else {
      handleEvents({ secondary_include_pmi: false });
    }
    // NOTE: Run effect once on component mount, please
    // recheck dependencies if effect is updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    data?.primary_purchase_price,
    data?.primary_down_payment,
    data?.primary_dollar_or_percent,
  ]);

  //the mortgage calculator takes interest rate, time and loan amount
  const secondaryInterestRateLocal =
    financialOptions.secondary_interest_rate / 100 / 12;
  const SecondaryTimeInYearsLocal =
    financialOptions.secondary_term_of_loan * 12;
  const secondaryLoanAmountLocal = financialOptions.localSecondaryLoanAmount;

  //secondary amount paid monthly
  const localSecondaryAmount = useMemo(() => {
    const amount = mortgageLoanCalculator(
      secondaryLoanAmountLocal,
      secondaryInterestRateLocal,
      SecondaryTimeInYearsLocal
    );
    dispatch(
      actionCreators.updateCarryingMonths({
        universalSecondaryLoanAmount: amount,
      })
    );
    return isNaNChecker(amount);
    // recheck dependencies if effect is updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    secondaryInterestRateLocal,
    SecondaryTimeInYearsLocal,
    secondaryLoanAmountLocal,
  ]);

  // interest only ====================================================================
  const rate = +financialOptions.secondary_annual_interest_rate;
  const amount = +financialOptions.localSecondaryLoanAmount;
  const monthOrYearChecker =
    financialOptions.secondary_interest_only_term_month_or_year;
  const monthOrYear = financialOptions.secondary_interest_only_term;

  const interestAloneMortgageFunc = useCallback(() => {
    const timeTransformer = yearsToMonthsTransformer(
      monthOrYearChecker,
      monthOrYear
    );
    const res = interestOnlyMortgageCalculator(rate, amount);
    dispatch(
      actionCreators.updateFinanceOptions({ secondaryInterestLoanAlt: res })
    );

    return { secondaryInterestOnly: res, time: timeTransformer };
    // recheck dependencies if effect is updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rate, amount, monthOrYearChecker, monthOrYear]);

  const interestAloneMortgage = useMemo(() => {
    const interestAloneMortgage = interestAloneMortgageFunc();
    return interestAloneMortgage;
  }, [interestAloneMortgageFunc]);

  // const secondaryLocalYearsToMonths = financialOptions.secondaryYearsToMonthsTransformed;
  // const interestOnlyReturned = financialOptions.secondaryInterestOnlyReturned;
  useEffect(() => {
    handleEvents({
      secondaryPmiValue: pmi_value,
    });

    // recheck dependencies if effect is updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pmi_value]);
  const secondaryInterestOnlyResult = interestAloneMortgage.time
    ? {
        interest_only_payment_term: interestAloneMortgage.time,
        interest_only_payment: interestAloneMortgage.secondaryInterestOnly,
      }
    : 0;

  const secondaryInterestOnly = secondaryInterestOnlyResult;

  return (
    <Box sx={styles.root}>
      <StyledLabel label="Select deal finance option" />
      <EnhancedRadioButtonGroup
        options={financeDealOptionsBuyAndSell}
        handler={handleDealFinanceOption}
        choice={data?.deal_finance_option}
        name="deal_finance_option"
      />
      <LoanTypeSelector
        handler={handleEvents}
        options={loanTypeOptions}
        headings={primaryHeadings}
        subOptions={nonConventionalLoanOptions}
        choice={data?.loan_type}
        loanTypeOff={+data?.deal_finance_option === 5}
        headingOverride={+data?.deal_finance_option === 1}
        data={data}
      />
      <PrimaryHomeLoanBuyAndSell
        handler={handleEvents}
        data={data}
        fetchResult={fetchResult}
        inputLabels={""}
        labelsAndNames={loanAmountInputLabelsAndNames}
        overrideOptions={buySellPrimaryControls()}
        mortgagePrimaryResultLoading={mortgagePrimaryResultLoading}
        dealAnalyzerResultsData={dealAnalyzerResultsData}
        remodelCost={remodelCost}
      />
      {(+checkDealFinanceOption() === 4 || +checkDealFinanceOption() === 2) && (
        <SecondaryCash
          data={data}
          handler={handleEvents}
          heading={
            +checkDealFinanceOption() === 4
              ? "Cash for Home"
              : "Cash for Remodel"
          }
          dealOption={+checkDealFinanceOption()}
        />
      )}
      {showSecondaryToggleButton() && ( //Selectively shown for certain deal finance options
        <div
          style={applyPadding ? { paddingBottom: "0px" } : { paddingBottom: 0 }}
        >
          {!showSecondary && (
            <Button
              variant="outlined"
              sx={styles.button}
              onClick={handleShowSecondary}
            >
              <BiPlus fontSize={"1.80px"} />{" "}
              <Typography variant="toggleText">
                Add Secondary Remodel Loan
              </Typography>
            </Button>
          )}
          {showSecondary && (
            <div>
              <LoanTypeSecondary
                handler={handleEvents}
                options={loanTypeOptions}
                subOptions={nonConventionalLoanOptions}
                headings={secondaryHeading}
                choice={
                  data?.secondary_loan_type ? data.secondary_loan_type : ""
                }
                data={data}
              />
              <div ref={scrollRef}></div>

              <SecondaryRemodelAddition
                handler={handleEvents}
                data={data}
                setPmiValue={setPmiValue}
                pmi_value={pmi_value}
                remodelCost={remodelCost}
              />
              {showSecondaryConventionalLoan() && (
                <>
                  <Box>
                    <LoanAmount
                      handler={handleEvents}
                      label="Loan Amount"
                      name="secondary_loan_amount"
                      loanAmount={
                        localSecondaryLoanAmount
                          ? localSecondaryLoanAmount?.effectiveLoan.toFixed()
                          : ""
                      }
                      greyed
                    />
                  </Box>
                  <Box>
                    <InterestTermResult
                      handler={handleEvents}
                      data={data}
                      prefixName="secondary"
                      beforeFetchResult={fetchResult}
                      monthlyPayment={
                        localSecondaryAmount || secondaryMonthlyPayment
                      }
                    />
                  </Box>
                </>
              )}
              {showSecondaryMortgageLoan() && (
                <>
                  <InterestOnlyMortgage
                    handler={handleEvents}
                    data={data}
                    namePrefix="secondary_"
                    mortgageAmount={
                      localSecondaryLoanAmount
                        ? localSecondaryLoanAmount?.effectiveLoan.toFixed()
                        : ""
                    }
                  />
                  {!secondaryInterestOnly && (
                    <Button
                      sx={styles.button}
                      onClick={handleShowmortgageResult}
                      variant="contained"
                    >
                      {!mortgageSecondaryResultLoading && (
                        <Typography variant="h5">CALCULATE</Typography>
                      )}

                      {mortgageSecondaryResultLoading && (
                        <ClipLoader color={"#fff"} loading={true} size={16} />
                      )}
                    </Button>
                  )}
                  {secondaryInterestOnly && (
                    <Divider
                      variant="fullWidth"
                      sx={{ height: "5px", marginTop: "40px" }}
                    />
                  )}
                  {secondaryInterestOnly !== isNaN && (
                    <InterestOnlyMortgageResult
                      data={data}
                      fetchResult={fetchResult}
                      mortgageResults={secondaryInterestOnly || mortgageData}
                    />
                  )}
                </>
              )}
            </div>
          )}
        </div>
      )}
      <Divider variant="fullWidth" sx={{ height: "5px", marginTop: "40px" }} />
    </Box>
  );
}

export default FinanceOptionsBuyAndRent;

const financeDealOptionsBuyAndSell = [
  "Obtain a Primary Loan for Rental",
  "Obtain Primary Home Loan  & a Secondary Remodel Loan",
  "Obtain Primary Home Loan  & Use Cash for Remodel",
  "Obtain single Primary Loan for Home & Remodel",
  "Obtain a Primary Remodel Loan & Pay Cash for Home",
  "Pay All Cash for Home & Remodel",
];

const loanTypeOptions = [
  "Conventional Loan",
  "Private Hard Money",
  "Seller Financing",
];
const primaryHeadings = [
  "Primary Loan for Rent",
  "Primary Home Loan",
  "Single Primary Loan",
  "Primary Remodel Loan",
  "Cash for Home & Remodel",
];
const secondaryHeading = "Secondary Remodel Loan";

export const acronyms = {
  IOM: "Interest Only Mortgage",
  SAR: "Straight Aesthetic Remodel",
};
const nonConventionalLoanOptions = [
  "Conventional Mortgage Loan",
  "Interest Only Mortgage Loan",
];
const loanAmountInputLabelsAndNames = [
  {
    name: "primary_purchase_price",
    label: "Purchase Price",
  },
  {
    name: "primary_remodel_cost",
    label: "Remodel Cost Amount",
  },
];
