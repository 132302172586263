import React,{useState} from "react";
import { Box, Card, Typography, Button, Divider } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Close } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
const useStyles = (theme) => {
  return {
    root: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
    },
    card: {
      padding: "1.25rem",
      width: "35vw",
      minWidth:"20rem"
    },
    close: {
      display: "flex",
      justifyContent: "flex-end",
    },
    buttonContainer: {
      display: "flex",
      width: "18.75rem",
    },
    monthly: {
      width: "50%",
      textAlign: "center",
      padding: "0.625rem 1.875rem",
      border: `solid 1px ${theme.palette.primary.main}`,
      borderRadius: "5px 0 0 5px",
      cursor: "pointer",
    },
    yearly: {
      width: "50%",
      textAlign: "center",
      padding: "0.625rem 1.875rem",
      border: `solid 1px ${theme.palette.primary.main}`,
      borderRadius: "0 5px 5px 0",
      cursor: "pointer",
    },
    active:{
      backgroundColor: theme.palette.primary.main,
      color: "white",
    },
    inactive:{
      backgroundColor: "white",
      color: theme.palette.primary.main,
    }
  };
};

function SelectedOffer({
  handleClose,
  price,
  unit,
  offerScope,
  offerScopeDetail,
  annually,
  annualUnit,
  offerIndex,
}) {
  const [offerPeriod, setOfferPeriod] = useState(1)
  const theme = useTheme();
  const styles = useStyles(theme);
  const navigate = useNavigate()


  const handleClick = ()=>{

    navigate(`/dealanalyzer-confirm-payment?offer=${offerIndex}&offerperiod=${offerPeriod}`)

  }
  return (
    <Box sx={styles.root}>
      <Card sx={styles.card}>
        <Box sx={styles.close}>
          <Close
            onClick={handleClose}
            sx={{ cursor: "pointer", fontSize: "2.1875rem" }}
          />
        </Box>
        <Box>
          <Typography
            variant="smallInactive"
            sx={{ color: "c4c4c4 !important" }}
          >
            MY SELECTED PLAN
          </Typography>
        </Box>
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "1.25rem",
            }}
          >
            <Typography variant="dealCta">{offerScope}</Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="dealCost" sx={{ marginRight: "2rem" }}>
                {price}
              </Typography>
              <Typography variant="h6">{unit}</Typography>
            </Box>
          </Box>
          <Box sx={{ marginBottom: "1rem", width: "50%" }}>
            <Typography variant="h6">{offerScopeDetail}</Typography>
          </Box>
        </Box>
        { offerIndex !==0 &&
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Box sx={styles.buttonContainer}>
            <div
              style={
                offerPeriod === 0
                  ? { ...styles.monthly, ...styles.active }
                  : { ...styles.monthly, ...styles.inactive }
              }
              onClick={() => {
                setOfferPeriod(0);
              }}
            >
              <Typography variant="buttonFont">Monthly</Typography>
            </div>
            <div
              style={
                offerPeriod === 1
                  ? { ...styles.yearly, ...styles.active }
                  : { ...styles.yearly, ...styles.inactive }
              }
              onClick={() => {
                setOfferPeriod(1);
              }}
            >
              <Typography variant="buttonFont">Annually</Typography>
            </div>
          </Box>
        </Box>}
        <Divider
          sx={{
            height: "1px",
            backgroundColor: "#FFD569",
            margin: "1rem 0",
          }}
        />
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="dealCta">Total</Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="dealCost" sx={{ marginRight: "2rem" }}>
                {offerPeriod===1 ?annually : price}
              </Typography>
              <Typography variant="dealCostUnit">{offerPeriod === 1 ? annualUnit:unit}</Typography>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "0.625rem",
          }}
        >
          <Button variant="outlined" sx={{ padding: "0.937rem 3.125rem" }} onClick = {handleClick}>
            <Typography variant="buttonFont" sx={{ textTransform: "none" }}>
              Continue
            </Typography>
          </Button>
        </Box>
      </Card>
    </Box>
  );
}

export default SelectedOffer;
