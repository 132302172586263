import { AttachMoney, BorderAll, Check, Close, Delete, Garage, SquareFoot } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoIcon from '@mui/icons-material/Info';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	FormControl,
	FormControlLabel,
	FormLabel,
	Grid,
	IconButton,
	ImageList,
	ImageListItem,
	InputAdornment,
	InputLabel,
	LinearProgress,
	Paper,
	Radio,
	RadioGroup,
	TextField,
	Tooltip,
	Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';
import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import base, { file } from '../../apis';
import Modal from '../../components/Modal/Modal';
import PlacesSearch from '../../components/PlacesSearch/PlacesSearch';
import Spinner from '../../components/Spinner/Spinner';
import { additionalFields } from '../../constants';
import { ReactComponent as CloseIcon } from '../../images/Close.svg';
import { commaReplace, formatNumber } from '../../utils/functions';
import '../CreateBpoReport/createbporeport.scss';
import { LoadingButton } from '@mui/lab';

const useStyles = makeStyles((theme) => ({
	root: {
		minWidth: '60vh',
		padding: '20px',
	},
	stepButton: {
		width: '80%',
		justifyContent: 'flex-start',
		textTransform: 'none',
		fontWeight: '400',
		color: grey['500'],
		position: 'relative',
		'&:hover': {
			backgroundColor: grey['200'],
		},
		'&[data-selected=true]': {
			color: 'black',
			fontWeight: '500',
			fontSize: '1.1rem',
			'&::before': {
				content: '""',
				position: 'absolute',
				left: '-10px',
				top: '50%',
				transform: 'translateY(-50%)',
				width: '5px',
				height: '100%',
				backgroundColor: theme.palette.secondary.main,
			},
		},
		margin: '10px 0',
	},
	stepButton1: {
		width: '40%',
		justifyContent: 'flex-start',
		textTransform: 'none',
		fontWeight: '400',
		color: grey['500'],
		position: 'relative',
		marginLeft: '1.5rem',
		'&:hover': {
			backgroundColor: grey['200'],
		},
		'&[data-selected=true]': {
			color: 'black',
			fontWeight: '500',
			fontSize: '1.1rem',
		},
	},
	card: {
		padding: '20px',
		height: '55vh',
		overflowY: 'auto',
		scrollBehavior: 'smooth',
	},
	compButton: { marginRight: 'auto', marginBottom: '1rem', display: 'block', textTransform: 'none' },
	listItemImage: {
		position: 'relative',
		'&:hover #delete': {
			display: 'block',
		},
		'& #delete': {
			position: 'absolute',
			top: '10px',
			right: '10px',
			display: 'none',
			cursor: 'pointer',
			// fill:'white',
			fill: '#d52121',
		},
	},
	tick: {
		//center with transform
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
	},
}));

const steps = [
	{ name: 'General Information' },
	{
		name: 'Comparable Closed Sales',
		path: 'comparable_closed_listings',
	},
	{ name: 'Comparable Active Listings', path: 'comparable_active_listings' },
	{ name: 'General Marketing Conditions' },
	{ name: 'Subject Marketability' },
	{ name: 'Comparable Summary' },
	{ name: 'The Market Value' },
	{ name: 'Final Summary' },
];

const current_market_conditions_options = [
	{ name: 'Decreased', helperText: 'The market has gone down in value within the last 3 to 6 months' },
	{ name: 'Stable', helperText: 'The current market values have not decreased or increased in the last 3 to 6  month' },
	{ name: 'Increased', helperText: 'The market value has gone up within the last 3 to 6 months' },
];
const subject_impact = [
	{ name: 'over', helperText: 'Home is in substantially improved,above & beyond neighboring properties' },
	{ name: 'under', helperText: 'Home is substantially lower inquality compared to neighboring properties' },
	{ name: 'appropriate', helperText: 'home is in standard condition compared to neighboring properties' },
];
const unit_type = [
	{ name: 'Single Family Detached', helperText: 'A standalone dwelling that resides in the property, detached from adjacent homes' },
	{ name: 'Single Family Attached', helperText: 'A dwelling that typically has a singular wall attached to another property' },
	{ name: 'Condominium', helperText: 'A multi-unit dwelling that are attached, up to all sides' },
	{ name: 'Town House', helperText: 'Two-story units with attached walls' },
];
const closed_listing_conditions = [
	{ value: 'very_poor', label: 'Very Poor', helperText: 'Select this option if this home is substandard to the rest of the neighborhood' },
	{ value: 'poor', label: 'Poor', helperText: 'Select this option if this home is slightly substandard to the rest of the neighborhood' },
	{ value: 'average', label: 'Average', helperText: 'Select this option if this home is  standard to the rest of the neighborhood' },
	{
		value: 'above_average',
		label: 'Above Average',
		helperText: 'Select this option if this home is slightly above standard to the rest of the neighborhood',
	},
	{ value: 'excellent', label: 'Excellent', helperText: 'Select this option if this home is greatly above standard to the rest of the neighborhood' },
];

const newActiveListing = {
	address: '',
	sales_price: '',
	price_per_gross_living_area: 0,
	days_on_market: '',
	description: '',
	lot_size: '',
	condition: null,
	num_bedrooms: '',
	num_bathrooms: '',
	gross_living_area: '',
	home_style_value: '',
	garage: 0,
	home_style_feedback: '',
	feng_shui_value: '',
	feng_shui_feedback: '',
	proximity_to_amenities_value: '',
	proximity_to_amenities_feedback: '',
	proximity_to_negatives_value: '',
	proximity_neighborhood_negative_feedback: '',
	lot_size_difference_amount: 0,
	garage_difference_amount: 0,
	livable_square_footage_difference_amount: 0,
	images: [],
};

const newComparableClosedListing = {
	address: '',
	sales_price: '',
	price_per_gross_living_area: 0,
	sale_date: '',
	description: '',
	lot_size: '',
	condition: null,
	num_bedrooms: '',
	num_bathrooms: '',
	gross_living_area: '',
	home_style_value: '',
	home_style_feedback: '',
	garage: 0,
	feng_shui_value: '',
	feng_shui_feedback: '',
	proximity_to_amenities_value: '',
	proximity_to_amenities_feedback: '',
	proximity_to_negatives_value: '',
	proximity_neighborhood_negative_feedback: '',
	lot_size_difference_amount: 0,
	garage_difference_amount: 0,
	livable_square_footage_difference_amount: 0,
	images: [],
};

function CreateReport() {
	const card = React.useRef(null);
	const classes = useStyles();
	const [formState, setFormState] = useState(1);
	const [closedState, setClosedState] = useState(1);
	const [activeState, setActiveState] = useState(1);
	const { id } = useParams();
	const auth = useSelector((state) => state.auth);
	const [isLoading, setIsLoading] = useState(false);
	const [hasChanged, setHasChanged] = useState(false);
	const [propertySearch, setPropertySearch] = useState('');
	const [detailsModal, setDetailsModal] = useState(false);
	const closedImageRef = useRef(null);
	const activeImageRef = useRef(null);
	const [isConPriceOpen, setIsConPriceOpen] = useState(false);

	const getLotSizeDifference = (listingPrice, customerLotSize, listingLotSize, gross_living_area) => {
		if (!customerLotSize || !listingPrice || !listingLotSize || !reportDetails?.amount_for_construction_area || !gross_living_area) return 0;
		const priceWithoutCommas = typeof listingPrice === 'string' ? +listingPrice.replace(/,/g, '') : listingPrice;
		const lotSizeDifference = listingLotSize - customerLotSize;
		if (lotSizeDifference === 0) return 0;
		const grossLivingArea = typeof gross_living_area === 'string' ? +gross_living_area.replace(/,/g, '') : gross_living_area;
		const totalBuildCost = grossLivingArea * reportDetails?.amount_for_construction_area;
		const totalLandValue = priceWithoutCommas - totalBuildCost;
		const averageDollarPerFootOfLotSizeOfComp = totalLandValue / Number(listingLotSize);
		const adjustedLotSizeValue = averageDollarPerFootOfLotSizeOfComp * lotSizeDifference;

		return Math.round(adjustedLotSizeValue);
	};

	const getGarageDifference = (customerGarage, listingGarage, price_per_gross_living_area) => {
		if (Math.abs(Number(customerGarage - listingGarage)) === 0 && (!customerGarage || !listingGarage || !price_per_gross_living_area)) return 0;
		const garageDifference = (listingGarage - customerGarage) * 225;
		// const multiplier = priceWithoutCommas / (2 * livableFtWithoutCommas);
		const pricePerGrossLivingArea =
			typeof price_per_gross_living_area === 'string' ? +price_per_gross_living_area.replace(/,/g, '') : price_per_gross_living_area;
		const garageDifferencePrice = garageDifference * (pricePerGrossLivingArea / 2);
		return Math.round(garageDifferencePrice);
	};

	const getInteriorAreaDifference = (listingPrice, customerInteriorArea, listingInteriorArea, price_per_gross_living_area) => {
		if (!customerInteriorArea || !listingPrice || !listingInteriorArea) return 0;
		const pricePerGrossLivingArea =
			typeof price_per_gross_living_area === 'string' ? +price_per_gross_living_area.replace(/,/g, '') : price_per_gross_living_area;
		const interiorWithoutCommas = typeof listingInteriorArea === 'string' ? +listingInteriorArea.replace(/,/g, '') : listingInteriorArea;
		const interiorAreaDifference = interiorWithoutCommas - customerInteriorArea;
		const interiorAreaDifferencePrice = interiorAreaDifference * pricePerGrossLivingArea;
		return Math.round(interiorAreaDifferencePrice);
	};

	const calculateListingPriceAdjustment = (listing) => {
		return formatNumber(Number(listing.sales_price?.replace(/,/g, '') || 0));
	};

	const calculateListingPriceAdjustmentWithFields = (listing) => {
		return formatNumber(
			Number(listing.sales_price?.replace(/,/g, '') || 0) +
				Number(listing.lot_size_difference_amount || 0) +
				Number(listing.garage_difference_amount || 0) +
				Number(listing.livable_square_footage_difference_amount || 0) +
				additionalFields.reduce((acc, field) => acc - Number(`${listing[field.amount] || 0}`?.replace(/,/g, '') || 0), 0),
			0
		);
	};

	const alertUser = useCallback(
		(e) => {
			if (hasChanged) {
				e.preventDefault();
				e.returnValue = '';
			}
		},
		[hasChanged]
	);

	useEffect(() => {
		card.current.scrollTop = 0;
	}, [formState, closedState, activeState]);

	useEffect(() => {
		// window.addEventListener('beforeunload', alertUser);
		// return () => {
		// 	window.removeEventListener('beforeunload', alertUser);
		// };
	}, [alertUser]);

	/**
	 *
	 * @param {[]} data
	 * @returns []
	 */
	const formatClosedListing = (data) => {
		const newClosed = data?.map((item) => {
			return {
				id: item?.id,
				address: item.address,
				sales_price: commaReplace(item.sales_price),
				price_per_gross_living_area: commaReplace(item.price_per_gross_living_area), //!number replace
				sale_date: new Date(item.sale_date).toJSON()?.slice(0, 10),
				description: item.description,
				lot_size: +item.lot_size,
				condition: item.condition,
				num_bedrooms: item.num_bedrooms,
				num_bathrooms: item.num_bathrooms,
				gross_living_area: commaReplace(item.gross_living_area),
				home_style_value: commaReplace(item.home_style_value || 0),
				home_style_feedback: item.home_style_feedback,
				feng_shui_value: commaReplace(item.feng_shui_value || 0),
				feng_shui_feedback: item.feng_shui_feedback,
				proximity_to_amenities_value: commaReplace(item.proximity_to_amenities_value || 0),
				proximity_to_amenities_feedback: item.proximity_to_amenities_feedback,
				proximity_to_negatives_value: commaReplace(item.proximity_to_negatives_value || 0),
				proximity_neighborhood_negative_feedback: item.proximity_neighborhood_negative_feedback,
				lot_size_difference_amount: item.lot_size_difference_amount || 0,
				garage_difference_amount: item.garage_difference_amount || 0,
				livable_square_footage_difference_amount: item.livable_square_footage_difference_amount || 0,
				garage: item.garage || 0,
				images: item.images || [],
			};
		});
		return newClosed;
	};

	/**
	 *
	 * @param {[]} data
	 * @returns []
	 */
	const formatActiveListing = (data) => {
		const newActive = data.map((item) => {
			return {
				id: item?.id,
				address: item.address,
				sales_price: commaReplace(item.sales_price),
				price_per_gross_living_area: commaReplace(item.price_per_gross_living_area), //!number replace
				days_on_market: item.days_on_market || 0,
				description: item.description,
				lot_size: +item.lot_size,
				condition: item.condition,
				num_bedrooms: item.num_bedrooms,
				num_bathrooms: item.num_bathrooms,
				gross_living_area: commaReplace(item.gross_living_area),
				home_style_value: commaReplace(item.home_style_value || 0),
				home_style_feedback: item.home_style_feedback,
				feng_shui_value: commaReplace(item.feng_shui_value || 0),
				feng_shui_feedback: item.feng_shui_feedback,
				proximity_to_amenities_value: commaReplace(item.proximity_to_amenities_value || 0),
				proximity_to_amenities_feedback: item.proximity_to_amenities_feedback,
				proximity_to_negatives_value: commaReplace(item.proximity_to_negatives_value || 0),
				proximity_neighborhood_negative_feedback: item.proximity_neighborhood_negative_feedback,
				lot_size_difference_amount: item.lot_size_difference_amount || 0,
				garage_difference_amount: item.garage_difference_amount || 0,
				livable_square_footage_difference_amount: item.livable_square_footage_difference_amount || 0,
				garage: item.garage || 0,
				images: item.images || [],
			};
		});
		return newActive;
	};

	const formatDataAndSet = useCallback((data) => {
		let ccListings = [...formatClosedListing(data?.comparable_closed_listings || [])];
		if (ccListings.length < 3) {
			ccListings = [...ccListings, ...new Array(3 - ccListings.length).fill(newComparableClosedListing)];
		}
		let caListings = [...formatActiveListing(data?.comparable_active_listings || [])];
		if (caListings.length < 3) {
			caListings = [...caListings, ...new Array(3 - caListings.length).fill(newActiveListing)];
		}

		setReportDetails({
			...data,
			neighborhood_price_lower: data?.neighborhood_price_lower ? commaReplace(data?.neighborhood_price_lower) : '',
			neighborhood_price_upper: data?.neighborhood_price_upper ? commaReplace(data?.neighborhood_price_upper) : '',
			current_market_value: data?.current_market_value ? commaReplace(data?.current_market_value) : '',
			current_suggested_list_price: data?.current_suggested_list_price ? commaReplace(data?.current_suggested_list_price) : '',
			repaired_market_value: data?.repaired_market_value ? commaReplace(data?.repaired_market_value) : '',
			repaired_suggested_list_price: data?.repaired_suggested_list_price ? commaReplace(data?.repaired_suggested_list_price) : '',
			comparable_closed_listings: ccListings,
			comparable_active_listings: caListings,
			customer_livable_square_footage: data.customer_livable_square_footage ? commaReplace(data.customer_livable_square_footage) : '',
		});
		if (data?.phone || data?.completed_by) {
			setHasChanged(true);
		}
	}, []);

	const navigate = useNavigate();

	useEffect(() => {
		const getDetails = async () => {
			setIsLoading(true);
			try {
				const { data: agentData } = await base.get(`register/agents/${auth?.typeId}/`);
				const { brokerage_name, phone, licenses, screen_name } = agentData;
				if (!brokerage_name || !phone || !licenses.find((item) => item.category === 'real_estate_agent') || !screen_name) {
					setIsLoading(false);
					toast.warn('Please complete your profile before creating a report');
					navigate('/profile/edit');
					return;
				}

				const { data } = await base.get(`/leads/report/${id}`);
				setPropertySearch(data.clients_physical_address);
				formatDataAndSet({
					...data,
				});
				setFormState(1);
				if (!data?.amount_for_construction_area) {
					const { data: areaData } = await base.get(`deal_analyzer/city_county_tax?location=${data?.clients_physical_address?.split(',')[1]}`);
					if (areaData?.data.length) {
						setReportDetails((prev) => ({ ...prev, amount_for_construction_area: areaData?.data[0]?.final_new_construction }));
						setHasChanged(true);
					}
				}
			} catch (error) {
				toast.warn('Could not load report');
			} finally {
				setIsLoading(false);
			}
		};
		getDetails();

		// base
		// 	.get(`register/agents/${auth?.typeId}/`)
		// 	.then(({ data: agentData }) => {
		// 		const { brokerage_name, phone, licenses, screen_name } = agentData;
		// 		if (!brokerage_name || !phone || !licenses.find((item) => item.category === 'real_estate_agent') || !screen_name) {
		// 			setIsLoading(false);
		// 			toast.warn('Please complete your profile before creating a report');
		// 			navigate('/profile/edit');
		// 			return;
		// 		}
		// 		base
		// 			.get(`/leads/report/${id}`)
		// 			.then(({ data }) => {
		// 				setPropertySearch(data.clients_physical_address);
		// 				const licen = licenses.find((item) => item.category === 'real_estate_agent');
		// 				formatDataAndSet({
		// 					...data,
		// 					phone: data?.phone || auth?.phone,
		// 					completed_by: data?.completed_by || auth?.first_name + ' ' + auth?.last_name,
		// 					brokerage: data?.brokerage || brokerage_name,
		// 					name: data?.name || `${licen?.number}`,
		// 				});
		// 				if (!data?.phone || !data?.completed_by || !data?.name || !data?.brokerage) {
		// 					setHasChanged(true);
		// 				}
		// 				setFormState(1);
		// 			})
		// 			.catch((err) => {
		// 				toast.warn('Could not load report');
		// 			})
		// 			.finally(() => {
		// 				setIsLoading(false);
		// 			});
		// 	})
		// 	.catch((err) => {
		// 		toast.warn('Could not load report');
		// 	})
		// 	.finally(() => {
		// 		setIsLoading(false);
		// 	});
	}, [formatDataAndSet, id, auth, navigate]);

	const [reportDetails, setReportDetails] = useState({
		clients_physical_address: '',
		brokerage: '',
		firm: '',
		agent: '',
		name: '',
		number: '',
		phone: '',
		completed_by: '',
		current_market_conditions: 'Decreased', //Increased, Decreased, Slow, Stable
		neighborhood_price_lower: '',
		neighborhood_price_upper: '',
		marketing_time: 0,
		subject_impact: 'over',
		unit_type: 'Single Family Detached', //[ Single Family Detached,Single Family Attached Condominium, Town House ]
		market_price_change_percentage: 0,
		time_change: 0,
		market_price_change_months: 0,
		current_market_value: '',
		current_suggested_list_price: '',
		repaired_market_value: '',
		repaired_suggested_list_price: '',
		num_of_active_listings: '',
		active_listings_period: '',
		num_of_sold_listings: '',
		sold_listings_time: '',
		exterior_photos: [],
		interior_photos: [],
		final_summary: '',
		is_property_remodelled: false,
		comparable_closed_listings: [
			{
				address: '',
				sales_price: '',
				price_per_gross_living_area: 0,
				sale_date: '',
				description: '',
				lot_size: '',
				condition: null,
				num_bedrooms: '',
				num_bathrooms: '',
				gross_living_area: '',
				home_style_value: '',
				home_style_feedback: '',
				feng_shui_value: '',
				feng_shui_feedback: '',
				proximity_to_amenities_value: '',
				proximity_to_amenities_feedback: '',
				proximity_to_negatives_value: '',
				proximity_neighborhood_negative_feedback: '',
				lot_size_difference_amount: 0,
				garage_difference_amount: 0,
				livable_square_footage_difference_amount: 0,
				garage: 0,
				images: [],
			},
		],
		comparable_active_listings: [
			{
				address: '',
				sales_price: '',
				price_per_gross_living_area: 0,
				days_on_market: '',
				description: '',
				lot_size: '',
				condition: null,
				num_bedrooms: '',
				num_bathrooms: '',
				gross_living_area: '',
				home_style_value: '',
				home_style_feedback: '',
				feng_shui_value: '',
				feng_shui_feedback: '',
				proximity_to_amenities_value: '',
				proximity_to_amenities_feedback: '',
				proximity_to_negatives_value: '',
				proximity_neighborhood_negative_feedback: '',
				lot_size_difference_amount: 0,
				garage_difference_amount: 0,
				livable_square_footage_difference_amount: 0,
				garage: 0,
				images: [],
			},
		],
	});

	const gotoNext = () => {
		//validation

		if (formState < 8) {
			if (formState === 3 && activeState !== reportDetails.comparable_active_listings.length) {
				setActiveState((cur) => cur + 1);
				if (activeState === reportDetails.comparable_active_listings.length) {
					setFormState((cur) => cur + 1);
				}
				return;
			}
			if (formState === 2 && closedState !== reportDetails.comparable_closed_listings.length) {
				setClosedState((cur) => cur + 1);
				if (closedState === reportDetails.comparable_closed_listings.length) {
					setFormState((cur) => cur + 1);
				}
				return;
			}
			//navigate to preview

			setFormState(formState + 1);
			return;
		}
		navigate('/preview-report/' + id);
	};

	const submitBody = () => {
		const body = {};
		if (reportDetails.status === 'Incomplete') {
			body.status = 'Pending';
			setHasChanged(true);
		}
		if (formState === 1) {
			if (!reportDetails?.amount_for_construction_area || Number(reportDetails?.amount_for_construction_area) <= 0) {
				toast.warn(`Please fill new construction area for ${reportDetails.clients_physical_address.split(',')[1]}`);
				return;
			}
			body.amount_for_construction_area = reportDetails.amount_for_construction_area;
			body.customer_lot_size = `${reportDetails.customer_lot_size}`.replace(/,/g, '');
			body.customer_garage = reportDetails.customer_garage;
			body.customer_livable_square_footage = `${reportDetails.customer_livable_square_footage}`.replace(/,/g, '');
		}
		if (formState === 4) {
			const check = ['current_market_conditions'];
			if (['Increased', 'Decreased'].includes(reportDetails.current_market_conditions)) {
				check.push('market_price_change_percentage');
				check.push('market_price_change_months');
			}
			for (let i = 0; i < check.length; i++) {
				if (!reportDetails[check[i]]) {
					toast.warn(`Please fill ${check[i].split('_').join(' ')}`);
					return;
				}
			}
			check.forEach((key) => {
				body[key] = reportDetails[key];
			});
		}

		if (formState === 5) {
			const check = ['neighborhood_price_lower', 'neighborhood_price_upper', 'subject_impact', 'unit_type'];
			for (let i = 0; i < check.length; i++) {
				if (!reportDetails[check[i]]) {
					toast.warn(`Please fill ${check[i].split('_').join(' ')}`);
					return;
				}
			}
			body['neighborhood_price_lower'] = Number(reportDetails.neighborhood_price_lower?.replace(/,/g, ''));
			body['neighborhood_price_upper'] = Number(reportDetails.neighborhood_price_upper?.replace(/,/g, ''));
			body['subject_impact'] = reportDetails.subject_impact;
			body['marketing_time'] = +reportDetails.marketing_time;
			body['unit_type'] = reportDetails.unit_type;
		}

		if (formState === 2 || formState === 3) {
			const closedListings = reportDetails.comparable_closed_listings
				.filter((item) => item.address)
				.map((item) => {
					const toReturn = {
						address: item.address,
						sales_price: Number((item.sales_price || '')?.replace(/,/g, '')),
						price_per_gross_living_area: Number(`${item.price_per_gross_living_area}`?.replace(/,/g, '')),
						sale_date: item.sale_date ? new Date(item.sale_date)?.toISOString() : null,
						description: item.description,
						lot_size: item.lot_size,
						condition: item.condition,
						num_bedrooms: item.num_bedrooms,
						num_bathrooms: item.num_bathrooms,
						gross_living_area: Number((item.gross_living_area || '')?.replace(/,/g, '')),
						lot_size_difference_amount: item.lot_size_difference_amount || 0,
						garage_difference_amount: item.garage_difference_amount || 0,
						livable_square_footage_difference_amount: item.livable_square_footage_difference_amount || 0,
						garage: +(item.garage || 0),
						images: item.images,
					};
					additionalFields.forEach((field) => {
						toReturn[field.amount] = Number((item[field.amount] || '0')?.replace(/,/g, '') || 0);
						toReturn[field.feedback] = item[field.feedback] || '';
					});
					if (item.id) {
						toReturn['id'] = item.id;
					}
					return toReturn;
				});
			body['comparable_closed_listings'] = closedListings;
			const activeListings = reportDetails.comparable_active_listings
				.filter((item) => item.address)
				.map((item) => {
					const toReturn = {
						address: item.address,
						sales_price: Number((item.sales_price || '')?.replace(/,/g, '')),
						price_per_gross_living_area: Number(`${item.price_per_gross_living_area}`?.replace(/,/g, '')),
						days_on_market: +item.days_on_market,
						description: item.description,
						lot_size: item.lot_size,
						condition: item.condition,
						num_bedrooms: +item.num_bedrooms,
						num_bathrooms: +item.num_bathrooms,
						gross_living_area: Number((item.gross_living_area || '')?.replace(/,/g, '')),
						lot_size_difference_amount: item.lot_size_difference_amount || 0,
						garage_difference_amount: item.garage_difference_amount || 0,
						livable_square_footage_difference_amount: item.livable_square_footage_difference_amount || 0,
						garage: +(item.garage || 0),
						images: item.images,
					};
					additionalFields.forEach((field) => {
						toReturn[field.amount] = Number((item[field.amount] || '')?.replace(/,/g, '') || 0);
						toReturn[field.feedback] = item[field.feedback] || '';
					});
					if (item.id) {
						toReturn['id'] = item.id;
					}
					return toReturn;
				});
			body['comparable_active_listings'] = activeListings;
		}

		if (formState === 6) {
			const check = ['num_of_sold_listings', 'sold_listings_time', 'num_of_active_listings', 'active_listings_period'];
			for (let i = 0; i < check.length; i++) {
				if (!reportDetails[check[i]]) {
					toast.warn(`Please fill ${check[i].split('_').join(' ')}`);
					return;
				}
			}
			check.forEach((key) => {
				body[key] = reportDetails[key];
			});
		}

		if (formState === 7) {
			const check = ['current_market_value', 'current_suggested_list_price'];
			for (let i = 0; i < check.length; i++) {
				if (!reportDetails[check[i]]) {
					toast.warn(`Please fill ${check[i].split('_').join(' ')}`);
					return;
				}
			}

			//todo show only current or repaired
			body['current_market_value'] = Number(reportDetails.current_market_value?.replace(/,/g, ''));
			body['current_suggested_list_price'] = Number(reportDetails.current_suggested_list_price?.replace(/,/g, ''));
			body['is_property_remodelled'] = reportDetails.is_property_remodelled;
		}

		if (formState === 8) {
			if (!reportDetails['final_summary']) {
				toast.warn('Please fill final summary');
				return;
			}
			body['final_summary'] = reportDetails.final_summary;
		}

		// gotoNext();

		if (!hasChanged) {
			gotoNext();
			return;
		}

		setIsLoading(true);
		let call = formState === 2 || formState === 3 ? base.put(`/leads/report/${id}/`, body) : base.patch(`/leads/report/${id}/`, body);

		call
			.then(({ data }) => {
				setIsLoading(false);
				formatDataAndSet(data);
				setHasChanged(false);
				gotoNext();
			})
			.catch((err) => {
				toast.warn('Could not update report');
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const gotoPrev = () => {
		if (formState > 1) {
			if (formState === 3 && activeState - 1 !== 0) {
				setActiveState((cur) => cur - 1);
				return;
			}
			if (formState === 2 && closedState - 1 !== 0) {
				setClosedState((cur) => cur - 1);
				return;
			}
			setFormState(formState - 1);
		}
	};

	const handleReportDetailsChange = (e, commerize = false) => {
		const { name, value } = e.target;
		setHasChanged(true);
		const body = {};
		if (name === 'current_market_conditions' && !['Increased', 'Decreased'].includes(value)) {
			body['market_price_change_percentage'] = 0;
			body['market_price_change_months'] = 0;
		}
		let recValue = value;
		if (commerize) {
			recValue = commaReplace(value);
		}
		setReportDetails({ ...reportDetails, ...body, [name]: recValue });
	};

	const handleListingChange = (e, listingType, index, backupName, commerize = false) => {
		let recValue = backupName === 'address' ? e : e.target.value;
		let nameValue = e?.target?.name || backupName;
		setHasChanged(true);
		if (commerize) {
			recValue = commaReplace(recValue);
		}
		const type = 'active' === listingType ? 'comparable_active_listings' : 'comparable_closed_listings';
		const allListings = [...JSON.parse(JSON.stringify(reportDetails[type]))];

		allListings[index][nameValue] = recValue;
		const currentListing = allListings[index];
		if (nameValue === 'lot_size') {
			currentListing['lot_size_difference_amount'] = getLotSizeDifference(
				currentListing.sales_price,
				reportDetails.customer_lot_size,
				currentListing.lot_size,
				currentListing.gross_living_area
			);
		}
		if (nameValue === 'garage') {
			currentListing['garage_difference_amount'] = getGarageDifference(
				reportDetails.customer_garage,
				currentListing.garage || 0,
				currentListing.price_per_gross_living_area
			);
		}
		if (nameValue === 'gross_living_area') {
			currentListing['garage_difference_amount'] = getGarageDifference(
				reportDetails.customer_garage,
				currentListing.garage || 0,
				currentListing.price_per_gross_living_area
			);
			currentListing['livable_square_footage_difference_amount'] = getInteriorAreaDifference(
				currentListing.sales_price,
				reportDetails.customer_livable_square_footage,
				currentListing.gross_living_area,
				currentListing.price_per_gross_living_area
			);
		}

		if (nameValue === 'sales_price') {
			currentListing['lot_size_difference_amount'] = getLotSizeDifference(
				currentListing.sales_price,
				reportDetails.customer_lot_size,
				currentListing.lot_size,
				currentListing.gross_living_area
			);
			currentListing['garage_difference_amount'] = getGarageDifference(
				reportDetails.customer_garage,
				currentListing.garage || 0,
				currentListing.price_per_gross_living_area
			);
			currentListing['livable_square_footage_difference_amount'] = getInteriorAreaDifference(
				currentListing.sales_price,
				reportDetails.customer_livable_square_footage,
				currentListing.gross_living_area,
				currentListing.price_per_gross_living_area
			);
		}

		setReportDetails({ ...reportDetails, [type]: allListings });
	};

	const handleBlur = (e, listingType, index) => {
		const type = 'active' === listingType ? 'comparable_active_listings' : 'comparable_closed_listings';
		const allListings = [...reportDetails[type]];
		if (reportDetails[type][index]['sales_price'] && reportDetails[type][index]['gross_living_area']) {
			let gross = reportDetails[type][index]['sales_price']?.replace(/,/g, '') / reportDetails[type][index]['gross_living_area']?.replace(/,/g, '');
			gross = Math.ceil(gross);
			allListings[index]['price_per_gross_living_area'] = commaReplace(gross);
		} else {
			allListings[index]['price_per_gross_living_area'] = '0';
		}
		setReportDetails({ ...reportDetails, [type]: allListings });
	};

	const addNewListing = () => {
		const type = formState === 3 ? 'comparable_active_listings' : 'comparable_closed_listings';
		const count = reportDetails[type].length;
		if (count === 5) {
			toast.warn(`You can add only 5 ${type.split('_').join(' ')}`);
			return;
		}
		const newListing = { ...(formState === 2 ? newComparableClosedListing : newActiveListing) };
		setReportDetails({ ...reportDetails, [type]: [...reportDetails[type], newListing] });
		setHasChanged(true);
	};

	const removeListing = (index) => {
		const type = formState === 5 ? 'comparable_active_listings' : 'comparable_closed_listings';
		const allListings = [...reportDetails[type]];
		if (allListings.length === 3) {
			toast.warn(`You can not remove more than 3 ${type.split('_').join(' ')}`);
			return;
		}
		allListings.splice(index, 1);
		setReportDetails({ ...reportDetails, [type]: allListings });
	};

	const [selectedClosedToAdd, setSelectedClosedToAdd] = useState([]);
	const [closedToChooseFrom, setClosedToChooseFrom] = useState([]);
	const getClosedComps = (address = propertySearch) => {
		if (!address) {
			toast.warn('Please enter an address');
			return;
		}
		setIsLoading(true);
		base
			.post('leads/get_listings/', { address: address || reportDetails.address })
			.then(({ data: { data } }) => {
				setClosedToChooseFrom(data);
				if (data.length === 0) {
					toast.warn('No closed listings found');
				}
			})
			.catch((err) => {
				toast.error('Could not fetch closed comparable listings');
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const openGetComps = () => {
		setPropertySearch(reportDetails.clients_physical_address);
		setDetailsModal(true);
		getClosedComps(reportDetails.clients_physical_address);
	};

	const handleSelectedClosedToAdd = (id) => {
		if (selectedClosedToAdd.includes(id)) {
			setSelectedClosedToAdd(selectedClosedToAdd.filter((item) => item !== id));
		} else {
			setSelectedClosedToAdd([...selectedClosedToAdd, id]);
		}
	};

	const onAddToClosed = () => {
		if (selectedClosedToAdd.length === 0) {
			toast.warn('Please select a listing to add');
			return;
		}
		if (selectedClosedToAdd.length > 5) {
			toast.warn('You can add up to 5 listings');
			return;
		}
		const toAdd = closedToChooseFrom.filter((item) => selectedClosedToAdd.includes(item.id));
		const newClosed = toAdd.map((item) => {
			return {
				address: `${item.site_address}, ${item.city}, ${item.state_or_province}, USA`,
				sales_price: commaReplace(item.price),
				price_per_gross_living_area: Math.round(item.price / item.per_sqft),
				sale_date: new Date(item.close_date).toJSON().slice(0, 10),
				description: '',
				lot_size: +item.new_lot_size,
				condition: 'average',
				num_bedrooms: item.bedrooms,
				num_bathrooms: item.bathrooms,
				gross_living_area: commaReplace(item.sqft),
				home_style_value: '',
				home_style_feedback: '',
				garage: item.garage || 0,
				feng_shui_value: '',
				feng_shui_feedback: '',
				proximity_to_amenities_value: '',
				proximity_to_amenities_feedback: '',
				proximity_to_negatives_value: '',
				proximity_neighborhood_negative_feedback: '',
				lot_size_difference_amount: getLotSizeDifference(item.price, reportDetails?.customer_lot_size, +item.new_lot_size, item.gross_living_area),
				garage_difference_amount: getGarageDifference(reportDetails.customer_garage, item.garage || 0, Math.round(item.price / item.per_sqft)),
				livable_square_footage_difference_amount: getInteriorAreaDifference(
					item.price,
					reportDetails.customer_livable_square_footage,
					item.sqft,
					Math.round(item.price / item.per_sqft)
				),
			};
		});

		setHasChanged(true);
		setReportDetails({ ...reportDetails, comparable_closed_listings: [...newClosed] });
		setDetailsModal(false);
	};

	const getCard = () => {
		if (formState === 1) {
			return (
				<Box>
					<Grid container spacing={2}>
						<Grid item xl={12}>
							<InputLabel>Address</InputLabel>
							<TextField
								placeholder='Address'
								fullWidth
								name='clients_physical_address'
								value={reportDetails.clients_physical_address}
								// disabled
								onChange={handleReportDetailsChange}
							/>
						</Grid>
						<Grid item xl={4}>
							<InputLabel>Property Lot Size</InputLabel>
							<TextField
								placeholder='Property Lot Size'
								fullWidth
								name='customer_lot_size'
								value={reportDetails.customer_lot_size || ''}
								onChange={handleReportDetailsChange}
								// disabled
							/>
						</Grid>
						<Grid item xl={4}>
							<InputLabel>Number of Garages</InputLabel>
							<TextField
								placeholder='Number of Garages'
								fullWidth
								// disabled
								type='number'
								name='customer_garage'
								value={reportDetails.customer_garage || ''}
								onChange={handleReportDetailsChange}
							/>
						</Grid>
						<Grid item xl={4}>
							<InputLabel>Livable Square Footage</InputLabel>
							<TextField
								placeholder='Livable Square Footage'
								fullWidth
								// disabled
								name='customer_livable_square_footage'
								value={reportDetails.customer_livable_square_footage || ''}
								onChange={(e) => handleReportDetailsChange(e, true)}
							/>
						</Grid>
						<Grid item xl={8}>
							<InputLabel>New Construction Price for {reportDetails?.clients_physical_address?.split(',')[1] || 'city'} ($/Sqft)</InputLabel>
							<TextField
								placeholder={`New Construction Price for ${reportDetails?.clients_physical_address?.split(',')[1] || 'city'}`}
								fullWidth
								name='amount_for_construction_area'
								value={reportDetails.amount_for_construction_area}
								onChange={handleReportDetailsChange}
							/>
						</Grid>
						<Grid item xl={4} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center' }}>
							<Tooltip title='Click to search for a specific city'>
								<InfoIcon color='secondary' fontSize='medium' sx={{ cursor: 'pointer' }} />
							</Tooltip>
							<Button
								variant='contained'
								color='secondary'
								onClick={() => {
									setIsConPriceOpen(true);
								}}
							>
								Get Price
							</Button>
						</Grid>
					</Grid>
				</Box>
			);
		}
		if (formState === 4) {
			return (
				<Box>
					<FormControl>
						<FormLabel sx={{ color: 'black' }}>
							Current Marketing Conditions{' '}
							<Tooltip title='Specifies how the market (where the subject property) is performing within the last 3 to 6 months'>
								<InfoIcon color='secondary' fontSize='14px' sx={{ cursor: 'pointer' }} />
							</Tooltip>
						</FormLabel>
						<RadioGroup
							row
							name='current_market_conditions'
							value={reportDetails.current_market_conditions}
							onChange={(e, val) => handleReportDetailsChange(e, false)}
						>
							{current_market_conditions_options.map((option, index) => (
								<Tooltip key={option.name} title={option.helperText}>
									<FormControlLabel value={option.name} control={<Radio color='secondary' />} label={option.name} />
								</Tooltip>
							))}
						</RadioGroup>
					</FormControl>
					<FormControl sx={{ marginTop: '1rem' }}>
						{['Decreased', 'Increased'].includes(reportDetails.current_market_conditions) && (
							<>
								<FormLabel sx={{ color: 'black', marginBottom: '10px' }}>Approximate marketing price of this property has:</FormLabel>
								<Box display='flex' gap='10px' alignItems='center'>
									<Typography variant='body1'>{reportDetails.current_market_conditions} by</Typography>
									<TextField
										type='number'
										name='market_price_change_percentage'
										value={reportDetails.market_price_change_percentage}
										onChange={handleReportDetailsChange}
										InputProps={{
											endAdornment: (
												<InputAdornment position='end' sx={{ '&>p': { color: 'black !important' }, marginRight: '5px' }}>
													%
												</InputAdornment>
											),
											sx: { width: '120px' },
											inputProps: { min: 0, max: 100, required: true },
										}}
									/>
									<Typography variant='body1'>in the past </Typography>
									<TextField
										type='number'
										name='market_price_change_months'
										value={reportDetails.market_price_change_months}
										onChange={handleReportDetailsChange}
										InputProps={{
											endAdornment: (
												<InputAdornment position='end' sx={{ '&>p': { color: 'black !important' }, marginRight: '5px' }}>
													months
												</InputAdornment>
											),
											sx: { width: '150px' },
											inputProps: { min: 0, required: true },
										}}
									/>
								</Box>
							</>
						)}
					</FormControl>
				</Box>
			);
		}
		if (formState === 5) {
			return (
				<Box>
					<FormControl sx={{ marginTop: '1rem' }}>
						<Box display='flex' gap='10px' alignItems='center' flexWrap='wrap'>
							<Typography variant='body1'>
								Approximate range of values in the neighborhood is{' '}
								<Tooltip title="Indicates the low to high range sale's price of homes in the area">
									<InfoIcon color='secondary' fontSize='14px' sx={{ cursor: 'pointer' }} />
								</Tooltip>
							</Typography>
							<TextField
								value={reportDetails.neighborhood_price_lower}
								onChange={(e) => handleReportDetailsChange(e, true)}
								name='neighborhood_price_lower'
								InputProps={{
									startAdornment: (
										<InputAdornment position='end' sx={{ '&>p': { color: 'black !important' }, marginRight: '5px' }}>
											$
										</InputAdornment>
									),
									sx: { width: '300px' },
									inputProps: { min: 0, max: 100, required: true },
								}}
							/>
							<Typography variant='body1'>to </Typography>
							<TextField
								value={reportDetails.neighborhood_price_upper}
								onChange={(e) => handleReportDetailsChange(e, true)}
								name='neighborhood_price_upper'
								InputProps={{
									startAdornment: (
										<InputAdornment position='end' sx={{ '&>p': { color: 'black !important' }, marginRight: '5px' }}>
											$
										</InputAdornment>
									),
									sx: { width: '300px' },
									inputProps: { min: 0, max: 100, required: true },
								}}
							/>
						</Box>
					</FormControl>
					<FormControl sx={{ marginTop: '1.5rem' }}>
						<FormLabel sx={{ color: 'black' }}>
							The subject is an ______ for the neighborhood{' '}
							<Tooltip title='Indicates how the home condition compares to others in the area'>
								<InfoIcon color='secondary' fontSize='14px' sx={{ cursor: 'pointer' }} />
							</Tooltip>
						</FormLabel>
						<RadioGroup row name='subject_impact' value={reportDetails.subject_impact} onChange={(e, val) => handleReportDetailsChange(e, false)}>
							{subject_impact.map(({ name, helperText }) => (
								<Tooltip title={helperText} key={name}>
									<FormControlLabel value={name} control={<Radio color='secondary' />} label={name + ' improvement'} />
								</Tooltip>
							))}
						</RadioGroup>
					</FormControl>
					<Box display='flex' gap='10px' alignItems='center' flexWrap='wrap' mt={1.5}>
						<Typography variant='body1'>
							Typical marketing time in the area is:{' '}
							<Tooltip title='Time a home in the area sits on the market for, prior to sale'>
								<InfoIcon color='secondary' fontSize='14px' sx={{ cursor: 'pointer' }} />
							</Tooltip>
						</Typography>
						<TextField
							type='number'
							name='marketing_time'
							value={reportDetails.marketing_time}
							onChange={handleReportDetailsChange}
							InputProps={{
								endAdornment: (
									<InputAdornment position='end' sx={{ '&>p': { color: 'black !important' }, marginRight: '5px' }}>
										days
									</InputAdornment>
								),
								sx: { width: '180px' },
								inputProps: { min: 0, required: true },
							}}
						/>
					</Box>
					<FormControl sx={{ marginTop: '1.5rem' }}>
						<FormLabel sx={{ color: 'black' }}>
							Dwelling Type:{' '}
							<Tooltip title='Indicates the configuration of the property'>
								<InfoIcon color='secondary' fontSize='14px' sx={{ cursor: 'pointer' }} />
							</Tooltip>
						</FormLabel>
						<RadioGroup row name='unit_type' value={reportDetails.unit_type} onChange={(e, val) => handleReportDetailsChange(e, false)}>
							{unit_type.map(({ name, helperText }) => (
								<Tooltip title={helperText} key={name}>
									<FormControlLabel value={name} control={<Radio color='secondary' />} label={name} />
								</Tooltip>
							))}
						</RadioGroup>
					</FormControl>
				</Box>
			);
		}
		if (formState === 2) {
			return (
				<Box>
					<Box display='flex' alignItems='center' justifyContent='space-between' mb='1rem'>
						{closedState === 1 && (
							<Box display='flex' gap='10px' alignItems='center'>
								<Button
									variant='contained'
									color='secondary'
									className={classes.compButton}
									sx={{ marginRight: '0 !important', marginBottom: '0 !important' }}
									onClick={openGetComps}
								>
									Get Comparables
								</Button>
								<Tooltip title='Locate a home similar to subject property'>
									<InfoIcon color='secondary' fontSize='14px' sx={{ cursor: 'pointer' }} />
								</Tooltip>
							</Box>
						)}
						<Button variant='contained' color='primary' sx={{ textTransform: 'capitalize', color: 'white' }} onClick={() => setZillowModalOpen(true)}>
							Add from Zillow
						</Button>
					</Box>
					<PlacesSearch
						mui={true}
						fullWidth
						value={reportDetails.comparable_closed_listings[closedState - 1].address}
						name='address'
						onChange={(e) => handleListingChange(e, 'closed', closedState - 1, 'address')}
						placeholder='Enter comparable address'
					/>
					<Grid container spacing={2} mt='1rem'>
						<Grid item xl={6}>
							<InputLabel>
								Sale Price{' '}
								<Tooltip title='The most recent price the comparable was sold for'>
									<InfoIcon color='secondary' fontSize='14px' sx={{ cursor: 'pointer' }} />
								</Tooltip>
							</InputLabel>
							<TextField
								placeholder='Sale Price'
								fullWidth
								value={reportDetails.comparable_closed_listings[closedState - 1].sales_price}
								onChange={(e) => handleListingChange(e, 'closed', closedState - 1, 'sales_price', true)}
								onBlur={(e) => handleBlur(e, 'closed', closedState - 1)}
								name='sales_price'
								InputProps={{
									startAdornment: (
										<InputAdornment position='end' sx={{ '&>p': { color: 'black !important' }, marginRight: '5px' }}>
											$
										</InputAdornment>
									),
								}}
							/>
						</Grid>
						<Grid item xl={6}>
							<InputLabel>
								Gross Living Area{' '}
								<Tooltip title='The interior living space (typically excludes garages & carports)'>
									<InfoIcon color='secondary' fontSize='14px' sx={{ cursor: 'pointer' }} />
								</Tooltip>
							</InputLabel>
							<TextField
								placeholder='Gross Living Area'
								fullWidth
								value={reportDetails.comparable_closed_listings[closedState - 1].gross_living_area}
								onChange={(e) => handleListingChange(e, 'closed', closedState - 1, 'gross_living_area', true)}
								name='gross_living_area'
								onBlur={(e) => handleBlur(e, 'closed', closedState - 1)}
								InputProps={{
									endAdornment: (
										<InputAdornment position='end' sx={{ '&>p': { color: 'black !important' }, marginRight: '5px' }}>
											Sqft
										</InputAdornment>
									),
								}}
							/>
						</Grid>
						<Grid item xl={6}>
							<InputLabel>
								Price/Gross Living Area{' '}
								<Tooltip title='Sales price divided by livable area'>
									<InfoIcon color='secondary' fontSize='14px' sx={{ cursor: 'pointer' }} />
								</Tooltip>
							</InputLabel>
							<TextField
								placeholder='Price/Gross Living Area'
								fullWidth
								disabled
								onBlur={(e) => handleBlur(e, 'closed', closedState - 1)}
								value={reportDetails.comparable_closed_listings[closedState - 1].price_per_gross_living_area}
								InputProps={{
									endAdornment: (
										<InputAdornment position='end' sx={{ '&>p': { color: 'black !important' }, marginRight: '5px' }}>
											$/Sqft
										</InputAdornment>
									),
								}}
							/>
						</Grid>
						<Grid item xl={6}>
							<InputLabel>
								Sale Date
								<Tooltip title='Date property was sold'>
									<InfoIcon color='secondary' fontSize='14px' sx={{ cursor: 'pointer' }} />
								</Tooltip>
							</InputLabel>
							<TextField
								type='date'
								value={reportDetails.comparable_closed_listings[closedState - 1].sale_date}
								onChange={(e) => handleListingChange(e, 'closed', closedState - 1, 'sale_date')}
								name='sale_date'
								placeholder='Sale Date'
								fullWidth
							/>
						</Grid>
						<Grid item xl={12}>
							<InputLabel>
								Description{' '}
								<Tooltip title='Please describe in your own words, the comparable property compared to the subject property'>
									<InfoIcon color='secondary' fontSize='14px' sx={{ cursor: 'pointer' }} />
								</Tooltip>
							</InputLabel>
							<TextField
								value={reportDetails.comparable_closed_listings[closedState - 1].description}
								onChange={(e) => handleListingChange(e, 'closed', closedState - 1, 'description')}
								name='description'
								placeholder='Description'
								fullWidth
								multiline
								inputProps={{ minLength: 50 }}
								rows={3}
							/>
						</Grid>
						<Grid item xl={6}>
							<InputLabel>
								Lot Size (Sq.Ft.){' '}
								<Tooltip title='Indicates the size of the land the property resides on'>
									<InfoIcon color='secondary' fontSize='14px' sx={{ cursor: 'pointer' }} />
								</Tooltip>
							</InputLabel>
							<TextField
								type='number'
								value={reportDetails.comparable_closed_listings[closedState - 1].lot_size}
								onChange={(e) => handleListingChange(e, 'closed', closedState - 1, 'lot_size')}
								name='lot_size'
								placeholder='Lot Size'
								fullWidth
							/>
						</Grid>
						<Grid item xl={6}>
							<InputLabel>
								Number of Bedrooms{' '}
								<Tooltip title='The amount of bedrooms in the home'>
									<InfoIcon color='secondary' fontSize='14px' sx={{ cursor: 'pointer' }} />
								</Tooltip>
							</InputLabel>
							<TextField
								type='number'
								value={reportDetails.comparable_closed_listings[closedState - 1].num_bedrooms}
								onChange={(e) => handleListingChange(e, 'closed', closedState - 1, 'num_bedrooms')}
								name='num_bedrooms'
								placeholder='Number of Bedrooms'
								fullWidth
							/>
						</Grid>
						<Grid item xl={6}>
							<InputLabel>
								Number of Bathrooms{' '}
								<Tooltip title='The amount of bathrooms in the home'>
									<InfoIcon color='secondary' fontSize='14px' sx={{ cursor: 'pointer' }} />
								</Tooltip>
							</InputLabel>
							<TextField
								type='number'
								value={reportDetails.comparable_closed_listings[closedState - 1].num_bathrooms}
								onChange={(e) => handleListingChange(e, 'closed', closedState - 1, 'num_bathrooms')}
								name='num_bathrooms'
								placeholder='Number of Bathrooms'
								fullWidth
							/>
						</Grid>
						<Grid item xl={6}>
							<InputLabel>
								Number of Garages{' '}
								<Tooltip title='The number of garages in the home'>
									<InfoIcon color='secondary' fontSize='14px' sx={{ cursor: 'pointer' }} />
								</Tooltip>
							</InputLabel>
							<TextField
								type='number'
								value={reportDetails.comparable_closed_listings[closedState - 1].garage}
								onChange={(e) => handleListingChange(e, 'closed', closedState - 1, 'garage')}
								name='garage'
								placeholder='Number of Garages'
								fullWidth
							/>
						</Grid>
						<Grid item xl={12}>
							<FormControl sx={{ marginTop: '1.5rem' }}>
								<FormLabel sx={{ color: 'black' }}>
									Condition{' '}
									<Tooltip title='Specifies the condition of the comparable property compared to the subject property'>
										<InfoIcon color='secondary' fontSize='14px' sx={{ cursor: 'pointer' }} />
									</Tooltip>
								</FormLabel>
								<RadioGroup
									row
									value={reportDetails.comparable_closed_listings[closedState - 1].condition}
									onChange={(e) => handleListingChange(e, 'closed', closedState - 1, 'condition')}
									name='condition'
								>
									{closed_listing_conditions.map((option) => (
										<Tooltip title={option.helperText} key={option.label}>
											<FormControlLabel value={option.value} control={<Radio color='secondary' />} label={option.label} />
										</Tooltip>
									))}
								</RadioGroup>
							</FormControl>
						</Grid>
						<Grid item xl={12} my={'1rem'}>
							<Typography sx={{ textDecoration: 'underline' }} color='secondary'>
								Property Desirability
							</Typography>
						</Grid>
						{additionalFields.map((field) => (
							<Fragment key={field.label}>
								<Grid item xl={12}>
									<Typography sx={{ display: 'inline', marginRight: '5px' }}>{field.label}</Typography>
									<Tooltip title={field.tooltip}>
										<InfoIcon color='secondary' fontSize='14px' sx={{ cursor: 'pointer' }} />
									</Tooltip>
								</Grid>
								<Grid item xl={5}>
									<InputLabel sx={{ fontSize: '16px' }}>
										Amount{' '}
										<Tooltip
											title={`The amount the ${field.label.toLowerCase()} increases/decreases the value of the property. Indicate with a minus if decrease`}
										>
											<InfoIcon color='secondary' fontSize='14px' sx={{ cursor: 'pointer' }} />
										</Tooltip>
									</InputLabel>
									<Tooltip title={'Indicate with a minus if decrease'}>
										<TextField
											value={reportDetails.comparable_closed_listings[closedState - 1][field.amount] || ''}
											onChange={(e) => handleListingChange(e, 'closed', closedState - 1, field.amount, true)}
											name={field.amount}
											placeholder={`Amount`}
											fullWidth
											InputProps={{
												startAdornment: (
													<InputAdornment position='start'>
														<AttachMoney />
													</InputAdornment>
												),
											}}
										></TextField>
									</Tooltip>
								</Grid>
								<Grid item xl={7}>
									<InputLabel sx={{ fontSize: '16px' }}>
										Feedback{' '}
										<Tooltip title={`Any comments on the ${field.label.toLowerCase()}`}>
											<InfoIcon color='secondary' fontSize='14px' sx={{ cursor: 'pointer' }} />
										</Tooltip>
									</InputLabel>
									<TextField
										multiline
										value={reportDetails.comparable_closed_listings[closedState - 1][field.feedback]}
										onChange={(e) => handleListingChange(e, 'closed', closedState - 1, field.feedback)}
										name={field.feedback}
										placeholder={`Feedback`}
										fullWidth
									/>
								</Grid>
							</Fragment>
						))}
						<Grid item xl={12} mt={'1rem'}>
							<Typography sx={{ textDecoration: 'underline' }} color='secondary'>
								Price Adjustments
							</Typography>
						</Grid>
						<Grid item xs={4}>
							Lot Size: $ {formatNumber(reportDetails.comparable_closed_listings[closedState - 1].lot_size_difference_amount, 0)}
						</Grid>
						<Grid item xs={4}>
							Garage: $ {formatNumber(reportDetails.comparable_closed_listings[closedState - 1].garage_difference_amount, 0)}
						</Grid>
						<Grid item xs={4}>
							Interior Livable Area: ${' '}
							{formatNumber(reportDetails.comparable_closed_listings[closedState - 1].livable_square_footage_difference_amount, 0)}
						</Grid>
						{reportDetails.comparable_closed_listings[closedState - 1].sales_price && (
							<>
								<Grid item xl={12} mt={'0.5rem'}>
									<Typography sx={{ textDecoration: 'underline' }} color='secondary'>
										Suggested List Price After Comp #{closedState} Adjustments
									</Typography>
								</Grid>
								<Grid item xl={12} mb={'0.5rem'}>
									<Typography variant='body1'>
										$ {calculateListingPriceAdjustmentWithFields(reportDetails.comparable_closed_listings[closedState - 1])}
									</Typography>
								</Grid>
							</>
						)}
						<Grid item xs={12}>
							<Typography>Images</Typography>
						</Grid>
						<Grid item xs={12}>
							{reportDetails.comparable_closed_listings[closedState - 1]?.images?.length > 0 && (
								<>
									<ImageList sx={{ mx: 'auto', overflowY: 'unset' }} cols={3} rowHeight={200}>
										{reportDetails.comparable_closed_listings[closedState - 1]?.images.map((item) => (
											<ImageListItem key={item} className={classes.listItemImage}>
												<img
													src={`${item}`}
													alt={'Property'}
													style={{
														cursor: 'pointer',
														border: '2px solid white',
														borderRadius: '5px',
														aspectRatio: '4/3',
													}}
												/>
												<Delete id='delete' onClick={() => onDeleteImage('comparable_closed_listings', closedState - 1, item)} />
											</ImageListItem>
										))}
									</ImageList>
								</>
							)}
							{reportDetails.comparable_closed_listings[closedState - 1]?.images.length < 3 && (
								<>
									<input
										type='file'
										accept='image/png, image/jpeg'
										name={`comparable_closed_listings-${closedState - 1}`}
										style={{ display: 'none' }}
										ref={closedImageRef}
										multiple
										onChange={setImages}
									/>
									<Button
										color='secondary'
										onClick={() => {
											closedImageRef.current.click();
										}}
									>
										Add Images (3 max)
									</Button>
								</>
							)}
						</Grid>
					</Grid>
				</Box>
			);
		}
		if (formState === 3) {
			return (
				<Box>
					<Box display='flex' alignItems='center' justifyContent='space-between' mb='1rem'>
						{activeState === 1 && (
							<Box display='flex' gap='10px' alignItems='center'>
								<Button
									variant='contained'
									color='secondary'
									className={classes.compButton}
									sx={{ marginRight: '0 !important', marginBottom: '0 !important', width: 'fit-content' }}
									href={`https://${process.env.REACT_APP_URL.includes('dev') ? 'testing' : ''}.bpohomes.com/homes-for-sale`}
									target='_blank'
								>
									Get Comparables
								</Button>
								<Tooltip title='Locate a home similar to subject property'>
									<InfoIcon color='secondary' fontSize='14px' sx={{ cursor: 'pointer' }} />
								</Tooltip>
							</Box>
						)}
						<Button variant='contained' color='primary' sx={{ textTransform: 'capitalize', color: 'white' }} onClick={() => setZillowModalOpen(true)}>
							Add from Zillow
						</Button>
					</Box>
					<PlacesSearch
						mui={true}
						fullWidth
						value={reportDetails.comparable_active_listings[activeState - 1].address}
						name='address'
						onChange={(e) => handleListingChange(e, 'active', activeState - 1, 'address')}
						placeholder='Enter comparable address'
					/>
					<Grid container spacing={2} mt='1rem'>
						<Grid item xl={6}>
							<InputLabel>
								List Price{' '}
								<Tooltip title='The price the comparable is up for'>
									<InfoIcon color='secondary' fontSize='14px' sx={{ cursor: 'pointer' }} />
								</Tooltip>
							</InputLabel>
							<TextField
								placeholder='List Price'
								fullWidth
								value={reportDetails.comparable_active_listings[activeState - 1].sales_price || ''}
								onChange={(e) => handleListingChange(e, 'active', activeState - 1, 'sales_price', true)}
								name='sales_price'
								onBlur={(e) => handleBlur(e, 'active', activeState - 1)}
								InputProps={{
									startAdornment: (
										<InputAdornment position='end' sx={{ '&>p': { color: 'black !important' }, marginRight: '5px' }}>
											$
										</InputAdornment>
									),
								}}
							/>
						</Grid>
						<Grid item xl={6}>
							<InputLabel>
								Gross Living Area{' '}
								<Tooltip title='The interior living space (typically excludes garages & carports)'>
									<InfoIcon color='secondary' fontSize='14px' sx={{ cursor: 'pointer' }} />
								</Tooltip>
							</InputLabel>
							<TextField
								placeholder='Gross Living Area'
								fullWidth
								value={reportDetails.comparable_active_listings[activeState - 1].gross_living_area || ''}
								onChange={(e) => handleListingChange(e, 'active', activeState - 1, 'gross_living_area', true)}
								name='gross_living_area'
								onBlur={(e) => handleBlur(e, 'active', activeState - 1)}
								InputProps={{
									endAdornment: (
										<InputAdornment position='end' sx={{ '&>p': { color: 'black !important' }, marginRight: '5px' }}>
											Sqft
										</InputAdornment>
									),
								}}
							/>
						</Grid>
						<Grid item xl={6}>
							<InputLabel>
								Price/Gross Living Area{' '}
								<Tooltip title='Sales price divided by livable area'>
									<InfoIcon color='secondary' fontSize='14px' sx={{ cursor: 'pointer' }} />
								</Tooltip>
							</InputLabel>
							<TextField
								placeholder='Price/Gross Living Area'
								fullWidth
								value={reportDetails.comparable_active_listings[activeState - 1].price_per_gross_living_area || ''}
								onChange={(e) => handleListingChange(e, 'active', activeState - 1, 'price_per_gross_living_area', true)}
								name='price_per_gross_living_area'
								InputProps={{
									endAdornment: (
										<InputAdornment position='end' sx={{ '&>p': { color: 'black !important' }, marginRight: '5px' }}>
											$/Sqft
										</InputAdornment>
									),
								}}
							/>
						</Grid>
						<Grid item xl={6}>
							<InputLabel>
								Days on Market{' '}
								<Tooltip title='How many days has the property being on the market'>
									<InfoIcon color='secondary' fontSize='14px' sx={{ cursor: 'pointer' }} />
								</Tooltip>
							</InputLabel>
							<TextField
								type='number'
								value={reportDetails.comparable_active_listings[activeState - 1].days_on_market || ''}
								onChange={(e) => handleListingChange(e, 'active', activeState - 1, 'days_on_market')}
								name='days_on_market'
								placeholder='Days on Market'
								fullWidth
							/>
						</Grid>
						<Grid item xl={12}>
							<InputLabel>
								Description{' '}
								<Tooltip title='Please describe in your own words, the comparable property compared to the subject property'>
									<InfoIcon color='secondary' fontSize='14px' sx={{ cursor: 'pointer' }} />
								</Tooltip>
							</InputLabel>
							<TextField
								placeholder='Description'
								value={reportDetails.comparable_active_listings[activeState - 1].description || ''}
								onChange={(e) => handleListingChange(e, 'active', activeState - 1, 'description')}
								name='description'
								fullWidth
								multiline
								rows={2}
							/>
						</Grid>
						<Grid item xl={6}>
							<InputLabel>
								Lot Size (Sq.Ft.){' '}
								<Tooltip title='Indicates the size of the land the property resides on'>
									<InfoIcon color='secondary' fontSize='14px' sx={{ cursor: 'pointer' }} />
								</Tooltip>
							</InputLabel>
							<TextField
								type='number'
								value={reportDetails.comparable_active_listings[activeState - 1].lot_size || ''}
								onChange={(e) => handleListingChange(e, 'active', activeState - 1, 'lot_size')}
								name='lot_size'
								placeholder='Lot Size'
								fullWidth
							/>
						</Grid>
						<Grid item xl={6}>
							<InputLabel>
								Number of Bedrooms{' '}
								<Tooltip title='The amount of bedrooms in the home'>
									<InfoIcon color='secondary' fontSize='14px' sx={{ cursor: 'pointer' }} />
								</Tooltip>
							</InputLabel>
							<TextField
								type='number'
								value={reportDetails.comparable_active_listings[activeState - 1].num_bedrooms || ''}
								onChange={(e) => handleListingChange(e, 'active', activeState - 1, 'num_bedrooms')}
								name='num_bedrooms'
								placeholder='Number of Bedrooms'
								fullWidth
							/>
						</Grid>
						<Grid item xl={6}>
							<InputLabel>
								Number of Bathrooms{' '}
								<Tooltip title='The amount of bathrooms in the home'>
									<InfoIcon color='secondary' fontSize='14px' sx={{ cursor: 'pointer' }} />
								</Tooltip>
							</InputLabel>
							<TextField
								type='number'
								value={reportDetails.comparable_active_listings[activeState - 1].num_bathrooms || ''}
								onChange={(e) => handleListingChange(e, 'active', activeState - 1, 'num_bathrooms')}
								name='num_bathrooms'
								placeholder='Number of Bathrooms'
								fullWidth
							/>
						</Grid>
						<Grid item xl={6}>
							<InputLabel>
								Number of Garages{' '}
								<Tooltip title='The number of garages in the home'>
									<InfoIcon color='secondary' fontSize='14px' sx={{ cursor: 'pointer' }} />
								</Tooltip>
							</InputLabel>
							<TextField
								type='number'
								value={reportDetails.comparable_active_listings[activeState - 1].garage || ''}
								onChange={(e) => handleListingChange(e, 'active', activeState - 1, 'garage')}
								name='garage'
								placeholder='Number of Garages'
								fullWidth
							/>
						</Grid>
						<Grid item xl={12}>
							<FormControl sx={{ marginTop: '1.5rem' }}>
								<FormLabel sx={{ color: 'black' }}>
									Condition{' '}
									<Tooltip title='Specifies the condition of the comparable property compared to the subject property'>
										<InfoIcon color='secondary' fontSize='14px' sx={{ cursor: 'pointer' }} />
									</Tooltip>
								</FormLabel>
								<RadioGroup
									row
									name='condition'
									value={reportDetails.comparable_active_listings[activeState - 1].condition}
									onChange={(e) => handleListingChange(e, 'active', activeState - 1, 'condition')}
								>
									{closed_listing_conditions.map((option) => (
										<Tooltip title={option.helperText} key={option.label}>
											<FormControlLabel value={option.value} control={<Radio color='secondary' />} label={option.label} />
										</Tooltip>
									))}
								</RadioGroup>
							</FormControl>
						</Grid>
						<Grid item xl={12} my={'1rem'}>
							<Typography sx={{ textDecoration: 'underline' }} color='secondary'>
								Property Desirability
							</Typography>
						</Grid>
						{additionalFields.map((field) => (
							<Fragment key={field.label}>
								<Grid item xl={12}>
									<Typography sx={{ display: 'inline', marginRight: '5px' }}>{field.label}</Typography>
									<Tooltip title={field.tooltip}>
										<InfoIcon color='secondary' fontSize='14px' sx={{ cursor: 'pointer' }} />
									</Tooltip>
								</Grid>
								<Grid item xl={5}>
									<InputLabel sx={{ fontSize: '16px' }}>
										Amount{' '}
										<Tooltip
											title={`The amount the ${field.label.toLowerCase()} increases/decreases the value of the property. Indicate with a minus if decrease`}
										>
											<InfoIcon color='secondary' fontSize='14px' sx={{ cursor: 'pointer' }} />
										</Tooltip>
									</InputLabel>
									<Tooltip title={'Indicate with a minus if decrease'}>
										<TextField
											value={reportDetails.comparable_active_listings[activeState - 1][field.amount] || ''}
											onChange={(e) => handleListingChange(e, 'active', activeState - 1, field.amount, true)}
											name={field.amount}
											placeholder={`Amount`}
											fullWidth
											InputProps={{
												startAdornment: (
													<InputAdornment position='start'>
														<AttachMoney />
													</InputAdornment>
												),
											}}
										></TextField>
									</Tooltip>
								</Grid>
								<Grid item xl={7}>
									<InputLabel sx={{ fontSize: '16px' }}>
										Feedback{' '}
										<Tooltip title={`Any comments on the ${field.label.toLowerCase()}`}>
											<InfoIcon color='secondary' fontSize='14px' sx={{ cursor: 'pointer' }} />
										</Tooltip>
									</InputLabel>
									<TextField
										multiline
										value={reportDetails.comparable_active_listings[activeState - 1][field.feedback] || ''}
										onChange={(e) => handleListingChange(e, 'active', activeState - 1, field.feedback)}
										name={field.feedback}
										placeholder={`Feedback`}
										fullWidth
									/>
								</Grid>
							</Fragment>
						))}
						<Grid item xl={12} mt={'1rem'}>
							<Typography sx={{ textDecoration: 'underline' }} color='secondary'>
								Price Adjustments
							</Typography>
						</Grid>
						<Grid item xs={4}>
							Lot Size: $ {formatNumber(reportDetails.comparable_active_listings[activeState - 1].lot_size_difference_amount, 0)}
						</Grid>
						<Grid item xs={4}>
							Garage: $ {formatNumber(reportDetails.comparable_active_listings[activeState - 1].garage_difference_amount, 0)}
						</Grid>
						<Grid item xs={4}>
							Interior Livable Area: ${' '}
							{formatNumber(reportDetails.comparable_active_listings[activeState - 1].livable_square_footage_difference_amount, 0)}
						</Grid>
						{reportDetails.comparable_active_listings[activeState - 1].sales_price && (
							<>
								<Grid item xl={12} mt={'0.5rem'}>
									<Typography sx={{ textDecoration: 'underline' }} color='secondary'>
										Suggested List Price After Comp #{activeState} Adjustments
									</Typography>
								</Grid>
								<Grid item xl={12} mb={'0.5rem'}>
									<Typography variant='body1'>
										$ {calculateListingPriceAdjustmentWithFields(reportDetails.comparable_active_listings?.[activeState - 1])}
									</Typography>
								</Grid>
							</>
						)}
						<Grid item xs={12}>
							<Typography>Images</Typography>
						</Grid>
						<Grid item xs={12}>
							{reportDetails.comparable_active_listings[activeState - 1]?.images?.length > 0 && (
								<>
									<ImageList sx={{ mx: 'auto', overflowY: 'unset' }} cols={3} rowHeight={200}>
										{reportDetails.comparable_active_listings[activeState - 1]?.images.map((item) => (
											<ImageListItem key={item} className={classes.listItemImage}>
												<img
													src={`${item}`}
													alt={'Property'}
													style={{
														cursor: 'pointer',
														border: '2px solid white',
														borderRadius: '5px',
														aspectRatio: '4/3',
													}}
												/>
												<Delete id='delete' onClick={() => onDeleteImage('comparable_active_listings', activeState - 1, item)} />
											</ImageListItem>
										))}
									</ImageList>
								</>
							)}
							{reportDetails.comparable_active_listings[activeState - 1]?.images.length < 3 && (
								<>
									<input
										type='file'
										accept='image/png, image/jpeg'
										name={`comparable_active_listings-${activeState - 1}`}
										style={{ display: 'none' }}
										ref={activeImageRef}
										multiple
										onChange={setImages}
									/>
									<Button
										color='secondary'
										onClick={() => {
											activeImageRef.current.click();
										}}
									>
										Add Images (3 max)
									</Button>
								</>
							)}
						</Grid>
					</Grid>
				</Box>
			);
		}
		if (formState === 6) {
			return (
				<Box>
					<FormControl sx={{ marginTop: '1rem' }}>
						<Box display='flex' gap='10px' alignItems='center' flexWrap='wrap'>
							<Typography variant='body1'>Approximately</Typography>
							<TextField
								value={reportDetails.num_of_sold_listings || ''}
								onChange={(e) => handleReportDetailsChange(e, false)}
								name='num_of_sold_listings'
								type='number'
								size='small'
								variant='standard'
								color='secondary'
								InputProps={{
									sx: { width: '70px' },
									inputProps: { min: 0 },
								}}
							/>
							<Typography variant='body1'>
								comparable <b>sold</b> listings in the neighborhood in the last{' '}
							</Typography>
							<TextField
								value={reportDetails.sold_listings_time}
								onChange={(e) => handleReportDetailsChange(e, false)}
								name='sold_listings_time'
								type='number'
								size='small'
								variant='standard'
								color='secondary'
								InputProps={{
									sx: { width: '70px' },
									inputProps: { min: 0 },
								}}
							/>
							<Typography variant='body1'>
								months{' '}
								<Tooltip title='Input the amount of comparables sold in the specified set time'>
									<InfoIcon color='secondary' fontSize='14px' sx={{ cursor: 'pointer' }} />
								</Tooltip>
							</Typography>
						</Box>
					</FormControl>
					<FormControl sx={{ marginTop: '3rem' }}>
						<Box display='flex' gap='10px' alignItems='center' flexWrap='wrap'>
							<Typography variant='body1'>Approximately</Typography>
							<TextField
								value={reportDetails.num_of_active_listings}
								onChange={(e) => handleReportDetailsChange(e, false)}
								name='num_of_active_listings'
								type='number'
								size='small'
								variant='standard'
								color='secondary'
								InputProps={{
									sx: { width: '70px' },
									inputProps: { min: 0 },
								}}
							/>
							<Typography variant='body1'>
								comparable <b>active</b> listings in the neighborhood in the last{' '}
							</Typography>
							<TextField
								value={reportDetails.active_listings_period}
								onChange={(e) => handleReportDetailsChange(e, false)}
								name='active_listings_period'
								type='number'
								size='small'
								variant='standard'
								color='secondary'
								InputProps={{
									sx: { width: '70px' },
									inputProps: { min: 0 },
								}}
							/>
							<Typography variant='body1'>
								months{' '}
								<Tooltip title='Input the amount of active comparables in the specified set time'>
									<InfoIcon color='secondary' fontSize='14px' sx={{ cursor: 'pointer' }} />
								</Tooltip>
							</Typography>
						</Box>
					</FormControl>
				</Box>
			);
		}
		if (formState === 7) {
			return (
				<Box>
					<Typography variant='body1' mb={2} fontWeight={500} textAlign='center'>
						(Please indicate the Current Market Value and the Suggested Price List , AS IS OR REMODELED. )
					</Typography>
					<Grid container spacing={2}>
						<Grid item xl={12} display='flex' gap='10px' alignItems='center'>
							<Typography variant='body1'>Is the Subject Property Remodeled</Typography>
							<Checkbox
								name='is_property_remodelled'
								onChange={(e) => {
									setReportDetails({ ...reportDetails, is_property_remodelled: e.target.checked });
									setHasChanged(true);
								}}
								color='secondary'
								checked={reportDetails?.is_property_remodelled}
							/>
						</Grid>
						<Grid item xl={4}>
							<Typography variant='body1' sx={{ width: '100px', marginTop: '2.5rem', whiteSpace: 'nowrap' }}>
								{reportDetails?.is_property_remodelled ? 'REMODELED' : 'AS IS'}{' '}
								<Tooltip title='Current condition of the home'>
									<InfoIcon color='secondary' fontSize='14px' sx={{ cursor: 'pointer' }} />
								</Tooltip>
							</Typography>
						</Grid>
						<Grid item xl={4}>
							<InputLabel>
								Market Value{' '}
								<Tooltip title='The current Market Value of the home based on “As Is” condition'>
									<InfoIcon color='secondary' fontSize='14px' sx={{ cursor: 'pointer' }} />
								</Tooltip>
							</InputLabel>
							<TextField
								value={reportDetails.current_market_value}
								name='current_market_value'
								onChange={(e) => handleReportDetailsChange(e, true)}
								InputProps={{
									startAdornment: (
										<InputAdornment position='end' sx={{ '&>p': { color: 'black !important' }, marginRight: '5px' }}>
											$
										</InputAdornment>
									),
								}}
								fullWidth
							/>
						</Grid>
						<Grid item xl={4}>
							<InputLabel>
								Suggested List Price{' '}
								<Tooltip title='The price you will list under “As Is” condition'>
									<InfoIcon color='secondary' fontSize='14px' sx={{ cursor: 'pointer' }} />
								</Tooltip>
							</InputLabel>
							<TextField
								value={reportDetails.current_suggested_list_price}
								name='current_suggested_list_price'
								onChange={(e) => handleReportDetailsChange(e, true)}
								InputProps={{
									startAdornment: (
										<InputAdornment position='end' sx={{ '&>p': { color: 'black !important' }, marginRight: '5px' }}>
											$
										</InputAdornment>
									),
								}}
								fullWidth
							/>
						</Grid>

						<Grid item xs={12} mt={2}>
							<Typography variant='body1' mb={2}>
								BPO Tech Suggested List Price: $
								<b>
									{formatNumber(
										[...reportDetails.comparable_active_listings, ...reportDetails.comparable_closed_listings]
											.filter((item) => item.sales_price && item.address)
											.reduce((acc, cur) => acc + Number(calculateListingPriceAdjustmentWithFields(cur).replace(/,/g, '')), 0) /
											([...reportDetails.comparable_active_listings, ...reportDetails.comparable_closed_listings].filter(
												(item) => item.sales_price && item.address
											).length || 1),
										0
									) || 0}
								</b>
							</Typography>
							<Typography variant='body1' mb={2}>
								Average Price of Sold Listings: $
								<b>
									{formatNumber(
										reportDetails.comparable_closed_listings
											.filter((item) => item.sales_price && item.address)
											.reduce((acc, cur) => acc + Number(calculateListingPriceAdjustment(cur).replace(/,/g, '')), 0) /
											([...reportDetails.comparable_closed_listings].filter((item) => item.sales_price && item.address).length || 1),
										0
									) || 0}
								</b>
							</Typography>
							<Typography variant='body1' mb={2}>
								Average Price of Active Listings: $
								<b>
									{formatNumber(
										reportDetails.comparable_active_listings
											.filter((item) => item.sales_price && item.address)
											.reduce((acc, cur) => acc + Number(calculateListingPriceAdjustment(cur).replace(/,/g, '')), 0) /
											([...reportDetails.comparable_active_listings].filter((item) => item.sales_price && item.address).length || 1),
										0
									) || 0}
								</b>
							</Typography>
						</Grid>
					</Grid>
				</Box>
			);
		}
		if (formState === 8) {
			return (
				<Box>
					<Box mt={'2rem'}>
						<InputLabel>
							Final Summary{' '}
							<Tooltip title='Express your final thoughts on the property, including reasoning behind the suggested pricing, in comparison to the comparables.  Please include positives, negatives and other insights.  This may include advantages such as: parks, amenities ,shopping, restaurants and disadvantages such as freeways, expressways, busy streets, traffic congestion, noiselevels, other residential areas, neighborhood appeal/feel'>
								<InfoIcon color='secondary' fontSize='14px' sx={{ cursor: 'pointer' }} />
							</Tooltip>
						</InputLabel>
						<TextField
							fullWidth
							multiline
							rows={8}
							value={reportDetails.final_summary || ''}
							name='final_summary'
							onChange={handleReportDetailsChange}
						/>
					</Box>
				</Box>
			);
		}
	};

	const [zillowUrl, setZillowUrl] = useState('');
	const [conPrice, setConPrice] = useState('');
	const [imageModalOpen, setImageModalOpen] = useState(false);
	const [zillowModalOpen, setZillowModalOpen] = useState(false);
	const [selectedImages, setSelectedImages] = useState([]);

	const toggleImage = (url) => {
		if (selectedImages.includes(url)) {
			setSelectedImages(selectedImages.filter((image) => image !== url));
		} else {
			if (selectedImages.length === 3) {
				toast.warn('Max of 3 images allowed');
				return;
			}
			setSelectedImages([...selectedImages, url]);
		}
	};

	const [listingDetails, setListingDetails] = useState(null);

	const getZillowListing = async () => {
		//mui textfield url validation
		if (!zillowUrl || !zillowUrl.includes('https')) {
			toast.warn('Please enter a valid Zillow URL');
			return;
		}
		setIsLoading(true);

		try {
			const { data } = await base.post('/leads/fetch-zillow-data-active-closed/', {
				zillow_url: zillowUrl,
				type: formState === 2 ? 'closed' : 'active',
			});
			//if more than 3 images, open images modal for selection else just add listing
			const images = data.images;
			setListingDetails(data);
			if (images.length > 3) {
				setImageModalOpen(true);

				return;
			}
			setSelectedImages(images);
			onSaveZillowItem(data);
			//save listing to item
		} catch (error) {
			toast.error('Something went wrong');
		} finally {
			setIsLoading(false);
		}
	};

	const [conpriceLoading, setConpriceLoading] = useState(false);
	const [conPriceOptions, setConPriceOptions] = useState([]);

	const getConPrice = async () => {
		if (!conPrice) {
			toast.warn('Please enter a city');
			return;
		}
		setConpriceLoading(true);
		try {
			const {
				data: { data },
			} = await base.get(`deal_analyzer/city_county_tax?location=${conPrice?.split(',')[0]}`);
			if (data?.length < 1) {
				toast.error('No results found');
			}
			setConPriceOptions(data);
		} catch (error) {
			toast.error('Could not get price');
		} finally {
			setConpriceLoading(false);
		}
	};

	const onSaveZillowItem = (listingDetailsOriginal = listingDetails) => {
		const lotSize = listingDetailsOriginal.lot_size ? +listingDetailsOriginal.lot_size.split(' ')[0].replace(/,/g, '') : 0;
		const listing = structuredClone(formState === 2 ? newComparableClosedListing : newActiveListing);
		listing.sales_price = commaReplace(listingDetailsOriginal.sales_price);
		listing.gross_living_area = commaReplace(listingDetailsOriginal.gross_living_area);
		listing.lot_size = lotSize;
		listing.garage = listingDetailsOriginal.garage;
		listing.num_bedrooms = listingDetailsOriginal.num_bedrooms;
		listing.num_bathrooms = listingDetailsOriginal.num_bathrooms;
		listing.description = listingDetailsOriginal.description;
		listing.address = listingDetailsOriginal.address;
		listing.price_per_gross_living_area = Math.round(listingDetailsOriginal.price_per_gross_living_area);
		//calculate the difference here
		listing.lot_size_difference_amount = getLotSizeDifference(
			listingDetailsOriginal.sales_price,
			reportDetails.customer_lot_size,
			lotSize,
			listingDetailsOriginal.gross_living_area
		);
		listing.garage_difference_amount = getGarageDifference(
			reportDetails.customer_garage,
			listingDetailsOriginal.garage || 0,
			listingDetailsOriginal.price_per_gross_living_area
		);
		listing.livable_square_footage_difference_amount = getInteriorAreaDifference(
			listingDetailsOriginal.sales_price,
			reportDetails.customer_livable_square_footage,
			listingDetailsOriginal.gross_living_area,
			listingDetailsOriginal.price_per_gross_living_area
		);

		listing['images'] = selectedImages;

		//if closed listing
		if (formState === 2) {
			listing.sale_date = listingDetailsOriginal.sale_date;
		} else {
			listing.days_on_market = listingDetailsOriginal?.days_on_market || 0;
		}

		setReportDetails((prev) => {
			const newListing = prev[formState === 2 ? 'comparable_closed_listings' : 'comparable_active_listings'];
			const index = (formState === 2 ? closedState : activeState) - 1;
			const id = newListing[index]?.id;
			newListing[index] = listing;
			if (id) {
				newListing[index].id = id;
			}
			return {
				...prev,
				[formState === 2 ? 'comparable_closed_listings' : 'comparable_active_listings']: newListing,
			};
		});
		setSelectedImages([]);
		setImageModalOpen(false);
		setZillowModalOpen(false);
		setListingDetails(null);
		setZillowUrl('');
		setHasChanged(true);
		//set images to empty after setting
	};
	const onZillowModalClose = (all) => {
		setSelectedImages([]);
		setImageModalOpen(false);
		if (all) {
			setZillowModalOpen(false);
			setZillowUrl('');
		}
	};

	const setImages = async (e) => {
		const { files, name } = e.target;
		let formData = new FormData();
		const type = name.split('-')[0];
		const index = name.split('-')[1];
		if (files.length + reportDetails[type][index]?.images?.length > 3) {
			toast.warn('Max of 3 images allowed');
			return;
		}

		setIsLoading(true);
		for (let i = 0; i < files.length; i++) {
			formData.append('image_file', files[i]);
		}
		try {
			const { data } = await file.post(`/leads/leads_images/`, formData);
			//set images to state
			setReportDetails((prev) => {
				const newListing = prev[type];
				const images = newListing[index]?.images || [];
				newListing[index].images = [...images, ...data.images];
				return {
					...prev,
					[type]: newListing,
				};
			});
		} catch (error) {
			toast.error('Error uploading images');
		} finally {
			setIsLoading(false);
		}
	};

	const onDeleteImage = (type, index, url) => {
		setReportDetails((prev) => {
			const newListing = prev[type];
			const images = newListing[index]?.images || [];
			newListing[index].images = images.filter((image) => image !== url);
			return {
				...prev,
				[type]: newListing,
			};
		});
	};

	return (
		<>
			{isLoading && <Spinner />}
			{/* get listings from db modal */}
			<Modal style={{ width: '70vw', maxWidth: '1500px' }} open={detailsModal} onClose={() => setDetailsModal(false)}>
				<div className='bpomodal'>
					<div className='bpomodal-header'>
						<p>{reportDetails.clients_physical_address}</p>
						<CloseIcon width='18' height='18' style={{ cursor: 'pointer' }} onClick={() => setDetailsModal(false)} />
					</div>
					<div className='bpomodal-body' sx={{ padding: '20px' }}>
						<Grid container spacing={2} marginY='2rem'>
							<Grid item xl={9}>
								<PlacesSearch
									mui={true}
									value={propertySearch}
									onChange={setPropertySearch}
									placeholder='Enter address to search for comparables'
									sx={{ width: '100%' }}
									inputProps={{ style: { paddingTop: '7.5px', paddingBottom: '7.5px' } }}
								/>
							</Grid>
							<Grid item xl={3}>
								<Button
									variant='contained'
									color='secondary'
									sx={{ textTransform: 'capitalize', marginLeft: 'auto', display: 'block' }}
									onClick={() => getClosedComps()}
								>
									Get Comparables
								</Button>
							</Grid>
						</Grid>
						<Button variant='outlined' color='secondary' sx={{ display: 'block', marginY: '1rem', marginLeft: 'auto' }} onClick={onAddToClosed}>
							Add Selected Comps
						</Button>
						<div className='bpomodal-title' style={{ marginBottom: '1rem' }}>
							<Grid container spacing={2}>
								<Grid item xl={2}></Grid>
								<Grid item xl={7}>
									<Typography fontWeight={500}>Address</Typography>
								</Grid>
								<Grid item xl={3}>
									<Typography fontWeight={500}>Close Date</Typography>
								</Grid>
							</Grid>
						</div>

						<div className='bpomodal-title-expand'>
							{closedToChooseFrom.map((listing) => (
								<Accordion key={listing.id} disableGutters>
									<AccordionSummary sx={{ border: '1px solid var(--primary-color)', borderRadius: '3px' }} expandIcon={<ExpandMoreIcon />}>
										<Grid container spacing={2}>
											<Grid item xl={2}>
												<Checkbox
													size='small'
													color='secondary'
													sx={{ padding: '0' }}
													checked={selectedClosedToAdd.includes(listing.id)}
													onClick={(e) => {
														e.stopPropagation();
														handleSelectedClosedToAdd(listing.id);
													}}
												/>
											</Grid>
											<Grid item xl={7}>
												<span>
													{listing?.site_address}, {listing?.city}, {listing?.state_or_province}, USA
												</span>
											</Grid>
											<Grid item xl={3}>
												<span>{new Date(listing?.close_date).toDateString()}</span>
											</Grid>
										</Grid>
									</AccordionSummary>
									<AccordionDetails>
										<Box>
											<table style={{ backgroundColor: '#3bbaf34d' }}>
												<thead>
													<tr>
														<th style={{ width: 'unset' }}>Price</th>
														<th style={{ width: 'unset' }}>SQFT</th>
														<th style={{ width: 'unset' }}>$ Per SQFT</th>
														<th style={{ width: 'unset' }}>Bedrooms</th>
														<th style={{ width: 'unset' }}>Baths</th>
														<th style={{ width: 'unset' }}>Garages</th>
														<th style={{ width: 'unset' }}>Year Built</th>
														<th style={{ width: 'unset' }}>Lot</th>
													</tr>
												</thead>
												<tbody>
													<tr>
														<td style={{ width: 'unset' }}>$ {formatNumber(listing?.price, 0)}</td>
														<td style={{ width: 'unset' }}>{listing?.sqft}</td>
														<td style={{ width: 'unset' }}>{Math.round(listing.price / listing.sqft)}</td>
														<td style={{ width: 'unset' }}>{listing.bedrooms}</td>
														<td style={{ width: 'unset' }}>{listing.bathrooms}</td>
														<td style={{ width: 'unset' }}>{listing.garage}</td>
														<td style={{ width: 'unset' }}>{listing.year_built}</td>
														<td style={{ width: 'unset' }}>{listing.new_lot_size}</td>
													</tr>
												</tbody>
											</table>
										</Box>
									</AccordionDetails>
								</Accordion>
							))}
						</div>
					</div>
				</div>
			</Modal>

			{/* Add from zillow dialog */}
			<Dialog open={zillowModalOpen} maxWidth='sm' fullWidth onClose={() => onZillowModalClose(true)}>
				<DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
					<Box display='flex' justifyContent='space-between' alignItems='center' marginRight='40px' flex='1' padding={1}>
						<Typography variant='body1'>Add from Zillow Url</Typography>
					</Box>
					<IconButton onClick={() => onZillowModalClose(true)}>
						<Close fontSize='small' />
					</IconButton>
				</DialogTitle>
				<DialogContent dividers>
					<Grid container spacing={2}>
						<Grid item xs={10}>
							<TextField
								fullWidth
								placeholder='Zillow Url'
								size='small'
								color='secondary'
								value={zillowUrl}
								onChange={(e) => setZillowUrl(e.target.value)}
							/>
						</Grid>
						<Grid item xs={2}>
							<Button color='secondary' variant='contained' onClick={getZillowListing}>
								Add
							</Button>
						</Grid>
					</Grid>
				</DialogContent>
			</Dialog>
			<Dialog open={isConPriceOpen} maxWidth='sm' fullWidth onClose={() => setIsConPriceOpen(false)}>
				<DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
					<Box display='flex' justifyContent='space-between' alignItems='center' marginRight='40px' flex='1' padding={1}>
						<Typography variant='body1'>Enter a city</Typography>
					</Box>
					<IconButton onClick={() => setIsConPriceOpen(false)}>
						<Close fontSize='small' />
					</IconButton>
				</DialogTitle>
				<DialogContent dividers sx={{ minHeight: '400px' }}>
					<Grid container spacing={2} alignItems='flex-end'>
						<Grid item xs={10}>
							<PlacesSearch
								mui={true}
								fullWidth
								placeholder='City'
								size='small'
								color='secondary'
								format={true}
								value={conPrice}
								types={['country', 'locality']}
								onChange={(value) => setConPrice(value)}
							/>
						</Grid>
						<Grid item xs={2}>
							<LoadingButton color='secondary' variant='contained' onClick={getConPrice} loading={conpriceLoading}>
								Submit
							</LoadingButton>
						</Grid>
						{conPriceOptions?.length > 0 && (
							<>
								<Grid item xs={12} fontWeight={'600'}>
									Suggested Prices
								</Grid>
								{conPriceOptions?.map((opt) => (
									<>
										<Grid item xs={10}>
											$ {Number(opt?.final_new_construction, 2).toLocaleString('en-US', { minimumFractionDigits: 2 })}
										</Grid>
										<Grid item xs={2}>
											<Button
												variant='contained'
												color='secondary'
												sx={{ textTransform: 'capitalize' }}
												onClick={() => {
													setIsConPriceOpen(false);
													setHasChanged(true);
													setReportDetails({
														...reportDetails,
														amount_for_construction_area: Number(opt?.final_new_construction, 2).toLocaleString('en-US', {
															minimumFractionDigits: 2,
														}),
													});
												}}
											>
												select
											</Button>
										</Grid>
									</>
								))}
							</>
						)}
					</Grid>
				</DialogContent>
			</Dialog>

			{/* select zillow images dialog */}
			<Dialog open={imageModalOpen} maxWidth='md' fullWidth onClose={() => onZillowModalClose()}>
				<DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
					<Box display='flex' justifyContent='space-between' alignItems='center' marginRight='40px' flex='1' padding={1}>
						<Typography variant='body1'>Select Images (max 3) for this listing</Typography>
					</Box>
					<IconButton onClick={() => onZillowModalClose()}>
						<Close fontSize='small' />
					</IconButton>
				</DialogTitle>
				<DialogContent dividers sx={{ maxHeight: '500px', overflowY: 'auto' }}>
					{listingDetails?.images.length > 0 && (
						<ImageList sx={{ mx: 'auto', overflowY: 'unset' }} cols={3} rowHeight={200}>
							{listingDetails?.images.map((item) => (
								<ImageListItem key={item} sx={{ position: 'relative' }} onClick={() => toggleImage(item)}>
									<img
										src={`${item}`}
										alt={'Property'}
										style={{
											cursor: 'pointer',
											border: '2px solid white',
											borderRadius: '5px',
											aspectRatio: '4/3',
											opacity: selectedImages.includes(item) ? 0.5 : 1,
										}}
									/>
									{selectedImages.includes(item) && <Check color='secondary' className={classes.tick} fontSize='large' />}
								</ImageListItem>
							))}
						</ImageList>
					)}
				</DialogContent>
				<DialogActions>
					<Button color='secondary' variant='contained' onClick={() => onSaveZillowItem()}>
						Save
					</Button>
				</DialogActions>
			</Dialog>

			<Box className={classes.root}>
				<Box display='flex' justifyContent='space-between' alignItems='center'>
					<Box sx={{ width: '50%' }}>
						<LinearProgress color='secondary' variant='determinate' value={(formState / 8) * 100} />
					</Box>
					<Typography color='secondary' fontWeight='500'>
						{formState} of 8
					</Typography>
				</Box>
				<Typography variant='h5' fontWeight='700' my={2}>
					Broker Price Opinion
				</Typography>
				{reportDetails.date_created && (
					<Typography variant='body1' fontWeight='500' mb={2}>
						{new Date(reportDetails?.date_created).toLocaleDateString('en-US')}
					</Typography>
				)}
				<Box display='flex' justifyContent='space-between'>
					<Box display='flex' gap={'1rem'}>
						<Box sx={{ display: 'flex', gap: '.2rem' }}>
							<Tooltip title='Property Lot Size' arrow>
								<BorderAll sx={{ fontSize: '1.4rem' }} color='secondary' />
							</Tooltip>
							<Typography variant='body2' color={'textPrimary'}>
								{formatNumber(reportDetails.customer_lot_size || 0, 2) || 'N/A'}
							</Typography>
						</Box>
						<Box sx={{ display: 'flex', gap: '.2rem' }}>
							<Tooltip title='Number of Garages' arrow>
								<Garage sx={{ fontSize: '1.4rem' }} color='secondary' />
							</Tooltip>
							<Typography variant='body2' color={'textPrimary'}>
								{reportDetails.customer_garage || 'N/A'}
							</Typography>
						</Box>
						<Box sx={{ display: 'flex', gap: '0rem' }}>
							<Tooltip title='Livable Square Footage' arrow>
								<SquareFoot sx={{ fontSize: '1.4rem' }} color='secondary' />
							</Tooltip>
							<Typography variant='body2' color={'textPrimary'}>
								{formatNumber(`${reportDetails.customer_livable_square_footage || ''}`?.replace(/,/g, '')) || 'N/A'} Sqft
							</Typography>
						</Box>
					</Box>
				</Box>

				<Divider sx={{ backgroundColor: '#1378a536', borderBottomWidth: 1 }} light />
				<Grid container spacing={2} pt={1}>
					<Grid item xl={4}>
						<Box borderLeft={`1px solid ${grey['200']}`} paddingLeft='10px'>
							{steps.map((step, index) => (
								<Fragment key={step.name}>
									<Button
										variant='text'
										className={classes.stepButton}
										data-selected={index + 1 === formState}
										onClick={() => {
											setFormState(index + 1);
											if (index + 1 === 2) setClosedState(1);
											if (index + 1 === 3) setActiveState(1);
										}}
									>
										{step.name}
									</Button>
									{formState === index + 1 && ['Comparable Active Listings', 'Comparable Closed Sales'].includes(step.name) && (
										<>
											{reportDetails[step.path].map((item, index) => (
												<Box display='flex' alignItems='center' gap='1rem' key={item.id || index}>
													<Button
														className={classes.stepButton1}
														data-selected={(formState === 2 ? closedState : activeState) === index + 1}
														onClick={() => {
															if (formState === 2) setClosedState(index + 1);
															if (formState === 3) setActiveState(index + 1);
														}}
													>
														Comparable {index + 1}
													</Button>
													<DeleteIcon fontSize='16px' sx={{ color: 'red', cursor: 'pointer' }} onClick={() => removeListing(index)} />
												</Box>
											))}
											<Button
												variant='outlined'
												color='secondary'
												sx={{ marginLeft: '1.5rem', marginTop: '1rem', textTransform: 'capitalize' }}
												onClick={addNewListing}
											>
												Add New
											</Button>
										</>
									)}
								</Fragment>
							))}
						</Box>
					</Grid>
					<Grid item xl={8}>
						<Paper className={classes.card} ref={card}>
							{getCard()}
						</Paper>
						<Box marginTop='1rem' display='flex'>
							{formState > 1 && (
								<Button variant='outlined' color='secondary' onClick={gotoPrev}>
									Previous
								</Button>
							)}
							<Button variant='contained' color='secondary' sx={{ marginLeft: 'auto', display: 'block' }} onClick={submitBody}>
								{formState === 8 ? 'Submit & Preview' : 'Save & Continue'}
							</Button>
						</Box>
					</Grid>
				</Grid>
			</Box>
		</>
	);
}

export default CreateReport;
