import React from 'react';
import './clientdashboard.scss';
import { useNavigate } from 'react-router-dom';
import { Avatar, Box, Button, Card, CardContent, CardHeader, Divider, Grid, Paper, Skeleton, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Check1 from '../../assets/checklist1.webp';
import Check2 from '../../assets/checklist2.webp';
import Check3 from '../../assets/checklist3.webp';
import { useEffect } from 'react';
import { useState } from 'react';
import base from '../../apis';
import { toast } from 'react-toastify';
import { sampleSize } from 'lodash';
const useStyles = makeStyles((theme) => ({
	root: {
		padding: '20px',
		backgroundColor: '#eaf8ff',
		display: 'flex',
		flexDirection: 'column',
		gap: '20px',
		minHeight: '60vh',
	},
	padder: {
		padding: '20px',
	},
	avatarHolder: {
		display: 'flex',
		gap: '20px',
		alignItems: 'center',
		cursor: 'pointer',
	},
}));

function ClientDashboard() {
	const classes = useStyles();
	const navigate = useNavigate();
	const [featuredAgents, setFeaturedAgents] = useState([]);
	const [isFeatLoading, setIsFeatLoading] = useState(false);
	const [outStandingReport, setOutStandingReport] = useState(null);

	useEffect(() => {
		const getOutstandingReports = async () => {
			try {
				const { data } = await base.get('leads/check_pending_report/');
				if (data?.length) {
					setOutStandingReport(data[0]);
				}
			} catch (err) {}
		};
		getOutstandingReports();
	}, []);

	useEffect(() => {
		setIsFeatLoading(true);
		base
			.get('/register/agents/')
			.then(({ data }) => {
				setFeaturedAgents(sampleSize(data?.results, 3));
			})
			.catch((err) => {
				toast.warn('Could not get featured agents');
			})
			.finally(() => {
				setIsFeatLoading(false);
			});
	}, []);

	const buyerSeller = () => (
		<>
			{!isFeatLoading ? (
				<>
					<Typography variant='h6' color='secondary'>
						Buyer/Seller Agents
					</Typography>
					<Typography variant='body2' paragraph>
						We've found 3 agents for you.
					</Typography>
					<Grid container spacing={2}>
						{featuredAgents.map((agent) => (
							<Grid key={agent.id} item lg={4} className={classes.avatarHolder} onClick={() => navigate('/agent-finder/' + agent.id)}>
								<Avatar
									sx={{
										width: 85,
										height: 85,
										'&>img': {
											objectPosition: 'top center',
										},
									}}
									src={agent?.profile_image}
								/>
								<Typography variant='subtitle1' paragraph>
									{agent?.first_name} {agent?.last_name}
								</Typography>
							</Grid>
						))}
					</Grid>
					<Button
						variant='text'
						color='secondary'
						sx={{ display: 'block', marginLeft: 'auto', textTransform: 'capitalize', width: 'fit-content' }}
						href='my-team'
					>
						View
					</Button>
				</>
			) : (
				<>
					<Skeleton variant='rectangular' sx={{ fontSize: '3rem', my: '10px' }} width='200px' height={20} />
					<Grid container spacing={2}>
						<Grid item lg={4}>
							<Skeleton variant='rectangular' height={100} />
						</Grid>
						<Grid item lg={4}>
							<Skeleton variant='rectangular' height={100} />
						</Grid>
						<Grid item lg={4}>
							<Skeleton variant='rectangular' height={100} />
						</Grid>
					</Grid>
					<Skeleton variant='rectangular' sx={{ fontSize: '3rem', my: '10px', marginLeft: 'auto' }} width='200px' height={20} />
				</>
			)}
		</>
	);

	return (
		<Box className={classes.root}>
			<Paper
				elevation={0}
				className={classes.padder}
				sx={{
					display: 'flex',
					justifyContent: {
						xs: 'center',
						sm: 'center',
						md: 'space-between',
						lg: 'space-between',
					},
					flexDirection: {
						xs: 'column',
						sm: 'column',
						md: 'row',
						lg: 'row',
					},
				}}
			>
				<Box>
					<Typography gutterBottom variant='h6' color='secondary'>
						Welcome to BPO HOMES
					</Typography>
					<Typography variant='body2' paragraph>
						We're excited to help you buy/sell your properties
					</Typography>
				</Box>
				{outStandingReport && (
					<Box sx={{ backgroundColor: '#ff00002b', borderRadius: '5px' }} padding={1}>
						<span>
							Please fill the details for <b>{outStandingReport?.clients_physical_address}</b>
						</span>
						<Button
							href={`/client-ordered-report?id=${outStandingReport?.id}`}
							variant='contained'
							color='secondary'
							sx={{ marginLeft: '10px', textTransform: 'capitalize' }}
						>
							Click to fill
						</Button>
					</Box>
				)}
			</Paper>
			<Paper elevation={1} className={classes.padder}>
				{/* <Divider light={true} /> */}
				{buyerSeller()}
			</Paper>
			<Grid container spacing={2}>
				<Grid item lg={8} md={8} sm={12} width={'100%'}>
					<iframe
						style={{
							margin: '0 auto',
							aspectRatio: '16/9',
							borderRadius: '5px',
							border: 'none',
							width: '100%',
						}}
						src={`https://www.youtube.com/embed/HIX9gI2OL8k`}
						title='Client video'
						allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
						allowFullScreen
					></iframe>
				</Grid>
				<Grid item lg={4} md={4} sm={12} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: '10px' }}>
					<Box>
						<Typography paragraph sx={{ fontSize: '14px', marginBottom: '5px', textAlign: 'justify' }}>
							Want to know your home value? Get a BPO Report from any of our local real-estate experts.
						</Typography>
						<Button variant='contained' color='secondary' sx={{ fontSize: '14px', width: '250px', textAlign: 'center' }} href='order-bpo-report'>
							Order now
						</Button>
					</Box>
					<Box>
						<Typography paragraph sx={{ fontSize: '14px', marginBottom: '5px', textAlign: 'justify' }}>
							Evaluate a deal like an investor by using our deal analyzer platform. Get a Pro-Forma Report today.
						</Typography>
						<Button variant='contained' color='secondary' sx={{ fontSize: '14px', width: '250px' }}>
							request a pro-forma report
						</Button>
					</Box>
				</Grid>
			</Grid>
			<Typography variant='h6' sx={{ textAlign: 'center' }} marginTop='50px'>
				Pro tips for to-be home buyers/sellers
			</Typography>
			<Divider light={true} sx={{ width: '25%', marginX: 'auto', backgroundColor: 'black' }} />
			<Box mt={4} mb={5}>
				<Grid container spacing={4}>
					<Grid item xs={12} sm={12} lg={4}>
						<Card variant='elevation' sx={{ cursor: 'pointer' }} onClick={() => navigate('/bpo-guide')}>
							<CardHeader style={{ backgroundImage: `url(${Check1})`, backgroundSize: 'cover', height: '15rem' }}></CardHeader>
							<CardContent style={{ padding: '2rem' }}>
								<Typography color={'secondary'} mb={2}>
									Getting Started
								</Typography>
								<Typography variant='body1' color={'textSecondary'}>
									Why Buyers Need A Broker's Price Opinion
								</Typography>
							</CardContent>
						</Card>
					</Grid>
					<Grid item xs={12} sm={12} lg={4}>
						<Card variant='elevation' sx={{ cursor: 'pointer' }} onClick={() => navigate('/buyer-score')}>
							<CardHeader style={{ backgroundImage: `url(${Check2})`, backgroundSize: 'cover', height: '15rem' }}></CardHeader>
							<CardContent style={{ padding: '2rem' }}>
								<Typography color={'secondary'} mb={2}>
									Getting Started
								</Typography>
								<Typography variant='body1' color={'textSecondary'}>
									How to Raise Your Credit Score to Get Your First Home Mortgage
								</Typography>
							</CardContent>
						</Card>
					</Grid>
					<Grid item xs={12} sm={12} lg={4}>
						<Card variant='elevation' sx={{ cursor: 'pointer' }} onClick={() => navigate('/buyer-checklist')}>
							<CardHeader style={{ backgroundImage: `url(${Check3})`, backgroundSize: 'cover', height: '15rem' }}></CardHeader>
							<CardContent style={{ padding: '2rem' }}>
								<Typography color={'secondary'} mb={2}>
									Getting Started
								</Typography>
								<Typography variant='body1' mb={3.5} color={'textSecondary'}>
									Buying A Home in 2022?
								</Typography>
							</CardContent>
						</Card>
					</Grid>
				</Grid>
			</Box>
		</Box>
	);
}

export default ClientDashboard;
