import React from "react";
import { useTheme } from "@mui/material/styles";
import { Box, Table, TableContainer, tableCellClasses } from "@mui/material";
import SubSectionContainer from "../TableComponents/SubSectionContainer";
import InnerSectionHeader from "../TableComponents/InnerSectionHeader";
import { splitArray } from "../../utils/splitArray";
import ComponentSelector from "../ComponentSelector";
import { splitToThree } from "../../utils/splitToThree";
import TotalRow from "../RowTypes/TotalRow";
import commaSeparate from "../../utils/commaSeparate";
import ProformaStartAdornRow from "../RowTypes/ProformaStartAdornRow";

const useStyles = (_theme, printVersion) => {
  return {
    root: {},
    table: {
      [`& .${tableCellClasses.root}`]: {
        border: "none",
        padding: "5px 0",
        margin: "0rem 0 !important",
      },
      width: "48%",
      overflow: printVersion ? "hidden" : "scroll",
    },
    table2: {
      [`& .${tableCellClasses.root}`]: {
        border: "none",
        padding: "5px 0",
        margin: "0rem 0 !important",
      },
      width: "48%",
      alignSelf: "start",
      overflow: printVersion ? "hidden" : "scroll",
    },
    fullTable: {
      [`& .${tableCellClasses.root}`]: {
        border: "none",
        padding: "5px 0",
        margin: "0rem 0 !important",
      },
      alignSelf: "start",
      overflow: printVersion ? "hidden" : "scroll",
    },
  };
};

function ProformaPage2({
  listingArray,
  propertyInformation,
  dealFinance,
  monthlyTaxPayment,
  propertyPermitAndFees,
  remodelCost,
  frontendClosingCost,
  backendClosingCost,
  carryingCost,
  operationalExpenses,
  printVersion,
  resultData,
  analyzerData,
  getAllLocalCalculations,
}) {
  const theme = useTheme();
  const styles = useStyles(theme, printVersion);
  const computeTotalRemodelItems = (itemArray) => {
    if (itemArray?.length) {
      return itemArray
        .map((item) => (!isNaN(item.value) ? +item.value : 0))
        .reduce((a, b) => a + b);
    }
    return 0;
  };

  const secondaryLoanCals = resultData?.secondary_finance_option_summary;
  return (
    <>
      {!!dealFinance?.secondaryRemodelLoan?.length && (
        <SubSectionContainer printVersion={printVersion} title="Deal Finance">
          <Box sx={{ mt: 2 }} />

          <>
            <InnerSectionHeader
              title={dealFinance?.secondaryHeader || "Secondary Home Loan"}
            />
            <Box sx={{ mt: 1 }} />

            <TableContainer
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <Table sx={styles.table}>
                {splitArray(dealFinance.secondaryRemodelLoan) &&
                  splitArray(dealFinance.secondaryRemodelLoan).result1.map(
                    (element) => (
                      <ComponentSelector
                        {...element}
                        printVersion={printVersion}
                      />
                    )
                  )}
              </Table>
              <Table sx={styles.table2}>
                {splitArray(dealFinance.secondaryRemodelLoan) &&
                  splitArray(dealFinance.secondaryRemodelLoan).result2.map(
                    (element) => (
                      <ComponentSelector
                        {...element}
                        printVersion={printVersion}
                      />
                    )
                  )}
              </Table>
            </TableContainer>
            <Table sx={{ ...styles.table, mt: 2 }}>
              {secondaryLoanCals?.total_monthly_payment && (
                <ProformaStartAdornRow
                  item="Total Monthly Payment"
                  symbol="$"
                  printVersion={printVersion}
                  value={secondaryLoanCals?.total_monthly_payment}
                  bold
                />
              )}
            </Table>
          </>
        </SubSectionContainer>
      )}
      <Box sx={{ mt: 2 }} />
      <SubSectionContainer
        printVersion={printVersion}
        title={remodelCost?.mainHeader || "Remodel Cost"}
      >
        <TableContainer
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <Table sx={{ ...styles.table }}>
            {splitArray(remodelCost.general).result1.map((element) => (
              <ComponentSelector {...element} printVersion={printVersion} />
            ))}
          </Table>
          <Table sx={{ ...styles.table, alignSelf: "flex-start" }}>
            {splitArray(remodelCost.general).result2.map((element) => (
              <ComponentSelector {...element} printVersion={printVersion} />
            ))}
          </Table>
        </TableContainer>
        <Box sx={{ mt: 4 }} />

        {remodelCost.aestheticItems?.length ? (
          <>
            <InnerSectionHeader title="Aesthetic Items" />
            <Box sx={{ height: "5px" }} />
            <TableContainer sx={{ display: "flex" }}>
              <Table sx={styles.table}>
                {splitToThree(remodelCost.aestheticItems).result1.map(
                  (element) => (
                    <ComponentSelector
                      {...element}
                      printVersion={printVersion}
                    />
                  )
                )}
              </Table>
              <Box sx={{ width: "3%" }} />
              <Table sx={styles.table}>
                {splitToThree(remodelCost.aestheticItems).result2.map(
                  (element) => (
                    <ComponentSelector
                      {...element}
                      printVersion={printVersion}
                    />
                  )
                )}
              </Table>
              <Box sx={{ width: "3%" }} />
              <Table sx={{ ...styles.table, alignSelf: "start" }}>
                {splitToThree(remodelCost.aestheticItems).result3.map(
                  (element) => (
                    <ComponentSelector
                      {...element}
                      printVersion={printVersion}
                    />
                  )
                )}
              </Table>
            </TableContainer>
            <TableContainer sx={{ display: "flex", paddingLeft: "6.25rem" }}>
              <Table sx={{ ...styles.table }}>
                <TotalRow
                  printVersion={printVersion}
                  item="Total:"
                  symbol="$"
                  value={computeTotalRemodelItems(remodelCost.aestheticItems)}
                />
              </Table>
            </TableContainer>
          </>
        ) : (
          ""
        )}
        <Box sx={{ mt: 4 }} />
        {remodelCost.nonAestheticItems?.length ? (
          <>
            <InnerSectionHeader title="Non Aesthetic Items" />
            <Box sx={{ height: "5px" }} />
            <TableContainer sx={{ display: "flex" }}>
              <Table sx={styles.table}>
                {splitToThree(remodelCost.nonAestheticItems).result1.map(
                  (element) => (
                    <ComponentSelector
                      {...element}
                      printVersion={printVersion}
                    />
                  )
                )}
              </Table>
              <Box sx={{ width: "3%" }} />
              <Table sx={{ ...styles.table, alignSelf: "flex-start" }}>
                {splitToThree(remodelCost.nonAestheticItems).result2.map(
                  (element) => (
                    <ComponentSelector
                      {...element}
                      printVersion={printVersion}
                    />
                  )
                )}
              </Table>
              <Box sx={{ width: "3%" }} />
              <Table sx={{ ...styles.table, alignSelf: "start" }}>
                {splitToThree(remodelCost.nonAestheticItems).result3.map(
                  (element) => (
                    <ComponentSelector
                      {...element}
                      printVersion={printVersion}
                    />
                  )
                )}
              </Table>
            </TableContainer>
            <TableContainer sx={{ display: "flex", paddingLeft: "6.25rem" }}>
              <Table sx={{ ...styles.table }}>
                <TotalRow
                  printVersion={printVersion}
                  item="Total:"
                  symbol="$"
                  value={computeTotalRemodelItems(
                    remodelCost.nonAestheticItems
                  )}
                />
              </Table>
            </TableContainer>
          </>
        ) : (
          ""
        )}

        <Box sx={{ mt: 4 }} />
        <TableContainer sx={{ display: "flex" }}>
          <Table sx={{ ...styles.table, width: "100%" }}>
            <TotalRow
              printVersion={printVersion}
              item="Estimated Total Remodel Cost:"
              symbol="$"
              value={commaSeparate(
                resultData?.remodel_cost?.estimated_total_remodel_cost
                  ? getAllLocalCalculations?.grandTotalRemodelCost ||
                      getAllLocalCalculations?.remodelCostUsed ||
                      analyzerData?.remodelCosts?.remodelCost
                  : resultData?.remodel_cost
                      ?.est_remodel_cost_down__to_studs_plus_additional_sqft
              )}
            />
          </Table>
        </TableContainer>
      </SubSectionContainer>
      <Box sx={{ mt: 2 }} />
    </>
  );
}

export default ProformaPage2;
