import React from 'react'
import { TableRow,TableHead,TableCell } from '@mui/material'

function EvenTableHead() {
  return (
    <TableHead>
        <TableRow>
        <TableCell sx={{width:"1rem !important"}}></TableCell>
        <TableCell sx={{width:"50% !important"}}></TableCell>
        </TableRow>
    </TableHead>
  )
}

export default EvenTableHead