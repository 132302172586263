import { Avatar, createTheme, Divider, Grid, IconButton, Stack, ThemeProvider, Typography, Link as NLink } from '@mui/material';
import { makeStyles, styled } from '@mui/styles';
import React, { Fragment } from 'react';
import { Link as RLink } from 'react-router-dom';
import RequestAQuote from '../components/RequestAQuote';
import { ReactComponent as FBIcon } from '../images/facebookfillable.svg';
import { ReactComponent as Doc } from '../images/indProfTemp4/Doc_ind4.svg';
import BG from '../images/indProfTemp4/mainBg.jpg';
import Man1 from '../images/indProfTemp4/man1.jpg';
import { ReactComponent as IGIcon } from '../images/instagramfillable.svg';
import { ReactComponent as LIIcon } from '../images/linkedInfillable.svg';
import { ReactComponent as TWIcon } from '../images/twitterfillable.svg';
import { ReactComponent as YTIcon } from '../images/youtubefillable.svg';
import { theme } from '../theme';

// !Plumbing
// https://www.figma.com/design/Q83dnbULW6YZeYlFf9qbZX/Premier-Agent?node-id=837-3054&t=LydMWu0WNk1I0iks-4

const pageTheme = createTheme({
	...theme,
	typography: {
		fontFamily: 'Inter, serif',
		button: {
			textTransform: 'capitalize',
		},
	},
	palette: {
		primary: {
			main: '#000B41',
		},
		secondary: {
			main: '#000',
		},
		text: {
			main: '#000',
		},
		paper: {
			main: '#fff',
		},
	},
	primaryColor: '#000B41',
	secondaryColor: '#000',
});

const mainPaddingX = '10rem';

const useStyles = makeStyles((theme) => ({
	root: {
		fontFamily: 'Inter, serif',
		scrollMarginTop: '100px',
		minHeight: '100vh',
	},
	leftLinks: {
		display: 'flex',
		alignItems: 'center',
		color: '#FFFFFF',
		fontFamily: 'Inter, serif',
		cursor: 'pointer',
	},
	main: {
		background: `url(${BG})`,
		// width: '100vw',
		padding: `1rem ${mainPaddingX}`,
		height: '100vh',
		backgroundSize: 'cover',
		backgroundPosition: 'center',
	},
}));

const socials = [
	{
		key: 'facebook',
		Icon: <FBIcon fill='#FFFFFF' />,
	},
	{
		key: 'twitter',
		Icon: <TWIcon fill='#FFFFFF' />,
	},
	{ key: 'instagram', Icon: <IGIcon fill='#FFFFFF' /> },
	{
		key: 'linkedin',
		Icon: <LIIcon fill='#FFFFFF' />,
	},
	{
		key: 'youtube',
		Icon: <YTIcon fill='#FFFFFF' />,
	},
];

const navItems = [
	{ label: 'Home', value: '#home' },
	{ label: 'Request A Quote', value: '#quote' },
	{ label: 'About Us', value: '#about-us' },
];
const Link = styled(RLink)(({ theme }) => ({
	cursor: 'pointer',
	color: theme.primaryColor,
}));

const IndProfTemp4 = ({ address, icon_file, company_name, id, formatted_phone_number, user, ...profDetails }) => {
	const classes = useStyles();
	return (
		<ThemeProvider theme={pageTheme}>
			<Stack className={classes.root}>
				<Stack sx={{ background: '#EDF1FC', paddingX: mainPaddingX, paddingY: '5px' }} direction='row' justifyContent={'space-between'}>
					<Stack direction='row' gap='2rem'>
						<Typography sx={{ borderRight: '1px #BABABA dashed', paddingRight: '1rem' }} color='#000B41'>
							{address}
						</Typography>
						<Typography sx={{ borderRight: '1px #BABABA dashed', paddingRight: '1rem' }} color='#000B41'>
							{user?.email}
						</Typography>
						<Typography color='#000B41'>{formatted_phone_number}</Typography>
					</Stack>
				</Stack>
				<Stack paddingX={mainPaddingX} direction='row' justifyContent={'space-between'} py='2rem'>
					<Stack direction='row' gap='1rem' alignItems={'center'}>
						<Avatar src={icon_file} />
						<Typography fontWeight={700} fontSize='30px'>
							{company_name}
						</Typography>
					</Stack>
					<Stack direction='row' gap='2rem'>
						{navItems.map((item) => (
							<NLink href={item.value} className={classes.leftLinks} underline='none' key={item.value}>
								{item.label}
							</NLink>
						))}
					</Stack>
				</Stack>
				<Stack className={classes.main} justifyContent='center'>
					<Typography sx={{ fontWeight: 700, fontSize: 50, color: 'white', width: '50%', textWrap: 'balance' }}>
						Some jobs should only ever be tackled by a professional, and plumbing is one of them.
					</Typography>
				</Stack>
				<Stack sx={{ background: '#EDF1FC', paddingX: mainPaddingX, paddingY: '5rem' }} mt='3rem' gap='1rem'>
					<Stack direction='row' alignItems='center' gap='1rem'>
						<Divider sx={{ backgroundColor: '#DF2935', width: '20px' }} />
						<Typography color='#DF2935' fontWeight='400' fontSize={20}>
							WHY US
						</Typography>
					</Stack>
					<Typography fontSize={55} fontWeight={700} color='#001062' textAlign={'center'}>
						Trusted Service With Affordable Prices
					</Typography>
					<Grid container spacing={2}>
						<Grid item xs={6}>
							<img
								src={Man1}
								alt='plumber'
								style={{ width: '100%', aspectRatio: '0.9', objectFit: 'cover', objectPosition: 'bottom center', borderRadius: '10px' }}
							/>
						</Grid>
						<Grid item xs={6}>
							<Grid container spacing={2} sx={{ height: '100%' }}>
								{['Experienced', 'Reliable', 'Capable', 'Flexible'].map((item) => (
									<Grid item xs={6} key={item}>
										<Stack
											sx={{ borderRadius: '10px', height: '100%', width: '100%', background: '#FBFFFE', padding: '1rem' }}
											alignItems='center'
											justifyContent={'center'}
											gap='1rem'
										>
											<Doc />
											<Typography color='#333333' fontSize={32} fontWeight={700}>
												{item}
											</Typography>
										</Stack>
									</Grid>
								))}
							</Grid>
						</Grid>
					</Grid>
				</Stack>
				<Stack sx={{ width: '50%', margin: 'auto', minWidth: '300px' }} gap='2rem' mt='2rem' pt='5rem' id='quote'>
					<Typography fontSize='30px' textAlign={'center'}>
						Want A Quote?
					</Typography>
					<RequestAQuote withMargin={false} id={id} />
				</Stack>
				<Stack sx={{ background: '#001062', paddingX: mainPaddingX }}>
					<Grid container spacing={2} my='5rem' className={classes.color}>
						<Grid item xs={4}>
							<Stack gap='1rem'>
								<Avatar src={icon_file} />

								<Typography color='white !important' fontWeight={700} fontSize='30px'>
									{company_name}
								</Typography>
								<Stack direction='row' gap='1rem'>
									{socials.map((social) => (
										<Fragment key={social.key}>
											{profDetails?.[social.key] && (
												<IconButton href={profDetails?.[social.key]} target='_blank'>
													{social.Icon}
												</IconButton>
											)}
										</Fragment>
									))}
								</Stack>
							</Stack>
						</Grid>
						<Grid item xs={4}>
							<Stack gap='1rem'>
								<Typography color='white !important' fontWeight={600} fontSize='20px'>
									{address}
								</Typography>
								<Typography color='white !important' sx={{ cursor: 'pointer' }} component='a' href={`tel: ${formatted_phone_number}`}>
									Phone: {formatted_phone_number}
								</Typography>
								<Typography component='a' color='white !important' href={`mailto: ${user?.email}`} sx={{ cursor: 'pointer' }}>
									Email: {user?.email}
								</Typography>
							</Stack>
						</Grid>
						<Grid item xs={4}>
							<Stack gap='1rem'>
								<Typography color='white !important' fontWeight={600} fontSize='20px'>
									Navigation
								</Typography>
								{navItems.map((item) => (
									<Link to={item.value} underline='none' key={item.value} style={{ fontWeight: 400, color: 'white' }}>
										{item.label}
									</Link>
								))}
							</Stack>
						</Grid>
						<Grid item xs={12}>
							<Divider sx={{ background: 'white' }} />
						</Grid>
						<Grid item xs={12} mt='3rem'>
							<Stack direction={'row'} justifyContent={'space-between'}>
								<Typography color='white !important'>&copy; {new Date().getFullYear()} BPO Tech All rights reserved.</Typography>
								<Stack direction='row' gap='1rem'>
									<Link to='/privacy-policy' color='white !important' style={{ color: 'white' }}>
										Privacy Policy
									</Link>
									<Link to='/terms-of-service' color='white !important' style={{ color: 'white' }}>
										Terms and Conditions
									</Link>
								</Stack>
							</Stack>
						</Grid>
					</Grid>
				</Stack>
			</Stack>
		</ThemeProvider>
	);
};

export default IndProfTemp4;
