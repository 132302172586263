import { Box, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import React from 'react'
import AccumulatedLinearProgressBar from './AccumulatedLinearProgressBar'
const useStyles = (_theme)=>{
    return {
        root:{
            display:"flex",
            justifyContent:"space-between",
            alignItems:"flex-end",
            padding:"1rem"
        },
        iconContainer:{
            display:"flex",
            alignItems:"flex-end",
            width:"50%"
        }
    }
}

function QuestionnaireMainHeader({icon:Icon,analyzerType,progress,total}) {
    const theme = useTheme()
    const styles = useStyles(theme)
  return (
    <Box sx={styles.root}>
        <Box sx={styles.iconContainer}>
            {Icon && <Icon sx={{fontSize:"2.187rem", marginRight:"10px"}}/>}
            <Typography variant='analyzerType' sx={{color:'#1378A5'}}>{analyzerType}</Typography>
        </Box>
        {   +progress !== 0 &&
            <Box sx={{width:"40%"}}>
                <AccumulatedLinearProgressBar max={total} active={progress}/>
            </Box>
        }

    </Box>
  )
}

export default QuestionnaireMainHeader

