import useProformaData from "./useProformaData";
import { propertyType } from "../../utils/numericToStringConvert";

function useProformaData2() {
  const {
    loading,
    dealFinance,
    propertyPermitAndFees,
    monthlyTaxPayment,
    frontendClosingCost,
    backendClosingCost,
    carryingCost,
    operationalExpenses,
    otherExpenses,
    estResaleValue,
    rentalIncome,
    capitalizationRate,
    netProfit,
    investorsProfit,
    resultData,
    fetchResult,
    analyzerData,
    user,
    summary,
    listingArray,
    getAllLocalCalculations,
  } = useProformaData();

  const otherUnitsPrice = analyzerData?.remodelCosts?.additional_units_price;
  const otherUnits = analyzerData?.propertyInformation?.additional_units;
  const adu = analyzerData.propertyInformation?.showADU
    ? {
      item: "ADU",
      value: analyzerData.propertyInformation?.adu || "N/A",
      render: "sqft",
    }
    : {};
  const aduAmount = analyzerData.propertyInformation?.showADU
    ? {
      item: "ADU",
      value: analyzerData.remodelCosts?.adu_price || "N/A",
      render: "dollar",
    }
    : {};

  // basement
  const basement = analyzerData.propertyInformation?.showBasement
    ? {
      item: "Basement",
      value: analyzerData.propertyInformation?.basement_psqf || "N/A",
      render: "sqft",
    }
    : {};

  const basement_price = analyzerData.propertyInformation?.showBasement
    ? {
      item: "Basement",
      value: analyzerData.remodelCosts?.basement_price || "N/A",
      render: "sqft",
    }
    : {};

  const otherPropertyType = analyzerData?.propertyInformation
    ?.other_property_type
    ? {
      item: "Specified Type",
      value: analyzerData?.propertyInformation?.other_property_type,
      render: "simple",
    }
    : {};

  const dealType = "Straight Remodel & Additional Sqft";

  const propertyInformation = [
    {
      item: "Property Type",
      value:
        propertyType[+analyzerData.propertyInformation?.property_type] || "N/A",
      render: "simple",
    },
    otherPropertyType,
    {
      item: "Bedroom",
      value: analyzerData.propertyInformation?.bedrooms,
      render: "simple",
    },
    {
      item: "Bathroom",
      value: analyzerData.propertyInformation?.bathrooms,
      render: "simple",
    },
    {
      item: "Pre-existing Livable Square Footage",
      value: analyzerData.propertyInformation?.pre_existing_livable_sqft,
      render: "sqft",
    },
    {
      item: "Additional Square Footage",
      value: analyzerData.propertyInformation?.additional_sqrft,
      render: "sqft",
    },
    ...(otherUnits?.length > 0
      ? otherUnits?.map((item, index) => ({
        item: `Unit ${index + 2}`,
        value: item.value || "N/A",
        render: "sqft",
      }))
      : []),
    {
      item: analyzerData?.propertyInformation?.carpot_or_garage || "Garage",
      value: analyzerData.propertyInformation?.garage,
      render: "simple",
    },
    {
      item: "Lot",
      value: analyzerData.propertyInformation?.lot_size,
      render: "simple",
    },
    {
      item: "Year Built",
      value: new Date(
        analyzerData.propertyInformation?.year_of_construction
      ).getFullYear(),
      render: "simple",
    },
    adu,
    basement,
  ];
  const remodelCost = {
    general: [
      {
        item: "Estimated Remodel Cost per Sqft",
        value: analyzerData?.remodelCosts?.est_remodel_cost_psqft,
        render: "persqft",
      },
      {
        item: "Estimated New Construction Cost per Sqft",
        value: analyzerData?.remodelCosts?.est_new_construction_cost_psqft,
        render: "persqft",
      },
      {
        item: "Pre-existing Livable Square Footage",
        value: analyzerData?.propertyInformation?.pre_existing_livable_sqft,
        render: "sqft",
      },
      {
        item: "Additional Square Footage",
        value: analyzerData?.propertyInformation?.additional_sqrft,
        render: "sqft",
      },
      {
        item: "Estimated Remodel Cost",
        value: resultData?.remodel_cost?.estimated_remodel_cost,
        render: "dollar",
      },
      {
        item: "Estimated New Construction Cost",
        value: resultData?.remodel_cost?.estimated_new_construction_cost,
        render: "dollar",
      },
      ...(otherUnitsPrice?.length > 0
        ? otherUnitsPrice?.map((item, index) => ({
          item: `Unit ${index + 2}`,
          value: item.value || "N/A",
          render: "dollar",
        }))
        : []),
      aduAmount,
      basement_price,
    ],
    aestheticItems: analyzerData?.remodelCosts?.aesthetic_items
      ? analyzerData?.remodelCosts?.aesthetic_items.map((element, index) => {
        return {
          ...element,
          render: "dollar",
          item: `${index + 1}. ${element.item} `,
        };
      })
      : [],
    nonAestheticItems: analyzerData?.remodelCosts?.non_aesthetic_items
      ? analyzerData?.remodelCosts?.non_aesthetic_items.map(
        (element, index) => {
          return {
            ...element,
            render: "dollar",
            item: `${index + 1}. ${element.item} `,
          };
        }
      )
      : [],
  };

  return {
    loading,
    listingArray,
    propertyInformation,
    dealFinance,
    propertyPermitAndFees,
    monthlyTaxPayment,
    remodelCost,
    frontendClosingCost,
    backendClosingCost,
    carryingCost,
    operationalExpenses,
    otherExpenses,
    estResaleValue,
    rentalIncome,
    capitalizationRate,
    netProfit,
    investorsProfit,
    resultData,
    fetchResult,
    analyzerData,
    user,
    dealType,
    summary,
    getAllLocalCalculations,
  };
}

export default useProformaData2;
