import { loanSubTypes, loanTypes } from "../../../utils/numericToStringConvert";

export const getPrimaryConventional = ({ analyzerData, resultData }) => {
  const { pmi_value, primary_include_pmi } = analyzerData?.financeOptions || {};

  //============ dynamic pmi value =================//
  const pmiValue = primary_include_pmi
    ? { item: "PMI Percentage", value: pmi_value, render: "percent" }
    : {};
  return [
    {
      item: "Loan Type",
      value:
        loanTypes?.[+analyzerData?.financeOptions?.primary_loan_type || 0] ||
        "N/A",
      render: "simple",
    },
    {
      item: "The Loan is",
      value: !+analyzerData?.financeOptions?.primary_loan_type
        ? loanSubTypes[0]
        : loanSubTypes[
            +analyzerData?.financeOptions?.primary_sub_loan_type || 0
          ],
      render: "simple",
    },
    {
      item: "Purchase Price",
      value: analyzerData?.financeOptions?.primary_purchase_price,
      render: "dollar",
    },
    {
      item: "Lender Points",
      value: analyzerData?.financeOptions?.primary_lender_points,
      render: "points",
    },
    pmiValue,
    {
      item: "Wholesale Fee",
      value: analyzerData?.financeOptions?.primary_wholesale_fee,
      render: "dollar",
    },
    {
      item: "Down Payment",
      value: analyzerData?.financeOptions?.primary_down_payment,
      render: "percent_dollar",
      rate: analyzerData?.financeOptions?.primary_dollar_or_percent || 0,
      referenceValue: analyzerData?.financeOptions?.primary_purchase_price,
    },
    {
      //from calculations
      item: "Loan Amount",
      value:
        resultData?.primary_finance_option_summary
          ?.conventional_mortgage_loan ||
        resultData?.primary_finance_option_summary?.interest_only_mortgage_loan,
      render: "dollar",
    },
    {
      item: "Interest Rate",
      value: analyzerData?.financeOptions?.primary_interest_rate,
      render: "percent",
    },
    {
      item: "Loan Term",
      value: analyzerData?.financeOptions?.primary_term_of_loan,
      render: "years",
    },
  ];
};
export const getSecondaryConventional = ({ analyzerData, resultData }) => {
  return [
    {
      item: "Loan Type",
      value:
        loanTypes?.[+analyzerData?.financeOptions?.secondary_loan_type] ||
        loanTypes?.[0],
      render: "simple",
    },
    {
      item: "The Loan is",
      value: !+analyzerData?.financeOptions?.secondary_loan_type
        ? loanSubTypes[0]
        : loanSubTypes[
            +analyzerData?.financeOptions?.secondary_sub_loan_type || 0
          ],
      render: "simple",
    },
    {
      item: "Remodel Cost",
      value:
        analyzerData?.financeOptions?.secondary_total_remodel_cost ||
        resultData?.remodel_cost?.estimated_total_remodel_cost,
      render: "dollar",
    },
    {
      item: "Lender Points",
      value: analyzerData?.financeOptions?.secondary_lender_points,
      render: "points",
    },
    {
      item: "Down Payment",
      value: analyzerData?.financeOptions?.secondary_down_payment,
      render: "percent_dollar",
      rate: analyzerData?.financeOptions?.secondary_dollar_or_percent || 0,
      referenceValue: resultData?.remodel_cost?.estimated_total_remodel_cost,
    },
    {
      item: "Loan Amount", //take from calculations
      value:
        resultData?.secondary_finance_option_summary
          ?.conventional_mortgage_loan ||
        resultData?.secondary_finance_option_summary
          ?.interest_only_mortgage_loan,
      render: "dollar",
    },
    {
      item: "Interest Rate",
      value:
        analyzerData?.financeOptions?.secondary_interest_rate ||
        analyzerData?.financeOptions?.secondary_annual_interest_rate,
      render: "percent",
    },
    {
      item: "Loan Term",
      value: analyzerData?.financeOptions?.secondary_term_of_loan,
      render: "years",
    },
  ];
};
export const getPrimaryConventionalBuyAndRent = (analyzerData, resultData) => {
  return [
    {
      item: "Loan Type",
      value:
        loanTypes?.[+analyzerData?.financeOptions?.primary_loan_type] || "N/A",
      render: "simple",
    },
    {
      item: "Rental Cost Amount",
      value: analyzerData?.financeOptions?.primary_purchase_price,
      render: "dollar",
    },
    {
      item: "Lender Points",
      value: analyzerData?.financeOptions?.primary_lender_points,
      render: "points",
    },
    {
      item: "Wholesale Fee",
      value: analyzerData?.financeOptions?.primary_wholesale_fee,
      render: "dollar",
    },
    {
      item: "Down Payment",
      value: analyzerData?.financeOptions?.primary_down_payment,
      render: "percent_dollar",
      rate:
        analyzerData?.financeOptions?.primary_down_payment_dollar_or_percent ||
        0,
      referenceValue: analyzerData?.financeOptions?.primary_purchase_price,
    },
    {
      //from calculations
      item: "Loan Amount",
      value:
        resultData?.primary_finance_option_summary
          ?.conventional_mortgage_loan ||
        resultData?.primary_finance_option_summary?.interest_only_mortgage_loan,
      render: "dollar",
    },
    {
      item: "Interest Rate",
      value: analyzerData?.financeOptions?.primary_interest_rate,
      render: "percent",
    },
    {
      item: "Loan Term",
      value: analyzerData?.financeOptions?.primary_term_of_loan,
      render: "months",
    },
  ];
};
