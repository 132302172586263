import { LoadingButton } from '@mui/lab';
import { CircularProgress, Stack, TextField, Typography } from '@mui/material';
import Save01 from '@untitled-ui/icons-react/build/esm/Save01';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import base from '../apis';

const IndProfProfile = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [profileData, setProfileData] = useState(null);
	useEffect(() => {
		(async () => {
			try {
				setIsLoading(true);
				const { data } = await base.get('register/ind-prof/user/profile/');
				setProfileData(data);
			} catch (error) {
				toast.error('Could not get details');
			} finally {
				setIsLoading(false);
			}
		})();
	}, []);

	const [isSaveLoading, setIsSaveLoading] = useState(false);
	const dispatch = useDispatch();

	const handleSave = async () => {
		setIsSaveLoading(true);
		const { first_name, last_name } = profileData;
		if (!first_name || !last_name) {
			toast.warn('Enter a first and last name');
			return;
		}
		try {
			const { data } = await base.patch('register/ind-prof/user/profile/', {
				first_name,
				last_name,
			});
			dispatch({ type: 'UPDATE_NAME', payload: { first_name: data.first_name, last_name: data.last_name } });
			toast.success('Profile updated successfully');
		} catch (error) {
			toast.error('Could not update details');
		} finally {
			setIsSaveLoading(false);
		}
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		setProfileData((v) => ({
			...v,
			[name]: value,
		}));
	};

	const getSaveButton = () => (
		<LoadingButton
			loading={isSaveLoading}
			variant='contained'
			color='secondary'
			onClick={handleSave}
			startIcon={<Save01 style={{ stroke: 'white' }} className='iconer' />}
		>
			Save
		</LoadingButton>
	);

	if (isLoading) {
		return (
			<Stack sx={{ minHeight: '100vh' }} alignItems='center' justifyContent='center'>
				<CircularProgress color='secondary' />
			</Stack>
		);
	}

	return (
		<Stack gap='1rem' p='1rem' sx={{ minHeight: '100vh' }}>
			<Typography fontWeight={700} fontSize={24} color='#344054' sx={{ borderBottom: '1px solid #EAECF0', paddingBottom: '1rem' }} fontFamily='Lato'>
				{' '}
				Profile
			</Typography>
			<Stack
				sx={{
					borderBottom: '1px solid #EAECF0',
					paddingY: '1.5rem',
					position: 'sticky',
					top: '74px',
					background: 'white',
					zIndex: 100,
				}}
				direction='row'
				justifyContent='space-between'
			>
				<Stack>
					<Typography color='#101828' fontSize={18} fontWeight={600}>
						Personal Info
					</Typography>
					<Typography color='#475467' fontSize={14} fontWeight={400}>
						Update your personal details here
					</Typography>
				</Stack>
				<Stack direction='row' alignItems='center'>
					{getSaveButton()}
				</Stack>
			</Stack>
			<Stack gap='1rem'>
				<Stack sx={{ borderBottom: '1px solid #EAECF0', paddingY: '1rem' }}>
					<Stack width='70%' direction='row' gap='1rem' alignItems='center'>
						<Typography color='#344054' fontSize='14px' fontWeight={600} sx={{ width: '100px' }}>
							{' '}
							Name
						</Typography>
						<TextField
							placeholder='First name'
							size='small'
							fullWidth
							color='secondary'
							value={profileData?.first_name}
							name='first_name'
							sx={{ flex: 1 }}
							onChange={handleChange}
						/>
						<TextField
							placeholder='Last name'
							size='small'
							fullWidth
							sx={{ flex: 1 }}
							color='secondary'
							value={profileData?.last_name}
							name='last_name'
							onChange={handleChange}
						/>
					</Stack>
				</Stack>
				<Stack sx={{ borderBottom: '1px solid #EAECF0', paddingY: '1rem' }}>
					<Stack width='70%' direction='row' gap='1rem' alignItems='center'>
						<Typography color='#344054' fontSize='14px' fontWeight={600} sx={{ width: '100px' }}>
							{' '}
							Email
						</Typography>
						<TextField placeholder='Email' size='small' fullWidth color='secondary' disabled value={profileData?.email} sx={{ flex: 1 }} />
					</Stack>
				</Stack>
			</Stack>
			<Stack sx={{ borderBottom: '1px solid #EAECF0', paddingY: '1rem' }} direction='row' justifyContent='flex-end'>
				{getSaveButton()}
			</Stack>
		</Stack>
	);
};

export default IndProfProfile;
