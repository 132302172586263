import { AccessTime, Close, PlaceOutlined, Tune } from '@mui/icons-material';
import CheckIcon from '@mui/icons-material/Check';
import {
	Avatar,
	Box,
	Button,
	Card,
	CardActions,
	CardContent,
	Chip,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	Grid,
	IconButton,
	InputAdornment,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Pagination,
	Popover,
	Rating,
	TextField,
	Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';
import axios from 'axios';
import debounce from 'lodash/debounce';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import SEO from '../../components/SEO';
import Spinner from '../../components/Spinner/Spinner';
import { URL } from '../../constants';
import { ReactComponent as AreaMap } from '../../images/area-map.svg';
import BG from '../../images/bpoagentsbg.webp';
import { ReactComponent as LanguageIcon } from '../../images/language.svg';
import { ReactComponent as LicenseIcon } from '../../images/license.svg';
import { ReactComponent as PriceIcon } from '../../images/priceTag.svg';
import { ReactComponent as SearchIcon } from '../../images/searchbpo.svg';

const useStyles = makeStyles((theme) => ({
	root: {},
	top: {
		height: '70vh',
		[theme.breakpoints.down('md')]: {
			justifyContent: 'flex-end',
		},
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		flexDirection: 'column',
		background: `url(${BG})`,
		backgroundSize: 'cover',
		backgroundPosition: 'center center',
	},

	leftBox: {
		'& .MuiOutlinedInput-root': {
			backgroundColor: 'white',
			borderTopRightRadius: `0`,
			borderBottomRightRadius: `0`,
			padding: '0',
			paddingLeft: '10px',
			'& fieldset': {
				borderTopRightRadius: `0`,
				borderBottomRightRadius: `0`,
				color: 'black',
			},
		},
	},
	middleBox: {
		'& .MuiOutlinedInput-root': {
			backgroundColor: 'white',
			borderRadius: `0`,
			padding: '0',
			paddingLeft: '10px',

			'& fieldset': {
				borderRadius: `0`,
				color: 'black',
			},
		},
	},
	rightBox: {
		'& .MuiOutlinedInput-root': {
			backgroundColor: 'white',
			borderTopLeftRadius: `0`,
			borderBottomLeftRadius: `0`,
			padding: '0',
			paddingLeft: '10px',

			'& fieldset': {
				borderTopLeftRadius: `0`,
				borderBottomLeftRadius: `0`,
				color: 'black',
			},
		},
	},
	blue: {
		padding: '1rem',
		position: 'relative',
		width: '100%',
		textAlign: 'center',

		'&::before': {
			content: '""',
			position: 'absolute',
			top: 0,
			left: 0,
			width: '100%',
			height: '100%',
			backgroundColor: '#005EA9',
			mixBlendMode: 'multiply',
			zIndex: 0,
			opacity: 0.65,
		},
		'&>*': {
			zIndex: 1,
			position: 'inherit',
		},
	},
}));

const calculateYearsOfExperience = (year_work_commenced, val) => {
	if (!year_work_commenced) return '1 Year';
	const dateToUse = new Date(year_work_commenced);
	const ageDifMs = Date.now() - dateToUse;
	const ageDate = new Date(ageDifMs);
	const numOfYears = Math.abs(ageDate.getUTCFullYear() - 1970) + 1;
	return `${numOfYears} ${numOfYears > 1 ? 'Years' : 'Year'}`;
};

function BpoAgents() {
	const classes = useStyles();
	const [agents, setAgents] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [page, setPage] = useState(1);
	const [searchParams, setSearchParams] = useState({
		name: '',
		service_area: '',
		zip_code: '',
	});
	const [selectedAgent, setSelectedAgent] = useState(null);
	const [isBioShort, setIsBioShort] = useState(true);
	const [detailsModalOpen, setDetailsModalOpen] = useState(false);
	const [urlSearch] = useSearchParams();
	const address = urlSearch.get('address');

	const scrollUp = () => {
		const element = document.getElementById('agents');
		element.scrollIntoView();
	};

	const getAgents = async (page) => {
		try {
			setIsLoading(true);
			const params = {
				page: page || 1,
				page_size: 12,
			};
			if (searchParams.name) params.search = searchParams.name;
			if (searchParams.service_area) params.service_areas__location__icontains = searchParams.service_area;
			if (searchParams.zip_code) params.zip_code__iexact = searchParams.zip_code;
			if (extraFilter.price_max) params.price_max = extraFilter.price_max;
			if (extraFilter.price_min) params.price_min = extraFilter.price_min;
			if (extraFilter.experience_min) params.experience_min = extraFilter.experience_min;
			if (extraFilter.experience_max) params.experience_max = extraFilter.experience_max;

			const { data: agents } = await axios.get(URL + 'register/agents/', {
				params,
			});
			scrollUp();
			setAgents(agents.results);
			setPage(agents.count);
		} catch (error) {
			toast.error('Something went wrong');
		} finally {
			setIsLoading(false);
		}
	};
	useEffect(() => {
		getAgents();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handlePageChange = async (e, page) => {
		getAgents(page);
	};

	const getSearchResults = async (obj) => {
		const params = {
			page_size: 12,
		};
		if (obj.name) params.search = obj.name;
		if (obj.service_area) params.service_areas__location__icontains = obj.service_area;
		if (obj.zip_code) params.zip_code__iexact = obj.zip_code;
		if (obj.price_max) params.price_max = obj.price_max;
		if (obj.price_min) params.price_min = obj.price_min;
		if (obj.experience_min) params.experience_min = obj.experience_min;
		if (obj.experience_max) params.experience_max = obj.experience_max;

		try {
			const { data } = await axios.get(URL + 'register/agents/', {
				params: params,
			});

			setAgents(data.results);
			setPage(data.count);
		} catch (error) {
			toast.error('Something went wrong');
		}
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const onFind = React.useCallback(
		debounce((obj) => {
			getSearchResults(obj);
		}, 500),
		[]
	);

	const handleParamChange = (e) => {
		const newChange = {
			...searchParams,
			[e.target.name]: e.target.value,
		};
		setSearchParams(newChange);
		onFind({ ...newChange, ...extraFilter });
	};

	const handleProfileView = (agent) => {
		setSelectedAgent(agent);
		setDetailsModalOpen(true);
		setIsBioShort(true);
	};

	const [anchorEl, setAnchorEl] = React.useState(null);
	const filterMoreOpen = Boolean(anchorEl);
	const handleFilterMoreClick = (e) => {
		setAnchorEl(e.currentTarget);
	};
	const id = filterMoreOpen ? 'simple-popover' : undefined;

	const handleFilterMoreClose = () => {
		setAnchorEl(null);
	};

	const [extraFilter, setExtraFilter] = useState({
		experience_min: '',
		experience_max: '',
		price_max: '',
		price_min: '',
	});

	const handleExtraFilterChange = (e) => {
		setExtraFilter({
			...extraFilter,
			[e.target.name]: e.target.value,
		});
	};

	const handleReset = () => {
		setExtraFilter({
			experience_min: '',
			experience_max: '',
			price_max: '',
			price_min: '',
		});
	};

	const handleExtraFilter = async () => {
		//check if at least one is filled
		if (!extraFilter.experience_max && !extraFilter.experience_min && !extraFilter.price_max && !extraFilter.price_min) {
			handleFilterMoreClose();
		}

		const params = {
			page_size: 12,
		};

		if (extraFilter.price_max) params.price_max = extraFilter.price_max;
		if (extraFilter.price_min) params.price_min = extraFilter.price_min;
		if (extraFilter.experience_min) params.experience_min = extraFilter.experience_min;
		if (extraFilter.experience_max) params.experience_max = extraFilter.experience_max;
		if (searchParams.name) params.search = searchParams.name;
		if (searchParams.service_area) params.service_areas__location__icontains = searchParams.service_area;
		if (searchParams.zip_code) params.zip_code__iexact = searchParams.zip_code;
		handleFilterMoreClose();

		try {
			const { data } = await axios.get(URL + 'register/agents/', {
				params: params,
			});

			setAgents(data.results);
			setPage(data.count);
			scrollUp();
		} catch (error) {
			toast.error('Something went wrong');
		}
	};

	const getLabel = (key) => {
		const { experience_min, experience_max, price_max, price_min } = extraFilter;
		if (key === 'price') {
			if (price_min && price_max) return `$${price_min} - $${price_max}`;
			if (price_min) return `> $${price_min}`;
			if (price_max) return `< $${price_max}`;
		}
		if (key === 'experience') {
			if (experience_min && experience_max) return `${experience_min} - ${experience_max} years`;
			if (experience_min) return `> ${experience_min} years`;
			if (experience_max) return `< ${experience_max} years`;
		}
	};

	const onDeleteFilter = (key) => {
		let data = {
			...extraFilter,
		};
		if (key === 'price') {
			data.price_max = '';
			data.price_min = '';
		}
		if (key === 'experience') {
			data.experience_max = '';
			data.experience_min = '';
		}
		setExtraFilter(data);

		getSearchResults({ ...data, ...searchParams });
	};

	return (
		<>
			<SEO>
				<title>Get Accurate Home Value Report from a Local Real Estate Expert</title>
				<meta name='og:title' content='Get Accurate Home Value Report from a Local Real Estate Expert' />
				<meta
					name='description'
					content="Get an unbiased home value report from a local real estate expert. Don't trust computer generated CMAs or estimates. Get your accurate report now by providing your property address and requesting a BPO report."
				/>
				<meta
					name='og:description'
					content="Get an unbiased home value report from a local real estate expert. Don't trust computer generated CMAs or estimates. Get your accurate report now by providing your property address and requesting a BPO report."
				/>
			</SEO>
			{isLoading && <Spinner />}
			<Dialog fullWidth maxWidth='sm' open={detailsModalOpen} onClose={() => setDetailsModalOpen(false)}>
				<DialogTitle sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
					<Box display='flex' alignItems='center' gap='10px'>
						<Avatar
							src={selectedAgent?.profile_image}
							alt='user'
							imgProps={{
								style: {
									objectFit: 'cover',
									objectPosition: 'top center',
								},
							}}
							sx={{
								width: '55px',
								height: '55px',
							}}
						/>
						<Typography>
							{selectedAgent?.first_name} {selectedAgent?.last_name}
						</Typography>
					</Box>
					<IconButton onClick={() => setDetailsModalOpen(false)}>
						<Close fontSize='small' />
					</IconButton>
				</DialogTitle>
				<DialogContent>
					<Divider style={{ margin: 'auto', width: '100%', background: 'var(--primary-color)', marginBottom: '1rem' }} />
					<Grid container rowSpacing={1} alignItems='center'>
						<Typography sx={{ whiteSpace: 'pre-line', fontWeight: '300', fontSize: '16px' }}>
							{isBioShort ? (selectedAgent?.bio || '')?.slice(0, 200) + (selectedAgent?.bio?.length > 200 ? '...' : '') : selectedAgent?.bio}
						</Typography>
						<Grid item xs={12}>
							{selectedAgent?.bio?.length > 200 && (
								<Button
									variant='text'
									sx={{
										textTransform: 'capitalize',
										marginTop: 1,
										'&:hover': { backgroundColor: 'transparent' },
										paddingLeft: 0,
										justifyContent: 'flex-start',
									}}
									color='secondary'
									onClick={() => setIsBioShort((v) => !v)}
								>
									<Typography fontSize='16px' fontWeight='500'>
										{isBioShort ? 'Show more' : 'Hide'}
									</Typography>
								</Button>
							)}
						</Grid>
						<Grid item xl={1} md={2} xs={2} sm={2}>
							<PlaceOutlined />
						</Grid>
						<Grid item xl={11} md={10} xs={10} sm={10} display='flex' flexDirection='column'>
							<Typography variant='body2' fontWeight='700' fontSize='16px'>
								{(selectedAgent?.brokerage_address || '') +
									' ' +
									(selectedAgent?.state || '') +
									' ' +
									(selectedAgent?.city || '') +
									', ' +
									(selectedAgent?.zip_code || '')}
							</Typography>
							<Typography fontSize='16px' fontWeight='400' color='#A2A2A2'>
								Broker Address
							</Typography>
						</Grid>
						<Grid item xl={1} md={2} xs={2} sm={2}>
							<AccessTime />
						</Grid>
						<Grid item xl={11} md={10} xs={10} sm={10} display='flex' flexDirection='column'>
							<Typography variant='body2' fontWeight='700' fontSize='16px'>
								{calculateYearsOfExperience(selectedAgent?.year_work_commenced, 'Not Specified')}
							</Typography>
							<Typography fontSize='16px' fontWeight='400' color='#A2A2A2'>
								Years of Experience
							</Typography>
						</Grid>
						<Grid item xl={1} md={2} xs={2} sm={2}>
							<LicenseIcon fill='black' />
						</Grid>
						<Grid item xl={11} md={10} xs={10} sm={10} display='flex' flexDirection='column'>
							<Typography variant='body2' fontWeight='700' fontSize='16px'>
								{selectedAgent?.licenses?.find((license) => license.category === 'real_estate_agent')?.number || 'Not Provided'}
							</Typography>
							<Typography fontSize='16px' fontWeight='400' color='#A2A2A2'>
								Real Estate License
							</Typography>
						</Grid>
						<Grid item xl={1} md={2} xs={2} sm={2}>
							<AreaMap />
						</Grid>
						<Grid item xl={11} md={10} xs={10} sm={10} display='flex' flexDirection='column'>
							<Typography variant='body2' fontWeight='700' fontSize='16px'>
								{selectedAgent?.service_areas?.map((location) => location.location)?.join(', ') || 'Not Provided'}
							</Typography>
							<Typography fontSize='16px' fontWeight='400' color='#A2A2A2'>
								Service Areas
							</Typography>
						</Grid>
						<Grid item xl={1} md={2} xs={2} sm={2}>
							<LanguageIcon />
						</Grid>
						<Grid item xl={11} md={10} xs={10} sm={10} display='flex' flexDirection='column'>
							<Typography variant='body2' fontWeight='700' fontSize='16px'>
								{selectedAgent?.languages?.map((lang) => lang.name)?.join(', ') || 'Not Provided'}
							</Typography>
							<Typography fontSize='16px' fontWeight='400' color='#A2A2A2'>
								Languages
							</Typography>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button
						href={`/order-bpo-report/${selectedAgent?.id}${address ? '?address=' + address : ''}`}
						variant='contained'
						disableElevation
						color='secondary'
						sx={{ marginLeft: '1rem', textTransform: 'capitalize' }}
					>
						Get BPO Report
					</Button>
				</DialogActions>
			</Dialog>
			<Box classes={classes.root} id='agents'>
				<Box className={classes.top}>
					{/* <Hidden smDown> */}
					<AdPrice />
					{/* </Hidden> */}
					<Box className={classes.blue}>
						<Typography fontWeight={400} fontSize={'30px'} color='white' mb='1rem'>
							Choose An Agent
						</Typography>
						<Box
							display='flex'
							justifyContent='center'
							alignItems='center'
							gap='10px'
							sx={{
								flexDirection: {
									sm: 'column',
									xs: 'column',
									md: 'row',
									lg: 'row',
									xl: 'row',
								},
							}}
						>
							<Grid
								container
								spacing={0}
								sx={{
									width: {
										xs: '100%',
										sm: '100%',
										md: '100%',
										lg: '70vw',
										xl: '70vw',
									},
									// margin: 'auto',
								}}
							>
								<Grid item xs={12} lg={4} sm={4} md={4}>
									<TextField
										size='medium'
										color='secondary'
										fullWidth
										value={searchParams.name}
										name='name'
										onChange={handleParamChange}
										InputProps={{
											startAdornment: (
												<InputAdornment position='end' sx={{ marginRight: '10px' }}>
													<SearchIcon />
												</InputAdornment>
											),
										}}
										placeholder='Agent Name'
										className={classes.leftBox}
									/>
								</Grid>
								<Grid item xs={12} lg={4} sm={4} md={4}>
									<TextField
										size='medium'
										color='secondary'
										value={searchParams.service_area}
										name='service_area'
										onChange={handleParamChange}
										InputProps={{
											startAdornment: (
												<InputAdornment position='end' sx={{ marginRight: '10px' }}>
													<SearchIcon />
												</InputAdornment>
											),
										}}
										fullWidth
										placeholder='Service Area'
										className={classes.middleBox}
									/>
								</Grid>
								<Grid item xs={12} lg={4} sm={4} md={4}>
									<TextField
										size='medium'
										color='secondary'
										value={searchParams.zip_code}
										name='zip_code'
										onChange={handleParamChange}
										InputProps={{
											startAdornment: (
												<InputAdornment position='end' sx={{ marginRight: '10px' }}>
													<SearchIcon />
												</InputAdornment>
											),
										}}
										fullWidth
										placeholder='Zipcode'
										className={classes.rightBox}
									/>
								</Grid>
							</Grid>
							<IconButton
								sx={{ backgroundColor: 'white', '&:hover': { backgroundColor: '#b9b7b7' }, height: 'fit-content' }}
								aria-describedby={id}
								onClick={handleFilterMoreClick}
							>
								<Tune />
							</IconButton>
						</Box>
						<Box display='flex' justifyContent='center' gap='1rem' mt={1}>
							{(extraFilter?.price_max || extraFilter?.price_min) && (
								<Chip label={getLabel('price')} onDelete={() => onDeleteFilter('price')} color='primary' />
							)}
							{(extraFilter?.experience_max || extraFilter?.experience_min) && (
								<Chip label={getLabel('experience')} onDelete={() => onDeleteFilter('experience')} color='primary' />
							)}
						</Box>
						<>
							{/* menu for more filters */}
							<Popover
								id={id}
								anchorEl={anchorEl}
								open={filterMoreOpen}
								onClose={handleFilterMoreClose}
								anchorOrigin={{
									vertical: 'bottom',
									horizontal: 'left',
								}}
								transformOrigin={{
									vertical: 'top',
									horizontal: 'right',
								}}
							>
								<Card elevation={0}>
									<CardContent sx={{ padding: '1rem' }}>
										<Typography mb={1} sx={{ fontSize: '1rem', fontWeight: '500' }} color='secondary'>
											Price Range
										</Typography>
										<Grid container spacing={1}>
											<Grid item lg={6} md={6} xs={12} sm={12}>
												<TextField
													type={'number'}
													inputProps={{ min: 199, step: 100 }}
													size='small'
													fullWidth
													variant='outlined'
													value={extraFilter.price_min}
													name='price_min'
													onChange={handleExtraFilterChange}
													placeholder='Enter min'
													InputProps={{
														endAdornment: <InputAdornment position='end'>$</InputAdornment>,
													}}
												/>
											</Grid>
											<Grid item lg={6} md={6} xs={12} sm={12}>
												<TextField
													type={'number'}
													inputProps={{ min: 199, step: 100 }}
													size='small'
													fullWidth
													variant='outlined'
													value={extraFilter.price_max}
													name='price_max'
													onChange={handleExtraFilterChange}
													placeholder='Enter max'
													InputProps={{
														endAdornment: <InputAdornment position='end'>$</InputAdornment>,
													}}
												/>
											</Grid>
										</Grid>
										<Typography my={1} sx={{ fontSize: '1rem', fontWeight: '500' }} color='secondary'>
											Years of experience (min-max)
										</Typography>
										<Grid container spacing={1}>
											<Grid item lg={6} md={6} xs={12} sm={12}>
												<TextField
													type={'number'}
													inputProps={{ min: 199, step: 100 }}
													size='small'
													fullWidth
													variant='outlined'
													value={extraFilter.experience_min}
													name='experience_min'
													onChange={handleExtraFilterChange}
													placeholder='Enter min'
												/>
											</Grid>
											<Grid item lg={6} md={6} xs={12} sm={12}>
												<TextField
													type={'number'}
													inputProps={{ min: 199, step: 100 }}
													size='small'
													fullWidth
													variant='outlined'
													value={extraFilter.experience_max}
													name='experience_max'
													onChange={handleExtraFilterChange}
													placeholder='Enter max'
												/>
											</Grid>
										</Grid>
									</CardContent>
									<Divider sx={{ background: grey[100] }} />
									<CardActions sx={{ padding: '.8rem 2rem', justifyContent: 'right' }}>
										<Button disableRipple color='secondary' sx={{ textTransform: 'none' }} onClick={handleReset}>
											Reset
										</Button>
										<Button disableRipple variant='contained' color='secondary' sx={{ textTransform: 'none' }} onClick={handleExtraFilter}>
											Done
										</Button>
									</CardActions>
								</Card>
							</Popover>
						</>
					</Box>
				</Box>
				<Box
					sx={{
						padding: {
							xs: '1rem',
							sm: '1rem',
							md: '1rem',
							lg: '1rem 2rem',
							xl: '1rem 4rem',
						},
					}}
				>
					<Grid container spacing={3}>
						{agents.map((agent) => (
							<Grid item xs={12} sm={6} md={4} lg={3} key={agent.id}>
								{/* <Link to={`/order-bpo-report/${agent.id}`} style={{ cursor: 'pointer' }}> */}
								<AgentCard {...agent} handleProfileView={handleProfileView} agent={agent} address={address} />
								{/* </Link> */}
							</Grid>
						))}
					</Grid>
				</Box>
				<Pagination
					size='medium'
					color='secondary'
					sx={{
						display: 'flex',
						justifyContent: 'center',
					}}
					shape='rounded'
					variant='outlined'
					count={Math.ceil(page / 12)}
					onChange={handlePageChange}
				/>
			</Box>
		</>
	);
}

export default BpoAgents;

const AgentCard = ({
	first_name,
	last_name,
	bio,
	profile_image,
	average_review,
	review_count,
	year_work_commenced,
	handleProfileView,
	address,
	agent,
}) => {
	const navigate = useNavigate();
	return (
		<Card
			sx={{
				padding: '1rem',
				width: '100%',
				height: '100%',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-between',
				gap: '10px',
				cursor: 'pointer',
			}}
			onClick={() => handleProfileView(agent)}
		>
			<Box display='flex' justifyContent='flex-start' alignItems='center' gap='10px'>
				<Avatar
					src={profile_image}
					alt={`${first_name} ${last_name}`}
					sx={{
						'&>img': {
							objectPosition: 'top center',
						},
						width: '60px',
						height: '60px',
					}}
				/>
				<Typography fontWeight='600' textTransform={'capitalize'} fontSize={'16px'}>
					{first_name} {last_name}
				</Typography>{' '}
			</Box>
			<Box display='flex' alignItems={'center'} gap='10px'>
				<AccessTime fontSize='small' />
				<Box display='flex' flexDirection='column'>
					<Typography fontSize='14px' fontWeight='700'>
						{calculateYearsOfExperience(year_work_commenced)}
					</Typography>
					<Typography fontSize='14px' fontWeight='300' color='#A2A2A2'>
						Work Experience
					</Typography>
				</Box>
			</Box>
			<Box>
				<Typography
					variant='body2'
					sx={{
						display: '-webkit-box',
						WebkitLineClamp: '3',
						' -webkit-box-orient': 'vertical',
						overflow: 'hidden',
						textOverflow: 'ellipsis',
					}}
					fontSize='14px'
					mb={1}
					color={'GrayText'}
				>
					{bio}
				</Typography>
			</Box>
			<Box display='flex' justifyContent='space-between' alignItems='center'>
				{agent?.discount_percent > 0 && (
					<Typography fontSize='18px' margin='0' display='flex' alignItems='center'>
						<PriceIcon /> <b>New:</b>{' '}
						<span style={{ color: '#005ea9', fontSize: '18px', fontWeight: '900', marginLeft: '5px' }}>
							$ {Number(agent?.discount_value).toFixed(2)}
						</span>
					</Typography>
				)}
				<Typography fontSize='16px' color={agent?.discount_percent > 0 ? '#A2A2A2' : 'black'} margin='0'>
					List Price:{' '}
					<span style={{ textDecoration: agent?.discount_percent > 0 ? 'line-through' : 'none' }}>$ {Number(agent?.report_price).toFixed(2)}</span>
				</Typography>
			</Box>
			<Box display='flex' alignItems='center' justifyContent='space-between'>
				<Box display='flex' gap='5px' alignItems='center'>
					<Rating name='read-only' value={average_review || 5} readOnly size='small' />
					<Typography fontSize='14px' fontWeight='300'>
						{review_count} <span style={{ color: '#818181', fontWeight: '400' }}>Reviews</span>
					</Typography>
				</Box>
				<Button
					color='secondary'
					variant='contained'
					sx={{ textTransform: 'capitalize' }}
					onClick={(e) => {
						e.stopPropagation();
						navigate(`${agent?.id}?${address ? '?address=' + address : ''}`);
					}}
				>
					<Typography fontSize='14px' fontWeight='400'>
						Order Report
					</Typography>
				</Button>
			</Box>
		</Card>
	);
};

const AdPrice = () => {
	return (
		<Card
			elevation={0}
			sx={{
				padding: '1rem',
				maxWidth: '400px',
				alignSelf: 'flex-start',
				margin: {
					sm: 'auto',
					xs: 'auto',
					md: 'auto',
					lg: '1rem 0 1rem 3rem',
					xl: '1rem 0 1rem 3rem',
				},
			}}
		>
			<Typography variant='h6' fontSize={'1.2rem'} fontWeight='600' textAlign='center'>
				Why You Need A BPO Report
			</Typography>
			<Divider style={{ margin: '0.5rem auto', width: '4rem', borderRadius: '5px', height: '4px', background: '#FFAA01' }} />
			<List sx={{ fontWeight: '300' }}>
				<ListItem>
					<ListItemIcon id='icon'>
						<CheckIcon color='primary' fontSize='small' />
					</ListItemIcon>
					<ListItemText>
						<Typography fontWeight='300' fontSize='14px' variant='body2'>
							Get your home value from an unbiased Local Real-Estate Expert{' '}
						</Typography>
					</ListItemText>
				</ListItem>
				<ListItem>
					<ListItemIcon id='icon'>
						<CheckIcon color='primary' fontSize='small' />
					</ListItemIcon>
					<ListItemText>
						<Typography variant='body2' fontWeight='300' fontSize='14px'>
							Don't trust computer generated <b>CMA's</b> or <b>ESTIMATES</b>
						</Typography>
					</ListItemText>
				</ListItem>
				<ListItem>
					<ListItemIcon id='icon'>
						<CheckIcon color='primary' fontSize='small' />
					</ListItemIcon>
					<ListItemText>
						<Typography variant='body2' fontWeight='300' fontSize='14px'>
							Sellers, Don't <b>UNDERLIST</b> your Home
						</Typography>
					</ListItemText>
				</ListItem>
				<ListItem>
					<ListItemIcon id='icon'>
						<CheckIcon color='primary' fontSize='small' />
					</ListItemIcon>
					<ListItemText>
						<Typography variant='body2' fontWeight='300' fontSize='14px'>
							Buyers, Don't <b>OVERPAY</b>
						</Typography>
					</ListItemText>
				</ListItem>
			</List>
		</Card>
	);
};
