import { Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Stack, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import base from '../apis';
import { getSubscriptionStatus } from '../utils/functions';

const CreateNewListingDialog = ({ open, close }) => {
	const [companyName, setCompanyName] = useState('');

	const [isCreationLoading, setIsCreationLoading] = useState(false);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const submitNewListing = async () => {
		if (!companyName) {
			toast.warn('Enter a company name');
			return;
		}
		setIsCreationLoading(true);
		try {
			const { data } = await base.post('register/ind-prof/create-profile/', {
				company_name: companyName,
			});
			dispatch({ type: 'ADD_LISTING', payload: data });
			dispatch({ type: 'SET_SUBSCRIPTIONS', payload: getSubscriptionStatus([data.subscription]) });

			close();
			navigate('/my-listing/selected');
			//route to business listing
		} catch (error) {
			toast.error('Could not create new listing');
		} finally {
			setIsCreationLoading(false);
		}
	};

	return (
		<Dialog fullWidth maxWidth='sm' open={open} onClose={close}>
			<DialogTitle sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
				<Stack sx={{ width: '100%' }}>
					<Stack direction='row' justifyContent={'space-between'} sx={{ width: '100%' }} alignItems='center'>
						<Typography fontWeight={600}>Create a New Listing</Typography>
						<IconButton onClick={close}>
							<Close fontSize='small' />
						</IconButton>
					</Stack>
				</Stack>
			</DialogTitle>
			<DialogContent dividers>
				<Grid container spacing={2}>
					<Grid item xs={4} alignItems='center' justifyContent={'center'}>
						<Typography>Company Name</Typography>
					</Grid>
					<Grid item xs={8}>
						<TextField
							size='small'
							color='secondary'
							fullWidth
							placeholder='Company Name'
							autoFocus
							value={companyName}
							onChange={(e) => setCompanyName(e.target.value)}
						/>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<LoadingButton color='secondary' variant='contained' onClick={submitNewListing} loading={isCreationLoading}>
					Submit
				</LoadingButton>
			</DialogActions>
		</Dialog>
	);
};

export default CreateNewListingDialog;
