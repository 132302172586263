import { Box,Typography} from '@mui/material'
import React from 'react'
import EnhancedLoanAmount from '../atomicComponents/EnhancedLoanAmount'
import FlatRatePercentageInput from '../atomicComponents/FlatRatePercentageInput'


function QuestionnaireSecondaryLoanAmount({handler,data,extraComponentData}) {

    const rateHandler = (value) =>{
        handler({secondary_down_payment_dollar_or_percent:value})
    }
  return (
    <Box sx={{width:"50%",minWith:"37.5rem"}}>
        <Box><Typography variant="heading" sx={{color:"#1378A5"}}>{extraComponentData?.secondaryHeaders?.[0]}</Typography></Box>
        <Box>
            <EnhancedLoanAmount
            name='secondary_loan_amount'
            data = {data}
            label='What is your Loan Amount?'
            noToolTip={true}
            handler = {handler}
            overrideStyle = {{            
            minWidth: "25rem",
            marginTop: "10px",
            marginRight:"5px"}}
            />
        </Box>
        <Box sx={{marginTop:"1.5rem"}}>
            <FlatRatePercentageInput
            name='secondary_down_payment'
            title='What is your Down Payment Amount?'
            noToolTip={true}
            data={data}
            rate = {+data?.secondary_down_payment_dollar_or_percent}
            handler = {handler}
            rateHandler={rateHandler}
            referenceValue={data?.secondary_loan_amount}    
            />         
        </Box>
    </Box>
  )
}

export default QuestionnaireSecondaryLoanAmount