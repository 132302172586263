import { Box, Divider, Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import bgImage from '../../../assets/dealAnalyzer/clientlanding.webp';
import { Container, Modal } from '@mui/material';
import MuiThemeLayout from '../../../Layouts/MuiThemeLayout';
import { Card } from '@mui/material';
import { useDispatch } from 'react-redux';
import DealItem from './DealItem';
import SARicon from '../../../assets/dealAnalyzer/iconComponents/SARicon';
import RDSADicon from '../../../assets/dealAnalyzer/iconComponents/RDSADicon';
import NCFicon from '../../../assets/dealAnalyzer/iconComponents/NCFicon';
import BARicon from '../../../assets/dealAnalyzer/iconComponents/BARicon';
import OfferItem from './OfferItem';
import SelectedOffer from './SelectedOffer';
import { updateDealAnalyzerOrderDetails } from '../../../actions/dealAnalyzer';
import SEO from '../../../components/SEO';

const useStyles = (_theme) => {
	return {
		root: {
			backgroundImage: `url(${bgImage})`,
			backgroundRepeat: 'no-repeat',
			width: '100%',
			height: '100vh',
			// overflowY:"hidden",
			// position:"fixed",
			backgroundSize: 'cover',
			backgroundPosition: 'center center',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			overflowY: 'scroll',
		},
		text: {
			fontSize: '3.75rem',
			fontFamily: 'Roboto',
			fontWeight: 700,
			lineHeight: '4.4rem',
			textTransform: 'capitalize',
			color: 'white',
			margin: '3.125rem',
		},
		inner: {
			// height:"35vw",
			// width:"80vw",
			border: 'solid 1px #FFAA01',
			borderRadius: '10px',
			margin: '0 1.25rem',
			overflowY: 'scroll',
			// marginTop:"50rem"
		},
		leftBox: {},
		leftTop: {
			padding: '2.5rem',
		},
		rightBox: {
			borderLeft: 'solid 1px #FFAA01',
			height: '100%',
			padding: '1.25rem',
		},
	};
};

function ClientLandingPage() {
	const styles = useStyles();
	const [open, setOpen] = React.useState(false);
	const dispatch = useDispatch();
	const dealArray = [deals.deal1, deals.deal2, deals.deal3];
	const [dealType, setDealType] = useState();

	const handleClickOpen = (index) => {
		setDealType(dealArray[index]);
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};
	const handleSelectOffer = (data) => {
		dispatch(updateDealAnalyzerOrderDetails(data));
	};

	return (
		<MuiThemeLayout>
			<SEO>
				<title>BPOTech: Analyze your Real Estate Investments Now</title>
				<meta name='og:title' content='BPOTech: Analyze your Real Estate Investments Now' />
				<meta
					name='description'
					content='Maximize your returns on real estate investments with BPOTech. Our platform provides data-driven insights and analysis to help you make informed decisions.'
				/>
				<meta
					name='og:description'
					content='Maximize your returns on real estate investments with BPOTech. Our platform provides data-driven insights and analysis to help you make informed decisions.'
				/>
				<meta name='og:image' content={window.location.origin + bgImage} />
			</SEO>
			<Box sx={styles.root}>
				<Card elevation={0} sx={styles.inner}>
					<Grid container gridColumn={12}>
						<Grid item md={12} lg={8}>
							<Box sx={styles.leftBox}>
								<Box sx={styles.leftTop}>
									<Box sx={{ textAlign: 'center' }}>
										<Typography variant='landingMain'> LET US ANALYZE IT!</Typography>
										<Box sx={{ display: 'flex', justifyContent: 'center' }}>
											<Divider
												variant='middle'
												sx={{
													width: '10%',
													backgroundColor: '#FFD569',
													height: '4px',
													margin: '1rem 0',
												}}
											/>
										</Box>
									</Box>
									<Box>
										<Box sx={{ display: 'flex', justifyContent: 'center' }}>
											<Typography variant='landingMid'>IS IT A GOOD OR BAD INVESTMENT?</Typography>
										</Box>
										<Box sx={{ display: 'flex', justifyContent: 'center' }}>
											<Typography variant='landingSmall'> At BPO Homes we can Analyze Any Real Estate Deal for YOU!</Typography>
										</Box>
									</Box>
									<Grid container columns={36} gap={1} sx={{ marginTop: '1.25rem' }}>
										<Grid item sm={10} xs={36}>
											<OfferItem {...deals.deal1} open={handleClickOpen} close={handleClose} handleSelectOrder={handleSelectOffer} dealType={0} />
										</Grid>
										<Grid item sm={1} sx={{ display: { xs: 'none', sm: 'block' } }}>
											<Box sx={{ height: '100%' }}>
												<Divider
													orientation='vertical'
													// flexItem
													sx={{
														width: '2px',
														margin: '2.5rem auto',
														backgroundColor: '#FFD569',
														height: '80%',
													}}
												/>
											</Box>
										</Grid>

										<Grid item sm={11} xs={36}>
											<OfferItem {...deals.deal2} open={handleClickOpen} close={handleClose} handleSelectOrder={handleSelectOffer} dealType={1} />
										</Grid>
										<Grid item sm={1} sx={{ display: { xs: 'none', sm: 'block' } }}>
											<Box sx={{ height: '100%' }}>
												<Divider
													orientation='vertical'
													// flexItem
													sx={{
														width: '2px',
														margin: '2.5rem auto',
														backgroundColor: '#FFD569',
														height: '80%',
													}}
												/>
											</Box>
										</Grid>

										<Grid item sm={11} xs={36}>
											<OfferItem {...deals.deal3} open={handleClickOpen} close={handleClose} handleSelectOrder={handleSelectOffer} dealType={2} />
										</Grid>
									</Grid>
								</Box>
								<Box sx={{ borderTop: 'solid 1px #FFAA01' }}>
									<Box sx={{ padding: '1.5rem', textAlign: 'center' }}>
										<Typography variant='dealCta'>All Deal Analyzations Include a Final Detailed Property Proforma Report</Typography>
									</Box>
								</Box>
							</Box>
						</Grid>
						<Grid item lg={4} md={0} sx={{ display: { xs: 'none', lg: 'block' } }}>
							<Box sx={styles.rightBox}>
								<Box>
									<Typography variant='landingIconText'>Our Deal Options:</Typography>
									<Divider
										sx={{
											width: '20%',
											backgroundColor: '#FFD569',
											height: '4px',
											margin: '1.25rem auto 1.25rem 0',
										}}
									/>
								</Box>
								<Box>
									{dealOptions.map((element) => (
										<DealItem text={element.title} icon={element.icon} />
									))}
								</Box>
							</Box>
						</Grid>
					</Grid>
				</Card>
				<Modal
					open={open}
					onClose={handleClose}
					width='55rem'
					sx={{
						padding: '0',
						'&:focus': {
							outline: 0,
						},
					}}
					fullwidth={true}
				>
					<Container>
						<Box sx={styles.modal}>{dealType && <SelectedOffer {...dealType} handleClose={handleClose} />}</Box>
					</Container>
				</Modal>
			</Box>
		</MuiThemeLayout>
	);
}

export default ClientLandingPage;

const dealOptions = [
	{ title: 'Straight Aesthetic Remodel', icon: SARicon },
	{ title: 'Remodel & Add Addition', icon: SARicon },
	{ title: 'Remodel Down to Studs', icon: RDSADicon },
	{ title: 'Remodel Down To Studs & Add Addition', icon: RDSADicon },
	{ title: 'New Construction/Full Development', icon: NCFicon },
	{ title: 'Buy & Rent', icon: BARicon },
];
const deals = {
	deal1: {
		offerIndex: 0,
		price: '$79.00',
		priceInNumber: 79,
		unit: 'per deal',
		annually: '$79.00',
		callToAction: {
			header: 'Use Us',
			main: 'Save your time & Pay as you go per Deal',
		},
		buttonText: 'Pay Now',
		offerScope: 'Pay As You Go',
		offerScopeDetail: 'Save your time & pay as you go per Deal',
	},
	deal2: {
		offerIndex: 1,
		price: '$99.00',
		priceInNumber: 99,
		unit: 'per month',
		annually: '$1089',
		annuallyInNumber: 1089,
		annualUnit: 'for a year',
		callToAction: {
			header: 'Experienced Investor',
			main: 'Do it yourself & Download our Deal Analyzer Software',
		},
		buttonText: 'Subscribe',
		offerScope: 'Experienced Investor',
		offerScopeDetail: 'Do it yourself',
	},
	deal3: {
		offerIndex: 2,
		price: '$199.00',
		priceInNumber: 199,
		unit: 'per month',
		annually: '$2189',
		annuallyInNumber: 2189,
		annualUnit: 'for a year',
		callToAction: {
			header: 'Use Us',
			main: 'Save your time we will Analyze All of your Real Estate Deals Unlimited!!',
		},
		buttonText: 'Subscribe',
		offerScope: 'Advanced',
		offerScopeDetail: 'Save your time, we will Analyze All of your Real Estate Deals Unlimited',
	},
};
