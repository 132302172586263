import { loanTypes } from "../../../utils/numericToStringConvert";
import { getPrimaryConventional } from "./conventionalLoanDealFinance";
import { getPrimaryMortgage } from "./mortgageLoanDealFinance";

export const getRestOfDealFinancesBuyAndRent = ({
  analyzerData,
  resultData,
  dealFinanceOption,
  typeId,
}) => {
  const primaryLoanSubType =
    +analyzerData?.financeOptions?.primary_sub_loan_type || 0;
  const primaryLoanType = +analyzerData?.financeOptions?.primary_loan_type || 0;

  const loanType = {
    item: "Loan Type",
    value:
      loanTypes?.[+analyzerData?.financeOptions?.primary_loan_type] || "N/A",
    render: "simple",
  };
  const purchasePrice = {
    item: "Purchase Price",
    value: analyzerData?.financeOptions?.cash_for_home_purchase_price,
    render: "dollar",
  };
  // const primaryPurchasePrice =     {
  //   item: "Purchase Price",
  //   value: analyzerData?.financeOptions?.primary_purchase_price,
  //   render: "dollar",
  // }
  // const primaryNewConstructionCost =     {
  //   item: "New Costruction Cost Amount",
  //   value: analyzerData?.financeOptions?.primary_new_construction_cost,
  //   render: "dollar",
  // }
  const mortgageLoanAmount = {
    //from calculations
    item: "Loan Amount",
    value:
      resultData?.primary_finance_option_summary?.interest_only_mortgage_loan
        ?.mortage_loan_amount,
    render: "dollar",
  };
  const mortgageInterestRate = {
    item: "Interest Rate",
    value: analyzerData?.financeOptions?.primary_annual_interest_rate,
    render: "percent",
  };
  // const mortgageInterestOnlyTerm = {
  //   item: "Interest Only Term",
  //   value: analyzerData?.financeOptions?.primary_interest_only_term,
  //   render:"months"
  // }
  // const mortgageInterestOnlyPayment = {
  //   item: "Interest Only Payment",
  //   value: resultData?.primary_finance_option_summary?.interest_only_mortgage_loan?.interest_only_payment,
  //   render:"dollar"
  // }
  // const mortgageInterestPlusPrinciple = {
  //   item: "Principal + Interest Payment",
  //   value: resultData?.primary_finance_option_summary?.interest_only_mortgage_loan?.principal_plus_interest_payment,
  //   render:"dollar"
  // }
  const remodelCost = {
    item: "Remodel Cost",
    value: analyzerData?.financeOptions?.primary_remodel_cost,
    render: "dollar",
  };
  const lenderPoints = {
    item: "Lender Points",
    value: analyzerData?.financeOptions?.primary_lender_points,
    render: "points",
  };
  const wholesaleFee = {
    item: "Wholesale Fee",
    value: analyzerData?.financeOptions?.primary_wholesale_fee,
    render: "dollar",
  };
  const downPayment = {
    item: "Down Payment",
    value: analyzerData?.financeOptions?.primary_down_payment,
    render: "percent_dollar",
    rate:
      analyzerData?.financeOptions?.primary_down_payment_dollar_or_percent || 0,
    referenceValue: analyzerData?.financeOptions?.primary_purchase_price,
  };
  const loanAmount = {
    item: "Loan Amount",
    value:
      resultData?.primary_finance_option_summary?.conventional_mortgage_loan ||
      resultData?.primary_finance_option_summary?.interest_only_mortgage_loan,
    render: "dollar",
  };
  // const newConstructionAmount = {
  //   item: "Loan Amount",
  //   value: resultData?.primary_finance_option_summary?.primary_new_construction_cost,
  //   render:"dollar"
  // }
  const interestRate = {
    item: "Interest Rate",
    value: analyzerData?.financeOptions?.primary_interest_rate,
    render: "percent",
  };

  const loanTerm = {
    item: "Loan Term",
    value: analyzerData?.financeOptions?.primary_term_of_loan,
    render: "months",
  };
  // const cashForRemodel =     {
  //   item: "Cash For Remodel",
  //   value: analyzerData?.financeOptions?.cash_for_home_purchase_price,
  //   render: "dollar",
  // }

  const case0 = {
    primaryHomeLoan:
      primaryLoanSubType === 0 || primaryLoanType === 0
        ? getPrimaryConventional({ resultData, analyzerData })
        : getPrimaryMortgage({ resultData, analyzerData }),
    primaryHeader: "Primary Loan for Rent",
  };
  const case2 = {
    primaryHomeLoan:
      primaryLoanSubType === 0 || primaryLoanType === 0
        ? getPrimaryConventional({ resultData, analyzerData })
        : getPrimaryMortgage({ resultData, analyzerData }),
    primaryHeader: "Primary Home Loan",
    secondaryHeader: "Cash For Remodel",
    secondaryHomeLoan: [
      {
        item: "Cash For Remodel",
        value: analyzerData?.financeOptions?.cash_for_home_purchase_price,
        render: "dollar",
      },
    ],
  };

  const case3 = {
    primaryHeader: "Single Primary Loan for Home & Remodel",
    primaryHomeLoan: !primaryLoanSubType
      ? [
          loanType,
          purchasePrice,
          remodelCost,
          lenderPoints,
          wholesaleFee,
          downPayment,
          loanAmount,
          interestRate,
          loanTerm,
        ]
      : [
          loanType,
          purchasePrice,
          remodelCost,
          lenderPoints,
          wholesaleFee,
          downPayment,
          mortgageLoanAmount,
          mortgageInterestRate,
          loanTerm,
        ],

    //   [loanType,primaryPurchasePrice,primaryNewConstructionCost,lenderPoints,wholesaleFee,downPayment,loanAmount,interestRate,loanTerm,mortgageInterestOnlyTerm,mortgageInterestOnlyPayment,mortgageInterestPlusPrinciple]
  };
  const case4 = {
    primaryHomeLoan:
      primaryLoanSubType === 0 || primaryLoanType === 0
        ? getPrimaryConventional({ resultData, analyzerData })
        : getPrimaryMortgage({ resultData, analyzerData }),
    primaryHeader: "Primary Remodel Loan",
    secondaryHeader: "Cash for Home",
    secondaryRemodelLoan: [
      {
        item: "Purchase Price",
        value: !typeId
          ? analyzerData?.financeOptions?.cash_for_home_purchase_price
          : analyzerData?.financeOptions?.cash_for_land_purchase_price,
        render: "dollar",
      },
    ],
  };

  const case5 = {
    primaryHeader: "Cash for Home & Remodel",
    primaryHomeLoan: [
      {
        item: "Purchase Price",
        value: analyzerData?.financeOptions?.primary_purchase_price,
        render: "dollar",
      },
      {
        item: "Remodel Cost",
        value: analyzerData?.financeOptions?.primary_remodel_cost,
        render: "dollar",
      },
    ],
  };

  switch (+dealFinanceOption) {
    case 0:
      return case0;
    case 2:
      return case2;
    case 3:
      return case3;
    case 4:
      return case4;
    case 5:
      return case5;
    default:
      return {};
  }
};

//     The given code is a JavaScript module that exports a function called getRestOfDealFinancesBuyAndRent. This function takes an object as an argument with properties analyzerData, resultData, dealFinanceOption, and typeId.

// Here's a breakdown of what the code does:

// It imports the loanTypes object from a file located at "../../../utils/numericToStringConvert".
// It imports the getPrimaryConventional function from a file located in the same directory as the current module, named "conventionalLoanDealFinance".
// It imports the getPrimaryMortgage function from a file located in the same directory as the current module, named "mortgageLoanDealFinance".
// It defines the getRestOfDealFinancesBuyAndRent function using arrow function syntax and destructuring the properties from the input object.
// Inside the function, it assigns the numeric values of primary_sub_loan_type and primary_loan_type from analyzerData.financeOptions to primaryLoanSubType and primaryLoanType variables respectively. If the values are falsy, they are set to 0.
// It creates a loanType object with properties item, value, and render. The value is obtained by using the loanTypes object and retrieving the value at the index analyzerData.financeOptions.primary_loan_type. If the value doesn't exist, it defaults to 'N/A'.
// It creates a purchasePrice object with properties item, value, and render. The value is obtained from analyzerData.financeOptions.cash_for_home_purchase_price.
// It creates a mortgageLoanAmount object with properties item, value, and render. The value is obtained from resultData.primary_finance_option_summary.interest_only_mortgage_loan.mortage_loan_amount.
// It creates a mortgageInterestRate object with properties item, value, and render. The value is obtained from analyzerData.financeOptions.primary_annual_interest_rate.
// It creates a remodelCost object with properties item, value, and render. The value is obtained from analyzerData.financeOptions.primary_remodel_cost.
// It creates a lenderPoints object with properties item, value, and render. The value is obtained from analyzerData.financeOptions.primary_lender_points.
// It creates a wholesaleFee object with properties item, value, and render. The value is obtained from analyzerData.financeOptions.primary_wholesale_fee.
// It creates a downPayment object with properties item, value, render, rate, and referenceValue. The value is obtained from analyzerData.financeOptions.primary_down_payment. The rate is obtained from analyzerData.financeOptions.primary_down_payment_dollar_or_percent and defaults to 0. The referenceValue is obtained from analyzerData.financeOptions.primary_purchase_price.
// It creates a loanAmount object with properties item, value, and render. The value is obtained from resultData.primary_finance_option_summary.conventional_mortgage_loan or resultData.primary_finance_option_summary.interest_only_mortgage_loan, depending on the conditions.
// It creates an interestRate object with properties item, value, and render. The value is obtained from analyzerData.financeOptions.primary_interest_rate.
// It creates a loanTerm object with properties item, value, and render. The value is obtained from analyzerData.financeOptions.primary_term_of_loan.
// It defines case0 object with primaryHomeLoan and primaryHeader properties. The value of primaryHomeLoan is determined based on conditions using the getPrimaryConventional or getPrimaryMortgage functions. The primaryHeader is set to "Primary Loan for Rent".
// It defines case2 object with primaryHomeLoan, primaryHeader, secondaryHeader, and secondaryHomeLoan properties. The values of primaryHomeLoan and primaryHeader are similar to case0. The secondaryHeader is set to "Cash For Remodel", and secondaryHomeLoan is an array with an object containing the item, value, and render properties.
// It defines case3 object with primaryHeader and primaryHomeLoan properties. The value of primaryHeader is set to "Single Primary Loan for Home & Remodel". The value of primaryHomeLoan is determined based on conditions using the loanType, purchasePrice, remodelCost, lenderPoints, wholesaleFee, downPayment, loanAmount, interestRate, and loanTerm objects.
// It defines case4 object with primaryHomeLoan, primaryHeader, secondaryHeader, and secondaryRemodelLoan properties. The values of primaryHomeLoan and primaryHeader are similar to case0. The secondaryHeader is set to "Cash for Home", and secondaryRemodelLoan is an array with an object containing the item, value, and render properties.
// It defines case5 object with primaryHeader and primaryHomeLoan properties. The value of primaryHeader is set to "Cash for Home & Remodel". The value of primaryHomeLoan is an array with two objects containing the item, value, and render properties.
// It uses a switch statement on the dealFinanceOption value to return the corresponding case object.
// If none of the cases match, it returns an empty object.
// Overall, this code seems to handle different cases based on the dealFinanceOption value and creates different objects with specific properties and values for each case.
