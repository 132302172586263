export const disclaimerText = `
BPO Homes Deal Analyzer Proformas are created independently by
Real Estate professionals & Consumers. The BPO Homes Deal Analyzer is a web
based and non web based application that allows for the creation of a Proforma 
report that indicates the potential ROI (Return on Investment) of a property for
the user or users clients. Proformas are created with data provided from BPO Homes
Inc and Data personally uploaded by users subscribed to the BPO Homes Deal Analyzer
platform and or application. Users' results may vary and proformas do not guarantee 
the final outcome of an investment nor market stability as markets can change from day
to day, month to month and year to year. The BPO Homes Deal Analyzer is strictly meant
to assist consumers, Investors Buyers, & Sellers to make sound decisions on Home 
Acquisitions, Home Evaluation, Home Improvement and or for the resale of property. 
BPO's in the Real Estate industry have been primarily used by Lenders or Banks in the 
Mortgage industry. There may be different Real Estate standards for the creation of BPO Reports. 
BPO Homes Inc has developed a proprietary platform to assist Consumers, Buyers, Sellers, Realtors 
and or Brokers with Real Estate Data in order to create BPO Proformas but in no way does BPO Homes 
Inc or BPOHomes.com provide anyone with an opinion of value. The final proforma opinion of ROI ( return on investment ) 
is strictly based on the data entry of the subscriber or user. All Data provided by BPO Homes Inc to consumers to help 
with creation of BPO Homes Inc Proformas may or may not be deemed accurate. Data provided by BPO Homes Inc or BPOHomes.com 
to Realtors is provided from 3rd party sources. BPO Homes Proformas are not intended to be the sole reason why a consumer or 
Buyer makes a home purchase, improve or not improve, demolish a property. BPO Homes Proformas are not intended to be the sole 
reason why a consumer or Seller makes the consideration or final decision to sell their property. Buyers, Sellers and or consumers are 
fully responsible to make the final ultimate decision to 
Buy or Sell Real Estate. BPO Homes Inc, BPOHomes.com, Realtors, and Brokers are not to be held liable by Buyers, Sellers or Consumers 
for creation of BPO Homes Deal Analyzer Proformas, BPO Homes Proforma Data, BPO Homes Proforma Content, or by BPO Homes Proforma Values.`;

export const copyrightName = `BPO Homes`;
