import React from 'react'
import { Box, Typography} from '@mui/material'
import {disclaimerText} from '../../userInputCards/disclaimerText'

function Disclaimer({printVersion}) {
  return (
    <>
    {printVersion? 
      <Box sx={{marginTop:"40px",position:"absolute",top:"780px"}}>
        <Box>
            <Typography variant = 'disclaimerHeader'>Disclaimer:</Typography>
        </Box>
        <Box>
            <Typography  align='justify'>{disclaimerText}</Typography>
        </Box>

    </Box>
    :
    <Box sx={{marginTop:"40px"}}>
        <Box>
            <Typography variant = 'disclaimerHeader'>Disclaimer:</Typography>
        </Box>
        <Box>
            <Typography  align='justify' variant='h5'>{disclaimerText}</Typography>
        </Box>

    </Box>
  
  }
  </>
  )
}

export default Disclaimer