import {
    FormControl,
    InputAdornment,
    Box,
    Typography,
    OutlinedInput,
  } from "@mui/material";
  import StyledLabel from "./StyledLabel";
  import React from "react";
  import useTheme from "@mui/material/styles/useTheme";
import HtmlToolTip from "./HtmlToolTip";
import commaSeparate from "../utils/commaSeparate";
  
  const useStyle = (_theme)=>{
      return{
          purchasePrice:{
              display:"flex",
              flexDirection:"column",
              marginTop:"1.25rem",
              width:"100%"        
        },
      }
  }
  
  function EnhancedLoanAmount({handler, label,data,name,overrideStyle, noToolTip}) {

    const theme = useTheme();
    const styles = useStyle(theme);

    const handleValues = (e) => {
      let value = e.target.value;
      if (value) {
        value = Number.parseInt(value.split(",").join(""));
        if (isNaN(value)) {
          return;
        }
        handler({ [name]: value });
      } else {
        handler({ [name]: value });
      }
    };
    return (
      <>
        <Box sx={styles.purchasePrice}>
            <Box>
                <StyledLabel label={label} />
                {!noToolTip && <HtmlToolTip/>}
            </Box>
          <FormControl
            sx={
            overrideStyle ||
            {
              minWidth: "25rem",
              marginTop: "10px",
            }}
          >
            <OutlinedInput
              fullWidth
              name={name}
              value = {commaSeparate(data?.[name])}
              margin="normal"
              inputProps={{ style: { fontSize: "1rem" } }}
              onChange = {(e)=>{handleValues(e)}}
              placeholder="0"
              startAdornment={
                <InputAdornment position="start">
                  {" "}
                  <Typography variant="adornment" color='secondary'> $</Typography>
                </InputAdornment>
              }
            />
          </FormControl>
        </Box>
      </>
    );
  }
  
  export default EnhancedLoanAmount;