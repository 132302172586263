
//0 --return flatrate --1 return percentage
export const convertPercentOrFlat = (value, referenceValue, option)=>{
    if(Number.isNaN(+value) || Number.isNaN(+referenceValue)) return ''
  
    if(option ===0){
      return((+value/100 ) * +referenceValue).toFixed(2)
    }
    if (option === 1){
      return ((+value / +referenceValue)* 100).toFixed(2)
    }
  }