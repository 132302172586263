import { Box, Button, Card, CardContent, Container, Divider, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import AdBanner from '../../components/MUI-Components/AdBanner';
// import Navbar from '../../components/MUI-Components/Navbar'
import AdPremier from '../../assets/adpremier.webp';
import GridSection from '../../components/MUI-Components/GridSection';
import Premier1 from '../../assets/premier01.webp';
import Premier2 from '../../assets/premier02.webp';
import Premier3 from '../../assets/premier03.webp';
import Premier4 from '../../assets/premier04.webp';
import Premier5 from '../../assets/premier05.webp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toggleAuthModal } from '../../actions';
import AdPrice from '../../components/AdPrice/AdPrice';
import SEO from '../../components/SEO';

const useStyles = makeStyles((theme) => ({
	grid: {
		height: '100%',
	},
}));

const AdvertisePremier = () => {
	const classes = useStyles();

	const sections = [
		{
			title: 'We advertise you in 5 cities of your choice as a BPO Homes Premier Listing & Buyers Agent',
			copy: `Select any 5 cities you desire and we post you on the BPOHomes.com Premier Agent Page and on our BPOHomes.com Agent Finder page!`,
			img: Premier1,
		},
		{
			title: 'We Build you a custom single Listing Upload Page',
			copy: `Upload your listings and create a picture perfect website for your Listing at no extra cost!

                Upload Listing Information
                Upload Listing Photos
                Upload Listing Videos
                Upload Open House Dates

            You can have your own unique Listing Url (Agent Provided) or we will provide you with a unique BPO Homes url at no charge!`,
			img: Premier2,
		},
		{
			title: 'Digital Business Card',
			copy: `
            Stop wasting money on Business Cards!!
            A complimentary Digital Business Card is provided to you with your Premier Agent Combo subscription.
            Your Digital Business card features NFC Technology (Near Field Communication) TECH.
            Set your card in close proximity to your new potential clients phone and they receive all your contact information and Premier Agent Page.
            Listings & Personal Video is right in front of them along with all other contact to get directly in touch with you!
            `,
			img: Premier3,
		},
		{
			title: 'We Build you a Custom Premier Agent Web Page',
			copy: 'A professional website with All Active & Sold Listing posted including your own personal marketing video showing your services!',
			img: Premier4,
		},
		{
			title: 'BPO Homes Deal Analyzer Platform ($199.00 Per Month)',
			copy: 'Analyze any Real Estate Deal just like an Investor and generate one of our Pro Forma Reports to insure your making the right decision.',
			img: Premier5,
		},
	];

	const loggedIn = useSelector((state) => state?.auth?.token?.access);
	const dispatch = useDispatch();

	const navigate = useNavigate();

	const gotoPremier = () => {
		const route = '/prem-sub';
		if (loggedIn) {
			navigate(route);
		} else {
			dispatch(toggleAuthModal());
			localStorage.setItem('nextRoute', route);
		}
	};

	return (
		<div>
			{/* NAVBAR */}
			{/* <Navbar /> */}
			<SEO>
				<title>Premier Agent Basic: Top Marketing for Real Estate Agents</title>
				<meta name='og:title' content='Premier Agent Basic: Top Marketing for Real Estate Agents' />
				<meta
					name='description'
					content='Boost your real estate business with Premier Agent Basic - Choose from Individual or Combo Package for comprehensive advertising solutions, custom website and digital business card, and Deal Analyzer platform.'
				/>
				<meta
					name='og:description'
					content='Boost your real estate business with Premier Agent Basic - Choose from Individual or Combo Package for comprehensive advertising solutions, custom website and digital business card, and Deal Analyzer platform.'
				/>
				<meta name='og:image' content={window.location.origin + AdPremier} />
			</SEO>

			<Box>
				{/* BANNER */}
				<AdBanner title='Premier Agent Basic does Everything You Need for Online Advertisement.' bgImage={AdPremier} titleColor={'#000'}>
					<AdPrice
						title='Individual Package'
						subHeader='Purchase Only the Premier Agent Basics to enjoy all Premier Agent Features'
						price='$199'
						interval={'per month'}
						onClick={() => gotoPremier()}
					/>
				</AdBanner>

				<Box padding={'4rem 2rem'} textAlign='center' color={'#000'}>
					<Container>
						<Box>
							<Typography variant='h5' gutterBottom>
								What's the catch?
							</Typography>
						</Box>

						<Box>
							{sections.map((el, index) => {
								return <GridSection key={index} index={index} title={el.title} copy={el.copy} img={el.img} />;
							})}
						</Box>

						<Box padding='2rem 0'>
							<Box textAlign={'center'} marginBottom='2rem'>
								<Typography variant='h5' style={{ fontWeight: 600 }}>
									Also Included
								</Typography>
							</Box>
							<Grid container spacing={3} className={classes.gridWrapper}>
								<Grid item lg={6} md={6} sm={12} className={classes.gridItem}>
									<Card variant='outlined' className={classes.grid}>
										<CardContent style={{ padding: '2rem' }}>
											<Typography variant='body2' paragraph>
												We Build you a personal custom BPO Report Agent Ordering Page
											</Typography>
										</CardContent>
									</Card>
								</Grid>
								<Grid item lg={6} md={6} sm={12} className={classes.gridItem}>
									<Card variant='outlined' className={classes.grid}>
										<CardContent style={{ padding: '2rem' }}>
											<Typography variant='body2' paragraph>
												You earn $100.00 per BPO Report Order. Paying for all your marketing with only 1 order per month!
											</Typography>
										</CardContent>
									</Card>
								</Grid>
							</Grid>
						</Box>
					</Container>
				</Box>

				<Box
					style={{
						backgroundImage: `linear-gradient(#1378A595, #1378A595), url(${AdPremier})`,
						backgroundSize: 'cover',
						padding: '3rem',
						marginTop: '2rem',
					}}
				>
					<Container>
						<Box textAlign={'center'} color='#fff'>
							<Typography variant='h6'>Premier Agent Basics Package</Typography>
							<Divider style={{ margin: '1rem auto', width: '4rem', height: '2px', background: '#fff' }} />
							<Typography>Purchase Only the Premier Agent Basics to enjoy all Premier Agent Features</Typography>
							<KeyboardArrowDownIcon />
							<Typography variant='h6' gutterBottom>
								$199 <span style={{ fontSize: '12px' }}>per month</span>
							</Typography>

							<Button
								variant='contained'
								disableElevation
								color='primary'
								style={{ width: '15rem', textTransform: 'none', marginTop: '1rem', color: '#fff' }}
								onClick={() => gotoPremier()}
							>
								Subscribe
							</Button>
						</Box>
						{/* <Box textAlign={'center'} color='#fff' width='80%' margin='0 auto'>
							<Typography variant='h6'>Premier Agent Basics Package</Typography>
							<Divider style={{ margin: '1rem auto', marginBottom: '2rem', width: '4rem', height: '2px', background: '#fff' }} />

							<Grid container spacing={6} style={{ margin: 0, width: '100%' }}>
								<Grid item lg={6} md={6} sm={12} style={{ padding: '2rem' }}>
									<Typography variant='h6' gutterBottom>
										Individual Package
									</Typography>
									<KeyboardArrowDownIcon />
									<Typography variant='body2' paragraph>
										Purchase Only the Premier Agent Basics to enjoy all Premier Agent Features
									</Typography>
									<Typography variant='h6' gutterBottom>
										$199 <span style={{ fontSize: '12px' }}>per month</span>
									</Typography>
									<Button
										variant='contained'
										disableElevation
										color='primary'
										style={{ width: '15rem', textTransform: 'none', marginTop: '1rem', color: '#fff' }}
										onClick={() => gotoPremier()}
									>
										Subscribe
									</Button>
								</Grid>
							</Grid>
						</Box> */}
					</Container>
				</Box>
			</Box>
		</div>
	);
};

export default AdvertisePremier;
