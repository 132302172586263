
import { useState } from 'react'
import base from '../../../../apis'
import { useSelector } from 'react-redux'

function useCompleteReport() {
    const analyzerData = useSelector(state=>state?.straightAestheticRemodel)
    const typeId = useSelector(state=>state?.straightAestheticRemodel?.dealAnalyzerReportId?.analyzerTypeId)
    let reportId = analyzerData?.dealAnalyzerReportId?.id



    const [loading,setLoading] = useState()
    const [error,setError] = useState()
    const dealReports = useSelector((state) => state.reports)


    const completeReport = async()=>{
        setLoading(true)
        try{
             await base.post(`deal_analyzer/complete_report?id=${reportId}&report_type=${typeId}`)

        }catch(err){
            setError("An error occured!")
        }finally{
            setLoading(false)
        }
    }
  return {reportError:error, loading, dealReports,completeReport}
}
export default useCompleteReport

