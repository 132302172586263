import { ArrowBack, ArrowForward, ChevronLeft, ChevronRight, Close, RoomOutlined, SquareFoot } from '@mui/icons-material';
import BedIcon from '@mui/icons-material/Bed';
import {
	Box,
	Button,
	Card,
	CardActions,
	CardContent,
	CardMedia,
	Chip,
	Link as DLink,
	Dialog,
	DialogContent,
	Divider,
	Grid,
	IconButton,
	Pagination,
	PaginationItem,
	Stack,
	TextField,
	ThemeProvider,
	Typography,
	createTheme,
} from '@mui/material';
import { makeStyles, styled } from '@mui/styles';
import React, { useState } from 'react';
import { BiBath } from 'react-icons/bi';
import { Link as RLink, useParams } from 'react-router-dom';
import { ReactComponent as FBIcon } from '../../images/facebookfillable.svg';
import { ReactComponent as IGIcon } from '../../images/instagramfillable.svg';
import { ReactComponent as LIIcon } from '../../images/linkedInfillable.svg';
import BG from '../../images/temp2bg.webp';
import { ReactComponent as TWIcon } from '../../images/twitterfillable.svg';
import { ReactComponent as YTIcon } from '../../images/youtubefillable.svg';
import { theme } from '../../theme';
import { formatNumber, formatPhoneNumber, getYoutubeEmbedLink, hideButton, scrollToTheLeft } from '../../utils/functions';
import './Temp1/temp1.scss';
import ServiceAreasSlider from '../../components/ServiceAreasSlider/ServiceAreasSlider';
import InvestorQuestionnaires from '../../components/InvestorQuestionnaires/InvestorQuestionnaires';

const Link = styled(DLink)(({ theme }) => ({
	cursor: 'pointer',
}));

const useStyles = makeStyles((theme) => ({
	root: {
		fontFamily: 'Shippori Mincho, serif',
		scrollMarginTop: '100px',
	},
	headerImage: {
		backgroundImage: `linear-gradient(100.07deg, rgba(0, 0, 0, 0.2) 6.1%, rgba(0, 0, 0, 0) 72.15%),url(${BG})`,
		height: '80vh',
		backgroundPosition: 'center',
		display: 'flex',
		alignItems: 'center',
		padding: '2rem',
		justifyContent: 'space-between',
	},
	innerBox: {
		backgroundColor: '#040F2ED9',
		width: '400px',
		color: 'white',
		padding: '1rem',
	},
	headerText: {
		color: '#122c77',
		textTransform: 'uppercase',
		fontFamily: 'Roboto, sans-serif',
		fontSize: '14px',
	},
	title: {
		fontSize: '25px',
		fontWeight: 'bold',
	},
	four: {
		width: '100%',
		backgroundSize: 'cover',
		backgroundPosition: 'center',
		aspectRatio: '21/9',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-end',
		gap: '1rem',
		'&>*': {
			color: 'white',
		},
	},
	videoHolder: {
		height: '100vh',
		background: `linear-gradient(to bottom, #0b1638 70%, white 70%, white 100%)`,
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		padding: '2rem',
		alignItems: 'center',
		marginTop: '2rem',
	},
	navroot: {
		boxShadow: '0px 4px 10px 0px #00000026', // Add box shadow
		position: 'sticky',
		top: 0,
		zIndex: 100,
		fontFamily: 'Roboto, sans-serif',
	},
	container: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: '1rem', // Add padding
		backgroundColor: 'white', // Set background color
		color: 'black', // Set text color
	},
	leftLinks: {
		display: 'flex',
		alignItems: 'center',
		fontFamily: 'Roboto, sans-serif',
		color: 'black',
	},
	link: {
		marginLeft: theme.spacing(2), // Add margin between links
		cursor: 'pointer', // Set cursor to pointer
		fontFamily: 'Roboto, sans-serif',
		fontWeight: '400',
		fontSize: '16px',
	},
	agentName: {
		marginRight: theme.spacing(2), // Add margin before agent name
		fontFamily: 'Roboto, sans-serif',
		fontWeight: '400',
		fontSize: '16px',
	},
	icons: {
		filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
	},
}));

const pageTheme = createTheme({
	...theme,
	typography: {
		fontFamily: 'Shippori Mincho, serif',
		button: {
			textTransform: 'capitalize',
		},
	},
	palette: {
		primary: {
			main: '#122c77',
		},
		secondary: {
			main: '#FFAA01',
		},
		text: {
			main: '#000',
		},
		paper: {
			main: '#fff',
		},
	},
	primaryColor: '#122c77',
	secondaryColor: '#FFAA01',
});
const UnThemedButton = styled(Button)(({ theme }) => ({
	fontFamily: 'Roboto, san-serif !important',
}));

function NewTemp2({ agent, listings, testimonials, contactDetails, updateContactDetails, sendMessage, serviceAreas }) {
	const classes = useStyles();
	const [dialogOpen, setDialogOpen] = React.useState(false);
	const name = agent?.user.first_name + ' ' + agent?.user.last_name;
	const agent_id = agent?.id;
	const member_since = agent?.year_work_commenced;
	const agent_phone = formatPhoneNumber(agent?.phone);
	const brokerage_phone = formatPhoneNumber(agent?.brokerage?.brokerage_phone);
	const brokerage_name = agent?.brokerage_name;
	const license = agent?.licenses.find((license) => license.category === 'real_estate_agent')?.number;
	const address = agent?.address;
	const video = agent?.video_link;
	const [page, setPage] = useState(1);
	const showInvestorForm = agent?.questionnaires_on;

	return (
		<ThemeProvider theme={pageTheme}>
			<Nav name={name} active={listings.active.length} showInvestor={showInvestorForm} />
			<Dialog open={dialogOpen} fullWidth maxWidth='md' onClose={() => setDialogOpen(false)}>
				<DialogContent dividers sx={{ position: 'relative' }}>
					<IconButton sx={{ position: 'absolute', top: '5%', right: '5%' }} onClick={() => setDialogOpen(false)}>
						<Close fontSize='small' />
					</IconButton>
					<Grid container spacing={2} mt={2}>
						<Grid item xs={7}>
							<Typography variant='body1' fontWeight={300} fontSize='15px'>
								For more information or inquires, use this form to contact me.
							</Typography>
							<Typography variant='h5' mb={3} fontFamily='Roboto' fontSize='35px' fontWeight={700}>
								Get In Touch
							</Typography>
							<Grid container spacing={2} mb='1rem'>
								<Grid item xs={12}>
									<TextField
										fullWidth
										size='small'
										variant='outlined'
										placeholder='Full name'
										color='primary'
										onChange={updateContactDetails}
										value={contactDetails?.name}
										name='name'
									/>
								</Grid>
								<Grid item xs={6}>
									<TextField
										fullWidth
										size='small'
										variant='outlined'
										placeholder='Email'
										color='primary'
										onChange={updateContactDetails}
										value={contactDetails?.email}
										name='email'
									/>
								</Grid>
								<Grid item xs={6}>
									<TextField
										fullWidth
										size='small'
										variant='outlined'
										placeholder='Phone Number'
										color='primary'
										onChange={updateContactDetails}
										value={contactDetails?.phone}
										name='phone'
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField
										multiline
										rows={4}
										fullWidth
										size='small'
										variant='outlined'
										placeholder='Message'
										color='primary'
										value={contactDetails?.message}
										onChange={updateContactDetails}
										name='message'
									/>
								</Grid>
							</Grid>
							<Button variant='outlined' color='secondary' sx={{ color: 'black', borderColor: 'black' }} onClick={sendMessage}>
								Let's connect
							</Button>
						</Grid>
						<Grid item xs={5}>
							<Typography variant='h6' fontWeight='700' mb='2rem' fontFamily='Roboto' fontSize='35px'>
								{name}
							</Typography>
							<Typography variant='body1' fontSize='14px'>
								{address}
							</Typography>
							<Typography variant='body1' mb={'1.5rem'} fontSize='14px'>
								{agent?.user?.email}
							</Typography>
							<Typography variant='body1' fontSize='14px'>
								Cell Phone:{agent_phone}
							</Typography>
							<Typography variant='body1' mb={'1.5rem'} fontSize='14px'>
								Brokerage: {brokerage_name} {brokerage_phone ? `(${brokerage_phone})` : ''}
							</Typography>
							<Typography variant='body1' fontSize='14px'>
								Member Since:{member_since}
							</Typography>
							<Typography variant='body1' mb={'1.5rem'} fontSize='14px'>
								Real Estate License: {license}
							</Typography>
							<Stack direction='row' spacing={3} mt='1rem' gap='10px'>
								{agent?.facebook && (
									<IconButton target='blank' href={agent?.facebook}>
										<FBIcon fill='black' />
									</IconButton>
								)}
								{agent?.twitter && (
									<IconButton target='blank' href={agent?.twitter}>
										<TWIcon fill='black' />
									</IconButton>
								)}
								{agent?.instagram && (
									<IconButton target='blank' href={agent?.instagram}>
										<IGIcon fill='black' />
									</IconButton>
								)}
								{agent?.linkedin && (
									<IconButton target='blank' href={agent?.linkedin}>
										<LIIcon fill='black' />
									</IconButton>
								)}
								{agent?.video_link && (
									<IconButton target='blank' href={agent?.video_link}>
										<YTIcon fill='black' />
									</IconButton>
								)}
							</Stack>
						</Grid>
					</Grid>
				</DialogContent>
			</Dialog>
			<Box className={classes.headerImage}>
				<Box className={classes.innerBox}>
					{agent?.landing_page_title && (
						<Typography mb='2rem' whiteSpace={'pre-line'}>
							{agent?.landing_page_title || 'Want to sell/ buy a property'}
						</Typography>
					)}
					<Button variant='outlined' color='secondary' onClick={() => setDialogOpen(true)}>
						Let's connect
					</Button>
				</Box>
				<Stack direction='column' gap={2} className={classes.icons}>
					{agent?.facebook && (
						<IconButton target='blank' href={agent?.facebook}>
							<FBIcon fill='white' />
						</IconButton>
					)}
					{agent?.twitter && (
						<IconButton target='blank' href={agent?.twitter}>
							<TWIcon fill='white' />
						</IconButton>
					)}
					{agent?.instagram && (
						<IconButton target='blank' href={agent?.instagram}>
							<IGIcon fill='white' />
						</IconButton>
					)}
					{agent?.linkedin && (
						<IconButton target='blank' href={agent?.linkedin}>
							<LIIcon fill='white' />
						</IconButton>
					)}
					{agent?.video_link && (
						<IconButton target='blank' href={agent?.video_link}>
							<YTIcon fill='white' />
						</IconButton>
					)}
				</Stack>
			</Box>
			{listings.active.length > 0 && (
				<Box mt='2rem'>
					<Typography variant='body1' textTransform={'uppercase'} textAlign='center' color='#122C77' fontFamily='Roboto,sans-serif'>
						Properties
					</Typography>
					<Typography variant='h6' textAlign='center' fontWeight={700} fontSize='25px'>
						Active Properties
					</Typography>
					<Box position='relative'>
						<IconButton
							ref={(el) => hideButton(el, 'active')}
							sx={{
								position: 'absolute',
								top: '40%',
								left: '0',
								backgroundColor: 'white',
								borderRadius: '2px',
								'&:hover': { backgroundColor: 'white' },
								boxShadow: '0px 0px 10px 0px #00000026',
							}}
							onClick={() => scrollToTheLeft('active', true)}
						>
							<ChevronLeft fontSize='large' />
						</IconButton>
						<Box
							p={1}
							padding={'2rem 5rem'}
							display='flex'
							gap='20px'
							id='active'
							sx={{
								maxWidth: '100%',
								overflowX: 'scroll',
								'&::-webkit-scrollbar': {
									display: 'none',
								},
								scrollBehavior: 'smooth',
							}}
						>
							{listings.active.map((listing) => (
								<ListingCard agentId={agent_id} {...listing} key={listing.id} />
							))}
						</Box>
						<IconButton
							ref={(el) => hideButton(el, 'active')}
							sx={{
								position: 'absolute',
								top: '40%',
								right: '0',
								backgroundColor: 'white',
								borderRadius: '2px',
								'&:hover': { backgroundColor: 'white' },
								boxShadow: '0px 0px 10px 0px #00000026',
							}}
							onClick={() => scrollToTheLeft('active', false)}
						>
							<ChevronRight fontSize='large' />
						</IconButton>
					</Box>
				</Box>
			)}
			<Grid container spacing={2} alignItems='center' padding={'2rem 5rem'} id='about'>
				<Grid item xs={8}>
					<Typography className={classes.headerText} mb={2}>
						About
					</Typography>
					<Typography mb={2} className={classes.title}>
						Meet {name}
					</Typography>
					<Typography fontFamily={'Roboto, sans-serif'} textAlign='justify' fontWeight={300} whiteSpace={'pre-line'}>
						{agent?.bio}
					</Typography>
					<Box display='flex' gap='10px' mt={2}>
						<UnThemedButton color='primary' variant='outlined' href={`/order-bpo-report/${agent_id}`} target='_blank'>
							Get A BPO Report
						</UnThemedButton>
						<UnThemedButton color='primary' variant='contained' onClick={() => setDialogOpen(true)}>
							Let's Connect
						</UnThemedButton>
					</Box>
				</Grid>
				<Grid item xs={4}>
					<img
						src={agent?.profile_image}
						alt='Agent'
						style={{
							width: '400px',
							aspectRatio: '3/4',
							objectFit: 'cover',
							objectPosition: 'top center',
						}}
					/>
				</Grid>
			</Grid>
			{showInvestorForm && (
				<Box padding={'2rem 5rem'}>
					<InvestorQuestionnaires agent={agent} />
				</Box>
			)}
			{listings.sold.length > 0 && (
				<>
					<Typography variant='body1' textTransform={'uppercase'} textAlign='center' color='#122C77' fontFamily='Roboto,sans-serif'>
						Properties
					</Typography>
					<Typography variant='h6' textAlign='center' fontWeight={700} fontSize='25px' mb='1rem'>
						Sold Properties
					</Typography>
					<Grid container spacing={0}>
						{listings.sold?.slice((page - 1) * 4, (page - 1) * 4 + 4).map((listing) => (
							<Grid
								item
								key={listing.id}
								xs={6}
								sx={{
									backgroundImage: `linear-gradient(rgba(0,0,0,0.2), rgba(0,0,0,0.2)),url(${listing?.photos?.[0]?.image})`,
									width: '100%',
									cursor: 'pointer',
								}}
								className={classes.four}
								component={RLink}
								to={`/view-listing/${agent_id}/${listing.id}`}
								target='_blank'
							>
								<ImageCardBlock {...listing} agentId={agent_id} />
							</Grid>
						))}
					</Grid>
					<Box display='flex' justifyContent={'center'} my={3}>
						<Pagination
							count={Math.ceil(listings.sold.length / 4)}
							color='primary'
							renderItem={(item) => <PaginationItem slots={{ previous: ArrowBack, next: ArrowForward }} {...item} />}
							onChange={(e, page) => {
								setPage(page);
							}}
						/>
					</Box>
				</>
			)}
			{video && (
				<Box className={classes.videoHolder}>
					<Typography className={classes.headerText} sx={{ color: 'white !important' }} mb={2}>
						Video Spotlight
					</Typography>
					<iframe
						style={{
							aspectRatio: '16/9',
							borderRadius: '5px',
							border: 'none',
							width: '70%',
						}}
						src={`https://www.youtube.com/embed/${getYoutubeEmbedLink(video)}`}
						title='Client video'
						allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
						allowFullScreen
					></iframe>
				</Box>
			)}
			{testimonials.length > 0 && (
				<Stack mt='3rem'>
					<Divider sx={{ margin: 'auto', width: 'auto', color: 'grey', height: '1px' }} />
					<Typography variant='body1' textTransform={'uppercase'} textAlign='center' color='#122C77' fontFamily='Roboto,sans-serif'>
						Testimonials
					</Typography>
					<Stack direction='row' justifyContent='center' alignItems='center' spacing={2} padding={'2rem 5rem'}>
						{testimonials.map((testimonial) => (
							<Review key={testimonial.id} {...testimonial} />
						))}
					</Stack>
				</Stack>
			)}
			{serviceAreas.length > 0 && (
				<Box mt={2}>
					<ServiceAreasSlider serviceAreas={serviceAreas} />
				</Box>
			)}
		</ThemeProvider>
	);
}

export default NewTemp2;

const ListingCard = ({ id, agentId, list_price, photos, beds, baths, square_footage, address }) => {
	return (
		<Link component={RLink} to={`/view-listing/${agentId}/${id}`} target='_blank'>
			<Card sx={{ maxWidth: '300px', minWidth: '300px', boxShadow: '0px 4px 10px 0px #00000026' }}>
				<CardMedia sx={{ height: 180 }} image={photos?.[0]?.image} title='property' />
				<CardContent>
					<Typography
						variant='body2'
						color='text.secondary'
						display={'flex'}
						alignItems='center'
						fontFamily='Roboto,sans-serif'
						fontWeight={300}
						mb={1}
					>
						<RoomOutlined fontSize='small' />
						{address}
					</Typography>
					<Chip label={`$ ${formatNumber(list_price, 0)}`} color='secondary' sx={{ fontFamily: 'Roboto,sans-serif', backgroundColor: '#FEC82F' }} />
				</CardContent>
				<CardActions sx={{ borderTop: '1px solid #e5e5e5' }}>
					<Box alignItems='center' justifyContent='space-between' display='flex' fontFamily='Roboto,sans-serif' mb={1} width='100%'>
						<Box display='flex' justifyContent='center' gap='10px'>
							<BedIcon fontSize='small' color='disabled' />
							<Typography variant='body2' color='grey' fontFamily='inherit' fontWeight={300}>
								{beds} beds
							</Typography>
						</Box>
						<Box display='flex' justifyContent='center' gap='10px'>
							<BiBath color='grey' />
							<Typography variant='body2' color='grey' fontFamily='inherit' fontWeight={300}>
								{baths} baths
							</Typography>
						</Box>
						<Box display='flex' justifyContent='center' gap='10px'>
							<SquareFoot fontSize='small' color='disabled' />
							<Typography variant='body2' color='grey' fontFamily='inherit' fontWeight={300}>
								{formatNumber(square_footage)} sqft
							</Typography>
						</Box>
					</Box>
				</CardActions>
			</Card>
		</Link>
	);
};

const Nav = ({ name, active, showInvestor }) => {
	const { id: agentId } = useParams();

	const classes = useStyles();
	return (
		<Box className={classes.navroot}>
			<Box className={classes.container}>
				<Box className={classes.leftLinks}>
					{/* Left side with 3 anchor links */}
					{active > 0 && (
						<Link href='#active' className={classes.link} underline='none'>
							Active Listings
						</Link>
					)}
					<Link href='#about' className={classes.link} underline='none'>
						About
					</Link>
					<Link href='#contact' className={classes.link} underline='none'>
						Contact
					</Link>
					<Link href={`/agent/${agentId}/blogs`} className={classes.link} underline='none'>
						Blogs
					</Link>
					{showInvestor && (
						<Link href='#investors' className={classes.link} underline='none'>
							Investors
						</Link>
					)}
				</Box>
				{/* Right side with agent name */}
				<Typography variant='h6' className={classes.agentName}>
					Agent:{name}
				</Typography>
			</Box>
		</Box>
	);
};

const ImageCardBlock = ({ id, agentId, list_price, photos, beds, baths, square_footage, address }) => (
	<Link component={RLink} to={`/view-listing/${agentId}/${id}`} target='_blank'>
		<Box pl={'1rem'}>
			<Typography variant='body2' color='white' fontFamily='Roboto,sans-serif'>
				$ {formatNumber(list_price, 0)}
			</Typography>
			<Typography variant='h5' color='white' my={1}>
				{address}
			</Typography>
			<Box alignItems='center' display='flex' gap={'1rem'} fontFamily='Roboto,sans-serif' mb={2}>
				<Box display='flex' justifyContent='center' gap='10px'>
					<BedIcon fontSize='small' />
					<Typography variant='body2' color='white'>
						{beds} beds
					</Typography>
				</Box>
				<Box display='flex' justifyContent='center' gap='10px'>
					<BiBath />
					<Typography variant='body2' color='white'>
						{baths} baths
					</Typography>
				</Box>
				<Box display='flex' justifyContent='center' gap='10px'>
					<SquareFoot fontSize='small' />
					<Typography variant='body2' color='white'>
						{formatNumber(square_footage, 0)} sqft
					</Typography>
				</Box>
			</Box>
		</Box>
	</Link>
);

const Review = ({ description, name, client }) => {
	return (
		<Stack spacing={2} alignItems='center' flex='1'>
			<Typography variant='body1' textAlign='center' fontFamily='Shippori Mincho, serif'>
				{description}
			</Typography>
			<Typography variant='body1' textAlign='center' fontWeight='700' fontSize='18px' mt='auto !important'>
				{client?.user?.first_name || client?.user?.last_name ? client?.user?.first_name + ' ' + client?.user?.last_name : name}
			</Typography>
		</Stack>
	);
};
