import { useState } from 'react';
import './addnewlisting.scss';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button/Button';
import Input from '../../components/Input/Input';
import { statesShort as states } from '../../constants';
import base from '../../apis';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import Spinner from '../../components/Spinner/Spinner';
import PlacesSearch from '../../components/PlacesSearch/PlacesSearch';
import commaSeparate from '../../components/DealAnalyzerComponents/utils/commaSeparate';
import { Button as MUIButton } from '@mui/material';

const representationOptions = [
	{
		value: 'Seller Agent',
		label: 'Seller Agent',
	},
	{
		value: 'Buyer Agent',
		label: 'Buyer Agent',
	},
];

export default function AddNewListing() {
	const navigate = useNavigate();

	const [listingDetails, setListingDetails] = useState({
		address: '',
		city: '',
		state: {},
		zip: '',
		lot: '',
		square_footage: '',
		beds: '',
		baths: '',
		year_built: '',
		list_price: '',
		unit: '',
		mls_num: '',
		agent_role: {
			value: 'Seller Agent',
			label: 'Seller Agent',
		},
		list_date: '',
	});

	const handleChange = (e) => {
		const { name, value } = e.target;
		setListingDetails({
			...listingDetails,
			[name]: name === 'list_price' ? commaReplace(value) : value,
		});
	};

	const [isLoading, setIsLoading] = useState(false);
	const agent_id = useSelector((state) => state.auth.typeId);

	const commaReplace = (value) => {
		const valueString = value.toString().replace(/[^\d]/g, '');
		return commaSeparate(valueString);
	};

	const selectStyles = {
		container: (provided) => ({
			...provided,
			width: '100%',
			marginTop: '30px',
			margin: '0px',
			borderRadius: '20px',
			// border: "0.1px light #0f0f0feb",
		}),
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		if (!listingDetails.state.value) {
			toast.warn('Please select a state.');
			return;
		}
		setIsLoading(true);
		const body = {
			...listingDetails,
			state: listingDetails.state.label,
			list_price: +listingDetails.list_price.replace(/,/g, ''),
			baths: +listingDetails.baths,
			beds: +listingDetails.beds,
			square_footage: +listingDetails.square_footage,
			unit: listingDetails.unit ? +listingDetails.unit : null,
			agent_id,
			agent_role: listingDetails.agent_role.value,
		};
		if (!body.list_date) {
			delete body.list_date;
		}
		base
			.post('/premiersite/listings/', body)
			.then((res) => {
				toast.success('Listing added successfully.');
				setListingDetails({
					address: '',
					city: '',
					state: null,
					zip: '',
					lot: '',
					square_footage: '',
					beds: '',
					baths: '',
					year_built: '',
					list_price: '',
					unit: '',
					mls_num: '',
					represented_by: null,
				});
			})
			.catch((err) => {
				toast.error('Could not create listing');
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const populateCity = (e) => {
		const city = e.target.value;
		const state = city?.split(', ')[2];
		const foundState = states.find((stateObj) => stateObj.value === state);
		if (city) {
			setListingDetails({
				...listingDetails,
				state: foundState,
				city: city?.split(', ')[1] || '',
			});
		}
	};

	return (
		<>
			{isLoading && <Spinner />}
			<div className='add-new-listing'>
				<div style={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
					<MUIButton sx={{ textTransform: 'capitalize' }} onClick={() => navigate('../')} variant='outlined' color='secondary'>
						Back
					</MUIButton>
				</div>
				<h3 className='headUnder'>Property Details</h3>
				<form onSubmit={handleSubmit}>
					<div className='add-new-listing_grid'>
						<div className='long'>
							<PlacesSearch
								type='text'
								onBlur={populateCity}
								placeholder='Enter address'
								label='Address'
								required
								fullStyles={{ width: '100%' }}
								value={listingDetails.address}
								name='address'
								onChange={(e) => {
									setListingDetails({ ...listingDetails, address: e });
								}}
							/>
						</div>
						<div className='short'>
							<Input
								type='text'
								placeholder='Enter unit'
								label='Unit'
								name='unit'
								fullStyles={{ width: '100%' }}
								value={listingDetails.unit}
								onChange={handleChange}
							/>
						</div>
						<div className='short'>
							<Input
								type='text'
								placeholder='Enter city'
								label='City'
								name='city'
								fullStyles={{ width: '100%' }}
								onChange={handleChange}
								value={listingDetails.city}
							/>
						</div>
						<div className='short'>
							{/* <div> */}
							<label htmlFor='state' style={{ display: 'block', marginBottom: '10px' }}>
								State
							</label>
							<Select
								placeholder='Select State'
								options={states}
								id='state'
								onChange={(e) => {
									setListingDetails((cur) => ({ ...cur, state: e }));
								}}
								styles={selectStyles}
								value={listingDetails.state}
							/>
							{/* </div> */}
						</div>
						<div className='short'>
							<Input
								type='text'
								placeholder='Enter zip/postal'
								label='Zip/Postal'
								name='zip'
								fullStyles={{ width: '100%' }}
								onChange={handleChange}
								value={listingDetails.zip}
							/>
						</div>
						<div>
							<Input
								placeholder='Enter lot size'
								label='Lot Size'
								name='lot'
								fullStyles={{ width: '100%' }}
								onChange={handleChange}
								value={listingDetails.lot}
							/>
						</div>
						<div>
							<Input
								placeholder='Enter square feet'
								label='Square Feet'
								name='square_footage'
								type='number'
								step={100}
								fullStyles={{ width: '100%' }}
								onChange={handleChange}
								value={listingDetails.square_footage}
							/>
						</div>
						<div>
							<Input
								placeholder='Enter bedrooms'
								label='Bedrooms'
								name='beds'
								fullStyles={{ width: '100%' }}
								type='number'
								min='1'
								onChange={handleChange}
								value={listingDetails.beds}
							/>
						</div>
						<div>
							<Input
								placeholder='Enter bathrooms'
								label='Bathrooms'
								name='baths'
								fullStyles={{ width: '100%' }}
								onChange={handleChange}
								type='number'
								value={listingDetails.baths}
							/>
						</div>
						<div>
							<Input
								placeholder='Enter year built'
								label='Year Built'
								name='year_built'
								fullStyles={{ width: '100%' }}
								type='number'
								maxLength='4'
								min='1800'
								max={new Date().getFullYear()}
								value={listingDetails.year_built}
								onChange={handleChange}
							/>
						</div>
						<div>
							<Input
								placeholder='Enter List date'
								label='Date Listed'
								name='list_date'
								fullStyles={{ width: '100%' }}
								type='date'
								min={new Date()}
								value={listingDetails.list_date}
								onChange={handleChange}
							/>
						</div>
						<div className='short'>
							<Input
								placeholder='Enter list price'
								label='List Price'
								name='list_price'
								fullStyles={{ width: '100%' }}
								type='text'
								// step={10000}
								onChange={handleChange}
								value={listingDetails.list_price}
							/>
						</div>
						<div className='short'>
							<Input
								placeholder='Enter mls number'
								label='MLS number'
								name='mls_num'
								fullStyles={{ width: '100%' }}
								value={listingDetails.mls_num}
								onChange={handleChange}
							/>
						</div>
						<div className='short'>
							<label htmlFor='agent_role' style={{ display: 'block', marginBottom: '10px' }}>
								Representation
							</label>
							<Select
								placeholder='Select Representation'
								options={representationOptions}
								id='agent_role'
								onChange={(e) => {
									setListingDetails((cur) => ({ ...cur, agent_role: e }));
								}}
								styles={selectStyles}
								isSearchable={false}
								value={listingDetails.agent_role}
							/>
						</div>
					</div>
					<Button
						// onClick={() => navigate('/premier-agent-website/view-listings/')}
						style={{
							width: 'fit-content',
							height: '45px',
							fontSize: '14px',
							padding: '10px 30px',
							color: 'white',
							backgroundColor: 'var(--primary-color)',
							border: '1px solid var(--primary-color)',
							display: 'block',
							marginTop: '20px',
							marginLeft: 'auto',
							marginRight: '10%',
						}}
						text='Submit'
						type='submit'
					/>
				</form>
			</div>
		</>
	);
}
