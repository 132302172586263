import { Box, Container } from "@mui/material";
import React, { useRef } from "react";
import MuiThemeLayout from "../../../Layouts/MuiThemeLayout";
import ProFormaReportWeb from "../../../components/DealAnalyzerComponents/ProFormaReport/ProFormaReportWeb";
import ProFormaReportPrint from "../../../components/DealAnalyzerComponents/ProFormaReport/ProFormaReportPrint";
import { useReactToPrint } from "react-to-print";
import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import { summaryTheme } from "./summaryUtils";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import MuiThemeLayoutPDF from "../../../Layouts/MuiThemeLayoutPDF";
import { createDocumentTitle } from "./utils";
import { useSearchParams } from "react-router-dom";
// import useDownloadPdf from "./ViewHooks/useDownloadPdf";

let theme = createTheme({
  palette: {
    primary: {
      main: "#1378A5",
      inactive: "#E6E6E6",
    },
  },
  typography: {
    fontFamily: ["Lato", "Roboto", "Helvetica"].join(","),
    htmlFontSize: 18,
    fontSize: 8,
    ...summaryTheme,
  },
});
theme = responsiveFontSizes(theme);

function ViewStraightAestheticRemodelReport({type}) {
  const componentRef = useRef(null);
  const navigate = useNavigate();
  const [currentTitle, setCurrentTitle] = React.useState();
  const data = useSelector((state) => state.straightAestheticRemodel);
  const [searchParams] = useSearchParams()
  const status = searchParams.get("status")
  const pageStyle = `
    @page {
      size: A4 ;   
    }
  
  `;
  // const {handleGeneratePdf} = useDownloadPdf()
  const options = {
    // orientation: 'landscape',
    unit: "in",
    format: [4, 2],
  };
  const handlePrevious = () => {
    navigate(-1);
  };
  const title = createDocumentTitle(data?.dealAnalyzerResults?.user);
  const onBeforePrint = () => {
    setCurrentTitle(Document.title);
    Document.title = title;
  };
  const onAfterPrint = () => {
    Document.title = currentTitle;
  };

  const getType = (typeInput)=>{

    switch(+typeInput){
      case 1: return "Straight Aesthetic Remodel";
      case 2: return "Remodel & Add Addition";
      case 3: return "Remodel Down to Studs";
      case 4 : return "Remodel Down to Studs & Add Addition";
      case 5 : return "New Construction";
      case 6: return "Buy and Rent";
      default : return ""
    }
  }

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    options: options,
    pageStyle: pageStyle,
    documentTitle: title,
    onBeforePrint: onBeforePrint,
    onAfterPrint: onAfterPrint,
  });
  return (
    <MuiThemeLayout selfTheme={theme}>
      <Box>
        {
          <>
            <Container maxWidth="xl">
              <Box sx={{ display: "block", displayPrint: "none" }}>
                <ProFormaReportWeb
                  previous={handlePrevious}
                  dealType= {getType(type)}
                  print={handlePrint}
                  address={data?.propertyInformation?.property_address}
                  buttonProps={status}
                />
              </Box>
              <div ref={componentRef}>
                <MuiThemeLayoutPDF>
                  <ProFormaReportPrint
                    previous={handlePrevious}
                    dealType= {getType(type)}
                  />
                </MuiThemeLayoutPDF>
                </div>
            </Container>
          </>
        }
      </Box>
    </MuiThemeLayout>
  );
}

export default ViewStraightAestheticRemodelReport;
