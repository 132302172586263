import { ThemeProvider } from '@emotion/react';
import React, { Fragment } from 'react';
import { theme } from '../theme';
import { Avatar, createTheme, Grid, IconButton, Stack, Typography, Link as NLink } from '@mui/material';
import { makeStyles, styled } from '@mui/styles';
import { Link as RLink } from 'react-router-dom';
import BG from '../images/indProfTemp3/mainBG.jpg';
import WBig from '../images/indProfTemp3/workerBig.jpg';
import WLil from '../images/indProfTemp3/workerLil.jpg';
import Man1 from '../images/indProfTemp3/man1.jpg';
import Man2 from '../images/indProfTemp3/man2.jpg';
import Man3 from '../images/indProfTemp3/man3.jpg';
import Serv1 from '../images/indProfTemp3/serv1.jpg';
import Serv2 from '../images/indProfTemp3/serv2.jpg';
import Serv3 from '../images/indProfTemp3/serv3.jpg';
import { ReactComponent as FBIcon } from '../images/facebookfillable.svg';
import { ReactComponent as IGIcon } from '../images/instagramfillable.svg';
import { ReactComponent as LIIcon } from '../images/linkedInfillable.svg';
import { ReactComponent as YTIcon } from '../images/youtubefillable.svg';
import { ReactComponent as TWIcon } from '../images/twitterfillable.svg';
import RequestAQuote from '../components/RequestAQuote';

//!Electrical
// https://www.figma.com/design/Q83dnbULW6YZeYlFf9qbZX/Premier-Agent?node-id=679-16808&t=LydMWu0WNk1I0iks-4

const pageTheme = createTheme({
	...theme,
	typography: {
		fontFamily: 'Lexend, serif',
		button: {
			textTransform: 'capitalize',
		},
	},
	palette: {
		primary: {
			main: '#4646B6',
		},
		secondary: {
			main: '#000',
		},
		text: {
			main: '#000',
		},
		paper: {
			main: '#fff',
		},
	},
	primaryColor: '#4646B6',
	secondaryColor: '#000',
});

const Link = styled(RLink)(({ theme }) => ({
	cursor: 'pointer',
}));

const useStyles = makeStyles((theme) => ({
	root: {
		fontFamily: 'Lexend, serif',
		scrollMarginTop: '100px',
		minHeight: '100vh',
	},
	leftLinks: {
		display: 'flex',
		alignItems: 'center',
		color: '#FFFFFF',
		fontFamily: 'Lexend, serif',
		cursor: 'pointer',
	},
	main: {
		background: `linear-gradient(0deg, rgba(0, 29, 56, 0.35), rgba(0, 29, 56, 0.35)),url(${BG})`,
		// width: '100vw',
		padding: '1rem',
		height: '90vh',
		backgroundSize: 'cover',
		paddingLeft: '4rem',
	},
}));

const navItems = [
	{ label: 'Home', value: '#home' },
	{ label: 'Request A Quote', value: '#quote' },
	{ label: 'About Us', value: '#about-us' },
];

const grider = [
	{ text: 'Commercial', image: Man1 },
	{ text: 'Industrial', image: Man2 },
	{ text: 'Residential', image: Man3 },
];
const services = [
	{
		title: 'Electrical Service',
		text: 'Installing electrical systems, including wiring, circuits, switches, outlets, and lighting fixtures',
		image: Serv1,
	},
	{
		title: 'Safety Compliance',
		text: 'Ensuring electrical systems comply with local, state, and national safety regulations and codes.',
		image: Serv2,
	},
	{
		title: 'Specialized Services',
		text: 'Performing testing, including voltage measurements, ground fault, and insulation to ensure system integrity.',
		image: Serv3,
	},
];

const socials = [
	{
		key: 'facebook',
		Icon: <FBIcon fill='#FFFFFF' />,
	},
	{
		key: 'twitter',
		Icon: <TWIcon fill='#FFFFFF' />,
	},
	{ key: 'instagram', Icon: <IGIcon fill='#FFFFFF' /> },
	{
		key: 'linkedin',
		Icon: <LIIcon fill='#FFFFFF' />,
	},
	{
		key: 'youtube',
		Icon: <YTIcon fill='#FFFFFF' />,
	},
];

const IndProfTemp3 = ({ address, icon_file, company_name, id, formatted_phone_number, user, ...profDetails }) => {
	const classes = useStyles();
	return (
		<ThemeProvider theme={pageTheme}>
			<Stack classes={classes.root}>
				<Stack px='6rem' py='3rem' alignItems='center' justifyContent='space-between' sx={{ background: '#4646B6' }} direction='row' id='home'>
					<Stack direction='row' gap='1rem' alignItems={'center'}>
						<Avatar src={icon_file} />
						<Typography color='white' fontWeight={700} fontSize='30px'>
							{company_name}
						</Typography>
					</Stack>
					<Stack direction='row' gap='2rem'>
						{navItems.map((item) => (
							<NLink href={item.value} className={classes.leftLinks} underline='none' key={item.value}>
								{item.label}
							</NLink>
						))}
					</Stack>
				</Stack>
				<Stack className={classes.main} justifyContent={'center'} gap='1rem'>
					<Typography color='white' fontSize={75} fontWeight={700} sx={{ width: '700px', textWrap: 'balance' }}>
						Electrician here illuminating your world
					</Typography>
					<Typography color='white' fontSize={20} fontWeight={400} sx={{ width: '700px', textWrap: 'balance' }}>
						As Electrician, we pride ourselves on our unwavering commitment to quality. With a team of highly trained and certified electricians, we
						are dedicated to delivering top-notch electrical solutions for both residential and commercial clients.
					</Typography>
				</Stack>
				<Stack
					// sx={{ position: 'relative' }}
					my={'7rem'}
					alignItems='center'
					direction='row'
					padding='0 10%'
					justifyContent='center'
				>
					<Stack
						sx={{
							width: 'max(35%,500px)',
							aspectRatio: '0.9',
							position: 'relative',
						}}
					>
						<img
							src={WBig}
							alt='worker big'
							style={{
								width: '100%',
								aspectRatio: '0.9',
								objectFit: 'cover',
								borderRadius: '13px',
								// position: 'absolute', left: '20%', top: '4rem'
							}}
						/>
						<img
							src={WLil}
							alt='worker small'
							style={{ width: 'max(15%,400px)', aspectRatio: '1.65', position: 'absolute', left: '-2rem', bottom: '-5rem' }}
						/>
					</Stack>
					<Stack padding={'1rem'} sx={{ background: 'white', marginLeft: '-40px', width: 'max(40%,600px)', zIndex: 10 }} gap={1} id='about-us'>
						<Typography fontFamily={'Cormorant Garamond'} fontSize={33} fontWeight={600}>
							About Us
						</Typography>
						<Typography fontFamily='Jost' fontWeight={700} fontSize={55}>
							Outstanding Residential & Commercial Services
						</Typography>
						<Typography fontFamily='Jost' fontWeight={300} fontSize={20}>
							All of our services are backed by our 100% satisfaction guarantee. Our electricians can install anything from new security lighting for
							your outdoors to a whole home generator that will keep your appliances working during a power outage.
						</Typography>
						<ul style={{ fontFamily: 'Jost', fontWeight: 300, fontSize: 20, paddingLeft: '2rem' }}>
							<li>Full-service electrical layout, design</li>
							<li>Wiring and installation/upgrades</li>
							<li>Emergency power solutions (generators)</li>
							<li>Virtually any electrical needs you have – just ask!</li>
						</ul>
						<Typography></Typography>
					</Stack>
				</Stack>
				<Stack gap='2rem' paddingX='10%' mt='5rem'>
					<Typography color='#4646B6' fontSize={20} fontWeight={600} textAlign={'center'} textTransform='uppercase'>
						24/7 Electrician Services – Safe and Efficient
					</Typography>
					<Typography color='#0C0C1C' fontSize={60} fontWeight={600} textAlign={'center'} sx={{ width: '800px', margin: 'auto' }}>
						Powering a Sustainable Future Together
					</Typography>
					<Typography color='#8F8F9E' fontSize={20} fontWeight={400} textAlign={'center'} sx={{ width: '800px', margin: 'auto' }}>
						Join us in powering a sustainable future together, as we strive to implement eco-friendly solutions for a greener tomorrow.{' '}
					</Typography>
					<Grid container spacing={2}>
						{grider.map((item) => (
							<Grid item xs={4} key={item.text}>
								<Stack
									sx={{
										width: '100%',
										aspectRatio: '0.67',
										padding: '1rem',
										backgroundSize: 'cover !important',
										backgroundPosition: 'center !important',
										background: ` linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),url(${item.image})`,
									}}
									justifyContent={'flex-end'}
								>
									<Typography color='white' fontSize='35px' fontWeight={600}>
										{item.text}
									</Typography>
								</Stack>
							</Grid>
						))}
					</Grid>
				</Stack>
				<Stack p='10%' marginY={'10rem'} sx={{ backgroundColor: '#E4E4FF' }} gap='2rem'>
					<Typography color='#4646B6' fontSize={20} fontWeight={600} textAlign={'center'} textTransform={'uppercase'}>
						Our Services
					</Typography>
					<Typography color='#0C0C1C' fontSize={60} fontWeight={600} textAlign={'center'} sx={{ width: '800px', margin: 'auto' }}>
						Prioritize safety in all aspects of electrical work
					</Typography>

					<Grid container spacing={2}>
						{services.map((item) => (
							<Grid item xs={4} key={item.text}>
								<Stack
									sx={{
										width: '100%',
										height: '100%',

										background: 'white',
									}}
									justifyContent={'space-between'}
								>
									<Stack gap='1rem' p='1rem'>
										<Typography color='#4646B6' fontSize='30px' fontWeight={600}>
											{item.title}
										</Typography>
										<Typography color='#8F8F9E'>{item.text}</Typography>
									</Stack>
									<img src={item.image} alt='electrical' style={{ aspectRatio: '1.5' }} />
								</Stack>
							</Grid>
						))}
					</Grid>
				</Stack>
				<Stack sx={{ width: '50%', margin: 'auto', minWidth: '300px' }} gap='2rem' mt='2rem' pt='2rem' id='quote'>
					<Typography fontSize='30px' textAlign={'center'}>
						Want A Quote?
					</Typography>
					<RequestAQuote withMargin={false} id={id} />
				</Stack>
				<Stack sx={{ background: '#0C0C1C' }}>
					<Grid container spacing={2} mx='auto' py='10rem' sx={{ width: '70%' }}>
						<Grid item xs={4}>
							<Stack gap='1rem'>
								<Avatar src={icon_file} />
								<Typography color='#FFFFFF' fontWeight={700} fontSize='30px'>
									{company_name}
								</Typography>
								<Stack direction='row' gap='1rem'>
									{socials.map((social) => (
										<Fragment key={social.key}>
											{profDetails?.[social.key] && (
												<IconButton href={profDetails?.[social.key]} target='_blank'>
													{social.Icon}
												</IconButton>
											)}
										</Fragment>
									))}
								</Stack>
							</Stack>
						</Grid>
						<Grid item xs={4}>
							<Stack gap='1rem'>
								<Typography color='#FFFFFF !important' fontWeight={600} fontSize='20px'>
									{address}
								</Typography>
								<Typography sx={{ cursor: 'pointer' }} component='a' href={`tel: ${formatted_phone_number}`} color='#FFFFFF !important'>
									Phone: {formatted_phone_number}
								</Typography>
								<Typography component='a' href={`mailto: ${user?.email}`} sx={{ cursor: 'pointer' }} color='#FFFFFF !important'>
									Email: {user?.email}
								</Typography>
							</Stack>
						</Grid>
						<Grid item xs={4}>
							<Stack gap='1rem'>
								<Typography color='#FFFFFF !important' fontWeight={600} fontSize='20px'>
									Navigation
								</Typography>
								{navItems.map((item) => (
									<Link to={item.value} underline='none' key={item.value} style={{ fontWeight: 400, color: 'white', fontFamily: 'Lexend,serif' }}>
										{item.label}
									</Link>
								))}
							</Stack>
						</Grid>
						<Grid item xs={12} mt='3rem'>
							<Stack direction={'row'} justifyContent={'space-between'}>
								<Typography color='#FFFFFF !important'>&copy; {new Date().getFullYear()} BPO Tech All rights reserved.</Typography>
								<Stack direction='row' gap='1rem'>
									<Link to='/privacy-policy' style={{ color: 'white', fontFamily: 'Lexend,serif' }}>
										Privacy Policy
									</Link>
									<Link to='/terms-of-service' style={{ color: 'white', fontFamily: 'Lexend,serif' }}>
										Terms and Conditions
									</Link>
								</Stack>
							</Stack>
						</Grid>
					</Grid>
				</Stack>
			</Stack>
		</ThemeProvider>
	);
};

export default IndProfTemp3;
