import React from 'react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useOutletContext } from 'react-router-dom';
import { toast } from 'react-toastify';
import base from '../../../apis';
import './listings.scss';

function Listings({ isActive }) {
	//eslint-disable-next-line
	const [agent, isLoading, setIsLoading, setAgent] = useOutletContext();
	const id = useSelector((state) => state.auth.typeId);
	const [listings, setListings] = useState([]);

	useEffect(() => {
		setIsLoading(true);
		base
			.get(`premiersite/listings/?agent_id=${id}`)
			.then(({ data }) => {
				const listingsData = data.filter((listing) =>
					isActive ? !(listing.date_sold && listing.sold_price) : listing.date_sold && listing.sold_price
				);
				setListings(listingsData);
				if (!listingsData.length) {
					toast.warn(`No ${isActive ? 'active' : 'sold'} listings found.`);
				}
			})
			.catch(() => {
				toast.error('Error fetching listings');
			})
			.finally(() => {
				setIsLoading(false);
			});
	}, [id, setIsLoading, isActive]);
	return (
		<div className='listings'>
			<div className='listings-top'>
				{/* {isActive && (
					<>
						<p>Click on 'Import from BPO Homes' button to transfer all recent and sold listings here</p>
						<Button
							style={{
								width: 'fit-content',
								height: '45px',
								fontSize: '14px',
								padding: '10px 20px',
								color: 'white',
								backgroundColor: 'var(--primary-color)',
								display: 'block',
								marginTop: '20px',
							}}
							text='Import from BPO Homes'
						/>
					</>
				)} */}
			</div>
			<p>
				{listings.length} {isActive ? 'Active' : 'Sold'} listings{' '}
			</p>
			{listings.length >= 1 ? (
				<table style={{ marginTop: '20px' }}>
					{listings.map((listing, index) => (
						<tr key={listing.id}>
							<td>{index + 1}</td>
							<td>{listing.address}</td>
							<td>$ {Number(listing.list_price).toLocaleString('en')}</td>
							<td>{new Date(listing.date_created).toLocaleDateString('en', { month: 'long', day: '2-digit', year: 'numeric' })}</td>
						</tr>
					))}
				</table>
			) : (
				<>
					<p>No listings found.</p>
					<NavLink
						to='/premier-agent-website/view-listings/add'
						style={{ color: 'var(--primary-color)', textDecoration: 'underline', cursor: 'pointer' }}
					>
						Add Listing
					</NavLink>
				</>
			)}
		</div>
	);
}

export default Listings;
