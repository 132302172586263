import React, { useEffect, useState } from 'react';
import savedHomesBg from '../../assets/savedHomes.jpg';
import { Box, Button, Grid, Skeleton, Stack, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import base from '../../apis';
import { toast } from 'react-toastify';
import HomeIMG from '../../images/home.webp';
import SavedHomeCards from '../../components/ListingCard/SavedHomesCard';


export const SavedHomes = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const user_id = useSelector((state) => state.auth.id);

    useEffect(() => {
        (async (params) => {
            setData([]);
            setIsLoading(true);
            try {
                const { data } = await base.get('/favorite/properties/', {
                    params: {
                        user_id,
                    },
                });
                setData(data);
            } catch (error) {
                toast.error('Could not get favorites');
            } finally {
                setIsLoading(false);
            }
        })();
    }, [user_id]);

    return (
        <Box sx={{ borderRadius: 15, p: '1rem' }}>
            <Box style={{ height: '30vh', overflow: 'hidden', position: 'relative', borderRadius: '15px' }}>
                <Typography zIndex={'10'}
                    style={{
                        position: 'absolute',
                        color: 'white',
                        fontSize: '6em',
                        fontWeight: 700,
                        textShadow: '1px 0px 5px rgba(92, 113, 237, 0.52)',
                        top: '8%',
                        right: '30%'
                    }}>
                    Saved Homes
                </Typography>
                <img src={savedHomesBg} alt='home' style={{
                    objectFit: 'cover',
                    objectPosition: 'center',
                    height: '100%',
                    width: '100%'
                }} />
            </Box>
            <Grid container spacing={2} xl={12} mt={4} sm={12} md={12} lg={12}>
                {isLoading ? (
                    [1, 2, 3, 4, 5, 6].map((ld) => {
                        return (
                            <Grid item xs={12} sm={6} md={4} lg={3} key={ld}>
                                <LoadingPropertyItem />
                            </Grid>
                        );
                    })
                ) : data.length > 0 ? (
                    data?.map((item) => {

                        return (
                            <Grid item xs={12} sm={6} md={4} lg={3} key={item?.id}>
                                <SavedHomeCards
                                    listDate={item.list_date}
                                    mlsId={item.property_id}
                                    status={item.status}
                                    address={item.address}
                                    price={Number(item.price).toLocaleString()}
                                    image={item.image}
                                    bed={item.bedrooms}
                                    bath={item.baths}
                                    area={item.area}
                                    setData={(id) => setData(data.filter((item) => item.id !== id))}
                                    id={item.id}
                                />
                            </Grid>
                        );
                    })
                ) : (
                    <Box
                        mt={3}
                        sx={{
                            width: '100%',
                            padding: '3rem',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                        }}
                    >
                        <img src={HomeIMG} style={{ marginBottom: '1rem', width: '100px', height: '100px' }} alt='home' />
                        <Typography color='GrayText' textAlign={'center'}>
                            Sorry, no saved listings
                        </Typography>
                        <Button href={process.env.REACT_APP_BPOHOMESREALTY_URL} target='_blank' variant='outlined' color='secondary'>
                            Click to view listings
                        </Button>
                    </Box>
                )}
            </Grid>
        </Box>
    )
}


const LoadingPropertyItem = () => {
    return (
        <Stack>
            <Skeleton sx={{ mb: '-2rem' }} height={'20rem'} animation='wave' />
            <Skeleton animation='wave' />
            <Skeleton animation='wave' width={'70%'} />
            <Skeleton animation='wave' height={'1rem'} width={'50%'} />
        </Stack>
    );
};