import { useDispatch, useSelector } from "react-redux";
import { updateCustomComps } from "../../../../../../actions/dealAnalyzer";
import base from "../../../../../../apis";
import useCreateAlert from "../../../../hooks/useCreateAlert";
import { useState } from "react";

function useCustomComps() {
  const { createAlert } = useCreateAlert();
  const [oldComps, setOldComps] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const analyzerData = useSelector((state) => state?.straightAestheticRemodel);
  const savedCustomComps = analyzerData?.customComps || [];
  const savedAddresses = savedCustomComps.map((item) => item?.address);
  const reportId = analyzerData?.dealAnalyzerReportId?.id;
  const analyzerTypeId = analyzerData?.dealAnalyzerReportId?.analyzerTypeId;

  const saveOldCustomComps = () => {
    setOldComps([...savedCustomComps]);
  };


  //updates clicked comps 
  const handleUpdateCustomComps = (e, index) => {
    // console.log(e.target.name, e.target.value)
    if (!savedCustomComps[+index]) return;
    const comp = { ...savedCustomComps[+index] };
    comp[e.target.name] = e.target.value;
    savedCustomComps[index] = comp;
    dispatch(updateCustomComps(savedCustomComps));
  };
  const replaceAllCustomComps = (data) => {
    if (!data?.length) { return }
    dispatch(updateCustomComps(data))
  }


  //use to add new comps
  const addNewCustomComps = (data) => {
    if (!data) {
      return;
    }
    if (savedAddresses.includes(data?.address)) {
      createAlert("Address already added to Custom Comps", "error");
      return;
    }
    dispatch(updateCustomComps([...savedCustomComps, { ...data, new: true }]));
  };

  const deleteCustomComp = (index) => {
    if (!savedCustomComps?.length) {
      return;
    }
    const comps = [...savedCustomComps];
    comps.splice(index, 1);
    dispatch(updateCustomComps(comps));
  };
  const validateRequiredFields = () => {
    const errors = [];
    for (let item of savedCustomComps) {
      let errorMessage = `Invalid Required Values : `;
      const missingFields = [];
      let errorState = false;
      requiredFields.forEach((field) => {
        if (isNaN(item?.[field?.name])) {
          errorMessage += ` ${field?.errorMessage},`;
          missingFields.push(field?.name);
          errorState = true;
        }
      });
      if (errorState) {
        errors.push({ errorMessage, missingFields });
      } else {
        errors.push("");
      }
    }
    return errors;
  };


  const submitCustomComps = async () => {
    setLoading(true);
    try {
      await base.put(
        `deal_analyzer/custom_comps?id=${reportId}&analyzer=${analyzerTypeId}`,
        { custom_comps: savedCustomComps }
      );
      createAlert("Custom Comps update successful", "success");
    } catch (error) {
      createAlert("Error adding Comps", "error");
    } finally {
      setLoading(false);
    }
  };
  const restoreOldCompsOnCancel = () => {
    dispatch(updateCustomComps(oldComps));
  };

  //console.log(savedCustomComps, 'hi')

  return {
    savedCustomComps,
    handleUpdateCustomComps,
    addNewCustomComps,
    deleteCustomComp,
    validateRequiredFields,
    submitCustomComps,
    saveOldCustomComps,
    restoreOldCompsOnCancel,
    replaceAllCustomComps,
    loading,
  };
}

export default useCustomComps;
const requiredFields = [
  { name: "price", errorMessage: "Price " },
  { name: "sqft", errorMessage: "Square Footage " },
  { name: "lot_size", errorMessage: "Lot Size " },
];
