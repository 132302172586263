import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { Box, Link as DLink, Typography as DTypo, Divider, Grid, IconButton, Button as MatBut, Stack, TextField, createTheme } from '@mui/material';
import { ThemeProvider, makeStyles, styled } from '@mui/styles';
import React from 'react';
import { Link as RLink } from 'react-router-dom';
import { ReactComponent as FBIcon } from '../../images/facebookfillable.svg';
import { ReactComponent as IGIcon } from '../../images/instagramfillable.svg';
import { ReactComponent as LIIcon } from '../../images/linkedInfillable.svg';
import BG from '../../images/temp5back.webp';
import { ReactComponent as TWIcon } from '../../images/twitterfillable.svg';
import { ReactComponent as YTIcon } from '../../images/youtubefillable.svg';
import { theme } from '../../theme';
import { formatNumber, formatPhoneNumber, getYoutubeEmbedLink, hideButton, scrollToTheLeft } from '../../utils/functions';
import ServiceAreasSlider from '../../components/ServiceAreasSlider/ServiceAreasSlider';
import InvestorQuestionnaires from '../../components/InvestorQuestionnaires/InvestorQuestionnaires';

const Link = styled(DLink)(({ theme }) => ({
	cursor: 'pointer',
}));
const Typography = styled(DTypo)(({ theme }) => ({
	fontFamily: 'Montserrat, sans-serif',
}));
const Button = styled(MatBut)(({ theme }) => ({
	background: `linear-gradient(to right, transparent 20%, #FFDDCA 20%, #FFDDCA 100%)`,
	'&:hover': {
		background: `linear-gradient(to right, transparent 20%, #FFDDCA 20%, #FFDDCA 100%)`,
	},
	fontFamily: 'Montserrat, sans-serif',
	color: 'black',
	paddingRight: '2rem',
	textTransform: 'capitalize',
	borderRadius: '0px',
	paddingLeft: 0,
}));

const BlackIconButton = styled(IconButton)(({ theme }) => ({
	// border: '1px solid black',
	borderRadius: '50%',
	width: '40px',
	aspectRatio: '1',
	backgroundColor: 'black',
	'&:hover': {
		backgroundColor: 'black',
	},
}));

const pageTheme = createTheme({
	...theme,
	typography: {
		fontFamily: 'Montserrat, sans-serif',
		fontSize: 16,
	},
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					borderRadius: 0,
				},
			},
		},
	},
	palette: {
		primary: {
			main: '#FFDDCA',
		},
		secondary: {
			main: '#fff',
		},
		success: {
			main: '#fff',
		},
		text: {
			main: '#000',
		},
		paper: {
			main: '#fff',
		},
	},
	primaryColor: '#001322',
	secondaryColor: '#fff',
});

const useStyles = makeStyles((theme) => ({
	home: {
		background: `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),url(${BG})`,
		backgroundSize: 'cover',
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat',
		height: '100vh',
		width: '100%',
		position: 'relative',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-end',
	},
	greyback: {
		backgroundColor: '#f5f3ef',
		padding: '4rem ',
		marginTop: '2rem',
	},
}));

export default function NewTemp5({ agent, listings, testimonials, contactDetails, updateContactDetails, sendMessage, serviceAreas }) {
	const classes = useStyles();
	const name = agent?.user.first_name + ' ' + agent?.user.last_name;
	const agent_id = agent?.id;
	const member_since = agent?.year_work_commenced;
	const agent_phone = formatPhoneNumber(agent?.phone);
	const brokerage_phone = formatPhoneNumber(agent?.brokerage?.brokerage_phone);
	const brokerage_name = agent?.brokerage_name;
	const license = agent?.licenses.find((license) => license.category === 'real_estate_agent')?.number;
	const address = agent?.address;
	const video = agent?.video_link;
	const showInvestorForm = agent?.questionnaires_on;

	return (
		<ThemeProvider theme={pageTheme}>
			<Box className={classes.home} id='home'>
				<Box sx={{ position: 'absolute', top: '0', width: '100%', padding: '4rem', display: 'flex', justifyContent: 'space-between' }}>
					<Box display='flex' gap='1rem' fontFamily='Montserrat, sans-serif'>
						<Link href='#home' underline='none' color='white' fontFamily='inherit' sx={{ textTransform: 'uppercase' }}>
							Home
						</Link>
						<Link href='#about' underline='none' color='white' fontFamily='inherit' sx={{ textTransform: 'uppercase' }}>
							About
						</Link>
					</Box>

					<Typography
						variant='body1'
						fontFamily='Montserrat, sans-serif'
						color='white'
						fontWeight='400'
						fontSize='16px'
						sx={{ textTransform: 'uppercase' }}
					>
						Listing Agent: {name}
					</Typography>

					<Box display='flex' gap='1rem' fontFamily='Montserrat, sans-serif'>
						{listings.active.length > 0 && (
							<Link href='#active' underline='none' color='white' fontFamily='inherit' sx={{ textTransform: 'uppercase' }}>
								Active Listings
							</Link>
						)}
						<Link href='#contact' underline='none' color='white' fontFamily='inherit' sx={{ textTransform: 'uppercase' }}>
							Contact
						</Link>
						<Link href={`/agent/${agent?.id}/blogs`} underline='none' color='white' fontFamily='inherit' sx={{ textTransform: 'uppercase' }}>
							Blog
						</Link>
						{showInvestorForm && (
							<Link href='#investors' underline='none' color='white' fontFamily='inherit' sx={{ textTransform: 'uppercase' }}>
								Investors
							</Link>
						)}
					</Box>
				</Box>
				<Box padding={'4rem'} mb={'5rem'}>
					<Typography variant='body1' textTransform='uppercase' color='white' fontSize='14px' fontWeight='700'>
						{address}
					</Typography>
					<Typography variant='h3' textTransform='uppercase' color='white'>
						{name}
					</Typography>
				</Box>
			</Box>
			{listings.active.length > 0 && (
				<Box p={'4rem'} position='relative'>
					<Typography variant='h6' mb={1}>
						Active Listings
					</Typography>
					<Box
						display='flex'
						gap='20px'
						id='active'
						sx={{
							maxWidth: '100%',
							overflowX: 'scroll',
							// position: 'relative',
							'&::-webkit-scrollbar': {
								display: 'none',
							},
							scrollBehavior: 'smooth',
						}}
					>
						<IconButton
							ref={(el) => hideButton(el, 'active')}
							sx={{
								position: 'absolute',
								top: '180px',
								right: '3rem',
								backgroundColor: '#C28563',
								borderRadius: '0px',
								'&:hover': { backgroundColor: '#C28563' },
								boxShadow: '0px 0px 10px 0px #00000026',
								transform: 'scale(1.2)',
							}}
							onClick={() => scrollToTheLeft('active', true)}
						>
							<ChevronLeft htmlColor='white' />
						</IconButton>
						<IconButton
							ref={(el) => hideButton(el, 'active')}
							sx={{
								position: 'absolute',
								top: '250px',
								right: '3rem',
								backgroundColor: '#2F3035',
								borderRadius: '0px',
								'&:hover': { backgroundColor: '#2F3035' },
								boxShadow: '0px 0px 10px 0px #00000026',
								transform: 'scale(1.2)',
							}}
							onClick={() => scrollToTheLeft('active')}
						>
							<ChevronRight htmlColor='white' />
						</IconButton>
						{listings.active.map((listing) => (
							<Card key={listing.id} {...listing} agentId={agent_id} />
						))}
					</Box>
				</Box>
			)}
			<Box sx={{ backgroundColor: '#f5f3ef' }}>
				<Grid container spacing={3} className={classes.greyback} alignItems='center' justifyContent='center'>
					<Grid item xs={7}>
						<Typography variant='h5' mb={2}>
							{name}
						</Typography>
						<Typography textAlign='justify' fontWeight={300} fontSize='16px' whiteSpace='pre-line'>
							{agent?.bio}
						</Typography>
						<Stack direction='column' spacing={2} divider={<Divider orientation='horizontal' flexItem sx={{ backgroundColor: 'black' }} />} my={2}>
							<Stack direction='column' spacing={2}>
								<Typography variant='body1' fontWeight={300} fontSize='16px'>
									{address}
								</Typography>
								<Typography variant='body1' fontWeight={300} fontSize='16px'>
									{agent?.user?.email}
								</Typography>
							</Stack>
							<Stack direction='column' spacing={2}>
								<Typography variant='body1' fontWeight={300} fontSize='16px'>
									Cell Phone: {agent_phone}
								</Typography>
								<Typography variant='body1' fontWeight={300} fontSize='16px'>
									Brokerage: {brokerage_name} {brokerage_phone ? `(${brokerage_phone})` : ''}
								</Typography>
							</Stack>
							<Stack direction='column' spacing={2}>
								<Typography variant='body1' fontWeight={300} fontSize='16px'>
									Member Since: {member_since}
								</Typography>
								<Typography variant='body1' fontWeight={300} fontSize='16px'>
									Real Estate License: {license}
								</Typography>
							</Stack>
						</Stack>
						<Button disableRipple href='#contact'>
							Let's Connect
						</Button>
					</Grid>
					<Grid item xs={5} alignItems='center' justifyContent='center'>
						<img
							src={agent?.profile_image}
							alt='Agent'
							style={{
								width: '400px',
								aspectRatio: '3/4',
								objectFit: 'cover',
								objectPosition: 'top center',
								display: 'block',
								margin: 'auto',
							}}
						/>
					</Grid>
				</Grid>
				{video && (
					<Grid container spacing={0} alignItems='center' mt={'4rem'}>
						<Grid item xs={7}>
							<iframe
								style={{
									aspectRatio: '16/9',
									borderRadius: '0px',
									border: 'none',
									width: '100%',
									// margin: '3rem auto',
									marginLeft: 'auto',
									display: 'block',
								}}
								src={`https://www.youtube.com/embed/${getYoutubeEmbedLink(video)}`}
								title='Client video'
								allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
								allowFullScreen
							></iframe>
						</Grid>
						<Grid item xs={5} pl={'4rem'} alignItems={'center'} justifyContent='center'>
							<Typography variant='h4' mb={3}>
								Video Spotlight
							</Typography>
							<Button disableRipple href='#contact'>
								Let's Connect
							</Button>
						</Grid>
					</Grid>
				)}
				{showInvestorForm && (
					<Box padding={'4rem'}>
						<InvestorQuestionnaires agent={agent} />
					</Box>
				)}
			</Box>
			{listings.sold.length > 0 && (
				<Box p={'4rem'} position='relative'>
					<Box
						display='flex'
						gap='20px'
						id='sold'
						sx={{
							maxWidth: '100%',
							overflowX: 'scroll',
							// position: 'relative',
							'&::-webkit-scrollbar': {
								display: 'none',
							},
							scrollBehavior: 'smooth',
						}}
					>
						<IconButton
							ref={(el) => hideButton(el, 'sold')}
							sx={{
								position: 'absolute',
								top: '180px',
								right: '3rem',
								backgroundColor: '#C28563',
								borderRadius: '0px',
								'&:hover': { backgroundColor: '#C28563' },
								boxShadow: '0px 0px 10px 0px #00000026',
								transform: 'scale(1.2)',
							}}
							onClick={() => scrollToTheLeft('sold', true)}
						>
							<ChevronLeft htmlColor='white' />
						</IconButton>
						<IconButton
							ref={(el) => hideButton(el, 'sold')}
							sx={{
								position: 'absolute',
								top: '250px',
								right: '3rem',
								backgroundColor: '#2F3035',
								borderRadius: '0px',
								'&:hover': { backgroundColor: '#2F3035' },
								boxShadow: '0px 0px 10px 0px #00000026',
								transform: 'scale(1.2)',
							}}
							onClick={() => scrollToTheLeft('sold')}
						>
							<ChevronRight htmlColor='white' />
						</IconButton>
						<Stack direction='row' alignItems='flex-start'>
							<Typography variant='h6' mb={1} whiteSpace='nowrap'>
								Sold Listings
							</Typography>
						</Stack>
						{listings.sold.map((listing) => (
							<Card {...listing} key={listing.id} />
						))}
					</Box>
				</Box>
			)}
			{testimonials.length > 0 && (
				<Box display='flex' flexDirection='column' alignItems='center' sx={{ backgroundColor: '#f5f3ef' }}>
					<Typography variant='h6' mt='3rem'>
						From My Clientele
					</Typography>
					<Stack direction='row' spacing={2} p={'2rem 4rem'} minHeight={'400px'} alignItems='center'>
						{testimonials.map((testimonial) => (
							<Review key={testimonial.id} {...testimonial} />
						))}
					</Stack>
				</Box>
			)}

			{serviceAreas.length > 0 && (
				<Box my={3}>
					<ServiceAreasSlider serviceAreas={serviceAreas} />
				</Box>
			)}

			<Grid container spacing={2} alignItems='flex-start' justifyContent='center' mt='4rem' p='4rem' id='contact'>
				<Grid item xs={4}>
					<Typography variant='h5' mb={3}>
						Get In Touch
					</Typography>
					<Typography variant='body1' fontWeight={300} fontSize='16px'>
						For more information or inquires,
						<br /> use this form to contact me.
					</Typography>
					<Stack direction='row' spacing={3} sx={{ mx: 'auto', mt: '1rem' }}>
						{agent?.facebook && (
							<BlackIconButton target='blank' href={agent?.facebook}>
								<FBIcon fill='white' />
							</BlackIconButton>
						)}
						{agent?.twitter && (
							<BlackIconButton target='blank' href={agent?.twitter}>
								<TWIcon fill='white' />
							</BlackIconButton>
						)}
						{agent?.instagram && (
							<BlackIconButton target='blank' href={agent?.instagram}>
								<IGIcon fill='white' />
							</BlackIconButton>
						)}
						{agent?.linkedin && (
							<BlackIconButton target='blank' href={agent?.linkedin}>
								<LIIcon fill='white' />
							</BlackIconButton>
						)}
						{agent?.video_link && (
							<BlackIconButton target='blank' href={agent?.video_link}>
								<YTIcon fill='white' />
							</BlackIconButton>
						)}
					</Stack>
				</Grid>
				<Grid item xs={8}>
					<Grid container spacing={2} pl='5rem' pr='10rem'>
						<Grid item xs={12}>
							<TextField
								fullWidth
								size='small'
								variant='outlined'
								placeholder='Full name'
								color='primary'
								value={contactDetails.name}
								onChange={updateContactDetails}
								name='name'
							/>
						</Grid>
						<Grid item xs={6}>
							<TextField
								fullWidth
								size='small'
								variant='outlined'
								placeholder='Email'
								color='primary'
								value={contactDetails.email}
								onChange={updateContactDetails}
								name='email'
							/>
						</Grid>
						<Grid item xs={6}>
							<TextField
								fullWidth
								size='small'
								variant='outlined'
								placeholder='Phone Number'
								color='primary'
								value={contactDetails.phone}
								onChange={updateContactDetails}
								name='phone'
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								multiline
								rows={4}
								fullWidth
								size='small'
								variant='outlined'
								placeholder='Message'
								color='primary'
								value={contactDetails.message}
								onChange={updateContactDetails}
								name='message'
							/>
						</Grid>
					</Grid>
					<Stack direction={'row'} spacing={2} pl='5rem' my='2rem'>
						<Button disableRipple onClick={sendMessage}>
							Submit
						</Button>
						<Button disableRipple href={`/order-bpo-report/${agent_id}`} target='_blank'>
							Order A BPO Report
						</Button>
					</Stack>
				</Grid>
			</Grid>
		</ThemeProvider>
	);
}

const Card = ({ id, agentId, list_price, photos, beds, baths, square_footage, address, date_sold, sold_price }) => {
	return (
		<Link component={RLink} to={`/view-listing/${agentId}/${id}`} target='_blank'>
			<Box width='300px'>
				<Box
					width='300px'
					sx={{
						background: `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),url(${photos?.[0]?.image})`,
						backgroundSize: 'cover',
						backgroundPosition: 'center',
						backgroundRepeat: 'no-repeat',
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'flex-end',
						aspectRatio: '0.72',
						padding: '1rem',
					}}
				>
					<Box>
						<Typography variant='body1' textTransform='uppercase' color='white' fontSize='14px'>
							{date_sold && sold_price ? 'Sold' : 'For Sale'}
						</Typography>
						<Typography variant='h3' textTransform='uppercase' color='white' fontSize='24px' fontWeight='700'>
							$ {formatNumber(list_price, 0)}
						</Typography>
					</Box>
				</Box>
				<Typography variant='subtitle1' color='#C4C4C4' fontSize='14px' fontWeight='400'>
					{address}
				</Typography>
				<Typography variant='subtitle1' fontSize='14px' fontWeight='400'>
					- {beds} Beds, {baths} Bath, {formatNumber(square_footage)} sqft
				</Typography>
			</Box>
		</Link>
	);
};

const Review = ({ description, name, client }) => {
	return (
		<Stack spacing={2} alignItems='center' flex='1'>
			<Typography variant='body1' fontSize='100px' textTransform='uppercase' color='#FFDDCA' lineHeight={0} fontFamily='auto'>
				&ldquo;
			</Typography>
			<Typography variant='body1' textAlign='center' fontStyle='italic'>
				{description}
			</Typography>
			<Typography variant='body1' textAlign='center' fontWeight='700' fontSize='18px' mt='auto'>
				- {client?.user?.first_name || client?.user?.last_name ? client?.user?.first_name + ' ' + client?.user?.last_name : name}
			</Typography>
		</Stack>
	);
};
