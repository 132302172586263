import { carryingMonthsCalcs } from "./CarryingMonths";
import { appreciatedResaleAmountCalc } from "../estResaleValue/estResaleValue";
import { financeOptionsCals } from "./FinanceOptions";

export const estimatedResalePriceCalcs = ({
  remodelCosts,
  developmentCost,
  financeOptions,
  resultData,
  dealId,
  propertyPermitAndFees,
  taxes,
  rentalIncome,
  propertyManagement,
  otherCosts,
  carryingMonths,
  estimatedResaleValue,
  dealAnalyzerResults,
}) => {
  const {
    annual_property_appreciation,
    resale_value_option,
    desired_resale_value,
    zillowComps,
    compsToApply,
    calculatedAverageZillowComps,
    appreciation_value_option,
    appreciation_value_option_desired,
  } = estimatedResaleValue || {};

  const carryingCostCalcs1 = carryingMonthsCalcs({
    remodelCosts,
    financeOptions,
    resultData,
    dealId,
    propertyPermitAndFees,
    taxes,
    rentalIncome,
    propertyManagement,
    otherCosts,
    carryingMonths,
  });

  const {
    primary_purchase_price,
    cash_for_home_purchase_price,
    deal_finance_option,
  } = financeOptions;
  const financeOptionsCals1 = financeOptionsCals({
    remodelCosts,
    developmentCost,
    financeOptions,
    resultData,
    dealId,
    propertyPermitAndFees,
    taxes,
    rentalIncome,
    propertyManagement,
  });

  const purhcasePrice =
    +deal_finance_option === 3
      ? cash_for_home_purchase_price
      : primary_purchase_price;

  const appreciationCalculator = () => {
    const val = appreciatedResaleAmountCalc(
      purhcasePrice,
      +annual_property_appreciation,
      carryingCostCalcs1?.months
    );

    return val;
  };

  const numberOfZillowComps = zillowComps?.length;
  const numberOfComps =
    dealAnalyzerResults?.est_resale_value?.number_of_comparables ||
    numberOfZillowComps;

  const zillowEstimate = calculatedAverageZillowComps;
  const estimates = resultData?.est_resale_value;

  const averagePurchasePrice = zillowEstimate?.averageTotalPrice;

  const customOrZillowComps =
    compsToApply === 1
      ? averagePurchasePrice
      : estimates?.average_purchase_price;

  const appreciationValueChoice = +appreciation_value_option - 1 || 0;
  const appreciationValueChoiceDesired =
    +appreciation_value_option_desired - 1 || 0;

  const optionalAddtion =
    +appreciationValueChoice === 1
      ? appreciationCalculator()?.appreciatedAmount
      : 0;
  const optionalAddtionDesired =
    +appreciationValueChoiceDesired === 1
      ? appreciationCalculator()?.appreciatedAmount
      : 0;

  const estimatedResaleAmount =
    resale_value_option === 2
      ? desired_resale_value + optionalAddtionDesired
      : numberOfComps && numberOfComps > 0
      ? customOrZillowComps + optionalAddtion
      : appreciationCalculator()?.estimatedHomePrice;

  return {
    estimatedResaleAmount,
    appreciationAmount: appreciationCalculator()?.appreciatedAmount,
    ...financeOptionsCals1,
  };
};
