import { Delete } from '@mui/icons-material';
import { Box, Button, Card, CircularProgress, Grid, IconButton, Pagination, Stack, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useOutletContext } from 'react-router-dom';
import { toast } from 'react-toastify';
import base from '../../../apis';
import NoReviews from '../../../images/noReviews.webp';
import './contact.scss';

function Contact() {
	//eslint-disable-next-line
	const [agent, isLoading, setIsLoading, setAgent] = useOutletContext();
	const [reviews, setReviews] = useState([]);
	const [isReviewLoading, setIsReviewLoading] = useState(false);
	const [pageCount, setPageCount] = useState(null);
	const id = useSelector((state) => state.auth.typeId);

	const getReviews = useCallback(
		(page = 1) => {
			setReviews([]);
			setIsReviewLoading(true);
			base
				.get(`register/agents/${id}/reviews/?page=${page}`)
				.then(({ data }) => {
					setReviews(data?.results);
					setPageCount(data?.count);
				})
				.catch((err) => {
					toast.error('Could not get reviews');
				})
				.finally(() => {
					setIsReviewLoading(false);
				});
		},
		[id]
	);

	const [selectedTestimonials, setSelectedTestimonials] = useState([]);

	useEffect(() => {
		getReviews();
		const getMyTestimonials = async () => {
			try {
				const { data } = await base.get(`/premiersite/choose-testimonials/?agent_id=${id}`);
				setSelectedTestimonials(data);
			} catch (error) {
				toast.error('Could not testimonials');
			}
		};
		getMyTestimonials();
	}, [getReviews, id]);

	const handlePageChange = (e, page) => {
		getReviews(page);
	};

	const onAddTestimonial = (review) => {
		if (selectedTestimonials.length >= 3) {
			toast.warn('You can only add 3 testimonials');
			return;
		}
		setSelectedTestimonials([...selectedTestimonials, review]);
	};

	const onRemoveTestimonial = (review) => {
		setSelectedTestimonials(selectedTestimonials.filter((item) => item.id !== review.id));
	};

	const onSaveTestimonials = async () => {
		setIsLoading(true);
		try {
			await base.post(`/premiersite/choose-testimonials/`, {
				agent_id: id,
				reviews: selectedTestimonials.map((item) => +item.id),
			});
			toast.success('Testimonials saved successfully');
			const { data: testimonials } = await base.get(`/premiersite/choose-testimonials/?agent_id=${id}`);
			setSelectedTestimonials(testimonials);
		} catch (error) {
			toast.error('Could not save testimonials');
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<>
			<Grid container spacing={2} mt={1} p={2} id='up' sx={{ scrollMarginTop: '100px' }}>
				<Grid item xl={6}>
					<Typography variant='h6' fontWeight={300} mb={2}>
						User Reviews
					</Typography>
					{!isReviewLoading ? (
						reviews.length > 0 ? (
							reviews.map((review) => (
								<React.Fragment key={review.id}>
									<Card sx={{ padding: '10px', marginBottom: '10px' }} key={review.id}>
										<Grid container spacing={2}>
											<Grid item xl={12} sm={12} fontWeight={300} display='flex' alignItems='center' justifyContent='space-between'>
												{new Date(review?.date_created).toLocaleDateString('en-US')} -{' '}
												{`${
													review?.client?.user?.first_name || review?.client?.user?.last_name
														? review?.client?.user?.first_name + ' ' + review?.client?.user?.last_name
														: review?.name
												} `}
												<Button
													variant='text'
													color='secondary'
													disabled={selectedTestimonials?.map((item) => item.id)?.includes(review.id)}
													onClick={() => onAddTestimonial(review)}
													sx={{ textTransform: 'capitalize' }}
												>
													Add to Testimonials
												</Button>
											</Grid>
											{/* <Grid item xl={12} sm={12} md={12}>
												<Grid container spacing={0} alignItems='flex-end'>
													{ratingOptions.map((item) => (
														<Fragment key={item.value}>
															<Grid item xl={6} lg={6} xs={6}>
																<Typography variant='body2'>{item.label}</Typography>
															</Grid>
															<Grid item xl={6} lg={6} xs={6}>
																<Rating readOnly value={review[item.value] || 1} />
															</Grid>
														</Fragment>
													))}
												</Grid>
											</Grid> */}
											<Grid item xl={12} sm={12} md={12} textAlign='justify'>
												{review.description}
											</Grid>
										</Grid>
									</Card>
								</React.Fragment>
							))
						) : (
							<Box bgcolor={grey[200]} padding={'4rem'} textAlign='center' borderRadius={'10px'}>
								<img src={NoReviews} alt='no-reviews' width={'15%'} style={{ marginBottom: '10px' }} />
								<Typography paragraph>Sorry, no reviews available</Typography>
							</Box>
						)
					) : (
						<Box height={'20rem'} padding={'4rem'} display='flex' alignItems='center' justifyContent='center' borderRadius={'10px'}>
							<CircularProgress />
						</Box>
					)}
				</Grid>
				<Grid item xl={6}>
					<Stack direction='row' justifyContent={'space-between'} alignItems='center'>
						<Typography variant='h6' fontWeight={300} mb={2}>
							Selected Testimonials
						</Typography>
						<Button sx={{ textTransform: 'capitalize' }} variant='contained' color='secondary' onClick={onSaveTestimonials}>
							Save
						</Button>
					</Stack>
					{selectedTestimonials?.length > 0 ? (
						selectedTestimonials?.map((review) => (
							<React.Fragment key={review.id}>
								<Card sx={{ padding: '10px', marginBottom: '10px' }} key={review.id}>
									<Grid container spacing={2}>
										<Grid item xl={12} sm={12} fontWeight={300} display='flex' alignItems='center' justifyContent='space-between'>
											{new Date(review?.date_created).toLocaleDateString('en-US')} -{' '}
											{`${
												review?.client?.user?.first_name || review?.client?.user?.last_name
													? review?.client?.user?.first_name + ' ' + review?.client?.user?.last_name
													: review?.name
											} `}
											<IconButton onClick={() => onRemoveTestimonial(review)}>
												<Delete />
											</IconButton>
										</Grid>
										<Grid item xl={12} sm={12} md={12}>
											{/* <Grid container spacing={0} alignItems='flex-end'>
												{ratingOptions.map((item) => (
													<Fragment key={item.value}>
														<Grid item xl={6} lg={6} xs={6}>
															<Typography variant='body2'>{item.label}</Typography>
														</Grid>
														<Grid item xl={6} lg={6} xs={6}>
															<Rating readOnly value={review[item.value] || 1} />
														</Grid>
													</Fragment>
												))}
											</Grid> */}
										</Grid>
										<Grid item xl={12} sm={12} md={12} textAlign='justify'>
											{review.description}
										</Grid>
									</Grid>
								</Card>
							</React.Fragment>
						))
					) : (
						<Box bgcolor={grey[200]} padding={'4rem'} textAlign='center' borderRadius={'10px'}>
							<img src={NoReviews} alt='no-reviews' width={'15%'} style={{ marginBottom: '10px' }} />
							<Typography paragraph>Sorry, no testimonials selected </Typography>
						</Box>
					)}
				</Grid>
				<Grid item xl={6} display='flex' justifyContent='center'>
					<Pagination
						size='large'
						color='secondary'
						sx={{
							display: 'flex',
							justifyContent: 'center',
						}}
						shape='rounded'
						variant='outlined'
						count={Math.ceil(pageCount / 5)}
						onChange={handlePageChange}
					/>
				</Grid>
			</Grid>
		</>
	);
}

export default Contact;
