import React from 'react';
import './checkbox.scss';

function Checkbox({ text, checked, disabled, setChecked, id, labelStyle, ...rest }) {
	return (
		<label className='container' style={{ ...labelStyle }}>
			{text}
			<input id={id} type='checkbox' checked={checked} onChange={setChecked} disabled={disabled} {...rest} />
			<span className='checkmark'></span>
		</label>
	);
}

export default Checkbox;
