import { CheckCircle } from '@mui/icons-material';
import { Box, Button, Container, Divider, Grid, List, ListItem, ListItemIcon, ListItemText, Typography, useMediaQuery } from '@mui/material';
import { grey } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { HiArrowRight } from 'react-icons/hi';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { toggleAuthModal } from '../../actions';
import base from '../../apis';
import Modal from '../../components/Modal/Modal';
import PlacesSearch from '../../components/PlacesSearch/PlacesSearch';
import Spinner from '../../components/Spinner/Spinner';
import { URL } from '../../constants';
import { ReactComponent as CloseIcon } from '../../images/Close.svg';
import defaultImage from '../../images/agentAv.webp';
import SEO from '../../components/SEO';

const useStyles = makeStyles((theme) => ({
	btn: {
		textTransform: 'none',
		width: '8rem',
	},
	profilePic: {
		height: '25rem',
		background: grey[300],
		borderRadius: '5px',
	},
	field: {
		marginBottom: '1.5rem',
		'& .MuiFilledInput-root': {
			backgroundColor: 'white',
		},
	},
	gridWrap: {},
	divider: {
		width: '5rem',
		height: '.3rem',
		background: theme.primaryColor,
		marginTop: '.8rem',
	},
	listItem: {
		color: '#fff',
		padding: '5px',
		'& #icon': {
			minWidth: '32px',
		},
	},
}));

const BpoReport = () => {
	const classes = useStyles();
	const { agentId } = useParams();
	const [isLoading, setIsLoading] = useState(false);
	const [agentDetails, setAgentDetails] = useState(null);

	const navigate = useNavigate();

	useEffect(() => {
		setIsLoading(true);
		const controller = new AbortController();

		axios
			.get(URL + 'register/agents/' + agentId, {
				signal: controller.signal,
			})
			.then(({ data }) => {
				setAgentDetails(data);
			})
			.catch((err) => {
				if ('detail' in err?.response?.data) {
					navigate('/404', { replace: true });
				}
				toast.error('Could not get agent');
			})
			.finally(() => {
				setIsLoading(false);
			});

		return () => {
			controller.abort();
		};
	}, [agentId, navigate]);

	const extras = [
		{ title: 'Get your home value from an unbiased Local Real-Estate Expert', icon: <CheckCircle color='paper' fontSize='small' /> },
		{ title: "Don't trust computer generated CMA's or ESTIMATES", icon: <CheckCircle color='paper' fontSize='small' /> },
		{ title: "Sellers, Don't UNDERLIST your Home", icon: <CheckCircle color='paper' fontSize='small' /> },
		{ title: "Buyers, Don't OVERPAY", icon: <CheckCircle color='paper' fontSize='small' /> },
	];

	const [bpoModal, setBpoModal] = useState(false);
	const [propertyAddress, setPropertyAddress] = useState('');
	const loggedIn = useSelector((state) => state?.auth?.token?.access);
	const dispatch = useDispatch();
	const [searchParams, setSearchParams] = useSearchParams();
	const [otherText, setOtherText] = useState('');
	const client_reason = searchParams.get('client_reason');

	useEffect(() => {
		const property = localStorage.getItem('propertyAddress');
		const isModalOpen = searchParams.get('modal');
		const address = searchParams.get('address');
		if (address) {
			setPropertyAddress(address);
		}
		if (property) {
			setPropertyAddress(property);
		}
		if (isModalOpen) {
			setBpoModal(true);
			searchParams.delete('modal');
			setSearchParams(searchParams);
		}
	}, [searchParams, setSearchParams]);

	const [isOnetime, setIsOnetime] = useState(false);

	const handleSubmit = (isOnetime) => {
		setIsOnetime(isOnetime);
		if (!propertyAddress) {
			toast.warn('Please enter property address');
			return;
		}
		if (loggedIn) {
			if (client_reason) {
				handleOptionClick({ target: { innerHTML: client_reason } });
				return;
			}
			setBpoModal(true);
		} else {
			localStorage.setItem('propertyAddress', propertyAddress);
			const nextRoute = `${window.location.pathname}${window.location.search ? '&' : '?'}modal=true`;
			localStorage.setItem('nextRoute', nextRoute);
			dispatch(toggleAuthModal());
		}
	};

	const clientId = useSelector((state) => state?.auth?.typeId);

	const handleOptionClick = async (e) => {
		const request = JSON.stringify({
			client_id: clientId,
			client_reason: e.target.innerHTML,
			propertyAddress,
			agent_id: +agentId,
		});
		localStorage.setItem('request', request);
		//navigate to report payment page
		//one
		let route = `/report-pay/${agentId}?mode=sub`;

		const { data } = await base.post('/register/check_client_report_status/', {
			clients_physical_address: propertyAddress,
			reason_for_request: e.target.innerHTML,
			client_register_id: clientId,
			client_id: clientId,
			payment_status: 'Complete',
			agent_id: +agentId,
		});
		if ('id' in data) {
			route = `/client-ordered-report?id=${data.id}`;
		} else {
			route = `/report-pay/${agentId}?mode=${isOnetime ? 'onetime' : 'sub'}`;
		}

		navigate(route);
	};

	const handleOtherClick = (e) => {
		e.preventDefault();
		if (!otherText) {
			toast.warn('Please enter reason');
			return;
		}
		handleOptionClick({ target: { innerHTML: otherText } });
	};
	const matches = useMediaQuery('(max-width:500px)');

	return (
		<>
			{isLoading && <Spinner />}
			<SEO>
				<title>{`${agentDetails?.first_name} ${agentDetails?.last_name}'s Expert BPO Report | Local Real Estate Agent Home Valuation`}</title>
				<meta
					name='og:title'
					content={`${agentDetails?.first_name} ${agentDetails?.last_name}'s Expert BPO Report | Local Real Estate Agent Home Valuation`}
				/>
				<meta
					name='description'
					content="Discover your home's accurate value with our expert, [Agent Name]'s BPO Report. As a local real estate professional, we offer unbiased valuations to help sellers avoid underlisting and buyers avoid overpaying. Get your customized report today!"
				/>
				<meta
					name='og:description'
					content="Discover your home's accurate value with our expert, [Agent Name]'s BPO Report. As a local real estate professional, we offer unbiased valuations to help sellers avoid underlisting and buyers avoid overpaying. Get your customized report today!"
				/>
			</SEO>
			<Modal open={bpoModal} onClose={() => setBpoModal(false)} style={{ width: matches ? '100%' : 'unset', minWidth: matches ? '100%' : null }}>
				<div className='modal_content' style={{ position: 'relative' }}>
					<CloseIcon
						onClick={() => {
							setBpoModal(false);
						}}
						className='close_icon'
						width='18'
						height='18'
						style={{ cursor: 'pointer', position: 'absolute', top: '1rem', right: '1rem' }}
					/>
					<div className='modal_body'>
						<p className='bpo_modal_title'>
							Why would you like a BPO report <span>?</span>
						</p>
						<div className='modal_options'>
							<p className='bpo_modal_option' onClick={handleOptionClick}>
								I'm buying a property
							</p>
							<p className='bpo_modal_option' onClick={handleOptionClick}>
								I'm selling a property
							</p>
							<p className='bpo_modal_option' onClick={handleOptionClick}>
								I want to know my value
							</p>
							<form className='formbtn'>
								<input
									placeholder='Other, Please Explain'
									type='text'
									value={otherText}
									onChange={(e) => setOtherText(e.target.value)}
									required
									style={{ fontSize: 'inherit', fontFamily: 'inherit' }}
								/>
								<button onClick={handleOtherClick} type='submit'>
									<HiArrowRight size='2rem' color='#ffffff' />
								</button>
							</form>
						</div>
					</div>
				</div>
			</Modal>

			<div>
				<Box padding={'5rem 1rem'} sx={{ backgroundImage: (theme) => `linear-gradient(to top, ${theme.secondaryColor} 79%, #fff 21%)` }}>
					<Container>
						<Box className={classes.gridWrap}>
							<Grid container spacing={4}>
								<Grid item sm={12} md={5} lg={5}>
									<Box bgcolor={'#fff'}>
										<img
											src={agentDetails?.profile_image || defaultImage}
											alt='agent-pix'
											width={'100%'}
											style={{ maxHeight: '600px', objectFit: 'cover', objectPosition: 'top center' }}
										/>
										<Grid container spacing={2}>
											<Grid item xl={12} lg={12} md={12} sm={12} xs={12} display='flex' justifyContent='center' alignItems='center'>
												{agentDetails?.office_logo && !isLoading && (
													<img src={agentDetails?.office_logo} alt='agent-pix' width={'60px'} style={{ aspectRatio: '4/3' }} />
												)}
												{/* <Avatar
													src={agentDetails?.office_logo}
													sx={{
														width: '60px',
														height: '60px',
													}}
												/> */}
											</Grid>
											<Grid item xl={12} lg={12} md={12} sm={12} xs={12} sx={{ paddingBottom: '1rem' }} paddingTop='0px !important'>
												<Typography variant='h6' textAlign={'center'}>
													{agentDetails?.first_name} {agentDetails?.last_name}
												</Typography>
												<Typography variant='body2' color={'textSecondary'} textAlign={'center'}>
													Licensed Real Estate Agent
												</Typography>
												<Typography variant='body2' color={'textSecondary'} textAlign={'center'}>
													{agentDetails?.brokerage_name || ''}
												</Typography>
												<Typography variant='body2' color={'textSecondary'} textAlign={'center'}>
													License #: {agentDetails?.licenses.find((item) => item.category === 'real_estate_agent')?.number || ''}
												</Typography>
											</Grid>
										</Grid>
									</Box>
								</Grid>

								<Grid item sm={12} md={7} lg={7}>
									<Box sx={{ padding: { xs: '.5rem', sm: '1rem', md: '2rem', lg: '2rem' } }}>
										<Typography
											variant='h5'
											sx={{ fontWeight: 600, mb: { xs: 4, md: 9, lg: 9 }, color: { xs: '#fff', sm: '#fff', md: '#000', lg: '#000' } }}
										>
											GET YOUR REPORT NOW!
										</Typography>
										<Typography variant='body1' mb={3} sx={{ color: '#fff' }}>
											{agentDetails?.first_name} is Ready to provide your BPO Report. {agentDetails?.first_name} {agentDetails?.last_name} is a local
											real-estate expert Agent in your Neighborhood . Let {agentDetails?.first_name} {agentDetails?.last_name} give you an expert
											opinion of value on your home today.
										</Typography>
										{/* form */}
										<form>
											<Typography variant='body2' paragraph sx={{ color: '#fff' }}>
												Provide the subject property’s address you wish to request a BPO Report for.
											</Typography>
											{/* <TextField className={classes.field} fullWidth placeholder='Property address' /> */}
											<PlacesSearch
												value={propertyAddress}
												onChange={setPropertyAddress}
												name='property_search'
												className={classes.field}
												placeholder='Property Address'
												mui={true}
												style={{ marginBottom: '5px', width: '100%', backgroundColor: 'white', borderRadius: '4px' }}
											/>
											<Grid container spacing={2} mt={1}>
												<Grid item xs={12} md={6} lg={6} xl={6}>
													<Button
														variant='contained'
														color='primary'
														sx={{ height: '3rem', color: 'white' }}
														fullWidth
														onClick={() => handleSubmit()}
													>
														Get Annual Report
													</Button>
												</Grid>
												<Grid item xs={12} md={6} lg={6} xl={6}>
													<Button
														variant='contained'
														sx={{ border: '1px solid #fff', height: '3rem' }}
														color='secondary'
														fullWidth
														disableElevation
														onClick={() => handleSubmit(true)}
													>
														Request One-time
													</Button>
												</Grid>
											</Grid>
										</form>

										{/* EXTRAS */}
										<Box mt={'3rem'}>
											<Typography variant='h6' sx={{ color: '#fff' }}>
												Why do you need a BPO Report <span>?</span>
											</Typography>
											<Divider className={classes.divider} />
											<List>
												{extras.map((el) => {
													return (
														<ListItem key={el.title} className={classes.listItem}>
															<ListItemIcon id='icon'>{el.icon}</ListItemIcon>
															<ListItemText>
																<Typography variant='body2'>{el.title}</Typography>
															</ListItemText>
														</ListItem>
													);
												})}
											</List>
										</Box>
									</Box>
								</Grid>
							</Grid>
						</Box>
					</Container>
				</Box>
			</div>
		</>
	);
};

export default BpoReport;
