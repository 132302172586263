import { Box } from "@mui/system";
import React from "react";
import useTheme from "@mui/material/styles/useTheme";
import { useSelector } from "react-redux";

import FlatRatePercentageInput from "../atomicComponents/FlatRatePercentageInput";
import StyledLabel from "../atomicComponents/StyledLabel";
import {
  FormControl,
  OutlinedInput,
  Typography,
  InputAdornment,
  Grid,
} from "@mui/material";

import commaSeparate from "../utils/commaSeparate";
const useStyles = (_theme) => {
  return {
    root: {
      margin: "1.25rem",
      mt: 1,
    },
    upperText: {
      display: "flex",
      alignItems: "center",
      margin: "0px 0 0 0",
    },
    small: {
      ml: 2,
      color: "#888888",
    },
    under: {
      margin: "5px 0 25px 0 ",
    },
    inputContainer: {
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      gap: "3.125rem",
    },
    inputWithLabel: {
      display: "flex",
      flexDirection: "column",
      // width:"100%"
    },
  };
};

function PropertyPermitFees({ handleEvents, data }) {
  const theme = useTheme();
  const styles = useStyles(theme);
  const analyzerData = useSelector((state) => state?.straightAestheticRemodel);

  const rateHandler = (value) => {
    handleEvents({ city_permit_impact_fees_dollar_or_percent: value });
  };

  // ========================= this section has been moved to Closing cost section ===============//
  // ================ check ClosingCost.js for updates ============================== //

  // const rateHandler2 = (value) => {
  //   handleEvents({ title_and_escrow_fees_dollar_or_percent: value });
  // };
  // const rateHandler3 = (value) => {
  //   handleEvents({ title_and_escrow_fees_resale_dollar_or_percent: value });
  // };
  const handleChange = (e) => {
    let value = e.target.value;
    const variable = e.target.name;
    if (value) {
      value = Number.parseInt(value.split(",").join(""));
      if (isNaN(value)) {
        return;
      }
      handleEvents({ [variable]: value });
    } else {
      handleEvents({ [variable]: value });
    }
  };
  const Label = ({ text, asideText }) => (
    <Box sx={styles.upperText}>
      <span>
        <StyledLabel label={text} />
      </span>
      <Typography variant="h6" sx={styles.small}>
        {asideText}
      </Typography>
    </Box>
  );
  return (
    <Box sx={styles.root}>
      <FlatRatePercentageInput
        handler={handleEvents}
        data={data}
        title={
          <Label
            text={propertyPermitFeesStaticData?.labelTitle}
            asideText={propertyPermitFeesStaticData?.labelTitleAside}
          />
        }
        noToolTip={true}
        rateHandler={rateHandler}
        name="city_permit_impact_fees"
        rate={
          data?.city_permit_impact_fees_dollar_or_percent
            ? data.city_permit_impact_fees_dollar_or_percent
            : 0
        }
        referenceValue={analyzerData?.financeOptions?.primary_purchase_price}
      />
      <Box sx={styles.under}>
        {" "}
        <Typography variant="notice">
          {propertyPermitFeesStaticData?.labelInfo2}
        </Typography>
      </Box>
      <Box sx={{ mt: 2 }} />
      <Grid container columns={24} columnGap={3} rowGap={2}>
        {propertyPermitFeesStaticData.inputs.map((input) => (
          <Grid item md={11} sm={24}>
            <Box sx={styles.inputWithLabel}>
              <StyledLabel label={input.label} />
              <FormControl>
                <OutlinedInput
                  margin="normal"
                  name={input.variableName}
                  onChange={handleChange}
                  value={commaSeparate(data?.[input?.variableName])}
                  placeholder="0"
                  renderValue={(value) => value}
                  inputProps={{ style: { fontSize: "1rem" } }}
                  startAdornment={
                    <InputAdornment position="start">
                      {" "}
                      <Typography variant="adornment" color="secondary">
                        {" "}
                        $
                      </Typography>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default PropertyPermitFees;

const propertyPermitFeesStaticData = {
  labelTitle: "City Permit & Impact Fees",
  labelTitleAside:
    "(Contact the Local Real Estate Title Company to get more Accurate Pricing)",
  labelTitleAside1:
    "(Contact your Local Title Company for more Accurate Pricing)",
  labelInfo:
    "Please Note. For Title & Escrow you can Estimate 0.25 to 0.5% of Purchase Price",
  labelInfoForResale:
    "Please Note. For Title & Escrow you can Estimate 0.5% of Resale Price",
  labelInfo2:
    "Please Note: For Remodel Estimate 1% - 3% of Purchase Price and for New Construction we recommend 3% - 5% of Purchase Price",

  escrow: "Title & Escrow Fees",
  architecture: "Architectural Fees",
  monthlyInsurance: "Monthly Home Insurance",
  hoa: "Monthly HOA Dues",
  inputs: [
    // {label:'Title & Escrow Fees',variableName:"title_and_escrow_fees"},
    {
      label: "Architectural & Engineering Fees",
      variableName: "architectural_fees",
    },
    { label: "Monthly Home Insurance", variableName: "monthly_home_insurance" },
    { label: "Monthly HOA Dues", variableName: "monthly_hoa_dues" },
  ],
};
