import { Box, Typography } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import EnhancedLoanAmount from "../atomicComponents/EnhancedLoanAmount";
import EnhancedRadioButtonGroup from "../atomicComponents/EnhancedRadioButtonGroup";
import StyledLabel from "../atomicComponents/StyledLabel";

function CashforHomeAndRemodel({ handler, data, extraComponentData }) {
  //this saves the option due to the financial option selected
  const dealFinanceOption = data?.deal_finance_option || 0;

  //creates a dynamic header base on the props received and the dealFinanceOption
  const header = extraComponentData.primaryHeaders?.[dealFinanceOption];

  //set the value and name to the handler function
  const specialHandler = (e) => {
    handler({ [e.target.name]: e.target.value });
  };

  useEffect(() => {
    !data?.cash_for_home_wholesale_fee &&
      handler({ cash_for_home_wholesale_fee: 1 });
    // NOTE: Run effect once on component mount, please
    // recheck dependencies if effect is updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={{ width: "50%", minWith: "18.75rem" }}>
      <Box>
        <Typography variant="heading" sx={{ color: "#1378A5" }}>
          {header}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItem: "center",
          width: "100%",
          marginTop: "1.25rem",
        }}
      >
        <Box sx={{ margin: "0.35rem 1.875rem 0 0" }}>
          <StyledLabel label="Is there a Wholesale Fee?" />
        </Box>
        <EnhancedRadioButtonGroup
          options={radioButtonOptions}
          name="cash_for_home_wholesale_fee"
          specialHandler={specialHandler}
          styles={{ display: "flex", flexDirection: "row-reverse" }}
          choice={data?.cash_for_home_wholesale_fee}
        />
      </Box>

      <Box>
        <EnhancedLoanAmount
          name="primary_purchase_price"
          data={data}
          label="What is the Purchase Price"
          handler={handler}
          noToolTip={true}
          overrideStyle={{
            minWidth: "15.6rem",
            marginTop: "5px",
            marginRight: "5px",
          }}
        />
      </Box>
      <Box>
        <EnhancedLoanAmount
          name="cash_amount"
          data={data}
          label={
            extraComponentData?.labels?.payAllCash?.label2 ||
            "What is the Remodel Cost Amount"
          }
          handler={handler}
          noToolTip={true}
          overrideStyle={{
            minWidth: "15.6rem",
            marginTop: "5px",
            marginRight: "5px",
          }}
        />
      </Box>
    </Box>
  );
}

export default CashforHomeAndRemodel;

const radioButtonOptions = ["No", "Yes"];
