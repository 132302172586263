import React, { useEffect, useMemo } from "react";
import { generateField } from "../../../utils/GenerateInputField";
import {
  Chip,
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  OutlinedInput,
  Typography,
} from "@mui/material";
import StyledLabel from "../../../atomicComponents/StyledLabel";
import { preventMinus } from "../../../utils/preventNegativeValues";

export const AdditionalUnitsComponent = ({
  propertyInfo,
  data,
  handler,
  item,
  handleEvents,
  hasAdditionalCost = true,
  unitIndex,
  handleChange,
  additionalUnitArray,
  setAdditionalUnitArray,
}) => {
  //========================== dynamic form generation ============================//
  // generate fields for additional sqf added
  const generateNewForm = useMemo(() => {
    return generateField(propertyInfo?.additional_units?.length);
  }, [propertyInfo?.additional_units]);

  const formsWithValues =
    data?.additional_units_price?.length === generateNewForm?.length
      ? data?.additional_units_price
      : undefined;

  useEffect(() => {
    if (!formsWithValues) {
      handler({ additional_units_price: [] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // generate fields for additional sqf added

  const componentDynamicDataFilter = (index, event) => {
    let data = [...additionalUnitArray];
    const { name, value } = event?.target || "";
    // Find the field to update within the specific unit
    const fieldIndex = data[index].findIndex((field) => field.name === name);

    if (fieldIndex !== -1) {
      const field = data[index][fieldIndex];
      field.value = value;
    }

    setAdditionalUnitArray(data);
    handler({ additional_units_price: data });
  };

  return (
    <>
      <Divider sx={{ mt: 2 }}>
        <Chip label={`Unit ${unitIndex + 2}`} style={{ fontSize: "10px" }} />
      </Divider>
      <Grid container columns={24} gap={6.5}>
        <Grid item sm={24} md={11}>
          <FormControl sx={{ mt: "1rem", width: "100%" }} key={unitIndex}>
            <StyledLabel
              label={`Est. Remodel Cost P/SqFt`}
              showToolTip
              // toolTipContent={toolTipTexts.bedrooms}
            />

            <OutlinedInput
              name={item?.[1].name}
              onKeyPress={preventMinus}
              type="number"
              onChange={(event) => componentDynamicDataFilter(unitIndex, event)}
              value={item?.[1].value}
              inputProps={{ style: { fontSize: "1rem" }, min: 0 }}
              sx={{ fontSize: "16px" }}
              endAdornment={
                <InputAdornment position="start">
                  {" "}
                  <Typography variant="adornment"> SqFt</Typography>
                </InputAdornment>
              }
            ></OutlinedInput>
          </FormControl>
        </Grid>
        {hasAdditionalCost && (
          <Grid item sm={24} md={11}>
            {" "}
            <FormControl sx={{ mt: "1rem", width: "100%" }} key={unitIndex}>
              <StyledLabel
                label={`Addition Cost P/Sqft`}
                showToolTip
                // toolTipContent={toolTipTexts.bedrooms}
              />

              <OutlinedInput
                name={item?.[0].name}
                onKeyPress={preventMinus}
                type="number"
                onChange={(event) =>
                  componentDynamicDataFilter(unitIndex, event)
                }
                value={item?.[0].value}
                inputProps={{ style: { fontSize: "1rem" }, min: 0 }}
                sx={{ fontSize: "16px" }}
                endAdornment={
                  <InputAdornment position="start">
                    {" "}
                    <Typography variant="adornment"> SqFt</Typography>
                  </InputAdornment>
                }
              ></OutlinedInput>
            </FormControl>
          </Grid>
        )}

        {/* For calculations  */}
      </Grid>
      {/* <Grid container columns={24}>
        <Grid item sm={24} md={10}>
          {" "}
          <FormControl sx={{ width: "45%", mt: "1rem" }} key={unitIndex}>
            <StyledLabel
              label={`Unit ${unitIndex + 2}`}
              showToolTip
              // toolTipContent={toolTipTexts.bedrooms}
            />

            <OutlinedInput
              name={"value"}
              onKeyPress={preventMinus}
              type="number"
              onChange={(event) => componentDynamicDataFilter(unitIndex, event)}
              value={item?.[0].value}
              inputProps={{ style: { fontSize: "1rem" }, min: 0 }}
              sx={{ fontSize: "16px" }}
              endAdornment={
                <InputAdornment position="start">
                  {" "}
                  <Typography variant="adornment"> SqFt</Typography>
                </InputAdornment>
              }
            ></OutlinedInput>
          </FormControl>
        </Grid>

        <Grid item sm={24} md={10}>
          {" "}
          <Box sx={styles.inputContainer}>
            <StyledLabel label={`Unit ${unitIndex + 2} Remodel Cost`} />
            <FormControl sx={{ minWidth: "18.75rem" }}>
              <OutlinedInput
                fullWidth
                margin="normal"
                name={"remodelMainCardStaticData?.input2.name"}
                onChange={handleChange}
                value={item?.value || 0}
                sx={greyout}
                placeholder="0"
                onKeyPress={preventMinus}
                renderValue={(value) => value}
                inputProps={{ style: { fontSize: "1rem" }, min: 0 }}
                type="number"
                startAdornment={
                  <InputAdornment position="start">
                    {" "}
                    <Typography variant="adornment" color="secondary">
                      {" "}
                      $
                    </Typography>
                  </InputAdornment>
                }
              />
            </FormControl>
          </Box>
        </Grid>
      </Grid> */}
    </>
  );
};
