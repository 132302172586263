import { useState } from "react";
import base from "../../../apis";
import { useSelector } from "react-redux";
import { getDealAnalyzerBaseUrlWithCode } from "../utils/getDealAnalyzerBaseUrlWithCode";

function useFetchComps() {
  const [data, setData] = useState();
  const [loading, setloading] = useState(false);
  const [loadingSelectedComps, setloadingSelectedComps] = useState(false);
  const typeId = useSelector(state => state?.straightAestheticRemodel?.dealAnalyzerReportId?.analyzerTypeId)
  const typeUrl = getDealAnalyzerBaseUrlWithCode(typeId)


  //creates new data
  const fetchData = async (pl) => {
    setloading(true);
    try {
      const res = await base.post(`/deal_analyzer/get_comps`, { ...pl });
      setData(res?.data?.data);
    } catch (err) {
    } finally {
      setloading((prev) => !prev);
    }
  };

  const sendSelectedComps = async ({ pl, url, id, closeWindow, type, comps_option }) => {

    setloadingSelectedComps((prev) => !prev);
    try {

      await base.put(`/${typeUrl}?id=${id}`,
        {
          est_resale_value: {
            ids: [...pl],
            comps_option,
            analyzer: type
          }
        });
    } catch (err) {
    } finally {
      setloadingSelectedComps((prev) => !prev);
      closeWindow()
    }
  };

  const sendResalevalueOption = async (resale_value_option, id, type, desired_resale_value) => {
    setloadingSelectedComps((prev) => !prev);
    try {
      await base.put(`/${typeUrl}?id=${id}`, { est_resale_value: { analyzer: type, resale_value_option, desired_resale_value } });
    } catch (err) {
    } finally {
      setloadingSelectedComps((prev) => !prev);
    }
  }




  return {
    data,
    loading,
    sendSelectedComps,
    loadingSelectedComps, fetchData, sendResalevalueOption
  };
}

export default useFetchComps;
