import React, { useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import UserMenuOptions from '../UserMenuOptions/UserMenuOptions';
import './navbarauth.scss';
import { useDispatch, useSelector } from 'react-redux';
import { toggleSidebar } from '../../actions';
import Logo from '../../images/bpoVerticalLogo.webp';
import { IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Close } from '@mui/icons-material';
import useMediaQuery from '@mui/material/useMediaQuery';

function NavbarAuth() {
	const dispatch = useDispatch();
	const auth = useSelector((state) => state.auth);
	const role = useSelector((state) => state.auth.role);
	const navigate = useNavigate();
	const isMobile = useMediaQuery('(max-width: 1200px)');

	//if senior marketer

	useLayoutEffect(() => {
		const navbar = document.getElementsByClassName('navbar')[0];
		document.documentElement.style.setProperty('--navheight', navbar.clientHeight + 'px');
	}, []);

	const isOpen = useSelector((state) => !state.sidebar.isOpen);
	const MenuButton = isOpen ? Close : MenuIcon;

	return (
		<div className='navbar-mod navbar'>
			<IconButton
				sx={{
					position: 'absolute',
					left: '10px',
					root: {
						padding: '5px!important',
					},
				}}
				onClick={() => {
					dispatch(toggleSidebar());
				}}
			>
				<MenuButton fontSize='medium' htmlColor={isMobile ? 'black' : 'white'} />
			</IconButton>
			<div
				// to={role === 'realty_agent' ? 'https://testing.bpohomes.com/' : '/'}
				onClick={() => {
					if (role === 'realty_agent') {
						window.location.assign(process.env.REACT_APP_BPOHOMESREALTY_URL);
					} else {
						navigate('/');
					}
				}}
				className='logo logo-mod'
				style={{ boxSizing: 'content-box', paddingRight: '0 !important', paddingLeft: '0 !important' }}
			>
				<img src={Logo} alt='logo' className='logo' />
			</div>

			<ul className=' logged' style={{ marginRight: '10px', marginTop: '0', marginBottom: '0' }}>
				{/* Depends on the state of the user⬇️ */}
				{/* <NavLink to='/feed'>Feed</NavLink> */}
				{/* <HeartIcon /> */}
				{/* TODO name should be user's name */}
				<UserMenuOptions name={`${auth.first_name} ${auth.last_name}`} />
			</ul>
		</div>
	);
}

export default NavbarAuth;
