import React from 'react'
import { Box, Typography, Button } from '@mui/material'

function OfferItem({price,unit,callToAction,buttonText,open,dealType}) {

  return (
    <Box sx={{display:"flex",flexDirection:"column",height:"15rem",position:"relative"}}>
      <Box sx={{ textAlign: "center",marginBottom:"1.7rem",whiteSpace:"nowrap" }}>
        <Typography variant='dealCost' sx={{ display: "inline-block", marginRight: "1.25rem" }}>
          {price}
        </Typography>
        <Typography variant='dealCostUnit' sx={{ display: "inline-block" }}>{unit}</Typography>
      </Box>
      <Box sx={{ textAlign: "center",marginBottom:"1.25rem" }}><Typography variant='dealCta'>{callToAction.header}</Typography></Box>
      <Box sx={{ textAlign: "center",margin:" 0 -10px 1.875rem -10px",color:"grey" }}><Typography variant='h6' sx={{color:"#808080"}}>{callToAction.main}</Typography></Box>
      <Box sx={{ position:"absolute",bottom:"0px",margin:"0 auto",width:"100%", textAlign:"center"}}>
        <Button  
        disableElevation variant='contained'  
        sx={{backgroundColor:"#FFAA01","&:hover":{backgroundColor:"#FFAA01",},
        padding:"1.0rem 30%"}}
        onClick={()=>open(dealType)}>
            <Typography variant='buttonFont' color={"secondary"} sx={{textTransform:"none", whiteSpace:"nowrap"}}>{buttonText}</Typography>
        </Button>
      </Box>
    </Box>
  );
}

export default OfferItem