import commaSeparate from "./commaSeparate"

export const convertToPercent = (variable,referenceValue) =>{
    if((variable && !Number.isNaN(+variable)) && ( referenceValue && !Number.isNaN(+referenceValue))){
        return ((variable/referenceValue) * 100).toFixed(2)
    }
    return ''
}
export const convertFromPercent = (variable,referenceValue) =>{

    if((variable && !Number.isNaN(+variable)) && ( referenceValue && !Number.isNaN(+referenceValue))){
        return commaSeparate(((variable/100) * referenceValue).toFixed(2))
    }
    return ''
}


