import { carryingMonthsCalcs } from "./CarryingMonths";
import { estimatedResalePriceCalcs } from "./EstimatedResalePrice";
import { financeOptionsCals } from "./FinanceOptions";
import {
  financeOptionsExpensesAmount,
  liquidExpensesAmount,
} from "../carryingCost/financialTotalExpenses";
import {
  interestOnlyMortgageCalculator,
  isNaNChecker,
} from "../utils/genericFunctions";
import { closingCostCalcs } from "./ClosingCost";
import {
  investorsProfit,
  totalExpenses,
  totalLiquidCapital,
} from "../profits/inverstorProfit";

export const investorProfitCalcs = ({
  remodelCosts,
  developmentCost,
  financeOptions,
  resultData,
  dealId,
  propertyPermitAndFees,
  taxes,
  rentalIncome,
  propertyManagement,
  otherCosts,
  carryingMonths,
  estimatedResaleValue,
  dealAnalyzerResults,
  closingCosts,
}) => {
  const {
    primary_interest_rate,
    secondary_interest_rate,
    deal_finance_option,
    primary_sub_loan_type,
    secondary_sub_loan_type,
    showSecondary,
    primary_loan_type,
    secondary_loan_type,
    primary_wholesale_fee,
    primary_wholesale_fee_options,
    primary_lender_points_options,
    secondary_lender_points_options,
    primary_purchase_price,
    cash_for_home_purchase_price,
  } = financeOptions || {};

  const {
    commission_on_resale_option,
    commission_on_purchase_option,
    closing_cost_option_resell,
    closing_cost_option_purchase,
    closing_cost_credit,
    closing_cost_credit_purchase,
  } = closingCosts || {};

  const { annual_maintenance } = propertyManagement || {};
  const { utilities, miscellaneous } = otherCosts;

  const { architectural_fees, monthly_home_insurance, monthly_hoa_dues } =
    propertyPermitAndFees || {};

  const purhcasePrice =
    +deal_finance_option === 3
      ? cash_for_home_purchase_price
      : primary_purchase_price;

  const financeOptionsCals1 = financeOptionsCals({
    remodelCosts,
    developmentCost,
    financeOptions,
    resultData,
    dealId,
    propertyPermitAndFees,
    taxes,
    rentalIncome,
    propertyManagement,
  });

  const carryingCostCalcs1 = carryingMonthsCalcs({
    remodelCosts,
    financeOptions,
    resultData,
    dealId,
    propertyPermitAndFees,
    taxes,
    rentalIncome,
    propertyManagement,
    otherCosts,
    carryingMonths,
  });

  const estimatedResalePrice = estimatedResalePriceCalcs({
    remodelCosts,
    financeOptions,
    resultData,
    dealId,
    propertyPermitAndFees,
    taxes,
    rentalIncome,
    propertyManagement,
    otherCosts,
    carryingMonths,
    estimatedResaleValue,
    dealAnalyzerResults,
  });

  const closingCostCalcs1 = closingCostCalcs({
    remodelCosts,
    financeOptions,
    resultData,
    dealId,
    propertyPermitAndFees,
    taxes,
    rentalIncome,
    propertyManagement,
    otherCosts,
    carryingMonths,
    estimatedResaleValue,
    dealAnalyzerResults,
    closingCosts,
  });

  //primary interest only on conventional Mortgage
  const primaryMonthlyPaymentInterestOnly = () => {
    const amount = interestOnlyMortgageCalculator(
      primary_interest_rate,
      financeOptionsCals1?.localGeneralLoanAmount
    );

    return amount;
  };

  //secondary interest only on conventional Mortgage
  const secondaryMonthlyAmountInterestOnly = () => {
    const amount = interestOnlyMortgageCalculator(
      secondary_interest_rate,
      financeOptionsCals1?.localSecondaryLoanAmount
    );
    return amount;
  };

  // console.log(primary_sub_loan_type);

  const financeOptionsExpensesAmountReturned = () => {
    const amount = financeOptionsExpensesAmount(
      financeOptionsCals1?.PrimaryInterestAloneMortgagePayment,
      secondaryMonthlyAmountInterestOnly(),
      primaryMonthlyPaymentInterestOnly(),
      +deal_finance_option,
      +primary_sub_loan_type,
      purhcasePrice,
      financeOptionsCals1?.remodelCostUsed,
      +secondary_sub_loan_type,
      financeOptionsCals1?.secondaryInterestOnlyMonthlyAmount,
      carryingCostCalcs1?.months,
      showSecondary,
      +primary_loan_type,
      financeOptionsCals1?.localGeneralLoanAmount,
      financeOptionsCals1?.localSecondaryLoanAmount,
      +secondary_loan_type,
      +primary_wholesale_fee,
      financeOptionsCals1?.primaryDownPayment,
      financeOptionsCals1?.secondaryDownPayment,
      financeOptionsCals1?.downPaymentForSingleLoan,
      financeOptionsCals1?.downPaymentForNewConstructionAndPayCash
    );
    return amount;
  };

  const financeOptionsExpensesAmountReturnedForLiquidCapital = () => {
    const amount = liquidExpensesAmount(
      financeOptionsCals1?.PrimaryInterestAloneMortgagePayment,
      financeOptionsCals1?.localSecondaryTotalMonthlyAmount,
      +financeOptionsCals1?.localPrimaryTotalMonthlyAmount,
      +deal_finance_option,
      +primary_sub_loan_type,
      purhcasePrice,
      financeOptionsCals1?.remodelCostUsed,
      +secondary_sub_loan_type,
      financeOptionsCals1?.secondaryInterestOnlyMonthlyAmount,
      carryingCostCalcs1?.months,
      showSecondary,
      +primary_loan_type,
      financeOptionsCals1?.localGeneralLoanAmount,
      financeOptionsCals1?.localSecondaryLoanAmount,
      +secondary_loan_type,
      +primary_wholesale_fee
    );
    return amount;
  };

  const totalExpenseAmount = () => {
    const amount = totalExpenses({
      financeOptionsExpensesAmountReturned:
        financeOptionsExpensesAmountReturned(),
      cityPermitFees: financeOptionsCals1?.cityPermitFeesCalculated,
      titleAndEscrowFeesAmount: closingCostCalcs1?.titleAndEscrowFeesAmount,
      architecturalFees: architectural_fees,
      monthlyHomeInsurance: monthly_home_insurance,
      monthlyHOA: monthly_hoa_dues,
      monthlyTax: financeOptionsCals1?.getMonthlyStatePropertyTax,
      propertyManagementFee: financeOptionsCals1?.monthlyPropertyManagementFees,
      annualMaintenance: annual_maintenance,
      miscellaneousCost: miscellaneous,
      utilities,
      months: carryingCostCalcs1?.months,
      titleAndEscrowFeesResaleAmount:
        closingCostCalcs1?.titleAndEscrowFeesResaleAmount,
      primaryLenderPointsAmount: financeOptionsCals1.primaryLenderPointsAmount,
      secondaryLenderPointsAmount:
        financeOptionsCals1?.secondaryLenderPointsAmount,
      dealtype: +deal_finance_option,
      primaryWholesaleFees: primary_wholesale_fee,
      secondaryWholesaleFees: 0,
      secondaryLenderPointsOption: secondary_lender_points_options,
      secondaryWholeSaleFeesOption: 0,
      primaryLenderPointsOption: +primary_lender_points_options,
      primaryWholeSaleFeesOption: +primary_wholesale_fee_options,
      commissionOnPurchaseOption: commission_on_purchase_option,
      commissionOnResaleOption: commission_on_resale_option,
      commissionOnPurchase: closingCostCalcs1?.commisionOnPurchaseAmount,
      commissionOnResale: closingCostCalcs1?.commissionOnResaleAmount,
      closing_cost_option_purchase,
      closing_cost_option_resell,
    });

    const profit = +amount;

    return isNaNChecker(profit);
  };

  const totalLiquidRequired = () => {
    const amount = totalLiquidCapital({
      financeOptionsExpensesAmountReturnedForLiquidCapital:
        financeOptionsExpensesAmountReturnedForLiquidCapital(),
      cityPermitFees: financeOptionsCals1?.cityPermitFeesCalculated,
      titleAndEscrowFeesForLiquidCapital:
        closingCostCalcs1?.titleAndEscrowFeesForLiquidCapital,
      architecturalFees: architectural_fees,
      monthlyHomeInsurance: +monthly_home_insurance,
      monthlyHOA: +monthly_hoa_dues,
      monthlyTax: financeOptionsCals1?.getMonthlyStatePropertyTax,
      propertyManagementFee: financeOptionsCals1?.monthlyPropertyManagementFees,
      annualMaintenance: +annual_maintenance,
      miscellaneousCost: +miscellaneous,
      utilities,
      months: carryingCostCalcs1?.months,
      primaryLenderPointsAmount: financeOptionsCals1.primaryLenderPointsAmount,
      secondaryLenderPointsAmount:
        financeOptionsCals1?.secondaryLenderPointsAmount,
      dealtype: +deal_finance_option,
      primaryWholesaleFees: +primary_wholesale_fee,
      secondaryWholesaleFees: 0,
      secondaryLenderPointsOption: +secondary_lender_points_options,
      secondaryWholeSaleFeesOption: 0,
      primaryLenderPointsOption: +primary_lender_points_options,
      primaryWholeSaleFeesOption: +primary_wholesale_fee_options,
      commissionForLiquidCapital: closingCostCalcs1?.commissionForLiquidCapital,
      downPayment: financeOptionsCals1?.primaryDownPayment,
    });

    const profit = +amount;
    return +profit.toFixed(2);
  };

  const localInvestorsProfit = () => {
    const amount = investorsProfit(
      estimatedResalePrice?.estimatedResaleAmount,
      financeOptionsCals1?.rentalProfit,
      closing_cost_credit,
      totalExpenseAmount(),
      closing_cost_credit_purchase
    );

    return +amount.toFixed(2);
  };

  return {
    totalExpenseAmount: totalExpenseAmount(),
    totalLiquidRequired: totalLiquidRequired(),
    localInvestorsProfit: localInvestorsProfit(),
    ...remodelCosts,
    ...financeOptionsCals1,
    ...carryingCostCalcs1,
    ...estimatedResalePrice,
    ...closingCostCalcs1,
  };
};
