import { Box, FormControl, OutlinedInput } from '@mui/material'
import React from 'react'
import { useTheme } from '@mui/material/styles'
import StyledLabel from '../atomicComponents/StyledLabel'

const useStyles = (_theme)=>{
    return{
        root:{
            width:"80%",
            minWidth:"18.75rem",
            display:"flex",
            Items:"center",
            flexDirection:"column"
        },
        label:{
            marginBottom:"0.625rem"
        }
    }
}

const PropertyAddress = ({data,handler,name}) => {

    const theme = useTheme()
    const styles = useStyles(theme)
    const handleChange = (e)=>{
        handler({[e.target.name]: e.target.value})
    }
    
  return (
    <Box sx={styles.root}>
        <Box sx={styles.label}>
            <StyledLabel label = 'Confirm Property Address'/>
          </Box>
        <FormControl sx={{width:"100%"}}>
        <OutlinedInput
        fullWidth
        margin="normal"
        name={name || 'property_address'}
        onChange = {handleChange}
        value = {data?.[name] || data?.["property_address"] }
        // placeholder='0'
        renderValue={value => value}
        inputProps={{ style: { fontSize: "1rem" } }}
      />
        </FormControl>
    </Box>
  )
}

export default PropertyAddress