import { Button, Popover, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import DateLocalizer from './DateLocalizer';
import { DateCalendar } from '@mui/x-date-pickers';
import Calendar from '@untitled-ui/icons-react/build/esm/Calendar';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
	icon: {
		stroke: '#344054',
		'& path': {
			stroke: 'inherit',
		},
	},
}));

const DatePickerForQuotes = ({ label, value: extValue, onChange }) => {
	const classes = useStyles();
	const [anchorEl, setAnchorEl] = useState(null);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
		setValue(extValue);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const [value, setValue] = useState(null);

	const open = Boolean(anchorEl);
	return (
		<>
			<Stack
				direction='row'
				gap='1rem'
				onClick={handleClick}
				alignItems='center'
				sx={{
					border: '1px solid #D0D5DD',
					boxShadow: '0px 1px 2px 0px #1018280D',
					borderRadius: '8px',
					p: '10px 14px',
					cursor: 'pointer',
				}}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
			>
				<Calendar stroke='#344054' className={classes.icon} />
				<Typography color='#344054'>{extValue ? extValue.format('DD MMM, YY') : label}</Typography>
			</Stack>
			<Popover
				component={Stack}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				sx={{
					'& .MuiPaper-root': {
						borderRadius: '12px',
						border: '1px solid #EAECF0',
						boxShadow: '0px 8px 8px -4px #10182808, 0px 20px 24px -4px #10182814',
					},
				}}
			>
				<Stack>
					<Stack sx={{ pb: 0 }}>
						<DateLocalizer>
							<DateCalendar value={value} onChange={setValue} />
						</DateLocalizer>
					</Stack>
					<Stack p='1rem' direction='row' gap='1rem' sx={{ borderTop: '1px solid #EAECF0' }}>
						<Button color='text' variant='outlined' sx={{ flex: 1 }} onClick={handleClose}>
							Cancel
						</Button>
						<Button
							color='secondary'
							variant='contained'
							sx={{ flex: 1 }}
							onClick={() => {
								onChange(value);
								handleClose();
							}}
						>
							Apply
						</Button>
					</Stack>
				</Stack>
			</Popover>
		</>
	);
};

export default DatePickerForQuotes;
