import React from 'react'
import { useTheme } from '@mui/material/styles'
import { Box,Table, TableContainer,tableCellClasses } from '@mui/material'
import SubSectionContainer from '../TableComponents/SubSectionContainer'
import InnerSectionHeader from '../TableComponents/InnerSectionHeader'
import { splitArray } from '../../utils/splitArray'
import ComponentSelector from '../ComponentSelector'
import ProformaStartAdornRow from '../RowTypes/ProformaStartAdornRow'
import EvenTableHead from './EvenTableHead'

const useStyles = (_theme,printVersion)=>{
    return{
        root:{

        },
        table:{
          [`& .${tableCellClasses.root}`]: {
            border: "none",
            padding:"5px 0",
            margin:"0rem 0 !important"
          },
          width:"48%",
          overflow:printVersion?"hidden":"scroll",
          alignSelf:"flex-start"
        },
        table2:{
          [`& .${tableCellClasses.root}`]: {
            border: "none",
            padding:"5px 0",
            margin:"0rem 0 !important"
          },
          width:"48%",
          alignSelf:"start",
          overflow:printVersion?"hidden":"scroll",
        },
        fullTable:{
          [`& .${tableCellClasses.root}`]: {
            border: "none",
            padding:"5px 0",
            margin:"0rem 0 !important"
          },
          alignSelf:"start",
          overflow:printVersion?"hidden":"scroll",

        }
    }
}

function ProformaPage1({
    listingArray,
    propertyInformation,
    dealFinance,
    monthlyTaxPayment,
    propertyPermitAndFees,
    printVersion,
    resultData
}) {

    const theme = useTheme();
    const styles = useStyles(theme,printVersion);
    const primaryLoanCals = resultData?.primary_finance_option_summary

  return (
    <>
          <SubSectionContainer printVersion={printVersion} title="Listing Information" tableContainerStyles={{display:"flex",justifyContent:"space-between"}}>
        <Table sx= {styles.table}>
          {splitArray(listingArray).result1.map(element =>(
            <ComponentSelector {...element} printVersion={printVersion}/>
          ))}
        </Table>
        <Table sx= {styles.table2}>
        {splitArray(listingArray).result2.map(element =>(
            <ComponentSelector {...element} printVersion={printVersion}/>
          ))}
        </Table>
      </SubSectionContainer>
      <Box sx={{mt:2}}/>
      <SubSectionContainer printVersion={printVersion} title="Property Information" tableContainerStyles={{display:"flex",justifyContent:"space-between",}}>
        <Table sx= {styles.table}>
          {splitArray(propertyInformation).result1.map(element =>(
            <ComponentSelector {...element} printVersion={printVersion}/>
          ))}
        </Table>
        <Table sx= {styles.table2}>
        {splitArray(propertyInformation).result2.map(element =>(
            <ComponentSelector {...element} printVersion={printVersion}/>
          ))}
        </Table>
      </SubSectionContainer>
      <Box sx={{mt:2}}/>
      <SubSectionContainer printVersion={printVersion} title="Deal Finance">
        <Box sx={{mt:2}}/>
        { dealFinance.primaryHomeLoan &&
          <><InnerSectionHeader title = {dealFinance?.primaryHeader || 'Primary Home Loan'}/>
        <Box sx={{mt:1}}/>
        <TableContainer sx={{display:"flex",justifyContent:"space-between"}}>
        <Table sx= {styles.table}>
          <EvenTableHead/>
          {dealFinance.primaryHomeLoan && splitArray(dealFinance.primaryHomeLoan).result1.map(element =>(
            <ComponentSelector {...element} printVersion={printVersion}/>
          ))}
        </Table>
        <Table sx= {styles.table}>
        <EvenTableHead/>
        {dealFinance.primaryHomeLoan && splitArray(dealFinance.primaryHomeLoan).result2.map(element =>(
            <ComponentSelector {...element} printVersion={printVersion}/>
          ))}
        </Table>
        </TableContainer>
        <Table sx={{...styles.table,mt:2}}>
          <ProformaStartAdornRow item = 'Total Monthly Payment' symbol='$' printVersion={printVersion} value = {primaryLoanCals?.total_monthly_payment} bold/>
        </Table>
        </>}
        <Box sx={{mt:2}}/>

      </SubSectionContainer>
      <Box sx={{mt:2}}/>
    </>
  )
}

export default ProformaPage1