// the total amount of money the investors will earn at the end of the deal
export const investorsProfit = (
  estimatedResaleValue,
  rentalIncome,
  closingCostCredit,
  totalExpenses,
  closingCostCreditPurchase
) => {
  const profit =
    (+estimatedResaleValue || 0) -
    (totalExpenses || 0) +
    (+rentalIncome || 0) -
    (+closingCostCredit || 0) +
    (closingCostCreditPurchase || 0);

  return profit;
};

export const totalExpenses = ({
  financeOptionsExpensesAmountReturned,
  cityPermitFees,
  titleAndEscrowFeesAmount,
  architecturalFees,
  monthlyHomeInsurance,
  monthlyHOA,
  monthlyTax,
  propertyManagementFee,
  annualMaintenance,
  miscellaneousCost,
  utilities,
  months,
  titleAndEscrowFeesResaleAmount,
  primaryLenderPointsAmount,
  secondaryLenderPointsAmount,
  dealtype,
  primaryWholesaleFees,
  secondaryWholesaleFees,
  secondaryLenderPointsOption,
  secondaryWholeSaleFeesOption,
  primaryLenderPointsOption,
  primaryWholeSaleFeesOption,
  commissionOnPurchaseOption,
  commissionOnResaleOption,
  commissionOnPurchase,
  commissionOnResale,
  closing_cost_option_purchase,
  closing_cost_option_resell,
}) => {
  const primaryLenderAmountChecked =
    dealtype !== 4 ? primaryLenderPointsAmount : 0;
  const secondaryLenderPointsAmountChecked =
    dealtype === 0 ? secondaryLenderPointsAmount : 0;
  const secondaryWholesaleFeesChecked =
    dealtype === 1 || dealtype === 3 ? secondaryWholesaleFees : 0;

  const titleAndEscrowFeesAmountR =
    +closing_cost_option_purchase === 4 ? 0 : titleAndEscrowFeesAmount;

  const titleAndEscrowFeesResaleAmountR =
    +closing_cost_option_resell === 4 ? 0 : titleAndEscrowFeesResaleAmount;

  const commissionOnPurchaseR =
    +commissionOnPurchaseOption === 4 ? 0 : commissionOnPurchase;

  const commissionOnResaleR =
    +commissionOnResaleOption === 4 ? 0 : commissionOnResale;

  const amount =
    (financeOptionsExpensesAmountReturned || 0) +
    (cityPermitFees || 0) +
    (titleAndEscrowFeesAmountR || 0) +
    (titleAndEscrowFeesResaleAmountR || 0) +
    (architecturalFees || 0) +
    (monthlyHomeInsurance || 0) * months +
    (monthlyHOA || 0) * months +
    (monthlyTax || 0) * months +
    (propertyManagementFee || 0) * months +
    (annualMaintenance || 0) +
    (utilities || 0) * months +
    (miscellaneousCost || 0) +
    (secondaryLenderPointsAmountChecked || 0) +
    (primaryLenderAmountChecked || 0) +
    (primaryWholesaleFees || 0) +
    (secondaryWholesaleFeesChecked || 0) +
    (commissionOnResaleR || 0) +
    (commissionOnPurchaseR || 0);
  return amount;
};
export const totalLiquidCapital = ({
  financeOptionsExpensesAmountReturnedForLiquidCapital,
  cityPermitFees,
  titleAndEscrowFeesForLiquidCapital,
  architecturalFees,
  monthlyHomeInsurance,
  monthlyHOA,
  monthlyTax,
  propertyManagementFee,
  annualMaintenance,
  miscellaneousCost,
  utilities,
  months,
  primaryLenderPointsAmount,
  secondaryLenderPointsAmount,
  dealtype,
  primaryWholesaleFees,
  secondaryWholesaleFees,
  secondaryLenderPointsOption,
  secondaryWholeSaleFeesOption,
  primaryLenderPointsOption,
  primaryWholeSaleFeesOption,
  commissionForLiquidCapital,
  downPayment,
}) => {
  const primaryWholeAmount =
    +primaryWholeSaleFeesOption === 1 ? primaryWholesaleFees : 0;
  const primaryLenderAmount =
    dealtype !== 4 && +primaryLenderPointsOption === 1
      ? primaryLenderPointsAmount
      : 0;
  const secondaryLenderPointsAmountCheck =
    dealtype === 0 && +secondaryLenderPointsOption === 1
      ? secondaryLenderPointsAmount
      : 0;
  const secondaryWholesaleFeesAmount =
    (dealtype === 3 || dealtype === 1) && +secondaryWholeSaleFeesOption === 1
      ? secondaryWholesaleFees
      : 0;

  const amount =
    (financeOptionsExpensesAmountReturnedForLiquidCapital || 0) +
    (cityPermitFees || 0) +
    (titleAndEscrowFeesForLiquidCapital || 0) +
    (architecturalFees || 0) +
    (monthlyHomeInsurance || 0) * months +
    (monthlyHOA || 0) * months +
    (monthlyTax || 0) * months +
    (propertyManagementFee || 0) * months +
    (annualMaintenance || 0) +
    (miscellaneousCost || 0) +
    (primaryWholeAmount || 0) +
    (primaryLenderAmount || 0) +
    (secondaryLenderPointsAmountCheck || 0) +
    (secondaryWholesaleFeesAmount || 0) +
    (downPayment || 0) +
    (commissionForLiquidCapital || 0);

  return amount;
};

export const rentalIncomeProfit = (
  rentalMonthsYearsOrMonthsChecker,
  rentalMonths,
  vacancyMonths,
  rentalIncome
) => {
  let yearsTOmonths;
  if (rentalMonthsYearsOrMonthsChecker === 1) {
    yearsTOmonths = rentalMonths * 12;
  } else {
    yearsTOmonths = rentalMonths;
  }

  const profitMonths = yearsTOmonths - vacancyMonths;
  const amount = profitMonths * rentalIncome;
  return amount;
};
