import { LoadingButton } from '@mui/lab'
import { Box, Button, Stack } from '@mui/material'
import React from 'react'

const BottomActions = ({ onSubmit, resetDispatch, load, buttons, classes, disable }) => {
    return (
        <div>
            {/* Bottom Actions */}
            <Box mt={5}>
                <Stack direction={'row'} gap={2} alignItems={'center'} justifyContent={'flex-end'}>
                    <Button disabled={disable || load} variant='outlined' color={'secondary'} onClick={() => resetDispatch()} disableElevation size='small' className={classes.btn}>{buttons[0]}</Button>
                    <LoadingButton disabled={disable} loading={load} onClick={() => onSubmit('exit')}
                        variant='outlined' color={'secondary'} disableElevation size='small' className={classes.btn}>{buttons[1]}</LoadingButton>
                    <LoadingButton disabled={disable} loading={load} variant='contained' onClick={() => onSubmit('continue')}
                        color='secondary' disableElevation size='small' className={classes.btn}>{buttons[2]}</LoadingButton>
                </Stack>
            </Box>
        </div>
    )
}

export default BottomActions