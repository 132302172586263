import React, { useState } from "react";
import { Box, Button, Typography, Card } from "@mui/material";
import useTheme from "@mui/material/styles/useTheme";
import { useNavigate } from "react-router-dom";

const useStyle = (theme) => {
  return {
    root: {
      display: "flex",
      justifyContent: "space-between",
      marginTop: "10px",
      // borderLeft:`1px solid ${theme.palette.primary.menuVerticalBar}`,
      paddingBottom: "100px",
    },
    menu: {
      // width:"30%"
      borderLeft: `1px solid ${theme.palette.primary.lightest}`,
    },
    menuItem: {
      padding: "4px 35px",
      color: theme.palette.primary.inactiveMenuColor,
      cursor: "pointer",
      paddingLeft: "34px",
      // borderRadius:"5px",
      "&:hover": {
        backgroundColor: theme.palette.primary.extraLight,
      },
    },
    menuItemActive: {
      padding: "0px 34px",
      cursor: "pointer",
      paddingLeft: "30px",
      // borderRadius:"5px",
      borderLeft: `4px solid ${theme.palette.primary.main}`,
      "&:hover": {
        backgroundColor: theme.palette.primary.extraLight,
      },
    },
    subMenuActive: {
      padding: "5px 34px",
      cursor: "pointer",
      paddingLeft: "35px",
      color: "#000",
    },
    subMenuInactive: {
      padding: "5px 35px",
      color: theme.palette.primary.inactiveMenuColor,
      cursor: "pointer",
      paddingLeft: "35px",
    },
    button: {
      textTransform: "none",
      margin: " 15px 35px",
      padding: "15px 40px",
    },
    button2: {
      textTransform: "none",
      margin: " 10px 0rem 0 20px",
      padding: "15px 20px",
      width: "9rem",
    },
    buttonGroup: {
      display: "flex",
      justifyContent: "flex-end",
      mt: 2,
    },
    contentBox: {
      width: "67%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    formCard: {
      border: `solid 1px ${theme.palette.primary.main}`,
      borderRadius: "1rem",
      height: "500px",
    },
    innerCard: {
      height: "470px",
      margin: "10px",
      overflowY: "scroll",
      overscrollBehavior: "contain",
    },
  };
};

function CardMainArea({
  menuArray,
  component: Component,
  handleEvents,
  data,
  active = 0,
  menuSelector = 0,
  setActiveTab,
  control,
  handleSave,
  generateReport,
  subMenus,
  additionalInfo,
}) {
  const theme = useTheme();
  const styles = useStyle(theme);
  const [activateSubMenu, setActivateSubMenu] = useState();
  const navigate = useNavigate();

  const handleSubMenu = (value) => {
    setActivateSubMenu(value);
  };
  const handleSaveAndExit = () => {
    handleSave(active);
    navigate("/analyze-deal");
  };
  const reloadCurrentMenu = () => {
    handleSubMenu("");
  };

  return (
    <Box sx={styles.root}>
      <Box sx={{ width: "30%" }}>
        <Box sx={styles.menu}>
          {menuArray &&
            menuArray.map((element, index) =>
              active === index ? (
                <Box>
                  <Box
                    sx={styles.menuItemActive}
                    key={element}
                    onClick={() => {
                      setActiveTab(index);
                      handleSubMenu("");
                    }}
                  >
                    <Typography variant="menuItemActive">{element}</Typography>
                  </Box>
                  {activateSubMenu && (
                    <Box>
                      {subMenus?.[element]?.map((subMenu, index2) =>
                        index2 + 1 === activateSubMenu ? (
                          <Box sx={styles.subMenuActive}>
                            <Typography variant="submenuActive">
                              {subMenu}
                            </Typography>
                          </Box>
                        ) : (
                          <Box sx={styles.subMenuInactive}>
                            <Typography
                              variant="submenuInactive"
                              onClick={() => handleSubMenu(index2 + 1)}
                            >
                              {subMenu}
                            </Typography>
                          </Box>
                        )
                      )}
                    </Box>
                  )}
                </Box>
              ) : (
                <Box
                  sx={styles.menuItem}
                  key={element}
                  onClick={() => setActiveTab(index)}
                >
                  <Typography variant="menuItem">{element}</Typography>
                </Box>
              )
            )}
        </Box>
        <Button
          variant="contained"
          sx={styles.button}
          onClick={
            +active === menuArray.length - 1
              ? generateReport
              : handleSaveAndExit
          }
        >
          {" "}
          <Typography variant="h4">{"Save & Exit"}</Typography>
        </Button>
      </Box>
      <Box sx={styles.contentBox}>
        <Card elevation={3} sx={styles.formCard}>
          <Card elevation={0} sx={styles.innerCard}>
            {/* Component receives data and update the needed component */}
            <Component
              handleEvents={handleEvents}
              data={data}
              handleSubMenu={handleSubMenu}
              activeSubMenu={activateSubMenu}
              reloadCurrentMenu={reloadCurrentMenu}
              additionalInfo={additionalInfo}
            />
          </Card>
        </Card>
        <Box sx={styles.buttonGroup}>
          {active !== 0 && (
            <Button
              variant="outlined"
              sx={styles.button2}
              onClick={() => {
                control.previous();
              }}
            >
              <Typography variant="h4">Previous</Typography>
            </Button>
          )}
          <Button
            variant="contained"
            sx={styles.button2}
            onClick={() => {
              +active === menuArray.length - 1
                ? generateReport()
                : control.next();
            }}
          >
            <Typography variant="h4">{"Next"}</Typography>
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default CardMainArea;
