export const mortgageLoanCalculator = (pv, i, n) => {
  let totalMonthlyPayment;
  let numerator = pv * i * Math.pow(1 + i, n);
  let denominator = Math.pow(1 + i, n) - 1;
  totalMonthlyPayment = numerator / denominator;

  return totalMonthlyPayment.toFixed(2);
};

export const interestOnlyMortgageCalculator = (rate, loanAmount) => {
  let rateTransformer = rate / 100 / 12;
  const interestOnlyMortgage = rateTransformer * loanAmount;

  return +interestOnlyMortgage.toFixed(2);
};

export const yearsToMonthsTransformer = (check, value) => {
  if (check === 0) {
    return +value;
  } else if (check === 1) {
    return value * 12;
  } else {
    return value === isNaN ? "" : value;
  }
};

export const estRemodelCostDownToStudPerSqrfCalculator = (ERC, EAC) => {
  // ERC = Est. Remodel Cost P/Sqft
  // EAC = Est. Addition Cost P/SqFt
  const result = (EAC + ERC) / 2;
  return result;
};

export const isNaNChecker = (value) => {
  const result = isNaN(value) ? 0 : value;
  return result;
};

export const isNaNCheckerRounded = (value) => {
  return typeof value === "number" ? value?.toFixed(2) : 0;
};
export const rateAmountConvertor = (checker, amount, sourceAmount) => {
  // rate
  // amount
  if (checker === 1) {
    return (amount / 100) * sourceAmount;
  } else {
    return amount;
  }
};
