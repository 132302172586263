import { Button, Grid, Rating, Stack, Typography } from '@mui/material';
import Building01 from '@untitled-ui/icons-react/build/esm/Building01';
import MarkerPin02 from '@untitled-ui/icons-react/build/esm/MarkerPin02';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getSubscriptionStatus } from '../utils/functions';
import CreateNewListingDialog from '../components/CreateNewListingDialog';

const MyListings = () => {
	const { listings, selectedListing } = useSelector((state) => state.auth);
	const [newListingOpen, setNewListingOpen] = useState(false);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const handleListingSelected = (listing) => {
		//set to selected listing and navigate to my-listing/selected
		dispatch({ type: 'SET_LISTING', payload: listing });
		dispatch({ type: 'SET_SUBSCRIPTIONS', payload: getSubscriptionStatus([listing.subscription]) });

		navigate('/my-listing/selected');
	};

	return (
		<>
			{newListingOpen && <CreateNewListingDialog open={newListingOpen} close={() => setNewListingOpen(false)} />}
			<Stack p='1rem' gap='1rem'>
				<Stack direction='row' justifyContent='space-between' alignItems='center' sx={{ borderBottom: '1px solid #EAECF0', paddingBottom: '1rem' }}>
					<Typography fontWeight={600} fontSize={24} color='#344054'>
						{' '}
						Business Listings
					</Typography>
					<Stack direction='row' gap='1rem' alignItems={'center'}>
						<Button color='secondary' variant='contained' onClick={() => setNewListingOpen(true)}>
							Create Listing
						</Button>
						<Button color='secondary' variant='outlined' href='/industry-professionals'>
							Claim Listing
						</Button>
					</Stack>
				</Stack>
				<Grid container spacing={2}>
					{listings.map((item) => (
						<Grid item xs={6} key={item.id}>
							<ListingCard {...item} selected={selectedListing.id === item.id} onClick={() => handleListingSelected(item)} />
						</Grid>
					))}
				</Grid>
			</Stack>
		</>
	);
};

export default MyListings;

const ListingCard = ({ selected, icon_file, company_name, bio, industry, rating, reviews, onClick, address }) => {
	return (
		<Stack
			sx={{
				background: 'white',
				border: `1px solid ${selected ? '#0D5894' : '#EAECF0'}`,
				borderRadius: '12px',
				boxShadow: '0px 1px 2px 0px #1018280D',
				padding: '1rem',
				cursor: 'pointer',
				height: '100%',
			}}
			justifyContent={'space-between'}
			onClick={onClick}
		>
			<Grid container spacing={2}>
				<Grid item xs={4}>
					<Stack height={'100%'}>
						{icon_file ? (
							<img src={icon_file} alt='listing' width='100%' style={{ aspectRatio: '1', borderRadius: '8px' }}></img>
						) : (
							<Stack
								width='100%'
								sx={{ aspectRatio: '1', borderRadius: '8px', border: '1px solid #EAECF0' }}
								alignItems='center'
								justifyContent={'center'}
							>
								<Building01 className='iconer' style={{ stroke: '#475467', width: '50px', height: '50px' }} />
							</Stack>
						)}
					</Stack>
				</Grid>
				<Grid item xs={8}>
					<Stack sx={{ height: '100%' }}>
						<Typography color='#0D5894' fontSize={14} fontWeight={600} sx={{ textTransform: 'capitalize', marginBottom: '5px' }}>
							{industry || 'No Industry selected'}
						</Typography>
						<Typography color='#101828' fontWeight={500} fontSize={18} sx={{ marginBottom: '5px' }}>
							{company_name || 'Company Name not set'}
						</Typography>
						{!!rating && (
							<Stack direction='row' gap='1rem' sx={{ marginBottom: '5px' }}>
								<Rating size='small' value={rating} readOnly precision={0.1} />
								<Typography>{rating}</Typography>
								<Typography color='#475467'>{reviews.length} reviews</Typography>
							</Stack>
						)}
						<Typography
							sx={{
								overflow: 'hidden',
								textOverflow: 'ellipsis',
								display: '-webkit-box',
								WebkitLineClamp: '2',
								WebkitBoxOrient: 'vertical',
								color: '#475467',
								fontSize: '15px',
								fontWeight: 400,
							}}
						>
							{bio || 'No description set'}
						</Typography>
						<Stack direction='row' sx={{ marginTop: 'auto', marginBottom: '6px' }}>
							<Stack direction='row'>
								<MarkerPin02 className='iconer' style={{ stroke: '#475467' }} />
								<Typography color='#475467'>{address?.replace(', USA', '') || 'Not Set'}</Typography>
							</Stack>
						</Stack>
					</Stack>
				</Grid>
			</Grid>
		</Stack>
	);
};
