import React from "react";
import { Box, Card, Typography,  Divider } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const useStyles = (theme) => {
  return {
    root: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    card: {
      padding: "1.875rem",
      width: "100vw",
      height:"22rem",
      marginRight:"0rem",
      border: `solid 1px ${theme.palette.primary.main}`,


    },
    close: {
      display: "flex",
      justifyContent: "flex-end",
    },
    buttonContainer: {
      display: "flex",
      width: "18.75rem",
    },
    monthly: {
      width: "50%",
      textAlign: "center",
      padding: "0.625rem 1.875rem",
      border: `solid 1px ${theme.palette.primary.main}`,
      borderRadius: "5px 0 0 5px",
      cursor: "pointer",
    },
    yearly: {
      width: "50%",
      textAlign: "center",
      padding: "0.625rem 1.875rem",
      border: `solid 1px ${theme.palette.primary.main}`,
      borderRadius: "0 5px 5px 0",
      cursor: "pointer",
    },
    active:{
      backgroundColor: theme.palette.primary.main,
      color: "white",
    },
    inactive:{
      backgroundColor: "white",
      color: theme.palette.primary.main,
    }
  };
};

function PaymentSelectedOffer({
  offerPeriod,
  offer,
  handleOfferPeriod
}) {
  const theme = useTheme();
  const styles = useStyles(theme);



  return (
    <Box sx={styles.root}>
      <Card sx={styles.card}>

        <Box >
          <Typography
            variant="h6"
            sx={{fontWeight:700}}
          >
           { +offer === 0 ?'Pay As You Go' :'Package Name'}
          </Typography>
          <Typography
            variant="h6"
            sx={{fontWeight:500}}
          >
            {deals?.[+offer]?.packageName}
          </Typography>
        </Box>
        <Box sx={{mt:2}}>
          <Typography variant="h5" sx={{fontWeight:700}}>Order Summary</Typography>
        </Box>
        <Divider
          sx={{
            height: "2px",
            backgroundColor:"#1378A5" ,
            margin: "0.937rem 0",
          }}
        />

        { +offer !==0 &&
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Box sx={styles.buttonContainer}>
            <div
              style={
                +offerPeriod === 0
                  ? { ...styles.monthly, ...styles.active }
                  : { ...styles.monthly, ...styles.inactive }
              }
              onClick={() => {
                handleOfferPeriod(0);
              }}
            >
              <Typography variant="buttonFont">Monthly</Typography>
            </div>
            <div
              style={
                +offerPeriod === 1
                  ? { ...styles.yearly, ...styles.active }
                  : { ...styles.yearly, ...styles.inactive }
              }
              onClick={() => {
                handleOfferPeriod(1);
              }}
            >
              <Typography variant="buttonFont">Annually</Typography>
            </div>
          </Box>
        </Box>}

        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mt:2
            }}
          >
            <Typography variant="dealCta">{+offer === 0 ? 'Price /deal':+offerPeriod === 1 ? "Price /year" : 'Price /month' }</Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="dealCost" sx={{ marginRight: "10px" }}>
                {+offer === 0?  deals[+offer]?.totalMonth : +offerPeriod === 1 ? deals[+offer]?.totalYear : deals?.[+offer]?.pricePerMonth }
              </Typography>
              <Typography variant="dealCostUnit">{ +offer === 0 ? ' /deal' : 
              +offerPeriod === 1 ? ' /year' :' /month'}</Typography>
            </Box>
          </Box>
          <Box sx={{display:"flex",justifyContent:"flex-end",mt:1}}>
            <Typography sx={{color:"grey"}}>
              {+offer ===0 ?'':
                +offerPeriod===1 ? 'Billed in the 15th of Januaty each year':
                'Billed on the 4th of each month'
              }
            </Typography>
          </Box>
        </Box>
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mt:2
            }}
          >
            <Typography variant="dealCta">Total</Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="dealCost" sx={{ marginRight: "0rem",color:"#1378A5" }}>
              {+offer===0? deals?.[offer]?.pricePerMonth : +offerPeriod === 1 ? deals?.[offer]?.totalYear : deals?.[offer]?.pricePerMonth }
              </Typography>
            </Box>
          </Box>
        </Box>

      </Card>
    </Box>
  );
}

export default PaymentSelectedOffer;


const deals = {
  0:{
    pricePerMonth: "$ 79.00",
    totalMonth:"$ 79.00",
    packageName:"Save your time & pay as you fo per deal",
    code:"price_1LYAlYLhxZuiEljHmHCyNYWC"
  },
  1:{
    pricePerMonth: "$ 99.00",
    totalMonth:"$ 99.00",
    totalYear:"$ 1089",
    monthCode:"price_1LLm0SLhxZuiEljHlyiLOEXj",
    yearCode:"price_1LYAkJLhxZuiEljH8KWiAPoE,",
    packageName:"Do it yourself & Download our Deal Analyzer Software"
  },
  2:{
    pricePerMonth: "$ 199.00",
    totalMonth:"$ 199.00",
    totalYear:"$2189",
    monthCode:"price_1LmyvNLhxZuiEljHlsayCOUe",
    yearCode:"price_1LmywpLhxZuiEljHt74sk5AP",
    packageName:"Save your time we will Analyze All of your RealEstate Deals Unlimited!!"
  }
}
