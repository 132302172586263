import { loanSubTypes, loanTypes } from "../../../utils/numericToStringConvert";

export const getPrimaryMortgage = ({ analyzerData, resultData }) => {
  const { pmi_value, primary_include_pmi } = analyzerData?.financeOptions || {};

  //============ dynamic pmi value =================//
  const pmiValue = primary_include_pmi
    ? { item: "PMI Percentage", value: pmi_value, render: "percent" }
    : {};
  return [
    {
      item: "Loan Type",
      value:
        loanTypes?.[+analyzerData?.financeOptions?.primary_loan_type] || "N/A",
      render: "simple",
    },
    {
      item: "Purchase Price",
      value: analyzerData?.financeOptions?.primary_purchase_price,
      render: "dollar",
    },
    {
      item: "Lender Points",
      value: analyzerData?.financeOptions?.primary_lender_points,
      render: "points",
    },
    {
      item: "Wholesale Fee",
      value: analyzerData?.financeOptions?.primary_wholesale_fee,
      render: "dollar",
    },
    pmiValue,
    {
      item: "Down Payment",
      value: analyzerData?.financeOptions?.primary_down_payment,
      render: "percent_dollar",
      rate:
        analyzerData?.financeOptions?.primary_down_payment_dollar_or_percent ||
        0,
      referenceValue: analyzerData?.financeOptions?.primary_purchase_price,
    },
    {
      //from calculations
      item: "Loan Amount",
      value:
        resultData?.primary_finance_option_summary?.interest_only_mortgage_loan
          ?.mortage_loan_amount,
      render: "dollar",
    },
    {
      item: "Interest Rate",
      value: analyzerData?.financeOptions?.primary_annual_interest_rate,
      render: "percent",
    },
    {
      item: "Interest Only Term",
      value: analyzerData?.financeOptions?.primary_interest_only_term,
      render: "months",
    },
    {
      item: "Interest Only Payment",
      value:
        resultData?.primary_finance_option_summary?.interest_only_mortgage_loan
          ?.interest_only_payment,
      render: "dollar",
    },
    {
      item: "Principal + Interest Payment",
      value:
        resultData?.primary_finance_option_summary?.interest_only_mortgage_loan
          ?.principal_plus_interest_payment,
      render: "dollar",
    },
  ];
};
export const getSecondaryMortgage = ({ analyzerData, resultData }) => {
  return [
    {
      item: "Loan Type",
      value:
        loanTypes?.[+analyzerData?.financeOptions?.secondary_loan_type] ||
        loanTypes?.[0],
      render: "simple",
    },
    {
      item: "The Loan is",
      value:
        loanSubTypes[+analyzerData?.financeOptions?.secondary_sub_loan_type],
      render: "simple",
    },
    {
      item: "Remodel Cost",
      value: resultData?.remodel_cost?.estimated_total_remodel_cost
        ? resultData?.remodel_cost?.estimated_total_remodel_cost
        : resultData?.remodel_cost
            ?.est_remodel_cost_down__to_studs_plus_additional_sqft,
      render: "dollar",
    },
    {
      item: "Lender Points",
      value: analyzerData?.financeOptions?.secondary_lender_points,
      render: "points",
    },
    {
      item: "Down Payment",
      value: analyzerData?.financeOptions?.secondary_down_payment,
      render: "percent_dollar",
      rate: analyzerData?.financeOptions?.secondary_dollar_or_percent || 0,
      referenceValue: resultData?.remodel_cost?.estimated_total_remodel_cost,
    },
    {
      item: "Loan Amount", //take from calculations
      value:
        resultData?.secondary_finance_option_summary
          ?.interest_only_mortgage_loan?.mortage_loan_amount,
      render: "dollar",
    },
    {
      item: "Interest Rate",
      value:
        analyzerData?.financeOptions?.secondary_interest_rate ||
        analyzerData?.financeOptions?.secondary_annual_interest_rate,
      render: "percent",
    },
    {
      item: "Interest Only Term",
      value: analyzerData?.financeOptions?.secondary_interest_only_term,
      render: "months",
    },
    {
      item: "Interest Only Payment",
      value:
        resultData?.secondary_finance_option_summary
          ?.interest_only_mortgage_loan?.interest_only_payment,
      render: "dollar",
    },
    {
      item: "Principal + Interest Payment",
      value:
        resultData?.secondary_finance_option_summary
          ?.interest_only_mortgage_loan?.principal_plus_interest_payment,
      render: "dollar",
    },
  ];
};
