import { Badge as Badged } from '@mui/material';
import { styled } from '@mui/styles';

const Badge = styled(Badged)(({ theme, isActive = true }) => ({
	'& .MuiBadge-badge': {
		background: isActive ? '#DBE9F3' : '#F9FAFB',
		border: '1px solid #A3C5E0',
		borderColor: isActive ? '#A3C5E0' : '#EAECF0',
		color: isActive ? '#0D5894' : '#344054',
	},
}));

const BadgeForQuotes = ({ badgeContent, isActive }) => <Badge color='secondary' badgeContent={badgeContent} isActive={isActive} showZero max={99} />;

export default BadgeForQuotes;
