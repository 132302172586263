export const createDocumentTitle = (agent)=>{
    const date = Date.now()
    return `deal_report_${agent?.role}_${agent?.license}_${date}`

}
export const removeNaNAndInfinite = (value) =>{
    try {
        if(+value && isFinite(+value) && !isNaN(+value)){
            return isFloat(+value) ? value.toFixed(2) : value
        }
        return  0
    } catch (error) {
        return value
    }


}
function isFloat(n){
    return Number(n) === n && n % 1 !== 0;
  }