import { Avatar, Button, createTheme, Divider, Grid, IconButton, Stack, ThemeProvider, Typography, Link as NLink } from '@mui/material';
import { makeStyles, styled } from '@mui/styles';
import React, { Fragment } from 'react';
import { Link as RLink } from 'react-router-dom';
import { ReactComponent as FBIcon } from '../images/facebookfillable.svg';
import { ReactComponent as IGIcon } from '../images/instagramfillable.svg';
import { ReactComponent as LIIcon } from '../images/linkedInfillable.svg';
import { ReactComponent as TWIcon } from '../images/twitterfillable.svg';
import { ReactComponent as YTIcon } from '../images/youtubefillable.svg';
import { theme } from '../theme';
import { Spoiler } from './IndustryProfessionalId';
import RequestAQuote from '../components/RequestAQuote';

//! https://www.figma.com/design/Q83dnbULW6YZeYlFf9qbZX/Premier-Agent?node-id=733-2876&t=qyywxefvwZZLfa7w-4

const pageTheme = createTheme({
	...theme,
	typography: {
		fontFamily: 'Poppins, serif',
	},
	palette: {
		primary: {
			main: '#AD580A',
		},
		secondary: {
			main: '#000',
		},
		text: {
			main: '#000',
		},
		paper: {
			main: '#fff',
		},
	},
	primaryColor: '#C43338',
	secondaryColor: '#000',
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					textTransform: 'capitalize',
					borderRadius: '0',
				},
			},
		},
	},
});

const socials = [
	{
		key: 'facebook',
		Icon: <FBIcon fill='#FFFFFF' />,
	},
	{
		key: 'twitter',
		Icon: <TWIcon fill='#FFFFFF' />,
	},
	{ key: 'instagram', Icon: <IGIcon fill='#FFFFFF' /> },
	{
		key: 'linkedin',
		Icon: <LIIcon fill='#FFFFFF' />,
	},
	{
		key: 'youtube',
		Icon: <YTIcon fill='#FFFFFF' />,
	},
];

const mainPaddingX = '10rem';

const navItems = [
	{ label: 'Home', value: '#home' },
	{ label: 'Request A Quote', value: '#quote' },
	{ label: 'About Us', value: '#about-us' },
];
const Link = styled(RLink)(({ theme }) => ({
	cursor: 'pointer',
	color: theme.primaryColor,
}));

const useStyles = makeStyles((theme) => ({
	root: {
		fontFamily: 'Poppins, serif',
		scrollMarginTop: '100px',
		minHeight: '100vh',
	},
	leftLinks: {
		display: 'flex',
		alignItems: 'center',
		color: '#C4C4C4 !important',
		fontFamily: 'Poppins, serif',
		fontWeight: 500,
		cursor: 'pointer',
	},
	main: {
		background: 'linear-gradient(219.84deg, #474747 4.14%, #222222 44.22%)',
		width: '100%',
		height: '100vh',
		position: 'relative',
	},

	whiter: {
		backgroundColor: '#F6F6F6CC',
		padding: `2rem ${mainPaddingX}`,
	},
}));

const services = [
	{
		image: 'https://bpooperationsmain.s3.eu-west-2.amazonaws.com/ind-professional/temp7con1.jpg',
		title: 'Industrial',
		text: `Check out our best service you can possibly orders in building your company and don't forget to ask via our email or our customer service if you are interested in using our services`,
	},
	{
		image: 'https://bpooperationsmain.s3.eu-west-2.amazonaws.com/ind-professional/temp7con2.jpg',
		title: 'Commercial',
		text: `Our experience building in the Commercial field includes Showrooms, Supermalls and Office Buildings`,
	},
	{
		image: 'https://bpooperationsmain.s3.eu-west-2.amazonaws.com/ind-professional/temp7con3.jpg',
		title: 'Residential',
		text: `Residential development is the beginning that has shaped us to this day. Our development includes Houses & Apartments`,
	},
];

const IndProfTemp6 = ({ address, icon_file, company_name = 'checker', id, formatted_phone_number, user, bio, ...profDetails }) => {
	const classes = useStyles();
	return (
		<ThemeProvider theme={pageTheme}>
			<Stack className={classes.root} gap='10rem'>
				<Stack className={classes.main} px={mainPaddingX} justifyContent={'space-between'} py='2rem' alignItems='flex-start'>
					<img
						src='https://bpooperationsmain.s3.eu-west-2.amazonaws.com/ind-professional/whitehouse.png'
						alt='background'
						style={{ position: 'absolute', right: 0, top: 0, height: '100vh', transform: 'rotateY(180deg)' }}
					/>
					<Stack direction='row' className={classes.withgrad} py={`1rem`} justifyContent='space-between' width={'100%'}>
						<Stack direction='row' gap='1rem' alignItems={'center'}>
							<Avatar src={icon_file} sx={{ backgroundColor: 'white' }} />
							<Typography fontWeight={700} fontSize='30px' color='#AD580A'>
								{company_name}
							</Typography>
						</Stack>
						<Stack direction='row' gap='1rem'>
							{navItems.map((item) => (
								<NLink href={item.value} className={classes.leftLinks} underline='none' key={item.value}>
									{item.label}
								</NLink>
							))}
						</Stack>
					</Stack>

					<Stack color='white' mt='5rem'>
						<Typography fontSize={70} fontWeight={500}>
							We Provide
						</Typography>
						<Typography fontSize={70} fontWeight={500}>
							Architectural design
						</Typography>
						<Typography fontSize={70} fontWeight={500}>
							and Construction
						</Typography>
					</Stack>
					<Button variant='contained' sx={{ borderRadius: 0 }}>
						Request A Quote
					</Button>
				</Stack>

				<Stack className={classes.whiter} gap='2rem'>
					<Grid container spacing={2}>
						<Grid item xs={6}>
							<Typography color={'#242527'} fontWeight={600} fontSize={55}>
								Our Excellent Services
							</Typography>
						</Grid>
						<Grid item xs={6}>
							<Typography fontWeight={500} fontSize={20} color='#C4C4C4'>
								Check out our best service you can possibly orders in building your company and don't forget to ask via our email or our customer
								service if you are interested in using our services
							</Typography>
						</Grid>
					</Grid>
					<Grid container spacing={2} mt='3rem'>
						{services.map((item) => (
							<Grid item xs={4} key={item.title}>
								<Stack gap='1rem'>
									<img
										alt={item.title}
										src={item.image}
										style={{ width: '100%', aspectRatio: '1', objectFit: 'cover', border: '20px solid white' }}
									/>
									<Typography color={'#242527'} fontWeight={600} fontSize={34}>
										{item.title}
									</Typography>
									<Typography fontWeight={500} fontSize={20} color='#C4C4C4'>
										{item.text}
									</Typography>
								</Stack>
							</Grid>
						))}
					</Grid>
				</Stack>
				<Grid container spacing={2} px={mainPaddingX}>
					<Grid item xs={6}>
						<Stack id='about-us'>
							<Typography color={'#242527'} fontWeight={600} fontSize={55}>
								Our Story <br /> Who we are
							</Typography>
							<Spoiler
								maxWidth='100%'
								text={bio}
								buttonProps={{ variant: 'contained', color: 'primary', sx: { borderRadius: '0', marginTop: '2rem' } }}
							/>
						</Stack>
					</Grid>
					<Grid item xs={6}>
						<Stack p='2rem' sx={{ boxShadow: '0px 13.33px 20px 0px #9797971F', background: 'white', width: '80%' }} alignItems={'center'}>
							<img
								alt='building'
								src='https://bpooperationsmain.s3.eu-west-2.amazonaws.com/ind-professional/ind7aboutus.jpg'
								style={{ width: '100%', aspectRatio: '1', transform: 'rotateY(180deg)', objectFit: 'cover' }}
							/>
						</Stack>
					</Grid>
				</Grid>
				<Stack padding={`3rem ${mainPaddingX}`}>
					<Typography fontSize='50px' textAlign={'center'} mb='2rem' id='quote' fontWeight={700} color='#00215B'>
						Want A Quote?
					</Typography>
					<RequestAQuote withMargin={false} id={id} />
				</Stack>
				<Stack sx={{ paddingX: mainPaddingX, background: 'linear-gradient(219.84deg, #474747 4.14%, #222222 44.22%)' }} mt='3rem'>
					<Grid container spacing={2} my='5rem' className={classes.color}>
						<Grid item xs={12}>
							<Divider sx={{ background: 'white' }} />
						</Grid>
						<Grid item xs={4}>
							<Stack gap='1rem'>
								<Avatar src={icon_file} sx={{ backgroundColor: 'white' }} />

								<Typography fontWeight={700} fontSize='30px' color='#ffffff'>
									{company_name}
								</Typography>
								<Stack direction='row' gap='1rem'>
									{socials.map((social) => (
										<Fragment key={social.key}>
											{profDetails?.[social.key] && (
												<IconButton href={profDetails?.[social.key]} target='_blank'>
													{social.Icon}
												</IconButton>
											)}
										</Fragment>
									))}
								</Stack>
							</Stack>
						</Grid>
						<Grid item xs={4}>
							<Stack gap='1rem'>
								<Typography fontWeight={600} fontSize='20px' color='white'>
									{address}
								</Typography>
								<Typography sx={{ cursor: 'pointer' }} component='a' href={`tel: ${formatted_phone_number}`} color='white'>
									Phone: {formatted_phone_number}
								</Typography>
								<Typography component='a' href={`mailto: ${user?.email}`} sx={{ cursor: 'pointer' }} color='white'>
									Email: {user?.email}
								</Typography>
							</Stack>
						</Grid>
						<Grid item xs={4}>
							<Stack gap='1rem'>
								<Typography fontWeight={600} fontSize='20px' color='white'>
									Navigation
								</Typography>
								{navItems.map((item) => (
									<NLink href={item.value} underline='none' key={item.value} style={{ fontWeight: 400, color: 'white', cursor: 'pointer' }}>
										{item.label}
									</NLink>
								))}
							</Stack>
						</Grid>

						<Grid item xs={12} mt='3rem'>
							<Stack direction={'row'} justifyContent={'space-between'}>
								<Typography color='white'>&copy; {new Date().getFullYear()} BPO Tech All rights reserved.</Typography>
								<Stack direction='row' gap='1rem'>
									<Link to='/privacy-policy' style={{ color: 'white' }}>
										Privacy Policy
									</Link>
									<Link to='/terms-of-service' style={{ color: 'white' }}>
										Terms and Conditions
									</Link>
								</Stack>
							</Stack>
						</Grid>
					</Grid>
				</Stack>
			</Stack>
		</ThemeProvider>
	);
};

export default IndProfTemp6;
