export const purchasingClosingCost = (
    titleAndEscort,
    taxes,
    carryingMonths,
    wholesaleFees,
    permitAndImpactFees
) => {
    // titleAndEscort,
    // taxes,
    // carryingMonths,
    // wholesaleFees,
    // permitAndImpactFees,
    const cost = (titleAndEscort || 0) + ((taxes || 0) * carryingMonths) + (permitAndImpactFees || 0)
        + (wholesaleFees || 0)
    return cost
};





export const resaleClosingCost = (titleAndEscort, commissionOnResale) => {
    return ((titleAndEscort || 0) + (commissionOnResale || 0))
}
