import { styled, Tooltip, tooltipClasses } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: theme.palette.common.white,
		color: 'rgba(0, 0, 0, 0.87)',
		boxShadow: theme.shadows[1],
		fontSize: 14,
		padding: '5px',
	},
}));

const Lockable = ({ subscribed, children, link, extLink, title = 'Click to upgrade to Premier Agent' }) => {
	const navigate = useNavigate();
	if (subscribed) return children;
	return (
		<>
			<LightTooltip title={title}>
				<div
					onClick={() => {
						if (!extLink) navigate(link);
						else {
							window.open(extLink, '_blank');
						}
					}}
					style={{ opacity: '0.5', cursor: 'pointer' }}
				>
					{children}
				</div>
			</LightTooltip>
		</>
	);
};

export default Lockable;
