import React from 'react'
import FlatRatePercentageInput from '../atomicComponents/FlatRatePercentageInput'
import { Box, FormControl, InputAdornment, OutlinedInput, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import useProformaData from '../hooks/dealReporthooks/useProformaData'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import commaSeparate from '../utils/commaSeparate'
import StyledLabel from '../atomicComponents/StyledLabel'
import HtmlToolTip from '../atomicComponents/HtmlToolTip'

const useStyles = (theme) => {
  return {
    root: {
      margin: "1.25rem"
    },
    appreciation: {
      display: "flex",
      flexDirection: "column"
    },
    rates: {
      marginBottom: "1.875rem"
    },
    typography: {
      '&:hover': {
        textDecoration: "underline"
      },
      cursor: "pointer"
    },
    capRate: {
      display: "flex",
      alignItems: "center",
      color: theme.palette.primary.main,
      margin: "1.25rem 0",
      padding: "10px 0"
    }, input: {
      display: "flex",
      flexDirection: "column",
      // backgroundColor: 'blue'

    }

  }
}

function PropertyManagement({ handleEvents, data }) {
  const theme = useTheme()
  const styles = useStyles(theme)
  const { fetchResult } = useProformaData({ noAutoLoad: true })
  const resultData = useSelector(state => state.straightAestheticRemodel?.dealAnalyzerResults)

  const propertyManagementStaticData = {
    withRates: [
      {
        label: 'Monthly Property Management Fees',
        toolTipText: 'Property Management Fees are for a property management company to manage your rental',
        lowerLabel: 'Typically, Property management fees are 7%-10% of the total rental income per year.',
        name: 'property_management_fees',
        rate: "property_management_fees_dollar_or_percent",
        lower: `Usually charge between 6-12% of one month's rent depending on the services offered and the location of the property`,
        referenceValue: resultData?.rentalIncome?.monthly_rental_income
      },
      // {
      //   label: 'Annual Maintenance',
      //   toolTipText: 'The ongoing cost of keeping the property in good condition and to prevent major issues in the future',
      //   name: 'annual_maintenance',
      //   rate: "annual_maintenance_dollar_or_percent",
      //   referenceValue: resultData?.rentalIncome?.rental_income

      // }
    ],
    withoutRates: {
      label: 'Appreciation'
    },
    capRateToolTipText: "Add items and their estimated dollar value individually "

  }


  const propertyManagementRateHandler = (value) => {

    return handleEvents({ property_management_fees_dollar_or_percent: value });
  };
  const annualMaintenanceRateHandler = (value) => {
    return handleEvents({ annual_maintenance_dollar_or_percent: value });
  };
  const handlerArray = [propertyManagementRateHandler, annualMaintenanceRateHandler]
  const referenceValues = [
    resultData?.rental_income?.monthly_rental_income,
    resultData?.rental_income?.rental_income,
  ]
  useEffect(() => {
    fetchResult()
    // NOTE: Run effect once on component mount, please
    // recheck dependencies if effect is updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps   
  }, [])



  //========================Property Management Change=============================//
  const handleValues = (e) => {
    let value = e.target.value;
    if (value) {
      value = Number.parseInt(value.split(",").join(""));
      if (isNaN(value)) {
        return;
      }
      handleEvents({ annual_maintenance: value });
      // console.log(e.target.name)
    } else {
      handleEvents({ annual_maintenance: value });
    }
  };

  //======================================================================
  //console.log(data)

  return (
    <>
      <Box sx={styles.root}>
        {propertyManagementStaticData.withRates.map((element, index) => (
          <>
            <Box sx={styles.rates}>
              <FlatRatePercentageInput
                title={element.label}
                name={element.name}
                rate={data?.[element?.rate] ? data?.[element?.rate] : 0}
                rateHandler={handlerArray[index]}
                handler={handleEvents}
                toolTipText={element.toolTipText}
                data={data}
                referenceValue={referenceValues?.[index]}
              />
            </Box>
            {element.lower && <Box sx={{ mt: -2, mb: 5 }}><Typography variant='h6'>{element.lower}</Typography></Box>}


          </>
        ))}


      </Box>
      <>

      </>
      <Box sx={styles.root}>
        <Box>
          <StyledLabel label='Annual Maintenance' />
          {<HtmlToolTip content={'The ongoing cost of keeping the property in good condition and to prevent major issues in the future'} />}
        </Box>
        {otherCostsStaticData.map((element, index) => (
          <Box>
            <Box sx={styles.input}>

              <FormControl
                sx={{
                  // width: "60rem",
                  minWidth: "18.75rem",
                  marginRight: "5px",
                }}
              >
                {index === 1 && ""

                }
                {index === 0 && <OutlinedInput
                  fullWidth

                  onChange={handleValues}
                  value={commaSeparate(data?.annual_maintenance
                  )}
                  placeholder="0"
                  margin="normal"
                  inputProps={{ style: { fontSize: "1rem" } }}
                  startAdornment={
                    <InputAdornment position="start">
                      {" "}
                      <Typography variant="adornment" color="secondary">
                        {" "}
                        $
                      </Typography>
                    </InputAdornment>
                  }
                />}
              </FormControl>
              {element?.noticeLabel && (
                <Typography variant="h6" sx={{ marginTop: "1rem" }} >
                  {element.noticeLabel}
                </Typography>
              )}
            </Box>
          </Box>
        ))}
      </Box>
    </>
  );
}

export default PropertyManagement


const otherCostsStaticData = [
  { label: 'Miscellaneous' },

]