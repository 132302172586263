import React, { useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import base from '../../apis';
import { useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { formatNumber } from '../../utils/functions';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useOutletContext } from 'react-router-dom';
import { toast } from 'react-toastify';
const useStyles = makeStyles((theme) => ({
	root: {
		height: '70vh',
	},
	ext: {
		color: theme.secondaryColor,
		cursor: 'pointer',
	},
}));

const columns = [
	{ field: 'date', headerName: 'Date', flex: 1 },
	{ field: 'carddetails', headerName: 'Card Details', flex: 1 },
	{ field: 'amount', headerName: 'Amount', flex: 1 },
	{
		field: 'link',
		headerName: 'Receipt',
		renderCell: (item) => (
			<OpenInNewIcon
				sx={{ color: 'var(--primary-color)', cursor: 'pointer' }}
				onClick={() => window.open(item.value)}
				title='Click to view receipt'
			/>
		),
		description: 'Click to view receipt',
		sortable: false,
	},
];

function SubscriptionHistory() {
	const classes = useStyles();
	const [rows, setRows] = useState([]);
	const [setIsLoading] = useOutletContext();

	useEffect(() => {
		setIsLoading(true);
		base
			.get('/register/get_payment_history/')
			.then(({ data }) => {
				const outData = data[0].map((item) => ({
					id: item.id,
					date: new Date(item.created * 1000).toLocaleDateString(),
					carddetails: `${item.payment_method_details.card.brand} - **** **** **** ${item.payment_method_details.card.last4}`,
					amount: formatNumber(item.amount / 100),
					link: item.receipt_url,
				}));

				setRows(outData);
			})
			.catch((err) => {
				toast.error('Could not get subscriptions');
			})
			.finally(() => setIsLoading(false));
	}, [setIsLoading]);
	return (
		<div className={classes.root}>
			<DataGrid columns={columns} rows={rows} pageSize={15} disableColumnFilter disableColumnMenu />
		</div>
	);
}

export default SubscriptionHistory;
