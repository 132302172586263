import React from 'react';
import { makeStyles } from '@mui/styles';
import { Avatar, Box, Button, Grid, TextField } from '@mui/material';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
	root: {
		padding: '20px',
		minHeight: '60vh',
	},
}));

function ClientProfile() {
	const classes = useStyles();
	const auth = useSelector((state) => state.auth);




	return (
		<>
			<Box className={classes.root}>
				<h1>Client Profile</h1>
				<Grid container spacing={2}>
					<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
						<Avatar sx={{ width: 150, height: 150, marginX: 'auto' }} />
					</Grid>
					<Grid item xl={9} lg={12} md={12} sm={12} xs={12} display='flex' gap='10px' flexDirection='column'>
						<Grid container display='flex' alignItems='flex-end' spacing={2}>
							<Grid item xl={3} lg={4} md={4} sm={4} xs={4}>
								First Name
							</Grid>
							<Grid item xl={9} lg={8} md={8} sm={8} xs={8}>
								<TextField value={auth?.first_name} disabled variant='standard' size='small' />
							</Grid>
						</Grid>
						<Grid container spacing={2} display='flex' alignItems='center'>
							<Grid item xl={3} lg={4} md={4} sm={4} xs={4}>
								Last Name
							</Grid>
							<Grid item xl={9} lg={8} md={8} sm={8} xs={8}>
								<TextField value={auth?.last_name} disabled variant='standard' />
							</Grid>
						</Grid>
						<Grid container spacing={2} display='flex' alignItems='center'>
							<Grid item xl={3} lg={4} md={4} sm={4} xs={4}>
								Phone Number
							</Grid>
							<Grid item xl={9} lg={8} md={8} sm={8} xs={8}>
								<TextField value={auth?.phone} disabled variant='standard' />
							</Grid>
						</Grid>
						<Grid container spacing={2} display='flex' alignItems='center'>
							<Grid item xl={3} lg={4} md={4} sm={4} xs={4}>
								Email
							</Grid>
							<Grid item xl={9} lg={8} md={8} sm={8} xs={8}>
								<TextField value={auth?.email} disabled variant='standard' />
							</Grid>
						</Grid>
					</Grid>
					<Grid item xl={3} lg={12} md={12} sm={12} xs={12}>
						<Button href='my-subscriptions' variant='contained' color='secondary'>
							View Subscriptions
						</Button>
					</Grid>
					{/* <Grid item xl={12} sm={12} md={12} lg={12} xs={12}>
						<h1 id='homes' style={{ scrollMarginTop: '100px' }}>
							Saved Homes
						</h1>
						<Grid container spacing={2} xl={12} sm={12} md={12} lg={12}>
							{isLoading ? (
								[1, 2, 3, 4, 5, 6].map((ld) => {
									return (
										<Grid item xs={12} sm={6} md={4} lg={3} key={ld}>
											<LoadingPropertyItem />
										</Grid>
									);
								})
							) : data.length > 0 ? (
								data?.map((item) => {
									return (
										<Grid item xs={12} sm={6} md={4} lg={4} key={item?.id}>
											<ListingCard
												listDate={item.list_date}
												mlsId={item.property_id}
												status={item.status}
												address={item.address}
												price={Number(item.price).toLocaleString()}
												image={item.image}
												bed={item.bedrooms}
												bath={item.baths}
												area={item.area}
												setData={(id) => setData(data.filter((item) => item.id !== id))}
												id={item.id}
											/>
										</Grid>
									);
								})
							) : (
								<Box
									mt={3}
									sx={{
										width: '100%',
										padding: '3rem',
										display: 'flex',
										flexDirection: 'column',
										justifyContent: 'center',
										alignItems: 'center',
										height: '100%',
									}}
								>
									<img src={HomeIMG} style={{ marginBottom: '1rem', width: '100px', height: '100px' }} alt='home' />
									<Typography color='GrayText' textAlign={'center'}>
										Sorry, no saved listings
									</Typography>
									<Button href={process.env.REACT_APP_BPOHOMESREALTY_URL} target='_blank' variant='outlined' color='secondary'>
										Click to view listings
									</Button>
								</Box>
							)}
						</Grid>
					</Grid> */}
				</Grid>
			</Box>
		</>
	);
}

export default ClientProfile;

