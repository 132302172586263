import React from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Typography } from '@mui/material';
const useStyles = makeStyles((theme) => ({
	root: {
		minHeight: '60vh',
		padding: '4rem 8rem',
		[theme.breakpoints.down('md')]: {
			padding: '20px',
		},
	},
	content: {
		textAlign: 'justify',
		fontWeight: 300,
	},
}));

const headers = [
	{
		subs: [
			{
				content:
					'BPO Tech Report Agent is a subscription service & an exclusive platform for Real Estate Brokers & Agents to advertise Real Estate Services on bpotech.io. The BPO Report Agent is a web based service application. ',
				render: false,
			},
			{
				content:
					'BPO Tech Report Agents are advertised as Report Agents on the BPO Tech site. Once the BPO Tech Report Agent subscriber is only assigned & advertised to write BPO reports in the Agents designated area of expertise identified by BPO Report Agent subscriber.',
				render: false,
			},
			{
				content:
					'BPO Tech Inc Data and all personally uploaded Data by BPO Tech Report Agent subscribers may vary and BPO Tech does not guarantee the final outcome of any leads generated or the quality of the leads from such Data or marketing services.',
				render: false,
			},
			{
				content:
					'The BPO Tech Report Agent platform is strictly meant to assist Real Estate Agents and Brokers to generate business leads to Buyers & Sellers along with assisting Buyers, Sellers and consumers in making Real Estate related decisions.',
				render: false,
			},
			{
				content:
					'BPO Tech Report Agent will be advertised at BPO Tech Inc discretion in which any such advertisement may vary from time to time. BPO Tech does not guarantee any leads, types of ads and or sales from any such advertisement on bpotech.io, or from any other Social Media sites and or 3rd party affiliated sites.',
				render: false,
			},
			{
				content:
					'BPO Tech Inc has developed a proprietary platform for BPO Tech Report Agents to assist Agents and Brokers managing leads but in no way does BPO Tech Inc, bpotech.io or any of BPO Tech representatives manage the follow up of such leads provided by BPO Tech Inc. Follow up of leads are to be handled strictly by BPO Tech Report Agent.',
				render: false,
			},
			{
				content:
					'All leads provided by BPO Tech Inc to BPO Tech Report Agent may or not be exclusive leads provided from BPO Tech Inc. BPO Tech may redistribute leads as needed.',
				render: false,
			},
			{
				content:
					'BPO Tech Report Agents and or BPO Reports are not intended to be the sole reason why consumers , Buyer and or Sellers make the consideration or final decision to Buy or sell their property. Buyers, Sellers and or consumers are fully responsible to make the final ultimate decision to Buy or Sell Real Estate or whom they choose to assist them with the acquisition or sale of their home.',
				render: false,
			},
			{
				content:
					'By subscribing to BPO Tech Report Agent you agree to update and or upload the BPO Tech Website with all of your public Real Estate report information which may include any and all public report information that Realtor or Broker has previously published directly or indirectly to the web. Realtor and or Broker Report information or data may be uploaded directly from Realtor and or Broker. BPO Tech Inc may directly upload Listing information if BPO Tech owns the Listing data directly published by BPO Tech Inc.Listing information found publicly will be the only information uploaded by bpotech.io unless otherwise privately provided from Realtor and or Broker directly. Report info will include and not be limited to Broker and or Realtor property photos, property descriptions or any other report information available which may be found on various public 3rd party sites which Agent previously uploaded to BPO Tech directly or indirectly. This info may have been originally distributed through or from an MLS opt-in service, MLS API and or IDX service and or any other feed or service. Broker and or Realtor by subscribing BPO Report Agent must notify BPO Tech Inc if there is any copyright infringement in regards to the Broker or Agent not having the sole right to use report Info for distribution to bpotech.io prior to any Broker and or Realtor BPO Report uploads to bpotech.io.',
				render: false,
			},
			{
				content:
					'Some Listing information posted on bpotech.io has been provided by 3rd public data companies or provided directly by Listing Brokers, Realtors, Agents directly to the bpotech.io website. As BPO Tech Report Agents, by this agreement & by submitting the acceptance of this disclaimer to bpotech.io, BPO Tech Broker & Realtor subscribers have fully authorized BPO Tech Inc to provide their BPO Report info and made claim that they have sole rights and ownership to the Report info uploaded to BPO Tech Inc and on any other public 3rd party sites. Brokers & Realtor subscribers have made the claim that all photos, and property descriptions pertaining to such reports have been designated by Listing Agent to not be copyright protected by any 3rd parties and Brokers & Realtors release any and all claims against BPO Tech Inc & BPO Tech.com for any posting of such Listing information. BPO Tech Report Agents confirm that any and all 3rd party listing info providers also release all claims against BPO Tech Inc & BPO Tech.com for any posting of such Listing information.',
				render: false,
			},
			{
				content: 'BPO Tech Report Agent subscribers will be advertised as BPO Report Agents indefinitely unless BPO Report Agent unsubscribes.',
				render: false,
			},
			{
				content:
					'BPO Tech Report Agent subscribers will be requested at any time to perform BPO Reports for bpotech.io. BPO Tech Report Agent subscribers will be compensated with an account credit from BPO Tech in the amount of $100.00 per BPO Report created by BPO Tech Report Agent subscriber. BPO report compensation rates may vary from state to state and are subject to change at any time. BPO Tech Inc are to notify BPO Tech Report Agent of any rate changes via email notification if and when applicable.',
				render: false,
			},
			{
				content: 'BPO Tech Report Agent will receive the exclusive lead to potential Buyer or Seller who requested the BPO reports.',
				render: false,
			},
			{
				content:
					'BPO Tech Report Agents are not authorized to create BPO Reports from utilizing BPO Tech BPO Report Agent Platform for Agents or Brokers personal listings.',
				render: false,
			},
			{
				content:
					'BPO Tech Report Agent will provide an independent login to bpotech.io Report Agent Platform. BPO Tech Report Agent will be required to use the BPO Tech Report Agent platform to create BPO Reports.',
				render: false,
			},
			{
				content: 'BPO Tech Inc will receive compensation directly from consumers for BPO Report orders completed by BPO Report Agent subscribers.',
				render: false,
			},
			{
				content:
					'BPO Report Agents may request compensation in lieu of an account credit. BPO Tech Inc is required to issue a 1099 for payments made to BPO Tech Report Agent of $600 or more for BPO Reports performed within that calendar year. All BPO Report Agent payments must be paid directly to the Broker Office of such Real Estate Agent. Realtor payments may be subject to commission split & split amounts may vary by Broker and any split amounts are strictly between BPO Report Agent Realtor, Broker & Broker offices.',
				render: false,
			},
			{
				content:
					'BPO Tech Report Agent subscribers must hold an active Real Estate license in the state in which subscribers are performing such BPO Reports.',
				render: false,
			},
			{
				content:
					'Terms of this Disclaimer may change at any time and consumer, Broker & or Realtor subscribers may request the most updated Disclaimer upon their request.',
				render: false,
			},
			{
				content:
					'BPO Report Agents have 6 hours from the time of request to respond to BPO Report requests from BPO Tech Inc and 48 hours to submit final BPO Report upon approval of such request.',
				render: false,
			},
			{
				content:
					'Upon no response within the allotted time to BPO report request. BPO Report Agent will lose the opportunity to submit a report, earn compensation, or receive lead from that BPO Report request.',
				render: false,
			},
			{
				content:
					'BPO Tech Inc, bpotech.io, is not to be held liable by BPO Report Agent subscribers for any consumer lead discrepancies, website, web page, or Ads Created for BPO Tech Premier Agent that are not to Agents liking.',
				render: false,
			},
			{
				content:
					'Upon electronic signatures acquired by users for policies, services, subscriptions and any other applicable documentation signed by the user for BPO Tech Inc the documents and all terms of use are fully valid and in this agreement.',
				render: false,
			},
			{
				content: 'By checking the box below Broker and or Agent Agree to the Terms of this disclaimer.',
				render: false,
			},
		],
	},
];

function ReportAgentDisclaimer() {
	const classes = useStyles();
	return (
		<Box className={classes.root}>
			<Typography variant='h1' fontSize='35px' textAlign='center' fontWeight='700' mb='2rem'>
				BPO Tech Report Agent Disclaimer
			</Typography>

			{headers.map((header, index) => (
				<React.Fragment key={index}>
					{header.subs.map((sub, index) =>
						sub.render ? (
							<Typography key={index} paragraph className={classes.content} dangerouslySetInnerHTML={{ __html: sub.content }}></Typography>
						) : (
							<Typography key={index} paragraph className={classes.content}>
								{sub.content}
							</Typography>
						)
					)}
				</React.Fragment>
			))}
		</Box>
	);
}

export default ReportAgentDisclaimer;
