export const TOGGLE_AUTH_MODAL = 'TOGGLE_AUTH_MODAL';
export const SWITCH_TO_SIGNIN = 'SWITCH_TO_SIGNIN';
export const SWITCH_TO_SIGNUP = 'SWITCH_TO_SIGNUP';
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
export const GET_TOKEN = 'GET_TOKEN';
export const TOKEN_ATTEMPT_ERROR = 'TOKEN_ATTEMPT_ERROR';
export const GET_USER_DETAILS = 'GET_USER_DETAILS';
export const GET_USER_DETAILS_FROM_LOCAL = 'GET_USER_DETAILS_FROM_LOCAL';
export const SIGNOUT = 'SIGNOUT';
export const SIGNUP = 'SIGNUP';
export const SET_MODAL = 'SET_MODAL';
export const SET_SUBSCRIPTIONS = 'SET_SUBSCRIPTIONS';
