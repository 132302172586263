import { Box, Button, Card, CardContent, Container, Divider, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import AdBanner from '../../components/MUI-Components/AdBanner';
//import Navbar from '../../components/MUI-Components/Navbar'
import DealBanner from '../../assets/dealBanner.webp';
import GridSection from '../../components/MUI-Components/GridSection';
import Deal1 from '../../assets/deal01.webp';
import Deal2 from '../../assets/deal02.webp';
import Premier05 from '../../assets/premier05.webp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toggleAuthModal } from '../../actions';
import AdPrice from '../../components/AdPrice/AdPrice';
import SEO from '../../components/SEO';

const useStyles = makeStyles((theme) => ({
	grid: {
		height: '100%',
	},
}));

const AdvertiseDeal = () => {
	const classes = useStyles();

	const sections = [
		{
			title: 'You can analyze any property in a multitude of ways',
			copy: `Whether you want to Rent a Property, Whether you want to Flip a  Property or if you want to Build a Property. Then the Deal Analyzer Platform is the easy to use investor Platform for YOU!`,
			img: Deal1,
		},
		{
			title: 'Get a custom Proforma Report',
			copy: `Don't get fooled again by taking on a bad Real Estate deal

            Simply input the Deal information into the Deal Analyzer which auto computes your return on investment.
            The Deal Analyzer provides you with a Proforma Report disclosing the performance of the property indicating if it's a BAD or Good deal!
            `,
			img: Deal2,
		},
	];

	const loggedIn = useSelector((state) => state?.auth?.token?.access);
	const dispatch = useDispatch();

	const navigate = useNavigate();

	const gotoDeal = (route) => {
		if (loggedIn) {
			navigate('/choose-sub' + route);
		} else {
			dispatch(toggleAuthModal());
			localStorage.setItem('nextRoute', '/choose-sub' + route);
		}
	};

	return (
		<div>
			{/* NAVBAR */}
			{/* <Navbar /> */}
			<SEO>
				<title>BPO Deal Analyzer: Invest in Real Estate Properties like a Pro</title>
				<meta name='og:title' content='BPO Deal Analyzer: Invest in Real Estate Properties like a Pro' />
				<meta
					name='description'
					content='Maximize your ROI with the BPO Deal Analyzer. Analyze rental, flipping, and building deals, get custom proforma reports, and earn $100 with each BPO report order. Choose from two packages starting at $99 per month.'
				/>
				<meta
					name='og:description'
					content='Maximize your ROI with the BPO Deal Analyzer. Analyze rental, flipping, and building deals, get custom proforma reports, and earn $100 with each BPO report order. Choose from two packages starting at $99 per month.'
				/>
				<meta name='og:image' content={window.location.origin + DealBanner} />
			</SEO>

			<Box>
				{/* BANNER */}
				<AdBanner title='Evaluate A Deal Like An investor' bgImage={DealBanner} titleColor={'#fff'}>
					<AdPrice
						title='Individual Package'
						subHeader='Purchase Only the BPO Homes Deal Analyzer to enjoy all Deal Analyzer Features'
						price='$199'
						interval={'per month'}
						onClick={() => gotoDeal('/deal-analyzer/subscription')}
					/>
				</AdBanner>

				<Box padding={'4rem 2rem'} textAlign='center' color={'#000'}>
					<Container>
						<Box>
							<Typography variant='h5' gutterBottom>
								What's the catch?
							</Typography>
						</Box>

						<Box>
							{sections.map((el, index) => {
								return <GridSection key={index} index={index} title={el.title} copy={el.copy} img={el.img} />;
							})}
						</Box>

						<Box padding='2rem 0'>
							<Box textAlign={'center'} marginBottom='2rem'>
								<Typography variant='h5' style={{ fontWeight: 600 }}>
									Also Included
								</Typography>
							</Box>
							<Grid container spacing={3} className={classes.gridWrapper}>
								<Grid item lg={4} md={4} sm={12} className={classes.gridItem}>
									<Card variant='outlined' className={classes.grid}>
										<CardContent style={{ padding: '2rem' }}>
											<Typography variant='body2' paragraph>
												BPO Report Agent Included
											</Typography>
										</CardContent>
									</Card>
								</Grid>
								<Grid item lg={4} md={4} sm={12} className={classes.gridItem}>
									<Card variant='outlined' className={classes.grid}>
										<CardContent style={{ padding: '2rem' }}>
											<Typography variant='body2' paragraph>
												You earn $100.00 per BPO Report Order. Paying for marketing with 1 order per month!
											</Typography>
										</CardContent>
									</Card>
								</Grid>
								<Grid item lg={4} md={4} sm={12} className={classes.gridItem}>
									<Card variant='outlined' className={classes.grid}>
										<CardContent style={{ padding: '2rem' }}>
											<Typography variant='body2' paragraph>
												We Build you a personal custom BPO Report Agent Ordering Page
											</Typography>
										</CardContent>
									</Card>
								</Grid>
							</Grid>
						</Box>
					</Container>
				</Box>

				<Box
					style={{
						backgroundImage: `linear-gradient(#1378A595, #1367a5), url(${Premier05})`,
						backgroundSize: 'cover',
						padding: '3rem',
						marginTop: '2rem',
					}}
				>
					<Container>
						<Box textAlign={'center'} color='#fff' width='80%' margin='0 auto'>
							<Typography variant='h6'>BPO Homes Deal Analyzer Package</Typography>
							<Divider style={{ margin: '1rem auto', marginBottom: '2rem', width: '4rem', height: '2px', background: '#fff' }} />

							<Grid container spacing={6} style={{ margin: 0, width: '100%' }}>
								<Grid item lg={12} md={12} sm={12} style={{ padding: '2rem' }}>
									<Typography variant='h6' gutterBottom>
										Individual Package
									</Typography>
									<KeyboardArrowDownIcon />
									<Typography variant='body2' paragraph>
										Purchase Only the BPO Homes Deal Analyzer to enjoy all Deal Analyzer Features
									</Typography>
									<Typography variant='h6' gutterBottom>
										$99 <span style={{ fontSize: '12px' }}>per month</span>
									</Typography>
									<Button
										variant='contained'
										disableElevation
										color='primary'
										style={{ width: '15rem', textTransform: 'none', marginTop: '1rem', color: '#fff' }}
										onClick={() => gotoDeal('/deal-analyzer/subscription')}
									>
										Subscribe
									</Button>
								</Grid>
								{/* <Grid item lg={6} md={6} sm={12} style={{ padding: '2rem' }}>
									<Typography variant='h6' gutterBottom>
										Combo Package
									</Typography>
									<KeyboardArrowDownIcon />
									<Typography variant='body2' paragraph>
										Upgrade to with Premier Agent for all online ad and full Digital Business Card Features
									</Typography>
									<Typography variant='h6' gutterBottom>
										$129 <span style={{ fontSize: '12px' }}>per month</span>
									</Typography>
									<Button
										variant='contained'
										disableElevation
										color='primary'
										style={{ width: '15rem', textTransform: 'none', marginTop: '1rem', color: '#fff' }}
										onClick={() => gotoDeal('/combo-package/subscription')}
									>
										Subscribe
									</Button>
								</Grid> */}
							</Grid>
						</Box>
					</Container>
				</Box>
			</Box>
		</div>
	);
};

export default AdvertiseDeal;
