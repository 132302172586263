import React, { useEffect } from "react";
import AestheticItems from "./AestheticItems";
import NonAestheticItems from "./NonAestheticItems";
import {
  Box,
  TextField,
  Typography,
  Button,
  FormControl,
  Autocomplete,
  OutlinedInput,
  InputAdornment,
  Divider,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import useTheme from "@mui/material/styles/useTheme";
import { remodelStaticItemNames } from "./remodelStaticData";
import RemodelMainCard from "./RemodelMainCard";
import base from "../../../../apis";
import useSaveNow from "../../hooks/useSaveNow";
import StyledLabel from "../../atomicComponents/StyledLabel";
import EnhancedRadioButtonGroup from "../../atomicComponents/EnhancedRadioButtonGroup";
import { remodelCostOptions } from "./constants";
// import { useSelector } from "react-redux";
const useStyles = (theme) => {
  return {
    dialogHeader: {
      margin: "0.875rem 1.875rem 0rem 1.875rem",
      padding: "5px",
      borderBottom: `1px solid ${theme.palette.primary.main}`,
    },
    dialogLower: {
      margin: "0.875rem 0rem 0rem 0rem",
      padding: "5px",
    },
    // underInput:{
    //   "&:hover":{
    //     borderBottom:"solid"
    //   }
    // }
  };
};

function RemodelCost({
  handleSubMenu,
  activeSubMenu,
  handleEvents,
  data,
  reloadCurrentMenu,
}) {
  const [open, setOpen] = React.useState(false);
  const [openAdu, setOpenAdu] = React.useState(false);
  const { saveNow } = useSaveNow();

  const [results, setResults] = React.useState([]);
  const [selectedValue, setSelectedValue] = React.useState("");
  const [city, setCity] = React.useState();
  const theme = useTheme();
  const styles = useStyles(theme);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleOpenDialogAdu = () => {
    setOpenAdu(true);
  };
  const handleClose = () => {
    setOpen(false);
    setOpenAdu(false)
  };

  const updateAestheticItems = (index, value) => {
    const copy = [...data?.aesthetic_items];
    copy[+index] = { ...copy[+index], ...value };
    handleEvents({
      aesthetic_items: [...copy],
    });
  };
  const updateNonAestheticItems = (index, value) => {
    const copy = [...data?.non_aesthetic_items];
    copy[+index] = { ...copy[+index], ...value };
    handleEvents({
      non_aesthetic_items: [...copy],
    });
  };
  const removeAestheticItem = (indexToDelete) => {
    let copy = data?.aesthetic_items;
    copy = copy.filter((_element, index) => index !== +indexToDelete);
    handleEvents({ aesthetic_items: copy });
  };
  const removeNonAestheticItem = (indexToDelete) => {
    let copy = data?.non_aesthetic_items;
    copy = copy.filter((_element, index) => index !== +indexToDelete);
    handleEvents({ non_aesthetic_items: copy });
  };
  const addNewRow = (type) => {
    if (type === 0) {
      const newItem = { item: "", value: "" };
      const copy = data?.aesthetic_items;
      handleEvents({
        aesthetic_items: [...copy, newItem],
      });
    } else {
      const newItem = { item: "", value: "" };
      const copy = data?.non_aesthetic_items;
      handleEvents({
        non_aesthetic_items: [...copy, newItem],
      });
    }
  };
  const fillInitialData = (type) => {
    const initialItems =
      type === 0
        ? remodelStaticItemNames.aesthetic_items
        : remodelStaticItemNames.non_aesthetic_items;
    if (type === 0) {
      handleEvents({
        aesthetic_items: [...initialItems],
      });
    } else {
      handleEvents({
        non_aesthetic_items: [...initialItems],
      });
    }
  };
  const handleCityChange = (e) => {
    setCity(e.target.value);
    searchCities();
  };
  const searchCities = async () => {
    if (!city) return;
    const res = await base.get(
      `deal_analyzer/city_county_tax?location=${city}`
    );
    setResults(res?.data?.data);
  };
  const handleSelection = (e, value) => {
    const extract = +value.split("-")[1];
    !isNaN(extract) && handleEvents({ est_remodel_cost_psqft: extract });
    !isNaN(extract) && setSelectedValue(extract);
  };
  const handleSelectionAdu = (e, value) => {
    const extract = +value.split("-")[1];
    !isNaN(extract) && handleEvents({ adu_price: extract });
    !isNaN(extract) && setSelectedValue(extract);
  };
  const save = async () => {
    try {
      saveNow({ remodel_cost: data });
    } catch (err) { }
  };

  const specialHandler = (e) => {
    handleEvents({ [e.target.name]: e.target.value });
    if (+e.target.value === 0) {
      setTimeout(() => {
        handleSubMenu("");
      }, 100);
    }
  };

  useEffect(() => {
    save();
    // NOTE: Run effect once on component mount, please
    // recheck dependencies if effect is updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.["est_remodel_cost_psqft"]]);

  return (
    <Box sx={{ margin: "2rem" }}>
      {+activeSubMenu === 1 && (
        <>
          <StyledLabel label="Choose a Remodel Cost Calculator " />
          <EnhancedRadioButtonGroup
            options={remodelCostOptions}
            name="remodel_cost_option"
            choice={data?.remodel_cost_option}
            specialHandler={specialHandler}
            styles={{ display: "flex" }}
          />
          <Divider
            light={true}
            // variant="middle"
            sx={{
              height: "1px",
              mt: -2,
              mb: 2,
              backgroundColor: "#C4c4c4",
            }}
          />
        </>
      )}
      {+activeSubMenu === 1 && (
        <AestheticItems
          reloadCurrentMenu={reloadCurrentMenu}
          handler={handleEvents}
          updateAestheticItems={updateAestheticItems}
          data={data}
          removeAestheticItem={removeAestheticItem}
          addNewRow={addNewRow}
          fillInitialData={fillInitialData}
          handleSubMenu={handleSubMenu}
          save={save}
        />
      )}
      {+activeSubMenu === 2 && (
        <NonAestheticItems
          reloadCurrentMenu={reloadCurrentMenu}
          handler={handleEvents}
          data={data}
          updateNonAestheticItems={updateNonAestheticItems}
          removeNonAestheticItem={removeNonAestheticItem}
          addNewRow={addNewRow}
          fillInitialData={fillInitialData}
          handleSubMenu={handleSubMenu}
          save={save}
          end={true}
        />
      )}
      {!activeSubMenu && (
        <RemodelMainCard
          handleOpenDialog={handleClickOpen}
          handleOpenDialogAdu={handleOpenDialogAdu}
          handleSubMenu={handleSubMenu}
          handler={handleEvents}
          data={data}
        />
      )}
      <Dialog open={open} onClose={handleClose}>
        <Box sx={styles.dialogHeader}>
          <Typography variant="modalTitle">
            Est Remodel Cost Per Square foot{" "}
          </Typography>
        </Box>
        <DialogContent>
          <FormControl
            sx={{
              minWidth: "18.75rem",
              margin: "0rem 0",
            }}
          >
            <Autocomplete
              freeSolo
              disableClearable
              options={results.map(
                (option) =>
                  option.location.toUpperCase() + " - " + option.final_remodel
              )}
              onInputChange={handleCityChange}
              onChange={handleSelection}
              sx={{ fontSize: "1.6rem" }}
              noOptionsText={"Data Unavailable"}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Search city"
                  InputProps={{
                    ...params.InputProps,
                    type: "search",
                    style: { fontSize: "1rem" },
                  }}
                />
              )}
            />
          </FormControl>
          <Box sx={styles.dialogLower}>
            <Typography variant="modalTitle" sx={styles.underInput}>
              Est Cost Per Square foot{" "}
            </Typography>
          </Box>

          <FormControl
            sx={{
              width: "100%",
              margin: "0rem 0",
            }}
          >
            <OutlinedInput
              margin="normal"
              name="owning_months"
              // defaultValue={row}
              inputProps={{ style: { fontSize: "1rem" } }}
              type="number"
              placeholder="0"
              value={selectedValue || 0}
              // onChange = {handleChange}

              endAdornment={
                <InputAdornment position="start">
                  {" "}
                  <Typography variant="adornment" color="secondary">
                    /SqFt
                  </Typography>
                </InputAdornment>
              }
              startAdornment={
                <InputAdornment position="start">
                  {" "}
                  <Typography variant="adornment" color="secondary">
                    $
                  </Typography>
                </InputAdornment>
              }
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            sx={{ margin: " 0 1.25rem 1.25rem 1.25rem", padding: "0.937rem" }}
            onClick={handleClose}
          >
            <Typography variant="tableRow">OK</Typography>
          </Button>
        </DialogActions>
      </Dialog>

      {/* Basement dialog */}
      <Dialog open={openAdu} onClose={handleClose}>
        <Box sx={styles.dialogHeader}>
          <Typography variant="modalTitle">
            Est Additional Unit Cost Per Square foot{" "}
          </Typography>
        </Box>
        <DialogContent>
          <FormControl
            sx={{
              minWidth: "18.75rem",
              margin: "0rem 0",
            }}
          >
            <Autocomplete
              freeSolo
              disableClearable
              options={results.map(
                (option) => {
                  return option.location.toUpperCase() + " - " + (option.final_remodel + (+(0.20 * option.final_remodel).toFixed(2)))
                }

              )}
              onInputChange={handleCityChange}
              onChange={handleSelectionAdu}
              sx={{ fontSize: "1.6rem" }}
              noOptionsText={"Data Unavailable"}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Search city"
                  InputProps={{
                    ...params.InputProps,
                    type: "search",
                    style: { fontSize: "1rem" },
                  }}
                />
              )}
            />
          </FormControl>
          <Box sx={styles.dialogLower}>
            <Typography variant="modalTitle" sx={styles.underInput}>
              Est Cost Per Square foot{" "}
            </Typography>
          </Box>

          <FormControl
            sx={{
              width: "100%",
              margin: "0rem 0",
            }}
          >
            <OutlinedInput
              margin="normal"
              name="owning_months"
              // defaultValue={row}
              inputProps={{ style: { fontSize: "1rem" } }}
              type="number"
              placeholder="0"
              value={selectedValue || 0}
              // onChange = {handleChange}

              endAdornment={
                <InputAdornment position="start">
                  {" "}
                  <Typography variant="adornment" color="secondary">
                    /SqFt
                  </Typography>
                </InputAdornment>
              }
              startAdornment={
                <InputAdornment position="start">
                  {" "}
                  <Typography variant="adornment" color="secondary">
                    $
                  </Typography>
                </InputAdornment>
              }
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            sx={{ margin: " 0 1.25rem 1.25rem 1.25rem", padding: "0.937rem" }}
            onClick={handleClose}
          >
            <Typography variant="tableRow">OK</Typography>
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default RemodelCost;
