import React from 'react';
import { useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { toast } from 'react-toastify';
import base from '../../../apis';
import Button from '../../../components/Button/Button';
import Input from '../../../components/Input/Input';
import './tour.scss';

function Tour() {
	const [tourUrl, setTourUrl] = useState('');
	const [listing, setIsLoading, setListing] = useOutletContext();
	React.useEffect(() => {
		setTourUrl(listing.link_to_matterport || '');
	}, [listing.link_to_matterport]);
	const saveMatter = (e) => {
		e.preventDefault();
		setIsLoading(true);
		base
			.patch(`premiersite/listings/${listing.id}/`, {
				link_to_matterport: tourUrl || null,
			})
			.then(({ data }) => {
				setListing(data);
				toast.success('Tour Link Updated');
			})
			.catch(() => {
				toast.error('Error updating description');
			})
			.finally(() => {
				setIsLoading(false);
			});
	};
	return (
		<form className='tour'>
			<p>Provide a link to the matterport.</p>
			<div className='top_row'>
				<div className='buttons'>
					<Button
						style={{
							width: 'fit-content',
							margin: '0px',
							fontSize: '16px',
							backgroundColor: 'var(--primary-color)',
							color: '#ffffff',
						}}
						onClick={saveMatter}
					>
						Save
					</Button>
				</div>
			</div>
			<div className='matterport_section'>
				<p className='link_text'>Link to matterport</p>
				<Input value={tourUrl} onChange={(e) => setTourUrl(e.target.value)} />
			</div>
		</form>
	);
}

export default Tour;
