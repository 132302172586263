import React, { useEffect } from 'react';
import { useState } from 'react';
import Button from '../../components/Button/Button';
import Spinner from '../../components/Spinner/Spinner';
import '../BPOSub/bposub.scss';
import '../ChooseSubscription/choosesubscription.scss';
import '../ChoosePackage/choosepackage.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import base from '../../apis';
import { BiArrowBack } from 'react-icons/bi';
import { formatNumber } from '../../utils/functions';
import {
	Card,
	Divider,
	Grid,
	Typography,
	Radio,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	DialogContentText,
	Button as ButtonMat,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/system';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const packages = [
	{
		title: 'BPO FARM AGENT',
		benefits: [
			'Post Card Mailer Every 6 months',
			'Yearly Sellers Calling (Every 6 months)',
			'Weekly Email Marketing',
			'Monthly MMS Text Marketing',
			'Unlimited NOD Leads',
		],
		name: 'regular',
	},
	{
		title: 'BPO FARM AGENT PRO',
		benefits: [
			'Quarterly Post Card Mailer per Year',
			'Yearly Sellers Calling (Every 6 months)',
			'Weekly Email Marketing',
			'Monthly MMS Text Marketing',
			'Unlimited NOD Leads',
		],
		name: 'pro',
	},
	{
		title: 'BPO FARM AGENT ELITE',
		benefits: [
			'Monthly Post Card Mailer per Year',
			'Quarterly Sellers Calling',
			'Weekly Email Marketing',
			'Monthly MMS Text Marketing',
			'Unlimited NOD Leads',
		],
		name: 'elite',
	},
];

const useStyles = makeStyles((theme) => ({
	card: {
		padding: '20px',
	},
	titleText: {
		// fontSize: '1.5rem',
		fontWeight: 'bold',
		color: theme.secondaryColor,
		textTransform: 'uppercase',
		textAlign: 'center',
		display: 'block',
	},
	divider: {
		background: theme.secondaryColor,
		height: '2px',
		width: '5rem',
		margin: '10px auto ',
		display: 'block',
		border: 'none !important',
	},
	checkbox: {
		color: '#1378a5',
		fontSize: 'small',
	},
}));

function AddonsPayment() {
	const classes = useStyles();
	const purchaseDetail = useLocation().state;
	const navigate = useNavigate();
	const [farmPrices, setFarmPrices] = useState({
		regular: {
			amount: '',
			priceId: '',
		},
		pro: {
			amount: '',
			priceId: '',
		},
		elite: {
			amount: '',
			priceId: '',
		},
	});

	useEffect(() => {
		if (!purchaseDetail) {
			navigate('/my-subscriptions');
		}
		setSelectedPackage(purchaseDetail.selectedPrice);
		setIsLoading(true);
		const controller = new AbortController();

		base
			.get('/register/list_prices/', {
				signal: controller.signal,
			})
			.then((res) => {
				if (!res) return;
				const out = res?.data[0];
				const isMain = purchaseDetail?.isMain;
				const isMonthly = purchaseDetail?.interval === 'monthly';
				const regular = out.find(
					(item) => item.id === purchaseDetail?.price_data?.[`${!isMain ? 'additional_' : ''}${isMonthly ? 'monthly' : 'annual'}_sub`]
				);
				const pro = out.find(
					(item) => item.id === purchaseDetail?.price_data?.[`pro_${!isMain ? 'additional_' : ''}${isMonthly ? 'monthly' : 'annual'}_sub`]
				);
				const elite = out.find(
					(item) => item.id === purchaseDetail?.price_data?.[`elite_${!isMain ? 'additional_' : ''}${isMonthly ? 'monthly' : 'annual'}_sub`]
				);

				const toSet = {
					regular: {
						amount: formatNumber(regular?.unit_amount / 100),
						priceId: regular?.id,
					},
					pro: {
						amount: formatNumber(pro?.unit_amount / 100),
						priceId: pro?.id,
					},
					elite: {
						amount: formatNumber(elite?.unit_amount / 100),
						priceId: elite?.id,
					},
				};
				setFarmPrices(toSet);
			})
			.catch((err) => {
				toast.error('Could not get prices');
			})
			.finally(() => {
				setIsLoading(false);
			});

		return () => {
			controller.abort();
		};
	}, [purchaseDetail, navigate]);

	const [isLoading, setIsLoading] = useState(false);

	const [selectedPackage, setSelectedPackage] = useState('');

	const handleSelected = (id) => {
		if (id === purchaseDetail?.selectedPrice) {
			toast.warn('You already have this package', { toastId: 'alreadyHavePackage' });
			return;
		}
		setSelectedPackage(id);
	};

	//handle go back
	const handleBack = () => {
		if (activeStep > 0) {
			setActiveStep((prevActiveStep) => prevActiveStep - 1);
		} else {
			navigate(-1);
		}
	};

	const [activeStep, setActiveStep] = useState(0);

	const onConfirmSwitch = () => {
		setIsLoading(true);

		base
			.post('/register/update_subscription/', {
				item_id: `${purchaseDetail?.subscriptionItemId}`,
				price_id: selectedPackage,
			})
			.then(({ data }) => {
				toast.success('Subscription updated successfully');
				navigate(-1);
				//go back
			})
			.catch((err) => {
				toast.error('Could not update subscription');
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const handleNext = () => {
		if (selectedPackage === purchaseDetail?.selectedPrice) {
			toast.warn('Please select a package to switch to', { toastId: 'select-package' });
			return;
		}
		setConfirmDeleteModal(true);

		//change happens here
	};
	const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);

	return (
		<>
			{isLoading && <Spinner />}
			<Dialog
				open={confirmDeleteModal}
				onClose={() => setConfirmDeleteModal(false)}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
			>
				<DialogTitle id='alert-dialog-title'>Confirm package change</DialogTitle>
				<DialogContent>
					<DialogContentText id='alert-dialog-description'>Are you sure you want to switch packages?</DialogContentText>
				</DialogContent>
				<DialogActions>
					<ButtonMat onClick={() => setConfirmDeleteModal(false)} variant='contained' color='secondary'>
						Cancel
					</ButtonMat>
					<ButtonMat
						autoFocus
						variant='contained'
						color='secondary'
						sx={{ backgroundColor: 'var(--primary-color)', '&:hover': { backgroundColor: 'var(--primary-color)' } }}
						onClick={onConfirmSwitch}
					>
						yes
					</ButtonMat>
				</DialogActions>
			</Dialog>
			<div
				style={{
					backgroundColor: 'var(--primary-color)',
					color: 'white',
					padding: '10px',
					paddingLeft: '30px ',
					display: 'flex',
					alignItems: 'center',
					gap: '10px',
				}}
			>
				<BiArrowBack onClick={handleBack} style={{ cursor: 'pointer' }} /> Checkout
			</div>

			<div>
				<h1 style={{ textAlign: 'center', position: 'sticky', backgroundColor: 'white', padding: '10px', top: 0, zIndex: 2 }}>
					{purchaseDetail?.name}
				</h1>
				<Box display={'flex'} justifyContent='center' gap='20px' alignItems='center'>
					<p style={{ textAlign: 'center', margin: 0 }}>{`${purchaseDetail?.state}, ${purchaseDetail?.county}, ${purchaseDetail?.city}`}</p>
				</Box>
				<Grid container spacing={2} sx={{ px: '5rem', m: 'auto', maxWidth: '1450px' }}>
					{packages.map((item) => {
						return (
							<Grid item xs={12} sm={12} md={4} lg={4} key={item.title}>
								<Card
									id={farmPrices[item.name]?.priceId}
									sx={{
										borderColor: farmPrices[item.name]?.priceId === selectedPackage ? 'var(--primary-color)' : 'white',
										borderWidth: '1px',
										filter: farmPrices[item.name]?.priceId === purchaseDetail?.selectedPrice ? 'brightness(0.5)' : '',
										borderStyle: 'solid',
										cursor: 'pointer',
									}}
									className={classes.card}
									onClick={() => handleSelected(farmPrices[item.name]?.priceId)}
								>
									<Typography paragraph gutterBottom className={classes.titleText}>
										{item.title}
									</Typography>
									<Divider className={classes.divider} />
									<Box display='flex' gap='20px' alignItems='center' width='fit-content' mx='auto' mb='30px !important'>
										<Radio
											checked={farmPrices[item.name]?.priceId === selectedPackage}
											disabled={farmPrices[item.name]?.priceId === purchaseDetail?.selectedPrice}
											size={'small'}
											value={farmPrices[item.name]?.priceId}
											name='radio-buttons'
											color='secondary'
										/>
										<Typography display='flex' gap='10px'>
											<Typography variant='span' sx={{ fontWeight: 'bolder', fontSize: '20px' }}>
												$ {farmPrices[item.name]?.amount}
											</Typography>
											per month
										</Typography>
									</Box>
									{item.benefits.map((benefit) => (
										<Box display='flex' alignItems='center' gap='10px' key={benefit} mb={'20px'}>
											<CheckCircleOutlineIcon className={classes.checkbox} />
											<Typography fontSize='15px'>{benefit}</Typography>
										</Box>
									))}
								</Card>
							</Grid>
						);
					})}
				</Grid>
			</div>

			<Box display='flex' justifyContent='center'>
				<Button
					type='submit'
					style={{
						width: '250px',
						fontSize: '16px',
						padding: '10px 30px',
						backgroundColor: 'white',
						color: 'var(--primary-color)',
						display: 'block',
					}}
					text='CHANGE PACKAGE'
					onClick={handleNext}
				/>
			</Box>
		</>
	);
}

export default AddonsPayment;
