import { Box, Button, Card, CardContent, CardHeader, Divider, Grid, Stack, TextField, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'
import React from 'react'

const ContingencyItem = ({title, classes, dispatch, data, index}) => {
  return (
    <Box>
        <Grid container>
            <Grid item sm={3}>
                <Typography fontWeight={500} color={'secondary'}>{title}</Typography>
            </Grid>
            <Grid item sm={9}>
                <Card elevation={0} sx={{ mb: 3, border: '1px solid lightgrey'}}>
                    <CardHeader sx={{bgcolor: grey[200]}} title={
                        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                            <Typography fontSize={'1.1rem'}>{data?.name}</Typography>
                            <Typography fontSize={'1.1rem'}>{data?.days} days</Typography>
                        </Stack>
                    }/>
                    <Divider sx={{ bgcolor: '#ededed' }} />
                    <CardContent sx={{p: 3}}>
                        <Typography variant='body1' mb={2} ><span style={{fontWeight: 500, fontSize: '1.2rem'}}>10</span> out of <span style={{fontWeight: 500, fontSize: '1.2rem'}}>{data?.days}</span> Days To Complete</Typography>
                        <Stack direction={'row'} gap={4}>
                            <Typography variant='body2' color={'GrayText'}><span style={{fontWeight: 500}}>Start Date:</span> {new Date(data?.startDate)?.toDateString()}</Typography>
                            <Typography variant='body2' color={'GrayText'}><span style={{fontWeight: 500}}>End Date:</span> {new Date(Date.now()).toDateString()}</Typography>
                        </Stack>
                    </CardContent>
                </Card>
                <TextField type={'text'}  multiline rows={5} sx={{mb: 2}}
                    onChange={(e) => dispatch({ type: 'UPDATE_NOTES', payload: {value: e?.target?.value, index} })}
                    size='small' fullWidth placeholder='Add a note'
                    value={data?.notes}
                />
                <Button sx={{mb: 3}} onClick={() => dispatch({type: 'MARK_AS_READ', payload: index})}
                    variant={data?.read ? 'contained' : 'outlined' } color={'secondary'} 
                    disableElevation size='small' 
                    className={classes.btn}>
                    { data?.read ? 'Marked' : 'Mark As Read' }
                </Button>
            </Grid>
        </Grid>
        <Divider sx={{ mt: 2, mb: 2, bgcolor: '#ededed' }} />
    </Box>
  )     
}

export default ContingencyItem