import {
  FormControl,
  InputAdornment,
  Box,
  Typography,
  OutlinedInput,
} from "@mui/material";
import StyledLabel from "./StyledLabel";
import React from "react";
import useTheme from "@mui/material/styles/useTheme";
import HtmlToolTip from "./HtmlToolTip";
import MonthYearSelector from "./MonthYearSelector";
import commaSeparate from "../utils/commaSeparate";
import { validateMortgageTerms } from "../utils/validateMortgageTerms";
import { preventMinus } from "../utils/preventNegativeValues";

const useStyle = (_theme) => {
  return {
    amount: {
      display: "flex",
      flexDirection: "column",
      marginTop: "1.875rem",
      marginRight: "3.125rem",
      width: "95%",
    },
    term: {
      display: "flex",
      flexDirection: "column",
      marginTop: "1.875rem",
      width: "95%",
    },
    interest: {
      display: "flex",
      flexDirection: "column",
      marginTop: "1.875rem",
      marginRight: "3.125rem",
      width: "80%",
    },
    interestTerm: {
      display: "flex",
      justifyContent: "space-between",
    },
    termFor: {
      display: "flex",
      flexDirection: "column",
      marginTop: "1.875rem",
      width: "100%",
    },
    button: {
      width: "100%",
      margin: "1.875rem 0",
      padding: "1rem",
    },
  };
};

function InterestOnlyMortgage({
  data,
  handler,
  namePrefix,
  dealAnalyzerResultsData,
  mortgageAmount,
}) {
  const theme = useTheme();
  const styles = useStyle(theme);

  // const handleValues = (e) => {
  //   let value = e.target.value;
  //   const variable = e.target.name
  //   if (value) {
  //     value = Number.parseInt(value.split(",").join(""));
  //     if (isNaN(value)) {
  //       return;
  //     }
  //     handler({ [variable]: value });
  //   } else {
  //     handler({ [variable]: value });
  //   }
  // };

  // Financial options data calculations
  const handlePercentValues = (e) => {
    handler({ [e.target.name]: e.target.value });
  };
  const checkErrors = () => {
    if (
      data?.[`${namePrefix}mortgage_term_of_loan`] &&
      data?.[`${namePrefix}interest_only_term`]
    ) {
      const res = validateMortgageTerms({
        fullTerm: data?.[`${namePrefix}mortgage_term_of_loan`],
        fullTermUnit:
          data?.[`${namePrefix}mortgage_term_of_loan_month_or_year`],
        interestOnlyTerm: data?.[`${namePrefix}interest_only_term`],
        interestOnlyTermUnit:
          data?.[`${namePrefix}interest_only_term_month_or_year`],
      });
      if (res) {
        return interstOnlyTermValidationErrorMessage;
      }
      return "";
    }
  };

  return (
    <>
      <Box sx={styles.interestTerm}>
        <Box sx={styles.amount}>
          <Box>
            <StyledLabel label="Mortgage Amount" />
            <HtmlToolTip
              content={"Mortgage is another name for the Loan Amount"}
            />
          </Box>
          <FormControl
            sx={{
              width: "100%",
              marginTop: "10px",
              backgroundColor: "#E5E4E2",
            }}
          >
            <OutlinedInput
              name={`${namePrefix}mortgage_amount`}
              value={commaSeparate(mortgageAmount)}
              margin="normal"
              inputProps={{ style: { fontSize: "1rem" } }}
              startAdornment={
                <InputAdornment position="start">
                  {" "}
                  <Typography variant="adornment" color="secondary">
                    $
                  </Typography>
                </InputAdornment>
              }
            />
          </FormControl>
        </Box>
        <Box sx={styles.term}>
          <MonthYearSelector
            data={data}
            handler={handler}
            name={`${namePrefix}mortgage_term_of_loan`}
            label="Term of Loan"
          />
        </Box>
      </Box>
      <Box sx={styles.interestTerm}>
        <Box sx={styles.interest}>
          <Box>
            <StyledLabel label="Interest Rate" />
            <HtmlToolTip
              content={
                "The additional percentage amount charged for taking the loan from the Lender"
              }
            />
          </Box>
          <FormControl
            sx={{
              width: "100%",
              marginTop: "10px",
            }}
          >
            <OutlinedInput
              fullWidth
              onKeyPress={preventMinus}
              name={`${namePrefix}annual_interest_rate`}
              value={data?.[`${namePrefix}annual_interest_rate`]}
              onChange={handlePercentValues}
              margin="normal"
              inputProps={{ style: { fontSize: "1rem" }, min: 0 }}
              type="number"
              endAdornment={
                <InputAdornment position="start">
                  {" "}
                  <Typography variant="adornment" color="secondary">
                    %
                  </Typography>
                </InputAdornment>
              }
            />
          </FormControl>
        </Box>
        <Box sx={styles.termFor}>
          <MonthYearSelector
            data={data}
            handler={handler}
            name={`${namePrefix}interest_only_term`}
            label="Interest Only Term"
            errorMessage={checkErrors()}
          />
        </Box>
      </Box>
    </>
  );
}

export default InterestOnlyMortgage;

const interstOnlyTermValidationErrorMessage =
  "Interest Only Term must not be more than Term of Loan";
