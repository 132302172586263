import React from 'react'
import theme from '../styling/theme';
import {StyledEngineProvider} from '@mui/material/styles';
import cssStyles from './muitheme.module.scss'
import { ThemeProvider, CssBaseline} from '@mui/material'


function MuiThemeLayout({selfTheme,children}) {



  return (
    <div className={cssStyles.root}>
      <ThemeProvider theme={selfTheme || theme}>
        <StyledEngineProvider injectFirst>
        <CssBaseline />
        {children}
        </StyledEngineProvider>
      </ThemeProvider>
    </div>
  );
}


export default MuiThemeLayout