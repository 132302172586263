import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Button, Card, Divider, Grid, Radio, Skeleton, Stack, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/system';
import { Elements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import base from '../../apis';
// import Button from '../../components/Button/Button';
import Checkbox from '../../components/Checkbox/Checkbox';
import Spinner from '../../components/Spinner/Spinner';
import { farmPackages as packages } from '../../constants';
import { formatNumber } from '../../utils/functions';
import '../ChoosePackage/choosepackage.scss';
import '../ChooseSubscription/choosesubscription.scss';
import './bposub.scss';
import { useSelector } from 'react-redux';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const useStyles = makeStyles((theme) => ({
	card: {
		padding: '20px',
	},
	titleText: {
		// fontSize: '1.5rem',
		fontWeight: 'bold',
		color: theme.secondaryColor,
		textTransform: 'uppercase',
		textAlign: 'center',
		display: 'block',
	},
	divider: {
		background: theme.secondaryColor,
		height: '2px',
		width: '5rem',
		margin: '10px auto ',
		display: 'block',
		border: 'none !important',
	},
	checkbox: {
		color: '#1378a5',
		fontSize: 'small',
	},
}));

function BPOSub1() {
	const classes = useStyles();
	const navigate = useNavigate();
	const [farmPrices, setFarmPrices] = useState({
		// base_setup_price_id: '',
		// base_setup_price_amount: '',
		addon_setup_price_id: '',
		addon_setup_price_amount: '',
		standard: {
			monthly: {
				amount: '',
				priceId: '',
			},
			yearly: {
				amount: '',
				priceId: '',
			},
		},
		pro: {
			monthly: {
				amount: '',
				priceId: '',
			},
			yearly: {
				amount: '',
				priceId: '',
			},
		},
		elite: {
			monthly: {
				amount: '',
				priceId: '',
			},
			yearly: {
				amount: '',
				priceId: '',
			},
		},
		ultra: {
			monthly: {
				amount: '',
				priceId: '',
			},
			yearly: {
				amount: '',
				priceId: '',
			},
		},
	});
	const isClient = useSelector((state) => state.auth.role === 'client');

	useEffect(() => {
		setIsLoading(true);
		const controller = new AbortController();
		if (isClient) {
			toast.warn('Clients not allowed');
			navigate('/client-dashboard');
			return;
		}

		base
			.get('/register/list_prices/', {
				signal: controller.signal,
			})
			.then((res) => {
				if (!res) return;
				const out = res?.data?.flat();
				//add product=bpo_combo_farm to metadata
				const farmProducts = out?.filter((item) => item.metadata?.product === 'premier_farm_agent' && item.active);
				const standardMonthly = farmProducts.find((item) => item.metadata?.package_grade === 'basic' && item.metadata?.recurring_type === 'monthly');
				const ultraMonthly = farmProducts.find((item) => item.metadata?.package_grade === 'ultra' && item.metadata?.recurring_type === 'monthly');
				const eliteMonthly = farmProducts.find((item) => item.metadata?.package_grade === 'elite' && item.metadata?.recurring_type === 'monthly');
				const proMonthly = farmProducts.find((item) => item.metadata?.package_grade === 'pro' && item.metadata?.recurring_type === 'monthly');
				const standardYearly = farmProducts.find((item) => item.metadata?.package_grade === 'basic' && item.metadata?.recurring_type === 'yearly');
				const ultraYearly = farmProducts.find((item) => item.metadata?.package_grade === 'ultra' && item.metadata?.recurring_type === 'yearly');
				const eliteYearly = farmProducts.find((item) => item.metadata?.package_grade === 'elite' && item.metadata?.recurring_type === 'yearly');
				const proYearly = farmProducts.find((item) => item.metadata?.package_grade === 'pro' && item.metadata?.recurring_type === 'yearly');
				const baseSetup = out.find((item) => item.metadata?.package === 'setup_fee');
				// const addOnSetup = out.find((item) => item.metadata?.product === 'farm_addon_setup');

				const toSet = {
					base_setup_price_id: baseSetup?.id,
					base_setup_price_amount: formatNumber(baseSetup?.unit_amount / 100),

					standard: {
						monthly: {
							amount: formatNumber(standardMonthly?.unit_amount / 100),
							priceId: standardMonthly?.id,
						},
						yearly: {
							amount: formatNumber(standardYearly?.unit_amount / 100),
							priceId: standardYearly?.id,
						},
					},
					pro: {
						monthly: {
							amount: formatNumber(proMonthly?.unit_amount / 100),
							priceId: proMonthly?.id,
						},
						yearly: {
							amount: formatNumber(proYearly?.unit_amount / 100),
							priceId: proYearly?.id,
						},
					},
					elite: {
						monthly: {
							amount: formatNumber(eliteMonthly?.unit_amount / 100),
							priceId: eliteMonthly?.id,
						},
						yearly: {
							amount: formatNumber(eliteYearly?.unit_amount / 100),
							priceId: eliteYearly?.id,
						},
					},
					ultra: {
						monthly: {
							amount: formatNumber(ultraMonthly?.unit_amount / 100),
							priceId: ultraMonthly?.id,
						},
						yearly: {
							amount: formatNumber(ultraYearly?.unit_amount / 100),
							priceId: ultraYearly?.id,
						},
					},
				};

				setFarmPrices(toSet);
			})
			.catch((err) => {
				toast.error('Could not get prices');
			})
			.finally(() => {
				setIsLoading(false);
			});

		return () => {
			controller.abort();
		};
	}, [navigate, isClient]);

	const [isLoading, setIsLoading] = useState(false);

	const [isMonthly, setIsMonthly] = useState(true);

	const [selectedPackages, setSelectedPackages] = useState({
		0: '',
		1: '',
		2: '',
		3: '',
	});

	const handleSelected = (index, id) => {
		setSelectedPackages((current) => ({ ...current, [index]: id }));
	};

	const [termsAgreed, setTermsAgreed] = useState(false);
	const [termsAgreed1, setTermsAgreed1] = useState(false);

	const handleIsMonthly = (e, value) => {
		setIsMonthly(value);
		setSelectedPackages({
			0: '',
			1: '',
			2: '',
		});
	};

	if (isLoading) {
		return (
			<Grid container spacing={2} px='2rem'>
				{[1, 2, 3, 4].map((item) => (
					<Grid item xs={3} key={item}>
						<Skeleton variant='rectangular' width={'100%'} height={'95vh'}></Skeleton>
					</Grid>
				))}
			</Grid>
		);
	}

	return (
		<>
			<Elements stripe={stripePromise}>
				{isLoading && <Spinner />}

				{[0].map((territory, index) => (
					<Stack gap='1rem' key={index}>
						<Box display={'flex'} justifyContent='center' gap='20px' alignItems='center' p='1rem'>
							<ToggleButtonGroup size='small' color='secondary' value={isMonthly} exclusive onChange={handleIsMonthly}>
								<ToggleButton value={true}>Monthly</ToggleButton>
								<ToggleButton value={false}>Annual</ToggleButton>
							</ToggleButtonGroup>
						</Box>
						<Grid container spacing={2} width='100%' p='1rem'>
							{packages.map((item, packageIndex) => {
								let value;
								switch (packageIndex) {
									case 0:
										value = 'standard';
										break;
									case 1:
										value = 'pro';

										break;
									case 2:
										value = 'elite';
										break;
									case 3:
										value = 'ultra';
										break;
									default:
										break;
								}
								const price =
									farmPrices?.[value][isMonthly ? 'monthly' : 'yearly']?.amount || farmPrices?.[value][isMonthly ? 'monthly' : 'yearly']?.amount;
								const id = farmPrices?.[value][isMonthly ? 'monthly' : 'yearly']?.priceId;
								return (
									<Grid item xs={12} sm={12} md={3} lg={3} key={item.title}>
										<Card
											id={id}
											sx={{
												borderColor: selectedPackages[index] === id ? 'var(--primary-color)' : 'white',
												borderWidth: '1px',
												borderStyle: 'solid',
												cursor: 'pointer',
												height: '100%',
											}}
											className={classes.card}
											onClick={() => handleSelected(index, id)}
										>
											<Typography paragraph gutterBottom className={classes.titleText}>
												{item.title}
											</Typography>
											<Divider className={classes.divider} />
											<Box display='flex' gap='20px' alignItems='center' width='fit-content' mx='auto' mb='30px !important'>
												<Radio checked={selectedPackages[index] === id} size={'small'} value={id} name='radio-buttons' color='secondary' />
												<Typography display='flex' gap='10px'>
													<Typography variant='span' sx={{ fontWeight: 'bolder', fontSize: '20px' }}>
														$ {price}
													</Typography>
													per {isMonthly ? 'month' : 'year'}
												</Typography>
											</Box>
											{item.benefits.map((benefit) => (
												<Box display='flex' alignItems='center' gap='10px' key={benefit} mb={'20px'}>
													<CheckCircleOutlineIcon className={classes.checkbox} />
													<span style={{ fontSize: '15px' }} dangerouslySetInnerHTML={{ __html: benefit }}></span>
												</Box>
											))}
										</Card>
									</Grid>
								);
							})}
						</Grid>
					</Stack>
				))}
				<Box display='flex' justifyContent='center'>
					<Box display='flex' alignItems='center' flexDirection='column' mt='2rem' sx={{ maxWidth: '85%' }} gap='1rem'>
						<Checkbox
							text='By checking this you have agreed to the terms and conditions'
							checked={termsAgreed}
							setChecked={(e) => setTermsAgreed(e.target.checked)}
							labelStyle={{ marginRight: 'auto' }}
						/>
						<div>
							<Checkbox
								labelStyle={{ marginRight: 'auto', display: 'inline' }}
								checked={termsAgreed1}
								setChecked={(e) => setTermsAgreed1(e.target.checked)}
								text={`By providing my phone number to “BPO Homes”, I agree and acknowledge that “BPO Homes ” may send text messages to my wireless phone number for any purpose. Message and data rates may apply. Message frequency will vary, and you will be able to Opt-out by replying “STOP”. For more information on how your data will be handled please visit `}
							/>
							<NavLink to='/privacy-policy' className='blue_link'>
								https://bpotech.io/privacy-policy
							</NavLink>
						</div>
						<p style={{ margin: 'auto' }}>
							By placing the order you agree with our{' '}
							<NavLink to={`/terms-of-service`} className='blue_link'>
								Terms & Conditions
							</NavLink>{' '}
							and{' '}
							<NavLink to='/privacy-policy' className='blue_link'>
								Privacy Policy
							</NavLink>
						</p>
						<StripePay
							base_setup_price_id={farmPrices?.base_setup_price_id}
							isMonthly={isMonthly}
							isChecked={termsAgreed && termsAgreed1}
							selectedPackage={selectedPackages['0']}
						/>
					</Box>
				</Box>
			</Elements>
		</>
	);
}

export default BPOSub1;

const StripePay = ({ base_setup_price_id, isMonthly, isChecked, selectedPackage }) => {
	const stripe = useStripe();

	const confirmPayment = () => {
		if (!isChecked) {
			toast.warn('Please agree to the terms and conditions');
			return;
		}
		if (!selectedPackage) {
			toast.warn('Please select a package');
			return;
		}
		const body = {
			interval: isMonthly ? 'monthly' : 'yearly',
			price_data: [
				{
					price: base_setup_price_id,
					quantity: 1,
				},
				{
					price: selectedPackage,
					quantity: 1,
				},
			],
		};

		base
			.post('/register/create_subscription/', {
				...body,
				cancel_url: window.location.origin + '/available-territories',
				success_url: window.location.origin + `/payment_success?type=BPO_Farm&`,
				payment_method_types: ['card'],
				mode: 'subscription',
				package: 'bpo-farm',
			})
			.then((res) => {
				if (!res?.data?.sessionId) {
					toast.error('Something went wrong');
					return;
				}
				stripe.redirectToCheckout({
					sessionId: res?.data?.sessionId,
				});
			})
			.catch((err) => {
				toast.error('Could not process payment');
			});
	};

	return (
		<Button onClick={confirmPayment} variant='contained' color='secondary'>
			Confirm payment
		</Button>
	);
};
