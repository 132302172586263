import React, { useEffect, useState } from 'react';
import './premieragentsdashboard.scss';
import { useSelector } from 'react-redux';
import base from '../../apis';
import {
	Box,
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	Grid,
	IconButton,
	InputLabel,
	TextField,
	Tooltip,
	Typography,
} from '@mui/material';
import { formatNumber, formatPhoneNumber } from '../../utils/functions';
import { Close } from '@mui/icons-material';
import Spinner from '../../components/Spinner/Spinner';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

const PremierAgentsDashboard = () => {
	const { premierAgentWebsite, bpoFarm, dealAnalyzer } = useSelector((state) => state.subscriptions);
	const [isLoading, setIsLoading] = useState(false);
	const type = useSelector((state) => state.auth.role);

	return (
		<>
			{isLoading && <Spinner />}
			<div className='listing_agents_wrapper'>
				<div className='cards_session'>
					{dealAnalyzer && (
						<div className='card_row'>
							{type === 'realty_agent' && (
								<div className='compensation' style={{ alignItems: 'flex-start' }}>
									<b style={{ textAlign: 'center' }}>Tuesdays/ Thursdays</b>
									<span>Topic: BPO Homes Training </span>
									<span>Time: 12:00 PM Pacific Time (US and Canada)</span>
									<div style={{ display: 'flex', gap: '100px', width: '100%' }}>
										<a
											href='https://us06web.zoom.us/j/84873173703?pwd=ZW1FRnE4eHkyMVYvcy85RFFVcjhEZz09'
											target='_blank'
											rel='noreferrer'
											style={{ cursor: 'pointer', color: 'var(--primary-color)' }}
										>
											Join Zoom meeting
										</a>
										<a
											href='https://us06web.zoom.us/meeting/tZAlcOqpqTgtGtei1105riYYZc8d0dgkSsuD/ics?icsToken=98tyKuGspjwpG9OWtxmERpwIGo_Cb-vwtnZajbdYlU60U3VBazrPDOEaNuJ7F9Pj '
											target='_blank'
											rel='noreferrer'
											style={{ cursor: 'pointer', color: 'var(--primary-color)' }}
										>
											Add to Calendar
										</a>
									</div>
								</div>
							)}
							<div className='compensation' style={{ alignItems: 'flex-start' }}>
								<b style={{ textAlign: 'center' }}>Wednesdays/Fridays</b>
								<span>Topic: BPO TECH Training Session</span>
								<span>Time: 12:00 PM Pacific Time (US and Canada)</span>
								<div style={{ display: 'flex', gap: '100px', width: '100%' }}>
									<a
										href='https://us06web.zoom.us/j/83123984545?pwd=QWtIck5IaG9BTmFycFhQSTM3dkRHZz09'
										target='_blank'
										rel='noreferrer'
										style={{ cursor: 'pointer', color: 'var(--primary-color)' }}
									>
										Join Zoom meeting
									</a>
									<a
										href='https://us06web.zoom.us/meeting/tZcsdeqhpj8vHtGGQs98YOJDOGkSBhXo9huv/ics?icsToken=98tyKuGrrzkpE9yRtR2CRpwqBYjob_PxiClYgrd6uy72WnhqTSnSGcVJAZB3KNeJ'
										target='_blank'
										rel='noreferrer'
										style={{ cursor: 'pointer', color: 'var(--primary-color)' }}
									>
										Add to Calendar
									</a>
								</div>
							</div>
						</div>
					)}
					<div className='card_row'>
						<iframe
							style={{
								margin: '0 auto',
								aspectRatio: '16/9',
								borderRadius: '5px',
								border: 'none',
								flex: 2,
								maxHeight: '400px',
							}}
							src={`https://www.youtube.com/embed/ETkGmXoPauk`}
							title='YouTube video player'
							allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
							allowFullScreen
						></iframe>
						{!bpoFarm && (
							<div className='sub_main'>
								<div className='sub_main_holder'>
									<div className='sub_main_child'>
										<div className='sub_main_imageHolder'>
											<img
												src='https://bpooperationsmain.s3.eu-west-2.amazonaws.com/template_images/farmPngIcon.png'
												alt='Farm Logo'
												style={{ width: '50px' }}
											/>
											<p style={{ fontWeight: '700' }}>BPO Farm Agent</p>
										</div>
										<div className='sub_main_title'>
											<p>BPO Farm Agent includes all what BPO Homes has to offer.</p>
											<a href='/available-territories' className='sub_main_link'>
												Subscribe
											</a>
										</div>
									</div>
									{!premierAgentWebsite && (
										<div className='sub_main_child'>
											<div className='sub_main_imageHolder'>
												<img
													src='https://bpooperationsmain.s3.eu-west-2.amazonaws.com/template_images/premPngIcon.png'
													alt='Farm Logo'
													style={{ width: '50px' }}
												/>
												<p style={{ fontWeight: '700' }}>BPO Premier Agent</p>
											</div>
											<div className='sub_main_title'>
												<p>BPO Premier does everything you need for online advertisement.</p>
												<a href='/choose-sub/premier-agent-website/subscription' className='sub_main_link'>
													Subscribe
												</a>
											</div>
										</div>
									)}
									{!dealAnalyzer && (
										<div className='sub_main_child'>
											<div className='sub_main_imageHolder'>
												<img
													src='https://bpooperationsmain.s3.eu-west-2.amazonaws.com/template_images/dealPngIcon.png'
													alt='Farm Logo'
													style={{ width: '50px' }}
												/>
												<p style={{ fontWeight: '700' }}>BPO Deal Analyzer</p>
											</div>
											<div className='sub_main_title'>
												<p>Evaluate deals like an investor.</p>
												<a href='/choose-sub/deal-analyzer/subscription' className='sub_main_link'>
													Subscribe
												</a>
											</div>
										</div>
									)}
								</div>
							</div>
						)}
					</div>
					<div className='card_row'>
						<ReportComp setLoader={setIsLoading} />
						<ReportStatus />
					</div>
				</div>
			</div>
		</>
	);
};

export default PremierAgentsDashboard;

const ReportComp = ({ setLoader }) => {
	const agent_id = useSelector((state) => state.auth.typeId);
	const [isLoading, setIsLoading] = useState(false);
	const [detailsModal, setDetailsModal] = useState(false);
	const [agentDetails, setAgentDetails] = useState({
		first_name: '',
		last_name: '',
		address: '',
		phone: '',
		brokerage_name: '',
		brokerage_address: '',
		brokers_ein: '',
		amount: '',
		has_ein: false,
	});
	const [comps, setComps] = useState({
		amount: 0,
		numberCompleted: 0,
	});
	React.useEffect(() => {
		setIsLoading(true);
		base
			.get('/compensations/get-agent-amount/', {
				params: {
					agent_id,
				},
			})
			.then(({ data }) => {
				const { agent_amount, numberCompleted } = data;
				setComps({ amount: agent_amount || 0, numberCompleted: numberCompleted || 0 });
			})
			.catch((err) => {})
			.finally(() => {
				setIsLoading(false);
			});
	}, [agent_id]);

	const typeId = useSelector((state) => state.auth.typeId);
	const openDetails = () => {
		if (comps.amount === 0) {
			toast.warn('No compensation to cashout.');
			return;
		}
		setLoader(true);
		base
			.get(`register/agents/${typeId}/`)
			.then(({ data }) => {
				setAgentDetails({
					first_name: data.first_name,
					last_name: data.last_name,
					phone: data.phone,
					brokerage_name: data.brokerage_name,
					brokerage_address: data.brokerage_address,
					amount: '',
					brokers_ein: data.brokers_ein || '',
					has_ein: data.brokers_ein ? true : false,
				});
				setDetailsModal(true);
			})
			.catch((err) => {})
			.finally(() => {
				setLoader(false);
			});

		// () => setDetailsModal(true)
	};

	const sendRequest = async () => {
		//todo validation
		if (
			!agentDetails.first_name ||
			!agentDetails.last_name ||
			!agentDetails.phone ||
			!agentDetails.brokerage_name ||
			!agentDetails.brokerage_address
		) {
			toast.warn('You have to complete your profile first');
			return;
		}
		if (!agentDetails.brokers_ein) {
			toast.warn("Please enter your broker's EIN");
			return;
		}
		if (!agentDetails.amount) {
			toast.warn('Please enter an amount');
			return;
		}
		if (+agentDetails.amount > comps.amount) {
			toast.warn('You cannot request more than you have earned');
			return;
		}

		setLoader(true);

		try {
			if (!agentDetails.has_ein) {
				await base.patch(`register/agents/${typeId}/`, { brokers_ein: agentDetails.brokers_ein });
			}
			const { data } = await base.post('/compensations/agent-report/', {
				agent: agent_id,
				amount: +agentDetails.amount,
			});
			toast.info(data.detail);
			setDetailsModal(false);
		} catch (err) {
			toast.error('Could not post request');
		} finally {
			setLoader(false);
		}
	};

	if (isLoading) {
		return (
			<div className='compensation' style={{ minHeight: '300px' }}>
				<CircularProgress color='secondary' />
			</div>
		);
	}

	return (
		<>
			<Dialog open={detailsModal} fullWidth onClose={() => setDetailsModal(false)}>
				<DialogContent dividers sx={{ position: 'relative' }}>
					<IconButton onClick={() => setDetailsModal(false)} sx={{ position: 'absolute', top: '5%', right: '5%' }}>
						<Close fontSize='small' />
					</IconButton>
					<Box sx={{ padding: '2rem' }}>
						<Typography mb={3} textAlign={'center'}>
							Cashout Pay
						</Typography>
						<Grid container spacing={2}>
							<Grid item xl={6}>
								<Tooltip title='Go to profile to edit'>
									<InputLabel>First Name </InputLabel>
								</Tooltip>
								<TextField fullWidth disabled name='first_name' defaultValue={agentDetails.first_name} variant='outlined' />
							</Grid>
							<Grid item xl={6}>
								<Tooltip title='Go to profile to edit'>
									<InputLabel>Last Name</InputLabel>
								</Tooltip>
								<TextField fullWidth name='last_name' disabled defaultValue={agentDetails.last_name} variant='outlined' />
							</Grid>
							<Grid item xl={12}>
								<Tooltip title='Go to profile to edit'>
									<InputLabel>Phone</InputLabel>
								</Tooltip>
								<TextField
									fullWidth
									name='address'
									disabled
									defaultValue={formatPhoneNumber(agentDetails.phone) || agentDetails.phone}
									variant='outlined'
								/>
							</Grid>
							<Grid item xl={12}>
								<Tooltip title='Go to profile to edit'>
									<InputLabel>Broker Name</InputLabel>
								</Tooltip>
								<TextField fullWidth name='brokerage_name' disabled defaultValue={agentDetails.brokerage_name} required variant='outlined' />
							</Grid>
							<Grid item xl={12}>
								<Tooltip title='Go to profile to edit'>
									<InputLabel>Broker Address</InputLabel>
								</Tooltip>
								<TextField fullWidth name='brokerage_address' disabled defaultValue={agentDetails.brokerage_address} variant='outlined' />
							</Grid>
							<Grid item xl={12}>
								<InputLabel>Broker EIN</InputLabel>
								<TextField
									fullWidth
									name='brokers_ein'
									value={agentDetails.brokers_ein}
									onChange={(e) => setAgentDetails((cur) => ({ ...cur, [e.target.name]: e.target.value }))}
									variant='outlined'
								/>
							</Grid>
							<Grid item xl={12}>
								<InputLabel>Amount($ {formatNumber(comps.amount)} available)</InputLabel>
								<TextField
									fullWidth
									name='amount'
									type='number'
									variant='outlined'
									value={agentDetails.amount}
									onChange={(e) => setAgentDetails((cur) => ({ ...cur, [e.target.name]: e.target.value }))}
								/>
							</Grid>
						</Grid>
					</Box>
				</DialogContent>
				<DialogActions sx={{ padding: '1rem 2rem' }}>
					<Button disableElevation sx={{ textTransform: 'none' }} variant='contained' color='secondary' onClick={sendRequest}>
						Submit
					</Button>
				</DialogActions>
			</Dialog>
			<Tooltip title='Click to request cashout'>
				<div className='compensation' onClick={openDetails} style={{ cursor: 'pointer', width: 'min(500px,100%)', paddingBottom: '4rem' }}>
					<p className='title'>Compensation</p>
					<div className='hr' />
					<div className='cash_total'>
						<p className='total_cost' style={{ whiteSpace: 'nowrap' }}>
							Total Cash:
						</p>
						<p className='total_value' style={{ whiteSpace: 'nowrap' }}>
							<span>$</span>&nbsp; {formatNumber(comps.amount)}
						</p>
					</div>
					{/* <p className='report_info' style={{ opacity: 0 }}>
						{comps.amount / 100} BPO Report(s) sold this month
					</p> */}
				</div>
			</Tooltip>
		</>
	);
};

const ReportStatus = () => {
	const [reportState, setreportState] = useState({
		pending: 0,
		completed: 0,
		incompleted: 0,
	});
	const [isLoading, setIsLoading] = useState(true);
	useEffect(() => {
		(async () => {
			try {
				const { data } = await base.get('/leads/summary_report/');
				setreportState(data);
			} catch (error) {
			} finally {
				setIsLoading(false);
			}
		})();
	}, []);

	if (isLoading) {
		return (
			<div className='compensation' style={{ minHeight: '300px' }}>
				<CircularProgress color='secondary' />
			</div>
		);
	}

	return (
		<div className='compensation' style={{ fontSize: '20px', fontWeight: 'bold' }}>
			<p className='title'>Report Summary</p>
			<div className='hr' />
			<Tooltip title='Click to view new requests for reports'>
				<Link
					className={reportState?.incompleted > 0 ? 'resizer' : ''}
					style={
						reportState?.incompleted === 0
							? { cursor: 'pointer' }
							: { cursor: 'pointer', backgroundColor: '#ff000082', borderRadius: '5px', color: 'white', padding: '1rem', fontSize: '35px' }
					}
					to='/customer-requested-report'
				>
					New reports: <span>{reportState?.incompleted}</span>
				</Link>
			</Tooltip>
			<Tooltip title='Click to view saved reports'>
				<Link style={{ cursor: 'pointer' }} to='/completed-reports'>
					Saved reports: {reportState?.pending}
				</Link>
			</Tooltip>
			<Tooltip title='Click to view completed reports'>
				<Link style={{ cursor: 'pointer' }} to='/completed-reports'>
					Completed reports: {reportState?.completed}
				</Link>
			</Tooltip>
		</div>
	);
};
