import { Box, Button, Container, Divider, Typography } from '@mui/material';
//import { makeStyles } from '@mui/styles'
import React from 'react';
import AdBanner from '../../components/MUI-Components/AdBanner';
//import Navbar from '../../components/MUI-Components/Navbar'
import ReportBanner from '../../assets/reportBanner.webp';
import GridSection from '../../components/MUI-Components/GridSection';
import Report1 from '../../assets/report01.webp';
import Report2 from '../../assets/report02.webp';
import Report3 from '../../assets/report03.webp';
import Report4 from '../../assets/report04.webp';
import Premier05 from '../../assets/premier05.webp';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toggleAuthModal } from '../../actions';
import SEO from '../../components/SEO';

const AdvertiseReport = () => {
	//const classes = useStyles()
	const loggedIn = useSelector((state) => state?.auth?.token?.access);
	const dispatch = useDispatch();

	const navigate = useNavigate();
	const gotoReport = () => {
		if (loggedIn) {
			navigate('/customer-requested-report');
		} else {
			dispatch(toggleAuthModal());
			//!navigate to view report pages
			// localStorage.setItem('nextRoute', '/available-territories');
		}
	};

	const sections = [
		{
			title: 'Create Custom BPO Reports',
			copy: `
            We have streamlined the BPO Report Process so consumers can make the right decisions
            just like Lenders. In just minutes you can generate a BPO Report Branded with your information & receive the lead to the Buyer or Seller who requested the report
            `,
			img: Report1,
		},
		{
			title: 'Custom BPO Report ordering page',
			copy: `Stop giving away worthless computer generated algorithmic CMA's and provide your clients with BPO Reports and get compensated for your time and efforts`,
			img: Report2,
		},
		{
			title: 'Earn $100 per Report',
			copy: `That's right...!!
            We pay you for your market expertise and you earn $100.00 per BPO Report you provide. `,
			img: Report3,
		},
		{
			title: 'Receive Account credits that pay for your marketing',
			copy: `Just 2-4 BPO orders per Month can pay for All of your Premier Agent Combo Marketing for an entire YEAR!`,
			img: Report4,
		},
	];

	return (
		<div>
			{/* NAVBAR */}
			{/* <Navbar /> */}
			<SEO>
				<title>Earn Money for Your Expertise with BPO Homes Report Agent - Free Platform</title>
				<meta name='og:title' content='Earn Money for Your Expertise with BPO Homes Report Agent - Free Platform' />
				<meta
					name='description'
					content='Join BPO Homes Report Agent and earn $100 per report for your market expertise. Create custom BPO reports and receive account credits to pay for your marketing. Sign up now for a 100% free platform.'
				/>
				<meta
					name='og:description'
					content='Join BPO Homes Report Agent and earn $100 per report for your market expertise. Create custom BPO reports and receive account credits to pay for your marketing. Sign up now for a 100% free platform.'
				/>
				<meta name='og:image' content={window.location.origin + ReportBanner} />
			</SEO>

			<Box>
				{/* BANNER */}
				<AdBanner title='Earn Money for Your Expertise & Begin Earning Advertising Money and Leads!' bgImage={ReportBanner} titleColor={'#fff'}>
					<Box
						textAlign={'center'}
						color='black'
						sx={{ backgroundColor: 'white' }}
						maxWidth='300px'
						ml='auto'
						borderRadius='5px'
						padding='15px 30px '
						maxHeight='100%'
					>
						<Typography variant='h6' fontSize='20px' fontWeight={500} lineHeight={1}>
							BPO Homes Report Agent
						</Typography>
						<Divider style={{ margin: '1rem auto', width: '4rem', height: '2px', background: 'var(--primary-color)' }} />

						<Typography variant='h6' mt={4} fontSize={'40px'}>
							100%
						</Typography>
						<Typography fontWeight={300} mb={4}>
							Free Platform
						</Typography>

						<Button
							variant='contained'
							disableElevation
							color='primary'
							style={{ textTransform: 'none', marginTop: '1rem', color: '#fff', width: 'fit-content' }}
							onClick={() => {
								gotoReport();
							}}
						>
							Subscribe
						</Button>
					</Box>
				</AdBanner>

				<Box padding={'4rem 2rem'} textAlign='center' color={'#000'}>
					<Container>
						<Box>
							<Typography variant='h5' gutterBottom>
								What's the catch?
							</Typography>
						</Box>

						<Box>
							{sections.map((el, index) => {
								return <GridSection key={index} index={index} title={el.title} copy={el.copy} img={el.img} />;
							})}
						</Box>
					</Container>
				</Box>

				<Box
					style={{
						backgroundImage: `linear-gradient(#1378A595, #1367a5), url(${Premier05})`,
						backgroundSize: 'cover',
						padding: '3rem',
						marginTop: '2rem',
					}}
				>
					<Container>
						<Box textAlign={'center'} color='#fff' width='80%' margin='0 auto'>
							<Typography variant='h6'>BPO Homes Report Agent 100% FREE Platform</Typography>
							<Divider style={{ margin: '1rem auto', marginBottom: '2rem', width: '4rem', height: '2px', background: '#fff' }} />
							<Button
								variant='contained'
								disableElevation
								color='primary'
								style={{ width: '15rem', textTransform: 'none', marginTop: '1rem', color: '#fff' }}
								onClick={() => {
									gotoReport();
								}}
							>
								Subscribe
							</Button>
						</Box>
					</Container>
				</Box>
			</Box>
		</div>
	);
};

export default AdvertiseReport;
