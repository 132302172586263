import { Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
	Autocomplete,
	Avatar,
	Chip,
	CircularProgress,
	Dialog,
	DialogTitle,
	Grid,
	IconButton,
	Button as NButton,
	Stack,
	Switch,
	TextField,
	Typography,
} from '@mui/material';
import { makeStyles, styled } from '@mui/styles';
import Delete from '@untitled-ui/icons-react/build/esm/Delete';
import FileSearch02 from '@untitled-ui/icons-react/build/esm/FileSearch02';
import Plus from '@untitled-ui/icons-react/build/esm/Plus';
import Save02 from '@untitled-ui/icons-react/build/esm/Save02';
import Save03 from '@untitled-ui/icons-react/build/esm/Save03';
import Send03 from '@untitled-ui/icons-react/build/esm/Send03';
import Trash01 from '@untitled-ui/icons-react/build/esm/Trash01';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import base from '../apis';
import FollowUpDialog from '../components/FollowUpDialog';
import QuotePDF from '../components/QuotePDF';
import { clientSchema, quoteDataSchema } from '../schema';

const Button = styled(NButton)(({ theme }) => ({
	textTransform: 'capitalize',
}));

const useStyles = makeStyles((theme) => ({
	card: {
		boxShadow: '0px 1px 2px 0px #1018280D',
		border: '1px solid #EAECF0',
		// padding: '1.25rem 1rem',
		borderRadius: '12px',
		height: '100%',
	},
	chip: {
		border: '1px solid #A3C5E0',
		background: '#DBE9F3',
		height: '22px',
		padding: '2px 8px',
		'& .MuiChip-label': {
			paddingLeft: 0,
			paddingRight: 0,
			color: '#0D5894',
		},
	},
	greyIcon: {
		'& path': {
			stroke: '#475467',
		},
	},
	notes: {
		height: '100%',
		'& .MuiInputBase-root': {
			height: '100%',
			alignItems: 'flex-start',
		},
		'& textarea::placeholder': {
			fontFamily: 'Inter',
		},
	},
}));

const to2 = (number) => +Number(number).toFixed(2);

const focuser = (arr) => {
	const id = arr.join('-');
	if (!id) return;
	document.getElementById(id)?.focus();
};

const GenerateQuotes = () => {
	const classes = useStyles();
	const { id } = useParams();
	const [isLoading, setIsLoading] = useState(false);
	const [userInfo, setUserInfo] = useState(null);
	const { indPro } = useSelector((state) => state.subscriptions);
	const selectedListing = useSelector((state) => state.auth.selectedListing);
	const [clientDetails, setClientDetails] = useState([
		{
			first_name: '',
			last_name: '',
			email: '',
			phone_number: '',
			message: '',
		},
	]);
	const navigate = useNavigate();

	useEffect(() => {
		//get user details
		//get invoice details
		(async () => {
			if (!selectedListing) {
				navigate('/my-listing/all');
				return;
			}
			if (!indPro) {
				navigate('/industry-prof-subscription', { replace: true });
				return;
			}
			try {
				setIsLoading(true);

				setUserInfo(selectedListing);
				if (id) {
					const { data: quote } = await base.get(`professionals/quotes/${id}/`);
					//check for quote_data if not then assign data.first_name etc to clientDetails
					const repliedQuotes = quote.replied_quotes;
					if (!repliedQuotes?.id) {
						const { first_name, last_name, email, message, phone_number } = quote;
						setClientDetails([
							{
								first_name,
								last_name,
								phone_number,
								message,
								email,
							},
						]);
					} else {
						const { request_quote, item, ...others } = repliedQuotes;
						const itemsToAdd = [...item].map((i) => ({ ...i, show: true, unit_price: i.amount, description: i.message }));
						setClientDetails([request_quote]);

						setDetails({
							...others,
							items: itemsToAdd,
						});
					}

					//get quote details
				}
			} catch (error) {
				toast.error('Could not get details');
			} finally {
				setIsLoading(false);
			}
		})();
	}, [id, indPro, navigate, selectedListing]);
	const [details, setDetails] = useState({
		tax_rate: '',
		message: '',
		items: [
			{
				item: '',
				description: '',
				unit_price: '',
				quantity: '',
				amount: '',
				show: true,
			},
		],
	});

	const onAddNewItem = () => {
		setDetails((v) => ({
			...v,
			items: [
				...v.items,
				{
					item: '',
					description: '',
					unit_price: '',
					quantity: '',
					amount: '',
					show: true,
				},
			],
		}));
	};

	const onClearAllItems = () => {
		setDetails((v) => ({
			...v,
			items: [
				{
					item: '',
					description: '',
					unit_price: '',
					quantity: '',
					amount: '',
					show: true,
				},
			],
		}));
	};

	const onAddNewClient = () => {
		setClientDetails((v) => {
			return [
				...v,
				{
					first_name: '',
					last_name: '',
					email: '',
					phone_number: '',
					message: '',
				},
			];
		});
	};

	const removeItem = (index) => {
		setDetails((v) => {
			const items = v.items.filter((_, i) => i !== index);
			if (items.length === 0) {
				items.push({
					item: '',
					description: '',
					unit_price: '',
					quantity: '',
					amount: '',
					show: true,
				});
			}

			return { ...v, items };
		});
	};
	const removeClientItem = (index) => {
		setClientDetails((v) => {
			const filtered = v.filter((_, i) => i !== index);

			if (filtered.length > 0) return filtered;

			return [
				{
					first_name: '',
					last_name: '',
					email: '',
					phone_number: '',
					message: '',
				},
			];
		});
	};

	const handleChange = (item, value, index) => {
		setDetails((v) => {
			const items = v.items;
			items[index][item] = value;

			return { ...v, items };
		});
	};
	const handleClientDetailsChange = (name, value, index) => {
		setClientDetails((v) => {
			const updatedClients = [...v];

			updatedClients[index][name] = value;
			return updatedClients;
		});
	};

	const [isSendLoading, setIsSendLoading] = useState(false);

	const aggregateData = () => {
		const body = {
			sub_total: to2(subtotal || 0),
			tax: to2(taxAmount || 0),
			tax_rate: `${details.tax_rate}` || '0',
			quote_amount: to2(subtotal + taxAmount),
			message: details.message,
			total: to2(subtotal + taxAmount),
			items: details.items
				.filter((i) => i.show)
				.map((item) => ({
					item: item.item,
					message: item.description,
					quantity: +item.quantity,
					amount: +item.unit_price,
					total_amount: to2(item.unit_price * item.quantity),
				})),
			quote_data: clientDetails,
		};
		const { quote_data, ...rest } = body;
		const clientResult = clientSchema.safeParse(quote_data);
		if (!clientResult.success) {
			const issue = clientResult?.error?.issues[0];
			focuser(issue?.path);
			toast.error(issue.message);
			return;
		}

		const result = quoteDataSchema.safeParse(rest);

		if (!result.success) {
			const issue = result?.error?.issues[0];
			focuser(issue?.path);
			toast.error(issue.message);
			return;
		}

		return {
			quote_data: clientResult.data.map((el) => {
				const toReturn = { ...el, profession_id: selectedListing?.id };
				if (id) toReturn['quote_id'] = id;
				return toReturn;
			}),
			...result.data,
		};
	};

	const [idToSave, setIdToSave] = useState('');
	const save = async (isDraft = true) => {
		try {
			const result = aggregateData();
			if (!result) return;
			setIsSendLoading(true);
			const { data } = await base.post('/professionals/save-multi-quotes/', {
				...result,
				status: isDraft ? 'draft' : 'closed',
			});
			setIdToSave(data.id);
			toast.success('Quote saved successfully');
		} catch (error) {
			toast.error('Could not save quote');
		} finally {
			setIsSendLoading(false);
		}
	};

	const handleSendOnly = async () => {
		try {
			const result = aggregateData();
			if (!result) return;
			setIsSendLoading(true);
			const { data } = await base.post('/professionals/save-multi-quotes/', {
				...result,
				status: 'only_send',
			});
			setIdToSave(data.id);

			setDetailsModalOpen(true);
			setFollowUp({
				date: null,
				message: '',
			});
		} catch (error) {
			toast.error('Could not send');
		} finally {
			setIsSendLoading(false);
		}
	};

	const subtotal = details.items.filter((i) => i.show).reduce((acc, cur) => acc + cur.unit_price * cur.quantity, 0);
	const taxAmount = subtotal * (details.tax_rate / 100);

	const [detailsModalOpen, setDetailsModalOpen] = useState(false);
	const [previewOpen, setPreviewOpen] = useState(false);
	const [followUp, setFollowUp] = useState({
		date: null,
		message: '',
	});

	const [content, setContent] = useState('');

	const onClickPreview = () => {
		const { company_name, address, icon_file, formatted_phone_number } = userInfo;
		const email = clientDetails[0]?.email || '';
		const name = `${clientDetails[0].first_name ? clientDetails[0].first_name : ''}${
			clientDetails[0].last_name ? ' ' + clientDetails[0].last_name : ''
		}`;
		const phone_number = clientDetails[0]?.phone_number || '';
		const items = details.items
			.filter((i) => i.show)
			.map((el) => ({
				...el,
				unit_price: Number(el.unit_price || 0).toFixed(2),
				amount: Number((el.quantity || 0) * (el.unit_price || 0)).toFixed(2),
			}));
		const tax_amount = Number(taxAmount).toFixed(2);
		const sub_total = Number(subtotal).toFixed(2);
		const Total = Number(subtotal + taxAmount).toFixed(2);

		const contentToShow = {
			company_name,
			address,
			icon_file,
			formatted_phone_number,
			email,
			name,
			phone_number,
			items,
			tax_rate: details.tax_rate,
			tax_amount,
			sub_total,
			Total,
			message: details.message,
		};
		setContent(contentToShow);

		setPreviewOpen(true);
	};

	const [isFollowupLoading, setIsFollowupLoading] = useState(false);
	const handleSubmitFollowup = async () => {
		if (!followUp.message) {
			toast.warn('Please enter a follow up message');
			return;
		}
		if (!followUp.date) {
			toast.warn('Please select a date');
			return;
		}
		setIsFollowupLoading(true);
		try {
			await base.post('professionals/follow-up/create/', {
				send_date: followUp.date?.format(),
				message: followUp.message,
				quote: id || idToSave,
			});
			toast.success('Follow up saved successfully');
			setDetailsModalOpen(false);
			setFollowUp({
				date: null,
				message: '',
			});
		} catch (error) {
			toast.error('Could not save follow up');
		} finally {
			setDetailsModalOpen(false);
		}
	};

	if (isLoading) {
		return (
			<Stack sx={{ height: '90vh' }} alignItems='center' justifyContent={'center'}>
				<CircularProgress size={60} color='secondary' variant='indeterminate' />
			</Stack>
		);
	}

	return (
		<>
			<FollowUpDialog
				isOpen={detailsModalOpen}
				setOpen={setDetailsModalOpen}
				date={followUp.date}
				onDateChange={(value) => setFollowUp((v) => ({ ...v, date: value }))}
				message={followUp.message}
				onMessageChange={(e) => setFollowUp((v) => ({ ...v, message: e.target.value }))}
				onSubmit={handleSubmitFollowup}
				isLoading={isFollowupLoading}
			/>

			{/* Preview modal */}
			<Dialog fullWidth maxWidth='lg' open={previewOpen} onClose={() => setPreviewOpen(false)}>
				<DialogTitle>
					<Stack direction='row' justifyContent={'space-between'}>
						<Typography fontWeight={600} fontSize={'20px'}>
							Preview
						</Typography>

						<IconButton onClick={() => setPreviewOpen(false)}>
							<Close fontSize='small' />
						</IconButton>
					</Stack>
				</DialogTitle>
				<QuotePDF content={content} />
				{/* <DialogContent sx={{ maxHeight: '60vh', overflowY: 'auto' }} dividers ref={doc}>
					<Stack p='1rem' gap='1rem'>
						<Typography
							variant='h4'
							align='center'
							color='#101828'
							fontSize='30px'
							fontWeight='600'
							gutterBottom
							sx={{ background: '#F2F4F7', borderRadius: '12px', padding: '1rem', textAlign: 'center' }}
						>
							Quotation
						</Typography>

						<Stack gap='20px' sx={{ mt: 3, mb: 2 }} borderBottom={'1px solid #EAECF0'} pb='1rem'>
							<Typography variant='h6' borderBottom={'1px solid #EAECF0'} pb='1rem'>
								Quotation From
							</Typography>
							<Grid container spacing={2}>
								<Grid item xs={2}>
									<Box
										component='img'
										sx={{ width: '100%', aspectRatio: 1, height: 150, objectFit: 'cover', borderRadius: '8px' }}
										alt='Company Building'
										src={selectedListing?.icon_file}
									/>
								</Grid>
								<Grid item xs={10}>
									<Stack direction='row' justifyContent='space-between' sx={{ height: '100%' }}>
										<Stack sx={{ height: '100%' }} justifyContent={'space-between'} py='1rem'>
											<Typography>
												<strong>Company Name:</strong> {content?.company_name}
											</Typography>
											<Typography>
												<strong>Company Address:</strong> {content?.address}
											</Typography>
											<Typography>
												<strong>Company Phone:</strong> {content?.phone}
											</Typography>
										</Stack>
										<Stack sx={{ height: '100%' }} justifyContent={'space-between'} py='1rem'>
											<Typography>
												<strong>Date:</strong> {content?.date || dayjs().format('DD MMMM, YYYY')}
											</Typography>
										</Stack>
									</Stack>
								</Grid>
							</Grid>
						</Stack>

						<Box sx={{ mb: 4 }} borderBottom={'1px solid #EAECF0'} py='1rem'>
							<Typography variant='h6' gutterBottom borderBottom={'1px solid #EAECF0'} py='1rem'>
								Quotation For
							</Typography>
							<Typography>
								<strong>Customer Name:</strong> {content?.name}
							</Typography>

							<Typography>
								<strong>Customer Phone:</strong> {content?.phone_number}
							</Typography>
						</Box>

						<TableContainer>
							<Table>
								<TableHead>
									<TableRow sx={{ backgroundColor: '#2978B7' }}>
										<TableCell sx={{ color: 'white' }}>#</TableCell>
										<TableCell sx={{ color: 'white' }}>Item</TableCell>
										<TableCell sx={{ color: 'white' }}>Description</TableCell>
										<TableCell sx={{ color: 'white' }} align='right'>
											Unit Price
										</TableCell>
										<TableCell sx={{ color: 'white' }} align='right'>
											Quantity
										</TableCell>
										<TableCell sx={{ color: 'white' }} align='right'>
											Amount
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{content?.items?.map((row, index) => (
										<TableRow key={index}>
											<TableCell>{index + 1}</TableCell>
											<TableCell>{row.item}</TableCell>
											<TableCell>{row.description}</TableCell>
											<TableCell align='right'>{row.unit_price}</TableCell>
											<TableCell align='right' sx={{ borderBottomColor: index + 1 === content?.items.length ? '#2978B7' : '' }}>
												{row.quantity}
											</TableCell>
											<TableCell align='right' sx={{ borderBottomColor: index + 1 === content?.items.length ? '#2978B7' : '' }}>
												{row.amount}
											</TableCell>
										</TableRow>
									))}
									<TableRow>
										<TableCell sx={{ border: 'none' }}></TableCell>
										<TableCell sx={{ border: 'none' }}></TableCell>
										<TableCell sx={{ border: 'none' }}></TableCell>
										<TableCell sx={{ border: 'none' }} align='right'></TableCell>
										<TableCell align='right' sx={{ fontWeight: 500, color: '#2978B7', border: '1px solid #2978B7' }}>
											SUBTOTAL
										</TableCell>
										<TableCell align='right' sx={{ color: '#2978B7', border: '1px solid #2978B7' }}>
											{content?.sub_total}
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell sx={{ border: 'none' }}></TableCell>
										<TableCell sx={{ border: 'none' }}></TableCell>
										<TableCell sx={{ border: 'none' }}></TableCell>
										<TableCell sx={{ border: 'none' }} align='right'></TableCell>
										<TableCell align='right' sx={{ fontWeight: 500, color: '#2978B7', border: '1px solid #2978B7' }}>
											TAX ({content?.tax_rate || 0}%)
										</TableCell>
										<TableCell align='right' sx={{ color: '#2978B7', border: '1px solid #2978B7' }}>
											{content?.tax_amount}
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell sx={{ border: 'none' }}></TableCell>
										<TableCell sx={{ border: 'none' }}></TableCell>
										<TableCell sx={{ border: 'none' }}></TableCell>
										<TableCell align='right' sx={{ border: 'none' }}></TableCell>
										<TableCell align='right' sx={{ fontWeight: 500, color: '#2978B7', border: '1px solid #2978B7' }}>
											TOTAL
										</TableCell>
										<TableCell align='right' sx={{ color: '#2978B7', border: '1px solid #2978B7' }}>
											{content?.Total}
										</TableCell>
									</TableRow>
								</TableBody>
							</Table>
						</TableContainer>
						<Typography sx={{ minHeight: '100px', border: '1px solid #EAECF0', borderRadius: '12px', padding: '1rem' }}>
							{content?.message}
						</Typography>
						<Typography>If you have any questions concerning this quotation. Kindly contact me.</Typography>
					</Stack>
				</DialogContent> */}
			</Dialog>
			<Stack p='1rem 2rem' gap='1rem'>
				<Stack sx={{ borderBottom: '1px solid #EAECF0', paddingBottom: '20px' }} direction='row'>
					<Typography variant='h1' color='#475467' fontWeight={700} fontSize='24px'>
						Generate Quote
					</Typography>
				</Stack>
				<Stack direction='row' gap='1rem' sx={{ borderBottom: '1px solid #EAECF0' }} py='1rem'>
					<Avatar src={selectedListing?.icon_file} />
					<Stack>
						<QuoteItemsWithChip num={details.items.length} />
						<Typography color='#475467' fontSize={'14px'}>
							Fill out the template below to generate a free quote. Once complete, you can save, download, or send the quote for free.
						</Typography>
					</Stack>
					<Stack ml='auto' direction='row' gap='1rem' alignItems='center'>
						<Button
							variant='outlined'
							color='secondary'
							startIcon={<FileSearch02 height={'18px'} width={'16px'} />}
							size='small'
							onClick={onClickPreview}
						>
							Preview
						</Button>
						<LoadingButton
							onClick={handleSendOnly}
							color='secondary'
							variant='contained'
							endIcon={<Send03 height={'18px'} width={'16px'} />}
							size='small'
							loading={isSendLoading}
						>
							Send
						</LoadingButton>
					</Stack>
				</Stack>
				<Stack className={classes.card} sx={{ overflow: 'hidden' }}>
					<Stack
						sx={{ background: '#F9FAFB', borderBottom: '1px solid #EAECF0' }}
						direction='row'
						justifyContent='space-between'
						p='1rem'
						alignItems='center'
					>
						<Typography fontWeight={600} fontSize={18}>
							Client Information
						</Typography>
						<Stack direction='row' gap='1rem'>
							<Button
								variant='outlined'
								color='text'
								startIcon={<Delete width='18px' height='18px' />}
								size='small'
								onClick={() =>
									setClientDetails([
										{
											first_name: '',
											last_name: '',
											email: '',
											phone_number: '',
											message: '',
										},
									])
								}
							>
								Clear All
							</Button>
							<Button variant='outlined' color='secondary' startIcon={<Plus width='18px' height='18px' />} size='small' onClick={onAddNewClient}>
								Add Another Client
							</Button>
						</Stack>
					</Stack>
					<Stack>
						{clientDetails.map((el, i) => (
							<Stack p='1rem' direction='row' alignItems='center' gap='1rem' key={i}>
								<IconButton onClick={() => removeClientItem(i)}>
									<Trash01 style={{ cursor: 'pointer' }} stroke='#475467' className={classes.greyIcon} />
								</IconButton>
								{[
									{ placeholder: 'First Name', name: 'first_name' },
									{ placeholder: 'Last Name', name: 'last_name' },
									{ placeholder: 'Email', name: 'email' },
									{ placeholder: 'Phone', name: 'phone_number' },
								].map((item) => (
									<TextField
										placeholder={item.placeholder}
										color='secondary'
										id={`${i}-${item.name}`}
										key={item.name}
										size='small'
										name={item.name}
										fullWidth
										sx={{ flex: 1 }}
										value={el?.[item.name]}
										onChange={(e) => {
											handleClientDetailsChange(item.name, e.target.value, i);
										}}
									/>
								))}
							</Stack>
						))}
					</Stack>
				</Stack>
				<Stack className={classes.card} sx={{ overflow: 'hidden' }}>
					<Stack
						sx={{ background: '#F9FAFB', borderBottom: '1px solid #EAECF0' }}
						direction='row'
						justifyContent='space-between'
						p='1rem'
						alignItems='center'
					>
						<QuoteItemsWithChip num={details.items.length} />
						<Stack direction='row' gap='1rem'>
							<Button variant='outlined' color='text' startIcon={<Delete width='18px' height='18px' />} size='small' onClick={onClearAllItems}>
								Clear All
							</Button>
							<Button variant='outlined' color='secondary' startIcon={<Plus width='18px' height='18px' />} size='small' onClick={onAddNewItem}>
								Add Another Item
							</Button>
						</Stack>
					</Stack>
					<Stack p='1rem' gap='2rem'>
						{details.items.map((row, index) => (
							<Grid container spacing={2} key={index}>
								<Grid item xs={1}>
									<Stack
										direction='row'
										sx={{
											borderRadius: '12px',
											height: '100%',
											px: '6px',

											background: row.show ? '#EDF4F9' : '#FFFFFF',
											border: row.show ? '1px solid #EDF4F9' : '1px solid #EAECF0',
											transition: 'all 0.3s ease',
										}}
										justifyContent={'space-between'}
										alignItems='center'
									>
										<Switch
											color='secondary'
											size='small'
											className={classes.switch}
											checked={row.show}
											onChange={(e, checked) => handleChange('show', checked, index)}
										/>
										<Trash01 style={{ cursor: 'pointer' }} stroke='#475467' className={classes.greyIcon} onClick={() => removeItem(index)} />
									</Stack>
								</Grid>
								<Grid item xs={2}>
									<Autocomplete
										freeSolo
										disableClearable
										options={userInfo?.services || []}
										placeholder='Select Item'
										fullWidth
										value={row.item}
										id={`items-${index}-item`}
										name='item'
										size='small'
										onInputChange={(e, value) => handleChange('item', value, index)}
										onChange={(e, value) => handleChange('item', value, index)}
										renderInput={(params) => <TextField size='small' placeholder='Item' color='secondary' fullWidth {...params} />}
									/>
								</Grid>
								<Grid item xs={3}>
									<TextField
										size='small'
										placeholder='Item Description'
										color='secondary'
										id={`items-${index}-message`}
										fullWidth
										value={row.description}
										onChange={(e) => handleChange('description', e.target.value, index)}
									/>
								</Grid>
								<Grid item xs={2}>
									<TextField
										size='small'
										placeholder='Unit Price'
										color='secondary'
										id={`items-${index}-unit_price`}
										fullWidth
										type='number'
										value={row.unit_price}
										inputProps={{ min: 0.01 }}
										onChange={(e) => handleChange('unit_price', e.target.value, index)}
									/>
								</Grid>
								<Grid item xs={2}>
									<TextField
										size='small'
										placeholder='Quantity'
										color='secondary'
										id={`items-${index}-quantity`}
										fullWidth
										type='number'
										inputProps={{ min: 1 }}
										value={row.quantity}
										onChange={(e) => handleChange('quantity', e.target.value, index)}
									/>
								</Grid>
								<Grid item xs={2}>
									<TextField size='small' placeholder='Amount' color='secondary' fullWidth value={Number(row.unit_price * row.quantity).toFixed(2)} />
								</Grid>
							</Grid>
						))}
						<Grid container spacing={2}>
							<Grid item xs={8}>
								<TextField
									size='small'
									placeholder='Notes'
									color='secondary'
									fullWidth
									multiline
									minRows={3}
									className={classes.notes}
									value={details.message}
									onChange={(e) => setDetails((v) => ({ ...v, message: e.target.value }))}
								/>
							</Grid>
							<Grid item xs={4}>
								<Grid container spacing={2} alignItems={'center'}>
									<Grid item xs={6}>
										<Typography fontSize='14px' fontWeight={700} color='#344054'>
											Subtotal:
										</Typography>
									</Grid>
									<Grid item xs={6}>
										<TextField size='small' placeholder='Subtotal' color='secondary' fullWidth value={Number(subtotal).toFixed(2)} />
									</Grid>
									<Grid item xs={3}>
										<Stack>
											<Typography fontSize='14px' fontWeight={700} color='#344054'>
												Tax:
											</Typography>
											<Switch
												color='secondary'
												size='small'
												className={classes.switch}
												checked={details.tax_rate ? true : false}
												onChange={(e, checked) => {
													let tax_rate = 0;
													if (checked) tax_rate = '5';
													setDetails((v) => ({ ...v, tax_rate }));
												}}
											/>
										</Stack>
									</Grid>
									<Grid item xs={3}>
										<TextField
											size='small'
											placeholder='0.00%'
											color='secondary'
											inputProps={{ min: 1, step: 1, max: 100 }}
											type='number'
											value={details.tax_rate || ''}
											onChange={(e) => setDetails((v) => ({ ...v, tax_rate: e.target.value }))}
										/>
									</Grid>
									<Grid item xs={6}>
										<TextField size='small' placeholder='Tax' color='secondary' fullWidth value={Number(taxAmount).toFixed(2)} />
									</Grid>
									<Grid item xs={6}>
										<Typography fontSize='14px' fontWeight={700} color='#344054'>
											Total:
										</Typography>
									</Grid>
									<Grid item xs={6}>
										<TextField size='small' placeholder='Total' color='secondary' fullWidth value={Number(subtotal + taxAmount).toFixed(2)} />
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Stack>
				</Stack>
				<Stack direction='row' gap='1rem' justifyContent='flex-end' sx={{ borderBottom: '1px solid #EAECF0', paddingBottom: '20px' }}>
					<Button
						variant='outlined'
						color='secondary'
						startIcon={<Save03 width='18px' height='18px' />}
						onClick={() => save()}
						loading={isSendLoading}
					>
						Save to Drafts
					</Button>
					<Button
						variant='contained'
						color='secondary'
						endIcon={<Save02 width='18px' height='18px' />}
						size='small'
						onClick={() => save(false)}
						loading={isSendLoading}
					>
						Save Completed
					</Button>
				</Stack>
			</Stack>
		</>
	);
};

export default GenerateQuotes;

const QuoteItemsWithChip = ({ num }) => {
	const classes = useStyles();
	return (
		<Stack direction='row' gap='1rem'>
			<Typography fontWeight={600} fontSize={18}>
				Quote Items
			</Typography>
			<Chip
				className={classes.chip}
				label={
					<Typography color='#0D5894' fontWeight={500} fontSize='12px'>
						{`${num} Item${num > 1 ? 's' : ''}`}
					</Typography>
				}
			></Chip>
		</Stack>
	);
};
