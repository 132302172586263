export const makePmiCompulsory = (loanAmount, downPayment, downPaymentUnit)=>{
    if(loanAmount && downPayment){
        const unit = downPaymentUnit? downPaymentUnit : 0
        if(+unit===0){
            return (+downPayment/loanAmount) < 0.2
        }
        if(+unit === 1){
            return +downPayment < 20
        }
    }
    return true
}