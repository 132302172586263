import React from 'react';
import { useOutletContext } from 'react-router-dom';
import base from '../../../apis';
import Button from '../../../components/Button/Button';
import { toast } from 'react-toastify';

function Description() {
	const [listing, setIsLoading, setListing] = useOutletContext();
	const [description, setDescription] = React.useState('');
	React.useEffect(() => {
		setDescription(listing.description || '');
	}, [listing.description]);

	const onSave = () => {
		setIsLoading(true);
		base
			.patch(`premiersite/listings/${listing.id}/`, {
				description,
			})
			.then(({ data }) => {
				setListing(data);
			})
			.catch(() => {
				toast.error('Error updating description');
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	return (
		<div style={{ padding: '10px' }}>
			<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
				<p>Property Description</p>
				<Button
					style={{
						width: 'fit-content',
						height: '45px',
						fontSize: '14px',
						padding: '10px 30px',
						color: 'white',
						backgroundColor: 'var(--primary-color)',
						border: '1px solid var(--primary-color)',
						margin: 0,
					}}
					onClick={onSave}
					text='Save'
				/>
			</div>
			<textarea
				rows={20}
				value={description}
				onChange={(e) => setDescription(e.target.value)}
				style={{ width: '100%', resize: 'none', fontFamily: 'inherit' }}
				placeholder='Give a description of the subject property'
			></textarea>
		</div>
	);
}

export default Description;
