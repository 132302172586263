import React, { useState, useRef } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import base, { file } from '../../../apis';
import Button from '../../../components/Button/Button';
import { Button as MUIButton } from '@mui/material';
import './image.scss';

function Images() {
	const imageSelector = useRef(null);
	const [listing, setIsLoading, setListing] = useOutletContext();
	const [toDelete, setToDelete] = useState([]);

	const { id } = useParams();

	const handleDrop = (e) => {
		// setIsLoading(true);
		const original = [...document.querySelectorAll('.image_card')].map((item) => +item.id);
		const position = original.findIndex((item) => item === +e.currentTarget.id);
		if (position === -1) return;
		const newOrder = original.filter((item) => +item !== +selectedImage);
		newOrder.splice(position - 1 < 0 ? 0 : position - 1, 0, selectedImage);
		const body = {
			photo_ordering: newOrder.map((item, index) => ({
				photo_id: item,
				position: index + 1,
			})),
		};
		base
			.patch(`premiersite/listings/${id}/`, body)
			.then(({ data }) => {
				setListing(data);
				setToDelete([]);
			})
			.catch((err) => {
				toast.error('Error updating photo order');
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const setImages = (e) => {
		setIsLoading(true);
		const { files } = e.target;
		let formData = new FormData();
		for (let i = 0; i < files.length; i++) {
			formData.append('photos', files[i]);
		}
		file
			.patch(`premiersite/listings/${id}/`, formData)
			.then(({ data }) => {
				setListing(data);
				setToDelete([]);
				toast.success('Images uploaded successfully');
			})
			.catch((err) => {
				toast.error('Could not upload image(s)');
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const [selectedImage, setSelectedImage] = useState('');

	const setChecked = (id) => {
		if (toDelete.includes(id)) {
			setToDelete(toDelete.filter((item) => item !== id));
		} else {
			setToDelete([...toDelete, id]);
		}
	};

	const onDelete = () => {
		if (toDelete.length === 0) {
			toast.warn('Please select at least one image to delete');
			return;
		}

		const photos = listing.photos.filter((item) => !toDelete.includes(item.id));
		const body = {
			photo_ordering: photos.map((item, index) => ({
				photo_id: item.id,
				position: index + 1,
			})),
		};
		setIsLoading(true);
		base
			.patch(`premiersite/listings/${id}/`, body)
			.then(({ data }) => {
				setListing(data);
				setToDelete([]);
				toast.success('Images  successfully');
			})
			.catch((err) => {
				toast.error('Could not delete images');
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	return (
		<div className='images'>
			<p>
				To sort the images, drag and drop them in a new location. You can move a group of images by checking and dragging them to a new location. The
				system will automatically save and display the images in that order. The first image will be used as the website's header image.
			</p>
			<div className='top_row'>
				<MUIButton onClick={onDelete} variant='contained' color='error' sx={{ textTransform: 'capitalize' }}>
					Delete
				</MUIButton>
				<div className='buttons'>
					<Button
						style={{ width: '150px', margin: '0px', fontSize: '16px' }}
						onClick={() => {
							setToDelete(toDelete.length === 0 ? listing.photos.map((item) => item.id) : []);
						}}
					>
						{toDelete.length > 0 ? 'Unselect All' : 'Select All'}
					</Button>
					<Button
						style={{
							width: 'fit-content',
							margin: '0px',
							fontSize: '16px',
							backgroundColor: 'var(--primary-color)',
							color: '#ffffff',
						}}
						onClick={() => {
							imageSelector.current.click();
						}}
					>
						Upload Images
					</Button>
					<input
						type='file'
						accept='image/png, image/jpeg'
						name='photos'
						style={{ display: 'none' }}
						ref={imageSelector}
						multiple
						onChange={setImages}
					/>
				</div>
			</div>
			<div className='image_section'>
				{listing?.photos?.map((image, index) => (
					<ImageCard
						src={image.image}
						key={image.id}
						id={image.id}
						isFirst={index === 0}
						onStart={(image) => setSelectedImage(image)}
						selectedImage={selectedImage}
						handleDrop={handleDrop}
						checked={toDelete.includes(image.id)}
						setChecked={setChecked}
					/>
				))}
			</div>
		</div>
	);
}

const ImageCard = ({ src, id, onStart, selectedImage, handleDrop, checked, setChecked, isFirst }) => {
	const [showIndicator, setShowIndicator] = useState(false);
	return (
		<div
			id={id}
			style={{ borderColor: isFirst ? 'var(--primary-color)' : '#818181' }}
			className={`image_card ${showIndicator && selectedImage !== id ? 'image_card-active' : ''}`}
			draggable
			onDragStart={(e) => {
				onStart(id);
				e.dataTransfer.setData('id', e.currentTarget.id || e.target.id);
			}}
			onDragOver={(e) => {
				e.preventDefault();
				setShowIndicator(true);
			}}
			onDragLeave={(e) => {
				e.preventDefault();
				setShowIndicator(false);
			}}
			onDrop={(e) => {
				e.preventDefault();
				setShowIndicator(false);
				handleDrop(e);
			}}
			onDragEnd={(e) => {
				setShowIndicator(false);
				setShowIndicator(false);
			}}
		>
			<img className='image' src={src} alt='house' draggable={false} style={{ cursor: 'grab' }} />
			<input className='checkbox' type='checkbox' checked={checked} onChange={() => setChecked(id)} />
		</div>
	);
};

export default Images;
