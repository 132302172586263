import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { Box, Button, Link as DLink, Divider, Grid, IconButton, Stack, TextField, ThemeProvider, Typography, createTheme } from '@mui/material';
import { makeStyles, styled } from '@mui/styles';
import React from 'react';
import { Link as RLink, useParams } from 'react-router-dom';
import BG from '../../images/temp10bg.webp';
import { theme } from '../../theme';
import { formatNumber, formatPhoneNumber, getYoutubeEmbedLink, hideButton, scrollToTheLeft } from '../../utils/functions';
import InvestorQuestionnaires from '../../components/InvestorQuestionnaires/InvestorQuestionnaires';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		height: '100vh',
		background: `url(${BG})`,
		backgroundSize: 'cover',
		display: 'flex',
		alignItems: 'center',
		padding: '4rem',
	},
	navroot: {
		position: 'fixed',
		top: 0,
		width: '100%',
		zIndex: 100,
	},
	container: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: '1.5rem 4rem', // Add padding
		backgroundColor: '#CCA772', // Set background color
		color: 'black', // Set text color
	},
	leftLinks: {
		display: 'flex',
		alignItems: 'center',
		fontFamily: 'Playfair Display,serif',
		color: 'black',
	},
	link: {
		marginLeft: theme.spacing(2), // Add margin between links
		cursor: 'pointer', // Set cursor to pointer
		fontFamily: 'Playfair Display,serif',
		fontWeight: '500',
		fontSize: '16px',
		textTransform: 'uppercase',
		color: 'black !important',
	},
	agentName: {
		marginRight: theme.spacing(2), // Add margin before agent name
		fontFamily: 'Playfair Display,serif',
		fontWeight: '400',
		fontSize: '16px',
		textTransform: 'uppercase',
	},
	details: {
		width: '35%',
		padding: '2rem',
		backgroundColor: 'rgba(0, 0, 0, 0.85)',
		color: 'white',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		gap: '3rem',
	},
	defaultBox: {
		padding: '3rem 15rem',
	},
}));

const Link = styled(DLink)(({ theme }) => ({
	cursor: 'pointer',
}));
const ModDivider = styled(Divider)(({ theme }) => ({
	background: `linear-gradient(to right, rgb(204, 167, 114) 70%,white 70%, white 80%, blue 80%, blue 100%)`,
	border: 'none',
	height: '1px',
	width: '100px',
}));
const TransBut = styled(Button)(({ theme }) => ({
	backgroundColor: 'transparent',
	border: '1px solid #CCA772',
	color: '#CCA772',
	padding: '0.5rem 1rem',
	width: 'fit-content',
	borderRadius: '0',
	textTransform: 'uppercase',
}));
const FullBut = styled(Button)(({ theme }) => ({
	backgroundColor: '#CCA772',
	color: 'white',
	padding: '0.5rem 1rem',
	width: 'fit-content',
	borderRadius: '0',
	textTransform: 'uppercase',
}));

const pageTheme = createTheme({
	...theme,
	typography: {
		fontFamily: 'Playfair Display',
		button: {
			textTransform: 'capitalize',
		},
	},
	palette: {
		primary: {
			main: '#122c77',
		},
		secondary: {
			main: '#CCA772',
		},
		text: {
			main: '#000',
		},
		paper: {
			main: '#fff',
		},
	},
	primaryColor: '#122c77',
	secondaryColor: '#CCA772',
});

function NewTemp10({ agent, listings, testimonials, contactDetails, updateContactDetails, sendMessage, serviceAreas }) {
	const classes = useStyles();
	const name = agent?.user.first_name + ' ' + agent?.user.last_name;
	const agent_id = agent?.id;
	const member_since = agent?.year_work_commenced;
	const agent_phone = formatPhoneNumber(agent?.phone);
	const brokerage_phone = formatPhoneNumber(agent?.brokerage?.brokerage_phone);
	const brokerage_name = agent?.brokerage_name;
	const license = agent?.licenses.find((license) => license.category === 'real_estate_agent')?.number;
	const address = agent?.address;
	const video = agent?.video_link;
	const showInvestorForm = agent?.questionnaires_on;

	return (
		<ThemeProvider theme={pageTheme}>
			<Nav name={name} active={listings.active.length} showInvestors={showInvestorForm} />
			<Box className={classes.root}>
				<Stack className={classes.details}>
					<Divider sx={{ width: '100px', height: '2px', backgroundColor: `#CCA772` }} />
					<Typography variant='h4' textTransform={'uppercase'}>
						{agent?.landing_page_title || 'Making your real estate dreams a reality'}
					</Typography>
					<Box>
						<Typography variant='h6' textTransform={'uppercase'}>
							{name}
						</Typography>
						<Typography variant='body1' mt='1rem'>
							{address}
						</Typography>
						<Typography variant='body1'>{agent?.user?.email}</Typography>
						<Typography variant='body1' mt='1rem'>
							Cell Phone:{agent_phone}
						</Typography>
						{brokerage_name && (
							<Typography variant='body1'>
								Brokerage: {brokerage_name} {brokerage_phone ? `(${brokerage_phone})` : ''}
							</Typography>
						)}
						<Typography variant='body1' mt='1rem'>
							Member since:{member_since}
						</Typography>
						<Typography variant='body1'>Real Estate License: {license}</Typography>
					</Box>
					<TransBut variant='contained'>Get in touch</TransBut>
				</Stack>
			</Box>
			<Grid container spacing={3} className={classes.defaultBox} alignItems='center' justifyContent={'center'}>
				<Grid item xs={6}>
					<img
						src={agent?.profile_image}
						alt='Agent'
						style={{
							width: '400px',
							aspectRatio: '3/4',
							objectFit: 'cover',
							objectPosition: 'top center',
							marginLeft: 'auto',
							marginRight: '1rem',
							display: 'block',
						}}
					/>
				</Grid>
				<Grid item xs={6} id='about'>
					<Typography variant='h5' fontWeight='700' mb='10px'>
						Welcome
					</Typography>
					<ModDivider />
					<Typography variant='body1' whiteSpace={'pre-line'} my='1rem'>
						{agent?.bio}
					</Typography>
					<FullBut variant='contained' disableElevation disableRipple href={`/order-bpo-report/${agent_id}`} target='_blank'>
						Order a bpo report
					</FullBut>
				</Grid>
			</Grid>
			{showInvestorForm && (
				<Box padding={'3rem'}>
					<InvestorQuestionnaires agent={agent} />
				</Box>
			)}
			{listings?.active.length > 0 && (
				<Grid container spacing={2} className={classes.defaultBox} sx={{ position: 'relative' }}>
					<Grid item xs={12}>
						<Typography variant='h5' textAlign={'center'} mb='10px' fontWeight={700}>
							Explore <span style={{ color: '#CCA772' }}>My Listings</span>
						</Typography>
						<ModDivider sx={{ marginX: 'auto', marginTop: '.5rem' }} />
					</Grid>
					<Grid item xs={12}>
						<Box
							display='flex'
							gap='20px'
							id='active'
							sx={{
								maxWidth: '100%',
								overflowX: 'scroll',
								padding: '1rem',
								'&::-webkit-scrollbar': {
									display: 'none',
								},
								scrollBehavior: 'smooth',
							}}
						>
							<IconButton
								ref={(el) => hideButton(el, 'active')}
								sx={{
									position: 'absolute',
									top: '40%',
									left: '100px',
									backgroundColor: 'white',
									borderRadius: '2px',
									justifyContent: 'center',
									gap: '2rem',
									'&:hover': { backgroundColor: 'white' },
									boxShadow: '0px 0px 10px 0px #00000026',
								}}
								onClick={() => scrollToTheLeft('active', true)}
							>
								<ChevronLeft fontSize='large' />
							</IconButton>
							{listings?.active?.map((active) => (
								<ActiveCard key={active.id} {...active} agentId={agent_id} />
							))}
							<IconButton
								ref={(el) => hideButton(el, 'active')}
								sx={{
									position: 'absolute',
									top: '40%',
									right: '100px',
									backgroundColor: 'white',
									borderRadius: '2px',
									'&:hover': { backgroundColor: 'white' },
									boxShadow: '0px 0px 10px 0px #00000026',
								}}
								onClick={() => scrollToTheLeft('active', false)}
							>
								<ChevronRight fontSize='large' />
							</IconButton>
						</Box>
					</Grid>
				</Grid>
			)}
			{video && (
				<iframe
					style={{
						// aspectRatio: '2.4',
						border: 'none',
						width: '100%',
						height: '70vh',
					}}
					src={`https://www.youtube.com/embed/${getYoutubeEmbedLink(video)}`}
					title='Client video'
					allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
					allowFullScreen
				></iframe>
			)}
			{listings?.sold.length > 0 && (
				<Grid container spacing={2} className={classes.defaultBox} sx={{ background: '#f7f9f9', position: 'relative' }}>
					<Grid item xs={12} mb='2rem'>
						<Typography variant='h5' textAlign={'center'} mb='10px'>
							Sold <span style={{ color: '#CCA772' }}> Listings</span>
						</Typography>
						<ModDivider sx={{ marginX: 'auto', marginTop: '.5rem' }} />
					</Grid>
					<Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column' }}>
						<Box
							display='flex'
							gap='20px'
							id='sold'
							sx={{
								maxWidth: '100%',
								overflowX: 'scroll',
								padding: '1rem',
								'&::-webkit-scrollbar': {
									display: 'none',
								},
								scrollBehavior: 'smooth',
							}}
						>
							<IconButton
								ref={(el) => hideButton(el, 'sold')}
								sx={{
									position: 'absolute',
									top: '40%',
									left: '100px',
									backgroundColor: 'white',
									borderRadius: '2px',
									justifyContent: 'center',
									gap: '2rem',
									'&:hover': { backgroundColor: 'white' },
									boxShadow: '0px 0px 10px 0px #00000026',
								}}
								onClick={() => scrollToTheLeft('sold', true)}
							>
								<ChevronLeft fontSize='large' />
							</IconButton>
							{listings?.sold?.map((sold) => (
								<SoldCard key={sold.id} {...sold} agentId={agent_id} />
							))}
							<IconButton
								ref={(el) => hideButton(el, 'sold')}
								sx={{
									position: 'absolute',
									top: '40%',
									right: '100px',
									backgroundColor: 'white',
									borderRadius: '2px',
									'&:hover': { backgroundColor: 'white' },
									boxShadow: '0px 0px 10px 0px #00000026',
								}}
								onClick={() => scrollToTheLeft('sold', false)}
							>
								<ChevronRight fontSize='large' />
							</IconButton>
						</Box>
					</Grid>
				</Grid>
			)}
			{testimonials?.length > 0 && (
				<Grid container spacing={2} sx={{ marginY: '3rem', paddingRight: '3rem' }}>
					<Grid item xs={3} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingLeft: '5rem !important' }}>
						<Typography variant='h5' fontWeight='700'>
							What
						</Typography>
						<Typography variant='h5' color='#CCA772' fontWeight='700' mb='10px'>
							My Clients Say
						</Typography>
						<ModDivider />
					</Grid>
					<Grid item xs={9}>
						<Box sx={{ display: 'flex', gap: '1rem' }}>
							{testimonials?.map((testimonial) => (
								<Testimonial {...testimonial} />
							))}
						</Box>
					</Grid>
				</Grid>
			)}
			<Grid container spacing={2} sx={{ marginY: '5rem', paddingRight: '3rem' }} id='contact'>
				<Grid item xs={3} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingLeft: '5rem !important' }}>
					<Typography variant='h5' fontWeight='700' mb='10px'>
						Contact Me
					</Typography>
					<ModDivider />
				</Grid>
				<Grid item xs={9}>
					<Grid container spacing={2}>
						<Grid item xs={6}>
							<Grid container spacing={2}>
								<Grid item xs={6}>
									<TextField
										name='name'
										placeholder='Full Name'
										value={contactDetails.name}
										onChange={updateContactDetails}
										variant='outlined'
										fullWidth
										size='small'
									/>
								</Grid>
								<Grid item xs={6}>
									<TextField
										name='email'
										placeholder='Email'
										value={contactDetails.email}
										onChange={updateContactDetails}
										variant='outlined'
										fullWidth
										size='small'
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField
										name='phone'
										placeholder='Phone Number'
										value={contactDetails.phone}
										onChange={updateContactDetails}
										variant='outlined'
										fullWidth
										size='small'
									/>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={6}>
							<TextField
								name='message'
								placeholder='Message'
								value={contactDetails.message}
								onChange={updateContactDetails}
								variant='outlined'
								fullWidth
								size='small'
								multiline
								rows={3}
							/>
						</Grid>
						<Grid item xs={12}>
							<FullBut
								variant='contained'
								onClick={sendMessage}
								disableElevation
								disableRipple
								href={`/order-bpo-report/${agent_id}`}
								target='_blank'
								sx={{ display: 'block', marginLeft: 'auto' }}
							>
								Send Message
							</FullBut>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</ThemeProvider>
	);
}

export default NewTemp10;

const Nav = ({ name, active, showInvestors }) => {
	const { id: agentId } = useParams();

	const classes = useStyles();
	return (
		<Box className={classes.navroot}>
			<Box className={classes.container}>
				<Box className={classes.leftLinks}>
					{/* Left side with 3 anchor links */}
					{active > 0 && (
						<Link href='#active' className={classes.link} underline='none'>
							Active Listings
						</Link>
					)}
					<Link href='#about' className={classes.link} underline='none'>
						About
					</Link>
					<Link href='#contact' className={classes.link} underline='none'>
						Contact
					</Link>
					<Link href={`/agent/${agentId}/blogs`} className={classes.link} underline='none'>
						Blogs
					</Link>
					{showInvestors && (
						<Link href='#investors' className={classes.link} underline='none'>
							Investors
						</Link>
					)}
				</Box>
				{/* Right side with agent name */}
				<Typography variant='h6' className={classes.agentName}>
					Agent:{name}
				</Typography>
			</Box>
		</Box>
	);
};

const ActiveCard = ({ id, agentId, list_price, photos, beds, baths, square_footage, address }) => {
	return (
		<Link component={RLink} to={`/view-listing/${agentId}/${id}`} target='_blank'>
			<Box
				sx={{
					width: '320px',
					'&>*': {
						transition: 'all .5s ease-in-out',
						fontSize: '14px',
					},
					'&:hover': {
						'& p': {
							color: '#CCA772',
							paddingLeft: '2rem',
						},
						'& h6': {
							color: '#CCA772',
							paddingLeft: '2rem',
						},
					},
				}}
			>
				<img
					src={photos?.[0]?.image}
					alt='listing'
					style={{
						width: '320px',
						aspectRatio: '.63',
						objectFit: 'cover',
						margin: 'auto',
					}}
				/>
				<Box>
					<Typography variant='h6' sx={{ transition: 'all .5s' }}>
						{address}
					</Typography>
					<Typography variant='body1' sx={{ transition: 'all .5s' }}>
						{beds} beds | {baths} baths | {formatNumber(square_footage, 0)} sqft
					</Typography>
					<Typography variant='body1' sx={{ transition: 'all .5s' }}>
						$ {formatNumber(list_price, 0)}
					</Typography>
				</Box>
			</Box>
		</Link>
	);
};

const SoldCard = ({ id, agentId, list_price, photos, beds, baths, square_footage, address }) => {
	return (
		<Link component={RLink} to={`/view-listing/${agentId}/${id}`} target='_blank'>
			<Box sx={{ width: '320px', display: 'flex', flexDirection: 'column' }}>
				<img
					src={photos?.[0]?.image}
					alt='listing'
					style={{
						width: '100%',
						// height: '500px',
						aspectRatio: '.93',
						objectFit: 'cover',
						margin: 'auto',
					}}
				/>
				<Box sx={{ backgroundColor: '#CCA772', color: 'white', padding: '1rem' }}>
					<Typography variant='h6'>{address}</Typography>
					<Typography variant='body1'>
						{beds} beds | {baths} baths | {formatNumber(square_footage, 0)} sqft
					</Typography>
					<Typography variant='body1'>$ {formatNumber(list_price, 0)}</Typography>
				</Box>
			</Box>
		</Link>
	);
};

const Testimonial = ({ description, name, client }) => {
	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-between',
				background: '#f7f9f9',
				gap: '1rem',
				padding: '1rem',
				borderRadius: '2px',
				width: '100%',
				flex: 1,
			}}
		>
			<Typography variant='body1' textAlign='justify'>
				{description}
			</Typography>
			<Typography variant='body1' textAlign='justify' fontWeight={700}>
				{client?.user?.first_name || client?.user?.last_name ? client?.user?.first_name + ' ' + client?.user?.last_name : name}
			</Typography>
		</Box>
	);
};
