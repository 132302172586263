import { rateAmountConvertor } from "../utils/genericFunctions";
import { financeOptionsCals } from "./FinanceOptions";
import { carryingMonthsCalcs } from "./CarryingMonths";
import { estimatedResalePriceCalcs } from "./EstimatedResalePrice";
import {
  purchasingClosingCost,
  resaleClosingCost,
} from "../closingCost/closingCostFunctions";
import { resaleAmountForLiquidCapital } from "../carryingCost/financialTotalExpenses";

export const closingCostCalcs = ({
  remodelCosts,
  developmentCost,
  financeOptions,
  resultData,
  dealId,
  propertyPermitAndFees,
  taxes,
  rentalIncome,
  propertyManagement,
  otherCosts,
  carryingMonths,
  estimatedResaleValue,
  dealAnalyzerResults,
  closingCosts,
}) => {
  const {
    closing_cost_option_purchase,
    commission_on_purchase_option,
    commission_on_resale_dollar_or_percent,
    commission_on_purchase_dollar_or_percent,
    title_and_escrow_fees_dollar_or_percent,
    title_and_escrow_fees,
    custom_title_and_escrow_buyer_pays_percent,
    custom_title_and_escrow_buyer_pays,
    commission_on_purchase,
    custom_commission_on_purchase_dollar_or_percent,
    custom_commission_on_purchase,
    title_and_escrow_fees_resale_dollar_or_percent,
    title_and_escrow_fees_resale,
    commission_on_resale,
  } = closingCosts || {};

  const financeOptionsCals1 = financeOptionsCals({
    remodelCosts,
    developmentCost,
    financeOptions,
    resultData,
    dealId,
    propertyPermitAndFees,
    taxes,
    rentalIncome,
    propertyManagement,
  });

  const {
    primary_wholesale_fee,
    primary_purchase_price,
    cash_for_home_purchase_price,
    deal_finance_option,
  } = financeOptions || {};

  const carryingCostCalcs1 = carryingMonthsCalcs({
    remodelCosts,
    financeOptions,
    resultData,
    dealId,
    propertyPermitAndFees,
    taxes,
    rentalIncome,
    propertyManagement,
    otherCosts,
    carryingMonths,
  });

  const estimatedResalePrice = estimatedResalePriceCalcs({
    remodelCosts,
    financeOptions,
    resultData,
    dealId,
    propertyPermitAndFees,
    taxes,
    rentalIncome,
    propertyManagement,
    otherCosts,
    carryingMonths,
    estimatedResaleValue,
    dealAnalyzerResults,
  });

  const purhcasePrice =
    +deal_finance_option === 3
      ? cash_for_home_purchase_price
      : primary_purchase_price;

  const commisionOnPurchaseAmount = () =>
    rateAmountConvertor(
      commission_on_purchase_dollar_or_percent,
      commission_on_purchase,
      purhcasePrice
    );

  const commissionOnResaleAmount = () => {
    const value = rateAmountConvertor(
      commission_on_resale_dollar_or_percent,
      commission_on_resale,
      estimatedResalePrice?.estimatedResaleAmount
    );
    const amount = value;
    return amount;
  };

  const titleAndEscrowFeesAmount = () => {
    const amount = rateAmountConvertor(
      title_and_escrow_fees_dollar_or_percent,
      title_and_escrow_fees,
      purhcasePrice
    );
    return +amount;
  };

  const titleAndEscrowFeesResaleAmount = () => {
    const amount = rateAmountConvertor(
      title_and_escrow_fees_resale_dollar_or_percent,
      title_and_escrow_fees_resale,
      estimatedResalePrice?.estimatedResaleAmount
    );
    return +amount;
  };

  const localPurchasingClosingCost = () => {
    let amount = purchasingClosingCost(
      titleAndEscrowFeesAmount(),
      financeOptionsCals1?.getMonthlyStatePropertyTax,
      carryingCostCalcs1?.months,
      primary_wholesale_fee,
      financeOptionsCals1?.cityPermitFeesCalculated
    );
    return +amount;
  };

  const localResaleClosingCost = () => {
    let amount = resaleClosingCost(
      titleAndEscrowFeesResaleAmount(),
      commissionOnResaleAmount()
    );
    return +amount;
  };

  const customTitleAndEscrowOnPurchaseBuyerPays = () =>
    rateAmountConvertor(
      custom_title_and_escrow_buyer_pays_percent,
      custom_title_and_escrow_buyer_pays,
      titleAndEscrowFeesAmount()
    );

  const customCommissionOnPurhcaseBuyerPays = () =>
    rateAmountConvertor(
      custom_commission_on_purchase_dollar_or_percent,
      custom_commission_on_purchase,
      commisionOnPurchaseAmount()
    );

  const titleAndEscrowFeesForLiquidCapital = () => {
    const amount = resaleAmountForLiquidCapital(
      +titleAndEscrowFeesAmount(),
      +closing_cost_option_purchase,
      +customTitleAndEscrowOnPurchaseBuyerPays()
    );

    return +amount;
  };

  const commissionForLiquidCapital = () => {
    const amount = resaleAmountForLiquidCapital(
      +commisionOnPurchaseAmount(),
      +commission_on_purchase_option,
      +customCommissionOnPurhcaseBuyerPays()
    );

    return +amount;
  };

  return {
    commisionOnPurchaseAmount: commisionOnPurchaseAmount(),
    commissionForLiquidCapital: commissionForLiquidCapital(),
    titleAndEscrowFeesForLiquidCapital: titleAndEscrowFeesForLiquidCapital(),
    customCommissionOnPurhcaseBuyerPays: customCommissionOnPurhcaseBuyerPays(),
    customTitleAndEscrowOnPurchaseBuyerPays:
      customTitleAndEscrowOnPurchaseBuyerPays(),
    localResaleClosingCost: localResaleClosingCost(),
    localPurchasingClosingCost: localPurchasingClosingCost(),
    titleAndEscrowFeesAmount: titleAndEscrowFeesAmount(),
    titleAndEscrowFeesResaleAmount: titleAndEscrowFeesResaleAmount(),
    commissionOnResaleAmount: commissionOnResaleAmount(),
    purhcasePrice,
    ...estimatedResalePrice,
  };
};
