import { CheckCircle, ScheduleOutlined } from '@mui/icons-material'
import { Box, Divider, Stack, Typography, alpha } from '@mui/material'
import { grey } from '@mui/material/colors'
import { makeStyles } from '@mui/styles'
import React from 'react'

const useStyles = makeStyles(theme => ({
    icon: {
        fontSize: '1rem'
    },
    title: {
        fontSize: '.8rem',
    },
    text: {
        fontSize: '.8rem',
        color: grey[600]
    },
    disableText: {
        fontSize: '.8rem',
        color: grey[500]
    },
    bgEnable: {
        backgroundColor: alpha('#FEC84B', 0.2)
    }
}))

const StatusItem = ({ status, classes, contract, level }) => {
    return (
        <Box width={'80%'}>
            <Typography className={classes.text} variant='body2' fontSize={'.85rem'}>{contract ? 'Contract Sign' : `Status ${level}`}</Typography>
            <Box className={status ? classes.bgEnable : null} mb={1} display={'flex'} flex={1} justifyContent={'space-between'} alignItems={'center'} px={1} py={.9} border={'1px solid lightgrey'} borderRadius={'8px'}>
                <Typography className={status ? classes.title : classes.disableText} variant='body2' >{status ? 'Completed' : 'Pending'}</Typography>
                {status ? <CheckCircle color='primary' className={classes.icon} fontSize='small' /> : <ScheduleOutlined sx={{ color: grey[500] }} className={classes.icon} fontSize='small' />}
            </Box>
            <Typography className={classes.text} variant='body2' fontSize={'.8rem'}>{status ? `Date: ${new Date(status?.date || Date.now()).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })}` : ''}</Typography>
        </Box>
    )
}


const LeadStatus = ({ progress }) => {
    const classes = useStyles()
    const contractSigned = progress?.find(el => el?.status === 'signed_contract')
    const status1 = progress?.find(el => el?.status === 'lead_level_1')
    const status2 = progress?.find(el => el?.status === 'lead_level_2')
    const status3 = progress?.find(el => el?.status === 'lead_level_3')
    const status4 = progress?.find(el => el?.status === 'lead_level_4')
    const status5 = progress?.find(el => el?.status === 'lead_level_5')
    const status6 = progress?.find(el => el?.status === 'lead_level_6')

    return (
        <>
            <Stack direction={'row'} gap={3}>
                <StatusItem classes={classes} status={contractSigned} contract />
                <StatusItem classes={classes} status={status1} level={1} />
                <StatusItem classes={classes} status={status2} level={2} />
                <StatusItem classes={classes} status={status3} level={3} />
                <StatusItem classes={classes} status={status4} level={4} />
                <StatusItem classes={classes} status={status5} level={5} />
                <StatusItem classes={classes} status={status6} level={6} />
            </Stack>
            <Divider light sx={{ my: 2, bgcolor: '#f7f7f7' }} />
        </>
    )
}

export default LeadStatus