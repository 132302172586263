import { LoadingButton } from '@mui/lab'
import { Box, Button, Divider, FormControlLabel, Grid, Radio, RadioGroup, Stack, TextField, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'
import { makeStyles } from '@mui/styles'
import React from 'react'
import { toast } from 'react-toastify'


const useStyles = makeStyles(theme => ({
    label: {
        fontSize: '.9rem',
        color: grey[600],
        marginBottom: '.5rem'
    },
    btn: {
        textTransform: 'none',
        borderRadius: '8px'
    }
}))

const StageOne = ({ data, saveForm, dispatch, exit, load }) => {
    const classes = useStyles()
    const appointments = [
        {
            text: 'Callback', dataName: 'contact_appointment', dataKey: 'callback', action: 'CALLBACK_SELECT', fields: [
                { type: 'date', label: 'Set Appointment', action: 'CALLBACK_DATE' },
                { type: 'time', label: 'Appointment Time', action: 'CALLBACK_TIME' },
            ]
        },
        {
            text: 'Online Appointment', dataName: 'contact_appointment', dataKey: 'online', action: 'ONLINE_SELECT', fields: [
                { type: 'date', label: 'Set Appointment', action: 'ONLINE_DATE' },
                { type: 'time', label: 'Appointment Time', action: 'ONLINE_TIME' },
            ],
        },
        {
            text: 'In-Person Appointment', dataName: 'contact_appointment', dataKey: 'inperson', action: 'INPERSON_SELECT', fields: [
                { type: 'date', label: 'Set Appointment', action: 'INPERSON_DATE' },
                { type: 'time', label: 'Appointment Time', action: 'INPERSON_TIME' },
            ],
        }
    ]

    const interests = [
        {
            text: 'Not Interested', dataName: 'contact_interest', dataKey: 'no_interest', action: 'NO_INTEREST_SELECT', fields: [
                { type: 'radio', label: 'Seller Not Interested', action: 'NO_INTEREST_SELLER' },
                { type: 'radio', label: 'Buyer Not Interested', action: 'NO_INTEREST_BUYER' },
                { type: 'text', label: 'Notes', action: 'NO_INTEREST_NOTES' },
            ]
        },
        {
            text: 'Not Interested At The Moment(Follow Up)', dataName: 'contact_interest', dataKey: 'not_interested_now', action: 'NOT_INTERESTED_SELECT', fields: [
                { type: 'date', label: 'Set Appointment', action: 'NOT_INTERESTED_DATE' },
                { type: 'time', label: 'Appointment Time', action: 'NOT_INTERESTED_TIME' },
                { type: 'text', label: 'Notes', action: 'NOT_INTERESTED_NOTES' },
            ],
        }
    ]

    const relationship = [
        { type: 'text', label: 'Lead First Name', action: 'LEAD_INFO_FNAME', dataName:  'lead_info', dataKey: 'firstname' },
        { type: 'text', label: 'Lead Last Name', action: 'LEAD_INFO_LNAME', dataName:  'lead_info', dataKey: 'lastname' },
        { type: 'number', label: 'Lead Phone Number 1', action: 'LEAD_INFO_PHONE1', dataName:  'lead_info', dataKey: 'phone1' },
        { type: 'number', label: 'Lead Phone Number 2', action: 'LEAD_INFO_PHONE2', dataName:  'lead_info', dataKey: 'phone2' },
        { type: 'email', label: 'Lead Email Address 1', action: 'LEAD_INFO_EMAIL1', dataName:  'lead_info', dataKey: 'email1' },
        { type: 'email', label: 'Lead Email Address 2', action: 'LEAD_INFO_EMAIL2', dataName:  'lead_info', dataKey: 'email2' },
    ]

    React.useEffect(() => {
        window?.scrollTo({ top: 0, behavior: 'smooth' })
    }, [])

    const onFormSubmit = async (val) => {
        if (val === 'exit') exit('stage-one')
        if (val === 'continue') {
            if (!data?.contact_appointment?.callback?.status && !data?.contact_appointment?.online?.status && !data?.contact_appointment?.inperson?.status){ toast.error('Please provide contact appointment details', { theme: 'colored' }); return }
            if (!data?.contact_interest?.no_interest?.status && !data?.contact_interest?.not_interested_now?.status){ toast.error('Please provide contact interest details', { theme: 'colored' }); return }
            if (data?.lead_type_verification === ''){ toast.error('Please select a lead type', { theme: 'colored' }); return }
            if (data?.lead_info?.relationship === ''){ toast.error('Please select a lead relationship', { theme: 'colored' }); return }
            if (data?.lead_info?.firstname === ''){ toast.error('Please provide lead first name', { theme: 'colored' }); return }
            if (data?.lead_info?.lastname === ''){ toast.error('Please provide lead last name', { theme: 'colored' }); return }
            if (data?.lead_info?.email1 === '' || !data?.lead_info?.email1?.includes('@')){ toast.error('Please provide a valid email', { theme: 'colored' }); return }
            if (data?.lead_info?.phone1 === ''){ toast.error('Please provide a phone number', { theme: 'colored' }); return }
            // update the global form data
            saveForm('stage-one')
        }
    }



    return (
        <Box>
            <Stack direction={'row'} gap={2} alignItems={'center'}>
                <Typography fontWeight={500} color={'secondary'}>Have you made contact with the lead, in whichever way or form?</Typography>
                <RadioGroup row disabled={data?.disableForm}
                    value={data?.lead_contact}
                    onChange={(e) => {
                        if(e?.target?.value === 'no'){
                            dispatch({ type: 'CONTACTED_LEAD', payload: e?.target?.value })
                            dispatch({ type: 'RESET', payload: e?.target?.value })
                        }else{
                            dispatch({ type: 'CONTACTED_LEAD', payload: e?.target?.value })
                        }
                    }}
                >
                    <FormControlLabel value="yes" sx={{ mr: 2 }} control={<Radio size='small' color='secondary' />} label="Yes" />
                    <FormControlLabel value="no" control={<Radio size='small' color='secondary' />} label="No" />
                </RadioGroup>
            </Stack>
            <Divider sx={{ mt: 2, mb: 5, bgcolor: '#ededed' }} />
            {
                data?.lead_contact === 'yes' &&
                <>
                    {/* Appointments */}
                    <Grid container>
                        <Grid item sm={3}>
                            <Typography fontWeight={500} color={'secondary'}>Contact Outcome: Appointment</Typography>
                        </Grid>
                        <Grid item sm={9} mt={-4}>
                            {
                                appointments?.map((el, i) => {
                                    return (
                                        <Grid container key={i} spacing={3} mb={2} mt={-3}>
                                            <Grid item sm={12} mt={3}>
                                                <FormControlLabel disabled={data?.disableForm}
                                                    label={<Typography color={'secondary'}>{el?.text}</Typography>}
                                                    control={<Radio color='secondary' size='small' checked={data?.[el?.dataName]?.[el?.dataKey]?.status} />}
                                                    onChange={(_e, checked) => dispatch({ type: el?.action, payload: checked })}
                                                />
                                            </Grid>
                                            {
                                                el?.fields?.map((field, i) => {
                                                    return (
                                                        <Grid key={i} item sm={6} mt={-1}>
                                                            <Typography className={classes.label}>{field?.label}</Typography>
                                                            {field?.type === 'date' ?
                                                                <TextField disabled={!data?.[el?.dataName]?.[el?.dataKey]?.status || data?.disableForm}
                                                                    value={data?.[el?.dataName]?.[el?.dataKey]?.date}
                                                                    size='small' fullWidth
                                                                    onChange={(e) => dispatch({ type: field?.action, payload: e?.target?.value })}
                                                                    type={'date'}

                                                                />
                                                                :
                                                                <TextField disabled={!data?.[el?.dataName]?.[el?.dataKey]?.status || data?.disableForm}
                                                                    onChange={(e) => dispatch({ type: field?.action, payload: e?.target?.value })}
                                                                    type={'time'}
                                                                    size='small' fullWidth
                                                                    value={data?.[el?.dataName]?.[el?.dataKey]?.time}
                                                                />
                                                            }
                                                        </Grid>
                                                    )
                                                })
                                            }
                                        </Grid>
                                    )
                                })
                            }
                        </Grid>

                    </Grid>
                    <Divider sx={{ mt: 5, mb: 5, bgcolor: '#ededed' }} />

                    {/* No Interest */}
                    <Grid container>
                        <Grid item sm={3}>
                            <Typography fontWeight={500} color={'secondary'}>Contact Outcome: Interest</Typography>
                        </Grid>
                        <Grid item sm={9} mt={-4}>
                            {
                                interests?.map((el, i) => {
                                    return (
                                        <Grid container key={i} spacing={3} mb={2} mt={-3}>
                                            <Grid item sm={12} mt={3}>
                                                <FormControlLabel disabled={data?.disableForm}
                                                    label={<Typography color={'secondary'}>{el?.text}</Typography>}
                                                    control={<Radio color='secondary' size='small' checked={data?.[el?.dataName]?.[el?.dataKey]?.status} />}
                                                    onChange={(_e, checked) => dispatch({ type: el?.action, payload: checked })}
                                                />
                                            </Grid>
                                            {
                                                el?.fields?.map((field, i) => {
                                                    if (field?.type === 'radio') {
                                                        return (
                                                            <Grid key={i} item sm={6} mt={-1}>
                                                                <FormControlLabel disabled={!data?.[el?.dataName]?.[el?.dataKey]?.status || data?.disableForm}
                                                                    label={<Typography color={'secondary'}>{field?.label}</Typography>}
                                                                    control={<Radio color='secondary' size='small' checked={i === 0 ? data?.[el?.dataName]?.[el?.dataKey]?.seller : data?.[el?.dataName]?.[el?.dataKey]?.buyer} />}
                                                                    onChange={(_e, checked) => dispatch({ type: field?.action, payload: checked })}
                                                                />
                                                            </Grid>
                                                        )
                                                    } if (field?.type === 'date') {
                                                        return (
                                                            <Grid key={i} item sm={6} mt={-1}>
                                                                <Typography className={classes.label}>{field?.label}</Typography>
                                                                <TextField disabled={!data?.[el?.dataName]?.[el?.dataKey]?.status} value={data?.[el?.dataName]?.[el?.dataKey]?.date}
                                                                    size='small' fullWidth onChange={(e) => dispatch({ type: field?.action, payload: e?.target?.value })} type={'date'} />
                                                            </Grid>
                                                        )
                                                    } if (field?.type === 'time') {
                                                        return (
                                                            <Grid key={i} item sm={6} mt={-1}>
                                                                <Typography className={classes.label}>{field?.label}</Typography>
                                                                <TextField disabled={!data?.[el?.dataName]?.[el?.dataKey]?.status || data?.disableForm} value={data?.[el?.dataName]?.[el?.dataKey]?.time}
                                                                    size='small' fullWidth onChange={(e) => dispatch({ type: field?.action, payload: e?.target?.value })} type={'time'} />
                                                            </Grid>
                                                        )
                                                    } else {
                                                        return (
                                                            <Grid key={i} item sm={12} mt={-1}>
                                                                <Typography className={classes.label}>{field?.label}</Typography>
                                                                <TextField disabled={!data?.[el?.dataName]?.[el?.dataKey]?.status || data?.disableForm } value={data?.[el?.dataName]?.[el?.dataKey]?.notes}
                                                                    rows={5} multiline onChange={(e) => dispatch({ type: field?.action, payload: e?.target?.value })} type={'text'} size='small' fullWidth />
                                                            </Grid>
                                                        )
                                                    }
                                                })
                                            }
                                        </Grid>
                                    )
                                })
                            }
                        </Grid>

                    </Grid>
                    <Divider sx={{ mt: 5, mb: 2, bgcolor: '#ededed' }} />

                    {/* Lead Verification */}
                    <Stack direction={'row'} gap={15} alignItems={'center'}>
                        <Typography fontWeight={500} color={'secondary'}>Lead Type Verification</Typography>
                        <RadioGroup row
                            value={data?.lead_type_verification}
                            onChange={(e) => dispatch({ type: 'LEAD_TYPE', payload: e?.target?.value })}
                        >
                            <FormControlLabel value="buy_lead" disabled={data?.disableForm} sx={{ mr: 2 }} control={<Radio size='small' color='secondary' />} label="Buy Lead" />
                            <FormControlLabel value="sell_lead" disabled={data?.disableForm} control={<Radio size='small' color='secondary' />} label="Sell Lead" />
                        </RadioGroup>
                    </Stack>
                    <Divider sx={{ mt: 2, mb: 5, bgcolor: '#ededed' }} />

                    {/* Relationship */}
                    <Grid container>
                        <Grid item sm={3}>
                            <Typography fontWeight={500} color={'secondary'}>Lead Information Verification</Typography>
                        </Grid>
                        <Grid item sm={9}>
                            <Grid container spacing={3} mt={-1}>
                                <Stack width={'100%'} direction={'row'} alignItems={'center'} mb={2} justifyContent={'space-between'}>
                                    <Typography>Relationship To Owner</Typography>
                                    <Box ml={'auto'}>
                                        <RadioGroup row
                                            value={data?.lead_info?.relationship}
                                            onChange={(e) => dispatch({ type: 'LEAD_INFO_RELATION', payload: e?.target?.value })}
                                        >
                                            <FormControlLabel disabled={data?.disableForm} value="owner" control={<Radio color='secondary' size='small' />} label={'Owner'} />
                                            <FormControlLabel disabled={data?.disableForm} value="husband" control={<Radio color='secondary' size='small' />} label={'Husband'} />
                                            <FormControlLabel disabled={data?.disableForm} value="wife" control={<Radio color='secondary' size='small' />} label={'Wife'} />
                                            <FormControlLabel disabled={data?.disableForm} value="other" control={<Radio color='secondary' size='small' />} label={'Other'} />
                                        </RadioGroup>
                                    </Box>
                                </Stack>
                                <Divider sx={{ mt: 1, mb: 2, bgcolor: '#ededed' }} />
                                {
                                    relationship?.map((el, i) => {
                                        return (
                                            <Grid key={i} item sm={6}>
                                                <Typography className={classes.label}>{el?.label}</Typography>
                                                <TextField value={data?.[el?.dataName]?.[el?.dataKey]} disabled={data?.disableForm}
                                                    onChange={(e) => dispatch({ type: el?.action, payload: e?.target?.value })} 
                                                    type={el?.type} size='small' fullWidth inputProps={{min: 0}}/>
                                            </Grid>
                                        )
                                    })
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            }


            {/* Bottom Actions */}
                <Box mt={5}>
                    <Stack direction={'row'} gap={2} alignItems={'center'} justifyContent={'flex-end'}>
                        <Button disabled={data?.disableForm} variant='outlined' color={'secondary'} onClick={() => dispatch({ type: 'RESET' })} disableElevation size='small' className={classes.btn}>Clear All Entries</Button>
                        <LoadingButton disabled={data?.disableForm} loading={load} onClick={() => onFormSubmit('exit')}
                            variant='outlined' color={'secondary'} disableElevation size='small' className={classes.btn}>Save & Exit</LoadingButton>
                        <LoadingButton disabled={data?.disableForm} loading={load} variant='contained' onClick={() => onFormSubmit('continue')}
                            color='secondary' disableElevation size='small' className={classes.btn}>Save & Continue</LoadingButton>
                    </Stack>
                </Box>
            
        </Box>
    )
}

export default StageOne