import * as actionTypes from "../../actions/dealAnalyzer/dealAnalyzerTypes";

const initialState = {
  propertyInformation: {},
  financeOptions: {},
  propertyPermitAndFees: {},
  taxes: {},
  remodelCosts: {
    aesthetic_items: [],
    non_aesthetic_items: [],
    additional_remodel_items: [],
  },
  demolishingCost: {},
  developmentCost: {
    aesthetic_items: [],
    non_aesthetic_items: [],
  },
  closingCosts: {},
  propertyManagement: {},
  rentalIncome: {},
  otherCosts: {},
  carryingMonths: {},
  estimatedResaleValue: {},
  investorProfit: {},
  investorProfitItems: [],
  summaryText: {},
  dealAnalyzerReportId: {},
  dealAnalyzerResults: {},
  dealAnalyzerOrderDetails: {},
  messageQueue: [],
  customComps: [],
};

const straightAestheticRemodelReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case actionTypes.UPDATE_PROPERTY_INFORMATION:
      return {
        ...state,
        propertyInformation: {
          ...state.propertyInformation,
          ...payload,
        },
      };
    case actionTypes.UPDATE_FINANCE_OPTIONS:
      return {
        ...state,
        financeOptions: {
          ...state.financeOptions,
          ...payload,
        },
      };
    case actionTypes.UPDATE_PROPERTY_PERMIT_AND_FEES:
      return {
        ...state,
        propertyPermitAndFees: {
          ...state.propertyPermitAndFees,
          ...payload,
        },
      };
    case actionTypes.UPDATE_TAXES:
      return {
        ...state,
        taxes: {
          ...state.taxes,
          ...payload,
        },
      };
    case actionTypes.UPDATE_REMODEL_COSTS:
      return {
        ...state,
        remodelCosts: {
          ...state.remodelCosts,
          ...payload,
        },
      };
    case actionTypes.UPDATE_DEMOLISHING_COST:
      return {
        ...state,
        demolishingCost: {
          ...state.demolishingCost,
          ...payload,
        },
      };
    case actionTypes.UPDATE_DEVELOPMENT_COST:
      return {
        ...state,
        developmentCost: {
          ...state.developmentCost,
          ...payload,
        },
      };
    case actionTypes.UPDATE_CLOSING_COSTS:
      return {
        ...state,
        closingCosts: {
          ...state.closingCosts,
          ...payload,
        },
      };
    case actionTypes.UPDATE_RENTAL_INCOME:
      return {
        ...state,
        rentalIncome: {
          ...state.rentalIncome,
          ...payload,
        },
      };
    case actionTypes.UPDATE_PROPERTY_MANAGEMENT:
      return {
        ...state,
        propertyManagement: {
          ...state.propertyManagement,
          ...payload,
        },
      };
    case actionTypes.UPDATE_OTHER_COSTS:
      return {
        ...state,
        otherCosts: {
          ...state.otherCosts,
          ...payload,
        },
      };
    case actionTypes.UPDATE_CARRYING_MONTHS:
      return {
        ...state,
        carryingMonths: {
          ...state.carryingMonths,
          ...payload,
        },
      };
    case actionTypes.UPDATE_ESTIMATED_RESALE_VALUE:
      return {
        ...state,
        estimatedResaleValue: {
          ...state.estimatedResaleValue,
          ...payload,
        },
      };
    case actionTypes.UPDATE_INVESTOR_PROFIT:
      return {
        ...state,
        investorProfit: payload,
      };
    case actionTypes.ADD_INVESTOR_PROFIT_ITEM:
      return {
        ...state,
        investorProfitItems: [...state.investorProfitItems, ...payload],
      };
    case actionTypes.UPDATE_INVESTOR_PROFIT_ITEMS:
      return {
        ...state,
        investorProfitItems: [...payload],
      };
    case actionTypes.DELETE_INVESTOR_PROFIT_ITEM:
      return {
        ...state,
        investorProfitItems: [
          ...state.investorProfitItems.filter(
            (element, index) => +index !== +payload
          ),
        ],
      };
    case actionTypes.UPDATE_SUMMARY_TEXT:
      return {
        ...state,
        summaryText: {
          ...state.summaryText,
          ...payload,
        },
      };
    case actionTypes.UPDATE_ANALYZER_REPORT_ID:
      return {
        ...state,
        dealAnalyzerReportId: {
          ...state.dealAnalyzerReportId,
          ...payload,
        },
      };
    case actionTypes.UPDATE_DEAL_ANALYZER_RESULTS:
      return {
        ...state,
        dealAnalyzerResults: {
          ...state.dealAnalyzerResults,
          ...payload,
        },
      };
    case actionTypes.ADD_TO_MESSAGE_QUEUE:
      return {
        ...state,
        messageQueue: [...state.messageQueue, ...payload],
      };
    case actionTypes.UPDATE_CUSTOM_COMPS:
      return {
        ...state,
        customComps: [...payload],
      };
    case actionTypes.UPDATE_ALL_STATE:
      return {
        ...state,
        propertyInformation: payload?.property_info?.[0]
          ? { ...payload.property_info[0] }
          : {},
        financeOptions: payload?.finance_options?.[0]
          ? { ...payload.finance_options[0] }
          : {},
        propertyPermitAndFees: payload?.property_permit_fees?.[0]
          ? { ...payload.property_permit_fees[0] }
          : {},
        taxes: payload?.taxes?.[0] ? { ...payload.taxes[0] } : {},
        remodelCosts: payload?.remodel_cost
          ? {
              ...payload.remodel_cost?.[0],
              ...payload.remodel_cost?.remodel_cost["0"],
            }
          : {},
        demolishingCost: payload?.demolishing_cost?.[0]
          ? { ...payload.demolishing_cost[0] }
          : {},
        developmentCost: payload?.development_cost?.[0]
          ? { ...payload.development_cost[0] }
          : {},
        closingCosts: payload?.closing_cost?.[0]
          ? { ...payload.closing_cost[0] }
          : {},
        propertyManagement: payload?.property_management
          ? { ...payload.property_management }
          : {},
        rentalIncome: payload?.rental_income?.[0]
          ? { ...payload.rental_income[0] }
          : {},
        otherCosts: payload?.other_costs?.[0]
          ? { ...payload.other_costs[0] }
          : {},
        carryingMonths: payload?.carrying_cost?.[0]
          ? { ...payload.carrying_cost[0] }
          : {},
        estimatedResaleValue: payload?.estimated_resale_value?.[0]
          ? { ...payload.estimated_resale_value[0] }
          : {},
        customComps: payload?.custom_comps ? [...payload.custom_comps] : [],
        investorProfit: payload?.investor_profit?.[0]
          ? { ...payload.investor_profit[0] }
          : {},
        investorProfitItems: [],
        summaryText: payload?.summary_text?.[0]
          ? { ...payload.summary_text[0] }
          : {},
      };
    case actionTypes.UPDATE_MESSAGE_QUEUE:
      return {
        ...state,
        messageQueue: state.messageQueue.filter(
          (element, index) => index !== 0
        ),
      };
    case actionTypes.RESTORE_INITIAL_STATE:
      return initialState;
    case actionTypes.UPDATE_DEAL_ANALYZER_ORDER_DETAILS:
      return {
        ...state,
        dealAnalyzerOrderDetails: {
          ...state.dealAnalyzerOrderDetails,
          ...payload,
        },
      };
    default:
      return state;
  }
};

export default straightAestheticRemodelReducer;

export const investorProfitMiddleware = (invObj) => {
  const investorProfit = invObj?.investment_values;
  const newInvestorProfit = investorProfit.map((element) => ({
    ...element,
    roi: undefined,
    roi_a: undefined,
  }));
  return { ...invObj, investment_values: newInvestorProfit };
};
