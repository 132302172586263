import React from 'react';
import { useState } from 'react';
import './propertyinfo.scss';
import Checkbox from '../../../components/Checkbox/Checkbox';
import Modal from '../../../components/Modal/Modal';
import Input from '../../../components/Input/Input';
import Button from '../../../components/Button/Button';
import { MdModeEditOutline } from 'react-icons/md';
import { useOutletContext } from 'react-router-dom';
import { removeUnderandCapitalize } from '../../../utils/functions';
import { toast } from 'react-toastify';

import base from '../../../apis';
import PlacesSearch from '../../../components/PlacesSearch/PlacesSearch';

const prop = [
	'address',
	'square_footage',
	'MLS_number',
	'number_of_rooms', //change to match number_of_rooms
	'city',
	'beds',
	'additional_area',
	'style',
	'state',
	'baths',
	'APN_formatted',
	'unit',
	'zip',
	'year_built',
	'construction_type',
	'zoning',
	'lot',
	'list_price',
];

const location = [
	'census_block',
	'community',
	'neighbourhood',
	'census_tract',
	'county',
	'sub_neighbourhood',
	'land_use',
	'metropolitan_statistical_area',
	'subdivision',
	'legal_desc', //change to legal description
	'municipality',
];

const schools = ['elementary_school', 'high_school', 'elementary_school_district', 'high_school_district', 'middle_school'];
const interiorFeatures = [
	'air_conditioning',
	'floor_type',
	'garage',
	'basement',
	'dining_rooms',
	'game_room',
	'two_car_garage',
	'breakfast_nook',
	'eat_in_kitchen',
	'high_end_appliances',
	'wall_to_wall_carpeting',
	'ceiling_fans',
	'pantry',
	'high_end_materials',
	'central_forced_air',
	'extra_storage',
	'home_theatre',
];

const exteriorFeatures = [
	'barbecue',
	'exterior_wall',
	'parking_space',
	'exterior_patio',
	'bocce_ball',
	'fire_pit',
	'parking_type',
	'porch_type',
	'covered_patio',
	'hot_tub',
	'garage',
	'roof_type',
	'deck',
	'pool',
	'exterior_garage_area',
	'roof_composition',
	'dog_run',
	'mature_landscaping',
	'exterior_garage_type',
	'spacious_backyard',
	'water_feature',
	'storeys',
];

const hoa = [
	'barbecue_area',
	'hot_tub_hoa',
	'tennis_court',
	'pool_hoa',
	'basketball_court',
	'park',
	'clubhouse',
	'walking_trails',
	'gym',
	'playground',
	'greenbelt',
];

const tours = ['meet_in_person', 'meet_via_video_call'];
const dates = ['date_1', 'date_2', ' ', '  ', 'time_1', 'time_2'];

function PropertyInfo() {
	const [listing, setIsLoading, setListing] = useOutletContext();

	//options prof, sfeat,hfeat,ohouse
	const [selectedSubHeader, setSelectedSubHeader] = useState('prof');
	const onChangeSubheader = (e) => {
		const { id } = e.target;
		setSelectedSubHeader(id);
	};

	const [mainModalOpen, setMainModalOpen] = useState(false);
	const [selectedProp, setSelectedProp] = useState({
		label: '',
		value: '',
	});
	const onSelectProp = (item) => {
		setSelectedProp({
			label: String(item).split('_').join(' '),
			value: listing[item] === '-' ? '' : listing[item],
			name: item,
		});

		setTempVal(String(listing[item] === '-' ? '' : listing[item]).trim());
		setMainModalOpen(true);
	};

	const onChangeItem = (e) => {
		const { checked, name } = e.target;
		setListing({ ...listing, [name]: checked ? '-' : '' });

		base
			.patch(`premiersite/listings/${listing.id}/`, {
				[name]: checked ? '-' : null,
			})
			.catch((err) => {
				toast.error('Error updating listing');
			});
	};

	const [tempVal, setTempVal] = useState(null);

	const onCancelChange = () => {
		setMainModalOpen(false);
		setTempVal(null);
		setSelectedProp(null);
	};

	const onSaveChanges = () => {
		setIsLoading(true);
		base
			.patch(`premiersite/listings/${listing.id}/`, {
				[selectedProp.name]: tempVal,
			})
			.then(({ data }) => {
				setListing(data);
				setMainModalOpen(false);
				setTempVal(null);
				setSelectedProp(null);
			})
			.catch((err) => {
				toast.error('Could not update listing');
			})
			.finally(() => {
				setIsLoading(false);
			});
		//start spinner
		//call patch
		//then close modal
	};

	return (
		<>
			{selectedProp && (
				<Modal open={mainModalOpen} onClose={onCancelChange}>
					<div style={{ padding: '10px 20px' }}>
						<h4 className='mod-bigger'>{String(selectedProp.label).toUpperCase()}</h4>
						{selectedProp.label === 'address' ? (
							<PlacesSearch
								placeholder={`Enter ${selectedProp.label}`}
								required
								fullStyles={{ width: '100%' }}
								value={tempVal}
								onChange={(val) => setTempVal(val)}
							/>
						) : (
							<Input
								// type='text'
								placeholder={`Enter ${selectedProp.label}`}
								required
								fullStyles={{ width: '100%' }}
								value={tempVal}
								onChange={(e) => setTempVal(e.target.value)}
							/>
						)}

						<div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px', gap: '20px', paddingTop: '20px' }}>
							<Button
								style={{
									width: 'fit-content',
									height: '45px',
									fontSize: '14px',
									padding: '10px 30px',
									color: 'white',
									backgroundColor: 'var(--primary-color)',
									border: '1px solid var(--primary-color)',
									margin: 0,
								}}
								onClick={onSaveChanges}
								text='Save'
							/>
							<Button
								style={{
									width: '100px',
									height: '45px',
									fontSize: '16px',
									padding: '10px 20px',
									backgroundColor: 'white',
									color: 'var(--primary-color)',
									border: '1px solid var(--primary-color)',
									margin: '0',
								}}
								onClick={onCancelChange}
								text='Cancel'
							/>
						</div>
					</div>
				</Modal>
			)}
			<div className='propertyinfo'>
				<div className='propertyinfo-selectors'>
					<span data-selected={selectedSubHeader === 'prof'} id='prof' onClick={onChangeSubheader}>
						Property Profile
					</span>
					<span data-selected={selectedSubHeader === 'sfeat'} id='sfeat' onClick={onChangeSubheader}>
						Standard Features
					</span>
					<span data-selected={selectedSubHeader === 'hfeat'} id='hfeat' onClick={onChangeSubheader}>
						HOA Features
					</span>
					<span data-selected={selectedSubHeader === 'ohouse'} id='ohouse' onClick={onChangeSubheader}>
						Open House Info
					</span>
				</div>
				{selectedSubHeader === 'prof' && (
					<>
						<div style={{ marginTop: '10px' }}>
							<span className='littleheader'>Property Overview:</span>
							<div className='content-grid'>
								{prop.map((item) => (
									<Item
										key={item}
										value={listing?.[item] || ''}
										label={`${removeUnderandCapitalize(item)}`}
										setChecked={onChangeItem}
										name={item}
										onEdit={() => onSelectProp(item)}
									/>
								))}
							</div>
						</div>
						<div style={{ marginTop: '10px' }}>
							<span className='littleheader'>Location:</span>
							<div className='content-grid content-grid-three'>
								{location.map((item) => (
									<Item
										key={item}
										value={listing?.[item] || ''}
										label={`${removeUnderandCapitalize(item)}`}
										setChecked={onChangeItem}
										name={item}
										onEdit={() => onSelectProp(item)}
									/>
								))}
							</div>
						</div>
						<div style={{ marginTop: '10px' }}>
							<span className='littleheader'>Schools:</span>
							<div className='content-grid'>
								{schools.map((item) => (
									<Item
										key={item}
										value={listing?.[item] || ''}
										label={`${removeUnderandCapitalize(item)}`}
										setChecked={onChangeItem}
										name={item}
										onEdit={() => onSelectProp(item)}
									/>
								))}
							</div>
						</div>
					</>
				)}
				{selectedSubHeader === 'sfeat' && (
					<>
						<div style={{ marginTop: '10px' }}>
							<span className='littleheader'>Interior Features:</span>
							<div className='content-grid'>
								{interiorFeatures.map((item) => (
									<Item
										key={item}
										value={listing?.[item] || ''}
										label={`${removeUnderandCapitalize(item)}`}
										setChecked={onChangeItem}
										name={item}
										onEdit={() => onSelectProp(item)}
									/>
								))}
							</div>
						</div>
						<div style={{ marginTop: '10px' }}>
							<span className='littleheader'>Exterior Features:</span>
							<div className='content-grid'>
								{exteriorFeatures.map((item) => (
									<Item
										key={item}
										value={listing?.[item] || ''}
										label={`${removeUnderandCapitalize(item)}`}
										setChecked={onChangeItem}
										name={item}
										onEdit={() => onSelectProp(item)}
									/>
								))}
							</div>
						</div>
					</>
				)}
				{selectedSubHeader === 'hfeat' && (
					<div style={{ marginTop: '10px' }}>
						<div className='content-grid'>
							{hoa.map((item) => (
								<Item
									key={item}
									value={listing?.[item] || ''}
									label={`${removeUnderandCapitalize(item)}`}
									setChecked={onChangeItem}
									name={item}
									onEdit={() => onSelectProp(item)}
								/>
							))}
						</div>
					</div>
				)}
				{selectedSubHeader === 'ohouse' && (
					<>
						<div style={{ marginTop: '10px' }}>
							<span className='littleheader'>Tours:</span>
							<div className='content-grid'>
								{tours.map((item) => (
									<Item
										key={item}
										value={listing?.[item] || ''}
										label={`${removeUnderandCapitalize(item)}`}
										setChecked={onChangeItem}
										name={item}
										onEdit={() => onSelectProp(item)}
									/>
								))}
							</div>
						</div>
						<div style={{ marginTop: '10px' }}>
							<span className='littleheader'>Date & Times:</span>
							<div className='content-grid'>
								{dates.map((item) => (
									<Item
										key={item}
										value={listing?.[item] || ''}
										label={`${removeUnderandCapitalize(item)}`}
										setChecked={onChangeItem}
										name={item}
										onEdit={() => onSelectProp(item)}
									/>
								))}
							</div>
						</div>
					</>
				)}
			</div>
		</>
	);
}

export default PropertyInfo;

const Item = ({ label, value, setChecked, name, onEdit }) => (
	<div className='content-grid-holder' key={label}>
		{value && <MdModeEditOutline className='content-grid-holder-edit' color='#818181' onClick={onEdit} />}
		{label.trim() && (
			<Checkbox
				text={label + (!value || value === '-' ? '' : `: ${label === 'List Price' ? '$ ' + Number(value).toLocaleString('en') : value}`)}
				checked={value}
				setChecked={setChecked}
				name={name}
			/>
		)}
	</div>
);
