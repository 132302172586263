import React, { useState } from "react";
import {
  Box,
  Checkbox,
  Table,
  TableContainer,
  Typography,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  InputBase,
  FormControl,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Delete from "@mui/icons-material/Delete";
import { removeNaNAndInfinite } from "../../../../../pages/DealAnalyzer/ViewDealAnalyzerReports/utils";
import commaSeparate from "../../../utils/commaSeparate";

const useStyles = (theme) => {
  return {
    parentTable: {
      border: `solid 2px ${theme.palette.primary.main}`,
      borderRadius: "5px",
    },
    table: {
      width: "95%",
      // border: `solid 1px ${theme.palette.primary.main}`,
      borderTop: "none",
      fontSize: "1.5625rem",
      tableLayout: "fixed",
    },
    cell0: {
      width: "9.37rem !important",
      border: `solid 1px ${theme.palette.primary.main}`,
      borderTop: "none !important",
      backgroundColor: "rgb(19, 120, 165,0.1)",
    },
    cells: {
      width: "6.325rem !important",
      border: `solid 1px ${theme.palette.primary.main}`,
      borderTop: "none !important",
      backgroundColor: "rgb(19, 120, 165,0.1)",
    },
    bodyCells: {
      border: `solid 1px ${theme.palette.primary.main}`,
      backgroundColor: "rgb(19, 120, 165,0.1)",
    },
    bodyCellsNoBg: {
      border: `solid 1px ${theme.palette.primary.main}`,
      // backgroundColor: "rgb(19, 120, 165,0.1)",
    },
    bodyCellsError: {
      border: `solid 1px ${theme.palette.primary.main}`,
      backgroundColor: "rgb(224, 0, 52,0.1)",
    },
    topTableCells: {
      display: "flex",
      alignItems: "center",
      width: "100%",
      border: `solid 1px ${theme.palette.primary.main}`,
      borderRadius: "10px",
      marginTop: "5px",
    },
    topTableCellsNoCheckbox: {
      display: "flex",
      alignItems: "center",
      width: "100%",
      border: `solid 1px ${theme.palette.primary.main}`,
      borderRadius: "5px",
      marginTop: "5px",
      padding: "5px",
    },
    topTableCellsNoCheckboxError: {
      display: "flex",
      alignItems: "center",
      width: "100%",
      border: `solid 1px red`,
      borderRadius: "5px",
      marginTop: "5px",
      padding: "5px",
    },
    topTableCellsheader: {
      display: "flex",
      alignItems: "center",
      width: "100%",
      margin: "5px 0",
    },
    check: {
      width: "6%",
    },
    address: {
      width: "54%",
      cursor: "pointer",
    },
    date: {
      width: "40%",
      cursor: "pointer",
    },
  };
};

function CompCheckTableItem({
  list,
  noCheckbox,
  handleAddComps,
  checked,
  isCustomComps,
  fill,
  deleteCustomComp,
  index,
  handleUpdate,
  errors,
}) {

  const theme = useTheme();
  const styles = useStyles(theme);
  const [showDetails, setShowDetails] = useState(!!list?.new);

  const handleShowDetails = () => {
    setShowDetails((prev) => !prev);
  };

  const filterComma = (e) => {
    let value = e.target.value;
    if (value) {
      value = Number.parseInt(value.split(",").join(""));
      if (isNaN(value)) {
        return "";
      }
      e.target.value = value;
      return e;
    } else {
      return e;
    }
  };



  return (
    <Box>
      <Box
        sx={
          noCheckbox && errors
            ? styles.topTableCellsNoCheckboxError
            : noCheckbox && !errors
              ? styles.topTableCellsNoCheckbox
              : styles.topTableCells
        }
      >
        {!noCheckbox && (
          <Box sx={styles.check}>
            <Checkbox
              sx={{ "& .MuiSvgIcon-root": { fontSize: 24 }, color: "grey" }}
              checked={checked}
              onChange={() => handleAddComps(list?.id)}
            />
          </Box>
        )}
        <Box sx={styles.address} onClick={handleShowDetails}>
          {!isCustomComps && (
            <Typography variant="h5">
              <Box sx={{ textTransform: "capitalize" }}>
                {`${list?.site_address && list?.site_address.toLowerCase()}, ${list?.street && list?.street.toLowerCase()
                  },${list?.city && list?.city.toLowerCase()}`}
              </Box>
            </Typography>
          )}
          {isCustomComps && (
            <Typography variant="h5">{list?.address}</Typography>
          )}
        </Box>
        <Box sx={styles.date} onClick={handleShowDetails}>
          {errors?.errorMessage ? (
            <Typography variant="h5" sx={{ color: "red" }}>
              {errors?.errorMessage}
            </Typography>
          ) : (
            <Typography variant="h5">
              {list?.close_date && list?.close_date.slice(0, 11)}
            </Typography>
          )}
        </Box>
        {isCustomComps && (
          <Delete
            sx={{
              fontSize: "1.6rem",
              color: "#1378A5",
              cursor: "pointer",
              mx: 3,
            }}
            onClick={() => deleteCustomComp(index)}
          />
        )}
      </Box>

      {showDetails && (
        <TableContainer
          sx={{ display: "flex", justifyContent: "center", marginTop: "0rem" }}
        >
          <Table sx={styles.table} aria-label="simple table" size="medium">
            <TableHead>
              <TableRow sx={styles.rows}>
                {headers.map((header, index) => (
                  <TableCell
                    align="left"
                    scope="row"
                    sx={index === 0 ? styles.cell0 : styles.cells}
                  >
                    <Typography variant="h4" sx={{ width: "6.25rem" }}>
                      {header}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            {!isCustomComps && (
              <TableBody>
                <TableRow sx={styles.rows}>
                  {compNames.map((element) => (
                    <TableCell component="th" scope="row" sx={styles.bodyCells}>
                      <Typography variant="h4">
                        {element?.name === 'price' ? commaSeparate(list?.[element?.name]) : list?.[element?.name]}
                      </Typography>{" "}
                    </TableCell>
                  ))}
                </TableRow>
              </TableBody>
            )}
            {!!isCustomComps && (
              <TableBody>
                <TableRow sx={styles.rows}>
                  <TableCell component="th" scope="row" sx={styles.bodyCells}>
                    <Typography variant="h4">
                      {removeNaNAndInfinite(list?.price / list?.sqft)}
                    </Typography>{" "}
                  </TableCell>
                  {customCompNames.map((element) => (
                    <TableCell
                      component="th"
                      scope="row"
                      sx={
                        errors && errors?.missingFields.includes(element?.name)
                          ? styles.bodyCellsError
                          : fill
                            ? styles.bodyCells
                            : styles.bodyCellsNoBg
                      }
                      key={element?.name}
                    >
                      <FormControl sx={{ display: "flex" }}>
                        <InputBase
                          name={element?.name}
                          placeholder={element?.placeholder}
                          onChange={(e) => {
                            e = element?.name === 'price' ? filterComma(e) : e
                            handleUpdate(e, index)
                          }}
                          value={element?.name === 'price' ? commaSeparate(list?.[element?.name]) : list?.[element?.name]}
                          sx={{
                            fontSize: "1rem",
                          }}
                        />
                      </FormControl>
                    </TableCell>
                  ))}
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      )}
    </Box>
  );
}

export default CompCheckTableItem;

const headers = ["$PSQFT", "PRICE", "BD", "BA", "YEAR", "SQFT", "LOT"];

const customCompNames = [
  { name: "price", placeholder: "Price", required: true },
  { name: "bedrooms", placeholder: "Bedrooms" },
  { name: "bathrooms", placeholder: "Bathrooms" },
  { name: "year_built", placeholder: "Year Built" },
  { name: "sqft", placeholder: "Square Footage" },
  { name: "lot_size", placeholder: "Lot Size" },
];
const compNames = [
  { name: "per_sqft", placeholder: "Price", },
  { name: "price", placeholder: "Price", required: true },
  { name: "bedrooms", placeholder: "Bedrooms" },
  { name: "bathrooms", placeholder: "Bathrooms" },
  { name: "year_built", placeholder: "Year Built" },
  { name: "sqft", placeholder: "Square Footage" },
  { name: "lot_size", placeholder: "Lot Size" },
];
