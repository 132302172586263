import { Typography, Box } from '@mui/material'
import React, { useEffect } from 'react'
import EnhancedRadioButtonGroup from '../atomicComponents/EnhancedRadioButtonGroup'
import StyledLabel from '../atomicComponents/StyledLabel';

const QuestionnairePrimaryLoan = ({handler,data, extraComponentData}) => {

    const dealFinanceOption = data?.deal_finance_option || 0
    const header = extraComponentData.primaryHeaders?.[dealFinanceOption]


    const additionalStyles = {
        display: "flex",
        justifyContent: "space-between",
      };

    const additionalStyles1 = {
        display: "flex",
        flexDirection:"row-reverse",
        justifyContent: "space-between",
      };

      const specialHandler = (e)=>{
        handler({[e.target.name]:e.target.value})
      }

      useEffect(()=>{
        !data?.primary_wholesale_fee_option && handler({primary_wholesale_fee_option:1})
        !data?.primary_lender_points_option && handler({primary_lender_points_option:1})
        !data?.primary_loan_type && handler({primary_loan_type:"0"})
        !data?.primary_loan_sub_type && handler({primary_loan_sub_type:1})

      // NOTE: Run effect once on component mount, please
      // recheck dependencies if effect is updated.
      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[])
  return (
      <Box>
        <Typography variant="heading" sx={{color:"#1378A5"}}>{header}</Typography>
        <Box sx={{ display: "flex",marginTop:"1.25rem" }}>
          <Box sx={{alignSelf:"top", padding:"5px",mr:2}}>
            <StyledLabel label="Is there a wholesale fee?" />
          </Box>
          <Box>
            <EnhancedRadioButtonGroup
              options={responseOptions}
              styles={additionalStyles1}
              specialHandler = {specialHandler}
              name='primary_wholesale_fee_option'
              choice = {data?.primary_wholesale_fee_option}
            />
          </Box>

        </Box>
        <Box sx={{ display: "flex" }}>
        <Box sx={{alignSelf:"top", padding:"5px",mr:2}}>
            <StyledLabel label="Is there Lender Points?" />
          </Box>
          <EnhancedRadioButtonGroup
            options={responseOptions}
            styles={additionalStyles1}
            specialHandler = {specialHandler}
            name = 'primary_lender_points_option'
            choice = {data?.primary_lender_points_option}
          />
        </Box>
        <Box>
          <Box  sx={{display:"flex",margin:"0.3rem 3rem 0 0"}}>
            <StyledLabel label="What Type of Loan are you using?" />
          </Box>
          <EnhancedRadioButtonGroup
            options={loanType}
            styles={additionalStyles}
            specialHandler = {specialHandler}
            name = 'primary_loan_type'
            choice = {data?.primary_loan_type}
          />
        </Box>
        {!!+data?.primary_loan_type &&
        <Box>
          <Box  sx={{display:"flex",margin:"0.3rem 3rem 0 0"}}>
            <StyledLabel label="What Type of Loan is it?" />
          </Box>
          <EnhancedRadioButtonGroup
            options={loanSubType}
            styles={additionalStyles}
            specialHandler = {specialHandler}
            name = 'primary_loan_sub_type'
            choice = {data?.primary_loan_sub_type}
          />
        </Box>}
        


      </Box>
  );
}
export default QuestionnairePrimaryLoan
const responseOptions = [
    'No',
    'Yes'
]
const loanType=[
    'Conventional',
    'Private Hard Money',
    'Seller Financing'
]
const loanSubType =[
    'Conventional Mortgage Loan',
    'Interest Only Mortgage Loan'
]
