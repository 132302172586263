import { Circle } from '@mui/icons-material'
import { Box, Card, CardContent, Stack, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'
import { makeStyles } from '@mui/styles'
import _ from 'lodash'
import React from 'react'


const useStyles = makeStyles(theme => ({
    card : {
        margin: '1rem 0',
        background: '#1378a50a',
        borderRadius: '10px',
        boxShadow: '0 1px 5px rgba(0,0,0, 26%)',
        cursor: 'pointer',
        borderLeft: '4px solid #1378a50a',
        transition: 'all .2s ease',
        '&:hover': {
            background: '#fff',
            borderLeft: `4px solid ${theme.primaryColor}`,
            '& #title': {
                color: theme.primaryColor
            }
        }
    }
}))

const LeadItem = (props) => {
    const { name, date, type, location, state, city, status, handleClick } = props
    const classes = useStyles()

    const clickHandler = () => {
        handleClick()
    }

  return (
    <Card elevation={0} className={classes.card} onClick={clickHandler}>
        <CardContent>
            <Stack>
                <span style={{display: 'flex', justifyContent: 'space-between'}}>
                    <Typography id='title' sx={{fontWeight: 600, transition: 'all .2s ease'}} noWrap>{name}</Typography>
                    <Box display={'flex'} justifyContent='center' alignItems={'center'} gap='.5rem' >
                        <Circle sx={{fontSize: '.6rem', color: (theme) => theme.secondaryColor}}/>
                        <Typography sx={{fontSize: '.8rem', color: grey[500]}}>{status}</Typography>
                    </Box>
                </span>
                <Typography variant='body2' noWrap>{type}</Typography>
                <Typography variant='body2' color='textSecondary' noWrap>{_.startCase(location?.toLowerCase())}</Typography>
                <Stack direction='row' justifyContent={'space-between'} alignItems={'center'}>
                    <Typography variant='body2' sx={{fontSize: '1rem'}} color='textSecondary' >
                    {_.startCase(city?.toLowerCase())} {state}
                    </Typography>
                    <Typography variant='body2' color='textSecondary'>{ date ? new Date(date).toLocaleDateString('en-US', {day: 'numeric', month: 'short'}) : null}</Typography>
                </Stack>

            </Stack>
        </CardContent>
    </Card>
  )
}

export default LeadItem