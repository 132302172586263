import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InfoIcon from '@mui/icons-material/Info';
import {
	Box,
	Button,
	Divider,
	FormLabel,
	Grid,
	InputLabel,
	List,
	ListItem,
	TextField,
	ToggleButton,
	ToggleButtonGroup,
	Tooltip,
	Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import base from '../../apis';
import Spinner from '../../components/Spinner/Spinner';
import { formatNumber, getTier } from '../../utils/functions';
import { AttachMoney, Percent } from '@mui/icons-material';
import PlacesSearch from '../../components/PlacesSearch/PlacesSearch';

const useStyles = makeStyles((theme) => ({
	btn: {
		textTransform: 'none',
		width: '8rem',
	},
	profilePic: {
		height: '22rem',
		background: grey[300],
		borderRadius: '5px',
	},
	field: {
		marginBottom: '1.5rem',
		'& .MuiOutlinedInput-root': {
			borderRadius: '5px',
			overflow: 'hidden',
		},
	},
}));

function MyOrderingPage() {
	const classes = useStyles();
	const { typeId, first_name, last_name } = useSelector((state) => state.auth);
	const [isLoading, setIsLoading] = useState(false);
	const [profileImage, setProfileImage] = useState('');
	const [report_price, setReport_price] = useState('');
	const [prices, setPrices] = useState([]);
	const [tier, setTier] = useState(null);
	const [discount_percent, setDiscount_percent] = useState(0);
	const [valueType, setValueType] = useState('percent');
	const [discountAmount, setDiscountAmount] = useState(0);
	const [distanceDetails, setDistanceDetails] = useState({
		distance_flat_fee: 0,
		drive_through_address: '',
		flat_fee: 0,
		price_per_mile: 0,
	});

	const copyUrl = () => {
		navigator.clipboard.writeText(`${window.origin}/order-bpo-report/${typeId}`);
		toast.success('Link copied to clipboard');
	};

	const onSavePrice = async () => {
		try {
			setIsLoading(true);
			await base.patch(`/register/agents/${typeId}/`, {
				report_price: Number(report_price),
				discount_percent: Number(discount_percent),
			});
			toast.success('Price updated successfully');
		} catch (err) {
			toast.error('Could not update price');
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		(async () => {
			setIsLoading(true);
			try {
				const { data } = await base.get('/register/agents/' + typeId);
				setProfileImage(data.profile_image || '');
				setReport_price(data.report_price);
				setDiscount_percent(data.discount_percent || 0);
				setDiscountAmount((data.report_price * data.discount_percent) / 100);
				setDistanceDetails({
					distance_flat_fee: data.distance_flat_fee,
					drive_through_address: data.drive_through_address,
					flat_fee: data.flat_fee,
					price_per_mile: data.price_per_mile,
				});

				const { data: prices } = await base.get('/register/list_prices/', {
					params: {
						product: 'bpo_report_tier',
					},
				});
				const pricesFlat = prices
					.flat()
					.map((item) => ({
						amount: item?.unit_amount / 100,
						description: item?.nickname,
						price_id: item?.id,
						number_of_report: item?.metadata?.report,
						tier: item?.metadata?.tier,
					}))
					.sort((a, b) => a.amount - b.amount);

				const tier1 = pricesFlat.filter((price) => +price.tier === 1);
				const tier2 = pricesFlat.filter((price) => +price.tier === 2);
				const tier3 = pricesFlat.filter((price) => +price.tier === 3);
				const tiers = [
					{ prices: tier1, range: 'Under $500', tier: 1, charge: '$ 99' },
					{ prices: tier2, range: '$501 - $700', tier: 2, charge: '20 %' },
					{ prices: tier3, range: 'Above $700', tier: 2, charge: '25 %' },
				];
				setPrices(tiers);

				setTier(getTier(data.report_price));
			} catch (error) {
				toast.warn('Could not get profile picture');
			} finally {
				setIsLoading(false);
			}
		})();
		// getLocation();
	}, [typeId]);

	const handleSave = () => {
		if (report_price === '') {
			toast.warn('Please enter price');
			return;
		}
		if (Number(report_price) < 99) {
			toast.warn('Price must be greater than $99');
			return;
		}
		if (Number(discount_percent) < 0 || Number(discount_percent) > 50) {
			toast.warn('Discount percent must be between 0 and 50');
			return;
		}

		onSavePrice();
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		if (name === 'percent') {
			setDiscount_percent(value);
			const discountAmount = (Number(report_price) * Number(value)) / 100;
			setDiscountAmount(discountAmount);
		} else {
			setDiscountAmount(value);
			const discountPerc = (Number(value) * 100) / Number(report_price);
			setDiscount_percent(discountPerc);
		}
	};

	const handleChangeDrive = async () => {
		try {
			setIsLoading(true);
			await base.patch(`/register/agents/${typeId}/`, distanceDetails);
			toast.success('Updated successfully');
		} catch (err) {
			toast.error('Could not update');
		} finally {
			setIsLoading(false);
		}
	};

	const getAmountToReceive = () => {
		const getTierCharge = (tierString) => {
			const tier = Number(tierString);
			if (tier === 1) {
				return 99;
			} else if (tier === 2) {
				return 0.2 * report_price;
			} else if (tier === 3) {
				return 0.25 * report_price;
			}
		};

		const amount = report_price - getTierCharge(tier) - (discount_percent / 100) * report_price;
		return formatNumber(amount || 0, 2);
	};

	const handleDistanceChange = (e) => {
		const { name, value } = e.target;
		setDistanceDetails((prev) => ({ ...prev, [name]: value }));
	};

	return (
		<>
			{isLoading && <Spinner />}
			<Box sx={{ pt: { xs: 3 }, p: '20px' }}>
				<span style={{ display: 'flex', justifyContent: 'flex-end', gap: '1rem', marginBottom: '2rem' }}>
					<Button
						className={classes.btn}
						sx={{ border: (theme) => `1px solid ${theme.secondaryColor}` }}
						variant='text'
						color='secondary'
						href={`${window.location.origin}/order-bpo-report/${typeId}`}
						target='_blank'
					>
						Preview Page
					</Button>
				</span>

				<Box padding='1rem' border={(theme) => `1px solid ${theme.primaryColor}`}>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={6} md={6} lg={4}>
							<Box padding={'1rem 2rem'} border={`1px solid ${grey[300]}`} display='flex' flexDirection='column'>
								<Typography textAlign={'center'} mb={3}>
									Profile Picture
								</Typography>
								<Box className={classes.profilePic}>
									<img
										src={profileImage || ''}
										alt='profile'
										width='100%'
										height='500px'
										style={{ objectFit: 'cover', height: '100%', objectPosition: 'top center' }}
									/>
								</Box>
								<Button
									sx={{ textTransform: 'none', margin: '1.5rem 0' }}
									disableElevation
									variant='contained'
									color='secondary'
									fullWidth
									href='/profile/edit'
								>
									Change Photo
								</Button>
								<Divider sx={{ width: '100%', my: '0.5rem' }} />
								<InputLabel sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
									Site Visit Start Address{' '}
									<Tooltip title='Address that clients will use as your starting point'>
										<InfoIcon color='secondary' fontSize='14px' sx={{ cursor: 'pointer' }} />
									</Tooltip>
								</InputLabel>
								<PlacesSearch
									fullWidth
									mui
									size='small'
									value={distanceDetails.drive_through_address || ''}
									onChange={(val) => {
										setDistanceDetails((cur) => ({ ...cur, drive_through_address: val }));
									}}
								/>

								<InputLabel sx={{ marginTop: '1rem', display: 'flex', alignItems: 'center', gap: '10px' }}>
									Flat Fee Distance (miles)
									<Tooltip title='The distance that covers the flat rate'>
										<InfoIcon color='secondary' fontSize='14px' sx={{ cursor: 'pointer' }} />
									</Tooltip>
								</InputLabel>
								<TextField
									type='number'
									fullWidth
									size='small'
									value={distanceDetails.distance_flat_fee || ''}
									name='distance_flat_fee'
									onChange={handleDistanceChange}
								/>
								<InputLabel sx={{ marginTop: '1rem', display: 'flex', alignItems: 'center', gap: '10px' }}>
									Flat Fee Amount ($)
									<Tooltip title='The price for the flat fee area'>
										<InfoIcon color='secondary' fontSize='14px' sx={{ cursor: 'pointer' }} />
									</Tooltip>
								</InputLabel>
								<TextField
									type='number'
									fullWidth
									size='small'
									value={distanceDetails.flat_fee || ''}
									name='flat_fee'
									onChange={handleDistanceChange}
								/>
								<InputLabel sx={{ marginTop: '1rem', display: 'flex', alignItems: 'center', gap: '10px' }}>
									Price Per Mile ($)
									<Tooltip title='The price per mile for areas out of the flat fee distance'>
										<InfoIcon color='secondary' fontSize='14px' sx={{ cursor: 'pointer' }} />
									</Tooltip>
								</InputLabel>
								<TextField
									type='number'
									fullWidth
									size='small'
									value={distanceDetails.price_per_mile || ''}
									name='price_per_mile'
									onChange={handleDistanceChange}
								/>
								<Button
									sx={{ textTransform: 'none', margin: '1.5rem 0' }}
									disableElevation
									variant='contained'
									color='secondary'
									fullWidth
									onClick={handleChangeDrive}
								>
									Save
								</Button>
							</Box>
						</Grid>
						<Grid item xs={12} sm={6} md={6} lg={8}>
							<Box padding={'1.5rem '} border={`1px solid ${grey[300]}`} height={'100%'}>
								<Typography textAlign={'center'} mb={2}>
									Profile Information
								</Typography>
								<form>
									<FormLabel>
										<Typography variant='body2' mb={1}>
											Full name
										</Typography>
									</FormLabel>
									<TextField
										className={classes.field}
										variant='outlined'
										fullWidth
										placeholder='Enter Fullname'
										disabled
										value={`${first_name} ${last_name}`}
									/>

									<FormLabel>
										<Typography variant='body2' mb={1}>
											BPO Report Order Page URL
										</Typography>
									</FormLabel>
									<TextField className={classes.field} variant='outlined' fullWidth value={`${window.origin}/order-bpo-report/${typeId}`} disabled />
									<Button variant='contained' disableElevation className={classes.btn} color='secondary' onClick={copyUrl}>
										Copy URL
									</Button>
									<Grid container spacing={2} alignItems='center' mt={'1rem'}>
										<Grid item xs={4}>
											<Typography fontWeight={300}>BPO Report Order Price:</Typography>
										</Grid>
										<Grid item xs={8}>
											<TextField
												placeholder='Price'
												size='small'
												value={report_price}
												type='number'
												fullWidth
												onChange={(e) => {
													const value = e.target.value;
													setReport_price(value);
													setTier(getTier(value));
												}}
												InputProps={{
													inputProps: { min: 199, required: true },
												}}
											/>
										</Grid>
										<Grid item xs={4}>
											<Typography fontWeight={300}>Discount percentage :</Typography>
										</Grid>
										<Grid item xs={8} display='flex' justifyContent='space-between' gap='10px'>
											<ToggleButtonGroup
												color='secondary'
												value={valueType}
												exclusive
												size='small'
												onChange={(e) => setValueType(e.target.value)}
												sx={{ marginBottom: '1rem' }}
											>
												<ToggleButton value='percent'>
													<Percent sx={{ pointerEvents: 'none' }} />
												</ToggleButton>
												<ToggleButton value='price'>
													<AttachMoney sx={{ pointerEvents: 'none' }} />
												</ToggleButton>
											</ToggleButtonGroup>
											<TextField
												placeholder='Discount percentage'
												size='small'
												type='number'
												name='percent'
												fullWidth
												error={Number(discount_percent) < 0 || Number(discount_percent) > 50}
												helperText={Number(discount_percent) < 0 || Number(discount_percent) > 50 ? 'Discount percent must be between 0 and 50' : ''}
												value={discount_percent}
												onChange={handleChange}
												disabled={valueType !== 'percent'}
												InputProps={{
													inputProps: { min: 0, max: 50, required: true },
												}}
											/>
											<TextField
												placeholder='Discount amount ($)'
												size='small'
												type='number'
												name='amount'
												fullWidth
												disabled={valueType !== 'price'}
												// error={Number(discount_percent) < 0 || Number(discount_percent) > 50}
												// helperText={Number(discount_percent) < 0 || Number(discount_percent) > 50 ? 'Discount percent must be between 0 and 50' : ''}
												value={discountAmount}
												onChange={handleChange}
												InputProps={{
													inputProps: { min: 0, required: true },
												}}
											/>
										</Grid>
										<Grid item xs={12} display='flex' justifyContent={'flex-end'}>
											<Button variant='contained' disableElevation className={classes.btn} color='secondary' onClick={handleSave}>
												Save
											</Button>
										</Grid>
										<Divider sx={{ width: '100%', my: '0.5rem' }} />
										<Grid item xs={12} display='flex' gap='10px' alignItems={'center'} mb={1}>
											<Typography fontWeight={300}>
												Amount to receive: <b> $ {getAmountToReceive()}</b>
											</Typography>
											<Tooltip title='Amount you will receive after bpo charge and discount have been deducted'>
												<InfoIcon color='secondary' fontSize='14px' sx={{ cursor: 'pointer' }} />
											</Tooltip>
										</Grid>
									</Grid>

									<Box mt={1}>
										{prices?.length &&
											prices.map((price, index) => (
												<Box key={index} display='flex' flexDirection={'column'} py={1}>
													<Box display={'flex'} gap='1rem'>
														<Typography fontWeight={500}>
															Tier {index + 1} ({price.range})
														</Typography>
														{+tier === index + 1 && <CheckCircleIcon color='primary' fontSize='small' />}
													</Box>
													<Typography variant='caption'>Amount Charged by BPOTech: {price.charge}</Typography>
													<List disablePadding>
														{price?.prices?.map((p) => (
															<ListItem key={p.price_id}>
																{p.description} - <b>${formatNumber(p.amount || 0)}/month</b>
															</ListItem>
														))}
													</List>
												</Box>
											))}
									</Box>
								</form>
							</Box>
						</Grid>
					</Grid>
				</Box>
			</Box>
		</>
	);
}

export default MyOrderingPage;
