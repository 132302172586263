import { loanTypes } from "../../../utils/numericToStringConvert";
import { getPrimaryConventional } from "./conventionalLoanDealFinance";
import { getPrimaryMortgage } from "./mortgageLoanDealFinance";

export const getRestOfDealFinances = ({
  analyzerData,
  resultData,
  dealFinanceOption,
  typeId,
}) => {
  const primaryLoanSubType =
    +analyzerData?.financeOptions?.primary_sub_loan_type || 0;
  const primaryLoanType = +analyzerData?.financeOptions?.primary_loan_type || 0;
  const { pmi_value, primary_include_pmi } = analyzerData?.financeOptions || {};

  //============ dynamic pmi value =================//
  const pmiValue = primary_include_pmi
    ? { item: "PMI Percentage", value: pmi_value, render: "percent" }
    : {};

  const loanType = {
    item: "Loan Type",
    value:
      loanTypes?.[+analyzerData?.financeOptions?.primary_loan_type] || "N/A",
    render: "simple",
  };
  const purchasePrice = {
    item: "Purchase Price",
    value: analyzerData?.financeOptions?.cash_for_home_purchase_price,
    render: "dollar",
  };
  const primaryPurchasePrice = {
    item: "Purchase Price",
    value: analyzerData?.financeOptions?.primary_purchase_price,
    render: "dollar",
  };
  const primaryNewConstructionCost = {
    item: "New Costruction Cost Amount",
    value: analyzerData?.financeOptions?.primary_new_construction_cost,
    render: "dollar",
  };
  const mortgageLoanAmount = {
    //from calculations
    item: "Loan Amount",
    value:
      resultData?.primary_finance_option_summary?.interest_only_mortgage_loan
        ?.mortage_loan_amount,
    render: "dollar",
  };
  const mortgageInterestRate = {
    item: "Interest Rate",
    value: analyzerData?.financeOptions?.primary_annual_interest_rate,
    render: "percent",
  };
  const mortgageInterestOnlyTerm = {
    item: "Interest Only Term",
    value: analyzerData?.financeOptions?.primary_interest_only_term,
    render: "months",
  };
  const mortgageInterestOnlyPayment = {
    item: "Interest Only Payment",
    value:
      resultData?.primary_finance_option_summary?.interest_only_mortgage_loan
        ?.interest_only_payment,
    render: "dollar",
  };
  const mortgageInterestPlusPrinciple = {
    item: "Principal + Interest Payment",
    value:
      resultData?.primary_finance_option_summary?.interest_only_mortgage_loan
        ?.principal_plus_interest_payment,
    render: "dollar",
  };
  const remodelCost = {
    item: "Remodel Cost",
    value: analyzerData?.financeOptions?.primary_remodel_cost,
    render: "dollar",
  };
  const lenderPoints = {
    item: "Lender Points",
    value: analyzerData?.financeOptions?.primary_lender_points,
    render: "points",
  };
  const wholesaleFee = {
    item: "Wholesale Fee",
    value: analyzerData?.financeOptions?.primary_wholesale_fee,
    render: "dollar",
  };
  const downPayment = {
    item: "Down Payment",
    value: analyzerData?.financeOptions?.primary_down_payment,
    render: "percent_dollar",
    rate:
      analyzerData?.financeOptions?.primary_down_payment_dollar_or_percent || 0,
    referenceValue: analyzerData?.financeOptions?.primary_purchase_price,
  };
  const loanAmount = {
    item: "Loan Amount",
    value:
      resultData?.primary_finance_option_summary?.conventional_mortgage_loan ||
      resultData?.primary_finance_option_summary?.interest_only_mortgage_loan,
    render: "dollar",
  };
  // const newConstructionAmount = {
  //   item: "Loan Amount",
  //   value: resultData?.primary_finance_option_summary?.primary_new_construction_cost,
  //   render:"dollar"
  // }
  const interestRate = {
    item: "Interest Rate",
    value: analyzerData?.financeOptions?.primary_interest_rate,
    render: "percent",
  };

  const loanTerm = {
    item: "Loan Term",
    value: analyzerData?.financeOptions?.primary_term_of_loan,
    render: "months",
  };
  // const cashForRemodel =     {
  //   item: "Cash For Remodel",
  //   value: analyzerData?.financeOptions?.cash_for_home_purchase_price,
  //   render: "dollar",
  // }

  const case1 = {
    primaryHomeLoan:
      primaryLoanSubType === 0 || primaryLoanType === 0
        ? getPrimaryConventional({ resultData, analyzerData })
        : getPrimaryMortgage({ resultData, analyzerData }),
    primaryHeader: !typeId ? "Primary Home Loan" : "Primary Loan for Land",
    secondaryHeader: !typeId ? "Cash For Remodel" : "Cash for New Construction",
    secondaryHomeLoan: [
      {
        item: "Cash For Remodel",
        value: analyzerData?.financeOptions?.cash_for_home_purchase_price,
        render: "dollar",
      },
    ],
  };

  const case2 = {
    primaryHeader: !typeId
      ? "Primary Home Loan"
      : "Single Loan for Land and New Construction",
    primaryHomeLoan:
      !typeId && !primaryLoanSubType
        ? [
            loanType,
            purchasePrice,

            remodelCost,
            lenderPoints,
            wholesaleFee,
            pmiValue,
            downPayment,
            loanAmount,
            interestRate,
            loanTerm,
          ]
        : !typeId && primaryLoanSubType
        ? [
            loanType,
            purchasePrice,
            remodelCost,
            lenderPoints,
            wholesaleFee,
            pmiValue,
            downPayment,
            mortgageLoanAmount,
            mortgageInterestRate,
            loanTerm,
          ]
        : [
            loanType,
            primaryPurchasePrice,
            primaryNewConstructionCost,
            lenderPoints,
            wholesaleFee,
            downPayment,
            pmiValue,
            loanAmount,
            interestRate,
            loanTerm,
            mortgageInterestOnlyTerm,
            mortgageInterestOnlyPayment,
            mortgageInterestPlusPrinciple,
          ],
  };
  const case3 = {
    primaryHomeLoan:
      primaryLoanSubType === 0 || primaryLoanType === 0
        ? getPrimaryConventional({ resultData, analyzerData })
        : getPrimaryMortgage({ resultData, analyzerData }),
    primaryHeader: !typeId
      ? "Primary Remodel Loan"
      : "Primary Loan for New Construction",
    secondaryHeader: !typeId ? "Cash for Home" : "Cash for Land",
    secondaryRemodelLoan: [
      {
        item: "Purchase Price",
        value: !typeId
          ? analyzerData?.financeOptions?.cash_for_home_purchase_price
          : analyzerData?.financeOptions?.cash_for_land_purchase_price,
        render: "dollar",
      },
    ],
  };

  const case4 = {
    primaryHeader: !typeId
      ? "Cash for Home & Remodel"
      : "Cash for Land and New Construction",
    secondaryHeader: "Cash For Remodel",
    primaryHomeLoan: [
      {
        item: "Purchase Price",
        value: analyzerData?.financeOptions?.primary_purchase_price,
        render: "dollar",
      },
      {
        item: !typeId ? "Remodel Cost" : "New Construction Amount",
        value: !typeId
          ? analyzerData?.financeOptions?.primary_remodel_cost
          : analyzerData?.financeOptions?.primary_new_construction_cost,
        render: "dollar",
      },
    ],
  };

  switch (+dealFinanceOption) {
    case 1:
      return case1;
    case 2:
      return case2;
    case 3:
      return case3;
    case 4:
      return case4;
    default:
      return "";
  }
};
