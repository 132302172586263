import React from 'react';
import './input.scss';

function Input({ label, labelStyle, fullStyles, style, className, ...rest }) {
	return (
		<div className={'input ' + (className || '')} style={fullStyles}>
			{label && (
				<label className='input-label' style={labelStyle}>
					{label}
				</label>
			)}
			<input {...rest} className='input-element' style={{ height: '100%', ...style }} />
		</div>
	);
}

export default Input;
