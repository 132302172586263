import React, { useEffect, useState } from 'react';
import { Outlet, NavLink } from 'react-router-dom';
import './websiteoverview.scss';
import { ReactComponent as Back } from '../../../images/back.svg';
import { useNavigate } from 'react-router-dom';
import base from '../../../apis';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import Spinner from '../../../components/Spinner/Spinner';
import { Box, Dialog, DialogContent, DialogTitle, IconButton, Button as MUIButton, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { Close } from '@mui/icons-material';

const domains = {
	gd: [
		`Log in to your GoDaddy account.`,
		`Click on the "Domains" tab.`,
		`Select the domain that you want to set up forwarding for.`,
		`Click on the "Forwarding" tab.`,
		`Click on the "Manage" button.`,
		`Select the "Forward with masking" option.`,
		`Enter the URL of the website that you want to forward to.`,
		`Enter a title and description for the forwarding.`,
		`Click on the "Save" button.`,
	],
	ionos: [
		`Log in to your IONOS account.`,
		`Click on the Domain & SSL tile.`,
		`Select the domain that you want to forward.`,
		`Click on the Details tab.`,
		`Click on the Adjust Destination link.`,
		`Click on the Domain Forwarding row.`,
		`Select Arbitrary URL as the Type.`,
		`In the Redirect to Destination field, enter the agent URL that you want to forward to.`,
		`Check the Also set up for www subdomain checkbox if you want the forwarding to also apply to the www subdomain.`,
		`Select Frame Redirect as the Redirect Type.`,
		`Enter your name in the Page title box.`,
		`Click on the Save button.`,
	],
};

function WebsiteOverview({ isPrem }) {
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = React.useState(false);
	const [agent, setAgent] = useState(null);
	const id = useSelector((state) => state.auth.typeId);
	const [tempModalOpen, setTempModalOpen] = useState(false);
	const [selectedDomain, setSelectedDomain] = useState('gd');

	useEffect(() => {
		setIsLoading(true);
		base
			.get(`register/agents/${id}/`)
			.then(({ data }) => {
				setAgent(data);
			})
			.catch(() => {
				toast.error('Could not get agent profile');
			})
			.finally(() => {
				setIsLoading(false);
			});
	}, [id]);
	return (
		<>
			{isLoading && <Spinner />}
			<Dialog Dialog open={tempModalOpen} onClose={() => setTempModalOpen(true)} fullWidth>
				<DialogTitle sx={{ display: 'flex' }}>
					Connect your custom domain
					<IconButton onClick={() => setTempModalOpen(false)} sx={{ marginLeft: 'auto' }}>
						<Close fontSize='small' />
					</IconButton>
				</DialogTitle>
				<DialogContent>
					<Box p={1}>
						<ToggleButtonGroup
							color='secondary'
							value={selectedDomain}
							exclusive
							onChange={(e) => setSelectedDomain(e.target.value)}
							sx={{ marginBottom: '1rem' }}
						>
							<ToggleButton value='gd'>Godaddy</ToggleButton>
							<ToggleButton value='ionos'>Ionos</ToggleButton>
						</ToggleButtonGroup>
						{domains[selectedDomain].map((item, index) => (
							<p key={index}>
								{index + 1}. {item}
							</p>
						))}
					</Box>
				</DialogContent>
			</Dialog>
			<div className='websiteoverview'>
				<div className='propHolder'>
					<div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
						<Back onClick={() => navigate('../')} style={{ cursor: 'pointer' }} />

						<h3>{isPrem ? 'Investor Website' : 'Digital Business Card'}</h3>
					</div>
					<div className='propHolder-buttons'>
						{isPrem && (
							<MUIButton sx={{ textTransform: 'capitalize' }} color='secondary' variant='contained' onClick={() => setTempModalOpen(true)}>
								Connect Domain
							</MUIButton>
						)}
						<MUIButton
							variant='outlined'
							color='secondary'
							sx={{ textTransform: 'capitalize' }}
							href={isPrem ? '/premier-agent-website/website/template' : `/digital-premier-agent-preview/${id}`}
							// onClick={() => {
							// 	if (isPrem) {
							// 		window.open(`/template`);
							// 	} else {
							// 		window.open(`/digital-premier-agent-preview/${id}`);
							// 	}
							// }}
						>
							Preview {isPrem ? 'Website' : 'Digital Business Card'}
						</MUIButton>
						{/* <Button
							style={{
								width: '200px',
								height: '45px',
								fontSize: '16px',
								padding: '10px 20px',
								backgroundColor: 'white',
								color: 'var(--primary-color)',
								border: '1px solid var(--primary-color)',
								display: 'block',
								marginTop: '20px',
							}}
							onClick={() => {
								if (isPrem) {
									window.open(`/agent/${id}`);
								} else {
									window.open(`/digital-premier-agent-preview/${id}`);
								}
							}}
							text='Preview Website'
						/> */}
					</div>
				</div>
				<div className='selectHeaders'>
					<div className='selectHeaders-header'>
						<NavLink to='' className='selectHeaders-items' style={({ isActive }) => (isActive ? { backgroundColor: '#ffaa01' } : undefined)} end>
							Profile
						</NavLink>
						<NavLink to='bio' className='selectHeaders-items' style={({ isActive }) => (isActive ? { backgroundColor: '#ffaa01' } : undefined)}>
							Biography
						</NavLink>
						<NavLink
							to='active-listings'
							className='selectHeaders-items'
							style={({ isActive }) => (isActive ? { backgroundColor: '#ffaa01' } : undefined)}
						>
							Active Listings
						</NavLink>
						<NavLink
							to='sold-listings'
							className='selectHeaders-items'
							style={({ isActive }) => (isActive ? { backgroundColor: '#ffaa01' } : undefined)}
						>
							Sold Listings
						</NavLink>

						<NavLink
							to='testimonials'
							className='selectHeaders-items'
							style={({ isActive }) => (isActive ? { backgroundColor: '#ffaa01' } : undefined)}
						>
							Testimonials
						</NavLink>

						<NavLink to='image' className='selectHeaders-items' style={({ isActive }) => (isActive ? { backgroundColor: '#ffaa01' } : undefined)}>
							Header Image
						</NavLink>
					</div>
					<Outlet context={[agent, isLoading, setIsLoading, setAgent]} />
				</div>
			</div>
		</>
	);
}

export default WebsiteOverview;
