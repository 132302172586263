import React,{useState} from 'react'
import base from '../../../../apis'

function useSubmitQuestionnaire() {
    const [loading, setLoading] = React.useState(false)
    const [error, setError] = useState()

    const submitQuestionnaire = async(url,id,data)=>{
        setLoading(true)
        if(!data?.investors){
            data = {...data,investors:[]}
        }else{
            const temp = {...data.investors}
            const investorsArray = []
            for(let key in temp){
                investorsArray.push({investor_value:temp[key]})
            }
            data['investors'] = investorsArray

        }
        try {
            const res = await base.put(`/${url}?id=${id}`,{...data})
            return res
        } catch (error) {
            setError(error)
            return ""
        }finally{
            setLoading(false)
        }
    }
  return {submitQuestionnaire,loading,submitError:error}
}

export default useSubmitQuestionnaire