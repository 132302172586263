import React from 'react'
import { useEffect } from 'react';
import EnhancedRadioButtonGroup from '../atomicComponents/EnhancedRadioButtonGroup';
import StyledLabel from '../atomicComponents/StyledLabel';
import { Box } from '@mui/material';


function QuestionnaireDealTypes({handler, data, extraComponentData}) {

    const handleOptions = (value)=>{
        handler({deal_finance_option:value})
    }
    useEffect(() => {
      !data?.deal_finance_option && handler({deal_finance_option:0})

      // NOTE: Run effect once on component mount, please
      // recheck dependencies if effect is updated.
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
  return (
    <div>
        <StyledLabel label='How do you intend to finance this deal?'/>
        <Box sx={{height:"1rem"}}/>
        <EnhancedRadioButtonGroup
            options = {extraComponentData.financeDealOptions}
            handler = {handleOptions}
            choice = {data?.deal_finance_option}
        />
    </div>
  )
}

export default QuestionnaireDealTypes

