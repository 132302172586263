import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { Snackbar, Alert } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { updateMessageQueue } from "../../../actions/dealAnalyzer";

function QuestionnaireContainer() {
  const dispatch = useDispatch();
  const straightAestheticRemodelData = useSelector(
    (state) => state.straightAestheticRemodel
  );
  const currentMessage = straightAestheticRemodelData?.messageQueue?.[0];

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(updateMessageQueue());
  };
  useEffect(()=>{

    return ()=>{
      dispatch(updateMessageQueue());
    }
        // NOTE: Run effect once on component mount, please
        // recheck dependencies if effect is updated.
        // eslint-disable-next-line react-hooks/exhaustive-deps  
  },[])
  return (
    <>
      <Snackbar
        open={!!currentMessage}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleClose} severity={currentMessage?.["severity"]}>
          {currentMessage?.message}
        </Alert>
      </Snackbar>
      <Outlet />
    </>
  );
}

export default QuestionnaireContainer;
