import React from "react";
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Table,
  TableContainer,
  TableBody,
  Typography,
  tableCellClasses,
  TableHead,
  TableRow,
  TableCell,
} from "@mui/material";
import SubSectionContainer from "../TableComponents/SubSectionContainer";
import InnerSectionHeader from "../TableComponents/InnerSectionHeader";
import ComponentSelector from "../ComponentSelector";
import TotalRow from "../RowTypes/TotalRow";
import { splitArray } from "../../utils/splitArray";
import ProformaEndAdornRow from "../RowTypes/ProformaEndAdornRow";
import commaSeparate from "../../utils/commaSeparate";

import ZillowCompTableForReport from "../../userInputCards/EstimatedResaleValue/zillowComps/ZillowCompTableForReport";

const useStyles = (_theme, printVersion) => {
  return {
    root: {},
    table: {
      [`& .${tableCellClasses.root}`]: {
        borderBottom: "none",
        padding: "5px 0",
        margin: "0rem 0 !important",
      },
      // width:"48%",
      overflow: printVersion ? "hidden" : "scroll",
    },
    table2: {
      [`& .${tableCellClasses.root}`]: {
        borderBottom: "none",
        padding: "5px 0",
        margin: "0rem 0 !important",
      },
      width: "48%",
      alignSelf: "start",
      overflow: printVersion ? "hidden" : "scroll",
    },
    fullTable: {
      [`& .${tableCellClasses.root}`]: {
        borderBottom: "none",
        padding: "5px 0",
        margin: "0rem 0 !important",
      },
      alignSelf: "start",
      overflow: printVersion ? "hidden" : "scroll",
    },
    valueCell: {
      width: "18% !important",
      borderBottom: "solid 1px white !important",
      borderTop: "solid 2px white !important",
      backgroundColor: "#f5f5f5",
      "print-color-adjust": "exact",
      "-webkit-print-color-adjust": "exact",
    },
    valueCell1: {
      width: "28% !important",
      borderBottom: "solid 1px white !important",
      borderTop: "solid 2px white !important",
      backgroundColor: "#f5f5f5",
      "print-color-adjust": "exact",
      "-webkit-print-color-adjust": "exact",
    },
    valueCell2: {
      width: "18% !important",
      borderBottom: "solid 1px white !important",
      borderTop: "solid 2px white !important",
      backgroundColor: "#e8e8e8",
      "print-color-adjust": "exact",
      "-webkit-print-color-adjust": "exact",
    },
  };
};

function ProformaPage4({
  otherExpenses,
  estResaleValue,
  rentalIncome,
  capitalizationRate,
  netProfit,
  investorsProfit,
  printVersion,
  resultData,
  carryingCost,
  operationalExpenses,
  analyzerData,
}) {
  const theme = useTheme();
  const styles = useStyles(theme, printVersion);

  const serverData = analyzerData?.dealAnalyzerResults;
  const serverComps = serverData?.zillowComps;
  const useComp = analyzerData?.estimatedResaleValue?.resale_value_option;
  const subCompToApply = analyzerData?.estimatedResaleValue?.compsToApply;

  const savedCustomComps =
    analyzerData?.estimatedResaleValue?.zillowComps || serverComps || [];
  // const serverProfit = serverData?.net_profit
  const zillowPrintChecker = useComp !== 2 && +subCompToApply === 1;
  const investorsProfitShareArray = analyzerData?.investorProfitItems;
  return (
    <>
      <Box
        sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", width: "49.5%" }}>
          <SubSectionContainer
            printVersion={printVersion}
            title="Est Resale Value"
          >
            <Table sx={{ ...styles.table, width: "100%" }}>
              {estResaleValue.map((element) => (
                <ComponentSelector {...element} printVersion={printVersion} />
              ))}
            </Table>
            <Box sx={{ mb: 0 }} />
          </SubSectionContainer>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", width: "49.5%" }}>
          <SubSectionContainer printVersion={printVersion} title="Net Profit">
            <Table sx={{ ...styles.table, width: "100%" }}>
              {netProfit.map((element) => (
                <ComponentSelector {...element} printVersion={printVersion} />
              ))}
            </Table>
            <Box sx={{ height: "5px" }} />
          </SubSectionContainer>
        </Box>
      </Box>
      <Box sx={{ height: "1rem" }} />
      <SubSectionContainer
        printVersion={printVersion}
        title={`Investors' Profit`}
      >
        <Table sx={{ ...styles.table, width: "50%" }}>
          <ProformaEndAdornRow
            item="Annual Assert appreciation "
            printVersion={printVersion}
            value={
              analyzerData?.estimatedResaleValue
                ?.annual_property_appreciation ||
              resultData?.est_resale_value?.asset_appreciation
            }
            rate={1}
            symbol={"%"}
          />
        </Table>
        <Box sx={{ height: "0.937rem" }} />
        <InnerSectionHeader title="Liquid Capital Required" />
        <Box sx={{ height: "1rem" }} />
        <TableContainer
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <Table sx={{ ...styles.table, width: "48%" }}>
            {splitArray(investorsProfit.liquidCapital).result1.map(
              (element) => (
                <ComponentSelector {...element} printVersion={printVersion} />
              )
            )}
          </Table>
          <Table
            sx={{ ...styles.table, width: "48%", alignSelf: "flex-start" }}
          >
            {splitArray(investorsProfit.liquidCapital).result2.map(
              (element) => (
                <ComponentSelector {...element} printVersion={printVersion} />
              )
            )}
          </Table>
        </TableContainer>

        <TableContainer sx={{ paddingLeft: "3.75rem" }}>
          <Table
            sx={{
              ...styles.table,
              width: "100%",
              paddingLeft: "1.25rem",
              mt: 1,
            }}
          >
            <TotalRow
              item="Total Liquid Capital"
              printVersion={printVersion}
              value={
                resultData?.total_liquid_capital_required?.total_liquid_capital
              }
              symbol="$"
            />
          </Table>
        </TableContainer>
        <Box sx={{ height: "1.25rem" }} />
        {investorsProfit?.profit?.investors?.length <= 2 ? (
          <TableContainer>
            <InnerSectionHeader title={`Investors' Profit`} />
            <Box sx={{ height: "1.25rem" }} />
            <Table sx={{ ...styles.table, width: "100%" }}>
              <TableHead>
                <TableRow>
                  {investorsProfit.profit.header.map((element) => (
                    <TableCell sx={{ width: "18% !important" }}>
                      <Typography sx={{ fontWeight: "700", p: 1 }} variant="h4">
                        {element}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {(
                  investorsProfitShareArray ||
                  investorsProfit?.profit?.investors
                ).map((investor, index) => (
                  <TableRow>
                    <TableCell>
                      <Typography sx={{ p: 1 }} variant="h4">
                        {investor?.investor}
                      </Typography>
                    </TableCell>
                    <TableCell sx={styles.valueCell1}>
                      <Typography sx={{ p: 1 }} variant="h4">
                        {commaSeparate(investor?.equity)}
                      </Typography>
                    </TableCell>
                    <TableCell sx={styles.valueCell2}>
                      <Typography sx={{ p: 1 }} variant="h4">
                        {commaSeparate(investor?.profit)}
                      </Typography>
                    </TableCell>
                    <TableCell sx={styles.valueCell}>
                      <Typography sx={{ p: 1 }} variant="h4">
                        {commaSeparate(investor?.roi)}
                      </Typography>
                    </TableCell>
                    <TableCell sx={styles.valueCell2}>
                      <Typography sx={{ p: 1 }} variant="h4">
                        {commaSeparate(investor?.roi_a)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {zillowPrintChecker && (
              <InnerSectionHeader title={`Zillow Comps`} />
            )}
            {zillowPrintChecker &&
              savedCustomComps &&
              savedCustomComps.map((prop) => (
                <ZillowCompTableForReport list={prop} />
              ))}
          </TableContainer>
        ) : (
          ""
        )}
      </SubSectionContainer>
    </>
  );
}

export default ProformaPage4;
