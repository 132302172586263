import { Box,Typography} from '@mui/material'
import React from 'react'
import EnhancedLoanAmount from '../atomicComponents/EnhancedLoanAmount'


function CashForRemodel({handler,data,extraComponentData}) {

    const dealFinanceOption = data?.deal_finance_option || 0
    const header = extraComponentData.secondaryHeaders?.[dealFinanceOption]
    const label = +dealFinanceOption === 3 ? "What is the Cash Amount?" : 'What is the Remodel Cost Amount?'
    const name = +dealFinanceOption === 3 ? "cash_for_home_amount?" : 'cash_remodel_cost'


  return (
    <Box sx={{width:"50%",minWith:"60rem"}}>
        <Box><Typography variant="heading" sx={{color:"#1378A5"}}>{header}</Typography></Box>
        <Box>
            <EnhancedLoanAmount
            name={name}
            data = {data}
            label= {label}
            handler={handler}
            noToolTip={true}
            overrideStyle = {{            
            minWidth: "42rem",
            marginTop: "1rem",
            marginRight:"0.5rem"}}
            />
        </Box>

    </Box>
  )
}

export default CashForRemodel