import { Avatar, Box, Card, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import React from 'react';
import { useState } from 'react';
import { formatPhoneNumber, getYoutubeEmbedLink } from '../../utils/functions';
import { IoMdPhonePortrait } from 'react-icons/io';
import { MdEmail, MdLocationOn, MdOutlinePublic } from 'react-icons/md';
import { ReactComponent as Facebook } from '../../images/facebook.svg';
import { ReactComponent as Twitter } from '../../images/twitterPrem.svg';
import { ReactComponent as Instagram } from '../../images/Instagram_logo.svg';
import { ReactComponent as Linkedin } from '../../images/linkedin.svg';
import Feed from '../../components/Feed/Feed';
import axios from 'axios';
import Vcard from 'vcard-creator';
import Button from '../../components/Button/Button';
import SEO from '../../components/SEO';

function DigiCard({ agent, setIsLoading, isLoading, listings, headerImages }) {
	const [showAll, setShowAll] = useState(false);
	const [listingsModal, setListingsModal] = useState(false);

	const getBase64FromUrl = async (url) => {
		const res = await axios.get(url, {
			responseType: 'arraybuffer',
		});
		const base64 = btoa(new Uint8Array(res.data).reduce((data, byte) => data + String.fromCharCode(byte), ''));

		return new Promise((resolve) => {
			resolve(base64);
		});
	};

	const title = `Real Estate ${agent?.agent_status === 'Agent' ? 'Agent' : 'Broker'}`;

	const saveContact = async () => {
		setIsLoading(true);
		//todo change true to agent.profile image
		//todo change hardcoded url to agent profile image url
		let image;
		let mime = 'JPEG';
		if (agent?.profile_image) {
			try {
				image = await getBase64FromUrl(agent?.profile_image);
				if (image.slice(0, 3) !== '/9j') mime = 'PNG';
			} catch (error) {}
		}

		//todo change address
		const myVard = new Vcard();
		myVard
			.addName(agent.last_name, agent.first_name)
			.addCompany(agent?.brokerage_name || 'BPO Homes')
			.addEmail(agent?.user?.email, 'WORK')
			.addRole(title)
			.addPhoneNumber(agent.phone, 'PREF;WORK')
			.addURL(`${window.location.origin}/agent/${agent?.id}`, 'WORK')
			.addJobtitle(title);

		if (agent?.twitter) {
			myVard.addSocial(agent, 'Twitter', agent?.twitter);
		}
		if (agent?.instagram) {
			myVard.addSocial(agent, 'Instagram', agent?.instagram);
		}
		if (agent?.linkedin) {
			myVard.addSocial(agent, 'Linkedin', agent?.linkedin);
		}

		if (agent?.facebook) {
			myVard.addSocial(agent?.facebook, 'WORK');
		}
		if (image) {
			myVard.addPhoto(image, mime).addLogo(image, mime);
		}
		const text = myVard.toString();

		const blob = new Blob([text], { type: 'text/vcard' });
		const url = URL.createObjectURL(blob);
		const link = document.createElement('a');
		link.href = url;
		link.download = `${agent.first_name}_${agent.last_name}_${agent.id}.vcf`;
		setIsLoading(false);
		link.click();
	};

	return (
		<>
			{!!agent && (
				<SEO>
					<title>{`${agent.first_name} ${agent.last_name} `}</title>
					<meta
						name='description'
						content={`${agent.first_name} ${agent.last_name} ${agent?.agent_status === 'Agent' ? 'Agent' : 'Broker'} ${agent?.brokerage_name}`}
					/>
				</SEO>
			)}
			<Dialog fullScreen={true} open={listingsModal} onClose={() => setListingsModal(false)} aria-labelledby='responsive-dialog-title'>
				<DialogContent style={{ overflowY: 'scroll' }}>
					{listings.active.length > 0 && (
						<section id='active' className='temp1-listings'>
							<h3>Active Listings</h3>
							<div>
								{listings.active.map((listing) => (
									<Feed
										key={listing.id}
										address={listing.address}
										baths={listing.baths}
										beds={listing.beds}
										date={listing.date_created}
										image={listing.photos[0]?.image}
										list_price={listing.list_price}
										onClick={() => window.open(`/view-listing/${agent.id}/${listing.id}`)}
									/>
								))}
							</div>
						</section>
					)}
					{listings.sold.length > 0 && (
						<section id='recent' className='temp1-listings'>
							<h3>Recently Sold Listings</h3>
							<div>
								{listings.sold.map((listing) => (
									<Feed
										key={listing.id}
										address={listing.address}
										baths={listing.baths}
										beds={listing.beds}
										date={listing.date_sold}
										image={listing.photos[0]?.image}
										list_price={listing.list_price}
										onClick={() => window.open(`/view-listing/${agent.id}/${listing.id}`)}
									/>
								))}
							</div>
						</section>
					)}
				</DialogContent>
				<DialogActions>
					<Button
						onClick={() => setListingsModal(false)}
						autoFocus
						style={{ width: 'fit-content', margin: 'auto', fontSize: '16px', padding: '10px' }}
					>
						Close
					</Button>
				</DialogActions>
			</Dialog>
			<div className='temp-mobile'>
				{!isLoading && (
					<img alt='Home' style={{ width: '100%', height: '30vh' }} src={agent?.landing_page_image || (!isLoading && headerImages[0].img)} />
				)}

				<Card
					elevation={4}
					sx={{
						marginTop: '-30px',
						backgroundColor: 'white',
						display: 'flex',
						marginX: 'auto',
						height: 'fit-content',
						borderRadius: '10px',
						maxWidth: '400px',
					}}
				>
					<img
						src={agent?.profile_image}
						alt=''
						style={{
							width: '40%',
							height: '220px',
							objectFit: 'cover',
						}}
					/>
					<Box display='flex' flexDirection='column' padding='15px' width='60%' backgroundColor='white'>
						{agent?.office_logo && (
							<img src={agent?.office_logo} alt='BPOHomes' style={{ height: '40px', padding: '0!important', marginLeft: 'auto', maxWidth: '100%' }} />
						)}
						<Typography sx={{ color: '#005EA9', fontWeight: 'bold', fontSize: '18px' }}>{agent?.first_name} </Typography>
						<Typography sx={{ color: '#005EA9', fontWeight: 'bolder', fontSize: '30px' }}>{agent?.last_name}</Typography>
						<span style={{ fontSize: '10px', fontWeight: '400' }}>{title}</span>
						<p style={{ marginBottom: 0, fontSize: '12px' }}>{agent?.brokerage_address?.replace('USA', '')}</p>
						<p style={{ marginBottom: 0, fontSize: '12px' }}>
							License: {agent?.licenses.find((license) => license.category === 'real_estate_agent')?.number}
						</p>

						{agent?.year_work_commenced && <p style={{ margin: 0, fontSize: '12px' }}>Member Since: {agent?.year_work_commenced}</p>}
					</Box>
				</Card>

				<div style={{ display: 'flex', gap: '20px', justifyContent: 'space-around', padding: '10px' }} className='withLine'>
					<Button
						text='SAVE CONTACT'
						style={{ fontSize: '12px', backgroundColor: 'white', color: 'var(--primary-color)', margin: '0', width: '150px' }}
						onClick={saveContact}
					/>
					{(listings.active.length > 0 || listings.sold.length > 0) && (
						<Button
							text='VIEW LISTINGS'
							style={{ fontSize: '12px', backgroundColor: '#ffaa01', color: 'white', borderColor: '#ffaa01', margin: '0', width: '150px' }}
							onClick={() => setListingsModal(true)}
						/>
					)}
				</div>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						backgroundImage: `linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)),url(${headerImages?.[4].img})`,
						backgroundSize: 'cover',
						alignItems: 'center',
						padding: '1rem',
						color: 'white',
						fontWeight: '500',
					}}
				>
					<p>Want to know your home value?</p>
					<p style={{ marginTop: 0 }}>Get a BPO Report Today!</p>
					<Button
						text='Order BPO Report'
						style={{ fontSize: '12px', backgroundColor: '#ffaa01', color: 'white', borderColor: '#ffaa01', margin: '0', width: '150px' }}
						onClick={() => window.open(`/order-bpo-report/${agent.id}/`)}
					/>
				</div>
				<div style={{ display: 'flex', flexDirection: 'column', padding: '10px', backgroundColor: '#eaf8ff' }}>
					<Typography paragraph sx={{ fontSize: '14px', mb: '0', whiteSpace: 'pre-line' }} textAlign='justify'>
						{showAll ? agent?.bio || '' : agent?.bio?.slice(0, 100) + '...'}
					</Typography>
					<span
						style={{ fontSize: '14px', textDecoration: 'underline', color: '#005EA9', cursor: 'pointer', textUnderlineOffset: '5px' }}
						onClick={() => setShowAll((v) => !v)}
					>
						View {showAll ? 'less' : 'more'}
					</span>

					{agent?.video_link && (
						<iframe
							style={{
								width: '100%',
								margin: '10px auto 0 auto',
								aspectRatio: '16/9',
								borderRadius: '5px',
								padding: '10px 20px',
							}}
							src={`https://www.youtube.com/embed/${getYoutubeEmbedLink(agent?.video_link)}`}
							title='YouTube video player'
							frameBorder='0'
							allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
							allowFullScreen
						></iframe>
					)}
					<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around', padding: '10px', marginTop: '10px' }}>
						{agent?.instagram && (
							<Avatar
								sx={{ bgcolor: 'white' }}
								onClick={() => {
									window.open(`${agent?.instagram}`);
								}}
							>
								<Instagram height='30' width='30' />
							</Avatar>
						)}
						{agent?.linkedin && (
							<Avatar
								sx={{ bgcolor: 'white' }}
								onClick={() => {
									window.open(`${agent?.linkedin}`);
								}}
							>
								<Linkedin width='25' height='25' />
							</Avatar>
						)}
						{agent?.twitter && (
							<Avatar
								sx={{ bgcolor: 'white' }}
								onClick={() => {
									window.open(`${agent?.twitter}`);
								}}
							>
								<Twitter width='30' />
							</Avatar>
						)}
						{agent?.facebook && (
							<Avatar
								sx={{ bgcolor: 'white' }}
								onClick={() => {
									window.open(`${agent?.facebook}`);
								}}
							>
								<Facebook width='22' height='30' />
							</Avatar>
						)}
					</div>
				</div>

				<div style={{ padding: '0 10px' }}>
					{agent?.phone && (
						<a
							href={`tel:${formatPhoneNumber(agent?.phone)}`}
							style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: '20px' }}
						>
							<IoMdPhonePortrait color={'var(--primary-color)'} />
							<p>{formatPhoneNumber(agent?.phone)}</p>
						</a>
					)}
					{agent?.user?.email && (
						<a href={`mailto:${agent?.user.email}`} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: '20px' }}>
							<MdEmail color={'var(--primary-color)'} />
							<p>{agent?.user.email}</p>
						</a>
					)}
					{agent?.web_link && (
						<a
							href={agent.web_link}
							style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: '20px' }}
							target='_blank'
							rel='noreferrer'
						>
							<MdOutlinePublic color={'var(--primary-color)'} />
							<p>{agent.web_link}</p>
						</a>
					)}
					{agent?.brokerage_address && (
						<div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: '20px' }}>
							<MdLocationOn color={'var(--primary-color)'} />
							<p>{agent?.brokerage_address}</p>
						</div>
					)}
				</div>
			</div>
		</>
	);
}

export default DigiCard;
