import React from "react";
import { Box, Card, Typography } from "@mui/material";
import useTheme from "@mui/material/styles/useTheme";
import { useNavigate } from "react-router-dom";
import HtmlToolTipClickable from "./HtmlTooltipClickable";
import { useDispatch, useSelector } from "react-redux";
import useCreateOrFetchReport from "../DealAnalyzerComponents/hooks/useCreateOrFetchReport";
import {
  restoreInitialState,
  updateDealAnalyzerReportId,
} from "../../actions/dealAnalyzer";
import { useEffect } from "react";
import { toggleAuthModal } from "../../actions";

const useStyles = (_theme) => {
  return {
    root: {
      display: "flex",
      position: "relative",
      flexDirection: "column",
      padding: "10px",
      height: "250px",
      cursor: "pointer",
    },
    icon: {
      display: "flex",
      justifyContent: "center",
      padding: "20px",
      paddingBottom: "0",
    },
    text: {
      textAlign: "center",
      padding: "50px",
      minWidth: "150px",
      paddingTop: "20px",
    },
  };
};

function IconCard({
  helpText,
  title,
  icon: Icon,
  link,
  createLink,
  actionCreator,
  dummyLoad,
  propertyAddress,
  typeId,
}) {
  const dispatch = useDispatch();
  const { createReport } = useCreateOrFetchReport();
  const loggedIn = useSelector((state) => state?.auth?.token?.access);
  const navigate = useNavigate();
  const theme = useTheme();
  const styles = useStyles(theme);
  let alertTimeout;




  const handleCreate = async () => {

    // checking for user auth 
    if (loggedIn) {
      const id = dummyLoad
        ? await createReport(createLink, dummyLoad)
        : await createReport(createLink);

      if (id !== "failed") {
        if (actionCreator) {
          dispatch(actionCreator({ undefined }));
          dispatch(actionCreator({ id }));
          if (propertyAddress) {
            dispatch(actionCreator({ property_address: propertyAddress }));
          }
          return navigate(link);
        }
        dispatch(restoreInitialState());
        dispatch(updateDealAnalyzerReportId({ id, analyzerTypeId: typeId }));
        return navigate(link);
      }

      if (dummyLoad) {
        setTimeout(() => {
          navigate("/analytics");
        }, 3000);
      }
    } else {
      const nextRoute = `${window.location.pathname}${link}`;
      localStorage.setItem('nextRoute', nextRoute);
      dispatch(toggleAuthModal());
    }


  };
  useEffect(() => {
    return () => {
      alertTimeout && clearTimeout(alertTimeout);
    };
    // NOTE: Run effect once on component mount, please
    // recheck dependencies if effect is updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card sx={styles.root} elevation={5} onClick={handleCreate}>
      <Box sx={styles.icon}>
        <Icon sx={{ fontSize: "98px" }} />
      </Box>
      <Box sx={styles.text}>
        <Typography variant="h4">{title}</Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          position: "absolute",
          bottom: "10px",
          right: "10px",
        }}
      >
        <HtmlToolTipClickable body={helpText} />
      </Box>
    </Card>
  );
}

export default IconCard;
