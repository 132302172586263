import React from 'react'
import { RadioGroup,FormControlLabel,Radio,Typography,Box } from '@mui/material'
import RegularToolTip from './RegularToolTip';


function EnhancedRadioButtonGroup({ options, handler,choice,radioOverride,name,specialHandler,...rest }) {


  const defaultStyle = { display:"flex",flexDirection:"column"}
  const radio = {    
    '& .MuiSvgIcon-root': {
    fontSize: 22,
  }}
  
  return (
    <div>
      <RadioGroup
        sx={{ marginTop: "0rem",marginBottom:"1.25rem" }}
        value = {choice}
        aria-labelledby=""
        name={name}
        onChange={(e) => {specialHandler ? specialHandler(e) : handler(e.target.value)}}
      >
      <Box sx={rest.styles ? rest.styles:defaultStyle}>
        { options.map((element, index) => (
          <FormControlLabel
            key={index}
            sx={{ padding: "0rem 0",...radioOverride }}
            value={index}
            control={<Radio sx ={radio} checked = {index === +choice} />}
            label={
           element?.name ? 
           < RegularToolTip content = {element?.info}><Typography variant="h4">{element?.name}</Typography></RegularToolTip> :
           <Typography variant="h4">{element}</Typography>
          }
          />
        ))}
      </Box>
      </RadioGroup>
    </div>
  );
}

export default EnhancedRadioButtonGroup;