import React, { useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import base from '../../apis';
import { Button, Card, Grid, IconButton } from '@mui/material';
import Modal from '../../components/Modal/Modal';
import { useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { Box } from '@mui/system';
import { BsCheckCircle } from 'react-icons/bs';
import { grey } from '@mui/material/colors';
import Input from '../../components/Input/Input';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { Close } from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({
	root: {},
	card: {
		padding: '10px',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginTop: '20px',
	},
	modal: {
		padding: '20px',
	},
	payheader: {
		color: grey['500'],
		marginTop: 0,
		borderBottom: `1px solid ${theme.secondaryColor}`,
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	label: {
		margin: '0 0 5px 0',
	},
}));

const dateOptions = [
	{ value: 1, label: 'January' },
	{ value: 2, label: 'February' },
	{ value: 3, label: 'March' },
	{ value: 4, label: 'April' },
	{ value: 5, label: 'May' },
	{ value: 6, label: 'June' },
	{ value: 7, label: 'July' },
	{ value: 8, label: 'August' },
	{ value: 9, label: 'September' },
	{ value: 10, label: 'October' },
	{ value: 11, label: 'November' },
	{ value: 12, label: 'December' },
];

const yearOptions = () => {
	const year = new Date().getFullYear();
	const years = [];
	for (let i = 0; i < 13; i++) {
		years.push({ value: year + i, label: year + i });
	}
	return years;
};

const selectStyles = {
	container: (provided) => ({
		...provided,
		width: '100%',
		padding: '10px 5px',
	}),
};

function PaymentDetails() {
	const classes = useStyles();
	const [card, setCards] = useState(null);
	const [setIsLoading] = useOutletContext();
	const [newCardOpen, setNewCardOpen] = useState(false);
	const [details, setDetails] = useState({
		name: '',
		number: '',
		month: null,
		year: null,
		cvc: '',
	});

	const handleNumber = (e) => {
		const { value } = e.target;
		setDetails((cur) => ({
			...cur,
			number:
				value
					.replace(/\s/g, '')
					.match(/.{1,4}/g)
					?.join(' ') || '',
		}));
	};

	const getPaymentDetails = React.useCallback(() => {
		setIsLoading(true);
		base
			.get('register/get_payment_method/')
			.then(({ data }) => {
				setCards(data);
			})
			.catch(() => {
				toast.warn('Could not get payment details');
			})
			.finally(() => {
				setIsLoading(false);
			});
	}, [setIsLoading]);

	useEffect(() => {
		getPaymentDetails();
	}, [getPaymentDetails]);

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setDetails((cur) => ({
			...cur,
			[name]: value,
		}));
	};

	const handleNewCard = (e) => {
		e.preventDefault();
		if (!details.month?.value || !details.year?.value) {
			toast.warn('Please select a valid expiry date');
			return;
		}
		setIsLoading(true);
		base
			.post('register/add_payment_method/', {
				...details,
				exp_month: details.month.value,
				exp_year: details.year.value,
				card_number: details.number.replace(/\s/g, ''),
			})
			.then(({ data }) => {
				toast.success('Card added successfully');
				setIsLoading(false);
				setNewCardOpen(false);
				getPaymentDetails();
			})
			.catch((err) => {
				if (err?.response?.data?.detail) {
					toast.error(err?.response?.data?.detail.split(': ')[1]);
				}
				setIsLoading(false);
			});
	};
	return (
		<>
			<Modal style={{ minWidth: 'unset' }} open={newCardOpen} onClose={() => setNewCardOpen(false)}>
				<form className={classes.modal}>
					<p className={classes.payheader}>
						<span>PAYMENT DETAILS</span>{' '}
						<IconButton onClick={() => setNewCardOpen(false)}>
							<Close fontSize='small' />
						</IconButton>
					</p>
					<Grid container spacing={2}>
						<Grid item xl={6} xs={12} sm={12}>
							<Input
								name='number'
								pattern='\d{4} \d{4} \d{4} \d{4}'
								label='Card Number'
								maxLength={19}
								value={details.number}
								minLength={19}
								required
								onChange={handleNumber}
							/>
						</Grid>
						<Grid item xl={6} xs={12} sm={12}>
							<Input name='name' label='Card Name' onChange={handleInputChange} value={details.name} required />
						</Grid>
						<Grid item xl={4} xs={12} sm={12}>
							<p className={classes.label}>Expiry Month</p>
							<Select
								options={dateOptions}
								placeholder='Month'
								isSearchable={false}
								name='month'
								value={details.month}
								styles={selectStyles}
								onChange={(e) => setDetails((cur) => ({ ...cur, month: e }))}
							/>
						</Grid>
						<Grid item xl={4} xs={12} sm={12}>
							<p className={classes.label}>Expiry Year</p>
							<Select
								options={yearOptions()}
								placeholder='Year'
								isSearchable={false}
								name='year'
								value={details.year}
								styles={selectStyles}
								onChange={(e) => setDetails((cur) => ({ ...cur, year: e }))}
							/>
						</Grid>
						<Grid item xl={4} xs={12} sm={12}>
							<Input name='cvc' label='CVC' pattern='\d{3,4}' maxLength={4} minLength={3} onChange={handleInputChange} value={details.cvc} required />
						</Grid>
					</Grid>
					<Button
						variant='contained'
						color='secondary'
						style={{ marginTop: '20px', marginLeft: 'auto', display: 'block' }}
						onClick={handleNewCard}
						required
						type='submit'
					>
						Save
					</Button>
				</form>
			</Modal>
			<div>
				<Button variant='outlined' color='secondary' onClick={() => setNewCardOpen(true)}>
					Add New Card
				</Button>
				{card && (
					<Card className={classes.card}>
						<Box display='flex' flexDirection='column'>
							<Box textTransform='capitalize' marginBottom='10px'>
								{card.card.brand} - **** **** **** {card.card.last4}
							</Box>
							<Box>
								{card.card.exp_month}/{card.card.exp_year}
							</Box>
						</Box>
						<BsCheckCircle color='limegreen' />
					</Card>
				)}
			</div>
		</>
	);
}

export default PaymentDetails;
