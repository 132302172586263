import React from 'react';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { signout } from '../../actions';
// import { toggleAuthModal, switchToSignin } from '../../actions';

function PrivateRoutes() {
	//! auth represents weather a user is logged in or not
	const { pathname,search } = useLocation();
	const loggedIn = useSelector((state) => state.auth.token.access && state.auth.token.refresh);
	const parseJwt = (token) => {
		try {
			return JSON.parse(atob(token?.split('.')[1]));
		} catch (e) {
			return null;
		}
	};
	const dispatch = useDispatch();
	const navigate = useNavigate();

	// const openLogin = () => {
	// 	dispatch(toggleAuthModal());
	// 	dispatch(switchToSignin());
	// };

	useEffect(() => {
		const user = localStorage.getItem('atoken');
		if (user) {
			const decodedJwt = parseJwt(user);
			if (decodedJwt.exp * 1000 < Date.now()) {
				toast.error('Session expired. Please login again.');
				dispatch(signout());
				navigate('/', { state: { from: pathname + search } });
				
			}
		} else {
			toast.error('Login to continue.');
			dispatch(signout());
			navigate('/', { state: { from: pathname + search } });
		}
	}, [dispatch, navigate, pathname,search]);

	return <>{loggedIn ? <Outlet /> : <Navigate to='/' state={{ from: pathname + search }} replace />}</>;
}

export default PrivateRoutes;
