import React from 'react'
import { Box, Typography} from '@mui/material'

function Summary({text}) {
  return (
    <Box sx={{marginTop:"10px" }}>

        <Box>
            <Typography  align='justify' variant ='h4'>{text}</Typography>
        </Box>

    </Box>
  )
}

export default Summary