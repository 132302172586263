import React, { useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/material";
import FlatRatePercentageInput from "../../atomicComponents/FlatRatePercentageInput";
import PurchasePrice from "../../atomicComponents/PurchasePrice";
import Points from "../../atomicComponents/Points";
import ExtraOptionsToggle from "../../atomicComponents/ExtraOptionsToggle";

import { makePmiCompulsory } from "../../utils";

const useStyle = (theme) => {
  return {
    root: {
      padding: "1.25rem",
    },
    heading: {
      color: theme.palette.primary.main,
      display: "flex",
      justifyContent: "center",
      marginTop: "3.125rem",
      padding: "5px",
    },
    span: {
      borderBottom: `solid 3px ${theme.palette.primary.main}`,
    },
    flatRate: {
      marginTop: "1.25rem",
    },

    lenderPointsExtra: {
      marginTop: "10rem",
    },
  };
};

function SecondaryRemodelAddition({
  data,
  handler,
  altHeading,
  setPmiValue,
  remodelCost,
  pmi_value,
}) {
  const theme = useTheme();

  const styles = useStyle(theme);

  const rateHandler = (value) => {
    handler({ secondary_dollar_or_percent: value });
  };
  useEffect(() => {
    const checkPmi = makePmiCompulsory(
      remodelCost,
      data?.secondary_down_payment,
      data?.secondary_dollar_or_percent
    );
    if (checkPmi) {
      handler({ secondary_include_pmi: true });
    } else {
      handler({ secondary_include_pmi: false });
    }
    // NOTE: Run effect once on component mount, please
    // recheck dependencies if effect is updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    remodelCost,
    data?.secondary_down_payment,
    data?.secondary_dollar_or_percent,
  ]);

  useEffect(() => {
    if (!data?.secondary_lender_points_options) {
      handler({ secondary_lender_points_options: 1 });
    }
    // NOTE: Run effect once on component mount, please
    // recheck dependencies if effect is updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Box sx={styles.purchasePrice}>
        <PurchasePrice
          label={altHeading || "Remodel Cost Amount"}
          handler={handler}
          data={data}
          name="secondary_total_remodel_cost"
        />
      </Box>
      <Box>
        <Points
          handler={handler}
          label="Lender Points"
          name="secondary_lender_points"
          data={data}
        />
        <ExtraOptionsToggle
          options={lenderPointOptions}
          handler={handler}
          name="secondary_lender_points_options"
          data={data}
          showText="Lender Points Options"
        />
      </Box>

      <Box sx={styles.flatRate}>
        <FlatRatePercentageInput
          title="Down Payment"
          name="secondary_down_payment"
          rate={data?.secondary_dollar_or_percent || 0}
          rateHandler={rateHandler}
          handler={handler}
          data={data}
          toolTipText={toolTipText.primaryDownPayment}
          referenceValue={+remodelCost}
        />
      </Box>
      {/* <Box sx={{ display: "flex", gap: 6, marginTop: 4, alignItems: "center" }}>
        <EnhancedCheckBoxGroup
          options={mortgageInsuranceData}
          data={data}
          handler={handler}
          name="secondary_loan_include_pmi"
          // autoCheckedArray={
          //supply boolean array in to match indexes in options prop
          //   [
          //     makePmiCompulsory(
          //       data?.secondary_loan_amount,
          //       data?.secondary_down_payment,
          //       data?.secondary_dollar_or_percent
          //     ),
          //   ]
          // }
        />
        <>
          <StyledLabel label="Enter PMI percentage" />
          <TextField
            id="outlined-basic"
            variant="outlined"
            type="number"
            value={pmi_value}
            onChange={(e) => setPmiValue(e.target.value)}
            inputProps={{ style: { fontSize: 15 }, min: 0 }}
            onKeyPress={preventMinus}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <Typography fontSize={15} fontWeight={"900"}>
                    %
                  </Typography>
                </InputAdornment>
              ),
            }}
          />
        </>
      </Box> */}
    </>
  );
}

export default SecondaryRemodelAddition;

const lenderPointOptions = [
  "Add Lender Points to Loan Amount",
  "Add Lender Points to Upfront Closing Cost",
  "Add Lender Points to Back End Closing Costs",
];
// const mortgageInsuranceData = [
//   {
//     name: "secondary_include_pmi",
//     label: "Add PMI",
//   },
// ];
const toolTipText = {
  primaryLenderPointsInfo:
    "Commission payable to Lender or Mortgage Broler for providing the loan. Usually between 0 - 3 percent of the loan amount.",
  primaryWholesaleFee:
    "This is a finders fee paid to an individual who found the home",
  primaryDownPayment:
    "The amount of cash or capital you are applying to the purchase price",
  includePmi: {
    title: "Private Mortgage Insurance",
    text: "A type of insurance that is often required for conventional loan borrowers with a down payment of less than 20% of the home's purchase price. PMI insures the lender's loan in the event the Borrower/Buyer defaults on the loan.",
  },
};
