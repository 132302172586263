import { Check, Circle } from '@mui/icons-material';
import {
	Box,
	Button,
	Card,
	CardContent,
	CardHeader,
	Container,
	Divider,
	Grid,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import BG from '../../assets/buycheck.webp';
import Check1 from '../../assets/checklist1.webp';
import Check2 from '../../assets/checklist2.webp';
import Check3 from '../../assets/checklist3.webp';
import SEO from '../../components/SEO';

const useStyles = makeStyles((theme) => ({
	banner: {
		height: '60vh',
		backgroundImage: `url(${BG})`,
		backgroundPosition: 'center',
		backgroundSize: 'cover',
	},
	title: {
		fontWeight: 600,
		marginBottom: '1rem',
	},
	listIcon: {
		fontSize: '.8rem',
		minWidth: '32px',
		color: '#000',
	},
}));
const BuyerChecklist = () => {
	const classes = useStyles();
	const navigate = useNavigate();
	const checkList1 = [
		{ name: 'Calculate Your Monthly Housing Budget', icon: <Check fontSize='small' /> },
		{ name: 'Put Money Toward a Down Payment', icon: <Check fontSize='small' /> },
		{ name: 'Discover A Reliable Real Estate Agent', icon: <Check fontSize='small' /> },
	];
	const checkList2 = [
		{ name: 'Consult a real estate lawyer', icon: <Check fontSize='small' /> },
		{ name: 'Arrange for a house inspection', icon: <Check fontSize='small' /> },
	];

	return (
		<div>
			<SEO>
				<title>The Ultimate Home Buying Checklist: Your Guide to a Smoother Purchase Process</title>
				<meta name='og:title' content='The Ultimate Home Buying Checklist: Your Guide to a Smoother Purchase Process' />
				<meta
					name='description'
					content='Learn the best steps for buying a home with this comprehensive checklist. From calculating your monthly housing budget to arranging for a house inspection, this guide has you covered.'
				/>
				<meta
					name='og:description'
					content='Learn the best steps for buying a home with this comprehensive checklist. From calculating your monthly housing budget to arranging for a house inspection, this guide has you covered.'
				/>
				<meta name='og:image' content={window.location.origin + BG} />
			</SEO>
			<Box className={classes.banner}></Box>
			<Box sx={{ background: (theme) => theme.secondaryColor, padding: '4rem' }}>
				<Typography textAlign={'center'} sx={{ color: '#fff' }} gutterBottom variant='h5'>
					Your Best Checklist for Buying a Home
				</Typography>
				<Divider sx={{ width: '7rem', height: '3px', margin: '.5rem auto', background: (theme) => theme.primaryColor }} />
			</Box>
			<>
				<Container>
					<Box padding='4rem 0'>
						<Typography mb={6}>
							Do you want to move? There is no "right" or "wrong" moment to buying a home, which is good news; it all depends on when you are
							emotionally and financially prepared. Get ready: While buying a property may seem simple on reality TV, it can be a complicated process.
							Since you're probably getting ready to make the most costly purchase of your life, it's fair to have questions. However, the good news
							is that you don't need to experience overload.
							<br />
							<br />
							How come that? Because we have created a checklist that will enable you to properly manage and finish each phase of the home buying
							process.
						</Typography>
						<Grid container spacing={4}>
							<Grid item lg={6}>
								{checkList1.map((el) => {
									return (
										<ListItem key={el.name}>
											<ListItemIcon>{el.icon}</ListItemIcon>
											<ListItemText>{el.name}</ListItemText>
										</ListItem>
									);
								})}
							</Grid>
							<Grid item lg={6}>
								{checkList2.map((el) => {
									return (
										<ListItem key={el.name}>
											<ListItemIcon>{el.icon}</ListItemIcon>
											<ListItemText>{el.name}</ListItemText>
										</ListItem>
									);
								})}
							</Grid>
						</Grid>

						{/* Calculate */}
						<Box mt={4} mb={3}>
							<Typography variant='h6' className={classes.title}>
								1. Calculate Your Monthly Housing Budget
							</Typography>
							<Typography paragraph>
								Setting a budget is the ideal method to get your home-buying process started. After all, this can be difficult enough without
								considering properties that are too expensive. Who would want to fall in love with something they could never have?
							</Typography>
							<Typography paragraph>
								You can start your search with a clear grasp of your price range by creating a realistic budget. Getting preapproved early on in the
								home search will help you determine your purchasing budget and set reasonable goals. Here are several methods for figuring out how
								much house you can afford:
							</Typography>
							<List>
								<ListItem>
									<ListItemIcon>
										<Circle fontSize='small' className={classes.listIcon} />
									</ListItemIcon>
									<ListItemText>
										Your debt-to-income ratio (DTI), or simply your monthly spending divided by your monthly income, is the first thing you must
										ascertain. This is your total monthly debt divided by your gross monthly income.
									</ListItemText>
								</ListItem>
								<ListItem>
									<ListItemIcon>
										<Circle fontSize='small' className={classes.listIcon} />
									</ListItemIcon>
									<ListItemText>
										Include any recurrent debts, such as student loans, auto payments, child support payments, or anything else you make a monthly
										payment for when you are thinking about your debts.
									</ListItemText>
								</ListItem>
								<ListItem>
									<ListItemIcon>
										<Circle fontSize='small' className={classes.listIcon} />
									</ListItemIcon>
									<ListItemText>
										Your DTI should be no greater than 43% to qualify for a mortgage with the majority of lenders. But keep in mind that's the
										"outside" amount you can spend; it doesn't follow that you should.
									</ListItemText>
								</ListItem>
								<ListItem>
									<ListItemIcon>
										<Circle fontSize='small' className={classes.listIcon} />
									</ListItemIcon>
									<ListItemText>
										Do you know what it means to be "housing poor"? That means you overspent on your house to the point where you have no money left
										over for extras like dining out or travel, let alone the numerous costs that will be incurred in addition to your mortgage. Budget
										not only for the "fun" things that come with homeownership, like routine maintenance and even unforeseen big-ticket items like a
										new water heater, but also for the "unfun" things, like furnishings, new paint, and plants for your new patio pots.
									</ListItemText>
								</ListItem>
							</List>
						</Box>

						{/* Payment */}
						<Box mt={3} mb={3}>
							<Typography variant='h6' className={classes.title}>
								2. Put Money Toward a Down Payment
							</Typography>
							<Typography paragraph>
								One part of the puzzle is your mortgage payment. Making a sizeable down payment is also crucial because it will save you a lot of
								money throughout your loan. This is how: You will no longer be required to pay private mortgage insurance if you put 20% or more down
								(PMI). Usually, a down payment of less than 20% necessitates PMI. Your DTI and credit score are risk factors that affect how much PMI
								insurance will cost you. Any homeowner would prefer to avoid this expense. Because a lower loan-to-value ratio (LTV) reduces your
								lender's risk, a larger down payment frequently results in a cheaper interest rate. A borrower may save thousands of dollars by making
								larger initial principal payments since they will pay less interest overall. Smaller monthly payments are the result of larger down
								payments because you owe less on the house. You may be more likely to get approved for a vehicle loan, credit, or another financing if
								you make smaller monthly payments in the future.
							</Typography>
						</Box>

						{/* Discover */}
						<Box mt={3} mb={1}>
							<Typography variant='h6' className={classes.title}>
								3. Discover A Reliable Real Estate Agent
							</Typography>
							<Typography paragraph>
								Even though you might wish to conduct some of your research, whether it be through online listings or open houses, the majority of
								home purchasers think that working with a real estate agent or REALTOR® adds value. They can offer experienced market expertise in
								addition to helping you find potential homes. Their knowledge might direct you toward:
							</Typography>
							<List>
								<ListItem>
									<ListItemIcon>
										<Circle fontSize='small' className={classes.listIcon} />
									</ListItemIcon>
									<ListItemText>Whether a price is reasonable to ask for weighing the benefits and drawbacks of residences</ListItemText>
								</ListItem>
								<ListItem>
									<ListItemIcon>
										<Circle fontSize='small' className={classes.listIcon} />
									</ListItemIcon>
									<ListItemText>Which areas are the most promising? The preapproval and mortgage process</ListItemText>
								</ListItem>
								<ListItem>
									<ListItemIcon>
										<Circle fontSize='small' className={classes.listIcon} />
									</ListItemIcon>
									<ListItemText>Wars of bids and negotiations</ListItemText>
								</ListItem>
								<ListItem>
									<ListItemIcon>
										<Circle fontSize='small' className={classes.listIcon} />
									</ListItemIcon>
									<ListItemText>Documentation and the closure procedure</ListItemText>
								</ListItem>
							</List>
						</Box>
					</Box>
				</Container>

				{/* Divider Section */}
				<Box style={{ height: '15rem', backgroundImage: `linear-gradient(#1378A595, #1378a5cf), url(${BG})` }}>
					<Container sx={{ display: 'flex', justifyContent: 'space-between', height: '100%', alignItems: 'center', width: '60%' }}>
						<Typography variant='h5' sx={{ color: '#fff' }}>
							Trust a BPO Homes Agent to help you all the way.
						</Typography>
						<Button size='large' variant='contained' disableElevation color='paper' href='/agent-finder'>
							Find Agent
						</Button>
					</Container>
				</Box>

				<Container>
					<Box>
						{/* Consult */}
						<Box mt={5} mb={3}>
							<Typography variant='h6' className={classes.title}>
								4. Consult a real estate lawyer
							</Typography>
							<Typography paragraph>
								Yes, we are including a new member in the cast: a real estate lawyer. A real estate attorney is recommended even in states where it is
								not legally compulsory to have one, particularly in complex legal issues. What should you do, for instance, if there is a current
								tenant or if you are uncertain about the title or an easement? Other complex circumstances requiring guidance from a real estate
								attorney include short sales and foreclosures. A real estate lawyer has the specialized knowledge necessary to respond to all of these
								legal queries and to review the purchase agreement, title documents, and other paperwork to ensure everything is in order.
							</Typography>
							<Typography paragraph>
								Even while hiring a real estate lawyer adds another expenditure at a time when you might already be strapped for cash, the peace of
								mind they can offer may be well worth the cost. The fact that buying a home is one of the biggest purchases you'll ever make just goes
								to show that your to-do list for the process should involve making sure you've done everything necessary to ensure due diligence.
							</Typography>
						</Box>

						{/* Consult */}
						<Box mt={5} mb={3}>
							<Typography variant='h6' className={classes.title}>
								5. Arrange for a house inspection
							</Typography>
							<Typography paragraph>
								Before submitting an offer, it is impossible to evaluate a house's integrity and suitability in all respects. A house inspection can
								be useful in this situation. You will be responsible for paying the money, but it will be well worth it in the long run because a
								house inspection will shield you from unanticipated expenses and obligations. You'll be able to tell whether the foundation is sound,
								how much "roof life" is left, and whether the electrical system is up to pace, for instance. Minor issues are also likely to be found
								during the home inspection. There's no need to fear; practically every check finds something.
							</Typography>
							<Typography paragraph>
								Instead, given that you agreed to an inspection contingency, you might utilize these problems to your advantage to barter for seller
								concessions or a lower final purchase price. Additionally, confirm with your real estate agent if this is reasonable because you don't
								want to nickel and dime a seller in a competitive market. You might think about having specialized examinations in addition to a
								regular home inspection, including radon or mold tests.
							</Typography>
						</Box>

						<Box mt={4} mb={5}>
							<Typography variant='h6' mb={3}>
								Read Also:
							</Typography>
							<Grid container spacing={4}>
								<Grid item xs={12} sm={12} lg={4}>
									<Card variant='elevation' sx={{ cursor: 'pointer' }} onClick={() => navigate('/bpo-guide')}>
										<CardHeader style={{ backgroundImage: `url(${Check1})`, backgroundSize: 'cover', height: '15rem' }}></CardHeader>
										<CardContent style={{ padding: '2rem' }}>
											<Typography color={'secondary'} mb={2}>
												Getting Started
											</Typography>
											<Typography variant='body1' color={'textSecondary'}>
												Why Buyers Need A Broker's Price Opinion
											</Typography>
										</CardContent>
									</Card>
								</Grid>
								<Grid item xs={12} sm={12} lg={4}>
									<Card variant='elevation' sx={{ cursor: 'pointer' }} onClick={() => navigate('/buyer-score')}>
										<CardHeader style={{ backgroundImage: `url(${Check2})`, backgroundSize: 'cover', height: '15rem' }}></CardHeader>
										<CardContent style={{ padding: '2rem' }}>
											<Typography color={'secondary'} mb={2}>
												Getting Started
											</Typography>
											<Typography variant='body1' color={'textSecondary'}>
												How to Raise Your Credit Score to Get Your First Home Mortgage
											</Typography>
										</CardContent>
									</Card>
								</Grid>
								<Grid item xs={12} sm={12} lg={4}>
									<Card variant='elevation' sx={{ cursor: 'pointer' }} onClick={() => navigate('/buyer-checklist')}>
										<CardHeader style={{ backgroundImage: `url(${Check3})`, backgroundSize: 'cover', height: '15rem' }}></CardHeader>
										<CardContent style={{ padding: '2rem' }}>
											<Typography color={'secondary'} mb={2}>
												Getting Started
											</Typography>
											<Typography variant='body1' mb={3.5} color={'textSecondary'}>
												Buying A Home in 2022?
											</Typography>
										</CardContent>
									</Card>
								</Grid>
							</Grid>
						</Box>
					</Box>
				</Container>
			</>
		</div>
	);
};

export default BuyerChecklist;
