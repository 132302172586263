import { FormControlLabel, Radio, RadioGroup, Stack, Typography } from '@mui/material'
import React from 'react'

const RadioInputItem = ({ title, onChange, formData, value, disable }) => {
    return (
        <Stack direction={'row'} gap={2} alignItems={'center'} justifyContent={'space-between'}>
            <Typography fontWeight={500} color={'secondary'}>{title}</Typography>
            <RadioGroup row
                value={value}
                onChange={(e) => onChange(e?.target?.value)}
            >
                {
                    formData?.map((el, i) => <FormControlLabel disabled={disable} key={i} value={el?.toLowerCase()} sx={{ mr: 4 }} control={<Radio size='small' color='secondary' />} label={el} />)
                }
            </RadioGroup>
        </Stack>
    )
}

export default RadioInputItem