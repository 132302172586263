import { Avatar, Button, createTheme, Divider, Grid, IconButton, Stack, ThemeProvider, Typography, Link as NLink } from '@mui/material';
import { makeStyles, styled } from '@mui/styles';
import React, { Fragment } from 'react';
import { Link as RLink } from 'react-router-dom';
import { ReactComponent as FBIcon } from '../images/facebookfillable.svg';
import { ReactComponent as IGIcon } from '../images/instagramfillable.svg';
import { ReactComponent as LIIcon } from '../images/linkedInfillable.svg';
import { ReactComponent as TWIcon } from '../images/twitterfillable.svg';
import { ReactComponent as YTIcon } from '../images/youtubefillable.svg';
import { theme } from '../theme';
import { Spoiler } from './IndustryProfessionalId';
import RequestAQuote from '../components/RequestAQuote';

//! https://www.figma.com/design/Q83dnbULW6YZeYlFf9qbZX/Premier-Agent?node-id=679-10829&t=99YPxsnL2rkiIt0g-4

const pageTheme = createTheme({
	...theme,
	typography: {
		fontFamily: 'Inter, serif',
		button: {
			textTransform: 'capitalize',
		},
	},
	palette: {
		primary: {
			main: '#C43338',
		},
		secondary: {
			main: '#000',
		},
		text: {
			main: '#000',
		},
		paper: {
			main: '#fff',
		},
	},
	primaryColor: '#C43338',
	secondaryColor: '#000',
});

const socials = [
	{
		key: 'facebook',
		Icon: <FBIcon fill='#FFFFFF' />,
	},
	{
		key: 'twitter',
		Icon: <TWIcon fill='#FFFFFF' />,
	},
	{ key: 'instagram', Icon: <IGIcon fill='#FFFFFF' /> },
	{
		key: 'linkedin',
		Icon: <LIIcon fill='#FFFFFF' />,
	},
	{
		key: 'youtube',
		Icon: <YTIcon fill='#FFFFFF' />,
	},
];

const mainPaddingX = '10rem';

const navItems = [
	{ label: 'Home', value: '#home' },
	{ label: 'Request A Quote', value: '#quote' },
	{ label: 'About Us', value: '#about-us' },
];
const Link = styled(RLink)(({ theme }) => ({
	cursor: 'pointer',
	color: theme.primaryColor,
}));

const useStyles = makeStyles((theme) => ({
	root: {
		fontFamily: 'Inter, serif',
		scrollMarginTop: '100px',
		minHeight: '100vh',
	},
	leftLinks: {
		display: 'flex',
		alignItems: 'center',
		color: '#ffffff !important',
		fontFamily: 'Inter, serif',
		fontWeight: 500,
		cursor: 'pointer',
	},

	withgrad: {
		background: 'linear-gradient(to right, #365BC6 , #C43338)',
	},
	backgrounder: {
		padding: `1rem ${mainPaddingX}`,
		height: '95vh',
		backgroundSize: 'cover',
		position: 'relative',
		backgroundPosition: 'center',
		backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),url(https://bpooperationsmain.s3.eu-west-2.amazonaws.com/ind-professional/ind6back.jpg)`,
	},
}));

const services = [
	{
		title: 'Roof Installation',
		body: `This involves installing new roofs on buildings. It includes selecting appropriate roofing materials`,
	},
	{
		title: 'Roof Repair',
		body: `Roof repair services address issues such as leaks, damaged shingles, flashing, or gutter problems.`,
	},
	{
		title: 'Roof Replacement',
		body: `When a roof is beyond repair or has reached the end of its lifespan, it may need to be replaced entirely.`,
	},
	{
		title: 'Roof Inspection',
		body: `Regular roof inspections are essential for identifying potential issues early on, such as leaks, structural damage, or signs of wear and tear`,
	},
	{
		title: 'Flat Roof Services',
		body: `For buildings with flat or low-slope roofs, specialized services may be required, such as installing or repairing built-up roofing (BUR)`,
	},
	{
		title: 'Green Roof Installation',
		body: `Some roofing companies specialize in installing green roofs, which involve planting vegetation on the roof surface to improve energy efficiency `,
	},
];

const roofs = [
	{
		image: 'https://bpooperationsmain.s3.eu-west-2.amazonaws.com/ind-professional/asphalt-roofing.jpg',
		title: 'Asphalt',
		body: `With asphalt shingles today providing a Beautiful, Affordable and Reliable roofing option for any home, asphalt shingles continuing to raise the bar when it comes to roofing.`,
	},
	{
		image: 'https://bpooperationsmain.s3.eu-west-2.amazonaws.com/ind-professional/metal-roofing.jpg',
		title: 'Metal',
		body: `Metal roofs offer a multitude of benefits for protecting your roof from snow and ice build-up. There is essentially nowhere for the water to accumulate and gain entry.`,
	},
	{
		image: 'https://bpooperationsmain.s3.eu-west-2.amazonaws.com/ind-professional/cedar-roofing.jpg',
		title: 'Cedar',
		body: `Unlike traditional roofing materials, cedar wood is durable, resulting in a cost-saving solution. The natural insulation of cedar wood gives roofs up to two times the energy efficiency`,
	},
];

const IndProfTemp6 = ({ address, icon_file, company_name, id, formatted_phone_number, user, bio, ...profDetails }) => {
	const classes = useStyles();
	return (
		<ThemeProvider theme={pageTheme}>
			<Stack className={classes.root}>
				<Stack direction='row' className={classes.withgrad} p={`1rem ${mainPaddingX}`} justifyContent='space-between'>
					<Stack direction='row' gap='1rem' alignItems={'center'}>
						<Avatar src={icon_file} sx={{ backgroundColor: 'white' }} />
						<Typography fontWeight={700} fontSize='30px' color='white'>
							{company_name}
						</Typography>
					</Stack>
					<Stack direction='row' gap='1rem'>
						{navItems.map((item) => (
							<NLink href={item.value} className={classes.leftLinks} underline='none' key={item.value}>
								{item.label}
							</NLink>
						))}
					</Stack>
				</Stack>
				<Stack className={classes.backgrounder} justifyContent={'center'}>
					<Stack
						sx={{
							position: 'absolute',
							top: 0,
							left: 0,
							width: '100%',
						}}
						direction='row'
						alignItems='center'
						justifyContent='space-between'
					>
						<Stack sx={{ width: '300px', height: '160px', background: '#C43338' }}></Stack>
						<Button sx={{ marginRight: '5rem' }} variant='contained' href='#quote'>
							Request A Quote
						</Button>
					</Stack>
					<Stack sx={{ maxWidth: '50%', color: 'white' }}>
						<Typography fontFamily={'Jost,Inter'} fontSize={40} fontWeight={700}>
							ROOFING EXCELLENCE ABOVE ALL ELSE
						</Typography>
						<Typography fontFamily={'Jost,Inter'} fontSize={22} fontWeight={300}>
							Regular inspection and maintenance are necessary to identify and address any issues such as leaks, damaged shingles, or flashing
							problems.
						</Typography>
					</Stack>
					{formatted_phone_number && (
						<Stack
							sx={{
								position: 'absolute',
								bottom: 0,
								transform: 'translateY(50%)',
								right: 0,
								padding: '1rem 5rem',
								background: '#365BC6',
								color: 'white',
							}}
							alignItems='center'
							justifyContent='center'
						>
							<Typography fontSize={30}>CALL US NOW</Typography>
							<Typography
								component={NLink}
								href={`tel:${formatted_phone_number}`}
								color='white'
								fontSize={40}
								sx={{ color: '#ffffff !important', textDecoration: 'none', cursor: 'pointer' }}
							>
								{formatted_phone_number}
							</Typography>
						</Stack>
					)}
				</Stack>

				<Grid container spacing={2} px={mainPaddingX} py='1rem' my='15rem'>
					<Grid item xs={3}>
						<Stack gap='10px'>
							<Typography fontFamily={'Jost,Inter'} fontSize={30}>
								Our Services
							</Typography>
							<Typography fontFamily={'Jost,Inter'} fontSize={40} fontWeight={800}>
								Premium Roofing Services Offered
							</Typography>
							<Typography fontFamily={'Jost,Inter'} fontSize={20}>
								Looking for reliable roofing services? Look no further! Roofly offers a comprehensive range of roofing solutions to meet your needs.
							</Typography>
						</Stack>
					</Grid>
					<Grid item xs={9}>
						<Stack sx={{ height: '100%' }} justifyContent={'space-between'}>
							<Stack gap='1rem' direction='row'>
								{services.slice(0, 3).map((item) => (
									<Stack gap='10px' key={item.title} sx={{ flex: 1 }}>
										<Typography fontFamily={'Jost,Inter'} fontSize={20} fontWeight={700}>
											{item.title}
										</Typography>
										<Typography fontFamily={'Jost,Inter'} fontSize={17} fontWeight={500}>
											{item.body}
										</Typography>
									</Stack>
								))}
							</Stack>
							<Stack gap='1rem' direction={'row'}>
								{services.slice(3).map((item) => (
									<Stack gap='10px' key={item.title} sx={{ flex: 1 }}>
										<Typography fontFamily={'Jost,Inter'} fontSize={20} fontWeight={700}>
											{item.title}
										</Typography>
										<Typography fontFamily={'Jost,Inter'} fontSize={17} fontWeight={500}>
											{item.body}
										</Typography>
									</Stack>
								))}
							</Stack>
						</Stack>
					</Grid>
				</Grid>
				<Stack
					gap='1rem'
					alignItems={'center'}
					sx={{ background: '#365BC6', color: 'white', paddingBottom: '7rem' }}
					mx={mainPaddingX}
					p='1rem'
					id='about-us'
					mt='15rem'
				>
					<Typography fontSize={25}>— About Us</Typography>
					<Typography fontSize={55} fontWeight={500}>
						Get to know more about us.
					</Typography>
				</Stack>

				<Stack px={mainPaddingX} direction='row' mb='15rem'>
					<Stack sx={{ flex: 1, height: '100%' }} alignItems='flex-end'>
						<img
							alt='brave'
							src='https://bpooperationsmain.s3.eu-west-2.amazonaws.com/ind-professional/brave.jpg'
							style={{ marginTop: '-3rem', width: '90%', aspectRatio: '1', objectFit: 'cover' }}
						/>
					</Stack>
					<Stack sx={{ flex: 1, height: '100%', padding: '1rem' }} justifyContent={'center'}>
						<Spoiler text={bio} maxWidth='100%' />
					</Stack>
				</Stack>

				<Stack padding={`1rem ${mainPaddingX}`} mt='3rem'>
					<Typography color='#A39992' textAlign={'center'} fontSize={40}>
						Anything is possible with us
					</Typography>
					<Typography color='#403230' textAlign={'center'} my='1rem' fontSize={60}>
						We offer the following type of roofing:
					</Typography>

					{roofs.map((item, index) => (
						<Stack direction={index === 1 ? 'row-reverse' : 'row'} gap='2px' key={item.title} mb='2px'>
							<img src={item.image} style={{ width: `60%`, aspectRatio: '2.5', objectFit: 'cover' }} alt='asphalt' />
							<Stack
								justifyContent={'center'}
								sx={{ background: '#365BC6', padding: '1rem', color: 'white', width: `40%`, aspectRatio: '1', flex: 1 }}
								gap='1rem'
							>
								<Typography fontSize={35}>{item.title} Roofing</Typography>
								<Typography>{item.body}</Typography>
							</Stack>
						</Stack>
					))}
				</Stack>
				<Stack px={mainPaddingX} my='3rem' id='quote'>
					<Typography fontSize='50px' textAlign={'center'} mb='2rem' id='quote' fontWeight={700}>
						Want A Quote?
					</Typography>
					<RequestAQuote withMargin={false} id={id} />
				</Stack>
				<Stack sx={{ paddingX: mainPaddingX }} mt='3rem'>
					<Grid container spacing={2} my='5rem' className={classes.color}>
						<Grid item xs={12}>
							<Divider sx={{ background: 'white' }} />
						</Grid>
						<Grid item xs={4}>
							<Stack gap='1rem'>
								<Avatar src={icon_file} />

								<Typography fontWeight={700} fontSize='30px' color='#C43338'>
									{company_name}
								</Typography>
								<Stack direction='row' gap='1rem'>
									{socials.map((social) => (
										<Fragment key={social.key}>
											{profDetails?.[social.key] && (
												<IconButton href={profDetails?.[social.key]} target='_blank'>
													{social.Icon}
												</IconButton>
											)}
										</Fragment>
									))}
								</Stack>
							</Stack>
						</Grid>
						<Grid item xs={4}>
							<Stack gap='1rem'>
								<Typography fontWeight={600} fontSize='20px'>
									{address}
								</Typography>
								<Typography sx={{ cursor: 'pointer' }} component='a' href={`tel: ${formatted_phone_number}`}>
									Phone: {formatted_phone_number}
								</Typography>
								<Typography component='a' href={`mailto: ${user?.email}`} sx={{ cursor: 'pointer' }}>
									Email: {user?.email}
								</Typography>
							</Stack>
						</Grid>
						<Grid item xs={4}>
							<Stack gap='1rem'>
								<Typography fontWeight={600} fontSize='20px'>
									Navigation
								</Typography>
								{navItems.map((item) => (
									<NLink href={item.value} underline='none' key={item.value} style={{ fontWeight: 400, color: 'black', cursor: 'pointer' }}>
										{item.label}
									</NLink>
								))}
							</Stack>
						</Grid>

						<Grid item xs={12} mt='3rem'>
							<Stack direction={'row'} justifyContent={'space-between'}>
								<Typography>&copy; {new Date().getFullYear()} BPO Tech All rights reserved.</Typography>
								<Stack direction='row' gap='1rem'>
									<Link to='/privacy-policy' style={{ color: 'black' }}>
										Privacy Policy
									</Link>
									<Link to='/terms-of-service' style={{ color: 'black' }}>
										Terms and Conditions
									</Link>
								</Stack>
							</Stack>
						</Grid>
					</Grid>
				</Stack>
			</Stack>
		</ThemeProvider>
	);
};

export default IndProfTemp6;
