import React from "react";
import {
  Box,
  Typography,
  FormControl,
  OutlinedInput,
  InputAdornment,
} from "@mui/material";
import StyledLabel from "../atomicComponents/StyledLabel";
import MonthYearSelector from "../atomicComponents/MonthYearSelector";
import { preventMinus } from "../utils/preventNegativeValues";

function QuestionnaireSecondaryInterestAndTerm({ data, handler }) {
  const handleChange = (e) => {
    handler({ [e.target.name]: e.target.value });
  };
  return (
    <Box sx={{ width: "50%", minWidth: "21.875rem" }}>
      <Box>
        <Typography variant="heading" sx={{ color: "#1378A5" }}>
          Secondary Remodel Loan
        </Typography>
      </Box>
      <Box sx={{ width: "100%", marginTop: "1.875rem" }}>
        <Box sx={{ marginBottom: "10px" }}>
          <StyledLabel label="What is the Interest Rate" />
        </Box>
        <FormControl sx={{ width: "100%" }}>
          <OutlinedInput
            fullWidth
            margin="normal"
            name={"secondary_interest_rate"}
            onChange={handleChange}
            value={data?.["secondary_interest_rate"]}
            // placeholder='0'
            renderValue={(value) => value}
            onKeyPress={preventMinus}
            inputProps={{ style: { fontSize: "1rem" }, min: 0 }}
            endAdornment={
              <InputAdornment>
                <Typography variant="adornment">%</Typography>
              </InputAdornment>
            }
          />
        </FormControl>
      </Box>
      <Box sx={{ marginTop: "1.875rem" }}>
        <MonthYearSelector
          data={data}
          label="What is the term of the Loan"
          handler={handler}
          name="secondary_term"
        />
      </Box>
    </Box>
  );
}

export default QuestionnaireSecondaryInterestAndTerm;
