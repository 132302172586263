import useProformaData from "./useProformaData";
import { propertyType } from "../../utils/numericToStringConvert";
import { getPrimaryConventional } from "./ProformaHooksSpecialUtils/conventionalLoanDealFinance";
import { getSecondaryConventional } from "./ProformaHooksSpecialUtils/conventionalLoanDealFinance";
import { getPrimaryMortgage } from "./ProformaHooksSpecialUtils/mortgageLoanDealFinance";
import { getSecondaryMortgage } from "./ProformaHooksSpecialUtils/mortgageLoanDealFinance";
import { getRestOfDealFinancesBuyAndRent } from "./ProformaHooksSpecialUtils/getRestOfDealFinancesBuyAndRent";

function useProformaData6() {
  const {
    loading,
    propertyPermitAndFees,
    monthlyTaxPayment,
    frontendClosingCost,
    backendClosingCost,
    carryingCost,
    operationalExpenses,
    otherExpenses,
    estResaleValue,
    rentalIncome,
    capitalizationRate,
    netProfit,
    investorsProfit,
    resultData,
    fetchResult,
    analyzerData,
    user,
    summary,
    listingArray,
  } = useProformaData();
  const dealFinanceOption = analyzerData?.financeOptions?.deal_finance_option;

  const dealType = "Buy and Rent";

  const otherPropertyType = analyzerData?.propertyInformation
    ?.other_property_type
    ? {
        item: "Specified Type",
        value: analyzerData?.propertyInformation?.other_property_type,
        render: "simple",
      }
    : {};

  const propertyInformation = [
    {
      item: "Property Type",
      value:
        propertyType[+analyzerData.propertyInformation?.property_type] || "N/A",
      render: "simple",
    },
    otherPropertyType,
    {
      item: "Bedrooms",
      value: analyzerData.propertyInformation?.bedrooms || "N/A",
      render: "simple",
    },
    {
      item: "Bathrooms",
      value: analyzerData.propertyInformation?.bathrooms || "N/A",
      render: "simple",
    },
    {
      item: "Pre-existing Livable Square Footage",
      value: analyzerData.propertyInformation?.pre_existing_livable_sqft,
      render: "sqft",
    },
    {
      item: "Additional Square Footage",
      value: analyzerData.propertyInformation?.additional_sqrft,
      render: "sqft",
    },
    {
      item: "Garage",
      value: analyzerData.propertyInformation?.garage,
      render: "simple",
    },
    {
      item: "Lot",
      value: analyzerData.propertyInformation?.lot_size,
      render: "simple",
    },
    {
      item: "Year Built",
      value: new Date(
        analyzerData.propertyInformation?.year_of_construction
      ).getFullYear(),
      render: "simple",
    },
  ];
  const remodelCost = {
    general: [
      {
        item: "Estimated Remodel Cost per Sqft",
        value: analyzerData?.remodelCosts?.est_remodel_cost_psqft,
        render: "persqft",
      },
      {
        item: "Estimated New Construction Cost per Sqft",
        value: analyzerData?.remodelCosts?.est_new_construction_cost_psqft,
        render: "persqft",
      },
      {
        item: "Pre-existing Livable Square Footage",
        value: analyzerData?.propertyInformation?.pre_existing_livable_sqft,
        render: "sqft",
      },
      {
        item: "Additional Square Footage",
        value: analyzerData?.propertyInformation?.additional_sqrft,
        render: "sqft",
      },
      {
        item: "Estimated Remodel Cost",
        value: resultData?.remodel_cost?.estimated_remodel_cost,
        render: "dollar",
      },
      {
        item: "Estimated New Construction Cost",
        value: resultData?.remodel_cost?.estimated_new_construction_cost,
        render: "dollar",
      },
    ],
    aestheticItems: analyzerData?.remodelCosts?.aesthetic_items
      ? analyzerData?.remodelCosts?.aesthetic_items.map((element, index) => {
          return {
            ...element,
            render: "dollar",
            item: `${index + 1}. ${element.item} `,
          };
        })
      : [],
    nonAestheticItems: analyzerData?.remodelCosts?.non_aesthetic_items
      ? analyzerData?.remodelCosts?.non_aesthetic_items.map(
          (element, index) => {
            return {
              ...element,
              render: "dollar",
              item: `${index + 1}. ${element.item} `,
            };
          }
        )
      : [],
  };
  const dealFinance =
    +dealFinanceOption === 1
      ? {
          primaryHomeLoan:
            +analyzerData?.financeOptions?.primary_loan_type === 0 ||
            +analyzerData?.financeOptions?.primary_sub_loan_type === 0
              ? getPrimaryConventional({ resultData, analyzerData })
              : getPrimaryMortgage({ resultData, analyzerData }),
          primaryHeader: "Primary Loan for Home",
          secondaryRemodelLoan:
            +analyzerData?.financeOptions?.secondary_loan_type === 0 ||
            +analyzerData?.financeOptions?.secondary_sub_loan_type === 0
              ? getSecondaryConventional({ resultData, analyzerData })
              : getSecondaryMortgage({ resultData, analyzerData }),
          secondaryHeader: "Secondary Remodel Loan",
        }
      : getRestOfDealFinancesBuyAndRent({
          analyzerData,
          resultData,
          dealFinanceOption,
          typeId: 6,
        });

  return {
    loading,
    listingArray,
    propertyInformation,
    dealFinance,
    propertyPermitAndFees,
    monthlyTaxPayment,
    remodelCost,
    frontendClosingCost,
    backendClosingCost,
    carryingCost,
    operationalExpenses,
    otherExpenses,
    estResaleValue,
    rentalIncome,
    capitalizationRate,
    netProfit,
    investorsProfit,
    resultData,
    fetchResult,
    analyzerData,
    user,
    dealType,
    summary,
  };
}

export default useProformaData6;
