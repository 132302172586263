import { Circle } from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  Divider,
  Grid,
  InputAdornment,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Carousel } from "react-responsive-carousel";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { GetDetails } from "../actions";
import PlacesSearch from "../components/PlacesSearch/PlacesSearch";
import ReadingCards from "../components/ReadingCards/ReadingCards";
import SEO from "../components/SEO";
import { ReactComponent as DealAnalyzer } from "../images/dealAnalyzer.svg";
import { ReactComponent as Farm } from "../images/farm.svg";
import HomeD1 from "../images/home1.webp";
import HomeD2 from "../images/home2.webp";
import HomeD3 from "../images/home3.webp";
import HomeD4 from "../images/home4.webp";
import Home1 from "../images/homepageImage1.webp";
import HomeSearch from "../images/homesearchback.webp";
import { ReactComponent as Premier } from "../images/premier.svg";
import { ReactComponent as Report } from "../images/report.svg";
import Slider1 from "../images/slider1.webp";
import Slider2 from "../images/slider2.webp";
import Slider3 from "../images/slider3.webp";
import Slider4 from "../images/slider4.webp";
import Slider5 from "../images/slider5.webp";
import aSlider1 from "../images/testimonies/image10.png";
import aSlider2 from "../images/testimonies/image11.png";
import aSlider3 from "../images/testimonies/image12.png";
import aSlider4 from "../images/testimonies/image14.png";
import aSlider5 from "../images/testimonies/image15.png";
import facebookSlider1 from "../images/testimonies/imagefacebook16.png";
import facebookSlider2 from "../images/testimonies/imagefacebook17.png";
import facebookSlider3 from "../images/testimonies/imagefacebook18.png";
import facebookSlider4 from "../images/testimonies/imagefacebook19.png";
import facebookSlider5 from "../images/testimonies/imagefacebook20.png";
import facebookSlider6 from "../images/testimonies/imagefacebook21.png";
import "./Homepage/homepage.scss";

const list = [
  {
    title: "AGENT & INVESTOR DEAL ANALYZER",
    Component: DealAnalyzer,
    subText:
      "Agents & Investors can Analyze Real Estate Deals within minutes Remodels, Flips, New Constructions, & Rentals",
    link: "/advertise/advertise-deal",
  },
  {
    title: "PREMIER AGENT COMBO",
    Component: Farm,
    subText:
      "Brand Yourself & Take control of your Neighborhoods & Farm Areas for Sellers",
    link: "/advertise/advertise-farm",
  },
  {
    title: "PREMIER AGENT BASIC",
    Component: Premier,
    subText:
      "Top Notch Web Presence with a Custom Premier Agent Web Page receive leads from BPO Homes, Seller NOD’s, Buyer & Seller Partner Agent Leads",
    link: "/advertise/advertise-premier",
  },
  {
    title: "BPO REPORT AGENT",
    Component: Report,
    subText: `100% FREE Platform. Earn $$$ writing BPO Reports.
  At BPO Homes we have streamlined the BPO Reporting process.
  Create BPO Reports within minutes.
  `,
    link: "/advertise/advertise-report",
  },
];

const images = [
  { id: 1, image: aSlider1 },
  { id: 11, image: facebookSlider1 },
  { id: 2, image: Slider1 },
  { id: 3, image: aSlider2 },
  { id: 12, image: facebookSlider2 },
  { id: 4, image: Slider2 },
  { id: 5, image: aSlider3 },
  { id: 13, image: facebookSlider3 },
  { id: 6, image: Slider3 },
  { id: 7, image: aSlider4 },
  { id: 14, image: facebookSlider4 },
  { id: 8, image: Slider4 },
  { id: 9, image: aSlider5 },
  { id: 15, image: facebookSlider5 },
  { id: 10, image: Slider5 },
  { id: 16, image: facebookSlider6 },
];

const useStyles = makeStyles((theme) => ({
  bpo: {
    // fontFamily: 'Roboto',
    backgroundColor: "#f5f5f5",
    padding: "3rem 1rem",
  },
  primarize: {
    backgroundColor: theme.primaryColor,
  },
  timelineBox: {
    display: "flex",
    flexDirection: "column",
    gap: "15px",
    marginBottom: "10px",
    alignItems: "flex-start",
    [theme.breakpoints.down("md")]: {
      marginLeft: "20px",
    },
  },
  dot: {
    position: "relative",
    // fontFamily:'Roboto',
    "&::before": {
      backgroundColor: theme.primaryColor,
      width: "10px",
      height: "10px",
      borderRadius: "50%",
      content: '""',
      position: "absolute",
      top: "50%",
      left: "-15%",
      transform: "translate(-50%, -50%)",
    },
  },
  button: {
    textTransform: "capitalize",
  },
  yellowDivider: {
    width: "100px",
    margin: "auto",
    height: "5px",
    borderRadius: "5px",
    backgroundColor: theme.primary,
  },
  titler: {
    marginTop: "3rem",
  },
  padder: {
    marginTop: "1rem",
    paddingLeft: "9rem",
    paddingRight: "1rem",
    [theme.breakpoints.down("lg")]: {
      paddingLeft: "10px",
    },
  },
  lightDivider: {
    margin: "3rem auto",
    width: "80%",
    borderBottomWidth: 1,
    backgroundColor: "#1378a536",
  },
  middler: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
  },
  cardPad: {
    padding: "0 1rem",
    marginTop: "4rem",
    [theme.breakpoints.up("xl")]: {
      padding: "0 15%",
    },
  },
  searchbox: {
    backgroundImage: `url(${HomeSearch})`,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "1rem",
    padding: "4rem 0",
    backgroundPosition: "center",
    [theme.breakpoints.down("md")]: {
      padding: "2rem 0",
    },
  },
  input: {
    backgroundColor: "white",
    borderRadius: "5px",
    width: "100%",
  },
  placesBox: {
    maxWidth: "700px",
    width: "80%",
    margin: "auto",
  },
}));

const deal = [
  "Analyze a real estate deal within minutes, Remodels, Flips, New Constructions & Rentals",
  "Generate property ProForma Reports to share with other investors or clients",
];

const realEstate = [
  "Complementary Digital Business Card provided to our Premier Basic Agents & Premier Combo Agents",
  "Premier Basic Agents & Premier Combo Agents highlighted in 5 cities of Agent's choice",
  "Premier Basic Agents & Premier Combo Agents receive a custom webpage",
  "Premier Basic Agents & Premier Combo Agents receive full access to our Custom Listing Page Builder. Promoting Active and Sold Listings",
];

const agents = [
  "Agents earn compensation for creating BPO Reports",
  "Strategic Marketing to Farm Areas",
];

const reports = [
  "Agents gain access to BPO Homes Report Agent. Generate Leads from Buyers & Sellers who are requesting BPO Reports NOW!",
  "Generate Proforma Reports. Proforma Reports show the performance of a real estate investment based on the inputs of the Agent, fully branded by the Agent",
];

function Homepager(props) {
  const { GetDetails } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const navigate = useNavigate();
  const [place, setPlace] = useState("");
  const path = useLocation().search;

  useEffect(() => {
    const fetchUser = async () => {
      if (path && path.startsWith("?uta")) {
        const atoken = path.split("&")[0].split("=")[1];
        const rtoken = path.split("&")[1].split("=")[1];
        const redirectLink = path.split("&")[2].split("=")[1].toString();
        localStorage.clear();
        localStorage.setItem("atoken", atoken);
        localStorage.setItem("rtoken", rtoken);
        await GetDetails();
        dispatch({
          type: "GET_TOKEN",
          payload: { refresh: rtoken, access: atoken },
        });
        navigate(redirectLink);
      }
    };
    fetchUser();
  }, [GetDetails, path, dispatch, navigate]);

  const onReportClick = () => {
    if (!place) {
      toast.warn("Please enter a valid address");
      return;
    }
    localStorage.setItem("propertyAddress", place);
    navigate(`/order-bpo-report?address=${place}`);
  };

  const onEnter = (e) => {
    if (e.key === "Enter") {
      onReportClick();
    }
  };
  return (
    <>
      <SEO>
        <title>
          BPOTech: Real Estate, Homes for Sale, Trusted Agents, Deal Analyzer
        </title>
        <meta
          name="og:title"
          content="BPOTech: Real Estate, Homes for Sale, Trusted Agents, Deal Analyzer"
        />
        <meta
          name="description"
          content="Find your dream home with BPOTech. Browse real estate listings, connect with trusted local agents, and use our deal analyzer tool to make informed decisions. Start your journey today."
        />
        <meta
          name="og:description"
          content="Find your dream home with BPOTech. Browse real estate listings, connect with trusted local agents, and use our deal analyzer tool to make informed decisions. Start your journey today."
        />
        <meta name="og:image" content={`${images[1].image}`} />
      </SEO>
      <Box>
        <Carousel
          autoPlay={true}
          infiniteLoop={true}
          showThumbs={false}
          stopOnHover={false}
          transitionTime={1000}
          interval={10000}
          showArrows={false}
          useKeyboardArrows={false}
          showStatus={false}
          showIndicators={false}
          autoFocus={true}
          animationHandler={"slide"}
          swipeable={true}
        >
          {images.map((image) => (
            <Box key={image.id}>
              <img src={image.image} alt="slider" style={{ width: "100%" }} />
            </Box>
          ))}
        </Carousel>
        <Box className={classes.searchbox}>
          <Typography paragraph textAlign="center" color="white">
            “Looking for a BPO Report? Buying or Selling? Enter your address
            here”
          </Typography>
          <Box className={classes.placesBox}>
            <PlacesSearch
              mui={true}
              value={place}
              onChange={setPlace}
              className={classes.input}
              name="place"
              placeholder="Enter an address for a BPO Report"
              onKeyDown={onEnter}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon
                      color="primary"
                      sx={{ cursor: "pointer" }}
                      onClick={onReportClick}
                    />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Box>
        <Box my="3rem">
          <Grid container spacing={2} px="2rem">
            {list.map(({ title, Component, subText, link }) => (
              <Grid key={title} item lg={3} md={6} sm={12} xs={12}>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  gap="1rem"
                  sx={{ cursor: "pointer", height: "100%" }}
                  component={Link}
                  to={link}
                >
                  <Component />
                  <Typography sx={{ textAlign: "center" }}>{title}</Typography>
                  <Typography
                    textAlign="center"
                    fontWeight="300"
                    fontSize="14px"
                  >
                    {subText}
                  </Typography>
                  <Button
                    variant="contained"
                    color="secondary"
                    disableElevation
                    sx={{
                      textTransform: "capitalize",
                      color: "white",
                      marginTop: "auto",
                    }}
                  >
                    Learn more
                  </Button>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
        <Divider
          variant="middle"
          className={classes.lightDivider}
          sx={{
            backgroundColor: "#FFAA01 !important",
            width: "200px !important",
            height: "3px",
            borderRadius: "5px",
          }}
        />

        <Box className={classes.bpo}>
          <Grid container spacing={4}>
            <Grid item lg={12} textAlign="center" width="100%">
              <Typography variant="h6" fontWeight={700}>
                Don't Underlist. Don't Overpay. Get A BPO Report Today.
              </Typography>
              <Typography color="secondary" variant="body2" fontSize={14}>
                Your Local Expert Agents Ready To Give You A BPO Report Now!
              </Typography>
            </Grid>
            <Grid item lg={6} md={12} sm={12} xs={12} sx={{ width: "100%" }}>
              <img
                src={Home1}
                alt="Tte"
                style={{ maxWidth: "80%", margin: "0 auto", display: "block" }}
              />
            </Grid>
            <Grid item lg={6} md={12} sm={12} xs={12}>
              <Grid container spacing={4}>
                <Grid item lg={12} md={6} sm={12} xs={12}>
                  <Box
                    className={classes.timelineBox}
                    sx={{ marginTop: { lg: "20px", md: 0 } }}
                  >
                    <Typography
                      variant="h6"
                      fontWeight={700}
                      className={classes.dot}
                    >
                      BUYERS
                    </Typography>
                    <Typography variant="body1" fontWeight={300}>
                      Buying a property? Get a BPO Report from the Local expert
                      agents.
                    </Typography>
                    <Button
                      variant="contained"
                      color="secondary"
                      href="/order-bpo-report"
                      className={classes.button}
                    >
                      Request BPO
                    </Button>
                  </Box>
                </Grid>
                <Grid item lg={12} md={6} sm={12} xs={12}>
                  <Box className={classes.timelineBox}>
                    <Typography
                      variant="h6"
                      fontWeight={700}
                      className={classes.dot}
                    >
                      SELLERS
                    </Typography>
                    <Typography variant="body1" fontWeight={300}>
                      Don't UNDERLIST! BPOs eliminate the RISKS
                    </Typography>
                    <Button
                      variant="contained"
                      color="secondary"
                      href="/order-bpo-report"
                      className={classes.button}
                    >
                      Request BPO
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Box className={classes.titler}>
          {/* <Typography variant='h6' fontWeight={700} textAlign='center'>
						Title Goes here
					</Typography> */}
          <Divider
            className={classes.yellowDivider}
            sx={{ marginBottom: "3rem !important" }}
          />
          <Grid container spacing={2}>
            <Grid
              item
              lg={6}
              md={6}
              sm={12}
              xs={12}
              className={classes.middler}
            >
              <img src={HomeD1} alt="like a pro" style={{ maxWidth: "100%" }} />
            </Grid>
            <Grid
              item
              lg={6}
              md={6}
              sm={12}
              xs={12}
              pl={1}
              className={classes.middler}
            >
              <Typography
                paragraph
                sx={{ fontWeight: "500", paddingLeft: "10px" }}
              >
                Evaluate a deal like a PRO
              </Typography>
              {deal.map((item, index) => (
                <ListItem sx={{ paddingLeft: "10px" }} key={item}>
                  <ListItemIcon
                    sx={{
                      minWidth: "35px",
                    }}
                  >
                    <Circle
                      sx={{
                        fontSize: ".7rem",
                      }}
                      color="primary"
                    />
                  </ListItemIcon>
                  <ListItemText
                    sx={{
                      fontSize: "15px !important",
                      "&>span": { fontSize: "inherit" },
                    }}
                  >
                    {item}
                  </ListItemText>
                </ListItem>
              ))}
              <Button
                variant="outlined"
                color="secondary"
                href="/analytics"
                sx={{
                  marginTop: "3rem",
                  marginLeft: "10px",
                  textTransform: "none",
                }}
              >
                Learn More
              </Button>
            </Grid>
          </Grid>
          <Divider
            light={true}
            variant="middle"
            className={classes.lightDivider}
          />
          <Grid container spacing={2}>
            <Grid
              item
              lg={6}
              md={6}
              sm={12}
              xs={12}
              pl={1}
              className={classes.middler}
            >
              <Box className={classes.padder}>
                <Typography
                  paragraph
                  sx={{ fontWeight: "500", paddingLeft: "10px" }}
                >
                  Find the right real estate solution for you
                </Typography>
                {realEstate.map((item) => (
                  <ListItem sx={{ paddingLeft: "10px" }} key={item}>
                    <ListItemIcon
                      sx={{
                        minWidth: "35px",
                      }}
                    >
                      <Circle
                        sx={{
                          fontSize: ".7rem",
                        }}
                        color="primary"
                      />
                    </ListItemIcon>
                    <ListItemText
                      sx={{
                        fontSize: "15px !important",
                        "&>span": { fontSize: "inherit" },
                      }}
                    >
                      {item}
                    </ListItemText>
                  </ListItem>
                ))}
                <Button
                  variant="outlined"
                  color="secondary"
                  href="/advertise/advertise-premier"
                  sx={{
                    marginTop: "2rem",
                    marginLeft: "10px",
                    textTransform: "none",
                  }}
                >
                  Learn More
                </Button>
              </Box>
            </Grid>
            <Grid
              item
              lg={6}
              md={6}
              sm={12}
              xs={12}
              className={classes.middler}
            >
              <img src={HomeD2} alt="like a pro" style={{ maxWidth: "100%" }} />
            </Grid>
          </Grid>
          <Divider
            light={true}
            variant="middle"
            className={classes.lightDivider}
          />

          <Grid container spacing={2}>
            <Grid
              item
              lg={6}
              md={6}
              sm={12}
              xs={12}
              className={classes.middler}
            >
              <img src={HomeD3} alt="like a pro" style={{ maxWidth: "100%" }} />
            </Grid>
            <Grid
              item
              lg={6}
              md={6}
              sm={12}
              xs={12}
              pl={1}
              className={classes.middler}
            >
              <Typography
                paragraph
                sx={{ fontWeight: "500", paddingLeft: "10px" }}
              >
                Your Marketing Pays for Itself
              </Typography>
              {agents.map((item, index) => (
                <ListItem sx={{ paddingLeft: "10px" }} key={item}>
                  <ListItemIcon
                    sx={{
                      minWidth: "35px",
                    }}
                  >
                    <Circle
                      sx={{
                        fontSize: ".7rem",
                      }}
                      color="primary"
                    />
                  </ListItemIcon>
                  <ListItemText
                    sx={{
                      fontSize: "15px !important",
                      "&>span": { fontSize: "inherit" },
                    }}
                  >
                    {item}
                  </ListItemText>
                </ListItem>
              ))}
              <Button
                variant="outlined"
                color="secondary"
                href="/advertise/advertise-farm"
                sx={{
                  marginTop: "3rem",
                  marginLeft: "10px",
                  textTransform: "none",
                }}
              >
                Learn More
              </Button>
            </Grid>
          </Grid>
          <Divider
            light={true}
            variant="middle"
            className={classes.lightDivider}
          />
          <Grid container spacing={2}>
            <Grid
              item
              lg={6}
              md={6}
              sm={12}
              xs={12}
              pl={1}
              className={classes.middler}
            >
              <Box className={classes.padder}>
                <Typography
                  paragraph
                  sx={{ fontWeight: "500", paddingLeft: "10px" }}
                >
                  Generate BPO Reports & ProForma Reports
                </Typography>
                {reports.map((item) => (
                  <ListItem sx={{ paddingLeft: "10px" }} key={item}>
                    <ListItemIcon
                      sx={{
                        minWidth: "35px",
                      }}
                    >
                      <Circle
                        sx={{
                          fontSize: ".7rem",
                        }}
                        color="primary"
                      />
                    </ListItemIcon>
                    <ListItemText
                      sx={{
                        fontSize: "15px !important",
                        "&>span": { fontSize: "inherit" },
                      }}
                    >
                      {item}
                    </ListItemText>
                  </ListItem>
                ))}
                <Button
                  variant="outlined"
                  color="secondary"
                  href="/advertise/advertise-report"
                  sx={{
                    marginTop: "2rem",
                    marginLeft: "10px",
                    textTransform: "none",
                  }}
                >
                  Learn More
                </Button>
              </Box>
            </Grid>
            <Grid
              item
              lg={6}
              md={6}
              sm={12}
              xs={12}
              className={classes.middler}
            >
              <img src={HomeD4} alt="like a pro" style={{ maxWidth: "100%" }} />
            </Grid>
          </Grid>
        </Box>
        <Box className={classes.cardPad}>
          <Typography paragraph sx={{ fontWeight: "700" }}>
            Read also:
          </Typography>
          <ReadingCards />
        </Box>
      </Box>
    </>
  );
}

const mapStateToProps = (state) => {
  return state;
};

export default connect(mapStateToProps, { GetDetails })(Homepager);
