import React, { useEffect } from 'react'
import { Box, Typography ,OutlinedInput, FormControl, FormLabel } from '@mui/material'

function ContactInfo({handler,data, handleButtonDisableControl}) {

    const handleChange = (e)=>{
        handler({[e.target.name]:e.target.value})
    }

    useEffect(()=>{
        function controlSubmitButton(){
            if(!(data?.contact_email && data?.contact_phone && data?.contact_name) ){
                handleButtonDisableControl({buttonType:"submit", state:true})
            }else{
                handleButtonDisableControl({buttonType:"submit", state:false})

            }
        }
        controlSubmitButton()

      // NOTE: Run effect once on component mount, please
      // recheck dependencies if effect is updated.
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[data?.contact_email, data?.contact_phone, data?.contact_name])

    
  return (
    <Box sx={{width:"50%",minWidth:"18.75rem"}}>
        <Box >
            <Typography></Typography>

        </Box>
        <Box>
        {details.map(element=>(
            <Box>
            {
            <Box sx={{marginTop:"1.25rem"}}>
                <FormControl sx={{width:"100%"}}>
                <FormLabel required sx={{fontSize:"1.2rem",fontWeight:500}}>
                    {element.label}
                </FormLabel>
                <OutlinedInput
                fullWidth
                margin="normal"
                name={element.name}
                onChange = {handleChange}
                value = {data?.[element.name] }
                placeholder={element.placeholder}
                renderValue={value => value}
                inputProps={{ style: { fontSize: "1rem" } }}
            />
                </FormControl>
    
            </Box>
            }
        </Box>
        ))}
        </Box>


    </Box>
  )
}

export default ContactInfo

const details = [
  { label: "NAME", name: "contact_name", placeholder:"Your name" },
  { label: "PHONE", name: "contact_phone", placeholder:"Your phone number" },
  { label: "EMAIL", name: "contact_email", placeholder:'Your email' },
];