import { createTheme, responsiveFontSizes } from '@mui/material/styles';

let themePDF = createTheme({
  status: {
    success: "#42B546",
  },
  components: {
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {color:"red"}
      }
    }
  },
  palette: {
    // mode: "dark",
    primary: {
      main: "#1378A5",
      inactive:"#E6E6E6",
      menuVerticalBar:"#C4C4C4",
      inactiveMenuColor:"#818181",
      menuHover: "#E5E4E2",
      darker:"#000000",
      lightest:"#C4c4c4",
      offerButton:"#FFAA01",
      extraLight:"rgb(229, 228, 226, 0.4)"

    //   main_text: "#989899",
    //   main_text_active: "#42B546",
    //   main_background_color: "#000000",
    //   main_mid_dark: "#2B2929",
    //   main_heading_color: "#fff",
    //   pageHeadingTabColor: "#686868",
    //   pageHeadingBorderBottom: "#82828280",
    //   navHover: "#2B2929",
    },
    secondary:{
      main:"#000"
    }
  },
  typography: {    
    fontFamily: ["Lato",'Roboto', "Helvetica"].join(","),
    htmlFontSize:32,
    fontSize:10,
    h1:{fontSize:"16px"},
    h2:{},
    h3:{fontSize:"16px"},
    h4:{fontSize:"12px"},
    h5:{fontSize:"8px"},
    h6:{fontSize:"6px"},
    dealAnalyzerHeader: {
      fontSize: "24px",
      fontWeight: 700,
      lineHeight: "28px",
    },
    analyzerType: {
      fontSize: "24px",
      fontWeight: 600,
      lineHeight: "36px",
    },
    updateTime: {
      fontSize: "20px",
      fontWeight: 400,
      lineHeight: "21px",
    },
    menuItem: {
      fontSize: "1.3rem",
      fontWeight: 500,
      lineHeight: "28px",
    },
    menuItemActive: {
      fontSize: "1.6rem",
      fontWeight: 700,
      lineHeight: "1.8rem",
    },
    buttonFont: {
      fontSize: "1.25rem",
      fontWeight: "500",
      lineHeight: "20px",
    },
    inputLabelFont: {
      fontSize: "1.25rem",
      fontWeight: 500,
      lineHeight: "26px",
    },
    radioLabelFont: {
      fontSize: "1rem",
      fontWeight: "400",
      lineHeight: "22px",
    },
    adornment: {
      fontSize: "16px",
      fontWeight: "700",
      lineHeight: "28px",
      color:"#000"
    },
    heading: {
      fontSize: "24px",
      fontWeight: 700,
      lineHeight: "28px",
    },
    toggleText: {
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "16px",
    },
    toolTip: {
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "20px",
    },
    toolTipHeader: {
      fontSize: "16px",
      fontWeight: 600,
      lineHeight: "20px",
      textTransform:"capitalize"
    },
    analyzePropertyHeader: {
      fontSize: "60px",
      fontFamily:"Roboto",
      fontWeight: 600,
      lineHeight: "20px",
      textTransform:"capitalize",
      color:"white"
    },
    deals: {
      fontSize: "2.40px",
      fontWeight: 500,
      lineHeight: "28px",
      textTransform:"capitalize",
    },
    dealAnalyzerh1:{
      fontSize: "50px",
      fontWeight: 700,
      lineHeight: "60px",
      textTransform:"capitalize",
      color:"white"
    },
    dealAnalyzerh3:{
      fontSize: "36px",
      fontWeight: 500,
      lineHeight: "43px",
      textTransform:"capitalize",
      color:"white"
    },
    notice:{
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "16px",
    },
    submenuActive:{
      fontSize: "16px",
      fontWeight: 600,
      lineHeight: "14px",
    },
    submenuInactive:{
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "19px",
    },
    tableHeader:{
      fontSize: "18px",
      fontWeight: 700,
      lineHeight: "28px",
    },
    enhancedTableHeader:{
      fontSize: "24px",
      fontWeight: 700,
      lineHeight: "28px",
    },
    tableRow:{
      fontSize: "20px",
      fontWeight: 400,
      lineHeight: "24px",
    },
    enhancedTableRow:{
      fontSize: "20px",
      fontWeight: 600,
      lineHeight: "40px",
    },
    printEnhancedTableRow:{
      fontSize: "20px",
      fontWeight: 600,
      lineHeight: "24px",
    },
    summaryTableRow:{
      fontSize: "18px",
      fontWeight: 500,
      lineHeight: "25px",
    },
    printSummaryTableRow:{
      fontSize: "16px",
      fontWeight: 500,
      lineHeight: "20px",
    },
    summaryTableValue:{
      fontSize: "18px",
      fontWeight: 600,
      lineHeight: "22px",
    },
    printSummaryTableValue:{
      fontSize: "18px",
      fontWeight: 600,
      lineHeight: "30px",
    },
    modalTitle:{
      fontSize: "18px",
      fontWeight: 600,
      lineHeight: "20px",
    },
    summaryContainerTitle: {
      fontSize: "20px",
      fontWeight: "700",
      lineHeight: "28px",
      color:"#fff"
    },
    printSummaryContainerTitle: {
      fontSize: "20px",
      fontWeight: "700",
      lineHeight: "28px",
      color:"#fff"
    },
    proformaAddress:{
      fontSize: "18px",
      fontWeight: 600,
      lineHeight: "24px",
    },
    proformaReportHeader:{
      fontSize: "20px",
      fontWeight: 700,
      lineHeight: "24px",
    },
   noResult:{
      fontSize: "18px",
      fontWeight: 500,
      lineHeight: "24px",
    },
   tableInactive:{
      fontSize: "18px",
      fontWeight: 500,
      lineHeight: "20px",
      color:"#c4c4c4"
    },
   printFooter:{
      fontSize: "20px",
      fontWeight: 500,
      lineHeight: "24px",
      color:"#b4b4b4"
    },
    disclaimer:{
      fontsize:"6px",
      lineHeight:"14.4px",
      fontWeight:400,
    },
    disclaimerHeader:{
      fontSize:"14px",
      lineHeight:"14.4px",
      fontWeight:700
    },
    landingIconText:{
      fontSize:"18px",
      fontWeight:500,
      lineHeight:"20px",

    },
    landingMain:{
      fontSize:"32px",
      fontWeight:700,
      lineHeight:"36px",
    },
    landingMid:{
      fontSize:"20px",
      fontWeight:700,
      lineHeight:"30px",
      color:"#1378A5"

    },
    landingSmall:{
      fontSize:"16px",
      fontWeight:500,
      lineHeight:"20px",
      color:"#1378A5"

    },
    dealCost:{
      fontSize:"32px",
      fontWeight:700,
      lineHeight:"38px",
    },
    dealCostUnit:{
      fontSize:"16px",
      fontWeight:400,
      lineHeight:"1.90px",
    },
    dealCta:{
      fontSize:"18px",
      fontWeight:400,
      lineHeight:"24px",
    },
    dealCta2:{
      fontSize:"16px",
      fontWeight:300,
      lineHeight:"19px",
    },
    smallInactive:{
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "19px",
      color:"grey"
    },
    payTel:{
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "20px",
    },
    payOpenTimes:{
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "20px",
      color:"grey"

    }
  },
});
themePDF = responsiveFontSizes(themePDF);


export default themePDF;