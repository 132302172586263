import {
  FormControl,
  InputAdornment,
  OutlinedInput,
  Paper,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import StyledLabel from "../atomicComponents/StyledLabel";
import { isNaNChecker } from "../localCalcutions/utils/genericFunctions";
import commaSeparate from "./commaSeparate";
export const ShowDetails = ({
  purchasePrice = 0,
  totalMonthlyPayment = 0,
  termsOfLoan = 0,
  pmiPayment,
}) => {
  const [show, setShow] = useState(false);

  const monthlyPrincipal = isNaNChecker(
    (purchasePrice / (termsOfLoan * 12)).toFixed(2)
  );
  const monthlyInterestOnly = isNaNChecker(
    (+totalMonthlyPayment - +monthlyPrincipal).toFixed(2)
  );

  return (
    <>
      <Paper elevation={0} sx={{ display: "flex", alignItems: "center" }}>
        <Typography
          onClick={() => setShow((prev) => !prev)}
          color={"primary"}
          sx={{ cursor: "pointer", fontSize: "1rem", alignItems: "center" }}
        >
          View payment breakdown
        </Typography>
        {!show ? (
          <KeyboardArrowRightIcon fontSize="large" color="primary" />
        ) : (
          <KeyboardArrowDownIcon fontSize="large" color="primary" />
        )}
      </Paper>
      {show && (
        <Paper
          elevation={0}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <FormControl
            sx={{
              minWidth: "20rem",
              marginTop: "10px",
            }}
          >
            <StyledLabel label={"Principal Paid per Month"} />
            <OutlinedInput
              fullWidth
              sx={{
                backgroundColor: "#E5E4E2",
              }}
              disabled={true}
              value={commaSeparate(monthlyPrincipal)}
              margin="normal"
              inputProps={{ style: { fontSize: "1rem" } }}
              startAdornment={
                <InputAdornment position="start">
                  {" "}
                  <Typography variant="adornment" color="secondary">
                    {" "}
                    $
                  </Typography>
                </InputAdornment>
              }
            />
          </FormControl>
          <FormControl
            sx={{
              minWidth: "20rem",
              marginTop: "10px",
            }}
          >
            {" "}
            <StyledLabel label={"Interest Paid per Month"} />
            <OutlinedInput
              disabled={true}
              fullWidth
              sx={{
                backgroundColor: "#E5E4E2",
              }}
              value={commaSeparate(monthlyInterestOnly)}
              margin="normal"
              inputProps={{ style: { fontSize: "1rem" } }}
              startAdornment={
                <InputAdornment position="start">
                  {" "}
                  <Typography variant="adornment" color="secondary">
                    {" "}
                    $
                  </Typography>
                </InputAdornment>
              }
            />
          </FormControl>
          {pmiPayment && (
            <FormControl
              sx={{
                minWidth: "20rem",
                marginTop: "10px",
              }}
            >
              {" "}
              <StyledLabel label={"Monthly PMI Amount"} />
              <OutlinedInput
                disabled={true}
                fullWidth
                sx={{
                  backgroundColor: "#E5E4E2",
                }}
                value={commaSeparate(+pmiPayment?.toFixed(2))}
                margin="normal"
                inputProps={{ style: { fontSize: "1rem" } }}
                startAdornment={
                  <InputAdornment position="start">
                    {" "}
                    <Typography variant="adornment" color="secondary">
                      {" "}
                      $
                    </Typography>
                  </InputAdornment>
                }
              />
            </FormControl>
          )}
        </Paper>
      )}
    </>
  );
};
