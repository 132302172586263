import { Box, FormControl,InputAdornment,OutlinedInput, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import EnhancedRadioButtonGroup from '../atomicComponents/EnhancedRadioButtonGroup'
import MonthYearSelector from '../atomicComponents/MonthYearSelector'
import StyledLabel from '../atomicComponents/StyledLabel'

const QuestionnaireRentalIncome = ({handler,data}) => {
    const showRentalIncome = +data?.rental_income
    const specialHandler = (e)=>{
        handler({[e.target.name]:e.target.value})
    }
  useEffect(()=>{

    !data?.rental_income && handler({rental_income:0})
      // NOTE: Run effect once on component mount, please
      // recheck dependencies if effect is updated.
      // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  return (
    <Box>
      <Box sx={{ marginTop: "1.875rem" }}>
        <MonthYearSelector
          data={data}
          label="How many months will you own the property this calender year?"
          handler={handler}
          name="owning_months"
        />
      </Box>
      <Box sx={{ display: "flex", marginTop: "1.875rem" }}>
        <Box sx={{ margin: "0.3rem 1.875rem 0 0" }}>
          <StyledLabel label="Is there rental income?" />
        </Box>
        <EnhancedRadioButtonGroup
          options={options}
          name="rental_income"
          choice={data?.rental_income}
          specialHandler={specialHandler}
          styles={{ display: "flex",flexDirection:"row-reverse" }}
        />
      </Box>
      {!!showRentalIncome && (
        <Box sx={{ width: "100%", marginTop: "10px" }}>
          <Box sx={{ marginBottom: "10px" }}>
            <StyledLabel label="What is the monthly rental income" />
          </Box>
          <FormControl sx={{ width: "100%" }}>
            <OutlinedInput
              fullWidth
              margin="normal"
              name={"monthly_rental_income"}
              onChange={specialHandler}
              value={data?.["monthly_rental_income"]}
              placeholder="0"
              type="number"
              renderValue={(value) => value}
              inputProps={{ style: { fontSize: "1rem" } }}
              startAdornment={
                <InputAdornment position="start">
                  {" "}
                  <Typography variant="adornment" color="secondary">
                    {" "}
                    $
                  </Typography>
                </InputAdornment>
              }
            />
          </FormControl>
        </Box>
      )}
    </Box>
  );
}
export default QuestionnaireRentalIncome

const options = [
    'No',
    'Yes'
]