import {
  FormControl,
  InputAdornment,
  Box,
  Typography,
  OutlinedInput,
} from "@mui/material";
import StyledLabel from "./StyledLabel";
import React from "react";
import useTheme from "@mui/material/styles/useTheme";
import HtmlToolTip from "./HtmlToolTip";
import commaSeparate from "../utils/commaSeparate";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import * as actionCreators from "../../../actions/dealAnalyzer/index";
import { useCallback } from "react";

const useStyle = (_theme) => {
  return {
    purchasePrice: {
      display: "flex",
      flexDirection: "column",
      marginTop: "1.25rem",
    },
  };
};

function LoanAmount({ label, name, loanAmount, greyed }) {
  const dispatch = useDispatch();

  const amountUpdator = useCallback(() => {
    const amount = {
      // Initialize the 'amount' object here

      localAmount: loanAmount,
    };
    dispatch(actionCreators.updateFinanceOptions(amount));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loanAmount]);

  //dispatches the local amount
  useEffect(() => {
    amountUpdator();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amountUpdator]);

  const theme = useTheme();
  const styles = useStyle(theme);
  return (
    <div>
      <Box sx={styles.purchasePrice}>
        <Box>
          <StyledLabel label={label} />
          <HtmlToolTip />
        </Box>
        <FormControl
          sx={{
            minWidth: "18.75rem",
            marginTop: "10px",
            backgroundColor: greyed ? "#E5E4E2" : "white",
          }}
        >
          <OutlinedInput
            fullWidth
            name={name}
            value={commaSeparate(loanAmount)}
            sx={{ "&:hover": { border: "none" } }}
            margin="normal"
            inputProps={{ style: { fontSize: "1rem" } }}
            startAdornment={
              <InputAdornment position="start">
                {" "}
                <Typography variant="adornment" color="secondary">
                  {" "}
                  $
                </Typography>
              </InputAdornment>
            }
          />
        </FormControl>
      </Box>
    </div>
  );
}

export default LoanAmount;
