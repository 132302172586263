export const carryingCostAmountFunc = (
  primaryPayment,
  primaryInterestOnlyLoan,
  PurchasePrice,
  remodelCost,
  primaryLoanType,
  primarySubLoanType,
  secondaryLoanSubType,
  secondaryInterestOnly,
  secondaryAmount,
  curryingMonths,
  secondaryLoanIsTrue,
  mainPrimaryLoanType,
  primaryMortgageAmount,
  secondaryMortageAmount,
  cashForHome,
  secondaryLoanType
) => {
  let amount = 0;
  if (primaryLoanType === 0 || undefined) {
    if (mainPrimaryLoanType && primarySubLoanType === 1) {
      amount = (primaryInterestOnlyLoan || 0) * curryingMonths;
    } else {
      amount = (primaryPayment || 0) * curryingMonths;
    }
  } else if (primaryLoanType === 1) {
    if (mainPrimaryLoanType && primarySubLoanType === 1) {
      amount = (primaryInterestOnlyLoan || 0) * curryingMonths;
    } else {
      amount = (primaryPayment || 0) * curryingMonths;
    }
  } else if (primaryLoanType === 2) {
    if (mainPrimaryLoanType && primarySubLoanType === 1) {
      amount = (primaryInterestOnlyLoan || 0) * curryingMonths;
    } else {
      amount = (primaryPayment || 0) * curryingMonths;
    }
  } else if (primaryLoanType === 3) {
    if (mainPrimaryLoanType && primarySubLoanType === 1) {
      amount = (primaryInterestOnlyLoan || 0) * curryingMonths || 0;
    } else {
      amount = (primaryPayment || 0) * curryingMonths;
    }
  } else if (primaryLoanType === 4) {
    amount = 0;
  }
  if (secondaryLoanIsTrue && primaryLoanType !== 4) {
    if (
      (primaryLoanType === 0 || undefined) &&
      secondaryLoanSubType === 1 &&
      secondaryLoanType === 1
    ) {
      amount += (secondaryInterestOnly || 0) * curryingMonths;
    } else {
      amount += (secondaryAmount || 0) * curryingMonths;
    }
  }

  return amount.toFixed(2);
};
