export const monthlyLoanCalc = (
  PLT,
  sPLT,
  SLT,
  localTotalMonthlyLoanAmount,
  primaryInterestOnlyLoan,
  secondaryMonthlyLoan,
  secondaryInterestOnly
) => {
  // PLT sub primary loan type
  // STL secondary Sub type

  let monthlyLoanPayment = 0;
  if (PLT === 1 && sPLT === 1) {
    monthlyLoanPayment = primaryInterestOnlyLoan;
  } else {
    monthlyLoanPayment = localTotalMonthlyLoanAmount;
  }
  if (SLT === 1) {
    monthlyLoanPayment += secondaryInterestOnly || 0;
  } else {
    monthlyLoanPayment += secondaryMonthlyLoan || 0;
  }
  //  console.log(PLT, sPLT, SLT, localTotalMonthlyLoanAmount, primaryInterestOnlyLoan, secondaryMonthlyLoan, secondaryInterestOnly)
  return monthlyLoanPayment;
};

export const propertyManagementFeeChecker = (PMFC, PMF, MTA) => {
  // PMFC property management fee checker
  // property management fee
  // monthly rental Income
  let amount = 0;
  if (PMFC === 1) {
    amount = (PMF / 100) * MTA;
  } else {
    amount = MTA;
  }
  return amount;
};
export const annualPropertyManagementFeeChecker = (
  rentalMonthsOrYear,
  rentalMonths,
  managementChecker,
  annualValue,
  monthlyRentalCome
) => {
  // PMFC property management fee checker
  // property management fee
  // monthly rental Income
  let date;
  if (rentalMonthsOrYear === 1) {
    date = rentalMonths * 12;
  } else {
    date = rentalMonths;
  }
  let amount = 0;
  if (managementChecker === 1) {
    amount = (annualValue / 100) * (date * monthlyRentalCome);
  } else {
    amount = annualValue;
  }
  return amount;
};

export const carryCostCalculator = (
  carryingCostAmount,
  monthlyHOA,
  monthlyHomeInsurance,
  monthlyTax,
  propertyManagementFee,
  annualMaintenance,
  miscellaneousCost,
  utilities,
  months
) => {
  let carryingCost = 0;

  carryingCost =
    (carryingCostAmount || 0) +
    (annualMaintenance / 12 || 0) * months +
    (utilities || 0) * months +
    (months * monthlyHOA || 0) +
    (months * propertyManagementFee || 0) +
    (months * monthlyHomeInsurance || 0) +
    (months * monthlyTax || 0);
  return carryingCost;
};
