import { PDFViewer, Page, Document, View, Text, StyleSheet, Image } from '@react-pdf/renderer';
import React from 'react';

const styles = StyleSheet.create({
	page: {
		padding: '20px',
		fontSize: '12px',
	},
	section: {
		marginBottom: '15px',
	},
	title: {
		fontSize: '30px',
		fontWeight: 'bold',
		textAlign: 'center',
		backgroundColor: '#F2F4F7',
		borderRadius: '12px',
		padding: '10px',
		marginBottom: '20px',
	},
	subtitle: {
		fontSize: '18px',
		borderBottom: '1px solid #EAECF0',
		paddingBottom: '5px',
		marginBottom: '10px',
		fontWeight: '400',
	},
	text: {
		fontSize: '12px',
		marginBottom: '5px',
	},
	messageBox: {
		border: '1px solid #EAECF0',
		borderRadius: '12px',
		padding: '10px',
		marginTop: '20px',
		marginBottom: '20px',
	},
});

const QuotePDF = ({
	content = {
		company_name: 'Alameda Roofing Service',
		address: '26 San Juan Rd, Watsonville, CA 95076, USA',
		icon_file: 'https://bpohomes-backend-testing.s3.amazonaws.com/professional_icons/istockphoto-524085051-612x612-min_EAOAhBB.jpg',
		formatted_phone_number: '(831) 476-2161',
		email: 'ccyamoah@gmail.com',
		name: 'Cyril Yamoah',
		phone_number: '12344',
		items: [
			{
				item: 'Asphalt Shingle Roof',
				description: '12',
				unit_price: '12.00',
				quantity: '12',
				amount: '144.00',
				show: true,
			},
			{
				item: 'Asphalt Shingle Roof',
				description: '12',
				unit_price: '12.00',
				quantity: '12',
				amount: '144.00',
				show: true,
			},
		],
		tax_rate: '',
		tax_amount: '0.00',
		sub_total: '144.00',
		Total: '144.00',
		message: 'a check to see how many people can',
	},
}) => {
	return (
		<PDFViewer width={'100%'} style={{ height: '100vh' }}>
			<Document>
				<Page size='A4' style={styles.page}>
					{/* Title */}
					<Text style={styles.title}>Quotation</Text>

					{/* Quotation From */}
					<View style={styles.section}>
						<Text style={styles.subtitle}>Quotation From</Text>
						<View style={{ flexDirection: 'row' }}>
							<View style={{ flex: 3, flexDirection: 'row', gap: '10px' }}>
								<Image
									style={{ width: '100px', height: '100px', borderRadius: '8px' }}
									src={{ uri: content.icon_file, method: 'GET', headers: { 'Cache-Control': 'no-cache' }, body: '' }}
								/>
								<View style={{ flex: 1, flexDirection: 'column', justifyContent: 'space-between' }}>
									<Text>{content?.company_name}</Text>
									<Text style={styles.text}>{content?.address}</Text>
									<Text style={styles.text}>{content?.formatted_phone_number}</Text>
								</View>
							</View>
							<View style={{ flex: 1, flexDirection: 'column', gap: '1rem' }}>
								<Text style={styles.text}>
									<strong>Date:</strong> {content?.date || new Date().toLocaleDateString()}
								</Text>
							</View>
						</View>
					</View>

					{/* Quotation For */}
					<View style={styles.section}>
						<Text style={styles.subtitle}>Quotation For</Text>
						<Text style={styles.text}>Customer name: {content?.name}</Text>
						<Text style={styles.text}>Customer email: {content?.email}</Text>

						<Text style={styles.text}>Customer Phone:{content?.phone_number}</Text>
					</View>

					<View style={{ backgroundColor: '#2978B7', flexDirection: 'row', paddingTop: '10px', paddingBottom: '10px' }}>
						<Text style={{ flex: 1, textAlign: 'center', color: 'white' }}>#</Text>
						<Text style={{ flex: 2, textAlign: 'center', color: 'white' }}>Item</Text>
						<Text style={{ flex: 2, textAlign: 'center', color: 'white' }}>Description</Text>
						<Text style={{ flex: 2, textAlign: 'center', color: 'white' }}>Unit Price</Text>
						<Text style={{ flex: 2, textAlign: 'center', color: 'white' }}>Quantity</Text>
						<Text style={{ flex: 2, textAlign: 'center', color: 'white' }}>Amount</Text>
					</View>
					{content?.items?.map((row, index) => (
						<View style={{ flexDirection: 'row', borderBottom: '1px solid #2978B7' }} key={index}>
							<Text style={{ flex: 1, textAlign: 'center', borderRight: '1px solid #2978B7', borderLeft: '1px solid #2978B7' }}>{index + 1}</Text>
							<Text style={{ flex: 2, textAlign: 'center', borderRight: '1px solid #2978B7' }}>{row.item}</Text>
							<Text style={{ flex: 2, textAlign: 'center', borderRight: '1px solid #2978B7' }}>{row.description}</Text>
							<Text style={{ flex: 2, textAlign: 'center', borderRight: '1px solid #2978B7' }}>{row.unit_price}</Text>
							<Text style={{ flex: 2, textAlign: 'center', borderRight: '1px solid #2978B7' }}>{row.quantity}</Text>
							<Text style={{ flex: 2, textAlign: 'center', borderRight: '1px solid #2978B7' }}>{row.amount}</Text>
						</View>
					))}
					<Totaler label={'SUBTOTAL'} value={content?.sub_total} />
					<Totaler label={'TAX (0%)'} value={content?.tax_amount} />
					<Totaler label={'TOTAL'} value={content?.Total} />

					{/* Message */}
					<View style={styles.messageBox}>
						<Text>{content?.message}</Text>
					</View>

					<Text>If you have any questions concerning this quotation, kindly contact me.</Text>
				</Page>
			</Document>
		</PDFViewer>
	);
};

export default QuotePDF;

const Totaler = ({ label, value }) => (
	<View style={{ flexDirection: 'row', borderBottom: '1px solid white' }}>
		<Text style={{ flex: 1, textAlign: 'center', borderRight: '1px solid white', borderLeft: '1px solid white', color: 'white' }}></Text>
		<Text style={{ flex: 2, textAlign: 'center', borderRight: '1px solid white', color: 'white' }}></Text>
		<Text style={{ flex: 2, textAlign: 'center', borderRight: '1px solid white', color: 'white' }}></Text>
		<Text style={{ flex: 2, textAlign: 'center', borderRight: '1px solid white', color: 'white' }}></Text>
		<Text
			style={{
				flex: 2,
				textAlign: 'center',
				paddingVertical: '10px',
				borderRight: '1px solid #2978B7',
				borderBottom: '1px solid #2978B7',
				color: '#2978B7',
				borderLeft: '1px solid #2978B7',
			}}
		>
			{label}
		</Text>
		<Text
			style={{
				flex: 2,
				textAlign: 'center',
				paddingVertical: '10px',
				borderRight: '1px solid #2978B7',
				borderBottom: '1px solid #2978B7',
				color: '#2978B7',
			}}
		>
			{value}
		</Text>
	</View>
);
