import React from 'react'
import EnhancedRadioButtonGroup from '../../atomicComponents/EnhancedRadioButtonGroup'
import useTheme from "@mui/material/styles/useTheme";
import { Box, Typography } from '@mui/material';
import StyledLabel from '../../atomicComponents/StyledLabel';

const useStyle = (theme) => {
  return {
    root: {
      padding: "1.25rem",
    },
    heading: {
        color:theme.palette.primary.main,
        display:"flex",
        justifyContent:"center",
        margin:"1.25rem 0",
        padding:"5px"
    },
    span:{
        borderBottom:`solid 3px ${theme.palette.primary.main} `
    }
  };
};

function LoanTypeSecondary({data,handler,options,subOptions,headings}) {
  const theme = useTheme();
  const styles = useStyle(theme);

  const getDefaultLoanTypeChoice = () =>{
    if(data?.secondary_loan_type){
        return data.secondary_loan_type
    }else{
        return 0
    }
  }
  const getSubLoanType = ()=>{
    return data?.secondary_sub_loan_type ? data.secondary_sub_loan_type : 0
  }

  const setLoanType =(value)=>{
    handler({secondary_loan_type: value})
  }

  const setSubLoanType = (value)=>{
    handler({secondary_sub_loan_type : value})
  }

  const additionalStyles = {
    display: "flex",
    justifyContent: "space-between",
  };
  const extraStyles = {
    display: "flex",
    justifyContent: "flex-start",
  };

  return (
    <>
      <Box sx={styles.heading}>
        <Box component={"span"} sx={styles.span}>
          <Typography variant="heading">{headings}</Typography>
        </Box>
      </Box>
      <Box>
        <StyledLabel label="Loan Type" />
        <EnhancedRadioButtonGroup
          options={options}
          handler={setLoanType}
          choice={getDefaultLoanTypeChoice()}
          styles={additionalStyles}
        />
      </Box>
      {(+data?.secondary_loan_type === 1 || +data?.secondary_loan_type === 2) && (
        <Box>
          <StyledLabel label="The loan is" />
          <EnhancedRadioButtonGroup
            options={subOptions}
            handler={setSubLoanType}
            choice={
              getSubLoanType()
            }
            styles={extraStyles}
            radioOverride={{ marginRight: "3.125rem" }}
          />
        </Box>
      )}
    </>
  );
}

export default LoanTypeSecondary