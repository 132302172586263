import React from "react";
import {
  TableContainer,
  Table,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
  Box,
  FormControl,
  Typography,
  tableCellClasses,
  InputBase,
} from "@mui/material";
import { Delete } from "@mui/icons-material";
import { totalCost } from "../userInputCards/remodelCost/totalCost";
import commaSeparate from "../utils/commaSeparate";

function EnhancedTable({ staticData, title, handleRemoveColumn, updateItems }) {
  const handleChange = (e, index) => {
    const data = { [e.target.name]: e.target.value };

    //this updated the function with the given value and name
    updateItems(index, data);
  };

  //this component generates data for the investors about the money and amount each should earn
  return (
    <Box sx={{ display: "flex" }}>
      <TableContainer
        component={Box}
        sx={{
          display: "flex",
          minWidth: "37.5rem",
          overflowX: "auto",
          width: "100%",
        }}
      >
        <Table
          sx={{
            width: "90%",
            border: "solid 1px #c4c4c4",
            fontSize: "1.5625rem",
          }}
          aria-label="simple table"
          size="small"
        >
          <TableHead>
            <TableRow sx={{ background: "#d7d7d7", padding: "20px" }}>
              <TableCell
                sx={{
                  width: "6.25rem",
                  borderLeft: "solid 1px #c4c4c4",
                  padding: "10px",
                }}
              >
                <Typography variant="h4" sx={{ fontWeight: "700" }}>
                  #
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  width: "100rem",
                  borderLeft: "solid 1px #c4c4c4",
                  padding: "10px",
                }}
              >
                <Typography variant="h4" sx={{ fontWeight: "700" }}>
                  {title}
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  width: "3.125rem",
                  borderLeft: "solid 1px #c4c4c4",
                  padding: "10px",
                }}
              >
                <Typography variant="h4" sx={{ fontWeight: "700" }}>
                  $ Value
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {staticData &&
              staticData?.map((row, index) => (
                <TableRow key={row?.item + index}>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ borderLeft: "solid 1px #c4c4c4" }}
                  >
                    <Typography variant="tableRow">{index + 1}</Typography>{" "}
                  </TableCell>
                  <TableCell sx={{ borderLeft: "solid 1px #c4c4c4" }}>
                    {/* <Typography variant="tableRow">{row}</Typography> */}
                    <FormControl
                      sx={{
                        width: "100%",
                      }}
                    >
                      <InputBase
                        name="item"
                        size="small"
                        autoFocus="autoFocus"
                        value={row?.item}
                        inputProps={{ style: { fontSize: "1.125rem" } }}
                        placeholder="Item"
                        onChange={(e) => handleChange(e, index)}
                      />
                    </FormControl>
                  </TableCell>
                  <TableCell sx={{ borderLeft: "solid 1px #c4c4c4" }}>
                    {
                      <FormControl
                        sx={{
                          width: "100%",
                        }}
                      >
                        <InputBase
                          name="value"
                          size="small"
                          value={row?.value}
                          inputProps={{ style: { fontSize: "1rem" } }}
                          type="number"
                          placeholder="$ Value"
                          onChange={(e) => handleChange(e, index)}
                        />
                      </FormControl>
                    }
                  </TableCell>
                </TableRow>
              ))}
            <TableRow>
              <TableCell
                component="th"
                scope="row"
                sx={{ borderLeft: "solid 1px #c4c4c4" }}
              >
                <Typography variant="tableRow"></Typography>{" "}
              </TableCell>
              <TableCell align="right" sx={{ borderLeft: "solid 1px #c4c4c4" }}>
                <Typography variant="tableRow">Total Cost:</Typography>
              </TableCell>
              <TableCell sx={{ borderLeft: "solid 1px #c4c4c4" }}>
                <Typography variant="tableRow">
                  {commaSeparate(totalCost(staticData)) || "0.00"}
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Table
          sx={{
            width: "10%",
            border: "solid 1px #fff",
            [`& .${tableCellClasses.root}`]: {
              borderBottom: "none",
            },
          }}
          size="small"
        >
          <TableHead>
            <TableRow sx={{ border: 0 }}>
              <TableCell>
                <Typography variant="adornment" sx={{ color: "#fff" }}>
                  Delete
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {staticData &&
              staticData?.map((row, index) => (
                <TableRow
                  key={"d" + row?.item + index}
                  sx={{ "&.MuiTableRow-root": { borderBottom: "none" } }}
                >
                  <TableCell
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      padding: "11px",
                    }}
                  >
                    {
                      <Delete
                        color="primary"
                        sx={{
                          margin: "0",
                          fontSize: "1.5625rem",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          handleRemoveColumn(index);
                        }}
                      />
                    }
                  </TableCell>
                </TableRow>
              ))}
            <TableRow sx={{ "&.MuiTableRow-root": { borderBottom: "none" } }}>
              <TableCell>
                {
                  <Delete
                    sx={{
                      margin: "0",
                      fontSize: "1.5625rem",
                      color: "#fff",
                    }}
                  />
                }
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default EnhancedTable;
