import { Dialog, DialogContent, IconButton, DialogTitle } from '@mui/material';
import React from 'react';
import { Carousel as CarouselPackage } from 'react-responsive-carousel';
import './carousel.scss';
import { Close } from '@mui/icons-material';

function Carousel({ children, open, onClose, style }) {
	return (
		<Dialog open={open} onClose={onClose} maxWidth='md'>
			<DialogTitle sx={{ position: 'relative' }}>
				<IconButton onClick={onClose} sx={{ position: 'fixed', top: '5%', right: '5%', backgroundColor: 'white' }}>
					<Close fontSize='small' />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<CarouselPackage
					autoPlay={false}
					infiniteLoop={true}
					showThumbs={false}
					stopOnHover={true}
					transitionTime={1000}
					useKeyboardArrows={true}
					autoFocus={true}
					swipeable={true}
					width={'100%'}
					dynamicHeight
				>
					{children}
				</CarouselPackage>
			</DialogContent>
		</Dialog>
	);
}

export default Carousel;
