import React, { useEffect, useMemo, useState } from "react";
import useTheme from "@mui/material/styles/useTheme";
import StyledLabel from "../../atomicComponents/StyledLabel";
import {
  FormControl,
  OutlinedInput,
  Typography,
  Box,
  InputAdornment,
  Grid,
  Divider,
} from "@mui/material";
import { greyout } from "../../utils/greyout";
import useProformaData from "../../hooks/dealReporthooks/useProformaData";
import commaSeparate from "../../utils/commaSeparate";
import EnhancedRadioButtonGroup from "../../atomicComponents/EnhancedRadioButtonGroup";
import { useDispatch, useSelector } from "react-redux";
import { remodelCostOptions } from "./constants";
import { getCostPsqftFromAdvancedRemodelCost } from "./remodelCostUtils/getCostPerSqft";
import EditIcon from "@mui/icons-material/Edit";
import RegularToolTip from "../../atomicComponents/RegularToolTip";
import {
  estRemodelCostDownToStudPerSqrfCalculator,
  isNaNChecker,
} from "../../localCalcutions/utils/genericFunctions";
import * as actionCreators from "../../../../actions/dealAnalyzer/index";
import { preventMinus } from "../../utils/preventNegativeValues";
import { generateField, multiplyArrays } from "../../utils/GenerateInputField";
import { AdditionalUnitsComponent } from "../AdditonalInputAddons/remodelConst/AdditionalUnitsComponent";

const useStyles = (_theme) => {
  return {
    root: {},
    inputContainer: {
      display: "flex",
      flexDirection: "column",
      marginTop: "1.5625rem",
    },
    underInput: {
      "&:hover": {
        borderBottom: "solid",
      },
      cursor: "pointer",
    },
    bottomLabel: {
      marginTop: "5px",
      display: "flex",
      alignItems: "center",
    },
    firstRow: {
      display: "flex",
      justifyContent: "space-between",
    },
    firstRowInputs: {
      display: "flex",
      flexDirection: "column",
      marginTop: "1rem",
      width: "48%",
    },
  };
};

function RemodelMainCardAlt({
  handleOpenDialog,
  handleOpenDialogAdu,
  handleSubMenu,
  handler,
  data,
}) {
  const theme = useTheme();
  const { resultData } = useProformaData();
  const analyzerData = useSelector((state) => state?.straightAestheticRemodel);
  const isAdvanced = !!+analyzerData?.remodelCosts?.remodel_cost_option;

  const propertyInfo = useSelector(
    (state) => state?.straightAestheticRemodel?.propertyInformation
  );

  const preExistingSquareFootage =
    analyzerData?.propertyInformation?.pre_existing_livable_sqft;

  const aestheticItems = analyzerData?.remodelCosts?.aesthetic_items || [];
  const nonAestheticItems =
    analyzerData?.remodelCosts?.non_aesthetic_items || [];
  const additionalItems =
    analyzerData?.remodelCosts?.additional_remodel_items || [];
  const {
    estimatedRemodelCostPsqft,
    estimatedNewConstructionCostPsqft,
    remodelCost,
  } =
    getCostPsqftFromAdvancedRemodelCost({
      preExistingSquareFootage,
      aestheticItems,
      nonAestheticItems,
      additionalItems,
    }) || {};
  let advancedRemodelTimer;
  const styles = useStyles(theme);
  const handleChange = (e) => {
    handler({ [e.target.name]: e.target.value });
  };
  const specialHandler = (e) => {
    handler({ [e.target.name]: e.target.value });
    if (+e.target.value === 1) {
      advancedRemodelTimer = setTimeout(() => {
        handleSubMenu(1);
      }, 1000);
    }
  };
  useEffect(() => {
    !data?.remodel_cost_option && handler({ remodel_cost_option: "0" });

    return () => {
      advancedRemodelTimer && clearTimeout(advancedRemodelTimer);
    };
    // NOTE: Run effect once on component mount, please
    // recheck dependencies if effect is updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dispatch = useDispatch();

  const preExistingLivingSqft =
    +analyzerData?.propertyInformation?.pre_existing_livable_sqft;
  const remodelCostPerSqft =
    +analyzerData?.remodelCosts?.est_remodel_cost_psqft;
  const estAdditionalCostSqft =
    +analyzerData?.remodelCosts?.est_new_construction_cost_psqft;
  const remodelCostData = resultData?.remodel_cost;

  //remodel cost
  const EstRemodelCostDownToStuds = useMemo(() => {
    const value = estRemodelCostDownToStudPerSqrfCalculator(
      remodelCostPerSqft,
      estAdditionalCostSqft
    ).toFixed(2);
    const result = isNaNChecker(value);
    return result;
    // recheck dependencies if effect is updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remodelCostPerSqft, estAdditionalCostSqft]);

  const generateNewForm = useMemo(() => {
    return generateField(propertyInfo?.additional_units?.length);
  }, [propertyInfo?.additional_units]);

  const formsWithValues =
    data?.additional_units_price?.length === generateNewForm?.length
      ? data?.additional_units_price
      : undefined;

  // empty prevPrices if unit length changes
  useEffect(() => {
    if (!formsWithValues) {
      handler({ additional_units_price: [] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // generate fields for additional sqf added
  const [additionalUnitArray, setAdditionalUnitArray] = useState(
    formsWithValues || generateNewForm
  );

  const componentDynamicDataFilter = (index, event) => {
    let data = [...additionalUnitArray];

    data[index][event.target.name] = event.target.value;
    setAdditionalUnitArray(data);
    handler({ additional_units_price: data });
    // handleEvents({ [e.target.name]: e.target.value });
  };

  const unitCostResult = useMemo(() => {
    return multiplyArrays(
      propertyInfo?.additional_units,
      data?.additional_units_price
    );
  }, [propertyInfo.additional_units, data?.additional_units_price]);

  //=============xxxxxxxxxxx============= dynamic form generation ==================xxxxxxxxx==========//
  const remodelDownToStudsCost =
    EstRemodelCostDownToStuds ||
    resultData?.remodel_cost?.[remodelMainCardStaticData.input2.name];

  //total Remodel Cost down to studs

  const estTotalRemodelCostDownToStuds = useMemo(() => {
    const result = preExistingLivingSqft * remodelDownToStudsCost;
    dispatch(
      actionCreators.updateFinanceOptions({
        totalRemodelCostDownToStuds: isNaNChecker(result),
      })
    );
    return isNaNChecker(result);
    // recheck dependencies if effect is updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preExistingLivingSqft, remodelDownToStudsCost]);

  const simpleRemodelTotal = useMemo(() => {
    const res =
      +estTotalRemodelCostDownToStuds +
      (unitCostResult?.totalSum?.additional_sqrft || 0) +
      (unitCostResult?.totalSum?.preExistLivable || 0) +
      (data?.adu_price * propertyInfo?.adu || 0) +
      (data?.basement_price * propertyInfo?.basement_psqf || 0);
    const localTotalRemodelCost = { remodelCost: res };

    dispatch(actionCreators.updateRemodelCosts(localTotalRemodelCost));
    return isNaNChecker(res);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    unitCostResult?.totalSum,
    data?.adu_price,
    propertyInfo?.adu,
    estTotalRemodelCostDownToStuds,
    data?.basement_price,
    propertyInfo?.basement_psqf,
  ]);

  useEffect(() => {
    dispatch(
      actionCreators.updateFinanceOptions({ advancedRemodelCost: remodelCost })
    );
    // NOTE: Run effect once on component mount, please
    // recheck dependencies if effect is updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remodelCost]);

  const totalRemodelCostDownToStuds =
    simpleRemodelTotal.toFixed(2) ||
    (resultData?.remodel_cost?.estimated_total_remodel_cost).toFixed(2);
  const simpleEstimatedRemodelCostPsqft = isNaN(
    +data?.[remodelMainCardStaticData.input1.name]
  )
    ? remodelCostData?.est_remodel_cost_psqft
    : data?.[remodelMainCardStaticData.input1.name];

  const simpleEstimatedNewConstructionCostPsqft = isNaN(
    +data?.[remodelMainCardStaticData.input1B.name]
  )
    ? remodelCostData?.est_new_construction_cost_psqft
    : data?.[remodelMainCardStaticData.input1B.name];
  //============================================= local Calculations  =====================================================//

  useEffect(() => {
    const grandTotalRemodelCost = isAdvanced
      ? remodelCost
      : +totalRemodelCostDownToStuds;

    handler({ grandTotalRemodelCost });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remodelCost, totalRemodelCostDownToStuds]);

  // Remodel Down to Studs
  return (
    <>
      <StyledLabel label="Choose a Remodel Cost Calculator 1" />
      <EnhancedRadioButtonGroup
        options={remodelCostOptions}
        name="remodel_cost_option"
        choice={data?.remodel_cost_option}
        specialHandler={specialHandler}
        styles={{ display: "flex" }}
      />
      <Divider
        light={true}
        // variant="middle"
        sx={{
          height: "1px",
          mt: -2,
          mb: 2,
          backgroundColor: "#C4c4c4",
        }}
      />
      <Box>
        {!isAdvanced && (
          <Grid container columns={24}>
            <Grid item sm={24} md={11}>
              <StyledLabel label={remodelMainCardStaticData.input1.label} />
              <FormControl sx={{ width: "100%" }}>
                <OutlinedInput
                  fullWidth
                  margin="normal"
                  name={remodelMainCardStaticData.input1.name}
                  onChange={isAdvanced ? "" : handleChange}
                  sx={isAdvanced ? greyout : {}}
                  value={
                    isAdvanced
                      ? estimatedRemodelCostPsqft
                      : simpleEstimatedRemodelCostPsqft
                  }
                  placeholder="0"
                  renderValue={(value) => value}
                  inputProps={{ style: { fontSize: "1rem" }, min: 0 }}
                  type="number"
                  onKeyPress={preventMinus}
                  startAdornment={
                    <InputAdornment position="start">
                      {" "}
                      <Typography variant="adornment" color="secondary">
                        {" "}
                        $
                      </Typography>
                    </InputAdornment>
                  }
                  endAdornment={
                    <InputAdornment position="start">
                      {" "}
                      <Typography variant="adornment" color="secondary">
                        {" "}
                        /SqFt
                      </Typography>
                    </InputAdornment>
                  }
                />
              </FormControl>
              {!isAdvanced && (
                <Box sx={styles.bottomLabel}>
                  <Typography
                    variant="h6"
                    color="primary"
                    sx={styles.underInput}
                    onClick={() => handleOpenDialog("remodel_cost")}
                  >
                    {remodelMainCardStaticData.input1.lowerLabel}
                  </Typography>
                </Box>
              )}
            </Grid>

            <Grid item sm={0} md={2} />
            <Grid item sm={24} md={11}>
              <StyledLabel label={remodelMainCardStaticData.input1B.label} />
              <FormControl sx={{ width: "100%" }}>
                <OutlinedInput
                  fullWidth
                  margin="normal"
                  sx={isAdvanced ? greyout : {}}
                  name={remodelMainCardStaticData.input1B.name}
                  onChange={handleChange}
                  value={
                    isAdvanced
                      ? estimatedNewConstructionCostPsqft
                      : simpleEstimatedNewConstructionCostPsqft
                  }
                  placeholder="0"
                  renderValue={(value) => value}
                  inputProps={{ style: { fontSize: "1rem" }, min: 0 }}
                  type="number"
                  onKeyPress={preventMinus}
                  startAdornment={
                    <InputAdornment position="start">
                      {" "}
                      <Typography variant="adornment" color="secondary">
                        {" "}
                        $
                      </Typography>
                    </InputAdornment>
                  }
                  endAdornment={
                    <InputAdornment position="start">
                      {" "}
                      <Typography variant="adornment" color="secondary">
                        {" "}
                        /SqFt
                      </Typography>
                    </InputAdornment>
                  }
                />
              </FormControl>

              {!isAdvanced && (
                <Box sx={styles.bottomLabel}>
                  <Typography
                    variant="h6"
                    color="primary"
                    sx={styles.underInput}
                    onClick={() => handleOpenDialog("new_construction")}
                  >
                    {remodelMainCardStaticData.input1.lowerLabel}
                  </Typography>
                </Box>
              )}
            </Grid>
          </Grid>
        )}
        {!!propertyInfo?.adu && (
          <Box sx={styles.inputContainer}>
            <StyledLabel label={"Accessory Dwelling Unit SqFt Cost"} />
            <FormControl sx={{ minWidth: "18.75rem" }}>
              <OutlinedInput
                fullWidth
                margin="normal"
                name={"adu_price"}
                onKeyPress={preventMinus}
                onChange={isAdvanced ? "" : handleChange}
                value={data?.adu_price}
                placeholder="0"
                renderValue={(value) => value}
                inputProps={{ style: { fontSize: "1rem" }, min: 0 }}
                type="number"
                sx={isAdvanced ? greyout : {}}
                startAdornment={
                  <InputAdornment position="start">
                    {" "}
                    <Typography variant="adornment" color="secondary">
                      {" "}
                      $
                    </Typography>
                  </InputAdornment>
                }
                endAdornment={
                  <InputAdornment position="start">
                    {" "}
                    <Typography variant="adornment" color="secondary">
                      {" "}
                      /SqFt
                    </Typography>
                  </InputAdornment>
                }
              />
            </FormControl>
            {!isAdvanced && (
              <Box sx={styles.bottomLabel}>
                <Typography
                  variant="h6"
                  color="primary"
                  sx={styles.underInput}
                  onClick={() => handleOpenDialogAdu('adu')}
                >
                  {remodelMainCardStaticData.input1.lowerLabel}
                </Typography>
              </Box>
            )}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                gap: "0.5rem",
              }}
            >
              {!isAdvanced &&
                additionalUnitArray?.map((item, index) => (
                  <FormControl sx={{ width: "45%", mt: "1rem" }} key={index}>
                    <StyledLabel
                      label={`Unit ${index + 2} Cost per Square Foot`}
                      showToolTip
                    // toolTipContent={toolTipTexts.bedrooms}
                    />

                    <OutlinedInput
                      name={"value"}
                      onKeyPress={preventMinus}
                      type="number"
                      onChange={(event) =>
                        componentDynamicDataFilter(index, event)
                      }
                      value={item?.value}
                      inputProps={{ style: { fontSize: "1rem" }, min: 0 }}
                      sx={{ fontSize: "16px" }}
                      endAdornment={
                        <InputAdornment position="start">
                          {" "}
                          <Typography variant="adornment"> SqFt</Typography>
                        </InputAdornment>
                      }
                    ></OutlinedInput>
                  </FormControl>
                ))}
            </Box>
          </Box>
        )}
        {!!propertyInfo?.basement_psqf && (
          <Box sx={styles.inputContainer}>
            <StyledLabel label={"Basement Unit SqFt Cost"} />
            <FormControl sx={{ minWidth: "18.75rem" }}>
              <OutlinedInput
                fullWidth
                margin="normal"
                name={"basement_price"}
                onKeyPress={preventMinus}
                onChange={isAdvanced ? "" : handleChange}
                value={data?.basement_price}
                placeholder="0"
                renderValue={(value) => value}
                inputProps={{ style: { fontSize: "1rem" }, min: 0 }}
                type="number"
                sx={isAdvanced ? greyout : {}}
                startAdornment={
                  <InputAdornment position="start">
                    {" "}
                    <Typography variant="adornment" color="secondary">
                      {" "}
                      $
                    </Typography>
                  </InputAdornment>
                }
                endAdornment={
                  <InputAdornment position="start">
                    {" "}
                    <Typography variant="adornment" color="secondary">
                      {" "}
                      /SqFt
                    </Typography>
                  </InputAdornment>
                }
              />
              <Box sx={styles.under}>

                <Typography variant="notice">
                  Basement cost typically 20% higher that Addition cost per square foot
                </Typography>
              </Box>
            </FormControl>
            {!isAdvanced && (
              <Box sx={styles.bottomLabel}>
                <Typography
                  variant="h6"
                  color="primary"
                  sx={styles.underInput}
                  onClick={() => handleOpenDialogAdu('basement')}
                >
                  {remodelMainCardStaticData.input1.lowerLabel}
                </Typography>
              </Box>
            )}
          </Box>
        )}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: "0.5rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: "0.5rem",
            }}
          >
            {!!propertyInfo?.adu && (
              <Box display={"flex"} gap={"4rem"}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    my: "2rem",
                  }}
                >
                  <StyledLabel label={"Accessory Dwelling Unit SqFt"} />
                  <FormControl sx={{ minWidth: "18.75rem" }}>
                    <OutlinedInput
                      fullWidth
                      margin="normal"
                      name={"adu"}
                      onChange={handleChange}
                      value={propertyInfo?.adu || 0}
                      sx={greyout}
                      placeholder="0"
                      onKeyPress={preventMinus}
                      renderValue={(value) => value}
                      inputProps={{ style: { fontSize: "1rem" }, min: 0 }}
                      type="number"
                      endAdornment={
                        <InputAdornment position="start">
                          {" "}
                          <Typography variant="adornment" color="secondary">
                            {" "}
                            SqFt
                          </Typography>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    my: "2rem",
                  }}
                >
                  <StyledLabel label={"Accs Dwelling Unit SqFt  Total Cost"} />
                  <FormControl sx={{ minWidth: "18.75rem" }}>
                    <OutlinedInput
                      fullWidth
                      margin="normal"
                      name={"adu"}
                      onChange={handleChange}
                      value={data?.adu_price * propertyInfo?.adu || 0}
                      sx={greyout}
                      placeholder="0"
                      onKeyPress={preventMinus}
                      renderValue={(value) => value}
                      inputProps={{ style: { fontSize: "1rem" }, min: 0 }}
                      type="number"
                      endAdornment={
                        <InputAdornment position="start">
                          {" "}
                          <Typography variant="adornment" color="secondary">
                            {" "}
                            SqFt
                          </Typography>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </Box>
              </Box>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: "0.5rem",
            }}
          >
            {!!propertyInfo?.basement_psqf && (
              <Box display={"flex"} gap={"4rem"}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    my: "2rem",
                  }}
                >
                  <StyledLabel label={"Basement Unit SqFt"} />
                  <FormControl sx={{ minWidth: "18.75rem" }}>
                    <OutlinedInput
                      fullWidth
                      margin="normal"
                      name={"adu"}
                      onChange={handleChange}
                      value={propertyInfo?.basement_psqf || 0}
                      sx={greyout}
                      placeholder="0"
                      onKeyPress={preventMinus}
                      renderValue={(value) => value}
                      inputProps={{ style: { fontSize: "1rem" }, min: 0 }}
                      type="number"
                      endAdornment={
                        <InputAdornment position="start">
                          {" "}
                          <Typography variant="adornment" color="secondary">
                            {" "}
                            SqFt
                          </Typography>
                        </InputAdornment>
                      }
                    />
                  </FormControl>

                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    my: "2rem",
                  }}
                >
                  <StyledLabel label={"Basement Unit SqFt  Total Cost"} />
                  <FormControl sx={{ minWidth: "18.75rem" }}>
                    <OutlinedInput
                      fullWidth
                      margin="normal"
                      name={"adu"}
                      onChange={handleChange}
                      value={
                        data?.basement_price * propertyInfo?.basement_psqf || 0
                      }
                      sx={greyout}
                      placeholder="0"
                      onKeyPress={preventMinus}
                      renderValue={(value) => value}
                      inputProps={{ style: { fontSize: "1rem" }, min: 0 }}
                      type="number"
                      endAdornment={
                        <InputAdornment position="start">
                          {" "}
                          <Typography variant="adornment" color="secondary">
                            {" "}
                            SqFt
                          </Typography>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
        <Box>
          {!isAdvanced &&
            additionalUnitArray.map((item, index) => (
              <AdditionalUnitsComponent
                propertyInfo={propertyInfo}
                data={data}
                handler={handler}
                item={item}
                unitIndex={index}
                hasAdditionalCost={false}
                additionalUnitArray={additionalUnitArray}
                setAdditionalUnitArray={setAdditionalUnitArray}
              />
            ))}
        </Box>
        {isAdvanced && <Box sx={{ mt: 6 }} />}
        {!isAdvanced && (
          <Box sx={styles.inputContainer}>
            <StyledLabel label={remodelMainCardStaticData?.input2.label} />
            <FormControl sx={{ width: "100%" }}>
              <OutlinedInput
                fullWidth
                margin="small"
                sx={{ ...greyout }}
                value={remodelDownToStudsCost}
                placeholder="0"
                onKeyPress={preventMinus}
                renderValue={(value) => value}
                inputProps={{ style: { fontSize: "1rem" }, min: 0 }}
                startAdornment={
                  <InputAdornment position="start">
                    {" "}
                    <Typography variant="adornment" color="secondary">
                      {" "}
                      $
                    </Typography>
                  </InputAdornment>
                }
                endAdornment={
                  <InputAdornment position="start">
                    {" "}
                    <Typography variant="adornment" color="secondary">
                      {" "}
                      /SqFt
                    </Typography>
                  </InputAdornment>
                }
              />
            </FormControl>
          </Box>
        )}
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          {unitCostResult?.indexMultiplications?.map((item, index) => (
            <Box sx={styles.inputContainer}>
              <StyledLabel label={`Unit ${index + 2} Remodel Cost`} />
              <FormControl sx={{ minWidth: "18.75rem" }}>
                <OutlinedInput
                  fullWidth
                  margin="normal"
                  name={remodelMainCardStaticData?.input2.name}
                  onChange={handleChange}
                  value={item?.[1]?.value || 0}
                  sx={greyout}
                  placeholder="0"
                  onKeyPress={preventMinus}
                  renderValue={(value) => value}
                  inputProps={{ style: { fontSize: "1rem" }, min: 0 }}
                  type="number"
                  startAdornment={
                    <InputAdornment position="start">
                      {" "}
                      <Typography variant="adornment" color="secondary">
                        {" "}
                        $
                      </Typography>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Box>
          ))}
        </Box>
        <Box sx={styles.inputContainer}>
          <Box>
            <StyledLabel
              label={
                !isAdvanced
                  ? remodelMainCardStaticData?.input3.label
                  : "Est Advanced Total Remodel Cost down to studs"
              }
            />
            {isAdvanced && (
              <RegularToolTip content="Edit">
                <EditIcon
                  sx={{
                    fontSize: "1.2rem",
                    mx: 3,
                    color: "#1378A5",
                    cursor: "pointer",
                  }}
                  onClick={() => handleSubMenu(1)}
                />
              </RegularToolTip>
            )}
          </Box>

          <FormControl sx={{ width: "100%" }}>
            <OutlinedInput
              fullWidth
              margin="normal"
              value={
                isAdvanced
                  ? commaSeparate(remodelCost)
                  : commaSeparate(totalRemodelCostDownToStuds)
              }
              sx={{ ...greyout }}
              placeholder="0"
              renderValue={(value) => value}
              inputProps={{ style: { fontSize: "1rem" } }}
              startAdornment={
                <InputAdornment position="start">
                  {" "}
                  <Typography variant="adornment" color="secondary">
                    {" "}
                    $
                  </Typography>
                </InputAdornment>
              }
            />
          </FormControl>
          {/* { !isAdvanced &&
              <Box sx={styles.bottomLabel}>
                <Typography variant="notice" color="primary" sx={styles.underInput} onClick = {()=>handleSubMenu(1)}>
                  {remodelMainCardStaticData.input3.lowerLabel}
                </Typography>
                <HtmlToolTip content={{text:"Add Items and their estimated dollar value individually"}}/>
              </Box>} */}
        </Box>
      </Box>
    </>
  );
}

export default RemodelMainCardAlt;

const remodelMainCardStaticData = {
  input1: {
    label: "Est. Remodel Cost P/Sqft (Main Unit)",
    lowerLabel:
      "Get an estimated dollar per square foot from a Local Licensed Contractor",
    name: "est_remodel_cost_psqft",
  },
  input1B: {
    label: "Est. Addition Cost P/SqFt",
    lowerLabel:
      "Get an estimated dollar per square foot from a Local Licensed Contractor",
    name: "est_new_construction_cost_psqft",
  },
  input2: {
    label: "Est Remodel Cost down to studs PSqft",
    name: "est_remodel_down_to_studs_psqft",
  },
  input3: {
    label: "Est Total Remodel Cost down to studs",
    lowerLabel: "Advanced Remodel Cost",
    name: "est_total_remodel_cost_down_to_studs",
  },
};
