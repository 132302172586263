import { Box, Container, Grid, Skeleton, Typography, Pagination,Dialog,Button, DialogContent, DialogTitle, Checkbox } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import MuiThemeLayout from "../../../Layouts/MuiThemeLayout";
import useFetchReports from "../utils/hooks/useFetchReports";
import ReportItem from "./ReportItem";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  updateDealAnalyzerReportId,
  updatePropertyInformation,
  restoreInitialState,
  updateAllState,
} from "../../../actions/dealAnalyzer";
import useProformaData from "../../../components/DealAnalyzerComponents/hooks/dealReporthooks/useProformaData";
import RegularToolTip from "../../../components/DealAnalyzerComponents/atomicComponents/RegularToolTip";
import { isArray } from "lodash";

function ViewUncompletedReports() {
  const { fetchReports, dealReports, loading,deleteReport,bulkDelete } = useFetchReports();
  const uncompletedReports = dealReports?.uncompletedReports;
  const [loadingToEdit, setLoadingToEdit] = React.useState(false);
  const [loadingToPrint, setLoadingToPrint] = React.useState(false);
  const [activeIndex, setActiveIndex] = React.useState("");
  const [loadingToDelete, setLoadingToDelete] = React.useState(false)
  const [currentPage, setCurrentPage] = React.useState(1)
  const [open, setOpen] = React.useState(false)
  const [deleteDetails, setDeleteDetails] = React.useState()
  const [checkedReports, setCheckedReports] = React.useState([])
  const [checkAll, setCheckAll] = React.useState(false)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { fetchResult, getAllData } = useProformaData({noAutoLoad:true});
  const skeletonArr = new Array(10).fill("A");


  const handleOpen = () =>{
    setOpen(prev=>!prev)
}
const handleClose = ()=>{
    setOpen(prev=>!prev)
}
const addToChecked = (id,type) =>{
  if(reportIsChecked(id,type)){
    removeFromchecked(id,type)
    return
  }
  setCheckedReports(prev=>([...prev,{id,type}]))
}
const removeFromchecked = (id,type) =>{
  setCheckedReports(prev => prev.filter(element =>!(element?.id === id && element?.type === type)))
}
const reportIsChecked = (id, type) =>{
  return checkedReports.filter(element =>element?.id === id && element?.type === type)?.length
}


  const handleOpenToEdit = async ({ report_type, id, property_address }) => {
    try {
      setLoadingToEdit(true);
      setActiveIndex(id);
      const data = await getAllData({id,type:report_type});
      // fetchResult();
      dispatch(restoreInitialState());
      dispatch(updateAllState(data?.data?.data));
      dispatch(updateDealAnalyzerReportId({ id, analyzerTypeId: report_type }));
      dispatch(updatePropertyInformation({ property_address }));
    } catch (err) {
      return err;
    } finally {
      setLoadingToEdit(false);
    }
    switch (+report_type) {
      case 1:
        return navigate(`/analyze-deal/menu/straight-aesthetic-remodel`);
      case 2:
        return navigate(`/analyze-deal/menu/straight-remodel-additional-sqft`);
      case 3:
        return navigate(`/analyze-deal/menu/remodel-down-to-studs`);
      case 4:
        return navigate(
          `/analyze-deal/menu/remodel-down-to-studs-add-addition`
        );
      case 5:
        return navigate(`/analyze-deal/menu/new-construction`);
      case 6:
        return navigate(`/analyze-deal/menu/buy-and-rent`);
      default:
        return "";
    }
  };
  const redirectToReport = async ({ report_type, id }) => {
    try {
      setLoadingToPrint(true);
      setActiveIndex(id);
      dispatch(restoreInitialState());
      await fetchResult();
      const data = await getAllData({id,type:report_type});
      dispatch(updateDealAnalyzerReportId({ id, analyzerTypeId: report_type }));
      dispatch(updateAllState(data?.data?.data));
      if (!data?.error) {
        switch (+report_type) {
          case 1:
            return navigate("/dealanalyzer-reports/straight-aesthetic-remodel");
          case 2:
            return navigate(
              "/dealanalyzer-reports/straight-remodel-add-addition"
            );
          case 3:
            return navigate("/dealanalyzer-reports/remodel-down-to-studs");
          case 4:
            return navigate("/dealanalyzer-reports/remodel-down-add-addition");
          case 5:
            return navigate("/dealanalyzer-reports/new-construction");
          case 6:
            return navigate("/dealanalyzer-reports/buy-and-rent");
          default:
            return "";
        }
      }
    } catch (err) {
      return err;
    } finally {
      setLoadingToPrint(false);
    }
  };
  const handleDeleteReport = async({id,report_type,index})=>{
    setDeleteDetails(prev=>({id,report_type,index}))
    handleOpen()
  }
  const handleDeleteBulk = ()=>{
    setDeleteDetails(checkedReports)
    handleOpen()
  }
  const confirmDelete = async() => {
    handleClose()
    const {id,report_type} = deleteDetails || {}
    setActiveIndex(id);
    setLoadingToDelete(true)
    if(isArray(deleteDetails)){
      await bulkDelete(checkedReports)
      setCheckedReports([])
    }else{
      await deleteReport(id,report_type)
    }
    setLoadingToDelete(false)
    fetchReports()
  }
  const handlePageChange = (event,value)=>{
    setCurrentPage(value)

  }
  const checkSize = { "& .MuiSvgIcon-root": { fontSize: 20 } };

  const handleCheckAll = () => {
    if (!checkAll) {
      setCheckAll((prev) => !prev);
      const allDisplayedReports = uncompletedReports
        .slice((+currentPage - 1) * 10, (+currentPage - 1) * 10 + 10)
        .map((element) => ({
          id: element?.id,
          type: element?.report_type,
        }));
      setCheckedReports((prev) => ([...allDisplayedReports]));
      return;
    }
    setCheckedReports([]);
    setCheckAll((prev) => !prev);
  };
  
  useEffect(() => {
    fetchReports();
    // NOTE: Run effect once on component mount, please
    // recheck dependencies if effect is updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <MuiThemeLayout>
      <Box sx={{ pt: 4,minHeight:"50vh" }}>
        <Container maxWidth="xl">
          <Box sx={{ m: 2, borderBottom: "solid 1px" }}>
            <Grid container columns={24}>
              <Grid item md={12}>
                <Typography variant="h4">Property Address</Typography>
              </Grid>
              <Grid item md={6}>
                <Typography variant="h4">Deal Type</Typography>
              </Grid>
              <Grid item md={6}>
                <Typography variant="h4">Last Edited</Typography>
              </Grid>
            </Grid>
          </Box>
          {!!checkedReports?.length && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                px: 2,
              }}
            >
              {" "}
              <Box sx={{ mx: 1 }}>
                <RegularToolTip
                  content={{ title: !checkAll ? "Select all" : "Unselect all" }}
                >
                  <Checkbox
                    sx={checkSize}
                    checked={checkAll}
                    onChange={handleCheckAll}
                  />
                </RegularToolTip>
              </Box>
              <Button
                variant="contained"
                sx={{
                  textTransform: "none",
                  py: 1,
                  px: 5,
                  alignSelf: "end",
                  my: 1,
                }}
                onClick={handleDeleteBulk}
              >
                <Typography variant="h4">Delete</Typography>
              </Button>
            </Box>
          )}
          {!loading &&
            !!uncompletedReports?.length &&
            uncompletedReports
              .slice((+currentPage - 1) * 10, (+currentPage - 1) * 10 + 10)
              .map((report, index) => (
                <ReportItem
                  report={report}
                  edit={handleOpenToEdit}
                  redirectToReport={redirectToReport}
                  loadingToEdit={loadingToEdit && activeIndex === report?.id}
                  loadingToPrint={loadingToPrint && activeIndex === report?.id}
                  handleDelete={handleDeleteReport}
                  unCompleted
                  bulkActions={{
                    addToChecked,
                    removeFromchecked,
                    reportIsChecked,
                  }}
                  loadingToDelete={
                    loadingToDelete && activeIndex === report?.id
                  }
                  index={index}
                  key={index}
                />
              ))}
          {!loading && !uncompletedReports?.length && (
            <Box sx={{ width: "100%", textAlign: "center" }}>
              <Typography variant="h4">
                {" "}
                No uncompleted reports found.
              </Typography>
            </Box>
          )}
          {loading &&
            skeletonArr.map((element) => (
              <Skeleton
                variant="rounded"
                width={"100%"}
                height={"4rem"}
                sx={{ mb: 2 }}
                animation="wave"
              />
            ))}
          {Math.ceil(uncompletedReports.length / 10) > 1 &&
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Pagination
                count={Math.ceil(uncompletedReports.length / 10)}
                variant="outlined"
                shape="rounded"
                onChange={handlePageChange}
                size="large"
                sx={{
                  "& .MuiPaginationItem-rounded": {
                    fontSize: "1rem",
                  },
                }}
              />
            </Box>
          }
          <Dialog open={open} onClose={handleClose} maxWidth="xl">
            <DialogTitle sx={{ my: 3 }}>
              <Typography variant="h4" sx={{ textAlign: "center" }}>
                Confirm Delete
              </Typography>{" "}
            </DialogTitle>
            <DialogContent>
              <Button
                variant="contained"
                onClick={handleClose}
                sx={{ mx: 2, py: 1, px: 2 }}
              >
                <Typography variant="h4" sx={{ textTransform: "none" }}>
                  Cancel
                </Typography>
              </Button>
              <Button
                variant="outlined"
                onClick={confirmDelete}
                sx={{ mx: 2, py: 1, px: 2 }}
              >
                <Typography variant="h4" sx={{ textTransform: "none" }}>
                  Delete
                </Typography>
              </Button>
            </DialogContent>
          </Dialog>
        </Container>
      </Box>
    </MuiThemeLayout>
  );
}

export default ViewUncompletedReports;
