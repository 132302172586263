import React, { useState,useRef, useEffect } from "react";
import useTheme from "@mui/material/styles/useTheme";
import EnhancedRadioButtonGroup from "./EnhancedRadioButtonGroup";
import { Box, Typography } from "@mui/material";
import { HiOutlineChevronDown, HiOutlineChevronRight } from "react-icons/hi";

const useStyles = (theme) => {
  return {
    points: {
      display: "flex",
      alignItems: "center",
      marginTop: "1.3rem",
    },
    pointsToggle: {
      color: theme.palette.primary.main,
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
    },
    pointsToggleOpen: {
      color: theme.palette.primary.main,
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
    },
    pointsExtra: {
      marginTop: "1rem",
    },
    underline:{
        borderBottom: `1px solid ${theme.palette.primary.main}`,
        paddingBottom:"0.2rem",
        marginBottom:"0.625rem"
    },
    scrollStyle:{
      paddingBottom:'31.25rem'
    }
  };
};
function ExtraOptionsToggle({ options, handler, data, name,showText }) {
  const theme = useTheme();
  const styles = useStyles(theme);
  const [show, setShow] = useState(false);
  const [applyMarginBottom,setApplyMarginBottom] = useState(false)
  const scrollRef = useRef(null)
  const dealFinanceOptionisCash = +data?.deal_finance_option === 4

  const handleValue = (value) => {
    handler({ [name]: value });
  };
  const handleShow = () => {
    setShow((prev) => !prev);
    if(dealFinanceOptionisCash){
      setTimeout(()=>{
        scrollRef.current.scrollIntoView({behavior:"smooth"})
      },200)
    }

  };
  const handleMargin = ()=>{
    setApplyMarginBottom(true)
    setTimeout(()=>{
      setApplyMarginBottom(false)
    },400)
  }

  useEffect(()=>{
    if(dealFinanceOptionisCash){
      handleMargin()
    }
  },[show, dealFinanceOptionisCash])

  return (
    <>
      <Box sx={styles.pointsExtra}>
        <Box
          component={"span"}
          sx={!show ? styles.pointsToggle : styles.pointsToggleOpen}
          onClick={handleShow}
        >
          <Typography variant="toggleText"><span style = {show? styles.underline : {}}>{showText}</span></Typography>
          {show && (
            <HiOutlineChevronDown
              style={{ marginLeft: "1rem", fontSize: "1rem" }}
            />
          )}
          {!show && (
            <HiOutlineChevronRight
              style={{ marginLeft: "1rem", fontSize: "1rem" }}
            />
          )}
        </Box>
        {show && (
          <div ref = {scrollRef} style ={applyMarginBottom? StyleSheet.scrollStyle : {}}>
            <EnhancedRadioButtonGroup
              options={options}
              handler={handleValue}
              choice={data?.[name] ? +data[name] : 0}
            />
          </div>
        )}
      </Box>
    </>
  );
}

export default ExtraOptionsToggle;
