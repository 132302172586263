import React from 'react'
import { Box,Typography } from '@mui/material'

function InnerSectionHeader({title}) {
  return (
    <>
        <Box sx={{borderBottom:"solid 0.5px #FFAA01 "}}>
         <Typography variant='h4' color={'primary'} sx={{fontWeight:700}}>{title}</Typography>   
        </Box>
    </>
  )
}

export default InnerSectionHeader