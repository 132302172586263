import React from 'react';
import { makeStyles, styled } from '@mui/styles';
import { Box, Button as MBat, Grid, Typography, Stack } from '@mui/material';
import { ReactComponent as Listings } from '../../images/listingsprem.svg';
import { ReactComponent as Website } from '../../images/websiteprem.svg';
import { ReactComponent as Digicard } from '../../images/digicardprem.svg';
import { ReactComponent as Sold } from '../../images/sold1prem.svg';
import { ReactComponent as Active } from '../../images/active1prem.svg';
import { ReactComponent as Manage } from '../../images/manage1prem.svg';
import ActivePremImage from '../../images/activepremimage.webp';
import SoldPremImage from '../../images/soldpremimage.webp';
import ManagePremImage from '../../images/managepremimage.webp';
import IncludeWebImage from '../../images/includeweb.webp';
import UploadWebImage from '../../images/uploadweb.webp';
import ManageWebImage from '../../images/manageweb.webp';
import SendDigiImage from '../../images/senddigiimage.webp';
import UsersDigiImage from '../../images/usersdigiimage.webp';
import ManageDigiImage from '../../images/managedigiimage.webp';
import { ReactComponent as Include } from '../../images/includewebimage.svg';
import { ReactComponent as Upload } from '../../images/uploadwebimage.svg';
import { ReactComponent as Users } from '../../images/senddigi.svg';
import { ReactComponent as Send } from '../../images/usersdigi.svg';
import { useState } from 'react';
import { Link } from 'react-router-dom';

const Button = styled(MBat)(({ theme }) => ({
	textTransform: 'capitalize',
}));

const useStyles = makeStyles((theme) => ({
	root: {
		height: '100vh',
		width: '100%',
		backgroundColor: '#f5f5f5',
		padding: '5rem ',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
	},
	line: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		color: 'black',
		fill: 'black',
		gap: '1rem',
		cursor: 'pointer',
		marginTop: '.5rem',
		marginBottom: '.5rem',
		width: 'fit-content',
		'&:hover': {
			color: '#005EA9',
			fill: '#005EA9',
			transform: 'scale(1.05)',
		},
	},
	gridItem: {
		justifyContent: 'center',
		alignitems: 'flex-start',
		display: 'flex',
		flexDirection: 'column',
	},
}));

const optionImages = {
	listings: [ActivePremImage, SoldPremImage, ManagePremImage],
	website: [IncludeWebImage, UploadWebImage, ManageWebImage],
	digicard: [SendDigiImage, UsersDigiImage, ManageDigiImage],
};

const headerOptions = [
	{ text: 'ADD LISTINGS', Icon: Listings, style: 'fill', value: 'listings' },
	{ text: 'INVESTOR WEBSITE', Icon: Website, style: 'stroke', value: 'website' },
	{ text: 'DIGITAL BUSINESS CARD', Icon: Digicard, style: 'fill', value: 'digicard' },
];

function PremHomePage() {
	const classes = useStyles();
	//listings,website,digicard
	const [selectedOption, setSelectedOption] = useState('listings');
	const [selectedIndex, setSelectedIndex] = useState(0);

	const handleHover = (e, index) => {
		setSelectedIndex(index);
	};

	const onChangeOption = (option) => {
		setSelectedOption(option);
		setSelectedIndex(0);
	};

	const options = {
		listings: {
			lines: [
				<Typography variant='body1' className={classes.line} component={Link} to='/premier-agent-website/view-listings'>
					<Active fill='inherit' />
					Add new active listings
				</Typography>,
				<Typography variant='body1' className={classes.line} component={Link} to='/premier-agent-website/view-listings'>
					<Sold fill='inherit' />
					Add recently sold listings.
				</Typography>,
				<Typography variant='body1' className={classes.line} component={Link} to='/premier-agent-website/view-listings'>
					<Manage fill='inherit' />
					Edit and manage each Active Listing Website
				</Typography>,
			],
			buttonActions: (
				<Button variant='outlined' color='secondary' disableRipple href='/premier-agent-website/view-listings'>
					View Listings
				</Button>
			),
		},
		website: {
			lines: [
				<Typography variant='body1' className={classes.line} component={Link} to='/premier-agent-website/website'>
					<Include fill='inherit' />
					Include all your active and recently sold listings
				</Typography>,
				<Typography variant='body1' className={classes.line} component={Link} to='/premier-agent-website/website'>
					<Upload fill='inherit' />
					Upload video tours, a full portrait and a descrptive video of yourself{' '}
				</Typography>,
				<Typography variant='body1' className={classes.line} component={Link} to='/premier-agent-website/website'>
					<Manage fill='inherit' />
					Edit and manage your Investor Website{' '}
				</Typography>,
			],
			buttonActions: (
				<Button variant='contained' color='secondary' disableRipple href='/premier-agent-website/website'>
					Manage investor website
				</Button>
			),
		},
		digicard: {
			lines: [
				<Typography variant='body1' className={classes.line} component={Link} to='/premier-agent-website/digital-card'>
					<Send fill='inherit' />
					Share and save contact information in real-time quickly
				</Typography>,
				<Typography variant='body1' className={classes.line} component={Link} to='/premier-agent-website/digital-card'>
					<Users fill='inherit' />
					Ensure better engagement with variety of media attached
				</Typography>,
				<Typography variant='body1' className={classes.line} component={Link} to='/premier-agent-website/digital-card'>
					<Manage fill='inherit' />
					Automatically update information any time
				</Typography>,
			],
			buttonActions: (
				<Button variant='contained' color='secondary' disableRipple href='/premier-agent-website/digital-card'>
					Update Information
				</Button>
			),
		},
	};
	return (
		<Box className={classes.root}>
			{/* <Listings fill='red' />
			<Website stroke='red' />
			<Digicard fill='red' /> */}
			<Box sx={{ filter: 'drop-shadow(0px 4px 30px rgba(0, 0, 0, 0.15))' }}>
				<Stack direction='row' justifyContent='space-between' gap='1rem' padding='0' width='100% !important' marginBottom='1rem'>
					{headerOptions.map((option) => (
						<Stack
							direction='column'
							key={option.text}
							alignItems={'center'}
							sx={{
								backgroundColor: option.value === selectedOption ? 'white' : '#FFAA01',
								color: option.value === selectedOption ? '#005EA9' : 'white',
								fill: option.value === selectedOption ? '#005EA9' : 'white',
								stroke: option.value === selectedOption ? '#005EA9' : 'white',
								cursor: 'pointer',
								borderRadius: '5px 5px 0px 0px',
							}}
							p='1rem'
							flex='1'
							onClick={() => onChangeOption(option.value)}
						>
							<option.Icon {...{ [option.style]: 'inherit' }} />
							<Typography variant='body1' color='inherit'>
								{option.text}
							</Typography>
						</Stack>
					))}
				</Stack>
				<Grid
					container
					spacing={2}
					sx={{ background: 'linear-gradient(100.23deg, #FFFFFF -0.01%, #F5F5F5 101.79%);', padding: '1rem', ml: '0', width: '100%' }}
				>
					<Grid item xs={6} className={classes.gridItem}>
						{options[selectedOption].lines.map((line, index) => (
							<Box key={index} onMouseOver={(e) => handleHover(e, index)}>
								{line}
							</Box>
						))}
						<Box sx={{ marginTop: '2rem', width: 'fit-content' }}>{options[selectedOption].buttonActions}</Box>
					</Grid>
					<Grid item xs={6} className={classes.gridItem}>
						<img
							src={optionImages[selectedOption][selectedIndex]}
							alt='active listing'
							style={{ width: '100%', aspectRatio: '1013/660', borderRadius: '0 10px', filter: 'drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.2))' }}
						/>
					</Grid>
				</Grid>
			</Box>
		</Box>
	);
}

export default PremHomePage;
