import React from "react";
import { Box } from "@mui/material";
import useSelectProformaType from "../hooks/useSelectProformaType";
import ProformaLetterhead from "./ProformaPages/ProformaLetterhead";
import ProformaPage1 from "./ProformaPages/ProformaPage1";
import ProformaPage2 from "./ProformaPages/ProformaPage2";
import ProformaPage3Type2 from "./ProformaPages/ProformaPage3Type2";
import PrintPageFooter from "./PrintPageFooter";
import ProformaPage4 from "./ProformaPages/ProformaPage4";
import ProformaPage5 from "./ProformaPages/ProformaPage5";

const ProFormaReportPrint = ({ address, dealType }) => {
  const activeProforma = useSelectProformaType();

  const {
    listingArray,
    propertyInformation,
    dealFinance,
    monthlyTaxPayment,
    propertyPermitAndFees,
    remodelCost,
    frontendClosingCost,
    backendClosingCost,
    carryingCost,
    operationalExpenses,
    otherExpenses,
    estResaleValue,
    rentalIncome,
    capitalizationRate,
    netProfit,
    investorsProfit,
    resultData,
    user,
    analyzerData,
    getAllLocalCalculations,
  } = activeProforma() || {};

  return (
    <Box
      sx={{ display: "none", displayPrint: "block", boxSizing: "border-box" }}
    >
      <Box sx={{ padding: "0 0rem" }}>
        <Box sx={{ height: "1.25rem" }} />
        <div style={{ position: "relative" }}>
          <ProformaLetterhead
            agent={user}
            address={address}
            dealType={dealType}
          />
          <ProformaPage1
            listingArray={listingArray}
            propertyInformation={propertyInformation}
            dealFinance={dealFinance}
            monthlyTaxPayment={monthlyTaxPayment}
            propertyPermitAndFees={propertyPermitAndFees}
            printVersion={true}
            resultData={resultData}
            getAllLocalCalculations={getAllLocalCalculations}
          />
          <PrintPageFooter currentPage={1} total={5} />
        </div>
        <Box sx={{ pageBreakBefore: "always !important" }}></Box>
        <Box sx={{ height: "1.25rem" }} />
        <div style={{ position: "relative" }}>
          <ProformaLetterhead
            agent={user}
            address={address}
            dealType={dealType}
            noImage={true}
          />
          <ProformaPage2
            listingArray={listingArray}
            propertyInformation={propertyInformation}
            dealFinance={dealFinance}
            monthlyTaxPayment={monthlyTaxPayment}
            propertyPermitAndFees={propertyPermitAndFees}
            remodelCost={remodelCost}
            frontendClosingCost={frontendClosingCost}
            backendClosingCost={backendClosingCost}
            carryingCost={carryingCost}
            operationalExpenses={operationalExpenses}
            printVersion={true}
            resultData={resultData}
            analyzerData={analyzerData}
            getAllLocalCalculations={getAllLocalCalculations}
          />
          <PrintPageFooter currentPage={2} total={5} />
        </div>
        <Box sx={{ pageBreakBefore: "always !important" }}></Box>
        <Box sx={{ height: "1.25rem" }} />
        <div style={{ position: "relative" }}>
          <ProformaLetterhead
            agent={user}
            address={address}
            dealType={dealType}
            noImage={true}
          />
          <ProformaPage3Type2
            frontendClosingCost={frontendClosingCost}
            backendClosingCost={backendClosingCost}
            otherExpenses={otherExpenses}
            estResaleValue={estResaleValue}
            rentalIncome={rentalIncome}
            capitalizationRate={capitalizationRate}
            netProfit={netProfit}
            investorsProfit={investorsProfit}
            carryingCost={carryingCost}
            operationalExpenses={operationalExpenses}
            printVersion={true}
            resultData={resultData}
            getAllLocalCalculations={getAllLocalCalculations}
          />
          <PrintPageFooter currentPage={3} total={5} />
        </div>
        <Box sx={{ pageBreakBefore: "always !important" }}></Box>
        <Box sx={{ height: "1.25rem" }} />
        <div style={{ position: "relative" }}>
          <ProformaLetterhead
            agent={user}
            address={address}
            dealType={dealType}
            noImage={true}
          />
          <ProformaPage4
            otherExpenses={otherExpenses}
            estResaleValue={estResaleValue}
            rentalIncome={rentalIncome}
            capitalizationRate={capitalizationRate}
            netProfit={netProfit}
            investorsProfit={investorsProfit}
            carryingCost={carryingCost}
            operationalExpenses={operationalExpenses}
            printVersion={true}
            resultData={resultData}
            getAllLocalCalculations={getAllLocalCalculations}
            analyzerData={analyzerData}
          />
          <PrintPageFooter currentPage={4} total={5} />
        </div>
        <Box sx={{ pageBreakBefore: "always !important" }}></Box>
        <Box sx={{ height: "1.25rem" }} />
        <div style={{ position: "relative" }}>
          <ProformaLetterhead
            agent={user}
            address={address}
            dealType={dealType}
            noImage={true}
          />
          <ProformaPage5
            investorsProfit={investorsProfit}
            analyzerData={analyzerData}
            getAllLocalCalculations={getAllLocalCalculations}
            printVersion
          />

          <PrintPageFooter currentPage={5} total={5} />
        </div>
      </Box>
    </Box>
  );
};

export default ProFormaReportPrint;
