export const presentPurchasePriceFuc = (PPP, TRC) => {
  // PPP primary purchase price
  // TRC total remodel cost
  let presentPurchasePrice = PPP || 0;

  return presentPurchasePrice;
};

export const futureValueOfHome = (presentPP, TIY) => {
  // presentPP presentPurchasePriceFuc
  // TIY time in year
  let futureValue = 0;

  const interestRate = 3.5;
  futureValue = presentPP * Math.pow(1 + interestRate / 100, TIY || 0);

  return futureValue;
};

export const assertAppreciationPercent = (FVH, PVH) => {
  //FVH future value of home
  //PVH present value of home
  let percent = (FVH - PVH) / (PVH * 100 || 1);
  // console.log(percent)
  return percent;
};

export const estResaleVal = (percent, presentPrice) => {
  const rate = (percent / 100) * presentPrice;

  return rate + presentPrice;
};

export const appreciatedResaleAmountCalc = (
  price,
  appreciation,
  monthsCarried
) => {
  let intiatialPrice = price;
  let i;
  const yearsCarried = Math.floor(monthsCarried / 12);
  const additionalMonths = monthsCarried % 12;

  for (i = 0; i < yearsCarried; i++) {
    price = price + price * (appreciation / 100);
  }

  if (additionalMonths > 0) {
    price = price + price * (appreciation / 100) * (additionalMonths / 12);
  }

  return {
    estimatedHomePrice: price,
    appreciatedAmount: price - intiatialPrice,
  };
};

//operational cost
export const operationalCost = (
  HoD,
  miscellaneous,
  architecturalFees,
  totalLoan
) => {
  // HoD => monthy hoa dues
  const cost =
    (HoD || 0) +
    (miscellaneous || 0) +
    (architecturalFees || 0) +
    (totalLoan || 0);
  return cost;
};

// closing cost
export const frontEndClosingCost = (
  lenderPoints,
  primaryLenderPointsOption,
  loanAmount,
  wholeFeesOptions,
  primaryWholesaleFees,
  taxes,
  owingMonths,
  closingCostOption,
  titleAndEscrowFees,
  purchaseClosingCostOption
) => {
  //  lenderPoints  ,
  //  primaryLenderPointsOption ,
  //  loanAmount ,
  //  wholeFeesOptions ,
  //  primaryWholesaleFees,
  //  taxes,
  //  owing months ,
  //  closingCostOption
  //  title and escrow fees,
  //  purchaseClosingCostOption,
  //  return closingCost
};
