import * as actionTypes from './dealAnalyzerTypes'

import * as questionnaireTypes from './dealAnalyzerQuestionnaireTypes'
import * as reportTypes from './dealAnalyzerReportTypes'


export const updatePropertyInformation = (payload) => ({ type: actionTypes.UPDATE_PROPERTY_INFORMATION, payload });
export const updateFinanceOptions = (payload) => ({ type: actionTypes.UPDATE_FINANCE_OPTIONS, payload });
export const updatePropertyPermitAndFees = (payload) => ({ type: actionTypes.UPDATE_PROPERTY_PERMIT_AND_FEES, payload });
export const updateTaxes = (payload) => ({ type: actionTypes.UPDATE_TAXES, payload });
export const updateRemodelCosts = (payload) => ({ type: actionTypes.UPDATE_REMODEL_COSTS, payload });
export const updateClosingCosts = (payload) => ({ type: actionTypes.UPDATE_CLOSING_COSTS, payload });
export const updateRentalIncome = (payload) => ({ type: actionTypes.UPDATE_RENTAL_INCOME, payload });
export const updatePropertyManagement = (payload) => ({ type: actionTypes.UPDATE_PROPERTY_MANAGEMENT, payload });
export const updateOtherCosts = (payload) => ({ type: actionTypes.UPDATE_OTHER_COSTS, payload });
export const updateCarryingMonths = (payload) => ({ type: actionTypes.UPDATE_CARRYING_MONTHS, payload });
export const updateEstimatedResaleValue = (payload) => ({ type: actionTypes.UPDATE_ESTIMATED_RESALE_VALUE, payload });
export const updateInvestorProfit = (payload) => ({ type: actionTypes.UPDATE_INVESTOR_PROFIT, payload });
export const updateInvestorProfitItems = (payload) => ({ type: actionTypes.UPDATE_INVESTOR_PROFIT_ITEMS, payload });
export const updateZillowComps = (payload) => ({ type: actionTypes.UPDATE_ZILLOW_COMPS_ITEMS, payload });
export const updateCustomComps = (payload) => ({ type: actionTypes.UPDATE_CUSTOM_COMPS, payload });
export const addInvestorProfitItem = (payload) => ({ type: actionTypes.ADD_INVESTOR_PROFIT_ITEM, payload });
export const deleteInvestorProfitItem = (payload) => ({ type: actionTypes.DELETE_INVESTOR_PROFIT_ITEM, payload });
export const updateSummaryText = (payload) => ({ type: actionTypes.UPDATE_SUMMARY_TEXT, payload });
export const updateDealAnalyzerReportId = (payload) => ({ type: actionTypes.UPDATE_ANALYZER_REPORT_ID, payload });
export const updateDealAnalyzerResults = (payload) => ({ type: actionTypes.UPDATE_DEAL_ANALYZER_RESULTS, payload });
export const updateAestheticItems = (payload) => ({ type: actionTypes.UPDATE_AESTHETIC_ITEMS, payload });
export const updateNonAestheticItems = (payload) => ({ type: actionTypes.UPDATE_NON_AESTHETIC_ITEMS, payload });
export const updateDemolishingCost = (payload) => ({ type: actionTypes.UPDATE_DEMOLISHING_COST, payload });
export const updateDevelopmentCost = (payload) => ({ type: actionTypes.UPDATE_DEVELOPMENT_COST, payload });
export const addToMessageQueue = (payload) => ({ type: actionTypes.ADD_TO_MESSAGE_QUEUE, payload })
export const updateMessageQueue = (payload) => ({ type: actionTypes.UPDATE_MESSAGE_QUEUE, payload })
export const restoreInitialState = (payload) => ({ type: actionTypes.RESTORE_INITIAL_STATE, payload })
export const updateAllState = (payload) => ({ type: actionTypes.UPDATE_ALL_STATE, payload })
export const updateDealAnalyzerOrderDetails = (payload) => ({ type: actionTypes.UPDATE_DEAL_ANALYZER_ORDER_DETAILS, payload })


export const updateQuestionnaireStraightAestheticRemodel = (payload) => ({ type: questionnaireTypes.UPDATE_STRAIGHT_AESTHETIC_REMODEL_QUESTIONNAIRE, payload })
export const updateQuestionnaireRemodelAddAddition = (payload) => ({ type: questionnaireTypes.UPDATE_REMODEL_ADD_ADDITION_QUESTIONNAIRE, payload })
export const updateQuestionnaireRemodelDownToStuds = (payload) => ({ type: questionnaireTypes.UPDATE_REMODEL_DOWN_TO_STUDS_QUESTIONNAIRE, payload })
export const updateQuestionnaireDownToStudsAddAddition = (payload) => ({ type: questionnaireTypes.UPDATE_DOWN_TO_STUDS_ADDITION_QUESTIONNAIRE, payload })
export const updateQuestionnaireNewConstruction = (payload) => ({ type: questionnaireTypes.UPDATE_NEW_CONSTRUCTION_QUESTIONNAIRE, payload })
export const updateQuestionnaireBuyAndRent = (payload) => ({ type: questionnaireTypes.UPDATE_BUY_AND_RENT_QUESTIONNAIRE, payload })
export const raiseInputCardError = (payload) => ({ type: actionTypes.RAISE_INPUT_CARD_ERROR, payload })
export const removeInputCardError = (payload) => ({ type: actionTypes.REMOVE_INPUT_CARD_ERROR, payload })

export const updateAllReports = (payload) => ({ type: reportTypes.UPDATE_ALL_REPORTS, payload })
export const updateCompledReports = (payload) => ({ type: reportTypes.UPDATE_COMPLETED_REPORTS, payload })
export const updateUncompledReports = (payload) => ({ type: reportTypes.UPDATE_UNCOMPLETED_REPORTS, payload })