import React, { useEffect } from 'react';
import { useState } from 'react';
import Button from '../../components/Button/Button';
import Spinner from '../../components/Spinner/Spinner';
import './bposub.scss';
import '../ChooseSubscription/choosesubscription.scss';
import '../ChoosePackage/choosepackage.scss';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import base from '../../apis';
import { Elements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { BiArrowBack } from 'react-icons/bi';
import { formatNumber } from '../../utils/functions';
import { Card, Divider, Grid, Typography, Radio, Stepper, Step, StepLabel, ToggleButtonGroup, ToggleButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/system';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { farmPackages as packages } from '../../constants';
import Checkbox from '../../components/Checkbox/Checkbox';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const useStyles = makeStyles((theme) => ({
	card: {
		padding: '20px',
	},
	titleText: {
		// fontSize: '1.5rem',
		fontWeight: 'bold',
		color: theme.secondaryColor,
		textTransform: 'uppercase',
		textAlign: 'center',
		display: 'block',
	},
	divider: {
		background: theme.secondaryColor,
		height: '2px',
		width: '5rem',
		margin: '10px auto ',
		display: 'block',
		border: 'none !important',
	},
	checkbox: {
		color: '#1378a5',
		fontSize: 'small',
	},
}));

function BPOSub() {
	const classes = useStyles();
	const purchaseDetail = useLocation().state;
	const navigate = useNavigate();
	const [farmPrices, setFarmPrices] = useState({
		// base_setup_price_id: '',
		// base_setup_price_amount: '',
		addon_setup_price_id: '',
		addon_setup_price_amount: '',
		standard: {
			monthly: {
				amount: '',
				priceId: '',
			},
			yearly: {
				amount: '',
				priceId: '',
			},
		},
		pro: {
			monthly: {
				amount: '',
				priceId: '',
			},
			yearly: {
				amount: '',
				priceId: '',
			},
		},
		elite: {
			monthly: {
				amount: '',
				priceId: '',
			},
			yearly: {
				amount: '',
				priceId: '',
			},
		},
		ultra: {
			monthly: {
				amount: '',
				priceId: '',
			},
			yearly: {
				amount: '',
				priceId: '',
			},
		},
	});

	useEffect(() => {
		if (!purchaseDetail) {
			toast.warn('Please select farms to purchase');
			navigate('/available-territories');
		}
		setIsLoading(true);
		const controller = new AbortController();

		base
			.get('/register/list_prices/', {
				signal: controller.signal,
			})
			.then((res) => {
				if (!res) return;
				const out = res?.data?.flat();
				//add product=bpo_combo_farm to metadata
				const farmProducts = out?.filter((item) => item.metadata?.product === 'bpo_combo_farm' && item.active);
				const standardMonthly = farmProducts.find(
					(item) => item.metadata?.package_grade === 'standard' && item.metadata?.recurring_type === 'monthly'
				);
				const ultraMonthly = farmProducts.find((item) => item.metadata?.package_grade === 'ultra' && item.metadata?.recurring_type === 'monthly');
				const eliteMonthly = farmProducts.find((item) => item.metadata?.package_grade === 'elite' && item.metadata?.recurring_type === 'monthly');
				const proMonthly = farmProducts.find((item) => item.metadata?.package_grade === 'pro' && item.metadata?.recurring_type === 'monthly');
				const standardYearly = farmProducts.find((item) => item.metadata?.package_grade === 'standard' && item.metadata?.recurring_type === 'yearly');
				const ultraYearly = farmProducts.find((item) => item.metadata?.package_grade === 'ultra' && item.metadata?.recurring_type === 'yearly');
				const eliteYearly = farmProducts.find((item) => item.metadata?.package_grade === 'elite' && item.metadata?.recurring_type === 'yearly');
				const proYearly = farmProducts.find((item) => item.metadata?.package_grade === 'pro' && item.metadata?.recurring_type === 'yearly');
				// const baseSetup = out.find((item) => item.metadata?.product === 'farm_setup');
				// const addOnSetup = out.find((item) => item.metadata?.product === 'farm_addon_setup');

				const toSet = {
					// base_setup_price_id: baseSetup?.id,
					// base_setup_price_amount: formatNumber(baseSetup?.unit_amount / 100),

					standard: {
						monthly: {
							amount: formatNumber(standardMonthly?.unit_amount / 100),
							priceId: standardMonthly?.id,
							amountToShow: formatNumber(299),
						},
						yearly: {
							amount: formatNumber(standardYearly?.unit_amount / 100),
							priceId: standardYearly?.id,
							amountToShow: formatNumber(3289),
						},
					},
					pro: {
						monthly: {
							amount: formatNumber(proMonthly?.unit_amount / 100),
							priceId: proMonthly?.id,
							amountToShow: formatNumber(399),
						},
						yearly: {
							amount: formatNumber(proYearly?.unit_amount / 100),
							priceId: proYearly?.id,
							amountToShow: formatNumber(4389),
						},
					},
					elite: {
						monthly: {
							amount: formatNumber(eliteMonthly?.unit_amount / 100),
							priceId: eliteMonthly?.id,
							amountToShow: formatNumber(499),
						},
						yearly: {
							amount: formatNumber(eliteYearly?.unit_amount / 100),
							priceId: eliteYearly?.id,
							amountToShow: formatNumber(5489),
						},
					},
					ultra: {
						monthly: {
							amount: formatNumber(ultraMonthly?.unit_amount / 100),
							priceId: ultraMonthly?.id,
							amountToShow: formatNumber(799),
						},
						yearly: {
							amount: formatNumber(ultraYearly?.unit_amount / 100),
							priceId: ultraYearly?.id,
							amountToShow: formatNumber(8789),
						},
					},
				};

				setFarmPrices(toSet);
			})
			.catch((err) => {
				toast.error('Could not get prices');
			})
			.finally(() => {
				setIsLoading(false);
			});

		return () => {
			controller.abort();
		};
	}, [purchaseDetail, navigate]);

	const [isLoading, setIsLoading] = useState(false);

	const [isMonthly, setIsMonthly] = useState(true);

	const [selectedPackages, setSelectedPackages] = useState({
		0: '',
		1: '',
		2: '',
		3: '',
	});

	const handleSelected = (index, id) => {
		setSelectedPackages((current) => ({ ...current, [index]: id }));
	};

	const handleBack = () => {
		if (activeStep > 0) {
			setActiveStep((prevActiveStep) => prevActiveStep - 1);
		} else {
			navigate('/available-territories');
		}
	};

	const [activeStep, setActiveStep] = useState(0);
	const [termsAgreed, setTermsAgreed] = useState(false);
	const [termsAgreed1, setTermsAgreed1] = useState(false);

	const handleIsMonthly = (e, value) => {
		setIsMonthly(value);
		setSelectedPackages({
			0: '',
			1: '',
			2: '',
		});
	};

	const handleNext = () => {
		if (!selectedPackages[activeStep]) {
			toast.warn('Please select a package', { toastId: 'select-package' });
			return;
		}
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
	};

	return (
		<>
			<Elements stripe={stripePromise}>
				{isLoading && <Spinner />}
				<div
					style={{
						backgroundColor: 'var(--primary-color)',
						color: 'white',
						padding: '10px',
						paddingLeft: '30px ',
						display: 'flex',
						alignItems: 'center',
						gap: '10px',
					}}
				>
					<BiArrowBack onClick={handleBack} style={{ cursor: 'pointer' }} /> Checkout
				</div>

				{purchaseDetail?.selectedTerritories.length > 1 && (
					<Stepper activeStep={activeStep} sx={{ paddingY: '10px', justifyContent: 'center' }} color='secondary'>
						{purchaseDetail?.selectedTerritories.map((territory, index) => (
							<Step key={territory.id}>
								<StepLabel>{territory.name}</StepLabel>
							</Step>
						))}
					</Stepper>
				)}
				{purchaseDetail?.selectedTerritories.map(
					(territory, index) =>
						index === activeStep && (
							<div key={territory.id}>
								<h1 style={{ textAlign: 'center', position: 'sticky', backgroundColor: 'white', padding: '10px', top: 0, zIndex: 2 }}>
									{territory.name}
								</h1>
								<Box display={'flex'} justifyContent='center' gap='20px' alignItems='center'>
									<p
										style={{ textAlign: 'center', margin: 0 }}
									>{`${purchaseDetail?.selectedTerritories?.[0]?.state}, ${purchaseDetail?.selectedTerritories?.[0]?.county}, ${purchaseDetail?.selectedTerritories?.[0]?.city}`}</p>
									{activeStep === 0 && (
										<ToggleButtonGroup size='small' color='secondary' value={isMonthly} exclusive onChange={handleIsMonthly}>
											<ToggleButton value={true}>Monthly</ToggleButton>
											<ToggleButton value={false}>Annual</ToggleButton>
										</ToggleButtonGroup>
									)}
								</Box>
								<Grid container spacing={2} sx={{ px: '2rem', m: 'auto', maxWidth: '1500px' }}>
									{packages.map((item, packageIndex) => {
										let value;
										switch (packageIndex) {
											case 0:
												value = 'standard';
												break;
											case 1:
												value = 'pro';

												break;
											case 2:
												value = 'elite';
												break;
											case 3:
												value = 'ultra';
												break;
											default:
												break;
										}
										const price =
											farmPrices?.[value][isMonthly ? 'monthly' : 'yearly']?.amountToShow ||
											farmPrices?.[value][isMonthly ? 'monthly' : 'yearly']?.amount;
										const id = farmPrices?.[value][isMonthly ? 'monthly' : 'yearly']?.priceId;
										return (
											<Grid item xs={12} sm={12} md={3} lg={3} key={item.title}>
												<Card
													id={id}
													sx={{
														borderColor: selectedPackages[index] === id ? 'var(--primary-color)' : 'white',
														borderWidth: '1px',
														borderStyle: 'solid',
														cursor: 'pointer',
													}}
													className={classes.card}
													onClick={() => handleSelected(index, id)}
												>
													<Typography paragraph gutterBottom className={classes.titleText}>
														{item.title}
													</Typography>
													<Divider className={classes.divider} />
													<Box display='flex' gap='20px' alignItems='center' width='fit-content' mx='auto' mb='30px !important'>
														<Radio checked={selectedPackages[index] === id} size={'small'} value={id} name='radio-buttons' color='secondary' />
														<Typography display='flex' gap='10px'>
															<Typography variant='span' sx={{ fontWeight: 'bolder', fontSize: '20px' }}>
																$ {price}
															</Typography>
															per {isMonthly ? 'month' : 'year'}
														</Typography>
													</Box>
													{item.benefits.map((benefit) => (
														<Box display='flex' alignItems='center' gap='10px' key={benefit} mb={'20px'}>
															<CheckCircleOutlineIcon className={classes.checkbox} />
															<Typography fontSize='15px'>{benefit}</Typography>
														</Box>
													))}
												</Card>
											</Grid>
										);
									})}
								</Grid>
							</div>
						)
				)}
				<Box display='flex' justifyContent='center'>
					{activeStep === purchaseDetail?.selectedTerritories.length - 1 ? (
						<Box display='flex' alignItems='center' flexDirection='column' mt='2rem' sx={{ maxWidth: '70%' }} gap='1rem'>
							<Checkbox
								text='By checking this you have agreed to the terms and conditions'
								checked={termsAgreed}
								setChecked={(e) => setTermsAgreed(e.target.checked)}
								labelStyle={{ marginRight: 'auto' }}
							/>
							<div>
								<Checkbox
									labelStyle={{ marginRight: 'auto', display: 'inline' }}
									checked={termsAgreed1}
									setChecked={(e) => setTermsAgreed1(e.target.checked)}
									text={`By providing my phone number to “BPO Homes”, I agree and acknowledge that “BPO Homes ” may send text messages to my wireless phone number for any purpose. Message and data rates may apply. Message frequency will vary, and you will be able to Opt-out by replying “STOP”. For more information on how your data will be handled please visit `}
								/>
								<NavLink to='/privacy-policy' className='blue_link'>
									https://bpotech.io/privacy-policy
								</NavLink>
							</div>
							<p style={{ margin: 'auto' }}>
								By placing the order you agree with our{' '}
								<NavLink to={`/terms-of-service`} className='blue_link'>
									Terms & Conditions
								</NavLink>{' '}
								and{' '}
								<NavLink to='/privacy-policy' className='blue_link'>
									Privacy Policy
								</NavLink>
							</p>
							<StripePay
								// base_setup_price_id={farmPrices?.base_setup_price_id}
								addon_setup_price_id={farmPrices?.addon_setup_price_id}
								selectedPackages={Object.values(selectedPackages)}
								addOnNumber={purchaseDetail?.selectedTerritories.length - 1}
								selectedFarms={purchaseDetail?.selectedTerritories.map((item) => item.id)}
								isMonthly={isMonthly}
								isChecked={termsAgreed && termsAgreed1}
							/>
						</Box>
					) : (
						<Button
							type='submit'
							style={{
								width: '250px',
								fontSize: '16px',
								padding: '10px 30px',
								backgroundColor: 'white',
								color: 'var(--primary-color)',
								display: 'block',
							}}
							text='NEXT STEP'
							onClick={handleNext}
						/>
					)}
				</Box>
			</Elements>
		</>
	);
}

export default BPOSub;

const StripePay = ({ base_setup_price_id, addon_setup_price_id, selectedFarms, isMonthly, addOnNumber, selectedPackages, isChecked }) => {
	const stripe = useStripe();

	const confirmPayment = () => {
		if (!isChecked) {
			toast.warn('Please agree to the terms and conditions');
			return;
		}
		if (selectedFarms.length !== selectedPackages.filter(Boolean).length) {
			toast.warn('Please select packages for all farms');
			return;
		}
		const body = {
			interval: isMonthly ? 'monthly' : 'yearly',
			price_data: [
				// {
				// 	price: base_setup_price_id,
				// 	quantity: selectedFarms.length,
				// },
			],
		};

		const chosenpackages = {};
		selectedPackages.filter(Boolean).forEach((farm) => {
			if (!chosenpackages[farm]) {
				chosenpackages[farm] = {
					price: farm,
					quantity: 1,
				};
			} else {
				chosenpackages[farm].quantity += 1;
			}
		});
		body.price_data.push(...Object.values(chosenpackages));
		body['extra_data'] = JSON.stringify(
			selectedFarms.map((farm, index) => ({
				farm_id: farm,
				price_id: selectedPackages[index],
			}))
		);

		base
			.post('/register/create_subscription/', {
				...body,
				cancel_url: window.location.origin + '/available-territories',
				success_url: window.location.origin + `/payment_success?type=BPO_Farm&`,
				payment_method_types: ['card'],
				mode: 'subscription',
				package: 'bpo-farm',
			})
			.then((res) => {
				if (!res?.data?.sessionId) {
					toast.error('Something went wrong');
					return;
				}
				stripe.redirectToCheckout({
					sessionId: res?.data?.sessionId,
				});
			})
			.catch((err) => {
				toast.error('Could not process payment');
			});
	};

	return (
		<Button
			style={{
				width: '250px',
				fontSize: '16px',
				padding: '10px 30px',
				color: 'white',
				backgroundColor: 'var(--primary-color)',
				display: 'block',
			}}
			onClick={confirmPayment}
			text={'CONFIRM PAYMENT'}
		/>
	);
};
