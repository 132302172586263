import { Close, KeyboardBackspace } from '@mui/icons-material';
import { Dialog, DialogContent, DialogTitle, IconButton, Stack, Typography } from '@mui/material';
import React from 'react';
import { Carousel } from 'react-responsive-carousel';

const ImagesCarousel = ({ open, close, images, startItem }) => {
	return (
		<Dialog open={open} onClose={close} maxWidth='md'>
			<DialogTitle>
				<Stack alignItems='center' justifyContent={'space-between'} direction='row' sx={{ borderBottom: 1, borderColor: 'divider' }}>
					<Typography variant='h3' fontWeight={700}>
						Images
					</Typography>
					<IconButton onClick={close}>
						<Close fontSize='small' />
					</IconButton>
				</Stack>
			</DialogTitle>
			<DialogContent>
				<Carousel
					autoPlay={false}
					infiniteLoop={true}
					showThumbs={false}
					stopOnHover={true}
					selectedItem={startItem || 0}
					interval={10000}
					showArrows={true}
					useKeyboardArrows={false}
					showStatus={false}
					showIndicators={false}
					autoFocus={true}
					animationHandler={'slide'}
					swipeable={true}
					renderArrowPrev={(onClickHandler, hasPrev) => {
						return (
							hasPrev && (
								<IconButton
									onClick={onClickHandler}
									sx={{
										borderRadius: '0',
										position: 'absolute',
										padding: '1.2rem',
										bottom: '45%',
										zIndex: '100',
										backgroundColor: '#00000080',
										'&:hover': { backgroundColor: '#00000080' },
									}}
								>
									<KeyboardBackspace sx={{ fill: 'white' }} fontSize='large' />
								</IconButton>
							)
						);
					}}
					renderArrowNext={(onClickHandler, hasNext) =>
						hasNext && (
							<IconButton
								onClick={onClickHandler}
								sx={{
									borderRadius: '0',
									position: 'absolute',
									padding: '1.2rem',
									bottom: '45%',
									right: 0,
									zIndex: '100',
									backgroundColor: '#00000080',
									'&:hover': { backgroundColor: '#00000080' },
								}}
							>
								<KeyboardBackspace sx={{ fill: 'white', transform: 'rotate(180deg)' }} fontSize='large' />
							</IconButton>
						)
					}
				>
					{images.map((image) => (
						<img
							src={image.image}
							key={image.image}
							alt='listing'
							style={{ width: '100%', objectFit: 'cover', borderRadius: '5px', aspectRatio: '1.5' }}
						/>
					))}
				</Carousel>
			</DialogContent>
		</Dialog>
	);
};

export default ImagesCarousel;
