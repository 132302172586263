import { DescriptionOutlined, Drafts, Phone } from '@mui/icons-material';
import { Avatar, Box, Button, Card, CardContent, Link, Popover, Stack, Typography, useMediaQuery } from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import {
	EmailIcon,
	EmailShareButton,
	FacebookIcon,
	FacebookShareButton,
	LinkedinIcon,
	LinkedinShareButton,
	TwitterIcon,
	TwitterShareButton,
} from 'react-share';
import { formatPhoneNumber } from '../../utils/functions';
import { URL, statesShort } from '../../constants';

const pageUrl = window.location.href;

const AgentAd = () => {
	const [anchorEl, setAnchorEl] = useState(null);
	const [agent, setAgent] = useState(null);
	const { id: agentId } = useParams();
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		(async () => {
			setIsLoading(true);
			try {
				const { data } = await axios.get(URL + `register/agents/${agentId}/`);
				setAgent(data);
				setIsLoading(false);
			} catch (error) {}
		})();
	}, [agentId]);
	const handlePopoverOpen = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handlePopoverClose = () => {
		setAnchorEl(null);
	};
	const open = Boolean(anchorEl);
	const image = agent?.profile_image || agent?.office_logo;
	const name = agent?.first_name + ' ' + agent?.last_name;
	const email = agent?.user?.email;
	const title = `${agent?.city}, ${statesShort?.find((item) => item.label.toLowerCase() === agent?.state)?.value || agent?.state}`;
	const phone = formatPhoneNumber(agent?.phone);
	const matches = useMediaQuery('(min-width:1300px)');

	const Show = (
		<Card
			elevation={1}
			sx={{
				pointerEvents: 'auto',
				display: 'flex',
				borderRadius: '10px',
				margin: '1rem',
				position: 'relative',
				boxShadow: '0px 4px 20px 0px #00000026',
				overflow: 'initial',
				'&::before': {
					content: '""',
					display: !matches ? 'block' : 'none',
					position: 'absolute',
					top: '13%',
					right: '-6px',
					borderTop: '10px solid transparent',
					borderBottom: '10px solid transparent',
					borderLeft: '10px solid white',
				},
			}}
			onClick={(e) => e.stopPropagation()}
		>
			<CardContent
				sx={{
					width: '15rem',
					padding: '1rem',
					flexDirection: 'column',
					alignItems: 'center',
					gap: '1rem',
					justifyContent: 'center',
					textAlign: 'center',
					fontFamily: 'Lato',
				}}
			>
				<Avatar
					sx={{
						width: '90px',
						height: '90px',
						margin: 'auto',
					}}
					src={image}
					alt='agent'
					imgProps={{
						style: {
							objectFit: 'cover',
							objectPosition: 'top center',
						},
					}}
				/>
				<Typography variant='h6' sx={{ fontWeight: 'bold', mt: '1rem', fontSize: '18px', fontFamily: 'Lato' }}>
					{name}
				</Typography>
				<Typography variant='body1' fontWeight={300} fontSize='14px' fontFamily='Lato'>
					{title}
				</Typography>
				<Typography
					variant='body1'
					fontWeight='300'
					fontSize='14px'
					sx={{
						display: 'flex',
						cursor: 'pointer',
						color: 'black',
						alignItems: 'center',
						textDecoration: 'none',
						justifyContent: 'center',
						mt: '1rem',
						fontFamily: 'Lato',
					}}
					component={Link}
					href={`tel:${agent?.phone}`}
				>
					<Phone sx={{ fontSize: '1rem', marginRight: '5px', fill: '#005EA9' }} />
					{phone}
				</Typography>
				<Typography
					variant='body1'
					fontWeight='300'
					fontSize='14px'
					fontFamily='Lato'
					sx={{ display: 'flex', cursor: 'pointer', color: 'black', alignItems: 'center', textDecoration: 'none', justifyContent: 'center' }}
					component={Link}
					href={`mailto:${email}`}
				>
					<Drafts sx={{ fontSize: '1rem', marginRight: '5px', fill: '#005EA9' }} />
					{email}
				</Typography>
				<Button
					variant='contained'
					disableElevation
					fullWidth
					color='primary'
					href={`/order-bpo-report/${agentId}`}
					sx={{
						textTransform: 'none',
						borderRadius: '40px',
						color: 'white',
						fontWeight: '200',
						fontSize: '14px',
						my: '1rem',
						fontFamily: 'Lato',
					}}
					startIcon={<DescriptionOutlined sx={{ fill: 'white', fontSize: '14px' }} />}
				>
					Order A BPO Report
				</Button>
				<Stack direction='row' spacing={1} justifyContent={'center'}>
					<FacebookShareButton url={pageUrl}>
						<FacebookIcon size={30} borderRadius='10px' />
					</FacebookShareButton>
					<LinkedinShareButton url={pageUrl}>
						<LinkedinIcon size={30} borderRadius='10px' />
					</LinkedinShareButton>
					<EmailShareButton url={pageUrl}>
						<EmailIcon size={30} borderRadius='10px' />
					</EmailShareButton>
					<TwitterShareButton url={pageUrl}>
						<TwitterIcon size={30} borderRadius='10px' />
					</TwitterShareButton>
				</Stack>
			</CardContent>
		</Card>
	);

	return (
		<>
			{!isLoading && (
				<>
					<Box sx={{ position: 'fixed', right: matches ? 0 : '1rem', top: '30vh', zIndex: '100' }}>
						{!matches ? (
							<Avatar
								onClick={handlePopoverOpen}
								sx={{
									backgroundColor: 'white',
									cursor: 'pointer',
									width: {
										xl: '80px',
										lg: '80px',
										md: '80px',
										sm: '50px',
										xs: '50px',
									},
									height: {
										xl: '80px',
										lg: '80px',
										md: '80px',
										sm: '50px',
										xs: '50px',
									},

									border: '1px solid white',
									boxShadow: '0px 3.7383177280426025px 9.345794677734375px 0px #00000026',
								}}
								src={image}
								alt='agent'
								imgProps={{
									style: {
										objectFit: 'cover',
										objectPosition: 'top center',
									},
								}}
							/>
						) : (
							Show
						)}
					</Box>
					<Popover
						id='mouse-over-popover'
						sx={{ marginLeft: '5px' }}
						open={open}
						anchorEl={anchorEl}
						anchorOrigin={{
							vertical: 'top',
							horizontal: 'left',
						}}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'right',
						}}
						onClose={handlePopoverClose}
						PaperProps={{
							style: {
								backgroundColor: 'transparent',
								marginLeft: '-0.5rem',
								borderRadius: 0,
								pointerEvents: 'auto !important',
								boxShadow: 'none',
								marginTop: '-2rem',
							},
						}}
						// boxShadow: '0px 4px 20px 0px #00000026'
					>
						{Show}
					</Popover>
				</>
			)}
			<Outlet />
		</>
	);
};

export default AgentAd;
