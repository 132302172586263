import { Check, Close } from '@mui/icons-material'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, ImageList, ImageListItem, TextField, Typography, } from '@mui/material'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
//import { useParams } from 'react-router-dom';
import { makeStyles } from "@mui/styles"
import base from '../../../../../apis';
import { grey } from '@mui/material/colors';
import { toast } from 'react-toastify';
//import { commaReplace } from '../../../../../utils/functions';
import '../../../../../pages/CreateBpoReport/createbporeport.scss';
import * as actionCreators from "../../../../../actions/dealAnalyzer/index";
import { ClipLoader } from 'react-spinners';
import useCreateAlert from '../../../hooks/useCreateAlert';



const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: '60vh',
        padding: '20px',
    },
    stepButton: {
        width: '80%',
        justifyContent: 'flex-start',
        textTransform: 'none',
        fontWeight: '400',
        color: grey['500'],
        position: 'relative',
        '&:hover': {
            backgroundColor: grey['200'],
        },
        '&[data-selected=true]': {
            color: 'black',
            fontWeight: '500',
            fontSize: '1.1rem',
            '&::before': {
                content: '""',
                position: 'absolute',
                left: '-10px',
                top: '50%',
                transform: 'translateY(-50%)',
                width: '5px',
                height: '100%',
                backgroundColor: theme.palette.secondary.main,
            },
        },
        margin: '10px 0',
    },
    stepButton1: {
        width: '40%',
        justifyContent: 'flex-start',
        textTransform: 'none',
        fontWeight: '400',
        color: grey['500'],
        position: 'relative',
        marginLeft: '1.5rem',
        '&:hover': {
            backgroundColor: grey['200'],
        },
        '&[data-selected=true]': {
            color: 'black',
            fontWeight: '500',
            fontSize: '1.1rem',
        },
    },
    card: {
        padding: '20px',
        height: '55vh',
        overflowY: 'auto',
        scrollBehavior: 'smooth',
    },
    compButton: { marginRight: 'auto', marginBottom: '1rem', display: 'block', textTransform: 'none' },
    listItemImage: {
        position: 'relative',
        '&:hover #delete': {
            display: 'block',
        },
        '& #delete': {
            position: 'absolute',
            top: '10px',
            right: '10px',
            display: 'none',
            cursor: 'pointer',
            // fill:'white',
            fill: '#d52121',
        },
    },
    tick: {
        //center with transform
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },
    linkButton: {
        color: '#1378a5',


    }
}));




export const ZillowSearchModal = ({ showModel }) => {

    const analyzerData = useSelector((state) => state?.straightAestheticRemodel);
    const zillowComp = analyzerData?.estimatedResaleValue?.zillowComps || []
    const { createAlert } = useCreateAlert();

    //   const card = React.useRef(null);
    const classes = useStyles();
    const formState = (1);

    const [zillowUrl, setZillowUrl] = useState('');
    const [imageModalOpen, setImageModalOpen] = useState(false);
    const [zillowModalOpen, setZillowModalOpen] = useState(true);
    const [selectedImages, setSelectedImages] = useState([]);
    const [listingDetails, setListingDetails] = useState('')
    const [loading, setLoading] = useState(false)

    const dispatch = useDispatch()

    const onZillowModalClose = (all) => {
        setSelectedImages([]);
        setImageModalOpen(false);
        if (all) {
            setZillowModalOpen(false);
            setZillowUrl('');
        }
    };



    const toggleImage = (url) => {
        if (selectedImages.includes(url)) {
            setSelectedImages(selectedImages.filter((image) => image !== url));
        } else {
            if (selectedImages.length === 3) {
                createAlert("Max of 3 images allowed", "warning");
                return;
            }
            setSelectedImages([...selectedImages, url]);
        }
    };

    const onSaveZillowItem = (listingDetailsOriginal = listingDetails) => {
        const lotSize = listingDetailsOriginal.lot_size ? +listingDetailsOriginal.lot_size.split(' ')[0].replace(/,/g, '') : 0;
        let sales_price = listingDetailsOriginal?.sales_price?.toFixed(2);
        let gross_living_area = listingDetailsOriginal?.gross_living_area?.toFixed(2);
        let lot_size = lotSize?.toFixed(2);
        let num_bedrooms = listingDetailsOriginal?.num_bedrooms?.toFixed(2);
        let num_bathrooms = listingDetailsOriginal?.num_bathrooms?.toFixed(2);
        let description = listingDetailsOriginal?.description;
        let address = listingDetailsOriginal?.address;
        let price_per_gross_living_area = Math.round(listingDetailsOriginal.price_per_gross_living_area);
        let images = selectedImages


        const newZillowComps = {
            sales_price: sales_price,
            gross_living_area: gross_living_area,
            lot_size,
            num_bedrooms,
            num_bathrooms,
            description,
            address,
            price_per_gross_living_area,
            images,
            new: true
        }

        // dispatch the actions 

        //   setSaveComp([...saveComp, newZillowComps])
        dispatch(actionCreators.updateEstimatedResaleValue({ zillowComps: [...zillowComp, newZillowComps] }))
        setSelectedImages([]);
        setImageModalOpen(false);
        setZillowModalOpen(false);
        setListingDetails(null);
        setZillowUrl('');
        showModel()
        // setHasChanged(true);
        //set images to empty after setting
    };







    const getZillowListing = async () => {
        //mui textfield url validation
        if (!zillowUrl || !zillowUrl.includes('https')) {
            createAlert('Please enter a valid Zillow URL', "warning");
            return;
        }


        try {
            setLoading(true)
            const { data } = await base.post('/leads/fetch-zillow-data-active-closed/', {
                zillow_url: zillowUrl,
                type: formState === 2 ? 'closed' : 'active',
            });
            setLoading(false)
            //if more than 3 images, open images modal for selection else just add listing
            const images = data.images;
            setListingDetails(data);
            if (images.length > 3) {
                setImageModalOpen(true);
                return;
            }
            setSelectedImages(images);
            onSaveZillowItem(data);
            //save listing to item
        } catch (error) {
            toast.error('Something went wrong');
        } finally {

            setLoading(false)
        }

    };

    return (
        <>

            <Dialog open={zillowModalOpen} maxWidth='sm' fullWidth onClose={() => onZillowModalClose(true)}>
                <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Box display='flex' justifyContent='space-between' alignItems='center' marginRight='40px' flex='1' padding={1}>
                        <Typography variant='h4'>Add from Zillow Url</Typography>
                    </Box>
                    <IconButton onClick={() => onZillowModalClose(true)}>
                        <Close fontSize='small' />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={2}>
                        <Grid item xs={10}>
                            <TextField
                                inputProps={{ style: { fontSize: 14 } }}
                                fullWidth
                                placeholder='Zillow Url'
                                size='small'
                                color='secondary'
                                value={zillowUrl}
                                onChange={(e) => setZillowUrl(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Button sx={StyleSheet.linkButton} style={{ fontSize: '10px', px: '2px' }} variant='contained' onClick={getZillowListing}>

                                {!loading ?
                                    <> Add link</> :
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            px: 2
                                        }}
                                    >
                                        <ClipLoader color={"#fff"} loading={true} size={20} />
                                    </Box>}

                            </Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>

            {/* Zillow Image Selection popUP */}
            <Dialog open={imageModalOpen} maxWidth='md' fullWidth onClose={() => onZillowModalClose()}>
                <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Box display='flex' justifyContent='space-between' alignItems='center' marginRight='40px' flex='1' padding={1}>
                        <Typography variant='h4'> 📷 Help us make your Report awesome! Pick up to 3 images you love for this listing</Typography>
                    </Box>
                    <IconButton onClick={() => onZillowModalClose()}>
                        <Close fontSize='small' />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers sx={{ maxHeight: '500px', overflowY: 'auto' }}>
                    {listingDetails && listingDetails?.images.length > 0 && (
                        <ImageList sx={{ mx: 'auto', overflowY: 'unset' }} cols={3} rowHeight={200}>
                            {listingDetails?.images.map((item) => (
                                <ImageListItem key={item} sx={{ position: 'relative' }} onClick={() => toggleImage(item)}>
                                    <img
                                        src={`${item}`}
                                        alt={'Property'}
                                        style={{
                                            cursor: 'pointer',
                                            border: '2px solid white',
                                            borderRadius: '5px',
                                            aspectRatio: '4/3',
                                            opacity: selectedImages.includes(item) ? 0.5 : 1,
                                        }}
                                    />
                                    {selectedImages.includes(item) && <Check color='secondary' className={classes.tick} fontSize='large' />}
                                </ImageListItem>
                            ))}
                        </ImageList>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button color='primary' variant='contained' onClick={() => onSaveZillowItem()}>
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
