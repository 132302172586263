import { Avatar, Button, createTheme, Divider, Grid, IconButton, Stack, ThemeProvider, Typography, Link as NLink } from '@mui/material';
import { makeStyles, styled } from '@mui/styles';
import React, { Fragment } from 'react';
import { Link as RLink } from 'react-router-dom';
import { ReactComponent as FBIcon } from '../images/facebookfillable.svg';
import { ReactComponent as IGIcon } from '../images/instagramfillable.svg';
import { ReactComponent as LIIcon } from '../images/linkedInfillable.svg';
import { ReactComponent as TWIcon } from '../images/twitterfillable.svg';
import { ReactComponent as YTIcon } from '../images/youtubefillable.svg';
import { theme } from '../theme';
import { Spoiler } from './IndustryProfessionalId';
import RequestAQuote from '../components/RequestAQuote';

// !Estate Planning
// https://www.figma.com/design/Q83dnbULW6YZeYlFf9qbZX/Premier-Agent?node-id=787-2260&t=TJiE4dwbEONU4H4u-4

const pageTheme = createTheme({
	...theme,
	typography: {
		fontFamily: 'Inter, serif',
		button: {
			textTransform: 'capitalize',
		},
	},
	palette: {
		primary: {
			main: '#283FB6',
		},
		secondary: {
			main: '#000',
		},
		text: {
			main: '#000',
		},
		paper: {
			main: '#fff',
		},
	},
	primaryColor: '#283FB6',
	secondaryColor: '#000',
});

const mainPaddingX = '10rem';

const useStyles = makeStyles((theme) => ({
	root: {
		fontFamily: 'Inter, serif',
		scrollMarginTop: '100px',
		minHeight: '100vh',
	},
	leftLinks: {
		display: 'flex',
		alignItems: 'center',
		color: '#000000 !important',
		fontFamily: 'Inter, serif',
		fontWeight: 500,
		cursor: 'pointer',
	},
	main: {
		backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),url(https://bpooperationsmain.s3.eu-west-2.amazonaws.com/ind-professional/ind5background.jpg)`,
		// width: '100vw',
		padding: `1rem ${mainPaddingX}`,
		height: '100vh',
		backgroundSize: 'cover',
		backgroundPosition: 'center',
		borderRadius: '8px',
	},
}));

const socials = [
	{
		key: 'facebook',
		Icon: <FBIcon fill='#FFFFFF' />,
	},
	{
		key: 'twitter',
		Icon: <TWIcon fill='#FFFFFF' />,
	},
	{ key: 'instagram', Icon: <IGIcon fill='#FFFFFF' /> },
	{
		key: 'linkedin',
		Icon: <LIIcon fill='#FFFFFF' />,
	},
	{
		key: 'youtube',
		Icon: <YTIcon fill='#FFFFFF' />,
	},
];

const navItems = [
	{ label: 'Home', value: '#home' },
	{ label: 'Request A Quote', value: '#quote' },
	{ label: 'About Us', value: '#about-us' },
];
const Link = styled(RLink)(({ theme }) => ({
	cursor: 'pointer',
	color: theme.primaryColor,
}));

const IndProfTemp5 = ({ address, icon_file, company_name, id, formatted_phone_number, user, bio, ...profDetails }) => {
	const classes = useStyles();
	return (
		<ThemeProvider theme={pageTheme}>
			<Stack classes={classes.root}>
				<Stack direction='row' justifyContent='space-between' sx={{ backgroundColor: 'white' }} p='1rem 3rem '>
					<Stack direction='row' gap='1rem' alignItems={'center'}>
						<Avatar src={icon_file} />
						<Typography fontWeight={700} fontSize='30px'>
							{company_name}
						</Typography>
					</Stack>
					<Stack direction='row' gap='1rem'>
						{navItems.map((item) => (
							<NLink href={item.value} className={classes.leftLinks} underline='none' key={item.value}>
								{item.label}
							</NLink>
						))}
						<Button variant='contained' color='primary' href='#quote'>
							Contact Us
						</Button>
					</Stack>
				</Stack>
				<Stack className={classes.main} justifyContent='center' gap='1rem' alignItems='flex-start'>
					<Typography color='white' fontSize={60} fontWeight={600}>
						Dedicated To One <br /> Client At A Time
					</Typography>
					<Typography color='white' fontWeight={300} fontSize={30}>
						We can handle your real estate
						<br /> matters and provide you with
						<br /> representation in any disputes.
					</Typography>
					<Button href='quote' variant='contained'>
						Request A Quote
					</Button>
				</Stack>
				<Stack p={`1rem ${mainPaddingX}`} direction='row' alignItems='center' mt='2rem' gap='2rem' id='about-us'>
					<Stack sx={{ flex: 1 }}>
						<Spoiler text={bio} />
					</Stack>

					<Stack sx={{ flex: 1 }} alignItems={'center'} justifyContent={'center'}>
						<img
							src='https://bpooperationsmain.s3.eu-west-2.amazonaws.com/ind-professional/ind5little.jpg'
							style={{ height: '400px', aspectRatio: '1.11', objectFit: 'cover' }}
							alt='lesson'
						/>
					</Stack>
				</Stack>
				<Grid container spacing={2} alignItems='center' p='2rem 5rem' justifyContent={'center'} mt='4rem'>
					<Grid item xs={6}>
						<Typography fontSize='50px' textAlign={'center'} mb='2rem' id='quote' fontWeight={700}>
							Want A Quote?
						</Typography>
						<RequestAQuote withMargin={false} id={id} />
					</Grid>
					<Grid item xs={6}>
						<img
							alt='hand'
							src='https://bpooperationsmain.s3.eu-west-2.amazonaws.com/ind-professional/2ccd36e6de29d6e1f2277624db91b706.jpg'
							style={{ maxWidth: '400px', aspectRatio: '1', borderRadius: '8px', objectFit: 'cover', margin: 'auto', display: 'flex' }}
						/>
					</Grid>
				</Grid>
				<Stack sx={{ paddingX: mainPaddingX }}>
					<Grid container spacing={2} my='5rem' className={classes.color}>
						<Grid item xs={12}>
							<Divider sx={{ background: 'white' }} />
						</Grid>
						<Grid item xs={4}>
							<Stack gap='1rem'>
								<Avatar src={icon_file} />

								<Typography fontWeight={700} fontSize='30px'>
									{company_name}
								</Typography>
								<Stack direction='row' gap='1rem'>
									{socials.map((social) => (
										<Fragment key={social.key}>
											{profDetails?.[social.key] && (
												<IconButton href={profDetails?.[social.key]} target='_blank'>
													{social.Icon}
												</IconButton>
											)}
										</Fragment>
									))}
								</Stack>
							</Stack>
						</Grid>
						<Grid item xs={4}>
							<Stack gap='1rem'>
								<Typography fontWeight={600} fontSize='20px'>
									{address}
								</Typography>
								<Typography sx={{ cursor: 'pointer' }} component='a' href={`tel: ${formatted_phone_number}`}>
									Phone: {formatted_phone_number}
								</Typography>
								<Typography component='a' href={`mailto: ${user?.email}`} sx={{ cursor: 'pointer' }}>
									Email: {user?.email}
								</Typography>
							</Stack>
						</Grid>
						<Grid item xs={4}>
							<Stack gap='1rem'>
								<Typography fontWeight={600} fontSize='20px'>
									Navigation
								</Typography>
								{navItems.map((item) => (
									<Link to={item.value} underline='none' key={item.value} style={{ fontWeight: 400, color: 'black' }}>
										{item.label}
									</Link>
								))}
							</Stack>
						</Grid>

						<Grid item xs={12} mt='3rem'>
							<Stack direction={'row'} justifyContent={'space-between'}>
								<Typography>&copy; {new Date().getFullYear()} BPO Tech All rights reserved.</Typography>
								<Stack direction='row' gap='1rem'>
									<Link to='/privacy-policy' style={{ color: 'black' }}>
										Privacy Policy
									</Link>
									<Link to='/terms-of-service' style={{ color: 'black' }}>
										Terms and Conditions
									</Link>
								</Stack>
							</Stack>
						</Grid>
					</Grid>
				</Stack>
			</Stack>
		</ThemeProvider>
	);
};

export default IndProfTemp5;
