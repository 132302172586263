import { ReactComponent as Back } from '../../images/back.svg';
import { useNavigate } from 'react-router-dom';
import './listingsoverview.scss';
import Button from '../../components/Button/Button';
import { useCallback, useState } from 'react';
import Modal from '../../components/Modal/Modal';
import Input from '../../components/Input/Input';
import { useEffect } from 'react';
import base from '../../apis';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Spinner from '../../components/Spinner/Spinner';
import DeleteIcon from '@mui/icons-material/Delete';
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Button as ButtonMat,
	Box,
	Typography,
	IconButton,
	FormLabel,
	TextField,
	Grid,
	Select,
	MenuItem,
	CircularProgress,
	Popover,
	Card,
	CardContent,
	Tooltip,
} from '@mui/material';
import { commaReplace } from '../../utils/functions';
import { Close, Visibility } from '@mui/icons-material';
import MarkChatReadIcon from '@mui/icons-material/MarkChatRead';

export default function ListingsOverview() {
	const navigate = useNavigate();
	const [openModal, setOpenModal] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
	const agent_id = useSelector((state) => state.auth.typeId);
	const [listings, setListings] = useState({
		active: [],
		sold: [],
	});

	const getListings = useCallback(() => {
		if (!agent_id) return;
		setIsLoading(true);
		base
			.get(`/premiersite/listings/`, {
				params: {
					agent_id: agent_id,
				},
			})
			.then(({ data }) => {
				setListings({
					active: data.filter((listing) => !listing.date_sold),
					sold: data.filter((listing) => listing.date_sold),
				});
				if (!data.length) {
					toast.warn('You have no listings.');
				}
			})
			.catch((err) => {
				toast.error('Could not get listings.');
			})
			.finally(() => {
				setIsLoading(false);
			});
	}, [agent_id]);

	useEffect(() => {
		getListings();
	}, [getListings]);

	const [soldDetails, setSoldDetails] = useState({
		date_sold: '',
		sold_price: '',
	});
	const [selectedListing, setSelectedListing] = useState(null);
	const [isActive, setIsActive] = useState(false);
	const onOpen = (listing, active) => {
		setIsActive(active);
		setSelectedListing(listing);
		setOpenModal(true);
		setSoldDetails({
			date_sold: active ? listing.date_sold || '' : listing.list_date || '',
			sold_price: listing.sold_price || '',
		});
	};

	const onChangeSoldDetail = (e) => {
		const { name, value } = e.target;
		const newValue = name === 'sold_price' ? commaReplace(value) : value;
		setSoldDetails((prevState) => ({
			...prevState,
			[name]: newValue,
		}));
	};

	const onClose = () => {
		setOpenModal(false);
		setSelectedListing(null);
		setSoldDetails({
			date_sold: '',
			sold_price: '',
		});
	};

	const onSaveSold = (e, listing, active) => {
		e?.preventDefault();
		setIsLoading(true);
		setSelectedListing(listing);
		const body = {};
		if (isActive) {
			body.sold_price = Number(`${soldDetails.sold_price}`?.replace(/,/g, ''));
			body.date_sold = soldDetails.date_sold;
		} else {
			body.sold_price = null;
			body.date_sold = null;
			body.list_date = soldDetails.date_sold;
		}

		const listingId = selectedListing?.id || listing?.id;
		base
			.patch(`/premiersite/listings/${listingId}/`, body)
			.then(({ data }) => {
				setOpenModal(false);
				getListings();
			})
			.catch((err) => {
				toast.warn('Could not mark as sold');
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const handleDelete = () => {
		setIsLoading(true);
		base
			.delete(`/premiersite/listings/${listingToDelete?.id}/`)
			.then(({ data }) => {
				const active = listings.active.filter((listing) => listing.id !== listingToDelete.id);
				const sold = listings.sold.filter((listing) => listing.id !== listingToDelete.id);
				toast.success('Listing deleted successfully');
				setListings({ active, sold });
				setConfirmDeleteModal(false);
			})
			.catch((err) => {
				toast.error('Could not delete listing');
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const [listingToDelete, setListingToDelete] = useState(null);
	const startDeletion = (listing) => {
		setConfirmDeleteModal(true);
		setListingToDelete(listing);
	};

	const [isZillowModalOpen, setIsZillowModalOpen] = useState(false);
	const [forZillow, setForZillow] = useState({
		url: '',
		rep: 'Buyer Agent',
	});
	const [zillowLoading, setZillowLoading] = useState(false);

	const getZillow = async () => {
		if (!forZillow.url) {
			toast.warn('Please enter a zillow url.');
			return;
		}
		if (!forZillow.rep) {
			toast.warn('Please select a representation.');
			return;
		}
		//check if url ends with / if not add it
		let url = forZillow.url;
		if (url[url.length - 1] !== '/') {
			url += '/';
		}
		setZillowLoading(true);
		try {
			await base.post('/premiersite/fetch-zillow-data/', {
				zillow_url: url,
				agent_id,
				agent_role: forZillow.rep,
			});
			toast.success('Listing added successfully.');
			getListings();
			setZillowLoading(false);
			setIsZillowModalOpen(false);

			//navgate back
		} catch (error) {
			toast.warn('Could not get listing from zillow');
		} finally {
			setZillowLoading(false);
		}
	};

	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);

	const id = open ? 'simple-popover' : undefined;
	const [counts, setCounts] = useState({ page_count: 10000, contact_count: 10000 });
	const handlePopoverOpen = (e, { page_count, contact_count }) => {
		setCounts({ page_count, contact_count });
		setAnchorEl(e.currentTarget);
	};

	const handlePopoverClose = () => {
		setAnchorEl(null);
	};

	return (
		<>
			{isLoading && <Spinner />}
			<Dialog open={isZillowModalOpen} onClose={() => setIsZillowModalOpen(false)} fullWidth>
				<DialogTitle>
					<Box display='flex' justifyContent='space-between' alignItems='center'>
						<Typography variant='body2'>Enter zillow url</Typography>
						<IconButton onClick={() => setIsZillowModalOpen(false)}>
							<Close fontSize='small' />
						</IconButton>
					</Box>
				</DialogTitle>
				<DialogContent>
					<Grid container spacing={2}>
						<Grid item xs={9}>
							<FormLabel>Zillow URL</FormLabel>
							<TextField
								fullWidth
								size='small'
								variant='outlined'
								value={forZillow.url}
								onChange={(e) => setForZillow((cur) => ({ ...cur, url: e.target.value }))}
							/>
						</Grid>
						<Grid item xs={3}>
							<FormLabel>Representation</FormLabel>
							<Select
								size='small'
								fullWidth
								displayEmpty
								variant='outlined'
								value={forZillow.rep}
								onChange={(e) => {
									setForZillow((cur) => ({ ...cur, rep: e.target.value }));
								}}
							>
								{['Buyer Agent', 'Seller Agent']?.map((el) => {
									return (
										<MenuItem value={el} key={el}>
											{el === '' ? 'Select' : el}
										</MenuItem>
									);
								})}
							</Select>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions sx={{ paddingRight: 2 }}>
					<ButtonMat
						sx={{ width: 'fit-content', display: 'block', marginLeft: 'auto' }}
						variant='contained'
						color='error'
						onClick={() => setIsZillowModalOpen(false)}
					>
						Close
					</ButtonMat>
					<ButtonMat sx={{ width: 'fit-content', display: 'block', marginLeft: 'auto' }} variant='contained' color='secondary' onClick={getZillow}>
						Add
					</ButtonMat>
				</DialogActions>
			</Dialog>
			<Dialog open={zillowLoading} onClose={() => setZillowLoading(false)}>
				<DialogContent>
					<Grid container spacing={2}>
						<Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
							Please wait while we fetch your listing info and pictures from zillow
						</Grid>
						<Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }} marginY={4}>
							<CircularProgress color='secondary' size={50} />
						</Grid>
					</Grid>
				</DialogContent>
			</Dialog>
			<Modal style={{ minWidth: '40vw' }} open={openModal} onClose={onClose}>
				<div className='aomodal' style={{ height: 'fit-content', paddingBottom: '10px' }}>
					<div className='modheader'></div>
					<form className='modcontent' onSubmit={onSaveSold} style={{ padding: '20px' }}>
						<p>{selectedListing?.address}</p>
						<div className='hr' />
						<div className='property_info'>
							<div className='sold_date'>
								<p>{isActive ? 'Date Sold' : 'Listing Date'}</p>
								<Input type='date' style={{ width: '100' }} value={soldDetails.date_sold} name='date_sold' onChange={onChangeSoldDetail} required />
							</div>
							{isActive && (
								<div className='sold_price'>
									<p>Sold Price</p>
									<Input placeholder='$' value={soldDetails.sold_price} name='sold_price' onChange={onChangeSoldDetail} required />
								</div>
							)}
						</div>

						<div className='buttons'>
							<Button
								style={{
									width: 'fit-content',
									color: 'white',
									backgroundColor: '#1378a5',
									fontSize: '16px',
								}}
								type='submit'
							>
								Save
							</Button>
							<Button style={{ width: 'fit-content', fontSize: '16px' }} onClick={onClose}>
								Cancel
							</Button>
						</div>
					</form>
				</div>
			</Modal>
			<Dialog
				open={confirmDeleteModal}
				onClose={() => setConfirmDeleteModal(false)}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
			>
				<DialogTitle id='alert-dialog-title'>{listingToDelete?.address}</DialogTitle>
				<DialogContent>
					<DialogContentText id='alert-dialog-description'>Are you sure you want to delete this listing?</DialogContentText>
				</DialogContent>
				<DialogActions>
					<ButtonMat onClick={() => setConfirmDeleteModal(false)} variant='contained' color='error'>
						Cancel
					</ButtonMat>
					<ButtonMat
						onClick={handleDelete}
						autoFocus
						variant='contained'
						sx={{ backgroundColor: 'var(--primary-color)', '&:hover': { backgroundColor: 'var(--primary-color)' } }}
					>
						yes
					</ButtonMat>
				</DialogActions>
			</Dialog>

			<Popover
				anchorEl={anchorEl}
				open={open}
				id={id}
				onClose={handlePopoverClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
			>
				<Card elevation={0}>
					<CardContent sx={{ display: 'flex', gap: '10px', padding: '0.5rem !important' }}>
						<p style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
							<Tooltip title='Number of page visits'>
								<Visibility color='secondary' />
							</Tooltip>{' '}
							{counts.page_count}
						</p>
						<p style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
							<Tooltip title='Number of times contacted'>
								<MarkChatReadIcon color='secondary' />
							</Tooltip>
							{counts.contact_count}
						</p>
					</CardContent>
				</Card>
			</Popover>

			<div className='listingsoverview'>
				<div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
					<Back onClick={() => navigate('/premier-agent-website')} style={{ cursor: 'pointer' }} />
					<div>
						<h2 style={{ margin: 0 }}>Active Listings</h2>
					</div>
				</div>
				<div className='button_row'>
					<div style={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
						<Button
							style={{
								width: 'fit-content',
								height: '45px',
								fontSize: '16px',
								padding: '10px 20px',
								color: 'white',
								backgroundColor: 'var(--primary-color)',
								display: 'block',
								marginTop: '0',
							}}
							onClick={() => navigate('add')}
							text='Add Manually'
						/>
						<ButtonMat
							variant='outlined'
							color='secondary'
							sx={{ textTransform: 'capitalize' }}
							onClick={() => {
								setIsZillowModalOpen(true);
								setForZillow({ url: '', rep: 'Buyer Agent' });
							}}
						>
							Add from zillow
						</ButtonMat>
					</div>

					{listings?.active.length > 0 && <p>{listings?.active.length} active Listings</p>}
				</div>
				{listings?.active.length > 0 && (
					<table>
						<thead>
							<tr>
								<th>No.</th>
								<th>Property Address</th>
								<th>Price</th>
								<th>Date Listed</th>
							</tr>
						</thead>
						<tbody>
							{listings?.active.map((listing, index) => (
								<tr key={listing.id}>
									<td style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '10px' }}>
										{index + 1}{' '}
										<ButtonMat
											variant='text'
											color='secondary'
											sx={{ fontSize: '14px', textDecoration: 'underline', textTransform: 'capitalize', whiteSpace: 'nowrap' }}
											onClick={(e) => handlePopoverOpen(e, listing)}
											aria-describedby={id}
										>
											View Stats
										</ButtonMat>{' '}
									</td>
									<td>{listing.address}</td>
									<td>$ {Number(listing.list_price).toLocaleString('en')}</td>
									<td>{new Date(listing.date_created).toLocaleDateString('en', { month: 'long', day: '2-digit', year: 'numeric' })}</td>
									<td>
										<Button
											style={{
												width: '100px',
												height: 'fit-content',
												fontSize: '16px',
												padding: '10px',
												marginTop: '0px',
												border: '1px solid var(--primary-color)',
											}}
											onClick={() => {
												navigate(`${listing.id}`);
											}}
										>
											Manage
										</Button>
									</td>
									<td>
										<Button
											style={{
												width: '150px',
												height: 'fit-content',
												fontSize: '16px',
												padding: '10px',
												color: listing.date_sold && listing.sold_price ? 'white' : 'red',
												backgroundColor: listing.date_sold && listing.sold_price ? '#d17777' : 'white',
												border: '1px solid #d17777',
												marginTop: '0px',
											}}
											onClick={() => {
												onOpen(listing, true);
											}}
										>
											{listing.date_sold && listing.sold_price ? 'Mark as Active' : 'Mark as Sold'}
										</Button>
									</td>
									<td>
										<DeleteIcon onClick={() => startDeletion(listing)} sx={{ cursor: 'pointer', color: '#d17777' }} />
									</td>
								</tr>
							))}
						</tbody>
					</table>
				)}
				{listings?.sold.length > 0 && (
					<>
						<p style={{ marginTop: '1rem', textAlign: 'right' }}>{listings?.sold.length} sold Listings</p>
						<table style={{}}>
							<thead>
								<tr>
									<th>No.</th>
									<th>Property Address</th>
									<th>Sold Price</th>
									<th>Sold Date</th>
								</tr>
							</thead>
							<tbody>
								{listings?.sold.map((listing, index) => (
									<tr key={listing.id}>
										<td style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '10px' }}>
											{index + 1}{' '}
											<ButtonMat
												variant='text'
												color='secondary'
												sx={{ fontSize: '14px', textDecoration: 'underline', textTransform: 'capitalize', whiteSpace: 'nowrap' }}
												onClick={(e) => handlePopoverOpen(e, listing)}
												aria-describedby={id}
											>
												View Stats
											</ButtonMat>{' '}
										</td>
										<td>{listing.address}</td>
										<td>$ {Number(listing.sold_price).toLocaleString('en')}</td>
										<td>
											{new Date(listing?.date_sold || listing.date_created).toLocaleDateString('en', {
												month: 'long',
												day: '2-digit',
												year: 'numeric',
											})}
										</td>
										<td>
											<Button
												style={{
													width: '100px',
													height: 'fit-content',
													fontSize: '16px',
													padding: '10px',
													marginTop: '0px',
													border: '1px solid var(--primary-color)',
												}}
												onClick={() => {
													navigate(`${listing.id}`);
												}}
											>
												Manage
											</Button>
										</td>
										<td>
											<Button
												style={{
													width: '150px',
													height: 'fit-content',
													fontSize: '16px',
													padding: '10px',
													color: 'white',
													backgroundColor: '#d17777',
													border: '1px solid #d17777',
													marginTop: '0px',
												}}
												onClick={() => {
													onOpen(listing, false);
												}}
											>
												Mark as Active
											</Button>
										</td>
										<td>
											<DeleteIcon onClick={() => startDeletion(listing)} sx={{ cursor: 'pointer', color: '#d17777' }} />
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</>
				)}
			</div>
		</>
	);
}
