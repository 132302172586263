import { Close, Download } from '@mui/icons-material';
import {
	Box,
	Button,
	CircularProgress,
	Dialog,
	Divider,
	Grid,
	IconButton,
	ImageList,
	ImageListItem,
	InputLabel,
	Stack,
	TextField,
	Tooltip,
	Typography,
	styled,
} from '@mui/material';
import { Document, Font, Image, PDFDownloadLink, Page, Path, StyleSheet, Svg, Text, View } from '@react-pdf/renderer';
import { useEffect, useReducer, useState } from 'react';
import { useSelector } from 'react-redux';
import { useOutletContext } from 'react-router-dom';
import { toast } from 'react-toastify';
import base from '../../apis';
import { ReactComponent as Bath } from '../../images/bathtemp.svg';
import { ReactComponent as Bed } from '../../images/bedtemp.svg';
import { ReactComponent as Square } from '../../images/sqfttemp.svg';
import { formatNumber, formatPhoneNumber } from '../../utils/functions';
import '../TemplateScreen/templatescreen.scss';
import Thumb1 from '../../images/thumbs/temp1preview.webp';
import Thumb2 from '../../images/thumbs/temp2preview.webp';
import Thumb3 from '../../images/thumbs/temp3preview.webp';
import Thumb4 from '../../images/thumbs/temp4preview.webp';
import Thumb5 from '../../images/thumbs/temp5preview.webp';

const BoxDivider = styled(Box)(({ theme }) => ({
	width: '100%',
	height: '20px',
	backgroundColor: '#A48111',
}));

Font.register({
	family: 'Ubuntu',
	fonts: [
		{
			src: 'https://fonts.gstatic.com/s/questrial/v13/QdVUSTchPBm7nuUeVf7EuStkm20oJA.ttf',
		},
		{
			src: 'https://fonts.gstatic.com/s/questrial/v13/QdVUSTchPBm7nuUeVf7EuStkm20oJA.ttf',
			fontWeight: 'bold',
		},
		{
			src: 'https://fonts.gstatic.com/s/questrial/v13/QdVUSTchPBm7nuUeVf7EuStkm20oJA.ttf',
			fontWeight: 'normal',
			fontStyle: 'italic',
		},
	],
});

Font.register({
	family: 'Playfair',
	fonts: [
		{
			src: 'https://fonts.gstatic.com/s/playfairdisplay/v10/2NBgzUtEeyB-Xtpr9bm1CV6uyC_qD11hrFQ6EGgTJWI.ttf',
		},
		{
			src: 'https://fonts.gstatic.com/s/playfairdisplay/v10/UC3ZEjagJi85gF9qFaBgICsv6SrURqJprbhH_C1Mw8w.ttf',
			fontWeight: 'bold',
		},
	],
});
Font.register({
	family: 'Roboto',
	fonts: [
		{
			src: 'https://fonts.gstatic.com/s/montserrat/v7/IQHow_FEYlDC4Gzy_m8fcgJKKGfqHaYFsRG-T3ceEVo.ttf',
			fontWeight: 'bold',
		},
	],
});

Font.register({
	family: 'Mont',
	fonts: [
		{
			src: 'https://fonts.gstatic.com/s/montserrat/v7/Kqy6-utIpx_30Xzecmeo8_esZW2xOQ-xsNqO47m55DA.ttf',
		},
		{
			src: 'https://fonts.gstatic.com/s/montserrat/v7/IQHow_FEYlDC4Gzy_m8fcgJKKGfqHaYFsRG-T3ceEVo.ttf',
			fontWeight: 'bold',
		},
	],
});
Font.register({
	family: 'Lora',
	fonts: [
		{
			src: 'https://fonts.gstatic.com/s/lora/v9/aXJ7KVIGcejEy1abawZazg.ttf',
		},
	],
});
Font.register({
	family: 'Bask',
	fonts: [
		{
			src: 'https://fonts.gstatic.com/s/librebaskerville/v4/pR0sBQVcY0JZc_ciXjFsKyyZRYCSvpCzQKuMWnP5NDY.ttf',
		},
	],
});
Font.register({
	family: 'Quant',
	fonts: [
		{
			src: 'https://fonts.gstatic.com/s/quattrocento/v8/WZDISdyil4HsmirlOdBRFC3USBnSvpkopQaUR-2r7iU.ttf',
			fontWeight: 'regular',
		},
		{
			src: 'https://fonts.gstatic.com/s/quattrocento/v8/Uvi-cRwyvqFpl9j3oT2mqkD2ttfZwueP-QU272T9-k4.ttf',
			fontWeight: 'bold',
		},
	],
});
Font.register({
	family: 'OpenSans',
	fonts: [
		{
			src: 'https://fonts.gstatic.com/s/opensans/v13/IgZJs4-7SA1XX_edsoXWog.ttf',
			fontWeight: 'regular',
		},
		{
			src: 'https://fonts.gstatic.com/s/opensans/v13/k3k702ZOKiLJc3WVjuplzC3USBnSvpkopQaUR-2r7iU.ttf',
			fontWeight: 'bold',
		},
		{
			src: 'https://fonts.gstatic.com/s/opensans/v13/DXI1ORHCpsQm3Vp6mXoaTS3USBnSvpkopQaUR-2r7iU.ttf',
			fontWeight: 'light',
		},
	],
});
Font.register({
	family: 'SourceSansPro',
	fonts: [
		{
			src: 'https://fonts.gstatic.com/s/sourcesanspro/v9/ODelI1aHBYDBqgeIAH2zlNRl0pGnog23EMYRrBmUzJQ.ttf',
			fontWeight: 'light',
		},
		{
			src: 'https://fonts.gstatic.com/s/sourcesanspro/v9/toadOcfmlt9b38dHJxOBGFP7R5lD_au4SZC6Ks_vyWs.ttf',
		},
	],
});

const styles = StyleSheet.create({
	page: {
		backgroundColor: '#fff',
		fontFamily: 'Ubuntu',
	},
	section: {
		margin: 0,
		padding: 0,
		gap: 0,
		fontSize: 16,
		height: '297mm',
		justifyContent: 'space-between',
	},
	heading: {
		fontSize: 16,
		fontWeight: 'bold',
		color: '#131925',
		marginBottom: 5,
	},
	statement: {
		fontSize: 14,
		color: '#131925',
		lineHeight: 1.4,
	},
	divider: {
		width: '100%',
		height: 1,
		backgroundColor: '#999999',
	},
	paragraph: {
		fontSize: 11,
		color: '#212935',
		lineHeight: 1.4,
	},
	columnParent: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		gap: 20,
		marginBottom: 15,
	},
	columnStart: {
		flex: 1,
	},
	columnEnd: {
		flex: 1,
		alignItems: 'flex-end',
	},
	headTitle: {
		fontSize: 14,
		fontWeight: 'bold',
		backgroundColor: '#3f5176',
		color: '#fff',
		width: '100%',
		padding: 7,
		textAlign: 'center',
	},
	label: {
		fontSize: 10,
		fontWeight: 'bold',
		marginBottom: 5,
	},
	img: { height: '208px', flex: 1 },
	borderedImage: {
		width: '100%',
		height: '100px',
		margin: 'auto',
		objectFit: 'cover',
		borderRadius: '2px',
	},
	bar: {
		width: '100%',
		height: '20px',
		backgroundColor: '#A48111',
	},
});

const templates = [
	{
		id: 1,
		image: Thumb1,
	},
	{
		id: 2,
		image: Thumb2,
	},
	{
		id: 3,
		image: Thumb3,
	},
	{
		id: 4,
		image: Thumb4,
	},
	{
		id: 5,
		image: Thumb5,
	},
];

const initialState = {
	listings: [],
	title: '',
	description: '',
	template: 2,
	property: '',
	selectedListingId: '',
	selectedListing: null,
	selectedImages: [],
	imagesBackup: [],
	indexToChange: null,
	agent: null,
};
const reducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case 'SET_LISTINGS':
			return { ...state, listings: payload.listings };
		case 'SET_TEXT':
			return { ...state, [payload.name]: payload.value };
		case 'SET_SELECTED_IMAGES':
			const { image, index } = payload;
			const newImages = [...state.selectedImages];
			newImages[index] = image;
			return { ...state, selectedImages: newImages };
		default:
			return state;
	}
};

const Brochure = () => {
	const id = useSelector((state) => state.auth.typeId);
	const [state, dispatch] = useReducer(reducer, initialState);
	const [listing, setIsLoading, setListing] = useOutletContext();

	useEffect(() => {
		dispatch({
			type: 'SET_TEXT',
			payload: {
				name: 'selectedListing',
				value: listing,
			},
		});
		dispatch({
			type: 'SET_TEXT',
			payload: {
				name: 'description',
				value: listing.description?.slice(0, 300),
			},
		});
		if (!listing || !id) return;
		(async () => {
			const { data } = await base.get(`register/agents/${id}`);
			dispatch({
				type: 'SET_TEXT',
				payload: {
					value: data,
					name: 'agent',
				},
			});
		})();
	}, [listing, id]);

	const handleChange = (e) => {
		const { name, value } = e.target;
		dispatch({ type: 'SET_TEXT', payload: { name, value } });
	};

	const handleTempClick = (temp, value) => {
		dispatch({ type: 'SET_TEXT', payload: { name: 'template', value } });
		//open modal

		setTempModalOpen(true);
		const photos = listing.photos.map((item) => item?.image);
		dispatch({
			type: 'SET_TEXT',
			payload: { name: 'selectedImages', value: photos },
		});
		dispatch({
			type: 'SET_TEXT',
			payload: { name: 'imagesBackup', value: photos },
		});
	};

	const [tempModalOpen, setTempModalOpen] = useState(false);
	const [photoModal, setPhotoModal] = useState(false);

	const heading = state.title || 'Heading here';
	const description =
		state.description ||
		'Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus, omnis. Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus, omnis. Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus, omnis.';
	const name = state?.agent?.user?.first_name + ' ' + state?.agent?.user?.last_name;
	const license = state?.agent?.licenses?.find((license) => license.category === 'real_estate_agent')?.number;
	const brok_name = state?.agent?.brokerage_name;
	const email = state?.agent?.user?.email;
	const phone = formatPhoneNumber(state?.agent?.phone);
	const features = [
		`$ ${formatNumber(state.selectedListing?.list_price, 0)}`,
		`${state.selectedListing?.beds} Beds`,
		`${state.selectedListing?.baths} Baths`,
		`${formatNumber(state.selectedListing?.square_footage, 0)} Sqft`,
	];
	const agentPhoto = state?.agent?.profile_image;
	const officeLogo = state?.agent?.office_logo;

	const getOption = (index) => {
		if (!state.imagesBackup.length) {
			toast.warn('No images added to this listing');
			return;
		}
		setPhotoModal(true);
		dispatch({ type: 'SET_TEXT', payload: { name: 'indexToChange', value: index } });
	};

	const getContent = () => {
		if (state.template === 1) {
			return (
				<Grid container spacing={2} sx={{ background: 'white' }}>
					<Grid item xs={6}>
						<Tooltip title='Click to change'>
							<img
								alt='Click to choose an property cover'
								src={state?.selectedImages[0]}
								style={{ aspectRatio: '4/3', width: '100%', cursor: 'pointer' }}
								onClick={() => getOption(0)}
							/>
						</Tooltip>
					</Grid>
					<Grid item xs={6}>
						<Tooltip title='Click to change'>
							<img
								alt='Click to choose an property cover'
								src={state?.selectedImages[1]}
								style={{ aspectRatio: '4/3', width: '100%', cursor: 'pointer' }}
								onClick={() => getOption(1)}
							/>
						</Tooltip>
					</Grid>
					<Grid item xs={6}>
						<Box sx={{ paddingLeft: '1rem' }}>
							<Typography variant='h4' textAlign={'left'} sx={{ fontFamily: 'Playfair Display', fontWeight: '700', textTransform: 'uppercase' }}>
								{heading}
							</Typography>
							<Typography variant='body1' textAlign={'left'} fontWeight='300' fontSize='16px' whiteSpace='pre-line'>
								{description}
							</Typography>
						</Box>
					</Grid>
					<Grid item xs={6}>
						<Tooltip title='Click to change'>
							<img
								alt='Click to choose an property cover'
								src={state?.selectedImages[2]}
								style={{ aspectRatio: '4/3', width: '100%', cursor: 'pointer' }}
								onClick={() => getOption(2)}
							/>
						</Tooltip>
					</Grid>
					<Grid item xs={6}>
						<Typography
							variant='h5'
							textAlign={'left'}
							sx={{
								fontSize: '32px',
								fontWeight: '700',
								fontFamily: 'Playfair Display',
								textTransform: 'uppercase',
								color: '#ffaa01',
								marginBottom: '1rem',
								paddingLeft: '1rem',
							}}
						>
							{features[0]}
						</Typography>
						<Box sx={{ paddingLeft: '1rem' }}>
							<Typography
								variant='h6'
								textAlign={'left'}
								sx={{ fontSize: '16px', fontWeight: '700', fontFamily: 'Playfair Display', textTransform: 'uppercase' }}
							>
								Features
							</Typography>
							<ul>
								{features.slice(1).map((feature) => (
									<li key={feature}>
										<Typography variant='body1' textAlign={'left'} fontWeight='300' fontSize='16px'>
											{feature}
										</Typography>
									</li>
								))}
							</ul>
						</Box>
					</Grid>
					<Grid item xs={6}>
						<Tooltip title='Click to change'>
							<img
								alt='Click to choose an property cover'
								src={state?.selectedImages[3]}
								style={{ aspectRatio: '4/3', width: '100%', cursor: 'pointer' }}
								onClick={() => getOption(3)}
							/>
						</Tooltip>
					</Grid>
					<Grid item xs={12} display={'flex'} sx={{ backgroundColor: '#ffaa01', padding: '1rem', gap: '1rem', marginTop: '1rem' }}>
						<img src={agentPhoto} alt='profile' style={{ width: '150px', aspectRatio: '3/4', marginLeft: '1rem' }} />
						<Stack justifyContent={'flex-end'} sx={{ color: 'white', fontSize: '16px', fontWeight: '400' }}>
							<Typography variant='body1' fontSize='inherit' fontWeight='inherit'>
								{name}
							</Typography>
							<Typography variant='body1' fontSize='inherit' fontWeight='inherit'>
								{brok_name}
							</Typography>
							<Typography variant='body1' fontSize='inherit' fontWeight='inherit'>
								{email}
							</Typography>
							<Typography variant='body1' fontSize='inherit' fontWeight='inherit'>
								{phone}
							</Typography>
							<Typography variant='body1' fontSize='inherit' fontWeight='inherit'>
								{license}
							</Typography>
						</Stack>
						<Box width='100px' height='100px' marginTop='auto' marginLeft='auto' sx={{ backgroundColor: 'white' }}>
							{officeLogo && <img alt='office' src={officeLogo} style={{ width: '100px', height: '100px', objectFit: 'contain' }} />}
						</Box>
					</Grid>
				</Grid>
			);
		}
		if (state.template === 2) {
			return (
				<Box sx={{ backgroundColor: 'white', width: '100%' }}>
					<Tooltip title='Click to change'>
						<img
							src={state?.selectedImages[0]}
							alt='Click to choose an property cover'
							style={{ height: '500px', backgroundSize: 'cover', width: '100%', cursor: 'pointer' }}
							onClick={() => getOption(0)}
						/>
					</Tooltip>
					<Grid container spacing={2} sx={{ background: 'white', padding: '0 1rem' }}>
						<Grid item xs={12}></Grid>
						<Grid item xs={4}>
							<Tooltip title='Click to change'>
								<img
									src={state?.selectedImages[1]}
									alt='Click to choose an property cover'
									style={{ aspectRatio: '4/3', width: '100%', cursor: 'pointer' }}
									onClick={() => getOption(1)}
								/>
							</Tooltip>
						</Grid>
						<Grid item xs={4}>
							<Tooltip title='Click to change'>
								<img
									src={state?.selectedImages[2]}
									alt='Click to choose an property cover'
									style={{ aspectRatio: '4/3', width: '100%', cursor: 'pointer' }}
									onClick={() => getOption(2)}
								/>
							</Tooltip>
						</Grid>
						<Grid item xs={4}>
							<Tooltip title='Click to change'>
								<img
									src={state?.selectedImages[3]}
									alt='Click to choose an property cover'
									style={{ aspectRatio: '4/3', width: '100%', cursor: 'pointer' }}
									onClick={() => getOption(3)}
								/>
							</Tooltip>
						</Grid>
						<Grid item xs={8}>
							<Typography variant='h6' sx={{ fontWeight: 'bold', textTransform: 'uppercase', fontFamily: 'Montserrat' }}>
								{heading}
							</Typography>
							<Typography variant='body1' sx={{ fontSize: '16px', fontFamily: 'Lora', whiteSpace: 'pre-line' }}>
								{description}
							</Typography>
						</Grid>
						<Grid item xs={4}>
							<Typography variant='h6' sx={{ fontWeight: 'bold', textTransform: 'uppercase', fontFamily: 'Montserrat' }}>
								Features
							</Typography>
							<ul style={{ fontFamily: 'Lora' }}>
								{features.map((feature) => (
									<li key={feature}>{feature}</li>
								))}
							</ul>
						</Grid>
						<Grid item xs={12}>
							<Grid container spacing={2}>
								<Grid item xs={4}>
									<img src={agentPhoto} alt='agent' style={{ aspectRatio: '3/4', width: '250px' }} />
								</Grid>
								<Grid item xs={8} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
									<Typography variant='body1' sx={{ width: '100%', fontFamily: 'Lora' }}>
										{state.selectedListing?.address}
									</Typography>
									<Divider orientation='horizontal' flexItem />
									<Grid container spacing={2} mt='0.5rem' sx={{ fontSize: '15px !important', fontWeight: '400', fontFamily: 'Montserrat' }}>
										<Grid item xs={4}>
											<Typography variant='body1' fontSize='inherit' fontWeight='inherit' fontFamily={'inherit'}>
												{name}
											</Typography>
											<Typography variant='body1' fontSize='inherit' fontWeight='inherit' fontFamily={'inherit'}>
												{brok_name}
											</Typography>
											<Typography variant='body1' fontSize='inherit' fontWeight='inherit' fontFamily={'inherit'}>
												{email}
											</Typography>
										</Grid>
										<Grid item xs={4}>
											<Typography variant='body1' fontSize='inherit' fontWeight='inherit' fontFamily={'inherit'}>
												{phone}
											</Typography>
											<Typography variant='body1' fontSize='inherit' fontWeight='inherit' fontFamily={'inherit'}>
												{license}
											</Typography>
										</Grid>
										<Grid item xs={4}>
											<Box sx={{ height: '100%', width: '50%', backgroundColor: '#C4C4C4', marginLeft: 'auto' }}></Box>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Box>
			);
		}
		if (state.template === 3) {
			return (
				<Box sx={{ backgroundColor: 'white' }}>
					<Box style={{ width: '100%', height: '400px', position: 'relative', marginBottom: '20px' }}>
						<Tooltip title='Click to change'>
							<img
								src={state?.selectedImages[0]}
								alt='Click to choose an property cover'
								style={{ height: '400px', width: '100%', cursor: 'pointer', objectFit: 'cover' }}
								onClick={() => getOption(0)}
							/>
						</Tooltip>
						<Box
							style={{
								position: 'absolute',
								top: 0,
								left: '0',
								width: '50%',
								height: '100%',
								display: 'flex',
								alignItems: 'flex-end',
								backgroundColor: '#005EA9bf',
								padding: '20px',
								color: 'white',
								fontWeight: '300',
								fontSize: '14px',
							}}
						>
							{state.selectedListing?.address}
						</Box>
					</Box>
					<Grid container spacing={2} paddingX={'2rem'}>
						<Grid item xs={4} sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
							<Tooltip title='Click to change'>
								<img
									src={state?.selectedImages[1]}
									alt='Click to choose an property cover'
									style={{ aspectRatio: '4/3', width: '100%', borderRadius: '5px', cursor: 'pointer' }}
									onClick={() => getOption(1)}
								/>
							</Tooltip>
							<Tooltip title='Click to change'>
								<img
									src={state?.selectedImages[2]}
									alt='Click to choose an property cover'
									style={{ aspectRatio: '4/3', width: '100%', borderRadius: '5px', cursor: 'pointer' }}
									onClick={() => getOption(2)}
								/>
							</Tooltip>
							<Tooltip title='Click to change'>
								<img
									src={state?.selectedImages[3]}
									alt='Click to choose an property cover'
									style={{ aspectRatio: '4/3', width: '100%', borderRadius: '5px', cursor: 'pointer' }}
									onClick={() => getOption(3)}
								/>
							</Tooltip>
							<Tooltip title='Click to change'>
								<img
									src={state?.selectedImages[4]}
									alt='Click to choose an property cover'
									style={{ aspectRatio: '4/3', width: '100%', borderRadius: '5px', cursor: 'pointer' }}
									onClick={() => getOption(4)}
								/>
							</Tooltip>
						</Grid>
						<Grid item xs={8}>
							<Typography variant='h5' color='#895B00' style={{ textTransform: 'uppercase', fontFamily: 'Montserrat', fontWeight: 'bold' }} mt={3}>
								{heading}
							</Typography>
							<Typography variant='body1'>{description}</Typography>
							<Typography variant='body1' mb={2} style={{ textTransform: 'uppercase', fontFamily: 'Montserrat', fontWeight: 'bold' }}>
								Features
							</Typography>
							<Box>
								{features.map((feature) => (
									<Typography variant='body1' key={feature}>
										{feature}
									</Typography>
								))}
							</Box>
						</Grid>
					</Grid>
					<Box>
						<Grid
							container
							spacing={2}
							sx={{
								fontSize: '15px !important',
								fontWeight: '300',
								backgroundColor: '#005EA9',
								marginTop: '20px',
								minHeight: '200px',
								color: 'white',
							}}
							alignItems='center'
						>
							<Grid item xs={3}>
								<Box sx={{ height: '100px', width: '50%', backgroundColor: 'white', margin: 'auto' }}>
									<img src={officeLogo} alt='office' style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
								</Box>
							</Grid>
							<Grid item xs={3}>
								<Typography variant='body1' fontSize='inherit' fontWeight='inherit' color='inherit'>
									{name}
								</Typography>
								<Typography variant='body1' fontSize='inherit' fontWeight='inherit' color='inherit'>
									{brok_name}
								</Typography>
								<Typography variant='body1' fontSize='inherit' fontWeight='inherit' color='inherit'>
									{email}
								</Typography>
							</Grid>
							<Grid item xs={3}>
								<Typography variant='body1' fontSize='inherit' fontWeight='inherit' color='inherit'>
									{phone}
								</Typography>
								<Typography variant='body1' fontSize='inherit' fontWeight='inherit' color='inherit'>
									Lic #: {license}
								</Typography>
							</Grid>
							<Grid item xs={3} sx={{ position: 'relative' }}>
								<img src={agentPhoto} alt='profile' style={{ width: '200px', aspectRatio: '3/4', position: 'absolute', top: '-200px', left: '0' }} />
							</Grid>
						</Grid>
					</Box>
				</Box>
			);
		}
		if (state.template === 4) {
			return (
				<Box sx={{ background: `#000000d4`, color: 'white', paddingTop: '2rem' }}>
					<Box sx={{ height: '100px', width: '100px', backgroundColor: 'white', marginRight: 'auto', margin: '2rem' }}>
						{officeLogo && <img src={officeLogo} alt='header' style={{ width: '100px', height: '100px', objectFit: 'contain' }} />}
					</Box>
					<Tooltip title='Click to change'>
						<img
							src={state?.selectedImages[0]}
							alt='Click to choose an property cover'
							style={{ width: '100%', height: '500px', objectFit: 'cover', position: 'relative', zIndex: '10', padding: '2rem', cursor: 'pointer' }}
							onClick={() => getOption(0)}
						/>
					</Tooltip>
					<Grid container spacing={2}>
						<Grid item xs={6} padding={'2rem'}>
							<Typography
								px='2rem'
								variant='h4'
								fontFamily='Libre Baskerville'
								style={{ fontSize: '24px', color: '#F5C20C', marginBottom: '10px', textTransform: 'uppercase' }}
							>
								{heading}
							</Typography>
							<Typography px='2rem' variant='body1' style={{ fontSize: '14px', maxHeight: '170px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
								{description}
							</Typography>
							<Typography px='2rem' variant='h6' style={{ fontWeight: 'bold', fontFamily: 'Open Sans', marginTop: '10px' }}>
								{state.selectedListing?.address?.replace(/, USA/g, '')}
							</Typography>
							<Typography px='2rem' variant='h6' my={1} fontSize='18px' fontFamily='Libre Baskerville'>
								Features
							</Typography>
							<Box sx={{ color: 'white', px: '2rem', fontSize: '14px' }}>
								{features.map((f) => (
									<Typography variant='body1' key={f}>
										{f}
									</Typography>
								))}
							</Box>
						</Grid>
						<Grid item xs={6} sx={{ fontSize: '16px', position: 'relative' }}>
							<Box
								sx={{
									position: 'absolute',
									backgroundColor: '#F5C20C',
									width: '100%',
									bottom: 0,
									right: '0',
									height: '120%',
									paddingTop: '7rem',
									paddingLeft: '1rem',
									color: 'black',
								}}
							>
								<img src={agentPhoto} alt='profile' style={{ height: '170px', aspectRatio: '3/4', marginBottom: '10px' }} />
								<Typography variant='body1' fontSize='inherit' fontWeight='bold'>
									{name}
								</Typography>
								<Typography variant='body1' fontSize='inherit' fontWeight='inherit'>
									{brok_name}
								</Typography>
								<Typography variant='body1' fontSize='inherit' fontWeight='inherit'>
									{email}
								</Typography>
								<Typography variant='body1' fontSize='inherit' fontWeight='inherit'>
									{phone}
								</Typography>
								<Typography variant='body1' fontSize='inherit' fontWeight='inherit'>
									Lic #: {license}
								</Typography>
							</Box>
						</Grid>
					</Grid>
				</Box>
			);
		}
		if (state.template === 5) {
			return (
				<Box sx={{ backgroundColor: 'white' }}>
					<Box style={{ display: 'flex', flexDirection: 'row', gap: '10px', alignItems: 'center', marginTop: '10px', marginBottom: '10px' }}>
						<BoxDivider sx={{ width: '50px' }} />

						<Box style={{ backgroundColor: 'white', width: '70px', height: '70px' }}>
							{officeLogo && <img alt='brokerage' src={officeLogo} style={{ width: '100%', height: '100%', objectFit: 'contain' }} />}
						</Box>
						<BoxDivider sx={{ flex: '1' }} />
					</Box>

					<Box sx={{ position: 'relative', height: '400px' }}>
						<Tooltip title='Click to change'>
							<img
								src={state?.selectedImages[0]}
								alt='Click to choose an property cover'
								style={{ width: '100%', height: '400px', objectFit: 'cover', position: 'relative', cursor: 'pointer' }}
								onClick={() => getOption(0)}
							/>
						</Tooltip>
						<Box
							sx={{
								height: '100%',
								position: 'absolute',
								right: 0,
								top: 0,
								width: '50%',
								backgroundColor: '#a48111d9',
								padding: '20px',
								color: 'white',
								justifyContent: 'center',
								display: 'flex',
								flexDirection: 'column',
								gap: '16px',
								'&> *': {
									fontFamily: 'Quattrocento !important',
								},
							}}
						>
							<Typography
								sx={{
									fontWeight: 'bold',
									fontSize: '40px',
									textTransform: 'uppercase',
									paddingBottom: '10px',
									borderBottom: '1px solid white',
									marginBottom: '10px',
								}}
							>
								{heading}
							</Typography>
							<Typography sx={{ fontSize: '16px' }}>{state?.selectedListing?.address}</Typography>
							<Typography sx={{ fontWeight: 'bold', fontSize: '32px' }}>{features[0]}</Typography>
						</Box>
					</Box>
					<Box padding='1rem'>
						<Stack direction='row' alignItems={'center'} gap='1rem'>
							<Typography fontFamily='Quattrocento' fontWeight='700' style={{ textTransform: 'uppercase' }}>
								Overview
							</Typography>
							<BoxDivider sx={{ flex: '1', height: '2px' }} />
						</Stack>
						<Stack direction='row' gap='1rem' mt='5px'>
							<Tooltip title='Click to change'>
								<img
									alt='Click to choose an property cover'
									src={state?.selectedImages[1]}
									style={{ width: '100%', height: '150px', objectFit: 'cover', cursor: 'pointer' }}
									onClick={() => getOption(1)}
								/>
							</Tooltip>
							<Tooltip title='Click to change'>
								<img
									alt='Click to choose an property cover'
									src={state?.selectedImages[2]}
									style={{ width: '100%', height: '150px', objectFit: 'cover', cursor: 'pointer' }}
									onClick={() => getOption(2)}
								/>
							</Tooltip>
							<Tooltip title='Click to change'>
								<img
									alt='Click to choose an property cover'
									src={state?.selectedImages[3]}
									style={{ width: '100%', height: '150px', objectFit: 'cover', cursor: 'pointer' }}
									onClick={() => getOption(3)}
								/>
							</Tooltip>
						</Stack>
					</Box>
					<Box padding='1rem' sx={{ fontFamily: 'Quattrocento', backgroundColor: '#F5F5F5' }}>
						<Stack direction='row' alignItems={'center'} gap='1rem'>
							<Typography fontFamily='Quattrocento' fontWeight='700' style={{ textTransform: 'uppercase' }}>
								About
							</Typography>
							<BoxDivider sx={{ flex: '1', height: '2px' }} />
						</Stack>
						<Stack direction='row' gap='1rem' mt='5px'>
							{description}
						</Stack>
					</Box>
					<Box padding='1rem' sx={{ fontFamily: 'Quattrocento', backgroundColor: '#F5F5F5' }}>
						<Stack direction='row' alignItems={'center'} gap='1rem'>
							<Typography fontFamily='Quattrocento' fontWeight='700' style={{ textTransform: 'uppercase' }}>
								Features
							</Typography>
							<BoxDivider sx={{ flex: '1', height: '2px' }} />
						</Stack>
						<Stack direction='row' gap='1rem' mt='5px'>
							<Stack direction='row' gap='1rem' mt='5px' flex='1'>
								<Bed />
								<Typography fontFamily='Quattrocento'>{features[1]}</Typography>
							</Stack>
							<Stack direction='row' gap='1rem' mt='5px' flex='1'>
								<Bath />
								<Typography fontFamily='Quattrocento'>{features[2]}</Typography>
							</Stack>
							<Stack direction='row' gap='1rem' mt='5px' flex='1'>
								<Square />
								<Typography fontFamily='Quattrocento'>{features[3]}</Typography>
							</Stack>
						</Stack>
					</Box>
					<Box style={{ display: 'flex', flexDirection: 'row', gap: '20px', alignItems: 'flex-end', padding: '20px' }}>
						<img alt='agent' src={agentPhoto} style={{ width: '110px', height: '120px', objectFit: 'cover' }} />
						<Box style={{ paddingTop: '10px', borderTop: '1px solid #A48111', flex: 1, fontFamily: 'Open Sans', fontSize: '12px' }}>
							<Box style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
								<Typography style={{ flex: 1 }}>{name}</Typography>
								<Typography variant='body1' fontFamily={'inherit !important'} style={{ flex: 1 }}>
									{email}
								</Typography>
								<Typography variant='body1' fontFamily={'inherit !important'} style={{ flex: 1 }}>
									Lic #: {license}
								</Typography>
							</Box>
							<Box style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
								<Typography variant='body1' fontFamily={'inherit !important'} style={{ flex: 1 }}>
									{brok_name}
								</Typography>
								<Typography variant='body1' fontFamily={'inherit !important'} style={{ flex: 1 }}>
									{phone}
								</Typography>
								<Typography variant='body1' fontFamily={'inherit !important'} style={{ flex: 1 }}></Typography>
							</Box>
						</Box>
					</Box>
				</Box>
			);
		}
	};

	const handleImageChange = (image) => {
		dispatch({
			type: 'SET_SELECTED_IMAGES',
			payload: { image: image, index: state.indexToChange },
		});
		setPhotoModal(false);
	};

	const PrintTemplate = () => {
		if (state.template === 1) {
			return (
				<Document>
					<Page size='A4' style={styles.page}>
						<View style={styles.section}>
							<View style={{ flexDirection: 'row', gap: '10px', marginBottom: '10px', padding: 0 }}>
								<View style={styles.img}>
									{state?.selectedImages[0] && (
										<Image
											src={{ uri: state?.selectedImages[0], method: 'GET', headers: { 'Cache-Control': 'no-cache' }, body: '' }}
											style={{ objectFit: 'cover', height: '100%', width: '100%' }}
										/>
									)}
								</View>
								<View style={styles.img}>
									{state?.selectedImages[1] && (
										<Image
											src={{ uri: state?.selectedImages[1], method: 'GET', headers: { 'Cache-Control': 'no-cache' }, body: '' }}
											style={{ objectFit: 'cover', height: '100%', width: '100%' }}
										/>
									)}
								</View>
							</View>
							<View style={{ flexDirection: 'row', gap: '10px', marginTop: '10px' }}>
								<View style={{ flex: '1' }}>
									<View style={{ paddingLeft: '10px' }}>
										<Text style={{ fontSize: '38px', fontWeight: '700', textTransform: 'uppercase', marginBottom: '10px', fontFamily: 'Playfair' }}>
											{heading}
										</Text>
										<Text style={{ fontSize: '12px' }}>{description}</Text>
									</View>
								</View>
								<View style={[styles.img, { flex: '1' }]}>
									{state?.selectedImages[2] && (
										<Image
											src={{ uri: state?.selectedImages[2], method: 'GET', headers: { 'Cache-Control': 'no-cache' }, body: '' }}
											style={{ objectFit: 'cover', height: '100%', width: '100%' }}
										/>
									)}
								</View>
							</View>
							<View style={{ flexDirection: 'row', gap: '10px', marginTop: '10px' }}>
								<View style={{ flex: '1' }}>
									<View style={{ paddingLeft: '10px' }}>
										<Text style={{ marginBottom: '20px', color: '#ffaa01', fontSize: 32, fontFamily: 'Playfair' }}>{features[0]}</Text>
										<Text style={{ fontWeight: 'bold', fontSize: '20px', marginBottom: '10px' }}>Features</Text>
										<Text style={{ marginTop: '10px', marginBottom: '10px' }}>{state.selectedListing?.address}</Text>
										<View style={{ paddingLeft: '20px', gap: '10px' }}>
											{features.slice(1).map((feature) => (
												<Text key={feature} style={{ fontSize: '12px' }}>
													{feature}
												</Text>
											))}
										</View>
									</View>
								</View>
								<View style={[styles.img, { flex: '1' }]}>
									{state?.selectedImages[3] && (
										<Image
											src={{ uri: state?.selectedImages[3], method: 'GET', headers: { 'Cache-Control': 'no-cache' }, body: '' }}
											style={{ objectFit: 'cover', height: '100%', width: '100%' }}
										/>
									)}
								</View>
							</View>
							<View
								wrap={false}
								style={{
									backgroundColor: '#ffaa01',
									color: 'white',
									marginTop: '20px',
									padding: '10px',
									flexDirection: 'row',
									height: '177.5px',
									gap: '10px',
									fontSize: '16px',
								}}
							>
								<View style={{ flex: 1 }}>
									{agentPhoto && (
										<Image
											src={{ uri: agentPhoto, method: 'GET', headers: { 'Cache-Control': 'no-cache' }, body: '' }}
											style={{ width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'top' }}
										/>
									)}
								</View>

								<View style={{ flex: 2, justifyContent: 'flex-end', fontSize: '14px' }}>
									<Text>{name}</Text>
									<Text>{brok_name}</Text>
									<Text>{email}</Text>
									<Text>{phone}</Text>
									<Text>{license}</Text>
								</View>
								<View style={{ justifyContent: 'flex-end' }}>
									<View style={{ width: '100px', height: '100px', backgroundColor: 'white' }}>
										{officeLogo && (
											<Image
												src={{ uri: officeLogo, method: 'GET', headers: { 'Cache-Control': 'no-cache' }, body: '' }}
												style={{ width: '100px', height: '100px', objectFit: 'contain' }}
											/>
										)}
									</View>
								</View>
							</View>
						</View>
					</Page>
				</Document>
			);
		}
		if (state.template === 2) {
			return (
				<Document>
					<Page size='A4' style={styles.page}>
						<View style={styles.section}>
							{state?.selectedImages[0] && (
								<Image
									src={{ uri: state?.selectedImages[0], method: 'GET', headers: { 'Cache-Control': 'no-cache' }, body: '' }}
									style={{ width: '100%', height: '250px', objectFit: 'cover' }}
								/>
							)}
							<View style={{ padding: '10px', gap: '10px', flexDirection: 'row' }}>
								{state?.selectedImages[1] && (
									<View style={{ flex: 1 }}>
										<Image
											src={{ uri: state?.selectedImages[1], method: 'GET', headers: { 'Cache-Control': 'no-cache' }, body: '' }}
											style={{ width: '100%', height: '150px', objectFit: 'cover' }}
										/>
									</View>
								)}
								{state?.selectedImages[2] && (
									<View style={{ flex: 1 }}>
										<Image
											src={{ uri: state?.selectedImages[2], method: 'GET', headers: { 'Cache-Control': 'no-cache' }, body: '' }}
											style={{ width: '100%', height: '150px', objectFit: 'cover' }}
										/>
									</View>
								)}
								{state?.selectedImages[3] && (
									<View style={{ flex: 1 }}>
										<Image
											src={{ uri: state?.selectedImages[3], method: 'GET', headers: { 'Cache-Control': 'no-cache' }, body: '' }}
											style={{ width: '100%', height: '150px', objectFit: 'cover' }}
										/>
									</View>
								)}
							</View>
							<View style={{ padding: '10px' }}>
								<View style={{ flexDirection: 'row', gap: '10px', marginTop: '15px' }}>
									<View style={{ flex: 2 }}>
										<Text style={{ marginBottom: '10px', fontWeight: 'bold', fontFamily: 'Mont', textTransform: 'uppercase' }}>{heading}</Text>
										<Text style={{ fontFamily: 'Lora', fontSize: '14px', fontWeight: '300' }}>{description}</Text>
									</View>
									<View style={{ flex: 1 }}>
										<Text style={{ marginBottom: '10px', fontWeight: 'bold', fontFamily: 'Mont', textTransform: 'uppercase' }}>Features</Text>
										<View style={{ paddingLeft: '20px', gap: '5px' }}>
											{features.map((feature) => (
												<Text key={feature}>{feature}</Text>
											))}
										</View>
									</View>
								</View>
								<View style={{ flexDirection: 'row', gap: '10px', marginTop: '10px', marginBottom: '10px' }}>
									<View style={{ flex: 1 }}>
										{agentPhoto && (
											<Image
												src={{ uri: agentPhoto, method: 'GET', headers: { 'Cache-Control': 'no-cache' }, body: '' }}
												style={{ width: '100%', height: '255px', objectFit: 'cover' }}
											/>
										)}
									</View>
									<View style={{ flex: 2, justifyContent: 'flex-end' }}>
										<Text style={{ borderBottom: '1px solid #1378a5', paddingBottom: '5px' }}>{state.selectedListing?.address}</Text>
										<View style={{ flexDirection: 'row', marginTop: '10px' }}>
											<View style={{ flex: 2, flexDirection: 'column', gap: '5px', fontFamily: 'Mont', fontWeight: 'normal', fontSize: '14px' }}>
												<View style={{ flexDirection: 'row' }}>
													<Text style={{ flex: 1 }}>{name}</Text>
													<Text style={{ flex: 1 }}>{phone}</Text>
												</View>
												<View style={{ flexDirection: 'row' }}>
													<Text style={{ flex: 1 }}>{brok_name}</Text>
													<Text style={{ flex: 1 }}>Lic #:{license}</Text>
												</View>
												<View>
													<Text style={{ flex: 1 }}>{email}</Text>
												</View>
											</View>
											<View style={{ flex: 1 }}>
												{officeLogo && (
													<Image
														src={{ uri: officeLogo, method: 'GET', headers: { 'Cache-Control': 'no-cache' }, body: '' }}
														style={{ width: '100px', height: '100px', objectFit: 'contain', marginLeft: 'auto' }}
													/>
												)}
											</View>
										</View>
									</View>
								</View>
							</View>
						</View>
					</Page>
				</Document>
			);
		}
		if (state.template === 3) {
			return (
				<Document>
					<Page size='A4' style={styles.page}>
						<View style={styles.section}>
							<View style={{ height: '220px', width: '100%', position: 'relative' }}>
								{state?.selectedImages[0] && (
									<Image
										src={{ uri: state?.selectedImages[0], method: 'GET', headers: { 'Cache-Control': 'no-cache' }, body: '' }}
										style={{ width: '100%', height: '220px', objectFit: 'cover', zIndex: 0 }}
									/>
								)}
								<View
									style={{
										backgroundColor: '#005EA9bf',
										position: 'absolute',
										top: 0,
										left: '0',
										width: '50%',
										height: '100%',
										padding: '20px',
										justifyContent: 'flex-end',
									}}
								>
									<Text style={{ color: 'white', fontSize: '14px', fontFamily: 'SourceSansPro' }}>{state.selectedListing?.address}</Text>
								</View>
							</View>

							<View style={{ flexDirection: 'row', padding: '20px', gap: '10px' }}>
								<View style={{ flex: 1, gap: '10px' }}>
									{state?.selectedImages[1] && (
										<Image
											src={{ uri: state?.selectedImages[1], method: 'GET', headers: { 'Cache-Control': 'no-cache' }, body: '' }}
											style={styles.borderedImage}
										/>
									)}
									{state?.selectedImages[2] && (
										<Image
											src={{ uri: state?.selectedImages[2], method: 'GET', headers: { 'Cache-Control': 'no-cache' }, body: '' }}
											style={styles.borderedImage}
										/>
									)}
									{state?.selectedImages[3] && (
										<Image
											src={{ uri: state?.selectedImages[3], method: 'GET', headers: { 'Cache-Control': 'no-cache' }, body: '' }}
											style={styles.borderedImage}
										/>
									)}
									{state?.selectedImages[4] && (
										<Image
											src={{ uri: state?.selectedImages[4], method: 'GET', headers: { 'Cache-Control': 'no-cache' }, body: '' }}
											style={styles.borderedImage}
										/>
									)}
								</View>
								<View style={{ flex: 3 }}>
									<Text
										style={{
											marginTop: '10px',
											marginBottom: '10px',
											fontSize: '24px',
											color: '#895B00',
											fontFamily: 'Mont',
											textTransform: 'uppercase',
											fontWeight: 'bold',
										}}
									>
										{heading}
									</Text>
									<View style={{ fontFamily: 'SourceSansPro' }}>
										<Text>{description}</Text>
										<Text style={{ marginTop: '20px', marginBottom: '10px', fontFamily: 'Mont', fontWeight: 'bold' }}>FEATURES</Text>
										<View>
											{features.map((feature) => (
												<Text key={feature}>{feature}</Text>
											))}
										</View>
									</View>
								</View>
							</View>
							<View
								style={{
									backgroundColor: '#005EA9',
									height: '150px',
									flexDirection: 'row',
									gap: '20px',
									padding: '20px',
								}}
							>
								<View style={{ flex: '1', backgroundColor: 'white', width: '100px', height: '100px' }}>
									{officeLogo && (
										<Image
											src={{ uri: officeLogo, method: 'GET', headers: { 'Cache-Control': 'no-cache' }, body: '' }}
											style={{ width: '100%', height: '100%', objectFit: 'contain' }}
										/>
									)}
								</View>
								<View style={{ flex: 2, color: 'white' }}>
									<View style={{ flexDirection: 'row', marginTop: '10px' }}>
										<View style={{ flex: 2, flexDirection: 'column', gap: '5px', fontSize: '14px' }}>
											<View style={{ flexDirection: 'row' }}>
												<Text style={{ flex: 1 }}>{name}</Text>
												<Text style={{ flex: 1 }}>{phone}</Text>
											</View>
											<View style={{ flexDirection: 'row' }}>
												<Text style={{ flex: 1 }}>{brok_name}</Text>
												<Text style={{ flex: 1 }}>Lic #:{license}</Text>
											</View>
											<View>
												<Text style={{ flex: 1 }}>{email}</Text>
											</View>
										</View>
									</View>
								</View>
								<View style={{ flex: 1.2, position: 'relative' }}>
									{agentPhoto && (
										<Image
											src={{ uri: agentPhoto, method: 'GET', headers: { 'Cache-Control': 'no-cache' }, body: '' }}
											style={{ width: '100%', height: '170px', objectFit: 'cover', position: 'absolute', bottom: 0, left: '0' }}
										/>
									)}
								</View>
							</View>
						</View>
					</Page>
				</Document>
			);
		}
		if (state.template === 4) {
			return (
				<Document>
					<Page size='A4' style={styles.page}>
						<View style={styles.section}>
							<View style={{ height: '100%', padding: '20px', position: 'relative', backgroundColor: '#000000d4', color: 'white' }}>
								<View style={{ backgroundColor: 'white', width: '100px', height: '100px', marginBottom: '20px' }}>
									{officeLogo && (
										<Image
											src={{ uri: officeLogo, method: 'GET', headers: { 'Cache-Control': 'no-cache' }, body: '' }}
											style={{ width: '100%', height: '100%', objectFit: 'contain' }}
										/>
									)}
								</View>
								{state?.selectedImages[0] && (
									<Image
										source={state?.selectedImages[0]}
										style={{ width: '100%', height: '350px', objectFit: 'cover', position: 'absolute', left: '20px', top: '140px' }}
									/>
								)}
								<View style={{ backgroundColor: '#F5C20C', top: '200px', right: '-50%', height: '100%', width: '100%', zIndex: -1 }}></View>
								<View style={{ position: 'absolute', width: '100%', top: '510px', left: '20px', flexDirection: 'row', gap: '10px' }}>
									<View style={{ flex: 1, fontSize: '16px' }}>
										<View style={{ maxHeight: '170px', overflow: 'hidden' }}>
											<Text style={{ fontFamily: 'Bask', fontSize: '24px', color: '#F5C20C', marginBottom: '10px', textTransform: 'uppercase' }}>
												{heading}
											</Text>
											<Text style={{ fontSize: '14px' }}>{description}</Text>
										</View>
										<Text style={{ fontWeight: 'bold', fontFamily: 'OpenSans', marginTop: '10px' }}>
											{state.selectedListing?.address?.replace(/, USA/g, '')}
										</Text>
										<Text style={{ fontFamily: 'Bask', marginBottom: '10px' }}>FEATURES</Text>
										<View style={{ paddingLeft: '10px', fontWeight: 'light', fontFamily: 'OpenSans', fontSize: '14px' }}>
											{features.map((f) => (
												<Text key={f}>{f}</Text>
											))}
										</View>
									</View>
									<View style={{ flex: 1, paddingLeft: '20px', color: 'black', fontFamily: 'OpenSans', fontSize: '14px' }}>
										{agentPhoto && (
											<Image
												src={{ uri: agentPhoto, method: 'GET', headers: { 'Cache-Control': 'no-cache' }, body: '' }}
												style={{ width: '150px', height: '170px', objectFit: 'cover' }}
											/>
										)}
										<Text style={{ marginTop: '10px', fontWeight: 'bold' }}>{name}</Text>
										<Text>{brok_name}</Text>
										<Text>{email}</Text>
										<Text>{phone}</Text>
										<Text>Lic #: {license}</Text>
									</View>
								</View>
							</View>
						</View>
					</Page>
				</Document>
			);
		}
		if (state.template === 5) {
			return (
				<Document>
					<Page size='A4' style={styles.page}>
						<View style={[styles.section, { gap: '10px' }]}>
							<View style={{ flexDirection: 'row', gap: '10px', alignItems: 'center', marginTop: '10px', marginBottom: '10px' }}>
								<View style={[styles.bar, { width: '50px' }]}></View>
								<View style={{ backgroundColor: 'white', width: '70px', height: '70px' }}>
									{officeLogo && <Image source={officeLogo} style={{ width: '100%', height: '100%', objectFit: 'contain' }} />}
								</View>
								<View style={[styles.bar, { flex: 1 }]}></View>
							</View>
							<View style={{ height: '300px', width: '100%', position: 'relative' }}>
								{state?.selectedImages[0] && (
									<Image
										src={{ uri: state?.selectedImages[0], method: 'GET', headers: { 'Cache-Control': 'no-cache' }, body: '' }}
										style={{ width: '100%', height: '300px', objectFit: 'cover', zIndex: -1 }}
									/>
								)}
								<View
									style={{
										height: '100%',
										position: 'absolute',
										right: 0,
										top: 0,
										width: '50%',
										backgroundColor: '#a48111d9',
										fontFamily: 'Quant',
										padding: '20px',
										color: 'white',
										justifyContent: 'center',
										gap: '16px',
									}}
								>
									<Text
										style={{
											fontWeight: 'bold',
											fontSize: '40px',
											textTransform: 'uppercase',
											paddingBottom: '10px',
											borderBottom: '1px solid white',
											marginBottom: '10px',
										}}
									>
										{heading}
									</Text>
									<Text style={{ fontSize: '16px' }}>{state?.selectedListing?.address}</Text>
									<Text style={{ fontWeight: 'bold', fontSize: '32px' }}>{features[0]}</Text>
								</View>
							</View>
							<View style={{ padding: '20px', fontFamily: 'Quant', gap: '5px' }}>
								<View style={{ flexDirection: 'row', gap: '30px', alignItems: 'center' }}>
									<Text style={{ fontWeight: 700 }}>OVERVIEW</Text>
									<View style={[styles.bar, { height: '1px', flex: 1 }]}></View>
								</View>
								<View style={{ flexDirection: 'row', gap: '10px' }}>
									{state?.selectedImages[1] && (
										<Image
											src={{ uri: state?.selectedImages[1], method: 'GET', headers: { 'Cache-Control': 'no-cache' }, body: '' }}
											style={{ width: '100%', height: '150px', objectFit: 'cover' }}
										/>
									)}
									{state?.selectedImages[2] && (
										<Image
											src={{ uri: state?.selectedImages[2], method: 'GET', headers: { 'Cache-Control': 'no-cache' }, body: '' }}
											style={{ width: '100%', height: '150px', objectFit: 'cover' }}
										/>
									)}
									{state?.selectedImages[3] && (
										<Image
											src={{ uri: state?.selectedImages[3], method: 'GET', headers: { 'Cache-Control': 'no-cache' }, body: '' }}
											style={{ width: '100%', height: '150px', objectFit: 'cover' }}
										/>
									)}
								</View>
							</View>
							<View style={{ padding: '20px', fontFamily: 'Quant', backgroundColor: '#F5F5F5', gap: '5px' }}>
								<View style={{ flexDirection: 'row', gap: '30px', alignItems: 'center', marginBottom: '5px' }}>
									<Text style={{ fontWeight: 700 }}>ABOUT</Text>
									<View style={[styles.bar, { height: '1px', flex: 1 }]}></View>
								</View>
								<Text style={{ fontFamily: 'OpenSans', fontSize: '12px', textAlign: 'justify' }}>{description}</Text>
							</View>
							<View style={{ padding: '20px', fontFamily: 'Quant', gap: '5px' }}>
								<View style={{ flexDirection: 'row', gap: '30px', alignItems: 'center', marginBottom: '5px' }}>
									<Text style={{ fontWeight: 700 }}>FEATURES</Text>
									<View style={[styles.bar, { height: '1px', flex: 1 }]}></View>
								</View>
								<View style={{ flexDirection: 'row', justifyContent: 'space-between', fontSize: '14px' }}>
									<View style={{ flex: 1, gap: '10px', flexDirection: 'row', alignItems: 'center' }}>
										<Svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
											<Path
												d='M6.6 12.0008C8.25413 12.0008 9.6 10.6549 9.6 9.00078C9.6 7.34666 8.25413 6.00078 6.6 6.00078C4.94588 6.00078 3.6 7.34666 3.6 9.00078C3.6 10.6549 4.94588 12.0008 6.6 12.0008ZM19.8 7.20078H11.4C11.0685 7.20078 10.8 7.46928 10.8 7.80078V13.2008H2.4V5.40078C2.4 5.06928 2.1315 4.80078 1.8 4.80078H0.6C0.2685 4.80078 0 5.06928 0 5.40078V18.6008C0 18.9323 0.2685 19.2008 0.6 19.2008H1.8C2.1315 19.2008 2.4 18.9323 2.4 18.6008V16.8008H21.6V18.6008C21.6 18.9323 21.8685 19.2008 22.2 19.2008H23.4C23.7315 19.2008 24 18.9323 24 18.6008V11.4008C24 9.08103 22.1198 7.20078 19.8 7.20078Z'
												fill='#A48111'
											/>
										</Svg>
										<Text>{features[1]}</Text>
									</View>
									<View style={{ flex: 1, gap: '10px', flexDirection: 'row', alignItems: 'center' }}>
										<Svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
											<Path
												d='M22.875 12H3.75V5.25C3.75 4.42289 4.42289 3.75 5.25 3.75C5.78208 3.75 6.24994 4.02867 6.51633 4.4475C5.74327 5.62908 5.82787 7.24012 6.83686 8.33977C6.64111 8.56069 6.64837 8.89833 6.85987 9.10983L7.39022 9.64017C7.60988 9.85983 7.96603 9.85983 8.18573 9.64017L12.6403 5.18569C12.8599 4.96603 12.8599 4.60987 12.6403 4.39017L12.1099 3.85983C11.8984 3.64833 11.5608 3.64111 11.3399 3.83681C10.4985 3.06483 9.35733 2.83233 8.33152 3.11658C7.6538 2.14064 6.52556 1.5 5.25 1.5C3.18225 1.5 1.5 3.18225 1.5 5.25V12H1.125C0.503672 12 0 12.5037 0 13.125V13.875C0 14.4963 0.503672 15 1.125 15H1.5V16.5C1.5 17.8327 2.07947 19.0298 3 19.8538V21.375C3 21.9963 3.50367 22.5 4.125 22.5H4.875C5.49633 22.5 6 21.9963 6 21.375V21H18V21.375C18 21.9963 18.5037 22.5 19.125 22.5H19.875C20.4963 22.5 21 21.9963 21 21.375V19.8538C21.9205 19.0298 22.5 17.8327 22.5 16.5V15H22.875C23.4963 15 24 14.4963 24 13.875V13.125C24 12.5037 23.4963 12 22.875 12Z'
												fill='#A48111'
											/>
										</Svg>
										<Text>{features[2]}</Text>
									</View>

									<View style={{ flex: 1, gap: '10px', flexDirection: 'row', alignItems: 'center' }}>
										<Svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
											<Path
												d='M23.4166 1.5329C23.0569 1.36898 22.648 1.42818 22.3493 1.68754L0.344241 20.8111C0.0271316 21.0867 -0.0827434 21.519 0.0643972 21.9125C0.211538 22.306 0.578007 22.5603 0.9981 22.5603H23.0032C23.5528 22.5603 24 22.1131 24 21.5634V2.43998C24 2.04449 23.7764 1.69691 23.4166 1.5329ZM23.2018 6.19349H23.2018H21.5308C21.3103 6.19349 21.1317 6.37218 21.1317 6.59259C21.1317 6.81299 21.3103 6.99168 21.5308 6.99168H23.2018V12.6366H21.5308C21.3103 12.6366 21.1317 12.8153 21.1317 13.0357C21.1317 13.2561 21.3103 13.4348 21.5308 13.4348H23.2018V18.932H21.78C21.5595 18.932 21.3809 19.1107 21.3809 19.3311C21.3809 19.5515 21.5595 19.7302 21.78 19.7302H23.2018V21.5635C23.2018 21.673 23.1127 21.7621 23.0032 21.7621H21.3094V20.4441C21.3094 20.2237 21.1307 20.045 20.9103 20.045C20.6898 20.045 20.5112 20.2237 20.5112 20.4441V21.7621H14.8663V20.0911C14.8663 19.8707 14.6877 19.692 14.4672 19.692C14.2468 19.692 14.0681 19.8707 14.0681 20.0911V21.7621H8.42319V20.0911C8.42319 19.8707 8.24455 19.692 8.0241 19.692C7.80365 19.692 7.62501 19.8707 7.62501 20.0911V21.7621H0.9981C0.873741 21.7621 0.826538 21.6719 0.812053 21.633C0.797569 21.5942 0.773897 21.4952 0.867835 21.4135L22.873 2.29002C22.9169 2.25182 22.9618 2.24029 23.0001 2.24029C23.0374 2.24029 23.0683 2.25135 23.0856 2.25918C23.1206 2.27507 23.2018 2.32462 23.2018 2.43993V6.19349Z'
												fill='#A48111'
											/>
											<Path
												d='M18.6024 10.0071C18.3828 9.90694 18.1334 9.94308 17.9511 10.1014L13.4802 13.9869C13.3138 14.1315 13.2962 14.3836 13.4408 14.5499C13.5854 14.7163 13.8375 14.7339 14.0038 14.5893L18.1601 10.9772V17.0622H11.1581L11.7386 16.5579C11.9049 16.4133 11.9226 16.1612 11.778 15.9948C11.6334 15.8284 11.3813 15.8108 11.215 15.9554L10.2511 16.7931C10.0576 16.9613 9.99052 17.2251 10.0803 17.4651C10.1701 17.7052 10.3937 17.8604 10.6501 17.8604H18.3501C18.6855 17.8604 18.9583 17.5875 18.9583 17.2521V10.5605C18.9583 10.3193 18.8219 10.1071 18.6024 10.0071Z'
												fill='#A48111'
											/>
											<Path
												d='M3.19206 21.349C3.41248 21.349 3.59116 21.1703 3.59116 20.9499C3.59116 20.7295 3.41248 20.5508 3.19206 20.5508C2.97165 20.5508 2.79297 20.7295 2.79297 20.9499C2.79297 21.1703 2.97165 21.349 3.19206 21.349Z'
												fill='#A48111'
											/>
											<Path
												d='M4.80144 21.349C5.02185 21.349 5.20053 21.1703 5.20053 20.9499C5.20053 20.7295 5.02185 20.5508 4.80144 20.5508C4.58102 20.5508 4.40234 20.7295 4.40234 20.9499C4.40234 21.1703 4.58102 21.349 4.80144 21.349Z'
												fill='#A48111'
											/>
											<Path
												d='M6.41277 21.349C6.63318 21.349 6.81186 21.1703 6.81186 20.9499C6.81186 20.7295 6.63318 20.5508 6.41277 20.5508C6.19235 20.5508 6.01367 20.7295 6.01367 20.9499C6.01367 21.1703 6.19235 21.349 6.41277 21.349Z'
												fill='#A48111'
											/>
											<Path
												d='M9.63542 21.349C9.85583 21.349 10.0345 21.1703 10.0345 20.9499C10.0345 20.7295 9.85583 20.5508 9.63542 20.5508C9.41501 20.5508 9.23633 20.7295 9.23633 20.9499C9.23633 21.1703 9.41501 21.349 9.63542 21.349Z'
												fill='#A48111'
											/>
											<Path
												d='M11.2468 21.349C11.4672 21.349 11.6458 21.1703 11.6458 20.9499C11.6458 20.7295 11.4672 20.5508 11.2468 20.5508C11.0263 20.5508 10.8477 20.7295 10.8477 20.9499C10.8477 21.1703 11.0263 21.349 11.2468 21.349Z'
												fill='#A48111'
											/>
											<Path
												d='M12.8561 21.349C13.0765 21.349 13.2552 21.1703 13.2552 20.9499C13.2552 20.7295 13.0765 20.5508 12.8561 20.5508C12.6357 20.5508 12.457 20.7295 12.457 20.9499C12.457 21.1703 12.6357 21.349 12.8561 21.349Z'
												fill='#A48111'
											/>
											<Path
												d='M16.0788 21.349C16.2992 21.349 16.4779 21.1703 16.4779 20.9499C16.4779 20.7295 16.2992 20.5508 16.0788 20.5508C15.8584 20.5508 15.6797 20.7295 15.6797 20.9499C15.6797 21.1703 15.8584 21.349 16.0788 21.349Z'
												fill='#A48111'
											/>
											<Path
												d='M17.6882 21.349C17.9086 21.349 18.0872 21.1703 18.0872 20.9499C18.0872 20.7295 17.9086 20.5508 17.6882 20.5508C17.4677 20.5508 17.2891 20.7295 17.2891 20.9499C17.2891 21.1703 17.4677 21.349 17.6882 21.349Z'
												fill='#A48111'
											/>
											<Path
												d='M19.2995 21.349C19.5199 21.349 19.6986 21.1703 19.6986 20.9499C19.6986 20.7295 19.5199 20.5508 19.2995 20.5508C19.0791 20.5508 18.9004 20.7295 18.9004 20.9499C18.9004 21.1703 19.0791 21.349 19.2995 21.349Z'
												fill='#A48111'
											/>
											<Path
												d='M22.3913 18.2669C22.6117 18.2669 22.7904 18.0883 22.7904 17.8678C22.7904 17.6474 22.6117 17.4688 22.3913 17.4688C22.1709 17.4688 21.9922 17.6474 21.9922 17.8678C21.9922 18.0883 22.1709 18.2669 22.3913 18.2669Z'
												fill='#A48111'
											/>
											<Path
												d='M22.3913 16.6556C22.6117 16.6556 22.7904 16.4769 22.7904 16.2565C22.7904 16.0361 22.6117 15.8574 22.3913 15.8574C22.1709 15.8574 21.9922 16.0361 21.9922 16.2565C21.9922 16.4769 22.1709 16.6556 22.3913 16.6556Z'
												fill='#A48111'
											/>
											<Path
												d='M22.3913 15.0443C22.6117 15.0443 22.7904 14.8656 22.7904 14.6452C22.7904 14.4248 22.6117 14.2461 22.3913 14.2461C22.1709 14.2461 21.9922 14.4248 21.9922 14.6452C21.9922 14.8656 22.1709 15.0443 22.3913 15.0443Z'
												fill='#A48111'
											/>
											<Path
												d='M22.3913 11.8236C22.6117 11.8236 22.7904 11.6449 22.7904 11.4245C22.7904 11.2041 22.6117 11.0254 22.3913 11.0254C22.1709 11.0254 21.9922 11.2041 21.9922 11.4245C21.9922 11.6449 22.1709 11.8236 22.3913 11.8236Z'
												fill='#A48111'
											/>
											<Path
												d='M22.3913 10.2122C22.6117 10.2122 22.7904 10.0336 22.7904 9.81316C22.7904 9.59274 22.6117 9.41406 22.3913 9.41406C22.1709 9.41406 21.9922 9.59274 21.9922 9.81316C21.9922 10.0336 22.1709 10.2122 22.3913 10.2122Z'
												fill='#A48111'
											/>
											<Path
												d='M22.3913 8.60092C22.6117 8.60092 22.7904 8.42224 22.7904 8.20183C22.7904 7.98142 22.6117 7.80273 22.3913 7.80273C22.1709 7.80273 21.9922 7.98142 21.9922 8.20183C21.9922 8.42224 22.1709 8.60092 22.3913 8.60092Z'
												fill='#A48111'
											/>
											<Path
												d='M22.3913 5.38022C22.6117 5.38022 22.7904 5.20154 22.7904 4.98112C22.7904 4.76071 22.6117 4.58203 22.3913 4.58203C22.1709 4.58203 21.9922 4.76071 21.9922 4.98112C21.9922 5.20154 22.1709 5.38022 22.3913 5.38022Z'
												fill='#A48111'
											/>
											<Path
												d='M22.3913 3.76889C22.6117 3.76889 22.7904 3.59021 22.7904 3.3698C22.7904 3.14938 22.6117 2.9707 22.3913 2.9707C22.1709 2.9707 21.9922 3.14938 21.9922 3.3698C21.9922 3.59021 22.1709 3.76889 22.3913 3.76889Z'
												fill='#A48111'
											/>
											<Path
												d='M12.6042 15.6947C12.8246 15.6947 13.0033 15.516 13.0033 15.2956C13.0033 15.0752 12.8246 14.8965 12.6042 14.8965C12.3838 14.8965 12.2051 15.0752 12.2051 15.2956C12.2051 15.516 12.3838 15.6947 12.6042 15.6947Z'
												fill='#A48111'
											/>
										</Svg>

										<Text>{features[3]}</Text>
									</View>
								</View>
							</View>
							<View style={{ flexDirection: 'row', gap: '20px', alignItems: 'flex-end', padding: '20px' }}>
								{agentPhoto && (
									<Image
										src={{ uri: agentPhoto, method: 'GET', headers: { 'Cache-Control': 'no-cache' }, body: '' }}
										style={{ width: '90px', height: '100px', objectFit: 'contain' }}
									/>
								)}
								<View style={{ paddingTop: '10px', borderTop: '1px solid #A48111', flex: 1, fontFamily: 'OpenSans', fontSize: '12px' }}>
									<View style={{ flexDirection: 'row', gap: '10px' }}>
										<Text style={{ flex: 1 }}>{name}</Text>
										<Text style={{ flex: 1 }}>{email}</Text>
										<Text style={{ flex: 1 }}>Lic #: {license}</Text>
									</View>
									<View style={{ flexDirection: 'row', gap: '10px' }}>
										<Text style={{ flex: 1 }}>{brok_name}</Text>
										<Text style={{ flex: 1 }}>{phone}</Text>
										<Text style={{ flex: 1 }}></Text>
									</View>
								</View>
							</View>
						</View>
					</Page>
				</Document>
			);
		}
	};

	const onSave = () => {
		setIsLoading(true);
		base
			.patch(`premiersite/listings/${listing.id}/`, {
				description: state?.description,
			})
			.then(({ data }) => {
				setListing(data);
				toast.success('Description updated successfully');
			})
			.catch(() => {
				toast.error('Error updating description');
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	return (
		<>
			<Dialog
				open={tempModalOpen}
				onClose={() => setTempModalOpen(false)}
				maxWidth='md'
				PaperProps={{
					style: {
						backgroundColor: 'transparent',
						boxShadow: 'none',
						borderRadius: '0',
						margin: '0',
					},
				}}
			>
				<>
					<IconButton sx={{ position: 'fixed', top: '2rem', right: '15rem', backgroundColor: 'white' }}>
						<Close size={20} onClick={() => setTempModalOpen(false)} />
					</IconButton>
					<PDFDownloadLink document={<PrintTemplate />} fileName={`${name}_brochure.pdf`}>
						{({ loading }) =>
							loading ? (
								<IconButton sx={{ position: 'fixed', top: '2rem', right: '5rem', backgroundColor: 'white' }}>
									<CircularProgress size={20} />
								</IconButton>
							) : (
								<IconButton sx={{ position: 'fixed', top: '2rem', right: '5rem', backgroundColor: 'white' }}>
									<Download />
								</IconButton>
							)
						}
					</PDFDownloadLink>

					<Box sx={{ width: '100%' }}>{getContent()}</Box>
				</>
			</Dialog>
			<Dialog open={photoModal} onClose={() => setPhotoModal(false)} maxWidth='md'>
				<ImageList sx={{ width: '100%', height: '100%', mx: 'auto', overflowY: 'unset' }} cols={3} rowHeight={200}>
					{state.imagesBackup?.map((image) => (
						<ImageListItem key={image}>
							<img
								src={`${image}`}
								alt={'Property'}
								style={{
									border: '2px solid white',
									borderRadius: '5px',
									aspectRatio: '4/3',
									height: '100%',
									cursor: 'pointer',
								}}
								onClick={() => handleImageChange(image)}
							/>
						</ImageListItem>
					))}
				</ImageList>
			</Dialog>
			<Box padding='1rem'>
				<Typography variant='body1'>
					To generate a brochure, select one of the templates below. You can insert or edit the property pictures on the brochure. Property info on
					the brochure flows from listing profile, Broker’s info flows from the Agent’s Profile. Provide a title and description to the brochure.
				</Typography>
				<Grid container spacing={2} my={2}>
					{templates.map((template, index) => (
						<Grid item xs={2.4} key={template.id}>
							<Selector number={index + 1} onClick={() => handleTempClick(template, index + 1)} {...template} />
						</Grid>
					))}
				</Grid>
				<Stack width='50%' gap='0.5rem' my='1rem'>
					<InputLabel>Title</InputLabel>
					<TextField fullWidth label='Title' size='small' value={state.title} name='title' onChange={handleChange} />
				</Stack>
				<Stack width='50%' gap='0.5rem' my='1rem'>
					<InputLabel>Body Text/Description (300 character limit)</InputLabel>
					<TextField
						fullWidth
						label='Description'
						size='small'
						multiline
						minRows={4}
						helperText={`${state.description?.length || 0}/300`}
						value={state.description}
						name='description'
						onChange={handleChange}
						sx={{
							'.MuiFormHelperText-root': {
								color: `${state.description?.length > 300 ? 'red' : 'green'} !important`,
							},
						}}
					/>
				</Stack>
				<Button variant='contained' color='secondary' onClick={onSave}>
					Save
				</Button>
			</Box>
		</>
	);
};

export default Brochure;

const Selector = ({ number, image, onClick }) => (
	<div className='templatescreen-preview-item' style={{ borderColor: '#e6e6e6' }} onClick={onClick}>
		<div>
			<span style={{ width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center', backgroundColor: '#0000005c' }}>Select</span>
			<img
				src={image}
				alt='template'
				style={{ width: '100%', objectFit: 'cover', height: '100%', borderBottom: '1px solid grey', objectPosition: 'top' }}
			/>
		</div>
		<p>{number}</p>
	</div>
);
