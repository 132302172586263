import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { Box, Button, Link as DLink, Divider, Grid, IconButton, Stack, TextField, Typography, createTheme } from '@mui/material';
import { ThemeProvider, makeStyles, styled } from '@mui/styles';
import React from 'react';
import { Link as RLink } from 'react-router-dom';
import BG from '../../images/temp12bg.webp';
import { formatNumber, formatPhoneNumber, getYoutubeEmbedLink, hideButton, scrollToTheLeft } from '../../utils/functions';

const pageTheme = createTheme({
	// ...theme,
	typography: {
		fontFamily: 'Playfair Display, san-serif',
		button: {
			textTransform: 'capitalize',
		},
	},
	palette: {
		primary: {
			main: '#000',
		},
		secondary: {
			main: '#AA8632',
		},
		text: {
			main: '#000',
		},
		paper: {
			main: '#fff',
		},
	},
	primaryColor: '#000',
	secondaryColor: '#AA8632',
});
const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		height: '100vh',
		// backgroundImage: `url(${BG})`,
		background: `linear-gradient(180deg, rgba(20, 27, 37, 0.44) 9.87%, rgba(0, 0, 0, 0.00) 72.13%), url(${BG}), lightgray 50% / cover no-repeat`,
		backgroundSize: 'cover',
		backgroundPosition: 'center',
		display: 'flex',
		// justifyContent: 'center',
		alignItems: 'center',
	},
	header: {
		position: 'absolute',
		top: '0',
		width: '100%',
		padding: '1.5rem',
		display: 'flex',
		justifyContent: 'space-between',
		// background: 'rgba(0, 0, 0, 0.1)',
		fontFamily: 'Playfair Display, san-serif',
	},
	padder: {
		padding: '3rem 5rem',
	},
	title: {
		textTransform: 'capitalize',
		fontWeight: '500',
		fontSize: '2.5rem',
	},
}));

const Link = styled(DLink)(({ theme }) => ({
	cursor: 'pointer',
	fontFamily: 'Montserrat, san-serif',
	fontWeight: '500',
	textTransform: 'capitalize',
}));

const TransBut = styled(Button)(({ theme }) => ({
	backgroundColor: 'transparent',
	border: '1px solid #333',
	color: '#333',
	padding: '0.5rem 1rem',
	width: 'fit-content',
	borderRadius: '40px',
	textTransform: 'uppercase',
	fontFamily: 'Playfair Display, san-serif',
	fontWeight: '500',
	fontSize: '16px',
	'&:hover': {
		backgroundColor: 'transparent',
	},
}));

const FullBut = styled(Button)(({ theme }) => ({
	backgroundColor: '#AA8632',
	color: 'white',
	padding: '0.5rem 1rem',
	borderRadius: '40px',
	width: 'fit-content',
	textTransform: 'uppercase',
	fontFamily: 'Playfair Display, san-serif',
	fontSize: '16px',
	'&:hover': {
		backgroundColor: '#AA8632',
	},
}));

function NewTemp12({ agent, listings, testimonials, contactDetails, updateContactDetails, sendMessage, serviceAreas }) {
	const classes = useStyles();
	const name = agent?.user.first_name + ' ' + agent?.user.last_name;
	const agent_id = agent?.id;
	// const member_since = agent?.year_work_commenced;
	const agent_phone = formatPhoneNumber(agent?.phone);
	// const brokerage_phone = formatPhoneNumber(agent?.brokerage?.brokerage_phone);
	const license = agent?.licenses.find((license) => license.category === 'real_estate_agent')?.number;
	const address = agent?.address;
	const video = agent?.video_link;
	return (
		<ThemeProvider theme={pageTheme}>
			<Box className={classes.root}>
				<Box className={classes.header} padding='2rem 5rem'>
					<Typography variant='body1' color='white' fontWeight='400' fontSize='16px' sx={{ textTransform: 'capitalize' }}>
						{name}
					</Typography>
					<Box display='flex' gap='1rem'>
						<Link href='#home' underline='none' color='white' fontFamily='inherit' sx={{ textTransform: 'uppercase' }}>
							Home
						</Link>
						<Link href='#about' underline='none' color='white' fontFamily='inherit' sx={{ textTransform: 'uppercase' }}>
							About
						</Link>
						{true && (
							<Link href='#active' underline='none' color='white' fontFamily='inherit' sx={{ textTransform: 'uppercase' }}>
								Active Listings
							</Link>
						)}
						<Link href='#contact' underline='none' color='white' fontFamily='inherit' sx={{ textTransform: 'uppercase' }}>
							Contact
						</Link>
						<Link underline='none' color='white' fontFamily='inherit' sx={{ textTransform: 'uppercase' }} href={`/agent/${agent?.id}/blogs`}>
							Blog
						</Link>
					</Box>
				</Box>
				<Typography
					variant='h4'
					whiteSpace={'pre-line'}
					color='white'
					textTransform='capitalize'
					fontFamily='Playfair Display'
					textAlign='left'
					pl='5rem'
					mb='10rem'
				>
					{agent?.landing_page_title || 'Welcome to Your Ultimate Real Estate Destination'}
				</Typography>
				<Stack direction='row' sx={{ position: 'absolute', bottom: 0, right: 0, background: 'white' }} gap='10rem' padding='1rem'>
					<Stack direction='column' gap='1rem'>
						<Typography variant='body1' fontWeight={700} color='#333' fontFamily={'Playfair Display'} fontSize='16px'>
							Location
						</Typography>
						<Typography variant='body1' color='#6A7680' fontWeight={400} fontFamily={'Montserrat'} fontSize='18px'>
							{address}
						</Typography>
					</Stack>
					<Stack direction='column' gap='1rem'>
						<Typography variant='body1' fontWeight={700} color='#333' fontFamily={'Playfair Display'} fontSize='16px'>
							Phone
						</Typography>
						<Typography variant='body1' color='#6A7680' fontWeight={400} fontFamily={'Montserrat'} fontSize='18px'>
							{agent_phone}
						</Typography>
					</Stack>
					<Stack direction='column' gap='1rem'>
						<Typography variant='body1' fontWeight={700} color='#333' fontFamily={'Playfair Display'} fontSize='16px'>
							Real Estate License
						</Typography>
						<Typography variant='body1' color='#6A7680' fontWeight={400} fontFamily={'Montserrat'} fontSize='18px'>
							{license}
						</Typography>
					</Stack>
				</Stack>
			</Box>
			<Grid container spacing={2} className={classes.padder} alignItems={'center'} justifyContent={'center'} id='about'>
				<Grid item xs={6}>
					<Box>
						<Divider sx={{ width: '70px', height: '2px', background: '#E3B957' }} />
						<Typography variant={'h6'} fontFamily='Playfair Display' className={classes.title} my='1rem'>
							Meet <span style={{ color: '#E3B957' }}>{name}</span>,<br /> Your Real Estate Expert
						</Typography>
						<Typography variant='body1' whiteSpace={'pre-line'} fontWeight={400} my='1rem' color='#6A7680' fontFamily='Montserrat'>
							{agent?.bio}
						</Typography>
						<Stack direction='row' spacing={2} mt='2rem'>
							<FullBut variant='contained' disableElevation href='#contact'>
								Let's Chat
							</FullBut>
							<TransBut variant='contained' disableElevation href={`/order-bpo-report/${agent_id}`} target='_blank'>
								Order a bpo report
							</TransBut>
						</Stack>
					</Box>
				</Grid>
				<Grid item xs={6}>
					<img
						src={agent?.profile_image}
						alt='Agent'
						style={{
							width: '400px',
							aspectRatio: '3/4',
							objectFit: 'cover',
							objectPosition: 'top center',
							marginLeft: 'auto',
							marginRight: '1rem',
							display: 'block',
						}}
					/>
				</Grid>
			</Grid>

			{video && (
				<iframe
					style={{
						border: 'none',
						width: '100%',
						height: '70vh',
						margin: '2rem 0',
					}}
					src={`https://www.youtube.com/embed/${getYoutubeEmbedLink(video)}`}
					title='Client video'
					allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
					allowFullScreen
				></iframe>
			)}

			{listings?.active.length > 0 && (
				<Box className={classes.padder} sx={{ background: '#f7f9f9', display: 'flex', flexDirection: 'column', position: 'relative' }}>
					<Stack direction='column' gap='1rem' alignItems={'center'}>
						<Divider sx={{ width: '70px', height: '2px', background: '#E3B957' }} />
						<Typography variant={'h6'} fontFamily='Playfair Display' className={classes.title} my='1rem'>
							Active Listings
						</Typography>
					</Stack>
					<Box
						display='flex'
						gap='20px'
						id='active'
						sx={{
							maxWidth: '100%',
							overflowX: 'scroll',
							padding: '1rem',
							'&::-webkit-scrollbar': {
								display: 'none',
							},
							scrollBehavior: 'smooth',
						}}
					>
						<IconButton
							ref={(el) => hideButton(el, 'active')}
							sx={{
								position: 'absolute',
								top: '40%',
								left: '15px',
								backgroundColor: 'white',
								borderRadius: '2px',
								justifyContent: 'center',
								gap: '2rem',
								'&:hover': { backgroundColor: 'white' },
								boxShadow: '0px 0px 10px 0px #00000026',
							}}
							onClick={() => scrollToTheLeft('active', true)}
						>
							<ChevronLeft fontSize='large' />
						</IconButton>
						{listings?.active?.map((active) => (
							<Card key={active.id} {...active} agentId={agent_id} />
						))}
						<IconButton
							ref={(el) => hideButton(el, 'active')}
							sx={{
								position: 'absolute',
								top: '40%',
								right: '15px',
								backgroundColor: 'white',
								borderRadius: '2px',
								'&:hover': { backgroundColor: 'white' },
								boxShadow: '0px 0px 10px 0px #00000026',
							}}
							onClick={() => scrollToTheLeft('active', false)}
						>
							<ChevronRight fontSize='large' />
						</IconButton>
					</Box>
				</Box>
			)}
			{listings?.sold.length > 0 && (
				<Box className={classes.padder} sx={{ display: 'flex', flexDirection: 'column', position: 'relative' }}>
					<Stack direction='column' gap='1rem' alignItems={'center'}>
						<Divider sx={{ width: '70px', height: '2px', background: '#E3B957' }} />
						<Typography variant={'h6'} fontFamily='Playfair Display' className={classes.title} my='1rem'>
							Sold Listings
						</Typography>
					</Stack>
					<Box
						display='flex'
						gap='20px'
						id='sold'
						sx={{
							maxWidth: '100%',
							overflowX: 'scroll',
							padding: '1rem',
							'&::-webkit-scrollbar': {
								display: 'none',
							},
							scrollBehavior: 'smooth',
						}}
					>
						<IconButton
							ref={(el) => hideButton(el, 'sold')}
							sx={{
								position: 'absolute',
								top: '40%',
								left: '15px',
								backgroundColor: 'white',
								borderRadius: '2px',
								justifyContent: 'center',
								gap: '2rem',
								'&:hover': { backgroundColor: 'white' },
								boxShadow: '0px 0px 10px 0px #00000026',
							}}
							onClick={() => scrollToTheLeft('sold', true)}
						>
							<ChevronLeft fontSize='large' />
						</IconButton>
						{listings?.sold?.map((sold) => (
							<Card key={sold.id} {...sold} agentId={agent_id} />
						))}
						<IconButton
							ref={(el) => hideButton(el, 'sold')}
							sx={{
								position: 'absolute',
								top: '40%',
								right: '15px',
								backgroundColor: 'white',
								borderRadius: '2px',
								'&:hover': { backgroundColor: 'white' },
								boxShadow: '0px 0px 10px 0px #00000026',
							}}
							onClick={() => scrollToTheLeft('sold', false)}
						>
							<ChevronRight fontSize='large' />
						</IconButton>
					</Box>
				</Box>
			)}

			<Grid container spacing={2} sx={{ width: '60%', margin: 'auto' }}>
				<Grid item xs={12}>
					<Stack direction='column' gap='1rem' alignItems={'center'}>
						<Divider sx={{ width: '70px', height: '2px', background: '#E3B957' }} />
						<Typography variant={'h6'} fontFamily='Playfair Display' className={classes.title} my='1rem'>
							Get In Touch
						</Typography>
					</Stack>
				</Grid>
				<Grid item xs={12}>
					<TextField
						variant='standard'
						fullWidth
						size='small'
						name='name'
						placeholder='Full Name'
						value={contactDetails.name}
						onChange={updateContactDetails}
					/>
				</Grid>
				<Grid item xs={6}>
					<TextField
						variant='standard'
						fullWidth
						size='small'
						name='email'
						placeholder='Email'
						value={contactDetails.email}
						onChange={updateContactDetails}
					/>
				</Grid>
				<Grid item xs={6}>
					<TextField
						variant='standard'
						fullWidth
						size='small'
						name='phone'
						placeholder='Phone Number'
						value={contactDetails.phone}
						onChange={updateContactDetails}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						variant='standard'
						fullWidth
						size='small'
						multiline
						maxRows={5}
						name='message'
						placeholder='Message'
						value={contactDetails.message}
						onChange={updateContactDetails}
					/>
					<FullBut variant='contained' disableElevation onClick={sendMessage} sx={{ display: 'block', margin: 'auto', marginTop: '2rem' }}>
						Send Message
					</FullBut>
				</Grid>
			</Grid>
		</ThemeProvider>
	);
}

export default NewTemp12;

const Card = ({ id, agentId, list_price, photos, beds, baths, square_footage, address }) => {
	return (
		<Link component={RLink} to={`/view-listing/${agentId}/${id}`} target='_blank'>
			<Box
				sx={{
					minWidth: '380px',
					background: 'white',
					boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.05)',
				}}
			>
				<img src={photos?.[0]?.image} alt='listing' style={{ width: '380px', aspectRatio: '1', objectFit: 'cover' }} />
				<Stack
					padding={'1rem'}
					sx={{ marginTop: '-15px', borderTopLeftRadius: '15px', borderTopRightRadius: '15px', background: 'white', position: 'relative', zIndex: 2 }}
				>
					<Typography fontSize='30px' fontWeight={'700'} fontFamily='Playfair Display, san-serif' textAlign='center'>
						$ {formatNumber(list_price, 0)}
					</Typography>
					<Stack
						direction='row'
						justifyContent={'center'}
						fontFamily='Montserrat'
						fontSize='14px'
						gap='1rem'
						divider={<span style={{ color: '#6A7680' }}>&middot;</span>}
					>
						<Typography fontWeight={300} fontFamily='inherit' color='#6A7680' fontSize='inherit'>
							{beds} beds
						</Typography>
						<Typography fontWeight={300} fontFamily='inherit' color='#6A7680' fontSize='inherit'>
							{baths} baths
						</Typography>
						<Typography fontWeight={300} fontFamily='inherit' color='#6A7680' fontSize='inherit'>
							{formatNumber(square_footage, 0)} sqft
						</Typography>
					</Stack>
					<Typography fontWeight={300} fontFamily='Montserrat, san-serif' fontSize='14px' color='#6A7680' textAlign='center'>
						{address}
					</Typography>
				</Stack>
			</Box>
		</Link>
	);
};

// const Testimonial = ({ description, name, client }) => {
// 	return (
// 		<Stack direction='column' justifyContent={'space-between'} sx={{ flex: '1', boxShadow: `0px 4px 20px 0px rgba(0, 0, 0, 0.05)`, padding: '1rem' }}>
// 			<Typography fontWeight={700} fontFamily='Playfair Display, san-serif' color='#222'>
// 				{client?.user?.first_name || client?.user?.last_name ? client?.user?.first_name + ' ' + client?.user?.last_name : name}
// 			</Typography>
// 			<Typography fontWeight={300} fontFamily='Playfair Display, san-serif' color='#777'>
// 				{description}
// 			</Typography>
// 		</Stack>
// 	);
// };
