import { Box, FormControl, TextField, OutlinedInput,InputAdornment, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import EnhancedRadioButtonGroup from '../atomicComponents/EnhancedRadioButtonGroup'
import StyledLabel from '../atomicComponents/StyledLabel';

function QuestionnairePropertyManagement({handler, data}) {
    const investors =data?.investors || []
    const showInvestors = +data?.investors_option===1 || data?.investors
    const [totalInvestors, setTotalInvestors] = React.useState([])


    const additionalStyles1 = {
        display: "flex",
        flexDirection:"row-reverse",
        justifyContent: "space-between",
      };
      const specialHandler = (e)=>{
        if(+e.target.value === 1){
          handler({investors: undefined,total_investors:0})
          setTotalInvestors([])
        }
        handler({[e.target.name]:e.target.value})
      }
      const handleChange = (e)=>{

        handler({investors:{...investors,[e.target.name]:e.target.value}})
      }
      const handleInvestors = (e)=>{
        if(+e.target.value < Object.keys(investors)?.length){
          const temp = data?.investors
          for(let i = 0; i<  Object.keys(investors)?.length ; i++){
             temp[`Investor${Object.keys(temp).length-1}`] = undefined
          }
          handler({investors: temp})
        }
        setTotalInvestors(Array(+e.target.value).fill("investor"))
      }

      useEffect(()=>{
        data?.investors && setTotalInvestors(Object.keys(data.investors))
        !data?.investors && handler({investors_option:0})
        !data?.property_manager && handler({property_manager:1})
      // NOTE: Run effect once on component mount, please
      // recheck dependencies if effect is updated.
      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[])
  return (
    <Box>
      <Box sx={{ display: "flex" }}>
        <Box sx={{ display: "flex",mr:4}}>
          <StyledLabel label="Are you using a Property Manager?" />
        </Box>
        <Box sx={{mt:2}}>
        <EnhancedRadioButtonGroup
          options={options}
          styles={additionalStyles1}
          specialHandler={specialHandler}
          name="property_manager"
          choice={data?.property_manager}
        />
        </Box>

      </Box>
      <Box sx={{ display: "flex" }}>
        <Box sx={{ display: "flex",mr:4 }}>
          <StyledLabel label="Are there any investors for the property?" />
        </Box>
        <Box sx={{mt:2}}>
        <EnhancedRadioButtonGroup
          options={options}
          styles={additionalStyles1}
          specialHandler={specialHandler}
          name="investors_option"
          choice={data?.investors_option}
        />
        </Box>

      </Box>
      { showInvestors &&
        <>
        <Box>
          <Box>
            <Box sx={{mb:1}}>
              <StyledLabel label ="How many investors invested in the property?"/>
            </Box>
            <Box>
            <FormControl sx={{width:"60%",minWidth:"17.5rem" }}>
                <OutlinedInput
                  fullWidth
                  margin="normal"
                  name='total_investors'
                  onChange={handleInvestors}
                  value={totalInvestors.length || 0}
                  type='number'
                  placeholder='0'
                  renderValue={(value) => value}
                  inputProps={{ style: { fontSize: "1rem" } }}
                />
              </FormControl>
            </Box>


          </Box>
        </Box>
        <Box sx={{mb:-2, mt:4}}>
        <StyledLabel label={`What is each investors' percentage contribution?`} />
        </Box>
        <Box sx={{display:"grid",gridTemplateColumns:"1fr 1fr",gap:"2rem", margin:"1.875rem 0"}}>
          {totalInvestors.map((element, index) => (
            <Box sx={{display:"flex",alignItems:"center"}}>
              <Box sx={{marginRight:"1rem"}}>
                <StyledLabel label={`Investor${index + 1}:`} />
              </Box>
              <FormControl sx={{  }}>
                <OutlinedInput
                  fullWidth
                  margin="normal"
                  name={`Investor${index}`}
                  onChange={handleChange}
                  type="number"
                  value={
                    data?.investors?.[`Investor${index}`]
                  }
                  renderValue={(value) => value}
                  placeholder={0}
                  inputProps={{ style: { fontSize: "1rem" } }}
                  endAdornment={
                    <InputAdornment position="start">
                      {" "}
                      <Typography variant="adornment" color="secondary">
                        {" "}
                        %
                      </Typography>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Box>
          ))}
        </Box>
        </>
      }
      <Box>
        <Box sx={{ marginBottom: "10px" }}>
          <StyledLabel label="Any Information you would like to share?" />
        </Box>
        <FormControl sx={{ width: "100%" }}>
          <TextField 
          fullWidth type="text" 
          name='information'
          multiline minRows={5}
          placeholder='Enter information here'
          onChange = {(e)=>{handler({[e.target.name]:e.target.value})}} 
          
          />
        </FormControl>
      </Box>
    </Box>
  );
}

export default QuestionnairePropertyManagement
const options = [
    'No',
    'Yes'
]
