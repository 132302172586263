import { ChevronLeft, ChevronRight, SquareFoot } from '@mui/icons-material';
import BedIcon from '@mui/icons-material/Bed';
import { Box, Button, Divider, Grid, IconButton, Link, Stack, TextField, Typography, createTheme } from '@mui/material';
import { ThemeProvider, makeStyles, styled } from '@mui/styles';
import React from 'react';
import { BiBath } from 'react-icons/bi';
import { Link as RLink, useParams } from 'react-router-dom';
import { ReactComponent as FBIcon } from '../../images/facebookfillable.svg';
import { ReactComponent as IGIcon } from '../../images/instagramfillable.svg';
import { ReactComponent as LIIcon } from '../../images/linkedInfillable.svg';
import BG from '../../images/temp8bg.webp';
import ConBG from '../../images/temp8contact.webp';
import { ReactComponent as TWIcon } from '../../images/twitterfillable.svg';
import { ReactComponent as YTIcon } from '../../images/youtubefillable.svg';
import { theme } from '../../theme';
import { formatNumber, formatPhoneNumber, getYoutubeEmbedLink, hideButton, scrollToTheLeft } from '../../utils/functions';
import ServiceAreasSlider from '../../components/ServiceAreasSlider/ServiceAreasSlider';
import InvestorQuestionnaires from '../../components/InvestorQuestionnaires/InvestorQuestionnaires';

const pageTheme = createTheme({
	...theme,
	typography: {
		fontFamily: 'Open Sans, sans-serif',
		fontSize: 16,
	},
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					borderRadius: '40px',
				},
			},
			defaultProps: {
				disableRipple: true,
				disableElevation: true,
			},
		},
		MuiIconButton: {
			defaultProps: {
				disableRipple: true,
			},
		},
	},
	palette: {
		primary: {
			main: '#FB742D',
		},
		secondary: {
			main: '#fff',
		},
		success: {
			main: '#FB742D',
		},
		text: {
			main: '#000',
		},
		paper: {
			main: '#FB742D',
		},
	},
	primaryColor: '#FB742D',
	secondaryColor: '#000',
});

const SButton = styled(Button)(({ theme, color }) => ({
	fontFamily: 'PT Serif, serif',
	fontSize: '16px',
	borderRadius: '40px',
	backgroundColor: color,
}));
const ELink = styled(Link)(({ theme, color }) => ({
	color: 'black',
	textDecoration: 'none',
	cursor: 'pointer',
}));
const R1Link = styled(Link)(({ theme, color }) => ({
	cursor: 'pointer',
}));

const useStyles = makeStyles((theme) => ({
	full: {
		width: '100%',
		height: '100vh',
		background: `linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${BG})`,
		backgroundSize: 'cover',
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat',
		display: 'flex',
		alignItems: 'center',
	},
	headerText: {
		textTransform: 'uppercase',
		fontFamily: 'Open Sans, sans-serif',
		fontSize: '14px',
	},
	title: {
		fontSize: '25px',
		fontWeight: 'bold',
		fontFamily: 'PT Serif, serif',
	},
	imgHolder: {
		width: '400px',
		aspectRatio: '3/4',
		position: 'relative',
		'&:before': {
			position: 'absolute',
			content: '""',
			bottom: '5px',
			left: 0,
			width: '25px',
			height: '150px',
			backgroundColor: '#D9D9D9',
		},
		'&:after': {
			position: 'absolute',
			content: '""',
			top: 0,
			right: 0,
			width: '25px',
			height: '150px',
			backgroundColor: '#FB742D',
		},
	},
	textbox: {
		'& .MuiInputBase-input': {
			color: 'white', // Change the text color
			borderColor: 'white !important', // Change the border color
			'&:hover': {
				borderColor: 'white !important', // Change the border color
			},
			'&::before': {
				borderColor: 'white !important', // Change the color of the form border
			},
			'&::placeholder': {
				color: 'white !important', // Change the color of the form border
				opacity: '1',
			},
			'&:-ms-input-placeholder': {
				color: 'white !important', // Change the color of the form border
			},
			'&::-ms-input-placeholder': {
				color: 'white !important', // Change the color of the form border
			},
			'& fieldset': {
				borderColor: 'white !important', // Change the border color
			},
			'&:hover fieldset': {
				borderColor: 'white !important', // Change the border color on hover
			},
			'&.Mui-focused fieldset': {
				borderColor: 'white !important', // Change the border color when focused
			},
		},
		'& .MuiOutlinedInput-root': {},
		'& .MuiInputLabel-root': {
			color: 'white !important', // Change the placeholder text color
		},
	},
	header: {
		position: 'fixed',
		top: '0',
		width: '100%',
		padding: '1.5rem 5rem',
		display: 'flex',
		justifyContent: 'space-between',
		zIndex: '100',
	},
}));

//todo - remove true on icons,

function NewTemp8({ agent, listings, testimonials, contactDetails, updateContactDetails, sendMessage, serviceAreas }) {
	const classes = useStyles();
	const name = agent?.user.first_name + ' ' + agent?.user.last_name;
	const agent_id = agent?.id;
	const member_since = agent?.year_work_commenced;
	const agent_phone = formatPhoneNumber(agent?.phone);
	const brokerage_phone = formatPhoneNumber(agent?.brokerage?.brokerage_phone);
	const brokerage_name = agent?.brokerage_name;
	const license = agent?.licenses.find((license) => license.category === 'real_estate_agent')?.number;
	const address = agent?.address;
	const video = agent?.video_link;
	const showInvestorForm = agent?.questionnaires_on;

	return (
		<ThemeProvider theme={pageTheme}>
			<Nav listings={listings} name={name} showInvestors={showInvestorForm} />
			<Box className={classes.full} id='home'>
				<Stack direction='column' spacing={3} sx={{ borderLeft: '10px  solid #FB742D', paddingLeft: '1rem' }}>
					{agent?.facebook && (
						<IconButton target='_blank' href={agent?.facebook}>
							<FBIcon fill='white' />
						</IconButton>
					)}
					{agent?.twitter && (
						<IconButton target='_blank' href={agent?.twitter}>
							<TWIcon fill='white' />
						</IconButton>
					)}
					{agent?.instagram && (
						<IconButton target='_blank' href={agent?.instagram}>
							<IGIcon fill='white' />
						</IconButton>
					)}
					{agent?.linkedin && (
						<IconButton target='_blank' href={agent?.linkedin}>
							<LIIcon fill='white' />
						</IconButton>
					)}
					{agent?.video_link && (
						<IconButton target='_blank' href={agent?.video_link}>
							<YTIcon fill='white' />
						</IconButton>
					)}
				</Stack>
			</Box>
			<Grid container spacing={4} mt={2}>
				{video && (
					<Grid item xs={7}>
						<iframe
							style={{
								aspectRatio: '16/9',
								border: 'none',
								width: '100%',
								marginLeft: 'auto',
								display: 'block',
							}}
							src={`https://www.youtube.com/embed/${getYoutubeEmbedLink(video)}`}
							title='Client video'
							allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
							allowFullScreen
						></iframe>
					</Grid>
				)}
				<Grid
					item
					xs={!video ? 12 : 5}
					alignItems={video ? 'flex-start' : 'center'}
					justifyContent={'center'}
					display='flex'
					flexDirection='column'
					sx={{
						paddingLeft: '50px !important',
					}}
				>
					<Typography variant='h5' fontWeight={700} fontSize='20px'>
						Real Estate Agent
					</Typography>
					<Typography variant='body1' fontWeight={300} mb='1rem'>
						Member Since: {member_since}
					</Typography>
					<Typography
						variant='h3'
						sx={{
							wordSpacing: '10000rem',
							textAlign: video ? 'left' : 'center',
							lineHeight: 2.4,
							fontFamily: 'PT Serif, serif',
							borderLeft: '5px  solid #FB742D',
							paddingLeft: '2rem',
						}}
					>
						{name}
					</Typography>
				</Grid>
			</Grid>
			<Grid container spacing={4} alignItems='center' padding={'2rem 5rem'} id='about'>
				<Grid item xs={8}>
					<Typography className={classes.title} mb={2}>
						About
					</Typography>
					<Typography textAlign='justify' fontWeight={300} whiteSpace={'pre-line'}>
						{agent?.bio}
					</Typography>
					<Box display='flex' gap='10px' mt={2}>
						<SButton
							color='primary'
							variant='contained'
							href='#contact'
							sx={{
								background: 'white !important',
								border: '1px solid black !important',
							}}
						>
							Let's Connect
						</SButton>
						<SButton
							color='secondary'
							variant='outlined'
							href={`/order-bpo-report/${agent_id}`}
							target='_blank'
							sx={{
								background: '#FB742D !important',
								borderColor: 'white !important',
								color: 'white !important',
							}}
						>
							Order a BPO Report
						</SButton>
					</Box>
				</Grid>
				<Grid item xs={4}>
					<Box className={classes.imgHolder}>
						<img
							src={agent?.profile_image}
							alt='Agent'
							style={{
								width: '400px',
								aspectRatio: '3/4',
								objectFit: 'cover',
								objectPosition: 'top center',
								boxShadow: '-10px 10px 30px 10px rgba(0, 0, 0, 0.1)',
							}}
						/>
					</Box>
				</Grid>
			</Grid>
			{listings?.active.length > 0 && (
				<Box py='5rem' sx={{ backgroundColor: '#F8F9FA' }}>
					<Typography variant='h5' pl='5rem' fontFamily='PT Serif,serif' color='#Fb742d'>
						Active Listings
					</Typography>
					<Box
						display='flex'
						gap='20px'
						id='active'
						mt={2}
						sx={{
							maxWidth: '100%',
							position: 'relative',
							overflowX: 'scroll',
							'&::-webkit-scrollbar': {
								display: 'none',
							},
							scrollBehavior: 'smooth',
						}}
					>
						<IconButton
							ref={(el) => hideButton(el, 'active')}
							sx={{
								position: 'sticky',
								height: '56px',
								top: '40%',
								left: '0',
								backgroundColor: 'white',
								borderRadius: '2px',
								'&:hover': { backgroundColor: 'white' },
								boxShadow: '0px 0px 10px 0px #00000026',
							}}
							onClick={() => scrollToTheLeft('active', true)}
						>
							<ChevronLeft fontSize='large' />
						</IconButton>
						{listings?.active.map((listing) => (
							<ListingCard key={listing.id} {...listing} agentId={agent_id} />
						))}
						<IconButton
							ref={(el) => hideButton(el, 'active')}
							sx={{
								position: 'sticky',
								height: '56px',
								top: '40%',
								right: '0px',
								backgroundColor: 'white',
								borderRadius: '2px',
								'&:hover': { backgroundColor: 'white' },
								boxShadow: '0px 0px 10px 0px #00000026',
							}}
							onClick={() => scrollToTheLeft('active', false)}
						>
							<ChevronRight fontSize='large' />
						</IconButton>
					</Box>
				</Box>
			)}
			{showInvestorForm && (
				<Box padding={'2rem 5rem'}>
					<InvestorQuestionnaires name={agent} />
				</Box>
			)}
			{listings?.sold.length > 0 && (
				<Box py='5rem' sx={{ backgroundColor: '#F8F9FA' }}>
					<Typography variant='h5' pl='5rem' fontFamily='PT Serif,serif' color='#Fb742d'>
						Sold Listings
					</Typography>
					<Box
						display='flex'
						gap='20px'
						id='sold'
						mt={2}
						sx={{
							maxWidth: '100%',
							position: 'relative',
							overflowX: 'scroll',
							'&::-webkit-scrollbar': {
								display: 'none',
							},
							scrollBehavior: 'smooth',
						}}
					>
						<IconButton
							ref={(el) => hideButton(el, 'sold')}
							sx={{
								position: 'absolute',
								height: '56px',
								top: '40%',
								left: '0',
								backgroundColor: 'white',
								borderRadius: '2px',
								'&:hover': { backgroundColor: 'white' },
								boxShadow: '0px 0px 10px 0px #00000026',
							}}
							onClick={() => scrollToTheLeft('sold', true)}
						>
							<ChevronLeft fontSize='large' />
						</IconButton>
						{listings?.sold.map((listing) => (
							<ListingCard key={listing.id} {...listing} agentId={agent_id} />
						))}
						<IconButton
							ref={(el) => hideButton(el, 'sold')}
							sx={{
								position: 'sticky',
								height: '56px',
								top: '40%',
								right: '0px',
								backgroundColor: 'white',
								borderRadius: '2px',
								'&:hover': { backgroundColor: 'white' },
								boxShadow: '0px 0px 10px 0px #00000026',
							}}
							onClick={() => scrollToTheLeft('sold', false)}
						>
							<ChevronRight fontSize='large' />
						</IconButton>
					</Box>
				</Box>
			)}
			<Box p='2rem 5rem'>
				{testimonials.length > 0 && (
					<Stack direction='row' spacing={2} alignItems='center' mt='2rem'>
						{testimonials.map((testimonial) => (
							<Review key={testimonial.id} {...testimonial} />
						))}
					</Stack>
				)}
			</Box>
			{serviceAreas.length > 0 && (
				<Box my={3} py={3} sx={{ background: '#181818', color: 'white' }}>
					<ServiceAreasSlider serviceAreas={serviceAreas} />
				</Box>
			)}
			<Box
				sx={{
					background: `linear-gradient(0deg, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)),url(${ConBG})`,
					backgroundSize: 'cover',
					backgroundPosition: 'center',
				}}
			>
				<Stack
					direction='row'
					justifyContent='center'
					alignItems='center'
					p='10rem 5rem'
					divider={<Divider flexItem sx={{ background: '#FB742D', width: '10px', height: '400px' }} orientation='vertical' />}
				>
					<Grid container spacing={2} alignItems='center' color='white !important' fontFamily='Open Sans, sans-serif'>
						<Grid item xs={12} fontFamily='PT Serif,serif' color='inherit' fontSize='45px' textTransform='uppercase'>
							Contact Details
						</Grid>
						<Grid item xs={6}>
							<Typography variant='body2' color='white' fontFamily='inherit' textTransform={'uppercase'}>
								{name}
							</Typography>
							<Divider sx={{ background: '#FB742D', width: '80px', height: '4px', marginTop: '5px' }} orientation='horizontal' />
						</Grid>
						<Grid item xs={6}>
							<Typography variant='body2' color='white' fontFamily='inherit' fontWeight={300}>
								{address}
							</Typography>
						</Grid>
						<Grid item xs={6}>
							<Typography variant='body2' color='white' fontFamily='inherit' fontWeight={300}>
								Cell Phone:{agent_phone}
							</Typography>
							<Typography variant='body2' color='white' fontFamily='inherit' fontWeight={300}>
								Brokerage: {brokerage_name} {brokerage_phone ? `(${brokerage_phone})` : ''}
							</Typography>
						</Grid>
						<Grid item xs={6}>
							<Typography variant='body2' color='white' fontFamily='inherit' fontWeight={300}>
								Member since: {member_since}
							</Typography>
							<Typography variant='body2' color='white' fontFamily='inherit' fontWeight={300}>
								Real Estate License: {license}
							</Typography>
						</Grid>
					</Grid>
					<Grid container spacing={2} mb='1rem' pl='2rem' id='contact'>
						<Grid item xs={12}>
							<TextField
								fullWidth
								size='small'
								variant='standard'
								placeholder='Full name'
								className={classes.textbox}
								onChange={updateContactDetails}
								value={contactDetails?.name}
								name='name'
							/>
						</Grid>
						<Grid item xs={6}>
							<TextField
								fullWidth
								size='small'
								variant='standard'
								placeholder='Email'
								className={classes.textbox}
								onChange={updateContactDetails}
								value={contactDetails?.email}
								name='email'
							/>
						</Grid>
						<Grid item xs={6}>
							<TextField
								fullWidth
								size='small'
								variant='standard'
								placeholder='Phone Number'
								className={classes.textbox}
								onChange={updateContactDetails}
								value={contactDetails?.phone}
								name='phone'
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								multiline
								rows={4}
								fullWidth
								size='small'
								variant='standard'
								placeholder='Message'
								className={classes.textbox}
								value={contactDetails?.message}
								onChange={updateContactDetails}
								name='message'
							/>
						</Grid>
						<Grid item xs={12}>
							<SButton
								color='primary'
								variant='contained'
								sx={{
									background: 'transparent !important',
									border: '1px solid white !important',
									color: 'white !important',
									padding: '10px 1rem',
									display: 'block',
									marginLeft: 'auto',
									width: 'fit-content',
								}}
								onClick={sendMessage}
							>
								Let's Connect
							</SButton>
						</Grid>
					</Grid>
				</Stack>
			</Box>
		</ThemeProvider>
	);
}

export default NewTemp8;

const ListingCard = ({ id, agentId, list_price, photos, beds, baths, square_footage, address }) => {
	return (
		<ELink component={RLink} to={`/view-listing/${agentId}/${id}`} target='_blank'>
			<Box>
				<img src={photos?.[0]?.image} alt='listing' style={{ width: '400px', aspectRatio: '5/3', objectFit: 'cover' }} />
				<Box
					width='90%'
					mx='auto'
					sx={{
						backgroundColor: 'white',
						boxShadow: '0px -4px 20px rgba(0, 0, 0, 0.05)',
						position: 'relative',
						zIndex: '10',
						marginTop: '-20px',
						padding: '1rem',
						color: 'black',
						minWidth: '360px',
					}}
				>
					<Typography variant='h6' fontFamily={'PT Serif,serif'}>
						$ {formatNumber(list_price)}
					</Typography>
					<Box alignItems='center' justifyContent='space-between' display='flex' fontFamily='Roboto,sans-serif' mb={2} width='100%'>
						<Box display='flex' justifyContent='center' gap='10px'>
							<BiBath color='grey' />
							<Typography variant='body2' fontFamily='inherit' fontWeight={300}>
								{baths}
							</Typography>
						</Box>
						<Box display='flex' justifyContent='center' gap='10px'>
							<BedIcon fontSize='small' color='disabled' />
							<Typography variant='body2' fontFamily='inherit' fontWeight={300}>
								{beds}
							</Typography>
						</Box>
						<Box display='flex' justifyContent='center' gap='10px'>
							<SquareFoot fontSize='small' color='disabled' />
							<Typography variant='body2' fontFamily='inherit' fontWeight={300}>
								{formatNumber(square_footage)} sqft
							</Typography>
						</Box>
					</Box>
					<Typography variant='body1' fontFamily={'Open Sans, sans-serif'} fontSize={'16px'} fontWeight={300}>
						{address}
					</Typography>
				</Box>
			</Box>
		</ELink>
	);
};

const Nav = ({ listings, name, showInvestors }) => {
	const [showHeader, setShowHeader] = React.useState(false);
	const { id: agentId } = useParams();

	React.useEffect(() => {
		const handleScroll = () => {
			const scrollPosition = window.scrollY;
			if (scrollPosition > window.innerHeight) {
				setShowHeader(true);
			} else {
				setShowHeader(false);
			}
		};

		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);
	const classes = useStyles();
	return (
		<Box className={classes.header} sx={{ background: showHeader ? '#000000D9' : 'transparent' }}>
			<Box display='flex' gap='1rem' fontFamily='Open Sans, sans-serif'>
				<R1Link href='#home' underline='none' color='white' fontFamily='inherit'>
					Home
				</R1Link>
				<R1Link href='#about' underline='none' color='white' fontFamily='inherit'>
					About
				</R1Link>
				{listings?.active.length > 0 && (
					<R1Link href='#active' underline='none' color='white' fontFamily='inherit'>
						Active Listings
					</R1Link>
				)}
				<R1Link href='#contact' underline='none' color='white' fontFamily='inherit'>
					Contact
				</R1Link>
				<R1Link href={`/agent/${agentId}/blogs`} underline='none' color='white' fontFamily='inherit'>
					Blog
				</R1Link>
				{showInvestors && (
					<R1Link href='#investors' underline='none' color='white' fontFamily='inherit'>
						Investors
					</R1Link>
				)}
			</Box>

			<Typography variant='body1' fontFamily='Open Sans, sans-serif' color='white' fontWeight='400' fontSize='16px'>
				Listing Agent: {name}
			</Typography>
		</Box>
	);
};

const Review = ({ description, name, client }) => {
	return (
		<Stack flex='1'>
			<Stack
				spacing={2}
				alignItems='flex-start'
				flex='1'
				sx={{
					position: 'relative',
					zIndex: 2,
					backgroundColor: 'transparent',
					quotes: `\\201C""\\201D""\\2018""\\2019`,
					'&:before': {
						position: 'absolute',
						top: '-36px',
						left: '-30px',
						color: '#F9B28C',
						content: ' open-quote',
						fontSize: '7em',
						fontFamily: 'auto',
						zIndex: -1,
					},
					'&:after': {
						visibility: 'hidden',
						content: 'close-quote',
					},
				}}
			>
				<Typography variant='body1' textAlign='left' fontFamily={'Open Sans,sans-serif'} fontSize='16px'>
					{description}
				</Typography>
			</Stack>
			<Typography variant='body1' textAlign='left' fontWeight='700' fontSize='18px' mt='auto' fontFamily='PT Serif,serif'>
				{client?.user?.first_name || client?.user?.last_name ? client?.user?.first_name + ' ' + client?.user?.last_name : name}
			</Typography>
		</Stack>
	);
};
