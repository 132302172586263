import { LoadingButton } from '@mui/lab';
import { Button, CircularProgress, Stack, Typography } from '@mui/material';
import Check from '@untitled-ui/icons-react/build/esm/Check';
import Plus from '@untitled-ui/icons-react/build/esm/Plus';
import XClose from '@untitled-ui/icons-react/build/esm/XClose';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import base from '../apis';

const IndPayment = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [details, setDetails] = useState({
		account_id: null,
		charges_enabled: false,
		details_submitted: false,
		payouts_enabled: false,
		email: null,
	});

	const [isConnectionLoading, setIsConnectionLoading] = useState(false);
	const onConnectStripe = async () => {
		try {
			setIsConnectionLoading(true);
			const { data } = await base.post('professionals/connect-stripe/', {
				success_url: window.location.href,
				refresh_url: window.location.href,
				profession_id: selectedListing?.id,
			});

			window.location.href = data?.url;
		} catch (error) {
			toast.error('An error occurred');
		} finally {
			setIsConnectionLoading(false);
		}
	};

	const { indElite } = useSelector((state) => state.subscriptions);
	const navigate = useNavigate();

	const getIcon = (exists) =>
		exists ? <Check style={{ stroke: '#067647' }} className='iconer' /> : <XClose style={{ stroke: '#B42318' }} className='iconer' />;

	useEffect(() => {}, [indElite, navigate]);

	const { selectedListing } = useSelector((state) => state.auth);

	useEffect(() => {
		(async () => {
			if (!selectedListing) {
				navigate('/my-listing/all');
				return;
			}
			if (!indElite) {
				navigate('/industry-dashboard', { replace: true });
				return;
			}
			try {
				setIsLoading(true);
				const { data } = await base.get('professionals/connected-account/', {
					params: {
						profession_id: selectedListing?.id,
					},
				});
				if (data?.message) {
					toast.warn('Click to connect your account');
					return;
				}
				setDetails(data);
			} catch (error) {
				toast.error('An error occurred');
			} finally {
				setIsLoading(false);
			}
		})();
	}, [selectedListing, navigate, indElite]);

	if (isLoading) {
		return (
			<Stack sx={{ height: '90vh' }} alignItems='center' justifyContent={'center'}>
				<CircularProgress size={60} color='secondary' variant='indeterminate' />
			</Stack>
		);
	}
	if (!details.account_id) {
		return (
			<Stack sx={{ height: '90vh' }} alignItems='center' justifyContent={'center'}>
				<LoadingButton
					sx={{ textTransform: 'capitalize' }}
					color='secondary'
					onClick={onConnectStripe}
					variant='outlined'
					loading={isConnectionLoading}
				>
					Connect to Stripe
				</LoadingButton>
			</Stack>
		);
	}
	return (
		<Stack p='1rem' sx={{ minHeight: '100vh' }} gap='1rem'>
			<Stack sx={{ borderBottom: '1px solid #EAECF0', paddingBottom: '20px' }} direction='row' justifyContent={'space-between'}>
				<Typography variant='h1' color='#475467' fontWeight={600} fontSize='24px'>
					Stripe Integration Status
				</Typography>
			</Stack>
			<Stack sx={{ border: '1px solid #EAECF0', borderRadius: '12px', boxShadow: '0px 1px 2px 0px #1018280D' }}>
				<Stack p='1rem' direction={'row'} justifyContent={'space-between'} alignItems='center'>
					<Typography color='#101828' fontWeight={600} fontSize={'18px'}>
						Added Email
					</Typography>
					{!(details.details_submitted || details.charges_enabled || details.payouts_enabled) && (
						<LoadingButton startIcon={<Plus />} color='secondary' variant='contained' onClick={onConnectStripe} loading={isConnectionLoading}>
							Add Email
						</LoadingButton>
					)}
				</Stack>
				<Stack sx={{ background: '#F9FAFB' }} direction='row' p='10px'>
					{['Email', 'Charges Enabled', 'Details Submitted', 'Payout Submitted', ''].map((item) => (
						<Typography color='#475467' sx={{ flex: 1 }} key={item || 'a'} fontWeight={500} textAlign={'center'}>
							{item}
						</Typography>
					))}
				</Stack>
				<Stack direction='row' p='10px'>
					<Stack sx={{ flex: 1 }} alignItems='center' justifyContent={'center'}>
						<Typography textAlign={'center'}>{details.email || 'Email not connected'}</Typography>
					</Stack>
					{['charges_enabled', 'details_submitted', 'payouts_enabled'].map((item) => (
						<Stack alignItems='center' justifyContent={'center'} sx={{ flex: 1 }} key={item}>
							{getIcon(details?.[item])}
						</Stack>
					))}
					<Stack alignItems='center' justifyContent={'center'} sx={{ flex: 1 }}>
						{(details.details_submitted || details.charges_enabled || details.payouts_enabled) && (
							<Button color='text' variant='text' sx={{ color: '#475467' }} href='https://dashboard.stripe.com/' target='_blank'>
								Edit on Stripe
							</Button>
						)}
					</Stack>
				</Stack>
			</Stack>
		</Stack>
	);
};

export default IndPayment;
