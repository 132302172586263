import { CallOutlined, ChevronLeft, ChevronRight, MailOutline } from '@mui/icons-material';
import { Button, Link as DLink, Grid, IconButton, InputLabel, Stack, TextField, Typography, createTheme } from '@mui/material';
import { ThemeProvider, makeStyles, styled } from '@mui/styles';
import React, { useState } from 'react';
import InvestorQuestionnaires from '../../components/InvestorQuestionnaires/InvestorQuestionnaires';
import BG from '../../images/CurvedBG.png';
import { ReactComponent as FBIcon } from '../../images/facebookfillable.svg';
import { ReactComponent as IGIcon } from '../../images/instagramfillable.svg';
import { ReactComponent as LIIcon } from '../../images/linkedInfillable.svg';
import { ReactComponent as SayHello } from '../../images/SayHello.svg';
import { ReactComponent as Star } from '../../images/Star.svg';
import TBG from '../../images/testimonial_bg14.jpg';
import { ReactComponent as TWIcon } from '../../images/twitterfillable.svg';
import { ReactComponent as YTIcon } from '../../images/youtubefillable.svg';
import { formatPhoneNumber, getYoutubeEmbedLink } from '../../utils/functions';
import { ListingCardfor13, PlainButton } from './NewTemp13';

const calculateYearsOfExperience = (year_work_commenced, val) => {
	if (!year_work_commenced) return 'N/A';
	const dateToUse = new Date(year_work_commenced);
	const ageDifMs = Date.now() - dateToUse;
	const ageDate = new Date(ageDifMs);
	const numOfYears = Math.abs(ageDate.getUTCFullYear() - 1970) + 1;
	return `${numOfYears}`;
};

const pageTheme = createTheme({
	// ...theme,
	typography: {
		fontFamily: 'Poppins, san-serif',
		button: {
			textTransform: 'capitalize',
		},
	},
	palette: {
		primary: {
			main: '#000000',
		},
		secondary: {
			main: '#4A60A1',
		},
		text: {
			main: '#000000',
		},
		paper: {
			main: '#fff',
		},
	},
	primaryColor: '#000000',
	secondaryColor: '#4A60A1',
});

const Link = styled(DLink)(({ theme }) => ({
	cursor: 'pointer',
}));

const ModText = styled(TextField)(({ theme }) => ({
	background: 'white',
	fontFamily: 'Nunito, san-serif',
	borderRadius: 200,

	'&>MuiInputBase-root': {
		background: 'white',
		fontFamily: 'Nunito, san-serif',
		borderRadius: 200,
		border: '1px solid #D3D3D3',
	},
	'& fieldset': {
		border: 'none',
	},
	'&::placeholder': {
		fontFamily: 'Nunito, san-serif',
	},
}));
const ModLabel = styled(InputLabel)(({ theme }) => ({
	fontFamily: 'Syne, san-serif',
	textTransform: 'capitalize',
	fontSize: '18px',
	color: '#030211',
}));

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		minHeight: '100vh',
		fontFamily: 'Inter, san-serif',
		position: 'relative',
	},
	navroot: {
		padding: '1rem',
		boxShadow: '0px 4px 10px 0px #00000026',
		color: 'black',
		backgroundColor: 'white',
		position: 'fixed',
		top: 0,
		zIndex: 100,
		width: '100%',
	},
	container: {
		width: '100%',
		height: '100vh',
		backgroundSize: 'cover',
		background: `url(${BG})`,
		display: 'flex',
		justifyContent: 'flex-end',
		alignContent: 'center',
	},
	link: {
		color: 'black',
		fontFamily: 'Nunito, san-serif',
	},

	clients: { width: '60%', background: `url(${TBG})`, backgroundSize: 'cover', aspectRatio: '1.5', padding: '2rem', backgroundPosition: 'center' },
	testimon: {
		background: '#075D8280',
		boxShadow: '0px 30px 60px 0px #0000001A',
		fontFamily: 'Space Grotesk, Inter, san-serif',
		width: '60%',
		padding: '2rem 5rem',
		borderRadius: '25px',
		position: 'relative',
		'&::before': {
			content: 'open-quote',
			position: 'absolute',
			top: '10px',
			left: '10px',
			fontSize: '100px',
			fontFamily: 'monospace',
			color: 'white',
		},
	},
}));

const StyleButton = styled(Button)(({ theme }) => ({
	background: '#030211',
	color: 'white',
	padding: '10px',
	border: '1px solid black',
	borderRadius: '40px',
	textTransform: 'capitalize',
	fontSize: '14px',
	minWidth: 'fit-content',
	fontFamily: 'Nunito, san-serif',
	marginTop: '2rem',
	'&:hover': {
		background: '#030211',
	},
	clients: { width: '60%', background: `url(${TBG})`, backgroundSize: 'cover', aspectRatio: '1.5', padding: '2rem', backgroundPosition: 'center' },
	testimon: {
		background: '#8E210980',
		boxShadow: '0px 30px 60px 0px #0000001A',
		fontFamily: 'Space Grotesk, Inter, san-serif',
		width: '60%',
		padding: '2rem 5rem',
		borderRadius: '25px',
		position: 'relative',
		'&::before': {
			content: 'open-quote',
			position: 'absolute',
			top: '10px',
			left: '10px',
			fontSize: '100px',
			fontFamily: 'monospace',
			color: 'white',
		},
	},
}));

const NewTemp14 = ({ agent, listings, testimonials, contactDetails, updateContactDetails, sendMessage }) => {
	const classes = useStyles();
	const name = agent?.user.first_name + ' ' + agent?.user.last_name;
	const agent_id = agent?.id;
	// const member_since = agent?.year_work_commenced;
	const agent_phone = formatPhoneNumber(agent?.phone);
	const brokerage_name = agent?.brokerage_name;
	const license = agent?.licenses.find((license) => license.category === 'real_estate_agent')?.number;
	const video = agent?.video_link;
	const showInvestorForm = agent?.questionnaires_on;
	const active = listings.active.length;
	const [testPage, setTestPage] = useState(0);
	const selectedTestimonial = testimonials[Math.abs(testPage % testimonials.length)];
	const testimonialName =
		selectedTestimonial?.client?.user?.first_name || selectedTestimonial?.client?.user?.last_name
			? selectedTestimonial?.client?.user?.first_name + ' ' + selectedTestimonial?.client?.user?.last_name
			: name;

	const [activePage, setActivePage] = useState(1);
	const activeToUse = listings.active?.slice((activePage - 1) * 6, 6 * (activePage - 1) + 6);
	const [soldPage, setSoldPage] = useState(1);
	const soldToUse = listings.sold?.slice((soldPage - 1) * 6, 6 * (soldPage - 1) + 6);
	return (
		<ThemeProvider theme={pageTheme}>
			<Stack className={classes.root} gap='4rem'>
				<Stack direction='row' gap='2rem' justifyContent='flex-end' p='2rem' mr='4rem'>
					{active > 0 && (
						<Link href='#active' className={classes.link} underline='none'>
							Active Listings
						</Link>
					)}
					<Link href='#about' className={classes.link} underline='none'>
						About
					</Link>
					<Link href='#contact' className={classes.link} underline='none'>
						Contact
					</Link>
					<Link href={`/agent/${agent_id}/blogs`} className={classes.link} underline='none'>
						Blogs
					</Link>
					{showInvestorForm && (
						<Link href='#investors' className={classes.link} underline='none'>
							Investors
						</Link>
					)}
				</Stack>
				<Star style={{ position: 'absolute', top: '100px', right: '20px' }} />
				<Stack justifyContent={'center'} alignItems='center' px='15%' sx={{ height: '70vh' }}>
					<Typography fontFamily={'Poppins'} fontWeight={'500'} fontSize={80} sx={{ textWrap: 'balance', lineClamp: 3 }} textAlign='center'>
						Unlock Your Real Estate Dreams: Experience Excellence with {agent?.user.first_name}
					</Typography>
					<Typography color='#7C7C7C' fontFamily={'Nunito'} fontWeight={400} fontSize={20}>
						High-end <span style={{ textDecoration: 'underline' }}>digital experiences.</span> Created at the heart of BPOHomes,
					</Typography>
					<StyleButton endIcon={<SayHello />}>Say Hello</StyleButton>
				</Stack>
				<img src={BG} width='100%' alt='curved background' style={{ marginTop: '-4rem' }} />

				<Stack direction='row' gap='1rem' alignItems='center'>
					<Stack flex='1' justifyContent={'flex-end'} direction='row'>
						<img
							src={agent?.profile_image}
							alt='Agent'
							style={{
								width: '400px',
								aspectRatio: '3/4',
								objectFit: 'cover',
								objectPosition: 'top center',
							}}
						/>
					</Stack>
					<Stack justifyContent={'flex-end'} sx={{ flex: 1 }} gap='2rem' id='about' padding='2rem 3rem'>
						<Typography fontWeight={500} fontSize={50}>
							About
						</Typography>
						<Typography fontFamily={'Inter, san-serif'} textAlign='justify' color='#7E7E7E'>
							{agent?.bio}
						</Typography>
						<Stack gap='2rem' direction='row'>
							<Stack
								width='200px'
								sx={{ backgroundColor: '#F4F4F4', padding: '20px', aspectRatio: '1/1' }}
								alignItems='center'
								justifyContent='center'
							>
								<Typography color='#FFB342' fontSize={40} fontWeight='700'>
									{calculateYearsOfExperience(agent?.year_work_commenced)}
								</Typography>
								<Typography color='#1C2752' fontSize={16}>
									Years of Experience
								</Typography>
							</Stack>
							<Stack gap='1rem' direction='row' flex='1' alignItems='center' justifyContent='center'>
								<Button
									variant='outlined'
									sx={{ textTransform: 'none', borderColor: '#030211', color: '#030211', fontFamily: 'Inter, san-serif', fontWeight: 400 }}
									href={`/order-bpo-report/${agent_id}`}
									target='_blank'
								>
									Get A BPO Report
								</Button>
								<Button
									variant='contained'
									sx={{ textTransform: 'none', backgroundColor: '#030211', color: 'white', fontFamily: 'Inter, san-serif', fontWeight: 400 }}
									href='#contact'
								>
									Let's Connect
								</Button>
							</Stack>
						</Stack>
					</Stack>
				</Stack>
				{listings.active.length > 0 && (
					<Stack justifyContent={'center'} alignItems='center' p='2rem 13rem' gap='2rem' id='active'>
						<Typography color={'#8E2109'} fontFamily={'Inter'} variant='h4' fontSize='40px' fontWeight={600}>
							Active Listings
						</Typography>
						<Stack direction='row' justifyContent='flex-end' alignSelf={'flex-end'} sx={{ width: '20%' }} gap='1rem'>
							<PlainButton onClick={() => setActivePage((v) => v - 1)} disabled={activePage === 1}>
								Previous
							</PlainButton>
							<PlainButton onClick={() => setActivePage((v) => v + 1)} disabled={activePage === Math.ceil(listings.active.length / 6)}>
								Next
							</PlainButton>
						</Stack>
						<Grid container spacing={2}>
							{activeToUse.map((listing) => (
								<Grid item xs={4} key={listing.key}>
									<ListingCardfor13 {...listing} agentId={agent?.id} />
								</Grid>
							))}
						</Grid>
					</Stack>
				)}
				{showInvestorForm && (
					<Stack sx={{ backgroundColor: '#F5FBFF' }} direction={'row'} justifyContent={'flex-end'}>
						<InvestorQuestionnaires agent={agent} />
					</Stack>
				)}
				{listings.sold.length > 0 && (
					<Stack justifyContent={'center'} alignItems='center' p='2rem 13rem' gap='2rem' id='active'>
						<Typography color={'#8E2109'} fontFamily={'Inter'} variant='h4' fontSize='40px' fontWeight={600}>
							Sold Listings
						</Typography>
						<Stack direction='row' justifyContent='flex-end' alignSelf={'flex-end'} sx={{ width: '20%' }} gap='1rem'>
							<PlainButton onClick={() => setSoldPage((v) => v - 1)} disabled={soldPage === 1}>
								Previous
							</PlainButton>
							<PlainButton onClick={() => setSoldPage((v) => v + 1)} disabled={soldPage === Math.ceil(listings.sold.length / 6)}>
								Next
							</PlainButton>
						</Stack>
						<Grid container spacing={2}>
							{soldToUse.map((listing) => (
								<Grid item xs={4} key={listing.key}>
									<ListingCardfor13 {...listing} agentId={agent?.id} />
								</Grid>
							))}
						</Grid>
					</Stack>
				)}
				{video && (
					<Stack alignItems='center' justifyContent={'center'} gap='1rem'>
						<Typography color='white' fontFamily={'Inter'} fontSize={42} fontWeight={700}>
							Video Spotlight
						</Typography>
						<iframe
							style={{
								aspectRatio: '16/9',
								borderRadius: '0px',
								border: 'none',
								width: '75%',
								margin: 'auto',
								display: 'block',
							}}
							src={`https://www.youtube.com/embed/${getYoutubeEmbedLink(video)}`}
							title='Client video'
							allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
							allowFullScreen
						></iframe>
					</Stack>
				)}
				{testimonials.length > 0 && (
					<Stack direction='row' justifyContent={'center'} alignItems='flex-start'>
						<Stack className={classes.clients} justifyContent='center' alignItems='center' mt='3rem' gap='3rem'>
							<Typography fontFamily='Space Grotesk' fontWeight={700} fontSize={30} color='white'>
								What Our Clients Say
							</Typography>
							<Stack direction='row' alignItems='center' justifyContent={'center'} gap='2rem'>
								<IconButton onClick={() => setTestPage((v) => v - 1)}>
									<ChevronLeft fontSize='large' sx={{ fill: 'white' }} />
								</IconButton>
								<Stack justifyContent={'center'} alignItems='center' className={classes.testimon}>
									<Typography fontFamily='Space Grotesk' color='white' fontWeight={400} fontSize={22}>
										{selectedTestimonial?.description}
									</Typography>
									<Typography color='white' fontWeight={400} fontSize={22} fontFamily='Space Grotesk'>
										{testimonialName}
									</Typography>
								</Stack>
								<IconButton onClick={() => setTestPage((v) => v + 1)}>
									<ChevronRight fontSize='large' sx={{ fill: 'white' }} />
								</IconButton>
							</Stack>
						</Stack>
					</Stack>
				)}
				<Stack justifyContent={'center'} alignItems='center' p='2rem 5rem' sx={{ background: '#D9D9D9' }} gap='2rem' id='contact'>
					<Stack gap='1rem' justifyContent='center' alignItems='center'>
						<Typography fontFamily={'Syne'} fontWeight={700} fontSize={'80px'} textAlign={'center'}>
							Contact Me
						</Typography>
						<Typography color='#7C7C7C' fontFamily={'Nunito'} fontSize='22px' textAlign={'center'} width='60%' mb='2rem'>
							We'll never push extras that don't make sense for your brand just to make a quick buck. Our pricing policy is as transparent as our
							process.
						</Typography>
					</Stack>
					<Stack direction='row'>
						<Stack flex='1'>
							<Typography fontFamily={'Syne, san-serif'}>
								Let's <span style={{ background: '#030211', color: 'white', padding: '5px' }}>Talk</span>
							</Typography>
							<Typography fontFamily={'Nunito'}>Send an email or start a conversation by filling the form below</Typography>
							<Typography fontFamily={'Syne, san-serif'} mt='2rem' fontSize={'25px'}>
								Reach Me Directly!
							</Typography>
							<Typography fontFamily={'Syne, san-serif'} mt='1rem' fontSize={'20px'}>
								Brokerage: {brokerage_name}
							</Typography>
							<Typography fontFamily={'Syne, san-serif'} mt='1rem' fontSize={'20px'}>
								License #: {license}
							</Typography>
							<Stack
								direction='row'
								component='a'
								sx={{ background: '#030211', borderRadius: 100, padding: '1rem 2rem 1rem 1rem ', width: 'fit-content', cursor: 'pointer' }}
								gap='1rem'
								mt='2rem'
								href={`mailto:${agent?.user?.email}`}
							>
								<Stack
									justifyContent='center'
									alignItems='center'
									sx={{ width: '54px', aspectRatio: '1', backgroundColor: 'white', borderRadius: '50%' }}
								>
									<MailOutline fontSize='medium' sx={{ fill: '#030211' }} />
								</Stack>
								<Stack>
									<Typography color='white' fontFamily={'Syne,san-serif'} fontSize='20px' fontWeight={700}>
										Email
									</Typography>
									<Typography color='white' fontFamily={'Nunito,san-serif'} fontSize='16px' fontWeight={700}>
										{agent?.user?.email}
									</Typography>
								</Stack>
							</Stack>
							<Stack
								direction='row'
								component='a'
								sx={{ background: '#030211', borderRadius: 100, padding: '1rem 2rem 1rem 1rem ', width: 'fit-content', cursor: 'pointer' }}
								gap='1rem'
								mt='1rem'
								href={`tel:${agent_phone}`}
							>
								<Stack
									justifyContent='center'
									alignItems='center'
									sx={{ width: '54px', aspectRatio: '1', backgroundColor: 'white', borderRadius: '50%' }}
								>
									<CallOutlined fontSize='medium' sx={{ fill: '#030211' }} />
								</Stack>
								<Stack>
									<Typography color='white' fontFamily={'Syne,san-serif'} fontSize='20px' fontWeight={700}>
										Phone Number
									</Typography>
									<Typography color='white' fontFamily={'Nunito,san-serif'} fontSize='16px' fontWeight={700}>
										{agent_phone}
									</Typography>
								</Stack>
							</Stack>
							<Stack direction='row' spacing={3} alignItems={'center'} mt='1rem'>
								{agent?.facebook && (
									<IconButton target='blank' href={agent?.facebook}>
										<FBIcon fill='#030211' />
									</IconButton>
								)}
								{agent?.twitter && (
									<IconButton target='blank' href={agent?.twitter}>
										<TWIcon fill='#030211' />
									</IconButton>
								)}
								{agent?.instagram && (
									<IconButton target='blank' href={agent?.instagram}>
										<IGIcon fill='#030211' />
									</IconButton>
								)}
								{agent?.linkedin && (
									<IconButton target='blank' href={agent?.linkedin}>
										<LIIcon fill='#030211' />
									</IconButton>
								)}
								{agent?.video_link && (
									<IconButton target='blank' href={agent?.video_link}>
										<YTIcon fill='#030211' />
									</IconButton>
								)}
							</Stack>
						</Stack>
						<Grid container spacing={2} flex='1'>
							<Grid item xs={12}>
								<ModLabel>Full Name *</ModLabel>
								<ModText placeholder='John David' fullWidth size='small' value={contactDetails.name} onChange={updateContactDetails} name='name' />
							</Grid>
							<Grid item xs={6}>
								<ModLabel>Your Email *</ModLabel>
								<ModText
									placeholder='example@yourmail.com'
									fullWidth
									size='small'
									value={contactDetails.email}
									onChange={updateContactDetails}
									name='email'
								/>
							</Grid>
							<Grid item xs={6}>
								<ModLabel>Phone *</ModLabel>
								<ModText
									placeholder='(000) 123 456'
									fullWidth
									size='small'
									value={contactDetails.phone}
									onChange={updateContactDetails}
									name='phone'
								/>
							</Grid>

							<Grid item xs={12}>
								<ModLabel>Leave A Message *</ModLabel>
								<ModText
									placeholder='Hello there,I would like to talk about how to...'
									fullWidth
									size='small'
									multiline
									rows={4}
									value={contactDetails.message}
									onChange={updateContactDetails}
									name='message'
									sx={{ borderRadius: '20px !important', '&>MuiInputBase-root': { borderRadius: '20px' } }}
								/>
								<StyleButton onClick={sendMessage}>Send Message</StyleButton>
							</Grid>
						</Grid>
					</Stack>
				</Stack>
			</Stack>
		</ThemeProvider>
	);
};

export default NewTemp14;
