import { sarLoanAmount } from "../../utils/loanAmount";
import {
  interestOnlyMortgageCalculator,
  mortgageLoanCalculator,
  rateAmountConvertor,
  yearsToMonthsTransformer,
} from "../utils/genericFunctions";
import {
  downPaymentCalculatorFunc,
  getLocalLenderPointAmount,
} from "../carryingCost/financialTotalExpenses";
import { rentalIncomeProfit } from "../profits/inverstorProfit";

export const financeOptionsCals = ({
  remodelCosts,
  developmentCost,
  financeOptions,
  resultData,
  dealId,
  propertyPermitAndFees,
  taxes,
  rentalIncome,
  propertyManagement,
}) => {
  const { remodelCost, grandTotalRemodelCost } = remodelCosts || {};
  const { grandTotalRemodelCost: devConst } = developmentCost || {};
  const { city_permit_impact_fees_dollar_or_percent, city_permit_impact_fees } =
    propertyPermitAndFees || {};
  const { city_and_county_tax_fees_dollar_or_percent, city_and_county_tax } =
    taxes || {};

  const {
    pmi_value,
    primary_include_pmi,
    primary_lender_points_options,
    deal_finance_option,
    primary_wholesale_fee_options,
    secondary_include_pmi,
    primary_purchase_price,
    primary_lender_points,
    primary_wholesale_fee,
    primary_dollar_or_percent,
    primary_down_payment,
    primary_interest_rate,
    primary_term_of_loan,
    secondary_lender_points_options,
    secondary_down_payment,
    secondary_dollar_or_percent,
    secondary_annual_interest_rate,
    totalAmountPaidRemodelDownToStudsAddition,
    totalRemodelCostDownToStuds,
    primary_annual_interest_rate,
    primary_interest_only_term_month_or_year,
    secondary_lender_points,
    secondary_wholesale_fee_options,
    secondary_interest_rate,
    secondary_term_of_loan,
    primary_interest_only_term,
  } = financeOptions || {};

  const {
    est_rental_income_per_month,
    owning_months,
    vacancy_months,
    owning_months_month_or_year,
    unitsRentalLoss,
    unitsRentalIncome,
  } = rentalIncome;

  const {
    property_management_fees_dollar_or_percent,
    property_management_fees,
  } = propertyManagement;

  //====================== PRIMARY LOAN SECTION ================================//
  const returnDynamicRemodelCost = +grandTotalRemodelCost || devConst;

  const localRemodelCost =
    returnDynamicRemodelCost ||
    totalRemodelCostDownToStuds ||
    totalAmountPaidRemodelDownToStudsAddition;

  const remodelCostUsed =
    localRemodelCost || resultData?.remodel_cost?.estimated_total_remodel_cost
      ? localRemodelCost ||
        resultData?.remodel_cost?.estimated_total_remodel_cost
      : resultData?.remodel_cost
          ?.est_remodel_cost_down__to_studs_plus_additional_sqft;

  const getPurchaseAmount = () => {
    const dealFinanceType = +deal_finance_option || 0;
    switch (dealFinanceType) {
      case 2:
      case 4:
        return (
          +primary_purchase_price + (+remodelCostUsed ? +remodelCostUsed : 0)
        );
      case 3:
        return +remodelCost;
      default:
        return primary_purchase_price;
    }
  };

  const localLoanAmount = () => {
    const val = sarLoanAmount({
      purchasePrice: getPurchaseAmount(),
      pmi_value: pmi_value,
      lenderPoints: primary_lender_points,
      wholesaleFee: primary_wholesale_fee,
      pmi: primary_include_pmi,
      downPayment: primary_down_payment,
      downPaymentRate: primary_dollar_or_percent,
      wholesaleFeeOption: primary_wholesale_fee_options,
      lenderpointOption: primary_lender_points_options,
    });

    return val;
  };

  const localPrimaryAmount = () => {
    const time = primary_term_of_loan * 12;
    const rate = primary_interest_rate / 100 / 12;
    const effectiveLoan = localLoanAmount();

    const amount = mortgageLoanCalculator(
      effectiveLoan?.effectiveLoan,
      rate,
      time
    );

    return amount;
  };

  const primaryInterestOnlyTime = yearsToMonthsTransformer(
    primary_interest_only_term_month_or_year,
    primary_interest_only_term
  );
  const PrimaryInterestAloneMortgagePayment = () => {
    const res = interestOnlyMortgageCalculator(
      primary_annual_interest_rate,
      localLoanAmount()?.effectiveLoan
    );
    return res;
  };

  const primaryInterestOnlyMonthlyAmount = interestOnlyMortgageCalculator(
    +primary_interest_rate,
    localLoanAmount()?.effectiveLoan
  );

  const primaryDownPayment = downPaymentCalculatorFunc(
    getPurchaseAmount(),
    primary_dollar_or_percent,
    primary_down_payment
  );

  //down payment for Obtain Single Primary Loan for Home & Remodel
  const baseAmountForSingleLoan = getPurchaseAmount();
  const downPaymentForSingleLoan = downPaymentCalculatorFunc(
    baseAmountForSingleLoan,
    primary_dollar_or_percent,
    primary_down_payment
  );
  const downPaymentForNewConstructionAndPayCash = downPaymentCalculatorFunc(
    remodelCostUsed,
    secondary_dollar_or_percent,
    primary_down_payment
  );
  //====================== END OF PRIMARY LOAD SECTION =============================//
  //                                                                                //
  //                                                                                //
  //                                                                                //
  //                                                                                //
  //                                                                                //
  //                                                                                //
  //                                                                                //
  //                                                                                //
  //                                                                                //
  //                                                                                //
  //===================== SECONDARY LOAN SECTION ===================================//

  //this is the value of the secondary loanAmount
  const localSecondaryLoanAmount = () => {
    return sarLoanAmount({
      purchasePrice: +remodelCostUsed,
      pmi_value: 0,
      lenderPoints: secondary_lender_points,
      wholesaleFee: 0,
      pmi: secondary_include_pmi,
      downPayment: secondary_down_payment,
      downPaymentRate: secondary_dollar_or_percent,
      wholesaleFeeOption: secondary_wholesale_fee_options,
      lenderpointOption: secondary_lender_points_options,
    });
  };

  //the mortgage calculator takes interest rate, time and loan amount
  const secondaryInterestRateLocal = secondary_interest_rate / 100 / 12;
  const SecondaryTimeInYearsLocal = secondary_term_of_loan * 12;
  const secondaryLoanAmountLocal = localSecondaryLoanAmount()?.effectiveLoan;

  //secondary amount paid monthly
  const localSecondaryAmount = () => {
    const amount = mortgageLoanCalculator(
      secondaryLoanAmountLocal,
      secondaryInterestRateLocal,
      SecondaryTimeInYearsLocal
    );
    return amount;
  };

  //  secondary interest only monthly amount
  const secondaryInterestOnlyMonthlyAmount = interestOnlyMortgageCalculator(
    secondary_annual_interest_rate,
    secondaryLoanAmountLocal
  );

  const secondaryDownPayment = downPaymentCalculatorFunc(
    remodelCostUsed,
    secondary_dollar_or_percent,
    secondary_down_payment
  );

  //=====================END OF SECONDARY LOAN SECTION ===================================//

  //===================== CITY IMPACT AND PERMIT || TAXES || PROPERTY MANAGEMENT ==============//
  const cityPermitFeesCalculated = rateAmountConvertor(
    +city_permit_impact_fees_dollar_or_percent,
    +city_permit_impact_fees,
    primary_purchase_price
  );

  const getYearlyStatePropertyTax = rateAmountConvertor(
    +city_and_county_tax_fees_dollar_or_percent,
    +city_and_county_tax,
    primary_purchase_price
  );

  const monthlyPropertyManagementFees = rateAmountConvertor(
    +property_management_fees_dollar_or_percent,
    +property_management_fees,
    +est_rental_income_per_month
  );

  const rentalProfit = () => {
    const unitsProfits =
      unitsRentalIncome - unitsRentalLoss > 0
        ? unitsRentalIncome - unitsRentalLoss
        : 0;

    const amount = rentalIncomeProfit(
      owning_months_month_or_year,
      +owning_months,
      +vacancy_months,
      est_rental_income_per_month
    );
    const zeroCheck = amount > 0 ? amount : 0;
    return zeroCheck + unitsProfits;
  };

  const primaryLenderPointsAmount = getLocalLenderPointAmount(
    localLoanAmount()?.effectiveLoan,
    primary_lender_points
  );
  const secondaryLenderPointsAmount = getLocalLenderPointAmount(
    localSecondaryLoanAmount()?.effectiveLoan,
    secondary_lender_points
  );

  return {
    localPrimaryTotalMonthlyAmount: localPrimaryAmount(),
    localGeneralLoanAmount: localLoanAmount()?.effectiveLoan,
    pmiAmount: localLoanAmount()?.monthlyPMI,
    primaryInterestOnlyMonthlyAmount,
    primaryPurchasePrice: getPurchaseAmount(),
    primaryDownPayment,
    localSecondaryLoanAmount: localSecondaryLoanAmount()?.effectiveLoan,
    localSecondaryTotalMonthlyAmount: localSecondaryAmount(),
    downPaymentForNewConstructionAndPayCash,
    downPaymentForSingleLoan,
    secondaryDownPayment,
    secondaryInterestOnlyMonthlyAmount: +secondaryInterestOnlyMonthlyAmount,
    cityPermitFeesCalculated,
    getYearlyStatePropertyTax,
    getMonthlyStatePropertyTax: getYearlyStatePropertyTax / 12,
    monthlyPropertyManagementFees,
    remodelCostUsed,
    primaryLenderPointsAmount,
    secondaryLenderPointsAmount,
    rentalProfit: rentalProfit(),
    PrimaryInterestAloneMortgagePayment: PrimaryInterestAloneMortgagePayment(),
    primaryInterestOnlyTime,
  };
};

//FIX REMODEL COST
