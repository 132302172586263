import React from 'react';
import { useState } from 'react';
import Button from '../../components/Button/Button';
import Input from '../../components/Input/Input';
import Select from 'react-select';
import './profileedit.scss';
import { languages, professionalOptions, states, statesShort, timeZones } from '../../constants';
import Checkbox from '../../components/Checkbox/Checkbox';
import { toast } from 'react-toastify';
import Spinner from '../../components/Spinner/Spinner';
import base, { file as fileUploader } from '../../apis';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { BiArrowBack } from 'react-icons/bi';
import { getValidUrl } from '../../utils/functions';
import PlacesSearch from '../../components/PlacesSearch/PlacesSearch';
import { IconButton, InputLabel, TextField, Box, Tooltip, Grid, Button as MButton, Stack } from '@mui/material';

const selectStyles = {
	container: (provided) => ({
		...provided,
		width: '100%',
	}),
};

const agentTitleOptions = [
	{ value: 'Agent', label: 'Agent' },
	{ value: 'Broker', label: 'Broker' },
];

function ProfileEdit() {
	const typeId = useSelector((state) => state.auth.typeId);
	const canEdit = useSelector((state) => Object.values(state.subscriptions).some(Boolean));
	const dispatch = useDispatch();
	const [searchParams] = useSearchParams();
	const [otherLanguage, setOtherLanguage] = useState('');
	useEffect(() => {
		const state = searchParams.get('state');
		if (state) {
			setStep(+state);
		}
		setIsLoading(true);
		base
			.get(`register/agents/${typeId}/`)
			.then(({ data }) => {
				const phoneNumber = (data.phone || '').replace(/[- )(]/g, '');
				let body = {
					...data,
					phone1: (phoneNumber || '').slice(0, 3),
					phone2: (phoneNumber || '').slice(3, 6),
					phone3: (phoneNumber || '').slice(6),
					brokerage_phone_1: (data.brokerage_phone || '').slice(0, 3),
					brokerage_phone_2: (data.brokerage_phone || '').slice(3, 6),
					brokerage_phone_3: (data.brokerage_phone || '').slice(6),
					state: data.state ? { value: data.state, label: data.state } : null,
					mailing_state: data.mailing_state ? { value: data.mailing_state, label: data.mailing_state } : null,
					category: professionalOptions.find((option) => option.value === data?.user?.role),
					agent_status: agentTitleOptions.find((option) => option.value === data?.agent_status),
				};
				if (data.timezone) {
					body['timezone'] = { value: data.timezone, label: data.timezone };
				}

				const realAgentLicense = data.licenses.find((item) => item.category === 'real_estate_agent');
				if (realAgentLicense) {
					body['licenses'] = {
						state: realAgentLicense.state ? { value: realAgentLicense.state, label: realAgentLicense.state } : null,
						number: realAgentLicense.number,
						category: realAgentLicense.category,
						description: realAgentLicense.description,
						day: new Date(realAgentLicense.expiration).getDate(),
						month: new Date(realAgentLicense.expiration).getMonth() + 1,
						year: new Date(realAgentLicense.expiration).getFullYear(),
					};
				}
				const otherLicenses = data.licenses.filter((item) => item.category !== 'real_estate_agent');
				if (otherLicenses.length > 0) {
					body['otherLicenses'] = otherLicenses.map((item) => ({
						number: item.number,
						description: item.description,
					}));
				} else {
					body['otherLicenses'] = [{ number: '', description: '' }];
				}
				body['languages'] = data.languages.map((item) => item.name);
				const otherLang = body.languages.find((item) => !languages.filter(Boolean).includes(item)) || '';
				if (otherLang) {
					setOtherLanguage(otherLang);
					body['languages'] = body.languages.filter((item) => item !== otherLang);
				}
				body['specialties'] = data.specialties.map((item) => item.title);
				if (data.year_work_commenced) {
					const year = new Date(data.year_work_commenced).getFullYear();
					body['year_work_commenced'] = year;
				}

				setDetails(body);
			})
			.catch((err) => {
				toast.error('Could not get agent profile');
			})
			.finally(() => {
				setIsLoading(false);
			});

		return () => {
			setIsLoading(false);
			setDetails({
				first_name: '',
				last_name: '',
				screen_name: '',
				title: '',
				brokerage_name: '',
				brokerage_address: '',
				brokerage_phone_1: '',
				brokerage_phone_2: '',
				brokerage_phone_3: '',
				category: null,
				profile_image: '',
				mailing_state: '',
				mailing_city: '',
				mailing_zipcode: '',
				city: '',
				state: null,
				zip_code: '',
				phone1: '',
				phone2: '',
				phone3: '',
				timezone: null,
				licenses: {
					state: null,
					number: '',
					category: '',
					description: '',
					day: '',
					month: '',
					year: '',
				},

				otherLicenses: [
					{
						number: '',
						description: '',
					},
				],
				service_areas: [{ location: '' }],
				specialties: [],
				languages: [],
				year_work_commenced: '',
				video_link: '',
				web_link: '',
				blog: '',
				facebook: '',
				twitter: '',
				linkedin: '',
			});
		};
	}, [typeId, searchParams]);

	const addMailingAddress = () => {
		if (details.mail_addresses.length === 2) {
			return;
		}
		setDetails((val) => ({ ...val, mail_addresses: [...val.mail_addresses, { name: '', suite_number: '' }] }));
	};

	const handleRemove = (index) => {
		setDetails((val) => {
			const mail_addresses = val.mail_addresses.filter((i, index1) => index1 !== index);
			return { ...val, mail_addresses };
		});
	};

	//#region Step
	//! set step to 1
	const [stepper, setStep] = useState(1);
	const [showMore, setShowMore] = useState(false);
	const [otherSpecialties, setOtherSpecialties] = useState(false);
	const [details, setDetails] = useState({
		first_name: '',
		last_name: '',
		screen_name: '',
		agent_status: agentTitleOptions[0],
		mail_addresses: [],
		title: '',
		brokerage_name: '',
		brokerage_address: '',
		brokerage_phone_1: '',
		brokerage_phone_2: '',
		brokerage_phone_3: '',
		suite_number: '',
		mailing_address: '',
		mailing_state: null,
		mailing_city: '',
		mailing_zipcode: '',
		category: { value: 'real_estate_agent', label: 'Real Estate Agent' },
		profile_image: '',
		city: '',
		state: null,
		zip_code: '',
		phone1: '',
		phone2: '',
		phone3: '',
		timezone: null,
		licenses: {
			state: null,
			number: '',
			category: '',
			description: '',
			day: '',
			month: '',
			year: '',
		},

		otherLicenses: [
			{
				number: '',
				description: '',
			},
		],
		service_areas: [{ location: '' }],
		specialties: [],
		languages: [],
		year_work_commenced: '',
		video_link: '',
		web_link: '',
		blog: '',
		facebook: '',
		twitter: '',
		linkedin: '',
		instagram: '',
	});

	const onBlurField = (e) => {
		const { name, value } = e.target;
		setDetails({ ...details, [name]: getValidUrl(value) });
	};

	const setChecked = (e) => {
		const { name } = e.target;
		if (details.languages.includes(name)) {
			setDetails((cur) => ({ ...cur, languages: details.languages.filter((lang) => lang !== name) }));
		} else {
			setDetails((cur) => ({ ...cur, languages: [...details.languages, name] }));
		}
	};

	const setSpecialty = (e) => {
		const { name } = e.target;
		if (details.specialties.includes(name)) {
			setDetails((cur) => ({ ...cur, specialties: details.specialties.filter((lang) => lang !== name) }));
		} else {
			setDetails((cur) => ({ ...cur, specialties: [...details.specialties, name] }));
		}
	};

	const changeDetails = (e) => {
		const { name, value } = e.target;
		setDetails({
			...details,
			[name]: value,
		});
	};

	const isPremSub = useSelector((state) => state.subscriptions.premierAgentWebsite);

	const setPremWeb = () => {
		setDetails((cur) => ({ ...cur, web_link: `${window.location.origin}/agent/${typeId}` }));
	};

	const [isLoading, setIsLoading] = useState(false);

	const setImage = (e) => {
		const { files } = e.target;
		const file = files[0];
		if (file) {
			setIsLoading(true);
			let formData = new FormData();
			formData.append('profile_image', file);
			fileUploader
				.patch(`register/agents/${typeId}/`, formData)
				.then(({ data }) => {
					setDetails((cur) => ({ ...cur, profile_image: data.profile_image }));
					e.target.value = '';
				})
				.catch((err) => {
					toast.error('Could not upload image');
				})
				.finally(() => {
					setIsLoading(false);
				});
		}
	};

	const navigate = useNavigate();

	const updateProfilePage = (body, moveAway) => {
		// return;
		if (Object.keys(body).length === 0) {
			if (stepper < 5) {
				setStep((cur) => cur + 1);
			} else {
				//navigate somewhere based to listing agent dashboard
				navigate('/agent-dashboard');
			}
			return;
		}
		setIsLoading(true);
		base
			.patch(`/register/agents/${typeId}/`, body)
			.then(() => {
				if (moveAway) {
					navigate('/agent-dashboard');
				}
				if (stepper < 5) {
					setStep((cur) => cur + 1);
				} else {
					//navigate somewhere
					//todo goto profile overview
					navigate('/agent-dashboard');
				}
			})
			.catch((err) => {
				toast.error('Could not update profile');
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const populateCity = (e) => {
		const city = e.target.value;
		const state = city?.split(', ')[2];
		const foundState = statesShort.find((stateObj) => stateObj.value === state);
		const longState = states.find((item) => item.value === foundState?.label);
		const newbody = {};
		if (city) {
			newbody.city = city?.split(', ')[1] || '';
		}
		if (longState) {
			newbody.state = longState;
		}
		setDetails((cur) => ({
			...cur,
			...newbody,
		}));
	};

	const gotoNext = (e, exit) => {
		e?.preventDefault();
		const form = document.getElementsByTagName('form')[0];
		if (!form.checkValidity()) {
			form?.reportValidity();
			return;
		}
		moveToNext(exit);
	};

	const moveToNext = (moveAway = false) => {
		let body = {};
		if (stepper === 1) {
			if (!details.first_name) {
				toast.warn('Please enter your first name');
				return;
			}
			if (!details.last_name) {
				toast.warn('Please enter your last name');
				return;
			}
			if (!details.screen_name) {
				toast.warn('Please enter your screen name');
				return;
			}
			base
				.patch('/auth/users/me/', {
					first_name: details.first_name,
					last_name: details.last_name,
				})
				.then(({ data }) => {
					dispatch({ type: 'UPDATE_NAME', payload: { first_name: data.first_name, last_name: data.last_name } });
				})
				.catch((err) => {});
			body['first_name'] = details.first_name;
			body['last_name'] = details.last_name;
			body['screen_name'] = details.screen_name;
			body['agent_status'] = details.agent_status.value;
		} else if (stepper === 2) {
			// if (!details.category?.value) {
			// 	toast.warn('Please select your category');
			// 	return;
			// }
			// if (!details.title) {
			// 	toast.warn('Please enter your title');
			// 	return;
			// }
			if (!details.brokerage_name) {
				toast.warn('Please enter your brokerage name');
				return;
			}
			if (!details.brokerage_address) {
				toast.warn('Please enter your brokerage address');
				return;
			}

			if (!details.city) {
				toast.warn('Please enter your city');
				return;
			}
			if (!details.state) {
				toast.warn('Please enter your state');
				return;
			}
			if (!details.zip_code) {
				toast.warn('Please enter your zip');
				return;
			}

			if (!details.mailing_address) {
				toast.warn('Please enter your mailing address');
				return;
			}
			if (!details.mailing_city) {
				toast.warn('Please enter your mailing address city');
				return;
			}
			if (!details.mailing_state) {
				toast.warn('Please enter your mailing address state');
				return;
			}
			if (!details.mailing_zipcode) {
				toast.warn('Please enter your mailing address zip');
				return;
			}

			// body['title'] = details.title;
			body['brokerage_name'] = details.brokerage_name;
			body['brokerage_address'] = details.brokerage_address;
			body['mailing_address'] = details.mailing_address;
			body['suite_number'] = details.suite_number;
			body['mailing_city'] = details.mailing_city;
			body['mailing_state'] = details.mailing_state.label;
			body['mailing_zipcode'] = details.mailing_zipcode;
			body['zip_code'] = details.zip_code;
			body['phone'] = details.phone1 + details.phone2 + details.phone3;
			if (details.brokerage_phone_1 && details.brokerage_phone_2 && details.brokerage_phone_3) {
				body['brokerage_phone'] = details.brokerage_phone_1 + details.brokerage_phone_2 + details.brokerage_phone_3;
			}
			// body['category'] = details.category.value;
			// body[]
			body['city'] = details.city;
			body['state'] = details.state.label;
			body['zip_code'] = details.zip_code;
			if (details.timezone) {
				body['timezone'] = details.timezone.label;
			}

			body['mail_addresses'] = details.mail_addresses;
		} else if (stepper === 3) {
			if (!details.licenses.state) {
				toast.warn('Please enter your license state');
				return;
			}
			body['licenses'] = [
				{
					state: '',
					number: '',
					category: 'real_estate_agent',
					expiration: '',
				},
			];
			body['licenses'][0].state = details.licenses.state.label;
			if (details.licenses.number === '') {
				toast.warn('Please enter your license number');
				return;
			}
			body['licenses'][0].number = details.licenses.number;

			if (details.licenses.day === '') {
				toast.warn('Please enter expiration day');
				return;
			}

			if (details.licenses.month === '') {
				toast.warn('Please enter expiration month');
				return;
			}
			if (details.licenses.year === '') {
				toast.warn('Please enter expiration year');
				return;
			}
			try {
				const date = new Date(details.licenses.year, details.licenses.month - 1, details.licenses.day).toJSON().slice(0, 10);
				if (date instanceof Date && !isNaN(date)) {
					toast.warn('Please enter a valid expiration date');
					return;
				}
				body['licenses'][0].expiration = date;
			} catch (error) {
				toast.warn('Please enter a valid expiration date');
				return;
			}

			if (details.service_areas.some((item) => item.location)) {
				body['service_areas'] = details.service_areas.filter((item) => item.location).map((area) => ({ location: area.location }));
			}
			if (details.otherLicenses.some((item) => (item.description && !item.number) || (!item.description && item.number))) {
				toast.warn('Please fill in all the fields for other licenses');
				return;
			}
			if (details.otherLicenses.some((item) => item.description && item.number)) {
				body['licenses'].push(
					...details.otherLicenses.map((item) => ({ description: item.description, number: item.number, category: 'other_profession' }))
				);
			}
		} else if (stepper === 4) {
			if (details.specialties.length > 0) {
				body['specialties'] = details.specialties.map((item) => ({ title: item }));
			}
			if (details.languages.length > 0 || otherLanguage) {
				body['languages'] = [...details.languages, otherLanguage]?.filter(Boolean).map((item) => ({ name: item }));
			}
			if (details.year_work_commenced) {
				try {
					const date = new Date(new Date().setFullYear(details.year_work_commenced));

					body['year_work_commenced'] = date.toJSON().slice(0, 10);
				} catch (error) {
					toast.warn('Please enter a valid years of work commenced');
					return;
				}
			}
		} else {
			body['video_link'] = getValidUrl(details.video_link);

			body['web_link'] = getValidUrl(details.web_link);
			body['blog'] = getValidUrl(details.blog);
			body['facebook'] = getValidUrl(details.facebook);
			body['twitter'] = getValidUrl(details.twitter);
			body['linkedin'] = getValidUrl(details.linkedin);
			body['instagram'] = getValidUrl(details.instagram);
		}
		updateProfilePage(body, moveAway);
	};

	const handleGoBack = () => {
		if (stepper > 1) {
			setStep(stepper - 1);
		}
		return false;
	};

	const changeStep = (step) => {
		const form = document.getElementsByTagName('form')[0];
		if (!form.checkValidity()) {
			form?.reportValidity();
			return;
		}
		setStep(step);
	};

	return (
		<>
			{isLoading && <Spinner />}
			<div className='pedit'>
				<div style={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
					{stepper > 1 && (
						<IconButton onClick={handleGoBack}>
							<BiArrowBack style={{ cursor: 'pointer' }} size={24} color='var(--primary-color)' />
						</IconButton>
					)}
					<h1 className='pedit_header'>Edit your profile information</h1>
				</div>
				<div className='pedit_h'>
					<div className='pedit__steps'>
						{[1, 2, 3, 4, 5].map((step) => (
							<Tooltip key={step} title={`Step ${step}`}>
								<div
									style={{ cursor: 'pointer' }}
									className={step <= stepper ? 'pedit__step pedit__step-active' : 'pedit__step'}
									onClick={() => changeStep(step)}
								></div>
							</Tooltip>
						))}
					</div>
					<div>{stepper} of 5</div>
				</div>
				<form onSubmit={gotoNext}>
					{stepper === 1 && (
						<div>
							<div className='pedit__thirty'>
								<div>
									<span className='pedit_title'>Name:</span>
								</div>
								<div style={{ display: 'flex', gap: '10px' }}>
									<Input
										placeholder='First Name'
										name='first_name'
										value={details.first_name || ''}
										onChange={changeDetails}
										fullStyles={{ marginTop: 'unset' }}
										style={{ padding: '9px 5px' }}
										required
									/>
									<Input
										placeholder='Last Name'
										name='last_name'
										value={details.last_name || ''}
										onChange={changeDetails}
										fullStyles={{ marginTop: 'unset' }}
										style={{ padding: '9px 5px' }}
										required
									/>
								</div>
							</div>
							<div className='pedit__thirty'>
								<div>
									<span className='pedit_title'>Screen Name:</span>
								</div>
								<div
									style={{
										flexDirection: 'column',
										alignItems: 'flex-start',
										gap: '5px',
									}}
								>
									<Input
										placeholder='Screen Name'
										onChange={changeDetails}
										name='screen_name'
										value={details.screen_name || ''}
										fullStyles={{ marginTop: 'unset' }}
										style={{ padding: '9px 5px' }}
										required
									/>
									<span className='pedit_aside'>Profile URL: www.BPOHomes/profile/prefilledname/</span>
								</div>
							</div>
							<div className='pedit__thirty'>
								<div>
									<span className='pedit_title'>Profile Photo</span>
									<span>(optional)</span>
								</div>
								<div style={{ flexDirection: 'column' }}>
									{details.profile_image && (
										<img src={details.profile_image} alt='profile' style={{ width: '120px', height: '120px', objectFit: 'contain' }} />
									)}
									<input type='file' accept='image/png, image/jpeg' onChange={setImage} />
									<span className='pedit_aside'>Photo should be at least 180px by 180px and jpg format.</span>
								</div>
							</div>
							<div className='pedit__thirty'>
								<div>
									<span className='pedit_title'>Agent Title</span>
								</div>
								<div style={{ flexDirection: 'column' }}>
									<Select
										isSearchable={false}
										placeholder='Select Agent Title'
										options={agentTitleOptions}
										styles={selectStyles}
										onChange={(e) => {
											setDetails((cur) => ({ ...cur, agent_status: e }));
										}}
										value={details.agent_status}
									/>
								</div>
							</div>
						</div>
					)}
					{stepper === 2 && (
						<div>
							<div className='pedit__thirty'>
								<div>
									<span className='pedit_title'>Professional Title:</span>
								</div>
								<div className='pedit_full'>
									<Select
										isSearchable={false}
										placeholder='Select Professional Category'
										options={professionalOptions}
										styles={selectStyles}
										isDisabled={true}
										onChange={(e) => {
											setDetails((cur) => ({ ...cur, category: e }));
										}}
										value={details.category}
									/>
								</div>
							</div>
							{/* <div className='pedit__thirty'>
								<div>
									<span className='pedit_title'>Professional Title:</span>
								</div>
								<div>
									<Input
										placeholder='Professional Title'
										name='title'
										onChange={changeDetails}
										value={details.title || ''}
										required
										fullStyles={{ marginTop: 'unset', width: '100%' }}
										style={{ padding: '9px 5px' }}
									/>
								</div>
							</div> */}
							<div className='pedit__thirty'>
								<div>
									<span className='pedit_title'>Brokerage Name:</span>
								</div>
								<div>
									<Input
										placeholder='Brokerage Name'
										name='brokerage_name'
										required
										onChange={changeDetails}
										value={details.brokerage_name || ''}
										fullStyles={{ marginTop: 'unset', width: '100%' }}
										style={{ padding: '9px 5px' }}
									/>
								</div>
							</div>
							<div className='pedit__thirty'>
								<div>
									<span className='pedit_title'>Brokerage Address:</span>
								</div>
								<div>
									<PlacesSearch
										placeholder='Brokerage Address'
										name='brokerage_address'
										onChange={(e) => {
											setDetails((cur) => ({ ...cur, brokerage_address: e }));
										}}
										onBlur={populateCity}
										value={details.brokerage_address || ''}
										fullStyles={{ marginTop: 'unset', width: '100%' }}
										style={{ padding: '9px 5px' }}
										required
									/>
								</div>
							</div>
							<div className='pedit__thirty'>
								<div>
									<span className='pedit_title'>City, State, Zip:</span>
								</div>

								<div>
									<Grid container spacing={2}>
										<Grid item xs={4}>
											<Input
												placeholder='City'
												name='city'
												onChange={changeDetails}
												value={details.city || ''}
												fullStyles={{ marginTop: 'unset' }}
												style={{ padding: '9px 5px' }}
												required
											/>
										</Grid>
										<Grid item xs={4}>
											<Select
												placeholder='State'
												options={states}
												onChange={(e) => {
													setDetails((cur) => ({ ...cur, state: e }));
												}}
												value={details.state}
												styles={selectStyles}
											/>
										</Grid>

										<Grid item xs={4}>
											<Input
												placeholder='ZIP'
												name='zip_code'
												value={details.zip_code || ''}
												onChange={changeDetails}
												fullStyles={{ marginTop: 'unset' }}
												style={{ padding: '9px 5px' }}
												required
											/>
										</Grid>
									</Grid>
								</div>
							</div>
							<div className='pedit__thirty'>
								<div>
									<span className='pedit_title'>Mailing Address:</span>
								</div>
								<div>
									<Grid container spacing={2}>
										<Grid item xs={10}>
											<PlacesSearch
												placeholder='Mailing Address'
												name='mailing_address'
												onChange={(e) => {
													setDetails((cur) => ({ ...cur, mailing_address: e }));
												}}
												value={details.mailing_address || ''}
												fullStyles={{ marginTop: 'unset', width: '100%' }}
												style={{ padding: '9px 5px' }}
												required
											/>
										</Grid>
										<Grid item xs={2}>
											<Input
												placeholder='Suite Number'
												name='suite_number'
												onChange={changeDetails}
												value={details.suite_number || ''}
												fullStyles={{ marginTop: 'unset' }}
												style={{ padding: '9px 5px' }}
											/>
										</Grid>
									</Grid>
								</div>
							</div>
							<div className='pedit__thirty'>
								<div>
									<span className='pedit_title'>Mailing Address City, State, Zip:</span>
								</div>
								<div>
									<Grid container spacing={2}>
										<Grid item xs={4}>
											<Input
												placeholder='City'
												name='mailing_city'
												onChange={changeDetails}
												value={details.mailing_city || ''}
												fullStyles={{ marginTop: 'unset' }}
												style={{ padding: '9px 5px' }}
												required
											/>
										</Grid>
										<Grid item xs={4}>
											<Select
												placeholder='State'
												options={states}
												onChange={(e) => {
													setDetails((cur) => ({ ...cur, mailing_state: e }));
												}}
												value={details.mailing_state}
												styles={selectStyles}
											/>
										</Grid>
										<Grid item xs={4}>
											<Input
												placeholder='ZIP'
												name='mailing_zipcode'
												value={details.mailing_zipcode || ''}
												onChange={changeDetails}
												fullStyles={{ marginTop: 'unset' }}
												style={{ padding: '9px 5px' }}
												required
											/>
										</Grid>
									</Grid>
								</div>
							</div>
							{details.mail_addresses.map((item, index) => (
								<div className='pedit__thirty' key={item}>
									<Stack direction='row ' gap='1rem' alignItems='center'>
										<span className='pedit_title'>Mailing Address {index + 2}: </span>
										<MButton variant='text' color='error' sx={{ fontSize: '10px' }} onClick={() => handleRemove(index)}>
											Remove{' '}
										</MButton>
									</Stack>
									<div>
										<Grid container spacing={2}>
											<Grid item xs={10}>
												<PlacesSearch
													placeholder='Mailing Address'
													name='name'
													value={details.mail_addresses[index].name || ''}
													onChange={(e) => {
														const mailingaddress = details.mail_addresses;
														mailingaddress[index].name = e;
														setDetails((cur) => ({ ...cur, mail_addresses: mailingaddress }));
													}}
													fullStyles={{ marginTop: 'unset', width: '100%' }}
													style={{ padding: '9px 5px' }}
												/>
											</Grid>
											<Grid item xs={2}>
												<Input
													placeholder='Suite Number'
													name='suite_number'
													onChange={(e) => {
														const mailingaddress = details.mail_addresses;
														mailingaddress[index].suite_number = e.target.value;
														setDetails((cur) => ({ ...cur, mail_addresses: mailingaddress }));
													}}
													value={details.mail_addresses[index].suite_number || ''}
													fullStyles={{ marginTop: 'unset' }}
													style={{ padding: '9px 5px' }}
												/>
											</Grid>
										</Grid>
									</div>
								</div>
							))}

							<div className='pedit__thirty'>
								<div>
									<MButton variant='text' sx={{ textTransform: 'capitalize' }} color='secondary' onClick={addMailingAddress}>
										Add another mailing address
									</MButton>
								</div>
							</div>
							<div className='pedit__thirty'>
								<div>
									<span className='pedit_title'>Timezone:</span>
									<span>&nbsp;(optional)</span>
								</div>
								<div className='pedit_full'>
									<Select
										placeholder='Timezone'
										options={timeZones}
										value={details.timezone}
										styles={selectStyles}
										onChange={(e) => {
											setDetails((state) => ({ ...state, timezone: e }));
										}}
									/>
								</div>
							</div>
							<div className='pedit__thirty'>
								<div>
									<span className='pedit_title'>Primary Phone</span>
									<span>&nbsp;(Mobile Preferred)</span>
								</div>
								<div className='pedit_div'>
									<Input
										placeholder='555'
										fullStyles={{ width: '15%' }}
										value={details.phone1 || ''}
										onChange={changeDetails}
										name='phone1'
										maxLength={3}
										pattern='[0-9]{3}'
										title='Enter a 3 digit number'
									/>
									<Input
										placeholder='555'
										fullStyles={{ width: '15%' }}
										value={details.phone2 || ''}
										onChange={changeDetails}
										name='phone2'
										maxLength={3}
										pattern='[0-9]{3}'
										title='Enter a 3 digit number'
									/>
									<Input
										placeholder='5555'
										fullStyles={{ width: '15%' }}
										value={details.phone3 || ''}
										onChange={changeDetails}
										name='phone3'
										maxLength={4}
										pattern='[0-9]{4}'
										title='Enter a 4 digit number'
									/>
								</div>
							</div>
							<div className='pedit__thirty'>
								<div>
									<span className='pedit_title'>Brokerage Phone</span>
									<span>&nbsp;(optional)</span>
								</div>
								<div className='pedit_div'>
									<Input
										placeholder='555'
										fullStyles={{ width: '15%' }}
										value={details.brokerage_phone_1 || ''}
										onChange={changeDetails}
										name='brokerage_phone_1'
										maxLength={3}
										pattern='[0-9]{3}'
										title='Enter a 3 digit number'
									/>
									<Input
										placeholder='555'
										fullStyles={{ width: '15%' }}
										value={details.brokerage_phone_2 || ''}
										onChange={changeDetails}
										name='brokerage_phone_2'
										maxLength={3}
										pattern='[0-9]{3}'
										title='Enter a 3 digit number'
									/>
									<Input
										placeholder='5555'
										fullStyles={{ width: '15%' }}
										value={details.brokerage_phone_3 || ''}
										onChange={changeDetails}
										name='brokerage_phone_3'
										maxLength={4}
										pattern='[0-9]{4}'
										title='Enter a 4 digit number'
									/>
								</div>
							</div>
						</div>
					)}
					{stepper === 3 && (
						<div>
							<div className='pedit__thirty'>
								<div style={{ alignItems: 'flex-start', marginTop: '20px' }}>
									<span className='pedit_title'>Real Estate Agent License:</span>
								</div>
								<div style={{ flexDirection: 'column' }}>
									<div className='pedit__thirty'>
										<div>
											<span className='pedit_title'>State:</span>
										</div>
										<div className='pedit_full'>
											<Select
												options={states}
												placeholder='Select State'
												value={details.licenses.state}
												styles={selectStyles}
												onChange={(e) => {
													setDetails((cur) => ({ ...cur, licenses: { ...cur.licenses, state: e } }));
												}}
											/>
										</div>
									</div>
									<div className='pedit__thirty'>
										<div>
											<span className='pedit_title'>License Number:</span>
										</div>
										<div>
											<Input
												placeholder='License Number'
												value={details.licenses.number || ''}
												required
												minLength={5}
												pattern='[0-9]*'
												onChange={(e) => {
													setDetails((cur) => ({ ...cur, licenses: { ...cur.licenses, number: e.target.value } }));
												}}
												fullStyles={{ marginTop: 'unset', width: '90%' }}
												style={{ padding: '9px 5px' }}
											/>
										</div>
									</div>
									<div className='pedit__thirty'>
										<div>
											<span className='pedit_title'>Expiration:</span>
										</div>
										<div>
											<div className='pedit_div'>
												<Input
													placeholder='MM'
													fullStyles={{ width: '25%' }}
													value={details.licenses.month || ''}
													maxLength='2'
													minLength='1'
													min='1'
													max='12'
													required
													title='Select a month between 1 and 12'
													type='number'
													onChange={(e) => {
														setDetails((cur) => ({ ...cur, licenses: { ...cur.licenses, month: e.target.value } }));
													}}
												/>
												/
												<Input
													placeholder='DD'
													fullStyles={{ width: '20%' }}
													value={details.licenses.day || ''}
													maxLength={2}
													type='number'
													min='1'
													max='31'
													pattern='[0-9]{1,2}'
													required
													title='Enter a date between 1 and 31'
													onChange={(e) => {
														setDetails((cur) => ({ ...cur, licenses: { ...cur.licenses, day: e.target.value } }));
													}}
												/>
												/
												<Input
													placeholder='YYYY'
													fullStyles={{ width: '25%' }}
													value={details.licenses.year || ''}
													maxLength='4'
													minLength='4'
													required
													title='Enter a valid year'
													type='number'
													pattern='[0-9]{4}'
													// min={new Date().getFullYear()}
													onChange={(e) => {
														setDetails((cur) => ({ ...cur, licenses: { ...cur.licenses, year: e.target.value } }));
													}}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className='pedit__thirty'>
								<div style={{ alignItems: 'flex-start', marginTop: '20px' }}>
									<span className='pedit_title'>Other Professional Licenses:</span>
									<span>&nbsp;(optional)</span>
								</div>
								<div style={{ display: 'flex', flexDirection: 'column' }}>
									{details.otherLicenses.map((license, index) => (
										<div style={{ flexDirection: 'column', width: '100%' }} key={index}>
											<div className='pedit__thirty'>
												<div>
													<span className='pedit_title'>Description:</span>
												</div>
												<div>
													<Input
														placeholder='Enter License Title and/or state issued'
														fullStyles={{ marginTop: 'unset', width: '90%' }}
														style={{ padding: '9px 5px' }}
														value={details.otherLicenses[index].description || ''}
														onChange={(e) => {
															setDetails((cur) => ({
																...cur,
																otherLicenses: [
																	...cur.otherLicenses.slice(0, index),
																	{ ...cur.otherLicenses[index], description: e.target.value },
																	...cur.otherLicenses.slice(index + 1),
																],
															}));
														}}
													/>
												</div>
											</div>
											<div className='pedit__thirty'>
												<div>
													<span className='pedit_title'>License Number:</span>
												</div>
												<div style={{ alignItems: 'center', gap: '10px' }}>
													<Input
														placeholder='License Number'
														fullStyles={{ marginTop: 'unset', width: '90%' }}
														style={{ padding: '9px 5px' }}
														value={details.otherLicenses[index].number || ''}
														onChange={(e) => {
															setDetails((cur) => ({
																...cur,
																otherLicenses: [
																	...cur.otherLicenses.slice(0, index),
																	{ ...cur.otherLicenses[index], number: e.target.value },
																	...cur.otherLicenses.slice(index + 1),
																],
															}));
														}}
													/>
													{index === details.otherLicenses.length - 1 && (
														<p
															className='pedit_add'
															onClick={() => {
																setDetails((cur) => ({
																	...cur,
																	otherLicenses: [...cur.otherLicenses, { description: '', number: '' }],
																}));
															}}
														>
															Add
														</p>
													)}
												</div>
											</div>
										</div>
									))}
									<p className='pedit_aside' style={{ margin: '0 15px' }}>
										Licenses must be entered and added one at a time. You must click "Add" to add a license to your profile.
									</p>
								</div>
							</div>
							<div className='pedit__thirty'>
								<div style={{ alignItems: 'flex-start', marginTop: '20px' }}>
									<span className='pedit_title'>Service Areas:</span>
									<span>&nbsp;(optional)</span>
								</div>
								<div style={{ display: 'flex', flexDirection: 'column' }}>
									<div
										style={{
											alignItems: 'center',
											gap: '10px',
											marginLeft: '15px',
											display: 'flex',
											width: '100%',
											justifyContent: 'space-between',
										}}
									>
										<div style={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '100%' }}>
											{details.service_areas.map((area, index) => (
												<React.Fragment key={index}>
													<PlacesSearch
														types={['country', 'locality']}
														placeholder='Neighborhood, city, county, zipcode, etc'
														fullStyles={{ marginTop: 'unset', flex: 1 }}
														style={{ padding: '9px 5px' }}
														key={index}
														value={details.service_areas[index].location || ''}
														onChange={(e) => {
															const serviceArea = details.service_areas;
															serviceArea[index].location = e;
															setDetails((cur) => ({ ...cur, service_areas: serviceArea }));
														}}
													/>
												</React.Fragment>
											))}
										</div>

										<p
											className='pedit_add'
											onClick={() => {
												setDetails((cur) => ({ ...cur, service_areas: [...cur.service_areas, { location: '' }] }));
											}}
										>
											Add
										</p>
									</div>
									<p className='pedit_aside' style={{ margin: '0 15px' }}>
										Enter the areas you serve. To add multiple areas, enter your first area in the box and click "Add more." Then repeat with your
										next service area.
									</p>
								</div>
							</div>
						</div>
					)}
					{stepper === 4 && (
						<div>
							<div className='pedit__thirty'>
								<div style={{ alignItems: 'flex-start', marginTop: '-12px' }}>
									<span className='pedit_title'>Language Fluency:</span>
									<span>&nbsp;(optional)</span>
								</div>
								<div style={{ flexDirection: 'column' }}>
									<div className='pedit_split'>
										<div>
											<Checkbox text='English' checked={details.languages.includes('English')} setChecked={setChecked} name='English' />
											<Checkbox text='Spanish' checked={details.languages.includes('Spanish')} setChecked={setChecked} name='Spanish' />
											<Checkbox text='German' checked={details.languages.includes('German')} setChecked={setChecked} name='German' />
											{showMore && (
												<>
													<Checkbox text='Mandarin' checked={details.languages.includes('Mandarin')} setChecked={setChecked} name='Mandarin' />
													<Checkbox text='Cantonese' checked={details.languages.includes('Cantonese')} setChecked={setChecked} name='Cantonese' />
													<Checkbox text='Arabic' checked={details.languages.includes('Arabic')} setChecked={setChecked} name='Arabic' />
													<Checkbox
														labelStyle={{ marginBottom: '0px' }}
														text='Hindi'
														checked={details.languages.includes('Hindi')}
														setChecked={setChecked}
														name='Hindi'
													/>
													<Box pl='35px'>
														<InputLabel>Other</InputLabel>
														<TextField color='secondary' size='small' value={otherLanguage} onChange={(e) => setOtherLanguage(e.target.value)} />
													</Box>
												</>
											)}
										</div>
										<div>
											<Checkbox text='French' checked={details.languages.includes('French')} setChecked={setChecked} name='French' />
											<Checkbox text='Polish' checked={details.languages.includes('Polish')} setChecked={setChecked} name='Polish' />
											{showMore && (
												<>
													<Checkbox text='Italian' checked={details.languages.includes('Italian')} setChecked={setChecked} name='Italian' />
													<Checkbox text='Thai' checked={details.languages.includes('Thai')} setChecked={setChecked} name='Thai' />
													<Checkbox text='Korean' checked={details.languages.includes('Korean')} setChecked={setChecked} name='Korean' />
													<Checkbox text='Greek' checked={details.languages.includes('Greek')} setChecked={setChecked} name='Greek' />
												</>
											)}
										</div>
									</div>
									<p style={{ color: 'var(--primary-color)', cursor: 'pointer' }} onClick={() => setShowMore((val) => !val)}>
										{showMore ? 'fewer' : 'more'}
									</p>
								</div>
							</div>
							<div className='pedit__thirty'>
								<div>
									<span className='pedit_title'>In business since:</span>
									<span>&nbsp;(optional)</span>
								</div>
								<div className='pedit_div'>
									<Input
										value={details.year_work_commenced || ''}
										minLength={4}
										maxLength={4}
										type='number'
										pattern='[0-9]{4}'
										min='1950'
										max={new Date().getFullYear()}
										title='Please enter a valid year'
										onChange={(e) => setDetails((cur) => ({ ...cur, year_work_commenced: e.target.value }))}
										placeholder='In business since'
									/>
								</div>
							</div>
							<div className='pedit__thirty'>
								<div style={{ alignItems: 'flex-start', marginTop: '-12px' }}>
									<span className='pedit_title'>Specialties:</span>
								</div>
								<div style={{ display: 'unset' }}>
									<p className='pedit_title pedit_title-mb'>Agent Specialties</p>
									<div className='pedit_split'>
										<div>
											<Checkbox
												text='Buyer Agent'
												checked={details.specialties.includes('Buyer Agent')}
												setChecked={setSpecialty}
												name='Buyer Agent'
											/>
											<Checkbox text='Relocation' checked={details.specialties.includes('Relocation')} setChecked={setSpecialty} name='Relocation' />
											<Checkbox text='Short Sale' checked={details.specialties.includes('Short Sale')} setChecked={setSpecialty} name='Short Sale' />
											<Checkbox text='Other' checked={details.specialties.includes('Other')} setChecked={setSpecialty} name='Other' />
										</div>
										<div>
											<Checkbox
												text='Listing Agent'
												checked={details.specialties.includes('Listing Agent')}
												setChecked={setSpecialty}
												name='Listing Agent'
											/>
											<Checkbox
												text='Foreclosure'
												checked={details.specialties.includes('Foreclosure')}
												setChecked={setSpecialty}
												name='Foreclosure'
											/>
											<Checkbox text='Consulting' checked={details.specialties.includes('Consulting')} setChecked={setSpecialty} name='Consulting' />
										</div>
									</div>
									<p className='pedit_title pedit_title-mb'>Property Management Specialties</p>
									<div className='pedit_split'>
										<div>
											<Checkbox
												text='Property Management'
												checked={details.specialties.includes('Property Management')}
												setChecked={setSpecialty}
												name='Property Management'
											/>
										</div>
									</div>
									<p className='pedit_title pedit_title-mb'>
										Other Real Estate Specialties{' '}
										<span
											style={{
												fontWeight: 'normal',
												color: 'var(--primary-color)',
												marginLeft: '10px',
												cursor: 'pointer',
											}}
											onClick={() => setOtherSpecialties((val) => !val)}
										>
											{otherSpecialties ? 'Hide' : 'Show'}
										</span>
									</p>
									{otherSpecialties && (
										<div className='pedit_split'>
											<div>
												<Checkbox text='Appraisal' checked={details.specialties.includes('Appraisal')} setChecked={setSpecialty} name='Appraisal' />
												<Checkbox text='Escrow' checked={details.specialties.includes('Escrow')} setChecked={setSpecialty} name='Escrow' />
												<Checkbox text='Legal' checked={details.specialties.includes('Legal')} setChecked={setSpecialty} name='Legal' />
												<Checkbox text='Notary' checked={details.specialties.includes('Notary')} setChecked={setSpecialty} name='Notary' />
												<Checkbox text='Title' checked={details.specialties.includes('Title')} setChecked={setSpecialty} name='Title' />
											</div>
											<div>
												<Checkbox
													text='Commercial R.E.'
													checked={details.specialties.includes('Commercial R.E.')}
													setChecked={setSpecialty}
													name='Commercial R.E.'
												/>
												<Checkbox text='Insurance' checked={details.specialties.includes('Insurance')} setChecked={setSpecialty} name='Insurance' />
												<Checkbox text='Moving' checked={details.specialties.includes('Moving')} setChecked={setSpecialty} name='Moving' />
												<Checkbox text='Staging' checked={details.specialties.includes('Staging')} setChecked={setSpecialty} name='Staging' />
												<Checkbox text='Other' checked={details.specialties.includes('Other')} setChecked={setSpecialty} name='Other' />
											</div>
										</div>
									)}
								</div>
							</div>
						</div>
					)}
					{stepper === 5 && (
						<div>
							<div className='pedit__thirty'>
								<div style={{ alignItems: 'center' }}>
									<span className='pedit_title'>Profile Video URL:</span>
									<span>&nbsp;(optional)</span>
								</div>
								<div>
									<Input
										placeholder='Profile Video URL'
										value={details.video_link || ''}
										type='url'
										onBlur={onBlurField}
										title='Please enter an address matching the format (https://www.example.com)'
										name='video_link'
										onChange={changeDetails}
										fullStyles={{ marginTop: 'unset', width: '100%' }}
										style={{ padding: '9px 5px' }}
									/>
								</div>
							</div>
							<div className='pedit__thirty'>
								<div style={{ alignItems: 'center' }}>
									<span className='pedit_title'>Website:</span>
									<span>&nbsp;(optional)</span>
								</div>
								<div style={{ display: 'flex', flexDirection: 'column' }}>
									<Input
										placeholder='Website (https://www.example.com)'
										value={details.web_link || ''}
										disabled={!canEdit}
										type='url'
										name='web_link'
										title='Please enter an address matching the format (https://www.example.com)'
										onBlur={onBlurField}
										onChange={changeDetails}
										fullStyles={{ marginTop: 'unset', width: '100%' }}
										style={{ padding: '9px 5px' }}
									/>
									{isPremSub && (
										<span
											style={{ fontWeight: 300, margin: '5px 0', color: 'var(--primary-color)', textDecoration: 'underline', cursor: 'pointer' }}
											onClick={setPremWeb}
										>
											Click to use Investor Website
										</span>
									)}
								</div>
							</div>
							<div className='pedit__thirty'>
								<div style={{ alignItems: 'center' }}>
									<span className='pedit_title'>Blog:</span>
									<span>&nbsp;(optional)</span>
								</div>
								<div>
									<Input
										placeholder='Blog (https://example.com)'
										value={details.blog || ''}
										name='blog'
										type='url'
										title='Please enter an address matching the format (https://example.com)'
										onBlur={onBlurField}
										onChange={changeDetails}
										fullStyles={{ marginTop: 'unset', width: '100%' }}
										style={{ padding: '9px 5px' }}
									/>
								</div>
							</div>
							<div className='pedit__thirty'>
								<div style={{ alignItems: 'center' }}>
									<span className='pedit_title'>Facebook:</span>
									<span>&nbsp;(optional)</span>
								</div>
								<div>
									<Input
										placeholder='Facebook (https://facebook/example.com)'
										value={details.facebook || ''}
										name='facebook'
										type='url'
										onBlur={onBlurField}
										title='Please enter an address matching the format (https://example.com)'
										onChange={changeDetails}
										fullStyles={{ marginTop: 'unset', width: '100%' }}
										style={{ padding: '9px 5px' }}
									/>
								</div>
							</div>
							<div className='pedit__thirty'>
								<div style={{ alignItems: 'center' }}>
									<span className='pedit_title'>Twitter:</span>
									<span>&nbsp;(optional)</span>
								</div>
								<div>
									<Input
										placeholder='Twitter (https://www.twitter.com/example)'
										value={details.twitter || ''}
										name='twitter'
										type='url'
										title='Please enter an address matching the format (https://example.com)'
										onChange={changeDetails}
										onBlur={onBlurField}
										fullStyles={{ marginTop: 'unset', width: '100%' }}
										style={{ padding: '9px 5px' }}
									/>
								</div>
							</div>
							<div className='pedit__thirty'>
								<div style={{ alignItems: 'center' }}>
									<span className='pedit_title'>LinkedIn:</span>
									<span>&nbsp;(optional)</span>
								</div>
								<div>
									<Input
										placeholder='LinkedIn (https://www.linkedin.com/example)'
										value={details.linkedin || ''}
										name='linkedin'
										onBlur={onBlurField}
										type='url'
										title='Please enter an address matching the format (https://example.com)'
										onChange={changeDetails}
										fullStyles={{ marginTop: 'unset', width: '100%' }}
										style={{ padding: '9px 5px' }}
									/>
								</div>
							</div>
							<div className='pedit__thirty'>
								<div style={{ alignItems: 'center' }}>
									<span className='pedit_title'>Instagram:</span>
									<span>&nbsp;(optional)</span>
								</div>
								<div>
									<Input
										placeholder='LinkedIn (https://www.instagram.com/example)'
										value={details.instagram || ''}
										name='instagram'
										onBlur={onBlurField}
										type='url'
										title='Please enter an address matching the format (https://example.com)'
										onChange={changeDetails}
										fullStyles={{ marginTop: 'unset', width: '100%' }}
										style={{ padding: '9px 5px' }}
									/>
								</div>
							</div>
						</div>
					)}

					<div className='pedit_buttons'>
						{stepper > 1 && (
							<Button
								style={{
									width: 'fit-content',
									fontSize: '16px',
									padding: '10px',
									color: 'white',
									backgroundColor: 'var(--primary-color)',
									display: 'block',
									marginRight: 'auto',
								}}
								onClick={handleGoBack}
								type='button'
								text='Previous'
							/>
						)}

						<Button
							style={{
								width: 'fit-content',
								fontSize: '16px',
								padding: '10px',
								color: 'white',
								backgroundColor: 'var(--primary-color)',
								display: 'block',
								marginRight: 'unset',
							}}
							type='submit'
							name='action'
							value='continue'
							onClick={(e) => gotoNext(e)}
							text='Save & Continue'
						/>
						<Button
							style={{
								width: 'fit-content',
								fontSize: '18px',
								padding: '10px',
								background: 'white',
								color: 'var(--primary-color)',
								display: 'block',
							}}
							type='submit'
							name='action'
							value='exit'
							text='Save & Exit'
							onClick={(e) => gotoNext(e, true)}
						/>
					</div>
				</form>
			</div>
		</>
	);
}

export default ProfileEdit;
