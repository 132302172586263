import React, { useState } from 'react'
import { useTheme } from '@mui/material/styles'
import { Box, Container, Grid, Typography, Checkbox, FormControlLabel, } from '@mui/material'
import MuiThemeLayout from '../../../../Layouts/MuiThemeLayout'
import { useNavigate } from 'react-router-dom'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import wideLogo from '../../../../assets/dealAnalyzer/iconComponents/logo-wide1.webp'
import PaymentSelectedOffer from './PaymentSelectedOffer';
import DealDetails from './DealDetails';
import { useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js';
import StripePay from '../../Questionnaires/SpecialHooks/StripePay'
import base from '../../../../apis'
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);


const useStyles = (_theme)=>(
  {
    root:{
      height:"100vh",

  },
  nav:{
    backgroundColor:"#1378A5",
    display:"flex",
    padding:"0.625rem 6.25rem",
    alignItems:"center",
    color:"white"
  },
  header:{
    display:"flex",
    justifyContent:"space-between",
    alignItems:"center",
    my:4
  },
  info:{
    display:"flex",
    flexDirection:"column",
    alignContent:"flex-end"
  }
}
)

const PaymentConfirmation = () => {
  const theme = useTheme()
  const styles = useStyles(theme)
  const [offerPeriod, setOfferPeriod] = useState()
  const [searchParams] = useSearchParams()
  const [acceptLegal, setAcceptLegal] = useState(true)
  const [offerCodes, setOfferCodes] = useState();
  const offer = searchParams.get("offer")
  const navigate = useNavigate()
  const paramsOfferPeriod = searchParams.get("offerperiod")
  const dealCodes = ["DA-01","DA-02M","DA-02A","DA-03M","DA-03A"]

  const handleChecked = ()=>{
    setAcceptLegal(prev=>!prev)
  }
  const handleOfferPeriod = (value)=>{
    setOfferPeriod(value)
  }
  const handleMoveBack = ()=>{
    navigate(-1)
  }


    const body = 
      +offer===0? {...deals[0],price_data:[{price:offerCodes?.[0]?.id,quantity: 1}]} :
      +offer===1 && +offerPeriod === 0 ? {...deals[1]['month'],price_data:[{price:offerCodes?.[1]?.id,quantity: 1}]} : 
      +offer===1 && +offerPeriod === 1 ? {...deals[1]['year'],price_data:[{price:offerCodes?.[2]?.id,quantity: 1}]} :
      +offer===2 && +offerPeriod === 0 ? {...deals[2]['month'],price_data:[{price:offerCodes?.[3]?.id,quantity: 1}]}:
      {...deals[2]['year'],price_data:[{price:offerCodes?.[4]?.id,quantity: 1}]}
    



  useEffect(()=>{
    setOfferPeriod(paramsOfferPeriod)
    const packages = async()=>{
      const res = await base.get('/register/list_prices/')
      const data = res?.data[0].filter(element => element?.metadata?.product ==='deal analyzer')
      setOfferCodes(dealCodes.map(element =>data.filter(ele=> ele.metadata.product_code===element)[0]))
    }
    packages()
        // NOTE: Run effect once on component mount, please
        // recheck dependencies if effect is updated.
        // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  return (
    <MuiThemeLayout>
      <Elements stripe={stripePromise}>

      <Box sx={styles.root}>
        <Box sx={styles.nav}>
          <KeyboardBackspaceIcon sx={{fontSize:"1rem",mr:2, cursor:"pointer"}} onClick = {handleMoveBack}/>
          <Typography variant='h6' onClick = {handleMoveBack} sx={{cursor:"pointer"}}>Checkout</Typography>
        </Box>
        <Container maxWidth="lg" >
          <Box sx={styles.header}>
            <Box><img src={wideLogo} alt = 'logo'/></Box>          
            <Box sx={styles.info}>
              <Typography variant="payTel" sx={{textAlign:"end",mb:1}}>Call Sales: 1 888 616 5270</Typography>
              <Typography variant="payOpenTimes">9:00 AM to 6:30 PM PST, Monday to Friday</Typography>
            </Box>          
          </Box>
          <Grid container spacing={{ xs: 0, md: 0 }} rowSpacing={2} columns={{ xs: 4,  md: 24 }}>
            <Grid item xs={4} sm={4} md={11} >
              <DealDetails/>
            </Grid>
            <Grid item xs={0} sm={0} md={1}></Grid>
            <Grid item xs={4}  md={12}>
              <PaymentSelectedOffer handleOfferPeriod={handleOfferPeriod} offer={offer} offerPeriod = {offerPeriod}/>
            </Grid>
          </Grid>
          <Box sx={{textAlign:"center",margin:"1.25rem"}}>
            <Typography  variant="payOpenTimes">{staticText.disclaimer}</Typography>
            <Box>
              <FormControlLabel
              label ={ <Typography variant = 'payTel'>I have read and agree to the above terms</Typography>}
                control={
                  <Checkbox
                  checked={acceptLegal}
                  onChange={handleChecked}
                  sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                />
                }
              />

            </Box>
            <Box sx={{margin:"2.5rem 0"}}>
              <Typography variant="payOpenTimes" sx={{margin:"0 5px"}}>By confirming payment, you agree with our</Typography>
              <Typography variant = 'buttonFont' sx={{color:"#1378A5"}}>Terms & Condition</Typography>
              <Typography variant="payOpenTimes" sx={{margin:"0 5px"}}>and</Typography>
              <Typography variant = 'buttonFont' sx={{color:"#1378A5"}}>Privacy Policy</Typography>
            </Box>
            <StripePay acceptLegal={acceptLegal} body = {body}/>
          </Box>

        </Container>
        
    </Box>
    </Elements>
    </MuiThemeLayout>

  )
}

export default PaymentConfirmation

const staticText = {
  disclaimer:"I understand that I am enrolling in a monthly subscription program, and I authorize BPO Homes to charge my credit card account the monthly amount on the 4th of each month for the next 12 months via electronic signature."
}
const deals = {
  0:{
    price_data:[{price:"price_1LYAlYLhxZuiEljHmHCyNYWC",quantity: 1}],
    cancel_url: window.location.origin + "/analytics",
    success_url: window.location.origin + "/analyzer-questionnaire?",
    payment_method_types: ['card'],
    mode: 'payment',
    package: 'deal-analyzer',
  },
  1:{
      month:{
        price_data:[{price:"price_1LLm0SLhxZuiEljHlyiLOEXj",quantity: 1}],
        cancel_url: window.location.origin + "/analytics",
        success_url: window.location.origin + "/analyze-deal?",
        payment_method_types: ['card'],
        mode: 'subscription',
        package: 'deal-analyzer',
        interval:"monthly"
      },
      year:{
        price_data:[{price:"price_1LYAkJLhxZuiEljH8KWiAPoE",quantity: 1}],
        cancel_url: window.location.origin + "/analytics",
        success_url: window.location.origin + "/analyze-deal?",
        payment_method_types: ['card'],
        mode: 'subscription',
        package: 'deal-analyzer',
        interval:"yearly"

      }

  },
  2:{
    month:{
      price_data:[{price:"price_1LmyvNLhxZuiEljHlsayCOUe",quantity: 1}],
      cancel_url: window.location.origin + "/analytics",
      success_url: window.location.origin + "/analyze-deal?",
      payment_method_types: ['card'],
      mode: 'subscription',
      package: 'deal-analyzer',
      interval:"monthly"
    },
    year:{
      price_data:[{price:"price_1LmywpLhxZuiEljHt74sk5AP",quantity: 1}],
      cancel_url: window.location.origin + "/analytics",
      success_url: window.location.origin + "/analyze-deal?",
      payment_method_types: ['card'],
      mode: 'subscription',
      package: 'deal-analyzer',
      interval:"yearly"
    }
  }
}