import { Close } from '@mui/icons-material';
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	FormControlLabel,
	Grid,
	IconButton,
	Radio,
	RadioGroup,
	ToggleButton,
	ToggleButtonGroup,
	Typography,
} from '@mui/material';
import { Elements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, NavLink, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import base from '../../apis';
import Checkbox from '../../components/Checkbox/Checkbox';
import Footer from '../../components/Footer/Footer';
import Spinner from '../../components/Spinner/Spinner';
import BPOLogo from '../../images/bpoVerticalLogo.webp';
import { formatNumber, getTier } from '../../utils/functions';
import '../ChoosePackage/choosepackage.scss';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

function ReportPay() {
	const [isLoading, setIsLoading] = useState(false);
	const [termsAgreed, setTermsAgreed] = useState(false);
	const isClient = useSelector((state) => state.auth.role === 'client');
	const { agentId } = useParams();
	const [tierPrices, setTierPrices] = useState([]);
	const [agentDetails, setAgentDetails] = useState(null);
	const [selectedOption, setSelectedOption] = useState('onetime');
	const [selectedSub, setSelectedSub] = useState(null);
	const [searchParams] = useSearchParams();
	const [driveThroughOpen, setDriveThroughOpen] = useState(false);
	const [driveThroughTotal, setDriveThroughTotal] = useState(0);
	const [distanceDetails, setDistanceDetails] = useState({
		clientAddress: '',
	});

	//! Step 1 for initial next button and step 2 to proceed to stripe
	const [step, setStep] = useState(1);

	const navigate = useNavigate();
	useEffect(() => {
		if (!isClient) {
			toast.warn('Only clients can request a bpo report');
			navigate('/agent-dashboard');
			return;
		}
		const mode = searchParams.get('mode');
		if (mode) {
			setSelectedOption(mode);
		}

		const getDetails = async () => {
			setIsLoading(true);
			try {
				const {
					data: {
						report_price,
						first_name,
						last_name,
						discount_value,
						discount_percent,
						distance_flat_fee,
						drive_through_address,
						flat_fee,
						price_per_mile,
					},
				} = await base.get(`/register/agents/${agentId}/`);
				setAgentDetails({
					report_price,
					name: `${first_name} ${last_name}`,
					discount_value,
					discount_percent,
					distance_flat_fee,
					drive_through_address,
					flat_fee,
					price_per_mile,
				});
				const { data: prices } = await base.get('/register/list_prices/', {
					params: {
						product: 'bpo_report_tier',
					},
				});
				const tier = getTier(report_price);

				if (prices?.flat().length !== 0) {
					const Prices = prices
						?.flat()
						.filter((item) => item?.metadata?.tier === tier)
						.map((item) => ({
							amount: item?.unit_amount / 100,
							description: item?.nickname,
							price_id: item?.id,
							number_of_report: item?.metadata?.report,
							tier: item?.metadata?.tier,
						}))
						.sort((a, b) => a.amount - b.amount);
					setTierPrices(Prices);
				}
				if (drive_through_address && distance_flat_fee && flat_fee) {
					const reportAddress = JSON.parse(localStorage.getItem('request'))?.propertyAddress;
					setDistanceDetails((cur) => ({ ...cur, clientAddress: reportAddress }));
					setIsLoading(true);
					try {
						const { data } = await base.get('/leads/get_delivery_distance/', {
							params: {
								agent_address: drive_through_address,
								client_address: reportAddress,
							},
						});
						const items = {
							distance: data?.distance,
							distance_after_flat_fee: data?.distance > distance_flat_fee ? data?.distance - distance_flat_fee : 0,
							amount_after_flat_fee: 0,
						};
						if (items.distance_after_flat_fee) {
							items.amount_after_flat_fee = items.distance_after_flat_fee * price_per_mile;
						}
						setDistanceDetails((cur) => ({ ...cur, ...items }));
						setStep(1);
					} catch (error) {
						toast.warn('Could not get distance details');
					} finally {
						setIsLoading(false);
					}
				} else {
					setStep(2);
				}
			} catch (error) {
				toast.warn('Something went wrong');
			} finally {
				setIsLoading(false);
			}
		};

		getDetails();
	}, [agentId, isClient, navigate, searchParams]);

	const handlePriceChange = (e) => {
		const { value } = e.target;

		const price = tierPrices.find((item) => item.price_id === value);
		setSelectedSub(price);
	};

	const handleToggleChange = (e, value) => {
		setSelectedOption(value);
		if (value === 'onetime') {
			setSelectedSub(null);
		}
	};

	const getCard = () => {
		const extra = agentDetails?.drive_through_address && agentDetails?.distance_flat_fee && agentDetails?.flat_fee && (
			<Button
				variant='outlined'
				onClick={() => {
					setDriveThroughOpen(true);
				}}
				sx={{ textTransform: 'capitalize', display: 'block' }}
				size='small'
				color='primary'
			>
				Request Site Visit
			</Button>
		);
		if (selectedOption === 'onetime') {
			return (
				<div>
					<h3>Your Package Selection</h3>
					<div>
						<div style={{ display: 'flex', justifyContent: 'center' }}>
							<p style={{ textAlign: 'justify', lineHeight: '1.5' }}>
								Looking for a reliable and expert opinion on the value of your property? Look no further than {agentDetails?.name}'s BPO Report
								service!{agentDetails?.name} can provide you with an accurate estimate of your property's value for just{' '}
								<b>$ {Number(agentDetails?.discount_value || agentDetails?.report_price || 0).toFixed(2)}</b>.
							</p>
						</div>
						{extra}
					</div>
				</div>
			);
		}
		return (
			<div>
				<h3>Your Package Selection</h3>
				<div>
					{
						<FormControl sx={{ fontSize: '16px' }}>
							<RadioGroup color='secondary' onChange={handlePriceChange} value={selectedSub?.price_id || ''}>
								{tierPrices.map((item) => (
									<FormControlLabel
										key={item.price_id}
										value={item.price_id}
										control={<Radio size='small' color='secondary' />}
										label={
											<p style={{ margin: 0, fontSize: '1rem' }}>
												{item.description} - <b>${item.amount}</b>/month
											</p>
										}
									/>
								))}
							</RadioGroup>
						</FormControl>
					}
					{extra}
				</div>
			</div>
		);
	};

	const handleAddDrive = () => {
		setDriveThroughTotal(distanceDetails?.amount_after_flat_fee + agentDetails?.flat_fee);
		setDriveThroughOpen(false);
		setStep(2);
	};

	const handleNext = () => {
		setDriveThroughOpen(true);
		setStep(2);
	};

	return (
		<>
			<Dialog fullWidth maxWidth='sm' open={driveThroughOpen} onClose={() => setDriveThroughOpen(false)}>
				<DialogTitle sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
					<Box display='flex' alignItems='center' gap='10px'>
						<Typography>Request Site Visit</Typography>
					</Box>
					<IconButton onClick={() => setDriveThroughOpen(false)}>
						<Close fontSize='small' />
					</IconButton>
				</DialogTitle>
				<DialogContent dividers>
					<Grid container spacing={2}>
						<Grid item xs={4}>
							Requested Address:
						</Grid>
						<Grid item xs={8}>
							{distanceDetails?.clientAddress}
						</Grid>
						<Grid item xs={4}>
							Total ($):
						</Grid>
						<Grid item xs={8}>
							$ {formatNumber(agentDetails?.flat_fee + distanceDetails?.amount_after_flat_fee || 0, 2)}
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button
						variant='primary'
						onClick={() => {
							setDriveThroughOpen(false);
						}}
					>
						Close
					</Button>
					<Button variant='contained' disableElevation color='secondary' sx={{ marginLeft: '1rem' }} onClick={handleAddDrive}>
						Request
					</Button>
				</DialogActions>
			</Dialog>
			{isLoading && <Spinner />}
			<Elements stripe={stripePromise}>
				<div className='choose-package'>
					<div className='choose-package_header'>
						<Link to={'/client-dashboard'}>
							<img alt='Logo' src={BPOLogo} />
						</Link>
						<div style={{ textAlign: 'right' }}>
							<p>Call Sales: 1.888.616.5270</p>
							<p>9:00 AM to 6:30 PM PST, Monday to Friday</p>
						</div>
					</div>
					<Box display='flex' justifyContent='center' my={1}>
						<ToggleButtonGroup size='small' color='secondary' value={selectedOption} exclusive onChange={handleToggleChange}>
							<ToggleButton value='onetime'>One Time</ToggleButton>
							<ToggleButton value='sub'>Subscription </ToggleButton>
						</ToggleButtonGroup>
					</Box>
					<div className='choose-package_boxes'>
						{getCard()}
						<div>
							<h3>Order Summary</h3>
							<div>
								<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
									<p style={{ fontWeight: '300' }}>
										<b>{agentDetails?.name}</b> Standard Fee
									</p>
									{agentDetails?.discount_percent > 0 && (
										<p className='strikethrough'>$ {formatNumber(Number(agentDetails?.report_price || 0, 2))}</p>
									)}
									<h3>$ {formatNumber(Number(agentDetails?.discount_value || agentDetails?.report_price || 0, 2))}</h3>
								</div>
								{!!selectedSub && (
									<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
										<p style={{ fontWeight: '300' }}>Price /month</p>
										<h3>$ {formatNumber(Number(tierPrices.find((item) => item?.price_id === selectedSub?.price_id)?.amount || 0, 2), 2)} </h3>
									</div>
								)}
								{driveThroughTotal > 0 && (
									<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
										<p style={{ fontWeight: '300' }}>Site Visit fee</p>

										<Button variant='text' onClick={() => setDriveThroughTotal(0)} sx={{ textTransform: 'capitalize' }} size='small' color='warning'>
											Click to Remove
										</Button>
										<h3>$ {formatNumber(driveThroughTotal || 0, 2)} </h3>
									</div>
								)}
								<div style={{ display: 'flex', justifyContent: 'space-between' }}>
									<h3>Total: </h3>
									<h3 style={{ color: 'var(--primary-color)' }}>
										${' '}
										{formatNumber(
											Number(
												(agentDetails?.discount_value || agentDetails?.report_price) +
													driveThroughTotal +
													(tierPrices.find((item) => item?.price_id === selectedSub?.price_id)?.amount || 0) || 0,
												2
											)
										)}
									</h3>
								</div>
							</div>
						</div>
					</div>
					<div className='choose-package_terms'>
						{/* <span>
          I understand that I am enrolling in a yearly subscription program, and I authorize BPO Homes to charge my credit card account the yearly
          amount on the 15th of every January via electronic signature, till I opt out.
        </span> */}
						<div>
							<Checkbox
								text='By checking this you have agreed to the'
								checked={termsAgreed}
								setChecked={(e) => setTermsAgreed(e.target.checked)}
								labelStyle={{ display: 'inline' }}
							/>
							<NavLink to={`/disclaimer#bpo-report`} className='blue_link' style={{}}>
								&nbsp; Terms of Use
							</NavLink>
						</div>
						<p>
							By placing the order you agree with our{' '}
							<NavLink to={`/disclaimer#bpo-report`} className='blue_link'>
								Terms of Use
							</NavLink>{' '}
							and{' '}
							<NavLink to='/privacy-policy' className='blue_link'>
								Privacy Policy
							</NavLink>
						</p>
						{step === 1 ? (
							<Button
								sx={{
									padding: '10px 30px',
									display: 'block',
									margin: '0 auto',
									textTransform: 'capitalize',
								}}
								color='secondary'
								onClick={handleNext}
								variant='contained'
							>
								Next
							</Button>
						) : (
							<StripePay
								termsAgreed={termsAgreed}
								agentId={agentId}
								selectedPrice={selectedSub}
								isSub={selectedOption === 'sub'}
								tier={getTier(agentDetails?.report_price)}
								driveThroughTotal={driveThroughTotal}
							/>
						)}
					</div>
				</div>
				<Footer />
			</Elements>
		</>
	);
}

export default ReportPay;

const StripePay = ({ termsAgreed, selectedPrice, isSub, agentId, tier, driveThroughTotal }) => {
	const stripe = useStripe();

	const confirmPayment = () => {
		if (!termsAgreed) {
			toast.warn('Please agree to the terms and conditions');
			return;
		}

		if (isSub && !selectedPrice) {
			toast.warn('Please select a price');
			return;
		}

		const body = {
			cancel_url: `${window.location.origin}/report-pay/` + agentId,
			success_url: `${window.location.origin}/payment_success?type=bpo-report&agent_id=${agentId}&tier=${tier}${
				'&num_of_report=' + (selectedPrice?.number_of_report || 1)
			}&mode=${isSub ? 'subscription' : 'one_time'}&`,
			mode: 'payment',
			payment_type: isSub ? 'monthly' : 'one_time',
			package: 'bpo_report_tier',
			payment_method_types: ['card'],
		};

		const extra_data = {
			agent_id: agentId,
			tier: tier,
			product: 'bpo_report_tier',
		};
		if (driveThroughTotal > 0) {
			extra_data['drive_amount'] = driveThroughTotal;
		}

		if (isSub) {
			extra_data['number_of_report'] = selectedPrice?.number_of_report;
			extra_data['price'] = selectedPrice?.price_id;
			body['interval'] = 'monthly';
			body['mode'] = 'subscription';
		} else {
			extra_data['number_of_report'] = 1;
		}

		body['extra_data'] = [extra_data];

		base
			.post('/register/create_subscription/', body)
			.then(({ data: { sessionId } }) => {
				if (!sessionId) {
					toast.error('Something went wrong');
					return;
				}
				stripe.redirectToCheckout({ sessionId });
			})
			.catch((err) => {
				toast.error('Something went wrong');
			});
	};

	return (
		<Button
			sx={{
				padding: '10px 30px',
				display: 'block',
				margin: '0 auto',
				textTransform: 'capitalize',
			}}
			color='secondary'
			onClick={confirmPayment}
			variant='contained'
		>
			Pay Now
		</Button>
	);
};
