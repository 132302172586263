import React from 'react';
import './choosepackage.scss';
import BPOLogo from '../../images/bpoVerticalLogo.webp';
import Checkbox from '../../components/Checkbox/Checkbox';
import { Link, NavLink, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Button from '../../components/Button/Button';
import { useState } from 'react';
import { useEffect } from 'react';
import base from '../../apis';
import { toast } from 'react-toastify';
import Spinner from '../../components/Spinner/Spinner';
import { Elements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useSelector } from 'react-redux';
// import { formatNumber } from '../../utils/functions';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

//! Page info
/*on navigate pass use format /choose-package/:package-name/:paymentOrSubscription
valid package names are 'premier-agent-website', 'deal-analyzer', 'deal-analyzer-unlimited', 'combo-package'
valid paymentOrSubscription are 'payment' or 'subscription'
*/
const ChoosePackage = () => {
	const [isMonthly, setIsMonthly] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [toPayFor, setToPayFor] = useState('');
	const [termsAgreed, setTermsAgreed] = useState(false);
	const [termsAgreed1, setTermsAgreed1] = useState(false);
	const { subType, packType } = useParams();
	const [possiblePackages, setPossiblePackages] = useState(null);
	const [packageDetails, setPackageDetails] = useState([]);
	const [searchParams] = useSearchParams();
	const isClient = useSelector((state) => state.auth.role === 'client');
	const [baseSetup] = useState(null);
	const navigate = useNavigate();
	useEffect(() => {
		const sessionId = searchParams.get('session_id');
		if (sessionId) {
			searchParams.delete('session_id');
		}
	}, [searchParams]);

	useEffect(() => {
		if (!packType) return;
		if (!isClient && packType === 'bpo-report') {
			toast.warn('Only clients can purchase BPO Reports');
			navigate('/agent-dashboard', {
				replace: true,
			});
			return;
		}
		if (packType !== 'bpo-report' && isClient) {
			toast.warn('You cannot subscribe to this product');
			navigate('/client-dashboard', {
				replace: true,
			});
			return;
		}
		setIsLoading(true);
		const controller = new AbortController();
		base
			.get('/register/list_prices/', {
				signal: controller.signal,
			})
			.then((res) => {
				if (!res) return;
				const packTypeSplit = packType.split('-').join(' ');
				const data = res?.data?.flat();
				const possible = data.filter((item) => item.metadata?.product === packTypeSplit && item.metadata?.payment_type === subType);
				if (['premier-agent-website'].includes(packType) && subType === 'subscription') {
					// const setup = data.find((item) => item.metadata?.base_for === packType);
					// const setupPrice = {
					// 	id: setup.id,
					// 	amount: formatNumber(setup?.unit_amount / 100),
					// };
					// setBaseSetup(setupPrice);
				}
				if (possible?.length === 0) {
					toast.error('No such package exists');
					return;
				}
				let name = '';
				if (packType === 'premier-agent-website') {
					name = 'Premier Agent Basic (Includes Setup Fee- $99)';
					setPackageDetails([
						'Advertisement in 5 cities of your choice',
						'Built custom premier agent web page',
						'Premier Agent high-converting single listing site',
						`Unlimited Leads , NOD's, Probate, Divorce, FSBO, Expired, Buyer & Seller Leads, BPO Report Leads`,
						'Deal Analyzer platform',
					]);
				}
				if (packType.includes('deal-analyzer')) {
					name = 'BPO Homes Deal Analyzer';
					setPackageDetails(['Agent Branded Pro-Forma Reports']);
				}
				// if (packType === 'combo-package') {
				// 	name = 'BPO Homes Combo Package';
				// 	setPackageDetails([
				// 		'We advertise you in 5 cities of your choice as a BPO Homes Premier Listing & Buyers Agent',
				// 		'We Build you a Custom Premier Agent Web Page',
				// 		'Premier Agent Website includes Agents uploaded listing and videos',
				// 		'Unlimited Notice of Default (NOD) Records Leads',
				// 		'Branded Investor Ready Pro-Forma Report',
				// 	]);
				// }
				if (packType === 'bpo-report') {
					name = 'BPO Report';
					setPackageDetails(['BPO Report']);
				}
				const body = {
					product: name,
					payment_type: subType,
					monthly: possible?.find((item) => item.metadata?.recurring_type === 'monthly'),
					yearly: possible.find((item) => item.metadata?.recurring_type === 'yearly'),
					onetime: possible.find((item) => item.metadata?.payment_type === 'payment'),
					isRecurring: subType === 'subscription',
				};
				setPossiblePackages(body);
				if (subType === 'subscription') {
					setIsMonthly(true);
					setToPayFor(body.monthly.id);
				} else {
					setToPayFor(body.onetime.id);
				}
			})
			.catch((err) => {
				toast.error('Could not fetch products.');
			})
			.finally(() => {
				setIsLoading(false);
			});

		return () => {
			controller.abort();
			setIsMonthly(false);
		};
	}, [packType, subType, isClient, navigate]);

	const switchRecurringType = (type) => {
		setIsMonthly(type === 'monthly' ? true : false);
		setToPayFor(possiblePackages[type].id);
	};

	return (
		<>
			{isLoading && <Spinner />}
			<Elements stripe={stripePromise}>
				<div className='choose-package'>
					<div className='choose-package_header'>
						<Link to={isClient ? '/client-dashboard' : '/agent-dashboard'}>
							<img alt='Logo' src={BPOLogo} />
						</Link>
						<div style={{ textAlign: 'right' }}>
							<p>Call Sales: 1.888.616.5270</p>
							<p>9:00 AM to 6:30 PM PST, Monday to Friday</p>
						</div>
					</div>
					<div className='choose-package_boxes'>
						<div>
							<h3>Your Package Selection</h3>
							<div>
								<div style={{ display: 'flex', justifyContent: 'center' }}>
									<input type='radio' defaultChecked />
									<label htmlFor='' style={{ fontSize: '20px', marginLeft: '20px' }}>
										{possiblePackages?.product}
									</label>
								</div>
								<p style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '14px', margin: '1.5rem' }}>Package details:</p>
								<div style={{ marginTop: '20px' }}>
									{packageDetails.map((item, index) => (
										<div key={index} style={{ display: 'flex', gap: '10px', marginTop: '5px' }}>
											<CheckCircleOutlineIcon color='#1378a5' fontSize='15px' sx={{ color: '#1378a5' }} />
											<span>{item}</span>
										</div>
									))}
								</div>
							</div>
						</div>
						<div>
							<h3>Order Summary</h3>
							<div>
								{subType === 'subscription' && (
									<div className='monoryear'>
										<span data-active={isMonthly} onClick={() => switchRecurringType('monthly')}>
											Monthly
										</span>
										<span data-active={!isMonthly} onClick={() => switchRecurringType('yearly')}>
											Annually
										</span>
									</div>
								)}
								<div style={{ display: 'flex', justifyContent: 'space-between' }}>
									{subType === 'subscription' && <h3>Price /{isMonthly ? 'month' : 'year'}</h3>}
									{subType === 'payment' && <h3>Price /deal</h3>}
									<h3>
										${' '}
										{subType === 'subscription' &&
											Number(possiblePackages?.[isMonthly ? 'monthly' : 'yearly']?.unit_amount / 100 || 0)
												.toFixed(2)
												.toLocaleString()}{' '}
										{subType === 'payment' &&
											Number(possiblePackages?.onetime?.unit_amount / 100 || 0)
												.toFixed(2)
												.toLocaleString()}
										{subType === 'subscription' && (
											<>
												<span>/</span>
												<span style={{ fontWeight: 'initial' }}>{isMonthly ? 'month' : 'year'}</span>
											</>
										)}
									</h3>
								</div>
								<div style={{ display: 'flex', justifyContent: 'space-between' }}>
									<h3>Total: </h3>
									<h3 style={{ color: 'var(--primary-color)' }}>
										${' '}
										{subType === 'subscription' &&
											Number(possiblePackages?.[isMonthly ? 'monthly' : 'yearly']?.unit_amount / 100 || 0)
												.toFixed(2)
												.toLocaleString()}
										{!!baseSetup && (
											<>
												<span style={{ fontSize: '14px' }}> + $ {baseSetup?.amount} (setup fee)</span>
											</>
										)}
										{subType === 'payment' &&
											Number(possiblePackages?.onetime?.unit_amount / 100 || 0)
												.toFixed(2)
												.toLocaleString()}
									</h3>
								</div>
							</div>
						</div>
					</div>
					<div
						className='choose-package_terms'
						style={{ display: 'flex', flexDirection: 'column', gap: '1rem', alignItems: 'flex-start', maxWidth: '70%', margin: 'auto' }}
					>
						{/* <span>
							I understand that I am enrolling in a yearly subscription program, and I authorize BPO Homes to charge my credit card account the yearly
							amount on the 15th of every January via electronic signature, till I opt out.
						</span> */}

						<div>
							<Checkbox
								text='By checking this you have agreed to the'
								checked={termsAgreed}
								setChecked={(e) => setTermsAgreed(e.target.checked)}
								labelStyle={{ display: 'inline', marginRight: 'auto' }}
							/>
							<NavLink to={`/disclaimer#${packType}`} className='blue_link' style={{}}>
								&nbsp; Terms of Use
							</NavLink>
						</div>

						<div>
							<Checkbox
								labelStyle={{ marginRight: 'auto', display: 'inline' }}
								checked={termsAgreed1}
								setChecked={(e) => setTermsAgreed1(e.target.checked)}
								text={`By providing my phone number to “BPO Homes”, I agree and acknowledge that “BPO Homes ” may send text messages to my wireless phone number for any purpose. Message and data rates may apply. Message frequency will vary, and you will be able to Opt-out by replying “STOP”. For more information on how your data will be handled please visit `}
							/>
							<NavLink to='/privacy-policy' className='blue_link'>
								https://bpotech.io/privacy-policy
							</NavLink>
						</div>
						<p style={{ margin: 'auto' }}>
							By placing the order you agree with our{' '}
							<NavLink to={`/disclaimer#${packType}`} className='blue_link'>
								Terms of Use
							</NavLink>{' '}
							and{' '}
							<NavLink to='/privacy-policy' className='blue_link'>
								Privacy Policy
							</NavLink>
						</p>
						<div style={{ margin: 'auto' }}>
							<StripePay
								termsAgreed={termsAgreed && termsAgreed1}
								toPayFor={toPayFor}
								isSub={subType === 'subscription'}
								packType={packType}
								isMonthly={isMonthly}
								baseSetup={baseSetup}
							/>
						</div>
					</div>
				</div>
			</Elements>
		</>
	);
};

export default ChoosePackage;

export const StripePay = ({ termsAgreed, toPayFor, isSub, packType, isMonthly, baseSetup }) => {
	const stripe = useStripe();

	const confirmPayment = () => {
		if (!termsAgreed) {
			toast.warn('Please agree to the terms and conditions');
			return;
		}

		//check out later
		const body = {
			price_data: [
				{
					quantity: 1,
					price: toPayFor,
				},
			],
			cancel_url: window.location.href,
			success_url: window.location.origin + `/payment_success?type=${packType}&`,
			mode: isSub ? 'subscription' : 'payment',
			payment_method_types: ['card'],
		};
		if (!!baseSetup) {
			body.price_data.push({
				quantity: 1,
				price: baseSetup?.id,
			});
		}
		if (isSub) {
			body.package = packType;
			body.interval = isMonthly ? 'monthly' : 'yearly';
		}

		base
			.post('/register/create_subscription/', body)
			.then(({ data: { sessionId } }) => {
				if (!sessionId) {
					toast.error('Something went wrong');
					return;
				}
				stripe.redirectToCheckout({ sessionId });
			})
			.catch((err) => {
				toast.error('Something went wrong');
			});
	};

	return (
		// <Elements stripe={stripePromise}>
		<Button
			style={{
				width: 'min(300px,100%)',
				fontSize: '16px',
				padding: '10px 30px',
				color: 'white',
				backgroundColor: 'var(--primary-color)',
				display: 'block',
			}}
			onClick={confirmPayment}
			text={isSub ? 'CONFIRM PAYMENT' : 'PAY NOW'}
		/>
		// </Elements>
	);
};
