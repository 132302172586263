import React, { useRef, useEffect } from 'react';
import Input from '../../../components/Input/Input';
import NoProfile from '../../../assets/no-profile.webp';
import OfficeLogoHandler from '../../../assets/office_logo_handler.webp';
import './profile.scss';
import Button from '../../../components/Button/Button';
import { NavLink, useOutletContext } from 'react-router-dom';
import base, { file as fileUploader } from '../../../apis';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

function Profile() {
	const id = useSelector((state) => state.auth.typeId);
	//eslint-disable-next-line
	const [listing, setIsLoading, setListing] = useOutletContext();
	const [agent, setAgent] = React.useState(null);

	const profileRef = useRef(null);
	const logoRef = useRef(null);

	useEffect(() => {
		setIsLoading(true);
		base
			.get(`register/agents/${id}/`)
			.then(({ data }) => {
				setAgent(data);
			})
			.catch(() => {
				toast.error('Could not get agent profile');
			})
			.finally(() => {
				setIsLoading(false);
			});
	}, [id, setIsLoading]);

	const setImage = (e) => {
		const { files, name } = e.target;
		const file = files[0];

		setIsLoading(true);
		let formData = new FormData();
		formData.append(name, file);
		fileUploader
			.patch(`register/agents/${id}/`, formData)
			.then(({ data }) => {
				setAgent(data);
			})
			.catch(() => {
				toast.error('Could not upload image');
			})
			.finally(() => {
				setIsLoading(false);
			});

		setAgent((cur) => ({ ...cur, [name]: file }));
	};

	return (
		<div className='profile_wrapper'>
			<div className='left_coluwn'>
				<div className='profile_pic_wrapper'>
					<p>Profile Picture</p>
					<img style={{ width: '120px', height: '120px' }} className='profile_handler' src={agent?.profile_image || NoProfile} alt='' />
					<Button onClick={() => profileRef.current.click()}>Upload</Button>
					<input type='file' accept='image/png, image/jpeg' name='profile_image' ref={profileRef} style={{ display: 'none' }} onChange={setImage} />
				</div>
				<div className='profile_pic_wrapper'>
					<p>Agent Office Logo</p>
					<img style={{ width: '120px', height: '120px' }} className='profile_handler' src={agent?.office_logo || OfficeLogoHandler} alt='profile' />
					<Button onClick={() => logoRef.current.click()}>Upload</Button>
					<input type='file' accept='image/png, image/jpeg' name='office_logo' style={{ display: 'none' }} ref={logoRef} onChange={setImage} />
				</div>
				<div className='office_logo_wrapper'></div>
			</div>
			<div className='right_column'>
				<p>Profile Information</p>
				<div className='input_row'>
					<Input fullStyles={{ width: '60%' }} label='Full Name' disabled value={agent?.user?.first_name + ' ' + agent?.user?.last_name || ''} />
					<Input fullStyles={{ width: '40%' }} label='Year work Commenced' disabled value={agent?.year_work_commenced || ''} />
				</div>
				<Input label="Broker's Address" value={agent?.brokerage_address || ''} disabled />
				<div className='input_row3'>
					<Input fullStyles={{ width: '50%' }} className='row_item' label='Cell phone' value={agent?.phone || ''} disabled />
					<Input fullStyles={{ width: '50%' }} label="Broker's Phone" value={agent?.brokerage_phone || ''} disabled />
				</div>
				<NavLink to='/profile/edit' style={{ color: 'var(--primary-color)', textDecoration: 'underline', cursor: 'pointer' }}>
					Edit Profile Info
				</NavLink>
			</div>
		</div>
	);
}

export default Profile;
