import { TOGGLE_SIDEBAR } from '../actions/types';

const initialState = {
	isOpen: false,
};

const sidebarReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case TOGGLE_SIDEBAR:
			return { ...state, isOpen: !state.isOpen };
		case 'SET_SIDEBAR':
			return { ...state, isOpen: payload || false };

		default:
			return state;
	}
};

export default sidebarReducer;
