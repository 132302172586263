export const financeOptionsExpensesAmount = (
  primaryInterestOnlyLoan,
  secondaryMonthlyAmountInterestOnly,
  primaryMonthlyPaymentInterestOnly,
  primaryLoanType = 0,
  primarySubLoanType,
  purchasePrice,
  remodelCost,
  secondaryLoanSubType,
  secondaryInterestOnly,
  curryingMonths,
  secondaryLoanIsTrue,
  mainPrimaryLoanType,
  primaryMortgageAmount,
  secondaryMortgageAmount,
  secondaryLoanType,
  primaryWholeSale,
  primaryDownPayment,
  secondaryDownPayment,
  downPaymentForSingleLoan,
  downPaymentForNewConstructionAndPayCash
) => {
  let amount = 0;
  if (primaryLoanType === 0) {
    if (mainPrimaryLoanType && primarySubLoanType === 1) {
      amount =
        (primaryInterestOnlyLoan || 0) * curryingMonths +
        (purchasePrice || 0) +
        (primaryWholeSale || 0);
    } else {
      amount =
        (primaryMonthlyPaymentInterestOnly || 0) * curryingMonths +
        (purchasePrice || 0) +
        (primaryWholeSale || 0);
    }
  } else if (primaryLoanType === 1) {
    if (mainPrimaryLoanType && primarySubLoanType === 1) {
      amount =
        ((primaryInterestOnlyLoan || 0) * curryingMonths || 0) +
        (purchasePrice || 0) +
        (primaryWholeSale || 0);
    } else {
      amount =
        (primaryMonthlyPaymentInterestOnly || 0) * curryingMonths +
        (purchasePrice || 0) +
        (primaryWholeSale || 0);
    }
  } else if (primaryLoanType === 2) {
    if (mainPrimaryLoanType && primarySubLoanType === 1) {
      amount =
        ((primaryInterestOnlyLoan || 0) * curryingMonths || 0) +
        (purchasePrice || 0) +
        (remodelCost || 0) +
        (primaryWholeSale || 0);
    } else {
      amount =
        (primaryMonthlyPaymentInterestOnly || 0) * curryingMonths +
        (purchasePrice || 0) +
        (remodelCost || 0) +
        (primaryWholeSale || 0);
    }
  } else if (primaryLoanType === 3) {
    if (mainPrimaryLoanType && primarySubLoanType === 1) {
      amount =
        ((primaryInterestOnlyLoan || 0) * curryingMonths || 0) +
        purchasePrice +
        (remodelCost || 0) +
        (primaryWholeSale || 0);
    } else {
      amount =
        (primaryMonthlyPaymentInterestOnly || 0) * curryingMonths +
        purchasePrice +
        (remodelCost || 0) +
        (primaryWholeSale || 0);
    }
  } else if (primaryLoanType === 4) {
    amount = purchasePrice + remodelCost + (primaryWholeSale || 0);
  }
  if (secondaryLoanIsTrue && primaryLoanType !== 4) {
    if (primaryLoanType === 0) {
      if (primaryLoanType === 0 && secondaryLoanSubType === 1) {
        amount +=
          (secondaryInterestOnly || 0) * curryingMonths +
          remodelCost +
          (secondaryDownPayment || 0);
      } else {
        amount +=
          (secondaryMonthlyAmountInterestOnly || 0) * curryingMonths +
          remodelCost;
      }
    }
    if (primaryLoanType === 1) {
      amount += remodelCost;
    }
  }

  return +amount.toFixed(2);
};

export const liquidExpensesAmount = (
  primaryInterestOnlyLoan,
  secondaryMonthlyAmount,
  primaryMonthlyPayment,
  primaryLoanType,
  primarySubLoanType,
  purchasePrice,
  remodelCost,
  secondaryLoanSubType,
  secondaryInterestOnly,
  curryingMonths,
  secondaryLoanIsTrue,
  mainPrimaryLoanType,
  primaryMortgageAmount,
  secondaryMortgageAmount,
  secondaryLoanType,
  primaryWholeSale
) => {
  // console.log({
  //   primaryInterestOnlyLoan,
  //   secondaryMonthlyAmount,
  //   primaryMonthlyPayment,
  //   primaryLoanType,
  //   primarySubLoanType,
  //   purchasePrice,
  //   remodelCost,
  //   secondaryLoanSubType,
  //   secondaryInterestOnly,
  //   curryingMonths,
  //   secondaryLoanIsTrue,
  //   mainPrimaryLoanType,
  //   primaryMortgageAmount,
  // secondaryMortgageAmount,
  //   secondaryLoanType,
  //   primaryWholeSale,
  // });

  let amount = 0;
  if (primaryLoanType === 0 || undefined) {
    if (mainPrimaryLoanType && primarySubLoanType === 1) {
      amount =
        (primaryInterestOnlyLoan || 0) * curryingMonths +
        (primaryMortgageAmount || 0);
    } else {
      amount = (primaryMonthlyPayment || 0) * curryingMonths;
    }
  } else if (primaryLoanType === 1) {
    if (mainPrimaryLoanType && primarySubLoanType === 1) {
      amount =
        ((primaryInterestOnlyLoan || 0) * curryingMonths || 0) +
        (primaryMortgageAmount || 0);
    } else {
      amount = (primaryMonthlyPayment || 0) * curryingMonths;
    }
  } else if (primaryLoanType === 2) {
    if (mainPrimaryLoanType && primarySubLoanType === 1) {
      amount =
        ((primaryInterestOnlyLoan || 0) * curryingMonths || 0) +
        (primaryMortgageAmount || 0);
    } else {
      amount = (primaryMonthlyPayment || 0) * curryingMonths;
    }
  } else if (primaryLoanType === 3) {
    if (mainPrimaryLoanType && primarySubLoanType === 1) {
      amount =
        ((primaryInterestOnlyLoan || 0) * curryingMonths || 0) +
        (purchasePrice || 0) +
        (primaryMortgageAmount || 0);
    } else {
      amount = (primaryMonthlyPayment || 0) * curryingMonths + purchasePrice;
    }
  } else if (primaryLoanType === 4) {
    amount = purchasePrice + remodelCost;
  }
  if (secondaryLoanIsTrue && primaryLoanType !== 4) {
    if (primaryLoanType === 0) {
      if (
        (primaryLoanType === 0 || undefined) &&
        secondaryLoanSubType === 1 &&
        secondaryLoanType === 1
      ) {
        amount +=
          (secondaryInterestOnly || 0) * curryingMonths +
          secondaryMortgageAmount;
      } else {
        amount += (secondaryMonthlyAmount || 0) * curryingMonths;
      }
    }
    if (primaryLoanType === 1) {
      amount += remodelCost;
    }
  }

  return +amount.toFixed(2);
};

export const resaleAmountForLiquidCapital = (
  purchaseAmount,
  purchaseOption,
  customTitleAndEscrowOnResaleBuyerPays
) => {
  let value;
  if (purchaseOption === 0 || undefined) {
    value = purchaseAmount;
  } else if (purchaseOption === 1) {
    value = 0;
  } else if (purchaseOption === 2) {
    value = purchaseAmount / 2;
  } else if (purchaseOption === 3) {
    value = customTitleAndEscrowOnResaleBuyerPays;
  } else if (purchaseOption === 4) {
    value = 0;
  }

  return value;
};

export const downPaymentCalculatorFunc = (baseAmount, checker, amount) => {
  //1
  return checker === 1 ? (+amount / 100) * baseAmount : +amount;
};

export const getLocalLenderPointAmount = (mortgageAmount, lenderPoints) => {
  return mortgageAmount * (lenderPoints / 100);
};
