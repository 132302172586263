import {
  Box,
  OutlinedInput,
  InputAdornment,
  Typography,
  FormControl,
} from "@mui/material";
import React from "react";
import { useTheme } from "@mui/material/styles";
import StyledLabel from "../atomicComponents/StyledLabel";
import commaSeparate from "../utils/commaSeparate";

const useStyles = (_theme) => {
  return {
    input: {
      display: "flex",
      flexDirection: "column",
      margin: "1.875rem 0",
    },
    root: {
      margin: "1.258rem",
    },
  };
};

function OtherCosts({ handleEvents, data }) {
  const theme = useTheme();
  const styles = useStyles(theme);

  const handleValues = (e) => {
    let value = e.target.value;
    if (value) {
      value = Number.parseInt(value.split(",").join(""));
      if (isNaN(value)) {
        return;
      }
      handleEvents({ [e.target.name]: value });
    } else {
      handleEvents({ [e.target.name]: value });
    }
  };

  return (
    <Box sx={styles.root}>
      {otherCostsStaticData.map((element, index) => (
        <Box>
          <Box sx={styles.input}>
            <StyledLabel label={element.label} />
            <FormControl
              sx={{
                minWidth: "18.75rem",
                marginRight: "5px",
              }}
            >
              {index === 1 && ""}
              {index === 0 && (
                <OutlinedInput
                  fullWidth
                  name="miscellaneous"
                  onChange={handleValues}
                  value={commaSeparate(data?.miscellaneous)}
                  placeholder="0"
                  margin="normal"
                  inputProps={{ style: { fontSize: "1rem" } }}
                  startAdornment={
                    <InputAdornment position="start">
                      {" "}
                      <Typography variant="adornment" color="secondary">
                        {" "}
                        $
                      </Typography>
                    </InputAdornment>
                  }
                />
              )}
            </FormControl>
            {element?.noticeLabel && (
              <Typography variant="h6" sx={{ marginTop: "1rem" }}>
                {element.noticeLabel}
              </Typography>
            )}
          </Box>
        </Box>
      ))}
      <Box>
        <Box sx={styles.input}>
          <StyledLabel label={"Monthly Utilities"} />
          <FormControl
            sx={{
              // width: "60rem",
              minWidth: "18.75rem",
              marginRight: "5px",
            }}
          >
            <OutlinedInput
              fullWidth
              name="utilities"
              onChange={handleValues}
              value={commaSeparate(data?.utilities)}
              placeholder="0"
              margin="normal"
              inputProps={{ style: { fontSize: "1rem" } }}
              startAdornment={
                <InputAdornment position="start">
                  {" "}
                  <Typography variant="adornment" color="secondary">
                    {" "}
                    $
                  </Typography>
                </InputAdornment>
              }
            />
          </FormControl>

          <Typography variant="h6" sx={{ marginTop: "1rem" }}>
            {""}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default OtherCosts;

const otherCostsStaticData = [{ label: "Miscellaneous" }];
