import { Button, Divider, Grid, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import Compensation from '../components/Compensation';
import ReportSummary from '../components/ReportSummary';
import Bubbles from '../images/bubbles.png';
import Grow from '../images/grow.png';
import YCircle from '../images/yellowCircle.jpg';

const useStyles = makeStyles((theme) => ({
	'@global': {
		'@keyframes resize': {
			from: {
				transform: 'scale(1)',
			},
			to: {
				transform: 'scale(1.05)',
			},
		},
	},

	root: {
		'& *': {
			fontFamily: 'Inter !important',
		},
	},
	upgradeBox: {
		borderRadius: '5px',
		boxShadow: '-10px 10px 20px #0000000d',

		height: '100%',
		'&>*': {
			fontFamily: 'Inter',
		},
		position: 'relative',
		overflow: 'hidden',
		'&::after': {
			backgroundSize: 'contain',
			backgroundPosition: 'right',
			backgroundRepeat: 'no-repeat',
			backgroundImage: `url(${Bubbles})`,
			content: "''",
			width: '43%',
			height: '100%',
			position: 'absolute',
			right: 0,
			zIndex: 2,
		},
	},
	buttonbase: {
		textTransform: 'capitalize',
		fontSize: 14,
		color: 'white',
		marginTop: '1rem',
		boxShadow: 'none',
		'&:hover': {
			boxShadow: 'none',
		},
	},
	smallBox: {
		position: 'relative',
		'&::before': {
			bottom: 0,
			position: 'absolute',
			backgroundColor: '#FFAA01',
			width: '70px',
			height: '8px',
			borderTopLeftRadius: '15px',
			borderTopRightRadius: '15px',
			content: "''",
		},
	},
	smallBox1: {
		position: 'relative',
		'&::before': {
			bottom: 0,
			position: 'absolute',
			backgroundColor: '#005EA9',
			width: '70px',
			height: '8px',
			borderTopLeftRadius: '15px',
			borderTopRightRadius: '15px',
			content: "''",
			left: '50%',
			transform: 'translateX(-50%)',
		},
	},
	pulser: {
		width: '79px',
		aspectRatio: '1/1',
		background: 'white',
		color: '#005EA9',
		borderRadius: '50%',
		position: 'relative',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		animation: 'resize 1.5s infinite alternate ',
		transformOrigin: 'center',

		fontSize: '46px',
		fontWeight: 700,
		'&::before': {
			position: 'absolute',
			content: "''",
			backgroundColor: '#ffffff26',
			zIndex: 10,
			borderRadius: '50%',
			width: '107px',
			aspectRatio: '1/1',
		},
	},
	baseCard: { borderRadius: '5px', boxShadow: '-10px 10px 20px 0px #0000000D', padding: '1rem', height: '100%' },
}));

const NewDashboard = () => {
	const classes = useStyles();

	return (
		<Grid padding='1rem' container spacing={2} className={classes.root}>
			<Grid item xs={6}>
				<UpgradeToolkit />
			</Grid>
			<Grid item xs={3}>
				<Compensation />
			</Grid>
			<Grid item xs={3}>
				<TrainingSession
					title={'BPO Homes Training'}
					joinLink='https://us06web.zoom.us/j/84873173703?pwd=ZW1FRnE4eHkyMVYvcy85RFFVcjhEZz09'
					calendarLink='https://us06web.zoom.us/meeting/tZAlcOqpqTgtGtei1105riYYZc8d0dgkSsuD/ics?icsToken=98tyKuGspjwpG9OWtxmERpwIGo_Cb-vwtnZajbdYlU60U3VBazrPDOEaNuJ7F9Pj'
				/>
			</Grid>
			<Grid item xs={4}>
				<ReportSummary />
			</Grid>
			<Grid item xs={2.5}>
				<HotDeals />
			</Grid>
			<Grid item xs={2.5}>
				<InstantDeals />
			</Grid>
			<Grid item xs={3}>
				<TrainingSession
					startColor='#6F42C1'
					endColor='#341F5B'
					joinLink='https://us06web.zoom.us/j/83123984545?pwd=QWtIck5IaG9BTmFycFhQSTM3dkRHZz09'
					calendarLink='https://us06web.zoom.us/meeting/tZcsdeqhpj8vHtGGQs98YOJDOGkSBhXo9huv/ics?icsToken=98tyKuGrrzkpE9yRtR2CRpwqBYjob_PxiClYgrd6uy72WnhqTSnSGcVJAZB3KNeJ'
					title='BPO TECH Training'
				/>
			</Grid>
			<Grid item xs={9}></Grid>
			<Grid item xs={3}>
				<Stack className={classes.baseCard} alignItems={'center'}>
					<img src={Grow} style={{ width: '100%', objectFit: 'cover' }} alt='Grow contacts' />
					<Typography textAlign={'center'} sx={{ textWrap: 'balance' }}>
						Grow your homeowner contacts with extra farms.
					</Typography>
					<Button variant='contained' className={classes.buttonbase} href='/available-territories'>
						Upgrade
					</Button>
				</Stack>
			</Grid>
		</Grid>
	);
};

export default NewDashboard;

const UpgradeToolkit = () => {
	const classes = useStyles();
	return (
		<Stack direction='row' justifyContent={'space-between'} className={classes.upgradeBox}>
			<Stack gap='1rem' p='1rem'>
				<Typography fontFamily='Inter' fontSize={20} fontWeight={700} color='#000D18'>
					Upgrade Your Toolkit
				</Typography>
				<Typography fontSize={14} color='#676767' fontFamily={'Inter'} sx={{ textWrap: 'balance' }}>
					Time to upgrade and amplify your investor reach.
					<br /> BPO's investor list holds the key to expanding your connections.
				</Typography>
				<Typography fontSize={14} color='#676767' fontFamily={'Inter'}>
					Upgrade with BPO's Investor list.
				</Typography>
			</Stack>
			<Stack sx={{ padding: '1rem', zIndex: 10 }}>
				<Typography fontSize={36} fontWeight={700} color='white'>
					$399.00
				</Typography>
				<Typography fontSize={14} color='white'>
					per Investor Group
				</Typography>
				<Button variant='contained' className={classes.buttonbase} sx={{ textWrap: 'nowrap' }} href='/investor-leads'>
					Close More Deals
				</Button>
			</Stack>
		</Stack>
	);
};

const TrainingSession = ({ title, joinLink, calendarLink, startColor = '#00796B', endColor = '#00C9B1' }) => {
	const classes = useStyles();

	return (
		<Stack
			sx={{
				width: '100%',
				height: '100%',
				color: 'white',
				background: `linear-gradient(112.46deg, ${startColor} 1.3%,${endColor} 97.52%)`,
				padding: '1rem',
				boxShadow: '-10px 10px 20px #0000000d',
				borderRadius: '5px',
			}}
			justifyContent={'space-between'}
		>
			<Stack>
				<Typography fontSize={18} fontWeight={700}>
					{title}
				</Typography>
				<Divider sx={{ width: '70px', backgroundColor: 'white' }} />
			</Stack>
			<Stack direction='row'>
				<Typography fontSize={14} fontWeight={300}>
					Days:&nbsp;
				</Typography>
				<Typography fontSize={14} fontWeight={500}>
					Wednesdays/Friday
				</Typography>
			</Stack>
			<Stack direction='row'>
				<Typography fontSize={14} fontWeight={300}>
					Time:&nbsp;
				</Typography>
				<Typography fontSize={14} fontWeight={500}>
					12:00 PM Pacific Time (US and Canada)
				</Typography>
			</Stack>
			<Stack direction='row' justifyContent={'space-between'}>
				<Button
					variant='contained'
					className={classes.buttonbase}
					disableElevation
					href={joinLink}
					disableRipple
					target='_blank'
					rel='noreferrer'
					sx={{ backgroundColor: 'white', color: '#4D4D4D !important', '&:hover': { backgroundColor: 'white' }, fontSize: '12px !important' }}
				>
					Join Meeting
				</Button>
				<Button
					variant='outlined'
					className={classes.buttonbase}
					href={calendarLink}
					target='_blank'
					rel='noreferrer'
					sx={{
						backgroundColor: 'transparent',
						color: 'white',
						borderColor: 'currentcolor',
						'&:hover': {
							borderColor: 'currentColor',
						},
						fontSize: '12px !important',
					}}
				>
					Add To Calendar
				</Button>
			</Stack>
		</Stack>
	);
};

const HotDeals = () => {
	const classes = useStyles();
	return (
		<Stack className={`${classes.baseCard} ${classes.smallBox1}`} justifyContent={'space-between'}>
			<Typography fontSize={24} fontWeight={700} color='#005EA9' sx={{ textWrap: 'balance' }}>
				Access Hot Leads and Close Deals Faster.
			</Typography>
			<Typography fontSize={12} sx={{ textWrap: 'balance' }} mb={'1rem'}>
				Elevate Your Sales Game with Our Lead Generation Powerhouse!
			</Typography>
		</Stack>
	);
};

const InstantDeals = () => {
	const classes = useStyles();
	return (
		<Stack
			className={classes.baseCard}
			justifyContent={'space-between'}
			sx={{ backgroundImage: `url(${YCircle})`, backgroundSize: 'contain', backgroundPosition: 'bottom left', backgroundRepeat: 'no-repeat' }}
		>
			<Typography color='black' fontSize={20}>
				Instantly Assess Deals and Optimize Your Real Estate Deals Today!
			</Typography>
			<Button
				variant='contained'
				className={classes.buttonbase}
				color='secondary'
				sx={{ width: 'fit-content', padding: '12px 20px' }}
				href='analyze-deal'
			>
				Analyze it
			</Button>
		</Stack>
	);
};
