import React from 'react';
import './modal.scss';
import { createPortal } from 'react-dom';
const Modal = ({ children, open, onClose, style, overlay = true }) => {
	if (!open) return null;
	return createPortal(
		<>
			{overlay && <div className='overlay' onClick={onClose} />}
			<div className='content' style={style}>
				{children}
			</div>
		</>,
		document.getElementById('portal')
	);
};

export default Modal;
