import { useDispatch } from 'react-redux'
import { addToMessageQueue } from '../../../actions/dealAnalyzer'

function useCreateAlert() {
    const dipatch = useDispatch()

    const createAlert = (message,severity)=>{
        dipatch(addToMessageQueue([{message,severity}]))
    }

  return {
    createAlert
  }
}

export default useCreateAlert