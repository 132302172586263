export const sarLoanAmount = ({
  purchasePrice,
  pmi_value,
  lenderPoints,
  pmi,
  wholesaleFee,
  downPayment,
  downPaymentRate,
  lenderpointOption,
  wholesaleFeeOption,
}) => {
  const pmiPercent = pmi && pmi_value ? +pmi_value : 0;

  lenderPoints = !lenderPoints || +lenderpointOption >= 1 ? 0 : lenderPoints;
  wholesaleFee = +wholesaleFeeOption >= 1 ? 0 : wholesaleFee;
  const allVars = [purchasePrice, lenderPoints, wholesaleFee, downPayment];
  const loanVars = [purchasePrice, wholesaleFee];

  if (allVars.every((element) => !isNaN(parseInt(element)))) {
    downPayment =
      !downPaymentRate || +downPaymentRate === 0
        ? downPayment
        : purchasePrice * (+downPayment / 100);

    const sum = loanVars.reduce((a, b) => a + +b);
    const effectiveLoan = sum - +downPayment;
    const effectiveLoanWithPmi = (+pmiPercent / 100) * effectiveLoan;

    return {
      monthlyPMI: effectiveLoanWithPmi / 12,
      effectiveLoan: effectiveLoan + effectiveLoan * (+lenderPoints / 100),
    };
  }
};

// the lender points is one percent
