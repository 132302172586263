import {
  FormControl,
  InputAdornment,
  Box,
  Typography,
  OutlinedInput,
  Button,
  Grid,
  Divider,
} from "@mui/material";
import StyledLabel from "./StyledLabel";
import React from "react";
import useTheme from "@mui/material/styles/useTheme";
import { ClipLoader } from "react-spinners";
import useProformaData from "../hooks/dealReporthooks/useProformaData";
import commaSeparate from "../utils/commaSeparate";
import { greyout } from "../utils/greyout";
import { preventMinus } from "../utils/preventNegativeValues";

const useStyle = (_theme) => {
  return {
    interest: {
      display: "flex",
      flexDirection: "column",
      marginTop: "1.25rem",
      marginRight: "3.125rem",
      width: "45%",
    },
    term: {
      display: "flex",
      flexDirection: "column",
      marginTop: "1.25rem",
      marginRight: "3.125rem",
      width: "50%",
    },
    interestTerm: {
      display: "flex",
    },
    button: {
      width: "100%",
      margin: "1.25rem 0",
      padding: "1rem",
    },
  };
};

function InterestTermResult({
  handler,
  prefixName,
  monthlyPayment,
  data,
  beforeFetchResult,
}) {
  const handleValues = (e) => {
    handler({ [e.target.name]: e.target.value });
  };
  const { loading, fetchResult } = useProformaData();

  const submit = () => {
    beforeFetchResult();
    fetchResult();
  };

  const theme = useTheme();
  const styles = useStyle(theme);
  return (
    <Box>
      <Box sx={{ mt: 2 }} />
      <Grid container columns={24}>
        <Grid item sm={24} md={11}>
          <Box>
            <StyledLabel label="Interest rate" />
          </Box>
          <FormControl
            sx={{
              minWidth: "18.75rem",
            }}
          >
            <OutlinedInput
              fullWidth
              name={`${prefixName}_interest_rate`}
              value={data?.[`${prefixName}_interest_rate`]}
              onChange={handleValues}
              margin="normal"
              onKeyPress={preventMinus}
              inputProps={{ style: { fontSize: "1rem" }, min: 0 }}
              type="number"
              endAdornment={
                <InputAdornment position="start">
                  {" "}
                  <Typography variant="adornment" color="secondary">
                    %
                  </Typography>
                </InputAdornment>
              }
            />
          </FormControl>
        </Grid>
        <Grid item sm={0} md={2} />
        <Grid item sm={24} md={11}>
          <Box>
            <StyledLabel label="Term of loan" />
          </Box>
          <FormControl
            sx={{
              width: "100%",
            }}
          >
            <OutlinedInput
              fullWidth
              name={`${prefixName}_term_of_loan`}
              value={data?.[`${prefixName}_term_of_loan`]}
              onChange={handleValues}
              margin="normal"
              onKeyPress={preventMinus}
              inputProps={{ style: { fontSize: "1rem" }, min: 0 }}
              type="number"
              endAdornment={
                <InputAdornment position="start">
                  {" "}
                  <Typography variant="adornment" color="secondary">
                    {" "}
                    Years
                  </Typography>
                </InputAdornment>
              }
            />
          </FormControl>
        </Grid>
      </Grid>
      <Box>
        {
          <Divider
            variant="fullWidth"
            sx={{ height: "5px", marginTop: "40px" }}
          />
        }
        <>
          {" "}
          {!monthlyPayment && (
            <Button sx={styles.button} variant="contained" onClick={submit}>
              {loading && (
                <ClipLoader color={"#fff"} loading={true} size={16} />
              )}
              {!loading && <Typography variant="h5">CALCULATE</Typography>}
            </Button>
          )}
        </>
      </Box>
      <Box>
        <Box>
          <StyledLabel label="Total Monthly Payment" />
        </Box>
        <FormControl
          sx={{
            minWidth: "18.75rem",
            marginTop: "5px",
            marginBottom: "10px",
            width: "100%",
          }}
        >
          <OutlinedInput
            fullWidth
            value={monthlyPayment ? commaSeparate(monthlyPayment) : 0}
            sx={{ ...greyout }}
            margin="normal"
            onKeyPress={preventMinus}
            inputProps={{ style: { fontSize: "1rem" }, min: 0 }}
            startAdornment={
              <InputAdornment position="start">
                {" "}
                <Typography variant="adornment" color="secondary">
                  {" "}
                  $
                </Typography>
              </InputAdornment>
            }
          />
        </FormControl>
      </Box>
    </Box>
  );
}

export default InterestTermResult;
