export const splitToThree = (arr)=>{
    if(arr){
    const arrayLength = arr.length
    const mid1 = Math.ceil(arrayLength/3)
    const midRounded = Math.floor(arrayLength/3)
    const result1 = arr.slice(0,(mid1 ))
    const result2 = arr.slice(mid1,mid1+midRounded)
    const result3 = arr.slice(mid1+midRounded)
    return {result1,result2,result3}
    }
}