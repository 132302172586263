import { CircularProgress, Dialog, DialogContent, Stack } from '@mui/material';
import React from 'react';

const LoadingDialog = ({ isLoading }) => (
	<Dialog open={isLoading} maxWidth='sm' fullWidth>
		<DialogContent>
			<Stack alignItems='center' justifyContent='center' p='1rem'>
				<CircularProgress color='secondary' />
			</Stack>
		</DialogContent>
	</Dialog>
);

export default LoadingDialog;
