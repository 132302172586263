// let id = 1;
export const generateField = (len) => {
  let arr = [];
  // generate random forms based on names
  // use redux to dispatch their names and values
  // get values and save and use for whatever you want
  let i = 0;

  for (i; i < len; i++) {
    let fieldCreator = [
      { name: `additonal_sqft${i}`, value: "" },
      { name: `pre_existing_living_square_ft_cost${i}`, value: "" },
    ];
    arr.push(fieldCreator);
  }

  return arr;
};

// export const multiplyArrays = (arr1 = [], arr2 = []) => {
//   console.log(arr1, arr2)
//   if (!arr1.length || !arr2.length) return;
//   const indexMultiplications = arr1?.map((item, index) => {
//     return { value: item?.value * arr2[index]?.value };
//   });
//   const totalSum = indexMultiplications?.reduce((a, b) => a + b?.value, 0);

//   return { indexMultiplications, totalSum };
// };

const sumSubarrays = (array = []) => {
  let additional_sqrft = 0;
  let preExistLivable = 0;

  array.forEach(subarray => {
    if (subarray[0]) {
      additional_sqrft += subarray[0].value || 0;
    }
    if (subarray[1]) {
      preExistLivable += subarray[1].value || 0;
    }
  });
  return { additional_sqrft, preExistLivable };
};
export const sumSqFtArrays = (array = []) => {
  let additional_sqrft = 0;
  let preExistLivable = 0;

  array?.forEach(subarray => {


    if (subarray[2]) {
      additional_sqrft += +subarray[2]?.value || 0;
    }
    if (subarray[3]) {
      preExistLivable += +subarray[3]?.value || 0;
    }
  });
  const totalSquareFootageAfterRemodel = additional_sqrft + preExistLivable
  return { totalSquareFootageAfterRemodel };
};


export const multiplyArrays = (arr1 = [], arr2 = []) => {

  if (!arr1.length || !arr2.length) return;
  const indexMultiplications = arr1.map((item, index) => {
    const [subItem1, subItem2] = [item[2], item[3]];
    const [arr2Item1, arr2Item2] = arr2[index] || [];

    return [
      { name: subItem1?.name, value: subItem1?.value * arr2Item1?.value || 0 },
      { name: subItem2?.name, value: subItem2?.value * arr2Item2?.value || 0 }
    ];
  });

  const totalSum = sumSubarrays(indexMultiplications)

  return { indexMultiplications, totalSum };
};














export const multiplyArraysRentalINcome = (
  arr1 = [],
  months,
  vacancy_months
) => {
  if (!arr1.length) return;
  const indexMultiplications = arr1?.map((item, index) => {
    return { value: item?.value * vacancy_months };
  });


  const totalSum = indexMultiplications?.reduce((a, b) => a + b?.value, 0);
  const indexMultiplicationsProfit = arr1?.map((item, index) => {
    return { value: item?.value * months };
  });
  const totalSumProfit = indexMultiplicationsProfit?.reduce(
    (a, b) => a + b?.value,
    0
  );

  return {
    indexMultiplications,
    totalSum,
    totalSumProfit,
    indexMultiplicationsProfit,
  };
};


