import { InputLabel, TextField } from '@mui/material';
import React from 'react';

function InputWithLabel({ name, placeholder, label, value, onChange, ...rest }) {
	return (
		<>
			<InputLabel sx={{ fontSize: '14px' }}>{label}</InputLabel>
			<TextField placeholder={placeholder || label} fullWidth name={name} value={value} onChange={onChange} {...rest} size='small' />
		</>
	);
}

export default InputWithLabel;
