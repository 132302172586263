import React, { useEffect, useState } from "react";
import cssStyles from "../dealAnalyzer.module.scss";
import MuiThemeLayout from "../../../Layouts/MuiThemeLayout";
import {
  Container,
  Divider,
  Box,
  Snackbar,
  Alert,
  Typography,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { straightAestheticRemodelArray } from "./straightAestheticRemodelComponentsArray";

import FiniteLinearProgressBar from "../../../components/DealAnalyzerComponents/FiniteLinearProgressBar";
import { aestheticRemodelMenu } from "./componentData";
import CardMainHeader from "../../../components/DealAnalyzerComponents/CardMainHeader";
import CardMainArea from "../../../components/DealAnalyzerComponents/CardMainArea";
import { useSearchParams, useNavigate } from "react-router-dom";
import base from "../../../apis";
import * as actionCreators from "../../../actions/dealAnalyzer/index";
import { headerData } from "../analyzerTypesStaticData";
import { toggleSidebar } from "../../../actions";
import { subMenus } from "./componentData";
import useProformaData from "../../../components/DealAnalyzerComponents/hooks/dealReporthooks/useProformaData";
import { investorProfitMiddleware } from "../../../reducer/dealAnalyzerReducers/straightAestheticRemodelReducer";
import { StraightAestheticRemodelCalculations } from "../../../components/DealAnalyzerComponents/localCalcutions/StraightAestheticRemodelCalculations";
import { SecondaryStraightAstheticRemodelCalcs } from "../../../components/DealAnalyzerComponents/localCalcutions/SecondaryStraightAstheticRemodelCalcs";

function StraightAestheticRemodel() {
  const [active, setActive] = useState(0);
  const navigate = useNavigate();

  const straightAestheticRemodelData = useSelector(
    (state) => state.straightAestheticRemodel
  );

  const currentMessage = straightAestheticRemodelData?.messageQueue?.[0];
  const dispatch = useDispatch();
  const timeTrigger = () => {
    setTimeNow(() => new Date());
  };

  const components = straightAestheticRemodelArray(
    dispatch,
    straightAestheticRemodelData,
    timeTrigger
  );

  // ;

  const [searchParams] = useSearchParams();
  const propertyAddress =
    searchParams.get("address") ||
    straightAestheticRemodelData?.propertyInformation?.property_address;
  const [timeNow, setTimeNow] = useState(new Date());
  const { fetchResult } = useProformaData();

  const straightAestheticRemodelProperties = [
    { property_information: straightAestheticRemodelData.propertyInformation },
    { remodel_cost: straightAestheticRemodelData.remodelCosts },
    { finance_options: straightAestheticRemodelData.financeOptions },
    {
      property_permit_fees: straightAestheticRemodelData.propertyPermitAndFees,
    },
    { taxes: straightAestheticRemodelData.taxes },
    { rental_income: straightAestheticRemodelData.rentalIncome },
    { property_management: straightAestheticRemodelData.propertyManagement },
    { other_costs: straightAestheticRemodelData.otherCosts },
    { carrying_costs: straightAestheticRemodelData.carryingMonths },
    { "": "" },
    { closing_cost: straightAestheticRemodelData.closingCosts },
    {
      investor_profit: investorProfitMiddleware({
        ...straightAestheticRemodelData.investorProfit,
        investment_values: straightAestheticRemodelData.investorProfitItems,
      }),
    },
    { summary_text: straightAestheticRemodelData.summaryText },
  ];

  //console.log(straightAestheticRemodelProperties);

  const setActiveTab = (value) => {
    handleSave(active);
    setActive(value);
  };

  const handleSave = async (index) => {
    const reportId = straightAestheticRemodelData?.dealAnalyzerReportId;
    try {
      await base.put(
        `/deal_analyzer/straight_model`,
        straightAestheticRemodelProperties[+index],
        { params: { id: reportId.id } }
      );
      await fetchResult();
    } catch (error) {}
  };
  const next = () => {
    const prevActive = active;
    if (prevActive < components.length) {
      handleSave(active);
      setActive(prevActive + 1);
    } else {
      generateReport();
    }
  };
  const previous = () => {
    const prevActive = active;
    if (prevActive > 0) {
      handleSave(active);
      setActive(prevActive - 1);
    }
  };

  const control = { previous, next };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(actionCreators.updateMessageQueue());
  };
  const generateReport = () => {
    const reportId = straightAestheticRemodelData?.dealAnalyzerReportId;
    navigate(
      `/dealanalyzer-reports/straight-aesthetic-remodel?id=${reportId.id}`
    );
  };

  useEffect(() => {
    propertyAddress &&
      dispatch(
        actionCreators.updatePropertyInformation({
          property_address: propertyAddress,
        })
      );
    dispatch(toggleSidebar());
    // NOTE: Run effect once on component mount, please
    // recheck dependencies if effect is updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // console.log(components[+active].data);

  return (
    <div>
      <MuiThemeLayout>
        <div className={cssStyles.root}>
          <>
            <StraightAestheticRemodelCalculations />
            <SecondaryStraightAstheticRemodelCalcs />
            <Container maxWidth="lg">
              <Box sx={{ margin: "0 0rem" }}>
                <FiniteLinearProgressBar
                  menuArray={aestheticRemodelMenu}
                  active={+active}
                />
                <CardMainHeader
                  analyzerType={headerData.straightAestheticRemodel.title}
                  icon={headerData.straightAestheticRemodel.icon}
                  address={propertyAddress}
                  updateTime={timeNow}
                />
              </Box>
            </Container>
            <Container maxWidth="xl">
              <Divider
                light={true}
                // variant="middle"
                sx={{
                  height: "1px",
                  mt: 2,
                  backgroundColor: "#C4c4c4",
                }}
              />
            </Container>

            <Container maxWidth="lg">
              <Box sx={{ margin: "0 0rem" }}>
                {/*  the card Main Area receives data and 
              active component from the components array which then displayed   component and pass it to the  */}

                <CardMainArea
                  menuArray={aestheticRemodelMenu}
                  setActiveTab={setActiveTab}
                  control={control}
                  data={components[+active].data}
                  active={active}
                  component={components[+active].component}
                  handleEvents={components[+active].handler}
                  handleSave={handleSave}
                  generateReport={generateReport}
                  subMenus={subMenus}
                />
              </Box>
            </Container>
          </>

          <Snackbar
            open={!!currentMessage?.message}
            autoHideDuration={4000}
            onClose={handleClose}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert
              onClose={handleClose}
              severity={currentMessage?.["severity"]}
            >
              <Typography variant="h4">{currentMessage?.message}</Typography>
            </Alert>
          </Snackbar>
        </div>
      </MuiThemeLayout>
    </div>
  );
}

export default StraightAestheticRemodel;
