import {
	ArrowCircleDown,
	ArrowCircleUp,
	CheckCircle,
	Close,
	Create,
	Delete,
	Language,
	LocalOffer,
	Person2Outlined,
	PlaylistRemove,
	Search,
	Tune,
} from '@mui/icons-material';
import {
	Box,
	Button,
	Checkbox,
	Chip,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	FormControl,
	FormControlLabel,
	FormGroup,
	FormLabel,
	IconButton,
	InputAdornment,
	InputLabel,
	Pagination,
	Popover,
	Radio,
	RadioGroup,
	Skeleton,
	Stack,
	Tab,
	Tabs,
	TextField,
	Tooltip,
	Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import base, { file as fileUploader } from '../../apis';
import PlacesSearch from '../../components/PlacesSearch/PlacesSearch';
import Spinner from '../../components/Spinner/Spinner';
import { commaReplace, formatNumber, formatPhoneNumber, validateEmail } from '../../utils/functions';
import Add from '@mui/icons-material/Add';
import InputWithLabel from '../../components/InputWithLabel';
import { investorTypes } from '../../constants';
import { useDispatch, useSelector } from 'react-redux';
import { Elements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { LoadingButton } from '@mui/lab';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const useStyles = makeStyles((theme) => ({
	title: {
		color: 'grey',
		fontSize: '14px',
	},
	answer: {
		fontSize: '16px',
	},
	typoHeader: {
		fontSize: '16px',
		color: theme.palette.secondary.main,
		borderBottom: `1px solid #f0f0f0`,
		marginBottom: '1rem',
	},
	popTitle: {
		fontSize: '14px',
		fontWeight: 'bold',
	},
}));

const headers = [
	{ label: 'Investor Name', key: 'investor_name', flex: 3 },
	{ label: 'Liquid Capital', key: 'how_much_liquid_capital', flex: 2 },
	{ label: 'Areas of Focus', key: 'areas_of_focus', flex: 3 },
	{ label: 'Investment Types', key: 'type_of_investments', flex: 2 },
	{ label: '', key: 'fe', flex: 1 },
];

const paidHeaders = [
	{ label: 'Investor Name', key: 'investor_name', flex: 3 },
	{ label: 'Liquid Capital', key: 'how_much_liquid_capital', flex: 2 },
	{ label: 'Areas of Focus', key: 'areas_of_focus', flex: 3 },
	{ label: 'Last Know Investment', key: 'last_known_investment', flex: 2 },
	{ label: 'Phone', key: 'phone_number', flex: 1 },
	{ label: 'Investment Types', key: 'type_of_investments', flex: 2 },
	{ label: '', key: 'fe', flex: 1 },
];

const typesOfInvestments = ['Flips', 'Developments', 'Home Additions', 'Rentals'];
const location = ['Northern California', 'Central California', 'Southern California'];
const initialDetailsState = {
	how_much_liquid_capital: '',
	area_of_focus: '',
	areas_of_focus: [],
	use_hard_money_to_fund: false,
	is_wholesaler: false,
	investing_years: '',
	is_accompanied_with_a_wholesale: true,
	is_finder_fee_buyer_commission: true,
	is_relisted_by_my_brokerage: true,
	investor_name: '',
	investor_types: [],
	phone_number: '',
	email: '',
	address: '',
};

const counties = ['Santa Clara', 'Alameda', 'Contra Costa'];

const tabs = [
	{
		value: 'bpo_list',
		name: 'BPO List',
		Icon: LocalOffer,
	},
	{
		Icon: Person2Outlined,
		value: 'website',
		name: 'Website List',
	},
	{
		Icon: Language,
		value: 'csv import',
		name: 'Imported List',
	},
];

function AgentInvestorLeads() {
	const classes = useStyles();

	const [isLoading, setIsLoading] = useState(false);
	const [leadsData, setLeadsData] = useState({
		page: 1,
		count: 0,
		results: [],
	});
	const [details, setDetails] = useState(initialDetailsState);

	const getLeads = useCallback(
		async ({ loader = true, ...searchParams }) => {
			const params = {
				source__icontains: searchParams.source || 'website',
			};
			if (searchParams?.use_hard_money_to_fund !== null) params['use_hard_money_to_fund__icontains'] = searchParams?.use_hard_money_to_fund;
			if (searchParams?.is_wholesaler !== null) params['is_wholesaler__icontains'] = searchParams?.is_wholesaler;
			if (searchParams?.min_liquid_capital) params['how_much_liquid_capital_min'] = searchParams?.min_liquid_capital;
			if (searchParams?.max_liquid_capital) params['how_much_liquid_capital_max'] = searchParams?.max_liquid_capital;
			if (searchParams?.area_of_focus?.length) params['areas_of_focus__icontains'] = searchParams?.area_of_focus.join(',');
			if (searchParams?.types_of_investment?.length) params['type_of_investments'] = searchParams?.types_of_investment.join(',');
			if (searchParams?.page) params['page'] = searchParams?.page;
			if (searchParams?.search) params['address__icontains'] = searchParams?.search;
			try {
				setIsLoading(loader);

				const { data } = await base.get('investors/list-investors/', {
					params,
				});
				setLeadsData((prev) => ({
					...prev,
					count: data.count,
					results: data.results,
					page: searchParams?.page || 1,
				}));
				if (searchParams.source === 'bpo_list' && (searchParams?.page === 1 || !searchParams?.page) && data.count === 0) {
					setAdModal(true);
				}
			} catch (error) {
				toast.warn('Could not fetch leads');
			} finally {
				setIsLoading(false);
			}
		},
		[setIsLoading]
	);

	const handlePageChange = async (event, value) => {
		setLeadsData((p) => ({ ...p, page: value }));
		getLeads({ page: value, ...filters });
	};

	const [selectedLead, setSelectedLead] = useState(null);
	const [detailsModalOpen, setDetailsModalOpen] = useState(false);
	const [addNewOpen, setAddNewOpen] = useState(false);
	const [filters, setFilters] = useState({
		search: '',
		hardMoney: null,
		wholesaler: null,
		investmentTimeframe: '',
		typesOfInvestments: [],
		min: '',
		max: '',
		location: [],
		source: 'website',
	});

	const [filtersBackup, setFiltersBackup] = useState({
		search: '',
		hardMoney: null,
		wholesaler: null,
		investmentTimeframe: '',
		typesOfInvestments: [],
		min: '',
		max: '',
		location: [],
	});

	useEffect(() => {
		getLeads({});
	}, [getLeads]);

	const getToolLabel = (type) => {
		if (type === 'csv import')
			return (
				<Tooltip title='Imported from file'>
					<ArrowCircleDown fontSize='small' sx={{ fill: 'grey' }} />
				</Tooltip>
			);
		if (type === 'website')
			return (
				<Tooltip title='From Investor Website'>
					<Language fontSize='small' sx={{ fill: 'grey' }} />
				</Tooltip>
			);
		if (type === 'bpo_list') {
			return (
				<Tooltip title='From BPO Paid Investor List'>
					<LocalOffer fontSize='small' sx={{ fill: 'grey' }} />
				</Tooltip>
			);
		}
		return null;
	};

	const handleCheckboxChange = (name, value, checked) => {
		setFilters((prev) => {
			const toChange = prev[name];
			if (checked) {
				toChange.push(value);
			} else {
				toChange.splice(toChange.indexOf(value), 1);
			}
			return { ...prev, [name]: toChange };
		});
	};

	const [uploadModal, setUploadModal] = useState(false);
	const imageSelector = React.useRef(null);

	const handleFilter = () => {
		const params = {};
		if (filters?.hardMoney) params['use_hard_money_to_fund'] = filters?.hardMoney;
		if (filters?.wholesaler) params['is_wholesaler'] = filters?.wholesaler;
		if (filters?.min) params['min_liquid_capital'] = filters?.min;
		if (filters?.max) params['max_liquid_capital'] = filters?.max;
		if (filters?.location.length) params['area_of_focus'] = filters?.location;
		if (filters?.typesOfInvestments.length) params['type_of_investments'] = filters?.typesOfInvestments;
		if (filters?.investmentTimeframe) params['investing_years'] = filters?.investmentTimeframe;
		if (filters?.search) params['search'] = filters?.search;
		if (filters.source) params['source'] = filters.source;

		getLeads({ page: 1, ...params });
		setAnchorEl(null);
	};

	const handleCancelFilter = () => {
		setAnchorEl(null);
		setFilters(filtersBackup);
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const onFind = React.useCallback(
		debounce((obj) => {
			getLeads(obj);
		}, 500),
		[]
	);

	const handleSearchFilter = (val) => {
		setFilters((p) => ({ ...p, search: val }));
		onFind({ ...filters, search: val, loader: false });
	};

	const handleChange = (event, newValue) => {
		getLeads({
			page: 1,
			source: newValue,
		});
		setFilters((p) => ({
			search: '',
			hardMoney: null,
			wholesaler: null,
			investmentTimeframe: '',
			typesOfInvestments: [],
			min: '',
			max: '',
			location: [],
			source: newValue,
		}));
	};

	const handleTextChange = (e) => {
		const { name, value } = e.target;
		setDetails((prev) => ({ ...prev, [name]: value }));
	};

	const handleCheckChange = (e, checked, parent) => {
		const { name } = e.target;
		if (checked) {
			setDetails((prev) => ({ ...prev, [parent]: [...prev[parent], name] }));
		} else {
			setDetails((prev) => ({ ...prev, [parent]: prev[parent].filter((item) => item !== name) }));
		}
	};

	const handleBoolChange = (e) => {
		const { name, value } = e.target;
		setDetails((prev) => ({ ...prev, [name]: value }));
	};

	const setImage = async (e) => {
		const { files } = e.target;
		const file = files?.[0];
		if (file) {
			setIsLoading(true);
			let formData = new FormData();
			formData.append('csv_file', file);
			try {
				setIsLoading(true);
				await fileUploader.post('investors/import-csv/', formData);
				getLeads({ page: 1, ...filters });
				setUploadModal(false);
			} catch (error) {
				toast.warn('Could not upload file');
			} finally {
				setIsLoading(false);
			}
		}
	};

	const handleExport = async () => {
		const params = new URLSearchParams();
		if (filters?.hardMoney) params.append('use_hard_money_to_fund', filters?.hardMoney);
		if (filters?.wholesaler) params.append('is_wholesaler', filters?.wholesaler);
		if (filters?.min) params.append('min_liquid_capital', filters?.min);
		if (filters?.max) params.append('max_liquid_capital', filters?.max);
		if (filters?.location.length) params.append('area_of_focus', filters?.location);
		if (filters?.typesOfInvestments.length) params.append('type_of_investments', filters?.typesOfInvestments);
		if (filters?.investmentTimeframe) params.append('investing_years', filters?.investmentTimeframe);
		if (filters?.search) params.append('search', filters?.search);
		if (filters.source?.length) params.append('source', filters.source);
		const url = `investors/export-csv/`;
		try {
			setIsLoading(true);
			const { data } = await base.get(url, {
				params: {
					...Object.fromEntries(params),
				},
			});
			const csvBlob = new Blob([data], { type: 'text/csv' });
			const csvURL = URL.createObjectURL(csvBlob);

			const downloadLink = document.createElement('a');
			downloadLink.href = csvURL;
			downloadLink.download = 'investor-list-data.csv';
			downloadLink.click();
		} catch (error) {
			toast.warn('Could not export data');
		} finally {
			setIsLoading(false);
		}
	};

	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);

	const handleClose = (e) => {
		setAnchorEl(null);
	};

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
		setFiltersBackup(JSON.parse(JSON.stringify(filters)));
	};

	const onAddNewInvestor = () => {
		setAddNewOpen(true);
		setStep(1);
		setDetails(initialDetailsState);
	};

	const [step, setStep] = useState(1);

	const checkFields = () => {
		if (details.investor_name === '') {
			toast.warn('Please enter your name');
			return;
		}
		if (details.phone_number === '') {
			toast.warn('Please enter your phone number');
			return;
		}
		if (!validateEmail(details.email)) {
			toast.warn('Please enter a valid email');
			return;
		}
		if (details.address === '') {
			toast.warn('Please enter your address');
			return;
		}
		if (details.how_much_liquid_capital === '') {
			toast.warn('Please enter your liquid capital');
			return;
		}
		if (!details.areas_of_focus.length) {
			toast.warn('Please select at least one area of focus');
			return;
		}
		if (!details.investing_years) {
			toast.warn('Please enter your investing years');
			return;
		}

		setStep(2);
	};

	const getContent = () => {
		if (step === 1) {
			return (
				<>
					<InputWithLabel
						label='Available Investment Capital'
						placeholder='Enter amount'
						value={commaReplace(details.how_much_liquid_capital)}
						fontSize='14px'
						name='how_much_liquid_capital'
						onChange={handleTextChange}
						InputProps={{
							startAdornment: (
								<InputAdornment position='end' sx={{ '&>p': { color: 'black !important' }, marginRight: '5px' }}>
									$
								</InputAdornment>
							),
						}}
					/>
					<FormControl>
						<FormLabel sx={{ fontSize: '14px' }}>What are the areas of focus?</FormLabel>
						<FormGroup sx={{ display: 'flex', gap: '10px', flexDirection: 'row' }}>
							{['Northern California', 'Central California', 'Southern California'].map((item) => (
								<FormControlLabel
									sx={{ fontSize: '14px' }}
									key={item}
									control={
										<Checkbox
											checked={details.areas_of_focus.includes(item)}
											onChange={(e, checked) => handleCheckChange(e, checked, 'areas_of_focus')}
											size='small'
											color='secondary'
										/>
									}
									label={item}
									name={item}
								/>
							))}
						</FormGroup>
					</FormControl>
					<InputWithLabel
						label='How long has the investor been investing?'
						placeholder='Enter number of years'
						value={commaReplace(details.investing_years)}
						name='investing_years'
						onChange={handleTextChange}
					/>
					<FormControl sx={{ flex: 1 }}>
						<FormLabel sx={{ fontSize: '14px' }}>Is the investor funding with hard money?</FormLabel>
						<RadioGroup row name='use_hard_money_to_fund' value={details.use_hard_money_to_fund} onChange={handleBoolChange}>
							<FormControlLabel
								value={true}
								control={<Radio color='secondary' size='small' />}
								label={<span style={{ fontSize: '14px' }}>Yes</span>}
							/>
							<FormControlLabel
								value={false}
								control={<Radio color='secondary' size='small' />}
								label={<span style={{ fontSize: '14px' }}>No</span>}
							/>
						</RadioGroup>
					</FormControl>
					<FormControl sx={{ flex: 1 }}>
						<FormLabel sx={{ fontSize: '14px' }}>Is the investor a wholesaler?</FormLabel>
						<RadioGroup row name='is_wholesaler' value={details.is_wholesaler} onChange={handleBoolChange}>
							<FormControlLabel
								value={true}
								control={<Radio color='secondary' size='small' />}
								label={<span style={{ fontSize: '14px' }}>Yes</span>}
							/>
							<FormControlLabel
								value={false}
								control={<Radio color='secondary' size='small' />}
								label={<span style={{ fontSize: '14px' }}>No</span>}
							/>
						</RadioGroup>
					</FormControl>
				</>
			);
		} else {
			return (
				<>
					<FormControl>
						<FormLabel sx={{ fontSize: '16px' }}>What types of investment does the investor do?</FormLabel>
						<FormGroup sx={{ display: 'flex', gap: '1rem', flexDirection: 'row' }}>
							{investorTypes.map(({ name, label }) => (
								<FormControlLabel
									key={name}
									control={
										<Checkbox
											checked={details.investor_types.includes(name)}
											onChange={(e, checked) => handleCheckChange(e, checked, 'investor_types')}
											size='small'
											color='secondary'
										/>
									}
									label={label}
									name={name}
								/>
							))}
						</FormGroup>
					</FormControl>
					<FormControl sx={{ marginBottom: '1rem' }}>
						<FormLabel sx={{ fontSize: '16px' }}>
							<Typography variant='body1' sx={{ fontSize: 'inherit', marginBottom: '10px' }}>
								Is the investor comfortable paying a wholesale if there's enough margin in the deal?
							</Typography>
						</FormLabel>
						<RadioGroup row name='is_accompanied_with_a_wholesale' value={details.is_accompanied_with_a_wholesale} onChange={handleTextChange}>
							<FormControlLabel
								value={true}
								control={<Radio color='secondary' size='small' />}
								label={<span style={{ fontSize: '16px' }}>Yes</span>}
							/>
							<FormControlLabel
								value={false}
								control={<Radio color='secondary' size='small' />}
								label={<span style={{ fontSize: '16px' }}>No</span>}
							/>
						</RadioGroup>
					</FormControl>
					<FormControl sx={{ marginBottom: '1rem' }}>
						<FormLabel sx={{ fontSize: '16px' }}>
							<Typography variant='body1' sx={{ fontSize: 'inherit', marginBottom: '10px' }}>
								Does the investor agree to paying a 1% Finder Fee Buyer Commission on his/her purchase?
							</Typography>
						</FormLabel>
						<RadioGroup row name='is_accompanied_with_a_wholesale' value={details.is_finder_fee_buyer_commission} onChange={handleTextChange}>
							<FormControlLabel
								value={true}
								control={<Radio color='secondary' size='small' />}
								label={<span style={{ fontSize: '16px' }}>Yes</span>}
							/>
							<FormControlLabel
								value={false}
								control={<Radio color='secondary' size='small' />}
								label={<span style={{ fontSize: '16px' }}>No</span>}
							/>
						</RadioGroup>
					</FormControl>
					<FormControl sx={{ marginBottom: '1rem' }}>
						<FormLabel sx={{ fontSize: '16px' }}>
							<Typography variant='body1' sx={{ fontSize: 'inherit', marginBottom: '10px' }}>
								Will the investor provide the property for relisting under your Brokerage after renovation?
							</Typography>
						</FormLabel>
						<RadioGroup row name='is_accompanied_with_a_wholesale' value={details.is_relisted_by_my_brokerage} onChange={handleTextChange}>
							<FormControlLabel
								value={true}
								control={<Radio color='secondary' size='small' />}
								label={<span style={{ fontSize: '16px' }}>Yes</span>}
							/>
							<FormControlLabel
								value={false}
								control={<Radio color='secondary' size='small' />}
								label={<span style={{ fontSize: '16px' }}>No</span>}
							/>
						</RadioGroup>
					</FormControl>
				</>
			);
		}
	};

	const getButtons = () => {
		if (step !== 1 && leadToEdit) {
			return (
				<>
					<Button variant='text' sx={{ textTransform: 'capitalize', fontSize: '14px' }} color='secondary' onClick={() => setStep(1)}>
						Back
					</Button>
					<Button
						variant='contained'
						color='secondary'
						sx={{ textTransform: 'capitalize', fontSize: '14px' }}
						onClick={() => onSavePerson(true, true)}
					>
						Save
					</Button>
				</>
			);
		}
		if (step === 1) {
			return (
				<>
					<Button variant='contained' color='secondary' sx={{ textTransform: 'capitalize', fontSize: '14px' }} onClick={checkFields}>
						Next
					</Button>
				</>
			);
		} else {
			return (
				<>
					<Button variant='text' sx={{ textTransform: 'capitalize', fontSize: '14px' }} color='secondary' onClick={() => setStep(1)}>
						Back
					</Button>
					<Button variant='outlined' sx={{ textTransform: 'capitalize', fontSize: '14px' }} color='secondary' onClick={() => onSavePerson(false)}>
						Save & Add Another
					</Button>
					<Button variant='contained' color='secondary' sx={{ textTransform: 'capitalize', fontSize: '14px' }} onClick={() => onSavePerson(true)}>
						Save & Exit
					</Button>
				</>
			);
		}
	};

	const id = useSelector((state) => state.auth.typeId);

	const onSavePerson = async (exit, edit = false) => {
		if (details.investor_types.length === 0) {
			toast.warn('Please select at least one investor type');
			return;
		}
		const body = {
			...details,
			area_of_focus: details.areas_of_focus,
			agent_id: id,
			investing_years: +details.investing_years.replaceAll(',', ''),
			how_much_liquid_capital: +details.how_much_liquid_capital.replaceAll(',', ''),
			can_edit: true,
		};
		delete body.areas_of_focus;
		setIsLoading(true);
		const url = edit ? `investors/list-investors/${leadToEdit.id}/` : 'investors/create-investor/';
		const call = edit ? 'patch' : 'post';
		try {
			await base[call](url, body);
			getLeads({ loader: false });
			setDetails(initialDetailsState);
			setStep(1);
			if (exit) {
				setAddNewOpen(false);
			}
			if (edit) {
				toast.success('Lead updated successfully');
			}
		} catch (error) {
			toast.warn('Something went wrong, please try again');
		} finally {
			setIsLoading(false);
		}
	};

	const [leadToEdit, setLeadToEdit] = useState(null);
	const [deleteModalOpen, setDeleteModalOpen] = useState(false);
	const [adModal, setAdModal] = useState(true);
	const [chooseModal, setChooseModal] = useState(false);

	const onConfirmDelete = (e, lead) => {
		e.stopPropagation();
		setLeadToEdit(lead);
		setDeleteModalOpen(true);
	};

	const onEdit = (e, lead) => {
		e.stopPropagation();
		setLeadToEdit(lead);
		setDetails({
			...lead,
			areas_of_focus: lead.areas_of_focus.map((item) => item.name),
			investor_types: lead.type_of_investments.map((i) => i.name),
			investing_years: String(lead.investing_years),
			how_much_liquid_capital: commaReplace(lead.how_much_liquid_capital),
		});
		setAddNewOpen(true);
	};

	const onDelete = async () => {
		setIsLoading(true);
		try {
			await base.delete(`investors/list-investors/${leadToEdit?.id}/`);
			setDeleteModalOpen(false);
			getLeads({ loader: false });
		} catch (error) {
			toast.error('Could not delete lead');
		} finally {
			setIsLoading(false);
		}
	};

	const buyList = () => {
		setChooseModal(true);
		setAdModal(false);
		setcountiesChosen([]);
	};
	const [countiesChosen, setcountiesChosen] = useState([]);
	const count = useSelector((state) => state.auth.investors_number);
	const alreadyBought = useSelector((state) => state.auth.bought_investor_list);

	const handleCountyChange = (e, checked) => {
		const { value } = e.target;
		if (countiesChosen.includes(value)) {
			setcountiesChosen((items) => items.filter((item) => item !== value));
		} else {
			if (count > 0 && countiesChosen.length + 1 > count) {
				toast.warn(`You have only paid for ${count} ${count === 1 ? 'county' : 'counties'}`);
				return;
			}
			setcountiesChosen((items) => [...items, value]);
		}
	};
	const dispatch = useDispatch();

	const [isLoadingAddition, setisLoadingAddition] = useState(false);
	const addToList = async () => {
		if (countiesChosen.length < 1) {
			toast.warn('Please select a location');
			return;
		}

		setisLoadingAddition(true);
		try {
			await base.post('/register/import-investors/', {
				county: countiesChosen,
			});
			dispatch({ type: 'UPDATE_INVESTOR_COUNT', payload: { investors_number: count - countiesChosen.length } });
			setChooseModal(false);
			handleChange(null, tabs[0].value);
		} catch (error) {
			toast.error('Could not add to list');
		} finally {
			setisLoadingAddition(false);
		}
	};

	return (
		<>
			<Elements stripe={stripePromise}>
				{isLoading && <Spinner />}
				{/* auto ad modal */}
				<Dialog
					open={adModal}
					onClose={() => setAdModal(false)}
					sx={{
						backdropFilter: 'blur(5px)',
					}}
				>
					<DialogTitle>
						<Typography fontWeight={700} textAlign={'center'}>
							Ready to close more deals?
						</Typography>
					</DialogTitle>
					<DialogContent>
						<Typography textAlign={'center'} fontSize='14px'>
							BPO Investor List is your key to sealing the deal. Unlock lucrative opportunities today! Don't miss out on the chance to expand your
							network and secure your success, Grab the BPO Investor List today!
						</Typography>
					</DialogContent>
					<DialogActions>
						<Stack direction={'row'} justifyContent={'center'} gap='1rem' style={{ flex: 1 }} mb='1rem'>
							<Button
								variant='outlined'
								size='small'
								color='secondary'
								onClick={() => {
									setAdModal(false);
									handleChange(null, tabs[alreadyBought.length > 0 ? 0 : 1].value);
								}}
								sx={{ textTransform: 'capitalize', color: 'grey', fontWeight: '300' }}
							>
								Continue {alreadyBought.length === 0 ? 'Without Investors' : ''}
							</Button>
							<Button
								variant='outlined'
								size='small'
								color='secondary'
								onClick={buyList}
								sx={{
									textTransform: 'capitalize',
									background: `linear-gradient(45deg, #005ea9 30%, #01345e 90%)`,
									color: 'white',
									px: '1rem',
									fontWeight: '300',
								}}
							>
								{count > 0 ? 'Select' : 'Buy'} List
							</Button>
						</Stack>
					</DialogActions>
				</Dialog>

				{/* choose county package */}
				<Dialog
					open={chooseModal}
					onClose={() => setChooseModal(false)}
					maxWidth='sm'
					sx={{
						backdropFilter: 'blur(5px)',
					}}
				>
					<DialogTitle>
						<Typography fontWeight={700} textAlign={'left'} ml='1rem'>
							Choose Package
						</Typography>
					</DialogTitle>
					<Divider color='grey' />
					<DialogContent>
						<Typography textAlign={'left'} fontSize='14px' color='grey'>
							BPO Investor List is categorized based on investors' location. <br />
							Choose which list of investors you wish to purchase.
						</Typography>
						<Stack my='1rem'>
							{counties.map((item) => (
								<FormControlLabel
									key={item}
									value={item}
									name={item}
									size='small'
									color='secondary'
									control={<Checkbox sx={{ marginLeft: 'auto' }} />}
									label={item + ' County'}
									disabled={alreadyBought.includes(item)}
									labelPlacement='start'
									checked={countiesChosen.includes(item)}
									onChange={handleCountyChange}
								/>
							))}
						</Stack>
						{count < 1 && (
							<Stack direction='row' justifyContent={'flex-end'} alignItems='center'>
								<Typography fontWeight={300} fontSize={'14px'}>
									Total:{' '}
								</Typography>
								<Typography fontWeight={700} fontSize='18px' ml='.5rem'>
									{Number(countiesChosen.length * 399).toFixed(2)}
								</Typography>
							</Stack>
						)}
					</DialogContent>
					<Stack direction='row' style={{ background: 'linear-gradient(45deg, #005ea9 30%, #01345e 90%)', padding: '1rem' }} gap='1rem'>
						<Button
							variant='outlined'
							size='small'
							color='secondary'
							onClick={() => setChooseModal(false)}
							sx={{ textTransform: 'capitalize', fontWeight: '300', flex: 1, color: 'white', borderColor: 'white' }}
						>
							Cancel
						</Button>
						{count > 0 ? (
							<LoadingButton
								variant='outlined'
								size='small'
								color='secondary'
								loading={isLoadingAddition}
								onClick={addToList}
								endIcon={<CheckCircle />}
								sx={{
									textTransform: 'capitalize',
									background: `white`,
									px: '1rem',
									fontWeight: '300',
									flex: 1,
								}}
							>
								Add to List
							</LoadingButton>
						) : (
							<StripePay selectedCounties={countiesChosen} />
						)}
					</Stack>
				</Dialog>

				{/* delete confirmation modal */}
				<Dialog
					sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
					maxWidth='xs'
					open={deleteModalOpen}
					onClose={() => setDeleteModalOpen(false)}
				>
					<DialogTitle>
						Delete Lead{' '}
						<IconButton onClick={() => setDeleteModalOpen(false)} sx={{ position: 'absolute', top: '5%', right: '5%' }}>
							<Close fontSize='small' />
						</IconButton>
					</DialogTitle>
					<DialogContent>
						Are you sure you want to <b>delete</b> this lead?. This process is irreversible and cannot undone.
					</DialogContent>
					<DialogActions>
						<Button variant='outlined' color='secondary' onClick={() => setDeleteModalOpen(false)} sx={{ textTransform: 'capitalize' }}>
							Cancel
						</Button>
						<Button variant='outlined' color='error' onClick={onDelete} sx={{ textTransform: 'capitalize' }}>
							Delete
						</Button>
					</DialogActions>
				</Dialog>

				{/* details modal */}
				<Dialog
					open={detailsModalOpen}
					fullWidth={true}
					maxWidth='md'
					onClose={() => {
						setDetailsModalOpen(false);
					}}
				>
					<DialogContent dividers sx={{ backgroundColor: '#f1f1f1', padding: '1rem !important', display: 'flex', gap: '1rem' }}>
						<Box sx={{ background: 'white', borderRadius: '5px', flex: 1, padding: '1rem' }}>
							<Typography gutterBottom className={classes.typoHeader}>
								Profile Info
							</Typography>
							<>
								<Typography gutterBottom className={classes.title}>
									Name
								</Typography>
								<Typography gutterBottom className={classes.answer}>
									{selectedLead?.investor_name}
								</Typography>
								<Typography gutterBottom className={classes.title}>
									Phone
								</Typography>
								<Typography gutterBottom className={classes.answer}>
									{selectedLead?.phone_number}
								</Typography>
								<Typography gutterBottom className={classes.title}>
									Email
								</Typography>
								<Typography gutterBottom className={classes.answer}>
									{selectedLead?.email}
								</Typography>
								<Typography gutterBottom className={classes.title}>
									{selectedLead?.source !== 'bpo_list' ? 'Address' : 'Last Known Address'}
								</Typography>
								<Typography gutterBottom className={classes.answer}>
									{selectedLead?.[selectedLead?.source === 'bpo_list' ? 'last_known_investment' : 'address']}
								</Typography>
							</>
						</Box>
						<Box sx={{ background: 'white', borderRadius: '5px', flex: 2, padding: '1rem' }}>
							<Typography
								gutterBottom
								className={classes.typoHeader}
								sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}
							>
								Investor Details
								<IconButton onClick={() => setDetailsModalOpen(false)}>
									<Close fontSize='small' />
								</IconButton>
							</Typography>
							<>
								<Typography gutterBottom className={classes.title}>
									Available Investment Capital
								</Typography>
								<Typography gutterBottom className={classes.answer}>
									{formatNumber(selectedLead?.how_much_liquid_capital)}
								</Typography>
								<Typography gutterBottom className={classes.title}>
									Area of Focus
								</Typography>
								<Typography gutterBottom className={classes.answer} sx={{ textTransform: 'capitalize' }}>
									{selectedLead?.areas_of_focus.map((type) => type?.name).join(', ')}
								</Typography>

								<Typography gutterBottom className={classes.title}>
									Do you use hard money for real estate investments?
								</Typography>
								<Typography gutterBottom className={classes.answer}>
									{selectedLead?.use_hard_money_to_fund ? 'Yes' : 'No'}
								</Typography>
								<Typography gutterBottom className={classes.title}>
									Are you a wholesaler?
								</Typography>
								<Typography gutterBottom className={classes.answer}>
									{selectedLead?.is_wholesaler ? 'Yes' : 'No'}
								</Typography>

								<Typography gutterBottom className={classes.title}>
									Which investments do you make?
								</Typography>
								<Typography gutterBottom className={classes.answer} sx={{ textTransform: 'capitalize' }}>
									{selectedLead?.type_of_investments.map((type) => type?.name).join(', ')}
								</Typography>
								<Typography gutterBottom className={classes.title}>
									Are you comfortable paying a wholesale fee if there's enough margin in the deal?
								</Typography>
								<Typography gutterBottom className={classes.answer}>
									{selectedLead?.is_accompanied_with_a_wholesale ? 'Yes' : 'No'}
								</Typography>
								<Typography gutterBottom className={classes.title}>
									Are you willing to pay a 1% Finder Fee Commission on your purchase?
								</Typography>
								<Typography gutterBottom className={classes.answer}>
									{selectedLead?.is_finder_fee_buyer_commission ? 'Yes' : 'No'}
								</Typography>
								<Typography gutterBottom className={classes.title}>
									Will you provide the property for relisting under my brokerage after renovation?
								</Typography>
								<Typography gutterBottom className={classes.answer}>
									{selectedLead?.is_relisted_by_my_brokerage ? 'Yes' : 'No'}
								</Typography>
							</>
						</Box>
					</DialogContent>
				</Dialog>
				{/* add new investor manually */}
				<Dialog
					open={addNewOpen}
					fullWidth={true}
					maxWidth='md'
					onClose={() => {
						setAddNewOpen(false);
					}}
				>
					<DialogContent
						dividers
						sx={{ backgroundColor: '#f1f1f1', padding: '1rem !important', display: 'flex', gap: '1rem', flexDirection: 'column' }}
					>
						<IconButton onClick={() => setAddNewOpen(false)} sx={{ position: 'fixed', right: '100px', top: '100px', background: 'white' }}>
							<Close fontSize='small' />
						</IconButton>
						<Box sx={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
							<Box sx={{ borderRadius: '5px', flex: 1, padding: '1rem' }}>
								<Typography gutterBottom className={classes.typoHeader}>
									Profile Info
								</Typography>
								<Stack gap='5px'>
									<InputWithLabel
										label={'Name'}
										placeholder={'Name'}
										name='investor_name'
										value={details.investor_name}
										onChange={handleTextChange}
									/>
									<InputWithLabel
										label={'Phone'}
										placeholder={'Phone Number'}
										value={formatPhoneNumber(details.phone_number) || ''}
										name='phone_number'
										onChange={handleTextChange}
									/>
									<InputWithLabel label={'Email'} placeholder={'Email'} value={details.email} name='email' onChange={handleTextChange} />
									<>
										<InputLabel sx={{ fontSize: '16px' }}>Address</InputLabel>
										<PlacesSearch
											mui={true}
											placeholder={'Address'}
											fullWidth
											name={'address'}
											value={details.address}
											onChange={(val) => setDetails((p) => ({ ...p, address: val }))}
											size='small'
										/>
									</>
								</Stack>
							</Box>
							<Stack direction='column' sx={{ background: 'white', borderRadius: '5px', flex: 2, padding: '1rem' }}>
								<Stack direction='row' justifyContent={'space-between'} className={classes.typoHeader}>
									<Typography gutterBottom sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
										Investor Details
									</Typography>
									<Stack direction='row' gap='10px' alignItems='center'>
										<Divider sx={{ height: '3px', width: '20px', backgroundColor: '#FFAA01' }} />
										<Divider sx={{ height: '3px', width: '20px', backgroundColor: step === 2 ? '#FFAA01' : '#cdcdcd' }} />
									</Stack>
								</Stack>
								<Stack gap='5px'>{getContent()}</Stack>
							</Stack>
						</Box>
						<Stack direction='row' justifyContent='flex-end' gap='1rem'>
							{getButtons()}
						</Stack>
					</DialogContent>
				</Dialog>
				<Dialog
					open={uploadModal}
					fullWidth={true}
					maxWidth='sm'
					onClose={() => {
						setUploadModal(false);
					}}
				>
					<DialogTitle sx={{ fontSize: '18px' }}>Upload file</DialogTitle>
					<DialogContent dividers>
						<Typography>
							Please click the button below to upload using{' '}
							<a
								href='https://bpooperationsmain.s3.eu-west-2.amazonaws.com/template_images/import_sample.csv'
								download='sample import template'
								target='_blank'
								rel='noreferrer'
								style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
							>
								this template
							</a>
						</Typography>
						<input
							type='file'
							accept='.csv'
							style={{ margin: '0 auto', display: 'none' }}
							ref={imageSelector}
							onChange={setImage}
							name='profile_image'
						/>
						<Button
							variant='contained'
							color='primary'
							sx={{ mx: 'auto', display: 'block', marginTop: '1rem', textTransform: 'capitalize', fontSize: '14px', color: 'white' }}
							onClick={() => {
								imageSelector.current.click();
							}}
						>
							Select file
						</Button>
					</DialogContent>
				</Dialog>
				<Popover
					sx={{ mt: 1 }}
					open={open}
					anchorEl={anchorEl}
					onClose={handleClose}
					anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
					transformOrigin={{ vertical: 'top', horizontal: 'center' }}
				>
					<Stack sx={{ fontSize: '14px !important', width: '400px' }} gap='1rem'>
						<Box p='1.5rem'>
							<Typography sx={{ borderBottom: '1px solid #f1f1f1' }} pb='.5rem'>
								Filters
							</Typography>
						</Box>
						<Stack gap='1rem' sx={{ overflowY: 'auto', maxHeight: '60vh', paddingLeft: '1.5rem' }}>
							{/* <FormControl>
							<FormLabel className={classes.popTitle}>Investor Group</FormLabel>
							<FormGroup>
								{source.map(({ name, value }) => (
									<FormControlLabel
										key={name}
										control={
											<Checkbox
												size='small'
												color='secondary'
												checked={filters.source.includes(name)}
												onChange={(e, checked) => handleCheckboxChange('source', name, checked)}
											/>
										}
										label={value}
										sx={{ fontSize: '14px' }}
									/>
								))}
							</FormGroup>
						</FormControl> */}
							<FormControl>
								<FormLabel className={classes.popTitle}>Hard Money Funding</FormLabel>
								<RadioGroup row value={filters.hardMoney} onChange={(e, value) => setFilters((prev) => ({ ...prev, hardMoney: value }))}>
									<FormControlLabel value={true} control={<Radio size='small' />} label='Yes' sx={{ fontSize: '14px' }} />
									<FormControlLabel value={false} control={<Radio size='small' />} label='No' sx={{ fontSize: '14px' }} />
								</RadioGroup>
							</FormControl>
							<FormControl>
								<FormLabel className={classes.popTitle}>Wholesaler</FormLabel>
								<RadioGroup
									row
									name='row-radio-buttons-group'
									value={filters.wholesaler}
									onChange={(e, value) => setFilters((prev) => ({ ...prev, wholesaler: value }))}
								>
									<FormControlLabel value={true} control={<Radio size='small' />} label='Yes' sx={{ fontSize: '14px' }} />
									<FormControlLabel value={false} control={<Radio size='small' />} label='No' sx={{ fontSize: '14px' }} />
								</RadioGroup>
							</FormControl>
							<FormControl>
								<FormLabel className={classes.popTitle}>Liquid Capital</FormLabel>
								<Stack direction='row' justifyContent='space-between' mt='.2rem'>
									<TextField
										size='small'
										placeholder='Min'
										sx={{ width: '40%' }}
										value={filters.min}
										onChange={(e) => setFilters((p) => ({ ...p, min: e.target.value }))}
										InputProps={{
											startAdornment: (
												<InputAdornment position='end' sx={{ '&>p': { color: 'black !important' }, marginRight: '5px' }}>
													$
												</InputAdornment>
											),
										}}
									/>
									<TextField
										size='small'
										placeholder='Max'
										sx={{ width: '40%' }}
										value={filters.max}
										onChange={(e) => setFilters((p) => ({ ...p, max: e.target.value }))}
										InputProps={{
											startAdornment: (
												<InputAdornment position='end' sx={{ '&>p': { color: 'black !important' }, marginRight: '5px' }}>
													$
												</InputAdornment>
											),
										}}
									/>
								</Stack>
							</FormControl>
							<FormControl>
								<FormLabel className={classes.popTitle}>Location</FormLabel>
								<FormGroup>
									{location.map((name) => (
										<FormControlLabel
											key={name}
											control={
												<Checkbox
													size='small'
													color='secondary'
													checked={filters.location.includes(name)}
													onChange={(e, checked) => handleCheckboxChange('location', name, checked)}
												/>
											}
											label={name}
											sx={{ fontSize: '14px' }}
										/>
									))}
								</FormGroup>
							</FormControl>
							<FormControl>
								<FormLabel className={classes.popTitle}>Types of Investment</FormLabel>
								<FormGroup>
									{typesOfInvestments.map((name) => (
										<FormControlLabel
											key={name}
											control={
												<Checkbox
													size='small'
													color='secondary'
													checked={filters.typesOfInvestments.includes(name)}
													onChange={(e, checked) => handleCheckboxChange('typesOfInvestments', name, checked)}
												/>
											}
											label={name}
											sx={{ fontSize: '16px' }}
										/>
									))}
								</FormGroup>
							</FormControl>
						</Stack>
						<Stack direction='row' gap='1rem' justifyContent='flex-end' sx={{ marginTop: 'auto', background: '#f1f1f1', padding: '2rem 1rem' }}>
							<Button variant='outlined' color='secondary' onClick={handleCancelFilter} sx={{ textTransform: 'capitalize', fontSize: '14px' }}>
								Cancel
							</Button>
							<Button variant='contained' color='secondary' onClick={handleFilter} sx={{ textTransform: 'capitalize', fontSize: '14px' }}>
								Apply
							</Button>
						</Stack>
					</Stack>
				</Popover>

				<Box sx={{ padding: '1rem' }}>
					<Stack direction='row' justifyContent={'space-between'} sx={{ background: '#f1f1f1', borderRadius: '5px', padding: '1rem' }}>
						<Box sx={{ width: '350px' }}>
							<PlacesSearch
								mui={true}
								size='small'
								fullWidth
								value={filters.search}
								types={['country', 'locality', 'postal_code']}
								placeholder='Search by county, city'
								sx={{ width: '100%', background: 'white' }}
								onChange={handleSearchFilter}
								InputProps={{
									endAdornment: (
										<InputAdornment position='end'>
											<Search />
										</InputAdornment>
									),
								}}
							/>
						</Box>
						<Stack direction='row' spacing={2}>
							<Button
								variant='outlined'
								color='secondary'
								onClick={handleClick}
								sx={{ textTransform: 'capitalize', fontSize: '16px' }}
								startIcon={<Tune />}
								size='small'
							>
								Filters
							</Button>
							<Button
								variant='outlined'
								color='secondary'
								onClick={() => setUploadModal(true)}
								sx={{ textTransform: 'capitalize', fontSize: '16px' }}
								startIcon={<ArrowCircleDown />}
								size='small'
							>
								Import
							</Button>
							<Button
								variant='contained'
								color='primary'
								onClick={handleExport}
								sx={{ textTransform: 'capitalize', fontSize: '16px', color: 'white' }}
								startIcon={<ArrowCircleUp />}
								size='small'
								disableElevation
							>
								Export
							</Button>
						</Stack>
					</Stack>
					<Stack direction={'row'} justifyContent={'space-between'}>
						<Tabs value={filters.source} onChange={handleChange} textColor='secondary' indicatorColor='secondary'>
							{tabs.map((item) => (
								<Tab
									key={item.name}
									label={
										<Stack direction='row' gap='10px' alignItems='center'>
											<item.Icon fontSize='small' sx={{ fill: 'grey' }} />
											<Typography sx={{ fontSize: '14px', textTransform: 'capitalize' }}>{item.name}</Typography>
											{filters.source === item.value && (
												<Chip label={leadsData.count} color='primary' sx={{ marginLeft: '1rem', color: 'white', fontSize: '14px' }} size='small' />
											)}
										</Stack>
									}
									color='secondary'
									value={item.value}
								/>
							))}
						</Tabs>
						<Button
							variant='text'
							color='secondary'
							onClick={onAddNewInvestor}
							sx={{ textTransform: 'capitalize', fontSize: '16px', mt: '1rem' }}
							startIcon={<Add />}
							size='small'
						>
							Add Investor
						</Button>
					</Stack>
					{leadsData.results?.length > 0 ? (
						<>
							<Stack
								direction='row'
								gap='1rem'
								alignItems={'center'}
								sx={{
									fontWeight: 'bold',
									my: '1rem',
									background: '#f1f1f1',
									padding: '1rem',
									borderRadius: '5px',
									color: 'grey',
									fontSize: '14px',
									scrollMarginTop: '100px',
									position: 'sticky',
									top: 'var(--navheight) ',
								}}
							>
								{[...(filters.source === 'bpo_list' ? paidHeaders : headers)].map((item) => (
									<Typography key={item.key} sx={{ fontSize: '14px', color: '#979797', flex: `${item.flex}` }}>
										{item.label}
									</Typography>
								))}
							</Stack>
							<Box sx={{ maxHeight: '55vh', overflowY: 'auto', paddingBottom: '1rem', marginBottom: '1rem' }} id='top'>
								{leadsData.results.map((lead) => (
									<Stack
										direction='row'
										gap='1rem'
										alignItems={'center'}
										my='.5rem'
										key={lead?.id}
										sx={{
											fontSize: '14px',
											fontWeight: '300',
											cursor: 'pointer',
											borderRadius: '5px',
											transition: 'all .2s ease-in-out',
											padding: '1rem',

											'&:hover': {
												background: 'white',
												boxShadow: '0px 3px 10px 0px rgb(0 0 0 / 10%)',
											},
										}}
										onClick={() => {
											setSelectedLead(lead);
											setDetailsModalOpen(true);
										}}
									>
										<Typography sx={{ flex: 3, fontWeight: 'inherit', fontSize: 'inherit', display: 'flex', alignItems: 'center', gap: '10px' }}>
											{getToolLabel(lead?.source)}
											{lead?.investor_name}
										</Typography>
										<Typography sx={{ flex: 2, fontWeight: 'inherit', fontSize: 'inherit' }}>
											$ {formatNumber(lead?.how_much_liquid_capital)}
										</Typography>
										<Typography sx={{ flex: 3, fontWeight: 'inherit', fontSize: 'inherit' }}>
											{lead?.areas_of_focus.map((item) => item.name).join(', ')}
										</Typography>
										{filters.source === 'bpo_list' && (
											<Typography sx={{ flex: 3, fontWeight: 'inherit', fontSize: 'inherit', textAlign: 'center' }}>
												{lead?.['last_known_investment']}
											</Typography>
										)}
										{filters.source === 'bpo_list' && (
											<Typography sx={{ flex: 1, fontWeight: 'inherit', fontSize: 'inherit' }}>{lead?.phone_number}</Typography>
										)}
										<Typography sx={{ textTransform: 'capitalize', flex: 2, fontWeight: 'inherit', fontSize: 'inherit' }}>
											{lead?.type_of_investments.map((type) => type.name).join(', ')}
										</Typography>
										<Stack direction='row' sx={{ display: 'inline-block', marginLeft: '5px', flex: 1 }}>
											{lead?.can_edit && (
												<>
													<IconButton onClick={(e) => onEdit(e, lead)}>
														<Create fontSize='small' />
													</IconButton>
													<IconButton onClick={(e) => onConfirmDelete(e, lead)}>
														<Delete fontSize='small' />
													</IconButton>
												</>
											)}
										</Stack>
									</Stack>
								))}
							</Box>
							<Pagination
								size='medium'
								color='secondary'
								sx={{
									display: 'flex',
									justifyContent: 'center',
								}}
								shape='rounded'
								variant='outlined'
								count={Math.ceil(leadsData.count / 10)}
								page={leadsData.page}
								onChange={handlePageChange}
							/>
						</>
					) : isLoading ? (
						<Stack gap='10px' sx={{ padding: '1rem' }}>
							{Array.from(Array(10).keys()).map((item) => (
								<Skeleton variant='rectangular' width={'100%'} height={90} sx={{ borderRadius: '5px' }} key={item} />
							))}
						</Stack>
					) : (
						<Box
							sx={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								height: '60vh',
								flexDirection: 'column',
								background: '#f1f1f1',
								borderRadius: '5px',
								marginTop: '1rem',
							}}
						>
							<PlaylistRemove fontSize='50px' sx={{ fill: 'grey', transform: 'scale(10)' }} />
							<Typography sx={{ marginTop: '100px' }}>No data found</Typography>
						</Box>
					)}
				</Box>
			</Elements>
		</>
	);
}

export default AgentInvestorLeads;

const StripePay = ({ selectedCounties }) => {
	const stripe = useStripe();
	const [isLoading, setIsLoading] = useState(false);

	const handleClick = async () => {
		setIsLoading(true);
		try {
			const { data } = await base.get('/register/list_prices/');
			const price = data?.flat().filter((item) => item.metadata?.product === 'investor_list')?.[0];
			const {
				data: { sessionId },
			} = await base.post('/register/create_subscription/', {
				cancel_url: window.location.href,
				success_url: window.location.origin + `/payment_success?type=investor_list&counties=${selectedCounties.toString()}&`,
				mode: 'payment',
				count: selectedCounties.length,
				payment_method_types: ['card'],
				price_data: [
					{
						quantity: selectedCounties.length,
						price: price.id,
					},
				],
				is_investor: true,
			});
			stripe.redirectToCheckout({ sessionId });
		} catch (error) {
			toast.error('Could not get prices');
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<LoadingButton
			loading={isLoading}
			variant='outlined'
			size='small'
			color='secondary'
			onClick={handleClick}
			endIcon={<CheckCircle />}
			sx={{
				textTransform: 'capitalize',
				background: `white`,
				px: '1rem',
				fontWeight: '300',
				flex: 1,
			}}
		>
			Checkout
		</LoadingButton>
	);
};
