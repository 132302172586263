import React from 'react';
import { Helmet } from 'react-helmet-async';
export default function SEO({ children }) {
	return (
		<Helmet prioritizeSeoTags>
			{children}
			<meta name='og:url' content={window.location.href} />
		</Helmet>
	);
}
