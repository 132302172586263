import React from 'react';
import './feed.scss';

const Feed = ({ image, address, list_price, date, baths, beds, onClick }) => {
	return (
		<div className='feed_card' style={{ height: 'unset', minWidth: '250px', maxWidth: '250px', cursor: 'pointer' }} onClick={onClick}>
			<div className='img_wrapper'>
				<img
					src={image}
					alt=''
					style={{
						borderTopRightRadius: '5px',
						borderTopLeftRadius: '5px',
						width: '100%',
						height: '150px',
						objectFit: 'cover',
					}}
				/>
			</div>
			<div className='feed_details' style={{ display: 'flex', flexDirection: 'column', gap: '10px', padding: '10px' }}>
				<p
					style={{
						margin: 'unset',
						color: 'var(--primary-color)',
						fontSize: '25px',
						fontWeight: '700',
					}}
				>
					$ {Number(list_price).toLocaleString('en')}
				</p>
				<p style={{ all: 'unset' }}>
					{beds} beds . {baths} baths
				</p>
				<span style={{ fontSize: '12px' }}>{address}</span>
				<span style={{ fontSize: '12px', marginTop: '25px' }}>
					{new Date(date).toLocaleDateString('en', { month: 'long', day: '2-digit', year: 'numeric' })}
				</span>
			</div>
		</div>
	);
};

export default Feed;
