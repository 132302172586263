export const totalCost = (arr) => {
  const costArr = [];
  if(arr){
  for (let element of arr) {
    if (!Number.isNaN(+element?.value)) {
      costArr.push(+element?.value);
    }
  }
  return costArr?.length ? costArr.reduce((a, b) => a + b).toFixed(2): 0
}
};
