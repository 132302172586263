import React from "react";
import {
  Box,
  Card,
  Typography,
  Divider,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

const useStyles = (theme) => {
  return {
    root: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    card: {
      padding: "1.875rem",
      width: "100vw",
      height: "22rem",
      marginRight: "0rem",
      border: `solid 1px ${theme.palette.primary.main}`,
    },
    close: {
      display: "flex",
      justifyContent: "flex-end",
    },
    buttonContainer: {
      display: "flex",
      width: "18.75rem",
    },
    monthly: {
      width: "50%",
      textAlign: "center",
      padding: "0.625rem 1.875rem",
      border: `solid 1px ${theme.palette.primary.main}`,
      borderRadius: "5px 0 0 5px",
      cursor: "pointer",
    },
    yearly: {
      width: "50%",
      textAlign: "center",
      padding: "0.625rem 1.875rem",
      border: `solid 1px ${theme.palette.primary.main}`,
      borderRadius: "0 5px 5px 0",
      cursor: "pointer",
    },
    active: {
      backgroundColor: theme.palette.primary.main,
      color: "white",
    },
    inactive: {
      backgroundColor: "white",
      color: theme.palette.primary.main,
    },
  };
};

function DealDetails({ price, unit, offerScope, offerScopeDetail }) {
  const theme = useTheme();
  const styles = useStyles(theme);

  return (
    <Box sx={styles.root}>
      <Card sx={styles.card}>
        <Box sx={{ textAlign: "center" }}>
          <Typography variant="h5" sx={{ fontWeight: 700 }}>
            Your Selection Package
          </Typography>
        </Box>
        <Divider
          sx={{
            height: "2px",
            backgroundColor: "#1378A5",
            margin: "1.25rem 0",
          }}
        />
        <Box sx={{ textAlign: "center" }}>
          <FormControlLabel
            control={
              <Radio
                sx={{
                  "& .MuiSvgIcon-root": {
                    fontSize: 28,
                  },
                }}
                checked={true}
              />
            }
            label={
              <Typography variant="h5" sx={{ fontWeight: 500 }}>
                BPO Homes Deal Analyzer
              </Typography>
            }
            labelPlacement="end"
          />
        </Box>
        <Box sx={{ textAlign: "center" }}>
          <Typography variant="h6" sx={{ fontWeight: "700", my: 2 }}>
            Package Details:
          </Typography>
          {deals.deal3.map((element) => (
            <Box
              sx={{
                display: "inline-flex",
                textAlign: "center",
                alignItems: "center",
              }}
            >
              <CheckCircleOutlineIcon sx={{ color: "#1378A5", mr: 2 }} />
              <Typography variant="h6">{element}</Typography>
            </Box>
          ))}
        </Box>
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "3rem",
            }}
          >
            <Typography variant="dealCta">{offerScope}</Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="dealCost" sx={{ marginRight: "2rem" }}>
                {price}
              </Typography>
              <Typography variant="dealCostUnit">{unit}</Typography>
            </Box>
          </Box>
          <Box sx={{ marginBottom: "2rem", width: "50%" }}>
            <Typography variant="dealCostUnit">{offerScopeDetail}</Typography>
          </Box>
        </Box>
      </Card>
    </Box>
  );
}

export default DealDetails;

const deals = {
  deal3: ["Agent branded Pro-Forma Reports"],
};
