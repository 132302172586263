import { Box, Button, Card, Container, Divider, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import AdBanner from '../../components/MUI-Components/AdBanner';
//import Navbar from '../../components/MUI-Components/Navbar'
import FarmImg from '../../assets/banner02.webp';
import Farm1 from '../../assets/farm01.webp';
import Farm2 from '../../assets/farm02.webp';
import Farm3 from '../../assets/farm03.webp';
import Farm4 from '../../assets/farm04.webp';
// import Farm5 from '../../assets/farm05.webp';
import Farm6 from '../../assets/farm06.webp';
import GridSection from '../../components/MUI-Components/GridSection';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toggleAuthModal } from '../../actions';
import AdPrice from '../../components/AdPrice/AdPrice';
import SEO from '../../components/SEO';

const useStyles = makeStyles((theme) => ({
	divider: {
		width: '25%',
		margin: '0 auto !important',
		marginTop: '3rem !important',
		height: '5px',
		background: theme.primaryColor,
	},
	para: {
		width: '50%',
		margin: '0 auto !important',
		[theme.breakpoints.down('md')]: {
			width: '80%',
		},
	},
	gridItem: {},
	grid: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		padding: '2rem',
		borderRadius: '5px',
		height: '100%',
		'& h6': {
			lineHeight: 1.1,
		},
		'& button': {
			textTransform: 'none',
			marginTop: 'auto',
		},
	},
	gridWrapper: {
		width: '100% !important',
		margin: '0 !important',
	},
}));

const AdvertiseFarm = () => {
	const classes = useStyles();

	const sections = [
		{
			title: 'We Text Your Sellers',
			copy: `There is a 99% chance a Seller has their cell phone near them 99% of the time. Text message marketing is a must and an essential part of your business growth so we Text Sellers monthly letting the Sellers know you're ready to assist with Listing their homes.`,
			img: Farm1,
		},
		{
			title: 'We Email Your Sellers',
			copy: 'Whether a Seller is on the go checking their emails or at their office we make sure to get you in front of them by dropping a monthly email.',
			img: Farm2,
		},
		{
			title: 'We Call Your Sellers',
			copy: "Ring Ring.... We know you hate cold calling. It's a grueling task. Let us over here at BPO Homes handle the calls for you. We call the Sellers informing them that if they're looking to sell you're ready to List!!",
			img: Farm3,
		},
		{
			title: 'We Mail Your Sellers',
			copy: "Sellers reach in the Mail Box and guess who's there? YOU!! We mail sleek postcards yearly to catch the attention of the Sellers and offer your Listing Services and advertise your custom BPO Report ordering page where you provide them with a BPO Report and get PAID for $$$ your efforts along with the lead!!",
			img: Farm4,
		},

		{
			title: 'We Provide you with Leads',
			copy: 'You receive leads from NOD Records, BPO Report Request & all of your Text, Emails, & Phone calls made made by BPO Homes on your behalf.',
			img: Farm6,
		},
	];

	const loggedIn = useSelector((state) => state?.auth?.token?.access);
	const dispatch = useDispatch();

	const navigate = useNavigate();
	const checkIfAuthorized = () => {
		if (loggedIn) {
			navigate('/available-territories');
		} else {
			dispatch(toggleAuthModal());
			localStorage.setItem('nextRoute', '/available-territories');
		}
	};

	return (
		<div className={classes.root}>
			{/* NAVBAR */}
			{/* <Navbar /> */}

			<SEO>
				<title>Premier Agent: Get Real Estate Solutions to boost your Business</title>
				<meta name='og:title' content='Premier Agent: Get Real Estate Solutions to boost your Business' />
				<meta
					name='description'
					content='Get the best platform for starting your own real estate business with Premier Agent. Our all-inclusive package starts at $199 per month and includes everything you need to grow your business. Get leads, text, email and phone support, and more. Start now!'
				/>
				<meta
					name='og:description'
					content='Get the best platform for starting your own real estate business with Premier Agent. Our all-inclusive package starts at $199 per month and includes everything you need to grow your business. Get leads, text, email and phone support, and more. Start now!'
				/>
				<meta name='og:image' content={window.location.origin + FarmImg} />
			</SEO>

			<Box>
				{/* BANNER */}
				<AdBanner title='Premier Agent is our Best Platform for Starting Your Own Business.' bgImage={FarmImg} titleColor={'#fff'}>
					<AdPrice
						title='Premier Agent'
						// setupFeeAmount={'300'}
						subHeader='All Inclusive Premier Agent Package'
						price='$299 - $699'
						interval={'per month'}
						onClick={checkIfAuthorized}
					/>
				</AdBanner>

				<Box bgcolor='#1378A5' padding={'4rem 2rem'} textAlign='center' color={'#fff'}>
					<Container>
						<Box>
							<Typography variant='h5' gutterBottom>
								Our Real Estate Farming Techniques are unparalleled to anything on the Market
							</Typography>
							<Typography paragraph variant='body2' className={classes.para}>
								Premier Agent is cost effective for any Agent to grow their business starting with a minimum of 300 homes per Farm Area
							</Typography>
							<Divider className={classes.divider} />
						</Box>
					</Container>
				</Box>

				<Box className={classes.wrapper}>
					<Container>
						<Box padding='3rem 0' textAlign={'center'}>
							<Typography variant='h5' style={{ fontWeight: 600 }}>
								What's The Catch?
							</Typography>
						</Box>

						<Box>
							{sections.map((el, index) => {
								return <GridSection key={index} index={index} title={el.title} copy={el.copy} img={el.img} />;
							})}
						</Box>

						<Box padding='2rem 0'>
							<Box textAlign={'center'}>
								<Typography variant='h5' style={{ fontWeight: 600 }}>
									Also Included
								</Typography>
							</Box>
							<Grid container spacing={3} className={classes.gridWrapper}>
								<Grid item lg={4} className={classes.gridItem}>
									<Card variant='outlined' className={classes.grid}>
										<Typography variant='h6' gutterBottom>
											BPO Homes Deal Analyzer Platform
										</Typography>
										<Typography variant='body2' paragraph>
											Analyze any Real Estate Deal just like an Investor and generate one of our Pro Forma Reports to insure your making the right
											decision.
										</Typography>
										<Button variant='contained' disableElevation color='secondary' onClick={() => navigate('/advertise/advertise-deal')}>
											Learn More
										</Button>
									</Card>
								</Grid>
								<Grid item lg={4} className={classes.gridItem}>
									<Card variant='outlined' className={classes.grid}>
										<Typography variant='h6' gutterBottom>
											BPO Homes Premier Agent Platform
										</Typography>
										<Typography variant='body2' paragraph>
											All of your online presence in a nutshell. We build you a Web Page Listing Page creator. We advertise you in 5 cities of your
											choice as a BPO Homes Premier Agent.
										</Typography>
										<Button variant='contained' disableElevation color='secondary' onClick={() => navigate('/advertise/advertise-premier')}>
											Learn More
										</Button>
									</Card>
								</Grid>
								<Grid item lg={4} className={classes.gridItem}>
									<Card variant='outlined' className={classes.grid}>
										<Typography variant='h6' gutterBottom>
											Digital Business Card
										</Typography>
										<Typography variant='body2' paragraph>
											Stop wasting money on Business Cards!! A complimentary Digital Business Card is provided to you with your Premier Agent
											subscription. The card features NFC Technology (Near Field Communication) TECH. Set your card in close proximity to your new
											potential clients phone and they receive all your contact information and Premier Agent Page. Listings & Personal Video is right
											in front of them along with all other contact to get directly in touch with you!
										</Typography>
										<Button variant='contained' disableElevation color='secondary' onClick={() => navigate('/advertise/advertise-premier')}>
											Learn More
										</Button>
									</Card>
								</Grid>
							</Grid>
						</Box>
					</Container>
				</Box>
				<Box
					style={{
						backgroundImage: `linear-gradient(#1378A595, #1378A595), url(${FarmImg})`,
						backgroundSize: 'cover',
						padding: '3rem',
						marginTop: '2rem',
					}}
				>
					<Container>
						<Box textAlign={'center'} color='#fff'>
							<Typography variant='h6'>Premier Agent Package</Typography>
							<Divider style={{ margin: '1rem auto', width: '4rem', height: '2px', background: '#fff' }} />
							<Typography>All Inclusive Premier Agent Package</Typography>
							<KeyboardArrowDownIcon />
							<Typography variant='h6' gutterBottom>
								$299-$699 <span style={{ fontSize: '14px' }}>per month</span>
							</Typography>
							<Typography variant='body2' paragraph>
								Set Up Fee
							</Typography>
							<Typography variant='h6'>$99</Typography>
							<Button
								variant='contained'
								disableElevation
								color='primary'
								style={{ width: '15rem', textTransform: 'none', marginTop: '1rem', color: '#fff' }}
								onClick={checkIfAuthorized}
							>
								Subscribe
							</Button>
						</Box>
					</Container>
				</Box>
			</Box>
		</div>
	);
};

export default AdvertiseFarm;
