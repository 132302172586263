import React from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { IoAlertCircleOutline } from "react-icons/io5";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#fff",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 280,
    fontSize: theme.typography.pxToRem(12),
    // border: "1px solid #dadde9",
    boxShadow: "0 0 10px  #818181",

    marginLeft: "3rem",
  },
}));

//this is the tooltip component to display the data of the tooltip

function HtmlToolTip({ content, liveFooter: LiveFooter }) {
  const title = typeof content === "object" ? content?.title : "";
  const body = typeof content === "object" ? content?.text : content;
  return (
    <>
      <HtmlTooltip
        title={
          <React.Fragment>
            <Box>
              {title && (
                <Box sx={{ textAlign: "center", color: "#818181", mb: 1 }}>
                  <Typography variant="toolTipHeader">{title}</Typography>
                </Box>
              )}
              <Box sx={{ textAlign: "center" }}>
                <Typography variant="toolTip" sx={{ color: "#818181" }}>
                  {body}
                </Typography>
              </Box>
              <Box>{LiveFooter && <LiveFooter />}</Box>
            </Box>
          </React.Fragment>
        }
        placement="bottom-start"
      >
        <span style={{ display: "inline-block" }}>
          <IoAlertCircleOutline
            style={{
              fontSize: "1rem",
              marginLeft: "1rem",
              color: "#1378A5",
            }}
          />
        </span>
      </HtmlTooltip>{" "}
    </>
  );
}

export default HtmlToolTip;
