import React from 'react';
import './forgotpassword.scss';
import Logo from '../../images/bpoVerticalLogo.webp';
import Input from '../../components/Input/Input';
import Button from '../../components/Button/Button';
import { ReactComponent as Back } from '../../images/back.svg';
import { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import Spinner from '../../components/Spinner/Spinner';
import { URL } from '../../constants';
import { useDispatch } from 'react-redux';
import SEO from '../../components/SEO';

function ForgotPassword() {
	const navigate = useNavigate();
	const { id, token } = useParams();

	const handleSubmit = (e) => {
		e.preventDefault();
		if (id && token) {
			resetPasswordConfirm();
		} else {
			getEmailToReset();
		}
	};

	const [isLoadingLocal, setIsLoadingLocal] = useState(false);

	const [emailToReset, setEmailToReset] = useState('');
	const dispatch = useDispatch();
	const goToSignIn = () => {
		navigate('/');
		dispatch({ type: 'SET_MODAL', payload: true });
	};

	const getEmailToReset = () => {
		setIsLoadingLocal(true);
		axios
			.post(URL + 'core/reset-password/', {
				email: emailToReset,
				url: window.location.origin,
			})
			.then(() => {
				toast.success('Email sent successfully.Please check your email');
				setTimeout(() => {
					navigate('/');
				}, 1000);
			})
			.catch((err) => {
				let message = 'Could not send email';
				if ('data' in err.response?.data) {
					message = err.response?.data?.data;
				}
				toast.error(message);
			})
			.finally(() => {
				setIsLoadingLocal(false);
			});
	};

	const [new_password, setNew_password] = useState('');
	const [confirm_password, setConfirm_password] = useState('');
	const resetPasswordConfirm = () => {
		if (new_password !== confirm_password) {
			toast.error('Passwords do not match');
			return;
		}

		setIsLoadingLocal(true);
		axios
			.post(URL + 'auth/users/reset_password_confirm/', {
				uid: id,
				new_password,
				token,
			})
			.then(() => {
				toast.success('Password reset successfully.Please login with new password');
				navigate('/');
			})
			.catch((err) => {
				if ('new_password' in err.response?.data || 'uid' in err.response?.data) {
					err.response?.data['uid']?.forEach((item) => {
						toast.error(item);
						return;
					});
					err.response?.data['new_password']?.forEach((item) => {
						toast.error(item);
					});
				} else {
					toast.error('Something went wrong');
					navigate('/');
				}
			})
			.finally(() => {
				setIsLoadingLocal(false);
			});
	};
	return (
		<>
			{isLoadingLocal && <Spinner />}
			<SEO>
				<title>BPOTech Password Reset</title>
				<meta name='og:title' content='BPOTech Password Reset' />
				<meta
					name='description'
					content='Reset your BPOTech account password easily with our secure password reset process. Protect your information and get back to using our services.'
				/>
				<meta
					name='og:description'
					content='Reset your BPOTech account password easily with our secure password reset process. Protect your information and get back to using our services.'
				/>
				<meta name='og:image' content={window.location.origin + Logo} />
			</SEO>
			<div className='forgotpassword'>
				<NavLink to='/'>
					<Back className='forgotpassword-back' />
				</NavLink>
				<form className='forgotpassword-content' onSubmit={handleSubmit}>
					<>
						<img src={Logo} alt='logo' style={{ width: '200px' }} />
						<h3>Forgot your password?</h3>
						<span>Don't worry. It is simple to change your password</span>
						<span>Simply enter the email address you used to register with BPO Homes</span>
						{token && id ? (
							<div style={{ width: '100%' }}>
								<Input
									label='New password'
									placeholder='New password'
									fullStyles={{ width: '100%' }}
									type='password'
									value={new_password}
									onChange={(e) => setNew_password(e.target.value)}
									required
								/>
								<Input
									label='Confirm password'
									placeholder='Confirm password'
									fullStyles={{ width: '100%' }}
									type='password'
									value={confirm_password}
									onChange={(e) => setConfirm_password(e.target.value)}
									required
								/>
							</div>
						) : (
							<Input
								label='Enter email'
								fullStyles={{ width: '100%' }}
								type='email'
								value={emailToReset}
								onChange={(e) => setEmailToReset(e.target.value)}
								required
							/>
						)}
						<Button
							style={{
								width: '100%',
								height: '45px',
								fontSize: '14px',
								padding: '10px 20px',
								color: 'white',
								backgroundColor: 'var(--primary-color)',
								display: 'block',
								marginTop: '20px',
							}}
							type='submit'
							text={token && id ? 'Reset password' : 'Send email'}
							// onClick={sendCode}
						/>
						<p>
							<span>Remember password? </span>
							<span onClick={goToSignIn}>Try logging in</span>
						</p>
					</>
				</form>
			</div>
		</>
	);
}

export default ForgotPassword;
