export const getTotal = (items) => {
  if (!items?.length) {
    return 0;
  }
  const res = items
    .map((item) => (+item?.value ? +item.value : 0))
    .reduce((a, b) => a + b);

  return res;
};



export const monthToYearsTransformer = (value, checker) => {
  if (checker && checker === 1) {
    return +value
  } else { return value / 12 }


}




