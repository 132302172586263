import React from 'react';
import { Box, Card, CardContent, CardHeader, Grid, Typography } from '@mui/material';

import Check1 from '../../assets/checklist1.webp';
import Check2 from '../../assets/checklist2.webp';
import Check3 from '../../assets/checklist3.webp';
import { useNavigate } from 'react-router-dom';

function ReadingCards() {
	const navigate = useNavigate();
	return (
		<Box mt={4} mb={5}>
			<Grid container spacing={4}>
				<Grid item xs={12} sm={12} lg={4} md={6}>
					<Card variant='elevation' sx={{ cursor: 'pointer', height: '100%' }} onClick={() => navigate('/bpo-guide')}>
						<CardHeader style={{ backgroundImage: `url(${Check1})`, backgroundSize: 'cover', height: '15rem' }}></CardHeader>
						<CardContent style={{ padding: '2rem' }}>
							<Typography color={'secondary'} mb={2}>
								Getting Started
							</Typography>
							<Typography variant='body1' color={'textSecondary'}>
								Why Buyers Need A Broker's Price Opinion
							</Typography>
						</CardContent>
					</Card>
				</Grid>
				<Grid item xs={12} sm={12} lg={4} md={6}>
					<Card variant='elevation' sx={{ cursor: 'pointer', height: '100%' }} onClick={() => navigate('/buyer-score')}>
						<CardHeader style={{ backgroundImage: `url(${Check2})`, backgroundSize: 'cover', height: '15rem' }}></CardHeader>
						<CardContent style={{ padding: '2rem' }}>
							<Typography color={'secondary'} mb={2}>
								Getting Started
							</Typography>
							<Typography variant='body1' color={'textSecondary'}>
								How to Raise Your Credit Score to Get Your First Home Mortgage
							</Typography>
						</CardContent>
					</Card>
				</Grid>
				<Grid item xs={12} sm={12} lg={4} md={12}>
					<Card variant='elevation' sx={{ cursor: 'pointer', height: '100%' }} onClick={() => navigate('/buyer-checklist')}>
						<CardHeader style={{ backgroundImage: `url(${Check3})`, backgroundSize: 'cover', height: '15rem' }}></CardHeader>
						<CardContent style={{ padding: '2rem' }}>
							<Typography color={'secondary'} mb={2}>
								Getting Started
							</Typography>
							<Typography variant='body1' mb={3.5} color={'textSecondary'}>
								Buying A Home in 2022?
							</Typography>
						</CardContent>
					</Card>
				</Grid>
			</Grid>
		</Box>
	);
}

export default ReadingCards;
