import { combineReducers } from 'redux';
import authReducer from './authReducer';
import modalReducer from './modalReducer';
import sidebarReducer from './sidebarReducer';
import subscriptionReducer from './subscriptionReducer';
import straightAestheticRemodelReducer from './dealAnalyzerReducers/straightAestheticRemodelReducer';
import dealAnalyzerQuestionnaireReducer from './dealAnalyzerReducers/dealAnalyzerQuestionnaireReducer';
import dealReportsReducer from './dealAnalyzerReducers/dealReportsReducer';

export default combineReducers({
	modals: modalReducer,
	sidebar: sidebarReducer,
	auth: authReducer,
	subscriptions: subscriptionReducer,
	straightAestheticRemodel: straightAestheticRemodelReducer,
	dealAnalyzerQuestionnaires: dealAnalyzerQuestionnaireReducer,
	reports:dealReportsReducer
});

