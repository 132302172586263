export const validateMortgageTerms = ({fullTerm, fullTermUnit, interestOnlyTerm, interestOnlyTermUnit})=>{

    if (+fullTermUnit === 1){
        fullTerm = +fullTerm * 12
    }
    if(+interestOnlyTermUnit === 1){
        interestOnlyTerm = +interestOnlyTerm * 12
    }

    return +interestOnlyTerm > +fullTerm

}