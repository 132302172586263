import React from "react";
import {
    Box,

    Table,
    TableContainer,
    Typography,
    TableRow,
    TableHead,
    TableCell,
    TableBody,
    InputBase,
    FormControl,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

//import { removeNaNAndInfinite } from "../../../../../pages/DealAnalyzer/ViewDealAnalyzerReports/utils";
import commaSeparate from "../../../utils/commaSeparate";
import '../../../ProFormaReport/printStyle.module.css'
const useStyles = (theme) => {
    return {
        parentTable: {
            border: `solid 2px ${theme.palette.primary.main}`,
            borderRadius: "5px",
        },
        table: {
            width: "95%",
            // border: `solid 1px ${theme.palette.primary.main}`,
            borderTop: "none",
            fontSize: "1.5625rem",
            tableLayout: "fixed",
        },
        cell0: {
            width: "9.37rem !important",
            border: `solid 1px ${theme.palette.primary.main}`,
            borderTop: "none !important",
            backgroundColor: "rgb(19, 120, 165,0.1)",
        },
        cells: {
            width: "6.325rem !important",
            border: `solid 1px ${theme.palette.primary.main}`,
            borderTop: "none !important",
            backgroundColor: "rgb(19, 120, 165,0.1)",
        },
        bodyCells: {
            border: `solid 1px ${theme.palette.primary.main}`,
            //  backgroundColor: "rgb(19, 120, 165,0.1)",
        },
        bodyCellsNoBg: {
            border: `solid 1px ${theme.palette.primary.main}`,
            // backgroundColor: "rgb(19, 120, 165,0.1)",
        },
        bodyCellsError: {
            border: `solid 1px ${theme.palette.primary.main}`,
            backgroundColor: "rgb(224, 0, 52,0.1)",
        },
        topTableCells: {
            display: "flex",
            alignItems: "center",
            width: "100%",
            border: `solid 1px ${theme.palette.primary.main}`,
            borderRadius: "10px",
            marginTop: "5px",
        },
        topTableCellsNoCheckbox: {
            display: "flex",
            alignItems: "center",
            width: "100%",
            border: `solid 1px ${theme.palette.primary.main}`,
            borderRadius: "5px",
            marginTop: "5px",
            padding: "5px",
        },
        topTableCellsNoCheckboxError: {
            display: "flex",
            alignItems: "center",
            width: "100%",
            border: `solid 1px red`,
            borderRadius: "5px",
            marginTop: "5px",
            padding: "5px",
        },
        topTableCellsheader: {
            display: "flex",
            alignItems: "center",
            width: "100%",
            margin: "5px 0",
        },
        check: {
            width: "6%",
        },
        address: {
            width: "54%",
            height: '40px',
            cursor: "pointer",
            display: 'flex',
            alignItems: 'center',
            paddingLeft: '10px'

        },
        date: {
            width: "40%",
            cursor: "pointer",
        }, imageContainer: {
            display: 'flex',
            justifyContent: 'start',
            width: '68rem',
            padding: '0.5rem'
        }
        ,
        imageBox: {
            display: 'flex',
            alignItems: 'center',

        }, image: {
            '@media print': {
                width: '220px'
            },
            height: '200px',
            margin: '0.5rem',
            borderRadius: '0.5rem'
        },
        description: {
            width: '67rem'
        }
        ,
        element: {
            breakInside: 'avoid', /* Prevent the element from breaking inside */
            pageBreakInside: 'avoid', /* For older browsers */
        }
    };
};

function ZillowCompTableForReport({
    list,
    noCheckbox,

    checked,

    fill,

    index,
    handleUpdate,
    errors,
}) {

    const theme = useTheme();
    const styles = useStyles(theme);




    // const filterComma = (e) => {
    //     let value = e.target.value;
    //     if (value) {
    //         value = Number.parseInt(value.split(",").join(""));
    //         if (isNaN(value)) {
    //             return "";
    //         }
    //         e.target.value = value;
    //         return e;
    //     } else {
    //         return e;
    //     }
    // };



    return (
        <Box className="unbreakable" sx={styles.element} >
            <Box
                sx={
                    noCheckbox && errors
                        ? styles.topTableCellsNoCheckboxError
                        : noCheckbox && !errors
                            ? styles.topTableCellsNoCheckbox
                            : styles.topTableCells
                }
            >

                <Box sx={styles.address} >
                    <Typography variant="h4">{list?.address}</Typography>
                </Box>
                <Box sx={styles.date}>
                    {errors?.errorMessage ? (
                        <Typography variant="h5" sx={{ color: "red" }}>
                            {errors?.errorMessage}
                        </Typography>
                    ) : (
                        <Typography variant="h5">
                            {list?.close_date && list?.close_date.slice(0, 11)}
                        </Typography>
                    )}
                </Box>

            </Box>


            <TableContainer
                sx={{ display: "flex", justifyContent: "center", marginTop: "0rem" }}
            >
                <Table sx={styles.table} aria-label="simple table" size="medium">
                    <TableHead>
                        <TableRow sx={styles.rows}>
                            {headers.map((header, index) => (
                                <TableCell
                                    align="left"
                                    scope="row"
                                    sx={index === 0 ? styles.cell0 : styles.cells}
                                >
                                    <Typography variant="h4" sx={{ width: "6.25rem" }}>
                                        {header}
                                    </Typography>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    {(
                        <TableBody>
                            <TableRow sx={styles.rows}>
                                <TableCell component="th" scope="row" sx={styles.bodyCells}>

                                    <Typography variant="h4">
                                        {list?.price_per_gross_living_area}
                                    </Typography>{" "}
                                </TableCell>
                                {customCompNames.map((element) => (
                                    <TableCell
                                        component="th"
                                        scope="row"
                                        sx={
                                            errors && errors?.missingFields.includes(element?.name)
                                                ? styles.bodyCellsError
                                                : fill
                                                    ? styles.bodyCells
                                                    : styles.bodyCellsNoBg
                                        }
                                        key={element?.name}
                                    >
                                        <FormControl sx={{ display: "flex" }}>
                                            <InputBase
                                                name={element?.name}
                                                placeholder={element?.placeholder}
                                                readOnly

                                                value={element?.name === 'price' ? commaSeparate(list?.[element?.name]) : list?.[element?.name]}
                                                sx={{
                                                    fontSize: "1rem",
                                                }}
                                            />
                                        </FormControl>
                                    </TableCell>
                                ))}
                            </TableRow>
                            <Box sx={styles.description}>
                                <Typography variant="h4" sx={{ marginTop: '0.3rem' }} >Property Description</Typography>
                                <Typography variant="h5" sx={{ marginTop: '0.2rem' }}>
                                    {list?.description}
                                </Typography>{" "}
                            </Box>
                            <TableRow sx={styles.rows}>
                                <Typography variant="h4" sx={{ marginTop: '0.3rem' }} >Property Images</Typography>
                                <Box style={styles.imageContainer}>

                                    <Box sx={styles.imageBox}>
                                        {list?.images?.map(element => (
                                            <Box component='img' src={`${element}`} sx={styles.image} alt="zillow" />
                                        ))}
                                    </Box>
                                </Box>

                            </TableRow>
                        </TableBody>
                    )}
                </Table>
            </TableContainer>

        </Box>
    );
}

export default ZillowCompTableForReport;

const headers = ["$PSQFT", "PRICE", "Beds", "Bathrooms", "Year", "SQFT", "Lot size"];

const customCompNames = [
    { name: "sales_price", placeholder: "Price", required: true },
    { name: "num_bedrooms", placeholder: "Bedrooms" },
    { name: "num_bathrooms", placeholder: "Bathrooms" },
    { name: "year_built", placeholder: "Year Built" },
    { name: "gross_living_area", placeholder: "Square Footage" },
    { name: "lot_size", placeholder: "Lot Size" },
];
// const compNames = [
//     { name: "price_per_gross_living_area", placeholder: "Price", },
//     { name: "sales_price", placeholder: "Price", required: true },
//     { name: "bedrooms", placeholder: "Bedrooms" },
//     { name: "bathrooms", placeholder: "Bathrooms" },
//     { name: "year_built", placeholder: "Year Built" },
//     { name: "sqft", placeholder: "Square Footage" },
//     { name: "lot_size", placeholder: "Lot Size" },
// ];


