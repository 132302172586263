import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/system';
import { Button, Switch, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import Spinner from '../../components/Spinner/Spinner';
import base from '../../apis';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
const useStyles = makeStyles((theme) => ({
	root: {
		padding: '20px',
		minHeight: '60vh',
	},
	dimmed: {
		margin: '5px 0',
		color: grey['A700'],
	},
}));

const notifs = ['premier_leads_notification', 'report_notification', 'live_leads_notification'];

function LeadsNotification() {
	const classes = useStyles();
	const agent_id = useSelector((state) => state.auth.typeId);

	useEffect(() => {
		setIsLoading(true);
		base
			.get(`register/leads_notification/${agent_id}/`)
			.then(({ data }) => {
				setOptions(data);
			})
			.catch((err) => {
				toast.warn('Could not get notification status');
			})
			.finally(() => {
				setIsLoading(false);
			});
	}, [agent_id]);
	const [isLoading, setIsLoading] = useState(false);
	const [options, setOptions] = useState(null);

	const handleChange = (e) => {
		const { name } = e.target;
		const item = options[name];
		let toSet = false;
		if (!item) {
			toSet = true;
		}
		setOptions((cur) => ({ ...cur, [name]: toSet }));
	};

	const handleSave = () => {
		setIsLoading(true);
		base
			.put(`register/leads_notification/${agent_id}/`, options)
			.then(({ data }) => {
				toast.success('Notification status updated');
			})
			.catch((err) => {
				toast.warn('Could not update notification status');
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	return (
		<>
			{isLoading && <Spinner />}
			<Box className={classes.root}>
				<Typography paragraph sx={{ fontWeight: 700, fontSize: '16px', textAlign: 'right' }}>
					Call Sales: 1 888 616 5270
				</Typography>
				<Typography paragraph sx={{ fontWeight: 700, fontSize: '18px' }}>
					Notifications
				</Typography>
				<Typography variant='body2' className={classes.dimmed}>
					We will continue to send you important account notifications despite your notification settings
				</Typography>
				<Typography paragraph sx={{ fontWeight: 700, fontSize: '16px', marginBottom: '5px' }}>
					Leads
				</Typography>
				<Typography variant='body2' className={classes.dimmed}>
					These are email notifications for the various leads available
				</Typography>
				{notifs.map((option, index) => (
					<Box display='flex' alignItems='center' justifyContent='space-between' key={option} my='15px'>
						<Typography sx={{ fontSize: '16px', textTransform: 'capitalize' }}>{option.split('_').join(' ')}</Typography>
						<Switch color='secondary' name={option} checked={options?.[option] || false} onChange={handleChange} />
					</Box>
				))}

				<Button
					color='secondary'
					variant='contained'
					sx={{ marginLeft: 'auto !important', width: 'fit-content', display: 'block' }}
					onClick={handleSave}
				>
					Save
				</Button>
			</Box>
		</>
	);
}

export default LeadsNotification;
