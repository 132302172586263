import FinanceOptions from "../../../components/DealAnalyzerComponents/userInputCards/financeOptions/FinanceOptions";
import PropertyPermitFees from "../../../components/DealAnalyzerComponents/userInputCards/PropertyPermitFees";
import Taxes from "../../../components/DealAnalyzerComponents/userInputCards/Taxes";
import ClosingCost from "../../../components/DealAnalyzerComponents/userInputCards/ClosingCost";
import RentalIncome from "../../../components/DealAnalyzerComponents/userInputCards/RentalIncome";
import PropertyManagement from "../../../components/DealAnalyzerComponents/userInputCards/PropertyManagement";
import OtherCosts from "../../../components/DealAnalyzerComponents/userInputCards/OtherCosts";
import CarryingMonths from "../../../components/DealAnalyzerComponents/userInputCards/CarryingMonths";
import EstimatedResaleValue from "../../../components/DealAnalyzerComponents/userInputCards/EstimatedResaleValue/EstimatedResaleValue";
import InvestorProfit from "../../../components/DealAnalyzerComponents/userInputCards/InvestorProfit";
import * as actionCreators from "../../../actions/dealAnalyzer/index";
import RemodelCostEnhancedAlt2 from "../../../components/DealAnalyzerComponents/userInputCards/remodelCost/RemodelCostEnhancedAlt2";
import PropertyInformationEnhanced from "../../../components/DealAnalyzerComponents/userInputCards/PropertyInformationEnhanced";
import SummaryText from "../../../components/DealAnalyzerComponents/userInputCards/SummaryText";
export const straightAestheticRemodelArray = (
  dispatch,
  straightAestheticRemodelData,
  timeTrigger
) => {
  const {
    propertyInformation,
    financeOptions,
    propertyPermitAndFees,
    taxes,
    remodelCosts,
    closingCosts,
    propertyManagement,
    rentalIncome,
    otherCosts,
    carryingMonths,
    estimatedResaleValue,
    investorProfit,
    summaryText
  } = straightAestheticRemodelData;

  return [
    {
      component: PropertyInformationEnhanced,
      data: propertyInformation,
      handler: function (value) {
        dispatch(actionCreators.updatePropertyInformation(value));
        timeTrigger();
      },
    },
    {
      component: RemodelCostEnhancedAlt2,
      data: remodelCosts,
      handler: function (value) {
        dispatch(actionCreators.updateRemodelCosts(value));
        timeTrigger();
      },
    },
    {
      component: FinanceOptions,
      data: financeOptions,
      handler: function (value) {
        dispatch(actionCreators.updateFinanceOptions(value));
        timeTrigger();
      },
    },
    {
      component: PropertyPermitFees,
      data: propertyPermitAndFees,
      handler: function (value) {
        dispatch(actionCreators.updatePropertyPermitAndFees(value));
        timeTrigger();
      },
    },
    {
      component: Taxes,
      data: taxes,
      handler: function (value) {
        dispatch(actionCreators.updateTaxes(value));
        timeTrigger();
      },
    },
    {
      component: RentalIncome,
      data: rentalIncome,
      handler: function (value) {
        dispatch(actionCreators.updateRentalIncome(value));
        timeTrigger();
      },
    },
    {
      component: PropertyManagement,
      data: propertyManagement,
      handler: function (value) {
        dispatch(actionCreators.updatePropertyManagement(value));
        timeTrigger();
      },
    },
    {
      component: OtherCosts,
      data: otherCosts,
      handler: function (value) {
        dispatch(actionCreators.updateOtherCosts(value));
        timeTrigger();
      },
    },
    {
      component: CarryingMonths,
      data: carryingMonths,
      handler: function (value) {
        dispatch(actionCreators.updateCarryingMonths(value));
        timeTrigger();
      },
    },
    {
      component: EstimatedResaleValue,
      data: estimatedResaleValue,
      handler: function (value) {
        dispatch(actionCreators.updateEstimatedResaleValue(value));
        timeTrigger();
      },
    },
    {
      component: ClosingCost,
      data: closingCosts,
      handler: function (value) {
        dispatch(actionCreators.updateClosingCosts(value));
        timeTrigger();
      },
    },
    {
      component: InvestorProfit,
      data: investorProfit,
      handler: function (value) {
        dispatch(actionCreators.updateInvestorProfit(value));
        timeTrigger();
      },
    },
    {
      component: SummaryText,
      data: summaryText,
      handler: function (value) {
        dispatch(actionCreators.updateSummaryText(value));
        timeTrigger();
      },
    },
  ];
};
