import React from 'react'
import { Box, Typography } from '@mui/material'
import useTheme from "@mui/material/styles/useTheme";

const useStyles = (theme)=>{
  return {
    root:{
      display:"flex",
      alignItems:"center",
      justifyContent:"space-between",
      color: theme.palette.primary.main,
    },
    barRoot:{ 
      display:"flex",
      width:"80%",
      '& :first-child':{
        borderRadius:"1rem 0 0 1rem",
        margin:"20px 5px 20px 0",

      },
      '& :last-child':{
        borderRadius:"0 1rem 1rem 0",
        margin:"20px 0 20px 5px",

      }
    },
    activeBar:{
    backgroundColor:theme.palette.primary.main,
    margin:"20px 8px",
    width:"56px",
    height:"7px",
  },
    inActiveBar:{
    backgroundColor:theme.palette.primary.inactive,
    margin:"20px 8px",
    width:"56px",
    height:"7px",
  },
  firstBar:{
    borderRadius:"1rem 0 0 1rem"
  },
  lastBar:{
    borderRadius:"0 1rem 1rem 0"
  },
  pageCounter:{
    width:"80%",
    textAlign:"right",
  }

}

}


function FiniteLinearProgressBar({menuArray,active}) {
  const theme = useTheme()
  const styles = useStyles(theme)
  
  return (
    <Box sx={styles.root}>
    <Box sx={styles.barRoot}>
      {
        menuArray && menuArray.map((element, index)=>(
          <Box sx = {index === active? styles.activeBar : styles.inActiveBar} key = {element}></Box>
        ))
      }
      
    </Box>
    <Box sx={styles.pageCounter}>
    <Typography variant = 'h4' sx={{fontWeight:"700"}}>
      {`${+active + 1} of ${menuArray.length}` }
    </Typography>
    </Box>

    </Box>
  )
}

export default FiniteLinearProgressBar