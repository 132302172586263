import React from 'react'
import ProformastandardRow from './RowTypes/ProformastandardRow';
import ProformaStartAdornRow from './RowTypes/ProformaStartAdornRow';
import ProformaEndAdornRow from './RowTypes/ProformaEndAdornRow';
import ProformaPointsRow from './RowTypes/ProformaPointsRow';

function ComponentSelector(props) {

    switch (props.render) {

        case "simple": return <ProformastandardRow item={props.item} value={props.value} printVersion={props?.printVersion} />;
        case 'dollar': return <ProformaStartAdornRow item={props.item} value={props.value} symbol='$' printVersion={props?.printVersion} />
        case 'sqft': return <ProformaEndAdornRow item={props.item} value={props.value} symbol='sqft' printVersion={props?.printVersion} />
        case 'persqft': return <ProformaEndAdornRow item={props.item} value={props.value} symbol='per sqft' printVersion={props?.printVersion} />
        case 'percent': return <ProformaEndAdornRow item={props.item} value={props.value} symbol='%' printVersion={props?.printVersion} convert />
        case 'months': return <ProformaEndAdornRow item={props.item} value={props.value} symbol='months' printVersion={props?.printVersion} />
        case 'years': return <ProformaEndAdornRow item={props.item} value={props.value} symbol='years' printVersion={props?.printVersion} />
        case 'points': return <ProformaEndAdornRow item={props.item} value={props.value} symbol='pts' printVersion={props?.printVersion} />
        case 'percent_dollar': return <ProformaPointsRow item={props.item} points={props.value} rate={props?.rate} referenceValue={props?.referenceValue} symbol='%' printVersion={props?.printVersion} />
        default: return <div></div>
    }

}

export default ComponentSelector