import React from "react";
import {
  TableContainer,
  Table,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
  Box,
  FormControl,
  Typography,
  InputBase,
  TextField,
} from "@mui/material";
import { ClipLoader } from "react-spinners"
import { useTheme } from '@mui/material/styles'
import { greyout } from "../utils/greyout";
import commaSeparate from "../utils/commaSeparate";
import RegularToolTip from "./RegularToolTip";
import Delete from "@mui/icons-material/Delete";

const useStyles = (_theme) => {
  return {
    root: {
      display: "flex"
    },// 
    table: {
      width: "100%",
      fontSize: "1.2rem",
      boxSizing: "border-box",
    },
    headCell1: {
      width: "30% !important",
      borderBottom: "solid 1px #000",
      boxSizing: "border-box"


    },
    headChildren12: {
      borderRight: "solid 1px #000",
      borderBottom: "solid 1px #000",
      borderLeft: "solid 1px #000",
      boxSizing: "border-box",
      // width: "15% !important", 

    },

    headCell23: {
      width: "25% !important",
      borderRight: "solid 1px #000",
      borderTop: "solid 1px #000",
      borderLeft: "solid 1px #000",
      boxSizing: "border-box",
      fontWeight: 600
    },
    headCell23Alt: {
      width: "18% !important",
      borderRight: "solid 1px #000",
      borderTop: "solid 1px #000",
      borderLeft: "solid 1px #000",
      boxSizing: "border-box",
      fontWeight: 200
    }
  }
}

function InvestorTable({ updateTable, investors, rate, loading, netProfit, totalExpense, convertToAmount, handleDeleteRow }) {
  const theme = useTheme()
  const styles = useStyles(theme)
  const errorLCC = investors?.length ? investors.map(investor => +investor?.equity ? +investor.equity : 0).reduce((a, b) => a + b) > 100 : false
  const errorIPC = investors?.length ? investors.map(investor => +investor?.profit ? +investor.profit : 0).reduce((a, b) => a + b) > 100 : false
  const clearZero = (input) => {
    return commaSeparate(input)
  }
  return (
    <Box sx={styles.root}>
      <TableContainer sx={{ width: "100%", display: "flex", flexWrap: "no-wrap" }}>
        <Table sx={styles.table}>
          {investors?.length ? (
            <TableHead>
              <TableRow>
                <TableCell sx={styles.headCell1}>
                  <Typography variant="h4"></Typography>
                </TableCell>
                <TableCell
                  sx={!+rate ? { ...styles.headCell23, borderBottom: "solid 1px" } : { ...styles.headCell23Alt, borderBottom: "solid 1px" }}
                >
                  <RegularToolTip content={"Liquid Capital Contribution"}>
                    <>
                      {errorLCC && (
                        <Typography
                          variant="h6"
                          sx={{ backgroundColor: "pink", padding: "2px" }}
                        >
                          {+rate
                            ? "Total Percentage exceeds 100% !"
                            : "Total equity exceeds Total Liquid Capital Required! "}
                        </Typography>
                      )}
                      <Typography
                        variant="h4"
                        sx={{ fontWeight: 700, whiteSpace: "nowrap" }}
                      >
                        LCC{+rate === 1 ? '(%)' : '($)'}
                      </Typography>
                    </>
                  </RegularToolTip>
                </TableCell>
                <TableCell
                  sx={!+rate ? { ...styles.headCell23, borderBottom: "solid 1px" } : { ...styles.headCell23Alt, borderBottom: "solid 1px" }}
                >
                  <RegularToolTip content={"Investor Profit Share"}>
                    <>
                      {errorIPC && (
                        <Typography
                          variant="h6"
                          sx={{ backgroundColor: "pink", padding: "2px" }}
                        >
                          {+rate
                            ? "Total Percentage exceeds 100% !"
                            : "Total Profit share exceeds Estimated Net Profit! "}
                        </Typography>
                      )}
                      <Typography
                        variant="h4"
                        sx={{ fontWeight: 700, whiteSpace: "nowrap" }}
                      >
                        IPS {+rate === 1 ? '(%)' : '($)'}
                      </Typography>
                    </>
                  </RegularToolTip>{" "}
                </TableCell>
                <TableCell
                  sx={!+rate ? { ...styles.headCell23, borderBottom: "solid 1px" } : { ...styles.headCell23Alt, borderBottom: "solid 1px" }}
                >
                  <RegularToolTip content={"Return on Investment"}>
                    <Typography
                      variant="h4"
                      sx={{ fontWeight: 700, whiteSpace: "nowrap" }}
                    >
                      ROI
                    </Typography>
                  </RegularToolTip>
                </TableCell>
                <TableCell
                  sx={!+rate ? { ...styles.headCell23, borderBottom: "solid 1px" } : { ...styles.headCell23Alt, borderBottom: "solid 1px" }}
                >
                  <RegularToolTip content={"Return on Investment"}>
                    <Typography
                      variant="h4"
                      sx={{ fontWeight: 700, whiteSpace: "nowrap" }}
                    >
                      ROI <span style={{ fontSize: "0.8rem" }}>(Annual)</span>
                    </Typography>
                  </RegularToolTip>
                </TableCell>
              </TableRow>
            </TableHead>
          ) : (
            ""
          )}
          <TableBody>
            {investors &&
              investors?.map((investor, index) => (
                <TableRow key={investor?.investor + index}>
                  <TableCell sx={styles.headChildren12}>
                    {/* <Typography variant="tableRow">{row}</Typography> */}
                    <FormControl
                      sx={{
                        width: "100%",
                      }}
                    >
                      <TextField
                        // sx={{'& ::placeholder': {
                        //     float:"right"
                        // }}}
                        margin="small"
                        name="investor"
                        size="small"
                        value={investor?.investor}
                        inputProps={{ style: { fontSize: "1rem" } }}
                        placeholder=" Enter Investor Name"
                        onChange={(e) => updateTable(e, index)}
                        autoFocus
                      />
                    </FormControl>
                  </TableCell>

                  {/* LCC */}
                  <TableCell sx={{ border: "solid 1px #000" }}>
                    {/* <Typography variant="tableRow">{row}</Typography> */}
                    <FormControl
                      sx={{
                        width: "100%",
                      }}
                    >
                      <InputBase
                        // sx={{'& ::placeholder': {
                        //     float:"right"
                        // }}}
                        margin="small"
                        name={"equity"}
                        size="small"
                        value={
                          +rate
                            ? clearZero(investor?.equity)
                            :
                            clearZero(convertToAmount(investor?.equity, totalExpense))

                        }
                        inputProps={{ style: { fontSize: "1.125rem" } }}
                        placeholder="Enter Investor Equity"
                        onChange={(e) => updateTable(e, index, true)}
                      // startAdornment={
                      //   <InputAdornment position="start">
                      //     {+rate === 0 && (
                      //       <Typography
                      //         variant="adornment"
                      //         color="secondary"
                      //         sx={{
                      //           padding: "5px",
                      //           marginBottom: "5px",
                      //         }}
                      //       >
                      //         $
                      //       </Typography>
                      //     )}
                      //   </InputAdornment>
                      // }
                      // endAdornment={
                      //   <InputAdornment position="start">
                      //     {+rate === 1 && (
                      //       <Typography variant="adornment" color="secondary">
                      //         %
                      //       </Typography>
                      //     )}
                      //   </InputAdornment>
                      // }
                      />
                    </FormControl>
                  </TableCell>

                  {/* IPS */}
                  <TableCell sx={{ border: "solid 1px #000" }}>
                    {/* <Typography variant="tableRow">{row}</Typography> */}
                    <FormControl
                      sx={{
                        width: "100%",
                      }}
                    >
                      <InputBase
                        // sx={{'& ::placeholder': {
                        //     float:"right"
                        // }}}
                        margin="small"
                        name={"profit"}
                        size="small"
                        value={
                          +rate
                            ? clearZero(investor?.profit)
                            : commaSeparate(
                              convertToAmount(investor?.profit, netProfit)
                            )
                        }
                        inputProps={{ style: { fontSize: "1rem" } }}
                        placeholder="Enter Profit"
                        onChange={(e) => updateTable(e, index, true)}
                      // startAdornment={
                      //   <InputAdornment position="start">
                      //     {+rate === 0 && (
                      //       <Typography
                      //         variant="adornment"
                      //         color="secondary"
                      //         sx={{
                      //           padding: "5px",
                      //           marginBottom: "5px",
                      //         }}
                      //       >
                      //         $
                      //       </Typography>
                      //     )}
                      //   </InputAdornment>
                      // }
                      // endAdornment={
                      //   <InputAdornment position="start">
                      //     {+rate === 1 && (
                      //       <Typography variant="adornment" color="secondary">
                      //         %
                      //       </Typography>
                      //     )}
                      //   </InputAdornment>
                      // }
                      />
                    </FormControl>
                  </TableCell>

                  <TableCell
                    sx={{
                      ...styles.headChildren12,
                      borderRight: "solid 1px #000",
                      ...greyout,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      {!loading && (
                        <>
                          <Box sx={{ width: "80%" }}>
                            <Typography variant="h5">
                              {!+investor?.roi || !isFinite(+investor?.roi)
                                ? 0
                                : investor?.roi}
                            </Typography>
                          </Box>
                          {
                            <Box>
                              <Typography variant="adornment" color="secondary">
                                %
                              </Typography>
                            </Box>
                          }
                        </>
                      )}
                      {loading && (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            width: "100%",
                          }}
                        >
                          <ClipLoader
                            color="grey"
                            size={12}
                            speedMultiplier={0.5}
                          />
                        </Box>
                      )}
                    </Box>
                  </TableCell>

                  <TableCell
                    sx={{
                      ...styles.headChildren12,
                      borderRight: "solid 1px #000",
                      ...greyout,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      {!loading && (
                        <>
                          <Box sx={{ width: "80%" }}>
                            <Typography variant="h5">
                              {!+investor?.roi_a || !isFinite(+investor?.roi_a)
                                ? 0
                                : investor?.roi_a}
                            </Typography>
                          </Box>
                          {
                            <Box>
                              <Typography variant="adornment" color="secondary">
                                %
                              </Typography>
                            </Box>
                          }
                        </>
                      )}

                    </Box>
                  </TableCell>

                  <TableCell>
                    <Box>
                      <RegularToolTip content='Delete Row'>
                        <Delete sx={{ color: "#1378A5", fontSize: "1.2rem", cursor: "pointer" }} onClick={() => handleDeleteRow(index)} />
                      </RegularToolTip>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default InvestorTable;
