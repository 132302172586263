import React, { useState, useEffect } from 'react';
import './listingtemplate.scss';
import { ReactComponent as Email } from '../../../assets/email.svg';
import { ReactComponent as Call } from '../../../assets/local_phone.svg';
import { ReactComponent as ListPrice } from '../../../images/listingPrice.svg';
import { ReactComponent as SquareFeet } from '../../../images/squareFootage.svg';
import { ReactComponent as Year } from '../../../images/year.svg';
import { ReactComponent as Bath } from '../../../images/bath.svg';
import { ReactComponent as Bed } from '../../../images/bed.svg';
import { ReactComponent as CloseIcon } from '../../../images/Close.svg';
import { ReactComponent as Applelogo } from '../../../assets/apple-logo.svg';
import { ReactComponent as Notice } from '../../../assets/notice.svg';
import { toast } from 'react-toastify';
import Footer from '../../../components/Footer/Footer';
import Button from '../../../components/Button/Button';
import Input from '../../../components/Input/Input';
import Modal from '../../../components/Modal/Modal';
import { useNavigate, useParams } from 'react-router-dom';
import Spinner from '../../../components/Spinner/Spinner';

import { formatNumber, formatPhoneNumber, getSubscriptionStatus, getYoutubeEmbedLink, removeUnderandCapitalize } from '../../../utils/functions';
import {
	AppBar,
	Box,
	IconButton,
	Toolbar,
	Drawer,
	Typography,
	Divider,
	List,
	ListItem,
	ListItemButton,
	ListItemText,
	Avatar,
	TextField,
	Dialog,
	DialogTitle,
	DialogActions,
	DialogContent,
	Grid,
	Button as MUIButton,
	Chip,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import axios from 'axios';
import { URL } from '../../../constants';
import Carousel from '../../../components/Carousel/Carousel';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
	EmailIcon,
	EmailShareButton,
	FacebookIcon,
	FacebookShareButton,
	LinkedinIcon,
	LinkedinShareButton,
	PinterestIcon,
	PinterestShareButton,
	TwitterIcon,
	TwitterShareButton,
} from 'react-share';
import { Close } from '@mui/icons-material';
import SEO from '../../../components/SEO';

const pageUrl = window.location.href;

const features = [
	'additional_area',
	'APN_formatted',
	'construction_type',
	'county_land_use',
	'style',
	'unit',
	'zoning',
	'census_block',
	'census_tract',
	'land_use',
	'legal_desc',
	'community',
	'county',
	'metropolitan_statistical_area',
	'municipality',
	'neighbourhood',
	'sub_neighbourhood',
	'subdivision',
	'elementary_school',
	'middle_school',
	'high_school',
	'elementary_school_district',
	'high_school_district',
	'air_conditioning',
	'basement',
	'breakfast_nook',
	'ceiling_fans',
	'central_forced_air',
	'dining_rooms',
	'eat_in_kitchen',
	'pantry',
	'extra_storage',
	'floor_type',
	'game_room',
	'high_end_appliances',
	'high_end_materials',
	'home_theatre',
	'garage',
	'two_car_garage',
	'wall_to_wall_carpeting',
	'barbecue',
	'bocce_ball',
	'covered_patio',
	'deck',
	'dog_run',
	'water_feature',
	'exterior_wall',
	'fire_pit',
	'hot_tub',
	'pool',
	'mature_landscaping',
	'parking_space',
	'parking_type',
	'exterior_garage_area',
	'exterior_garage_type',
	'exterior_patio',
	'porch_type',
	'roof_type',
	'roof_composition',
	'spacious_backyard',
	'storeys',
	'barbecue_area',
	'basketball_court',
	'gym',
	'hot_tub_hoa',
	'park',
	'playground',
	'tennis_court',
	'clubhouse',
	'greenbelt',
	'pool_hoa',
	'walking_trails',
];

const drawerWidth = 240;

function ListingTemplate() {
	const [addNewModalOpen, setAddNewModalOpen] = useState(false);
	const { agentId, listingId } = useParams();
	// let [dateCount, setDateCount] = useState(1);
	const [isLoading, setIsLoading] = useState(false);
	const [data, setData] = useState(null);
	const [listing, setListing] = useState(null);
	const navigate = useNavigate();
	const [contactDetails, setContactDetails] = useState({
		client_name: '',
		client_email: '',
		client_phone: '',
		client_message: '',
	});
	const matches = useMediaQuery('(min-width:1300px)');

	const handleContactDetails = (e) => {
		const { name, value } = e.target;
		setContactDetails((prevState) => ({
			...prevState,
			[name]: value,
		}));
	};

	const handleContactSubmission = (e) => {
		e.preventDefault();
		const { target } = e;
		if (!target.checkValidity()) {
			target?.reportValidity();
			return;
		}
		setIsLoading(true);
		axios
			.post(URL + 'register/contact_agent/', { ...contactDetails, agent_id: agentId })
			.then(() => {
				toast.success('Message sent successfully');
				setContactDetails({
					client_name: '',
					client_email: '',
					client_phone: '',
					client_message: '',
				});
				handleIncrement('contact');
			})
			.catch((err) => {
				toast.error('Could not send message');
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const [recDetails, setRecDetails] = useState({
		email: '',
		phone: '',
		name: '',
	});
	const [isRecLoading, setIsRecLoading] = useState(false);

	const handleRecDetails = (e) => {
		const { name, value } = e.target;
		setRecDetails((cur) => ({ ...cur, [name]: value }));
	};

	const [isRecModalOpen, setIsRecModalOpen] = useState(false);

	const sendRecEmail = () => {
		const { email, phone, name } = recDetails;
		if (!email || !phone) {
			toast.warn('Please fill in all the fields');
			return;
		}
		setIsRecLoading(true);

		axios
			.post(URL + 'register/contact_agent/', {
				client_email: email,
				client_phone: phone,
				client_name: name,
				client_message: `${name} just visisted your listing ${listing.address} and would like to see more properties.`,
				agent_id: agentId,
			})
			.then(() => {
				toast.success('Message sent successfully');
				setRecDetails({
					email: '',
					phone: '',
				});
				setIsRecModalOpen(false);
			})
			.catch((err) => {
				toast.error('Could not send message');
			})
			.finally(() => {
				setIsRecLoading(false);
			});
	};

	useEffect(() => {
		//check if user is at the bottom of the page
		const handleScroll = () => {
			const { scrollHeight, scrollTop, clientHeight } = document.documentElement;
			if (scrollTop + clientHeight >= scrollHeight) {
				setIsRecModalOpen(true);
			}
		};
		window.addEventListener('scroll', handleScroll);
		return () => window.removeEventListener('scroll', handleScroll);
	});

	/**
	 *
	 * @param {'page'|'contact'} type
	 * @returns
	 */
	const handleIncrement = (type) =>
		axios.post(URL + 'premiersite/page-contact-increment/', { listing_id: parseInt(listingId), category: type || 'page' });

	useEffect(() => {
		const fetchProfileInfo = async () => {
			//get agent details
			try {
				const { data } = await axios.get(URL + `register/agents/${agentId}/`);
				setData(data);
				const { user } = data;

				const res = await axios.get(URL + `register/subscriptions/`, { params: { user_id: user?.id } });
				const { premierAgentWebsite } = getSubscriptionStatus(res.data);
				if (!premierAgentWebsite) {
					navigate('/404', {
						replace: true,
					});
				}

				const { data: listingData } = await axios.get(URL + `premiersite/listings/${listingId}/`);
				setListing(listingData);
				axios.post(URL + 'premiersite/page-contact-increment/', { listing_id: parseInt(listingId), category: 'page' });
			} catch (err) {
				toast.warn('An error occurred');
			} finally {
				setIsLoading(false);
			}
		};

		fetchProfileInfo();
	}, [agentId, listingId, navigate]);

	const [mobileOpen, setMobileOpen] = React.useState(false);
	const [galleryOpen, setGalleryOpen] = useState(false);

	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};
	const title = listing?.address || 'BPO TECH';
	const description = listing?.description?.slice(0, 60) || 'Property listed on BPO Tech';
	const image = listing?.photos?.[0]?.image;
	const name = `${data?.first_name} ${data?.last_name}`;

	return (
		<>
			<SEO description={listing?.description?.slice(0, 60) || 'Property listed on BPO Tech'} image={listing?.photos?.[0]?.image}>
				<title>{listing?.address || 'BPO TECH'}</title>
				<meta name='description' content={description} />

				<meta property='og:type' content='website' />
				<meta property='og:title' content={title} />
				<meta property='og:description' content={description} />
				<meta property='og:image' content={image} />
				<meta property='og:image:alt' content={title} />
				<meta property='og:site_name' content='BPO TECH' />
				<meta property='og:locale' content='en_US' />

				<meta name='twitter:creator' content={name} />
				<meta name='twitter:card' content={'website'} />
				<meta name='twitter:title' content={title} />
				<meta name='twitter:text:title' content={title} />
				<meta name='twitter:description' content={description} />
				<meta name='twitter:image' content={image} />
				<meta name='twitter:image:alt' content={title} />
				<meta name='twitter:image:src' content={image} />
				<link rel='canonical' href={window.location.pathname} />
			</SEO>

			{(isRecLoading || isLoading) && <Spinner />}
			<Carousel
				open={galleryOpen}
				onClose={() => setGalleryOpen(false)}
				style={{ maxWidth: matches ? '60%' : '80%', maxHeight: '98vh', aspectRatio: '3/2' }}
			>
				{listing?.photos?.map((photo) => (
					<div key={photo.id}>
						<img src={photo.image} alt='homes' loading='lazy' style={{ width: '100%', objectFit: 'cover' }} />
					</div>
				))}
			</Carousel>
			<Dialog open={isRecModalOpen} onClose={() => setIsRecModalOpen(false)} sx={{ width: 'min(400px,90%)', margin: 'auto' }}>
				<DialogTitle>
					<Box display='flex' justifyContent='center' alignItems='center'>
						<Box display='flex' justifyContent='center' alignItems='center' mx='auto' gap='10px'>
							<Avatar
								src={data?.profile_image}
								alt='user'
								imgProps={{
									style: {
										objectFit: 'cover',
										objectPosition: 'top center',
									},
								}}
								sx={{
									width: '70px',
									height: '70px',
									marginX: {
										xs: 'auto',
										sm: 'auto',
										md: 'auto',
									},
								}}
							/>
						</Box>
						<IconButton onClick={() => setIsRecModalOpen(false)}>
							<Close fontSize='small' />
						</IconButton>
					</Box>
				</DialogTitle>
				<DialogContent>
					<Typography variant='h6' textAlign={'center'}>
						{data?.first_name} {data?.last_name}
					</Typography>
					<Typography variant='body2' color={'textSecondary'} textAlign={'center'}>
						{data?.brokerage_name || ''}
					</Typography>

					<Typography variant='body2' color={'textSecondary'} textAlign={'center'} mb={'1rem'}>
						License #: {data?.licenses.find((item) => item.category === 'real_estate_agent')?.number || ''}
					</Typography>

					<Typography variant='body2' textAlign={'center'} mb={1} fontWeight='500'>
						See more listings from {data?.first_name}
					</Typography>

					<Grid container spacing={2}>
						<Grid item lg={12} md={12} sm={12} xs={12}>
							<TextField placeholder='Name' size='small' type='email' name='name' fullWidth value={recDetails.name} onChange={handleRecDetails} />
						</Grid>
						<Grid item lg={12} md={12} sm={12} xs={12}>
							<TextField placeholder='Email' size='small' type='email' name='email' fullWidth value={recDetails.email} onChange={handleRecDetails} />
						</Grid>
						<Grid item lg={12} md={12} sm={12} xs={12}>
							<TextField placeholder='Phone' size='small' type='tel' name='phone' fullWidth value={recDetails.phone} onChange={handleRecDetails} />
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions sx={{ paddingRight: 3, paddingLeft: 3, display: 'flex', flexDirection: 'column' }}>
					<MUIButton fullWidth variant='contained' onClick={sendRecEmail} sx={{ color: 'white', textTransform: 'capitalize' }}>
						Send
					</MUIButton>
					<Typography variant='body2' textAlign={'center'} color='textSecondary' fontSize='11px' my={1}>
						Powered by BPOTECH
					</Typography>
				</DialogActions>
			</Dialog>
			<Modal style={{ minWidth: '60%' }} open={addNewModalOpen} onClose={() => setAddNewModalOpen(false)}>
				<div className='aomodal' style={{ height: 'unset', background: 'white', width: 'inherit' }}>
					<div
						className='modheader'
						style={{
							background: 'white',
							display: 'flex',
							justifyContent: 'space-between',
						}}
					>
						<p
							style={{
								fontSize: '24px',
								fontWeight: '700',
								color: 'black',
							}}
						>
							Schedule A Tour
						</p>
						<CloseIcon style={{ fill: 'black', cursor: 'pointer' }} width='18px' height='18px' onClick={() => setAddNewModalOpen(false)} />
					</div>
					<div className='annoffice'>
						<div className='modalbg'>
							<p>
								{listing?.address} {listing?.city},
								<br /> {listing?.state} {listing?.zip}
							</p>
						</div>
						<div
							className='buttons'
							style={{
								display: 'flex',
								width: '100%',
								margin: 'auto',
								gap: '20px',
							}}
						>
							<Button
								style={{
									border: '1px solid #1378a5',
									display: 'flex',
									justifyContent: 'space-evenly',
								}}
							>
								<Applelogo />
								Meet at home
							</Button>
							<Button
								style={{
									border: '1px solid #1378a5',
									display: 'flex',
									justifyContent: 'space-evenly',
								}}
							>
								<Applelogo />
								Meet via video call
							</Button>
						</div>
						<p className='notice'>
							<Notice /> &nbsp; Staying safe during <b> &nbsp;Covid-19</b>
						</p>
						<p className='date_info'>When would you like to meet with a BPO Homes Agent?</p>
						<p>Pick a time</p>
					</div>
				</div>
			</Modal>
			<div className='listingtemplate'>
				<Box sx={{ display: 'flex' }}>
					<AppBar component='nav' style={{ backgroundColor: 'white' }}>
						<Toolbar>
							<IconButton color='inherit' aria-label='open drawer' edge='start' onClick={handleDrawerToggle} sx={{ mr: 2, display: { sm: 'none' } }}>
								<MenuIcon />
							</IconButton>

							<Box sx={{ display: { xs: 'none', sm: 'flex' } }}>
								<ul style={{ display: 'flex', listStyle: 'none', gap: '20px' }}>
									<li style={{ cursor: 'pointer' }}>
										<a href='#home'>Home</a>
									</li>
									<li style={{ cursor: 'pointer' }}>
										<a href='#gallery'>Gallery</a>
									</li>
									<li style={{ cursor: 'pointer' }}>
										<a href='#info'>Property Info</a>
									</li>
									{listing?.video_link && (
										<li style={{ cursor: 'pointer' }}>
											<a href='#videos'>Video</a>
										</li>
									)}
									{listing?.link_to_matterport && (
										<li style={{ cursor: 'pointer' }}>
											<a href='#3dtour'>3D Tour</a>
										</li>
									)}
									<li style={{ cursor: 'pointer' }}>
										<a href='#contact'>Contact</a>
									</li>
								</ul>
							</Box>
							<Box sx={{ ml: 'auto', display: 'flex', gap: '10px', alignItems: 'center', justifyContent: { sm: 'space-around' }, my: '5px' }}>
								{data?.office_logo && (
									<Avatar
										src={data?.office_logo}
										alt='user'
										sx={{
											width: '30px',
											height: '30px',
										}}
									/>
								)}
								<Avatar
									src={data?.profile_image}
									alt='user'
									imgProps={{
										style: {
											objectFit: 'cover',
											objectPosition: 'top center',
										},
									}}
									sx={{
										width: '50px',
										height: '50px',
									}}
								/>
								<span>
									{data?.first_name || 'First Name'} {data?.last_name || 'Last Name'}
								</span>
							</Box>
						</Toolbar>
					</AppBar>
					<Box component='nav'>
						<Drawer
							variant='temporary'
							open={mobileOpen}
							onClose={handleDrawerToggle}
							ModalProps={{
								keepMounted: true, // Better open performance on mobile.
							}}
							sx={{
								display: { xs: 'block', sm: 'none' },
								'& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
							}}
						>
							<Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
								<Box sx={{ display: 'flex', alignItems: 'center', gap: '10px', justifyContent: 'space-around' }}>
									<Typography variant='h6' sx={{ my: 2 }}>
										{data?.first_name} {data?.last_name}
									</Typography>
									<Avatar
										src={data?.office_logo}
										alt='user'
										sx={{
											width: '45px',
											height: '45px',
										}}
									/>
								</Box>
								<Divider />
								<List>
									<ListItem disablePadding>
										<ListItemButton sx={{ textAlign: 'center', cursor: 'pointer' }} href='#home'>
											<ListItemText primary='Home' />
										</ListItemButton>
									</ListItem>
									<ListItem disablePadding>
										<ListItemButton sx={{ textAlign: 'center', cursor: 'pointer' }} href='#gallery'>
											<ListItemText primary='Gallery' />
										</ListItemButton>
									</ListItem>
									<ListItem disablePadding>
										<ListItemButton sx={{ textAlign: 'center', cursor: 'pointer' }} href='#info'>
											<ListItemText primary='Property Info' />
										</ListItemButton>
									</ListItem>
									{listing?.video_link && (
										<ListItem disablePadding>
											<ListItemButton sx={{ textAlign: 'center', cursor: 'pointer' }} href='#videos'>
												<ListItemText primary='Video' />
											</ListItemButton>
										</ListItem>
									)}
									{listing?.link_to_matterport && (
										<ListItem disablePadding>
											<ListItemButton sx={{ textAlign: 'center', cursor: 'pointer' }} href='#3dtour'>
												<ListItemText primary='3D Tour' />
											</ListItemButton>
										</ListItem>
									)}
									<ListItem disablePadding>
										<ListItemButton sx={{ textAlign: 'center' }} href='#contact'>
											<ListItemText primary='Contact' />
										</ListItemButton>
									</ListItem>
								</List>
							</Box>
						</Drawer>
					</Box>
				</Box>
				<section id='home' className='listingtemplate-home' style={{ backgroundImage: `url(${listing?.photos[0]?.image})`, position: 'relative' }}>
					{!!listing && (
						<Chip
							outlined
							label={listing?.sold_price && listing.date_sold ? 'Sold' : 'Active'}
							color={listing?.sold_price && listing.date_sold ? 'primary' : 'secondary'}
							sx={{ position: 'absolute', bottom: '20px', left: '10px' }}
						/>
					)}
					<h2>{listing?.address}</h2>
					<h3>
						{listing?.county ? listing?.county + ' ,' : ''} {listing?.state ? listing?.state + ' ,' : ''} {listing?.zip || ''}
					</h3>
				</section>
				<section className='listingtemplate-details' style={{ backgroundImage: { listing } }}>
					<h3>Property Details</h3>
					<p>{listing?.description}</p>
					<div>
						<div>
							<ListPrice />
							<span className='descrip'>Listing Price</span>
							<span className='val'>$ {formatNumber(listing?.list_price)}</span>
						</div>
						<div>
							<SquareFeet />
							<span className='descrip'>Square Footage</span>
							<span className='val'>{listing?.square_footage}</span>
						</div>
						<div>
							<Bed height={50} />
							<span className='descrip'>Bedrooms</span>
							<span className='val'>{listing?.beds}</span>
						</div>
						<div>
							<Bath />
							<span className='descrip'>Bathrooms</span>
							<span className='val'>{listing?.baths}</span>
						</div>
						<div>
							<Year />
							<span className='descrip'>Year Built</span>
							<span className='val'>{listing?.year_built}</span>
						</div>
					</div>
				</section>
				<section id='gallery' className='listingtemplate-gallery'>
					<h3>Gallery</h3>
					<div>
						{listing?.photos?.map(({ image, id }) => (
							<img src={image} alt='background' key={id} onClick={() => setGalleryOpen(true)} loading='lazy' />
						))}
					</div>
				</section>
				<section id='info' className='listingtemplate-info'>
					<h3>property info</h3>
					<div>
						<div>
							<h4>General Profile</h4>
							<div>
								<p>
									<span>Address:</span>
									<span>{listing?.['address']}</span>
								</p>
								<p>
									<span>Unit:</span>
									<span>{listing?.['unit']}</span>
								</p>
								<p>
									<span>City:</span>
									<span>{listing?.['city']}</span>
								</p>
								<p>
									<span>State:</span>
									<span>{listing?.['state']}</span>
								</p>
								<p>
									<span>Zipcode:</span>
									<span>{listing?.['zip']}</span>
								</p>
								<p>
									<span>Bedrooms:</span>
									<span>{listing?.['beds']}</span>
								</p>
								<p>
									<span>Baths:</span>
									<span>{listing?.['baths']}</span>
								</p>
							</div>
						</div>
						<div style={{ backgroundColor: '#fffbee' }}>
							<h4>Standard Features</h4>
							<div>
								{features.map(
									(item) =>
										listing?.[item] && (
											<p key={item}>
												<span>{removeUnderandCapitalize(item) || 'hsfd'}</span>
												<span>{listing?.[item] === '-' ? '' : listing?.[item]}</span>
											</p>
										)
								)}
							</div>
						</div>
						{(listing?.date_1 || listing?.date_2) && (
							<div>
								<h4>Open Houses</h4>
								<div>
									{listing?.date_1 && (
										<p>
											<span>Date 1</span>
											<span>
												{listing?.date_1} ({listing?.time_1})
											</span>
										</p>
									)}
									{listing?.date_2 && (
										<p>
											<span>Date 2</span>
											<span>
												{listing?.date_2} ({listing?.time_2})
											</span>
										</p>
									)}
								</div>
								<div>
									{listing?.meet_in_person && (
										<p>
											<span>Meet In Person</span>
											<span>{listing?.meet_in_person && listing?.meet_in_person !== '-' ? listing?.meet_in_person : 'No'}</span>
										</p>
									)}
									{listing?.meet_via_video_call && (
										<p>
											<span>Meet Via Video Call</span>
											<span>{listing?.meet_via_video_call ? 'Yes' : 'No'}</span>
										</p>
									)}
								</div>
							</div>
						)}
					</div>
				</section>
				{listing?.video_link && (
					<section id='videos' className='listingtemplate-3dtour'>
						<h3>Videos</h3>
						<div style={{ width: '100%' }}>
							<iframe
								style={{
									width: '100%',
									margin: '0 auto',
									aspectRatio: '16/9',
									borderRadius: '5px',
								}}
								src={`https://www.youtube.com/embed/${getYoutubeEmbedLink(listing?.video_link)}`}
								title='YouTube video player'
								frameBorder='0'
								allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
								allowFullScreen
							></iframe>
						</div>
					</section>
				)}
				{listing?.link_to_matterport && (
					<section id='3dtour' className='listingtemplate-3dtour'>
						<h3>3D Tour</h3>
						<iframe
							style={{
								width: '100%',
								margin: '0 auto',
								aspectRatio: '16/9',
								borderRadius: '5px',
							}}
							src={listing?.link_to_matterport}
							title='Listing Matterport'
							frameBorder='0'
							allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
							allowFullScreen
						></iframe>
					</section>
				)}
				<section className='profileform' id='contact'>
					<div className='profile_info'>
						<div className='row profile_row'>
							<img
								src={data?.profile_image}
								style={{
									height: '100px',
									aspectRatio: '1/1',
									borderRadius: '50%',
									objectFit: 'cover',
									objectPosition: 'top center',
								}}
								alt='User'
							/>
							<div className='right'>
								<p className='bold'>
									{data?.first_name} {data?.last_name}
								</p>
								<p style={{ display: 'flex', gap: '10px' }}>
									<Email width={18} />
									<a
										style={{ cursor: 'pointer' }}
										href={`mailto:${data?.user?.email}`}
										onClick={() => {
											handleIncrement('contact');
										}}
									>
										{data?.user?.email}
									</a>
								</p>
								<p style={{ display: 'flex', gap: '10px' }}>
									<Call width={18} />
									<a
										style={{ cursor: 'pointer' }}
										href={`tel:${formatPhoneNumber(data?.phone)}`}
										onClick={() => {
											handleIncrement('contact');
										}}
									>
										{data?.phone}
									</a>
								</p>
								{data?.licenses?.find((item) => item.category === 'real_estate_agent') && (
									<p>
										<b>License: &nbsp;</b>
										{data?.licenses?.find((item) => item.category === 'real_estate_agent').number}
									</p>
								)}
								<p style={{ whiteSpace: 'nowrap' }}>
									<b>Brokerage Name: &nbsp;</b>
									{data?.brokerage_name}
								</p>
							</div>
						</div>
					</div>
					<div className='form_section'>
						<form className='contact_form' onSubmit={handleContactSubmission}>
							<h3>Contact</h3>
							<p>For more information or inquiries, kindly use this form to contact me.</p>

							<Input
								placeholder='Enter full name'
								fullStyles={{
									width: '100%',
									paddingLeft: '0px',
									paddingRight: '0px',
								}}
								name='client_name'
								value={contactDetails.client_name}
								onChange={handleContactDetails}
								style={{ width: '100%' }}
							/>
							<Input
								placeholder='Enter phone number'
								type='tel'
								name='client_phone'
								pattern='[ -/()0-9]{10,14}'
								title='Phone number must be in the format: 123-456-7890'
								value={contactDetails.client_phone}
								onChange={handleContactDetails}
								fullStyles={{
									width: '100%',
									paddingLeft: '0px',
									paddingRight: '0px',
								}}
								style={{ width: '100%' }}
							/>
							<Input
								placeholder='Enter email'
								type='email'
								name='client_email'
								value={contactDetails.client_email}
								onChange={handleContactDetails}
								fullStyles={{
									width: '100%',
									paddingLeft: '0px',
									paddingRight: '0px',
								}}
								style={{ width: '100%' }}
							/>
							<textarea
								style={{
									width: '100%',
									margin: '10px 0',
									height: '60px',
									resize: 'none',
									fontFamily: 'inherit',
									outline: 'none',
									backgroundColor: '#f9f9fb',
								}}
								required
								name='client_message'
								value={contactDetails.client_message}
								onChange={handleContactDetails}
								placeholder='Write your message'
								rows={5}
							/>
							<Button text='Submit' type='submit' style={{ fontSize: '15px' }} />
						</form>
					</div>
				</section>
				<Divider sx={{ margin: '3rem auto', maxWidth: '1152px' }} />
				<Box
					fullwidth
					sx={{ position: 'fixed', right: '0', zIndex: '500', height: '100vh', flexDirection: 'column', display: 'flex', justifyContent: 'center' }}
				>
					<LinkedinShareButton url={pageUrl}>
						<LinkedinIcon size={40} borderRadius='10px' />
					</LinkedinShareButton>
					<FacebookShareButton url={pageUrl}>
						<FacebookIcon size={40} borderRadius='10px' />
					</FacebookShareButton>
					<EmailShareButton url={pageUrl}>
						<EmailIcon size={40} borderRadius='10px' />
					</EmailShareButton>
					<TwitterShareButton url={pageUrl}>
						<TwitterIcon size={40} borderRadius='10px' />
					</TwitterShareButton>
					{listing?.photos[0]?.image && (
						<PinterestShareButton url={pageUrl} media={listing?.photos[0]?.image}>
							<PinterestIcon size={40} borderRadius='10px' />
						</PinterestShareButton>
					)}
				</Box>
				<Footer />
			</div>
		</>
	);
}

export default ListingTemplate;
