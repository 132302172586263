import { ChevronLeft, ChevronRight, KingBedOutlined, Place, SquareFootOutlined } from '@mui/icons-material';
import {
	Box,
	Button,
	Card,
	CardContent,
	CardMedia,
	Link as DLink,
	TextField as DText,
	Divider,
	Grid,
	Stack,
	ThemeProvider,
	Typography,
	IconButton as WIconButton,
	createTheme,
} from '@mui/material';
import { makeStyles, styled } from '@mui/styles';
import React from 'react';
import { BiBath } from 'react-icons/bi';
import { Link as RLink } from 'react-router-dom';
import { ReactComponent as FBIcon } from '../../images/facebookfillable.svg';
import { ReactComponent as IGIcon } from '../../images/instagramfillable.svg';
import { ReactComponent as LIIcon } from '../../images/linkedInfillable.svg';
import BG from '../../images/temp1bg.webp';
import BG1 from '../../images/temp1bg1.webp';
import CBG1 from '../../images/temp1contactbg.webp';
import RBG from '../../images/temp1reviewbg.webp';
import SBG from '../../images/temp6bg.webp';
import { ReactComponent as TWIcon } from '../../images/twitterfillable.svg';
import { ReactComponent as YTIcon } from '../../images/youtubefillable.svg';
import { formatNumber, formatPhoneNumber, getYoutubeEmbedLink, hideButton, scrollToTheLeft } from '../../utils/functions';
import ServiceAreasSlider from '../../components/ServiceAreasSlider/ServiceAreasSlider';
import InvestorQuestionnaires from '../../components/InvestorQuestionnaires/InvestorQuestionnaires';

const TextField = styled(DText)(({ theme }) => ({
	'& .MuiOutlinedInput-root': {
		'& fieldset': {
			borderColor: 'white',
			color: 'white',
		},
	},

	'& .MuiInputBase-input::placeholder': {
		color: 'white',
	},
	'& .MuiInputBase-input': {
		color: 'white',
	},
	'& .MuiInput-underline:after': {
		borderBottomColor: 'white',
		transform: 'scaleX(1) translate(0)',
		borderWidth: '1px',
	},
}));

const IconButton = styled(WIconButton)(({ theme }) => ({
	borderRadius: '50%',
	width: '40px',
	aspectRatio: '1',
}));

const Link = styled(DLink)(({ theme }) => ({
	cursor: 'pointer',
}));

const pageTheme = createTheme({
	typography: {
		fontFamily: 'Poppins, sans-serif',
		fontSize: 16,
	},
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					borderRadius: 0,
				},
			},
			defaultProps: {
				disableRipple: true,
			},
		},
		MuiIconButton: {
			defaultProps: {
				disableRipple: true,
			},
		},
	},
	palette: {
		primary: {
			main: '#000',
		},
		secondary: {
			main: '#000',
		},
		success: {
			main: '#FDAE5C',
		},
		text: {
			main: '#000',
		},
		paper: {
			main: '#fff',
		},
	},
});

const useStyles = makeStyles((theme) => ({
	header: {
		width: '100vw',
		height: '90vh',
		backgroundImage: `linear-gradient(0deg, rgba(0, 29, 56, 0.35), rgba(0, 29, 56, 0.35)),url(${BG})`,
		display: 'flex',
		justifyContent: 'space-between',
		alignContent: 'center',
		flexDirection: 'column',
		padding: '2rem 4rem',
		backgroundSize: 'cover',
	},
	sticky: {
		width: '100%',
		padding: '1.5rem',
		display: 'flex',
		justifyContent: 'space-between',
	},
	withPaper: {
		background: `linear-gradient(white 0%, white 30%, #E49C52E5 30%, #E49C52E5 100%), url(${BG1}) no-repeat center top / cover`,
		width: '100vw',
		// height: '100vh',
		padding: '2rem 4rem',
		paddingTop: '0',
	},
	contact: {
		width: '100vw',
		background: `linear-gradient(to right, #000000BF 0%, #000000BF 50%, transparent 50%, transparent 100%),url(${CBG1})`,
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
		padding: '2rem 4rem',
	},
	scrollable: {
		maxWidth: '100%',
		display: 'flex',
		gap: '20px',
		overflowX: 'auto',
		'&::-webkit-scrollbar': {
			display: 'none',
		},

		scrollBehavior: 'smooth',
	},
}));

function NewTemp1({ agent, listings, testimonials, contactDetails, updateContactDetails, sendMessage, serviceAreas, isRealty }) {
	const name = agent?.user.first_name + ' ' + agent?.user.last_name;
	const agent_id = agent?.id;
	const member_since = agent?.year_work_commenced;
	const agent_phone = formatPhoneNumber(agent?.phone);
	const brokerage_phone = formatPhoneNumber(agent?.brokerage?.brokerage_phone);
	const brokerage_name = agent?.brokerage_name;
	const license = agent?.licenses.find((license) => license.category === 'real_estate_agent')?.number;
	const address = agent?.address;
	const video = agent?.video_link;
	const showInvestorForm = agent?.questionnaires_on;

	const classes = useStyles();
	return (
		<ThemeProvider theme={pageTheme}>
			<Box sx={{ overflowX: 'hidden' }}>
				<Box className={classes.header}>
					<Box className={classes.sticky}>
						<Box display='flex' gap='1rem' fontFamily='Poppins, sans-serif'>
							<Link href='#home' underline='none' color='white' fontFamily='inherit' sx={{ textTransform: 'uppercase' }}>
								Home
							</Link>
							<Link href='#about' underline='none' color='white' fontFamily='inherit' sx={{ textTransform: 'uppercase' }}>
								About
							</Link>
							{listings.active.length > 0 && (
								<Link href='#active' underline='none' color='white' fontFamily='inherit' sx={{ textTransform: 'uppercase' }}>
									Active Listings
								</Link>
							)}
							<Link href='#contact' underline='none' color='white' fontFamily='inherit' sx={{ textTransform: 'uppercase' }}>
								Contact
							</Link>
							<Link href={`/agent/${agent?.id}/blogs`} underline='none' color='white' fontFamily='inherit' sx={{ textTransform: 'uppercase' }}>
								Blogs
							</Link>
							{showInvestorForm && (
								<Link href='#investors' underline='none' color='white' fontFamily='inherit' sx={{ textTransform: 'uppercase' }}>
									Investors
								</Link>
							)}
						</Box>

						<Typography
							variant='body1'
							fontFamily='Poppins, sans-serif'
							color='white'
							fontWeight='400'
							fontSize='16px'
							sx={{ textTransform: 'uppercase' }}
						>
							Listing Agent: {name}
						</Typography>
					</Box>
					{agent?.landing_page_title && (
						<Typography color='white' textAlign='center' variant='h4' whiteSpace={'pre-line'}>
							{agent?.landing_page_title}
						</Typography>
					)}
					<Stack direction='row' spacing={3}>
						{agent?.facebook && (
							<IconButton target='blank' href={agent?.facebook}>
								<FBIcon fill='white' />
							</IconButton>
						)}
						{agent?.twitter && (
							<IconButton target='blank' href={agent?.twitter}>
								<TWIcon fill='white' />
							</IconButton>
						)}
						{agent?.instagram && (
							<IconButton target='blank' href={agent?.instagram}>
								<IGIcon fill='white' />
							</IconButton>
						)}
						{agent?.linkedin && (
							<IconButton target='blank' href={agent?.linkedin}>
								<LIIcon fill='white' />
							</IconButton>
						)}
						{agent?.video_link && (
							<IconButton target='blank' href={agent?.video_link}>
								<YTIcon fill='white' />
							</IconButton>
						)}
					</Stack>
				</Box>
				<Box className={classes.withPaper} id='about'>
					<Grid container spacing={2}>
						<Grid item xs={6} alignItems='flex-start' justifyContent='center' pt='0 !important'>
							<img
								src={agent?.profile_image}
								alt='Agent'
								style={{
									width: '400px',
									aspectRatio: '3/4',
									objectFit: 'cover',
									objectPosition: 'top center',
									display: 'block',
									margin: 'auto',
									zIndex: '2',
									// marginBottom: '3rem',
								}}
							/>
						</Grid>
						<Grid item xs={6} sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
							<Box sx={{ height: 'calc(30% - 2rem)', width: '100%', display: 'flex', alignItems: 'flex-end' }}>
								<Typography variant='h4'>{name}</Typography>
							</Box>
							<Typography textAlign='justify' fontWeight={400} fontSize='16px' color='white' mt='2rem' whiteSpace={'pre-line'}>
								{agent?.bio}
							</Typography>
						</Grid>
					</Grid>
				</Box>
				{listings.active.length > 0 && (
					<Box padding='2rem 4rem' my='4rem'>
						<Typography variant='h4' textAlign={'center'}>
							Active Listings
						</Typography>
						<Box sx={{ position: 'relative' }}>
							<WIconButton
								ref={(el) => hideButton(el, 'active')}
								sx={{
									position: 'absolute',
									top: '40%',
									left: '0',
									backgroundColor: 'white',
									borderRadius: '2px',
									'&:hover': { backgroundColor: 'white' },
									boxShadow: '0px 0px 10px 0px #00000026',
								}}
								onClick={() => scrollToTheLeft('active', true)}
							>
								<ChevronLeft fontSize='large' />
							</WIconButton>
							<Box display='flex' gap='20px' mt={2} className={classes.scrollable} p='1rem' id='active'>
								{listings.active.map((listing) => (
									<ListingCard key={listing.id} agentId={agent_id} {...listing} />
								))}
							</Box>
							<WIconButton
								ref={(el) => hideButton(el, 'active')}
								sx={{
									position: 'absolute',
									top: '40%',
									right: '0',
									backgroundColor: 'white',
									borderRadius: '2px',
									'&:hover': { backgroundColor: 'white' },
									boxShadow: '0px 0px 10px 0px #00000026',
								}}
								onClick={() => scrollToTheLeft('active', false)}
							>
								<ChevronRight fontSize='large' />
							</WIconButton>
						</Box>
					</Box>
				)}
				{listings?.sold?.length > 0 && (
					<Box sx={{ background: `url(${SBG})`, width: '100vw', backgroundPosition: 'center', marginBottom: '3rem' }} p='4rem'>
						<Box position='relative'>
							<WIconButton
								ref={(ref) => hideButton(ref, 'sold')}
								sx={{
									position: 'absolute',
									top: '40%',
									left: '0',
									backgroundColor: 'white',
									borderRadius: '2px',
									zIndex: '100',
									'&:hover': { backgroundColor: 'white' },
									boxShadow: '0px 0px 10px 0px #00000026',
								}}
								onClick={() => scrollToTheLeft('sold', true)}
							>
								<ChevronLeft fontSize='large' />
							</WIconButton>
							<Box className={classes.scrollable} p='1rem' id='sold'>
								{listings.sold.map((listing) => (
									<SoldCard key={listing.id} agentId={agent_id} {...listing} />
								))}
							</Box>
							<WIconButton
								ref={(el) => hideButton(el, 'sold')}
								sx={{
									position: 'absolute',
									top: '40%',
									right: '0',
									backgroundColor: 'white',
									borderRadius: '2px',
									'&:hover': { backgroundColor: 'white' },
									boxShadow: '0px 0px 10px 0px #00000026',
								}}
								onClick={() => scrollToTheLeft('sold', false)}
							>
								<ChevronRight fontSize='large' />
							</WIconButton>
						</Box>
					</Box>
				)}
				{showInvestorForm && <InvestorQuestionnaires agent={agent} />}
				<Grid container spacing={2} p='2rem 4rem' mb='2rem'>
					{video && (
						<Grid item xs={8}>
							<iframe
								style={{
									aspectRatio: '16/9',
									border: 'none',
									width: '80%',
									// margin: '3rem auto',
									borderRadius: '5px',
									margin: 'auto',
									display: 'block',
								}}
								src={`https://www.youtube.com/embed/${getYoutubeEmbedLink(video)}`}
								title='Client video'
								allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
								allowFullScreen
							></iframe>
						</Grid>
					)}
					<Grid
						item
						xs={!video ? 12 : 4}
						alignItems={video ? 'flex-start' : 'center'}
						justifyContent={'center'}
						display='flex'
						flexDirection='column'
					>
						<Typography
							variant='h3'
							sx={{ wordSpacing: '10000rem', textAlign: video ? 'left' : 'center', textShadow: '-9px -3px 3px #8b8b8b3b', lineHeight: 2.4 }}
						>
							{name}
						</Typography>
						<Typography variant='h5' fontWeight={700} fontSize='20px'>
							Real estate agent
						</Typography>
						<Typography variant='body1' fontWeight={300}>
							Member Since: {member_since}
						</Typography>
					</Grid>
				</Grid>
				<Box className={classes.contact} id='contact'>
					<Grid container spacing={2}>
						<Grid item xs={6} color='white' p='2rem' display='flex' flexDirection='column' gap='2rem' alignItems='flex-start'>
							<Typography variant='body1' fontSize={20}>
								For more information or inquiries, kindly use this form to contact me.
							</Typography>
							<TextField
								fullWidth
								variant='standard'
								placeholder='Enter Full name'
								name='name'
								size='small'
								color='primary'
								value={contactDetails.name}
								onChange={updateContactDetails}
							/>
							<TextField
								fullWidth
								variant='standard'
								placeholder='Enter Phone number'
								name='phone'
								size='small'
								color='primary'
								value={contactDetails.phone}
								onChange={updateContactDetails}
							/>
							<TextField
								fullWidth
								variant='standard'
								placeholder='Enter Email'
								size='small'
								name='email'
								color='primary'
								value={contactDetails.email}
								onChange={updateContactDetails}
							/>
							<TextField
								fullWidth
								variant='standard'
								placeholder='Write your message'
								multiline
								value={contactDetails.message}
								size='small'
								name='message'
								color='primary'
								onChange={updateContactDetails}
							/>
							<Button variant='outlined' color='success' sx={{ borderRadius: '40px', textTransform: 'capitalize' }} onClick={sendMessage}>
								Let's Connect
							</Button>
						</Grid>
					</Grid>
				</Box>
				<Stack
					direction='row'
					spacing={2}
					divider={<Divider orientation='vertical' flexItem sx={{ backgroundColor: '#FDAE5C', width: '2px' }} />}
					my={'4rem'}
					justifyContent='center'
				>
					<Stack direction='column' spacing={2}>
						<Typography variant='body1' fontWeight={300} fontSize='16px'>
							{name}
						</Typography>
						<Typography variant='body1' fontWeight={300} fontSize='16px'>
							Member Since: {member_since}
						</Typography>
					</Stack>
					<Stack direction='column' spacing={2}>
						<Typography variant='body1' fontWeight={300} fontSize='16px'>
							{address}
						</Typography>
						<Typography variant='body1' fontWeight={300} fontSize='16px'>
							Real Estate License: {license}
						</Typography>
					</Stack>
					<Stack direction='column' spacing={2}>
						<Typography variant='body1' fontWeight={300} fontSize='16px'>
							Cell Phone: {agent_phone}
						</Typography>
						<Typography variant='body1' fontWeight={300} fontSize='16px'>
							Brokerage: {brokerage_name} {brokerage_phone ? `(${brokerage_phone})` : ''}
						</Typography>
					</Stack>
				</Stack>
				{testimonials.length > 0 && (
					<Stack
						direction='row'
						spacing={2}
						p={'2rem 4rem'}
						// minHeight={'400px'}
						alignItems='flex-start'
						sx={{ background: `linear-gradient(#00000080,#00000080),url(${RBG})`, pt: '5rem', width: '100vw' }}
					>
						{testimonials.map((testimonial) => (
							<Review key={testimonial.id} {...testimonial} />
						))}
					</Stack>
				)}
			</Box>

			{serviceAreas.length > 0 && (
				<Box mt={2}>
					<ServiceAreasSlider serviceAreas={serviceAreas} />
				</Box>
			)}
		</ThemeProvider>
	);
}

export default NewTemp1;

const ListingCard = ({ id, agentId, list_price, photos, beds, baths, square_footage, address }) => {
	return (
		<Card
			sx={{
				width: '300px !important',
				minWidth: '300px',
				maxWidth: '300px',
				boxShadow: '0px 4.45528507232666px 11.138212203979492px 2.22764253616333px #00000026',
				cursor: 'pointer',
			}}
			component={RLink}
			to={`/view-listing/${agentId}/${id}`}
			target='_blank'
		>
			<CardMedia component='img' sx={{ aspectRatio: '1.6' }} image={photos?.[0]?.image} alt='listing' width='300px' />
			<CardContent>
				<Typography color='#005EA9' variant='h5' fontWeight='700' fontSize='20px' mb='1rem'>
					$ {formatNumber(list_price, 0)}
				</Typography>
				<Stack direction='row' alignItems='center' divider={<span>.</span>} gap='10px' mb='0.5rem'>
					<Typography variant='body1' fontSize='14px'>
						{beds} beds
					</Typography>
					<Typography variant='body1' fontSize='14px'>
						{baths} baths
					</Typography>
					<Typography variant='body1' fontSize='14px'>
						{formatNumber(square_footage)} sqft
					</Typography>
				</Stack>
				<Typography variant='body1' fontSize='16px'>
					{address}
				</Typography>
			</CardContent>
		</Card>
	);
};

const SoldCard = ({ id, agentId, list_price, beds, baths, square_footage, address, date_sold }) => {
	return (
		<Box
			width='450px'
			minWidth={'450px'}
			maxWidth={'450px'}
			sx={{
				backgroundColor: 'white',
				borderRadius: '10px',
				boxShadow: '4px 4px 10px 0px #00000026',
				padding: '2rem',
				display: 'flex',
				flexDirection: 'column',
				gap: '10px',
				justifyContent: 'center',
				alignItems: 'flex-start',
				position: 'relative',
			}}
		>
			<Typography
				variant='body1'
				sx={{ backgroundColor: '#FDAE5C', borderRadius: '10px', top: '-1rem', left: '2rem', position: 'absolute', padding: '0.5rem 1rem' }}
			>
				Sold
			</Typography>
			<Typography variant='h6' fontWeight={500} mt={'1rem'}>
				Modern Classic Home
			</Typography>
			<Stack direction='row' gap='10px' alignItems='center'>
				<Place sx={{ fill: '#3F95D9' }} />
				<Typography variant='body1' fontSize='14px' color='#818181'>
					{address}
				</Typography>
			</Stack>
			<Divider orientation='horizontal' flexItem sx={{ backgroundColor: '#C4C4C4', width: '100%' }} />
			<Stack direction='row' gap='10px' alignItems='center'>
				<Stack direction='row' gap='10px' alignItems='center'>
					<KingBedOutlined fontSize='small' />
					<Typography variant='body1' fontSize='16px' fontWeight={300}>
						{beds} beds
					</Typography>
				</Stack>
				<Stack direction='row' gap='10px' alignItems='center'>
					<BiBath />
					<Typography variant='body1' fontSize='16px' fontWeight={300}>
						{baths} baths
					</Typography>
				</Stack>
				<Stack direction='row' gap='10px' alignItems='center'>
					<SquareFootOutlined fontSize='small' />
					<Typography variant='body1' fontSize='16px' fontWeight={300}>
						{formatNumber(square_footage)} sqft
					</Typography>
				</Stack>
			</Stack>
			<Typography variant='h6' fontSize='25px' fontWeight={500}>
				$ {formatNumber(list_price, 0)}
			</Typography>
			<Stack direction='row' justifyContent='space-between' alignItems='center' width='100%'>
				<Typography variant='body1' fontSize='16px' fontWeight={300}>
					{new Date(date_sold).toLocaleDateString()}
				</Typography>
				<Button variant='outlined' color='success' sx={{ borderRadius: '40px', textTransform: 'capitalize' }} href={`/view-listing/${agentId}/${id}`}>
					View Details
				</Button>
			</Stack>
		</Box>
	);
};

const Review = ({ description, name, client }) => {
	return (
		<Stack spacing={2} alignItems='center' flex='1'>
			<Typography variant='body1' fontSize='100px' textTransform='uppercase' color='#EDEDED' lineHeight={0}>
				&ldquo;
			</Typography>
			<Typography variant='body1' textAlign='center' fontStyle='italic' color='white'>
				{description}
			</Typography>
			<Typography variant='body1' textAlign='center' fontWeight='700' fontSize='18px' color='white' mt='auto !important'>
				- {client?.user?.first_name || client?.user?.last_name ? client?.user?.first_name + ' ' + client?.user?.last_name : name}
			</Typography>
		</Stack>
	);
};
