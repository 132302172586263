import React from 'react'
import {TableCell, TableRow, Typography, Box } from '@mui/material'
import useTheme from '@mui/material/styles/useTheme'
import commaSeparate from '../../utils/commaSeparate'
import { makeBold } from './extraStyles'
import { convertPercentOrFlat } from '../../utils/convertPercentOrFlat'
import { removeNaNAndInfinite } from '../../../../pages/DealAnalyzer/ViewDealAnalyzerReports/utils'


const useStyles = (_theme)=>{
    return{
        root:{

        },
        valueCell:{
            width:"100% !important",
            display:"flex",
            borderBottom:"solid 1px white !important",
            borderTop:"solid 2px white !important",
            padding:"0 !important",
            "print-color-adjust": "exact",
            "-webkit-print-color-adjust": "exact",

        },
        dollar:{
        }
    }
}


function ProformaPointsRow({item,points,symbol,printVersion,rate,referenceValue}) {
    const theme = useTheme()
    const styles = useStyles(theme)
    const convertedIntoFlat = convertPercentOrFlat(points,referenceValue,0)
    const convertedIntoPercent = convertPercentOrFlat(points,referenceValue,1)

  return (
    <>
      <TableRow sx={{ p:2}}>
        <TableCell sx={{width:"40% !important"}} >
            <Typography variant={"h4"}>{item}</Typography>
        </TableCell>
        <TableCell sx={styles.valueCell}>
          <Box sx={{ width:"50%",display:"flex", p:1,backgroundColor: "#f5f5f5"}}>
              <Typography variant={"h4"} sx={{...makeBold,mr:1}}>{+rate === 1 ? points: symbol === 'pts' ? removeNaNAndInfinite(points): removeNaNAndInfinite(convertedIntoPercent)}</Typography>
              <Typography variant={"h4"} sx={makeBold}>{symbol}</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              backgroundColor: "#e8e8e8",
              width:"50%",
              p:1,            }}
          >
            <Box sx={{ width: "15%" }}>
              <Typography variant={"h4"}  sx={makeBold} >$</Typography>
            </Box>
            <Box sx={{ width: "100%" }}>
              <Typography variant={"h4"} sx={makeBold}>{+rate === 0 ? commaSeparate(removeNaNAndInfinite(points)):commaSeparate(removeNaNAndInfinite(convertedIntoFlat))}</Typography>
            </Box>
          </Box>
        </TableCell>
      </TableRow>
    </>
  );
}

export default ProformaPointsRow