import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
	typography: {
		fontFamily: 'Inter',
		fontSize: 15,
	},
	palette: {
		primary: {
			main: '#FFAA01',
		},
		secondary: {
			main: '#005EA9',
		},
		text: {
			main: '#000',
		},
		paper: {
			main: '#fff',
		},
	},
	primaryColor: '#FFAA01',
	secondaryColor: '#005EA9',
	components: {
		MuiTypography: {
			styleOverrides: {
				root: {
					fontSize: 16,
				},
			},
		},
		MuiTextField: {
			styleOverrides: {
				root: {
					// '& .MuiOutlinedInput-root': {
					// 	fontSize: 16,
					// 	borderRadius: '8px',
					// },
				},
			},
		},
		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					borderRadius: '8px',
					fontSize: '16px',
				},
			},
		},
		MuiButton: {
			styleOverrides: {
				root: {
					textTransform: 'capitalize',
					borderRadius: '8px',
				},
			},
		},
		MuiSwitch: {
			styleOverrides: {
				root: {
					width: 36,
					height: 20,
					padding: 0,
					'& .MuiSwitch-switchBase': {
						padding: 0,
						margin: 2,
						transitionDuration: '300ms',
						'&.Mui-checked': {
							transform: 'translateX(16px)',
							color: '#fff',
							'& + .MuiSwitch-track': {
								backgroundColor: '#005EA9',
								opacity: 1,
								border: 0,
							},
						},
					},
					'& .MuiSwitch-thumb': {
						boxSizing: 'border-box',
						width: 16,
						height: 16,
					},
					'& .MuiSwitch-track': {
						borderRadius: 24 / 2,
						backgroundColor: '#E9E9EA',
						opacity: 1,
						transition: 'background-color 300ms',
					},
				},
			},
		},
	},
});
