
const getReportRoute = (typeId,reportId) =>{
    switch(+typeId){
        case 1 : return `/dealanalyzer-reports/straight-aesthetic-remodel?id=${reportId}`
        case 2 : return `/dealanalyzer-reports/straight-remodel-add-addition?id=${reportId}`
        case 3 : return `/dealanalyzer-reports/remodel-down-to-studs?id=${reportId}`
        case 4 : return `/dealanalyzer-reports/remodel-down-to-studs-add-addition?id=${reportId}`
        case 5 : return `/dealanalyzer-reports/new-construction?id=${reportId}`
        case 6 : return `/dealanalyzer-reports/buy-and-rent?id=${reportId}`
        default : return ''
    }
}

export default getReportRoute