import React from 'react'
import { useTheme } from '@mui/material/styles'
import { Box,Table,  tableCellClasses,  } from '@mui/material'
import SubSectionContainer from '../TableComponents/SubSectionContainer'
import ComponentSelector from '../ComponentSelector'


const useStyles = (_theme,printVersion)=>{
  return{
      root:{

      },
      table:{
        [`& .${tableCellClasses.root}`]: {
          borderBottom: "none",
          padding:"5px 0",
          margin:"0rem 0 !important"
        },
        width:"48%",
        overflow:printVersion?"hidden":"scroll",
      },
      table2:{
        [`& .${tableCellClasses.root}`]: {
          borderBottom: "none",
          padding:"5px 0",
          margin:"0rem 0 !important"
        },
        width:"48%",
        alignSelf:"start",
        overflow:printVersion?"hidden":"scroll",
      },
      fullTable:{
        [`& .${tableCellClasses.root}`]: {
          borderBottom: "none",
          padding:"5px 0",
          margin:"0rem 0 !important"
        },
        alignSelf:"start",
        overflow:printVersion?"hidden":"scroll",

      },
      valueCell:{
        width:"18% !important",
        borderBottom:"solid 1px white !important",
        borderTop:"solid 2px white !important",
        backgroundColor:"#f5f5f5",
        "print-color-adjust": "exact",
        "-webkit-print-color-adjust": "exact",

    },
      valueCell1:{
        width:"28% !important",
        borderBottom:"solid 1px white !important",
        borderTop:"solid 2px white !important",
        backgroundColor:"#f5f5f5",
        "print-color-adjust": "exact",
        "-webkit-print-color-adjust": "exact",

    },
      valueCell2:{
        width:"18% !important",
        borderBottom:"solid 1px white !important",
        borderTop:"solid 2px white !important",
        backgroundColor:"#e8e8e8",
        "print-color-adjust": "exact",
        "-webkit-print-color-adjust": "exact",

    }
  }
}

function ProformaPage3Type2({
    otherExpenses,
    estResaleValue,
    rentalIncome,
    capitalizationRate,
    frontendClosingCost,
    backendClosingCost,
    netProfit,
    investorsProfit,
    printVersion,
    resultData,
    carryingCost,
    operationalExpenses,
}) {

    const theme = useTheme();
    const styles = useStyles(theme,printVersion);

  return (
    <>

<Box sx={{display:"flex",justifyContent:"space-between"}}>
  <Box sx = {{display:"flex", flexDirection:"column", width:"49.5%"}}>
  <SubSectionContainer printVersion={printVersion} title="Estimated Front End/ Purchase Closing Cost">
          <Table sx={{ ...styles.table,overflow:"hidden",width: "100%" }}>
            {frontendClosingCost.map((element) => (
              <ComponentSelector {...element} printVersion={printVersion}/>
            ))}
          </Table>
        </SubSectionContainer>
        <Box sx={{height:"1rem"}}/>
  <SubSectionContainer printVersion={printVersion} title="Carrying Cost">
          <Table sx={{ ...styles.table, width: "100%" }}>
            {carryingCost.map((element) => (
              <ComponentSelector {...element} printVersion={printVersion}/>
            ))}
          </Table>
          <Box sx={{mb:0.5}}/>
  </SubSectionContainer>
  <Box sx={{height:"1.25rem"}}/>

        <SubSectionContainer printVersion = {printVersion} title="Rental Income">
          <Table sx={{ ...styles.table, width: "100%",overflow:"hidden" }}>
            {rentalIncome.map((element) => (
              <ComponentSelector {...element} printVersion={printVersion} />
            ))}
          </Table>
        </SubSectionContainer>
        <Box sx={{height:"1.25rem"}}/>

        {/* <SubSectionContainer printVersion = {printVersion} title="Est Resale Value">
          <Table sx={{ ...styles.table, width: "100%" }}>
            {estResaleValue.map((element) => (
              <ComponentSelector {...element} printVersion={printVersion} />
            ))}
          </Table>
          <Box sx={{mb:0}}/>
        </SubSectionContainer> */}
  </Box>
  <Box sx={{display:"flex", flexDirection:"column",width:"49.5%"}}>
  <SubSectionContainer printVersion={printVersion} title="Estimated Back End/ Purchase Closing Cost">
          <Table sx={{ ...styles.table, overflow:"hidden",width: "100%"  }}>
            {backendClosingCost.map((element) => (
              <ComponentSelector {...element} printVersion={printVersion}/>
            ))}
          </Table>
        </SubSectionContainer>
        <Box sx={{height:"1rem"}}/>
  <SubSectionContainer printVersion={printVersion} title="Operational Expenses">
          <Table sx={{ ...styles.table, width: "100%",overflow:"hidden" }}>
            {operationalExpenses.map((element) => (
              <ComponentSelector {...element} printVersion={printVersion}/>
            ))}
          </Table>
          <Box sx={{height:"5px"}}/>
        </SubSectionContainer>
        <Box sx={{height:"1rem"}}/>
        <SubSectionContainer printVersion = {printVersion} title="Capitalization Rate">
          <Table sx={{ ...styles.table, width: "100%",overflow:"hidden" }}>
            {capitalizationRate.map((element) => (
              <ComponentSelector {...element} printVersion={printVersion} />
            ))}
          </Table>
        </SubSectionContainer>

        <Box sx={{height:"1rem"}}/>
        {/* <SubSectionContainer printVersion = {printVersion} title="Net Profit">
          <Table sx={{ ...styles.table, width: "100%" }}>
            {netProfit.map((element) => (
              <ComponentSelector {...element} printVersion={printVersion} />
            ))}
          </Table>
          <Box sx={{height:"5px"}}/>
        </SubSectionContainer> */}
  </Box>
       

      </Box>
      <Box sx={{height:"1rem"}}/>

    </>
  )
}

export default ProformaPage3Type2