import { SvgIcon } from "@mui/material";
import React from "react";

function BARicon(props) {
  return (
    <SvgIcon
      width="99"
      height="80"
      viewBox="0 0 99 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M90.0437 78.5268C90.0437 78.9175 89.8885 79.2922 89.6122 79.5685C89.336 79.8448 88.9612 80 88.5705 80H70.4759C70.3896 78.5284 69.9679 77.0961 69.2429 75.8126H85.8483V41.8812C85.8405 41.7853 85.8405 41.6889 85.8483 41.5929L49.4749 14.8753L12.3649 41.633C12.3971 41.7558 12.4132 41.8823 12.4129 42.0093V75.8126H27.8815L27.8254 75.9247C27.1473 77.1809 26.7614 78.5739 26.6965 80H9.69074C9.49765 80 9.30647 79.9618 9.12818 79.8877C8.94989 79.8136 8.78801 79.7049 8.65185 79.568C8.51569 79.4311 8.40794 79.2687 8.33478 79.09C8.26162 78.9113 8.2245 78.7199 8.22555 78.5268V43.5225C6.80988 44.1148 5.23063 44.1885 3.76595 43.7307C2.7958 43.4098 1.94115 42.8113 1.30796 42.0093C0.676723 41.2085 0.285303 40.2452 0.179044 39.231C0.100644 38.2991 0.20897 37.361 0.497678 36.4715C0.786385 35.582 1.24967 34.759 1.8604 34.0508C1.93026 33.9635 2.01097 33.8855 2.1006 33.8186L48.2179 0.383607C48.4749 0.149743 48.8063 0.0142475 49.1535 0.00106178C49.5007 -0.012124 49.8414 0.097849 50.1154 0.311548L96.3208 33.6825C96.3852 33.7294 96.4442 33.7831 96.4969 33.8427C97.298 34.6194 97.8897 35.5859 98.2173 36.6525C98.5448 37.7191 98.5975 38.8512 98.3705 39.9436C98.1589 40.8545 97.7229 41.6979 97.102 42.3971C96.481 43.0964 95.6951 43.6291 94.8156 43.9468C94.0409 44.2155 93.2203 44.3262 92.4021 44.2726C91.5839 44.219 90.7847 44.002 90.0517 43.6346V78.5268H90.0437Z"
        fill="#1378A5"
      />
      <path
        d="M26.5078 69.5723H34.9408V44.2733H26.5078V69.5723ZM72.8893 46.3815C72.8893 44.0624 70.9919 42.165 68.6728 42.165H55.3698L57.3726 32.5303L57.4359 31.8557C57.4359 30.9913 57.0774 30.1901 56.5082 29.6209L54.2735 27.4073L40.4012 41.3006C39.6211 42.0596 39.1573 43.1137 39.1573 44.2733V65.3558C39.1573 67.6748 41.0547 69.5723 43.3738 69.5723H62.3481C64.0979 69.5723 65.5948 68.5182 66.2273 67.0002L72.5942 52.137C72.7839 51.6521 72.8893 51.1462 72.8893 50.598V46.3815Z"
        fill="#1378A5"
      />
    </SvgIcon>
  );
}

export default BARicon;
