import { Box } from "@mui/material";
import bgImage from "../../../assets/dealAnalyzer/propertysearchbg.webp";
import GoogleAutoComplete from "../../../components/AnalyzePropertyComponents/GoogleAutoComplete";
import { useNavigate } from "react-router-dom";
import MuiThemeLayout from "../../../Layouts/MuiThemeLayout";
// import cssStyles from '../DealAnalyzer/dealAnalyzer.module.css'

const useStyles = (_theme) => {
  return {
    root: {
      backgroundImage: `url(${bgImage})`,
      backgroundRepeat: "no-repeat",
      width: "100%",
      height: "100vh",
      overflow: "hidden",
      backgroundSize: "cover",
      backgroundPosition: "center center",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    text: {
      fontSize: "3.75rem",
      fontFamily: "Roboto",
      fontWeight: 700,
      lineHeight: "4.125rem",
      textTransform: "capitalize",
      color: "white",
      margin: "3.125rem",
      textAlign: "center",
    },
    inner: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      marginBottom: "18.75rem",
      width: "100%",
    },
  };
};

function AnalyzerPropertySearch() {
  const styles = useStyles();
  const navigate = useNavigate();
  const handleGooglePlace = (data) => {
    navigate(`menu/?address=${data?.description}`);
  };
  const override = {
    width: "60%",
    minWidth: "25rem",
    "& .MuiAutocomplete-inputRoot": {
      background: " #ffff",
      fontSize: "1.5rem",
      fontWeight: 200,
      marginBottom: "10px",
    },
  };
  return (
    <MuiThemeLayout>
      <Box sx={styles.root}>
        <Box sx={styles.inner}>
          <Box sx={styles.text}>Analyze Property</Box>
          <GoogleAutoComplete
            handleLocation={handleGooglePlace}
            styleOverride={override}
          />
        </Box>
      </Box>
    </MuiThemeLayout>
  );
}

export default AnalyzerPropertySearch;
