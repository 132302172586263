import React from 'react';
import './spinner.scss';

function Spinner({ overlay = true }) {
	if (!overlay) {
		return (
			<div className='cnter'>
				<span className='spinner'></span>
			</div>
		);
	}
	return (
		<div className='overlay-spinner'>
			<div className='overlay__inner'>
				<div className='overlay__content'>
					<span className='spinner'></span>
				</div>
			</div>
		</div>
	);
}

export default Spinner;
