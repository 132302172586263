import { toast } from "react-toastify";

export const getDaysDiff = (start, end) => {
    const date1 = new Date(start);
    const date2 = new Date(end);
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
    return diffDays
}

export const initState = {
    switchedBroker: "no",
    changedLicense: "no",
    isAgreementSigned: false,
    showAgreementBox: true,
    broker: {
        broker_name: "",
        brokerage_name: "",
        brokerage_address: "",
        brokerage_license: "",
        brokerage_email: "",
        brokerage_phone: "",
        brokerage_city: "",
        brokerage_state: "",
        brokerage_zip: ""
    },
    agent: {
        isFirstTime: true,
        first_name: "",
        last_name: "",
        license: "",
        phone_1: "",
        phone_2: "",
        email_1: "",
        email_2: ""
    }
}

export const stateOne = {
    disableForm: false,
    lead_contact: 'no',
    contact_appointment: {
        callback: { status: false, date: '', time: '' },
        online: { status: false, date: '', time: '' },
        inperson: { status: false, date: '', time: '' }
    },
    contact_interest: {
        no_interest: {
            status: false,
            seller: false,
            buyer: false,
            notes: ''
        },
        not_interested_now: {
            status: false,
            date: '',
            time: '',
            notes: ''
        }
    },
    lead_type_verification: '',
    lead_info: {
        relationship: '',
        firstname:'',
        lastname: '',
        email1: '',
        email2: '',
        phone1: '',
        phone2: ''
    }
}

export const stateTwo = {
    disableForm: false,
    signedListing: '',
    files : {
        contractSignedFile: '',
        buyerBrokerAgreement: ''
    }
    }


export const stateThree = {
    disableForm: false,
    offerAccepted: 'no',
    purchase: {
        price: 0,
        escrow_date: '',
        commission: 0,
        total_gross: 0,
    },
    dual_agent: '',
    escrow_info: {
        name: '',
        phone: '',
        address: '',
        city: '',
        officer_fname: '',
        officer_lname: '',
        officer_phone: '',
        officer_email: ''
    },
    company_info: {
        name: '',
        phone: '',
        address: '',
        city: '',
        officer_fname: '',
        officer_lname: '',
        officer_phone: '',
        officer_email: ''
    },
    buyer: {
        first_name: '',
        last_name: ''
    },
    seller: {
        first_name: '',
        last_name: ''
    },
    contingency: '',
}

export const stateFour = {
    disableForm: false,
    contingency: '',
    contingents: [],
    contingency_notes: ''
}

export const stateSix = {
    disableForm: false,
    status: '',
    closed: {
        date: '',
        time: '',
        notes: ''
    },
    fell_through: {
        date: '',
        time: '',
        notes: '',
        interest: ''
    }
}

export const formReducerFn = (state, action) => {
    switch (action?.type) {
        case 'SWITCHED':
            return { ...state, switchedBroker: action?.payload }
        case 'CHANGED_LICENSE':
            return { ...state, changedLicense: action?.payload }
        case 'BROKER_NAME':
            return { ...state, broker: { ...state?.broker, broker_name: action?.payload } }
        case 'BROKERAGE_NAME':
            return { ...state, broker: { ...state?.broker, brokerage_name: action?.payload } }
        case 'BROKERAGE_ADDRESS':
            return { ...state, broker: { ...state?.broker, brokerage_address: action?.payload } }
        case 'BROKERAGE_LICENSE':
            return { ...state, broker: { ...state?.broker, brokerage_license: action?.payload } }
        case 'BROKERAGE_EMAIL':
            return { ...state, broker: { ...state?.broker, brokerage_email: action?.payload } }
        case 'BROKERAGE_PHONE':
            return { ...state, broker: { ...state?.broker, brokerage_phone: action?.payload } }
        case 'BROKERAGE_CITY':
            return { ...state, broker: { ...state?.broker, brokerage_city: action?.payload } }
        case 'BROKERAGE_STATE':
            return { ...state, broker: { ...state?.broker, brokerage_state: action?.payload } }
        case 'BROKERAGE_ZIP':
            return { ...state, broker: { ...state?.broker, brokerage_zip: action?.payload } }
        case 'UPDATE_BROKER':
            return {
                ...state, broker: {
                    broker_name: action?.payload?.brokerName,
                    brokerage_name: action?.payload?.brokerageName,
                    brokerage_address: action?.payload?.brokerageAddress,
                    brokerage_license: action?.payload?.brokerageLicense,
                    brokerage_email: action?.payload?.brokerageEmail,
                    brokerage_phone: action?.payload?.brokeragePhone,
                    brokerage_city: action?.payload?.brokerageCity,
                    brokerage_state: action?.payload?.brokerageState,
                    brokerage_zip: action?.payload?.brokerageZip
                }
            }
        case 'FIRST_NAME':
            return { ...state, agent: { ...state?.agent, first_name: action?.payload } }
        case 'LAST_NAME':
            return { ...state, agent: { ...state?.agent, last_name: action?.payload } }
        case 'LICENSE':
            return { ...state, agent: { ...state?.agent, license: action?.payload } }
        case 'PHONE_1':
            return { ...state, agent: { ...state?.agent, phone_1: action?.payload } }
        case 'PHONE_2':
            return { ...state, agent: { ...state?.agent, phone_2: action?.payload } }
        case 'EMAIL_1':
            return { ...state, agent: { ...state?.agent, email_1: action?.payload } }
        case 'EMAIL_2':
            return { ...state, agent: { ...state?.agent, email_2: action?.payload } }
        case 'UPDATE_AGENT':
            return {
                ...state, agent: {
                    isFirstTime: action?.payload?.isFirstTime,
                    first_name: action?.payload?.firstName,
                    last_name: action?.payload?.lastName,
                    phone_1: action?.payload?.phoneNumber,
                    email_1: action?.payload?.userEmail,
                    license: action?.payload?.licenses[0]?.number || "",
                    phone_2: action?.payload?.secondaryPhoneNumber || "",
                    email_2: action?.payload?.secondaryEmail || "",
                }
            }

        case 'AGREEMENT':
            return { ...state, isAgreementSigned: action?.payload }
        case 'SHOW_AGREEMENT':
            return { ...state, showAgreementBox: action?.payload }
        case 'RESET':
            return initState
        default:
            return state;
    }
}

export const statusOneFn = (state, action) => {
    switch (action?.type) {
        case 'DISABLE':
            return { ...state, disableForm: action?.payload }
        case 'CONTACTED_LEAD':
            return { ...state, lead_contact: action?.payload }

        case 'CALLBACK_SELECT':
            return { ...state, contact_appointment: { ...stateOne?.contact_appointment, callback: { ...state?.contact_appointment?.callback, status: action?.payload } } }
        case 'CALLBACK_DATE':
            return { ...state, contact_appointment: { ...state?.contact_appointment, callback: { ...state?.contact_appointment?.callback, date: action?.payload } } }
        case 'CALLBACK_TIME':
            return { ...state, contact_appointment: { ...state?.contact_appointment, callback: { ...state?.contact_appointment?.callback, time: action?.payload } } }

        case 'ONLINE_SELECT':
            return { ...state, contact_appointment: { ...stateOne?.contact_appointment, online: { ...state?.contact_appointment?.online, status: action?.payload } } }
        case 'ONLINE_DATE':
            return { ...state, contact_appointment: { ...state?.contact_appointment, online: { ...state?.contact_appointment?.online, date: action?.payload } } }
        case 'ONLINE_TIME':
            return { ...state, contact_appointment: { ...state?.contact_appointment, online: { ...state?.contact_appointment?.online, time: action?.payload } } }

        case 'INPERSON_SELECT':
            return { ...state, contact_appointment: { ...stateOne?.contact_appointment, inperson: { ...state?.contact_appointment?.inperson, status: action?.payload } } }
        case 'INPERSON_DATE':
            return { ...state, contact_appointment: { ...state?.contact_appointment, inperson: { ...state?.contact_appointment?.inperson, date: action?.payload } } }
        case 'INPERSON_TIME':
            return { ...state, contact_appointment: { ...state?.contact_appointment, inperson: { ...state?.contact_appointment?.inperson, time: action?.payload } } }

        case 'NO_INTEREST_SELECT':
            return { ...state, contact_interest: { ...stateOne?.contact_interest, no_interest: { ...state?.contact_interest?.no_interest, status: action?.payload } } }
        case 'NO_INTEREST_BUYER':
            return { ...state, contact_interest: { ...state?.contact_interest, no_interest: { ...state?.contact_interest?.no_interest, buyer: action?.payload, seller: false } } }
        case 'NO_INTEREST_SELLER':
            return { ...state, contact_interest: { ...state?.contact_interest, no_interest: { ...state?.contact_interest?.no_interest, seller: action?.payload, buyer: false } } }
        case 'NO_INTEREST_NOTES':
            return { ...state, contact_interest: { ...state?.contact_interest, no_interest: { ...state?.contact_interest?.no_interest, notes: action?.payload } } }

        case 'NOT_INTERESTED_SELECT':
            return { ...state, contact_interest: { ...stateOne?.contact_interest, not_interested_now: { ...state?.contact_interest?.not_interested_now, status: action?.payload } } }
        case 'NOT_INTERESTED_DATE':
            return { ...state, contact_interest: { ...state?.contact_interest, not_interested_now: { ...state?.contact_interest?.not_interested_now, date: action?.payload } } }
        case 'NOT_INTERESTED_TIME':
            return { ...state, contact_interest: { ...state?.contact_interest, not_interested_now: { ...state?.contact_interest?.not_interested_now, time: action?.payload } } }
        case 'NOT_INTERESTED_NOTES':
            return { ...state, contact_interest: { ...state?.contact_interest, not_interested_now: { ...state?.contact_interest?.not_interested_now, notes: action?.payload } } }
        case 'LEAD_TYPE':
            return { ...state, lead_type_verification: action?.payload }
        case 'LEAD_INFO_RELATION':
            return { ...state, lead_info: {...state?.lead_info, relationship: action?.payload } }
        case 'LEAD_INFO_FNAME':
            return { ...state, lead_info: {...state?.lead_info, firstname: action?.payload } }
        case 'LEAD_INFO_LNAME':
            return { ...state, lead_info: {...state?.lead_info, lastname: action?.payload } }
        case 'LEAD_INFO_PHONE1':
            return { ...state, lead_info: {...state?.lead_info, phone1: action?.payload } }
        case 'LEAD_INFO_PHONE2':
            return { ...state, lead_info: {...state?.lead_info, phone2: action?.payload } }
        case 'LEAD_INFO_EMAIL1':
            return { ...state, lead_info: {...state?.lead_info, email1: action?.payload } }
        case 'LEAD_INFO_EMAIL2':
            return { ...state, lead_info: {...state?.lead_info, email2: action?.payload } }
        case 'UPDATE':
            return {
                disableForm: true,
                lead_contact: action?.payload?.leadContact,
                contact_appointment: action?.payload?.contactAppointment,
                contact_interest: {
                    no_interest: action?.payload?.contactInterest?.noInterest,
                    not_interested_now: action?.payload?.contactInterest?.noInterestedNow
                },
                lead_type_verification: action?.payload?.leadTypeVerification,
                lead_info: action?.payload?.leadInfo
            }
        case 'RESET':
            return stateOne
        default:
            return state;
    }
}

export const statusTwoFn = (state, action) => {
    switch (action?.type) {
        case 'SIGNED_LISTING':
            return { ...state, signedListing: action?.payload }
        case 'FILES':
            return { ...state, files: action?.payload }
        case 'DISABLE':
            return { ...state, disableForm: action?.payload }
        case 'UPDATE':
            return {
                disableForm: true,
                signedListing: action?.payload?.signedListing
            }
        case 'RESET':
            return stateTwo
        default:
            return state;
    }
}

export const statusThreeFn = (state, action) => {
    switch (action?.type) {
        case 'OFFER_ACCEPTED':
            return { ...state, offerAccepted: action?.payload }
        case 'DISABLE':
            return { ...state, disableForm: action?.payload }
        case 'PRICE':
            return { ...state, purchase: { ...state?.purchase, price: action?.payload } }
        case 'ESCROW_DATE':
            return { ...state, purchase: { ...state?.purchase, escrow_date: action?.payload } }
        case 'COMMISSION':
            return { ...state, purchase: { ...state?.purchase, commission: action?.payload } }
        case 'TOTAL_GROSS':
            return { ...state, purchase: { ...state?.purchase, total_gross: action?.payload } }
        case 'DUAL_AGENT':
            return { ...state, dual_agent: action?.payload }

        case 'ESC_NAME':
            return { ...state, escrow_info: { ...state?.escrow_info, name: action?.payload } }
        case 'ESC_PHONE':
            return { ...state, escrow_info: { ...state?.escrow_info, phone: action?.payload } }
        case 'ESC_ADDRESS':
            return { ...state, escrow_info: { ...state?.escrow_info, address: action?.payload } }
        case 'ESC_CITY':
            return { ...state, escrow_info: { ...state?.escrow_info, city: action?.payload } }
        case 'ESC_OFFICER_FNAME':
            return { ...state, escrow_info: { ...state?.escrow_info, officer_fname: action?.payload } }
        case 'ESC_OFFICER_LNAME':
            return { ...state, escrow_info: { ...state?.escrow_info, officer_lname: action?.payload } }
        case 'ESC_OFFICER_PHONE':
            return { ...state, escrow_info: { ...state?.escrow_info, officer_phone: action?.payload } }
        case 'ESC_OFFICER_EMAIL':
            return { ...state, escrow_info: { ...state?.escrow_info, officer_email: action?.payload } }

        case 'COMPANY_NAME':
            return { ...state, company_info: { ...state?.company_info, name: action?.payload } }
        case 'COMPANY_PHONE':
            return { ...state, company_info: { ...state?.company_info, phone: action?.payload } }
        case 'COMPANY_ADDRESS':
            return { ...state, company_info: { ...state?.company_info, address: action?.payload } }
        case 'COMPANY_CITY':
            return { ...state, company_info: { ...state?.company_info, city: action?.payload } }
        case 'COMPANY_OFFICER_FNAME':
            return { ...state, company_info: { ...state?.company_info, officer_fname: action?.payload } }
        case 'COMPANY_OFFICER_LNAME':
            return { ...state, company_info: { ...state?.company_info, officer_lname: action?.payload } }
        case 'COMPANY_OFFICER_PHONE':
            return { ...state, company_info: { ...state?.company_info, officer_phone: action?.payload } }
        case 'COMPANY_OFFICER_EMAIL':
            return { ...state, company_info: { ...state?.company_info, officer_email: action?.payload } }
        case 'UPDATE_COMPANY_WITH_ESCROW':
            return { ...state, company_info: { ...state?.escrow_info } }
        case 'RESET_COMPANY':
            return { ...state, company_info: { ...stateThree?.company_info } }

        case 'BUYER_FNAME':
            return { ...state, buyer: { ...state?.buyer, first_name: action?.payload } }
        case 'BUYER_LNAME':
            return { ...state, buyer: { ...state?.buyer, last_name: action?.payload } }

        case 'SELLER_FNAME':
            return { ...state, seller: { ...state?.seller, first_name: action?.payload } }
        case 'SELLER_LNAME':
            return { ...state, seller: { ...state?.seller, last_name: action?.payload } }
        case 'CONTINGENCY':
            return { ...state, contingency: action?.payload }

        case 'UPDATE':
            return {
                disableForm: true,
                offerAccepted: action?.payload?.offerAccepted || '',
                purchase: {
                    ...action?.payload?.purchase,
                    escrow_date: action?.payload?.purchase?.escrowDate || '',
                    total_gross: action?.payload?.purchase?.totalGross || 0,
                },
                dual_agent: action?.payload?.dualAgent,
                escrow_info: {
                    ...action?.payload?.escrowInfo,
                    officer_fname: action?.payload?.escrowInfo?.officerFname || '',
                    officer_lname: action?.payload?.escrowInfo?.officerLname || '',
                    officer_phone: action?.payload?.escrowInfo?.officerPhone || '',
                    officer_email: action?.payload?.escrowInfo?.officerEmail || ''
                },
                company_info: {
                    ...action?.payload?.companyInfo,
                    officer_fname: action?.payload?.companyInfo?.officerFname || '',
                    officer_lname: action?.payload?.companyInfo?.officerLname || '',
                    officer_phone: action?.payload?.companyInfo?.officerPhone || '',
                    officer_email: action?.payload?.companyInfo?.officerEmail || ''
                },
                buyer: {
                    first_name: action?.payload?.buyer?.firstName || '',
                    last_name: action?.payload?.buyer?.lastName || '',
                },
                seller: {
                    first_name: action?.payload?.seller?.firstName || '',
                    last_name: action?.payload?.seller?.lastName || ''
                },
                contingency: action?.payload?.contingency || '',    
            }
        case 'RESET':
            return stateThree
        default:
            return state;
    }
}


export const statusFourFn = (state, action) => {
    switch (action?.type) {
        case 'CONTINGENCY':
            return { ...state, contingency: action?.payload };
        case 'DISABLE':
            return { ...state, disableForm: action?.payload }
        case 'CONTINGENCY_NOTES':
            return { ...state, contingency_notes: action?.payload };
        case 'ADD_CONTINGENT':
            const result = state?.contingents?.some(el => el?.name?.toLowerCase === action?.payload?.name?.toLowerCase())
            if (result) return toast.error('Sorry, contingent already exists')
            return { ...state, contingents: [...state?.contingents, action.payload] }
        case 'REMOVE_CONTINGENT':
            const res = state?.contingents?.filter(el => el?.name !== action?.payload)
            return { ...state, contingents: res }
        case 'UPDATE_CONTINGENT':
            const i = action?.payload?.index
            const filtered = state?.contingents?.[i]
            const newList = [...state?.contingents?.slice(0, i), { ...filtered, days: action?.payload?.value }, ...state?.contingents?.slice(i + 1)]
            return { ...state, contingents: newList }
        case 'MARK_AS_READ':
            const index = action?.payload
            const fil = state?.contingents?.[index]
            const filteredList = [...state?.contingents?.slice(0, index), { ...fil, read: fil?.read ? false : true }, ...state?.contingents?.slice(index + 1)]
            return { ...state, contingents: filteredList }
        case 'UPDATE_NOTES':
            const indx = action?.payload?.index
            const contingent = state?.contingents?.[indx]
            const allList = [...state?.contingents?.slice(0, indx), { ...contingent, notes: action?.payload?.value }, ...state?.contingents?.slice(indx + 1)]
            return { ...state, contingents: allList }
        case 'UPDATE':
            return {
                ...state,
                disableForm: true,
                contingents: action?.payload?.contingents || [],
                contingency_notes: action?.payload?.contingencyNotes || ''
            }
        case 'RESET':
            return stateFour
        default:
            return state;
    }
}

export const statusSixFn = (state, action) => {
    switch (action?.type) {
        case 'STATUS':
            return { status: action?.payload, closed: stateSix?.closed, fell_through: stateSix?.fell_through}
        case 'CLOSED_DATE':
            return { ...state, closed: { ...state?.closed, date: action?.payload } }
        case 'CLOSED_TIME':
            return { ...state, closed: { ...state?.closed, time: action?.payload } }
        case 'CLOSED_NOTES':
            return { ...state, closed: { ...state?.closed, notes: action?.payload } }
        case 'FT_DATE':
            return { ...state, fell_through: { ...state?.fell_through, date: action?.payload } }
        case 'FT_TIME':
            return { ...state, fell_through: { ...state?.fell_through, time: action?.payload } }
        case 'FT_NOTES':
            return { ...state, fell_through: { ...state?.fell_through, notes: action?.payload } }
        case 'FT_INTEREST':
            return { ...state, fell_through: { ...state?.fell_through, interest: action?.payload } }
        case 'UPDATE':
            return {
                disableForm: true,
                status: action?.payload?.status || '',
                closed: action?.payload?.closed,
                fell_through: action?.payload?.fellThrough || {}
            }
        case 'RESET':
            return stateSix;
        default:
            return state;
    }
}


export const validateForm = (stage, data) => {
    if (stage === 'contract') {
        if (data?.broker?.broker_name === '') return toast.error('Please provide broker name', { theme: 'colored' })
        if (data?.broker?.brokerage_name === '') return toast.error('Please select your brokerage', { theme: 'colored' })
        if (data?.broker?.brokerage_address === '') return toast.error('Please provide brokerage address', { theme: 'colored' })
        if (data?.broker?.brokerage_email === '' || !data?.broker?.brokerage_email?.includes('@')) return toast.error('Please provide a valid brokerage email', { theme: 'colored' })
        if (data?.broker?.brokerage_license === '') return toast.error('Please provide brokerage license', { theme: 'colored' })
        if (data?.broker?.brokerage_city === '') return toast.error('Please provide brokerage city', { theme: 'colored' })
        if (data?.broker?.brokerage_zip === '') return toast.error('Please provide brokerage zip', { theme: 'colored' })
        if (data?.broker?.brokerage_state === '') return toast.error('Please provide brokerage state', { theme: 'colored' })
        if (data?.broker?.brokerage_address === '') return toast.error('Please provide brokerage address', { theme: 'colored' })
        if (data?.agent?.first_name === '') return toast.error('Please provide your first name', { theme: 'colored' })
        if (data?.agent?.last_name === '') return toast.error('Please provide your last name', { theme: 'colored' })
        if (data?.agent?.license === '') return toast.error('Please provide your license number', { theme: 'colored' })
        if (data?.agent?.phone_1 === '') return toast.error('Please provide your phone number', { theme: 'colored' })
        if (data?.agent?.email_1 === '' || !data?.agent?.email_1?.includes('@')) return toast.error('Please provide a valid agent email address', { theme: 'colored' })
    }
    if (stage === 's2') {
        if (data?.signedListing === '') {
            toast.error('Please select an option for Listing Agreement', { theme: 'colored' })
            alert('error found')
            return
        }
    }
    if (stage === 's3') {
        if (data?.offerAccepted === '') { toast.error('Please select for offer accepted', { theme: 'colored' }); return }
        if (data?.purchase?.price === 0) { toast.error('Please provide purchase price', { theme: 'colored' }); return }
        if (data?.purchase?.escrow_date === '') { toast.error('Please provide Escrow Close Date', { theme: 'colored' }); return }
        if (data?.purchase?.commission === 0) return toast.error('Please provide Commission', { theme: 'colored' })
        if (data?.purchase?.total_gross === 0) return toast.error('Please provide Total Gross Commission', { theme: 'colored' })
        if (data?.dual_agent === '') return toast.error('Please select for Dual Agents', { theme: 'colored' })
        if (data?.escrow_info?.name === '') return toast.error('Please provide Escrow company name', { theme: 'colored' })
        if (data?.escrow_info?.phone === '') return toast.error('Please provide Escrow company phone number', { theme: 'colored' })
        if (data?.escrow_info?.address === '') return toast.error('Please provide Escrow company address', { theme: 'colored' })
        if (data?.escrow_info?.city === '') return toast.error('Please provide Escrow company city', { theme: 'colored' })
        if (data?.escrow_info?.officer_fname === '') return toast.error('Please provide Escrow officer firstname', { theme: 'colored' })
        if (data?.escrow_info?.officer_lname === '') return toast.error('Please provide Escrow officer lastname', { theme: 'colored' })
        if (data?.escrow_info?.officer_phone === '') return toast.error('Please provide Escrow officer phone', { theme: 'colored' })
        if (data?.escrow_info?.officer_email === '' || !data?.escrow_info?.officer_email?.includes('@')) return toast.error('Please provide Escrow officer email', { theme: 'colored' })
        if (data?.company_info?.name === '') return toast.error('Please provide company name', { theme: 'colored' })
        if (data?.company_info?.phone === '') return toast.error('Please provide company phone number', { theme: 'colored' })
        if (data?.company_info?.address === '') return toast.error('Please provide company address', { theme: 'colored' })
        if (data?.company_info?.city === '') return toast.error('Please provide company city', { theme: 'colored' })
        if (data?.company_info?.officer_fname === '') return toast.error('Please provide company officer firstname', { theme: 'colored' })
        if (data?.company_info?.officer_lname === '') return toast.error('Please provide company officer lastname', { theme: 'colored' })
        if (data?.company_info?.officer_phone === '') return toast.error('Please provide company officer phone', { theme: 'colored' })
        if (data?.company_info?.officer_email === '' || !data?.company_info?.officer_email?.includes('@')) return toast.error('Please provide company officer email', { theme: 'colored' })
        if (data?.buyer?.first_name === '') return toast.error('Please provide buyer first name', { theme: 'colored' })
        if (data?.buyer?.last_name === '') return toast.error('Please provide buyer last name', { theme: 'colored' })
        if (data?.seller?.first_name === '') return toast.error('Please provide seller first name', { theme: 'colored' })
        if (data?.seller?.last_name === '') return toast.error('Please provide buyer last name', { theme: 'colored' })
        if (data?.contingency === '') return toast.error('Please select a contingency', { theme: 'colored' })
    }
    if (stage === 's4') {
        if (data?.contingents?.length === 0) { toast.error('Please add a contingent', { theme: 'colored' }); return }
        if (data?.contingency_notes === '') { toast.error('Please add a note description', { theme: 'colored' }); return }
    }
    if (stage === 's5') {
        // if (data?.contingents?.length === 0) return toast.error('Please add a contingent', { theme: 'colored' })
        // if (data?.contingency_notes === '') return toast.error('Please add a note description', { theme: 'colored' })
    }
    if (stage === 's6') {
        if (data?.status === '') return toast.error('Please select', { theme: 'colored' })
        if (data?.status === 'closed') {
            if (data?.closed?.date === '') return toast.error('Please provide a date', { theme: 'colored' })
            if (data?.closed?.time === '') return toast.error('Please provide a time', { theme: 'colored' })
            if (data?.closed?.notes === '') return toast.error('Please provide a date', { theme: 'colored' })
        }
        if (data?.status === 'transaction fell through') {
            if (data?.closed?.interest === '') return toast.error('Please select an interest', { theme: 'colored' })
            if (data?.closed?.date === '') return toast.error('Please provide a date', { theme: 'colored' })
            if (data?.closed?.time === '') return toast.error('Please provide a time', { theme: 'colored' })
            if (data?.closed?.notes === '') return toast.error('Please provide a date', { theme: 'colored' })
        }
    }


}

export const getDateFromNow = (date, days) => {
    var newDate = new Date(date);
    var result = newDate.setDate(newDate.getDate() + Number(days));
    return result
}