import React from 'react'
import { Box, Card, Typography } from '@mui/material'



function DealItem({icon:Icon, text}) {
  return (
    <Card elevation={5} sx={{display:"flex",margin:"0.625rem 0",padding:"0.925rem",minWidth:"20rem"}}>
        <Box sx={{width:"15%"}}>
            <Icon sx={{fontSize:"1.5625rem"}}/>
        </Box>
        <Box sx={{width:"85%",display:"flex",alignItems:"center"}}>
            <Typography variant = 'h5' sx={{whiteSpace:"nowrap"}}>{text}</Typography>
        </Box>

    </Card>
  )
}

export default DealItem