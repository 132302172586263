import React from 'react';
import { Box, Link, Typography } from '@mui/material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { hideButton, scrollToTheLeft } from '../../utils/functions';
import Explore1 from '../../images/explore1.webp';
import Explore2 from '../../images/explore2.webp';
import Explore3 from '../../images/explore3.webp';
import Explore4 from '../../images/explore4.webp';
import Explore5 from '../../images/explore5.webp';
import Explore6 from '../../images/explore6.webp';
import { styled } from '@mui/styles';

const images = {
	0: Explore1,
	1: Explore2,
	2: Explore3,
	3: Explore4,
	4: Explore5,
	5: Explore6,
};

const RLink = styled(Link)(({ theme }) => ({
	textDecoration: 'none',
	cursor: 'pointer',
}));

function ServiceAreasSlider({ serviceAreas }) {
	return (
		<Box>
			<Typography variant='h5' textAlign={'center'}>
				Where Luxury Meets Lifestyle: Find Your Perfect Property Today!
			</Typography>
			<Box p={2} display='flex' justifyContent={'center'} alignItems='center'>
				<ChevronLeft
					fontSize='large'
					sx={{ cursor: 'pointer' }}
					onClick={() => scrollToTheLeft('service-areas-slider', true)}
					ref={(el) => hideButton(el, 'service-areas-slider')}
				/>
				<Box
					id='service-areas-slider'
					sx={{
						maxWidth: '80%',
						overflowX: 'scroll',
						'&::-webkit-scrollbar': {
							display: 'none',
						},
						scrollBehavior: 'smooth',
						display: 'flex',
						gap: '10px',
					}}
				>
					{[].map((area, index) => (
						<React.Fragment key={index}>
							<Box
								component={RLink}
								href={`${window.location?.href}/area-search?location=${area?.location?.replace(', USA', '')}`}
								target='_blank'
								sx={{
									height: '400px',
									aspectRatio: '1',
									justifyContent: 'center',
									alignItems: 'center',
									display: 'flex',
									background: `linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0.6)),url(${images[index % 6]})`,
									flexDirection: 'column',
									backgroundSize: 'cover',
									borderRadius: '5px',
								}}
							>
								<Typography variant='h5' color='white' sx={{ padding: '10px' }}>
									{area?.location?.replace(', USA', '')}
								</Typography>
								<Typography
									sx={{
										color: 'white',
										textDecoration: 'none',
										padding: '10px',
										cursor: 'pointer',
										'&:hover': { textDecoration: 'underline' },
										fontSize: '14px',
										fontFamily: 'inherit !important',
									}}
								>
									View Listings
								</Typography>
							</Box>
						</React.Fragment>
					))}
				</Box>
				<ChevronRight
					fontSize='large'
					sx={{ cursor: 'pointer' }}
					onClick={() => scrollToTheLeft('service-areas-slider', false)}
					ref={(el) => hideButton(el, 'service-areas-slider')}
				/>
			</Box>
		</Box>
	);
}

export default ServiceAreasSlider;
