import { Dns, LocalOfferOutlined, Lock, LockOpen, Schedule } from '@mui/icons-material'
import { Box, Button, Chip, Divider, Stack, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'
import { makeStyles } from '@mui/styles'
import React from 'react'
import LeadStatus from '../helpers/LeadStatus'
import { getDaysDiff } from '../utils'


const useStyles = makeStyles(theme => ({
    btn: {
        textTransform: 'none',
        borderRadius: '8px'
    }
}))



const LeadItem = ({ lead, acceptLead, lastAssigned, deadline, dispatch, openLead, isFinalized }) => {
    const classes = useStyles()
    const { assigned, uniqueId, progress, leadTypeName, city, leadSourceName, isContractSigned } = lead
    const daysLeft = getDaysDiff(lastAssigned, deadline) || 0

    const leadActionHandler = () => {
        if (isContractSigned) {
            openLead(lead)
        } else {
            dispatch({ type: 'AGREEMENT', payload: isContractSigned })
            if (assigned) {
                acceptLead(lead)
            }
        }
    }


    return (
        <Box p={3} mb={2} bgcolor={'#fff'} border={'1px solid lightgrey'} position={'relative'} overflow={'hidden'} borderRadius={'10px'} sx={{
            transition: 'all .3s ease-in', ':hover': {
                borderColor: theme => theme.palette.secondary.main,
                boxShadow: '0 2px 10px rgba(0,0,0, 12%)'
            }
        }}>
            {/* Ribbon */}
            {daysLeft < 10 && <Box sx={{ position: 'absolute', top: 0, left: 0, bgcolor: 'red', color: '#fff', px: 3 }}>
                <Typography>{daysLeft} Days More</Typography>
            </Box>
            }
            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'flex-start'}>
                <Box>
                    <Stack direction={'row'} gap={1} alignItems={'center'}>
                        {!isContractSigned ? <Lock fontSize='small' sx={{ color: grey[400] }} /> : <LockOpen fontSize='small' sx={{ color: grey[400] }} />}
                        <Typography sx={{ fontSize: '1.3rem', textTransform: 'uppercase' }}>{uniqueId}</Typography>
                    </Stack>
                    <Typography mt={.5} variant='body2' color={'GrayText'}>Unlock this lead by clicking accept</Typography>
                </Box>
                {city && <Chip size='small' label={city} />}
            </Stack>
            <Divider sx={{ my: 3, bgcolor: '#f7f7f7' }} />
            {
                isContractSigned &&
                <LeadStatus progress={progress} />
            }
            <Stack direction={'row'} gap={3} alignItems={'flex-end'}>
                <Box>
                    <Stack direction={'row'} gap={1} mb={1} alignItems={'center'}>
                        <Dns fontSize='small' sx={{ fontSize: '.9rem', color: grey[400] }} />
                        <Typography variant='body2' color={'GrayText'}>Lead Source: {leadSourceName}</Typography>
                    </Stack>
                    <Stack direction={'row'} gap={1} alignItems={'center'}>
                        <Schedule fontSize='small' sx={{ fontSize: '.9rem', color: grey[400] }} />
                        <Typography variant='body2' color={'GrayText'}>Date Assigned: {new Date(lastAssigned)?.toLocaleDateString('en-US', { month: 'short', year: 'numeric', day: '2-digit' })}</Typography>
                    </Stack>
                </Box>
                <Box>
                    <Stack direction={'row'} gap={1} mb={1} alignItems={'center'}>
                        <LocalOfferOutlined fontSize='small' sx={{ fontSize: '.9rem', color: grey[400] }} />
                        <Typography variant='body2' color={'GrayText'}>Lead Type: {leadTypeName}</Typography>
                    </Stack>
                    <Stack direction={'row'} gap={1} alignItems={'center'}>
                        <Schedule fontSize='small' sx={{ fontSize: '.9rem', color: grey[400] }} />
                        <Typography variant='body2' color={'GrayText'}>Deadline: {new Date(deadline)?.toLocaleDateString('en-US', { month: 'short', year: 'numeric', day: '2-digit' })}</Typography>
                    </Stack>
                </Box>

                <Box ml={'auto'} display={'flex'} flexDirection={'column'}>
                    <Button sx={{ mb: 1 }} onClick={leadActionHandler} size='small' variant='contained' disableElevation color={'secondary'} className={classes.btn}>
                        {isContractSigned ? 'Open Lead' : isFinalized ? 'View Report' : 'Accept Lead'}
                    </Button>
                    {(isContractSigned || isFinalized) && <Button sx={{ bgcolor: 'red', color: '#fff' }} disabled={isFinalized} size='small' variant='contained' disableElevation className={classes.btn}>Mark Lead as TFT</Button>}
                </Box>

            </Stack>
        </Box>
    )
}

export default LeadItem