import { Circle } from '@mui/icons-material';
import { Box, Card, CardContent, CardHeader, Container, Divider, Grid, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import SellerBG from '../../assets/sellerguide.webp';
import Check1 from '../../assets/checklist1.webp';
import Check2 from '../../assets/explore.webp';
import Check3 from '../../assets/homeimprove.webp';
import SEO from '../../components/SEO';

const useStyles = makeStyles((theme) => ({
	banner: {
		height: '60vh',
		backgroundImage: `url(${SellerBG})`,
		backgroundPosition: 'center',
		backgroundSize: 'cover',
	},
	title: {
		fontWeight: 600,
		marginBottom: '1rem',
	},
}));

const SellerGuide = () => {
	const classes = useStyles();
	const navigate = useNavigate();
	const lists = [
		'When is the Ideal Time to sell A House?',
		'Which Month is Best for listing houses?',
		'What Day is Ideal for listing a house?',
		'Seasonality influences the timing of home sales',
		`When you're ready, sell`,
		`How long does the home listing process take?`,
	];

	const choices = [
		`Lifestyle changes: The most frequent causes for selling include downsizing, a growing family, relocation, and job changes. While family-related relocation can occasionally be scheduled to benefit from advantageous market variables, job-related moves frequently require a stricter timeline.`,
		`No matter why you're moving, it's crucial to make sure you're emotionally ready for the transition. It might be challenging to let go of the memories connected to your family home, even if you're moving for a rational reason—for instance, you're a recent empty-nester downsizing after your kids are grown. Adapt your schedule.`,
		`Financial advantages: If your property has built up a significant amount of equity, you may be able to use that value as a down payment for your ideal home or as a cushion for retirement.`,
	];

	return (
		<div>
			<SEO>
				<title>2022 Home Buying Guide: Avoiding Costly Blunders</title>
				<meta name='og:title' content='2022 Home Buying Guide: Avoiding Costly Blunders' />
				<meta
					name='description'
					content="Learn about the best months, days and seasons to sell your house, factors to consider and tips to maximize your home's value in the market. Find out when to take advantage of low mortgage rates and when you should list your home for sale."
				/>
				<meta
					name='og:description'
					content="Learn about the best months, days and seasons to sell your house, factors to consider and tips to maximize your home's value in the market. Find out when to take advantage of low mortgage rates and when you should list your home for sale."
				/>
				<meta name='og:image' content={window.location.origin + SellerBG} />
			</SEO>
			<Box className={classes.banner}></Box>
			<Box sx={{ background: (theme) => theme.secondaryColor, padding: '4rem', color: '#fff' }}>
				<Typography textAlign={'center'} gutterBottom variant='h4'>
					Seller's Guide
				</Typography>
				<Divider sx={{ width: '7rem', height: '3px', margin: '1rem auto', background: (theme) => theme.primaryColor }} />
			</Box>
			<Box padding={'3rem 0'} bgcolor={grey[100]}>
				<Container>
					<Grid container>
						{lists?.map((el, index) => {
							return (
								<Grid item sm={6} key={index}>
									<ListItem>
										<ListItemIcon sx={{ minWidth: '35px' }}>
											<Circle sx={{ fontSize: '.7rem' }} color='secondary' />
										</ListItemIcon>
										<ListItemText>{el}</ListItemText>
									</ListItem>
								</Grid>
							);
						})}
					</Grid>
				</Container>
			</Box>

			<Box padding={'3rem 0'}>
				<Container>
					<Box mt={5}>
						<Typography className={classes.title} variant='h6'>
							When is the Ideal Time to sell A House?
						</Typography>
						<Typography paragraph mb={5}>
							If you want to sell your home quickly, the best month to do so is March, while the best month to do so is July. Based on past market
							trends, BPO Homes advises advertising your house for sale in March and no later than Labor Day.
						</Typography>

						<Typography className={classes.title} variant='h6'>
							Which Month is Best for listing houses?
						</Typography>
						<Typography paragraph>
							According to a May 2021 analysis from real estate research company ATTOM Data Solutions, late spring and early summer are the greatest
							seasons of the year to sell a home. The study examined single-family and condominium home sales for a period of ten years, from 2011
							through 2020. The figures show that in May when median sales prices peaked at $197,400 compared to a $174,000 median value, sellers
							received the greatest premium of 13.4%.
						</Typography>
						<Typography paragraph mb={5}>
							Sales throughout March, April, June, July, and August also provide bigger premiums than those in the fall and winter. The top six
							months' premiums are broken out in the table below based on the median sales price and median value.
						</Typography>

						<Typography className={classes.title} variant='h6'>
							What Day is Ideal for listing a house?
						</Typography>
						<Typography paragraph>
							Data shows that properties sold between July 2020 and February 2021 indicate that homes listed on Tuesday, Wednesday, or Thursday will
							fetch you $1,700 more than a home listed on the weekend.
						</Typography>
						<Typography paragraph mb={5}>
							However, depending on where you live, the amount you'll make on a midweek listing premium fluctuates. A Boston listing during the week
							might sell for $7,100 more than once during the weekend, for instance. The difference between midweek and weekend listing in Kansas
							City, Missouri, meanwhile, is only $400.
						</Typography>

						<Typography className={classes.title} variant='h6'>
							Seasonality influences the Timing of Home Sales
						</Typography>
						<Typography paragraph>
							The best season for listing a home for sale is often spring, at least in the years before the housing bubble caused by the epidemic.
							It's when buyers usually emerge from their winter hibernation and swarm the market.
						</Typography>
						<Typography paragraph mb={5}>
							Whether that sense of normalcy will return in 2021 is still up in the air. Even while the fluctuations from season to season are less
							drastic than in the past, seasonality in the housing market will likely continue in some form.
						</Typography>

						<Typography className={classes.title} variant='h6'>
							Listing Your House in Spring
						</Typography>
						<Typography paragraph mb={5}>
							As you can see from the aforementioned tendencies, individuals start looking for homes as the weather warms up, thus preparing your home
							for sale in the winter usually makes sense. It is understandable why spring is often the busiest season for buying and selling because
							people have their tax refunds in hand, it will be nicer to move in the coming days, and the kids will be out of school for the summer.
						</Typography>

						<Typography className={classes.title} variant='h6'>
							Listing Your House in Summer
						</Typography>
						<Typography paragraph mb={5}>
							Warm weather, school breaks, and simply having more daylight hours to visit open houses and take home tours are many of the same factors
							that influence buyers' decision-making when they browse for a home in the spring. The summer season is when most people take their
							vacations, so that may be the cause of the slight slowdown. Additionally, some regions of the country experience a decline in real
							estate activity during the summer since it is simply too hot to go shopping.
						</Typography>

						<Typography className={classes.title} variant='h6'>
							Listing Your House in Fall
						</Typography>
						<Typography paragraph mb={5}>
							Early-fall purchasers may be rushing to move to settle down before the start of the school year or the weather turns inclement, which
							can be advantageous to sellers. The fact that many off-season buyers are relocating due to a job transfer, layoff, short sale, or family
							difficulty, however, may make them more price sensitive.
						</Typography>

						<Typography className={classes.title} variant='h6'>
							Listing Your House in Winter
						</Typography>
						<Typography paragraph mb={5}>
							Although the winter months are often the slowest for house sales, if your region has a warm temperature all year round (like Florida or
							the Southwest), your window for selling may even be stronger during the winter as snowbirds go to the sunnier climates.
						</Typography>

						<Typography className={classes.title} variant='h6'>
							When Mortgage Rates decline, Sell
						</Typography>
						<Typography paragraph mb={5}>
							Mortgage interest rates have been historically low for the past four years or so. The further a buyer's budget has to stretch to get the
							desired home, the higher the rates. However, first-time buyers are more likely to purchase when interest rates are low.
						</Typography>

						<Typography className={classes.title} variant='h6'>
							When you're Ready, Sell
						</Typography>
						<Typography paragraph>
							In the end, the ideal moment for you to sell is also the best time for buyers. Your decision to sell may be influenced by a variety of
							personal considerations, including changes in your employment or family as well as a general feeling of readiness. Consider the
							following elements:
						</Typography>
						<List>
							{choices?.map((el, index) => {
								return (
									<ListItem key={index}>
										<ListItemIcon sx={{ minWidth: '35px' }}>
											<Circle sx={{ fontSize: '.7rem' }} color='secondary' />
										</ListItemIcon>
										<ListItemText>
											<Typography variant='body1'>{el}</Typography>
										</ListItemText>
									</ListItem>
								);
							})}
						</List>

						<Typography className={classes.title} variant='h6'>
							How long does the home listing process take?
						</Typography>
						<Typography paragraph mb={5}>
							Before officially advertising their home for sale, sellers spend an average of seven months just considering selling. Additionally, they
							usually do at least a few home renovations.
						</Typography>
					</Box>
				</Container>
			</Box>

			<Box padding={'5rem 0'} sx={{ background: (theme) => theme.secondaryColor, color: '#fff' }}>
				<Container>
					<Typography variant='h6' textAlign={'center'}>
						U.S. homes typically sell 55 to 70 days after being listed, including the typical closing time and the 25 days on the market (a record low
						in 2020).
					</Typography>
				</Container>
			</Box>

			<Box mt={4} mb={5}>
				<Container>
					<Typography mb={3} mt={3}>
						To prepare for a sale, you should plan to set aside at least two months. This will give you time to investigate the local market, select a
						trustworthy agent, properly clean your house, and finish any little repairs.
					</Typography>
					<Typography variant='h6' mb={3}>
						Read Also:
					</Typography>
					<Grid container spacing={4}>
						<Grid item xs={12} sm={12} lg={4}>
							<Card variant='elevation' sx={{ cursor: 'pointer' }} onClick={() => navigate('/bpo-guide')}>
								<CardHeader style={{ backgroundImage: `url(${Check1})`, backgroundSize: 'cover', height: '15rem' }}></CardHeader>
								<CardContent style={{ padding: '2rem' }}>
									<Typography color={'secondary'} mb={2}>
										Getting Started
									</Typography>
									<Typography variant='body1' color={'textSecondary'}>
										Why Buyers Need A Broker's Price Opinion
									</Typography>
								</CardContent>
							</Card>
						</Grid>
						<Grid item xs={12} sm={12} lg={4}>
							<Card variant='elevation' sx={{ cursor: 'pointer' }} onClick={() => navigate('/explore')}>
								<CardHeader style={{ backgroundImage: `url(${Check2})`, backgroundSize: 'cover', height: '15rem' }}></CardHeader>
								<CardContent style={{ padding: '2rem' }}>
									<Typography color={'secondary'} mb={2}>
										Getting Started
									</Typography>
									<Typography variant='body1' mb={3.5} color={'textSecondary'}>
										Explore My Options
									</Typography>
								</CardContent>
							</Card>
						</Grid>
						<Grid item xs={12} sm={12} lg={4}>
							<Card variant='elevation' sx={{ cursor: 'pointer' }} onClick={() => navigate('/home-improvement')}>
								<CardHeader style={{ backgroundImage: `url(${Check3})`, backgroundSize: 'cover', height: '15rem' }}></CardHeader>
								<CardContent style={{ padding: '2rem' }}>
									<Typography color={'secondary'} mb={2}>
										Getting Started
									</Typography>
									<Typography variant='body1' color={'textSecondary'}>
										Home Improvement and Remodeling Trends in 2022
									</Typography>
								</CardContent>
							</Card>
						</Grid>
					</Grid>
				</Container>
			</Box>
		</div>
	);
};

export default SellerGuide;
