import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useOutletContext } from 'react-router-dom';
import { toast } from 'react-toastify';
import base from '../../../apis';
import Button from '../../../components/Button/Button';

function Bio() {
	//eslint-disable-next-line
	const [agent, isLoading, setIsLoading, setAgent] = useOutletContext();
	const [bio, setBio] = useState('');
	const id = useSelector((state) => state.auth.typeId);

	useEffect(() => {
		if (agent) {
			setBio(agent.bio || '');
		}
	}, [agent]);

	const onSubmit = (e) => {
		e.preventDefault();
		if (!e.target.checkValidity()) return;
		setIsLoading(true);
		base
			.patch(`register/agents/${id}/`, {
				bio: bio || null,
			})
			.then(({ data }) => {
				setAgent(data);
				toast.success('Bio updated');
			})
			.catch((err) => {
				toast.error('Could update Bio');
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	return (
		<form style={{ padding: '10px', minHeight: '60vh' }} onSubmit={onSubmit}>
			<div style={{ display: 'flex', justifyContent: 'space-between' }}>
				<p>About</p>
				<Button
					style={{
						width: 'fit-content',
						height: '45px',
						fontSize: '16px',
						padding: '10px 30px',
						color: 'white',
						backgroundColor: 'var(--primary-color)',
						border: '1px solid var(--primary-color)',
						marginTop: '0',
					}}
					type='submit'
					text='Save'
				/>
			</div>
			<textarea
				rows={20}
				value={bio}
				onChange={(e) => setBio(e.target.value)}
				style={{ width: '100%', resize: 'none', fontFamily: 'Lato' }}
				placeholder='Give a description of the subject property'
			></textarea>
		</form>
	);
}

export default Bio;
