import BARicon from "../../assets/dealAnalyzer/iconComponents/BARicon";
import NCFicon from "../../assets/dealAnalyzer/iconComponents/NCFicon";
import RDSADicon from "../../assets/dealAnalyzer/iconComponents/RDSADicon";
import SARicon from "../../assets/dealAnalyzer/iconComponents/SARicon";
import Rds from "../../assets/dealAnalyzer/iconComponents/Rds";

export const headerData = {
  straightAestheticRemodel: {
    title: "Straight Aesthetic Remodel",
    icon: SARicon,
  },
  remodelAndAdditionalSqft: {
    title: "Remodel & Add Addition",
    icon: SARicon,
  },
  remodelDownToStuds: {
    title: "Remodel Down to Studs",
    icon: Rds,
  },
  remodelDownToStudsAdditonal: {
    title: "Remodel Down to Studs & Add Addition",
    icon: RDSADicon,
  },
  newConstruction: {
    title: "New Construction / Full Development",
    icon: NCFicon,
  },
  buyAndRent: {
    title: "Buy & Rent",
    icon: BARicon,
  },
};
