import { Box, Card, Checkbox, Grid, IconButton, Typography,Menu, MenuItem } from '@mui/material'
import React from 'react'
import RegularToolTip from '../../../components/DealAnalyzerComponents/atomicComponents/RegularToolTip';
import { ClipLoader } from 'react-spinners';
import { GridMoreVertIcon } from '@mui/x-data-grid';
function ReportItem({report,redirectToReport,edit,loadingToEdit, loadingToPrint,index,loadingToDelete,handleDelete, bulkActions,unCompleted}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (option, event) => {
    setAnchorEl(null);
    switch (option) {
      case "Delete":
        handleDelete({
          id: report?.id,
          report_type: report?.report_type,
          index,
        });
        break;
      case "Edit":
        edit(report, index);
        break;
      case "Print":
        redirectToReport({
          id: report?.id,
          report_type: report?.report_type,
          index,
        });
        break;
      default:
        return "";
    }
  };
  const ITEM_HEIGHT = 28;

  const options = [
    'Edit',
    'Print',
    'Delete'
  ]

  const checkSize = { "& .MuiSvgIcon-root": { fontSize: 20 } };

    return (
      <Card sx={{ m: 2, py: 2 }}>
        <Grid container columns={24}>
          <Grid item md={12}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                "&:hover": { textDecoration: "underline" },
                cursor: "pointer",
              }}
            >
              <Box sx={{ mx: 1 }}>
                <Checkbox
                  sx={checkSize}
                  checked={
                    !!bulkActions.reportIsChecked(
                      report?.id,
                      report?.report_type
                    )
                  }
                  onChange={() =>
                    bulkActions.addToChecked(report?.id, report?.report_type)
                  }
                />
              </Box>
              <Typography
                onClick={() =>
                  redirectToReport({
                    id: report?.id,
                    report_type: report?.report_type,
                  })
                }
                variant="h5"
              >
                {report?.property_address}
              </Typography>
            </Box>
          </Grid>
          <Grid item md={6} sx={{display: "flex", alignItems: "center"}}>
            <Box >
              <Typography variant="h5">
                {dealTypes?.[+report?.report_type - 1 || 0]}
              </Typography>
            </Box>
          </Grid>
          <Grid item md={4} sx={{display: "flex", alignItems: "center"}}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h5">
                {new Date(
                  unCompleted ? report?.created : report?.listing_date
                ).toDateString()}
              </Typography>
            </Box>
          </Grid>
          <Grid item md={2} sx={{display: "flex", alignItems: "center"}}>
            {!(loadingToDelete || loadingToEdit || loadingToPrint) &&
            <RegularToolTip content="Menu">
              <Box>
                <IconButton
                  aria-label="more"
                  id="long-button"
                  aria-controls={open ? "long-menu" : undefined}
                  aria-expanded={open ? "true" : undefined}
                  aria-haspopup="true"
                  onClick={handleClick}
                >
                  <GridMoreVertIcon sx={{fontSize:"1.4rem"}} />
                </IconButton>
                <Menu
                  id="long-menu"
                  MenuListProps={{
                    "aria-labelledby": "long-button",
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  PaperProps={{
                    style: {
                      maxHeight: ITEM_HEIGHT * 4.5,
                      width: "20ch",
                    },
                  }}
                >
                  {options.map((option) => (
                    <MenuItem
                      key={option}
                      value={option}
                      onClick={(event) => handleClose(option, event)}
                    >
                      <Typography variant="h5">{option}</Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
              </RegularToolTip>
            }
            <Box>
              {(loadingToDelete || loadingToEdit || loadingToPrint) && (
                <Box sx={{ml:1}}>
                  <ClipLoader color={"#B8D6E4"} loading={true} size={18} />
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
      </Card>
    );
}

export default ReportItem

const dealTypes = [
    'Straight Aesthetic Remodel',
    "Straight Remodel & Add Addition",
    "Remodel Down to Studs",
    'Remodel Down to Studs & Add Addition',
    'New Construction',
    'Buy & Rent'
]