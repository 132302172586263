import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useProformaData from "../hooks/dealReporthooks/useProformaData";
// import { getTotal } from "./utils/dealAnalyzerUtilsFunctions";
import * as actionCreators from "../../../actions/dealAnalyzer/index";
import {
  interestOnlyMortgageCalculator,
  mortgageLoanCalculator,
  yearsToMonthsTransformer,
} from "./utils/genericFunctions";

export const StraightAestheticRemodelCalculations = () => {
  const { dealFinance } = useProformaData();

  const primaryHomeLoan = dealFinance && dealFinance?.primaryHomeLoan;

  const dispatch = useDispatch();

  const financialOptions = useSelector(
    (state) => state?.straightAestheticRemodel.financeOptions
  );

  // calculates total Monthly Mortgage loan
  const localLoanAmount = financialOptions.localGeneralLoanAmount;
  const loanRate = financialOptions.primary_interest_rate;
  const primaryHomeLoanInterestRate = +loanRate / 100 / 12;
  const primaryHomeLoanLoanTerm = +primaryHomeLoan[8]?.value * 12;

  const primaryLoanInterestRateFunc = useCallback(() => {
    const TotalPrimaryMonthlyLoanAmount = mortgageLoanCalculator(
      localLoanAmount,
      primaryHomeLoanInterestRate,
      primaryHomeLoanLoanTerm
    );
    const localTotalPrimaryMonthlyLoanAmount = {
      localTotalPrimaryMonthlyLoanAmount: TotalPrimaryMonthlyLoanAmount,
    };
    //   const generalData = useSelector((state) => state?.straightAestheticRemodel);
    dispatch(
      actionCreators.updateFinanceOptions(localTotalPrimaryMonthlyLoanAmount)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localLoanAmount, primaryHomeLoanInterestRate, primaryHomeLoanLoanTerm]);

  useEffect(() => {
    primaryLoanInterestRateFunc();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [primaryLoanInterestRateFunc]);

  const rate = +financialOptions.primary_annual_interest_rate;
  const amount = +financialOptions.localGeneralLoanAmount;
  const monthOrYearChecker =
    financialOptions.primary_interest_only_term_month_or_year;
  const monthOrYear = financialOptions.primary_interest_only_term;

  const interestAloneMortgageFunc = useCallback(() => {
    const timeTransformer = yearsToMonthsTransformer(
      monthOrYearChecker,
      monthOrYear
    );
    const res = interestOnlyMortgageCalculator(rate, amount);

    const yearsToMonthsTransformed = {
      yearsToMonthsTransformed: timeTransformer,
    };
    const interestOnlyReturned = { interestOnlyReturned: res };
    dispatch(actionCreators.updateFinanceOptions(interestOnlyReturned));
    dispatch(actionCreators.updateFinanceOptions(yearsToMonthsTransformed));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rate, amount, monthOrYear, monthOrYearChecker]);

  useEffect(() => {
    interestAloneMortgageFunc();
  }, [interestAloneMortgageFunc]);

  return <></>;
};
