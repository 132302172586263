import { Box, FormControl, OutlinedInput,InputAdornment ,Typography } from '@mui/material'
import React from 'react'
import { useTheme } from '@mui/material/styles'
import StyledLabel from '../atomicComponents/StyledLabel'

const useStyles = (_theme)=>{
    return{
        root:{
            width:"50%",
            minWidth:"40rem",
            display:"flex",
            alignItems:"center",
            flexDirection:"column",
        },
        label:{
            marginBottom:"1rem"
        }
    }
}

const QuestionnaireAdditionalSqft = ({data,handler,name, extraComponentData}) => {

    const theme = useTheme()
    const styles = useStyles(theme)
    const handleChange = (e)=>{
        handler({[e.target.name]: e.target.value})
    }
    
  return (
    <Box sx={styles.root}>
        <Box sx={{ width: "100%", marginTop: "1.25rem" }}>
          <Box sx={{ marginBottom: "10px" }}>
            <StyledLabel label={extraComponentData?.additionalSqftHeader ||"How much square footage do you intend to add?"} />
          </Box>
          <FormControl sx={{ width: "100%" }}>
            <OutlinedInput
              fullWidth
              margin="normal"
              name={name ||"square_footage"}
              value={!name ? data?.["additional_sqft"]:data[name]}
              placeholder="0"
              onChange={handleChange}
              type="number"
              renderValue={(value) => value}
              inputProps={{ style: { fontSize: "1rem" } }}
              endAdornment={
                <InputAdornment position="start">
                  {" "}
                  <Typography variant="adornment" color="secondary">
                    {" "}
                    SqFt
                  </Typography>
                </InputAdornment>
              }
            />
          </FormControl>
        </Box>
    </Box>
  )
}

export default QuestionnaireAdditionalSqft