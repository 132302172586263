import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  OutlinedInput,
  InputAdornment,
  FormControl,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import StyledLabel from "../atomicComponents/StyledLabel";
import useProformaData from "../hooks/dealReporthooks/useProformaData";
import commaSeparate from "../utils/commaSeparate";
import useSaveNow from "../hooks/useSaveNow";
import { useDispatch, useSelector } from "react-redux";
import { greyout } from "../utils/greyout";
import FlatRatePercentageInput from "../atomicComponents/FlatRatePercentageInput";
import * as actionCreators from "../../../actions/dealAnalyzer/index";

const useStyles = (theme) => {
  return {
    root: {
      margin: "1.25rem",
    },
    inputContainer: {
      display: "flex",
      flexDirection: "column",
      marginTop: "3.125rem",
    },
    bottomLabel: {
      color: theme.palette.primary.main,
      marginTop: "10px",
    },
    under: {
      margin: "5px 0 25px 0 ",
    },
  };
};

function Taxes({ handleEvents, data }) {
  const { fetchResult, resultData } = useProformaData({ noAutoLoad: true });
  const { saveNow } = useSaveNow();
  const analyzerData = useSelector((state) => state?.straightAestheticRemodel);

  const handleChange = (e) => {
    let value = e.target.value;
    const variable = e.target.name;
    if (value) {
      value = Number.parseInt(value.split(",").join(""));
      if (isNaN(value)) {
        return;
      }
      handleEvents({ [variable]: value });
    } else {
      handleEvents({ [variable]: value });
    }
  };
  const rateHandler = (value) => {
    handleEvents({ city_and_county_tax_fees_dollar_or_percent: value });
  };

  const theme = useTheme();
  const styles = useStyles(theme);

  const save = async () => {
    try {
      saveNow({ taxes: analyzerData?.taxes });
      fetchResult();
    } catch (err) {}
  };
  const dispatch = useDispatch();
  //=========================== tax calculations ===============================//

  const [monthlyAmount, setMonthlyAmount] = useState();
  const financialOptions = useSelector(
    (state) => state?.straightAestheticRemodel.financeOptions
  );
  const taxes = useSelector((state) => state?.straightAestheticRemodel.taxes);

  const purchasePrice = financialOptions.primary_purchase_price;
  const taskRate = taxes.city_and_county_tax;
  const taskRateChecker = +taxes.city_and_county_tax_fees_dollar_or_percent;

  const MonthlyTaxPayment = useCallback(() => {
    if (taskRateChecker === 1) {
      const rate = +taskRate / 100 / 12;
      const monthlyAmount = rate * purchasePrice;
      setMonthlyAmount(monthlyAmount.toFixed(2));
    } else {
      const rate = (0 || taskRate) / purchasePrice / 12;
      const monthlyAmount = rate * purchasePrice;

      setMonthlyAmount(monthlyAmount.toFixed(2));
    }

    // recheck dependencies if effect is updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [purchasePrice, taskRate, monthlyAmount]);

  useEffect(() => {
    MonthlyTaxPayment();
    dispatch(
      actionCreators.updateTaxes({
        monthlyTaxPaid: monthlyAmount,
      })
    );
    // recheck dependencies if effect is updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [MonthlyTaxPayment]);

  const amount = isNaN(monthlyAmount) ? 0 : monthlyAmount;

  const monthlyAmountToPay =
    commaSeparate(amount) || commaSeparate(resultData?.monthly_tax_payment);

  //=============xxxx===============================xxx=================//

  useEffect(() => {
    save();
    // NOTE: Run effect once on component mount, please
    // recheck dependencies if effect is updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    data?.city_and_county_tax,
    data?.city_and_county_tax_fees_dollar_or_percent,
  ]);
  return (
    <Box sx={styles.root}>
      <Box sx={styles.amount}>
        <FlatRatePercentageInput
          handler={handleEvents}
          data={data}
          title="Yearly State Property Tax"
          noToolTip={true}
          rateHandler={rateHandler}
          name="city_and_county_tax"
          rate={
            data?.city_and_county_tax_fees_dollar_or_percent
              ? data.city_and_county_tax_fees_dollar_or_percent
              : 0
          }
          referenceValue={analyzerData?.financeOptions?.primary_purchase_price}
        />

        <Box sx={styles.under}>
          {" "}
          <Typography variant="notice" paragraph mb={0}>
            Please Note: Estimate 1%-3% of Purchase Price.
          </Typography>
          <Typography variant="notice">
            (Contact the Local Building Department to get more accurate Pricing)
          </Typography>
        </Box>
        <Box sx={styles.inputWithLabel}>
          {TaxesStaticData.map((element, index) => (
            <Box sx={styles.inputContainer}>
              <StyledLabel label={element?.label} />
              <FormControl sx={{ minWidth: "18.5rem" }}>
                <OutlinedInput
                  fullWidth
                  margin="normal"
                  name={element.variableName}
                  onChange={index === 0 ? handleChange : ""}
                  value={monthlyAmountToPay}
                  sx={greyout}
                  placeholder="0"
                  renderValue={(value) => value}
                  inputProps={{ style: { fontSize: "1rem" } }}
                  startAdornment={
                    <InputAdornment position="start">
                      {" "}
                      <Typography variant="adornment" color="secondary">
                        {" "}
                        $
                      </Typography>
                    </InputAdornment>
                  }
                />
              </FormControl>
              {element?.bottomLabel && (
                <Box sx={styles.bottomLabel}>
                  <Typography variant="notice" color="primary">
                    {element.bottomLabel}
                  </Typography>
                </Box>
              )}
            </Box>
          ))}
          <StyledLabel label={""} />
        </Box>
      </Box>
    </Box>
  );
}

export default Taxes;

const TaxesStaticData = [
  // {label:"City & County Tax",variableName:"city_and_county_tax",bottomLabel:"Get the State & City Yearly Tax Rate"},
  { label: "Monthly Tax Payment", variableName: "monthly_tax_payment" },
];
