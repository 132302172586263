import { Close } from '@mui/icons-material'
import { Box, Button, Checkbox, Divider, FormControlLabel, Grid, IconButton, InputAdornment, Radio, RadioGroup, Stack, TextField, Typography } from '@mui/material'
import React from 'react'

const FormInputItem = ({ title, formData, formDispatch, classes, required, disable, data, flex, isContingent, addContingency, showButton, updateContingent, removeContingent }) => {
    
    return (
        <Grid container>
            <Grid item sm={3}>
                <Typography fontWeight={500} color={'secondary'}>{title}</Typography>
            </Grid>
            <Grid item sm={9}>
                <Grid container spacing={3} mb={2}>
                    {
                        formData?.map((el, i) => {
                            return (
                                <Grid key={i} item sm={flex || (el?.type === 'textarea' || el?.type === 'radio') ? 12 : 6}>
                                    { (el?.type === 'ctgs' || el?.type === 'radio') ? null : <Typography className={classes.label}>{el?.label}{required && <span style={{ color: 'red', marginLeft: '5px' }}>*</span>}</Typography>}
                                    { 
                                        el?.type === 'date' &&
                                        <TextField disabled={disable}
                                            value={data?.[el?.dataName]?.[el?.dataKey]}
                                            size='small' fullWidth
                                            onChange={(e) => formDispatch({ type: el?.action, payload: e?.target?.value })}
                                            type={'date'}

                                        />
                                    }
                                    {
                                        el?.type === 'number' &&
                                        <TextField type={el?.type} inputProps={{ min: 0 }} disabled={disable} InputProps={{
                                            startAdornment: <InputAdornment position='start'>$</InputAdornment>
                                        }}
                                            onChange={(e) => formDispatch({ type: el?.action, payload: e?.target?.value })}
                                            size='small' fullWidth
                                            value={data?.[el?.dataName]?.[el?.dataKey]}
                                        />
                                    }
                                    {
                                        (el?.type === 'text' || el?.type === 'tel' || el?.type === 'email') &&
                                        <TextField type={el?.type} disabled={disable}
                                            onChange={(e) => formDispatch({ type: el?.action, payload: e?.target?.value })}
                                            size='small' fullWidth
                                            value={data?.[el?.dataName]?.[el?.dataKey]}
                                        />
                                    }
                                    {
                                        el?.type === 'time' &&
                                        <TextField type={el?.type} disabled={disable}
                                            onChange={(e) => formDispatch({ type: el?.action, payload: e?.target?.value })}
                                            size='small' fullWidth
                                            value={data?.[el?.dataName]?.[el?.dataKey]}
                                        />
                                    }
                                    {
                                        el?.type === 'textarea' &&
                                        <TextField type={el?.type} disabled={disable} multiline rows={5}
                                            onChange={(e) => formDispatch({ type: el?.action, payload: e?.target?.value })}
                                            size='small' fullWidth placeholder='Add a note'
                                            value={data?.[el?.dataName]?.[el?.dataKey]}
                                        />
                                    }
                                    {
                                        el?.type === 'ctgs' &&
                                        <Box>
                                            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                                <FormControlLabel label={el?.name} control={<Checkbox checked size='small' />} />
                                                <Stack direction={'row'} gap={2} alignItems={'center'}>
                                                    <TextField type='number' inputProps={{ min: 0 }} size='small' 
                                                        fullWidth value={el?.days} 
                                                        onChange={(e)=>updateContingent(i, e?.target?.value)} 
                                                    />
                                                    <IconButton size='small' onClick={()=>removeContingent(el?.name)}><Close fontSize='small' /></IconButton>
                                                </Stack>
                                            </Stack>
                                            <Divider sx={{ mt: 2.5, bgcolor: '#ededed' }} />
                                        </Box>
                                    }
                                    {
                                        el?.type === 'radio' &&
                                            <RadioGroup row 
                                                value={data?.[el?.dataName]?.[el?.dataKey]}
                                                onChange={(e) => formDispatch({ type: el?.action, payload: e?.target?.value })}
                                            >
                                                {
                                                    el?.label?.map((el, i) => <FormControlLabel disabled={disable} key={i} value={el?.toLowerCase()} sx={{ mr: 4 }} control={<Radio size='small' color='secondary' />} label={el} />)
                                                }
                                            </RadioGroup>
                                    }

                                </Grid>
                            )
                        })
                    }
                    {
                        isContingent &&
                        <Grid item sm={flex}>
                            <Typography className={classes.label}>{'Notes'}{required && <span style={{ color: 'red', marginLeft: '5px' }}>*</span>}</Typography>
                            <TextField multiline value={data?.contingency_notes || ''} 
                                onChange={(e) => formDispatch({ type: "CONTINGENCY_NOTES", payload: e?.target?.value })} 
                                rows={5} placeholder='Add a note' fullWidth />
                        </Grid>
                    }
                    {
                        showButton &&
                        <Grid item sm={flex} display={'flex'} justifyContent={'flex-end'}>
                            <Button variant='outlined' color='secondary' onClick={addContingency} disableElevation className={classes.btn} >Add Contingency</Button>
                        </Grid>

                    }
                </Grid>
            </Grid>
        </Grid>
    )
}

export default FormInputItem