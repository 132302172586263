import React from 'react';
import { useParams } from 'react-router-dom';
import './digitalpreview.scss';

function DigitalCardPreview() {
	const { id } = useParams();

	return (
		<div style={{ display: 'flex', justifyContent: 'center' }}>
			<div style={{ position: 'absolute' }}>
				{/* <img src='https://i.stack.imgur.com/AR3kw.png' style={{ zIndex: 1, position: 'absolute', pointerEvents: 'none' }} alt='phone' /> */}
				<iframe title='Card Preview' src={`${window.location.origin}/agent/${id}`} className='iframe'></iframe>
			</div>
		</div>
	);
}

export default DigitalCardPreview;
