import {
  Box,
  OutlinedInput,
  FormControl,
  InputAdornment,
  Typography,
  Button,
} from "@mui/material";
import React, { useEffect } from "react";
import StyledLabel from "../atomicComponents/StyledLabel";
import MonthYearSelector from "../atomicComponents/MonthYearSelector";
import { useTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";
import useProformaData from "../hooks/dealReporthooks/useProformaData";
import useSaveNow from "../hooks/useSaveNow";
import { greyout } from "../utils/greyout";
import commaSeparate from "../utils/commaSeparate";
import { ClipLoader } from "react-spinners";
import { useMemo } from "react";
import { carryingMonthsCalcs } from "../localCalcutions/formulas/CarryingMonths";

const useStyles = (_theme) => {
  return {
    root: {
      margin: "1.25rem",
    },
    total: {
      margin: "1.875rem 0",
    },
  };
};
function CarryingMonths({ handleEvents, data }) {
  const { resultData, fetchResult } = useProformaData();
  const [loading, setLoading] = React.useState(false);
  const { saveNow } = useSaveNow();
  const analyzerData = useSelector((state) => state?.straightAestheticRemodel);

  const theme = useTheme();
  const styles = useStyles(theme);

  const save = async () => {
    setLoading(true);
    try {
      await saveNow({ carrying_costs: analyzerData?.carryingMonths });
      await fetchResult();
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!data?.carrying_cost_owning_months) {
      handleEvents({ carrying_cost_owning_months: 0 });
    }
    fetchResult();
    // NOTE: Run effect once on component mount, please
    // recheck dependencies if effect is updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //======================local Curring cost calculations ======================================//

  //========================================== carrying cost amount calcs
  const carryingCostCalculations = useMemo(
    () =>
      carryingMonthsCalcs({
        remodelCosts: analyzerData.remodelCosts,
        developmentCost: analyzerData?.developmentCost,
        financeOptions: analyzerData.financeOptions,
        resultData,
        dealId: analyzerData.dealAnalyzerReportId.analyzerTypeId,
        propertyPermitAndFees: analyzerData?.propertyPermitAndFees,
        taxes: analyzerData.taxes,
        rentalIncome: analyzerData.rentalIncome,
        propertyManagement: analyzerData.propertyManagement,
        otherCosts: analyzerData?.otherCosts,
        carryingMonths: data,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      analyzerData.remodelCosts,
      analyzerData.financeOptions,
      analyzerData?.propertyPermitAndFees,
      analyzerData?.developmentCos,
      analyzerData.taxes,
      analyzerData.rentalIncome,
      analyzerData.propertyManagement,
      analyzerData?.otherCosts,
      analyzerData?.carryingMonths,
      data,
    ]
  );

  const returnedCurryingCost = isNaN(
    carryingCostCalculations?.localTotalCurryingCost
  )
    ? 0
    : carryingCostCalculations?.localTotalCurryingCost;

  const curryingCost =
    commaSeparate(returnedCurryingCost) ||
    commaSeparate(resultData?.carrying_months?.total_carrying_cost);

  return (
    <Box sx={styles.root}>
      <MonthYearSelector
        data={data}
        label={carryingMonthsStaticData.input1.label}
        override={{ minWidth: "18.75rem" }}
        handler={handleEvents}
        name="carrying_cost_owning_months"
      />
      <Box sx={styles.total}>
        <Box sx={{ marginBottom: "5px" }}>
          <StyledLabel label={carryingMonthsStaticData.input2.label} />
        </Box>
        <Box sx={{ display: "flex" }}>
          <FormControl
            sx={{
              minWidth: "18.75rem",
              width: "100%",
              marginRight: "5px",
            }}
          >
            <OutlinedInput
              fullWidth
              sx={greyout}
              name="listing_date"
              // onChange={handleChange}
              value={curryingCost}
              placeholder="0"
              margin="normal"
              inputProps={{ style: { fontSize: "1rem" } }}
              startAdornment={
                <InputAdornment position="start">
                  {" "}
                  <Typography variant="adornment" color="secondary">
                    {" "}
                    $
                  </Typography>
                </InputAdornment>
              }
            />
          </FormControl>
          {!returnedCurryingCost && (
            <Button variant="outlined" onClick={save}>
              {!loading && <Typography variant="h5">Calculate</Typography>}
              {loading && (
                <Box sx={{ mx: 2 }}>
                  <ClipLoader color={"#B8D6E4"} loading={true} size={16} />
                </Box>
              )}
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default CarryingMonths;

const carryingMonthsStaticData = {
  input1: {
    label: "How many months/years will you own the property",
  },
  input2: {
    label: "Total Carrying Cost",
  },
};
