import {
	Box,
	Button,
	Link as DLink,
	Grid,
	Button as MButton,
	Stack,
	TextField as TField,
	Typography,
	IconButton as WIconButton,
	createTheme,
} from '@mui/material';
import { ThemeProvider, makeStyles, styled } from '@mui/styles';
import React from 'react';
import { Link as RLink } from 'react-router-dom';
import Dots from '../../images/dots.webp';
import LDots from '../../images/ldots.webp';
import BG from '../../images/temp7bg.webp';
import { formatNumber, formatPhoneNumber, getYoutubeEmbedLink, hideButton, scrollToTheLeft } from '../../utils/functions';

import { ReactComponent as FBIcon } from '../../images/facebookfillable.svg';
import { ReactComponent as IGIcon } from '../../images/instagramfillable.svg';
import { ReactComponent as LIIcon } from '../../images/linkedInfillable.svg';
import { ReactComponent as TWIcon } from '../../images/twitterfillable.svg';
import { ReactComponent as YTIcon } from '../../images/youtubefillable.svg';
import ServiceAreasSlider from '../../components/ServiceAreasSlider/ServiceAreasSlider';
import InvestorQuestionnaires from '../../components/InvestorQuestionnaires/InvestorQuestionnaires';

const IconButton = styled(WIconButton)(({ theme }) => ({
	// border: '1px solid white',
	borderRadius: '50%',
	width: '40px',
	aspectRatio: '1',
}));

const GradButton = styled(MButton)(({ theme }) => ({
	background: 'linear-gradient(289.73deg, #CFA165 -43.22%, #FFCB85 32.24%, #976F40 76.52%);',
	borderRadius: '10px',
	padding: '10px',
	textTransform: 'capitalize',
}));
const BordButton = styled(MButton)(({ theme }) => ({
	background: 'transparent',
	color: 'white',
	padding: '10px',
	border: '1px solid #F8C580',
	borderRadius: '10px',
	textTransform: 'capitalize',
	fontSize: '14px',
}));
const TextField = styled(TField)(({ theme }) => ({
	'& .MuiOutlinedInput-root': {
		'& fieldset': {
			backgroundColor: 'transparent',
			borderColor: 'white',
			color: 'white',
		},
	},

	'& .MuiInputBase-input::placeholder': {
		color: 'white',
	},
	//set font color to white
	'& .MuiInputBase-input': {
		color: 'white',
	},
}));

const pageTheme = createTheme({
	typography: {
		fontFamily: 'Roboto, sans-serif',
		fontSize: 16,
	},
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					borderRadius: 0,
				},
			},
			defaultProps: {
				disableRipple: true,
			},
		},
		MuiIconButton: {
			defaultProps: {
				disableRipple: true,
			},
		},
	},
	palette: {
		primary: {
			main: '#fff',
		},
		secondary: {
			main: '#000',
		},
		success: {
			main: '#fff',
		},
		text: {
			main: '#000',
		},
		paper: {
			main: '#fff',
		},
	},
	primaryColor: '#fff',
	secondaryColor: '#000',
});

const Link = styled(DLink)(({ theme }) => ({
	cursor: 'pointer',
}));
const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: '#212121',
	},
	header: {
		position: 'fixed',
		top: '0',
		width: '100%',
		padding: '1.5rem',
		display: 'flex',
		justifyContent: 'space-between',
		background: '#212121',
		zIndex: '100',
	},
	full: {
		width: '100%',
		height: '100vh',
		background: `linear-gradient(to bottom, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0.35) 80%, #212121 80%, #212121 100%), url(${BG}) no-repeat center top / cover`,
		padding: '2rem 4rem',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'flex-end',
	},
	yellowbox: {
		backgroundColor: '#FFCB85',
		padding: '2rem !important',
		borderRadius: '10px',
		display: 'flex',
		justifyContent: 'center',
		flexDirection: 'column',
		alignItems: 'center',
		flex: '1',
	},
}));

function NewTemp7({ agent, listings, testimonials, contactDetails, updateContactDetails, sendMessage, serviceAreas }) {
	const classes = useStyles();
	const name = agent?.user.first_name + ' ' + agent?.user.last_name;
	const agent_id = agent?.id;
	const member_since = agent?.year_work_commenced;
	const agent_phone = formatPhoneNumber(agent?.phone);
	const brokerage_phone = formatPhoneNumber(agent?.brokerage?.brokerage_phone);
	const brokerage_name = agent?.brokerage_name;
	const license = agent?.licenses.find((license) => license.category === 'real_estate_agent')?.number;
	const address = agent?.address;
	const video = agent?.video_link;
	const showInvestorForm = agent?.questionnaires_on;
	return (
		<ThemeProvider theme={pageTheme}>
			<Box className={classes.root}>
				<Box className={classes.header}>
					<Box display='flex' gap='1rem' fontFamily='Poppins, sans-serif'>
						<Link href='#home' underline='none' color='white' fontFamily='inherit' sx={{ textTransform: 'uppercase' }}>
							Home
						</Link>
						<Link href='#about' underline='none' color='white' fontFamily='inherit' sx={{ textTransform: 'uppercase' }}>
							About
						</Link>
						<Link href='#active' underline='none' color='white' fontFamily='inherit' sx={{ textTransform: 'uppercase' }}>
							Active Listings
						</Link>
						<Link href='#contact' underline='none' color='white' fontFamily='inherit' sx={{ textTransform: 'uppercase' }}>
							Contact
						</Link>
						<Link href={`/agent/${agent_id}/blogs`} underline='none' color='white' fontFamily='inherit' sx={{ textTransform: 'uppercase' }}>
							Blog
						</Link>
						{showInvestorForm && (
							<Link href='#investors' underline='none' color='white' fontFamily='inherit' sx={{ textTransform: 'uppercase' }}>
								Investors
							</Link>
						)}
					</Box>

					<Typography
						variant='body1'
						fontFamily='Poppins, sans-serif'
						color='white'
						fontWeight='400'
						fontSize='16px'
						sx={{ textTransform: 'uppercase' }}
					>
						Listing Agent: {name}
					</Typography>
				</Box>
				<Box className={classes.full} id='home'>
					{agent?.landing_page_title && (
						<Typography mb='10rem' color='white' fontFamily='Italiana, serif' variant='h4'>
							{agent?.landing_page_title}
						</Typography>
					)}
					<Stack direction='row' gap='10px' width='100%' sx={{ marginBottom: 'calc(20vh - 6rem)' }}>
						<Box className={classes.yellowbox}>
							<Typography variant='body1' fontWeight='700'>
								{name}
							</Typography>
							<Typography variant='body1' fontWeight={600}>
								{address}
							</Typography>
						</Box>
						<Box className={classes.yellowbox}>
							<Typography variant='body1' fontWeight='700'>
								Member Since: {member_since}
							</Typography>
							<Typography variant='body1' fontWeight='700'>
								Real Estate License: {license}
							</Typography>
						</Box>
						<Box className={classes.yellowbox}>
							<Typography variant='body1' fontWeight='700'>
								Cell Phone: {agent_phone}
							</Typography>
							<Typography variant='body1' fontWeight='700'>
								Brokerage: {brokerage_name} {brokerage_phone ? `(${brokerage_phone})` : ''}
							</Typography>
						</Box>
						<Box className={classes.yellowbox}>
							<Typography variant='body1' fontWeight='700'>
								Want to know your home value?
							</Typography>
							<Button
								variant='text'
								sx={{
									textTransform: 'none',
									color: '#000',
									fontWeight: '700',
									fontSize: '16px',
								}}
								href={`/order-bpo-report/${agent_id}`}
								target='_blank'
							>
								Get A BPO Today
							</Button>
						</Box>
					</Stack>
				</Box>
				{listings.active.length && (
					<Box p={'2rem 4rem'} sx={{ backgroundColor: '#181818' }}>
						<Stack direction='row' justifyContent='space-between'>
							<Typography variant='h5' fontFamily='Italiana, serif' textTransform='uppercase' color='#FFCB85'>
								Active Listings
							</Typography>
							<Stack direction='row' spacing={2} ref={(el) => hideButton(el, 'active')}>
								<GradButton
									variant='contained'
									sx={{ fontSize: '14px !important', color: 'black' }}
									onClick={() => {
										scrollToTheLeft('active', true);
									}}
								>
									Previous
								</GradButton>
								<GradButton
									variant='contained'
									sx={{ fontSize: '14px !important', color: 'black', py: '20px' }}
									onClick={() => {
										scrollToTheLeft('active');
									}}
								>
									Next
								</GradButton>
							</Stack>
						</Stack>
						<Box
							display='flex'
							gap='20px'
							id='active'
							mt={2}
							sx={{
								maxWidth: '100%',
								overflowX: 'scroll',
								scrollMarginTop: '100px',
								// position: 'relative',
								'&::-webkit-scrollbar': {
									display: 'none',
								},
								scrollBehavior: 'smooth',
							}}
						>
							{listings.active.map((listing) => (
								<Card key={listing.id} {...listing} agentId={agent_id} />
							))}
						</Box>
					</Box>
				)}
				<Box p={'2rem 4rem'} sx={{ backgroundColor: '#181818', mt: '4rem' }} id='about'>
					<Typography variant='h5' fontFamily='Italiana, serif' textTransform='uppercase' color='#FFCB85' textAlign='center' my='2rem'>
						{name}
					</Typography>
					<Grid container spacing={2}>
						<Grid item xs={6} alignItems='center' justifyContent='center' sx={{ position: 'relative', py: '1rem' }}>
							<img
								src={agent?.profile_image}
								alt='Agent'
								style={{
									width: '400px',
									aspectRatio: '3/4',
									objectFit: 'cover',
									objectPosition: 'top center',
									display: 'block',
									margin: 'auto',
									zIndex: '2',
									position: 'relative',
									marginBottom: '3rem',
								}}
							/>
							<img
								alt='dots'
								src={Dots}
								style={{
									position: 'absolute',
									bottom: '0',
									right: '0',
									zIndex: '1',
									width: '70%',
								}}
							/>
						</Grid>
						<Grid item xs={6}>
							<Typography textAlign='justify' fontWeight={400} fontSize='16px' color='white' whiteSpace={'pre-line'}>
								{agent?.bio}
							</Typography>
						</Grid>
					</Grid>
				</Box>
				{showInvestorForm && (
					<Box padding={'2rem 4rem'}>
						<InvestorQuestionnaires agent={agent} />
					</Box>
				)}
				{video && (
					<Box p={'2rem 4rem'} sx={{ position: 'relative' }}>
						<Typography variant='h5' fontFamily='Italiana, serif' textTransform='uppercase' color='#FFCB85' textAlign='center' my='2rem'>
							Video Spotlight
						</Typography>
						<iframe
							style={{
								aspectRatio: '16/9',
								borderRadius: '0px',
								border: 'none',
								width: '60%',
								// margin: '3rem auto',
								margin: 'auto',
								display: 'block',
								position: 'relative',
								zIndex: '2',
							}}
							src={`https://www.youtube.com/embed/${getYoutubeEmbedLink(video)}`}
							title='Client video'
							allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
							allowFullScreen
						></iframe>
						<img
							alt='dots'
							src={LDots}
							style={{
								position: 'absolute',
								bottom: '5rem',
								left: '300px',
								zIndex: '1',
								// width: '70%',
							}}
						/>
					</Box>
				)}
				{listings.sold.length > 0 && (
					<Box p={'2rem 4rem'} sx={{ backgroundColor: '#181818' }}>
						<Stack direction='row' justifyContent='space-between'>
							<Typography variant='h5' fontFamily='Italiana, serif' textTransform='uppercase' color='#FFCB85'>
								Sold Listings
							</Typography>
							<Stack direction='row' spacing={2} ref={(el) => hideButton(el, 'sold')}>
								<GradButton
									variant='contained'
									sx={{ fontSize: '14px !important', color: 'black' }}
									onClick={() => {
										scrollToTheLeft('sold', true);
									}}
								>
									Previous
								</GradButton>
								<GradButton
									variant='contained'
									sx={{ fontSize: '14px !important', color: 'black', py: '20px' }}
									onClick={() => {
										scrollToTheLeft('sold');
									}}
								>
									Next
								</GradButton>
							</Stack>
						</Stack>
						<Box
							display='flex'
							gap='20px'
							id='sold'
							mt={2}
							sx={{
								maxWidth: '100%',
								overflowX: 'scroll',
								// position: 'relative',
								'&::-webkit-scrollbar': {
									display: 'none',
								},
								scrollBehavior: 'smooth',
							}}
						>
							{listings.sold.map((listing) => (
								<Card key={listing.id} {...listing} agentId={agent_id} />
							))}
						</Box>
					</Box>
				)}
				{testimonials.length > 0 && (
					<Box p={'2rem 4rem'}>
						<Typography variant='h5' fontFamily='Italiana, serif' textTransform='uppercase' color='#FFCB85' textAlign='left' mb='3rem'>
							Testimonials
						</Typography>
						<Stack direction='row' spacing={2}>
							{testimonials.map((testimonial) => (
								<Review key={testimonial.id} {...testimonial} />
							))}
						</Stack>
					</Box>
				)}

				{serviceAreas.length > 0 && (
					<Box my={3} py={3} sx={{ background: '#181818', color: 'white' }}>
						<ServiceAreasSlider serviceAreas={serviceAreas} />
					</Box>
				)}
				<Grid
					container
					spacing={2}
					sx={{ width: '60%', margin: 'auto', backgroundColor: '#181818D9', padding: '2rem', marginTop: '2rem' }}
					id='contact'
					mb='2rem'
				>
					<Grid item xs={12}>
						<Typography variant='h5' fontFamily='Italiana, serif' textTransform='uppercase' color='#FFCB85' textAlign='center' mb='2rem'>
							Work with {name}
						</Typography>
						<Typography variant='body1' textAlign='center' color='white' fontSize='16px' mb='2rem'>
							Let {name} help you find your perfect home or get the best possible price for your current one.
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<TextField
							fullWidth
							placeholder='Full name'
							variant='outlined'
							color='primary'
							size='small'
							value={contactDetails.name}
							onChange={updateContactDetails}
							name='name'
						/>
					</Grid>
					<Grid item xs={6}>
						<TextField
							fullWidth
							placeholder='Email'
							variant='outlined'
							color='primary'
							size='small'
							value={contactDetails.email}
							onChange={updateContactDetails}
							name='email'
						/>
					</Grid>
					<Grid item xs={6}>
						<TextField
							fullWidth
							placeholder='Phone number'
							variant='outlined'
							color='primary'
							size='small'
							value={contactDetails.phone}
							onChange={updateContactDetails}
							name='phone'
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							fullWidth
							multiline
							maxRows={5}
							placeholder='Message'
							variant='outlined'
							color='primary'
							size='small'
							value={contactDetails.message}
							onChange={updateContactDetails}
							name='message'
						/>
					</Grid>
					<Grid item xs={12} display={'flex'} alignItems='center' justifyContent='center' gap='10px'>
						<BordButton variant='contained' color='primary' onClick={sendMessage}>
							Let's Connect
						</BordButton>
						<GradButton
							variant='contained'
							sx={{ fontSize: '14px !important', color: 'black', py: '20px' }}
							href={`/order-bpo-report/${agent_id}`}
							target='_blank'
						>
							Order A BPO Report
						</GradButton>
					</Grid>
					<Stack direction='row' spacing={3} sx={{ mx: 'auto', mt: '1rem' }}>
						{agent?.facebook && (
							<IconButton target='blank' href={agent?.facebook}>
								<FBIcon fill='white' />
							</IconButton>
						)}
						{agent?.twitter && (
							<IconButton target='blank' href={agent?.twitter}>
								<TWIcon fill='white' />
							</IconButton>
						)}
						{agent?.instagram && (
							<IconButton target='blank' href={agent?.instagram}>
								<IGIcon fill='white' />
							</IconButton>
						)}
						{agent?.linkedin && (
							<IconButton target='blank' href={agent?.linkedin}>
								<LIIcon fill='white' />
							</IconButton>
						)}
						{agent?.video_link && (
							<IconButton target='blank' href={agent?.video_link}>
								<YTIcon fill='white' />
							</IconButton>
						)}
					</Stack>
				</Grid>
			</Box>
		</ThemeProvider>
	);
}

export default NewTemp7;

const Card = ({ id, agentId, list_price, photos, beds, baths, square_footage, address, date_sold, sold_price, sold }) => {
	return (
		<Link component={RLink} to={`/view-listing/${agentId}/${id}`} target='_blank'>
			<Box
				sx={{
					width: '350px',
					aspectRatio: '55/60',
					position: 'relative',
				}}
			>
				<img
					alt='property'
					style={{
						width: '350px',
						aspectRatio: '55/60',
						objectFit: 'cover',
					}}
					src={photos?.[0]?.image}
				/>
				<Box
					width='100%'
					p={2}
					sx={{ position: 'absolute', bottom: '5px', left: '0', width: '80%', borderTopRightRadius: '5px', backgroundColor: '#FFCB85' }}
				>
					<Typography variant='body1' fontFamily='Italiana, serif' fontSize='20px'>
						$ {formatNumber(list_price, 0)}
					</Typography>
					<Typography variant='body1' mb={'10px'} fontSize='14px' fontWeight='300'>
						{address}
					</Typography>
					<Stack spacing={1} mb={'10px'} direction='row' justifyContent='flex-start'>
						<Typography variant='body1' fontSize='14px' fontWeight='300'>
							{beds} Beds
						</Typography>
						<Typography variant='body1' fontSize='14px' fontWeight='300'>
							{baths} Baths
						</Typography>
						<Typography variant='body1' fontSize='14px' fontWeight='300'>
							{formatNumber(square_footage)} SqFt
						</Typography>
					</Stack>
				</Box>
			</Box>
		</Link>
	);
};

const Review = ({ description, name, client }) => {
	return (
		<Stack spacing={2} alignItems='center' flex='1' color='white'>
			<Typography variant='body1' fontWeight={700} mb={'4rem'}>
				{client?.user?.first_name || client?.user?.last_name ? client?.user?.first_name + ' ' + client?.user?.last_name : name}
			</Typography>
			<Typography variant='body1' textAlign='center' fontSize='14px' mt='auto'>
				{description}
			</Typography>
		</Stack>
	);
};
