import { SvgIcon } from '@mui/material'
import React from 'react'

function SARicon(props) {
  return (
    <SvgIcon
      width="97"
      height="80"
      viewBox="0 0 97 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.59288 39.4746C7.24099 40.0353 5.73473 40.1013 4.33892 39.6613C3.41822 39.3528 2.60743 38.7826 2.00586 38.0204C1.40501 37.2584 1.03284 36.3414 0.932647 35.3762C0.856425 34.4866 0.958891 33.5907 1.23402 32.7413C1.50914 31.8919 1.95137 31.1061 2.53469 30.4301C2.60466 30.3473 2.68283 30.2717 2.76799 30.2046L47.594 0.372446C47.8364 0.147129 48.1511 0.0155441 48.4818 0.0012922C48.8124 -0.0129596 49.1373 0.0910537 49.3982 0.294677L94.3331 30.0957C94.3911 30.1408 94.4457 30.1902 94.4964 30.2435C95.2635 30.9818 95.8301 31.9031 96.143 32.9207C96.4559 33.9383 96.5048 35.0188 96.2851 36.0606C96.0817 36.9273 95.6646 37.7294 95.0719 38.3936C94.4821 39.06 93.7329 39.5657 92.8943 39.8635C92.1546 40.12 91.3711 40.2261 90.5898 40.1753C89.8084 40.1246 89.0452 39.9181 88.3449 39.568V77.7136C88.4025 77.9886 88.3982 78.273 88.3325 78.5462C88.2668 78.8194 88.1413 79.0746 87.9649 79.2934C87.7886 79.5122 87.5658 79.689 87.3128 79.8112C87.0597 79.9334 86.7827 79.9979 86.5017 80H10.4282C10.1435 79.9968 9.86305 79.9298 9.60759 79.804C9.35213 79.6781 9.12812 79.4966 8.95205 79.2728C8.77598 79.049 8.65232 78.7886 8.59016 78.5107C8.52801 78.2327 8.52893 77.9444 8.59288 77.6669V39.4746ZM84.1298 76.026V38.3392C84.1298 37.6626 51.7002 16.4628 48.7994 14.2309C45.6886 16.5639 12.808 37.6082 12.808 38.4792V76.026H29.3416L29.5594 49.709L30.0104 48.7836L36.5275 34.9951L37.0874 33.8519L37.6707 34.9951L44.6154 48.8147L45.0976 49.7479L45.292 76.0649H56.4052L59.2049 53.5975L65.1231 53.9474L66.1263 76.026H84.1298ZM62.3156 51.6999C57.4939 51.4666 55.5653 49.5146 56.4596 45.9994C56.6618 45.1673 57.9217 41.8855 57.3229 39.0391C56.724 36.1928 54.2121 33.8442 54.2121 33.7508C58.8005 36.8149 76.314 50.8522 62.3156 51.6999ZM38.565 39.4591H35.7653L31.6047 48.4258H42.9901L38.565 39.4591ZM76.9206 2.37888L88.4226 2.85327V18.407L76.9206 12.8932V2.37888Z"
        fill="#1378A5"
      />
    </SvgIcon>
  );
}

export default SARicon