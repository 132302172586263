export const summaryTheme = {
      summaryContainerTitle: {
        fontSize: "20px",
        fontWeight: "700",
        lineHeight: "28px",
        color:"#fff"
      },
      printSummaryContainerTitle: {
        fontSize: "20px",
        fontWeight: "700",
        lineHeight: "28px",
        color:"#fff"
      },
      proformaAddress:{
        fontSize: "18px",
        fontWeight: 600,
        lineHeight: "24px",
      },
      proformaReportHeader:{
        fontSize: "20px",
        fontWeight: 700,
        lineHeight: "24px",
      },
     noResult:{
        fontSize: "18px",
        fontWeight: 500,
        lineHeight: "24px",
      },
     tableInactive:{
        fontSize: "18px",
        fontWeight: 500,
        lineHeight: "20px",
        color:"#c4c4c4"
      },
     printFooter:{
        fontSize: "20px",
        fontWeight: 500,
        lineHeight: "24px",
        color:"#b4b4b4"
      },
      disclaimer:{
        fontsize:"6px",
        lineHeight:"14.4px",
        fontWeight:400,
      },
      disclaimerHeader:{
        fontSize:"14px",
        lineHeight:"14.4px",
        fontWeight:700
      },
      tableHeader:{
        fontSize: "18px",
        fontWeight: 700,
        lineHeight: "28px",
      },
      enhancedTableHeader:{
        fontSize: "24px",
        fontWeight: 700,
        lineHeight: "28px",
      },
      tableRow:{
        fontSize: "20px",
        fontWeight: 400,
        lineHeight: "24px",
      },
      enhancedTableRow:{
        fontSize: "20px",
        fontWeight: 600,
        lineHeight: "40px",
      },
      printEnhancedTableRow:{
        fontSize: "20px",
        fontWeight: 600,
        lineHeight: "24px",
      },
      summaryTableRow:{
        fontSize: "18px",
        fontWeight: 500,
        lineHeight: "25px",
      },
      printSummaryTableRow:{
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "20px",
      },
}