import {
  FormControl,
  InputAdornment,
  Box,
  Typography,
  OutlinedInput,
} from "@mui/material";
import StyledLabel from "./StyledLabel";
import React from "react";
import useTheme from "@mui/material/styles/useTheme";
import commaSeparate from "../utils/commaSeparate";

import { useSelector } from "react-redux";

const useStyle = (_theme) => {
  return {
    purchasePrice: {
      display: "flex",
      flexDirection: "column",
      marginTop: "1rem",
    },
  };
};

function PurchasePrice({ handler, label, name, data }) {
  //localRemodelCost ==========================================================================
  const financialOptions = useSelector(
    (state) => state?.straightAestheticRemodel.financeOptions
  );


  //checks remodel type before setting remodel loan amount 
  const remodelCost = financialOptions?.universalRemodelCost

  //  label && label.includes("Remodel") || label && label.includes("New") ? commaSeparate(remodelCost) : commaSeparate(data?.[name])

  //checks to fix UI bug (putting the exact values there makes the new construction cost editable, please check for a better solutions before cha)


  const handleValues = (e) => {
    let value = e.target.value;
    if (value) {
      value = Number.parseInt(value.split(",").join(""));
      if (isNaN(value)) {
        return;
      }
      handler({ [name]: value });
    } else {
      handler({ [name]: value });
    }
  };

  const theme = useTheme();
  const styles = useStyle(theme);

  //const dynamicValue = dealId === 5 ? commaSeparate(remodelCost) :

  return (
    <div>
      <Box sx={styles.purchasePrice}>
        <StyledLabel label={label || "Purchase Price"} />
        <FormControl
          sx={{
            minWidth: "28rem",
            marginTop: "10px",
          }}
        >
          <OutlinedInput
            fullWidth
            sx={{
              backgroundColor:
                label && label.includes("Remodel") ? "#E5E4E2" : "inherit",
            }}
            name={name}
            onChange={label && label.includes("Remodel") ? "" : handleValues}
            value={
              label && (label.includes("Remodel") || label.includes("New"))
                ? commaSeparate(remodelCost)
                : commaSeparate(data?.[name])
            }
            margin="normal"
            inputProps={{ style: { fontSize: "1rem" } }}
            startAdornment={
              <InputAdornment position="start">
                {" "}
                <Typography variant="adornment" color="secondary">
                  {" "}
                  $
                </Typography>
              </InputAdornment>
            }
          />
        </FormControl>
      </Box>
    </div>
  );
}

export default PurchasePrice;
