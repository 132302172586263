import React, { useEffect, useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Grid } from "@mui/material";

import {
  FormControl,
  InputAdornment,
  OutlinedInput,
  Select,
  Box,
  MenuItem,
  Typography,
} from "@mui/material";
import { listingSource, propertyType } from "./cardStaticData";
import useTheme from "@mui/material/styles/useTheme";
import StyledLabel from "../atomicComponents/StyledLabel";
import { preventMinus } from "../utils/preventNegativeValues";

const useStyle = (_theme) => {
  return {
    root: {
      padding: "10px",
    },
    row1: {
      display: "flex",
      marginTop: "10px",
      width: "100%",
    },
    row2: {
      display: "flex",
      marginTop: "10px",
      width: "100%",
      justifyContent: "space-between",
    },
  };
};

function PropertyInformationNewConstruction({ handleEvents, data }) {
  const theme = useTheme();
  const styles = useStyle(theme);
  const componentDataFilter = (e) => {
    handleEvents({ [e.target.name]: e.target.value });
  };
  const handleDate = (year) => {
    handleEvents({ year_of_construction: year });
  };

  // console.log(data)

  //--------------------- setting carport or garage -----------------//
  const prevCarportOrGarageValue =
    data.carportOrGarage && data.carportOrGarage === "Garage" ? 0 : 1;

  const [carportOrgarage, setCarportOrGarage] = useState(
    prevCarportOrGarageValue
  );

  // const selectedDate = new Date(data?.["listing_date"])
  // const currentDate = new Date()

  //-------------- this effect adds garage or carport option to the property field
  useEffect(() => {
    handleEvents({
      carportOrGarage: carportOrgarage === 0 ? "Garage" : "Carport",
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carportOrgarage]);

  return (
    <Box sx={styles.root}>
      <Grid container columns={24}>
        <Grid item sm={24} md={11}>
          <FormControl sx={{ width: "100%", marginRight: "40px" }}>
            <StyledLabel
              label="Listing Date"
              showToolTip
              toolTipContent={toolTipTexts.listingDate}
            />
            <OutlinedInput
              sx={{
                "&:hover ": {
                  borderColor: "red",
                },
              }}
              fullWidth
              name="listing_date"
              onChange={componentDataFilter}
              value={data?.["listing_date"]}
              margin="normal"
              inputProps={{ style: { fontSize: "16px" } }}
              type="date"
            />
            {/* {selectedDate > currentDate && <Box>
              <Typography
                variant="h6"
                sx={{ backgroundColor: "pink", padding: "5px", width: "80%" }}
              >
                Listing Date can not be greater than today's date
              </Typography>
            </Box>} */}
          </FormControl>
        </Grid>
        <Grid sm={0} md={2} />
        <Grid item sm={24} md={11}>
          <FormControl sx={{ width: "100%" }}>
            <StyledLabel
              label="Listing Source"
              showToolTip
              toolTipContent={toolTipTexts.listingSource}
            />
            <Select
              name="listing_source"
              onChange={componentDataFilter}
              defaultValue={data?.["listing_source"]}
              inputProps={{ style: { fontSize: "34px" } }}
              sx={{ fontSize: "16px" }}
            >
              {listingSource.map((element, index) => (
                <MenuItem sx={{ fontSize: "16px" }} value={index}>
                  {element}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {data?.listing_source === 2 && (
            <FormControl sx={{ width: "100%", mt: "1rem" }}>
              <StyledLabel
                label="Please Specify"
                showToolTip
                toolTipContent={toolTipTexts.bedrooms}
              />
              <OutlinedInput
                name="other_listing_source"
                type="text"
                onChange={componentDataFilter}
                value={data?.other_listing_source}
                inputProps={{ style: { fontSize: "1rem" }, min: 0 }}
                sx={{ fontSize: "16px" }}
              ></OutlinedInput>
            </FormControl>
          )}
        </Grid>
      </Grid>

      <Grid container columns={36} sx={{ mt: 2 }}>
        <Grid item sm={36} md={10}>
          <FormControl sx={{ width: "100%" }}>
            <StyledLabel
              label="Bedrooms"
              showToolTip
              toolTipContent={toolTipTexts.bedrooms}
            />
            <OutlinedInput
              name="bedrooms"
              onChange={componentDataFilter}
              type="number"
              onKeyPress={preventMinus}
              value={data?.bedrooms}
              inputProps={{ style: { fontSize: "1rem" }, min: 0 }}
              sx={{ fontSize: "16px" }}
            ></OutlinedInput>
          </FormControl>
        </Grid>
        <Grid item sm={0} md={2} />
        <Grid item sm={36} md={10}>
          <FormControl sx={{ width: "100%" }}>
            <StyledLabel
              label="Bathrooms"
              showToolTip
              toolTipContent={toolTipTexts.bathrooms}
            />
            <OutlinedInput
              name="bathrooms"
              onChange={componentDataFilter}
              type="number"
              onKeyPress={preventMinus}
              value={data?.bathrooms}
              inputProps={{ style: { fontSize: "1rem" }, min: 0 }}
              sx={{ fontSize: "16px" }}
            ></OutlinedInput>
          </FormControl>
        </Grid>
        <Grid item sm={0} md={2} />
        <Grid item sm={36} md={12}>
          <FormControl sx={{ width: "100%" }}>
            <StyledLabel
              label="Lot Size"
              showToolTip
              toolTipContent={toolTipTexts.lotSize}
            />
            <OutlinedInput
              fullWidth
              name="lot_size"
              onChange={componentDataFilter}
              value={data?.lot_size}
              type="number"
              margin="normal"
              onKeyPress={preventMinus}
              inputProps={{ style: { fontSize: "16px" }, min: 0 }}
              endAdornment={
                <InputAdornment position="start">
                  {" "}
                  <Typography variant="adornment"> SqFt</Typography>
                </InputAdornment>
              }
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container columns={24} sx={{ mt: 2 }}>
        <Grid item sm={24} md={10}>
          <FormControl sx={{ width: "100%", marginRight: "40px" }}>
            <StyledLabel
              label="Year of Construction"
              showToolTip
              toolTipContent={toolTipTexts.constuctionYear}
            />
            <LocalizationProvider dateAdapter={AdapterMoment}>
              {/* <YearPicker
            variant="inline"
            value = {data?.year_of_construction}
            // type="number"
            // defaultValue={2022}
            // inputProps={{ style: { fontSize: "1.60px", min: "1700" } }}
            // renderInput={(params) => <TextField {...params} />}
            sx={{ fontSize: "1.60px" }} */}
              {/* /> */}
              <DatePicker
                views={["year"]}
                name="year_of_construction"
                sx={{ fontSize: "16px", padding: "10px" }}
                value={
                  data?.["year_of_construction"]
                    ? data.year_of_construction
                    : null
                }
                onChange={handleDate}
                renderInput={(params) => (
                  <OutlinedInput
                    size="normal"
                    // InputProps={{ style: { fontSize: 40 } }}

                    sx={{ fontSize: "16px", borderColor: "black" }}
                    {...params}
                    helperText={null}
                  />
                )}
              />
            </LocalizationProvider>
          </FormControl>
        </Grid>
        <Grid item sm={0} md={2} />
        <Grid item sm={24} md={11}>
          <FormControl sx={{ width: "100%" }}>
            <StyledLabel
              label="Garage / Carport Spaces"
              showToolTip
              toolTipContent={toolTipTexts.garage}
            />
            <OutlinedInput
              name="garage"
              onChange={componentDataFilter}
              value={data?.garage}
              type="number"
              onKeyPress={preventMinus}
              inputProps={{ style: { fontSize: "16px" }, min: 0 }}
              sx={{ fontSize: "16px" }}
              endAdornment={
                <>
                  <InputAdornment position="start">
                    {
                      <FormControl sx={{ width: "7.5rem" }}>
                        <Select
                          // name={`${name}_month_or_year`}
                          displayEmpty={true}
                          // disabled={disable}
                          error={true}
                          placeholder="none"
                          renderValue={(value) =>
                            value ? value : <b>months</b>
                          }
                          sx={{
                            fieldSet: {
                              border: "none !important",
                            },
                            fontSize: "1.125rem",
                            color: "black",
                            fontWeight: "bold",
                          }}
                          onChange={(e) => setCarportOrGarage(e.target.value)}
                          value={carportOrgarage === 0 ? "Garage" : "Carport"}
                        >
                          {["Garage", "Carport"].map((element, index) => (
                            <MenuItem
                              value={index}
                              key={element}
                              sx={{
                                "&:hover": { backgroundColor: "#1378A5" },
                                fontSize: "1rem",
                              }}
                            >
                              {element}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    }
                  </InputAdornment>
                </>
                // <InputAdornment position="start">
                //   {" "}
                //   <Typography variant="adornment"> Spaces</Typography>
                // </InputAdornment>

                //-------------------- updating the carport and garage---------------------//
              }
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container columns={24} sx={{ mt: 2 }}>
        <Grid item sm={24} md={11}>
          <FormControl sx={{ width: "100%" }}>
            <StyledLabel label="New Construction SqFt" />
            <OutlinedInput
              name="pre_existing_livable_sqft"
              onChange={componentDataFilter}
              value={data?.pre_existing_livable_sqft}
              inputProps={{ style: { fontSize: "16px" } }}
              sx={{ fontSize: "16px" }}
              endAdornment={
                <InputAdornment position="start">
                  {" "}
                  <Typography variant="adornment"> SqFt</Typography>
                </InputAdornment>
              }
            />
          </FormControl>
        </Grid>
        <Grid item sm={2} />
        <Grid item sm={24} md={11}>
          <FormControl sx={{ width: "100%" }}>
            <StyledLabel label="Property Type" />
            <Select
              name="property_type"
              onChange={componentDataFilter}
              value={data?.property_type}
              inputProps={{ style: { fontSize: "34px" } }}
              sx={{ fontSize: "16px" }}
            >
              {propertyType.map((element, index) => (
                <MenuItem sx={{ fontSize: "16px" }} value={index}>
                  {element}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {data?.property_type === 6 && (
            <FormControl sx={{ width: "100%", mt: "1rem" }}>
              <StyledLabel
                label="Please Specify"
                showToolTip
                toolTipContent={toolTipTexts.bedrooms}
              />
              <OutlinedInput
                name="other_property_type"
                type="text"
                onChange={componentDataFilter}
                value={data?.other_property_type}
                inputProps={{ style: { fontSize: "1rem" }, min: 0 }}
                sx={{ fontSize: "16px" }}
              ></OutlinedInput>
            </FormControl>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}
export default PropertyInformationNewConstruction;
const toolTipTexts = {
  listingDate:
    "The date home was actively listed on MLS or another source provider",
  listingSource: "MLS, BPO HOMES, Others (Leads to type in option)",
  bedrooms: "Number of bedrooms in the property",
  bathrooms: "Number of bathrooms in the property",
  lotSize: "Square footage of the property's land",
  pre_existing_livable_sqft:
    "Amount of square footage currently existing at the residence",
  garage: "Number of garage spaces, currently at the residence",
  constuctionYear: "The year property was built",
};
