import { Box, Container, Typography} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import React from 'react'
import { useNavigate } from 'react-router'
import QuestionnaireMainHeader from '../../../../components/DealAnalyzerComponents/QuestionnaireMainHeader'
import MuiThemeLayout from '../../../../Layouts/MuiThemeLayout'
import SARicon from '../../../../assets/dealAnalyzer/iconComponents/SARicon'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import * as actionTypes from '../../../../actions/dealAnalyzer'
import useCreateAlert from '../../../../components/DealAnalyzerComponents/hooks/useCreateAlert'
import useQuestionnaireButtons from '../SpecialHooks/useQuestionnaireButtons'

import useQuestionnaireStraightAestheticRemodelFlowControl from '../SpecialHooks/useQuestionnaireStraightAestheticRemodelFlowControl'
import useSubmitQuestionnaire from '../SpecialHooks/useSubmitQuestionnaire'
const useStyles = (_theme)=>{
    return {
        root:{
        },
        mainHeading:{
            textAlign:"center",
            marginBottom:"1.25rem"
        },
        componentContainer:{
            display:"flex",
            justifyContent:"center",
            margin:"3.125rem 0"
        },
        buttonContainer:{
            display:"flex",
            justifyContent:"center",
            margin:"2.5rem 0"
        },
        button:{
            margin:"0.625rem",
            padding:"0.9375rem 3.75rem",
            textTransform:"none"
        }
    }
}

function QuestionnaireStraightAestheticRemodel() {
    const theme = useTheme()
    const styles = useStyles(theme)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [activeTab,setActiveTab] = React.useState(0)
    const straightAestheticQuestionnaireData = useSelector(state=> state.dealAnalyzerQuestionnaires.straightAestheticRemodel)
    const dealFinanceOption = straightAestheticQuestionnaireData?.deal_finance_option || 0
    const {currentFlow,headings,counterArray} = useQuestionnaireStraightAestheticRemodelFlowControl(dealFinanceOption)
    const {loading,submitQuestionnaire,submitError} = useSubmitQuestionnaire()
    const ActiveComponent = currentFlow[activeTab] 
    const {createAlert} = useCreateAlert()
    const {questionnaireButtons:QuestionnaireButtons,buttonDisableControl} = useQuestionnaireButtons()
    const extraComponentData = {
        financeDealOptions,
        primaryHeaders,
        secondaryHeaders
    }
    const url = "deal_analyzer/straight_aesthetics/questionnaire"
    const id = straightAestheticQuestionnaireData?.id
    const handleNext = ()=>{
        if(activeTab < headings.length-1){
            setActiveTab(prev=>prev+1)
        }
    }
    const handlePrevious = () => {
        if(activeTab > 0){
            setActiveTab(prev=>prev-1)
        }else{
            navigate(`/analyzer-questionnaire`)
        }
    }
    const handleData = (payload) => {
      dispatch(actionTypes.updateQuestionnaireStraightAestheticRemodel(payload));
    };
    const handleSubmit = async ()=>{ 
      try{
        await submitQuestionnaire(url,id,straightAestheticQuestionnaireData)
        if (submitError){
          createAlert("Submit Error", "error")
        }else{
          createAlert("Submit Successful", "success")
          setTimeout(()=>{
            dispatch(actionTypes.updateMessageQueue())
            navigate("/client-ordered-report")
          },1000)
        }
      }catch{
        createAlert("Submit Error", "error")
      }
    }
  
  return (
    <MuiThemeLayout>
      <Container maxWidth="xl">
        <Box sx={styles.root}>
          <QuestionnaireMainHeader
            analyzerType="Straight Aesthetic Remodel"
            icon={SARicon}
            progress={counterArray?.[+activeTab]}
            total={counterArray?.[counterArray?.length - 1]}
          />
          <Container>
            <Box sx={styles.mainHeading}>
              <Typography variant="menuItemActive">
                {headings[activeTab]}
              </Typography>
            </Box>
            <Box sx={styles.componentContainer}>
              <ActiveComponent
                data={straightAestheticQuestionnaireData}
                handler={handleData}
                extraComponentData={extraComponentData}
                handleSubmit={handleSubmit}
                handleButtonDisableControl = {buttonDisableControl}
              />
            </Box>
            <QuestionnaireButtons
              activeTab={activeTab}
              handleNext = {handleNext}
              loading = {loading}
              handlePrevious = {handlePrevious}
              headings={headings}
              handleSubmit = {handleSubmit}
              />
          </Container>
        </Box>
      </Container>
    </MuiThemeLayout>
  );
}

export default QuestionnaireStraightAestheticRemodel


const primaryHeaders = {
    0:'Primary Home Loan',
    1:'Primary Home Loan',
    2:'Single Primary Loan',
    3:'Primary Remodel Loan',
    4:'Cash For Home & Remodel'
}
const secondaryHeaders = {
    0: 'Secondary Remodel Loan',
    1: 'Cash For Remodel',
    3:'Cash For Home'
}


const financeDealOptions = [
    'Obtain Primary Home Loan  & a Secondary Remodel Loan',
    'Obtain Primary Home Loan  & Use Cash for Remodel',
    'Obtain single Primary Loan for Home & Remodel',
    'Obtain a Primary Remodel Loan & Pay Cash for Home',
    'Pay All Cash for Home & Remodel'
  ]
