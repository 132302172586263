
import { useEffect, useState } from "react";
import useCreateAlert from "../../../hooks/useCreateAlert";
import { useDispatch, useSelector } from "react-redux";
import { updateDealAnalyzerResults, updateEstimatedResaleValue } from "../../../../../actions/dealAnalyzer";
import base from "../../../../../apis";
import { changeCodeToDealType } from "../utils/EstimatedResaleUtils";

function useZillowComps() {


    const [oldComps, setOldComps] = useState([]);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const analyzerData = useSelector((state) => state?.straightAestheticRemodel);


    const { createAlert } = useCreateAlert();
    const savedCustomComps = analyzerData?.estimatedResaleValue?.zillowComps || [];
    //  const savedAddresses = savedCustomComps.map((item) => item?.address) || [];
    const { analyzerTypeId, id } = analyzerData.dealAnalyzerReportId
    // console.log(resultData)

    const saveOldCustomComps = () => {
        setOldComps([...savedCustomComps]);
    };


    //updates clicked comps 
    const handleUpdateCustomComps = async (e, index) => {
        // console.log(e.target.name, e.target.value)
        if (!savedCustomComps[+index]) return;
        const comp = { ...savedCustomComps[+index] };
        comp[e.target.name] = +e.target.value;
        savedCustomComps[index] = comp;
        savedCustomComps[index].new = false
        dispatch(updateEstimatedResaleValue({ zillowComps: savedCustomComps }));




        //dispatch(updateEstimatedResaleValue({ zillowComps: resultData }));
    };
    const replaceAllCustomComps = async (data) => {

        if (!data?.length) { return }
        dispatch(updateEstimatedResaleValue({ zillowComps: data }))
    }

    //use to add new comps
    // const addNewCustomComps = (data) => {
    //     if (!data) {
    //         return;
    //     }
    //     if (savedAddresses.includes(data?.address)) {
    //         createAlert("Address already added to Custom Comps", "error");
    //         return;
    //     }
    //     dispatch(updateEstimatedResaleValue({ zillowComps: [...savedCustomComps, { ...data, new: true }] }));
    // };

    const deleteZillowComp = async (index) => {


        try {

            await base.delete(`deal_analyzer/zillow-comps`, { data: { ids: index } })
            //  createAlert("Zillow Comp deleted Successfully", "success");
            getZillowComps()
        } catch (error) {

            // createAlert("Failed to delete", "error");
        }

    };
    const deleteCustomComp = (index) => {

        if (!savedCustomComps?.length) {
            return;
        }
        savedCustomComps.forEach(() => { })
        const comps = [...savedCustomComps];
        comps.splice(index, 1);

        dispatch(updateEstimatedResaleValue({ zillowComps: comps }));

    }
    //checks for empty fields before submits 
    const validateRequiredFields = () => {
        const errors = [];
        for (let item of savedCustomComps) {
            let errorMessage = ``;
            const missingFields = [];
            let errorState = false;
            requiredFields.forEach((field) => {
                if (isNaN(item?.[field?.name]) || item?.[field?.name] === 0) {
                    errorMessage += ` ${field?.errorMessage},`;
                    missingFields.push(field?.name);
                    errorState = true;
                }
            });
            if (errorState) {
                errors.push({ errorMessage, missingFields });
            } else {
                errors.push("");
            }
        }
        return errors;
    };



    useEffect(() => {
        getZillowComps()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // calculates average of zillow comps 
    const calculateAverageZillowComps = () => {
        let totalPrice = 0
        let grossLivingArea = 0
        let lotSize = 0
        let averagePricePerSquareFootage = 0
        let length = savedCustomComps?.length

        savedCustomComps.forEach((home) => {
            totalPrice += +home?.sales_price
            grossLivingArea += home?.gross_living_area
            lotSize += home?.lot_size
            averagePricePerSquareFootage += home?.price_per_gross_living_area
        })
        let a = ((totalPrice || 0) / length)
        let b = ((grossLivingArea || 0) / length)
        let c = ((lotSize || 0) / length)
        let d = ((averagePricePerSquareFootage || 0) / length)
        let averageTotalPrice = a?.toFixed(2)
        let averageGrossLivingArea = b?.toFixed(2)
        let averageLotSize = c?.toFixed(2)
        let averageAveragePricePerSquareFootage = d?.toFixed(2)

        //instantly dispatches calculated zillow comps 
        const averages = {
            average_total_price: averageTotalPrice,
            average_gross_living_area: averageGrossLivingArea,
            average_lot_size: averageLotSize,
            average_average_price_per_square_footage: averageAveragePricePerSquareFootage,
        }
        submitZillowSummary(averages)
        //    console.log(JSON.stringify(averages))
        dispatch(updateEstimatedResaleValue({
            calculatedAverageZillowComps: {
                averageTotalPrice,
                averageGrossLivingArea,
                averageLotSize,
                averageAveragePricePerSquareFootage
            }
        }));
    }

    useEffect(() => {
        calculateAverageZillowComps()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(savedCustomComps)])

    const submitZillowSummary = async (data) => {
        try {
            await base.put(
                `deal_analyzer/zillow-comps/summary?model_type=${dealType}&model_id=${id}`,
                { summary: data })
        } catch (error) {
            // createAlert("Error adding Comps", "error");
        }
    }


    const dealType = changeCodeToDealType(analyzerTypeId)
    const submitZillowComps = async () => {
        // const compsLength = savedCustomComps.length - 1
        setLoading(true);
        try {
            await base.put(
                `deal_analyzer/zillow-comps?model_type=${dealType}&model_id=${id}`,
                { zillow_comps: savedCustomComps }
            );

            // await base.put(
            //     `deal_analyzer/zillow-comps?model_type=${dealType}&model_id=${id}`,
            //     { zillow_comps: [savedCustomComps[compsLength]] }
            // );


            createAlert("Zillow Comps update successful", "success");
        } catch (error) {
            //   createAlert("Error adding Comps", "error");
        } finally {
            setLoading(false);
        }
    };
    const restoreOldCompsOnCancel = () => {
        dispatch(updateEstimatedResaleValue({ zillowComps: oldComps }));
    };

    const getZillowComps = async () => {
        const dealType = changeCodeToDealType(analyzerTypeId)
        try {
            const response = await base.get(`deal_analyzer/zillow-comps?model_type=${dealType}&model_id=${id}`)
            const { data } = response
            dispatch(updateDealAnalyzerResults({ zillowComps: data }))
        } catch (error) {
        }
    }

    return {
        savedCustomComps,
        handleUpdateCustomComps,
        deleteZillowComp,
        deleteCustomComp,
        validateRequiredFields,
        submitZillowComps,
        saveOldCustomComps,
        restoreOldCompsOnCancel,
        replaceAllCustomComps,
        calculateAverageZillowComps,
        loading,

    };
}

export default useZillowComps;
const requiredFields = [
    { name: "sales_price", errorMessage: "Price " },
    { name: "gross_living_area", errorMessage: "Square Footage " },
    { name: "lot_size", errorMessage: "Lot Size " },
    { name: "year_built", errorMessage: "Year Built is Required, Please" },
];








