export const listingSource = ["BPO Homes", "MLS", "Zillow", "Others"];

export const bedBath = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

export const propertyType = [
  "Single Family Home",
  "Townhouse",
  "Condo",
  "Duplex",
  "Triplex",
  "Fourplex",
  "Others",
];
