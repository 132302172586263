import axios from 'axios'
import { useEffect, useState } from 'react'

const useGeoLocation = () => {
    const [ location, setLocation ] = useState({
        loaded: true,
        address: null
    })

    const onSuccess = async(success) => {
        const { coords } = success
        //console.log(coords)
        const {data} = await axios.get(`https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${coords.latitude}&lon=${coords.longitude}`)
        //console.log(data)
        setLocation( 
            (state) => {
                return {
                    ...state,
                    loaded: true,
                    address: {...data.address, display_name: data.display_name }
                }
        })

    }

    const onError = (_error) => {
        //console.log(error)
        alert('Sorry could not get your location')
    }

    // const onGeoClose = () => {
    //     setLocation( 
    //         (state) => {
    //             return {
    //                 ...state,
    //                 loaded: true
    //             }
    //     })
    //     // console.log('User denied access')
    //     //console.log(location)
    // }

    // const getLocation = () => {
    //     if(navigator.geolocation){
    //         navigator.geolocation.getCurrentPosition(onSuccess, onError) 
    //     }else{
    //         alert('Sorry, Geolocation is not supported by this browser.')
    //     }
    // }

    useEffect(() => {
        const checkPersmissions = () => {
            navigator.permissions.query({name:'geolocation'})
            .then((result) => {
                // console.log(result)
                if (result.state === 'granted') {
                        navigator.geolocation.getCurrentPosition(onSuccess, onError) 
                } 
                else if (result.state === 'prompt') {
                    if(navigator.geolocation){
                        navigator.geolocation.getCurrentPosition(onSuccess, onError) 
                    }else{
                        alert('Sorry, Geolocation is not supported by this browser.')
                    }
                }
              });
        }

        checkPersmissions()
    },[])
    

  return { location }
}

export default useGeoLocation