import {
    FormControl,
    InputAdornment,
    Box,
    Typography,
    OutlinedInput,
  } from "@mui/material";
  import StyledLabel from "./StyledLabel";
  import React from "react";
  import useTheme from "@mui/material/styles/useTheme";
  import HtmlToolTip from "./HtmlToolTip";
import commaSeparate from "../utils/commaSeparate";
  
  const useStyle = (_theme)=>{
      return{
          purchasePrice:{
              display:"flex",
              flexDirection:"column",
              marginTop:"1.3rem"
        },
        wholesalerText:{
            color:"red"
          }
      }
  }
  
  function WholesaleFee({handler,name,tooltipText,data}) {
  
    const handleValues = (e) => {
      let value = e.target.value;
      const variable = e.target.name
      if (value) {
        value = Number.parseInt(value.split(",").join(""));
        if (isNaN(value)) {
          return;
        }
        handler({ [variable]: value });
      } else {
        handler({ [variable]: value });
      }
    };
  
    const theme = useTheme();
    const styles = useStyle(theme);
    return (
      <div>
        <Box sx={styles.purchasePrice}>
        <Box>
        <StyledLabel label="Wholesale Fee" />
        <HtmlToolTip content = {tooltipText}/>
        </Box>
        <Box sx = {styles.wholesalerText}>
            <Typography variant='toggleText'>(Enter an amount if there is a wholesaler involved in the transaction)</Typography></Box>
        <FormControl
          sx={{
            minWidth: "28.125rem",
            marginTop: "10px",
          }}
        >
          <OutlinedInput
            fullWidth
            name={name ? name : 'primary_wholesale_fee'}
            onChange={handleValues}
            value = {commaSeparate(data?.[name]) }
            margin="normal"
            inputProps={{ style: { fontSize: "1rem" } }}
            startAdornment={
              <InputAdornment position="start">
                {" "}
                <Typography variant="adornment" color='secondary'> $</Typography>
              </InputAdornment>
            }
          />
        </FormControl>
        </Box>
      </div>
    );
  }
  
  export default WholesaleFee;