import { Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
	Box,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	Grid,
	IconButton,
	InputLabel,
	Stack,
	TextField,
	Tooltip,
	Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import base from '../apis';
import { formatNumber, formatPhoneNumber } from '../utils/functions';

const useStyles = makeStyles((theme) => ({
	smallBox: {
		position: 'relative',
		'&::before': {
			bottom: 0,
			position: 'absolute',
			backgroundColor: '#FFAA01',
			width: '70px',
			height: '8px',
			borderTopLeftRadius: '15px',
			borderTopRightRadius: '15px',
			content: "''",
		},
	},
}));

const Compensation = () => {
	const classes = useStyles();
	const agent_id = useSelector((state) => state.auth.typeId);
	const [isLoading, setIsLoading] = useState(false);
	const [detailsModal, setDetailsModal] = useState(false);
	const [agentDetails, setAgentDetails] = useState({
		first_name: '',
		last_name: '',
		address: '',
		phone: '',
		brokerage_name: '',
		brokerage_address: '',
		brokers_ein: '',
		amount: '',
		has_ein: false,
	});
	const [loader, setLoader] = useState(false);
	const [comps, setComps] = useState({
		amount: 0,
		numberCompleted: 0,
	});
	React.useEffect(() => {
		setLoader(true);
		base
			.get('/compensations/get-agent-amount/', {
				params: {
					agent_id,
				},
			})
			.then(({ data }) => {
				const { agent_amount, numberCompleted } = data;
				setComps({ amount: agent_amount || 0, numberCompleted: numberCompleted || 0 });
			})
			.catch((err) => {})
			.finally(() => {
				setLoader(false);
			});
	}, [agent_id]);

	const typeId = useSelector((state) => state.auth.typeId);

	const openDetails = () => {
		if (comps.amount === 0) {
			toast.warn('No compensation to cashout.');
			return;
		}
		setDetailsModal(true);
		base
			.get(`register/agents/${typeId}/`)
			.then(({ data }) => {
				setAgentDetails({
					first_name: data.first_name,
					last_name: data.last_name,
					phone: data.phone,
					brokerage_name: data.brokerage_name,
					brokerage_address: data.brokerage_address,
					amount: '',
					brokers_ein: data.brokers_ein || '',
					has_ein: data.brokers_ein ? true : false,
				});
			})
			.catch((err) => {})
			.finally(() => {
				// setIsLoading(false);
			});

		// () => setDetailsModal(true)
	};

	const sendRequest = async () => {
		//todo validation
		if (
			!agentDetails.first_name ||
			!agentDetails.last_name ||
			!agentDetails.phone ||
			!agentDetails.brokerage_name ||
			!agentDetails.brokerage_address
		) {
			toast.warn('You have to complete your profile first');
			return;
		}
		if (!agentDetails.brokers_ein) {
			toast.warn("Please enter your broker's EIN");
			return;
		}
		if (!agentDetails.amount) {
			toast.warn('Please enter an amount');
			return;
		}
		if (+agentDetails.amount > comps.amount) {
			toast.warn('You cannot request more than you have earned');
			return;
		}

		setIsLoading(true);

		try {
			if (!agentDetails.has_ein) {
				await base.patch(`register/agents/${typeId}/`, { brokers_ein: agentDetails.brokers_ein });
			}
			const { data } = await base.post('/compensations/agent-report/', {
				agent: agent_id,
				amount: +agentDetails.amount,
			});
			toast.info(data.detail);
			setDetailsModal(false);
		} catch (err) {
			toast.error('Could not post request');
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<>
			<Dialog open={detailsModal} fullWidth onClose={() => setDetailsModal(false)}>
				<DialogContent dividers sx={{ position: 'relative' }}>
					<IconButton onClick={() => setDetailsModal(false)} sx={{ position: 'absolute', top: '5%', right: '5%' }}>
						<Close fontSize='small' />
					</IconButton>
					<Box sx={{ padding: '2rem' }}>
						<Typography mb={3} textAlign={'center'}>
							Cashout Pay
						</Typography>
						<Grid container spacing={2}>
							<Grid item xl={6}>
								<Tooltip title='Go to profile to edit'>
									<InputLabel>First Name </InputLabel>
								</Tooltip>
								<TextField fullWidth disabled name='first_name' defaultValue={agentDetails.first_name} variant='outlined' />
							</Grid>
							<Grid item xl={6}>
								<Tooltip title='Go to profile to edit'>
									<InputLabel>Last Name</InputLabel>
								</Tooltip>
								<TextField fullWidth name='last_name' disabled defaultValue={agentDetails.last_name} variant='outlined' />
							</Grid>
							<Grid item xl={12}>
								<Tooltip title='Go to profile to edit'>
									<InputLabel>Phone</InputLabel>
								</Tooltip>
								<TextField
									fullWidth
									name='address'
									disabled
									defaultValue={formatPhoneNumber(agentDetails.phone) || agentDetails.phone}
									variant='outlined'
								/>
							</Grid>
							<Grid item xl={12}>
								<Tooltip title='Go to profile to edit'>
									<InputLabel>Broker Name</InputLabel>
								</Tooltip>
								<TextField fullWidth name='brokerage_name' disabled defaultValue={agentDetails.brokerage_name} required variant='outlined' />
							</Grid>
							<Grid item xl={12}>
								<Tooltip title='Go to profile to edit'>
									<InputLabel>Broker Address</InputLabel>
								</Tooltip>
								<TextField fullWidth name='brokerage_address' disabled defaultValue={agentDetails.brokerage_address} variant='outlined' />
							</Grid>
							<Grid item xl={12}>
								<InputLabel>Broker EIN</InputLabel>
								<TextField
									fullWidth
									name='brokers_ein'
									value={agentDetails.brokers_ein}
									onChange={(e) => setAgentDetails((cur) => ({ ...cur, [e.target.name]: e.target.value }))}
									variant='outlined'
								/>
							</Grid>
							<Grid item xl={12}>
								<InputLabel>Amount($ {formatNumber(comps.amount)} available)</InputLabel>
								<TextField
									fullWidth
									name='amount'
									type='number'
									variant='outlined'
									value={agentDetails.amount}
									onChange={(e) => setAgentDetails((cur) => ({ ...cur, [e.target.name]: e.target.value }))}
								/>
							</Grid>
						</Grid>
					</Box>
				</DialogContent>
				<DialogActions sx={{ padding: '1rem 2rem' }}>
					<LoadingButton
						loading={isLoading}
						disableElevation
						sx={{ textTransform: 'none' }}
						variant='contained'
						color='secondary'
						onClick={sendRequest}
					>
						Submit
					</LoadingButton>
				</DialogActions>
			</Dialog>
			<Tooltip title='Click to request cashout'>
				<Stack
					onClick={openDetails}
					justifyContent={'space-between'}
					sx={{ height: '100%', width: '100%', boxShadow: '-10px 10px 20px #0000000d', borderRadius: '5px', overflow: 'hidden', cursor: 'pointer' }}
					alignItems={'center'}
					p='1rem'
					className={classes.smallBox}
				>
					{loader ? (
						<Stack width='100%' height='100%' alignItems='center' justifyContent='center'>
							<CircularProgress color='primary' />
						</Stack>
					) : (
						<>
							<Typography fontSize={14} color='#000D18'>
								Compensation
							</Typography>
							<Typography color='#005EA9' fontSize={64} fontWeight={700} sx={{ display: 'flex', alignItems: 'center' }}>
								<span style={{ fontSize: 18, fontWeight: 500 }}>$</span> {formatNumber(comps.amount)}
							</Typography>
							<Typography fontSize={14} color='#000D18'>
								Total Cash
							</Typography>
						</>
					)}
				</Stack>
			</Tooltip>
		</>
	);
};

export default Compensation;
