import React from 'react';
import './profile.scss';
import { useOutletContext } from 'react-router-dom';
import base, { file as fileUploader } from '../../../apis';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import Input from '../../../components/Input/Input';
import Button from '../../../components/Button/Button';
import { Avatar } from '@mui/material';

function Profile() {
	//eslint-disable-next-line
	const [agent, isLoading, setIsLoading, setAgent] = useOutletContext();
	const id = useSelector((state) => state.auth.typeId);
	const [video_link, setVideo_link] = React.useState('');

	React.useEffect(() => {
		if (agent) {
			setVideo_link(agent?.video_link || '');
		}
	}, [agent]);

	const setImage = (e) => {
		const { files, name } = e.target;
		const file = files[0];

		setIsLoading(true);
		let formData = new FormData();
		formData.append(name, file);
		fileUploader
			.patch(`register/agents/${id}/`, formData)
			.then(({ data }) => {
				setAgent(data);
				e.target.value = '';
			})
			.catch((err) => {
				toast.error('Could not upload image');
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const onSaveVideoLink = (e) => {
		e.preventDefault();
		if (!e.target.checkValidity()) return;
		setIsLoading(true);
		base
			.patch(`register/agents/${id}/`, {
				video_link: video_link || null,
			})
			.then(({ data }) => {
				setAgent(data);
				toast.success('Video link updated');
			})
			.catch((err) => {
				toast.error('Could update description');
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	return (
		<div className='profile'>
			<div>
				<p>Profile Picture</p>
				<span>The photo should be a full photo of yourself with a less distractive background</span>
				<Avatar src={agent?.profile_image} style={{ width: '150px', height: '150px', margin: '10px auto' }} />

				<input type='file' accept='image/png, image/jpeg' style={{ margin: '0 auto' }} onChange={setImage} name='profile_image' />
			</div>
			<form onSubmit={onSaveVideoLink}>
				<div>
					<p>Introductory Video</p>
				</div>
				<span style={{ fontSize: '14px', textAlign: 'left' }}>The introductory video informs the viewer about you and your services.</span>
				<Input placeholder='Link to video' type='url' value={video_link} onChange={(e) => setVideo_link(e.target.value)} />
				<Button
					style={{
						width: 'fit-content',
						height: '45px',
						fontSize: '16px',
						padding: '10px 30px',
						color: 'white',
						backgroundColor: 'var(--primary-color)',
						border: '1px solid var(--primary-color)',
					}}
					type='submit'
					text='Save'
				/>
			</form>
		</div>
	);
}

export default Profile;
