import { makeStyles } from '@mui/styles';
import { Box, Button, Container, Divider, Grid, Typography } from '@mui/material';
import React from 'react';
//import Navbar from '../../components/MUI-Components/Navbar'
import BG from '../../assets/adbg.webp';
import Cost from '../../assets/cost.webp';
import Ad2 from '../../assets/adsec2.webp';
import Ad3 from '../../assets/adsec3.webp';
import Ad4 from '../../assets/adsec4.webp';
import SEO from '../../components/SEO';

const useStyles = makeStyles((theme) => ({
	root: {},
	wrapper: {
		padding: '4rem 0',
	},
	banner: {
		height: '70vh',
		backgroundSize: 'cover',
		backgroundPosition: 'center',
	},
	bannerTitle: {
		color: '#fff',
		display: 'flex',
		flexDirection: 'column !important',
		justifyContent: 'center',
		alignItems: 'flex-start',
		[theme.breakpoints.down('md')]: {
			padding: '2rem',
		},
		[theme.breakpoints.down('sm')]: {
			padding: '1.0rem',
		},
		'& h6': {
			fontSize: '2.5rem',
			fontWeight: 600,
			fontFamily: 'Lato',
			lineHeight: '1.1',
			marginBottom: '2rem',
			[theme.breakpoints.down('sm')]: {
				fontSize: '2.0rem',
			},
		},
		'& p': {
			fontWeight: 400,
			fontFamily: 'Lato',
			fontSize: '1.4rem',
			lineHeight: '1.2',
			[theme.breakpoints.down('sm')]: {
				fontSize: '1.2rem',
			},
		},
	},
	btn: {
		textTransform: 'none !important',
		marginTop: '1rem !important',
		width: '40%',
		[theme.breakpoints.down('sm')]: {
			width: 'auto',
		},
	},
	card: {
		height: '100%',
		borderRadius: '8px',
		color: '#fff',
		textAlign: 'center',
		background: theme.primaryColor,
		display: 'flex',
		flexDirection: 'column',
		padding: '1.5rem',
		'& h6': {
			fontSize: '1.2rem',
		},
		'& #btn': {
			textTransform: 'none',
			fontSize: '.9rem',
			marginTop: 'auto',
			background: '#fff',
		},
	},
	divider: {
		background: theme.primaryColor,
		height: '10px',
		width: '5rem',
		margin: '2rem 0 !important',
		display: 'block',
		border: 'none !important',
	},
	secGrid: {
		margin: '0 !important',
		width: '100% !important',
		[theme.breakpoints.down('md')]: {
			margin: 'auto',
			width: 'auto !important',
		},
		[theme.breakpoints.down('sm')]: {
			margin: 'auto',
			width: 'auto !important',
		},
	},
	secTitle: {
		fontWeight: '600 !important',
	},
	secBtn: {
		width: '12rem',
		textTransform: 'none !important',
		marginTop: '1rem !important',
	},
	secContent: {
		padding: '2.5rem',
		display: 'flex',
		flexDirection: 'column !important',
		justifyContent: 'center',
	},
	secImg: {
		height: '34rem',
		backgroundPosition: 'center',
		backgroundSize: 'cover',
		[theme.breakpoints.down('md')]: {
			height: '30rem',
		},
		[theme.breakpoints.down('sm')]: {
			height: '20rem',
		},
	},
}));

export const AdCard = ({ title, copy, link }) => {
	const classes = useStyles();
	return (
		<>
			<Box className={classes.card}>
				<Typography variant='h6' gutterBottom>
					{title}
				</Typography>
				<Typography variant='body2' paragraph>
					{copy}
				</Typography>
				<Button variant='contained' id='btn' disableElevation fullWidth href={link}>
					Learn More
				</Button>
			</Box>
		</>
	);
};

const Advertise = () => {
	const classes = useStyles();

	const cards = [
		{
			title: 'Premier Agent',
			copy: '$299-$699 per Month ( Initial Farm Set Up fee of $99 not Included)',
			link: '/advertise/advertise-farm',
		},
		{
			title: 'Premier Agent Standard',
			copy: '$199 Per Month for our Premier Agent (Initial Set Up fee of $99 not Included)',
			link: '/advertise/advertise-premier',
		},
		{
			title: 'BPO Deal Analyzer',
			copy: '$199 Per Month  for our BPO Deal Analyzer',
			link: '/advertise/advertise-deal',
		},
		{
			title: 'BPO Homes Report Agent',
			copy: 'Our completely free service for any agent to get started, with the option of upgrading to one of our Premier Agent Services in the near future.',
			link: '/advertise/advertise-report',
		},
	];

	return (
		<div className={classes.root}>
			{/* NAVBAR */}
			{/* <Navbar /> */}
			<SEO>
				<title>Get Free & Paid Solutions for Real Estate Agents</title>
				<meta name='og:title' content='Get Free & Paid Solutions for Real Estate Agents' />
				<meta
					name='description'
					content='Boost your real estate business with both free and paid advertising solutions from BPO Tech. Reach more potential clients and grow your business today.'
				/>
				<meta
					name='og:description'
					content='Boost your real estate business with both free and paid advertising solutions from BPO Tech. Reach more potential clients and grow your business today.'
				/>
				<meta name='og:image' content={window.location.origin + BG} />
			</SEO>
			{/* CONTENT */}
			<Box>
				{/* BANNER */}
				<Box className={classes.banner} style={{ backgroundImage: `url(${BG})` }}>
					<Container style={{ height: '100%' }}>
						<Grid container style={{ height: '100%' }}>
							<Grid item sm={12} md={7} lg={7} className={classes.bannerTitle}>
								<Typography variant='h6'>Are you looking to Build your Real Estate Business?</Typography>
								<Typography paragraph>We have a plethora of options for your business growth! See your business growth options</Typography>
							</Grid>
						</Grid>
					</Container>
				</Box>

				{/* CARD SECTION */}
				<Box className={classes.wrapper} bgcolor='#F2FBFF'>
					<Container>
						<Box textAlign={'center'} marginBottom='2rem'>
							<Typography variant='h5' gutterBottom>
								Find the right real estate solution for you
							</Typography>
							<Typography variant='body2' color={'text'}>
								Whether you work independently or lead a large team of agents, we have tailored options to grow your real estate network.
							</Typography>
						</Box>
						<Grid container spacing={3}>
							{cards.map((el) => {
								return (
									<Grid key={el.title} item xs={12} sm={6} md={6} lg={3}>
										<AdCard title={el.title} copy={el.copy} link={el.link} />
									</Grid>
								);
							})}
						</Grid>
					</Container>
				</Box>

				{/* SECTION 1*/}
				<Box className={classes.wrapper}>
					<Container>
						<Grid container spacing={6} className={classes.secGrid}>
							<Grid item lg={6} md={6} sm={12} xs={12} className={classes.secImg} style={{ backgroundImage: `url(${Cost})` }}></Grid>
							<Grid item lg={6} md={6} sm={12} xs={12} className={classes.secContent}>
								<Typography variant='h6' gutterBottom className={classes.secTitle}>
									Cost effective to grow your business
								</Typography>
								<Divider className={classes.divider} />
								<Typography paragraph>
									Premier Agent Combo is our Top Platform that builds your business from the ground up. The Premier Agent Combo Platform is fully
									loaded and includes all of what BPO Tech has to offer
								</Typography>
								<Typography paragraph>
									Our Real Estate Farming Techniques are unparalleled to anything on the Market. Premier Agent Combo is cost effective for any Agent
									to grow their business starting with a minimum of 300 homes per Farm Area.
								</Typography>
								<Button className={classes.secBtn} variant='contained' href='advertise/advertise-farm' disableElevation color='secondary'>
									Learn More
								</Button>
							</Grid>
						</Grid>
					</Container>
				</Box>

				{/* SECTION 2*/}
				<Box className={classes.wrapper} bgcolor='#F2FBFF'>
					<Container>
						<Grid container spacing={6} className={classes.secGrid}>
							<Grid item lg={6} md={6} sm={12} xs={12} className={classes.secContent} order={{ md: 1, sm: 2, xs: 2 }}>
								<Typography variant='h6' gutterBottom className={classes.secTitle}>
									Find the right real estate solution for you
								</Typography>
								<Divider className={classes.divider} />
								<Typography paragraph>
									BPO Premier Agent Basic is our 2nd Tier platform to build your business. BPO Premier Agent Basic does everything you need for online
									advertisement
								</Typography>

								<Button className={classes.secBtn} variant='contained' disableElevation href='advertise/advertise-premier' color='secondary'>
									Learn More
								</Button>
							</Grid>
							<Grid
								item
								lg={6}
								md={6}
								sm={12}
								xs={12}
								className={classes.secImg}
								order={{ md: 2, sm: 1, xs: 1 }}
								style={{ backgroundImage: `url(${Ad2})` }}
							></Grid>
						</Grid>
					</Container>
				</Box>

				{/* SECTION 3*/}
				<Box className={classes.wrapper}>
					<Container>
						<Grid container spacing={6} className={classes.secGrid}>
							<Grid item lg={6} md={6} sm={12} xs={12} className={classes.secImg} style={{ backgroundImage: `url(${Ad3})` }}></Grid>
							<Grid item lg={6} md={6} sm={12} xs={12} className={classes.secContent}>
								<Typography variant='h6' gutterBottom className={classes.secTitle}>
									Evaluate a deal like a PRO
								</Typography>
								<Divider className={classes.divider} />
								<Typography paragraph>Looking to upgrade your investor portfolio but scared to Analyze a Deal?</Typography>
								<Typography paragraph>
									We have your back! The BPO Homes Deal Analyzer allows you to evaluate a deal just like an investor and send a fully branded Property
									Proforma to your client within minutes
								</Typography>
								<Button className={classes.secBtn} variant='contained' href='advertise/advertise-deal' disableElevation color='secondary'>
									Learn More
								</Button>
							</Grid>
						</Grid>
					</Container>
				</Box>

				{/* SECTION 4*/}
				<Box className={classes.wrapper} bgcolor='#F2FBFF'>
					<Container>
						<Grid container spacing={6} className={classes.secGrid}>
							<Grid item lg={6} md={6} sm={12} xs={12} className={classes.secContent} order={{ md: 1, sm: 2, xs: 2 }}>
								<Typography variant='h6' gutterBottom className={classes.secTitle}>
									Earn money for your insight
								</Typography>
								<Divider className={classes.divider} />
								<Typography paragraph>Dead Broke? We have all been there...😪</Typography>
								<Typography paragraph>
									Fully Free Service for Any Agent to get started with the ability to upgrade to one of our Premier Agent Services in the near future.
								</Typography>

								<Button className={classes.secBtn} variant='contained' href='advertise/advertise-report' disableElevation color='secondary'>
									Learn More
								</Button>
							</Grid>
							<Grid
								item
								lg={6}
								md={6}
								sm={12}
								xs={12}
								className={classes.secImg}
								order={{ md: 2, sm: 1, xs: 1 }}
								style={{ backgroundImage: `url(${Ad4})` }}
							></Grid>
						</Grid>
					</Container>
				</Box>
			</Box>
		</div>
	);
};

export default Advertise;
