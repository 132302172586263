import React, { useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import StyledLabel from "../../atomicComponents/StyledLabel";
import EnhancedRadioButtonGroup from "../../atomicComponents/EnhancedRadioButtonGroup";
import FlatRatePercentageInput from "../../atomicComponents/FlatRatePercentageInput";
import { useSelector } from "react-redux";

const useStyles = (_theme) => {
  return {
    root: {
      margin: "2rem",
    },
    underInput: {
      "&:hover": {
        borderBottom: "solid",
      },
      cursor: "pointer",
    },
    bottomLabel: {
      marginTop: "5px",
      display: "flex",
      alignItems: "center",
      padding: "0.5rem 0",
    },
  };
};
function DemolishingCost({ handleEvents, data }) {
  const theme = useTheme();
  const styles = useStyles(theme);
  const purchasePrice = useSelector(state=>state.straightAestheticRemodel?.financeOptions?.primary_purchase_price) 

  // const [open, setOpen] = React.useState(false);

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };
  // const handleClose = () => {
  //   setOpen(false);
  // };

 
  const extraStyles = {
    display: "flex",
    flexDirection: "column-reverse"
  };
  const radioOverride = {
    marginRight: "3.125rem",
  };
  const handleChange = (value) => {
    handleEvents({ demolishing_cost_option: value });
  };
  const rateHandler = (value) => {
    handleEvents({ [`demolishing_cost_dollar_or_percent`]: value });
  };



  useEffect(()=>{
    !data?.demolishing_cost_option && handleEvents({ demolishing_cost_option: true})
      // NOTE: Run effect once on component mount, please
      // recheck dependencies if effect is updated.
      // eslint-disable-next-line react-hooks/exhaustive-deps 
  },[])

  return (
    <Box sx={styles.root}>
      <Box>
        <StyledLabel label="Do you want to demolish the pre-existing property" />
        <EnhancedRadioButtonGroup
          options={radioGroupOptions}
          styles={extraStyles}
          radioOverride={radioOverride}
          handler={handleChange}
          name = {'demolishing_cost_option'}
          choice={+data["demolishing_cost_option"]}
        />
      </Box>
      {+data?.demolishing_cost_option ?
      <>
      <FlatRatePercentageInput
        title={demolitionCostData.title}
        name={"demolishing_cost"}
        toolTipText={demolitionCostData.tooltipText}
        rateHandler={rateHandler}
        handler={handleEvents}
        data={data}
        rate={0}
        referenceValue = {purchasePrice}
        fieldOnly={true}
      />
  

      <Box sx={styles.bottomLabel}>
        <Typography
          variant="h6"
          color="primary"
          sx={styles.underInput}
          // onClick={handleClickOpen}
        >
          {demolitionCostData.clickOpenDialogText}
        </Typography>
      </Box>
      </>
      :""}
      
      {/* <SimpleDialog
        title="Est. Demolition Cost Per Square foot"
        handleClose={handleClose}
        open={open}
        names={{ name1: "", name2: "est_demolition_cost_psqft" }}
        handler={handleEvents}
      /> */}
    </Box>
  );
}

export default DemolishingCost;

const radioGroupOptions = ["Not Applicable (Vacant Land)","Yes"];
const demolitionCostData = {
  title: "Demolishing Cost",
  name: "demolishing_cost",
  tooltipText:
    "The cost to remove the pre-existing dwelling/property. Typical cost is 3-5% of purchase price.",
  clickOpenDialogText:
    "Demolition cost may vary. contact your local contractor for Demolition cost",
};
