import { Close } from '@mui/icons-material';
import { Box, Dialog, DialogActions, DialogContent, Grid, IconButton, ImageList, ImageListItem, Tooltip, Typography } from '@mui/material';
import { startCase } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { MdCheckBoxOutlineBlank, MdOutlineCheckBox } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import SignatureCanvas from 'react-signature-canvas';
import { useReactToPrint } from 'react-to-print';
import { toast } from 'react-toastify';
import base, { file as fileUpload } from '../../apis';
import Button from '../../components/Button/Button';
import Spinner from '../../components/Spinner/Spinner';
import { additionalFields } from '../../constants';
import Logo from '../../images/bpoVerticalLogo.webp';
import { formatNumber, formatPhoneNumber } from '../../utils/functions';
import './previewreport.scss';

const currentMarketConditions = ['Decreased', 'Stable', 'Increased'];

export default function PreviewReport() {
	const { id } = useParams();
	const [isLoading, setIsLoading] = useState(false);
	const [report, setReport] = useState(null);
	useEffect(() => {
		setIsLoading(true);
		base
			.get(`/leads/report/${id}`)
			.then(({ data }) => {
				setReport(data);
			})
			.catch((err) => {
				toast.warn('Could not load report');
			})
			.finally(() => {
				setIsLoading(false);
			});
	}, [id]);

	const doc = useRef(null);

	const handlePrint = useReactToPrint({
		content: () => doc.current,
		documentTitle: 'BPO-report-' + (report?.clients_physical_address || report?.id),
		pageStyle: `
    @page {
      size: 297mm 420mm;
    }

  `,
	});

	const sendToClient = () => {
		if (!report?.signature || !report?.date_signed) {
			toast.warn('Please sign before sending it.');
			return;
		}
		setIsLoading(true);
		base
			.post(`/leads/send_report_email/`, { report_id: id })
			.then(({ data }) => {
				toast.success('Report sent to client');
			})
			.catch((err) => {
				toast.warn('Could not send report to client');
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const navigate = useNavigate();
	const goBack = () => {
		navigate(-1);
	};

	const role = useSelector((state) => state.auth.role);
	const mine = useSelector((state) => (state.auth.role === 'agent' || state.auth.role === 'realty_agent') && state.auth.typeId === report?.agent_id);
	const [recSign, setRecSign] = useState(false);

	// signature functions //
	let signPadRecipient = {};

	const openSign = () => {
		if (role === 'client' || !mine) return;
		setRecSign(true);
	};

	const clearPadRecipient = () => {
		signPadRecipient.clear();
	};

	const signRecipient = async () => {
		if (signPadRecipient.isEmpty()) {
			toast.warn('Please sign before submitting');
			return;
		}
		const sig = signPadRecipient.toDataURL('image/webp');

		const url = sig;
		let file = null;

		const blob = await fetch(url);
		const file1 = await blob.blob();
		const file2 = new File([file1], `bpo_report_signature_${id}.png`, { type: 'image/png' });
		file = file2;

		setIsLoading(true);
		let formData = new FormData();
		formData.append('signature', file);
		formData.append('date_signed', new Date().toJSON());
		fileUpload
			.patch(`/leads/report/${id}/`, formData)
			.then(({ data }) => {
				setReport(data);
			})
			.catch((err) => {})
			.finally(() => {
				setIsLoading(false);
				setRecSign(false);
			});
	};

	return (
		<>
			{isLoading && <Spinner />}
			{/* Sign Recipient Contract */}
			<Dialog open={recSign} fullWidth onClose={() => setRecSign(false)}>
				<DialogContent dividers sx={{ position: 'relative' }}>
					<IconButton onClick={() => setRecSign(false)} sx={{ position: 'absolute', top: '5%', right: '5%' }}>
						<Close fontSize='small' />
					</IconButton>
					<Box sx={{ padding: '2rem' }}>
						<Typography mb={3} textAlign={'center'}>
							Recipient Agent Signature
						</Typography>
						<Box border='1px dashed grey' borderRadius='5px'>
							<SignatureCanvas
								ref={(ref) => {
									signPadRecipient = ref;
								}}
								penColor='#000'
								canvasProps={{ width: 500, height: 200, className: 'sigCanvas' }}
							/>
						</Box>
					</Box>
				</DialogContent>
				<DialogActions sx={{ padding: '1rem 2rem' }}>
					<Button disableElevation sx={{ textTransform: 'none' }} onClick={clearPadRecipient} variant='outlined' color='secondary'>
						Clear
					</Button>
					<Button disableElevation sx={{ textTransform: 'none' }} onClick={signRecipient} variant='contained' color='secondary'>
						Continue
					</Button>
				</DialogActions>
			</Dialog>
			<div className='sticky-rep'>
				<div className='preport-header'>
					<Grid container spacing={2}>
						<Grid item xl={3}>
							{role === 'agent' && <span onClick={goBack}>Back</span>}
						</Grid>
						<Grid item xl={6}>
							<p style={{ textAlign: 'center' }}>BPO Report Preview</p>
						</Grid>
						<Grid item xl={3}>
							<span>{report?.clients_physical_address}</span>
						</Grid>
					</Grid>
				</div>
			</div>
			<div ref={doc}>
				<div className='preport'>
					<main className='preport-main'>
						<div className='imgheader'>
							<img src={Logo} alt='logo' width={100} />
							<div>
								<span>6920 Santa Teresa Blvd, San Jose CA 95119</span>
								<span>Phone: +1 (888) 616-5270</span>
								<span>Email: support@bpohomes.com</span>
							</div>
						</div>
						<p className='preport-main__header'>broker price opinion</p>
						<div className='preport-main__between'>
							<span>Date: {new Date(report?.date_created).toLocaleDateString('en-US')}</span>
							<span>
								Licenses Number: {report?.agent?.licenses?.find((license) => license.category === 'real_estate_agent')?.number || 'Not Provided'}
							</span>
						</div>
						<div className='preport-main__between'>
							<span>Property Address: {report?.clients_physical_address}</span>
						</div>
						<div className='preport-main__between'>
							<span>Brokerage Name: {report?.agent?.brokerage_name}</span>
							<span>Agent Name: {report?.agent?.user?.first_name + ' ' + report?.agent?.user?.last_name}</span>
							{/* <span>Firm Name: {report?.name}</span> */}
							<span>Phone Number: {formatPhoneNumber(report?.agent?.phone) || report?.agent?.phone}</span>
						</div>
						<hr />
						<div className='preport-main__generic'>
							<h3>general marketing conditions</h3>
							<div className='seventy'>
								<span>Current Marketing Condition:</span>
								{currentMarketConditions.map((cmd) => (
									<div className='seventy__even' key={cmd}>
										{report?.current_market_conditions === cmd ? (
											<MdOutlineCheckBox className='preport__icon' />
										) : (
											<MdCheckBoxOutlineBlank className='preport__icon' />
										)}
										<span>{cmd}</span>
									</div>
								))}
							</div>
							{['Increased', 'Decreased'].includes(report?.current_market_conditions) && (
								<div className='seventy' style={{ alignItems: 'flex-start', width: 'fit' }}>
									<span style={{ width: 'fitContent', marginRight: '10px' }}>Approximate marketing price of this property has:</span>
									<div
										style={{
											display: 'flex',
											flexDirection: 'column',
											marginLeft: '3rem',
										}}
									>
										<div style={{ display: 'flex', marginBottom: '10px' }}>
											<div className='seventy__even' style={{ marginRight: 0 }}>
												{/* <MdCheckBoxOutlineBlank className='preport__icon' style={{ opacity: 0 }} /> */}
												<b>{report?.current_market_conditions} by&nbsp;</b>
											</div>
											<div className='seventy__even' style={{ margin: 0 }}>
												<b>&nbsp;{report?.market_price_change_percentage}&nbsp;%</b>
											</div>
											<div className='seventy__even' style={{ margin: 0 }}>
												&nbsp;in the past&nbsp;
											</div>
											<div className='seventy__even' style={{ margin: 0 }}>
												<b>{report?.market_price_change_months} months</b>
											</div>
										</div>
									</div>
								</div>
							)}
							<p>
								Approximately <b>{report?.num_of_sold_listings || 0}</b> comparable sold listings in the neighborhood in the last{' '}
								<b>{report?.sold_listings_time || 0}</b> weeks.
							</p>
							<p>
								Approximately <b>{report?.num_of_active_listings || 0}</b> comparable active listings in the neighborhood in the last{' '}
								<b>{report?.active_listings_period || 0}</b> weeks.
							</p>
						</div>
						<hr />
						<div className='preport-main__generic'>
							<h3>Subject Marketability</h3>
							<p>
								Approximate range of values in the neighborhood is <b>$ {formatNumber(report?.neighborhood_price_lower, 0)}</b> to{' '}
								<b>$ {formatNumber(report?.neighborhood_price_upper, 0)}</b>
							</p>
							<div className='seventy'>
								<p>
									The subject is an <b>{report?.subject_impact} improvement</b> for the neighborhood
								</p>
							</div>
							<p>
								Typical Marketing time in the area is <b>{report?.marketing_time} days</b>
							</p>
							<div className='seventy'>
								<p>
									Dwelling Type: <b>{report?.unit_type}</b>
								</p>
							</div>
						</div>
						<hr />
						{/* comparable closed sales */}
						<div className='preport-main__generic'>
							<h3>comparable closed sales</h3>
							<table>
								<thead>
									<tr>
										<th className='unseter' style={{ width: '50%' }}>
											Address
										</th>
										<th className='unseter'>Sale Price</th>
										<th className='unseter'>Sq. Ft</th>
										<th className='unseter'>Price/Sq. Ft</th>
										<th className='unseter'>Sales Date</th>
										{/* <th className='unseter'>Days on Mkt</th> */}
										<th className='unseter'> Lot Size</th>
										<th className='unseter'>Condition</th>
										<th className='unseter'>Bedrooms</th>
										<th className='unseter'>Baths</th>
									</tr>
								</thead>
								<tbody>
									{report?.comparable_closed_listings
										.filter((item, i) => i <= 4)
										.map((item) => (
											<tr key={item.id}>
												<td className='unseter'>{item.address}</td>
												<td className='unseter'>{formatNumber(item.sales_price, 0)}</td>
												<td className='unseter'>{formatNumber(item.gross_living_area, 0)}</td>
												<td className='unseter'>{formatNumber(Math.round(item.sales_price / item.gross_living_area), 0)}</td>
												<td className='unseter'>{new Date(item.sale_date).toLocaleDateString('en-US')}</td>
												<td className='unseter'>{item.lot_size}</td>
												<td className='unseter'>{startCase(item.condition?.split('_').join(' ')) || 'N/A'}</td>
												<td className='unseter'>{item.num_bedrooms}</td>
												<td className='unseter'>{item.num_bathrooms}</td>
											</tr>
										))}
								</tbody>
							</table>
						</div>
						<hr />
						{/* comparable closed sales Feedback */}
						<div className='preport-main__generic'>
							<h3>comparable closed sales Feedback</h3>
							<table style={{ pageBreakInside: 'avoid' }} className='tabler'>
								<thead>
									<tr>
										<th style={{ width: `${(1 / (1 + additionalFields.length)) * 100}%`, whiteSpace: 'unset' }}>Address</th>
										{additionalFields.map((item) => (
											<th className='unseter' key={item.id} style={{ width: `${(1 / (additionalFields.length + 1)) * 100}%`, whiteSpace: 'unset' }}>
												{item.label.slice(0, 33)}
											</th>
										))}
									</tr>
								</thead>
								<tbody>
									{report?.comparable_closed_listings
										.filter((item, i) => i <= 4)
										.map((item) => (
											<tr key={item.id}>
												<td className='unseter' style={{ width: `${(1 / (1 + additionalFields.length)) * 100}%`, whiteSpace: 'unset' }}>
													{item.address}
												</td>
												{additionalFields.map((field) => (
													<td
														className='unseter'
														key={item[field.label]}
														style={{ width: `${(1 / (1 + additionalFields.length)) * 100}%`, whiteSpace: 'unset' }}
													>
														{item[field.feedback] || 'N/A'}
													</td>
												))}
											</tr>
										))}
								</tbody>
							</table>
						</div>
						{/* comparable closed Descriptions */}
						<hr />
						<div className='preport-main__generic'>
							<p className='titler'>Comparable Descriptions</p>
							<ol style={{ pageBreakAfter: 'auto' }}>
								{report?.comparable_closed_listings.map((item) => (
									<li key={item.id} style={{ marginTop: '10px' }}>
										<b>{item.address}</b>
										<p style={{ textAlign: 'justify', lineHeight: '1.4' }}>{item.description}</p>
									</li>
								))}
							</ol>
						</div>

						<hr />
						{/* comparable closed sales Adjustments */}
						<div className='preport-main__generic'>
							<h3>comparable closed sales Adjustments</h3>
							<table>
								<thead>
									<tr>
										{additionalFields.map((item) => (
											<th className='unseter' key={item.id} style={{ width: `${(1 / (additionalFields.length + 4)) * 100}%`, whiteSpace: 'unset' }}>
												{item.label.slice(0, 33)}
											</th>
										))}
										<th className='unseter' style={{ width: `${(1 / (additionalFields.length + 4)) * 100}%`, whiteSpace: 'unset' }}>
											Garage
										</th>
										<th className='unseter' style={{ width: `${(1 / (additionalFields.length + 4)) * 100}%`, whiteSpace: 'unset' }}>
											Lot Size{' '}
										</th>
										<th className='unseter' style={{ width: `${(1 / (additionalFields.length + 4)) * 100}%`, whiteSpace: 'unset' }}>
											Interior Livable Area
										</th>
										<th className='unseter' style={{ width: `${(1 / (additionalFields.length + 4)) * 100}%`, whiteSpace: 'unset' }}>
											Total ($)
										</th>
									</tr>
								</thead>
								<tbody>
									{report?.comparable_closed_listings
										.filter((item, i) => i <= 4)
										.map((item) => (
											<tr key={item.id}>
												{additionalFields.map((field) => (
													<td
														className='unseter'
														key={item[field.label]}
														style={{ width: `${(1 / (additionalFields.length + 4)) * 100}%`, whiteSpace: 'unset' }}
													>
														{formatNumber(item[field.amount] || 0, 2)}
													</td>
												))}
												<td className='unseter' style={{ width: `${(1 / (additionalFields.length + 4)) * 100}%`, whiteSpace: 'unset' }}>
													{formatNumber(item.garage_difference_amount, 2)}
												</td>
												<td className='unseter' style={{ width: `${(1 / (additionalFields.length + 4)) * 100}%`, whiteSpace: 'unset' }}>
													{formatNumber(item.lot_size_difference_amount, 2)}
												</td>
												<td className='unseter' style={{ width: `${(1 / (additionalFields.length + 4)) * 100}%`, whiteSpace: 'unset' }}>
													{formatNumber(item.livable_square_footage_difference_amount, 2)}
												</td>
												<td className='unseter' style={{ width: `${(1 / (additionalFields.length + 4)) * 100}%`, whiteSpace: 'unset' }}>
													{formatNumber(
														additionalFields.reduce((acc, field) => acc + (item[field.amount] || 0), 0) +
															(item.garage_difference_amount || 0) +
															(item.lot_size_difference_amount || 0) +
															(item.livable_square_footage_difference_amount || 0),
														2
													)}
												</td>
											</tr>
										))}
								</tbody>
							</table>
						</div>

						<hr />
						{/* comparable closed sales images */}
						{report?.comparable_closed_listings.some((item) => item?.images?.length > 0) && (
							<div className='preport-main__generic' style={{ pageBreakInside: 'avoid' }}>
								<h3>comparable closed sales images</h3>
								{report?.comparable_closed_listings
									.filter((item) => item?.images?.length > 0)
									.map((listing) => (
										<div key={listing.id} style={{ pageBreakInside: 'avoid' }}>
											<b style={{ textDecoration: 'underline' }}>{listing.address}</b>
											<ImageList key={listing.id} sx={{ width: '100%', height: '100%', mx: 'auto', overflowY: 'unset' }} cols={3} rowHeight={200}>
												{listing.images.map((image) => (
													<ImageListItem key={image}>
														<img
															src={`${image}`}
															alt={'Property'}
															style={{
																border: '2px solid white',
																borderRadius: '5px',
																aspectRatio: '4/3',
																height: '100%',
															}}
														/>
													</ImageListItem>
												))}
											</ImageList>
										</div>
									))}
							</div>
						)}
					</main>
				</div>

				<div className='preport' style={{ breakBefore: 'always' }}>
					<main className='preport-main'>
						<hr />
						{/* comparable active listings */}
						<div className='preport-main__generic'>
							<h3>comparable active listings</h3>
							<table>
								<thead>
									<tr>
										<th className='unseter' style={{ width: '50%' }}>
											Address
										</th>
										<th className='unseter'>List Price</th>
										<th className='unseter'>Sq. Ft</th>
										<th className='unseter'>Price/Sq. Ft</th>
										<th className='unseter'>Days on Market</th>
										<th className='unseter'>Lot Size</th>
										<th className='unseter'>Condition</th>
										<th className='unseter'>Bedrooms</th>
										<th className='unseter'>Baths</th>
									</tr>
								</thead>
								<tbody>
									{report?.comparable_active_listings
										.filter((item, i) => i <= 4)
										.map((item) => (
											<tr key={item.id}>
												<td className='unseter'>{item.address}</td>
												<td className='unseter'>{formatNumber(item.sales_price, 0)}</td>
												<td className='unseter'>{formatNumber(item.gross_living_area, 0)}</td>
												<td className='unseter'>{formatNumber(Math.round(item.sales_price / item.gross_living_area), 0)}</td>
												<td className='unseter'>{item.days_on_market}</td>
												<td className='unseter'>{item.lot_size}</td>
												<td className='unseter'>{startCase(item.condition?.split('_').join(' ')) || 'N/A'}</td>
												<td className='unseter'>{item.num_bedrooms}</td>
												<td className='unseter'>{item.num_bathrooms}</td>
											</tr>
										))}
								</tbody>
							</table>
						</div>

						<hr />
						{/* comparable active listing Feedback */}
						<div className='preport-main__generic' style={{ pageBreakInside: 'avoid' }}>
							<h3>comparable active listings Feedback</h3>
							<table style={{ pageBreakInside: 'avoid' }}>
								<thead>
									<tr>
										<th style={{ width: `${(1 / (1 + additionalFields.length)) * 100}%`, whiteSpace: 'unset' }}>Address</th>
										{additionalFields.map((item) => (
											<th className='unseter' key={item.id} style={{ width: `${(1 / (additionalFields.length + 1)) * 100}%`, whiteSpace: 'unset' }}>
												{item.label.slice(0, 33)}
											</th>
										))}
									</tr>
								</thead>
								<tbody>
									{report?.comparable_active_listings
										.filter((item, i) => i <= 4)
										.map((item) => (
											<tr key={item.id}>
												<td className='unseter' style={{ width: `${(1 / (1 + additionalFields.length)) * 100}%`, whiteSpace: 'unset' }}>
													{item.address}
												</td>
												{additionalFields.map((field) => (
													<td
														className='unseter'
														key={item[field.label]}
														style={{ width: `${(1 / (1 + additionalFields.length)) * 100}%`, whiteSpace: 'unset' }}
													>
														{item[field.feedback] || 'N/A'}
													</td>
												))}
											</tr>
										))}
								</tbody>
							</table>
						</div>

						<hr />
						{/* comparable active listing Adjustments */}
						<div className='preport-main__generic'>
							<h3>comparable active listing Adjustments</h3>
							<table>
								<thead>
									<tr>
										{additionalFields.map((item) => (
											<th className='unseter' key={item.id} style={{ width: `${(1 / (additionalFields.length + 4)) * 100}%`, whiteSpace: 'unset' }}>
												{item.label.slice(0, 33)}
											</th>
										))}
										<th className='unseter' style={{ width: `${(1 / (additionalFields.length + 4)) * 100}%`, whiteSpace: 'unset' }}>
											Garage
										</th>
										<th className='unseter' style={{ width: `${(1 / (additionalFields.length + 4)) * 100}%`, whiteSpace: 'unset' }}>
											Lot Size{' '}
										</th>
										<th className='unseter' style={{ width: `${(1 / (additionalFields.length + 4)) * 100}%`, whiteSpace: 'unset' }}>
											Interior Livable Area
										</th>
										<th className='unseter' style={{ width: `${(1 / (additionalFields.length + 4)) * 100}%`, whiteSpace: 'unset' }}>
											Total ($)
										</th>
									</tr>
								</thead>
								<tbody>
									{report?.comparable_active_listings
										.filter((item, i) => i <= 4)
										.map((item) => (
											<tr key={item.id}>
												{additionalFields.map((field) => (
													<td
														className='unseter'
														key={item[field.label]}
														style={{ width: `${(1 / (additionalFields.length + 4)) * 100}%`, whiteSpace: 'unset' }}
													>
														{formatNumber(item[field.amount] || 0, 2)}
													</td>
												))}
												<td className='unseter' style={{ width: `${(1 / (additionalFields.length + 4)) * 100}%`, whiteSpace: 'unset' }}>
													{formatNumber(item.garage_difference_amount, 2)}
												</td>
												<td className='unseter' style={{ width: `${(1 / (additionalFields.length + 4)) * 100}%`, whiteSpace: 'unset' }}>
													{formatNumber(item.lot_size_difference_amount, 2)}
												</td>
												<td className='unseter' style={{ width: `${(1 / (additionalFields.length + 4)) * 100}%`, whiteSpace: 'unset' }}>
													{formatNumber(item.livable_square_footage_difference_amount, 2)}
												</td>
												<td className='unseter' style={{ width: `${(1 / (additionalFields.length + 4)) * 100}%`, whiteSpace: 'unset' }}>
													{formatNumber(
														additionalFields.reduce((acc, field) => acc + (item[field.amount] || 0), 0) +
															(item.garage_difference_amount || 0) +
															(item.lot_size_difference_amount || 0) +
															(item.livable_square_footage_difference_amount || 0),
														2
													)}
												</td>
											</tr>
										))}
								</tbody>
							</table>
						</div>

						<hr />
						{/* comparable active listing description  */}
						<div className='preport-main__generic'>
							<p className='titler'>Comparable Descriptions</p>
							<ol style={{ pageBreakAfter: 'auto' }}>
								{report?.comparable_closed_listings.map((item) => (
									<li key={item.id} style={{ marginTop: '10px' }}>
										<b>{item.address}</b>
										<p style={{ textAlign: 'justify', lineHeight: '1.4' }}>{item.description}</p>
									</li>
								))}
							</ol>
						</div>

						<hr />
						{/* comparable active listing images */}
						{report?.comparable_active_listings.some((item) => item?.images?.length > 0) && (
							<div className='preport-main__generic'>
								<h3>comparable active listing images</h3>
								{report?.comparable_active_listings
									.filter((item) => item?.images?.length > 0)
									.map((listing) => (
										<div key={listing.id} style={{ pageBreakInside: 'avoid' }}>
											<b style={{ textDecoration: 'underline' }}>{listing.address}</b>
											<ImageList key={listing.id} sx={{ width: '100%', height: '100%', mx: 'auto', overflowY: 'unset' }} cols={3} rowHeight={200}>
												{listing.images.map((image) => (
													<ImageListItem key={image}>
														<img
															src={`${image}`}
															alt={'Property'}
															style={{
																border: '2px solid white',
																borderRadius: '5px',
																aspectRatio: '4/3',
																height: '100%',
															}}
														/>
													</ImageListItem>
												))}
											</ImageList>
										</div>
									))}
								<hr />
							</div>
						)}

						{(report?.interior_photos.length > 0 || report?.exterior_photos.length > 0) && (
							<div className='preport-main__generic'>
								<h3>Subject Property Photos</h3>
								{report?.interior_photos.length > 0 && (
									<div style={{ pageBreakInside: 'avoid' }}>
										<b style={{ textDecoration: 'underline' }}>Interior Photos</b>
										<ImageList sx={{ width: '100%', height: '100%', mx: 'auto', overflowY: 'unset' }} cols={3} rowHeight={200}>
											{report?.interior_photos.map((image) => (
												<ImageListItem key={image}>
													<img
														src={`${image.image}`}
														alt={'Property'}
														style={{
															border: '2px solid white',
															borderRadius: '5px',
															aspectRatio: '4/3',
															height: '100%',
														}}
													/>
												</ImageListItem>
											))}
										</ImageList>
									</div>
								)}
								{report?.exterior_photos.length > 0 && (
									<div style={{ pageBreakInside: 'avoid' }}>
										<b style={{ textDecoration: 'underline' }}>Exterior Photos</b>
										<ImageList sx={{ width: '100%', height: '100%', mx: 'auto', overflowY: 'unset' }} cols={3} rowHeight={200}>
											{report?.exterior_photos.map((image) => (
												<ImageListItem key={image}>
													<img
														src={`${image.image}`}
														alt={'Property'}
														style={{
															border: '2px solid white',
															borderRadius: '5px',
															aspectRatio: '4/3',
															height: '100%',
														}}
													/>
												</ImageListItem>
											))}
										</ImageList>
									</div>
								)}
								<hr />
							</div>
						)}

						<div className='preport-main__generic'>
							<h3>market value</h3>
							<table className='midtable'>
								<thead>
									<tr>
										<th style={{ backgroundColor: 'white' }}></th>
										<th className='grey' style={{ width: '50%' }}>
											Market Value
										</th>
										<th className='grey' style={{ width: '50%', whiteSpace: 'nowrap' }}>
											Suggested List Price
										</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td className='grey' style={{ whiteSpace: 'nowrap' }}>
											{report?.is_property_remodelled ? 'Remodeled' : 'As is'}
										</td>
										<td>$ {formatNumber(report?.current_market_value, 0)}</td>
										<td>$ {formatNumber(report?.current_suggested_list_price, 0)}</td>
									</tr>
								</tbody>
							</table>
							<p className='titler'>Final Summary:</p>
							<p style={{ textAlign: 'justify' }}>{report?.final_summary}</p>
						</div>
						<Grid container spacing={2} marginTop={'3rem'} sx={{ pageBreakInside: 'avoid' }}>
							<Grid item xl={6}>
								<Typography sx={{ fontWeight: 600 }}>Agent's signature:</Typography>
								{report?.signature ? (
									<Box>
										<Tooltip title={mine ? 'Click to edit' : ''}>
											<img
												height='150px'
												style={{
													objectFit: 'contain',
													aspectRatio: '5/2',
													borderRadius: '5px',
													border: '1px dashed black',
													cursor: mine ? 'pointer' : 'default',
												}}
												alt='referrer_sign'
												src={report?.signature}
												onClick={openSign}
												title={mine ? 'Click to edit' : ''}
											/>
										</Tooltip>
									</Box>
								) : (
									mine && (
										<Box
											sx={{ height: '5rem', cursor: 'pointer', width: '100%', borderRadius: '10px', border: '1px dashed grey' }}
											onClick={openSign}
										>
											<Typography variant='body1' sx={{ textAlign: 'center', marginTop: '1rem' }}>
												Click to add signature
											</Typography>
										</Box>
									)
								)}
							</Grid>
							<Grid item xl={6} display='flex' flexDirection='column' justifyContent='space-between'>
								<Typography sx={{ fontWeight: 600 }}>
									Date: {report?.date_signed ? new Date(report?.date_signed).toLocaleDateString('en-US') : ''}
								</Typography>
							</Grid>
						</Grid>
						<hr />
						<h6>BPO Report Disclaimer</h6>
						<p style={{ fontSize: '10px' }}>
							BPO Homes Inc Reports are created by 3rd party Licensed Real Estate professionals. BPO's and are representative of (Broker Price
							Opinions). <br />
							BPO's are Real Estate strictly professional opinions. Realtors and or Brokers use and are not limited to use Real Estate Data,Electronic
							Real Estate Data, Market Analysis, Neighborhood Analysis, Market Insight, Personal Intuitions, and Non Personal Intuitions to provide an
							opinion of value of Real Estate property and the desirability of subject property within a particular Real Estate market at a particular
							time.
							<br />
							BPO Reports are meant to assist consumers, Buyers, Sellers to make sound decisions on home acquisitions and or for the resale of
							property.
							<br />
							BPO's & BPO Reports in the Real Estate industry have been primarily used by Lenders or Banks in the Mortgage industry. There may be
							different Real Estate standards for the creation of such BPO Reports. BPO Homes Inc has its own standard form for delivery of BPO
							Reports. This form could change by BPO Homes Inc at any given time. BPO Homes does not follow any government guidelines for BPO Report
							creation.
							<br />
							BPO Homes Inc has developed a proprietary platform to assist Realtors and or Brokers with Real Estate Data in order to create BPO
							Reports but in no way does BPO Homes Inc or BPOHomes.com provide Realtors and or Brokers with an opinion of value. Realtors and or
							Brokers opinions of value are strictly provided only by Realtors and Brokers.
							<br />
							All Data provided by BPO Homes Inc to assist Realtors and Brokers for the creation of BPO Homes Inc Reports may or may not be deemed
							accurate. Data provided by BPO Homes Inc or BPOHomes.com to Realtors and Brokers is provided from 3rd party resources.
							<br />
							BPO Homes Reports are not intended to be the sole reason why a consumer or Buyer makes a home purchase or Seller makes the consideration
							or final decision to sell their property. Buyers, Sellers and or consumers are fully responsible to make the final ultimate decision to
							Buy or Sell Real Estate.
							<br />
							Electronic signatures acquired by users for policies, services, subscriptions and any other applicable documentation signed by the user
							for BPO Homes Inc the documents and all terms of use are fully valid and enforceable in this & other disclaimers or agreements.BPO Homes
							Inc, BPOHomes.com, Realtors,and Brokers are not to be held liable by Buyers, Sellers or Consumers for creation of BPO Reports, BPO
							Report Data, BPO Report Content, BPO Report Values and agree or have agreed to all terms of this BPO Reports Disclaimer.
							<br />
							<br />
							The suggested market value & suggested list price indicated by such Agent who provided report is only an opinion of value at the date
							and time of the BPO Report and BPO Homes suggest you look at other valuations that may be provided in comparison suchas but not limited
							to CMA's, Appraisals, and others if necessary prior to making any Real Estate decisions.
						</p>
					</main>
				</div>
			</div>
			<div className='buttons_end'>
				{mine && (
					<Button
						style={{
							width: 'fit-content',
							fontSize: '16px',
							padding: '10px',
							color: 'var(--primary-color)',
							backgroundColor: 'white',
							display: 'block',
							textTransform: 'uppercase',
						}}
						onClick={sendToClient}
						text='send to client'
					/>
				)}
				<Button
					style={{
						width: '160px',
						fontSize: '16px',
						padding: '10px',
						color: 'white',
						backgroundColor: 'var(--primary-color)',
						display: 'block',

						textTransform: 'uppercase',
					}}
					onClick={handlePrint}
					text='download'
				/>
			</div>
		</>
	);
}
