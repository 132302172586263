import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import {useNavigate} from 'react-router-dom'
import useCompleteReport from '../../../pages/DealAnalyzer/utils/hooks/useCompleteReport'
import { ClipLoader } from 'react-spinners'
import useCreateAlert from '../hooks/useCreateAlert'

const useStyles = ()=>{
    return{
        button:{
            padding:"0.937rem 1.875rem",
            textTransform:"none"
        },
        exitButton:{
            padding:"0.937rem 3rem",
            textTransform:"none" 
        }
    }
}

function ProformaPageActions({previous,print,buttonProps}) {
    const {completeReport,error,loading} = useCompleteReport()
    const {createAlert} = useCreateAlert()
    const navigate = useNavigate()
    const styles=useStyles()


    const handleCompleteReport = async()=>{
        await completeReport()
        if(error){
            createAlert('An error occured',"error")
        }else{
            navigate('/dealanalyzer-reports/completed-reports')
        }

    }
  return (
    <Box sx={{display:"flex",justifyContent:"space-between", margin:"2.5rem 5rem"}}>
        {buttonProps !== "completed" &&
        <Box>
            <Button variant='contained' sx={{marginRight:"1.875rem",...styles.button}} onClick={handleCompleteReport}>
                {!loading && <Typography variant = 'tableRow' >Complete</Typography>} 
                {loading &&  
                    <Box sx={{mx:4}}>
                        <ClipLoader color={"#fff"} loading={true} size={16} />
                    </Box>
                }
            </Button>
            <Button variant='outlined' sx={styles.button} onClick = {previous}><Typography variant = 'tableRow'>Previous</Typography> </Button>
        </Box>}
        {buttonProps === 'completed' &&
        <Box>
            <Button variant='contained' sx={{marginRight:"1.875rem",...styles.exitButton}} onClick = {previous}>
                {<Typography variant = 'tableRow' >Exit</Typography>} 
            </Button>
            {/* <Button variant='outlined' sx={styles.button} onClick = {previous}><Typography variant = 'tableRow'>Previous</Typography> </Button> */}
        </Box>}
        <Box>
            <Button variant='contained' sx={styles.button}><Typography variant = 'tableRow' onClick = {print}>Download Report</Typography></Button>

        </Box>

    </Box>
  )
}

export default ProformaPageActions