import React, { useEffect }  from 'react'
import {useTheme } from '@mui/material/styles'
import { Box, FormControl, TextField, } from '@mui/material'
import useProformaData from '../hooks/dealReporthooks/useProformaData';



const useStyles = (theme)=>({
  root:{
    margin:"1rem"
  }
})


function SummaryText({data, handleEvents}) {
    const theme = useTheme()
    const styles = useStyles(theme)
    const handler = (e)=>{
      handleEvents({[e.target.name]:e.target.value})
    }
    const{ fetchResult} = useProformaData()


useEffect(()=>{
  fetchResult()

        // NOTE: Run effect once on component mount, please
        // recheck dependencies if effect is updated.
        // eslint-disable-next-line react-hooks/exhaustive-deps
},[])
  return (
    <Box sx={styles.root}>
      <FormControl sx={{width:"100%",height:"100%",fontSize:"1rem !important", border:"none"}}>
      <TextField multiline rows={4}
      name = 'text'
      type='textarea'
      value = {data?.text}
      sx={{border:"none"}}
      placeholder="Share your thoughts on this investment..."
      onChange ={handler}
      // minRows={20}
      />
      </FormControl>

    </Box>

  )
}

export default SummaryText