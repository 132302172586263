import { Box, Divider } from '@mui/material'
import { grey } from '@mui/material/colors'
import { makeStyles } from '@mui/styles'
import React, { useState } from 'react'
import { toast } from 'react-toastify'
import BottomActions from '../helpers/BottomActions'
import FormInputItem from '../helpers/FormInputItem'
import RadioInputItem from '../helpers/RadioInputItem'

const useStyles = makeStyles(theme => ({
    label: {
        fontSize: '.9rem',
        color: grey[600],
        marginBottom: '.5rem'
    },
    btn: {
        textTransform: 'none',
        borderRadius: '8px'
    }
}))


const StageThree = ({ data, dispatch, exit, saveForm, load }) => {
    const classes = useStyles()
    const [sameAs, setSameAs] = useState('')

    const purchase = [
        { type: 'number', label: 'Purchase Price', action: 'PRICE', dataName: 'purchase', dataKey: 'price' },
        { type: 'date', label: 'Close of Escrow Date', action: 'ESCROW_DATE', dataName: 'purchase', dataKey: 'escrow_date' },
        { type: 'number', label: 'Commission Rate - Purchase Price %', action: 'COMMISSION', dataName: 'purchase', dataKey: 'commission' },
        { type: 'number', label: 'Total Gross Commission', action: 'TOTAL_GROSS', dataName: 'purchase', dataKey: 'total_gross' },
    ]

    const escrow = [
        { type: 'text', label: 'Company Name', action: 'ESC_NAME', dataName: 'escrow_info', dataKey: 'name' },
        { type: 'tel', label: 'Phone Number', action: 'ESC_PHONE', dataName: 'escrow_info', dataKey: 'phone' },
        { type: 'text', label: 'Address', action: 'ESC_ADDRESS', dataName: 'escrow_info', dataKey: 'address' },
        { type: 'text', label: 'City', action: 'ESC_CITY', dataName: 'escrow_info', dataKey: 'city' },
        { type: 'text', label: 'Officer First Name', action: 'ESC_OFFICER_FNAME', dataName: 'escrow_info', dataKey: 'officer_fname' },
        { type: 'text', label: 'Officer Last Name', action: 'ESC_OFFICER_LNAME', dataName: 'escrow_info', dataKey: 'officer_lname' },
        { type: 'tel', label: 'Officer Phone', action: 'ESC_OFFICER_PHONE', dataName: 'escrow_info', dataKey: 'officer_phone' },
        { type: 'email', label: 'Officer Email', action: 'ESC_OFFICER_EMAIL', dataName: 'escrow_info', dataKey: 'officer_email' },
    ]

    const company = [
        { type: 'text', label: 'Company Name', action: 'COMPANY_NAME', dataName: 'company_info', dataKey: 'name' },
        { type: 'tel', label: 'Phone Number', action: 'COMPANY_PHONE', dataName: 'company_info', dataKey: 'phone' },
        { type: 'text', label: 'Address', action: 'COMPANY_ADDRESS', dataName: 'company_info', dataKey: 'address' },
        { type: 'text', label: 'City', action: 'COMPANY_CITY', dataName: 'company_info', dataKey: 'city' },
        { type: 'text', label: 'Officer First Name', action: 'COMPANY_OFFICER_FNAME', dataName: 'company_info', dataKey: 'officer_fname' },
        { type: 'text', label: 'Officer Last Name', action: 'COMPANY_OFFICER_LNAME', dataName: 'company_info', dataKey: 'officer_lname' },
        { type: 'tel', label: 'Officer Phone', action: 'COMPANY_OFFICER_PHONE', dataName: 'company_info', dataKey: 'officer_phone' },
        { type: 'email', label: 'Officer Email', action: 'COMPANY_OFFICER_EMAIL', dataName: 'company_info', dataKey: 'officer_email' },
    ]
    const buyer = [
        { type: 'text', label: 'Buyer First Name', action: 'BUYER_FNAME', dataName: 'buyer', dataKey: 'first_name' },
        { type: 'text', label: 'Buyer Last Name', action: 'BUYER_LNAME', dataName: 'buyer', dataKey: 'last_name' },
    ]
    const seller = [
        { type: 'text', label: 'Seller First Name', action: 'SELLER_FNAME', dataName: 'seller', dataKey: 'first_name' },
        { type: 'text', label: 'Seller Last Name', action: 'SELLER_LNAME', dataName: 'seller', dataKey: 'last_name' },
    ]

    const validateForm = (val) => {
        if (data?.offerAccepted === ''){ toast.error('Please select for offer accepted', { theme: 'colored' }); return }
        if (data?.purchase?.price === 0){ toast.error('Please provide purchase price', { theme: 'colored' }); return }
        if (data?.purchase?.escrow_date === ''){ toast.error('Please provide Escrow Close Date', { theme: 'colored' }); return }
        if (data?.purchase?.commission === 0){ toast.error('Please provide Commission', { theme: 'colored' }); return }
        if (data?.purchase?.total_gross === 0){ toast.error('Please provide Total Gross Commission', { theme: 'colored' }); return }
        if (data?.dual_agent === ''){ toast.error('Please select for Dual Agents', { theme: 'colored' }); return }
        if (data?.escrow_info?.name === ''){ toast.error('Please provide Escrow company name', { theme: 'colored' }); return }
        if (data?.escrow_info?.phone === ''){ toast.error('Please provide Escrow company phone number', { theme: 'colored' }); return }
        if (data?.escrow_info?.address === ''){ toast.error('Please provide Escrow company address', { theme: 'colored' }); return }
        if (data?.escrow_info?.city === ''){ toast.error('Please provide Escrow company city', { theme: 'colored' }); return }
        if (data?.escrow_info?.officer_fname === ''){ toast.error('Please provide Escrow officer firstname', { theme: 'colored' }); return }
        if (data?.escrow_info?.officer_lname === ''){ toast.error('Please provide Escrow officer lastname', { theme: 'colored' }); return }
        if (data?.escrow_info?.officer_phone === ''){ toast.error('Please provide Escrow officer phone', { theme: 'colored' }); return }
        if (data?.escrow_info?.officer_email === '' || !data?.escrow_info?.officer_email?.includes('@')){ toast.error('Please provide Escrow officer email', { theme: 'colored' }); return }
        if (data?.company_info?.name === ''){ toast.error('Please provide company name', { theme: 'colored' }); return }
        if (data?.company_info?.phone === ''){ toast.error('Please provide company phone number', { theme: 'colored' }); return }
        if (data?.company_info?.address === ''){ toast.error('Please provide company address', { theme: 'colored' }); return }
        if (data?.company_info?.city === ''){ toast.error('Please provide company city', { theme: 'colored' }); return }
        if (data?.company_info?.officer_fname === ''){ toast.error('Please provide company officer firstname', { theme: 'colored' }); return }
        if (data?.company_info?.officer_lname === ''){ toast.error('Please provide company officer lastname', { theme: 'colored' }); return }
        if (data?.company_info?.officer_phone === ''){ toast.error('Please provide company officer phone', { theme: 'colored' }); return }
        if (data?.company_info?.officer_email === '' || !data?.company_info?.officer_email?.includes('@')){ toast.error('Please provide company officer email', { theme: 'colored' }); return }
        if (data?.buyer?.first_name === ''){ toast.error('Please provide buyer first name', { theme: 'colored' }); return }
        if (data?.buyer?.last_name === ''){ toast.error('Please provide buyer last name', { theme: 'colored' }); return }
        if (data?.seller?.first_name === ''){ toast.error('Please provide seller first name', { theme: 'colored' }); return }
        if (data?.seller?.last_name === ''){ toast.error('Please provide buyer last name', { theme: 'colored' }); return }
        if (data?.contingency === ''){ toast.error('Please select a contingency', { theme: 'colored' }); return }
        else{
            val === 'continue' ? saveForm('stage-three') : exit('stage-three')
        }
    }


    const onFormSubmit = (val) => {
        validateForm(val)
    }

    return (
        <Box>
            <RadioInputItem
                formData={['Yes', 'No']}
                onChange={(val) => {
                    dispatch({ type: 'OFFER_ACCEPTED', payload: val })
                    if(val === 'no') dispatch({type: 'RESET'})
                }}
                title={'Has the offer been accepted?'}
                value={data?.offerAccepted}
            />
            <Divider sx={{ mt: 2, mb: 3, bgcolor: '#ededed' }} />
            {
                data?.offerAccepted === 'yes' && 
                <>
                    <FormInputItem required={true}
                        title={'Purchase'}
                        formData={purchase}
                        formDispatch={dispatch}
                        classes={classes}
                        data={data}

                    />
                    <Divider sx={{ mt: 2, mb: 3, bgcolor: '#ededed' }} />
                    <RadioInputItem
                        formData={['Yes', 'No']}
                        onChange={(val) => dispatch({ type: 'DUAL_AGENT', payload: val })}
                        title={'Dual Agents?'}
                        value={data?.dual_agent}

                    />
                    <Divider sx={{ mt: 2, mb: 3, bgcolor: '#ededed' }} />
                    <FormInputItem required={true}
                        title={'Escrow Information'}
                        formData={escrow}
                        formDispatch={dispatch}
                        classes={classes}
                        data={data}

                    />
                    <Divider sx={{ mt: 2, mb: 3, bgcolor: '#ededed' }} />
                    <RadioInputItem required={true}
                        formData={['Yes', 'No']}
                        onChange={(val) => { setSameAs(val); dispatch({ type: val === 'yes' ? 'UPDATE_COMPANY_WITH_ESCROW' : 'RESET_COMPANY' }) }}
                        title={'Is the Escrow Company same as the Title Company?'}
                        value={sameAs}

                    />
                    <Divider sx={{ mt: 2, mb: 3, bgcolor: '#ededed' }} />
                    <FormInputItem required={true}
                        title={'Title Company Information'}
                        formData={company}
                        formDispatch={dispatch}
                        classes={classes}
                        data={data}
                        disable={sameAs === 'yes' || data?.disableForm}
                    />
                    <Divider sx={{ mt: 2, mb: 3, bgcolor: '#ededed' }} />
                    <FormInputItem required={true}
                        title={'Buyer Information'}
                        formData={buyer}
                        formDispatch={dispatch}
                        classes={classes}
                        data={data}

                    />
                    <Divider sx={{ mt: 2, mb: 3, bgcolor: '#ededed' }} />
                    <FormInputItem required={true}
                        title={'Seller Information'}
                        formData={seller}
                        formDispatch={dispatch}
                        classes={classes}
                        data={data}

                    />
                    <Divider sx={{ mt: 2, mb: 3, bgcolor: '#ededed' }} />
                    <RadioInputItem
                        formData={['Contract Pending & Contingent', 'Contract Pending Non Contingent']}
                        onChange={(val) => dispatch({ type: 'CONTINGENCY', payload: val })}
                        action={'IS_OFFER_ACCEPTED'}
                        title={'Contingency'}
                        value={data?.contingency}
                    />
                    <Divider sx={{ mt: 2, mb: 3, bgcolor: '#ededed' }} />
                </>
            }
            <BottomActions
                classes={classes}
                load={load}
                onSubmit={(val) => onFormSubmit(val)}
                resetDispatch={() => { dispatch({ type: 'RESET' }); setSameAs('') }}
                buttons={['Clear Entries', 'Save & Exit', 'Save & Continue']}
            />
        </Box>
    )
}

export default StageThree