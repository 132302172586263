import { useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import base from "../../../../apis/index";
import * as actionCreators from "../../../../actions/dealAnalyzer/index";
import {
  listingSource,
  propertyType,
} from "../../utils/numericToStringConvert";
import { getDealAnalyzerBaseUrlWithCode } from "../../utils/getDealAnalyzerBaseUrlWithCode";
import { getPrimaryConventional } from "./ProformaHooksSpecialUtils/conventionalLoanDealFinance";
import {
  getPrimaryMortgage,
  getSecondaryMortgage,
} from "./ProformaHooksSpecialUtils/mortgageLoanDealFinance";
import { getSecondaryConventional } from "./ProformaHooksSpecialUtils/conventionalLoanDealFinance";
import { getRestOfDealFinances } from "./ProformaHooksSpecialUtils/getRestOfDealFinances";
import { investorProfitCalcs } from "../../localCalcutions/formulas/InvestorProfit";
import { isNaNCheckerRounded } from "../../localCalcutions/utils/genericFunctions";

//this function updates the entire formData
//so new calculations will fill this form instead of the data from the api

function useProformaData(params) {
  const analyzerData = useSelector((state) => state?.straightAestheticRemodel);
  const resultData = analyzerData?.dealAnalyzerResults;
  const typeId = analyzerData?.dealAnalyzerReportId?.analyzerTypeId;
  const url = getDealAnalyzerBaseUrlWithCode(typeId);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const fetchResult = async (_level) => {
    let reportId = analyzerData?.dealAnalyzerReportId?.id;
    setLoading(true);
    try {
      const response = await base.get(`/${url}_calculations`, {
        params: { id: reportId },
      });
      const responseData = response?.data?.data;
      //  console.log(responseData)
      //uses the response data to update the dealAnalyzer
      dispatch(actionCreators.updateDealAnalyzerResults(responseData));
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  const getAllData = async ({ type, id }) => {
    let reportId = analyzerData?.dealAnalyzerReportId?.id || id;
    const typeURL = url || getDealAnalyzerBaseUrlWithCode(type);
    setLoading(true);
    try {
      const response = await base.get(`/${typeURL}`, {
        params: { id: reportId },
      });
      return { data: response?.data };
    } catch (err) {
      return { error: err };
    } finally {
      setLoading(false);
    }
  };

  // fetch all results
  const getAllLocalCalculations = useMemo(
    () =>
      investorProfitCalcs({
        remodelCosts: analyzerData.remodelCosts,
        financeOptions: analyzerData.financeOptions,
        resultData,
        dealId: analyzerData.dealAnalyzerReportId.analyzerTypeId,
        propertyPermitAndFees: analyzerData?.propertyPermitAndFees,
        taxes: analyzerData.taxes,
        rentalIncome: analyzerData.rentalIncome,
        propertyManagement: analyzerData.propertyManagement,
        otherCosts: analyzerData?.otherCosts,
        carryingMonths: analyzerData?.carryingMonths,
        estimatedResaleValue: analyzerData?.estimatedResaleValue,
        dealAnalyzerResults: analyzerData?.dealAnalyzerResults,
        closingCosts: analyzerData?.closingCosts,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      analyzerData.remodelCosts,
      analyzerData.financeOptions,
      analyzerData?.propertyPermitAndFees,
      analyzerData.taxes,
      analyzerData.rentalIncome,
      analyzerData.propertyManagement,
      analyzerData?.otherCosts,
      analyzerData?.carryingMonths,
      analyzerData?.estimatedResaleValue,
      analyzerData?.closingCosts,
    ]
  );

  // destructure data
  const {
    closingCosts,
    otherCosts,
    carryingMonths,
    estimatedResaleValue,
    rentalIncome: rIncome,
  } = analyzerData || {};

  // other cost data
  const { miscellaneous, utilities } = otherCosts || {};

  const { carrying_cost_owning_months } = carryingMonths || {};

  const {
    commission_on_purchase,
    commission_on_purchase_dollar_or_percent,
    commission_on_resale,
    commission_on_resale_dollar_or_percent,
    title_and_escrow_fees,
    title_and_escrow_fees_dollar_or_percent,
    title_and_escrow_fees_resale,
    title_and_escrow_fees_resale_dollar_or_percent,
  } = closingCosts || {};

  //rental income
  const { additional_units_rental_price, unitsRentalIncome, unitsRentalLoss } =
    rIncome || {};

  const { annual_property_appreciation } = estimatedResaleValue || {};

  const serverData = analyzerData?.dealAnalyzerResults;
  const serverComps = serverData?.zillowComps;
  const savedCustomComps =
    analyzerData?.estimatedResaleValue?.zillowComps || serverComps || [];
  const serverProfit = serverData?.returnedInvestorsProfit;
  const { calculatedAverageZillowComps, compsToApply } =
    analyzerData?.estimatedResaleValue;
  const lenghtOfZillowComps =
    +compsToApply === 1 ? savedCustomComps?.length : 0;
  const averageZillowTotalPrice =
    +compsToApply === 1 ? calculatedAverageZillowComps?.averageTotalPrice : 0;
  const averageZillowGrossLivingArea =
    +compsToApply === 1
      ? calculatedAverageZillowComps?.averageGrossLivingArea
      : 0;
  const averageZillowLotSize =
    +compsToApply === 1 ? calculatedAverageZillowComps?.averageLotSize : 0;
  const averageZillowAveragePricePerSquareFootage =
    +compsToApply === 1
      ? calculatedAverageZillowComps?.averageAveragePricePerSquareFootage
      : 0;

  const otherUnitsPrice = analyzerData?.remodelCosts?.additional_units_price;

  useEffect(() => {
    !params?.noAutoLoad && fetchResult();
    // NOTE: Run effect once on component mount, please
    // recheck dependencies if effect is updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const user = resultData?.users || resultData?.user;
  const otherUnits = analyzerData?.propertyInformation?.additional_units;
  const adu = analyzerData.propertyInformation?.showADU
    ? {
      item: "ADU",
      value: analyzerData.propertyInformation?.adu || "N/A",
      render: "sqft",
    }
    : {};

  const aduAmount = analyzerData.propertyInformation?.showADU
    ? {
      item: "ADU",
      value: analyzerData.remodelCosts?.adu_price || "N/A",
      render: "dollar",
    }
    : {};

  // basement
  const basement = analyzerData.propertyInformation?.showBasement
    ? {
      item: "Basement",
      value: analyzerData.propertyInformation?.basement_psqf || "N/A",
      render: "sqft",
    }
    : {};

  const basement_price = analyzerData.propertyInformation?.showBasement
    ? {
      item: "Basement",
      value: analyzerData.remodelCosts?.basement_price || "N/A",
      render: "sqft",
    }
    : {};

  const otherListingSource = analyzerData?.propertyInformation
    ?.other_listing_source
    ? {
      item: "Specified Source",
      value: analyzerData?.propertyInformation?.other_listing_source,
      render: "simple",
    }
    : {};
  const otherPropertyType = analyzerData?.propertyInformation
    ?.other_property_type
    ? {
      item: "Specified Type",
      value: analyzerData?.propertyInformation?.other_property_type,
      render: "simple",
    }
    : {};

  const listingArray = [
    {
      item: "Listing Price",
      value: analyzerData.financeOptions?.primary_purchase_price || "N/A",
      render: "dollar",
    },
    {
      item: "Listing Date",
      value: analyzerData.propertyInformation?.listing_date
        ? new Date(
          analyzerData.propertyInformation?.listing_date
        ).toLocaleDateString()
        : "N/A",
      render: "simple",
    },
    {
      item: "Source",
      value:
        listingSource[+analyzerData.propertyInformation?.listing_source] ||
        "N/A",
      render: "simple",
    },
    otherListingSource,
  ];

  const propertyInformation = [
    {
      item: "Property Type",
      value:
        propertyType[+analyzerData.propertyInformation?.property_type] || "N/A",
      render: "simple",
    },
    otherPropertyType,
    {
      item: "Bedrooms",
      value: analyzerData.propertyInformation?.bedrooms || "N/A",
      render: "simple",
    },
    {
      item: "Bathrooms",
      value: analyzerData.propertyInformation?.bathrooms || "N/A",
      render: "simple",
    },
    {
      item: "Pre-existing Livable Square Footage",
      value:
        analyzerData.propertyInformation?.pre_existing_livable_sqft || "N/A",
      render: "sqft",
    },
    ...(otherUnits?.length > 0
      ? otherUnits?.map((item, index) => ({
        item: `Unit ${index + 2}`,
        value: item.value || "N/A",
        render: "sqft",
      }))
      : []),

    {
      item: analyzerData?.propertyInformation?.carpot_or_garage || "Garage",
      value: analyzerData.propertyInformation?.garage || "N/A",
      render: "simple",
    },
    {
      item: "Lot",
      value: analyzerData.propertyInformation?.lot_size || "N/A",
      render: "simple",
    },
    {
      item: "Year Built",
      value:
        new Date(
          analyzerData.propertyInformation?.year_of_construction
        ).getFullYear() || "N/A",
      render: "simple",
    },
    adu,
    basement,
  ];
  const dealFinanceOption = analyzerData?.financeOptions?.deal_finance_option;

  const dealFinance = !+dealFinanceOption
    ? {
      primaryHomeLoan:
        +analyzerData?.financeOptions?.primary_loan_type === 0 ||
          +analyzerData?.financeOptions?.primary_sub_loan_type === 0
          ? getPrimaryConventional({ resultData, analyzerData })
          : getPrimaryMortgage({ resultData, analyzerData }),
      secondaryRemodelLoan:
        +analyzerData?.financeOptions?.secondary_loan_type === 0 ||
          +analyzerData?.financeOptions?.secondary_sub_loan_type === 0
          ? getSecondaryConventional({ resultData, analyzerData })
          : getSecondaryMortgage({ resultData, analyzerData }),
    }
    : getRestOfDealFinances({ analyzerData, resultData, dealFinanceOption });
  const propertyPermitAndFees = [
    {
      item: "City Permit & Impact Fees",
      value: analyzerData?.propertyPermitAndFees?.city_permit_impact_fees,
      rate:
        analyzerData?.propertyPermitAndFees
          ?.city_permit_impact_fees_dollar_or_percent || 0,
      render: "percent_dollar",
      referenceValue: analyzerData?.financeOptions?.primary_purchase_price,
    },
    {
      item: "Title & Escrow Fees on Purchase",
      value:
        isNaNCheckerRounded(
          getAllLocalCalculations?.titleAndEscrowFeesAmount || 0
        ) || analyzerData?.propertyPermitAndFees?.title_and_escrow_fees,
      render: "dollar",
    },
    {
      item: "Title & Escrow Fees on Resale",
      value:
        (getAllLocalCalculations?.titleAndEscrowFeesResaleAmount || 0)?.toFixed(
          2
        ) || analyzerData?.propertyPermitAndFees?.title_and_escrow_fees,
      render: "dollar",
    },
    {
      item: "Commission on Purchase",
      value:
        isNaNCheckerRounded(
          getAllLocalCalculations?.commisionOnPurchaseAmount || 0
        ) || analyzerData?.propertyPermitAndFees?.title_and_escrow_fees,
      render: "dollar",
    },
    {
      item: "Commission on Resale",
      value:
        isNaNCheckerRounded(
          getAllLocalCalculations?.commissionOnResaleAmount || 0
        ) || analyzerData?.propertyPermitAndFees?.title_and_escrow_fees,
      render: "dollar",
    },
    {
      item: "Architectural Fees",
      value: analyzerData?.propertyPermitAndFees?.architectural_fees,
      render: "dollar",
    },
    {
      item: "Monthly Home Insurance",
      value: analyzerData?.propertyPermitAndFees?.monthly_home_insurance,
      render: "dollar",
    },
    {
      item: "Monthly HOA Dues",
      value: analyzerData?.propertyPermitAndFees?.monthly_hoa_dues,
      render: "dollar",
    },
  ];

  const monthlyTaxPayment = [
    {
      item: "Carrying Months",
      value:
        carrying_cost_owning_months ||
        resultData?.carrying_months?.carrying_months,
      render: "months",
    },
    {
      item: "Yearly State Property Tax",
      value: analyzerData?.taxes?.city_and_county_tax,
      render: "dollar",
    },
    {
      item: "Acquisition/ Purchase Price",
      value:
        getAllLocalCalculations?.primaryPurchasePrice ||
        analyzerData?.financeOptions?.primary_purchase_price,
      render: "dollar",
    },
    {
      item: "Total Monthly Tax Payment",
      value: resultData?.monthly_tax_payment,
      render: "dollar",
    },
  ];
  const remodelCost = {
    general: [
      {
        item: "Estimated Remodel Cost Per SqFt",
        value: analyzerData?.remodelCosts?.est_remodel_cost_psqft,
        render: "dollar",
      },
      {
        item: "Pre-existing Livable Square Footage",
        value: analyzerData?.propertyInformation?.pre_existing_livable_sqft,
        render: "sqft",
      },
      ...(otherUnitsPrice?.length > 0
        ? otherUnitsPrice?.map((item, index) => ({
          item: `Unit ${index + 2}`,
          value: item.value || "N/A",
          render: "dollar",
        }))
        : []),
      aduAmount,
      basement_price,
    ],
    aestheticItems: analyzerData?.remodelCosts?.aesthetic_items
      ? analyzerData?.remodelCosts?.aesthetic_items
        ?.filter((el) => el?.value !== undefined)
        .map((element, index) => {
          return {
            ...element,
            render: "dollar",
            item: `${index + 1}. ${element.item} `,
          };
        })
      : [],
    nonAestheticItems: analyzerData?.remodelCosts?.non_aesthetic_items
      ? analyzerData?.remodelCosts?.non_aesthetic_items
        ?.filter((el) => el?.value !== undefined)
        .map((element, index) => {
          return {
            ...element,
            render: "dollar",
            item: `${index + 1}. ${element.item} `,
          };
        })
      : [],
  };

  const frontendClosingCost = [
    //from calculations
    {
      item: "Lender Points",
      value: analyzerData?.financeOptions?.secondary_lender_points,
      render: "pts",
    },
    {
      item: "Title & Escrow Fees Purchase",
      value: +title_and_escrow_fees,
      render: "percent_dollar",
      referenceValue: getAllLocalCalculations?.primaryPurchasePrice,
      rate: title_and_escrow_fees_dollar_or_percent,
    },
    {
      item: "Commission on Purchase",
      value: +commission_on_purchase,
      render: "percent_dollar",
      referenceValue: isNaNCheckerRounded(
        getAllLocalCalculations?.titleAndEscrowFeesAmount || 0
      ),
      rate: commission_on_purchase_dollar_or_percent,
    },
    {
      item: "Carrying Months",
      value:
        carrying_cost_owning_months ||
        resultData?.carrying_months?.carrying_months,
      render: "months",
    },
    {
      item: "Total Monthly Tax Payment",
      value: resultData?.monthly_tax_payment,
      render: "dollar",
    },
    {
      item: "Est. Frontend Closing Cost",
      value:
        isNaNCheckerRounded(
          getAllLocalCalculations?.commisionOnPurchaseAmount +
          getAllLocalCalculations?.titleAndEscrowFeesAmount
        ) || resultData?.closing_cost?.est_front_end_closing_cost,
      render: "dollar",
    },
  ];

  const backendClosingCost = [
    //from calculations
    {
      item: "Lender Points",
      value: resultData?.total_liquid_capital_required?.lender_point,
      render: "points",
    },
    {
      item: "Title & Escrow Fees on Resale",
      value: +title_and_escrow_fees_resale,
      render: "percent_dollar",
      referenceValue: getAllLocalCalculations?.estimatedResaleAmount,
      rate: title_and_escrow_fees_resale_dollar_or_percent,
    },
    {
      item: "Commission on Purchase",
      value: +commission_on_resale,
      render: "percent_dollar",
      referenceValue: getAllLocalCalculations?.titleAndEscrowFeesResaleAmount,
      rate: commission_on_resale_dollar_or_percent,
    },
    {
      item: "Est. Backend Closing Cost",
      value:
        isNaNCheckerRounded(
          getAllLocalCalculations?.commissionOnResaleAmount +
          getAllLocalCalculations?.titleAndEscrowFeesResaleAmount || 0
        ) || resultData?.closing_cost?.est_back_end_closing_cost,
      render: "dollar",
    },
  ];

  const carryingCost = [
    {
      item: "Carrying Months",
      value:
        carrying_cost_owning_months ||
        resultData?.carrying_months?.carrying_months,
      render: "months",
    },
    {
      item: "Total Loan Monthly Payments",
      value: resultData?.other_expenses?.total_loan_monthly_payment,
      render: "dollar",
    },
    {
      item: "Total Monthly Interest Only Payment",
      value: resultData?.monthly_tax_payment,
      render: "dollar",
    },
    {
      item: "Total Monthly Tax Payment",
      value: resultData?.monthly_tax_payment,
      render: "dollar",
    },
    {
      item: "Other Amounts and Fees",
      value:
        miscellaneous + utilities ||
        resultData?.carrying_months?.other_amounts_and_fees,
      render: "dollar",
    },
    {
      item: "Total Carrying Cost",
      value:
        isNaNCheckerRounded(getAllLocalCalculations?.carryingCostAmount || 0) ||
        resultData?.carrying_months?.total_carrying_cost,
      render: "dollar",
    },
  ];
  //   const carryingCost = [
  //     {
  //         item:"Carrying Months",
  //         value:resultData?.carrying_months?.carrying_months,
  //         render:"months"
  //     },
  //     {
  //         item:"Loan Monthly Payment",
  //         value:"",
  //         render:"dollar"
  //     },
  //     {
  //         item:"Total Monthly Tax Payment",
  //         value:resultData?.monthly_tax_payment,
  //         render:"dollar"
  //     },
  //     {
  //         item:"Annual Maintenance Fees",
  //         value:resultData?.property_management?.annual_maintenance_fee,
  //         render:"dollar"
  //     },
  //     {
  //         item:"Total Carring Cost",
  //         value:resultData?.carrying_months?.total_carrying_cost,
  //         render:"dollar"
  //     }

  //   ]
  //   const operationalExpenses =   [
  //     {
  //         item:"Total Remodel Cost",
  //         value:resultData?.other_expenses?.total_remodel_cost,
  //         render:"dollar"
  //     },
  //     {
  //         item:"Total Tax Payment ",
  //         value:resultData?.other_expenses?.total_tax_payment,
  //         render:"dollar",
  //         rate:0
  //     },
  //     {
  //         item:"Total Closing Cost ",
  //         value:resultData?.other_expenses?.total_closing_cost,
  //         render:"dollar"
  //     },
  //     {
  //         item:"Total Insurance Payment",
  //         value:resultData?.other_expenses?.total_insurance_costs,
  //         render:"dollar"
  //     },
  //     {
  //         item:"Total Property Management Fees",
  //         value:resultData?.other_expenses?.total_property_management_fees,
  //         render:"dollar"
  //     },
  //     {
  //         item:"Total Property Permit & Impact Fees",
  //         value:resultData?.other_expenses?.total_property_permit_impact_and_fees,
  //         render:"dollar"
  //     },
  //     {
  //         item:"Total Vacancy Loss Amount",
  //         value:resultData?.other_expenses?.total_vacancy_factor_loss,
  //         render:"dollar"
  //     },
  //   ]
  const operationalExpenses = [
    {
      item: "Total Remodel Cost",
      value: resultData?.other_expenses?.total_remodel_cost,
      render: "dollar",
    },
    {
      item: "Total Tax Payment ",
      value: resultData?.other_expenses?.total_tax_payment,
      render: "dollar",
      rate: 0,
    },
    {
      item: "Total Closing Cost ",
      value: resultData?.other_expenses?.total_closing_cost,
      render: "dollar",
    },
    {
      item: "Total Insurance Payment",
      value: resultData?.other_expenses?.total_insurance_costs,
      render: "dollar",
    },
    {
      item: "Total Property Management Fees",
      value: isNaNCheckerRounded(
        resultData?.other_expenses?.total_property_management_fees || 0
      ),
      render: "dollar",
    },
    {
      item: "Total Property Permit & Impact Fees",
      value: resultData?.other_expenses?.total_property_permit_impact_and_fees,
      render: "dollar",
    },
    // {
    //     item:"Total Vacancy Loss Amount",
    //     value:resultData?.other_expenses?.total_vacancy_factor_loss,
    //     render:"dollar"
    // },
    {
      item: "Total Acquisition Cost ",
      value:
        isNaNCheckerRounded(
          getAllLocalCalculations?.primaryPurchasePrice || 0
        ) || resultData?.other_expenses?.total_acquisition_cost,
      render: "dollar",
    },
    {
      item: "Other Operational Expenses",
      value: resultData?.operational_expenses?.other_operational_expenses,
      render: "dollar",
    },
    {
      item: "Total Expenses ",
      value:
        isNaNCheckerRounded(getAllLocalCalculations?.totalExpenseAmount || 0) ||
        isNaNCheckerRounded(serverProfit?.totalExpenseAmount || 0),
      render: "dollar",
    },
  ];
  const otherExpenses = [
    {
      item: "Total H.O.A Fees",
      value: resultData?.operational_expenses?.other_operational_expenses,
      render: "dollar",
    },

    {
      item: "Total Miscellaneous Expenses",
      value: resultData?.operational_expenses?.total_expenses,
      render: "dollar",
    },
    {
      item: "Total Architectural Design Fees",
      value: resultData?.operational_expenses?.total_expenses,
      render: "dollar",
    },
    {
      item: "Total Cost of Borrowing [Home]",
      value: resultData?.operational_expenses?.total_expenses,
      render: "dollar",
    },
    {
      item: "Total Cost of Borrowing [Remodel]",
      value: resultData?.operational_expenses?.total_expenses,
      render: "dollar",
    },
    {
      item: "Loan Amount Balance Due [Home]",
      value: resultData?.operational_expenses?.total_expenses,
      render: "dollar",
    },
  ];

  //   const otherExpenses =[
  //     {
  //         item:"Total Remodel Cost",
  //         value:resultData?.remodel_cost?.estimated_total_remodel_cost,
  //         render:"dollar"
  //     },
  //     {
  //         item:"Purcase/Acquisition Price",
  //         value:analyzerData?.financeOptions?.primary_purchase_price,
  //         render:"dollar"
  //     },
  //     {
  //         item:"Frontend Closing Cost",
  //         value:resultData?.closing_cost?.est_front_end_closing_cost,
  //         render:"dollar"
  //     },
  //     {
  //         item:"Backend Closing Cost",
  //         value:resultData?.closing_cost?.est_back_end_closing_cost,
  //         render:"dollar"
  //     },
  //     {
  //         item:"Commission",
  //         value:resultData?.closing_cost?.commissions_on_resale,
  //         render:"dollar"
  //     },
  //     {
  //         item:"Total Expenses",
  //         value:resultData?.other_expenses?.total_loan_expenses,
  //         render:"dollar"
  //     }
  //   ]

  function createItem(item, value, render) {
    return value ? { item, value, render } : {};
  }

  const estResaleValue = [
    {
      item: "Annual Assert appreciation",
      value:
        annual_property_appreciation ||
        0 ||
        resultData?.est_resale_value?.asset_appreciation,
      render: "percent",
    },
    createItem(
      "Number of Comparables Selected",
      lenghtOfZillowComps ||
      resultData?.est_resale_value?.number_of_comparables,
      "simple"
    ),
    createItem(
      "Average Square Footage",
      averageZillowGrossLivingArea ||
      resultData?.est_resale_value?.average_square_footage,
      "sqft"
    ),
    createItem(
      "Average Price per Square Footage",
      averageZillowAveragePricePerSquareFootage ||
      resultData?.est_resale_value?.average_price_per_sqft,
      "dollar"
    ),
    createItem(
      "Average Lot Size",
      averageZillowLotSize || resultData?.est_resale_value?.average_lot_size,
      "sqft"
    ),
    {
      item: "Est. Resale Value",
      value:
        averageZillowTotalPrice ||
        (getAllLocalCalculations?.estimatedResaleAmount &&
          getAllLocalCalculations?.estimatedResaleAmount) ||
        resultData?.est_resale_value?.estimated_resale_value,
      render: "dollar",
    },
  ];
  const rentalIncome = [
    {
      item: "Holding Period",
      value: analyzerData?.rentalIncome?.owning_months,
      render: "months",
    },
    {
      item: "Estimated Rental Income Per Month",
      value: analyzerData?.rentalIncome?.est_rental_income_per_month,
      render: "dollar",
    },
    ...(additional_units_rental_price?.length > 0
      ? additional_units_rental_price?.map((item, index) => ({
        item: `Unit ${index + 2}`,
        value: item.value || "N/A",
        render: "dollar",
      }))
      : []),
    {
      item: "Estimated Total Vacancy Loss",
      value:
        resultData?.rental_income?.total_vacancy_factor_loss_factor +
        (unitsRentalLoss || 0),
      render: "dollar",
    },

    {
      item: "Estimated Total Rental Income",
      value: !isNaN(
        +analyzerData?.rentalIncome?.owning_months *
        +analyzerData?.rentalIncome?.est_rental_income_per_month +
        (unitsRentalIncome || 0)
      )
        ? +analyzerData?.rentalIncome?.owning_months *
        +analyzerData?.rentalIncome?.est_rental_income_per_month +
        (unitsRentalIncome || 0)
        : "",
      render: "dollar",
    },
  ];
  const capitalizationRate = [
    {
      item: "Current Property Value",
      value: resultData?.capitalization_rate?.current_property_value,
      render: "dollar",
    },
    {
      item: "Annual Net operating Income (NOI)",
      value: resultData?.capitalization_rate?.annual_net_operating_income,
      render: "dollar",
    },
    {
      item: "Cap Rate",
      value: resultData?.capitalization_rate?.cap_rate,
      render: "dollar",
    },
  ];
  const netProfit = [
    {
      item: "Resale Value",
      value:
        getAllLocalCalculations?.estimatedResaleAmount ||
        resultData?.net_profit?.resale_value,
      render: "dollar",
    },
    {
      item: "Total Rental Income",
      value:
        isNaNCheckerRounded(getAllLocalCalculations?.rentalProfit || 0) ||
        resultData?.net_profit?.total_rental_income,
      render: "dollar",
    },
    {
      item: "Closing Cost Credit",
      value: resultData?.net_profit?.closing_cost_credit || 0,
      render: "dollar",
    },
    {
      item: "Estimated Net Profit",
      value:
        isNaNCheckerRounded(
          getAllLocalCalculations?.localInvestorsProfit || 0
        ) || serverProfit?.estNetProfit,
      render: "dollar",
    },
  ];
  const investorsProfit = {
    general: [
      {
        item: "Annual Assert appreciation",
        value:
          annual_property_appreciation ||
          0 ||
          resultData?.est_resale_value?.asset_appreciation,
        render: "percent",
      },
    ],
    liquidCapital: [
      {
        item: "Estimated Frontend Closing Cost",
        value:
          isNaNCheckerRounded(
            getAllLocalCalculations?.totalLiquidRequired || 0
          ) ||
          resultData?.total_liquid_capital_required?.est_frontend_closing_cost,
        render: "dollar",
      },
      {
        item: "Acquisition Cost",
        value: resultData?.total_liquid_capital_required?.acquisition_cost,
        render: "dollar",
      },
      {
        item: "Lender Point",
        value: resultData?.total_liquid_capital_required?.lender_point,
        render: "points",
      },
      {
        item: "Total Remodel Cost ",
        value:
          isNaNCheckerRounded(
            getAllLocalCalculations?.grandTotalRemodelCost ||
            getAllLocalCalculations?.remodelCostUsed ||
            0
          ) || resultData?.total_liquid_capital_required?.total_remodel_cost,
        render: "dollar",
      },
      // {
      //     item:"Total Down Payment (Home)",
      //     value:resultData?.total_liquid_capital_required?.downpayment_for_home_loan
      //     ,
      //     render:"dollar"
      // },
      // {
      //     item:"Total Down Payment (Remodel)",
      //     value:resultData?.total_liquid_capital_required?.downpayment_for_remodel_loan,
      //     render:"dollar"
      // },
      {
        item: "Total City and Impact Fees",
        value:
          (getAllLocalCalculations?.cityPermitFeesCalculated || 0)?.toFixed(
            2
          ) ||
          resultData?.total_liquid_capital_required?.total_city_and_impact_fees,
        render: "dollar",
      },
      {
        item: "Total Tax Payment",
        value:
          isNaNCheckerRounded(
            (getAllLocalCalculations?.getMonthlyStatePropertyTax *
              getAllLocalCalculations?.months || 0) +
            (getAllLocalCalculations?.getYearlyStatePropertyTax || 0)
          ) || resultData?.total_liquid_capital_required?.total_tax_payment,
        render: "dollar",
      },
      {
        item: "Other Liquid Capital Expenses",
        value:
          resultData?.total_liquid_capital_required
            ?.other_liquid_capital_expenses,
        render: "dollar",
      },
    ],
    profit: {
      header: [
        "Investor",
        "Liquid Capital Contribution $",
        "Profit Share %",
        "ROI %",
        "Annualized ROI",
      ],
      investors: resultData?.returnedInvestorsProfit?.investors_profit || [],
    },
  };

  const summary = "";

  return {
    loading,
    listingArray,
    propertyInformation,
    dealFinance,
    propertyPermitAndFees,
    monthlyTaxPayment,
    remodelCost,
    frontendClosingCost,
    backendClosingCost,
    carryingCost,
    operationalExpenses,
    otherExpenses,
    estResaleValue,
    rentalIncome,
    capitalizationRate,
    netProfit,
    investorsProfit,
    resultData,
    fetchResult,
    analyzerData,
    user,
    summary,
    getAllData,
    getAllLocalCalculations,
  };
}

export default useProformaData;
