import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import EnhancedRadioButtonGroup from "../../atomicComponents/EnhancedRadioButtonGroup";
import useTheme from "@mui/material/styles/useTheme";
import {
  Box,
  Button,
  Divider,
  FormControl,
  InputAdornment,
  OutlinedInput,
  Typography,
} from "@mui/material";
import { BiPlus } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";

import StyledLabel from "../../atomicComponents/StyledLabel";
import PrimaryHomeLoan from "./PrimaryHomeLoan";
import LoanTypeSelector from "./LoanTypeSelector";
import SecondaryRemodelAddition from "./SecondaryRemodelAddition";
import InterestOnlyMortgage from "../../atomicComponents/InterestOnlyMortgage";
import InterestOnlyMortgageResult from "../../atomicComponents/InterestOnlyMortgageResult";
import LoanTypeSecondary from "./LoanTypeSecondary";
import SecondaryCash from "./SecondaryCash";
import LoanAmount from "../../atomicComponents/LoanAmount";
import InterestTermResult from "../../atomicComponents/InterestTermResult";
import { sarLoanAmount } from "../../utils/loanAmount";
import useProformaData from "../../hooks/dealReporthooks/useProformaData";
import useSaveNow from "../../hooks/useSaveNow";
import { makePmiCompulsory } from "../../utils";
import * as actionCreators from "../../../../actions/dealAnalyzer/index";
import {
  interestOnlyMortgageCalculator,
  mortgageLoanCalculator,
  yearsToMonthsTransformer,
} from "../../localCalcutions/utils/genericFunctions";
import commaSeparate from "../../utils/commaSeparate";

const useStyle = (_theme) => {
  return {
    root: {
      padding: "10px",
    },
    row1: {
      display: "flex",
      marginTop: "30px",
    },
    button: {
      textTransform: "none",
      width: "100%",
      margin: "30px 0",
      padding: "15px",
      marginBottom: "2rem",
    },
  };
};

function FinanceOptions({ handleEvents, data }) {
  const financialOptions = useSelector(
    (state) => state?.straightAestheticRemodel.financeOptions
  );
  const theme = useTheme();
  const styles = useStyle(theme);
  //showMortgageResult,
  const [setShowMortgageResult] = useState(false);
  const [mortgagePrimaryResultLoading, setMortgagePrimaryResultLoading] =
    useState(false);
  const [mortgageSecondaryResultLoading, setMortgageSecondaryResultLoading] =
    useState(false);
  const [pmi_value, setPmiValue] = useState(
    financialOptions?.secondaryPmiValue
  );
  const fetchAndUpdateResults = useProformaData({
    noAutoLoad: true,
  })?.fetchResult;
  const { saveNow } = useSaveNow();
  const scrollRef = useRef(null);
  const [applyPadding, setApplyPadding] = useState(false);

  const mortgageData = useSelector(
    (state) =>
      state?.straightAestheticRemodel.dealAnalyzerResults
        ?.secondary_finance_option_summary?.interest_only_mortgage_loan
  );
  const dealAnalyzerResultsData = useSelector(
    (state) => state?.straightAestheticRemodel.dealAnalyzerResults
  );

  const analyzerData = useSelector((state) => state?.straightAestheticRemodel);
  const dealId = analyzerData.dealAnalyzerReportId.analyzerTypeId;

  const showSecondary = !!analyzerData?.financeOptions?.showSecondary;
  const { resultData } = useProformaData();

  //======================================== primary remodel calcs goes here ==================================================//
  const localPrimaryLoanAmount = financialOptions.localGeneralLoanAmount;
  const localPrimaryRate = financialOptions.primary_interest_rate;
  const localPrimaryTermsOfLoan = financialOptions.primary_term_of_loan;
  const localTotalRemodelCost =
    analyzerData?.remodelCosts?.grandTotalRemodelCost ||
    financialOptions.totalRemodelCost;
  const primary_include_pmi = financialOptions.primary_include_pmi;

  const localPrimaryAmount = useMemo(() => {
    const time = localPrimaryTermsOfLoan * 12;
    const rate = localPrimaryRate / 100 / 12;
    const amount = mortgageLoanCalculator(localPrimaryLoanAmount, rate, time);

    return amount;
    // NOTE: Run effect once on component mount, please
    // recheck dependencies if effect is updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localPrimaryLoanAmount, localPrimaryRate, localPrimaryTermsOfLoan]);

  useEffect(() => {
    const localMonthlyAmount = isNaN(localPrimaryAmount)
      ? 0
      : localPrimaryAmount;
    dispatch(
      actionCreators.updateFinanceOptions({
        localMonthlyAmount: localMonthlyAmount,
      })
    );
    // NOTE: Run effect once on component mount, please
    // recheck dependencies if effect is updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localPrimaryAmount]);
  //======================================================= xxxxxxxxxxxxxxxxxxxxx =============================================//

  const localRemodelCostChecker =
    +analyzerData?.remodelCosts?.remodel_cost_option;
  const remodelDownToStudsCost =
    analyzerData?.financeOptions?.totalRemodelCostDownToStuds;
  const totalAmountPaidRemodelDownToStudsAddition =
    analyzerData?.financeOptions?.totalAmountPaidRemodelDownToStudsAddition;

  const returnDynamicRemodelCost =
    dealId === 1
      ? analyzerData?.remodelCosts?.grandTotalRemodelCost ||
        analyzerData?.remodelCosts.remodelCost
      : localTotalRemodelCost;
  const localRemodelCost =
    localRemodelCostChecker && localRemodelCostChecker === 1
      ? null
      : returnDynamicRemodelCost ||
        remodelDownToStudsCost ||
        totalAmountPaidRemodelDownToStudsAddition;

  const remodelCost =
    localRemodelCost || resultData?.remodel_cost?.estimated_total_remodel_cost
      ? localRemodelCost ||
        resultData?.remodel_cost?.estimated_total_remodel_cost
      : resultData?.remodel_cost
          ?.est_remodel_cost_down__to_studs_plus_additional_sqft;
  const secondaryMonthlyPayment =
    dealAnalyzerResultsData?.secondary_finance_option_summary
      ?.total_monthly_payment;

  //this is the value of the secondary loanAmount
  const localSecondaryLoanAmount = sarLoanAmount({
    purchasePrice: remodelCost,
    pmi_value,
    lenderPoints: data?.secondary_lender_points,
    wholesaleFee: 0,
    pmi: data?.secondary_include_pmi,
    downPayment: data?.secondary_down_payment,
    downPaymentRate: data?.secondary_dollar_or_percent,
    wholesaleFeeOption: data?.secondary_wholesale_fee_options,
    lenderpointOption: data?.secondary_lender_points_options,
  });

  //dispatches the secondaryLoanAmount
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      actionCreators.updateFinanceOptions({ universalRemodelCost: remodelCost })
    );
    // recheck dependencies if effect is updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remodelCost]);

  //dispatch secondary Loan for local calculations
  const localSecondaryLoanAmountDispatcher = useCallback(
    () => {
      const amount = {
        localSecondaryLoanAmount: localSecondaryLoanAmount?.effectiveLoan,
      };
      dispatch(actionCreators.updateFinanceOptions(amount));
    },

    // recheck dependencies if effect is updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [localSecondaryLoanAmount?.effectiveLoan]
  );

  //effect to dispatch the value
  useEffect(() => {
    // recheck dependencies if effect is updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    localSecondaryLoanAmountDispatcher();
  }, [localSecondaryLoanAmountDispatcher]);

  const handleDealFinanceOption = (value) => {
    handleEvents({ deal_finance_option: value });
  };

  const handleShowSecondary = () => {
    handleEvents({ showSecondary: true });
    setTimeout(() => {
      scrollRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }, 0);
  };
  const checkDealFinanceOption = () => {
    return data?.deal_finance_option ? data.deal_finance_option : 0;
  };

  const loanTypeIsConventional =
    +data?.secondary_loan_type === 0 || !data?.secondary_loan_type;
  const loanTypeIsPrivate = +data?.secondary_loan_type === 1;
  const loanTypeIsSeller = +data?.secondary_loan_type === 2;
  const isSellerLoanTypeAndConventional =
    loanTypeIsSeller &&
    (+data?.secondary_sub_loan_type === 0 || !data?.secondary_sub_loan_type);
  const isSellerLoanTypeAndMortgage =
    loanTypeIsSeller && +data?.secondary_sub_loan_type === 1;
  const isPrivateLoanTypeAndConventional =
    loanTypeIsPrivate &&
    (+data?.secondary_sub_loan_type === 0 || !data?.secondary_sub_loan_type);
  const isPrivateLoanTypeAndMortgage =
    loanTypeIsPrivate && +data?.secondary_sub_loan_type === 1;
  const secondaryCash = [1, 3].includes(+data?.deal_finance_option);

  const showSecondaryToggleButton = () => {
    const dealOption = checkDealFinanceOption();
    return +dealOption === 0;
  };
  const showSecondaryConventionalLoan = () => {
    return (
      loanTypeIsConventional ||
      isSellerLoanTypeAndConventional ||
      isPrivateLoanTypeAndConventional
    );
  };
  const showSecondaryMortgageLoan = () => {
    return (
      (isPrivateLoanTypeAndMortgage || isSellerLoanTypeAndMortgage) &&
      !loanTypeIsConventional
    );
  };

  const fetchResult = async (level) => {
    level === "mortgage"
      ? setMortgageSecondaryResultLoading(true)
      : setMortgagePrimaryResultLoading(true);

    try {
      await saveNow({ finance_options: analyzerData?.financeOptions });
      await fetchAndUpdateResults();
    } catch (err) {
    } finally {
      if (level === "mortgage") {
        setShowMortgageResult(true);
      }
      setMortgageSecondaryResultLoading(false);
      setMortgagePrimaryResultLoading(false);
    }
  };
  const handleShowmortgageResult = () => {
    fetchResult("mortgage");
  };

  useEffect(() => {
    setApplyPadding(true);
    const timer = setTimeout(() => {
      setApplyPadding(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, [showSecondary]);
  useEffect(() => {
    !data?.deal_finance_option && handleEvents({ deal_finance_option: "0" });
    !data?.primary_loan_type && handleEvents({ primary_loan_type: "0" });
    !data?.secondary_loan_type && handleEvents({ secondary_loan_type: "1" });
    !data?.primary_sub_loan_type &&
      handleEvents({ primary_sub_loan_type: "1" });
    !data?.secondary_sub_loan_type &&
      handleEvents({ secondary_sub_loan_type: "1" });
    !data?.primary_month_year && handleEvents({ primary_month_year: "0" });
    !data?.secondary_month_year && handleEvents({ secondary_month_year: "0" });
    !data?.primary_wholesale_fee_options &&
      handleEvents({ primary_wholesale_fee_options: "1" });

    // NOTE: Run effect once on component mount, please
    // recheck dependencies if effect is updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const checkPmi = makePmiCompulsory(
      data?.secondary_purchase_price,
      data?.secondary_down_payment,
      data?.secondary_dollar_or_percent
    );
    if (checkPmi) {
      handleEvents({ secondary_include_pmi: true });
    } else {
      handleEvents({ secondary_include_pmi: false });
    }
    // NOTE: Run effect once on component mount, please
    // recheck dependencies if effect is updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    data?.primary_purchase_price,
    data?.primary_down_payment,
    data?.primary_dollar_or_percent,
  ]);

  //==================================this section deals with secondary loans====================================================

  //Obtain Single Primary Loan for Home & Remodel calcs
  const primaryAnnualRate = financialOptions.primary_annual_interest_rate;
  const primaryOnlyTermFor = financialOptions.primary_interest_only_term;
  const primaryMortgageAmount = financialOptions.localGeneralLoanAmount;
  const primaryMonthOrYearChecker =
    financialOptions.primary_interest_only_term_month_or_year;

  const PrimaryInterestAloneMortgageFunc = useCallback(() => {
    const timeTransformer = yearsToMonthsTransformer(
      primaryMonthOrYearChecker,
      primaryOnlyTermFor
    );
    const res = interestOnlyMortgageCalculator(
      primaryAnnualRate,
      primaryMortgageAmount
    );

    dispatch(
      actionCreators.updateFinanceOptions({
        primaryInterestOnly: res,
        primaryInterestOnlyTime: timeTransformer,
      })
    );

    // recheck dependencies if effect is updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    primaryAnnualRate,
    primaryOnlyTermFor,
    primaryMortgageAmount,
    primaryMonthOrYearChecker,
  ]);

  useEffect(() => {
    // recheck dependencies if effect is updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    PrimaryInterestAloneMortgageFunc();
  }, [PrimaryInterestAloneMortgageFunc]);

  //============================ secondary loan for Straight Remodel & Addition ===================================//

  //the mortgage calculator takes interest rate, time and loan amount
  const secondaryInterestRateLocal =
    financialOptions.secondary_interest_rate / 100 / 12;
  const SecondaryTimeInYearsLocal =
    financialOptions.secondary_term_of_loan * 12;
  const secondaryLoanAmountLocal = financialOptions.localSecondaryLoanAmount;

  //secondary amount paid monthly
  const localSecondaryAmount = useMemo(() => {
    const amount = mortgageLoanCalculator(
      secondaryLoanAmountLocal,
      secondaryInterestRateLocal,
      SecondaryTimeInYearsLocal
    );
    return amount;
    // recheck dependencies if effect is updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    secondaryInterestRateLocal,
    SecondaryTimeInYearsLocal,
    secondaryLoanAmountLocal,
  ]);

  // interest only ====================================================================
  const rate = +financialOptions.secondary_annual_interest_rate;
  const amount = +financialOptions.localSecondaryLoanAmount;
  const monthOrYearChecker =
    financialOptions.secondary_interest_only_term_month_or_year;
  const monthOrYear = financialOptions.secondary_interest_only_term;

  const interestAloneMortgageFunc = useCallback(() => {
    const timeTransformer = yearsToMonthsTransformer(
      monthOrYearChecker,
      monthOrYear
    );
    const res = interestOnlyMortgageCalculator(rate, amount);
    dispatch(
      actionCreators.updateFinanceOptions({ secondaryInterestLoanAlt: res })
    );

    return { secondaryInterestOnly: res, time: timeTransformer };
    // recheck dependencies if effect is updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rate, amount, monthOrYearChecker, monthOrYear]);

  const interestAloneMortgage = useMemo(() => {
    const interestAloneMortgage = interestAloneMortgageFunc();
    return interestAloneMortgage;
  }, [interestAloneMortgageFunc]);

  //=================================================================================================================//

  //updates the secondaryLoanMonthly Payment
  const localLoanMonthlyAmount =
    +financialOptions.TotalSecondaryMonthlyLoanAmount;

  //updates the year and the interest on interest only returned
  const secondaryLocalYearsToMonths =
    financialOptions.secondaryYearsToMonthsTransformed;
  const interestOnlyReturned = financialOptions.secondaryInterestOnlyReturned;
  const secondaryInterestOnlyResult = secondaryLocalYearsToMonths
    ? {
        interest_only_payment_term: secondaryLocalYearsToMonths,
        interest_only_payment: interestOnlyReturned,
      }
    : {
        interest_only_payment_term: interestAloneMortgage.time,
        interest_only_payment: interestAloneMortgage.secondaryInterestOnly,
      };

  const dataOnlyPay = secondaryInterestOnlyResult || mortgageData;

  const amountToAddToMonthlyTotalPaymentDisplay =
    +data?.secondary_sub_loan_type === 0
      ? +data?.TotalSecondaryMonthlyLoanAmount
      : +interestOnlyReturned;

  const dynamicLocalLoanMonthlyAmount =
    localLoanMonthlyAmount || localSecondaryAmount;
  const localSecondaryMonthlyPayment =
    dynamicLocalLoanMonthlyAmount === isNaN
      ? 0
      : dynamicLocalLoanMonthlyAmount || secondaryMonthlyPayment;

  useEffect(() => {
    dispatch(
      actionCreators.updateCarryingMonths({
        universalSecondaryLoanAmount: localSecondaryMonthlyPayment,
      })
    );
    // recheck dependencies if effect is updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localSecondaryMonthlyPayment]);
  useEffect(() => {
    dispatch(
      actionCreators.updateFinanceOptions({
        secondaryPmiValue: pmi_value,
      })
    );
    // recheck dependencies if effect is updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pmi_value]);

  const allPaymentPaidInAMonth = (
    (data?.primary_monthly_amount_for_display || 0) +
    (data?.primary_include_pmi ? data?.pmi_amount_for_display || 0 : 0) +
    (amountToAddToMonthlyTotalPaymentDisplay || 0)
  )?.toFixed(2);

  return (
    <Box sx={styles.root}>
      <StyledLabel
        label="Select deal finance option"
        showToolTip
        toolTipContent={toolTipTexts.dealFinance}
      />

      <EnhancedRadioButtonGroup
        options={financeDealOptions}
        handler={handleDealFinanceOption}
        choice={data?.deal_finance_option}
      />
      <LoanTypeSelector
        handler={handleEvents}
        options={loanTypeOptions}
        headings={primaryHeadings}
        subOptions={nonConventionalLoanOptions}
        choice={data?.loan_type}
        data={data}
      />
      <PrimaryHomeLoan
        handler={handleEvents}
        primary_include_pmi={primary_include_pmi}
        data={data}
        fetchResult={fetchResult}
        remodelCost={remodelCost}
        mortgagePrimaryResultLoading={mortgagePrimaryResultLoading}
        dealAnalyzerResultsData={dealAnalyzerResultsData}
      />
      {secondaryCash && (
        <SecondaryCash
          data={data}
          handler={handleEvents}
          heading={
            +checkDealFinanceOption() === 3
              ? "Cash for Home"
              : "Cash for Remodel"
          }
          dealOption={+checkDealFinanceOption()}
        />
      )}
      {showSecondaryToggleButton() && ( //Selectively shown for certain deal finance options
        <div
          style={applyPadding ? { paddingBottom: "0px" } : { paddingBottom: 0 }}
        >
          {!showSecondary && (
            <Button
              variant="outlined"
              sx={styles.button}
              onClick={handleShowSecondary}
            >
              <BiPlus fontSize={"16px"} />{" "}
              <Typography variant="toggleText">
                Add Secondary Remodel Loan
              </Typography>
            </Button>
          )}
          {showSecondary && (
            <div>
              <LoanTypeSecondary
                handler={handleEvents}
                options={loanTypeOptions}
                subOptions={nonConventionalLoanOptions}
                headings={secondaryHeading}
                choice={
                  data?.secondary_loan_type ? data.secondary_loan_type : ""
                }
                data={data}
              />
              <div ref={scrollRef}></div>
              <SecondaryRemodelAddition
                setPmiValue={setPmiValue}
                pmi_value={pmi_value}
                handler={handleEvents}
                data={data}
                remodelCost={remodelCost}
              />

              {showSecondaryConventionalLoan() && (
                <>
                  <Box>
                    <LoanAmount
                      handler={handleEvents}
                      label="Loan Amount"
                      name="secondary_loan_amount"
                      loanAmount={
                        localSecondaryLoanAmount?.effectiveLoan
                          ? localSecondaryLoanAmount.effectiveLoan?.toFixed(2)
                          : ""
                      }
                      greyed
                    />
                  </Box>

                  <Box>
                    {/*Secondary  Monthly amount paid  */}
                    <InterestTermResult
                      handler={handleEvents}
                      data={data}
                      prefixName="secondary"
                      beforeFetchResult={fetchResult}
                      monthlyPayment={localSecondaryMonthlyPayment}
                    />
                  </Box>
                </>
              )}
              {showSecondaryMortgageLoan() && (
                <>
                  <InterestOnlyMortgage
                    handler={handleEvents}
                    data={data}
                    namePrefix="secondary_"
                    mortgageAmount={
                      localSecondaryLoanAmount?.effectiveLoan
                        ? localSecondaryLoanAmount.effectiveLoan.toFixed()
                        : ""
                    }
                  />
                  {/* Don't forget to remove the button later */}
                  {!dataOnlyPay && (
                    <Button
                      sx={styles.button}
                      onClick={handleShowmortgageResult}
                      variant="contained"
                    >
                      {!mortgageSecondaryResultLoading && (
                        <Typography variant="h5">CALCULATE</Typography>
                      )}
                      {mortgageSecondaryResultLoading && (
                        <ClipLoader color={"#fff"} loading={true} size={16} />
                      )}
                    </Button>
                  )}
                  <Divider
                    variant="fullWidth"
                    sx={{ height: "5px", marginTop: "40px" }}
                  />
                  {dataOnlyPay !== isNaN && (
                    <InterestOnlyMortgageResult
                      data={data}
                      fetchResult={fetchResult}
                      mortgageResults={dataOnlyPay}
                    />
                  )}
                </>
              )}
            </div>
          )}
        </div>
      )}
      <Divider variant="fullWidth" sx={{ height: "5px", marginTop: "40px" }} />
      <Box sx={{ display: "flex", gap: "2rem", mt: "2rem" }}>
        <Box>
          {" "}
          <StyledLabel
            label="Total Combined Monthly Mortgage Amount"
            showToolTip
          />
        </Box>
        <FormControl
          sx={{
            minWidth: "18.75rem",
          }}
        >
          <OutlinedInput
            fullWidth
            // disabled
            name={`_interest_rate`}
            value={commaSeparate(+allPaymentPaidInAMonth)}
            margin="normal"
            inputProps={{ style: { fontSize: "1rem" }, min: 0 }}
            type="text"
            endAdornment={
              <InputAdornment position="start">
                {" "}
                <Typography variant="adornment" color="secondary">
                  $
                </Typography>
              </InputAdornment>
            }
          />
        </FormControl>
      </Box>
    </Box>
  );
}

export default FinanceOptions;

const financeDealOptions = [
  {
    name: "Obtain Primary Home Loan  & a Secondary Remodel Loan",
    info: "Intend to get two separate loans.  One for the property and the other for the remodel",
  },
  {
    name: "Obtain Primary Home Loan  & Use Cash for Remodel",
    info: " Intend to get a loan for the property and use cash for the remodel",
  },
  {
    name: "Obtain Single Primary Loan for Home & Remodel",
    info: "Intend to get one loan for both the property and the remodel",
  },
  {
    name: "Obtain a Primary Remodel Loan & Pay Cash for Home",
    info: "Intend to buy the property with cash and get a loan for the remodel",
  },
  {
    name: "Pay All Cash for Home & Remodel",
    info: "Intend to use only cash for purchasing property and remodel",
  },
];

const loanTypeOptions = [
  {
    name: "Conventional Loan",
    info: "Standard Loan from a bank or financial institution",
  },
  { name: "Private Hard Money", info: "This is from a private leader source" },
  {
    name: "Seller Financing",
    info: "Owner of the property will finance the home and you will make payments directly to the homeowner",
  },
];
const primaryHeadings = [
  "Primary Home Loan",
  "Single Primary Loan",
  "Primary Remodel Loan",
  "Primary Cash for Home & Remodel",
];
const secondaryHeading = "Secondary Remodel Loan";

export const acronyms = {
  IOM: "Interest Only Mortgage",
  SAR: "Straight Aesthetic Remodel",
};
const nonConventionalLoanOptions = [
  "Conventional Mortgage Loan",
  "Interest Only Mortgage Loan",
];
const toolTipTexts = {
  dealFinance: "Select the way you will be paying for the property",
};
