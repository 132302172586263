export const remodelStaticItemNames = {
    aesthetic_items :[
        {item:'Remodel Kitchens',value:""},
        {item:'Remodel Bathrooms',value:""},
        {item:'New Flooring',value:""},
        {item:'Painting Exterior',value:""},
        {item:'Painting Interior',value:""},
        {item:'Landscaping',value:""}
    ],
    non_aesthetic_items :[
        {item:'Roof Replacement',value:""},
        {item:'New Electrical',value:""},
        {item:'New Insulation',value:""},
        {item:'New Interior Doors',value:""},
        {item:'New Plumbing Fixtures',value:""},
        {item:'New Plumbing',value:""},
    ]
}