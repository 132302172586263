import React, { useEffect, useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Chip, Divider, Grid, IconButton, Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  FormControl,
  InputAdornment,
  OutlinedInput,
  Select,
  Box,
  MenuItem,
  Typography,
} from "@mui/material";
import useTheme from "@mui/material/styles/useTheme";

import StyledLabel from "../../../atomicComponents/StyledLabel";
import { preventMinus } from "../../../utils/preventNegativeValues";

const useStyle = (_theme) => {
  return {
    root: {
      // padding: "10px",
    },
    row1: {
      display: "flex",
      marginTop: "10px",
      width: "100%",
    },
    row2: {
      display: "flex",
      marginTop: "10px",
      width: "100%",
      justifyContent: "space-between",
    },
  };
};

function PropertyInformationAdditionalUnit({
  handleEvents,
  data,
  item,
  hasAdditionalSquareFootage = true,
  unitIndex = 0,  
  additionalUnitArray,
  setAdditionalUnitArray,
}) {
  const theme = useTheme();
  const styles = useStyle(theme);

  // adding multiple input fields
  const componentDynamicDataFilter = (index, event, dateName) => {
    let data = [...additionalUnitArray];
    const { name, value } = event?.target || "";
    const fieldName = name || dateName;

    // Find the field to update within the specific unit
    const fieldIndex = data[index].findIndex(
      (field) => field.name === fieldName
    );

    if (fieldIndex !== -1) {
      const field = data[index][fieldIndex];
      field.value = field.name.includes("year_of_construction") ? event : value;
    }

    setAdditionalUnitArray(data);
    handleEvents({ additional_units: data });
  };

  //--------------------- setting carport or garage -----------------//
  const prevCarportOrGarageValue = data.carpot_or_garage
    ? data.carpot_or_garage === "Garage"
      ? 0
      : 1
    : 0;

  const [carportOrgarage, setCarportOrGarage] = useState(
    prevCarportOrGarageValue
  );

  //-------------- this effect adds garage or carport option to the property field
  useEffect(() => {
    handleEvents({
      carpot_or_garage: carportOrgarage === 0 ? "Garage" : "Carport",
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carportOrgarage]);

  const deleteUnit = (selectedIndex) => {
    const data = additionalUnitArray.filter(
      (f, index) => index !== selectedIndex
    );
    setAdditionalUnitArray(data);
    handleEvents({ additional_units: data });
  };

  return (
    <Box sx={styles.root}>
      <Grid container columns={24}>
        <Grid item sm={22}>
          <Divider>
            <Chip
              label={`Unit ${unitIndex + 2}`}
              style={{ fontSize: "10px" }}
            />
          </Divider>
        </Grid>
        <Grid item sm={2}>
          <IconButton
            variant="outlined"
            color="red"
            onClick={() => deleteUnit(unitIndex)}
          >
            <Tooltip
              title={
                <Typography
                  sx={{
                    fontSize: "13px",
                  }}
                >{`Click here to delete unit ${unitIndex + 2}`}</Typography>
              }
            >
              <DeleteIcon color="warning" />
            </Tooltip>
          </IconButton>
        </Grid>
      </Grid>
      <Grid container columns={24}>
        <Grid sm={0} md={2} />
      </Grid>
      <Grid container columns={36} sx={{ mt: 2 }}>
        <Grid item sm={36} md={10}>
          <FormControl sx={{ width: "100%" }}>
            <StyledLabel
              label={`Bedrooms`}
              showToolTip
              toolTipContent={toolTipTexts.bedrooms}
            />
            <OutlinedInput
              name={item?.[0]?.name}
              onKeyPress={preventMinus}
              onChange={(event) => componentDynamicDataFilter(unitIndex, event)}
              type="number"
              value={item?.[0]?.value || ""}
              inputProps={{ style: { fontSize: "1rem" }, min: 0 }}
              sx={{ fontSize: "16px" }}
            ></OutlinedInput>
          </FormControl>
        </Grid>
        <Grid item sm={0} md={2} />
        <Grid item sm={36} md={10}>
          <FormControl sx={{ width: "100%" }}>
            <StyledLabel
              label="Bathrooms"
              showToolTip
              toolTipContent={toolTipTexts.bathrooms}
            />
            <OutlinedInput
              name={item?.[1]?.name}
              onChange={(event) => componentDynamicDataFilter(unitIndex, event)}
              type="number"
              onKeyPress={preventMinus}
              value={item?.[1]?.value || ""}
              inputProps={{ style: { fontSize: "1rem" }, min: 0 }}
              sx={{ fontSize: "16px" }}
            ></OutlinedInput>
          </FormControl>
        </Grid>

        {/* ------------------------------------ */}

        <Grid item sm={0} md={2} />
        <Grid item sm={36} md={10}>
          <FormControl sx={{ width: "100%" }}>
            <StyledLabel label="Pre Existing Livable" />
            <OutlinedInput
              name={item?.[3]?.name}
              onChange={(event) => componentDynamicDataFilter(unitIndex, event)}
              value={item?.[3]?.value || ""}
              inputProps={{ style: { fontSize: "16px" }, min: 0 }}
              sx={{ fontSize: "16px" }}
              onKeyPress={preventMinus}
              endAdornment={
                <InputAdornment position="start">
                  {" "}
                  <Typography variant="adornment"> SqFt</Typography>
                </InputAdornment>
              }
            />
          </FormControl>
        </Grid>

        {/* ------------------------------------ */}
      </Grid>
      <Grid
        container
        columns={36}
        sx={{
          mt: 2,
        }}
      >
        {/* NOTE: Only show this Field if component support additional square footage */}
        {hasAdditionalSquareFootage && (
          <>
            <Grid item sm={24} md={10}>
              <FormControl sx={{ width: "100%" }}>
                <StyledLabel label="Additional SqFt" />
                <OutlinedInput
                  name={item?.[2]?.name}
                  onChange={(event) =>
                    componentDynamicDataFilter(unitIndex, event)
                  }
                  value={item?.[2]?.value || ""}
                  inputProps={{ style: { fontSize: "1rem" }, min: 0 }}
                  sx={{ fontSize: "1rem" }}
                  onKeyPress={preventMinus}
                  endAdornment={
                    <InputAdornment position="start">
                      {" "}
                      <Typography variant="adornment"> SqFt</Typography>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Grid>
          </>
        )}
        <Grid item sm={0} md={2} />
        <Grid item sm={36} md={hasAdditionalSquareFootage ? 10 : 16}>
          <FormControl sx={{ width: "100%" }}>
            <StyledLabel
              label="Garage / Carport Sp"
              showToolTip
              toolTipContent={toolTipTexts.garage}
            />
            <OutlinedInput
              name={item?.[4]?.name}
              onChange={(event) => componentDynamicDataFilter(unitIndex, event)}
              value={item?.[4]?.value || ""}
              type="number"
              onKeyPress={preventMinus}
              inputProps={{ style: { fontSize: "16px" }, min: 0 }}
              sx={{ fontSize: "16px" }}
              endAdornment={
                <>
                  <InputAdornment position="start">
                    {
                      <FormControl sx={{ width: "7.5rem" }}>
                        <Select
                          // name={`${name}_month_or_year`}
                          displayEmpty={true}
                          // disabled={disable}
                          error={true}
                          placeholder="none"
                          renderValue={(value) =>
                            value ? value : <b>months</b>
                          }
                          sx={{
                            fieldSet: {
                              border: "none !important",
                            },
                            fontSize: "1.125rem",
                            color: "black",
                            fontWeight: "bold",
                          }}
                          onChange={(e) => setCarportOrGarage(e.target.value)}
                          value={carportOrgarage === 0 ? "Garage" : "Carport"}
                        >
                          {["Garage", "Carport"].map((element, index) => (
                            <MenuItem
                              value={index}
                              key={element}
                              sx={{
                                "&:hover": { backgroundColor: "#1378A5" },
                                fontSize: "1rem",
                              }}
                            >
                              {element}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    }
                  </InputAdornment>
                </>
                // <InputAdornment position="start">
                //   {" "}
                //   <Typography variant="adornment"> Spaces</Typography>
                // </InputAdornment>

                //-------------------- updating the carport and garage---------------------//
              }
            />
          </FormControl>
        </Grid>
        <Grid item sm={0} md={2} />
        <Grid item sm={36} md={hasAdditionalSquareFootage ? 10 : 16}>
          <FormControl sx={{ width: "100%", marginRight: "40px" }}>
            <StyledLabel
              label="Year of Construction"
              showToolTip
              toolTipContent={toolTipTexts.constuctionYear}
            />
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                views={["year"]}
                name={item?.[5]?.name}
                inputProps={{ style: { fontSize: "16px" }, min: 0 }}
                sx={{ fontSize: "16px" }}
                size="medium"
                value={item?.[5]?.value || null}
                onChange={(date) =>
                  componentDynamicDataFilter(
                    unitIndex,
                    date,
                    `year_of_construction${unitIndex + 1}`
                  )
                }
                renderInput={(params) => (
                  <OutlinedInput
                    size="normal"
                    inputProps={{ style: { fontSize: "16px" } }}
                    sx={{
                      fontSize: "16px",
                      borderColor: "black",
                      bgcolor: "pink",
                    }}
                    {...params}
                    helperText={null}
                  />
                )}
              />
            </LocalizationProvider>
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  );
}

export default PropertyInformationAdditionalUnit;

const toolTipTexts = {
  listingDate:
    "The date home was actively listed on MLS or another source provider",
  listingSource: "MLS, BPO HOMES, Others (Leads to type in option)",
  bedrooms: "Number of bedrooms in the property",
  bathrooms: "Number of bathrooms in the property",
  lotSize: "Square footage of the property's land",
  pre_existing_livable_sqft:
    "Amount of square footage currently existing at the residence",
  garage:
    "Number of garage spaces, currently at the residence. Garages are Covered Spaces that are fully enclosed. Carports are Covered Spaces that are open",
  constuctionYear: "The year property was built",
};
