import {
  FormControl,
  InputAdornment,
  Box,
  Typography,
  OutlinedInput,
} from "@mui/material";

import StyledLabel from "./StyledLabel";
import React from "react";
import useTheme from "@mui/material/styles/useTheme";
import commaSeparate from "../utils/commaSeparate";
import { greyout } from "../utils/greyout";

const useStyle = (_theme) => {
  return {
    amount: {
      display: "flex",
      flexDirection: "column",
      marginTop: "10px",
      // marginRight: "5rem",
      width: "47%",
    },
    term: {
      display: "flex",
      flexDirection: "column",
      marginTop: "10px",
      width: "95%",
    },
    interest: {
      display: "flex",
      flexDirection: "column",
      marginTop: "10px",
      marginRight: "5rem",
      width: "80%",
    },
    interestTerm: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: "1.875rem",
    },
    termFor: {
      display: "flex",
      flexDirection: "column",
      marginTop: "10px",
      width: "100%",
    },
    button: {
      width: "100%",
      margin: "1.875rem 0 0 0",
      padding: "1rem",
    },
  };
};

//this component displays the mortgage result
function InterestOnlyMortgageResult({ mortgageResults }) {
  const theme = useTheme();
  const styles = useStyle(theme);


  return (
    <>
      <Box sx={styles.interestTerm}>
        <Box sx={styles.amount}>
          <Box>
            <StyledLabel label="Interest Only Payment" />
          </Box>
          <FormControl
            sx={{
              width: "100%",
              marginTop: "5px",
            }}
          >
            <OutlinedInput
              margin="normal"
              value={commaSeparate(mortgageResults?.interest_only_payment)}
              inputProps={{ style: { fontSize: "1rem" } }}
              sx={{ ...greyout }}
              startAdornment={
                <InputAdornment position="start">
                  {" "}
                  <Typography variant="adornment" color="secondary">
                    $
                  </Typography>
                </InputAdornment>
              }
            />
          </FormControl>
        </Box>
        <Box sx={styles.amount}>
          <Box>
            <StyledLabel label="Term" />
          </Box>
          <FormControl
            sx={{
              width: "100%",
              marginTop: "5px",
            }}
          >
            <OutlinedInput
              margin="normal"
              inputProps={{ style: { fontSize: "1rem" } }}
              // renderValue={(value)=>value}
              sx={{ ...greyout }}
              value={mortgageResults?.interest_only_payment_term}
              endAdornment={
                <InputAdornment position="start">
                  {" "}
                  <Typography variant="adornment" color="secondary">
                    months
                  </Typography>
                </InputAdornment>
              }
            />
          </FormControl>
        </Box>
      </Box>
      <Box sx={styles.interestTerm}>
        {/* <Box sx={styles.amount}>
            <Box>
              <StyledLabel label="Principal + Interest Payment" />
            </Box>
            <FormControl
              sx={{
                width: "100%",
                marginTop: "5px",
              }}
            >
              <OutlinedInput
                margin="normal"
                sx={{...greyout}}
                inputProps={{ style: { fontSize: "1rem" } }}
                value = {commaSeparate(mortgageResults?.principal_plus_interest_payment) }
                startAdornment={
                  <InputAdornment position="start">
                    {" "}
                    <Typography variant="adornment" color="secondary">
                      $
                    </Typography>
                  </InputAdornment>
                }
              />
            </FormControl>
          </Box> */}
        {/* <Box sx={styles.amount}>
            <Box>
              <StyledLabel label="Term" />
            </Box>
            <FormControl
              sx={{
                width: "100%",
                marginTop: "5px",
              }}
            >
              <OutlinedInput
                margin="normal"
                sx={{...greyout}}
                inputProps={{ style: { fontSize: "1rem" } }}
                value = {mortgageResults?.principal_plus_interest_payment_term}
                endAdornment={
                  <InputAdornment position="start">
                    {" "}
                    <Typography variant="adornment" color="secondary">
                      months
                    </Typography>
                  </InputAdornment>
                }
              />
            </FormControl>
          </Box> */}
      </Box>
    </>
  );
}

export default InterestOnlyMortgageResult;

export const InterestOnlyMortgageResultAcronyms = {
  IOP: "Interest Only Payment",
  IOP_term: "Interest Only Payment Term",
  PIP: "Principal + Interest Payment",
  PIP_term: "Principal + Interest Payment Term",
};
