import React,{useState} from 'react'
import { Box,Typography,Button } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import {ClipLoader} from "react-spinners"


const useStyles = (_theme)=>{
  return {
      root:{
      },
      mainHeading:{
          textAlign:"center",
          marginBottom:"1.25rem"
      },
      componentContainer:{
          display:"flex",
          justifyContent:"center",
          margin:"3.125rem 0"
      },
      buttonContainer:{
          display:"flex",
          justifyContent:"center",
          margin:"2.5rem 0",
      },
      button:{
          margin:"0.625rem",
          padding:"0.9375rem 3.75rem",
          textTransform:"none"
      }
  }
}

function useQuestionnaireButtons() {
  const [deactivateSubmitButton, setDeactivateSubmitButton] = useState(false)
  const [deactivateNextButton, setDeactivateNextButton] = useState(false)
  const theme = useTheme()
  const styles = useStyles(theme)

  const buttonDisableControl = ({buttonType, state}) =>{
    switch(buttonType){
      case "submit" : setDeactivateSubmitButton(!!state)
      break;
      case "next" : setDeactivateNextButton(!!state)
      break;
      default : return
    }
  }

  const QuestionnaireButtons = ({activeTab,handleNext,loading,handlePrevious,headings,handleSubmit})=>{
    return(
    <Box sx={styles.buttonContainer}>
    <Button
      variant="outlined"
      sx={styles.button}
      onClick={handlePrevious}
    >
      <Typography variant="buttonFont">Back</Typography>
    </Button>
    {+activeTab !== headings.length - 1 && (
      <Button
        variant="contained"
        sx={styles.button}
        onClick={handleNext}
        disabled = {deactivateNextButton}
      >
        <Typography variant="buttonFont">Next</Typography>
      </Button>
    )}
    {+activeTab === headings.length - 1 && (
      <Button
        variant="contained"
        sx={styles.button}
        onClick={handleSubmit}
        disabled={deactivateSubmitButton }
      >
        {!loading && (
          <Typography variant="buttonFont">Submit</Typography>
        )}
        {loading && (
          <ClipLoader color="white" size={12} speedMultiplier={0.5} />
        )}
      </Button>
    )}
    
  </Box>)
  }

  return {buttonDisableControl,questionnaireButtons:QuestionnaireButtons}

}

export default useQuestionnaireButtons