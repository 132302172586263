import React, { useEffect } from "react";
import EnhancedTable from "../../atomicComponents/EnhancedTable";
import { Button, Typography, Grid, Paper } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

function NonAestheticItems({
  data,
  handler,
  fillInitialData,
  removeNonAestheticItem,
  updateNonAestheticItems,
  addNewRow,
  handleSubMenu,
  reloadCurrentMenu,
  save,
  end,
}) {
  const handleRemoveColumn = (itemIndex) => {
    removeNonAestheticItem(itemIndex);
  };

  const updateItems = (index, value) => {
    updateNonAestheticItems(index, value);
  };
  useEffect(() => {
    function createItems() {
      if (!data?.["non_aesthetic_items"]?.length) {
        fillInitialData(1);
      }
    }
    createItems();
    // NOTE: Run effect once on component mount, please
    // recheck dependencies if effect is updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleReturnToMain = () => {
    save();
    reloadCurrentMenu();
  };

  return (
    <Paper elevation={0} sx={{ height: "100%" }}>
      <EnhancedTable
        staticData={data?.non_aesthetic_items}
        title="Non Aesthetic Items"
        name="non_aesthetic_cost"
        updateItems={updateItems}
        data={data}
        handler={handler}
        handleRemoveColumn={handleRemoveColumn}
      />
      <Grid
        container
        columns={12}
        columnSpacing={2}
        sx={{ position: "sticky", bottom: 0 }}
      >
        <Grid item xs={2}>
          <Button
            variant="contained"
            sx={{ width: "100%", padding: "0.937rem", marginTop: "1.875rem" }}
            onClick={() => handleSubMenu(1)}
          >
            <Typography
              variant="h5"
              sx={{ display: "flex", alignItems: "center" }}
            >
              <span>
                <ArrowBackIosIcon sx={{ fontSize: "1.25rem", mr: 1 }} />
              </span>{" "}
            </Typography>{" "}
          </Button>
        </Grid>
        <Grid item xs={8}>
          <Button
            variant="contained"
            sx={{ width: "100%", padding: "0.937rem", marginTop: "1.875rem" }}
            onClick={() => addNewRow(1)}
          >
            <Typography
              variant="h4"
              sx={{ display: "flex", alignItems: "center" }}
            >
              <span>
                <AddIcon sx={{ fontSize: "1.25rem", mr: 1 }} />
              </span>{" "}
              <span>ADD AN ITEM</span>
            </Typography>{" "}
          </Button>
        </Grid>
        <Grid item xs={2}>
          {!end ? (
            <Button
              variant="contained"
              sx={{ width: "100%", padding: "0.937rem", marginTop: "1.875rem" }}
              onClick={() => handleSubMenu(3)}
            >
              <Typography
                variant="h5"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <span>
                  <ArrowForwardIosIcon sx={{ fontSize: "1.25rem", mr: 1 }} />
                </span>{" "}
              </Typography>{" "}
            </Button>
          ) : (
            <Button
              variant="contained"
              sx={{ width: "100%", padding: "0.937rem", marginTop: "1.875rem" }}
              onClick={handleReturnToMain}
            >
              <Typography
                variant="h4"
                sx={{ display: "flex", alignItems: "center" }}
              >
                Done
              </Typography>{" "}
            </Button>
          )}
        </Grid>
      </Grid>
    </Paper>
  );
}

export default NonAestheticItems;
