import base from "../../../apis";
import useCreateAlert from "./useCreateAlert";
import { useState } from "react";

function useCreateOrFetchReport(_id) {
  const {createAlert} = useCreateAlert()
  const [error,setError] = useState()


  const createReport = async(link,load)=>{
    try {
      let response
      response = load? await base.post(`/${link}`,{...load}): await base.post(`/${link}`)
      let id
      id = response?.data?.data?.id 
      return id
    } catch (error) {
     const msg = error?.response?.data?.detail
      setError(error?.response)
      createAlert(msg,"error")
      return "failed"
    }
  }

  return {createReport,error };
}

export default useCreateOrFetchReport;
