import {
	Box,
	Checkbox,
	Button as DButton,
	Divider,
	FormControl,
	FormControlLabel,
	FormGroup,
	FormLabel,
	InputAdornment,
	InputLabel,
	Radio,
	RadioGroup,
	Stack,
	Typography,
	createTheme,
} from '@mui/material';
import { ThemeProvider, makeStyles, styled } from '@mui/styles';
import axios from 'axios';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { URL, investorTypes } from '../../constants';
import BG from '../../images/investor_bg.webp';
import { theme } from '../../theme';
import { commaReplace, formatPhoneNumber, validateEmail } from '../../utils/functions';
import PlacesSearch from '../PlacesSearch/PlacesSearch';
import { LoadingButton } from '@mui/lab';
import Inputter from '../InputWithLabel';

const Button = styled(DButton)(({ theme }) => ({
	textTransform: 'capitalize',
}));

const pageTheme = createTheme({
	...theme,
	typography: {
		fontFamily: 'Lato, sans-serif',
		fontSize: 16,
	},
});

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		background: `white`,
		scrollMarginTop: '200px',
		overflow: 'hidden',
		position: 'relative',
		'&::after': {
			content: '""',
			position: 'absolute',
			top: 0,
			left: 0,
			width: '10%',
			height: '100%',
			background: `radial-gradient(96.01% 57.06% at 27.17% 19.89%, #59A6E4 0%, #005EA9 100%) `,
			zIndex: 1,
		},
	},
	box: {
		// background: `radial-gradient(96.01% 57.06% at 27.17% 19.89%, #59A6E4 0%, #005EA9 100%) `,
		// height: '400px',
		flex: 1,
		zIndex: 2,
		position: 'relative',
		'&::after': {
			content: '""',
			position: 'absolute',
			top: 0,
			bottom: 0,
			right: 'calc(2rem - 7px)',
			margin: 'auto 0',
			width: '12px',
			height: '180px',
			backgroundColor: '#FFAA01',
			borderBottomRightRadius: '10px',
			borderTopRightRadius: '10px',
		},
	},
}));

const initialDetailsState = {
	how_much_liquid_capital: '',
	area_of_focus: '',
	areas_of_focus: [],
	use_hard_money_to_fund: false,
	is_wholesaler: false,
	investing_years: '',
	is_accompanied_with_a_wholesale: true,
	is_finder_fee_buyer_commission: true,
	is_relisted_by_my_brokerage: true,
	investor_name: '',
	investor_types: [],
	phone_number: '',
	email: '',
	address: '',
};

function InvestorQuestionnaires({ agent }) {
	const classes = useStyles();
	const [stepper, setStepper] = useState(1);
	const [details, setDetails] = useState(initialDetailsState);
	const name = agent?.user.first_name + ' ' + agent?.user.last_name;

	const handleChange = (e) => {
		const { name, value } = e.target;
		setDetails((prev) => ({ ...prev, [name]: value }));
	};
	const handleCheckChange = (e, checked, parent) => {
		const { name } = e.target;
		if (checked) {
			setDetails((prev) => ({ ...prev, [parent]: [...prev[parent], name] }));
		} else {
			setDetails((prev) => ({ ...prev, [parent]: prev[parent].filter((item) => item !== name) }));
		}
	};
	const onReset = () => {
		setDetails(initialDetailsState);
	};

	const onCheck = () => {
		if (stepper === 2) {
			if (details.how_much_liquid_capital === '') {
				toast.warn('Please enter your liquid capital');
				return;
			}
			if (!details.areas_of_focus.length) {
				toast.warn('Please select at least one area of focus');
				return;
			}
			if (!details.investing_years) {
				toast.warn('Please enter your investing years');
				return;
			}
			if (details.investor_types.length === 0) {
				toast.warn('Please select at least one investor type');
				return;
			}
		}
		if (stepper === 4) {
			if (details.investor_name === '') {
				toast.warn('Please enter your name');
				return;
			}
			if (details.phone_number === '') {
				toast.warn('Please enter your phone number');
				return;
			}
			if (!validateEmail(details.email)) {
				toast.warn('Please enter a valid email');
				return;
			}
			if (details.address === '') {
				toast.warn('Please enter your address');
				return;
			}
		}
		return true;
	};

	const getContent = () => {
		if (stepper === 1) {
			return (
				<Stack justifyContent={'center'} sx={{ height: '100%' }} gap='1rem' alignItems='center'>
					<Typography variant='h4' sx={{ fontWeight: 'bold' }}>
						Are you A Real Estate Investor?
					</Typography>
					<Typography variant='body1'>Partner with {name || 'me'} for secure and profitable investments. Get started today.</Typography>
					<Button
						variant='contained'
						disableElevation
						sx={(theme) => ({ color: 'white', backgroundColor: theme.palette.primary.main })}
						onClick={() => {
							setStepper(2);
						}}
					>
						Get Started
					</Button>
				</Stack>
			);
		}
		if (stepper === 2) {
			return (
				<StepContent stepper={stepper} setStep={setStepper} onCheck={onCheck} details={details}>
					<Stack gap='1rem'>
						<Inputter
							label='How much liquid capital do you have to invest?'
							placeholder='Enter amount'
							value={commaReplace(details.how_much_liquid_capital)}
							name='how_much_liquid_capital'
							onChange={handleChange}
							InputProps={{
								startAdornment: (
									<InputAdornment position='end' sx={{ '&>p': { color: 'black !important' }, marginRight: '5px' }}>
										$
									</InputAdornment>
								),
							}}
						/>
						<FormControl>
							<FormLabel sx={{ fontSize: '16px' }}>What are your areas of focus?</FormLabel>
							<FormGroup sx={{ display: 'flex', gap: '1rem', flexDirection: 'row' }}>
								{['Northern California', 'Central California', 'Southern California'].map((item) => (
									<FormControlLabel
										key={item}
										control={
											<Checkbox
												checked={details.areas_of_focus.includes(item)}
												onChange={(e, checked) => handleCheckChange(e, checked, 'areas_of_focus')}
												size='small'
												color='secondary'
											/>
										}
										label={item}
										name={item}
									/>
								))}
							</FormGroup>
						</FormControl>
						<Stack direction='row' gap='1rem'>
							<FormControl sx={{ flex: 1 }}>
								<FormLabel sx={{ fontSize: '16px' }}>Are you a wholesaler?</FormLabel>
								<RadioGroup row name='is_wholesaler' value={details.is_wholesaler} onChange={handleChange}>
									<FormControlLabel
										value={true}
										control={<Radio color='secondary' size='small' />}
										label={<span style={{ fontSize: '16px' }}>Yes</span>}
									/>
									<FormControlLabel
										value={false}
										control={<Radio color='secondary' size='small' />}
										label={<span style={{ fontSize: '16px' }}>No</span>}
									/>
								</RadioGroup>
							</FormControl>
							<FormControl sx={{ flex: 1 }}>
								<FormLabel sx={{ fontSize: '16px' }}>Are you funding with hard money?</FormLabel>
								<RadioGroup row name='use_hard_money_to_fund' value={details.use_hard_money_to_fund} onChange={handleChange}>
									<FormControlLabel
										value={true}
										control={<Radio color='secondary' size='small' />}
										label={<span style={{ fontSize: '16px' }}>Yes</span>}
									/>
									<FormControlLabel
										value={false}
										control={<Radio color='secondary' size='small' />}
										label={<span style={{ fontSize: '16px' }}>No</span>}
									/>
								</RadioGroup>
							</FormControl>
						</Stack>
						<Inputter
							label='How long have you been investing?'
							placeholder='Enter number of years'
							value={commaReplace(details.investing_years)}
							name='investing_years'
							onChange={handleChange}
						/>
						<FormControl>
							<FormLabel sx={{ fontSize: '16px' }}>What types of investment do you do?</FormLabel>
							<FormGroup sx={{ display: 'flex', gap: '1rem', flexDirection: 'row' }}>
								{investorTypes.map(({ name, label }) => (
									<FormControlLabel
										key={name}
										control={
											<Checkbox
												checked={details.investor_types.includes(name)}
												onChange={(e, checked) => handleCheckChange(e, checked, 'investor_types')}
												size='small'
												color='secondary'
											/>
										}
										label={label}
										name={name}
									/>
								))}
							</FormGroup>
						</FormControl>
					</Stack>
				</StepContent>
			);
		}
		if (stepper === 3) {
			return (
				<StepContent stepper={stepper} setStep={setStepper} onCheck={onCheck} details={details}>
					<FormControl sx={{ marginBottom: '1rem' }}>
						<FormLabel sx={{ fontSize: '16px' }}>
							<Typography variant='body1' sx={{ fontSize: 'inherit', marginBottom: '10px' }}>
								Some real estate deals come with a variable wholesale fee. Are you comfortable paying this fee if the deal has enough margin after
								such fee?
							</Typography>
							<Typography variant='body1' sx={{ fontSize: '14px' }}>
								<b style={{ color: 'black' }}>Please Note:</b> Answering No to this question will potentially put you out of contention for many
								various properties and investment opportunities
							</Typography>
						</FormLabel>
						<RadioGroup row name='is_accompanied_with_a_wholesale' value={details.is_accompanied_with_a_wholesale} onChange={handleChange}>
							<FormControlLabel
								value={true}
								control={<Radio color='secondary' size='small' />}
								label={<span style={{ fontSize: '16px' }}>Yes</span>}
							/>
							<FormControlLabel
								value={false}
								control={<Radio color='secondary' size='small' />}
								label={<span style={{ fontSize: '16px' }}>No</span>}
							/>
						</RadioGroup>
					</FormControl>
					<FormControl sx={{ marginBottom: '1rem' }}>
						<FormLabel sx={{ fontSize: '16px' }}>
							<Typography variant='body1' sx={{ fontSize: 'inherit', marginBottom: '10px' }}>
								There is a charge of 1% Finder Fee Buyer Commission which amounts to 1% of the Contract Price for finding the Real Estate investment.
								Do you agree to paying a 1% Finder Fee Buyer Commission on your purchase?
							</Typography>
							<Typography variant='body1' sx={{ fontSize: '14px' }}>
								<b style={{ color: 'black' }}>Please Note:</b> Answering No to this question will potentially put you out of contention for many
								various properties and investment opportunities, as the majority of investors I work with pay this commission Fee.
							</Typography>
						</FormLabel>
						<RadioGroup row name='is_finder_fee_buyer_commission' value={details.is_finder_fee_buyer_commission} onChange={handleChange}>
							<FormControlLabel
								value={true}
								control={<Radio color='secondary' size='small' />}
								label={<span style={{ fontSize: '16px' }}>Yes</span>}
							/>
							<FormControlLabel
								value={false}
								control={<Radio color='secondary' size='small' />}
								label={<span style={{ fontSize: '16px' }}>No</span>}
							/>
						</RadioGroup>
					</FormControl>
					<FormControl sx={{ marginBottom: '1rem' }}>
						<FormLabel sx={{ fontSize: '16px' }}>
							<Typography variant='body1' sx={{ fontSize: 'inherit', marginBottom: '10px' }}>
								The Properties we find are required to be relisted by me under my Brokerage after renovation/rehab. This relisting fee may vary. Do
								you agree to provide me with the re-list of the property once the project is complete?
							</Typography>
							<Typography variant='body1' sx={{ fontSize: '14px' }}>
								<b style={{ color: 'black' }}>Please Note:</b> This relisting of any such investment properties will be agreed to in writing prior to
								such property purchase/acquisition. By answering No to this question will potentially put you out of contention for many various
								properties and investment opportunities, as the majority of investors I work with provide a relist on their investment properties when
								acquired through our Brokerage.
							</Typography>
						</FormLabel>
						<RadioGroup row name='is_relisted_by_my_brokerage' value={details.is_relisted_by_my_brokerage} onChange={handleChange}>
							<FormControlLabel
								value={true}
								control={<Radio color='secondary' size='small' />}
								label={<span style={{ fontSize: '16px' }}>Yes</span>}
							/>
							<FormControlLabel
								value={false}
								control={<Radio color='secondary' size='small' />}
								label={<span style={{ fontSize: '16px' }}>No</span>}
							/>
						</RadioGroup>
					</FormControl>
				</StepContent>
			);
		}
		if (stepper === 4) {
			return (
				<StepContent stepper={stepper} setStep={setStepper} onCheck={onCheck} details={details} onReset={onReset}>
					<Stack gap='1rem'>
						<Inputter label='Full Name' name='investor_name' value={details.investor_name} onChange={handleChange} />
						<Inputter label='Phone Number' value={formatPhoneNumber(details.phone_number) || ''} name='phone_number' onChange={handleChange} />
						<Inputter label='Email' value={details.email} name='email' onChange={handleChange} />
						<>
							<InputLabel sx={{ fontSize: '16px' }}>Address</InputLabel>
							<PlacesSearch
								mui={true}
								placeholder={'Address'}
								fullWidth
								name={'address'}
								value={details.address}
								onChange={(val) => setDetails((p) => ({ ...p, address: val }))}
								size='small'
							/>
						</>
					</Stack>
				</StepContent>
			);
		}
		if (stepper === 5) {
			return (
				<Stack justifyContent={'center'} sx={{ height: '100%' }} gap='1rem' alignItems='flex-start' pl='5rem'>
					<Typography variant='h6' sx={{ fontWeight: 'bold' }}>
						Thank You
					</Typography>
					<Divider sx={{ width: '100px', height: '5px' }} color='primary' />
					<Typography variant='body1'>You will be contacted shortly.</Typography>
				</Stack>
			);
		}
	};
	return (
		<ThemeProvider theme={pageTheme}>
			<Stack direction='row' className={classes.root} id='investors'>
				<Box className={classes.box}>
					<img src={BG} alt='investor' style={{ width: '100%', paddingTop: '4rem', paddingBottom: '4rem', paddingRight: '2rem' }} />
				</Box>
				<Box sx={{ flex: 1, padding: '2rem' }}>{getContent()}</Box>
			</Stack>
		</ThemeProvider>
	);
}

export default InvestorQuestionnaires;

const Header = ({ step }) => {
	return (
		<Stack direction='row' justifyContent={'space-between'} sx={{ backgroundColor: '#004277', color: 'white', padding: '1rem' }}>
			<Typography variant='body1'>Kindly fill out the form to get started</Typography>
			<Stack direction='row' gap='10px' alignItems='center'>
				<Divider sx={{ height: '3px', width: '20px', backgroundColor: step === 2 ? '#FFAA01' : 'white' }} />
				<Divider sx={{ height: '3px', width: '20px', backgroundColor: step === 3 ? '#FFAA01' : 'white' }} />
				<Divider sx={{ height: '3px', width: '20px', backgroundColor: step === 4 ? '#FFAA01' : 'white' }} />
			</Stack>
		</Stack>
	);
};

const StepContent = ({ stepper, children, setStep, onCheck, onReset, details }) => {
	const { id } = useParams();
	const [isLoading, setIsLoading] = useState(false);
	const handleNext = async () => {
		if (!onCheck()) return;
		if (stepper === 4) {
			const body = {
				...details,
				area_of_focus: details.areas_of_focus,
				agent_id: id,
				investing_years: +details.investing_years.replaceAll(',', ''),
				how_much_liquid_capital: +details.how_much_liquid_capital.replaceAll(',', ''),
			};
			delete body.areas_of_focus;
			try {
				setIsLoading(true);
				await axios.post(URL + 'investors/create-investor/', body);
				onReset();
				setStep(5);
				setTimeout(() => {
					setStep(1);
				}, 3000);
			} catch (error) {
				toast.warn('Something went wrong, please try again');
			} finally {
				setIsLoading(false);
			}
		} else {
			setStep((prev) => prev + 1);
		}
	};

	return (
		<ThemeProvider theme={pageTheme}>
			<Stack sx={{ height: '100%' }}>
				<Header step={stepper} />
				<Box sx={{ padding: '1rem' }}>{children}</Box>
				<Stack direction='row' justifyContent={'space-between'} mt='auto'>
					<Button
						variant='text'
						sx={{ color: 'grey', marginLeft: '1rem' }}
						onClick={() => {
							setStep(1);
						}}
					>
						Cancel
					</Button>
					<Stack direction='row' justifyContent={'flex-end'} gap='1rem'>
						{stepper !== 2 && (
							<Button
								variant='text'
								sx={{ color: 'grey', marginRight: '1rem' }}
								onClick={() => {
									setStep((prev) => prev - 1);
								}}
							>
								Back
							</Button>
						)}
						<LoadingButton
							loading={isLoading}
							variant='contained'
							color='secondary'
							disableElevation
							sx={{ marginRight: '1rem', textTransform: 'capitalize' }}
							onClick={handleNext}
						>
							{stepper === 4 ? 'Submit' : 'Next'}
						</LoadingButton>
					</Stack>
				</Stack>
			</Stack>
		</ThemeProvider>
	);
};
