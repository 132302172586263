import React from 'react';
import { makeStyles } from '@mui/styles';
import { NavLink, Outlet } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { useState } from 'react';
import Spinner from '../../components/Spinner/Spinner';
const useStyles = makeStyles((theme) => ({
	root: {
		padding: '20px',
		minHeight: '60vh',
	},
	sales: {
		fontSize: '14px',
		textAlign: 'right',
		fontWeight: 'bold',
	},
	tabs: {
		display: 'flex',
		gap: '20px',
		marginBottom: '10px',
		maxWidth: '100%',
		overflowY: 'auto',

		'& a': {
			paddingBottom: '5px',
			color: theme.secondaryColor,
			textDecoration: 'none',
			borderBottom: '3px solid transparent',
			cursor: 'pointer',
			whiteSpace: 'nowrap',

			'&.active': {
				borderColor: theme.secondaryColor,
			},
		},
	},
}));

function SubscriptionOverview() {
	const classes = useStyles();
	const [isLoading, setIsLoading] = useState(false);
	return (
		<>
			{isLoading && <Spinner />}
			<div className={classes.root}>
				<Box>
					<Typography paragraph className={classes.sales}>
						Call Sales:1 888 616 5270
					</Typography>
				</Box>
				<Box className={classes.tabs}>
					<NavLink to='' end>
						My Subscriptions
					</NavLink>
					<NavLink to='history'>Subscription History</NavLink>
					<NavLink to='payment-details'>Payment Details</NavLink>
					<NavLink to='account-settings'>Account Settings</NavLink>
				</Box>
				<Outlet context={[setIsLoading]} />
			</div>
		</>
	);
}

export default SubscriptionOverview;
