import React from "react";
import {
  Box,
  OutlinedInput,
  FormControl,
  Select,
  InputAdornment,
  MenuItem,
  Typography,
} from "@mui/material";
import StyledLabel from "./StyledLabel";
import HtmlToolTip from "./HtmlToolTip";
import { preventMinus } from "../utils/preventNegativeValues";

function MonthYearSelector({
  data,
  handler,
  name,
  label,
  toolTipText,
  override,
  errorMessage,
  disable,
}) {
  const handleChange = (e) => {
    handler({ [e.target.name]: e.target.value });
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box sx={{ display: "flex" }}>
        <StyledLabel label={label} disable={disable} />
        {toolTipText && <HtmlToolTip content={{ text: toolTipText }} />}
      </Box>
      <FormControl
        sx={
          override
            ? override
            : {
                width: "100%",
                marginTop: "10px",
                position: "relative",
                // border:"solid 1px red",
                // borderRadius:"5px",
                // boxSizing:"border-box"
                // backgroundColor:'#ffcdd2'
              }
        }
      >
        <OutlinedInput
          fullWidth
          disabled={disable}
          name={name}
          onChange={handleChange}
          value={data?.[name] || ""}
          placeholder="0"
          margin="normal"
          sx={{
            border: errorMessage ? "solid 1px pink" : "none",
            margin: errorMessage ? "2px" : "",
          }}
          onKeyPress={preventMinus}
          inputProps={{ style: { fontSize: "1rem", border: "red" }, min: 0 }}
          type="number"
          endAdornment={
            <InputAdornment position="start">
              {
                <FormControl sx={{ width: "7.5rem" }}>
                  <Select
                    name={`${name}_month_or_year`}
                    displayEmpty={true}
                    disabled={disable}
                    error={true}
                    placeholder="none"
                    renderValue={(value) => (value ? value : <b>months</b>)}
                    sx={{
                      fieldSet: {
                        border: "none !important",
                      },
                      fontSize: "1.125rem",
                      color: "black",
                      fontWeight: "bold",
                    }}
                    onChange={handleChange}
                    value={
                      +data[`${name}_month_or_year`] === 1 ? "years" : "months"
                    }
                  >
                    {["months", "years"].map((element, index) => (
                      <MenuItem
                        value={index}
                        key={element}
                        sx={{
                          "&:hover": { backgroundColor: "#1378A5" },
                          fontSize: "1rem",
                        }}
                      >
                        {element}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              }
            </InputAdornment>
          }
        />
        <Box sx={{ display: errorMessage ? "block" : "none", width: "50%" }}>
          <Typography
            variant="h6"
            sx={{
              backgroundColor: "pink",
              padding: "5px",
              margin: "-2px auto auto 3px",
            }}
          >
            {errorMessage}
          </Typography>
        </Box>
      </FormControl>
    </Box>
  );
}

export default MonthYearSelector;
