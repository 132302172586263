import React from "react";
import { Box } from "@mui/material";

import useSelectProformaType from "../hooks/useSelectProformaType";
import ProformaPage3Type2 from "./ProformaPages/ProformaPage3Type2";
import ProformaPageActions from "./ProformaPageActions";
import ProformaLetterhead from "./ProformaPages/ProformaLetterhead";
import ProformaPage1 from "./ProformaPages/ProformaPage1";
import ProformaPage2 from "./ProformaPages/ProformaPage2";
import printStyle from "./printStyle.module.css";
import ProformaPage4 from "./ProformaPages/ProformaPage4";
import ProformaPage5 from "./ProformaPages/ProformaPage5";

const ProFormaReportWeb = ({
  address,
  previous,
  print,
  dealType,
  buttonProps,
}) => {
  const activeProforma = useSelectProformaType();
  const {
    listingArray,
    propertyInformation,
    dealFinance,
    monthlyTaxPayment,
    propertyPermitAndFees,
    remodelCost,
    frontendClosingCost,
    backendClosingCost,
    carryingCost,
    operationalExpenses,
    otherExpenses,
    estResaleValue,
    rentalIncome,
    capitalizationRate,
    netProfit,
    investorsProfit,
    user,
    resultData,
    analyzerData,
    getAllLocalCalculations,
  } = activeProforma() || {};

  return (
    <div>
      <Box sx={{ padding: "3.125rem" }}>
        <ProformaLetterhead
          agent={user}
          address={address}
          dealType={dealType}
        />
        <ProformaPage1
          listingArray={listingArray}
          propertyInformation={propertyInformation}
          dealFinance={dealFinance}
          monthlyTaxPayment={monthlyTaxPayment}
          propertyPermitAndFees={propertyPermitAndFees}
          resultData={resultData}
          printVersion={false}
        />
        <Box sx={{ mt: 2 }} />
        <div className={printStyle.pagebreak}></div>
        <ProformaPage2
          listingArray={listingArray}
          propertyInformation={propertyInformation}
          dealFinance={dealFinance}
          monthlyTaxPayment={monthlyTaxPayment}
          propertyPermitAndFees={propertyPermitAndFees}
          remodelCost={remodelCost}
          frontendClosingCost={frontendClosingCost}
          backendClosingCost={backendClosingCost}
          carryingCost={carryingCost}
          operationalExpenses={operationalExpenses}
          printVersion={false}
          resultData={resultData}
          analyzerData={analyzerData}
          getAllLocalCalculations={getAllLocalCalculations}
        />
        <Box sx={{ mt: 2 }} />
        <div className={printStyle.pagebreak}></div>
        <ProformaPage3Type2
          frontendClosingCost={frontendClosingCost}
          backendClosingCost={backendClosingCost}
          otherExpenses={otherExpenses}
          estResaleValue={estResaleValue}
          rentalIncome={rentalIncome}
          capitalizationRate={capitalizationRate}
          netProfit={netProfit}
          investorsProfit={investorsProfit}
          carryingCost={carryingCost}
          operationalExpenses={operationalExpenses}
          printVersion={false}
          resultData={resultData}
        />
        <ProformaPage4
          otherExpenses={otherExpenses}
          estResaleValue={estResaleValue}
          rentalIncome={rentalIncome}
          capitalizationRate={capitalizationRate}
          netProfit={netProfit}
          investorsProfit={investorsProfit}
          carryingCost={carryingCost}
          operationalExpenses={operationalExpenses}
          printVersion={true}
          resultData={resultData}
          analyzerData={analyzerData}
        />
        <ProformaPage5
          investorsProfit={investorsProfit || []}
          analyzerData={analyzerData}
        />
        <ProformaPageActions
          previous={previous}
          print={print}
          buttonProps={buttonProps}
        />
      </Box>
      <Box sx={{ height: "1.25rem" }} />
    </div>
  );
};

export default ProFormaReportWeb;
