import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Button, Divider, Grid, ImageList, ImageListItem } from '@mui/material';
import { useOutletContext } from 'react-router-dom';
import base, { file as fileUploader } from '../../apis';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { headerImages } from '../../constants';
import { useRef } from 'react';

const useStyles = makeStyles((theme) => ({
	root: {
		minHeight: '70vh',
		padding: '1.5rem',
	},
}));

function HeaderImage() {
	const classes = useStyles();
	//eslint-disable-next-line
	const [agent, isLoading, setIsLoading, setAgent] = useOutletContext();
	const [selectedImage, setSelectedImage] = useState(headerImages[0].img);
	const id = useSelector((state) => state.auth.typeId);
	const imageSelector = useRef(null);

	useEffect(() => {
		if (agent) {
			setSelectedImage(agent?.landing_page_image || headerImages[0].img);
		}
	}, [agent]);

	const handleImageClick = (image) => {
		setIsLoading(true);
		base
			.patch(`register/agents/${id}/`, {
				landing_page_image: image,
			})
			.then(({ data }) => {
				setAgent(data);
				toast.success('Header Image updated', { toastId: 'success' });
			})
			.catch((err) => {
				toast.error('Could update header image', { toastId: 'error' });
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const upload = async (formData) => {
		try {
			const {
				data: { image },
			} = await fileUploader.post(`operations/imagestock/`, formData);

			const { data } = await base.patch(`register/agents/${id}/`, {
				landing_page_image: image,
			});
			setAgent(data);
			toast.success('Header Image updated', { toastId: 'success' });
		} catch (err) {
			toast.error('Could not upload image');
		} finally {
			setIsLoading(false);
		}
	};

	const setImage = (e) => {
		const { files } = e.target;
		const file = files?.[0];
		if (file) {
			setIsLoading(true);
			let formData = new FormData();
			formData.append('image', file);
			upload(formData);
		}
	};

	return (
		<Box className={classes.root}>
			<p style={{ textAlign: 'center' }}>Upload an image as header of your website</p>
			<Grid spacing={2} container>
				<Grid item lg={6} padding={2}>
					<p>Click to upload a custom image</p>
					<Button
						variant='contained'
						color='secondary'
						sx={{ ml: 2 }}
						onClick={() => {
							imageSelector.current.click();
						}}
					>
						Upload
					</Button>
					<input type='file' accept='image/png, image/jpeg' name='photos' style={{ display: 'none' }} ref={imageSelector} onChange={setImage} />
				</Grid>
				<Grid item lg={6}>
					<p>Preview</p>
					<img
						src={selectedImage}
						alt={'Check'}
						style={{
							cursor: 'pointer',
							border: '2px solid white',
							borderRadius: '5px',
							width: '300px',
							aspectRatio: '4/3',
						}}
						loading='lazy'
					/>
				</Grid>
			</Grid>
			<Divider sx={{ margin: '3rem 0' }}>Or select one from the options below</Divider>
			<ImageList sx={{ width: '100%', height: '100%', mx: 'auto' }} cols={5} rowHeight={200}>
				{headerImages.map((item, index) => (
					<ImageListItem key={item.img}>
						<img
							src={`${item.img}`}
							alt={item.title}
							onClick={() => handleImageClick(item.img)}
							style={{
								cursor: 'pointer',
								border: '2px solid white',
								borderRadius: '5px',
								borderColor: item.img === selectedImage ? 'blue' : 'white',
								opacity: item.img === selectedImage ? 1 : 0.6,
								aspectRatio: '4/3',
							}}
							loading='lazy'
						/>
					</ImageListItem>
				))}
			</ImageList>
		</Box>
	);
}

export default HeaderImage;
