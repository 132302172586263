import { CheckCircle, ScheduleOutlined } from '@mui/icons-material'
import { Box, Divider, Stack, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'
import { makeStyles } from '@mui/styles'
import React from 'react'

const useStyles = makeStyles(theme => ({
  icon: {
    fontSize: '1.5rem'
  },
  title: {
    fontSize: '.8rem',
  },
  text: {
    fontSize: '.8rem',
    color: grey[600]
  }
}))


const ProgressItem = ({ status, title, date, classes }) => {
  return (
    <Box>
      <Typography mb={1} fontSize={'1rem'} color={'secondary'}>{title}</Typography>
      <Stack direction={'row'} gap={1.5} alignItems={'center'} sx={{ p: 1, mb: 1, bgcolor: '#ffaa010f' }} border={'1px solid lightgrey'} borderRadius={'10px'}>
        {status ? <CheckCircle color='primary' className={classes.icon} fontSize='small' /> : <ScheduleOutlined sx={{ color: grey[500] }} className={classes.icon} fontSize='small' />}
        <Typography variant='body2' fontSize={'.9rem'}>{status ? 'Completed' : 'Pending'}</Typography>
      </Stack>
      {date && <Typography variant='body2' color={'GrayText'} fontSize={'.9rem'}>Deadline: {new Date(status?.date).toDateString()}</Typography>}
      <Divider sx={{ my: 2, bgcolor: '#ededed' }} />
    </Box>
  )
}

const ContractStatus = ({ progress }) => {
  const classes = useStyles()
  const contractSigned = progress?.find(el => el?.status === 'signed_contract')
  const status1 = progress?.find(el => el?.status === 'lead_level_1')
  const status2 = progress?.find(el => el?.status === 'lead_level_2')
  const status3 = progress?.find(el => el?.status === 'lead_level_3')
  const status4 = progress?.find(el => el?.status === 'lead_level_4')
  const status5 = progress?.find(el => el?.status === 'lead_level_5')
  const status6 = progress?.find(el => el?.status === 'lead_level_6')

  return (
    <Box>
      <ProgressItem status={contractSigned} title={'Contract Signing'} classes={classes} date={contractSigned?.date} />
      <ProgressItem status={status1} title={'Status 1'} classes={classes} date={status1?.date} />
      <ProgressItem status={status2} title={'Status 2'} classes={classes} date={status2?.date} />
      <ProgressItem status={status3} title={'Status 3'} classes={classes} date={status3?.date} />
      <ProgressItem status={status4} title={'Status 4'} classes={classes} date={status4?.date} />
      <ProgressItem status={status5} title={'Status 5'} classes={classes} date={status5?.date} />
      <ProgressItem status={status6} title={'Status 6'} classes={classes} date={status6?.date} />


    </Box>
  )
}

export default ContractStatus