
import { useState } from 'react'
import base from '../../../../apis'
import { useSelector,useDispatch } from 'react-redux'
import { updateCompledReports, updateUncompledReports } from '../../../../actions/dealAnalyzer'

function useFetchReports() {
    const [loading,setLoading] = useState()
    const [error,setError] = useState()
    const dealReports = useSelector((state) => state.reports)
    const dispatch = useDispatch()

   

    const fetchReports = async()=>{
        setLoading(true)
        try{
            const result = await base.get(`deal_analyzer/report`)
            const{completed,uncompleted} = result?.data?.data
            dispatch(updateCompledReports(completed))
            dispatch(updateUncompledReports(uncompleted))
        }catch(err){
            setError(error)
        }finally{
            setLoading(false)
        }
    }
    const deleteReport = async(id,type) =>{
        setLoading(true)
        try{
             await base.post(`deal_analyzer/delete_report`,{ids:[{id,type}]})
            
        }catch(err){
            setError(err)
        }finally{
            setLoading(false)
        }
    }
    const bulkDelete = async(data)=>{
        setLoading(true)
        try{
            await base.post(`deal_analyzer/delete_report`,{ids:[...data]})
        }catch(err){
            setError(err)
        }
    }

  return {reportError:error, loading, dealReports,fetchReports,deleteReport,bulkDelete}
}

export default useFetchReports



