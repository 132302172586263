import { Box, TableContainer, Typography } from '@mui/material'
import {useTheme} from '@mui/material/styles'
import React from 'react'
const useStyles = (theme)=>{
    return{
        root:{
            border:" solid 1px #1378A5",
            borderRadius:" 0 0 5px 5px",
            overflowY:"hide"
        },
        root2:{
            borderRadius:" 0 0 5px 5px",
            overflowY:"hide"
        },
        title:{
            backgroundColor:"#1378A5",
           "print-color-adjust": "exact",
           "-webkit-print-color-adjust": "exact",
            border:"solid 1px #1378A5",
            padding:"10px 10px",
            borderRadius:"5px 5px 0 0",
            color:"#fff"


        },
        children:{
          padding:"10px 10px",
          display:"flex"
        }
    }
}

function SubSectionContainer({title,children,tableContainerStyles,printVersion,noTitle}) {
    const theme = useTheme()
    const styles = useStyles(theme)
  return (
    <Box sx={{width:"100%"}}>
     { !noTitle && <div style={styles.title}>
        <Typography variant={printVersion ? "h4" :"summaryContainerTitle"}>{title}</Typography>
      </div>}
    <TableContainer sx={!noTitle ? styles.root : styles.root2}>

      <Box sx={styles.children}>
        <TableContainer sx={tableContainerStyles}>{children}</TableContainer>
      </Box>
    </TableContainer>
    </Box>
  );
}

export default SubSectionContainer