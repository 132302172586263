export const loanTypes = [
  "Conventional Loan",
  "Private Hard Money",
  "Seller Financing",
];

export const loanSubTypes = ["Conventional Loan", "Interest Only "];

export const listingSource = ["BPO Homes", "MLS", "Zillow", "Others"];

export const propertyType = [
  "Single Family Home",
  "Townhouse",
  "Condo",
  "Duplex",
  "Triplex",
  "Fourplex",
  "Others",
];
