import BARicon from "../../../assets/dealAnalyzer/iconComponents/BARicon"
import NCFicon from "../../../assets/dealAnalyzer/iconComponents/NCFicon"
import RDSADicon from "../../../assets/dealAnalyzer/iconComponents/RDSADicon"
import SARicon from "../../../assets/dealAnalyzer/iconComponents/SARicon"
import Rds from "../../../assets/dealAnalyzer/iconComponents/Rds"


export const menuData = [
    {
        title:'Straight Aesthetic Remodel',
        icon:SARicon,
        info:"Straight aesthetic Remodel not intending to  add square footage to the property",
        navigation:"straight-aesthetic-remodel",
        create:"deal_analyzer/create_straight_model",
        typeId:1
    },
    {
        title:'Remodel & Add Addition',
        icon:SARicon,
        info:"Straight aesthetic Remodel not intending to  add square footage to the property",
        navigation:"straight-remodel-additional-sqft",
        create:'deal_analyzer/create_remodel_add_addition',
        typeId:2

    },
    {
        title:'Remodel Down to Studs',
        icon:Rds,
        info:"This option is for a property that needs new plumbing, electrical, roofing, major miscellaneous repairs and room layout reconfiguration",
        navigation:"remodel-down-to-studs",
        create:'deal_analyzer/create_remodel_down_studs',
        typeId:3
    },
    {
        title:'Remodel Down to Studs & Add Addition',
        icon:RDSADicon,
        info:"This option is for a property that needs a full remodel and additional square footage",
        navigation:"remodel-down-to-studs-add-addition",
        typeId:4,
        create : 'deal_analyzer/create_remodel_down_studs_addition'
    },
    {
        title:'New Construction / Full Development',
        icon:NCFicon,
        info:"This option is for a property that needs to be demolished and a complete New Home needs to be rebuilt on the site",
        navigation:"new-construction",
        typeId:5,
        create:"deal_analyzer/create_new_construction"

    },
    {
        title:'Buy & Rent',
        icon:BARicon,
        info:"This option is for a property that will be rented out",
        navigation:"buy-and-rent",
        typeId:6,
        create:"deal_analyzer/create_buy_and_rent"

    },
]