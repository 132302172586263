import React from 'react'
import { Box, Typography } from '@mui/material'
import { copyrightName } from '../userInputCards/disclaimerText'

function PrintPageFooter({currentPage,total}) {
  return (
    <Box sx = {{position:"absolute ", top:"980px",width:"100%",}}>
        <Box sx={{display:"flex",borderTop:"solid 1px #c3c3c3",width:"100%",justifyContent:"space-between",paddingTop:"0rem"}}>
            <Box sx={{justifySelf:"left"}}><Typography variant = 'h4'>{ `Report Designed By ${copyrightName}`}</Typography></Box>
            <Box sx={{justifySelf:"center",width:"55%"}}><Typography variant = 'h4' > {`Page ${currentPage} of ${total}`}</Typography></Box>
        </Box>

    </Box>
  )
}

export default PrintPageFooter