import React from "react";
import PurchasePrice from "../../atomicComponents/PurchasePrice";
import WholesaleFee from "../../atomicComponents/WholesaleFee";
import ExtraOptionsToggle from "../../atomicComponents/ExtraOptionsToggle";
import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const useStyles = (theme) => {
  return {
    root: {
      padding: "1.25rem",
    },
    heading: {
      color: theme.palette.primary.main,
      display: "flex",
      justifyContent: "center",
      marginTop: "3.125rem",
      padding: "5px",
    },
    span: {
      mt: 3,
    },
    flatRate: {
      marginTop: "1.25rem",
    },

    lenderPointsExtra: {
      marginTop: "10px",
    },
    button: {
      width: "100%",
      margin: "1.875rem 0 0 0",
      padding: "1rem",
    },
  };
};

function SecondaryCash({
  data,
  handler,
  heading,
  namePrefix,
  dealOption,
  altHeading,
  buyAndRent,
}) {
  const theme = useTheme();
  const styles = useStyles(theme);
  const showWholesale = false;

  return (
    <>
      <Box sx={{ textAlign: "center", marginBottom: "10px" }}>
        <Box sx={styles.span}>
          <Typography
            variant="heading"
            color="primary"
            sx={{ borderBottom: `solid 3px ${theme.palette.primary.main} ` }}
          >
            {heading}
          </Typography>
        </Box>
      </Box>
      <PurchasePrice
        handler={handler}
        name={
          namePrefix
            ? `${namePrefix}_purchase_price`
            : "cash_for_home_purchase_price"
        }
        label={
          altHeading || +dealOption === 4
            ? "Purchase Price"
            : +dealOption === 3
            ? "Purchase Price"
            : "Remodel Cost"
        }
        data={data}
      />

      <Box>
        {/* <Points
          handler={handler}
          label="Lender Points"
          name={namePrefix? `${namePrefix}_lender_points`:"cash_for_home_lender_points"}
          toolTipText={toolTipText.primaryLenderPointsInfo}
          data={data}
        /> */}
        {/* <ExtraOptionsToggle
          options={lenderPointOptions}
          handler={handler}
          name={namePrefix? `${namePrefix}_lender_points_options`:"cash_for_home_lender_points_options"}
          data={data}
          showText="Lender Points Options"
        /> */}
      </Box>

      {showWholesale && (
        <Box sx={styles.purchasePrice}>
          <WholesaleFee
            handler={handler}
            name={
              namePrefix
                ? `${namePrefix}_wholesale_fee`
                : "cash_for_home_wholesale_fee"
            }
            tooltipText={toolTipText.primaryWholesaleFee}
            data={data}
          />
          <ExtraOptionsToggle
            options={wholesaleFeeOptions}
            handler={handler}
            name={
              namePrefix
                ? `${namePrefix}_wholesale_fee_options`
                : "cash_for_home_wholesale_fee_options"
            }
            data={data}
            showText="Wholesale Fee Options"
          />
        </Box>
      )}
    </>
  );
}

export default SecondaryCash;
const wholesaleFeeOptions = [
  "Add Wholesale Fee to Loan Amount",
  "Add Wholesale Fee to Upfront Closing Cost",
  "Add Wholesale Fee to Back End Closing Cost",
];
const toolTipText = {
  primaryLenderPointsInfo:
    "Commission payable to Lender or Mortgage Broker for providing the loan. Usually between 0 - 3 percent of the loan amount.",
  primaryWholesaleFee:
    "This is a finders fee paid to an individual who found the home",
  primaryDownPayment:
    "The amount of cash or capital you are applying to the purchase price",
  includePmi: {
    title: "Private Mortgage Insurance",
    text: "A type of insurance that is often required for conventional loan borrowers with a down payment of less than 20% of the home's purchase price. PMI insures the lender's loan in the event the Borrower/Buyer defaults on the loan.",
  },
};
