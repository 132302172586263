import { useSelector } from "react-redux";
import base from "../../../apis";
import { getDealAnalyzerBaseUrlWithCode } from "../utils/getDealAnalyzerBaseUrlWithCode";

function useSaveNow() {
  const typeId = useSelector(
    (state) => state.straightAestheticRemodel?.dealAnalyzerReportId?.analyzerTypeId
  );
  const url = getDealAnalyzerBaseUrlWithCode(typeId);
  const id = useSelector(
    (state) => state.straightAestheticRemodel?.dealAnalyzerReportId?.id
  );

  const saveNow = async (data) => {

    try {
      await base.put(`/${url}?id=${id}`, { ...data });
    } catch (err) { }
  };
  return { saveNow };
}

export default useSaveNow;
