import React from 'react'
import { TableCell, TableRow, Typography, Box} from '@mui/material'
import useTheme from '@mui/material/styles/useTheme'
import { makeBold } from './extraStyles'
import commaSeparate from '../../utils/commaSeparate'


const useStyles = (_theme)=>{
    return{
        root:{

        },
        valueCell:{
            width:"100% !important",
            display:"flex",
            borderBottom:"solid 1px white !important",
            borderTop:"solid 2px white !important",
            padding:"0 !important",
            backgroundColor:"#f5f5f5",
            "-webkit-print-color-adjust": "exact",

        }
    }
}

function TotalRow({item,value,symbol,printVersion}) {
    const theme = useTheme()
    const styles = useStyles(theme)
  return (
    <>
    <TableRow >
        <TableCell sx={{width:"25% !important" }}>
            <Typography variant={"h4"} sx={{...makeBold}}>{item}</Typography>
        </TableCell>
        <TableCell sx={styles.valueCell}>
            <Box sx={{p:1,display:"flex"}}>
            <Typography variant={'h4'} sx ={{...makeBold, mr:2}}>{symbol}</Typography>
            <Typography variant={"h4"} sx ={{...makeBold, mr:2}}>{commaSeparate(value && value.toString()) }</Typography>
            </Box>
        </TableCell>
    </TableRow>
    </>
  )
}

export default TotalRow