import React from 'react';
import { Box, Button, Divider, Typography } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

function AdPrice({ title, setupFeeAmount, subHeader, price, interval, onClick }) {
	return (
		<Box
			textAlign={'center'}
			color='black'
			sx={{ backgroundColor: 'white' }}
			maxWidth='300px'
			ml='auto'
			borderRadius='5px'
			padding='15px 30px '
			maxHeight='100%'
		>
			<Typography variant='h6' fontSize='20px' fontWeight={500} lineHeight={1}>
				{title}
			</Typography>
			<Divider style={{ margin: '1rem auto', width: '4rem', height: '2px', background: 'var(--primary-color)' }} />
			<Typography fontWeight={300} fontSize={'14px'}>
				{subHeader}
			</Typography>
			<KeyboardArrowDownIcon color='secondary' />
			<Typography variant='h6'>{price}</Typography>
			<Typography fontWeight={300} fontSize={'12px'}>
				{interval}
			</Typography>
			{setupFeeAmount && (
				<>
					<Typography variant='body2' mt={'10px'}>
						Set Up Fee
					</Typography>
					<Typography variant='body2' fontWeight={500}>
						${setupFeeAmount}
					</Typography>
				</>
			)}
			<Button
				variant='contained'
				disableElevation
				color='primary'
				style={{ textTransform: 'none', marginTop: '1rem', color: '#fff', width: 'fit-content' }}
				onClick={onClick}
			>
				Subscribe
			</Button>
		</Box>
	);
}

export default AdPrice;
