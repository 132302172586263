import React, { useState, useEffect, useReducer, Fragment } from 'react';
import NoList from '../../assets/noListing.webp';
import NoReviews from '../../images/noReviews.webp';
import CircleIcon from '@mui/icons-material/Circle';
import Logo from '../../images/bpoVerticalLogo.webp';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import {
	Avatar,
	Box,
	Button,
	Card,
	CardContent,
	CardHeader,
	CircularProgress,
	Container,
	Dialog,
	Divider,
	FormLabel,
	Grid,
	Hidden,
	IconButton,
	InputLabel,
	Pagination,
	Rating,
	Slide,
	Stack,
	TextField,
	Tooltip,
	Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import axios from 'axios';
import { URL, ratingOptions } from '../../constants';
import { toast } from 'react-toastify';
import { formatPhoneNumber } from '../../utils/functions';
import { LoadingButton } from '@mui/lab';
import CloseIcon from '@mui/icons-material/Close';
import PlacesSearch from '../../components/PlacesSearch/PlacesSearch';
import base from '../../apis';
import useAuth from '../../components/DealAnalyzerComponents/hooks/useAuth';
import { useSelector } from 'react-redux';
import { useCallback } from 'react';
import SEO from '../../components/SEO';
import { startCase } from 'lodash';

const useStyles = makeStyles((theme) => ({
	wrap: {
		padding: '3rem 0',
	},
	profilePic: {
		height: theme.spacing(21),
		width: theme.spacing(21),
		'&>img': {
			objectPosition: 'top center',
		},
	},
	field: {
		marginTop: '.5rem',
		marginBottom: '1rem',
	},
	listImg: {
		height: theme.spacing(9),
		width: theme.spacing(14),
		borderRadius: '10px',
	},
	flexGrid: {
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'center',
	},
	header: {
		display: 'flex',
		gap: '3rem',
		justifyContent: 'flex-start',
		alignItems: 'flex-end',
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
			alignItems: 'center',
		},
	},
	container: {
		padding: '20px',
		marginLeft: 'auto',
		marginRight: 'auto',
		width: '50%',
		[theme.breakpoints.down('md')]: {
			width: '100%',
		},
	},
	body2: {
		fontWeight: '300',
		textAlign: 'justify',
		marginBottom: '1rem',
	},
}));

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction='up' ref={ref} {...props} />;
});

const AgentPage = () => {
	const classes = useStyles();
	const { id } = useParams();
	const [data, setData] = useState([]);
	const [open, setOpen] = useState(false);
	const [reviewOpen, setReviewOpen] = useState(false);
	const navigate = useNavigate();
	const [newReview, setNewReview] = useState({
		local_knowledge: 1,
		process_expertise: 1,
		responsiveness: 1,
		negotiation_skills: 1,
		description: '',
		address: '',
		year: `${new Date().getFullYear()}`,
	});

	const handleRatingChange = (e, num = false) => {
		const { name, value } = e.target;

		setNewReview({ ...newReview, [name]: num ? +value : value });
	};

	const client = useSelector((state) => state.auth.typeId);
	const [reviews, setReviews] = useState([]);
	const [isReviewLoading, setIsReviewLoading] = useState(false);
	const [pageCount, setPageCount] = useState(null);

	const getReviews = useCallback(
		(page) => {
			setReviews([]);
			setIsReviewLoading(true);
			axios
				.get(`${URL}register/agents/${id}/reviews/?page=${page || 1}`)
				.then(({ data }) => {
					setReviews(data?.results);
					setPageCount(data?.count);

					const up = document.getElementById('up');
					if (page) up?.scrollIntoView();
				})
				.catch((err) => {
					toast.error('Could not get reviews');
				})
				.finally(() => {
					setIsReviewLoading(false);
				});
		},
		[id]
	);

	useEffect(() => {
		getReviews();
	}, [getReviews]);

	const role = useSelector((state) => state.auth.role);
	const [isNewLoading, setIsNewLoading] = useState(false);
	const onNewReviewSubmit = () => {
		if (!newReview.address) {
			toast.warn('Please enter an address');
			return;
		}
		if (!newReview.year) {
			toast.warn('Please enter a year');
			return;
		}
		if (!newReview.description) {
			toast.warn('Please enter a description');
			return;
		}
		if (role !== 'client') {
			toast.warn('Only clients can post reviews');
			return;
		}

		setIsNewLoading(true);
		base
			.post(`/register/agents/${id}/reviews/`, {
				...newReview,
				client,
			})
			.then((res) => {
				toast.success('Review posted successfully');
				setReviewOpen(false);
				setNewReview({
					local_knowledge: 1,
					process_expertise: 1,
					responsiveness: 1,
					negotiation_skills: 1,
					description: '',
					address: '',
					year: `${new Date().getFullYear()}`,
				});
				//get reviews again
				getReviews(1);
			})
			.catch((err) => {
				toast.error('Error posting review');
			})
			.finally(() => {
				setIsNewLoading(false);
			});
	};

	const { checkAuth, openLogin } = useAuth();
	const [searchParams, setSearchParams] = useSearchParams();

	const onWriteReview = () => {
		if (!checkAuth()) {
			openLogin();
			toast.info('Please login to write a review');
			localStorage.setItem('nextRoute', window.location.pathname + '?review=true');
			return;
		}

		if (review) {
			searchParams.delete('review');
			setSearchParams(searchParams);
		}

		setReviewOpen(true);
	};

	// const review = searchParams.get('review');

	const review = React.useRef(searchParams.get('review'));

	useEffect(() => {
		const fetchAgent = () => {
			const urls = [URL + `register/agents/${id}`, URL + `premiersite/listings?agent_id=${id}`];

			Promise.all(urls.map((endpoint) => axios.get(endpoint)))
				.then((responseData) => {
					const agentData = responseData[0].data;
					const listing = responseData[1].data;
					const agent = {
						...agentData,
						listing,
					};

					setData(agent);
					if (review.current) {
						onWriteReview();
					}
				})
				.catch((error) => {
					if (error?.response?.data?.detail === 'Not found.') {
						navigate('/404');
						return;
					}
					toast.warn('Something went wrong');
				});
		};

		fetchAgent();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id, navigate]);

	const formState = {
		name: '',
		email: '',
		phone: '',
		message: '',
	};

	const formReducer = (state, action) => {
		switch (action.type) {
			case 'NAME':
				return { ...state, name: action.payload };
			case 'PHONE':
				return { ...state, phone: action.payload.trim() };
			case 'EMAIL':
				return { ...state, email: action.payload.trim() };
			case 'MSG':
				return { ...state, message: action.payload };
			case 'RESET':
				return formState;
			default:
				return state;
		}
	};

	const [formInput, dispatch] = useReducer(formReducer, formState);

	const onFormSubmit = () => {
		//if checks
		if (formInput.name === '' || !formInput.email.includes('@') || formInput.phone === '' || formInput.message === '') {
			return;
		}
		const data = {
			client_name: formInput.name,
			client_email: formInput.email,
			client_phone: formInput.phone,
			client_message: formInput.message,
			agent_id: id,
		};
		setTimeout(async () => {
			try {
				const res = await axios.post(URL + 'register/contact_agent/', data);
				if (res) {
					//console.log(res.data)
					dispatch({ type: 'RESET' });
					setOpen(false);
					toast.success('Message sent successfully');
					// alert('Message sent successfully');
				}
			} catch (error) {
				//console.log(error)
				toast.warn('Sorry, something went wrong');
			}
		});
	};

	const up = React.useRef(null);
	const handlePageChange = (e, page) => {
		getReviews(page);
	};
	const [isBioShort, setIsBioShort] = useState(true);
	const [isServShort, setIsServShort] = useState(false);

	return (
		<div className={classes.wrap}>
			{!!data && (
				<SEO>
					<title>
						{`${startCase(data?.first_name || '')} ${startCase(data?.last_name || '')}: Real Estate ${data?.agent_status || ''} in ${
							data?.city || ''
						}, ${data?.state || ''}, ${data?.zip_code || ''}`}
					</title>
				</SEO>
			)}
			<Container>
				<Grid container spacing={5}>
					<Grid item lg={7}>
						<Box className={classes.header}>
							<Avatar className={classes.profilePic} src={data?.profile_image} alt='profile_pic' />
							<Stack>
								<Typography variant='h5' sx={{ textTransform: 'capitalize' }}>
									{(data?.first_name || '') + ' ' + (data?.last_name || '')}
								</Typography>
								<Typography color={'textPrimary'} variant='body1'>
									{data?.brokerage_name || 'BPO Homes'}
								</Typography>
								<Typography color={'textSecondary'} variant='body1'>
									{`BPO ${data.subscription ? 'Premier' : 'Partner'} Agent`}
								</Typography>
								<span style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
									<Typography variant='body2' color='textSecondary'>
										{Number(data.average_review || 5).toFixed(1)}
									</Typography>
									<Rating size='small' value={data.average_review || 5} readOnly />
									<Typography
										variant='body2'
										color='textSecondary'
										sx={{ cursor: 'pointer', textDecoration: 'underline' }}
										onClick={() => {
											const up = document.getElementById('up');
											up.scrollIntoView({ behavior: 'smooth' });
										}}
									>
										{pageCount} Reviews
									</Typography>
								</span>
								<Box display='flex' gap='10px'>
									{data?.subscription && data?.web_link && (
										<Button
											disableElevation
											variant='contained'
											color='secondary'
											target='_blank'
											href={data?.web_link}
											sx={{ textTransform: 'none', marginTop: '10px' }}
										>
											Visit Website
										</Button>
									)}
								</Box>
							</Stack>
						</Box>

						{/* About */}
						<Box margin={'3rem 0'}>
							<Typography variant='h5' gutterBottom>
								About Me
							</Typography>
							{data?.specialties?.length > 0 && (
								<Typography variant='h6' color={'secondary'} gutterBottom>
									Specialities:{' '}
									<Typography variant='body1' sx={{ color: 'black', display: 'inline-block', fontWeight: '300' }}>
										{data?.specialties?.map((spec) => spec.title)?.join(', ') || 'Not Provided'}
									</Typography>
								</Typography>
							)}

							{data?.bio && (
								<>
									<Typography variant='body1' sx={{ whiteSpace: 'pre-line' }}>
										{isBioShort ? (data?.bio || '')?.slice(0, 200) + (data?.bio?.length > 200 ? '...' : '') : data?.bio}
									</Typography>
									{data?.bio?.length > 200 && (
										<Button
											sx={{ textTransform: 'capitalize', marginTop: 1 }}
											color='secondary'
											variant='outlined'
											onClick={() => setIsBioShort((v) => !v)}
										>
											{isBioShort ? 'Show more' : 'Hide'}
										</Button>
									)}
								</>
							)}
						</Box>

						{/* Listing Areas */}
						<Box marginBottom={'3rem'}>
							<Typography variant='h5' gutterBottom>
								Agent Listings
							</Typography>
							{/* <Typography paragraph color={'secondary'}>
								Most recent listings and sales around your area.
							</Typography> */}
							<Card variant='outlined'>
								{data?.listing?.length > 0 && (
									<Box display='flex' justifyContent='flex-start' mb={0} mt='10px' gap='10px' px={1}>
										<Box display='flex' gap='5px' alignItems='center'>
											<CircleIcon fontSize='2px' color='primary' />
											<span>Seller Agent</span>
										</Box>
										<Box display='flex' gap='5px' alignItems='center'>
											<CircleIcon fontSize='2px' color='secondary' />
											<span>Buyer Agent</span>
										</Box>
									</Box>
								)}
								<CardContent>
									<Box sx={{ maxHeight: '50vh', overflowY: 'auto' }}>
										{/* <Typography variant='h5' mb={2}>
											Agent Listings
										</Typography> */}
										<Grid container>
											<Grid item xl={10} md={10} sm={8} xs={8}>
												<Typography color='secondary'>Address</Typography>
											</Grid>
											{/* <Grid item xl={4}>
												<Typography color='secondary'>Date Created</Typography>
											</Grid> */}
											<Grid item xl={2} md={2} sm={4} xs={4}>
												<Typography color='secondary'>Price</Typography>
											</Grid>
											{/* <Grid item xl={2}>
							<Typography color='secondary'>Represented</Typography>
						</Grid> */}
											<Divider style={{ margin: '1rem 0' }} />
										</Grid>

										{/* populate listings */}
										{data?.listing && data.listing.length > 0 ? (
											data.listing.map((list) => {
												return (
													<Card
														variant='outlined'
														key={list.id}
														style={{
															border: 'none',
															borderBottom: `1px solid ${grey[400]}`,
															borderRadius: 0,
															cursor: 'pointer',
														}}
														onClick={() => navigate(`/view-listing/${id}/${list.id}#home`)}
													>
														<CardContent sx={{ overflow: 'unset' }}>
															<Grid container alignItems='flex-start' sx={{ position: 'relative' }}>
																<Tooltip title={list.agent_role || 'Seller Agent'}>
																	<CircleIcon
																		fontSize='2px'
																		color={list.agent_role === 'Seller Agent' ? 'primary' : 'secondary'}
																		sx={{ position: 'absolute', right: '-20px', top: '5px' }}
																	/>
																</Tooltip>

																<Grid item xl={10} md={10} sm={8} xs={8} style={{ display: 'flex', gap: '1rem' }}>
																	<Hidden smDown>
																		<Avatar
																			className={classes.listImg}
																			src={list.photos.length > 0 ? list.photos[0].image : null}
																			alt='listing_pic'
																		/>
																	</Hidden>
																	<Stack>
																		<Typography>{list?.address}</Typography>
																		{/* <Typography>{list?.state}</Typography>
																		<Typography variant='body2'>{list?.city}</Typography> */}
																	</Stack>
																</Grid>
																{/* <Grid item xl={4} className={classes.flexGrid}>
																	<Typography>{new Date(list?.date_created).toLocaleDateString('en-US')}</Typography>
																</Grid> */}
																<Grid item xl={2} md={2} sm={4} xs={4} className={classes.flexGrid} sx={{ flexDirection: 'column' }}>
																	<Typography>$ {list?.[!list.date_sold ? 'list_price' : 'sold_price']?.toLocaleString()}</Typography>
																	<Typography fontWeight={300} fontSize={'13px'}>
																		{list?.sold_price && list.date_sold ? 'Sold' : 'Active'}
																	</Typography>
																</Grid>
																{/* <Grid item xl={2} className={classes.flexGrid}>
												<Typography>{list.agent_role || 'Seller'}</Typography>
											</Grid> */}
															</Grid>
														</CardContent>
													</Card>
												);
											})
										) : (
											<Box height={'20rem'} bgcolor={grey[200]} padding={'4rem'} textAlign='center' borderRadius={'10px'}>
												<img src={NoList} alt='no-listing' width={'15%'} style={{ marginBottom: '10px' }} />
												<Typography paragraph>Sorry, no listing available</Typography>
											</Box>
										)}
									</Box>
								</CardContent>
							</Card>
						</Box>
					</Grid>

					<Grid item lg={5}>
						{/* Info Box */}
						<Box
							padding={'2rem'}
							marginBottom={'2rem'}
							borderRadius='10px'
							color={'#fff'}
							style={{ backgroundImage: 'linear-gradient(310deg, #0d5384, #014ab19c)' }}
						>
							<Typography variant='h6' gutterBottom>
								Work as a team on BPO Homes
							</Typography>
							<Typography variant='body2'>You can contact this agent and request a BPO report, alongside other things</Typography>
						</Box>

						<Card variant='outlined' sx={{ marginBottom: '2rem' }}>
							<Typography sx={{ margin: '1rem 2rem' }} variant='h6'>
								Professional Details
							</Typography>
							<Divider sx={{ margin: '1rem 0', background: grey[200] }} />
							<CardContent style={{ padding: ' 1rem 2rem' }}>
								<Grid container rowSpacing={1}>
									<Grid item xl={6} md={6} xs={6} sm={6}>
										<Typography>Broker address:</Typography>
									</Grid>
									<Grid item xl={6} md={6} xs={6} sm={6}>
										<Typography variant='body2' color={'textSecondary'}>
											{data?.brokerage_address + ' ' + data?.state + ' ' + data?.city + ', ' + data?.zip_code}
										</Typography>
									</Grid>
									<Grid item xl={6} md={6} xs={6} sm={6}>
										<Typography>Phone number:</Typography>
									</Grid>
									<Grid item xl={6} md={6} xs={6} sm={6}>
										<Typography variant='body2' color={'textSecondary'}>
											{formatPhoneNumber(data?.phone) || data?.phone || 'Not Provided'}
										</Typography>
									</Grid>
									<Grid item xl={6} md={6} xs={6} sm={6}>
										<Typography>Estate Licenses:</Typography>
									</Grid>
									<Grid item xl={6} md={6} xs={6} sm={6}>
										<Typography variant='body2' color={'textSecondary'}>
											{data?.licenses?.find((license) => license.category === 'real_estate_agent')?.number || 'Not Provided'}
										</Typography>
									</Grid>
									<Grid item xl={6} md={6} xs={6} sm={6}>
										<Typography>Service Areas:</Typography>
									</Grid>
									<Grid item xl={6} md={6} xs={6} sm={6}>
										<Typography variant='body2' color={'textSecondary'}>
											{data?.service_areas
												?.slice(0, !isServShort ? 5 : Infinity)
												?.map((location) => location.location)
												?.join(', ') || 'Not Provided'}
											{data?.service_areas?.length > 5 && (
												<Button
													variant='text'
													sx={{ textTransform: 'capitalize', textDecoration: 'underline' }}
													color='secondary'
													onClick={() => {
														setIsServShort((v) => !v);
													}}
												>
													{!isServShort ? 'Show more' : 'Show less'}
												</Button>
											)}
										</Typography>
									</Grid>
									<Grid item xl={6} md={6} xs={6} sm={6}>
										<Typography>Screen Name:</Typography>
									</Grid>
									<Grid item xl={6} md={6} xs={6} sm={6}>
										<Typography variant='body2' color={'textSecondary'}>
											{data?.screen_name}
										</Typography>
									</Grid>
									<Grid item xl={6} md={6} xs={6} sm={6}>
										<Typography>Languages:</Typography>
									</Grid>
									<Grid item xl={6} md={6} xs={6} sm={6}>
										<Typography variant='body2' color={'textSecondary'}>
											{data?.languages?.map((lang) => lang.name)?.join(', ') || 'Not Provided'}
										</Typography>
									</Grid>
								</Grid>
							</CardContent>
						</Card>

						{/* Form */}
						<Card elevation={4} id='contact'>
							<CardHeader title='Contact Agent' sx={{ textAlign: 'center', padding: '2rem' }} />
							<Divider />
							<CardContent style={{ padding: '2.5rem 3rem' }}>
								<Stack>
									<FormLabel>Name</FormLabel>
									<TextField
										size='small'
										className={classes.field}
										fullWidth
										variant='outlined'
										placeholder='Enter Fullname'
										value={formInput.name}
										onChange={(e) => dispatch({ type: 'NAME', payload: e.target.value })}
									/>
								</Stack>
								<Stack>
									<FormLabel>Phone</FormLabel>
									<TextField
										size='small'
										className={classes.field}
										type={'number'}
										fullWidth
										variant='outlined'
										placeholder='Enter Phone Number'
										value={formInput.phone}
										onChange={(e) => dispatch({ type: 'PHONE', payload: e.target.value })}
									/>
								</Stack>
								<Stack>
									<FormLabel>Email</FormLabel>
									<TextField
										size='small'
										className={classes.field}
										type={'email'}
										fullWidth
										variant='outlined'
										placeholder='Enter Email Address'
										value={formInput.email}
										onChange={(e) => dispatch({ type: 'EMAIL', payload: e.target.value })}
									/>
								</Stack>
								<Stack>
									<FormLabel>Message</FormLabel>
									<TextField
										size='small'
										className={classes.field}
										fullWidth
										multiline
										rows={4}
										variant='outlined'
										placeholder='How can we help?'
										value={formInput.message}
										onChange={(e) => dispatch({ type: 'MSG', payload: e.target.value })}
									/>
								</Stack>
								<LoadingButton
									onClick={onFormSubmit}
									disableElevation
									loading={open}
									variant='contained'
									fullWidth
									color='secondary'
									sx={{ textTransform: 'none', marginTop: '10px', height: '3.5rem' }}
								>
									SUBMIT
								</LoadingButton>
							</CardContent>
						</Card>
					</Grid>

					<Grid item xl={12}>
						<Box display='flex' justifyContent='space-between' mb='5px'>
							<Typography variant='h5' sx={{ fontWeight: 'bold', marginBottom: '10px', scrollMarginTop: 'var(--navheight)' }} ref={up} id='up'>
								Ratings & Reviews
							</Typography>
							<Button color='secondary' sx={{ textTransform: 'capitalize', textDecoration: 'underline' }} onClick={onWriteReview}>
								Write a Review
							</Button>
						</Box>
						{!isReviewLoading ? (
							reviews.length > 0 ? (
								reviews.map((review) => (
									<Fragment key={review.id}>
										<Card sx={{ padding: '10px', marginBottom: '10px' }}>
											<Grid container spacing={2}>
												<Grid item xl={12} sm={12} fontWeight={300}>
													{new Date(review?.date_created).toLocaleDateString('en-US')} -{' '}
													{`${
														review?.client?.user?.first_name || review?.client?.user?.last_name
															? review?.client?.user?.first_name + ' ' + review?.client?.user?.last_name
															: review?.name
													} `}
												</Grid>
												<Grid item xl={4} sm={6} md={5}>
													<Grid container spacing={0} alignItems='flex-end'>
														{ratingOptions.map((item, index) => (
															<Fragment key={item.value}>
																<Grid item xl={6} lg={6} xs={6} key={item.value}>
																	<Typography variant='body2'>{item.label}</Typography>
																</Grid>
																<Grid item xl={6} lg={6} xs={6}>
																	<Rating readOnly value={review[item.value] || 1} />
																</Grid>
															</Fragment>
														))}
													</Grid>
												</Grid>
												<Grid item xl={8} sm={6} md={7} textAlign='justify'>
													{review.description}
												</Grid>
											</Grid>
										</Card>
									</Fragment>
								))
							) : (
								<Box bgcolor={grey[200]} padding={'4rem'} textAlign='center' borderRadius={'10px'}>
									<img src={NoReviews} alt='no-reviews' width={'15%'} style={{ marginBottom: '10px' }} />
									<Typography paragraph>Sorry, no reviews available</Typography>
									<Button color='secondary' variant='contained' sx={{ textTransform: 'capitalize', marginBottom: '1rem' }} onClick={onWriteReview}>
										Write a Review
									</Button>
								</Box>
							)
						) : (
							<Box height={'20rem'} padding={'4rem'} display='flex' alignItems='center' justifyContent='center' borderRadius={'10px'}>
								<CircularProgress />
							</Box>
						)}
					</Grid>
					<Grid item xl={12} display='flex' justifyContent='center'>
						<Pagination
							size='large'
							color='secondary'
							sx={{
								display: 'flex',
								justifyContent: 'center',
							}}
							shape='rounded'
							variant='outlined'
							count={Math.ceil(pageCount / 5)}
							onChange={handlePageChange}
						/>
					</Grid>
				</Grid>
			</Container>
			<Dialog fullWidth fullScreen open={reviewOpen} onClose={() => setReviewOpen(true)} TransitionComponent={Transition}>
				<Container maxWidth='xl' sx={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: '400' }}>
					<Box padding={'1rem 0'} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', position: 'relative' }}>
						<img src={Logo} width='15%' style={{ width: '15%', minWidth: '100px' }} alt='logo' />
						<Box display='flex' gap='10px' alignItems='center'>
							<Typography variant='body2' color={'textSecondary'} sx={{ flex: 1 }} textAlign='end'>
								<Hidden smDown>Need Help?</Hidden> <span style={{ color: '#000' }}>Call 1(888) 616-5270</span>
							</Typography>
							<IconButton
								onClick={() => {
									if (isNewLoading) return;
									setReviewOpen(false);
								}}
							>
								<CloseIcon fontSize='small' />
							</IconButton>
						</Box>
					</Box>
					<Divider sx={{ bgcolor: grey[200] }} />
				</Container>

				<Box className={classes.container}>
					<Typography paragraph>Request Reviews</Typography>
					{/* <Typography variant='body2'>How likely are you to recommend {(data?.first_name || '') + ' ' + (data?.last_name || '')}?</Typography> */}
					<Grid container spacing={2} alignItems='flex-end'>
						{ratingOptions.map((item, index) => (
							<Fragment key={item.value}>
								<Grid item xl={4} lg={4} xs={6}>
									<Typography variant='body2'>{item.label}</Typography>
								</Grid>
								<Grid item xl={8} lg={8} xs={6}>
									<Rating onChange={(e) => handleRatingChange(e, true)} name={item.value} value={newReview[item.value]} />
								</Grid>
							</Fragment>
						))}
					</Grid>
					<Box my='1rem'>
						<InputLabel>Describe your experience with {(data?.first_name || '') + ' ' + (data?.last_name || '')}</InputLabel>
						<TextField
							multiline
							rows={4}
							variant='outlined'
							fullWidth
							value={newReview.description}
							name='description'
							onChange={handleRatingChange}
						/>
					</Box>
					<Box mt='1rem'>
						<InputLabel>Year of Service</InputLabel>
						<TextField
							placeholder='YYYY'
							variant='outlined'
							fullWidth
							type='number'
							min='2000'
							value={newReview.year}
							name='year'
							onChange={handleRatingChange}
							max={new Date().getFullYear()}
							inputProps={{
								min: 2000,
								max: new Date().getFullYear(),
								step: 1,
								type: 'number',
							}}
						/>
					</Box>
					<Box mt='1rem'>
						<InputLabel>Full Address</InputLabel>
						<PlacesSearch
							placeholder={'Enter Complete Address'}
							variant='outlined'
							mui={true}
							fullWidth
							helperText='This would not be published'
							value={newReview.address}
							name='address'
							onChange={(value) => setNewReview({ ...newReview, address: value })}
						/>
					</Box>
					<LoadingButton
						color='secondary'
						loading={isNewLoading}
						variant='contained'
						sx={{ textTransform: 'capitalize', marginLeft: 'auto', display: 'block', marginTop: '1rem', marginBottom: '1rem' }}
						onClick={onNewReviewSubmit}
					>
						Submit Review
					</LoadingButton>
				</Box>
			</Dialog>
		</div>
	);
};

export default AgentPage;
