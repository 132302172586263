import React from 'react'
import EnhancedRadioButtonGroup from '../../atomicComponents/EnhancedRadioButtonGroup'
import useTheme from "@mui/material/styles/useTheme";
import { Box, Typography } from '@mui/material';
import StyledLabel from '../../atomicComponents/StyledLabel';

const useStyle = (theme) => {
  return {
    root: {
      padding: "1.25rem",
    },
    heading: {
        color:theme.palette.primary.main,
        display:"flex",
        justifyContent:"center",
        margin:"1.25rem 0",
        padding:"5px"
    },
    span:{
        borderBottom:`solid 3px ${theme.palette.primary.main} `
    }
  };
};

function LoanTypeSelector({data,handler,options,headings,subOptions,loanTypeOff,headingOverride}) {
  const theme = useTheme();
  const styles = useStyle(theme);

  const getDefaultChoice = () =>{
    if(data?.primary_loan_type){
        return data.primary_loan_type
    }else{
        return ''
    }
  }


  const getHeading =()=>{

    const option = +data?.deal_finance_option
    if(!option) return headings[0]

    switch(option){
        case 0: return headings?.[0];
        case 1: return headingOverride ? headings?.[1]: headings?.[0]
        case 2: return headings?.[1];
        case 3: return headings?.[2];
        case 4: return headings?.[3];
        case 5: return headings?.[4];
        default: return  headings?.[0]

    }

  }


  const getSubLoanType = ()=>{
    return data?.primary_sub_loan_type 
  }

  const setPrimaryLoanType = (value)=>{
    handler({primary_loan_type: value})
  }


  const setSubLoan = (value)=>{
    handler({primary_sub_loan_type : value})
  }


  const additionalStyles = {
    display: "flex",
    justifyContent: "space-between",
  };
  const extraStyles = {
    display: "flex",
    justifyContent: "flex-start",
  };

  return (
    <>
      <Box sx={styles.heading}>
        <Box component={"span"} sx={styles.span}>
          <Typography variant="heading">{typeof (headings) === 'string'? headings : getHeading()}</Typography>
        </Box>
      </Box>
      {(+data?.deal_finance_option !== 4 && !loanTypeOff) && (
        <>
        <Box>
          <StyledLabel label="Loan Type" />
          <EnhancedRadioButtonGroup
            options={options}
            handler={setPrimaryLoanType}
            choice={getDefaultChoice()}
            styles={additionalStyles}
          />
        </Box>
        {(+data?.primary_loan_type === 1 || +data?.primary_loan_type === 2) && (
        <Box>
          <StyledLabel label="The loan is" />
          <EnhancedRadioButtonGroup
            options={subOptions}
            handler={setSubLoan}
            choice={
              +getSubLoanType()
            }
            styles={extraStyles}
            radioOverride={{ marginRight: "3.125rem" }}
          />
        </Box>
      )}
        </>

      )}
    </>
  );
}

export default LoanTypeSelector



