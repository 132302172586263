import React from 'react'
import { TableCell, TableRow, Typography, Box,} from '@mui/material'
import useTheme from '@mui/material/styles/useTheme'
import commaSeparate from '../../utils/commaSeparate'
import { makeBold } from './extraStyles'

const useStyles = (_theme)=>{
    return{
        root:{

        },
        valueCell:{
            width:"100% !important",
            display:"flex",
            borderBottom:"solid 1px white !important",
            borderTop:"solid 2px white !important",
            padding:"0 !important",
            backgroundColor:"#f5f5f5",
            "print-color-adjust": "exact",
            "-webkit-print-color-adjust": "exact",

        }
    }
}

function ProformaStartAdornRow({item,value,symbol,printVersion,bold}) {
    const theme = useTheme()
    const styles = useStyles(theme)
  return (
    <>
    <TableRow >
        <TableCell sx={{width:"60% !important" }}>
            <Typography variant={"h4"} sx={bold? makeBold : {}}>{item}</Typography>
        </TableCell>
        <TableCell sx={styles.valueCell}>
            <Box sx={{p:1,display:"flex",}}>
            <Typography variant={"h4"} sx ={{mr:2,...makeBold}}>{symbol}</Typography>
            <Typography variant={"h4"} sx={makeBold}>{value === 0 ? 0 : commaSeparate(value)}</Typography>
            </Box>
        </TableCell>
    </TableRow>
    </>
  )
}

export default ProformaStartAdornRow