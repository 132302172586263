import {
	Box,
	Container,
	Divider,
	Grid,
	InputAdornment,
	styled,
	TextField,
	Typography,
	Button,
	Card,
	CardActions,
	CardContent,
	CardMedia,
	Chip,
} from '@mui/material';
import React, { useState } from 'react';
import HomeBG from '../../images/blog1.webp';
import BuyerCheck from '../../images/buycheck.webp';
import BuyerFirst from '../../images/buyerfirst.webp';
import BuyerScore from '../../images/buyerscore.webp';
import BPOGuide from '../../images/bpoguide.webp';
import SellerBG from '../../images/sellerguide.webp';
import HomeImprove from '../../images/homeimprove.webp';
import Explore from '../../images/explore.webp';
import Relocation from '../../images/relocation.webp';
import Upgrading from '../../images/upgrading.webp';
import Financial from '../../images/Financial.webp';
import LifeStyle from '../../images/lifestyle.webp';

import { Search } from '@mui/icons-material';
import { useParams } from 'react-router-dom';

const Banner = styled(Box)(({ theme }) => ({
	height: '40vh',
	backgroundImage: `url(${HomeBG})`,
	backgroundPosition: 'center',
	backgroundSize: 'cover',
}));

const posts = [
	{
		title: 'Relocation: Selling Your Home For A Smooth Move',
		desc: 'Relocation can be a stressful and overwhelming process, especially when it comes to selling your home. Selling your home before relocating has many benefits, including financial stability and peace of mind.',
		image: Relocation,
		link: 'relocation',
	},
	{
		title: 'Upgrading Or Downsizing: Finding The Perfect Home For Your Changing Needs',
		desc: 'Housing needs often change over time, leading to a decision between upgrading or downsizing. Upgrading refers to moving to a larger home with more features and amenities, while downsizing involves moving to a smaller home that better fits current requirements. This decision can be influenced by various factors such as family size, financial situation, location preferences, and lifestyle changes.',
		image: Upgrading,
		link: 'upgrading-or-downsizing',
	},
	{
		title: 'Financial Considerations: Selling Your Home To Improve Your Financial Health',
		desc: `In today's economy, many individuals are faced with financial challenges that can impact their overall health and well-being. One option to improve your financial health is to consider selling your home. While this decision can be difficult and emotional, it is important to approach it in a strategic and analytical manner.`,
		image: Financial,
		link: 'financial-considerations',
	},
	{
		title: 'Lifestyle Changes: Selling Your Home For A Fresh Start',
		desc: `The decision to sell one's home can be a difficult and emotional process, especially when prompted by significant life events such as divorce, marriage, or the loss of a loved one. However, selling one's home can also provide an opportunity for a fresh start and new beginnings.`,
		image: LifeStyle,
		link: 'lifestyle',
	},
	{
		title: 'Your Best Checklist for Buying a Home',
		desc: 'Learn the best steps for buying a home with this comprehensive checklist. From calculating your monthly housing budget to arranging for a house inspection, this guide has you covered.',
		image: BuyerCheck,
		link: 'buyer-checklist',
	},
	{
		title: 'Buying a Home in 2023?',
		desc: 'We know how challenging the entire process can be. You can navigate your first experience and make it as painless as you can by following the advice provided here.',
		image: BuyerFirst,
		link: 'buyer-firsttimer',
	},
	{
		title: 'How to Raise Your Credit Score to Get Your First Home Mortgage',
		desc: "Do you want to raise your credit score? Yes, it's simple to become enamored with the concept of home ownership.",
		image: BuyerScore,
		link: 'buyer-score',
	},
	{
		title: 'Why Buyers & Sellers Need A Broker’s Price Opinion',
		desc: 'A broker price opinion (BPO) is an estimated value of a property made by a real estate broker or professional.',
		image: BPOGuide,
		link: 'bpo-guide',
	},
	{
		title: "Seller's Guide",
		desc: 'Discover the best month and day to list your home, how seasonality influences home sales, and what to consider before you sell.',
		image: SellerBG,
		link: 'sellers-guide',
	},
	{
		title: 'Home Improvement and Remodeling Trends for 2023',
		desc: 'Home remodeling and renovation can be a terrific method to raise the value of your home and give it the appearance you want.',
		image: HomeImprove,
		link: 'home-improvement',
	},
	{
		title: 'Explore. Sell Your Home Easily with a Participating BPO Home Premier Agent',
		desc: 'Get local market knowledge, lower listing costs, and expert support with negotiating and evaluating offers when you list your property with a participating BPO Home Premier Agent.',
		image: Explore,
		link: 'explore',
	},
];

const Blog = () => {
	const [search, setSearch] = useState(posts);
	const { id: agentId } = useParams();

	const onSearch = (e) => {
		if (e.target.value === '') {
			setSearch(posts);
			return;
		}
		const filtered = posts?.filter((el) => el.title.toLowerCase().includes(e.target.value.toLowerCase()));
		setSearch(filtered);
	};

	return (
		<div style={{ marginBottom: '10rem' }}>
			<Banner />
			<Box sx={{ background: (theme) => theme.secondaryColor, padding: '4rem', color: '#fff' }}>
				<Typography textAlign={'center'} gutterBottom variant='h4'>
					Our Blogs
				</Typography>
				<Divider sx={{ width: '7rem', height: '3px', margin: '1rem auto', background: (theme) => theme.primaryColor }} />
			</Box>
			<Container maxWidth='xl'>
				<Box my={'4rem'}>
					<TextField
						type={'search'}
						onChange={(e) => onSearch(e)}
						sx={{ mb: '2rem' }}
						variant='outlined'
						fullWidth
						placeholder='Search for blogs'
						InputProps={{
							endAdornment: (
								<InputAdornment position='start'>
									<Search fontSize='small' color='#eee' />
								</InputAdornment>
							),
						}}
					/>
					<Grid container spacing={3}>
						{search?.map((el) => {
							return (
								<Grid key={el.title} item xs={12} sm={6} md={4} lg={3}>
									<BlogItem image={el.image} title={el.title} desc={el.desc} link={el.link} agentId={agentId} />
								</Grid>
							);
						})}
					</Grid>
				</Box>
			</Container>
		</div>
	);
};

export default Blog;

const BlogItem = ({ title, desc, location, image, closed, link, agentId }) => {
	return (
		<Card
			variant='outlined'
			elevation={0}
			sx={{
				borderRadius: '10px',
				'& .MuiCardMedia-root': {
					height: '180px',
				},
			}}
		>
			<CardMedia alt='foods' image={image}>
				<Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '15px' }}>
					{closed && <Chip size='small' color='secondary' label={<Typography variant='body2'>Closed</Typography>} />}
					<Chip
						size='small'
						color='primary'
						label={
							<Typography variant='body2' sx={{ fontSize: '.8rem' }}>
								{2023}
							</Typography>
						}
					/>
				</Box>
			</CardMedia>
			<CardContent>
				<Typography variant='h6' noWrap sx={{ fontWeight: 500, fontSize: '1.2rem' }}>
					{title}
				</Typography>
				<Typography variant='body1' noWrap mb={1} color={'GrayText'}>
					{location}
				</Typography>
				<Typography
					variant='body2'
					sx={{
						display: '-webkit-box',
						'-webkit-line-clamp': '3',
						' -webkit-box-orient': 'vertical',
						overflow: 'hidden',
						'text-overflow': 'ellipsis',
					}}
					mb={1}
					color={'GrayText'}
				>
					{desc}
				</Typography>
			</CardContent>
			<Divider />
			<CardActions sx={{ padding: '1rem', display: 'flex', justifyContent: 'space-between' }}>
				<Button size='small' variant='text' color='secondary' href={`/agent/${agentId}/blogs/${link}`} disableElevation sx={{ borderRadius: '5px' }}>
					Read More
				</Button>
				{/* <Typography variant='h6' sx={{fontWeight: 700, fontSize: '1rem'}}>GHc{price}</Typography> */}
			</CardActions>
		</Card>
	);
};
