import React, { useLayoutEffect, useEffect } from 'react';
import { Outlet, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import Footer from './Footer/Footer';
import Navbar from './Navbar/Navbar';
import { googleOAuth, facebookOAuth } from '../actions';
import { useDispatch } from 'react-redux';
import { useGoogleOneTapLogin } from '@react-oauth/google';
import { toast } from 'react-toastify';

function PublicRoute() {
	const { pathname } = useLocation();
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();

	useGoogleOneTapLogin({
		onSuccess: (response) => {
			const { credential } = response;
			dispatch(googleOAuth(credential, navigate, true));
		},
		onError: (error) => {
			toast.error('Something went wrong');
		},
	});

	useEffect(() => {
		const id = searchParams.get('affiliateId');
		const ghanaian = searchParams.get('ghanaianmarkerter_id');
		if (id) {
			localStorage.setItem('affiliateId', id);
		}
		if (ghanaian && ghanaian !== 'None') {
			localStorage.setItem('ghanaian_marketer', ghanaian);
		}
	}, [searchParams]);

	const facebookLink = useLocation().search.split('&')[2];
	const path = useLocation().search;
	const location = useLocation().pathname;
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch({ type: 'STOP_LOADING' });
		if (path) {
			if (facebookLink?.startsWith('code')) {
				const facebookAuthCode = path.split('&')[2].split('=')[1];
				dispatch(facebookOAuth(facebookAuthCode, navigate));
			}
		}
	}, [path, location, facebookLink, dispatch, navigate]);

	useLayoutEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);
	return (
		<>
			<Navbar />
			<Outlet />
			<Footer />
		</>
	);
}

export default PublicRoute;
