import React, { useState, useRef, useEffect, useCallback } from "react";
import EnhancedRadioButtonGroup from "../../atomicComponents/EnhancedRadioButtonGroup";
import useTheme from "@mui/material/styles/useTheme";
import { Box, Button, Divider, Typography } from "@mui/material";
import { BiPlus } from "react-icons/bi";
import ClipLoader from "react-spinners/ClipLoader";

import StyledLabel from "../../atomicComponents/StyledLabel";
import PrimaryHomeLoan from "./PrimaryHomeLoan";
import LoanTypeSelector from "./LoanTypeSelector";
import SecondaryRemodelAddition from "./SecondaryRemodelAddition";
import InterestOnlyMortgage from "../../atomicComponents/InterestOnlyMortgage";
import InterestOnlyMortgageResult from "../../atomicComponents/InterestOnlyMortgageResult";
import LoanTypeSecondary from "./LoanTypeSecondary";
import { useDispatch, useSelector } from "react-redux";
import SecondaryCash from "./SecondaryCash";
import useSaveNow from "../../hooks/useSaveNow";
import useProformaData from "../../hooks/dealReporthooks/useProformaData";
import InterestTermResult from "../../atomicComponents/InterestTermResult";
import { sarLoanAmount } from "../../utils/loanAmount";
import LoanAmount from "../../atomicComponents/LoanAmount";
import { useMemo } from "react";
import {
  interestOnlyMortgageCalculator,
  isNaNChecker,
  mortgageLoanCalculator,
  yearsToMonthsTransformer,
} from "../../localCalcutions/utils/genericFunctions";
import * as actionCreators from "../../../../actions/dealAnalyzer/index";

const useStyle = (theme) => {
  return {
    root: {
      padding: "2rem",
    },
    row1: {
      display: "flex",
      marginTop: "3rem",
    },
    button: {
      textTransform: "none",
      width: "100%",
      margin: "30px 0",
      padding: "15px",
      marginBottom: "2rem",
    },
    span: {
      borderBottom: `solid 3px ${theme.palette.primary.main} `,
    },
  };
};

function FinanceOptionsNewConstruction({ handleEvents, data }) {
  const theme = useTheme();
  const styles = useStyle(theme);
  // const [ setShowMortgageResult] = useState(false)
  const [mortgagePrimaryResultLoading, setMortgagePrimaryResultLoading] =
    useState(false);
  const [mortgageSecondaryResultLoading, setMortgageSecondaryResultLoading] =
    useState(false);
  const resultData = useSelector(
    (state) => state?.straightAestheticRemodel?.dealAnalyzerResults
  );
  const fetchAndUpdateResults = useProformaData()?.fetchResult;
  const dealAnalyzerResultsData = useSelector(
    (state) => state?.straightAestheticRemodel.dealAnalyzerResults
  );
  const secondaryMonthlyPayment =
    dealAnalyzerResultsData?.secondary_finance_option_summary
      ?.total_monthly_payment;
  const { saveNow } = useSaveNow();

  const scrollRef = useRef(null);
  const [applyPadding, setApplyPadding] = useState(false);
  const mortgageData = useSelector(
    (state) =>
      state?.straightAestheticRemodel.dealAnalyzerResults
        ?.secondary_finance_option_summary?.interest_only_mortgage_loan
  );
  const analyzerData = useSelector((state) => state?.straightAestheticRemodel);

  const localRemodelCostChecker =
    +analyzerData?.developmentCost?.remodel_cost_option;
  const totalNewConstructionBuildCost =
    +analyzerData?.financeOptions?.totalNewConstructionBuildCost;
  const localRemodelCost =
    localRemodelCostChecker && localRemodelCostChecker === 1
      ? +analyzerData?.financeOptions?.AdvancedDevelopmentCost
      : totalNewConstructionBuildCost;

  const showSecondary = !!analyzerData?.financeOptions?.showSecondary;
  const remodelCost =
    localRemodelCost ||
    resultData?.development_cost?.estimated_total_remodel_cost ||
    resultData?.development_cost?.total_new_construction_build_cost;

  const handleDealFinanceOption = (value) => {
    handleEvents({ deal_finance_option: value })``;
  };

  const handleShowSecondary = () => {
    handleEvents({ showSecondary: true });
    setTimeout(() => {
      scrollRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }, 0);
  };
  const checkDealFinanceOption = () => {
    return data?.deal_finance_option ? data.deal_finance_option : 0;
  };

  //========================local Calculation ================================//

  const financialOptions = useSelector(
    (state) => state?.straightAestheticRemodel.financeOptions
  );

  const localPrimaryLoanAmount = financialOptions.localGeneralLoanAmount;
  const localPrimaryRate = financialOptions.primary_interest_rate;
  const localPrimaryTermsOfLoan = financialOptions.primary_term_of_loan;

  const dispatch = useDispatch();
  const localPrimaryAmount = useMemo(() => {
    const time = localPrimaryTermsOfLoan * 12;
    const rate = localPrimaryRate / 100 / 12;
    const amount = mortgageLoanCalculator(localPrimaryLoanAmount, rate, time);
    //  console.log(amount)
    return isNaNChecker(amount);

    // NOTE: Run effect once on component mount, please
    // recheck dependencies if effect is updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localPrimaryLoanAmount, localPrimaryRate, localPrimaryTermsOfLoan]);

  useEffect(() => {
    dispatch(
      actionCreators.updateFinanceOptions({
        localMonthlyAmount: localPrimaryAmount,
      })
    );
    dispatch(
      actionCreators.updateFinanceOptions({
        newConstructionMonthlyAmount: localPrimaryAmount,
      })
    );

    // recheck dependencies if effect is updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localPrimaryAmount]);

  //dispatches the newConstruction cost for carrying month
  useEffect(() => {
    dispatch(
      actionCreators.updateFinanceOptions({ universalRemodelCost: remodelCost })
    );
    // recheck dependencies if effect is updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remodelCost]);

  //==================================this section deals with secondary loans====================================================

  //Obtain Single Primary Loan for Home & Remodel calcs
  const primaryAnnualRate = financialOptions.primary_annual_interest_rate;
  const primaryOnlyTermFor = financialOptions.primary_interest_only_term;
  const primaryMortgageAmount = financialOptions.localGeneralLoanAmount;
  const primaryMonthOrYearChecker =
    financialOptions.primary_interest_only_term_month_or_year;

  const PrimaryInterestAloneMortgageFunc = useCallback(() => {
    const timeTransformer = yearsToMonthsTransformer(
      primaryMonthOrYearChecker,
      primaryOnlyTermFor
    );
    const res = interestOnlyMortgageCalculator(
      primaryAnnualRate,
      primaryMortgageAmount
    );
    dispatch(
      actionCreators.updateFinanceOptions({
        primaryInterestOnly: res,
        primaryInterestOnlyTime: timeTransformer,
      })
    );

    // recheck dependencies if effect is updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    primaryAnnualRate,
    primaryOnlyTermFor,
    primaryMortgageAmount,
    primaryMonthOrYearChecker,
  ]);

  useEffect(() => {
    // recheck dependencies if effect is updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    PrimaryInterestAloneMortgageFunc();
  }, [PrimaryInterestAloneMortgageFunc]);

  const secondaryInterestRateLocal =
    financialOptions.secondary_interest_rate / 100 / 12;
  const SecondaryTimeInYearsLocal =
    financialOptions.secondary_term_of_loan * 12;
  const secondaryLoanAmountLocal = financialOptions.localSecondaryLoanAmount;
  //secondary amount paid monthly
  const localSecondaryAmount = useMemo(() => {
    const amount = mortgageLoanCalculator(
      secondaryLoanAmountLocal,
      secondaryInterestRateLocal,
      SecondaryTimeInYearsLocal
    );
    dispatch(
      actionCreators.updateCarryingMonths({
        universalSecondaryLoanAmount: amount,
      })
    );

    return amount;
    // recheck dependencies if effect is updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    secondaryInterestRateLocal,
    SecondaryTimeInYearsLocal,
    secondaryLoanAmountLocal,
  ]);

  //=================================xxxxxxxxxx========================================//

  const loanTypeIsConventional =
    +data?.secondary_loan_type === 0 || !data?.secondary_loan_type;
  const loanTypeIsPrivate = +data?.secondary_loan_type === 1;
  const loanTypeIsSeller = +data?.secondary_loan_type === 2;
  const isSellerLoanTypeAndConventional =
    loanTypeIsSeller &&
    (+data?.secondary_sub_loan_type === 0 || !data?.secondary_sub_loan_type);
  const isSellerLoanTypeAndMortgage =
    loanTypeIsSeller && +data?.secondary_sub_loan_type === 1;
  const isPrivateLoanTypeAndConventional =
    loanTypeIsPrivate &&
    (+data?.secondary_sub_loan_type === 0 || !data?.secondary_sub_loan_type);
  const isPrivateLoanTypeAndMortgage =
    loanTypeIsPrivate && +data?.secondary_sub_loan_type === 1;

  const localSecondaryLoanAmount = sarLoanAmount({
    purchasePrice: remodelCost,
    lenderPoints: data?.secondary_lender_points,
    wholesaleFee: 0,
    pmi: data?.secondary_include_pmi,
    downPayment: data?.secondary_down_payment,
    downPaymentRate: data?.secondary_dollar_or_percent,
    wholesaleFeeOption: data?.secondary_wholesale_fee_options,
    lenderpointOption: data?.secondary_lender_points_options,
  });

  useEffect(() => {
    // const amount = { localSecondaryLoanAmount: localSecondaryLoanAmount };
    // dispatch(actionCreators.updateFinanceOptions(amount));

    dispatch(
      actionCreators.updateFinanceOptions({
        localSecondaryLoanAmount: localSecondaryLoanAmount?.effectiveLoan,
      })
    );

    // recheck dependencies if effect is updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localSecondaryLoanAmount?.effectiveLoan]);

  // interest only secondary ====================================================================

  const rate = +financialOptions.secondary_annual_interest_rate;
  const amount = +financialOptions.localSecondaryLoanAmount;
  const monthOrYearChecker =
    financialOptions.secondary_interest_only_term_month_or_year;
  const monthOrYear = financialOptions.secondary_interest_only_term;
  //const secondaryLocalYearsToMonths = financialOptions.secondaryYearsToMonthsTransformed;

  const interestAloneMortgageFunc = useCallback(() => {
    const timeTransformer = yearsToMonthsTransformer(
      monthOrYearChecker,
      monthOrYear
    );
    const res = interestOnlyMortgageCalculator(rate, amount);

    dispatch(
      actionCreators.updateFinanceOptions({
        secondaryInterestOnlyReturned: res,
      })
    );
    return {
      interest_only_payment: res,
      interest_only_payment_term: timeTransformer,
    };

    // recheck dependencies if effect is updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rate, amount, monthOrYearChecker, monthOrYear]);

  const interestAloneMortgage = useMemo(() => {
    const interestAloneMortgage = interestAloneMortgageFunc();
    return interestAloneMortgage;
  }, [interestAloneMortgageFunc]);

  const showSecondaryToggleButton = () => {
    const dealOption = checkDealFinanceOption();
    return +dealOption === 0;
  };
  const showSecondaryConventionalLoan = () => {
    return (
      loanTypeIsConventional ||
      isSellerLoanTypeAndConventional ||
      isPrivateLoanTypeAndConventional
    );
  };
  const showSecondaryMortgageLoan = () => {
    return (
      (isPrivateLoanTypeAndMortgage || isSellerLoanTypeAndMortgage) &&
      !loanTypeIsConventional
    );
  };

  const fetchResult = async (level) => {
    level === "mortgage"
      ? setMortgageSecondaryResultLoading(true)
      : setMortgagePrimaryResultLoading(true);

    try {
      await saveNow({ finance_options: analyzerData?.financeOptions });
      await fetchAndUpdateResults();
    } catch (err) {
    } finally {
      if (level === "mortgage") {
        //  setShowMortgageResult(true)
      }
      setMortgagePrimaryResultLoading(false);
      setMortgageSecondaryResultLoading(false);
    }
  };

  const handleShowmortgageResult = () => {
    fetchResult("mortgage");
  };

  useEffect(() => {
    setApplyPadding(true);
    const timer = setTimeout(() => {
      setApplyPadding(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, [showSecondary]);
  useEffect(() => {
    !data?.deal_finance_option && handleEvents({ deal_finance_option: "0" });
    !data?.primary_loan_type && handleEvents({ primary_loan_type: "0" });
    !data?.secondary_loan_type && handleEvents({ secondary_loan_type: "1" });
    !data?.primary_sub_loan_type &&
      handleEvents({ primary_sub_loan_type: "1" });
    !data?.secondary_sub_loan_type &&
      handleEvents({ secondary_sub_loan_type: "1" });
    !data?.primary_month_year && handleEvents({ primary_month_year: "0" });
    !data?.secondary_month_year && handleEvents({ secondary_month_year: "0" });
    !data?.primary_wholesale_fee_options &&
      handleEvents({ primary_wholesale_fee_options: "1" });

    // NOTE: Run effect once on component mount, please
    // recheck dependencies if effect is updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //========================= returned data ===========================//

  const modifiedData = interestAloneMortgage || mortgageData;

  //  console.log(secondaryLocalYearsToMonths)

  // console.log(secondaryMonthlyPayment)
  return (
    <Box sx={styles.root}>
      <StyledLabel label="Select deal finance option" />
      <EnhancedRadioButtonGroup
        options={financeDealOptionsNewConstruction}
        handler={handleDealFinanceOption}
        choice={data?.deal_finance_option}
      />
      <LoanTypeSelector
        handler={handleEvents}
        options={loanTypeOptions}
        headings={primaryHeadings}
        subOptions={nonConventionalLoanOptions}
        choice={data?.loan_type}
        data={data}
      />
      <PrimaryHomeLoan
        handler={handleEvents}
        data={data}
        fetchResult={fetchResult}
        inputLabels={""}
        labelsAndNames={loanAmountInputLabelsAndNames}
        mortgagePrimaryResultLoading={mortgagePrimaryResultLoading}
        dealAnalyzerResultsData={resultData}
        remodelCost={remodelCost}
      />
      {+checkDealFinanceOption() === 1 && (
        <>
          {/* <Box sx={{ textAlign: "center", marginBottom: "2rem",mt:2 }}>
            <Box component={"span"} sx={styles.span}>
              <Typography variant="heading" color="primary">
                Cash for New Construction
              </Typography>
            </Box>
          </Box>
          <PurchasePrice
            handler={handleEvents}
            name="new_construction_cost_amount"
            label="New Construction Cost Amount"
            data={data}
          /> */}
        </>
      )}
      {+checkDealFinanceOption() === 3 && (
        <SecondaryCash
          heading="Cash for Land"
          data={data}
          handler={handleEvents}
          namePrefix="cash_for_land"
          altHeading="Purchase Price"
        />
      )}
      {showSecondaryToggleButton() && ( //Selectively shown for certain deal finance options
        <div
          ref={scrollRef}
          style={applyPadding ? { paddingBottom: "0px" } : { paddingBottom: 0 }}
        >
          {!showSecondary && (
            <Button
              variant="outlined"
              sx={styles.button}
              onClick={handleShowSecondary}
            >
              <BiPlus fontSize={"16px"} />{" "}
              <Typography variant="toggleText">
                Add Secondary Loan for New Construction
              </Typography>
            </Button>
          )}
          {showSecondary && (
            <div>
              <LoanTypeSecondary
                handler={handleEvents}
                options={loanTypeOptions}
                subOptions={nonConventionalLoanOptions}
                headings={secondaryHeading}
                choice={
                  data?.secondary_loan_type ? data.secondary_loan_type : ""
                }
                data={data}
              />
              <SecondaryRemodelAddition
                handler={handleEvents}
                data={data}
                altHeading="New Construction Cost Amount"
              />
              {showSecondaryConventionalLoan() && (
                <>
                  <LoanAmount
                    handler={handleEvents}
                    label="Loan Amount"
                    name="secondary_loan_amount"
                    loanAmount={
                      localSecondaryLoanAmount
                        ? localSecondaryLoanAmount?.effectiveLoan.toFixed()
                        : ""
                    }
                    greyed
                  />
                  <InterestTermResult
                    handler={handleEvents}
                    data={data}
                    prefixName="secondary"
                    beforeFetchResult={fetchResult}
                    monthlyPayment={
                      localSecondaryAmount || secondaryMonthlyPayment
                    }
                  />
                </>
              )}
              {showSecondaryMortgageLoan() && (
                <>
                  <InterestOnlyMortgage
                    handler={handleEvents}
                    data={data}
                    namePrefix="secondary_"
                    mortgageAmount={
                      localSecondaryLoanAmount
                        ? localSecondaryLoanAmount?.effectiveLoan.toFixed()
                        : ""
                    }
                  />
                  <Typography variant="h5">CALCULATE</Typography>
                  {!interestAloneMortgage && (
                    <Button
                      sx={styles.button}
                      onClick={handleShowmortgageResult}
                      variant="contained"
                    >
                      {!mortgageSecondaryResultLoading && (
                        <Typography variant="h5">CALCULATE</Typography>
                      )}
                      {mortgageSecondaryResultLoading && (
                        <ClipLoader color={"#fff"} loading={true} size={16} />
                      )}
                    </Button>
                  )}

                  {interestAloneMortgage && (
                    <InterestOnlyMortgageResult
                      data={data}
                      fetchResult={fetchResult}
                      mortgageResults={modifiedData}
                    />
                  )}
                </>
              )}
            </div>
          )}
        </div>
      )}
      <Divider variant="fullWidth" sx={{ height: "5px", marginTop: "40px" }} />
    </Box>
  );
}

export default FinanceOptionsNewConstruction;

const financeDealOptionsNewConstruction = [
  "Obtain Primary Loan for Land & Secondary Loan for New Construction",
  "Obtain Primary Loan for Land and pay Cash for New Construction",
  "Obtain Single Loan for Land & New Construction",
  "Obtain Primary Loan for New Construction & Pay Cash for Land",
  "Pay Cash for Land & New Construction",
];
const loanTypeOptions = [
  "Conventional Loan",
  "Private Hard Money",
  "Seller Financing",
];
const primaryHeadings = [
  "Primary Loan for Land",
  "Single Primary Loan",
  "Primary Loan for new Construction",
  "Pay Cash for Land & New Construction",
];
const secondaryHeading = "Secondary Loan for New Construction ";

export const acronyms = {
  IOM: "Interest Only Mortgage",
  SAR: "Straight Aesthetic Remodel",
};
const nonConventionalLoanOptions = [
  "Conventional Mortgage Loan",
  "Interest Only Mortgage Loan",
];
const loanAmountInputLabelsAndNames = [
  {
    name: "primary_purchase_price",
    label: "Purchase Price",
  },
  {
    name: "primary_new_construction_cost",
    label: "New Construction Cost Amount",
  },
];

// import * from God

// //useCallback for optimization of life
// const life = useGod(() => {
//   try {
//     alert('Success!!!')
//   } catch (error) {
//     alert('Get connected!!!')
//   }

// }, [Jesus, HolySpirit])

// export default life
