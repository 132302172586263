import {
  Box,
  FormControl,
  OutlinedInput,
  InputAdornment,
  Typography,
  FormLabel,
  FormControlLabel,
  Radio,
  RadioGroup,
  Grid,
} from "@mui/material";
import React, { useRef, useEffect } from "react";
import HtmlToolTip from "../atomicComponents/HtmlToolTip";
import StyledLabel from "../atomicComponents/StyledLabel";
import useTheme from "@mui/material/styles/useTheme";
import useProformaData from "../hooks/dealReporthooks/useProformaData";
import EnhancedRadioButtonGroup from "../atomicComponents/EnhancedRadioButtonGroup";
import { greyout } from "../utils/greyout";
import commaSeparate from "../utils/commaSeparate";
import FlatRatePercentageInput from "../atomicComponents/FlatRatePercentageInput";
import useSaveNow from "../hooks/useSaveNow";
import { useSelector } from "react-redux";

import { useMemo } from "react";

import { closingCostCalcs } from "../localCalcutions/formulas/ClosingCost";
const useStyles = (theme) => {
  return {
    root: {
      margin: "1.25rem",
    },
    inputs: {
      margin: " 2.5rem 0",
    },
    label: {
      mt: 2,
      marginBottom: "5px",
    },
  };
};

function ClosingCost({ handleEvents, data }) {
  const { fetchResult, resultData } = useProformaData({ noAutoLoad: true });
  const [showClosingCredit, setShowClosingCredit] = React.useState(
    !!data?.closing_cost_credit
  );
  const [showClosingCreditPurchase, setShowClosingCreditPurchase] =
    React.useState(!!data?.closing_cost_credit_purchase);
  const { saveNow } = useSaveNow();
  const scrollRef = useRef();
  //const [loading, setLoading] = React.useState(false)

  const rateHandler = (value) => {
    handleEvents({ commission_on_resale_dollar_or_percent: value });
  };
  const rateHandler1 = (value) => {
    handleEvents({ commission_on_purchase_dollar_or_percent: value });
  };

  const theme = useTheme();
  const styles = useStyles(theme);
  const handleChoice = (e) => {
    handleEvents({ [e.target.name]: e.target.value });
  };
  const handleShowCredit = (e) => {
    setShowClosingCredit((prev) => !prev);
    handleEvents({ closing_cost_credit: 0 });
    setTimeout(() => {
      scrollRef.current &&
        scrollRef.current.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "nearest",
        });
    }, 100);
  };
  const handleShowCreditPurchase = (e) => {
    setShowClosingCreditPurchase((prev) => !prev);
    handleEvents({ closing_cost_credit_purchase: 0 });
  };

  const handleValues = (e) => {
    let value = e.target.value;
    const name = e.target.name;
    if (value) {
      value = Number.parseInt(value.split(",").join(""));
      if (isNaN(value)) {
        return;
      }
      handleEvents({ [name]: value });
    } else {
      handleEvents({ [name]: value });
    }
  };
  const radio = {
    "& .MuiSvgIcon-root": {
      fontSize: 22,
    },
  };

  //================== Title and Escrow fees ===========================//

  const Label = ({ text, asideText }) => (
    <Box sx={styles.upperText}>
      <span>
        <StyledLabel label={text} />
      </span>
      <Typography variant="h6" sx={styles.small}>
        {asideText}
      </Typography>
    </Box>
  );

  const rateHandler2 = (value) => {
    handleEvents({ title_and_escrow_fees_dollar_or_percent: value });
  };
  const rateHandler3 = (value) => {
    handleEvents({ title_and_escrow_fees_resale_dollar_or_percent: value });
  };
  const rateHandler4 = (value) => {
    handleEvents({ custom_title_and_escrow_buyer_pays_percent: value });
  };
  const rateHandler5 = (value) => {
    handleEvents({ custom_commission_on_purchase_dollar_or_percent: value });
  };
  const rateHandler6 = (value) => {
    handleEvents({
      custom_title_and_escrow_on_resale_dollar_or_percent: value,
    });
  };
  const rateHandler7 = (value) => {
    handleEvents({
      custom_commission_on_resale_dollar_or_percent: value,
    });
  };

  useEffect(() => {
    const purchaseClosingCostOption = data?.closing_cost_option_purchase;
    const resellClosingCostOption = data?.closing_cost_option_resell;
    const commisionOnResaleRate = data?.commission_on_resale_dollar_or_percent;
    const commissionOnResaleOption = data?.commission_on_resale_option;
    const commissionOnPurchaseOption = data?.commission_on_purchase_option;

    const commisionOnPurchaseRate =
      data?.commission_on_purchase_dollar_or_percent;
    if (!purchaseClosingCostOption) {
      handleEvents({ closing_cost_option_purchase: 0 });
    }
    if (!commissionOnResaleOption) {
      handleEvents({ commission_on_resale_option: 1 });
    }
    if (!commissionOnPurchaseOption) {
      handleEvents({ commission_on_purchase_option: 0 });
    }

    if (!commisionOnResaleRate) {
      handleEvents({ commission_on_resale_dollar_or_percent: 0 });
    }
    if (!commisionOnPurchaseRate) {
      handleEvents({ commission_on_purchase_dollar_or_percent: 0 });
    }
    if (!resellClosingCostOption) {
      handleEvents({ closing_cost_option_resell: 1 });
    }
    if (!data?.commission_on_resale_dollar_or_percent) {
      handleEvents({ commission_on_resale_dollar_or_percent: 0 });
    }
    if (!data?.commission_on_purchase_dollar_or_percent) {
      handleEvents({ commission_on_purchase_dollar_or_percent: 0 });
    }
    fetchResult();
    // NOTE: Run effect once on component mount, please
    // recheck dependencies if effect is updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // const save = async () => {
  //   setLoading(true)
  //   await saveNow({ closing_cost: data })
  //   await fetchResult()
  //   setLoading(false)
  // }
  useEffect(() => {
    const save = async () => {
      saveNow({ closing_cost: data });
      fetchResult();
    };
    save();
    // NOTE: Run effect once on component mount, please
    // recheck dependencies if effect is updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.commission_on_resale, data?.closing_cost_credit]);

  //====================== local calculations ===========================//
  const analyzerData = useSelector((state) => state?.straightAestheticRemodel);

  const closingCostCalculations = useMemo(
    () =>
      closingCostCalcs({
        remodelCosts: analyzerData.remodelCosts,
        financeOptions: analyzerData.financeOptions,
        resultData,
        dealId: analyzerData.dealAnalyzerReportId.analyzerTypeId,
        propertyPermitAndFees: analyzerData?.propertyPermitAndFees,
        taxes: analyzerData.taxes,
        rentalIncome: analyzerData.rentalIncome,
        propertyManagement: analyzerData.propertyManagement,
        otherCosts: analyzerData?.otherCosts,
        carryingMonths: analyzerData?.carryingMonths,
        estimatedResaleValue: analyzerData?.estimatedResaleValue,
        dealAnalyzerResults: analyzerData?.dealAnalyzerResults,
        closingCosts: data,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      analyzerData.remodelCosts,
      analyzerData.financeOptions,
      analyzerData?.propertyPermitAndFees,
      analyzerData.taxes,
      analyzerData.rentalIncome,
      analyzerData.propertyManagement,
      analyzerData?.otherCosts,
      analyzerData?.carryingMonths,
      analyzerData?.estimatedResaleValue,
      analyzerData?.closingCosts,
      data,
    ]
  );

  const onlinePurchasingClosingCost =
    resultData?.closing_cost?.est_front_end_closing_cost;
  const onlineResaleClosingCost =
    resultData?.closing_cost?.est_back_end_closing_cost;

  const purchasingClosingCostReturned =
    closingCostCalculations?.localPurchasingClosingCost.toFixed(2) ||
    onlinePurchasingClosingCost;
  const resaleClosingCostReturned =
    closingCostCalculations?.localResaleClosingCost.toFixed(2) ||
    onlineResaleClosingCost;

  const purchasingClosingCostStaticelement = {
    label: "Purchase Closing Cost",
    toolTip: "Title & Escrow Fees at Purchase",
    variableName: "est_front_end_closing_cost",
  };

  const resaleClosingCostStaticelements = {
    label: "Resell Closing Cost",
    toolTip: "Title & Escrow Fees at Resell",
    variableName: "est_back_end_closing_cost",
  };

  //============== Commission on Purchase Amount=====================//

  return (
    <Box sx={styles.root}>
      <Box sx={{ mt: 2 }}>
        <FlatRatePercentageInput
          handler={handleEvents}
          data={data}
          title={
            <Label
              text={"Title & Escrow Fees on Purchase"}
              asideText={propertyPermitFeesStaticData?.labelTitleAside1}
            />
          }
          noToolTip={true}
          rateHandler={rateHandler2}
          name="title_and_escrow_fees"
          rate={
            data?.title_and_escrow_fees_dollar_or_percent
              ? data.title_and_escrow_fees_dollar_or_percent
              : 0
          }
          referenceValue={analyzerData?.financeOptions?.primary_purchase_price}
        />
        <Box sx={styles.under}>
          {" "}
          <Typography variant="notice">
            {propertyPermitFeesStaticData?.labelInfo}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ mt: 2 }} />
      <Box>
        <StyledLabel label="Who’s paying  for the Title & Escrow fee at purchase? " />
        <EnhancedRadioButtonGroup
          options={closingCostOptions}
          handler={handleEvents}
          name="closing_cost_option_purchase"
          choice={data?.closing_cost_option_purchase}
          specialHandler={handleChoice}
        />
        {+data?.closing_cost_option_purchase === 3 && (
          <>
            <FlatRatePercentageInput
              rateHandler={rateHandler4}
              handler={handleEvents}
              rate={data?.custom_title_and_escrow_buyer_pays_percent}
              noToolTip={true}
              name="custom_title_and_escrow_buyer_pays"
              data={data}
              title="What Amount/Percentage of Title & Escrow Fee is Paid by Buyer?"
              referenceValue={closingCostCalculations?.titleAndEscrowFeesAmount}
            />
            <Box sx={styles.under}>
              {" "}
              <Typography variant="notice" fontWeight={700}>
                {
                  "NOTE: Seller pays the remaining Amount of the Title & Escrow Fee"
                }
              </Typography>
            </Box>
          </>
        )}
      </Box>

      <Box sx={{ mt: 3 }}>
        <FlatRatePercentageInput
          rateHandler={rateHandler1}
          handler={handleEvents}
          rate={data?.["commission_on_purchase_dollar_or_percent"]}
          noToolTip={true}
          name="commission_on_purchase"
          data={data}
          title="Commissions on Purchase"
          referenceValue={closingCostCalculations?.purhcasePrice}
        />
        <Box sx={{ mt: 1 }}>
          <StyledLabel label="Who’s paying for the commission on Purchase? " />
          <EnhancedRadioButtonGroup
            options={closingCostOptions}
            handler={handleEvents}
            name="commission_on_purchase_option"
            choice={data?.commission_on_purchase_option}
            specialHandler={handleChoice}
          />
          {+data?.commission_on_purchase_option === 3 && (
            <>
              <FlatRatePercentageInput
                rateHandler={rateHandler5}
                handler={handleEvents}
                rate={data?.["custom_commission_on_purchase_dollar_or_percent"]}
                noToolTip={true}
                name="custom_commission_on_purchase"
                data={data}
                title="What Amount/Percentage of commission on Purchase is Paid by Buyer?"
                referenceValue={
                  closingCostCalculations?.commisionOnPurchaseAmount
                }
              />
              <Box sx={styles.under}>
                {" "}
                <Typography variant="notice" fontWeight={700}>
                  {
                    "NOTE: Seller pays the remaining Amount of commission on Purchase"
                  }
                </Typography>
              </Box>
            </>
          )}
        </Box>
      </Box>
      <FormControl sx={{ mt: 4 }}>
        <FormLabel>
          <Typography variant="h4" sx={{ fontWeight: 500, color: "#000" }}>
            Is there a Purchase closing cost credit being applied?
          </Typography>{" "}
        </FormLabel>
        <RadioGroup
          onChange={handleShowCreditPurchase}
          value={showClosingCreditPurchase}
        >
          <Box sx={{ display: "flex" }}>
            <FormControlLabel
              value={false}
              control={<Radio sx={radio} />}
              label={<Typography variant="h4">No</Typography>}
            />
            <FormControlLabel
              value={true}
              control={<Radio sx={radio} />}
              label={<Typography variant="h4">Yes</Typography>}
            />
          </Box>
        </RadioGroup>
      </FormControl>
      {showClosingCreditPurchase && (
        <Box sx={{ width: "100%", mt: 2 }}>
          <Box sx={styles.label}>
            <StyledLabel label="Purchase Closing Credit Amount" />
          </Box>
          <FormControl sx={{ minWidth: "18.75rem", width: "100%" }}>
            <OutlinedInput
              fullWidth
              margin="normal"
              name="closing_cost_credit_purchase"
              onChange={handleValues}
              value={commaSeparate(data?.closing_cost_credit_purchase)}
              inputProps={{ style: { fontSize: "1rem" } }}
              startAdornment={
                <InputAdornment position="start">
                  {" "}
                  <Typography variant="adornment" color="secondary">
                    {" "}
                    $
                  </Typography>
                </InputAdornment>
              }
            />
          </FormControl>
          <div ref={scrollRef} />
        </Box>
      )}

      {/* this is the end of the purchase options  */}

      <Box sx={{ mt: 4 }}>
        <FlatRatePercentageInput
          handler={handleEvents}
          data={data}
          title={
            <Label
              text={"Title & Escrow Fees on Resale"}
              asideText={propertyPermitFeesStaticData?.labelTitleAside1}
            />
          }
          noToolTip={true}
          rateHandler={rateHandler3}
          name="title_and_escrow_fees_resale"
          rate={
            data?.title_and_escrow_fees_resale_dollar_or_percent
              ? data.title_and_escrow_fees_resale_dollar_or_percent
              : 0
          }
          referenceValue={closingCostCalculations?.estimatedResaleAmount}
        />
        <Box sx={styles.under}>
          {" "}
          <Typography variant="notice">
            {propertyPermitFeesStaticData?.labelInfoForResale}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ mt: 2 }}>
        <StyledLabel label="Who’s paying  for the Title & Escrow fee at resell? " />
        <EnhancedRadioButtonGroup
          options={closingCostOptions}
          handler={handleEvents}
          name="closing_cost_option_resell"
          choice={data?.closing_cost_option_resell}
          specialHandler={handleChoice}
        />
        {+data?.closing_cost_option_resell === 3 && (
          <>
            <FlatRatePercentageInput
              rateHandler={rateHandler6}
              handler={handleEvents}
              rate={
                data?.["custom_title_and_escrow_on_resale_dollar_or_percent"]
              }
              noToolTip={true}
              name="custom_title_and_escrow_on_resale"
              data={data}
              title="What Amount/Percentage of Title & Escrow Fee at resell is Paid by Buyer?"
              referenceValue={
                closingCostCalculations?.titleAndEscrowFeesResaleAmount
              }
            />
            <Box sx={styles.under}>
              {" "}
              <Typography variant="notice" fontWeight={700}>
                {
                  "NOTE: Seller pays the remaining Amount of the Title & Escrow Fee at resell"
                }
              </Typography>
            </Box>
          </>
        )}
      </Box>
      <Box sx={{ mt: 2 }} />
      <FlatRatePercentageInput
        rateHandler={rateHandler}
        handler={handleEvents}
        rate={data?.["commission_on_resale_dollar_or_percent"]}
        noToolTip={true}
        name="commission_on_resale"
        data={data}
        title="Commissions on Resale"
        referenceValue={closingCostCalculations?.estimatedResaleAmount}
      />
      <Box sx={{ mt: 1 }}>
        <StyledLabel label="Who’s paying  for the commission on resale? " />
        <EnhancedRadioButtonGroup
          options={closingCostOptions}
          handler={handleEvents}
          name="commission_on_resale_option"
          choice={data?.commission_on_resale_option}
          specialHandler={handleChoice}
        />
        {+data?.commission_on_resale_option === 3 && (
          <>
            <FlatRatePercentageInput
              rateHandler={rateHandler7}
              handler={handleEvents}
              rate={data?.["custom_commission_on_resale_dollar_or_percent"]}
              noToolTip={true}
              name="custom_commission_on_resale"
              data={data}
              title="What Amount/Percentage of Commission on Resale is Paid by Buyer?"
              referenceValue={closingCostCalculations?.commissionOnResaleAmount}
            />
            <Box sx={styles.under}>
              {" "}
              <Typography variant="notice" fontWeight={700}>
                {
                  "NOTE: Seller pays the remaining Amount of the Commission on Resale"
                }
              </Typography>
            </Box>
          </>
        )}
      </Box>
      <Grid container columns={24} sx={{ mb: 1, mt: 2 }}>
        <Grid item sm={24} md={8}>
          <Box sx={styles.label}>
            <StyledLabel label={purchasingClosingCostStaticelement.label} />
            <HtmlToolTip
              content={{ text: purchasingClosingCostStaticelement.toolTip }}
            />
          </Box>
          <FormControl sx={{ width: "100%" }}>
            <OutlinedInput
              fullWidth
              margin="normal"
              name={purchasingClosingCostStaticelement.variableName}
              value={commaSeparate(purchasingClosingCostReturned)}
              // placeholder='0'
              renderValue={(value) => value}
              sx={{ ...greyout }}
              inputProps={{ style: { fontSize: "1rem" } }}
              startAdornment={
                <InputAdornment position="start">
                  {" "}
                  <Typography variant="adornment" color="secondary">
                    {" "}
                    $
                  </Typography>
                </InputAdornment>
              }
            />
          </FormControl>
        </Grid>
        {<Grid sm={0} md={1} />}
        <Grid item sm={24} md={8}>
          <Box sx={styles.label}>
            <StyledLabel label={resaleClosingCostStaticelements.label} />
            <HtmlToolTip
              content={{ text: resaleClosingCostStaticelements.toolTip }}
            />
          </Box>
          <FormControl sx={{ width: "100%" }}>
            <OutlinedInput
              fullWidth
              margin="normal"
              name={resaleClosingCostStaticelements.variableName}
              value={commaSeparate(resaleClosingCostReturned)}
              // placeholder='0'
              renderValue={(value) => value}
              sx={{ ...greyout }}
              inputProps={{ style: { fontSize: "1rem" } }}
              startAdornment={
                <InputAdornment position="start">
                  {" "}
                  <Typography variant="adornment" color="secondary">
                    {" "}
                    $
                  </Typography>
                </InputAdornment>
              }
            />
          </FormControl>
        </Grid>
        {<Grid sm={0} md={1} />}

        {/* <Grid item sm={24} md={5}>
          <Box sx={{ mt: 6 }} />
          <Button variant="outlined" sx={{ p: 2, textTransform: "none" }} onClick={save}>
            {loading ? (
              <Box sx={{ mx: 2 }}>
                <ClipLoader color={"#B8D6E4"} loading={true} size={20} />
              </Box>
            ) : (
              <Typography variant="h4" sx={{ textDecoration: "none" }}>
                Calculate
              </Typography>
            )}
          </Button>
        </Grid> */}
      </Grid>

      {<Grid sm={0} md={1} />}

      {/* <Grid item sm={24} md={5}>
          <Box sx={{ mt: 6 }} />
          <Button variant="outlined" sx={{ p: 2, textTransform: "none" }} onClick={save}>
            {loading ? (
              <Box sx={{ mx: 2 }}>
                <ClipLoader color={"#B8D6E4"} loading={true} size={20} />
              </Box>
            ) : (
              <Typography variant="h4" sx={{ textDecoration: "none" }}>
                Calculate
              </Typography>
            )}
          </Button>
        </Grid> */}

      <FormControl sx={{ mt: 4 }}>
        <FormLabel>
          <Typography variant="h4" sx={{ fontWeight: 500, color: "#000" }}>
            Is there a resale closing cost credit being applied?
          </Typography>{" "}
        </FormLabel>
        <RadioGroup onChange={handleShowCredit} value={showClosingCredit}>
          <Box sx={{ display: "flex" }}>
            <FormControlLabel
              value={false}
              control={<Radio sx={radio} />}
              label={<Typography variant="h4">No</Typography>}
            />
            <FormControlLabel
              value={true}
              control={<Radio sx={radio} />}
              label={<Typography variant="h4">Yes</Typography>}
            />
          </Box>
        </RadioGroup>
      </FormControl>
      {showClosingCredit && (
        <Box sx={{ width: "100%", mt: 2 }}>
          <Box sx={styles.label}>
            <StyledLabel label="Resale Closing Credit Amount" />
          </Box>
          <FormControl sx={{ minWidth: "18.75rem", width: "100%" }}>
            <OutlinedInput
              fullWidth
              margin="normal"
              name="closing_cost_credit"
              onChange={handleValues}
              value={commaSeparate(data?.closing_cost_credit)}
              inputProps={{ style: { fontSize: "1rem" } }}
              startAdornment={
                <InputAdornment position="start">
                  {" "}
                  <Typography variant="adornment" color="secondary">
                    {" "}
                    $
                  </Typography>
                </InputAdornment>
              }
            />
          </FormControl>
          <div ref={scrollRef} />
        </Box>
      )}
      <div ref={scrollRef} />
    </Box>
  );
}

export default ClosingCost;

const closingCostOptions = [
  "Buyer Pays",
  "Seller Pays",
  "Buyer/Seller Split Closing Cost 50% each",
  "Buyer/Seller Split Closing Cost by custom Amount/Percentage",
  "Not Applicable",
];

const propertyPermitFeesStaticData = {
  labelTitle: "City Permit & Impact Fees",
  labelTitleAside:
    "(Contact the Local Real Estate Title Company to get more Accurate Pricing)",
  labelTitleAside1:
    "(Contact your Local Title Company for more Accurate Pricing)",
  labelInfo:
    "Please Note. For Title & Escrow you can Estimate 0.25 to 0.5% of Purchase Price",
  labelInfoForResale:
    "Please Note. For Title & Escrow you can Estimate 0.25 to 0.5% of Resale Price",
  labelInfo2:
    "Please Note: For Remodel Estimate 0.25% - 0.5% of Purchase Price and for New Construction we recommend 1% - 2% of Purchase Price",

  escrow: "Title & Escrow Fees",
  architecture: "Architectural Fees",
  monthlyInsurance: "Monthly Home Insurance",
  hoa: "Monthly HOA Dues",
  inputs: [
    // {label:'Title & Escrow Fees',variableName:"title_and_escrow_fees"},
    {
      label: "Architectural & Engineering Fees",
      variableName: "architectural_fees",
    },
    { label: "Monthly Home Insurance", variableName: "monthly_home_insurance" },
    { label: "Monthly HOA Dues", variableName: "monthly_hoa_dues" },
  ],
};
