import { Add, Circle, Close, Delete } from '@mui/icons-material';
import DescriptionIcon from '@mui/icons-material/Description';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Button,
	Checkbox,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	FormControlLabel,
	FormLabel,
	Grid,
	IconButton,
	ImageList,
	ImageListItem,
	InputLabel,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Radio,
	RadioGroup,
	TextField,
	ToggleButton,
	ToggleButtonGroup,
	Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';
import { AiOutlineHistory } from 'react-icons/ai';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import base, { file } from '../../apis';
import Spinner from '../../components/Spinner/Spinner';
import { exteriorList, interiorList, property_condition_options } from '../../constants';

const timeOptions = [
	{ label: 'Years', value: true },
	{ label: 'Months', value: false },
];

const useStyles = makeStyles((theme) => ({
	root: {
		padding: '20px',
		minHeight: '60vh',
	},
	header: {
		borderBottom: '2px solid black',
		padding: '15px',
		marginBottom: '10px',
	},
	accSum: {
		boxShadow: ' 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
		borderRadius: '5px',
	},
	title: {
		color: '#1068ae',
		fontWeight: '600',
	},
	button: {
		backgroundColor: theme.secondaryColor,
		color: 'white',
		marginLeft: 'auto',
		textTransform: 'none',
		display: 'block',
		'&:hover': {
			backgroundColor: theme.secondaryColor,
		},
	},
	addPhotos: {
		border: '1px dashed black',
		height: '100px',
		width: '100px',
		borderRadius: '5px',
		alignItems: 'center',
		justifyContent: 'center',
		textAlign: 'center',
		backgroundColor: '#e6e6e6',
		cursor: 'pointer',
		display: 'flex',
		flexDirection: 'column',
	},
	toggleButton: {
		border: 'none',
		backgroundColor: 'transparent',
		fontSize: '14px',
		textTransform: 'capitalize',
	},
	toggleButtonGroup: {
		borderBottom: '1px solid grey',
		width: '100%',
		borderRadius: '0',
	},
	headerText: {
		fontSize: '16px',
	},
	listItem: {
		padding: '5px',
		'& #icon': {
			width: '10px',
			height: '10px',
		},
	},
	listItemImage: {
		'&:hover #delete': {
			display: 'block',
		},
		'& #delete': {
			position: 'absolute',
			top: '10px',
			right: '10px',
			display: 'none',
			cursor: 'pointer',
			// fill:'white',
			fill: '#d52121',
		},
	},
}));

function ClientOrderedReport() {
	const classes = useStyles();
	const [completed, setCompleted] = useState([]);
	const [uncompleted, setUncompleted] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const client_register_id = useSelector((state) => state.auth.typeId);
	const [uploadLoading, setUploadLoading] = useState(false);

	//get search query in url with react router dom
	const [searchParams, setSearchParams] = useSearchParams();
	const reportId = searchParams.get('id');
	const [outStandingReport, setOutStandingReport] = useState(null);

	React.useEffect(() => {
		const getOutstandingReports = async () => {
			try {
				const { data } = await base.get('leads/check_pending_report/');
				if (data?.length) {
					setOutStandingReport(data[0]);
				}
			} catch (err) {}
		};
		getOutstandingReports();
	}, []);

	React.useEffect(() => {
		setIsLoading(true);
		base
			.get('/leads/search-report/', {
				params: {
					client_register_id,
					lead_type: 'ordered',
				},
			})
			.then(({ data }) => {
				const completed = data.filter((item) => item.status.toLowerCase() === 'complete');
				const uncompleted = data.filter((item) => item.status.toLowerCase() !== 'complete');
				if (reportId) {
					const selectedReport = uncompleted.find((item) => item.id === +reportId);
					if (!!selectedReport) {
						openDetailsModal(selectedReport);
					}
					searchParams.delete('id');
					setSearchParams(searchParams);
				}
				setCompleted(completed);
				setUncompleted(uncompleted);

				if (!data.length) {
					toast.warn('No requested reports');
				}
			})
			.catch((err) => {
				toast.error('Something went wrong');
			})
			.finally(() => {
				setIsLoading(false);
			});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [client_register_id]);

	const [detailsOpen, setDetailsOpen] = useState(false);
	const [toggleSelected, setToggleSelected] = useState('purpose');

	const openDetailsModal = (report) => {
		setDetailsOpen(true);
		setSelectedReport(report);
		setToggleSelected('purpose');
	};
	const [selectedReport, setSelectedReport] = useState(null);

	const handleSelectedReportChange = (e) => {
		const { value, name } = e.target;
		setSelectedReport((prev) => ({ ...prev, [name]: value }));
	};

	const extSelector = React.useRef(null);
	const intSelector = React.useRef(null);

	const setImages = async (e) => {
		setUploadLoading(true);
		const { files, name } = e.target;
		let formData = new FormData();
		for (let i = 0; i < files.length; i++) {
			formData.append(name, files[i]);
		}
		try {
			const { data } = await file.patch(`/leads/report/${selectedReport?.id}/`, formData);
			setSelectedReport((prev) => ({ ...prev, ...data }));
			setUncompleted((prev) => {
				const index = prev.findIndex((item) => item.id === data.id);
				if (index !== -1) {
					prev[index] = data;
				}
				return [...prev];
			});
		} catch (error) {
			toast.error('Error uploading images');
		} finally {
			setUploadLoading(false);
		}
	};

	const deletePhotos = async (type, image) => {
		const url = `/leads/${type.split('_')[0]}/${image.id}/`;
		setIsLoading(true);
		try {
			await base.delete(url);
			setSelectedReport((prev) => ({ ...prev, [type]: prev[type].filter((item) => item.id !== image.id) }));
		} catch (error) {
			toast.error('Error deleting image');
		} finally {
			setIsLoading(false);
		}
	};

	const savePropertyInfo = async () => {
		if (toggleSelected === 'purpose') {
			setToggleSelected('info');
			return;
		}
		if (toggleSelected === 'photos') {
			setDetailsOpen(false);
			return;
		}
		const {
			customer_livable_square_footage,
			customer_garage,
			property_condition,
			customer_lot_size,
			is_home_new,
			last_time_remodeled,
			is_year,
			nearby_nuisances,
		} = selectedReport;
		if (!customer_livable_square_footage) {
			toast.warn('Please enter gross living area');
			return;
		}
		if (!customer_lot_size) {
			toast.warn('Please enter lot size');
			return;
		}
		if (!customer_garage) {
			toast.warn('Please enter number of garages');
			return;
		}
		if (!property_condition) {
			toast.warn('Please select property condition');
			return;
		}

		setIsLoading(true);
		try {
			const { data } = await base.patch(`/leads/report/${selectedReport?.id}/`, {
				customer_livable_square_footage: +customer_livable_square_footage,
				customer_garage: +customer_garage,
				property_condition,
				customer_lot_size: +customer_lot_size,
				is_home_new,
				is_year: is_year === 'true',
				last_time_remodeled,
				nearby_nuisances,
			});

			setSelectedReport(data);
			setUncompleted((prev) => {
				const index = prev.findIndex((item) => item.id === data.id);
				if (index !== -1) {
					prev[index] = data;
				}
				return [...prev];
			});
			setToggleSelected('photos');
			toast.success('Property info saved');
			const { data: pendingReports } = await base.get('leads/check_pending_report/');
			if (pendingReports?.length) {
				setOutStandingReport(pendingReports[0]);
			}
		} catch (error) {
			toast.warn('Error saving property info');
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<>
			{isLoading && <Spinner />}
			{/* report details dialog */}
			<Dialog open={detailsOpen} fullWidth maxWidth='md'>
				<DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
					<Box display='flex' justifyContent='space-between' alignItems='center' marginRight='40px' flex='1' padding={1}>
						<Typography variant='body1'>{selectedReport?.clients_physical_address}</Typography>
						<Typography variant='body2'>Ordered Date:{new Date(selectedReport?.date_created).toDateString()}</Typography>
					</Box>
					<IconButton onClick={() => setDetailsOpen(false)}>
						<Close fontSize='small' />
					</IconButton>
				</DialogTitle>
				<DialogContent sx={{ position: 'relative' }}>
					<ToggleButtonGroup
						color='secondary'
						value={toggleSelected}
						exclusive
						onChange={(e) => setToggleSelected(e.target.value)}
						sx={{ marginBottom: '1rem' }}
						className={classes.toggleButtonGroup}
					>
						<ToggleButton className={classes.toggleButton} value='purpose'>
							Purpose
						</ToggleButton>
						<ToggleButton className={classes.toggleButton} value='info'>
							Property Info
						</ToggleButton>
						<ToggleButton className={classes.toggleButton} value='photos'>
							Property Photos
						</ToggleButton>
					</ToggleButtonGroup>
					<Box sx={{ height: '300px', overflowX: 'auto', display: 'flex', flexDirection: 'column', gap: '1rem' }}>
						{toggleSelected === 'purpose' && (
							<>
								<Typography variant='body1' className={classes.headerText} fontWeight='500'>
									Why would you like a BPO Report?
								</Typography>
								<Typography variant='body1' className={classes.headerText}>
									{selectedReport?.reason_for_request}
								</Typography>
							</>
						)}
						{toggleSelected === 'info' && (
							<>
								<Typography variant='body1' fontWeight='300' fontSize='14px'>
									If you can provide more infomation regarding your property, it will help the agent make a more accurate BPO Report
								</Typography>
								<Grid container spacing={2}>
									<Grid item xs={4}>
										<InputLabel className={classes.headerText} sx={{ color: 'black', fontWeight: '500' }}>
											Gross Living Area (sqft)
										</InputLabel>
										<TextField
											placeholder='Gross Living Area (sqft)'
											fullWidth
											color='secondary'
											size='small'
											type='number'
											name='customer_livable_square_footage'
											value={selectedReport?.customer_livable_square_footage || ''}
											onChange={handleSelectedReportChange}
										/>
									</Grid>
									<Grid item xs={4}>
										<InputLabel className={classes.headerText} sx={{ color: 'black', fontWeight: '500' }}>
											Lot Size (sqft)
										</InputLabel>
										<TextField
											placeholder='Lot Size (sqft)'
											fullWidth
											color='secondary'
											size='small'
											type='number'
											name='customer_lot_size'
											value={selectedReport?.customer_lot_size || ''}
											onChange={handleSelectedReportChange}
										/>
									</Grid>
									<Grid item xs={4}>
										<InputLabel className={classes.headerText} sx={{ color: 'black', fontWeight: '500' }}>
											Number of Garages
										</InputLabel>
										<TextField
											placeholder='Number of Garages'
											fullWidth
											color='secondary'
											size='small'
											type='number'
											name='customer_garage'
											value={selectedReport?.customer_garage || ''}
											onChange={handleSelectedReportChange}
										/>
									</Grid>
								</Grid>
								<FormControl my={1}>
									<FormLabel className={classes.headerText} sx={{ color: 'black', fontWeight: '500' }}>
										Current Property Conditions{' '}
									</FormLabel>
									<RadioGroup
										row
										name='property_condition'
										size='small'
										value={selectedReport?.property_condition || ''}
										onChange={(e) => handleSelectedReportChange(e)}
									>
										{property_condition_options.map((option) => (
											<FormControlLabel
												className={classes.headerText}
												key={option}
												value={option}
												control={<Radio color='secondary' />}
												label={option}
											/>
										))}
									</RadioGroup>
								</FormControl>
								<Grid container spacing={2} alignItems='center' justifyContent='center'>
									<Grid item xs={6}>
										<InputLabel className={classes.headerText} sx={{ color: 'black', display: 'flex', alignItems: 'center', fontWeight: '500' }}>
											When was the last time the home was remodelled?
										</InputLabel>
									</Grid>
									<Grid item xs={2}>
										<TextField
											className={classes.headerText}
											placeholder={`${selectedReport?.is_year ? 'Years' : 'Months'} Ago`}
											fullWidth
											color='secondary'
											size='small'
											type='number'
											name='last_time_remodeled'
											value={selectedReport?.last_time_remodeled || ''}
											onChange={handleSelectedReportChange}
											inputProps={{
												min: 0,
											}}
										/>
									</Grid>
									<Grid item xs={4}>
										<FormControl my={1}>
											<RadioGroup row name='is_year' size='small' value={selectedReport?.is_year} onChange={(e) => handleSelectedReportChange(e)}>
												{timeOptions.map((option) => (
													<FormControlLabel
														className={classes.headerText}
														key={option.label}
														value={option.value}
														control={<Radio color='secondary' />}
														label={option.label}
													/>
												))}
											</RadioGroup>
										</FormControl>
									</Grid>
								</Grid>
								<Grid container spacing={2} alignItems='center' justifyContent='center'>
									<Grid item xs={6}>
										<InputLabel className={classes.headerText} sx={{ color: 'black', display: 'flex', alignItems: 'center', fontWeight: '500' }}>
											Is the home a new construction?
										</InputLabel>
									</Grid>
									<Grid item xs={6}>
										<Checkbox
											name='is_home_new'
											onChange={(e) => {
												setSelectedReport({ ...selectedReport, is_home_new: e.target.checked });
											}}
											color='secondary'
											checked={selectedReport?.is_home_new}
										/>
									</Grid>
								</Grid>

								<Box>
									<InputLabel className={classes.headerText} sx={{ color: 'black', fontWeight: '500' }}>
										Defects or nearby nuisances affecting the home?
									</InputLabel>
									<TextField
										placeholder='Please explain if any'
										fullWidth
										className={classes.headerText}
										size='small'
										color='secondary'
										name='nearby_nuisances'
										multiline
										value={selectedReport?.nearby_nuisances || ''}
										onChange={handleSelectedReportChange}
									/>
								</Box>
							</>
						)}
						{toggleSelected === 'photos' && (
							<Box>
								<Typography variant='body1' fontWeight='300' fontSize='14px'>
									If you can provide more infomation regarding your property, it will help the agent make a more accurate BPO Report
								</Typography>
								<Box>
									<Typography variant='body1' className={classes.headerText} fontWeight='500'>
										EXTERIOR PHOTO UPLOADS
									</Typography>
									<Typography variant='body1' className={classes.headerText}>
										Kindly upload the following photos
									</Typography>

									<List sx={{ display: 'flex' }}>
										{exteriorList.map((item) => (
											<ListItem className={classes.listItem} key={item}>
												<ListItemIcon sx={{ minWidth: 'unset', paddingRight: '5px' }}>
													<Circle color='primary' id='icon' />
												</ListItemIcon>
												<ListItemText>
													<Typography variant='body2' whiteSpace='nowrap'>
														{item}
													</Typography>
												</ListItemText>
											</ListItem>
										))}
									</List>
									<input
										type='file'
										accept='image/png, image/jpeg'
										name='exterior_photos'
										style={{ display: 'none' }}
										ref={extSelector}
										multiple
										onChange={setImages}
									/>
									{selectedReport?.exterior_photos.length > 0 && (
										<ImageList sx={{ width: '100%', height: '100%', mx: 'auto' }} cols={5} rowHeight={200}>
											{selectedReport?.exterior_photos.map((item) => (
												<ImageListItem key={item.image} className={classes.listItemImage}>
													<img
														src={`${item.image}`}
														alt={'Property'}
														style={{
															cursor: 'pointer',
															border: '2px solid white',
															borderRadius: '5px',
															aspectRatio: '4/3',
															objectFit: 'contain',
														}}
													/>
													<Delete id='delete' onClick={() => deletePhotos('exterior_photos', item)} />
												</ImageListItem>
											))}
										</ImageList>
									)}
									<Box
										className={classes.addPhotos}
										onClick={() => {
											extSelector.current.click();
										}}
									>
										<Add />
										<p style={{ color: 'grey' }}>Add Photos</p>
									</Box>
								</Box>
								<Box mt={1}>
									<Typography variant='body1' className={classes.headerText} fontWeight='500'>
										INTERIOR PHOTO UPLOADS
									</Typography>
									<Typography variant='body1' className={classes.headerText}>
										Kindly upload the following photos
									</Typography>
									<List sx={{ display: 'flex' }}>
										{interiorList.map((item) => (
											<ListItem className={classes.listItem} key={item}>
												<ListItemIcon sx={{ minWidth: 'unset', paddingRight: '5px' }}>
													<Circle color='primary' id='icon' />
												</ListItemIcon>
												<ListItemText>
													<Typography variant='body2' whiteSpace='nowrap'>
														{item}
													</Typography>
												</ListItemText>
											</ListItem>
										))}
									</List>
									<input
										type='file'
										accept='image/png, image/jpeg'
										name='interior_photos'
										style={{ display: 'none' }}
										ref={intSelector}
										multiple
										onChange={setImages}
									/>
									{selectedReport?.interior_photos.length > 0 && (
										<ImageList sx={{ width: '100%', height: '100%', mx: 'auto' }} cols={5} rowHeight={200}>
											{selectedReport?.interior_photos.map((item, index) => (
												<ImageListItem key={item.image} className={classes.listItemImage}>
													<img
														src={`${item.image}`}
														alt={'Property'}
														style={{
															cursor: 'pointer',
															border: '2px solid white',
															borderRadius: '5px',
															aspectRatio: '4/3',
															objectFit: 'contain',
														}}
													/>
													<Delete id='delete' onClick={() => deletePhotos('interior_photos', item)} />
												</ImageListItem>
											))}
										</ImageList>
									)}
									<Box
										className={classes.addPhotos}
										onClick={() => {
											intSelector.current.click();
										}}
									>
										<Add />
										<p style={{ color: 'grey' }}>Add Photos</p>
									</Box>
								</Box>
							</Box>
						)}
					</Box>
				</DialogContent>
				<DialogActions sx={{ padding: '1rem 2rem' }}>
					{/* <Button
						disableElevation
						sx={{ textTransform: 'none' }}
						variant='outlined'
						color='secondary'
						onClick={() => {
							setDetailsOpen(false);
						}}
					>
						Close
					</Button> */}

					<Button disableElevation sx={{ textTransform: 'none' }} variant='contained' color='secondary' onClick={savePropertyInfo}>
						{toggleSelected === 'photos' ? 'Save' : 'Next'}
					</Button>
				</DialogActions>
			</Dialog>

			{/* image upload dialog */}
			<Dialog open={uploadLoading}>
				<DialogContent sx={{ padding: '3rem', width: '20rem', textAlign: 'center' }}>
					<CircularProgress value={30} color='primary' />
					<Typography mt={2}>Uploading Images...</Typography>
				</DialogContent>
			</Dialog>
			<div className={classes.root}>
				{completed.length !== 0 || uncompleted.length !== 0 ? (
					<>
						<Box display='flex' alignItems='center'>
							{outStandingReport && (
								<Box sx={{ backgroundColor: '#ff00002b', borderRadius: '5px' }} padding={1}>
									<span>
										Please fill the details for <b>{outStandingReport?.clients_physical_address}</b>
									</span>
									<Button
										href={`/client-ordered-report?id=${outStandingReport?.id}`}
										variant='contained'
										color='secondary'
										sx={{ marginLeft: '10px', textTransform: 'capitalize' }}
									>
										Click to fill
									</Button>
								</Box>
							)}
							<Button
								variant='contained'
								className={classes.button}
								sx={{ marginLeft: 'auto !important', width: 'fit-content' }}
								href='order-bpo-report'
							>
								Order BPO Report
							</Button>
						</Box>
						<div>
							<Typography variant='p' gutterBottom className={classes.title}>
								Pending Orders
							</Typography>
							<Box className={classes.header}>
								<Grid container spacing={0}>
									<Grid item xs={1}></Grid>
									<Grid item xs={8}>
										Property Address
									</Grid>
									<Grid item xs={3}>
										Date Ordered
									</Grid>
								</Grid>
							</Box>
							{uncompleted.map((item) => (
								<Accordion elevation={0} key={item.id}>
									<AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.accSum}>
										<Grid container spacing={2}>
											<Grid item xs={1}>
												<AiOutlineHistory size={'20px'} color='#808080ba' sx={{ opacity: '0.6 !important' }} />
											</Grid>
											<Grid item xs={8}>
												{item.clients_physical_address}
											</Grid>
											<Grid item xs={3}>
												{new Date(item.date_created).toDateString()}
											</Grid>
										</Grid>
									</AccordionSummary>
									<AccordionDetails>
										<Box>
											<Typography>{item.clients_physical_address}</Typography>
											<Typography sx={{ mt: '10px' }}>{item.reason_for_request}</Typography>
											<Button variant='contained' className={classes.button} onClick={() => openDetailsModal(item)} sx={{ width: 'fit-content' }}>
												View Details
											</Button>
										</Box>
									</AccordionDetails>
								</Accordion>
							))}
						</div>
						<div style={{ marginTop: '20px' }}>
							<Typography variant='p' gutterBottom className={classes.title}>
								Completed Orders
							</Typography>
							<Box className={classes.header}>
								<Grid container spacing={0}>
									<Grid item xs={1}></Grid>
									<Grid item xs={8}>
										Property Address
									</Grid>
									<Grid item xs={3}>
										Date Ordered
									</Grid>
								</Grid>
							</Box>
							{completed.map((item) => (
								<Accordion elevation={0} key={item.id}>
									<AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.accSum}>
										<Grid container spacing={2}>
											<Grid item xs={1}>
												<DescriptionIcon color='#808080ba' size={'20px'} />
											</Grid>
											<Grid item xs={8}>
												{item.clients_physical_address}
											</Grid>
											<Grid item xs={3}>
												{new Date(item.date_created).toDateString()}
											</Grid>
										</Grid>
									</AccordionSummary>
									<AccordionDetails>
										<Box>
											<Typography>{item.clients_physical_address}</Typography>
											<Typography sx={{ mt: '10px' }}>{item.reason_for_request}</Typography>

											<Button variant='contained' className={classes.button} href={`/preview-report/${item?.id}`} sx={{ width: 'fit-content' }}>
												View Report
											</Button>
										</Box>
									</AccordionDetails>
								</Accordion>
							))}
						</div>
					</>
				) : (
					<ToBPOReport />
				)}
			</div>
		</>
	);
}

export default ClientOrderedReport;

const ToBPOReport = () => {
	const classes = useStyles();

	return (
		<Box minHeight={'50vh'} display='flex' alignItems='center' justifyContent='center' flexDirection='column' gap='5px'>
			<Typography variant='p' gutterBottom className={classes.title}>
				Want to know your home value? Get a BPO Report from any of our local real estate experts
			</Typography>
			<DescriptionIcon color='secondary' fontSize='large' />
			<Button variant='contained' className={classes.button} sx={{ marginLeft: 'unset !important' }} href='order-bpo-report'>
				Order BPO Report
			</Button>
		</Box>
	);
};
