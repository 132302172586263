
export const changeCodeToDealType = (code) => {
    let dealType
    switch (code) {
        case 1:
            dealType = 'straight_model'
            break
        case 2:
            dealType = 'remodel_add_addition'
            break
        case 3:
            dealType = 'remodel_down_studs'
            break
        case 4:
            dealType = 'remodel_down_studs_addition'
            break
        case 5:
            dealType = 'new_construction'
            break
        case 6:
            dealType = 'buy_and_rent'
            break
        default:
            dealType = 'straight_model'
    }
    return dealType
}



