import { Bed, CallOutlined, ChevronLeft, ChevronRight, EastOutlined, MailOutline, PlaceOutlined } from '@mui/icons-material';
import { Box, Button, Link as DLink, Divider, Grid, IconButton, InputLabel, Stack, TextField, Typography, createTheme } from '@mui/material';
import { ThemeProvider, makeStyles, styled } from '@mui/styles';
import React, { useState } from 'react';
import { Link as RLink } from 'react-router-dom';
import InvestorQuestionnaires from '../../components/InvestorQuestionnaires/InvestorQuestionnaires';
import { ReactComponent as ArrowsOut } from '../../images/ArrowsOut.svg';
import { ReactComponent as Bathtub } from '../../images/Bathtub.svg';
import { ReactComponent as FBIcon } from '../../images/facebookfillable.svg';
import { ReactComponent as IGIcon } from '../../images/instagramfillable.svg';
import { ReactComponent as LIIcon } from '../../images/linkedInfillable.svg';
import BG from '../../images/temp13bg.jpg';
import TBG from '../../images/testimonial_bg.jpg';
import { ReactComponent as TWIcon } from '../../images/twitterfillable.svg';
import { ReactComponent as YTIcon } from '../../images/youtubefillable.svg';
import { formatNumber, formatPhoneNumber, getYoutubeEmbedLink } from '../../utils/functions';

const pageTheme = createTheme({
	// ...theme,
	typography: {
		fontFamily: 'Inter, san-serif',
		button: {
			textTransform: 'capitalize',
		},
	},
	palette: {
		primary: {
			main: '#8E2109',
		},
		secondary: {
			main: '#4A60A1',
		},
		text: {
			main: '#8E2109',
		},
		paper: {
			main: '#fff',
		},
	},
	primaryColor: '#8E2109',
	secondaryColor: '#4A60A1',
});

const ModText = styled(TextField)(({ theme }) => ({
	background: 'white',
	fontFamily: 'Inter, san-serif',
	'&>MuiInputBase-root': {
		background: 'white',
		fontFamily: 'Inter, san-serif',
		border: '1px solid #D3D3D3',
	},

	'&::placeholder': {
		fontFamily: 'Inter, san-serif',
	},
}));
const ModLabel = styled(InputLabel)(({ theme }) => ({
	fontFamily: 'Inter, san-serif',
	textTransform: 'capitalize',
	fontWeight: 400,
	fontSize: '16px',
	color: '#A54D3A',
}));

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		minHeight: '100vh',
		fontFamily: 'Inter, san-serif',
	},
	navroot: {
		padding: '1rem',
		boxShadow: '0px 4px 10px 0px #00000026',
		color: 'black',
		backgroundColor: 'white',
		position: 'fixed',
		top: 0,
		zIndex: 100,
		width: '100%',
	},
	container: {
		width: '100%',
		height: '100vh',
		backgroundSize: 'cover',
		background: `url(${BG})`,
		display: 'flex',
		justifyContent: 'flex-end',
		alignContent: 'center',
	},
	link: {
		color: 'black',
	},
	half: {
		minHeight: '500px',
		background: 'linear-gradient(to bottom, #8E2109 50%, white 50%)',
		padding: '2rem',
		paddingTop: '5rem',
	},
	video: {
		background: '#8E2109CC',
		padding: '2rem',
	},
	clients: { width: '60%', background: `url(${TBG})`, backgroundSize: 'cover', aspectRatio: '1.5', padding: '2rem', backgroundPosition: 'center' },
	testimon: {
		background: '#8E210980',
		boxShadow: '0px 30px 60px 0px #0000001A',
		fontFamily: 'Space Grotesk, Inter, san-serif',
		width: '60%',
		padding: '2rem 5rem',
		borderRadius: '25px',
		position: 'relative',
		'&::before': {
			content: 'open-quote',
			position: 'absolute',
			top: '10px',
			left: '10px',
			fontSize: '100px',
			fontFamily: 'monospace',
			color: 'white',
		},
	},
}));

const Link = styled(DLink)(({ theme }) => ({
	cursor: 'pointer',
}));

export const PlainButton = styled(Button)(({ theme }) => ({
	background: 'white',
	color: 'black',
	padding: '10px',
	border: '1px solid black',
	borderRadius: '100px',
	textTransform: 'capitalize',
	fontSize: '14px',
	minWidth: 'fit-content',
	flex: 1,
	'&:hover': {
		background: 'white',
	},
}));

const NewTemp13 = ({ agent, listings, testimonials, contactDetails, updateContactDetails, sendMessage }) => {
	const classes = useStyles();
	const name = agent?.user.first_name + ' ' + agent?.user.last_name;
	const agent_id = agent?.id;
	// const member_since = agent?.year_work_commenced;
	const agent_phone = formatPhoneNumber(agent?.phone);
	const brokerage_name = agent?.brokerage_name;
	const license = agent?.licenses.find((license) => license.category === 'real_estate_agent')?.number;
	const address = agent?.address;
	const video = agent?.video_link;
	const showInvestorForm = agent?.questionnaires_on;
	const active = listings.active.length;
	const [testPage, setTestPage] = useState(0);
	const selectedTestimonial = testimonials[Math.abs(testPage % testimonials.length)];
	const testimonialName =
		selectedTestimonial?.client?.user?.first_name || selectedTestimonial?.client?.user?.last_name
			? selectedTestimonial?.client?.user?.first_name + ' ' + selectedTestimonial?.client?.user?.last_name
			: name;

	const [activePage, setActivePage] = useState(1);
	const activeToUse = listings.active?.slice((activePage - 1) * 6, 6 * (activePage - 1) + 6);
	const [soldPage, setSoldPage] = useState(1);
	const soldToUse = listings.sold?.slice((soldPage - 1) * 6, 6 * (soldPage - 1) + 6);

	return (
		<ThemeProvider theme={pageTheme}>
			<Stack className={classes.root}>
				<Stack gap='2rem'>
					<Box className={classes.container}>
						<Stack direction='row' gap='2rem' justifyContent={'space-between'} alignItems='center' className={classes.navroot}>
							<Typography variant='h6' className={classes.agentName}>
								{name}
							</Typography>
							{/* Left side with 3 anchor links */}
							<Stack direction='row' gap='2rem' justifyContent='space-between'>
								{active > 0 && (
									<Link href='#active' className={classes.link} underline='none'>
										Active Listings
									</Link>
								)}
								<Link href='#about' className={classes.link} underline='none'>
									About
								</Link>
								<Link href='#contact' className={classes.link} underline='none'>
									Contact
								</Link>
								<Link href={`/agent/${agent_id}/blogs`} className={classes.link} underline='none'>
									Blogs
								</Link>
								{showInvestorForm && (
									<Link href='#investors' className={classes.link} underline='none'>
										Investors
									</Link>
								)}
							</Stack>
							<Button
								color='primary'
								variant='contained'
								href='#contact'
								sx={{
									borderRadius: '30px',
									textTransform: 'capitalize',
									backgroundColor: '#8E2109',
									color: 'white',
									fontFamily: 'Inter, san-serif',
									'&:hover': { backgroundColor: '#8E2109' },
								}}
							>
								Contact Us
							</Button>
						</Stack>
						<Stack direction='column' spacing={3} alignItems={'center'} justifyContent={'center'} ml='3rem'>
							{agent?.facebook && (
								<IconButton target='blank' href={agent?.facebook}>
									<FBIcon fill='white' />
								</IconButton>
							)}
							{agent?.twitter && (
								<IconButton target='blank' href={agent?.twitter}>
									<TWIcon fill='white' />
								</IconButton>
							)}
							{agent?.instagram && (
								<IconButton target='blank' href={agent?.instagram}>
									<IGIcon fill='white' />
								</IconButton>
							)}
							{agent?.linkedin && (
								<IconButton target='blank' href={agent?.linkedin}>
									<LIIcon fill='white' />
								</IconButton>
							)}
							{agent?.video_link && (
								<IconButton target='blank' href={agent?.video_link}>
									<YTIcon fill='white' />
								</IconButton>
							)}
						</Stack>
					</Box>
					{listings.active.length > 0 && (
						<Stack justifyContent={'center'} alignItems='center' p='2rem 13rem' gap='2rem' id='active'>
							<Typography color={'#8E2109'} fontFamily={'Inter'} variant='h4' fontSize='40px' fontWeight={600}>
								Active Listings
							</Typography>
							<Stack direction='row' justifyContent='flex-end' alignSelf={'flex-end'} sx={{ width: '20%' }} gap='1rem'>
								<PlainButton onClick={() => setActivePage((v) => v - 1)} disabled={activePage === 1}>
									Previous
								</PlainButton>
								<PlainButton onClick={() => setActivePage((v) => v + 1)} disabled={activePage === Math.ceil(listings.active.length / 6)}>
									Next
								</PlainButton>
							</Stack>
							<Grid container spacing={2}>
								{activeToUse.map((listing) => (
									<Grid item xs={4} key={listing.key}>
										<ListingCardfor13 {...listing} agentId={agent?.id} />
									</Grid>
								))}
							</Grid>
						</Stack>
					)}
					{showInvestorForm && (
						<Stack sx={{ backgroundColor: '#F5FBFF' }} direction={'row'} justifyContent={'flex-end'}>
							<InvestorQuestionnaires agent={agent} />
						</Stack>
					)}
					<Stack className={classes.half}>
						<Stack direction='row' gap='1rem' alignItems='flex-end'>
							<Stack flex='1' justifyContent={'flex-end'} direction='row'>
								<img
									src={agent?.profile_image}
									alt='Agent'
									style={{
										width: '400px',
										aspectRatio: '3/4',
										objectFit: 'cover',
										objectPosition: 'top center',
									}}
								/>
							</Stack>
							<Stack justifyContent={'flex-end'} sx={{ flex: 1 }} gap='2rem' id='#about'>
								<Typography fontWeight={500} fontSize={50}>
									About
								</Typography>
								<Typography fontFamily={'Jost, Inter, san-serif'} textAlign='justify'>
									{agent?.bio}
								</Typography>
								<Stack gap='1rem' direction='row'>
									<Button
										variant='outlined'
										sx={{ textTransform: 'none', borderColor: '#8E2109', color: '#8E2109', fontFamily: 'Jost, Inter, san-serif', fontWeight: 400 }}
										href={`/order-bpo-report/${agent_id}`}
										target='_blank'
									>
										Get A BPO Report
									</Button>
									<Button
										variant='contained'
										sx={{ textTransform: 'none', backgroundColor: '#8E2109', color: 'white', fontFamily: 'Jost, Inter, san-serif', fontWeight: 400 }}
										href='#contact'
									>
										Let's Connect
									</Button>
								</Stack>
							</Stack>
						</Stack>
					</Stack>
					{listings.sold.length > 0 && (
						<Stack justifyContent={'center'} alignItems='center' p='2rem 13rem' gap='2rem' id='active'>
							<Typography color={'#8E2109'} fontFamily={'Inter'} variant='h4' fontSize='40px' fontWeight={600}>
								Sold Listings
							</Typography>
							<Stack direction='row' justifyContent='flex-end' alignSelf={'flex-end'} sx={{ width: '20%' }} gap='1rem'>
								<PlainButton onClick={() => setSoldPage((v) => v - 1)} disabled={soldPage === 1}>
									Previous
								</PlainButton>
								<PlainButton onClick={() => setSoldPage((v) => v + 1)} disabled={soldPage === Math.ceil(listings.sold.length / 6)}>
									Next
								</PlainButton>
							</Stack>
							<Grid container spacing={2}>
								{soldToUse.map((listing) => (
									<Grid item xs={4} key={listing.key}>
										<ListingCardfor13 {...listing} agentId={agent?.id} />
									</Grid>
								))}
							</Grid>
						</Stack>
					)}
					{video && (
						<Stack className={classes.video} alignItems='center' justifyContent={'center'} gap='1rem'>
							<Typography color='white' fontFamily={'Syne'} fontSize={42} fontWeight={700}>
								Video Spotlight
							</Typography>
							<iframe
								style={{
									aspectRatio: '16/9',
									borderRadius: '0px',
									border: 'none',
									width: '75%',
									margin: 'auto',
									display: 'block',
								}}
								src={`https://www.youtube.com/embed/${getYoutubeEmbedLink(video)}`}
								title='Client video'
								allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
								allowFullScreen
							></iframe>
						</Stack>
					)}
					{testimonials.length > 0 && (
						<Stack direction='row' justifyContent={'center'} alignItems='flex-start'>
							<Stack className={classes.clients} justifyContent='center' alignItems='center' mt='3rem' gap='3rem'>
								<Typography fontFamily='Space Grotesk' fontWeight={700} fontSize={30}>
									What Our Clients Say
								</Typography>
								<Stack direction='row' alignItems='center' justifyContent={'center'} gap='2rem'>
									<IconButton onClick={() => setTestPage((v) => v - 1)}>
										<ChevronLeft fontSize='large' />
									</IconButton>
									<Stack justifyContent={'center'} alignItems='center' className={classes.testimon}>
										<Typography fontFamily='Space Grotesk' color='white' fontWeight={400} fontSize={22}>
											{selectedTestimonial?.description}
										</Typography>
										<Typography color='white' fontWeight={400} fontSize={22} fontFamily='Space Grotesk'>
											{testimonialName}
										</Typography>
									</Stack>
									<IconButton onClick={() => setTestPage((v) => v + 1)}>
										<ChevronRight fontSize='large' />
									</IconButton>
								</Stack>
							</Stack>
						</Stack>
					)}
					<Stack m='3rem' sx={{ backgroundColor: '#F5F6F7' }} justifyContent='center' gap='1rem' alignItems='center' p='2rem'>
						<Stack sx={{ width: '50%', color: '#061C3D', fontFamily: 'Inter, san-serif', textAlign: 'center' }}>
							<Typography color='inherit' fontFamily={'inherit'} textAlign='inherit' fontSize={'50px'} fontWeight={700} sx={{ textWrap: 'balance' }}>
								Have a home in mind? Drop me a line.
							</Typography>
							<Typography color='inherit' fontFamily={'inherit'} textAlign='inherit' fontWeight={400}>
								Have a home in mind! Drop me a line.
							</Typography>
						</Stack>
						<Stack sx={{ backgroundColor: 'white', borderRadius: '24px', width: '70%', overflow: 'hidden', maxWidth: '1000px' }} direction='row'>
							<Stack width='44%' sx={{ backgroundColor: '#A54D3A', position: 'relative', fontFamily: 'Inter,san-serif' }} p='2rem'>
								<Typography fontSize={'32px'} fontWeight={700} fontFamily={'inherit'} color='white'>
									Get In Touch
								</Typography>
								<Typography fontSize={'20px'} fontFamily={'inherit'} color='white'>
									Brokerage: {brokerage_name}
								</Typography>
								<Typography fontSize={'20px'} fontFamily={'inherit'} color='white'>
									License: {license}
								</Typography>
								<Stack gap='2rem' my='3rem'>
									<Stack
										direction='row'
										component='a'
										sx={{ borderRadius: 100, width: 'fit-content', cursor: 'pointer' }}
										gap='1rem'
										href={`mailto:${agent?.user?.email}`}
									>
										<Stack
											justifyContent='center'
											alignItems='center'
											sx={{ width: '54px', aspectRatio: '1', backgroundColor: '#FFFFFF14', borderRadius: '50%' }}
										>
											<MailOutline fontSize='medium' sx={{ fill: 'white' }} />
										</Stack>
										<Stack>
											<Typography color='white' fontFamily={'Inter,san-serif'} fontSize='20px' fontWeight={700}>
												Email
											</Typography>
											<Typography color='white' fontFamily={'Inter,san-serif'} fontSize='16px' fontWeight={400}>
												{agent?.user?.email}
											</Typography>
										</Stack>
									</Stack>
									<Stack
										direction='row'
										component='a'
										sx={{ borderRadius: 100, width: 'fit-content', cursor: 'pointer' }}
										gap='1rem'
										href={`tel:${agent_phone}`}
									>
										<Stack
											justifyContent='center'
											alignItems='center'
											sx={{ width: '54px', aspectRatio: '1', backgroundColor: '#FFFFFF14', borderRadius: '50%' }}
										>
											<CallOutlined fontSize='medium' sx={{ fill: 'white' }} />
										</Stack>
										<Stack>
											<Typography color='white' fontFamily={'Inter,san-serif'} fontSize='20px' fontWeight={700}>
												Phone Number
											</Typography>
											<Typography color='white' fontFamily={'Inter,san-serif'} fontSize='16px' fontWeight={400}>
												{agent_phone}
											</Typography>
										</Stack>
									</Stack>
									<Stack
										direction='row'
										component='a'
										sx={{ borderRadius: 100, width: 'fit-content', cursor: 'pointer' }}
										gap='1rem'
										href={`tel:${agent_phone}`}
									>
										<Stack
											justifyContent='center'
											alignItems='center'
											sx={{ width: '54px', aspectRatio: '1', backgroundColor: '#FFFFFF14', borderRadius: '50%' }}
										>
											<PlaceOutlined fontSize='medium' sx={{ fill: 'white' }} />
										</Stack>
										<Stack justifyContent='center'>
											<Typography color='white' fontFamily={'Inter,san-serif'} fontSize='16px' fontWeight={400}>
												{address}
											</Typography>
										</Stack>
									</Stack>

									<Stack mt='3rem'>
										<Stack direction='row' alignItems='center' gap='1rem'>
											<Divider sx={{ width: '32px', backgroundColor: 'white', height: '2px' }} />
											<Typography fontFamily={'Inter'} fontWeight={500} color='white'>
												Connect with me:
											</Typography>
										</Stack>
										<Stack direction='row' gap='1rem'>
											{agent?.facebook && (
												<IconButton
													sx={{ borderRadius: '5px !important', backgroundColor: '#FFFFFF14 !important' }}
													target='blank'
													href={agent?.facebook}
												>
													<FBIcon fill='white' />
												</IconButton>
											)}
											{agent?.twitter && (
												<IconButton target='blank' href={agent?.twitter}>
													<TWIcon fill='white' />
												</IconButton>
											)}
											{agent?.instagram && (
												<IconButton target='blank' href={agent?.instagram}>
													<IGIcon fill='white' />
												</IconButton>
											)}
											{agent?.linkedin && (
												<IconButton target='blank' href={agent?.linkedin}>
													<LIIcon fill='white' />
												</IconButton>
											)}
											{agent?.video_link && (
												<IconButton target='blank' href={agent?.video_link}>
													<YTIcon fill='white' />
												</IconButton>
											)}
										</Stack>
									</Stack>
								</Stack>
							</Stack>
							<Stack p='2rem'>
								<Grid container spacing={2} justifyContent={'center'} alignItems='center'>
									<Grid item xs={12}>
										<ModLabel>Full Name </ModLabel>
										<ModText
											placeholder='John David'
											fullWidth
											size='small'
											value={contactDetails.name}
											onChange={updateContactDetails}
											name='name'
										/>
									</Grid>
									<Grid item xs={6}>
										<ModLabel>Your Email </ModLabel>
										<ModText
											placeholder='example@yourmail.com'
											fullWidth
											size='small'
											value={contactDetails.email}
											onChange={updateContactDetails}
											name='email'
										/>
									</Grid>
									<Grid item xs={6}>
										<ModLabel>Phone </ModLabel>
										<ModText
											placeholder='(000) 123 456'
											fullWidth
											size='small'
											value={contactDetails.phone}
											onChange={updateContactDetails}
											name='phone'
										/>
									</Grid>

									<Grid item xs={12}>
										<ModLabel>Leave A Message </ModLabel>
										<ModText
											placeholder='Hello there,I would like to talk about how to...'
											fullWidth
											size='small'
											multiline
											rows={4}
											value={contactDetails.message}
											onChange={updateContactDetails}
											name='message'
											sx={{ borderRadius: '20px !important', '&>MuiInputBase-root': { borderRadius: '20px' } }}
										/>
										<Button
											onClick={sendMessage}
											variant='contained'
											sx={{ backgroundColor: '#A54D3A', textTransform: 'capitalize', color: 'white', marginTop: '2rem' }}
											endIcon={<EastOutlined sx={{ fill: 'white' }} />}
										>
											Send Message
										</Button>
									</Grid>
								</Grid>
							</Stack>
						</Stack>
					</Stack>
				</Stack>
			</Stack>
		</ThemeProvider>
	);
};

export default NewTemp13;

export const ListingCardfor13 = ({ id, agentId, list_price, photos, beds, baths, square_footage, address }) => {
	return (
		<Link component={RLink} to={`/view-listing/${agentId}/${id}`} target='_blank'>
			<Stack sx={{ borderRadius: '7.3px', boxShadow: '0px 29.21px 82.77px 0px #3B4D8114', overflow: 'hidden', height: '100%' }}>
				<img
					alt='property'
					style={{
						width: '100%',
						aspectRatio: '18/10',
						objectFit: 'cover',
					}}
					src={photos?.[0]?.image}
				/>
				<Stack padding='1rem' gap='1rem'>
					<Typography variant='body1' fontWeight={500} fontSize={'20px'} color='#363A3D'>
						{address}
					</Typography>
					<Typography fontWeight={600} color='#4A60A1' fontSize={18}>
						$ {formatNumber(list_price, 0)}
					</Typography>
					<Stack direction='row' gap='1rem' alignItems='center'>
						<Stack direction='row' gap='5px' alignItems='center'>
							<Bed sx={{ fill: '#6D737A' }} />
							<Typography fontWeight={500} fontSize='16px' color='#6D737A'>
								{beds || '-'}
							</Typography>
						</Stack>
						<Stack direction='row' gap='5px' alignItems='center'>
							<Bathtub />
							<Typography fontWeight={500} fontSize='16px' color='#6D737A'>
								{baths}
							</Typography>
						</Stack>
						<Stack direction='row' gap='5px' alignItems='center'>
							<ArrowsOut />
							<Typography fontWeight={500} fontSize='16px' color='#6D737A'>
								{formatNumber(square_footage)} sq ft
							</Typography>
						</Stack>
					</Stack>
				</Stack>
			</Stack>
		</Link>
	);
};
