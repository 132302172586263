import {
	Checkbox,
	Chip,
	Collapse,
	Divider,
	IconButton,
	InputAdornment,
	MenuItem,
	Button,
	Pagination,
	Popover,
	Select,
	Stack,
	Tab as Tabb,
	Tabs,
	TextField,
	Typography,
} from '@mui/material';
import { makeStyles, styled } from '@mui/styles';
import ChevronUp from '@untitled-ui/icons-react/build/esm/ChevronUp';
import ChevronUpDouble from '@untitled-ui/icons-react/build/esm/ChevronUpDouble';
import ClipboardCheck from '@untitled-ui/icons-react/build/esm/ClipboardCheck';
import DotsVertical from '@untitled-ui/icons-react/build/esm/DotsVertical';
import Edit05 from '@untitled-ui/icons-react/build/esm/Edit05';
import FilePlus02 from '@untitled-ui/icons-react/build/esm/FilePlus02';
import SearchLg from '@untitled-ui/icons-react/build/esm/SearchLg';
import Trash01 from '@untitled-ui/icons-react/build/esm/Trash01';
import dayjs from 'dayjs';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import base from '../apis';
import BadgeForQuotes from '../components/BadgeForQuotes';
import DatePickerForQuotes from '../components/DatePickerForQuotes';
import DeleteDialog from '../components/DeleteDialog';
import LoadingDialog from '../components/LoadingDialog';
import QuoteTableHeader from '../components/QuoteTableHeader';

const Tab = styled(Tabb)(({ theme }) => ({
	textTransform: 'capitalize',
	gap: '1rem',
	minHeight: 'unset',
}));

const useStyles = makeStyles((theme) => ({
	checkbox: {
		marginRight: '1.2rem',
	},
	hoverer: {
		whiteSpace: 'nowrap',
		cursor: 'pointer',
		textAlign: 'center',
	},
	red: {
		stroke: '#D92D20',
		'& path': {
			stroke: 'inherit',
		},
	},
}));

const statusColor = {
	pending: {
		main: '#B54708',
		background: '#FFFAEB',
	},
	closed: {
		main: '#027A48',
		background: '#ECFDF3',
	},
	draft: {
		main: '#344054',
		background: '#F2F4F7',
	},
};

const tabs = [
	{
		value: '',
		tabKey: 'all_quotes',
		label: 'All Quotes',
	},
	{
		value: 'pending',
		label: 'Pending Submission',
	},
	{
		value: 'closed',
		label: 'Closed Quotes',
	},
	{
		value: 'draft',
		label: 'Draft Quotes',
	},
];

const RequestedQuotes = () => {
	const [quotes, setQuotes] = useState([]);
	const [page, setPage] = useState(0);
	const [count, setCount] = useState(0);
	const [tabCounts, setTabCounts] = useState({
		all_quotes: 0,
		pending: 0,
		closed: 0,
		draft: 0,
	});
	const [isLoading, setIsLoading] = useState(true);
	const { indPro } = useSelector((state) => state.subscriptions);
	const selectedListing = useSelector((state) => state.auth.selectedListing);
	const [selectedQuotes, setSelectedQuotes] = useState([]);
	const allQuoteIds = quotes.map((i) => i.id);
	const equal = _.isEqual(allQuoteIds.sort(), selectedQuotes.sort()) && selectedQuotes.length > 0;
	const [option, setOption] = useState('');

	const navigate = useNavigate();
	const [selectedTab, setSelectedTab] = useState('');

	const getAllData = useCallback(
		async (extraParams) => {
			const { start_date, end_date, page: mainPage } = extraParams || {};
			try {
				setIsLoading(true);
				const params = {
					profession_id: selectedListing?.id,
				};
				if (mainPage || page) params.page = mainPage || page;
				if (selectedTab) params.status = selectedTab;
				if (start_date) params.date_gt = dayjs(start_date).format('YYYY-MM-DD');
				if (end_date) params.date_lt = dayjs(end_date).format('YYYY-MM-DD');
				const { data } = await base.get('professionals/quotes', {
					params,
				});
				setTabCounts(data.quote_count);
				setCount(data.count);
				setPage(page || 1);
				setOption('');
				setSelectedQuotes([]);

				setQuotes(data.results);
			} catch (error) {
				toast.warn(error?.response?.data?.detail || 'Could not get requested quotes');
			} finally {
				setIsLoading(false);
			}
		},
		[selectedListing?.id, page, selectedTab]
	);

	useEffect(() => {
		(async () => {
			if (!selectedListing) {
				navigate('/my-listing/all');
				return;
			}
			if (!indPro) {
				navigate('/industry-prof-subscription');
				return;
			}

			getAllData();
		})();
	}, [indPro, navigate, selectedListing, getAllData]);

	const handleSelectAll = () => {
		if (equal) setSelectedQuotes([]);
		else setSelectedQuotes(allQuoteIds);
	};
	const onCheckboxChange = (id) => {
		if (selectedQuotes.includes(id)) setSelectedQuotes((v) => v.filter((i) => i !== id));
		else setSelectedQuotes((v) => [id, ...v]);
	};

	const handlePageChange = async (e, page) => {
		setPage(page);
	};

	const deleteQuotes = async () => {
		try {
			setIsLoading(true);
			await base.post('/professionals/delete-quotes/', {
				ids: toDelete,
			});
			setDeleteOpen(false);
			setToDelete([]);
			getAllData();
		} catch (error) {
			toast.error('Could not close quotes');
		} finally {
			setIsLoading(false);
		}
	};

	const [toDelete, setToDelete] = useState([]);
	const [deleteOpen, setDeleteOpen] = useState(false);
	const startDeletionProcess = (ids) => {
		setToDelete(ids);
		setDeleteOpen(true);
	};

	const closeQuotes = async (ids) => {
		try {
			setIsLoading(true);
			const statusIds = [];
			ids.forEach((item) => {
				const quote = quotes.find((i) => i.id === item);
				if (quote) {
					const statusId = quote.status.id;
					if (statusId) statusIds.push(statusId);
				}
			});
			await base.post('/professionals/change-quotes/', {
				ids: statusIds,
				status: 'closed',
			});
			getAllData();
		} catch (error) {
			toast.error('Could not close quotes');
		} finally {
			setIsLoading(false);
		}
	};

	const handleChange = (event, newValue) => {
		setSelectedTab(newValue);
		getAllData({ page: 1 });
	};

	const handleBulkAction = async () => {
		if (option === 'Delete Selected') {
			startDeletionProcess(selectedQuotes);
			setOption('');
			return;
		}
		if (option === 'Close Selected') {
			await closeQuotes(selectedQuotes);
			setOption('');
			return;
		}
	};

	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);

	return (
		<>
			<LoadingDialog isLoading={isLoading} />

			<DeleteDialog
				isOpen={deleteOpen}
				onCancel={() => {
					setToDelete([]);
				}}
				items={selectedQuotes}
				onConfirm={deleteQuotes}
				setOpen={setDeleteOpen}
			/>

			<Stack p='1rem' sx={{ minHeight: '100vh' }} gap='1rem'>
				<Stack sx={{ borderBottom: '1px solid #EAECF0', paddingBottom: '20px' }} direction='row' justifyContent={'space-between'}>
					<Typography variant='h1' color='#475467' fontWeight={600} fontSize='24px'>
						Quotes
					</Typography>
					<Stack direction='row' gap='1rem'>
						<DatePickerForQuotes label='Start Date' value={startDate} onChange={setStartDate} />
						<DatePickerForQuotes label='End Date' value={endDate} onChange={setEndDate} />
						<Button
							color='secondary'
							variant='outlined'
							onClick={() => {
								getAllData({ start_date: startDate, end_date: endDate, page: 1 });
							}}
						>
							Find
						</Button>
					</Stack>
				</Stack>
				<Tabs
					value={selectedTab}
					onChange={handleChange}
					sx={{ borderBottom: 1, borderColor: '#EAECF0' }}
					textColor='secondary'
					indicatorColor='secondary'
				>
					{tabs.map((el) => (
						<Tab
							label={el.label}
							value={el.value}
							key={el.value}
							icon={<BadgeForQuotes badgeContent={tabCounts?.[el.tabKey || el.value]} isActive={selectedTab === el.value}></BadgeForQuotes>}
							iconPosition='end'
						/>
					))}
				</Tabs>

				<Stack direction='row' justifyContent={'space-between'} sx={{ background: '#F9FAFB', borderRadius: '12px', padding: '1rem' }}>
					<TextField
						size='small'
						color='secondary'
						placeholder='Search'
						InputProps={{
							startAdornment: (
								<InputAdornment position='start'>
									<SearchLg />
								</InputAdornment>
							),
						}}
					/>
					<Stack direction='row' gap='1rem'>
						<Select
							size='small'
							value={option}
							onChange={(e) => setOption(e.target.value)}
							displayEmpty
							color='secondary'
							renderValue={(value) => (value ? value : 'Bulk Action')}
							disabled={selectedQuotes.length === 0}
						>
							<MenuItem value='Delete Selected'>Delete Selected</MenuItem>
							{selectedTab !== 'closed' && <MenuItem value='Close Selected'>Close Selected</MenuItem>}
						</Select>
						<Button color='secondary' variant='outlined' onClick={handleBulkAction}>
							Apply
						</Button>
					</Stack>
				</Stack>
				<QuoteTableHeader
					allselected={equal}
					onChange={handleSelectAll}
					indeterminate={selectedQuotes.length > 0 && selectedQuotes.length < allQuoteIds.length}
				/>

				{quotes.map((item, index) => (
					<Collapsible
						key={item.id}
						{...item}
						index={index}
						handleCheckbox={onCheckboxChange}
						handleCloseQuotes={closeQuotes}
						handleDeleteQuote={startDeletionProcess}
						selected={selectedQuotes?.includes(item.id)}
					/>
				))}
				<Pagination
					page={page}
					count={Math.ceil(count / 10)}
					onChange={handlePageChange}
					color='secondary'
					variant='outlined'
					shape='rounded'
					sx={{ margin: 'auto' }}
				/>
			</Stack>
		</>
	);
};

export default RequestedQuotes;

const Collapsible = ({
	first_name,
	last_name,
	email,
	phone_number,
	message,
	handleCheckbox,
	id,
	index,
	created_at,
	status: { status },
	handleCloseQuotes,
	handleDeleteQuote,
	selected,
}) => {
	const classes = useStyles();
	const [open, setOpen] = useState(false);
	const name = `${first_name} ${last_name}`;

	const [anchorEl, setAnchorEl] = useState(null);

	const handleClick = (event) => {
		preventer(event);
		setAnchorEl(event.currentTarget);
	};
	const handleDelete = (e) => {
		preventer(e);
		handleDeleteQuote([id]);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};
	const popOverOpen = Boolean(anchorEl);

	const preventer = (e) => {
		e?.stopPropagation();
	};

	return (
		<>
			<Popover
				component={Stack}
				open={popOverOpen}
				anchorEl={anchorEl}
				onClose={handleClose}
				sx={{
					'& .MuiPaper-root': {
						borderRadius: '12px',
						border: '1px solid #EAECF0',
						boxShadow: '0px 8px 8px -4px #10182808, 0px 20px 24px -4px #10182814',
						p: '0.5rem',
					},
				}}
			>
				<Stack divider={<Divider sx={{ borderColor: '#EAECF0' }} />} gap='5px'>
					{status !== 'closed' && (
						<Button
							onClick={() => {
								handleCloseQuotes([id]);
								handleClose();
							}}
							color='text'
							variant='text'
							fullWidth
						>
							Close Quote
						</Button>
					)}
					<Button href={`/requested-quotes/${id}`} variant='text' color='text' className={classes.hoverer}>
						Generate Quote
					</Button>
				</Stack>
			</Popover>
			<Stack
				direction='row'
				justifyContent={'space-evenly'}
				alignItems='center'
				sx={{ p: '1rem', boxShadow: '0px 0px 5px 0px #0000001A', borderRadius: '8px', fontSize: '14px', color: '#475467', cursor: 'pointer' }}
				onClick={() => setOpen(true)}
			>
				<Stack direction='row' gap='1rem' sx={{ flex: 2 }} alignItems='center'>
					<Checkbox
						size='small'
						color='secondary'
						className={classes.checkbox}
						onClick={preventer}
						onChange={(e) => handleCheckbox(id)}
						checked={selected}
					/>
					<Typography color='#475467' fontSize='14px'>
						{index + 1}
					</Typography>
					<Typography fontWeight={500} fontSize='14px' color='#101828'>
						{name}
					</Typography>
				</Stack>
				<Typography sx={{ flex: 2 }} fontSize='14px' textAlign={'center'}>
					{email}
				</Typography>
				<Typography sx={{ flex: 2 }} fontSize='14px' textAlign={'center'}>
					{phone_number}
				</Typography>
				<Typography sx={{ flex: 1 }} fontSize='14px' textAlign={'center'}>
					{dayjs(created_at).format('MMMM DD, YYYY')}
				</Typography>
				<Stack sx={{ flex: 1 }} fontSize='14px' alignItems='center'>
					<Chip
						label={status}
						sx={{
							color: statusColor?.[status].main,
							background: statusColor?.[status].background,
							textTransform: 'capitalize',
						}}
					/>
				</Stack>
				<Stack sx={{ flex: 1 }} gap='1rem' direction='row'>
					<IconButton href={`/requested-quotes/${id}`} size='small' onClick={preventer}>
						<Edit05 />
					</IconButton>
					<IconButton onClick={handleDelete} size='small'>
						<Trash01 />
					</IconButton>
					<IconButton onClick={handleClick} size='small'>
						<DotsVertical />
					</IconButton>
				</Stack>
			</Stack>
			<Collapse in={open}>
				<Stack
					divider={<Divider sx={{ borderColor: '#EAECF0' }} />}
					gap='1rem'
					justifyContent={'space-evenly'}
					sx={{ p: '1rem', boxShadow: '0px 0px 5px 0px #0000001A', borderRadius: '8px', fontSize: '14px', color: '#475467' }}
				>
					<Stack direction='row' justifyContent='space-between' alignItems='center'>
						<Typography color='#101828' fontWeight={600} fontSize='18px'>
							Notes
						</Typography>
						<IconButton
							onClick={() => {
								setOpen(false);
							}}
						>
							<ChevronUp />
						</IconButton>
					</Stack>
					<Typography color='#475467'>{message}</Typography>
					<Stack direction='row' justifyContent={'space-between'}>
						<Button
							color='text'
							variant='outlined'
							startIcon={<ChevronUpDouble />}
							endIcon={<ChevronUpDouble />}
							onClick={() => {
								setOpen(false);
							}}
							sx={{ borderColor: '#D0D5DD', color: '#344054' }}
						>
							Cascade
						</Button>
						<Stack direction='row' gap='1rem'>
							{status !== 'closed' && (
								<Button
									color='text'
									variant='outlined'
									startIcon={<ClipboardCheck />}
									sx={{ borderColor: '#D0D5DD', color: '#344054' }}
									onClick={() => {
										handleCloseQuotes([id]);
									}}
								>
									Close Quote
								</Button>
							)}
							<Button color='secondary' variant='contained' startIcon={<FilePlus02 />} href={`/requested-quotes/${id}`}>
								Generate Quote
							</Button>
						</Stack>
					</Stack>
				</Stack>
			</Collapse>
		</>
	);
};
