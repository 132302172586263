import { ArrowBack, Description, Lock } from '@mui/icons-material'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Box, Button, Divider, Grid, IconButton, Stack, Tab, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'
import React, { useEffect, useReducer } from 'react'
import ContractSign from './Contract/ContractSign'
import StageOne from './Contract/StageOne'
import StageTwo from './Contract/StageTwo'
import ContractStatus from './helpers/ContractStatus'
import { stateFour, stateOne, stateSix, stateThree, stateTwo, statusFourFn, statusOneFn, statusSixFn, statusThreeFn, statusTwoFn } from './utils'
import StageThree from './Contract/StageThree'
import { leadUrl } from '../../../apis'
import { toast } from 'react-toastify'
import StageFour from './Contract/StageFour'
import StageFive from './Contract/StageFive'
import StageSix from './Contract/StageSix'

const ContractForm = ({ agent, lead, dispatch, data, setLeadState }) => {
    const [active, setActive] = React.useState({ value: '0', contract: false, status_1: true, status_2: true, status_3: true, status_4: true, status_5: true, status_6: true });
    const [load, setLoad] = React.useState(false)

    const handleChange = (event, newValue) => {
        setActive(prev => ({ ...prev, value: newValue }));
    };

    const [input1, dispatch1] = useReducer(statusOneFn, stateOne)
    const [input2, dispatch2] = useReducer(statusTwoFn, stateTwo)
    const [input3, dispatch3] = useReducer(statusThreeFn, stateThree)
    const [input4, dispatch4] = useReducer(statusFourFn, stateFour)
    const [input6, dispatch6] = useReducer(statusSixFn, stateSix)

    const contractSigned = lead?.progress?.some(el => el?.status === 'signed_contract')
    const status1 = lead?.progress?.some(el => el?.status === 'lead_level_1')
    const status2 = lead?.progress?.some(el => el?.status === 'lead_level_2')
    const status3 = lead?.progress?.some(el => el?.status === 'lead_level_3')
    const status4 = lead?.progress?.some(el => el?.status === 'lead_level_4')
    const status5 = lead?.progress?.some(el => el?.status === 'lead_level_5')
    const status6 = lead?.progress?.some(el => el?.status === 'lead_level_6')

    const getActiveValue = () => {
        if (status6) return '6'
        if (status5) return '6'
        if (status4) return '5'
        if (status3) return '4'
        if (status2) return '3'
        if (status1) return '2'
        if (contractSigned) return '1'
    }

    const dispatchUpdate = (data, buyerFile, contractFile) => {
        if (data?.s1) {
            dispatch1({ type: 'UPDATE', payload: data?.s1 })
            dispatch({ type: 'AGREEMENT', payload: lead?.isContractSigned })
        }
       if(data?.s2){
            dispatch2({ type: 'UPDATE', payload: data?.s2 })
            dispatch2({type: 'FILES', payload: {
                    contractSignedFile: contractFile,
                    buyerBrokerAgreement: buyerFile
            }})
        } 
        data?.s3 && dispatch3({ type: 'UPDATE', payload: data?.s3 })
        data?.s4 && dispatch4({ type: 'UPDATE', payload: data?.s4 })
        data?.s6 && dispatch6({ type: 'UPDATE', payload: data?.s6 })
    }

    const updateFormData = async () => {
        try {
            const { data: res } = await leadUrl.get(`/lead-captures/${lead?.id}`)
            if (res?.status === 'success') {
                dispatchUpdate(res?.data?.formStructure, res?.data?.buyerBrokerAgreement, res?.data?.contractSignedFile)
            }
        } catch (error) {
            toast.error('Update failed. Could not get Lead data')
        }
    }

    useEffect(() => {
        updateFormData()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (lead?.isContractSigned) {
            const state = {
                contract: true,
                status_1: Number(getActiveValue()) >= 1 ? false : true,
                status_2: Number(getActiveValue()) >= 2 ? false : true,
                status_3: Number(getActiveValue()) >= 3 ? false : true,
                status_4: Number(getActiveValue()) >= 4 ? false : true,
                status_5: Number(getActiveValue()) >= 5 ? false : true,
                status_6: Number(getActiveValue()) >= 6 ? false : true
            }
            setActive({ ...state, value: getActiveValue() })
        }
        // eslint-disable-next-line
    }, [lead?.isContractSigned])

    const saveContractForm = async () => {
        const agentPayload = {
            firstName: data?.agent?.first_name,
            lastName: data?.agent?.last_name,
            secondaryPhoneNumber: data?.agent?.phone_2,
            secondaryEmail: data?.agent?.email_2,
            licenseNumber: data?.agent?.license,
            isFirstTime: false
        }
        // if agent license is changed, update the agent info with broker details
        const payload = data?.changedLicense === 'yes' ? { ...agentPayload, brokerData: data?.broker } : { brokerData: data?.broker }
        try {
            setLoad(true)
            await leadUrl.patch(`/lead-distribution/lead-agents/${agent?.id}`, payload)
            toast.success('Success. Form saved', { theme: 'colored' })
        } catch (error) {
            toast.error('Sorry, could save contract. Please try again', { theme: 'colored' })
        } finally {
            setLoad(false)
        }
    }

    const sendPayload = async (body, stage, level, leadId) => {
        let payload = {
            s1: input1,
            s2: input2,
            s3: input3,
            s4: input4,
            s6: input6
        }
        try {
            setLoad(true)
            await leadUrl.post(`/lead-captures/lead-progress/`, { status: level, lead: leadId, date: new Date(Date.now()) })
            const { data: res } = await leadUrl.patch(`/lead-captures/${leadId}`, { formStructure: payload })
            if (res?.status === 'success') {
                stage === 's1' && setActive(prev => ({ ...prev, value: '2', status_2: false }))
                if (stage === 's3' && input3?.contingency === 'contract pending non contingent') {
                    setActive(prev => ({ ...prev, value: '6', status_6: false }))
                } if (stage === 's3' && input3?.contingency === 'contract pending & contingent') {
                    setActive(prev => ({ ...prev, value: '4', status_4: false }))
                }
                stage === 's4' && setActive(prev => ({ ...prev, value: '5', status_5: false }))
                stage === 's5' && setActive(prev => ({ ...prev, value: '6', status_6: false }))
                dispatchUpdate(res?.data?.formStructure)
                toast.success('Success. Form saved', { theme: 'colored' })
                stage === 's6' && setTimeout(() => {
                    window.location?.reload()
                }, 1000);
            }
        } catch (error) {
            toast.error('Sorry, could not save form. Please try again', { theme: 'colored' })
        } finally {
            setLoad(false)
        }
    }

    const saveAndExit = async (body, stage, leadId) => {
        let payload = {}
        Object.defineProperty(payload, stage, {
            value: body,
            enumerable: true,
            writable: true
        })
        try {
            setLoad(true)
            const { data: res } = await leadUrl.patch(`/lead-captures/${leadId}`, { formStructure: payload })
            dispatchUpdate(res?.data)
            toast.success('Success. Form saved', { theme: 'colored' })
            setTimeout(() => {
                window.location.reload()
            }, 2000);
        } catch (error) {
            toast.error('Sorry, could not save form. Please try again', { theme: 'colored' })
        } finally {
            setLoad(false)
        }
    }

    const onFormSave = async (val, files) => {
        if (val === 'contract') {
            await saveContractForm()
            try {
                // save pdf version of referral fee contract 
                await leadUrl.post(`/lead-captures/lead-progress/`, { status: 'signed_contract', lead: lead?.id, date: new Date(Date.now()) })
                const { data: res } = await leadUrl.patch(`/lead-captures/${lead?.id}`, { isContractSigned: true })
                if (res?.status === 'success') {
                    setActive(prev => ({ ...prev, value: '1', status_1: false }))
                    toast.success('Form saved successfully', { theme: 'colored' })
                }
            } catch (error) {
                setLoad(false)
                toast.error('Sorry, could not sign contract. Please try again', { theme: 'colored' })
            }
        }
        if (val === 'stage-one') await sendPayload(input1, 's1', 'lead_level_1', lead?.id)
        if (val === 'stage-two') {
            await sendPayload(input2, 's2', 'lead_level_2', lead?.id)
            // save pdfs of listing agreements
            if (files?.contractSignedFile || files?.buyerBrokerAgreement) {
                await leadUrl.patch(`/lead-captures/${lead?.id}`, files, {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                })
            }
            setActive(prev => ({ ...prev, value: '3', status_3: false }))
            toast.success('Documents saved successfully', { theme: 'colored' })
        }
        if (val === 'stage-three') await sendPayload(input3, 's3', 'lead_level_3', lead?.id)
        if (val === 'stage-four') await sendPayload(input4, 's4', 'lead_level_4', lead?.id)
        if (val === 'stage-five') await sendPayload(input4, 's5', 'lead_level_5', lead?.id)
        if (val === 'stage-six') await sendPayload(input6, 's6', 'lead_level_6', lead?.id)

    }

    const exitForm = async (val, files) => {
        if (val === 'contract') {
            await saveContractForm()
            setTimeout(() => {
                window.location.reload()
            }, 2000);
        }
        if (val === 'stage-one') await saveAndExit(input1, 's1', lead?.id)
        if (val === 'stage-two') {
            // save pdfs of listing agreements
            if (files?.contractSignedFile || files?.buyerBrokerAgreement) {
                const { data: res} = await leadUrl.patch(`/lead-captures/${lead?.id}`, files, {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                })
                dispatchUpdate(res?.data)
            }
            toast.success('Success. Form saved', { theme: 'colored' })
            setTimeout(() => {
                window.location.reload()
            }, 2000);
        }
        if (val === 'stage-three') await saveAndExit(input3, 's2', lead?.id)
        if (val === 'stage-four') await saveAndExit(input4, 's4', lead?.id)
        if (val === 'stage-five') await saveAndExit(input4, 's5', lead?.id)
        if (val === 'stage-six') await saveAndExit(input6, 's6', lead?.id)
    }



    return (
        <Box>
            {/* Title */}
            <Stack direction={'row'} gap={2} alignItems={'center'}>
                <IconButton onClick={() => setLeadState({ confirm: false, lead: null })}><ArrowBack fontSize='small' /></IconButton>
                <span>
                    <Typography fontSize={'1.4rem'}>{lead?.uniqueId}</Typography>
                    <Typography variant='body2' color={'GrayText'} ml={'auto'} fontSize={'.9rem'}>Lead Type: {lead?.leadTypeName}</Typography>
                </span>
                <Button disabled={!data?.isAgreementSigned} disableElevation size='small' variant='contained' color='secondary' sx={{ ml: 'auto', textTransform: 'none' }}
                    startIcon={data?.isAgreementSigned ? <Description fontSize='small' /> : <Lock fontSize='small' />}>Lead Details</Button>
            </Stack>
            <Divider light sx={{ my: 3, bgcolor: grey[300] }} />
            <TabContext value={active?.value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 3 }}>
                    <TabList onChange={handleChange}>
                        <Tab sx={{ textTransform: 'none', bgcolor: 'transparent !important' }} disabled={active?.contract} label="Contract Signing" value="0" />
                        <Tab sx={{ textTransform: 'none', bgcolor: 'transparent !important' }} disabled={active?.status_1} label="Status 1" value="1" />
                        <Tab sx={{ textTransform: 'none', bgcolor: 'transparent !important' }} disabled={active?.status_2} label="Status 2" value="2" />
                        <Tab sx={{ textTransform: 'none', bgcolor: 'transparent !important' }} disabled={active?.status_3} label="Status 3" value="3" />
                        <Tab sx={{ textTransform: 'none', bgcolor: 'transparent !important' }} disabled={active?.status_4} label="Status 4" value="4" />
                        <Tab sx={{ textTransform: 'none', bgcolor: 'transparent !important' }} disabled={active?.status_5} label="Status 5" value="5" />
                        <Tab sx={{ textTransform: 'none', bgcolor: 'transparent !important' }} disabled={active?.status_6} label="Status 6" value="6" />
                    </TabList>
                </Box>
                <Grid container spacing={3}>
                    <Grid item sm={9}>
                        <TabPanel value="0">
                            <ContractSign load={load} dispatch={dispatch} data={data} exitForm={exitForm} saveForm={(val) => onFormSave(val)} />
                        </TabPanel>
                        <TabPanel value="1">
                            <StageOne load={load} data={input1} dispatch={dispatch1} exit={exitForm} saveForm={(val) => onFormSave(val)} />
                        </TabPanel>
                        <TabPanel value="2">
                            <StageTwo load={load} data={input2} dispatch={dispatch2} exit={(val, files) => exitForm(val, files)} saveForm={(val, files) => onFormSave(val, files)} />
                        </TabPanel>
                        <TabPanel value="3">
                            <StageThree load={load} data={input3} dispatch={dispatch3} exit={exitForm} saveForm={(val) => onFormSave(val)} />
                        </TabPanel>
                        <TabPanel value="4">
                            <StageFour load={load} data={input4} dispatch={dispatch4} exit={exitForm} saveForm={(val) => onFormSave(val)} contingency={input3?.contingency} />
                        </TabPanel>
                        <TabPanel value="5">
                            <StageFive load={load} data={input4} dispatch={dispatch4} exit={exitForm} saveForm={(val) => onFormSave(val)} />
                        </TabPanel>
                        <TabPanel value="6">
                            <StageSix load={load} data={input6} dispatch={dispatch6} exit={exitForm} saveForm={(val) => onFormSave(val)} />
                        </TabPanel>
                    </Grid>

                    {/* Statuses */}
                    <Grid item sm={3}>
                        <Box p={3} bgcolor={'#b3e5fc1f'} sx={{ border: '1px solid lightgrey', borderRadius: '10px', position: 'sticky', top: 0 }}>
                            <ContractStatus progress={lead?.progress} />
                        </Box>
                    </Grid>
                </Grid>
            </TabContext>
        </Box>
    )
}

export default ContractForm