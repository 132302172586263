import React, { useState } from 'react';
import './allocatedterritories.scss';
import Modal from '../../components/Modal/Modal';
import { ReactComponent as CloseIcon } from '../../images/Close.svg';
import { AiFillHome } from 'react-icons/ai';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import base from '../../apis';
import { toast } from 'react-toastify';
import Spinner from '../../components/Spinner/Spinner';
import Map from '../../components/Map/Map';
import { Polygon } from '@react-google-maps/api';
import CheckIcon from '@mui/icons-material/Check';
import { Box, Button, Grid, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
// import { useNavigate } from 'react-router-dom';
import { getPosition, getTerritoriesOutline, getTimelines } from '../../utils/functions';
import { farmPackages, mapPolygonStyles } from '../../constants';

const headers = ['Address', 'County', 'City', 'Zip', 'Owner 1', 'Owner 2'];

const getDoorDate = (createdDate) => {
	const today = new Date();
	const dateC = new Date(createdDate);

	let outDate;
	const diffInDays = Math.abs(Math.floor((createdDate - today) / (1000 * 60 * 60 * 24)));

	// if (today-)
	if (diffInDays <= 30) {
		// return createdDate +30
		outDate = dateC.setDate(dateC.getDate() + 30);
	}
	const dayss = diffInDays - 30;
	const multiplier = Math.ceil(dayss / 90);
	outDate = dateC.setDate(dateC.getDate() + multiplier * 90 + 30);

	return `Date for next door knocking- ${new Date(outDate).toLocaleDateString('en-US', { month: 'short', day: 'numeric' })}`;
};

const AllocatedTerritories = () => {
	const [terModal, setTerModal] = useState(false);
	const agentId = useSelector((state) => state.auth.typeId);
	const [territories, setTerritories] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [selectedTerritory, setSelectedTerritory] = useState(null);
	const [menuOption, setMenuOption] = useState('first');

	// const navigate = useNavigate();

	useEffect(() => {
		if (!agentId) return;
		setIsLoading(true);
		base
			.get('/farm/territories/', {
				params: {
					agent_id: agentId,
				},
			})
			.then(({ data }) => {
				if (!data?.length) {
					toast.warn('No territories allocated');
					return;
				}
				setTerritories(data);
			})
			.catch((err) => {
				toast.error('Could not get farms');
			})
			.finally(() => {
				setIsLoading(false);
			});
	}, [agentId]);

	const openMap = (territory) => {
		const { subscription_item } = territory;
		const body = {
			selectedPrice: subscription_item?.price_id,
			farmType: subscription_item?.farm_type,
			subscriptionItemId: subscription_item?.subscription_item_id,
			interval: subscription_item?.parent_subscription?.interval,
			grade: subscription_item?.package_grade,
			updated_date: subscription_item?.parent_subscription?.current_period_start,
			created_date: subscription_item?.parent_subscription?.date_created,
		};
		setIsLoading(true);
		base
			.get(`/farm/territories/${territory.id}`)
			.then(({ data }) => {
				setSelectedTerritory({ ...data, ...body, outline: getTerritoriesOutline(data) });
				setTerModal(true);
			})
			.catch((err) => {
				toast.error('could not load farm details');
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	// const getFarmPrices = () => {
	// 	setIsLoading(true);
	// 	base
	// 		.get('/register/farm_prices/', {
	// 			params: {
	// 				city__iexact: selectedTerritory.city,
	// 			},
	// 		})
	// 		.then(({ data }) => {
	// 			const state = {
	// 				id: selectedTerritory.id,
	// 				name: selectedTerritory?.name,
	// 				city: selectedTerritory?.city,
	// 				county: selectedTerritory?.county,
	// 				isMain: selectedTerritory?.farmType === 'main',
	// 				interval: selectedTerritory?.interval,
	// 				state: selectedTerritory?.state,
	// 				selectedPrice: selectedTerritory?.selectedPrice,
	// 				subscriptionItemId: selectedTerritory?.subscriptionItemId,
	// 				price_data: data[0].price_data,
	// 			};
	// 			navigate('/update-farm', { state });
	// 			setIsLoading(false);
	// 		})
	// 		.catch((err) => {
	// 			setIsLoading(false);
	// 			toast.error('Could not get farm prices');
	// 		});
	// };

	const handleDownload = async () => {
		setIsLoading(true);
		try {
			const { data } = await base.get('/farm/exportcsv/', {
				params: {
					territory_id: selectedTerritory.id,
				},
			});

			const text = `${headers.join(',')}\n${data
				.map(
					(item) => `"${item.site_addr}",${item.site_county},${item.site_city},${item.site_zip},"${item.owner_name_1}","${item.owner_name_2 || ''}"`
				)
				.join('\n')}`;
			const blob = new Blob([text], { type: 'text/csv' });
			const url = window.URL.createObjectURL(blob);
			const link = document.createElement('a');
			link.href = url;
			link.download = `${selectedTerritory?.name}_data_${new Date().toLocaleDateString()}.csv`;
			link.click();
		} catch (error) {
			toast.error('Could not get farm details');
		} finally {
			setIsLoading(false);
		}
		// const handleDownload = () => {
		// 	if (elements.length === 0) {
		// 		toast.warn('No data to download');
		// 		return;
		// 	}

		// 	const text = `${headers[activeTab].join(',')}\n${elements
		// 		.map((item) => {
		// 			return `${item.name},${item.signup},${item.active_payments},${item.total}`;
		// 		})
		// 		.join('\n')}`;
		// const blob = new Blob([text], { type: 'text/csv' });
		// const url = window.URL.createObjectURL(blob);
		// const link = document.createElement('a');
		// link.href = url;
		// link.download = `${activeTab}_compensation_report_${new Date().toLocaleDateString()}.csv`;
		// link.click();
		// };
	};

	return (
		<>
			{isLoading && <Spinner />}
			<Modal open={terModal} onClose={() => setTerModal(false)} style={{ minWidth: '70vw' }}>
				<div className='termod'>
					<div>
						<Map
							center={{
								lng: selectedTerritory?.parcels?.features.map((item) => item.geometry.coordinates[0][0][0][0])[0],
								lat: selectedTerritory?.parcels?.features.map((item) => item.geometry.coordinates[0][0][0][1])[1],
							}}
						>
							{selectedTerritory?.outline && (
								<Polygon paths={selectedTerritory?.outline.map((el) => ({ lng: el[0], lat: el[1] }))} options={mapPolygonStyles} />
							)}
						</Map>
					</div>
					<div>
						<CloseIcon
							onClick={() => setTerModal(false)}
							style={{
								marginLeft: 'auto',
								display: 'block',
								cursor: 'pointer',
							}}
						/>
						<h2>{selectedTerritory?.name}</h2>
						<p>
							{selectedTerritory?.state} &nbsp; <span className='gr'>&gt;</span> &nbsp; {selectedTerritory?.county} &nbsp;{' '}
							<span className='gr'>&gt;</span> &nbsp; {selectedTerritory?.city}
						</p>
						<div className='terhouse'>
							<div>
								<div className='tericontainer'>
									<AiFillHome size={40} className='tericon' />
								</div>
								<span>Home Owners</span>
							</div>
							<div>
								<span style={{ width: '180px' }}>Number of home owners in this territory</span>
								<p style={{ fontWeight: '700' }}>300</p>
							</div>
						</div>
						<p className='terside'>BPO Farm By BPO Homes</p>
						<ToggleButtonGroup size='small' color='secondary' value={menuOption} exclusive onChange={(e, value) => setMenuOption(value)}>
							<ToggleButton value='first'>Addons</ToggleButton>
							<ToggleButton value='second'>Timelines </ToggleButton>
							<ToggleButton value='third'>Subscription</ToggleButton>
							<ToggleButton value='four'>Farm Data</ToggleButton>
						</ToggleButtonGroup>
						<div style={{ height: '200px', overflowY: 'scroll' }}>
							{menuOption === 'first' && (
								<>
									<p style={{ fontWeight: 'bold' }}>{farmPackages[getPosition(selectedTerritory?.grade)]?.title}</p>
									{farmPackages[getPosition(selectedTerritory?.grade)]?.benefits.map((item, index) => (
										<Box key={item} display='flex' gap='10px' alignItems='center'>
											<CheckIcon color='primary' fontSize='14px' />
											<p style={{ fontWeight: '300', margin: 0 }} dangerouslySetInnerHTML={{ __html: item }}></p>
										</Box>
									))}
								</>
							)}
							{menuOption === 'second' && (
								<>
									{selectedTerritory &&
										selectedTerritory?.subscriptionItemId &&
										getTimelines(selectedTerritory?.grade, selectedTerritory?.created_date, selectedTerritory?.updated_date).map((item, index) => (
											<Box key={item} display='flex' gap='10px' alignItems='center' padding='10px'>
												<CheckIcon color='primary' fontSize='14px' />
												<p style={{ fontWeight: '300', margin: 0 }}>{item}</p>
											</Box>
										))}
									{selectedTerritory?.subscriptionItemId && (
										<Box display='flex' gap='10px' alignItems='center' padding='10px'>
											<CheckIcon color='primary' fontSize='14px' />
											<p style={{ fontWeight: '300', margin: 0 }}>{getDoorDate(new Date(selectedTerritory?.created_date))}</p>
										</Box>
									)}
								</>
							)}
							{menuOption === 'third' && (
								<>
									<p style={{ fontWeight: 'bold' }}>Your Subscription</p>
									{farmPackages.map((item, index) => (
										<Box key={item.title} display='flex' gap='10px' alignItems='center'>
											<CheckIcon color={getPosition(selectedTerritory?.grade) >= index ? 'primary' : 'grey'} fontSize='14px' />
											<p style={{ fontWeight: '400', margin: 0, color: getPosition(selectedTerritory?.grade) >= index ? 'black' : 'grey' }}>
												{item.title}
											</p>
										</Box>
									))}
									{/* <Button
										color='secondary'
										variant='contained'
										sx={{ fontSize: '16px', display: 'block', marginLeft: 'auto', marginTop: '10px' }}
										onClick={getFarmPrices}
									>
										Change Subscription
									</Button> */}
								</>
							)}
							{menuOption === 'four' && (
								<>
									<Typography variant='body2' fontSize='14px ' mt={1} sx={{ color: 'grey' }}>
										Please click to download your Farm Records of 300 Homes in a csv file format.
									</Typography>
									<Button variant='contained' sx={{ textTransform: 'capitalize', marginTop: '2rem' }} color='secondary' onClick={handleDownload}>
										Download
									</Button>
									<Typography variant='body2' fontSize='14px ' mt={1} sx={{ color: 'grey' }}>
										<b>Disclaimer:&nbsp;</b>Data is updated every 3 months
									</Typography>
								</>
							)}
						</div>
					</div>
				</div>
			</Modal>
			<div className='alloc_territories'>
				<div className='main_content'>
					<div className='territories'>
						<p className='title'>My Territories</p>
						<Grid container spacing={2}>
							{territories.map((territory, index) => (
								<Grid item lg={4} key={territory.id}>
									<TerriCard {...territory} onClick={() => openMap(territory)} selected={selectedTerritory?.id === territory.id} />
								</Grid>
							))}
						</Grid>
						<div className='cards_wrapper'></div>
					</div>
				</div>
			</div>
		</>
	);
};

export default AllocatedTerritories;

const TerriCard = ({ onClick, name, state, county, city, selected }) => {
	return (
		<div className='terri_card' onClick={onClick} style={selected ? { border: '1px solid var(--primary-color)' } : {}}>
			<p>{name}</p>
			<p>
				{state}, {county}, {city}
			</p>
		</div>
	);
};
