import React from "react";
import {
  TableContainer,
  Table,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
  Box,
  Typography,

} from "@mui/material";
import { useTheme } from "@mui/material/styles";
const useStyles = (theme) => {
  return {
    table: {
      width: "100%",
      // border: `solid 1px ${theme.palette.primary.main}`,
      fontSize: "1.5625rem",
      tableLayout: "fixed",
    },
    cell0: {
      width: "12.5rem !important",
      border: `solid 1px ${theme.palette.primary.main}`,
    },
    cells: {
      width: "4rem !important",
      border: `solid 1px ${theme.palette.primary.main}`,
    },
    bodyCells: {
      border: `solid 1px ${theme.palette.primary.main}`,
    },
    rows: {
      "&.MuiTableRow-root": `solid 1px ${theme.palette.primary.main}`

    }
  };
}


function CompTable({ headers, owners }) {

  const theme = useTheme()
  const styles = useStyles(theme)

  return (
    <Box>
      <TableContainer
        component={"paper"}
        sx={{ maxWidth: "content", padding: 1 }}
      >
        <Table sx={styles.table} aria-label="simple table" size="medium">
          <TableHead>
            <TableRow sx={styles.rows}>
              {headers.map((header, index) => (
                <TableCell
                  align="left"
                  scope="row"
                  sx={index === 0 ? styles.cell0 : styles.cells}
                >
                  <Typography variant="tableHeader" sx={{ width: "6.25rem" }}>
                    {header}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {owners &&
              owners?.map((owner, index) => (
                <TableRow key={owner?.owner + index} sx={styles.rows}>
                  <TableCell component="th" scope="row" sx={styles.bodyCells}>
                    {owner?.address ? (
                      <Typography variant="h4">
                        {owner?.address.split(',')[0] + ',' + owner?.address.split(',')[1]}
                      </Typography>
                    ) : (
                      <Typography variant="tableInactive">
                        Not Available
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell sx={styles.bodyCells}>
                    <Typography variant="h4">{owner?.beds}</Typography>{" "}
                  </TableCell>
                  <TableCell sx={styles.bodyCells}>
                    <Typography variant="h4">{owner?.bath}</Typography>{" "}
                  </TableCell>
                  <TableCell sx={styles.bodyCells}>
                    <Typography variant="h4">
                      {owner?.year_built || ''}
                    </Typography>{" "}
                  </TableCell>
                  <TableCell sx={styles.bodyCells}>
                    <Typography variant="h4">{owner?.sqft}</Typography>{" "}
                  </TableCell>
                  <TableCell sx={styles.bodyCells}>
                    <Typography variant="h4">{owner?.lot_size}</Typography>{" "}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default CompTable;
