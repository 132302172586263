import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField, Typography } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import Flag05 from '@untitled-ui/icons-react/build/esm/Flag05';
import dayjs from 'dayjs';
import React from 'react';
import DateLocalizer from '../components/DateLocalizer';

const FollowUpDialog = ({
	isOpen,
	setOpen,
	date,
	onDateChange,
	onSubmit,
	message,
	onMessageChange,
	isLoading,
	onCancel,
	cancelButtonText = 'Skip',
	actionButtonText = 'Continue',
	title = 'Set Follow Up Date',
}) => {
	return (
		<Dialog
			maxWidth='sm'
			fullWidth
			open={isOpen}
			onClose={() => setOpen(false)}
			PaperProps={{
				sx: { borderRadius: '10px' },
			}}
		>
			<DialogTitle sx={{ borderBottom: '1px solid #EAECF0' }}>
				<Stack direction='row' alignItems={'center'} gap='0.5rem'>
					<Stack sx={{ p: '1rem', border: '1px solid #EAECF0', borderRadius: '10px' }}>
						<Flag05 />
					</Stack>
					<Typography fontWeight={600} fontSize={'18px'} color='#101828'>
						{title}
					</Typography>
				</Stack>
			</DialogTitle>
			<DialogContent sx={{ pb: '0' }}>
				<Stack gap='1rem' mt='1rem'>
					<Typography>Date & Time</Typography>
					<DateLocalizer>
						<DateTimePicker value={date} onChange={onDateChange} minDate={dayjs()} label='Date & Time' />
					</DateLocalizer>
					<Typography>Follow Up Message</Typography>
					<TextField multiline rows={4} placholder='Follow up message' color='secondary' value={message} onChange={onMessageChange} />
				</Stack>
				<Stack direction='row' gap='1rem' mt='2rem'>
					<Button
						variant='outlined'
						color='secondary'
						onClick={() => {
							if (onCancel) onCancel();
							setOpen(false);
						}}
						sx={{ flex: 1 }}
					>
						{cancelButtonText}
					</Button>
					<LoadingButton
						variant='contained'
						color='secondary'
						sx={{ textTransform: 'capitalize', flex: 1 }}
						type='submit'
						onClick={onSubmit}
						loading={isLoading}
					>
						{actionButtonText}
					</LoadingButton>
				</Stack>
			</DialogContent>
			<DialogActions></DialogActions>
		</Dialog>
	);
};

export default FollowUpDialog;
