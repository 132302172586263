import { KeyboardDoubleArrowDown } from '@mui/icons-material';
import { Box, Link as DLink, Divider, Grid, IconButton, Button as MButton, Stack, TextField, Typography, createTheme } from '@mui/material';
import { ThemeProvider, makeStyles, styled } from '@mui/styles';
import React from 'react';
import { Link as RLink } from 'react-router-dom';
import { ReactComponent as FBIcon } from '../../images/facebookfillable.svg';
import { ReactComponent as IGIcon } from '../../images/instagramfillable.svg';
import { ReactComponent as LIIcon } from '../../images/linkedInfillable.svg';
import { ReactComponent as TWIcon } from '../../images/twitterfillable.svg';
import { ReactComponent as YTIcon } from '../../images/youtubefillable.svg';
import BG from '../../images/temp6bg.webp';

import { formatNumber, formatPhoneNumber, getYoutubeEmbedLink, hideButton, scrollToTheLeft } from '../../utils/functions';
import ServiceAreasSlider from '../../components/ServiceAreasSlider/ServiceAreasSlider';
import InvestorQuestionnaires from '../../components/InvestorQuestionnaires/InvestorQuestionnaires';

const Link = styled(DLink)(({ theme }) => ({
	cursor: 'pointer',
}));

const Button = styled(MButton)(({ theme }) => ({
	fontFamily: 'Poppins, sans-serif',
	fontSize: '16px',
	borderRadius: '0',
}));
const WhiteIconButton = styled(IconButton)(({ theme }) => ({
	border: '1px solid white',
	borderRadius: '50%',
	width: '40px',
	aspectRatio: '1',
}));
const BlackIconButton = styled(IconButton)(({ theme }) => ({
	border: '1px solid black',
	borderRadius: '50%',
	width: '40px',
	aspectRatio: '1',
}));

const pageTheme = createTheme({
	typography: {
		fontFamily: 'Poppins, sans-serif',
		fontSize: 16,
	},
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					borderRadius: 0,
				},
			},
			defaultProps: {
				disableRipple: true,
			},
		},
		MuiIconButton: {
			defaultProps: {
				disableRipple: true,
			},
		},
	},
	palette: {
		primary: {
			main: '#fff',
		},
		secondary: {
			main: '#000',
		},
		success: {
			main: '#fff',
		},
		text: {
			main: '#000',
		},
		paper: {
			main: '#fff',
		},
	},
	primaryColor: '#fff',
	secondaryColor: '#000',
});

const useStyles = makeStyles((theme) => ({
	root: {},
	full: {
		height: '100vh',
		background: `linear-gradient(0deg, rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35)), url(${BG})`,
		backgroundSize: 'cover',
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'flex-end',
		position: 'relative',
	},
	whiteBackButton: {
		color: '#000',
		backgroundColor: '#fff',
		border: 'none',
		'&:hover': {
			color: '#000',
			backgroundColor: '#fff',
			border: 'none',
		},
	},
	transBackButton: {
		color: '#fff',
		backgroundColor: 'transparent',
		border: '1px solid currentColor',
		'&:hover': {
			color: '#fff',
			backgroundColor: 'transparent',
		},
	},
	transBlackButton: {
		color: '#000',
		backgroundColor: 'transparent',
		border: '1px solid currentColor',
		'&:hover': {
			color: '#000',
			backgroundColor: 'transparent',
		},
	},
	header: {
		position: 'fixed',
		top: '0',
		width: '100%',
		padding: '1.5rem',
		display: 'flex',
		justifyContent: 'space-between',
		background: 'rgba(0, 0, 0, 0.1)',
		zIndex: '100',
	},
	black: {
		backgroundColor: 'black',
		color: 'white',
		marginTop: '2rem',
	},
}));

function NewTemp6({ agent, listings, testimonials, contactDetails, updateContactDetails, sendMessage, serviceAreas }) {
	const classes = useStyles();
	const name = agent?.user.first_name + ' ' + agent?.user.last_name;
	const agent_id = agent?.id;
	const member_since = agent?.year_work_commenced;
	const agent_phone = formatPhoneNumber(agent?.phone);
	const brokerage_phone = formatPhoneNumber(agent?.brokerage?.brokerage_phone);
	const brokerage_name = agent?.brokerage_name;
	const license = agent?.licenses.find((license) => license.category === 'real_estate_agent')?.number;
	const address = agent?.address;
	const video = agent?.video_link;
	const showInvestorForm = agent?.questionnaires_on;
	return (
		<ThemeProvider theme={pageTheme}>
			<Box className={classes.root} id='home'>
				<Box className={classes.full}>
					<Box className={classes.header}>
						<Typography
							variant='body1'
							fontFamily='Poppins, sans-serif'
							color='white'
							fontWeight='400'
							fontSize='16px'
							sx={{ textTransform: 'uppercase' }}
						>
							Listing Agent: {name}
						</Typography>

						<Box display='flex' gap='1rem' fontFamily='Poppins, sans-serif'>
							<Link href='#home' underline='none' color='white' fontFamily='inherit' sx={{ textTransform: 'uppercase' }}>
								Home
							</Link>
							<Link href='#about' underline='none' color='white' fontFamily='inherit' sx={{ textTransform: 'uppercase' }}>
								About
							</Link>
							{listings.active.length > 0 && (
								<Link href='#active' underline='none' color='white' fontFamily='inherit' sx={{ textTransform: 'uppercase' }}>
									Active Listings
								</Link>
							)}
							<Link href='#contact' underline='none' color='white' fontFamily='inherit' sx={{ textTransform: 'uppercase' }}>
								Contact
							</Link>
							<Link href={`/agent/${agent?.id}/blogs`} underline='none' color='white' fontFamily='inherit' sx={{ textTransform: 'uppercase' }}>
								Blog
							</Link>
							{showInvestorForm && (
								<Link href='#investors' underline='none' color='white' fontFamily='inherit' sx={{ textTransform: 'uppercase' }}>
									Investors
								</Link>
							)}
						</Box>
					</Box>
					<Stack mb={'30vh'} alignItems='center'>
						{agent?.landing_page_title && (
							<Typography color='white' fontWeight={700} textTransform={'uppercase'}>
								{agent?.landing_page_title}
							</Typography>
						)}
						<Typography variant='h1' fontFamily='Tenor Sans, sans-serif' color='white' fontSize='70px' mb='10rem'>
							{name}
						</Typography>
						<Stack direction='row' spacing={2} mb='1'>
							<Button variant='outlined' color='secondary' className={classes.whiteBackButton} href={`/order-bpo-report/${agent_id}`} target='_blank'>
								Order a BPO Report
							</Button>
							<Button variant='filled' color='primary' className={classes.transBackButton} href='#contact'>
								Let's Connect
							</Button>
						</Stack>
					</Stack>
				</Box>
				{listings?.active.length > 0 && (
					<Box p={'2rem 4rem'}>
						<Stack direction='row' justifyContent='space-between'>
							<Typography variant='h4' fontFamily='Tenor Sans, sans-serif' textTransform='uppercase'>
								Active Listings
							</Typography>
							<Stack
								direction='row'
								spacing={2}
								ref={(el) => hideButton(el, 'active')}
								divider={<Divider orientation='vertical' flexItem sx={{ width: '1px', backgroundColor: 'black' }} />}
							>
								<Button
									variant='text'
									sx={{ fontSize: '14px !important', color: 'black' }}
									onClick={() => {
										scrollToTheLeft('active', true);
									}}
								>
									Previous
								</Button>
								<Button
									variant='text'
									sx={{ fontSize: '14px !important', color: 'black' }}
									onClick={() => {
										scrollToTheLeft('active');
									}}
								>
									Next
								</Button>
							</Stack>
						</Stack>
						<Box
							display='flex'
							gap='20px'
							id='active'
							mt={2}
							sx={{
								maxWidth: '100%',
								overflowX: 'scroll',
								// position: 'relative',
								'&::-webkit-scrollbar': {
									display: 'none',
								},
								scrollBehavior: 'smooth',
							}}
						>
							{listings?.active.map((listing) => (
								<Card key={listing.id} {...listing} agentId={agent_id} />
							))}
						</Box>
					</Box>
				)}
				<Grid container spacing={2} p={'2rem 4rem'} className={classes.black} alignItems='center' justifyContent='center' id='about'>
					<Grid item xs={6} alignItems='center' justifyContent='center'>
						<img
							src={agent?.profile_image}
							alt='Agent'
							style={{
								width: '400px',
								aspectRatio: '3/4',
								objectFit: 'cover',
								objectPosition: 'top center',
								display: 'block',
								margin: 'auto',
							}}
						/>
					</Grid>
					<Grid item xs={6} justifyContent='center' display='flex' flexDirection='column' gap={2} alignItems='flex-start'>
						<Typography variant='h4' fontFamily='Tenor Sans, sans-serif' textTransform='uppercase'>
							Meet {name}
						</Typography>
						<Typography textAlign='justify' fontWeight={400} fontSize='16px' fontFamily='Poppins,sans-serif' whiteSpace={'pre-line'}>
							{agent?.bio}
						</Typography>
						<Button variant='filled' color='primary' className={classes.transBackButton} href='#contact'>
							Let's Connect
						</Button>
						<Stack direction='row' spacing={3}>
							{agent?.facebook && (
								<WhiteIconButton target='blank' href={agent?.facebook}>
									<FBIcon fill='white' />
								</WhiteIconButton>
							)}
							{agent?.twitter && (
								<WhiteIconButton target='blank' href={agent?.twitter}>
									<TWIcon fill='white' />
								</WhiteIconButton>
							)}
							{agent?.instagram && (
								<WhiteIconButton target='blank' href={agent?.instagram}>
									<IGIcon fill='white' />
								</WhiteIconButton>
							)}
							{agent?.linkedin && (
								<WhiteIconButton target='blank' href={agent?.linkedin}>
									<LIIcon fill='white' />
								</WhiteIconButton>
							)}
							{agent?.video_link && (
								<WhiteIconButton target='blank' href={agent?.video_link}>
									<YTIcon fill='white' />
								</WhiteIconButton>
							)}
						</Stack>
					</Grid>
				</Grid>

				<Grid container spacing={2} p={'2rem 4rem'} fontWeight={700} textAlign='center'>
					<Grid item xs={4}>
						{address}
					</Grid>
					<Grid item xs={4}>
						Cell Phone: {agent_phone}
					</Grid>
					<Grid item xs={4}>
						Member Since: {member_since}
					</Grid>
					<Grid item xs={4}>
						{agent?.user?.email}
					</Grid>
					<Grid item xs={4}>
						Brokerage: {brokerage_name} {brokerage_phone ? `(${brokerage_phone})` : ''}
					</Grid>
					<Grid item xs={4}>
						Real Estate License: {license}
					</Grid>
				</Grid>
				{showInvestorForm && (
					<Box padding={'2rem 4rem'}>
						<InvestorQuestionnaires agent={agent} />
					</Box>
				)}
				{video && (
					<Grid container spacing={2} p={'2rem 4rem'} sx={{ backgroundColor: 'white' }}>
						<Grid item xs={5} display='flex' flexDirection='column' gap={2} alignItems='flex-start' justifyContent='center'>
							<Typography variant='h4' fontFamily='Tenor Sans, sans-serif' textTransform='uppercase'>
								Video Spotlight
							</Typography>
							<Typography variant='body1' fontWeight={300}>
								{address}
							</Typography>
							<Stack direction='row' spacing={3}>
								{agent?.facebook && (
									<BlackIconButton target='blank' href={agent?.facebook}>
										<FBIcon fill='black' />
									</BlackIconButton>
								)}
								{agent?.twitter && (
									<BlackIconButton target='blank' href={agent?.twitter}>
										<TWIcon fill='black' />
									</BlackIconButton>
								)}
								{agent?.instagram && (
									<BlackIconButton target='blank' href={agent?.instagram}>
										<IGIcon fill='black' />
									</BlackIconButton>
								)}
								{agent?.linkedin && (
									<BlackIconButton target='blank' href={agent?.linkedin}>
										<LIIcon fill='black' />
									</BlackIconButton>
								)}
								{agent?.video_link && (
									<BlackIconButton target='blank' href={agent?.video_link}>
										<YTIcon fill='black' />
									</BlackIconButton>
								)}
							</Stack>
						</Grid>
						<Grid item xs={7}>
							<iframe
								style={{
									aspectRatio: '16/9',
									borderRadius: '0px',
									border: 'none',
									width: '100%',
									marginLeft: 'auto',
									display: 'block',
								}}
								src={`https://www.youtube.com/embed/${getYoutubeEmbedLink(video)}`}
								title='Client video'
								allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
								allowFullScreen
							></iframe>
						</Grid>
					</Grid>
				)}
				{listings?.sold.length > 0 && (
					<Box p={'2rem 4rem'} sx={{ backgroundColor: 'black', color: 'black' }}>
						<Stack direction='row' justifyContent='space-between'>
							<Typography variant='h4' fontFamily='Tenor Sans, sans-serif' textTransform='uppercase' color='white'>
								Sold Listings
							</Typography>
							<Stack
								direction='row'
								spacing={2}
								ref={(el) => hideButton(el, 'sold')}
								divider={<Divider orientation='vertical' flexItem sx={{ width: '1px', backgroundColor: 'white' }} />}
							>
								<Button
									variant='text'
									sx={{ fontSize: '14px !important', color: 'white' }}
									onClick={() => {
										scrollToTheLeft('sold', true);
									}}
								>
									Previous
								</Button>
								<Button
									variant='text'
									sx={{ fontSize: '14px !important', color: 'white' }}
									onClick={() => {
										scrollToTheLeft('sold');
									}}
								>
									Next
								</Button>
							</Stack>
						</Stack>
						<Box
							display='flex'
							gap='20px'
							id='sold'
							mt={2}
							sx={{
								maxWidth: '100%',
								overflowX: 'scroll',
								// position: 'relative',
								'&::-webkit-scrollbar': {
									display: 'none',
								},
								scrollBehavior: 'smooth',
							}}
						>
							{listings?.sold.map((sold) => (
								<Card key={sold.id} {...sold} sold={sold.sold_price && sold.date_sold} agentId={agent_id} />
							))}
						</Box>
					</Box>
				)}
				{testimonials.length > 0 && (
					<>
						<Stack spacing={2} p={'3rem 4rem'} alignItems='center'>
							<KeyboardDoubleArrowDown sx={{ fontSize: 100 }} />
							<Typography variant='h4' fontFamily='Tenor Sans, sans-serif' textTransform='uppercase'>
								MY SATISFIED CUSTOMER SAYS
							</Typography>
						</Stack>
						<Stack direction='row' spacing={2} p={'2rem 4rem'}>
							{testimonials.map((testimonial) => (
								<Review key={testimonial.id} {...testimonial} />
							))}
						</Stack>
					</>
				)}

				{serviceAreas.length > 0 && (
					<Box my={3} py={3} sx={{ backgroundColor: 'black', color: 'white' }}>
						<ServiceAreasSlider serviceAreas={serviceAreas} />
					</Box>
				)}

				<Grid container spacing={2} p={'2rem 4rem'} id='contact'>
					<Grid item xs={6} alignItems='center'>
						<Typography variant='h4' fontFamily='Tenor Sans, sans-serif' textTransform='uppercase' mb={3}>
							contact details
						</Typography>
						<Typography variant='h6' mb={2}>
							{name}
						</Typography>
						<Typography variant='body1' fontSize='14px'>
							{address}
						</Typography>
						<Typography variant='body1' mb={1} fontSize='14px'>
							{agent?.user?.email}
						</Typography>
						<Typography variant='body1' fontSize='14px'>
							Cell Phone:{agent_phone}
						</Typography>
						<Typography variant='body1' mb={1} fontSize='14px'>
							Broker Phone: {brokerage_phone}
						</Typography>
						<Typography variant='body1' fontSize='14px'>
							Member Since: {member_since}
						</Typography>
						<Typography variant='body1' mb={1} fontSize='14px'>
							Real Estate License: {license}
						</Typography>
					</Grid>
					<Grid item xs={6} alignItems='center' display='flex'>
						<Grid container spacing={3} sx={{ backgroundColor: 'white', padding: '2rem' }}>
							<Grid item xs={12}>
								<TextField
									fullWidth
									variant='standard'
									placeholder='Full Name'
									value={contactDetails.name}
									onChange={updateContactDetails}
									name='name'
								/>
							</Grid>
							<Grid item xs={6}>
								<TextField
									fullWidth
									variant='standard'
									placeholder='Email'
									value={contactDetails.email}
									onChange={updateContactDetails}
									name='email'
								/>
							</Grid>
							<Grid item xs={6}>
								<TextField
									fullWidth
									placeholder='Phone Number'
									variant='standard'
									value={contactDetails.phone}
									onChange={updateContactDetails}
									name='phone'
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									fullWidth
									multiline
									placeholder='Message'
									variant='standard'
									maxRows={5}
									value={contactDetails.message}
									onChange={updateContactDetails}
									name='message'
								/>
							</Grid>
							<Grid item xs={12}>
								<Button variant='filled' color='secondary' className={classes.transBlackButton} onClick={sendMessage}>
									Let's Connect
								</Button>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Box>
		</ThemeProvider>
	);
}

export default NewTemp6;

const Card = ({ id, agentId, list_price, photos, beds, baths, square_footage, address, date_sold, sold_price, sold }) => {
	return (
		<Link component={RLink} to={`/view-listing/${agentId}/${id}`} target='_blank'>
			<ThemeProvider theme={pageTheme}>
				<Box width='300px' position='relative' display='flex' flexDirection='column' gap='0'>
					<Typography
						variant='body1'
						position='absolute'
						top='0px'
						right='0px'
						width='fit-content'
						fontFamily='Poppins, sans-serif'
						sx={{ backgroundColor: sold ? 'white' : 'black', color: sold ? 'black' : 'white', fontWeight: 500, padding: '0.5rem 1rem' }}
					>
						{sold ? 'Sold' : 'For Sale'}
					</Typography>
					<img
						src={photos?.[0]?.image}
						alt='header'
						width='300px'
						style={{
							objectFit: 'cover',
							aspectRatio: '45/35',
						}}
					/>
					<Box width='100%' sx={{ backgroundColor: sold ? 'white' : 'black' }} color={sold ? 'black' : 'white'} p={2}>
						<Typography variant='body1' mb={'10px'} fontSize='14px' fontWeight='300'>
							{address}
						</Typography>
						<Stack
							spacing={1}
							mb={'10px'}
							direction='row'
							justifyContent='flex-start'
							divider={<Divider orientation='vertical' flexItem sx={{ width: '0.5px', backgroundColor: sold ? 'black' : 'white' }} />}
						>
							<Typography variant='body1' fontSize='14px' fontWeight='300'>
								{beds} Beds
							</Typography>
							<Typography variant='body1' fontSize='14px' fontWeight='300'>
								{baths} Baths
							</Typography>
							<Typography variant='body1' fontSize='14px' fontWeight='300'>
								{formatNumber(square_footage, 0)} SqFt
							</Typography>
						</Stack>
						<Typography variant='body1' fontFamily='Tenor Sans, sans-serif' fontSize='20px'>
							$ {formatNumber(list_price, 0)}
						</Typography>
					</Box>
				</Box>
			</ThemeProvider>
		</Link>
	);
};

const Review = ({ description, name, client }) => {
	return (
		<Stack spacing={2} alignItems='center' flex='1'>
			<Typography variant='body1' fontSize='100px' textTransform='uppercase' fontFamily='auto' color='#EDEDED' lineHeight={0}>
				&ldquo;
			</Typography>
			<Typography variant='body1' textAlign='center' fontStyle='italic'>
				{description}
			</Typography>
			<Typography variant='body1' textAlign='center' fontWeight='700' fontSize='18px' color='#3E4247' mt='auto !important'>
				- {client?.user?.first_name || client?.user?.last_name ? client?.user?.first_name + ' ' + client?.user?.last_name : name}
			</Typography>
		</Stack>
	);
};
