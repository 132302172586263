import {useStripe } from '@stripe/react-stripe-js';
import base from '../../../../apis';
import { toast } from 'react-toastify';
import { Box,Button,Typography } from '@mui/material';

const StripePay = ({acceptLegal,body}) => {
	const stripe = useStripe();



    const initiatePayment = async() =>{
        try{
           const res =  await base.post('/register/create_subscription/', {...body})
           const sessionId = res?.data?.sessionId
			stripe.redirectToCheckout({
					sessionId
				});		
        }catch(err){
            toast.error('Could not process payment');
        }finally{
          

        }

    }

	

	return (
    <Box sx={{ pb: 5 }}>
      <Button
        disabled={!acceptLegal}
        variant="contained"
        sx={{ padding: "0.937rem 17%" }}
        onClick={initiatePayment}
      >
        {" "}
        <Typography variant="buttonFont">Confirm Payment</Typography>
      </Button>
    </Box>
  );
};
export default StripePay