import { ChevronLeft, ChevronRight, Mail, Phone, PlaceOutlined, SquareFoot, Timer } from '@mui/icons-material';
import BedIcon from '@mui/icons-material/Bed';
import { Box, Button, Grid, IconButton, Link, Stack, TextField, Typography, createTheme } from '@mui/material';
import { ThemeProvider, makeStyles, styled } from '@mui/styles';
import { useEffect, useState } from 'react';
import { BiBath } from 'react-icons/bi';
import { Carousel } from 'react-responsive-carousel';
import { Link as RLink, useParams } from 'react-router-dom';
import { ReactComponent as LicenseIcon } from '../../images/license.svg';
import ConImage from '../../images/temp9con.webp';
import Slide1 from '../../images/temp9slide1.webp';
import { theme } from '../../theme';
import { formatNumber, formatPhoneNumber, getYoutubeEmbedLink, hideButton, scrollToTheLeft } from '../../utils/functions';
import ServiceAreasSlider from '../../components/ServiceAreasSlider/ServiceAreasSlider';
import InvestorQuestionnaires from '../../components/InvestorQuestionnaires/InvestorQuestionnaires';

const images = [{ img: Slide1, id: 1 }];

const SButton = styled(Button)(({ theme, color }) => ({
	fontSize: '16px',
	borderRadius: '2px',
	padding: '0.5rem 1rem',
	color: '#ff2424',
	border: '1px solid #ff2424',
	textTransform: 'none',
}));
const BButton = styled(Button)(({ theme, color }) => ({
	fontSize: '16px',
	borderRadius: '2px',
	padding: '0.5rem 1rem',
	color: 'white',
	backgroundColor: 'black',
	textTransform: 'none',
	'&:hover': {
		backgroundColor: 'black',
		color: 'white',
	},
}));

const pageTheme = createTheme({
	...theme,
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					borderRadius: '40px',
				},
			},
			defaultProps: {
				disableRipple: true,
				disableElevation: true,
			},
		},
		MuiIconButton: {
			defaultProps: {
				disableRipple: true,
			},
		},
	},
	palette: {
		primary: {
			main: '#FF2424',
		},
		secondary: {
			main: '#fff',
		},
		success: {
			main: '#FF2424',
		},
		text: {
			main: '#000',
		},
		paper: {
			main: '#FF2424',
		},
	},
	primaryColor: '#FF2424',
	secondaryColor: '#000',
});

const NewTemp9 = ({ agent, listings, testimonials, contactDetails, updateContactDetails, sendMessage, serviceAreas }) => {
	const name = agent?.user.first_name + ' ' + agent?.user.last_name;
	const agent_id = agent?.id;
	const member_since = agent?.year_work_commenced;
	const agent_phone = formatPhoneNumber(agent?.phone);
	const brokerage_phone = formatPhoneNumber(agent?.brokerage?.brokerage_phone);
	const brokerage_name = agent?.brokerage_name;
	const license = agent?.licenses.find((license) => license.category === 'real_estate_agent')?.number;
	const address = agent?.address;
	const video = agent?.video_link;
	const [selectedListing, setSelectedListing] = useState(listings?.active?.length > 0 ? '0' : null);
	const showInvestorForm = agent?.questionnaires_on;

	return (
		<ThemeProvider theme={pageTheme}>
			<Nav listings={listings} name={name} showInvestor={showInvestorForm} />
			<Box sx={{ position: 'relative', scrolMarginTop: '100px' }} id='home'>
				<Carousel
					// autoPlay={true}
					infiniteLoop={true}
					// className={classes.carousel}
					showThumbs={false}
					stopOnHover={false}
					transitionTime={1000}
					interval={5000}
					showArrows={true}
					useKeyboardArrows={false}
					showStatus={false}
					showIndicators={false}
					autoFocus={true}
					animationHandler={'fade'}
					swipeable={true}
					onChange={(index) => {
						// setCurrentImage(index);
						setSelectedListing(`${index}`);
					}}
					renderArrowPrev={(onClickHandler, hasPrev) =>
						hasPrev && (
							<IconButton
								onClick={onClickHandler}
								// disabled={!hasPrev}
								sx={{
									position: 'absolute',
									bottom: '50vh',
									left: '3rem',
									borderRadius: '50%',
									width: 'fit-content',
									aspectRatio: '1/1',
									padding: '0.5rem',
									zIndex: '100',

									backgroundColor: '#B91818',
									'&:hover': { backgroundColor: '#B91818' },
								}}
							>
								<ChevronLeft sx={{ fill: 'white' }} fontSize='large' />
							</IconButton>
						)
					}
					renderArrowNext={(onClickHandler, hasNext) =>
						hasNext && (
							<Box
								sx={{
									position: 'absolute',
									bottom: '50vh',
									right: '2rem',
									display: 'flex',
									gap: '10px',
									zIndex: '100',
								}}
							>
								<IconButton
									onClick={onClickHandler}
									sx={{
										zIndex: '100',
										borderRadius: '50%',
										width: 'fit-content',
										aspectRatio: '1/1',
										padding: '0.5rem',

										backgroundColor: '#B91818',
										'&:hover': { backgroundColor: '#B91818' },
									}}
								>
									<ChevronRight sx={{ fill: 'white' }} fontSize='large' />
								</IconButton>
							</Box>
						)
					}
				>
					{(listings?.active.length > 0 ? listings?.active : images.map((item) => ({ photos: [{ image: item.img }] })))
						.map((item) => item.photos?.[0])
						.filter(Boolean)
						.map((image) => (
							<Box key={image.id} sx={{ width: '100%' }}>
								<img
									src={image.image}
									alt='slider'
									style={{
										width: '100%',
										objectFit: 'cover',
										height: '100vh',
										zIndex: -1,
										backgroundImage: `rgba(33, 37, 41, 0.65) @ 4.43% rgba(0, 0, 0, 0) @ 29.69% rgba(0, 0, 0, 0) @ 77.08% rgba(0, 0, 0, 0.35) @ 100%`,
									}}
									fill='true'
									width={'100%'}
									height={'100%'}
								/>
							</Box>
						))}
				</Carousel>
				{!!selectedListing && (
					<Box
						sx={{
							position: 'absolute',
							bottom: '20vh',
							left: '3rem',
							display: 'flex',
							flexDirection: 'column',
							gap: '10px',
							zIndex: '100',
							width: '350px',
						}}
					>
						<Box sx={{ backgroundColor: 'white', borderRadius: '5px', borderTopLeftRadius: '0px', padding: '1rem' }}>
							<Typography color='black' variat='h4' fontWeight={700} textAlign='left' fontSize='30px'>
								$ {formatNumber(listings?.active[selectedListing]?.list_price)}
							</Typography>
							<Typography
								fontWeight={300}
								mt='1rem'
								fontSize='16px'
								display='flex'
								gap='10px'
								color='black'
								alignItems='center'
								fontfamily='Oswald, sans-serif !important'
							>
								{listings?.active[selectedListing]?.address}
							</Typography>
						</Box>
						<Stack direction='row' gap='10px' alignItems='center' color='white'>
							<Box display='flex' justifyContent='center' gap='10px'>
								<BiBath color='white' />
								<Typography variant='body2' fontFamily='inherit' fontWeight={300}>
									{listings?.active[selectedListing]?.baths}
								</Typography>
							</Box>
							<Box display='flex' justifyContent='center' gap='10px'>
								<BedIcon fontSize='small' sx={{ fill: 'white' }} />
								<Typography variant='body2' fontFamily='inherit' fontWeight={300}>
									{listings?.active[selectedListing]?.beds}
								</Typography>
							</Box>
							<Box display='flex' justifyContent='center' gap='10px'>
								<SquareFoot fontSize='small' sx={{ fill: 'white' }} />
								<Typography variant='body2' fontFamily='inherit' fontWeight={300}>
									{formatNumber(listings?.active[selectedListing]?.square_footage)} sqft
								</Typography>
							</Box>
						</Stack>
					</Box>
				)}
			</Box>
			<Grid container padding={'2rem 5rem'} spacing={2} alignItems='flex-start' my={'3rem'}>
				<Grid item xs={4} display='flex' flexDirection={'column'} gap='1rem' pl='5rem !important'>
					<Stack direction='row' gap='10px' alignItems='center'>
						<Circle>
							<Phone sx={{ fill: '#B91818' }} />
						</Circle>
						<Box>
							<Typography variant='body1' fontSize='16px'>
								<b>Cell Phone:</b>
								{agent_phone}
							</Typography>
							<Typography variant='body1' fontSize='16px'>
								<b>Brokerage:</b>
								{brokerage_name} {brokerage_phone ? `(${brokerage_phone})` : ''}
							</Typography>
						</Box>
					</Stack>
					<Stack direction='row' gap='10px' alignItems='center'>
						<Circle>
							<PlaceOutlined sx={{ fill: '#B91818' }} />
						</Circle>
						<Box>
							<Typography variant='body1' fontSize='16px'>
								{address}
							</Typography>
						</Box>
					</Stack>
					<Stack direction='row' gap='10px' alignItems='center'>
						<Circle>
							<Timer sx={{ fill: '#B91818' }} />
						</Circle>
						<Box>
							<Typography variant='body1' fontSize='16px'>
								<b>Member Since: </b> {member_since}
							</Typography>
						</Box>
					</Stack>
					<Stack direction='row' gap='10px' alignItems='center'>
						<Circle>
							<Mail sx={{ fill: '#B91818' }} />
						</Circle>
						<Box>
							<Typography variant='body1' fontSize='16px'>
								{agent?.user?.email}
							</Typography>
						</Box>
					</Stack>
					<Stack direction='row' gap='10px' alignItems='center'>
						<Circle>
							<LicenseIcon fill='#B91818' />
						</Circle>
						<Box>
							<Typography variant='body1' fontSize='16px'>
								<b>Real Estate License #: </b> {license}
							</Typography>
						</Box>
					</Stack>
				</Grid>
				<Grid item xs={4}>
					<img
						src={agent?.profile_image}
						alt='Agent'
						style={{
							width: '400px',
							aspectRatio: '3/4',
							objectFit: 'cover',
							objectPosition: 'top center',
							borderRadius: '5px',
							marginTop: '-2rem',
						}}
					/>
				</Grid>
				<Grid item xs={4}>
					<Typography variant='h4' sx={{ fontWeight: 'bold', fontFamily: 'Oswald, sans-serif' }}>
						MEET
					</Typography>
					<Typography variant='h4' sx={{ fontWeight: 'bold', fontFamily: 'Oswald, sans-serif', color: '#ff2424', textTransform: 'uppercase' }}>
						{name}
					</Typography>

					<Typography variant='body1' mt={'2rem'} fontWeight={300} whiteSpace={'pre-line'} id='about'>
						{agent?.bio}
					</Typography>
					<SButton variant='text' color='primary' sx={{ marginTop: '2rem' }}>
						Get In Touch
					</SButton>
				</Grid>
			</Grid>
			{showInvestorForm && (
				<Box padding={'2rem'}>
					<InvestorQuestionnaires agent={agent} />
				</Box>
			)}
			{!!video && (
				<Box my={2}>
					<iframe
						style={{
							aspectRatio: '2.4',
							border: 'none',
							width: '100%',
							// height: '800px',
						}}
						src={`https://www.youtube.com/embed/${getYoutubeEmbedLink(video)}`}
						title='Client video'
						allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
						allowFullScreen
					></iframe>
				</Box>
			)}
			{listings?.active.length && (
				<Box py='2rem'>
					<Typography variant='h4' sx={{ fontWeight: '400', fontFamily: 'Oswald, sans-serif', paddingLeft: '5rem', marginBottom: '1rem' }}>
						PROPERTIES{' '}
						<Typography
							variant='h4'
							sx={{
								fontWeight: 'inherit',
								fontFamily: 'Oswald, sans-serif',
								color: '#ff2424',
								textTransform: 'uppercase',
								display: 'inline-block',
							}}
						>
							ON THE MARKET
						</Typography>
					</Typography>
					<Box
						display='flex'
						gap='20px'
						id='active'
						sx={{
							maxWidth: '100%',
							position: 'relative',
							overflowX: 'scroll',
							'&::-webkit-scrollbar': {
								display: 'none',
							},
							scrollBehavior: 'smooth',
						}}
					>
						<IconButton
							ref={(el) => hideButton(el, 'active')}
							sx={{
								position: 'sticky',
								height: '56px',
								top: '40%',
								left: '10px',
								backgroundColor: 'transparent',
								borderRadius: '50%',
								border: '2px solid white',
								'&:hover': { backgroundColor: 'transparent' },
								// boxShadow: '0px 0px 10px 0px #00000026',
							}}
							onClick={() => scrollToTheLeft('active', true)}
						>
							<ChevronLeft fontSize='large' sx={{ fill: 'white' }} />
						</IconButton>
						{listings?.active?.map((listing) => (
							<Card key={listing.id} {...listing} agentId={agent_id} />
						))}

						<IconButton
							ref={(el) => hideButton(el, 'active')}
							sx={{
								position: 'sticky',
								height: '56px',
								top: '40%',
								right: '10px',
								backgroundColor: 'transparent',
								borderRadius: '50%',
								border: '2px solid white',
								'&:hover': { backgroundColor: 'transparent' },
								// boxShadow: '0px 0px 10px 0px #00000026',
							}}
							onClick={() => scrollToTheLeft('active')}
						>
							<ChevronRight fontSize='large' sx={{ fill: 'white' }} />
						</IconButton>
					</Box>
				</Box>
			)}
			{listings?.sold.length > 0 && (
				<Box py='2rem'>
					<Typography variant='h4' sx={{ fontWeight: '400', fontFamily: 'Oswald, sans-serif', paddingLeft: '5rem', marginBottom: '1rem' }}>
						OFF{' '}
						<Typography
							variant='h4'
							sx={{
								fontWeight: 'inherit',
								fontFamily: 'Oswald, sans-serif',
								color: '#ff2424',
								textTransform: 'uppercase',
								display: 'inline-block',
							}}
						>
							THE MARKET
						</Typography>
					</Typography>
					<Box
						display='flex'
						gap='20px'
						id='sold'
						sx={{
							maxWidth: '100%',
							position: 'relative',
							overflowX: 'scroll',
							'&::-webkit-scrollbar': {
								display: 'none',
							},
							scrollBehavior: 'smooth',
						}}
					>
						<IconButton
							ref={(el) => hideButton(el, 'sold')}
							sx={{
								position: 'sticky',
								height: '56px',
								top: '40%',
								left: '10px',
								backgroundColor: 'transparent',
								borderRadius: '50%',
								border: '2px solid white',
								'&:hover': { backgroundColor: 'transparent' },
								// boxShadow: '0px 0px 10px 0px #00000026',
							}}
							onClick={() => scrollToTheLeft('sold', true)}
						>
							<ChevronLeft fontSize='large' sx={{ fill: 'white' }} />
						</IconButton>
						{listings?.sold?.map((listing) => (
							<Card key={listing.id} {...listing} agentId={agent_id} />
						))}

						<IconButton
							ref={(el) => hideButton(el, 'sold')}
							sx={{
								position: 'sticky',
								height: '56px',
								top: '40%',
								right: '10px',
								backgroundColor: 'transparent',
								borderRadius: '50%',
								border: '2px solid white',
								'&:hover': { backgroundColor: 'transparent' },
								// boxShadow: '0px 0px 10px 0px #00000026',
							}}
							onClick={() => scrollToTheLeft('sold')}
						>
							<ChevronRight fontSize='large' sx={{ fill: 'white' }} />
						</IconButton>
					</Box>
				</Box>
			)}

			{testimonials.length > 0 && (
				<Stack
					direction='row'
					justifyContent={'center'}
					alignItems='center'
					gap='1rem'
					sx={{ backgroundColor: '#212529', minHeight: '300px', p: '5rem !important' }}
					my='3rem'
				>
					{testimonials.map((item) => (
						<Review key={item.id} {...item} />
					))}{' '}
				</Stack>
			)}

			{serviceAreas.length > 0 && (
				<Box my={3} py={3} sx={{ background: '#181818', color: 'white' }}>
					<ServiceAreasSlider serviceAreas={serviceAreas} />
				</Box>
			)}
			<Box
				sx={{
					background: `linear-gradient(to right, white 50%, transparent 50%), url(${ConImage}) center center no-repeat`,
					backgroundSize: 'cover',
					padding: '10rem',
					width: '100%',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					mb: '3rem',
				}}
			>
				<Grid container spacing={2} sx={{ maxWidth: '1200px', background: '#FFFFFFE5', padding: '5rem' }} alignItems='center'>
					<Grid item xs={6}>
						<Typography
							variant='h4'
							sx={{
								fontWeight: 'inherit',
								fontFamily: 'Oswald, sans-serif',
								textTransform: 'uppercase',
							}}
						>
							GET MORE INQUIRES
						</Typography>
						<Stack direction='row' alignItems='center' mt='3rem' gap='10px'>
							<SButton variant='text' color='primary' sx={{ px: '3rem !important' }} onClick={sendMessage}>
								Submit
							</SButton>
							<BButton variant='text' color='primary' sx={{ px: '3rem !important' }} href={`/order-bpo-report/${agent_id}`}>
								Get A BPO Report
							</BButton>
						</Stack>
					</Grid>
					<Grid item xs={6}>
						<Grid container spacing={3} pl='2rem'>
							<Grid item xs={12}>
								<TextField
									fullWidth
									label='Full Name'
									variant='outlined'
									value={contactDetails?.name}
									onChange={updateContactDetails}
									name='name'
									size='small'
								/>
							</Grid>
							<Grid item xs={6}>
								<TextField
									fullWidth
									size='small'
									label='Email'
									variant='outlined'
									value={contactDetails?.email}
									onChange={updateContactDetails}
									name='email'
								/>
							</Grid>
							<Grid item xs={6}>
								<TextField
									fullWidth
									label='Phone Number'
									variant='outlined'
									size='small'
									value={contactDetails?.phone}
									onChange={updateContactDetails}
									name='phone'
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									fullWidth
									multiline
									label='Message'
									variant='outlined'
									size='small'
									rows={5}
									value={contactDetails?.message}
									onChange={updateContactDetails}
									name='message'
								/>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Box>
		</ThemeProvider>
	);
};

export default NewTemp9;

const ELink = styled(Link)(({ theme, color }) => ({
	color: 'white',
	textDecoration: 'none',
	cursor: 'pointer',
	fontFamily: 'Oswald, sans-serif',
}));

const Card = ({ id, agentId, list_price, photos, beds, baths, square_footage, address }) => {
	return (
		<ELink component={RLink} to={`/view-listing/${agentId}/${id}`} target='_blank'>
			<Box
				width='400px'
				sx={{
					background: `linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 70.31%),url(${photos?.[0]?.image})`,
					backgroundSize: 'cover',
					backgroundPosition: 'center',
					backgroundRepeat: 'no-repeat',
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'flex-end',
					aspectRatio: '1.12',
					padding: '1rem',
				}}
			>
				<Box>
					<Typography variant='h3' textTransform='uppercase' color='white' fontSize='24px' fontWeight='700' fontFamily={'Oswald, sans-serif'}>
						$ {formatNumber(list_price, 0)}
					</Typography>
					<Typography variant='subtitle1' fontSize='14px' fontWeight='400'>
						{address}
					</Typography>
					<Box display='flex'>
						<Typography variant='body' fontWeight='300'>
							{beds} Beds
						</Typography>
						<Typography
							variant='body'
							fontWeight='300'
							sx={{ borderLeft: '1px solid currentColor', borderRight: '1px solid currentColor' }}
							px='10px'
							mx='10px'
						>
							{baths} Baths
						</Typography>
						<Typography variant='body' fontWeight='300'>
							{formatNumber(square_footage, 0)} Sqft
						</Typography>
					</Box>
				</Box>
			</Box>
		</ELink>
	);
};

const Review = ({ description, client, name }) => {
	return (
		<Stack spacing={2} alignItems='center' flex='1' color='white'>
			<Typography variant='body1' fontSize='100px' textTransform='uppercase' fontFamily='Roboto' color='#ff2424' lineHeight={0}>
				&ldquo;
			</Typography>
			<Typography variant='body1' textAlign='center' fontWeight='300' fontSize='18px' mt='auto !important' fontFamily={'Oswald, sans-serif'}>
				- {client?.user?.first_name || client?.user?.last_name ? client?.user?.first_name + ' ' + client?.user?.last_name : name}
			</Typography>
			<Typography variant='body1' textAlign='center' fontStyle='italic'>
				{description}
			</Typography>
		</Stack>
	);
};

const Circle = ({ children }) => {
	return (
		<Box sx={{ width: 'fit-content', aspectRatio: '1', padding: '10px', borderRadius: '50%', boxShadow: '-4px 4px 30px 0px #0000000D' }}>
			{children}
		</Box>
	);
};

const R1Link = styled(Link)(({ theme, color }) => ({
	cursor: 'pointer',
}));

const useStyles = makeStyles((theme) => ({
	header: {
		position: 'fixed',
		top: '0',
		width: '100%',
		padding: '1.5rem 5rem',
		display: 'flex',
		justifyContent: 'space-between',
		zIndex: '100',
	},
}));

const Nav = ({ listings, name, showInvestor }) => {
	const [showHeader, setShowHeader] = useState(false);
	const { id: agentId } = useParams();

	useEffect(() => {
		const handleScroll = () => {
			const scrollPosition = window.scrollY;
			if (scrollPosition > window.innerHeight) {
				setShowHeader(true);
			} else {
				setShowHeader(false);
			}
		};

		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	const classes = useStyles();
	return (
		<Box className={classes.header} sx={{ background: showHeader ? 'white' : 'transparent', color: showHeader ? 'black' : 'white' }}>
			<Box display='flex' gap='1rem' fontFamily='Oswald, sans-serif' textTransform={'uppercase'}>
				<R1Link href='#home' underline='none' color='inherit' fontFamily='inherit'>
					Home
				</R1Link>
				<R1Link href='#about' underline='none' color='inherit' fontFamily='inherit'>
					About
				</R1Link>
				{listings?.active.length > 0 && (
					<R1Link href='#active' underline='none' color='inherit' fontFamily='inherit'>
						Active Listings
					</R1Link>
				)}
				<R1Link href='#contact' underline='none' color='inherit' fontFamily='inherit'>
					Contact
				</R1Link>
				<R1Link href={`/agent/${agentId}/blogs`} underline='none' color='inherit' fontFamily='inherit'>
					Blog
				</R1Link>
				{showInvestor && (
					<R1Link href='#investors' underline='none' color='inherit' fontFamily='inherit'>
						Investors
					</R1Link>
				)}
			</Box>

			<Typography variant='body1' fontFamily='Oswald, sans-serif' color='inherit' fontWeight='400' fontSize='16px' textTransform={'uppercase'}>
				Listing Agent: {name}
			</Typography>
		</Box>
	);
};
