import React from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#fff",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 280,
    fontSize: theme.typography.pxToRem(12),
    // border: "1px solid #dadde9",
    boxShadow: "0 0 10px  #818181",

    marginLeft: "3rem",
  },
}));

function RegularToolTip({ content, children }) {
  const title = typeof content === "object" ? content?.title : null;
  const body = typeof content === "object" ? content?.text : content;
  return (
    <>
      <HtmlTooltip
        title={
          <React.Fragment>
            <Box>
              {title && (
                <Box sx={{ textAlign: "center", color: "#818181" }}>
                  <Typography variant="h6">{title}</Typography>
                </Box>
              )}
              <Box sx={{ textAlign: "center" }}>
                <Typography variant="toolTip" sx={{ color: "#818181" }}>
                  {body}
                </Typography>
              </Box>
            </Box>
          </React.Fragment>
        }
        placement="right-end"
      >
        {children && children}
      </HtmlTooltip>{" "}
    </>
  );
}

export default RegularToolTip;
