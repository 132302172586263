import React from 'react'
import { Box, Divider, Grid, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({

    bar: {
        width: '5rem',
        margin: '1.5rem 0 !important',
        height: '8px',
        background: theme.primaryColor,
        border: 'none !important'
    },
    secGrid: {
        margin: '0 !important',
        width: '100% !important',
        [theme.breakpoints.down('md')]:{
            margin: 'auto',
            width: 'auto !important'
        },
        [theme.breakpoints.down('sm')]:{
            margin: 'auto',
            width: 'auto !important'
        }
    },
    secContent:{
        padding:'3.5rem !important',
        display: 'flex',
        flexDirection: 'column !important',
        justifyContent: 'center',
        textAlign: 'left'
    },
    secImg: {
        height: '28rem',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        [theme.breakpoints.down('md')]: {
            height: '30rem',
            width: '100% !important'
        },
        [theme.breakpoints.down('sm')]: {
            height: '20rem',
            width: '100% !important'
        }
    },
}))

const GridSection = ({index, title, copy, img}) => {
    const classes = useStyles()
    
    const renderPage = () => {
        if(index % 2 === 0){
            return (
                <Box padding='3rem 0'>
                    <Grid container spacing={5} className={classes.secGrid}>
                        <Grid item lg={7} md={7} sm={12} xs={12} className={classes.secImg} style={{backgroundImage: `url(${img})`}}></Grid>
                        <Grid item lg={5} md={5} sm={12} xs={12} className={classes.secContent}>
                            <Divider className={classes.bar} />
                            <Typography variant='h6' gutterBottom style={{marginBottom: '1.4rem', lineHeight: 1.3}}>{title}</Typography>
                            <Typography variant='body2' paragraph >{copy}</Typography>
                        </Grid>
                    </Grid>
                </Box>
            )
        }else{
            return (
                <Box padding='3rem 0'>
                    <Grid container spacing={5} className={classes.secGrid}>
                        <Grid item lg={5} md={5} sm={12} xs={12} className={classes.secContent} order={{md:1, sm: 2, xs: 2}}>
                            <Divider className={classes.bar} />
                            <Typography variant='h6' gutterBottom style={{marginBottom: '1.4rem', lineHeight: 1.3}}>{title}</Typography>
                            <Typography variant='body2' paragraph >{copy}</Typography>
                        </Grid>
                        <Grid item lg={7} md={7} sm={12} xs={12} className={classes.secImg} style={{backgroundImage: `url(${img})`}} order={{md:2, sm: 1, xs: 1}}></Grid>
                    </Grid>
                </Box>
            )
        }
    }

    return (
        <>
        { renderPage() }
        </>
    )
}

export default GridSection