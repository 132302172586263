import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { Box, Button, Link as DLink, Grid, IconButton, Stack, TextField, ThemeProvider, Typography, createTheme } from '@mui/material';
import { makeStyles, styled } from '@mui/styles';
import React from 'react';
import { Link as RLink } from 'react-router-dom';
import { headerImages } from '../../constants';
import BG from '../../images/bac.webp';
import { ReactComponent as FBIcon } from '../../images/facebookfillable.svg';
import { ReactComponent as IGIcon } from '../../images/instagramfillable.svg';
import { ReactComponent as LIIcon } from '../../images/linkedInfillable.svg';
import { ReactComponent as TWIcon } from '../../images/twitterfillable.svg';
import { ReactComponent as YTIcon } from '../../images/youtubefillable.svg';
import { theme } from '../../theme';
import { formatNumber, formatPhoneNumber, getYoutubeEmbedLink, hideButton, scrollToTheLeft } from '../../utils/functions';
import ServiceAreasSlider from '../../components/ServiceAreasSlider/ServiceAreasSlider';
import InvestorQuestionnaires from '../../components/InvestorQuestionnaires/InvestorQuestionnaires';

const Link = styled(DLink)(({ theme }) => ({
	cursor: 'pointer',
}));

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundImage: `url(${BG})`,
		backgroundAttachment: 'fixed',
		backgroundSize: 'cover',
	},
	mainBack: {
		height: '100vh',
		width: '100%',
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		animation: 'zoomInOut 5s infinite alternate',
	},
	title: {
		color: '#3e4247',
		fontSize: '20px',
		textTransform: 'uppercase',
		borderLeft: `3px solid currentColor`,
		paddingLeft: '10px',
		fontFamily: 'Montserrat, sans-serif',
		fontWeight: '700',
	},
	// '@keyframes zoomInOut': {
	// 	'0%': {
	// 		transform: 'scale(1)',
	// 	},
	// 	'50%': {
	// 		transform: 'scale(1.2)',
	// 	},
	// 	'100%': {
	// 		transform: 'scale(1)',
	// 	},
	// },
}));

const pageTheme = createTheme({
	...theme,
	typography: {
		fontFamily: 'Cormorant Garamond, serif',
		button: {
			borderRadius: '2px',
		},
	},
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					borderRadius: 2,
				},
			},
		},
	},
	palette: {
		primary: {
			// main: '#3e4247',
			main: '#000',
		},
		secondary: {
			main: '#3e4247',
		},
		text: {
			main: '#000',
		},
		paper: {
			main: '#fff',
		},
	},
	primaryColor: '#122c77',
	secondaryColor: '#3e4247',
});

function NewTemp4({ agent, listings, testimonials, contactDetails, updateContactDetails, sendMessage, serviceAreas }) {
	const classes = useStyles();
	const name = agent?.user.first_name + ' ' + agent?.user.last_name;
	const agent_id = agent?.id;
	const member_since = agent?.year_work_commenced;
	const agent_phone = formatPhoneNumber(agent?.phone);
	const brokerage_phone = formatPhoneNumber(agent?.brokerage?.brokerage_phone);
	const brokerage_name = agent?.brokerage_name;
	const license = agent?.licenses.find((license) => license.category === 'real_estate_agent')?.number;
	const address = agent?.address;
	const video = agent?.video_link;
	const showInvestorForm = agent?.questionnaires_on;

	return (
		<ThemeProvider theme={pageTheme}>
			<Box className={classes.root} id='home'>
				<Box
					sx={{
						backgroundImage: `linear-gradient(rgba(0,0,0,0.2), rgba(0,0,0,0.2)),url(${headerImages?.[0].img})`,
					}}
					className={classes.mainBack}
				>
					<Box
						height='100%'
						width='100%'
						sx={{ background: `linear-gradient(to right, transparent 60%, #02020263 60%, #02020263 100%)`, zIndex: '20', position: 'relative' }}
					>
						<Box sx={{ position: 'absolute', top: '0', width: '100%', padding: '2rem', display: 'flex' }}>
							<Box width='60vw'>
								<Typography variant='body1' paddingLeft='2rem' color='white' sx={{ textTransform: 'uppercase' }}>
									Listing Agent: {name}
								</Typography>
							</Box>
							<Box display='flex' gap='1rem' fontFamily='Cormorant Garamond, serif'>
								<Link href='#home' underline='none' color='white' fontFamily='inherit' sx={{ textTransform: 'uppercase' }}>
									Home
								</Link>
								<Link href='#about' underline='none' color='white' fontFamily='inherit' sx={{ textTransform: 'uppercase' }}>
									About
								</Link>
								<Link href='#active' underline='none' color='white' fontFamily='inherit' sx={{ textTransform: 'uppercase' }}>
									Active Listings
								</Link>
								<Link href='#contact' underline='none' color='white' fontFamily='inherit' sx={{ textTransform: 'uppercase' }}>
									Contact
								</Link>
								<Link href={`/agent/${agent?.id}/blogs`} underline='none' color='white' fontFamily='inherit' sx={{ textTransform: 'uppercase' }}>
									Blog
								</Link>
								{showInvestorForm && (
									<Link href='#investors' underline='none' color='white' fontFamily='inherit' sx={{ textTransform: 'uppercase' }}>
										Investors
									</Link>
								)}
							</Box>
						</Box>
						<Stack direction='row' spacing={3} sx={{ position: 'absolute', bottom: '3rem', left: '2rem' }}>
							{agent?.facebook && (
								<IconButton target='blank' href={agent?.facebook}>
									<FBIcon fill='white' />
								</IconButton>
							)}
							{agent?.twitter && (
								<IconButton target='blank' href={agent?.twitter}>
									<TWIcon fill='white' />
								</IconButton>
							)}
							{agent?.instagram && (
								<IconButton target='blank' href={agent?.instagram}>
									<IGIcon fill='white' />
								</IconButton>
							)}
							{agent?.linkedin && (
								<IconButton target='blank' href={agent?.linkedin}>
									<LIIcon fill='white' />
								</IconButton>
							)}
							{agent?.video_link && (
								<IconButton target='blank' href={agent?.video_link}>
									<YTIcon fill='white' />
								</IconButton>
							)}
						</Stack>
						<Box display='flex' flexDirection='column' width='40vw' marginLeft='auto' padding='20vh 2rem 2rem 2rem' height={'100%'}>
							<Typography variant='h4' fontFamily='Montserrat, sans-serif' color='#fff' fontSize='40px' fontWeight='700'>
								{name}
							</Typography>
							<Typography variant='body1' color='#fff' fontSize='16px' mt={2}>
								{address}
							</Typography>
							<Box display='flex' gap='10px' my={3}>
								<Button variant='outlined' disableElevation href={`/order-bpo-report/${agent_id}`} target='_blank'>
									GET A BPO REPORT
								</Button>
								<Button variant='contained' disableElevation href='#contact'>
									LET'S CONNECT
								</Button>
							</Box>
							<Grid container spacing={2} mt={4} color='#fff' fontFamily='Cormorant Garamond, serif'>
								<Grid item xs={6}>
									Member Since: {member_since}
								</Grid>
								<Grid item xs={6}>
									Cell Phone:{agent_phone}
								</Grid>
								<Grid item xs={6}>
									Real Estate License: {license}
								</Grid>
								<Grid item xs={6}>
									Brokerage: {brokerage_name} {brokerage_phone ? `(${brokerage_phone})` : ''}
								</Grid>
							</Grid>
						</Box>
					</Box>
				</Box>
				<Box padding='2rem 4rem'>
					<Typography variant='h4' className={classes.title} my={1} id='about'>
						About
					</Typography>
					<Box mt={5} sx={{ backgroundColor: 'white' }}>
						<Grid container spacing={2} alignItems='center' justifyContent='center'>
							<Grid item xs={4} alignItems='flex-start' sx={{ position: 'relative', pt: '0 !important' }}>
								<Typography
									variant='body'
									fontFamily={'Montserrat, sans-serif'}
									fontWeight={500}
									color='#3e4247'
									sx={{ position: 'absolute', top: 0, left: '1rem', padding: '0.5rem 1rem', backgroundColor: 'white', textTransform: 'uppercase' }}
								>
									{name}
								</Typography>
								<img
									src={agent?.profile_image}
									alt='Agent'
									style={{
										width: '400px',
										aspectRatio: '3/4',
										objectFit: 'cover',
										objectPosition: 'top center',
									}}
								/>
							</Grid>
							<Grid item xs={8}>
								<Typography textAlign='justify' fontWeight={300} p={2} whiteSpace='pre-line'>
									{agent?.bio}
								</Typography>
								<Button variant='outlined' color='secondary' href='#contact'>
									Get In Touch
								</Button>
							</Grid>
						</Grid>
					</Box>
				</Box>
				{video && (
					<Box padding='2rem 4rem'>
						<iframe
							style={{
								aspectRatio: '16/9',
								border: 'none',
								width: '60%',
								marginLeft: 'auto',
								display: 'block',
							}}
							src={`https://www.youtube.com/embed/${getYoutubeEmbedLink(video)}`}
							title='Client video'
							allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
							allowFullScreen
						></iframe>
					</Box>
				)}

				{showInvestorForm && (
					<Box padding={'2rem 4rem'}>
						<InvestorQuestionnaires agent={agent} />
					</Box>
				)}

				{/* active listings */}
				{listings?.active?.length > 0 && (
					<Box
						padding={'2rem 4rem'}
						display='flex'
						flexDirection='column'
						sx={{
							position: 'relative',
							backgroundColor: '#f2f3f4',
						}}
					>
						<Typography variant='h4' className={classes.title} mb={2}>
							Active Listings
						</Typography>
						<Box
							display='flex'
							gap='20px'
							id='active'
							sx={{
								maxWidth: '100%',
								overflowX: 'scroll',
								'&::-webkit-scrollbar': {
									display: 'none',
								},
								scrollBehavior: 'smooth',
							}}
						>
							<IconButton
								ref={(el) => hideButton(el, 'active')}
								sx={{
									position: 'absolute',
									top: '40%',
									left: '40px',
									backgroundColor: 'white',
									borderRadius: '2px',
									'&:hover': { backgroundColor: 'white' },
									boxShadow: '0px 0px 10px 0px #00000026',
								}}
								onClick={() => scrollToTheLeft('active', true)}
							>
								<ChevronLeft fontSize='large' />
							</IconButton>
							{listings?.active?.map((active) => (
								<Card key={active.id} {...active} agentId={agent_id} />
							))}
							<IconButton
								ref={(el) => hideButton(el, 'active')}
								sx={{
									position: 'absolute',
									top: '40%',
									right: '40px',
									backgroundColor: 'white',
									borderRadius: '2px',
									'&:hover': { backgroundColor: 'white' },
									boxShadow: '0px 0px 10px 0px #00000026',
								}}
								onClick={() => scrollToTheLeft('active', false)}
							>
								<ChevronRight fontSize='large' />
							</IconButton>
						</Box>
					</Box>
				)}
				{/* sold listings */}
				{listings?.sold?.length > 0 && (
					<Box
						padding={'2rem 4rem'}
						display='flex'
						flexDirection='column'
						sx={{
							position: 'relative',
						}}
					>
						<Typography variant='h4' className={classes.title} mb={2}>
							Sold Listings
						</Typography>
						<Box
							display='flex'
							gap='20px'
							id='sold'
							sx={{
								maxWidth: '100%',
								overflowX: 'scroll',
								'&::-webkit-scrollbar': {
									display: 'none',
								},
								scrollBehavior: 'smooth',
							}}
						>
							<IconButton
								ref={(el) => hideButton(el, 'sold')}
								sx={{
									position: 'absolute',
									top: '40%',
									left: '40px',
									backgroundColor: 'white',
									borderRadius: '2px',
									'&:hover': { backgroundColor: 'white' },
									boxShadow: '0px 0px 10px 0px #00000026',
								}}
								onClick={() => scrollToTheLeft('sold', true)}
							>
								<ChevronLeft fontSize='large' />
							</IconButton>
							{listings?.sold?.map((sold) => (
								<Card key={sold.id} {...sold} agentId={agent_id} />
							))}
							<IconButton
								ref={(el) => hideButton(el, 'sold')}
								sx={{
									position: 'absolute',
									top: '40%',
									right: '40px',
									backgroundColor: 'white',
									borderRadius: '2px',
									'&:hover': { backgroundColor: 'white' },
									boxShadow: '0px 0px 10px 0px #00000026',
								}}
								onClick={() => scrollToTheLeft('sold', false)}
							>
								<ChevronRight fontSize='large' />
							</IconButton>
						</Box>
					</Box>
				)}

				<Grid container spacing={2} padding={'2rem 4rem'} alignItems='center' justifyContent='center' id='contact'>
					<Grid item xs={6} display='flex' flexDirection='column'>
						<Typography variant='h4' className={classes.title}>
							stay updated. be the first to know
						</Typography>
						<Typography variant='body' fontWeight='300' fontFamily='Cormorant Garamond, serif' mt={1}>
							For more information or inquires, kindly use the form to contact me
						</Typography>
					</Grid>
					<Grid item xs={6} display='flex' flexDirection='column' gap='2rem' alignItems='flex-start'>
						<TextField
							variant='standard'
							color='secondary'
							fullWidth
							placeholder='Enter full name'
							value={contactDetails.name}
							onChange={updateContactDetails}
							name='name'
						/>
						<TextField
							variant='standard'
							color='secondary'
							fullWidth
							placeholder='Enter phone number'
							value={contactDetails.phone}
							onChange={updateContactDetails}
							name='phone'
						/>
						<TextField
							variant='standard'
							color='secondary'
							fullWidth
							placeholder='Enter email'
							value={contactDetails.email}
							onChange={updateContactDetails}
							name='email'
						/>
						<TextField
							variant='standard'
							color='secondary'
							fullWidth
							multiline
							placeholder='Write message'
							value={contactDetails.message}
							onChange={updateContactDetails}
							name='message'
							sx={{ fontFamily: 'Cormorant Garamond, serif' }}
						/>
						<Button variant='outlined' disableElevation color='secondary' sx={{ px: '4rem' }} onClick={sendMessage}>
							Submit
						</Button>
					</Grid>
				</Grid>
				<Box sx={{ backgroundColor: 'white', p: '2rem 4rem' }}>
					<Typography variant='h4' className={classes.title}>
						My Satisfied Customer says
					</Typography>
					{testimonials.length > 0 && (
						<Stack direction='row' spacing={2} alignItems='center' mt='2rem'>
							{testimonials.map((testimonial) => (
								<Review key={testimonial.id} {...testimonial} />
							))}
						</Stack>
					)}
				</Box>
				{serviceAreas.length > 0 && (
					<Box my={3}>
						<ServiceAreasSlider serviceAreas={serviceAreas} />
					</Box>
				)}
			</Box>
		</ThemeProvider>
	);
}

export default NewTemp4;

const Card = ({ id, agentId, list_price, photos, beds, baths, square_footage, address }) => {
	return (
		<Link component={RLink} to={`/view-listing/${agentId}/${id}`} target='_blank'>
			<Box display='flex' flexDirection='column' gap='10px' fontFamily='Cormorant Garamond, serif'>
				<img alt='house' src={photos?.[0]?.image} style={{ width: '300px', aspectRatio: '13.5/10' }} />
				<Typography variant='h6' fontWeight='700' fontFamily='Montserrat, sans-serif'>
					$ {formatNumber(list_price, 0)}
				</Typography>
				<Typography variant='body' fontWeight='300'>
					{address}
				</Typography>
				<Box display='flex'>
					<Typography variant='body' fontWeight='300'>
						{beds} Beds
					</Typography>
					<Typography
						variant='body'
						fontWeight='300'
						sx={{ borderLeft: '1px solid currentColor', borderRight: '1px solid currentColor' }}
						px='10px'
						mx='10px'
					>
						{baths} Baths
					</Typography>
					<Typography variant='body' fontWeight='300'>
						{formatNumber(square_footage, 0)} Sqft
					</Typography>
				</Box>
			</Box>
		</Link>
	);
};

const Review = ({ description, name, client }) => {
	return (
		<Stack flex='1'>
			<Stack
				spacing={2}
				alignItems='flex-start'
				flex='1'
				sx={{
					position: 'relative',
					zIndex: 2,
					backgroundColor: 'transparent',
					quotes: `\\201C""\\201D""\\2018""\\2019`,
					'&:before': {
						position: 'absolute',
						top: '-10px',
						left: 0,
						color: '#ccc',
						content: ' open-quote',
						fontSize: '7em',
						fontFamily: 'auto',
						zIndex: -1,
					},
					'&:after': {
						visibility: 'hidden',
						content: 'close-quote',
					},
				}}
			>
				<Typography variant='body1' textAlign='left'>
					{description}
				</Typography>
			</Stack>
			<Typography variant='body1' textAlign='left' fontWeight='700' fontSize='18px' mt='auto'>
				{client?.user?.first_name || client?.user?.last_name ? client?.user?.first_name + ' ' + client?.user?.last_name : name}
			</Typography>
		</Stack>
	);
};
