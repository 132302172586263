import { Box, Typography, Button } from '@mui/material'
import GoogleAutoComplete from '../../../../AnalyzePropertyComponents/GoogleAutoComplete'
import React, { useState } from 'react'
import { useTheme } from '@mui/material/styles'
import CompCheckTableItem from './CompCheckTableItem'
import { ClipLoader } from 'react-spinners'
import useCustomComps from './compsHooks/useCustomComps'
import { useEffect } from 'react'

const useStyles = (theme) => {
  return {
    root: {

    },
    heading: {
      borderBottom: `solid 1px ${theme.palette.primary.main}`
    },
    buttonBox: {
      display: "flex",
      marginTop: "1.25rem",
      justifyContent: "flex-end"
    }

  }
}

function CustomComp({ handleClose, handleAddComps }) {
  const theme = useTheme()
  const styles = useStyles(theme)
  const [errorArray, setErrorArray] = useState([])
  const [showGooglePlaces, setShowGooglePlaces] = useState(false)

  const {
    savedCustomComps,
    handleUpdateCustomComps,
    addNewCustomComps,
    deleteCustomComp,
    validateRequiredFields,
    submitCustomComps,
    saveOldCustomComps,
    restoreOldCompsOnCancel,
    replaceAllCustomComps,
    loading
  } = useCustomComps();

  const handleGooglePlace = (data) => {
    // setSearchData(data?.description)      
    // searchCustomComps(data?.description)
    // navigate(`deal-analyzer/?address=${data?.description}`)
    setShowGooglePlaces(prev => !prev)
    addNewCustomComps({ address: data?.description })
  }
  const styleOverride = {
    "& .MuiAutocomplete-inputRoot": {
      background: " #ffff",
      fontSize: "1.5rem",
      fontWeight: 400,
      marginBottom: "3.125rem",
      padding: "0"
    },
    width: "100%",
    marginTop: "1.25rem"

  }

  const addCustomCompsAndClosePopup = async () => {
    const errors = validateRequiredFields()
    if (errors?.length && !errors.every(element => element === '')) {
      setErrorArray(prev => errors)
      return
    }
    replaceAllCustomComps(savedCustomComps.map(element => ({ ...element, new: false })))
    await submitCustomComps()
    handleClose()
  }
  const handleCancel = () => {
    restoreOldCompsOnCancel()
    handleClose()
  }

  useEffect(() => {
    saveOldCustomComps()
    // NOTE: Run effect once on component mount, please
    // recheck dependencies if effect is updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [])
  return (
    <Box>
      <Box sx={styles.heading}>
        <Typography variant="tableRow">Custom Comps</Typography>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        {showGooglePlaces ? (
          <GoogleAutoComplete
            handleLocation={handleGooglePlace}
            styleOverride={styleOverride}
            noSearchIcon={true}
            placeholder="Enter Property Address"
          />
        ) : (
          <Button
            variant="outlined"
            sx={{ my: 2, px: 2, py: 1, textTransform: "none" }}
            onClick={() => setShowGooglePlaces((prev) => !prev)}
          >
            <Typography variant="h4">Add a new property</Typography>
          </Button>
        )}
      </Box>
      {!!savedCustomComps?.length &&
        savedCustomComps.map((element, index) => (
          <CompCheckTableItem
            list={element}
            noCheckbox={true}
            isCustomComps
            deleteCustomComp={deleteCustomComp}
            index={index}
            handleUpdate={handleUpdateCustomComps}
            errors={errorArray?.[index]}
          />
        ))}
      <Box sx={styles.buttonBox}>
        <Button variant="outlined" onClick={handleCancel} sx={{ mr: 4 }}>
          <Typography variant="buttonFont" sx={{ padding: "0.5rem 1rem" }}>
            Cancel
          </Typography>
        </Button>
        <Button variant="contained" onClick={addCustomCompsAndClosePopup}>
          {!loading ? (
            <Typography variant="buttonFont" sx={{ padding: "0.5rem 1.25rem" }}>
              OK
            </Typography>
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                px: 2
              }}
            >
              <ClipLoader color={"#fff"} loading={true} size={24} />
            </Box>
          )}
        </Button>
      </Box>
    </Box>
  );
}

export default CustomComp
