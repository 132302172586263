import { financeOptionsCals } from "./FinanceOptions";
import { carryingCostAmountFunc } from "../carryingCost/curringCostAmount";
import { yearsToMonthsTransformer } from "../utils/genericFunctions";
import { carryCostCalculator } from "../carryingCost/carryingCostCalcs";

export const carryingMonthsCalcs = ({
  remodelCosts,
  developmentCost,
  financeOptions,
  resultData,
  dealId,
  propertyPermitAndFees,
  taxes,
  rentalIncome,
  propertyManagement,
  otherCosts,
  carryingMonths,
}) => {
  const { miscellaneous, utilities } = otherCosts;

  const { annual_maintenance } = propertyManagement || {};

  const { monthly_hoa_dues, monthly_home_insurance } =
    propertyPermitAndFees || {};

  const {
    carrying_cost_owning_months,
    carrying_cost_owning_months_month_or_year,
  } = carryingMonths;

  const {
    deal_finance_option,
    primary_sub_loan_type,
    secondary_sub_loan_type,
    showSecondary,
    primary_loan_type,
    cash_for_home_purchase_price,
    secondary_loan_type,
    primary_purchase_price,
  } = financeOptions || {};

  const financeOptionsCals1 = financeOptionsCals({
    remodelCosts,
    developmentCost,
    financeOptions,
    resultData,
    dealId,
    propertyPermitAndFees,
    taxes,
    rentalIncome,
    propertyManagement,
  });

  const months = +yearsToMonthsTransformer(
    carrying_cost_owning_months_month_or_year,
    carrying_cost_owning_months
  );

  const purhcasePrice =
    +deal_finance_option === 3
      ? cash_for_home_purchase_price
      : primary_purchase_price;

  const carryingCostAmount = () => {
    const carryingCostAmount = carryingCostAmountFunc(
      +financeOptionsCals1?.localPrimaryTotalMonthlyAmount, //1
      financeOptionsCals1?.PrimaryInterestAloneMortgagePayment, //2
      +purhcasePrice, //3
      financeOptionsCals1?.remodelCostUsed, //4
      +deal_finance_option, //5
      +primary_sub_loan_type, //6
      +secondary_sub_loan_type, //7
      financeOptionsCals1?.secondaryInterestOnlyMonthlyAmount, //8
      +financeOptionsCals1?.localSecondaryTotalMonthlyAmount, //9
      months, //10
      showSecondary, //11
      +primary_loan_type, //12
      financeOptionsCals1.localGeneralLoanAmount, //13
      financeOptionsCals1?.localSecondaryLoanAmount, //14
      +cash_for_home_purchase_price, //15
      +secondary_loan_type //16
    );

    return +carryingCostAmount;
  };

  const localTotalCurryingCost = () => {
    const amount = carryingCostAmount();

    const localCurryingCost = carryCostCalculator(
      amount,
      monthly_hoa_dues,
      +monthly_home_insurance,
      +financeOptionsCals1?.getMonthlyStatePropertyTax,
      financeOptionsCals1?.monthlyPropertyManagementFees,
      +annual_maintenance,
      +miscellaneous,
      +utilities,
      +months
    ).toFixed(2);
    return localCurryingCost;
  };

  return {
    carryingCostAmount: carryingCostAmount(),
    localTotalCurryingCost: localTotalCurryingCost(),
    months,
  };
};
