import { GoogleMap } from '@react-google-maps/api';
import React from 'react';

//!lat between -90 and 90 and lng between -180 and 180

const Map = ({ center, children, zoom = 15, ...rest }) => {
	// const { isLoaded } = useLoadScript({ googleMapsApiKey: process.env.REACT_APP_GOOGLE_KEY });
	// console.log(isLoaded);
	// if (!isLoaded) return <Spinner overlay={false} />;

	return (
		<GoogleMap center={center} zoom={zoom} mapContainerStyle={{ width: '100%', height: '100%' }} options={{ fullscreenControl: true }} {...rest}>
			{children}
		</GoogleMap>
	);
};

export default Map;
